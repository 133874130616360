import React from 'react';
import SearchInput from '../../../reusable/form/items/inputs/SearchInput.jsx';
import PrimaryButton from '../../../reusable/btns/buttons/PrimaryButton.jsx';
import style from './chose-image.module.scss';
import UploadField from './UploadField.jsx';
import useSetParenComponentProps from '../../../../hooks/useSetParenComponentProps.js';
import {useDispatch, useSelector} from 'react-redux';
import {
  clearParentComponent,
  closeModal,
} from '../../../../store/slices/modalSlice/modalSlice.js';
import Strings from '../../../../string_key/Strings.web.js';

const ChoseImageModal = props => {
  const dispatch = useDispatch();
  const setProps = useSetParenComponentProps();
  let selectedImage = '';
  const {callback} = useSelector(state => state.modal);
  const onChange = e => {
    const {value} = e.target;
    selectedImage = value;
  };

  const save = e => {
    e.preventDefault();
    dispatch(clearParentComponent());
    setTimeout(() => {
      setProps('ChoseImage', {image: selectedImage});
    }, 150);
    // if (callback) {
    //   callback(selectedImage);
    // }
    dispatch(closeModal());
  };

  return (
    <div className={style['chose-image']}>
      <div className="h4 text-center other-services__title">
        {Strings.choose_cover_image}
      </div>
      <UploadField />
      <SearchInput />
      <form
        id="redirectForm"
        className={`row g-21 ${style['chose-image__content']}`}>
        {props?.coverList != null &&
          props?.coverList.length > 0 &&
          props?.coverList.map((creator, index) => (
            <label className="col-3" key={index}>
              <input
                type="radio"
                name="image"
                value={creator}
                onChange={onChange}
              />
              <div className={style['chose-image__img']}>
                <img src={creator} className="img-fluid" />
              </div>
            </label>
          ))}
      </form>
      <div className={style['chose-image__button']}>
        <PrimaryButton onClick={save} type="button">
          {Strings.save}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default ChoseImageModal;
