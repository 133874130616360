import {useMutation} from 'react-query';
import {createTask} from '../../../network/api/app';

export const useCreateTask = () => {
  const {
    mutate: mutateCreateTask,
    isLoading: isCreateTaskLoading,
    isError: isCreateTaskError,
    data: taskResponse,
    error: taskErrorResponse,
  } = useMutation(createTask);

  const postCreateTask = params => mutateCreateTask(params);

  return {
    taskErrorResponse,
    isCreateTaskError,
    isCreateTaskLoading,
    taskResponse,
    postCreateTask,
  };
};
