import React from 'react';
import image3 from '../../../../../../../assets/creators/1.jpg';
const FriendCard = ({
  avatar,
  assignedIds,
  name,
  nickname,
  position,
  socialLink,
  children,
  className = '',
  onClick,
  key,
  friend_id
}) => {

  return (
    <div className={`friend-card ${className}`} onClick={onClick}>
      <div className="friend-data__wrapper">
        <div className="friend-data__avatar-wrapper">
          <img
            src={avatar ? avatar : image3}
            alt="friend avatar"
            className="friend-data__avatar"
          />

          {/* <div className="friend-data__avatar-social-link">
            <img src={socialLink?.src} alt={socialLink?.name} />
          </div> */}
        </div>

        <div className="friend-data__info">
          <div className="d-flex flex-row align-items-center friend-data__name">
            <p className="h6">{name}</p>
          
            <img
              src="/images/post/profile-approved.svg"
              alt="profile approved"
            />
          </div>
          {assignedIds?.length > 0 ? assignedIds?.includes(friend_id) ? <p className='text-[#909090] text-center	text-[9px]'>Assigned</p> : '' : ''}
          <p className="friend-data__nickname text-r">@{nickname}</p>
          {/* <p className="friend-data__position text-s">{position}</p> */}
        </div>
      </div>

      {children}
    </div>
  );
};

export default FriendCard;
