import {atom} from 'jotai';

const emailErrorAtom = atom(null);
const registerPhoneErrorAtom = atom(null);
const registerEmailErrorAtom = atom(null);
const verifyEmailOtpErrorAtom = atom(null);
const verifyPhoneOtpErrorAtom = atom(null);
const languageAtom = atom('en');
const disturbAtom = atom({
  state: false,
  dndText: '',
});
const emailLoadAtom = atom(false);
const snoozeAtom = atom({
  state: false,
  snoozeText: '',
});
const registerPhoneLoadAtom = atom(false);
const registerEmailLoadAtom = atom(false);
const verifyPhoneOtpLoadAtom = atom(false);
const verifyEmailOtpLoadAtom = atom(false);
const verifyEmailOtpSuccessAtom = atom(false);
const verifyPhoneOtpSuccessAtom = atom(false);

export {
  snoozeAtom,
  disturbAtom,
  languageAtom,
  emailLoadAtom,
  emailErrorAtom,
  registerPhoneLoadAtom,
  registerEmailLoadAtom,
  verifyEmailOtpLoadAtom,
  verifyPhoneOtpLoadAtom,
  registerPhoneErrorAtom,
  registerEmailErrorAtom,
  verifyEmailOtpErrorAtom,
  verifyPhoneOtpErrorAtom,
  verifyEmailOtpSuccessAtom,
  verifyPhoneOtpSuccessAtom,
};
