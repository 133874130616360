import React, {useEffect, useState} from 'react';
// import {Link} from 'react-router-dom';
import {Link} from '@react-navigation/native';

import PrimaryButton from '../../../components/reusable/form/items/buttons/PrimaryButton.jsx';
import BaseLayout from '../../../components/layouts/baseLayout/BaseLayout';

import SearchInput from '../../../components/reusable/form/items/inputs/SearchInput.jsx';
import ChoseSocial from '../items/ChoseSocial.jsx';
import PeopleChoseSocial from '../../../container/Auth/items/PeopleUsersList.jsx';
import {useNavigation} from '@react-navigation/core';
import Button from '../../../components/reusable/form/items/buttons/Button.jsx';

const peoplesData = {
  recommendation: [
    {
      name: 'Pritam',
      avatar: '/images/creators/1.jpg',
      description: 'Some short description text',
    },
  ],
  suggested: [
    {
      name: 'Taylor Swift',
      avatar: '/images/creators/2.jpg',
      description: 'Some short description text',
    },
    {
      name: 'The Weekend',
      avatar: '/images/creators/3.jpg',
      description: 'Some short description text',
    },
    {
      name: 'BTS',
      avatar: '/images/creators/4.jpg',
      description: 'Some short description text',
    },
    {
      name: ' Ed Sheeran',
      avatar: '/images/creators/5.jpg',
      description: 'Some short description text',
    },
    {
      name: 'A.R. Rahman',
      avatar: '/images/creators/6.jpg',
      description: 'Some short description text',
    },
    {
      name: 'Drake',
      avatar: '/images/creators/7.jpg',
      description: 'Some short description text',
    },
    {
      name: 'Doja Cat',
      avatar: '/images/creators/8.jpg',
      description: 'Some short description text',
    },
  ],
};
const AddPeople = () => {
  const {navigate} = useNavigation();
  //   const [peoples, setPeoples] = useState({});

  //   useEffect(() => {
  //     setPeoples(peoplesData);
  //   }, []);
  const handleContinue = () => {
    navigate('ChooseInterest');
  };

  return (
    <BaseLayout>
      <div className="profile__wrapper mt-sm-7">
        <div className="row align-items-center mb-md-6  mb-2 gx-0">
          <div className="d-flex align-items-center justify-content-center ">
            <div className="d-none d-sm-block ">
              <img
                src="/images/assets/rocket.png"
                alt="rocket"
                className="img-fluid"
              />
            </div>
            <div className="h4 ms-2 mb-2">Invite the people you know</div>
          </div>
          <div className="mb-5">
            <div>
              <SearchInput />
            </div>
            <ChoseSocial />
            <div className="profile__content">
              <PeopleChoseSocial users={peoplesData} />
            </div>
            <div className="d-none d-sm-block">
              <Button
                onClick={handleContinue}
                className="d-block my-sm-8 w-100">
                <PrimaryButton>Next</PrimaryButton>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default AddPeople;
