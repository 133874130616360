// noinspection ES6CheckImport

import enJson from '../string_key/en.json';
import { Alert } from 'react-native';
import { removeData } from '../res/asyncStorageHelper';
import TokenManager from '../libs/TokenManager.ts';
// import { SendbirdCalls } from "@sendbird/calls-react-native";
// import realm from '../realmStore';
export const getLanguageValueFromKey = key => {
  let index = enJson.findIndex(l => l.key === key);
  if (index > -1) {
    return enJson[index].value;
  } else {
    return key;
  }
};

export const Logout = (props, logout, actionClearData) => {
  Alert.alert(
    'Buzzmi',
    'Are you sure want to logout?',
    [
      {
        text: 'No',
        style: 'cancel',
      },
      {
        text: 'Yes',
        onPress: () => {
          if (actionClearData) {
            actionClearData();
          }
          removeData('userDetails');
          removeData('company_id');
          logout();
          setTimeout(() => {
            props.navigation.replace('Auth');
          }, 150);
        },
      },
    ],
    {cancelable: false},
  );
};

export const getrelamContactName = number => {
  if (number === 'Group' || number === 'Channel') {
    return number === 'Group' ? 'Group' : 'Channel';
  }

  // const _contacts = JSON.parse(
  //   JSON.stringify(
  //     realm.objects('Contact').filtered('phoneNumber == $0', number),
  //   ),
  // );

  // return _contacts?.length > 0 ? _contacts[0].value : number;
  return `${number}`;
};

export const unregisterToken = async () => {
  const token = await TokenManager.get();
  console.log(token);
  if (token) {
    // switch (token.type) {
    //   case 'apns':
    //   case 'fcm': {
    //     await SendbirdCalls.unregisterPushToken(token.value);
    //     break;
    //   }
    //   case 'voip': {
    //     await SendbirdCalls.ios_unregisterVoIPPushToken(token.value);
    //     break;
    //   }
    // }
    await TokenManager.set(null);
  }
};
