import React, {useState, useEffect} from 'react';
import BackBtnWithTitlePage from '../../../../components/reusable/btns/backBtn/BackBtnWithTitlePage.jsx';
import InfoCard from '../../../../components/reusable/cards/infoCard/InfoCard.jsx';
import Calendar from '../../../../components/reusable/calendar/Calendar.jsx';
import ProgressHeader from './header/ProgressHeader.jsx';
import PageNavigation from '../../../../components/reusable/pageNavigation/PageNavigation.jsx';
import {pageNav} from '../../../../mock-data/hub/hub.js';
import HubTaskList from '../Items/form/checkList/items/HubTaskList.jsx';
import PictureUploaderList from '../../../../components/reusable/uploader/pictureUploader/PictureUploaderList.jsx';
import CustomizableCircleChartWrapper from '../../../../components/reusable/assets/charts/customizableCircleChart/CustomizableCircleChartWrapper.jsx';
import Box from '../../../../components/reusable/cards/box/Box.jsx';
import HubBasicInfo from './items/HubBasicInfo.jsx';
import HubNote from './items/HubNote.jsx';
import {useFormValidator} from '../../../../form-validator/hooks/index.js';
import Form from '../../../../components/reusable/form/Form.jsx';
import ValidationSchema from '../../../../form-validator/ValidationSchema.js';
import HubMedia from '../Items/hubMedia/HubMedia.jsx';
import {useParams} from 'react-router-dom';
import MultiBox from '../../../../components/reusable/cards/multiBox/MultiBox.jsx';
import {mocData} from './mocData.js';
import MainLayout from '../../../../components/layouts/mainLayout/MainLayout.jsx';
import Description from '../../../../components/reusable/assets/description/Description.jsx';
import {useDeletePlannedActivity} from '../../../../appState/hooks/useDeletePlannedActivity';
import {useBuzzmiAuth} from '../../../../appState/hooks/useBuzzmiAuth/index.web.js';
import {EventRegister} from 'react-native-event-listeners';
import Loading from 'react-fullscreen-loading';
import {colors} from '../../../../res/colors.js';
import {useNavigation} from '@react-navigation/core';
import {useToast} from 'react-native-toast-notifications';
import {ShowSuccess} from '../../../HOC/MessageWeb.js';
import {useEventEmitter} from '../../../../hooks/useEventEmitter.js';
import {useUpdatePlanActivity} from '../../../../appState/hooks/useUpdatePlanActivity';

const validationSchema = new ValidationSchema({});

const getCurrentType = (list, id) => list?.filter(el => el.id === id)[0]?.text;
const HubTaskInfo = props => {
  // let { id } = useParams()
  const {navigate} = useNavigation();
  const data = mocData[0];
  const {deletePlanned} = useDeletePlannedActivity();
  const {postUpdatePlanActivity} = useUpdatePlanActivity();
  const [calendarValue, setCalendarValue] = useState(
    data?.dayList[0]?.date || null,
  );
  const {userData} = useBuzzmiAuth();
  const [viewTypeId, setViewTypeId] = useState(0);
  const [currentType, setCurrentType] = useState(getCurrentType(pageNav, 0));
  const [error, setError] = useState(null);
  const [deleteLoading, setdeleteLoading] = useState(false);
  const toast = useToast();
  const {item} = props.route.params;

  const sendRequest = async formData => {
    try {
      //TODO: send request
      console.log('formData', formData, item);
      // postUpdatePlanActivity(item.id, {
      //   goal: `${formData.progressInfo.currentValue / 60000} mins`,
      // });
    } catch (e) {
      setError(e);
    }
  };

  const {handleSubmit, setValue, values, setValues} = useFormValidator(
    validationSchema,
    sendRequest,
    {
      defaultValues: {
        images: item?.progressImages?.images,
        progressInfo: {
          ...data.progressInfo, // TODO: need to remove dummy data after adding all types...
          ...(item?.goal?.includes('mins') && {
            currentValue: 0,
            initValue: Number(item?.goal.split(' ')[0]) * 60000,
            type: 'time',
          }),
          ...(item?.goal?.includes('km') && {
            currentValue: 0,
            initValue: Number(item?.goal.split(' ')[0]),
            type: 'distanceMetric',
          }),
        },
        tasks: item?.checkList?.list,
      },
    },
  );

  const handleDeletePlannedActivity = id => {
    setdeleteLoading(true);
    deletePlanned(
      id,
      userData.token,

      deleteResponse => {
        console.log('deleteResponseHeres', deleteResponse);
        setdeleteLoading(false);
        ShowSuccess('Activity deleted successfully', toast);
        EventRegister.emit('refresh-planActivities');
        navigate('HubTasks');
      },
    );
  };

  const onPageNavChange = id => {
    setViewTypeId(id);
    if (getCurrentType) {
      setCurrentType(getCurrentType(pageNav, id));
    }
  };

  return (
    <MainLayout>
      <Loading
        loading={deleteLoading}
        background="transparent"
        loaderColor={colors.PRIMARY_COLOR}
      />
      <div className="hub">
        <div className="hub__content">
          <BackBtnWithTitlePage
            title={item?.title || 'Treadmill'}
            btnText="Back to stats"
            // lastItemLink={{href: `/hub`, title: 'Edit Activity'}}
            lastItemLink={{href: `/hub`, title: ''}}
          />
          <Form error={error} onSubmit={handleSubmit}>
            <div className="mb-4">
              {/*<InfoCard item={data.infoCardData} /> */}
              <Description item={item} />
            </div>

            <HubMedia
              galleryList={item?.descriptionMedia?.values?.[0] || []}
              videoSrcLink={item?.descriptionVideo}
              // audioSrcLink={item?.descriptionAudio}
              audioSrcLink={
                'https://actions.google.com/sounds/v1/alarms/digital_watch_alarm_long.ogg'
              }
            />

            {/*TODO: Need to pass this data while creating the planned-activty first... */}
            {item?.goal && (
              <Box className=" hub-form-box mb-4">
                <CustomizableCircleChartWrapper
                  values={values?.progressInfo}
                  setValues={value => setValue('progressInfo', value)}
                />
              </Box>
            )}

            {values?.images?.length > 0 && (
              <div className="mb-4">
                <MultiBox
                  icon="/images/hub/form/photo.png"
                  title="Add photos of your progress">
                  <PictureUploaderList
                    defaultValues={values?.images}
                    setValue={setValue}
                    classNames="picture-uploader__wrapper_mod"
                    locked={false}
                  />
                </MultiBox>
              </div>
            )}

            <div className="mb-4">
              <MultiBox
                icon="/images/hub/form/check-list.png"
                title="Checklist">
                <HubTaskList
                  // showAddTask={false}
                  value={values.tasks}
                  setValues={value => setValue('tasks', value)}
                  // locked={true}
                />
              </MultiBox>
            </div>
            <ProgressHeader text="2 completed" />
            <div className="mb-4">
              <PageNavigation
                list={data.pageNav}
                value={viewTypeId}
                onChange={onPageNavChange}
              />
            </div>
            <div className="box box_big mb-4">
              <Calendar
                value={calendarValue}
                onChange={setCalendarValue}
                dayList={data.dayList}
                period={
                  currentType === data.viewTypeList.doneLastMonth ||
                  currentType === data.viewTypeList.donePastMonth
                    ? 'month'
                    : 'week'
                }
                noIndent
              />
            </div>
            <HubBasicInfo
              reminder={item?.remindingTime}
              repeat={
                item?.repeatIntervalDay?.days
                  ? `Repeat every ${item?.repeatInterval?.days} day`
                  : null
              }
              when={item?.when}
            />
            {item?.note?.trim()?.length > 0 && <HubNote value={item.note} />}

            {item?.createdBy == userData.id && (
              <div className="d-flex justify-content-center mt-5 mt-md-7">
                <button
                  onClick={() => handleDeletePlannedActivity(item.id)}
                  className="button button_secondary button_m double-secondary px-6 px-lg-14">
                  Delete
                </button>
              </div>
            )}
          </Form>
        </div>
      </div>
    </MainLayout>
  );
};

export default HubTaskInfo;
