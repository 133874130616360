import React, {useState} from 'react';
import EditProfileBoxForm from '../EditProfileBoxForm';
import InputKits from '../../../../../../components/reusable/form/items/inputKits/InputKits';
import AddComponent from '../../../../../../components/reusable/assets/addComponent/AddComponent';
import RadioBtn from '../../../../../../components/reusable/form/items/radioBtn/RadioBtn';

const kit = [
  {
    label: 'Instagram',
    value: 'instagram',
  },
  {
    label: 'Facebook',
    value: 'facebook',
  },
  {
    label: 'Phone number',
    value: 'phone',
  },
  {
    label: 'E-mail',
    value: 'email',
  },
  {
    label: 'Website',
    value: 'website',
  },
  {
    label: 'LinkedIn',
    value: 'linkedIn',
  },
  {
    label: 'URL',
    value: 'url',
  },
];

const EditProfileBusinessInformation = ({register}) => {
  const [variantsInput, setVariantsInput] = useState([...kit]);
  const [countOfInput, setCountOfInput] = useState(1);

  // Блин и че с этим делать???
  const handleSelect = (selected, oldSelected) => {
    if (!!oldSelected) setVariantsInput([...variantsInput, oldSelected]);
    setVariantsInput(prev =>
      prev.filter(item => item.value !== selected.value),
    );
    // console.log("oldSelected", selected, variantsInput)
  };

  return (
    <EditProfileBoxForm
      title="Public Business Information"
      className="profile-settings">
      <p className="profile-form__addition-info">
        Add Instagram, email, URL or other contacts. You can hide this
        information from users or make it public.
      </p>
      {new Array(countOfInput).fill(0).map((_, index) => (
        <InputKits
          key={index}
          kit={variantsInput}
          register={register}
          callback={handleSelect}
        />
      ))}
      <div className="text-right">
        {countOfInput < kit.length && (
          <AddComponent callback={() => setCountOfInput(1 + countOfInput)} />
        )}
      </div>
      <div className="mt-4">
        <RadioBtn name="show-info" value="Hide" labelText="Hide" />
      </div>
      <div className="mt-3">
        <RadioBtn name="show-info" value="Unhide" labelText="Unhide" checked />
      </div>
    </EditProfileBoxForm>
  );
};

export default EditProfileBusinessInformation;
