import { useAtom } from 'jotai';
import { userTaskList } from '../hubState';
import { getMyTask } from '../../../network/api/app';

export const useUserTasks = () => {
  const [taskList, setTaskList] = useAtom(userTaskList);

  const getMyTaskList = async (token, setLoading) => {
    setLoading(true);
    try {
      const response = await getMyTask({ token });

      console.log("daily task response")
      console.log(response)

      setTaskList(response);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log('error => ', e.message);
    }
  };

  return {
    taskList,
    getMyTaskList,
  };
};
