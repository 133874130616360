import React from 'react';
import PropsTypes from 'prop-types';
import numberFormat from '../../../../../../components/reusable/numberFormat';
import HeartIcon from '../../../../../../components/reusable/icons/HeartIcon';

const ProfilePremiumContentTop = ({
  img,
  reviewsQuantity,
  averageRating,
  sold,
  likes,
}) => (
  <div className="profile-content__top relative">
    <img className="profile-content__main-image" src={img.src} alt={img.alt} />
    <div className="profile-content__top-bottom">
      <div>
        <div className="profile-content__top-item">
          <img src="/images/assets/star-s.svg" alt="star" />
          <span className="profile-content__top-bold">{averageRating}</span>
          <span className="text-s">({reviewsQuantity})</span>
        </div>
        <div className="profile-content__top-item">
          <img src="/images/assets/basket.svg" alt="basket" />{' '}
          <span className="profile-content__top-bold">{sold}</span>
          <span className="text-s">sold</span>
        </div>
      </div>
      <div className="profile-content__top-likes">
        <HeartIcon />
        <span>{numberFormat(likes)}</span>
      </div>
    </div>
  </div>
);

ProfilePremiumContentTop.propTypes = {
  img: PropsTypes.shape({
    src: PropsTypes.string,
    alt: PropsTypes.string,
  }),
  rating: PropsTypes.shape({
    value: PropsTypes.number,
    count: PropsTypes.number,
  }),
  sold: PropsTypes.number,
  likes: PropsTypes.number,
};

export default ProfilePremiumContentTop;
