import React from 'react';
import AdditionalInformationPlan from './items/additionalInformationPlan/AdditionalInformationPlan.jsx';
import HubWallet from '../../../../container/App/Home/items/hubWallet/HubWallet.jsx';
import PrimaryLink from '../../btns/links/PrimaryLink.jsx';
import Strings from '../../../../string_key/Strings.web.js';
import { useBuzzmiAuth } from '../../../../appState/hooks/useBuzzmiAuth/index.web.js';

const AdditionalInformation = ({ onPresetBtn, onMyTaskBtn, isFromWallet = false }) => {
  const { language } = useBuzzmiAuth();

  return (
    <div>
      <AdditionalInformationPlan />
      <HubWallet isFromWallet={isFromWallet}/>
      {onMyTaskBtn && (
        <div className="additional-information__btn mt-3">
          <PrimaryLink
            style={{ textAlign: 'center' }}
            className="w-100 button_m double-secondary"
            to="/hub/add-activity">
            {Strings.add_my_own_task}
          </PrimaryLink>
        </div>
      )}
    </div>
  );
};

export default AdditionalInformation;
