// noinspection JSUnresolvedReference

import React, {useState, useEffect} from 'react';
import {Keyboard} from 'react-native';
import DoubleLayout from '../../../components/layouts/doubleLayout/DoubleLayout.jsx';
import QuadServicesButtons from './items/serviceButtons/QuadServicesButtons';
import {useEncryptedAtom} from '../../../appState/hooks/useEncryptedAtom';
import Logo from '../../../components/reusable/assets/logo/Logo.jsx';
import {getLanguageValueFromKey} from '../../../commonAction';
import {Link, useNavigation} from '@react-navigation/native';
import {_userDetailsAtom} from '../../../appState/userState';
import {useToast} from 'react-native-toast-notifications';
import LoginForms from './items/loginForms/LoginForms';
import {useBuzzmiAuth} from '../../../appState/hooks';
import Strings from '../../../string_key/Strings.web';
import {yupResolver} from '@hookform/resolvers/yup';
import {ShowError} from '../../HOC/MessageWeb';
import Loading from 'react-fullscreen-loading';
import {colors} from '../../../res/colors.js';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';

const Login = props => {
  const {navigate} = useNavigation();

  const schema = yup
    .object({
      email: yup.string().required('Please enter your email or username.'),
      password: yup.string().required('Please enter your password'),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(schema),
  });
  const toast = useToast();

  const [userDetails, setUserDetails] = useEncryptedAtom(_userDetailsAtom);
  const [isLoad, setIsLoad] = useState(false);

  const {login, loginError} = useBuzzmiAuth();

  useEffect(() => {
    if (userDetails) {
      // console.log('User data changed!!!', userDetails);
      setTimeout(
        () => window.location.reload(),
        props.navigation.replace('Tab'),
        150,
      );
    }
  }, [userDetails]);

  useEffect(() => {
    if (loginError != null) {
      Keyboard.dismiss();
      ShowError(loginError, toast);
    }
  }, [loginError, toast]);

  const onSubmit = data => {
    let re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (data?.identifier === '') {
      ShowError(getLanguageValueFromKey('please_enter_email_or_user'), toast);
    }
    // else if (!re.test(data?.identifier)) {
    // ShowError(getLanguageValueFromKey('please_enter_valid_email'), toast)
    //}
    else if (data?.password === '') {
      ShowError(getLanguageValueFromKey('please_enter_password'), toast);
    } else {
      login(data?.identifier, data?.password);
    }
  };

  return (
    <DoubleLayout imageUrl={'./images/login/intro.png'}>
      <Loading
        loading={isLoad}
        background="transparent"
        loaderColor={colors.PRIMARY_COLOR}
      />
      <div className="form-wrapper w-100">
        <div className="form form_bg">
          <Logo subtitle={'Welcome to'} />
          <LoginForms onSubmit={onSubmit} props={props} />
          <div className="login__assets">{Strings.or}</div>
          <QuadServicesButtons props={props} />
          <div className="text-center text-r">
            <span className={'text-secondary'}>
              {Strings.dont_have_an_account}
            </span>
            &nbsp;
            <Link to={{screen: 'Registration'}}>
              <text className={'text-primary'}>{Strings.sign_up}</text>
            </Link>
          </div>
        </div>
      </div>
    </DoubleLayout>
  );
};

export default Login;
