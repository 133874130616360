import {useEffect, useState, forwardRef, useImperativeHandle} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Button from '../../../../../../components/reusable/btns/buttons/Button.jsx';
import CheckTag from './CheckTag.jsx';
import {openModal} from '../../../../../../store/slices/modalSlice/modalSlice.js';
import TagsModal from '../../../../../../components/modal/TagsModal/index.js';
import addImg from '../../../../../../assets/add_icon.png';
const CheckTags = forwardRef((props, ref) => {
  const {value, setValue, valueName, viewOnly = false} = props;
  const dispatch = useDispatch();
  // const {props, name} = useSelector(state => state.modal.parentComponent);
  const [open, setOpen] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      handleAdd: () => setOpen(true),
    }),
    [],
  );

  const handleAddNew = () => {
    setOpen(true);
    // dispatch(
    //   openModal({
    //     modalName: 'AddTags',
    //     modalType: 'swipe',
    //     modalProps: {value},
    //     callback: SelectedTags => {
    //       setValue('tags', value?.concat(SelectedTags));
    //     },
    //   }),
    // );
  };
  const setTags = SelectedTags => {
    // console.log('SelectedTags', SelectedTags);

    setValue('tags', SelectedTags);
  };
  const handleRemove = tag => {
    setValue(
      valueName,
      value.filter(t => t.id !== tag.id),
    );
  };

  // useEffect(() => {
  //   if (name === 'CheckTags' && !!props) {
  //     if (props?.tags) {
  //       // setValue('tags', [value, ...props?.tags]);
  //       setValue('tags', props?.tags?.concat(value));
  //     }
  //   }
  // }, [name, props]);

  return (
    <>
      {TagsModal(open, setOpen, value, setTags)}
      {/* {!!setValue && !hideBtn && (
        <button onClick={handleAddNew} type="button ">
          <img
            src={addImg}
            className="h-8 w-8 sm:w-10 sm:h-10 md:w-10 md:h-10 lg:w-12 lg:h-12 xl:w-12 xl:h-12 mr-2"
            onClick={() => {}}
          />
        </button>
      )} */}
      <div className="check-tags">
        {value?.length > 0 &&
          value.map(tag => (
            <CheckTag
              handleRemove={handleRemove}
              value={value}
              key={tag.value}
              tag={tag}
              isChanged={!viewOnly && !!setValue}
            />
          ))}
      </div>
    </>
  );
});

export default CheckTags;
