import React, {useState} from 'react';
import GemsIcon from '../../../icons/GemsIcon';
import {Link} from '@react-navigation/native';
import GemsModal from '../../../../ProfileMain/componentsV2/GemsModal';
// import { Link } from "react-router-dom"

const GemsPostBtn = ({gems, postId}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [openGemsModal, setOpenGemsModal] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      {/* <Link to={`/profile/post/${postId}/gems`}> */}
      <div
        onClick={e => {
          e.preventDefault();
          setOpenGemsModal(() => true);
        }}
        className="post-buttons__item d-flex align-items-center"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <GemsIcon isHovered={isHovered} />
        <p className={`post-buttons__item-text ${isHovered ? 'hovered' : ''}`}>
          {gems}
        </p>
      </div>
      {/* </Link> */}
      <GemsModal
        open={openGemsModal}
        setOpen={setOpenGemsModal}
        data={{entity: 'post', id: postId}}
      />
    </>
  );
};

export default GemsPostBtn;
