import React, {useRef, useState, useEffect} from 'react';
import {AnothersPostMenuData, MyPostMenuData} from './PostMenuData';
import PostMenuItem from './PostMenuItem';
import useOnClickOutside from '../../../../../hooks/useOnClickOutside';
import EllipsisIcon from '../icons/EllipsisIcon';
import PropTypes from 'prop-types';
import {Fragment} from 'react';
import {Popover, Transition} from '@headlessui/react';
import {useEventEmitter} from '../../../../../hooks/useEventEmitter';
import UnfollowIcon from '../icons/UnfollowIcon';
import SaveIcon from '../icons/SaveIcon';

const EllipsisMenuV2 = ({type, isMyPost, Id, post}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const menuPostRef = useRef(null);

  const handleMenu = event => {
    event.stopPropagation();
    setShowMenu(!showMenu);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useOnClickOutside(menuPostRef, showMenu, setShowMenu);

  useEventEmitter('refresh-bookmark-list', data => {});

  const getAltName = item => {
    if (item.text == 'Save') {
      return post?.isBookmarked == true ? 'saved' : item.alt;
    } else if (item.text == 'Follow') {
      return post?.followed == true ? 'unfollowed' : 'followed';
    } else {
      return item.alt;
    }
  };

  const getTextName = item => {
    if (item.text == 'Save') {
      return post?.isBookmarked == true ? 'Saved' : item.text;
    } else if (item.text == 'Follow') {
      return post?.followed == true ? 'Unfollow' : item.text;
    } else {
      return item.text;
    }
  };

  const getIcon = item => {
    if (item.text == 'Save') {
      return post?.isBookmarked == true ? (
        <SaveIcon isBooked={true} isHovered={false} />
      ) : (
        item.icon
      );
    } else if (item.text == 'Follow') {
      return post?.followed == true ? (
        <UnfollowIcon isHovered={false} />
      ) : (
        item.icon
      );
    } else {
      return item.icon;
    }
  };

  return (
    <>
      <Popover className="relative">
        <Popover.Button className="inline-flex  gap-x-1 text-sm font-semibold leading-6 text-gray-900">
          {type === 'reel' ? (
            <button
              className="reels__buttons-list-button"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}>
              <EllipsisIcon isHovered={isHovered} />
            </button>
          ) : (
            <button className="ellipsis-menu__dots">
              <img
                src="/images/assets/info-card/dots_menu.svg"
                alt="dots menu"
              />
            </button>
          )}
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1">
          <Popover.Panel className="absolute right-0 z-10 mt-2 flex w-screen max-w-min  px-4">
            {({close}) => (
              <div className="w-56 shrink rounded-xl bg-white p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5 mb-16">
                {isMyPost
                  ? MyPostMenuData.map((item, ind) => (
                      <div
                        className={
                          ind !== MyPostMenuData.length - 1 ? 'mb-2' : ''
                        }>
                        <PostMenuItem
                          key={item.id}
                          text={item.text}
                          icon={item.icon}
                          alt={item.alt}
                          id={Id}
                          type={type}
                          item={post}
                          onClose={() => {
                            close();
                          }}
                        />
                      </div>
                    ))
                  : AnothersPostMenuData.map(item => (
                      <div className={'mb-2'}>
                        <PostMenuItem
                          key={item.id}
                          text={getTextName(item)}
                          icon={getIcon(item)}
                          alt={getAltName(item)}
                          id={Id}
                          item={post}
                          onClose={() => {
                            close();
                          }}
                        />
                      </div>
                    ))}
              </div>
            )}
          </Popover.Panel>
        </Transition>
      </Popover>
    </>
  );
};

EllipsisMenuV2.propTypes = {
  type: PropTypes.string,
  isMyPost: PropTypes.bool,
};

export default EllipsisMenuV2;
