function formatDate(date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}
export function getCurrentWeekDates() {
  const today = new Date();
  const currentDay = today.getDay(); // 0 (Sunday) to 6 (Saturday)

  // Calculate the difference between the current day and Monday
  const daysSinceMonday = currentDay === 0 ? 6 : currentDay - 1;

  // Calculate the start date of the current week (Monday)
  const startDate = new Date(today);
  startDate.setDate(today.getDate() - daysSinceMonday);

  // Calculate the end date of the current week (Sunday)
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 6);

  // Format dates as YYYY-MM-DD
  const formattedStartDate = startDate.toISOString().split('T')[0];
  const formattedEndDate = endDate.toISOString().split('T')[0];

  return {startDate: formattedStartDate, endDate: formattedEndDate};
}
export function getLastWeekDates() {
  const today = new Date();
  const currentDay = today.getDay(); // 0 (Sunday) to 6 (Saturday)

  // Calculate the difference between the current day and Monday
  const daysSinceMonday = currentDay === 0 ? 6 : currentDay - 1;

  // Calculate the start date of the last week (Monday)
  const lastMonday = new Date(today);
  lastMonday.setDate(today.getDate() - daysSinceMonday - 7);

  // Calculate the end date of the last week (Sunday)
  const lastSunday = new Date(lastMonday);
  lastSunday.setDate(lastMonday.getDate() + 6);

  // Format dates as YYYY-MM-DD
  const formattedLastMonday = lastMonday.toISOString().split('T')[0];
  const formattedLastSunday = lastSunday.toISOString().split('T')[0];

  return {startDate: formattedLastMonday, endDate: formattedLastSunday};
}

export function getLastMonthDates() {
  const today = new Date();
  const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);

  // Calculate the last day of the last month
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 0);

  // Format dates as YYYY-MM-DD
  const formattedFirstDay = formatDate(lastMonth);
  const formattedLastDay = formatDate(lastDayOfMonth);

  return {startDate: formattedFirstDay, endDate: formattedLastDay};
}
export function getCurrentMonthDates() {
  const today = new Date();
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  // Calculate the last day of the current month
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  // Format dates as YYYY-MM-DD
  const formattedFirstDay = formatDate(firstDayOfMonth);
  const formattedLastDay = formatDate(lastDayOfMonth);

  return {startDate: formattedFirstDay, endDate: formattedLastDay};
}
export function getNextMonthDates() {
  const today = new Date();

  // Calculate the first day of the next month
  const firstDayOfNextMonth = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    1,
  );

  // Calculate the last day of the next month
  const lastDayOfNextMonth = new Date(
    today.getFullYear(),
    today.getMonth() + 2,
    0,
  );

  // Format dates as YYYY-MM-DD
  const formattedFirstDay = formatDate(firstDayOfNextMonth);
  const formattedLastDay = formatDate(lastDayOfNextMonth);

  return {startDate: formattedFirstDay, endDate: formattedLastDay};
}
