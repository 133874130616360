import React, { useEffect } from 'react';
import FriendsList from '../../../../container/App/Profile/items/profilePost/items/friends/FriendsList';
import ShareModalBottomMenu from './ShareModalBottomMenu';
import Button from '../../../../components/reusable/btns/buttons/Button';

const ShareModal = ({
  userName,
  userAvatar,
  userShareDetails,
  forV2 = false,
}) => {
  return (
    <div className="share-modal">
      {userShareDetails?.thumbnail && (
        <div className="share-modal__object-to-share d-flex flex-row align-itmes-center">
          <img
            src={userAvatar}
            alt="author avatar"
            className="share-modal__author-avatar"
          />

          <div className="d-flex flex-column share-modal__info">
            <p className="h6">Post</p>
            <p className="text-r">by {userName}</p>
          </div>
        </div>
      )}

      {userShareDetails?.QR && (
        <div
          style={{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center'
          }}>
          <img
            src={userShareDetails?.QR}
            alt="Red dot"
            style={{ width: '100%', maxWidth: '340px' }}
          />
        </div>
      )}

      {userShareDetails?.thumbnail && (
        <div
          className="min-h-[500px]"
          style={{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}>
          <img
            className={"object-cover"}
            src={userShareDetails?.thumbnail}
            alt="Red dot"
            style={{ width: '100%', maxWidth: '340px', margin: 9, }}
          />
        </div>
      )}
      {/* <FriendsList
        actionComponent={
          <Button
            className="button_secondary button_thin btn-text"
            onClick={() => {}}>
            Send
          </Button>
        }
      /> */}
      <ShareModalBottomMenu userShareDetails={userShareDetails} />
    </div>
  );
};

export default ShareModal;
