import ProfileInformationDesktop from './tabs/ProfileInformationDesktop';
import ProfileAboutDesktop from './tabs/ProfileAboutDesktop';
import ProfileContentTabsV2 from '../profileContentTabs/ProfileContentTabsV2';
import React from 'react';
import ProfileUsersBody from '../../profileMobileUsers/ProfileUsersBody';

const OtherProfileDesktopTabsChildrensV2 = ({activeTabId}) => {
  const contentMap = {
    Post: <ProfileContentTabsV2 isOtherProfile />,
    About: <ProfileAboutDesktop isOtherProfile />,
    Information: <ProfileInformationDesktop isOtherProfile />,
    Friends: <ProfileUsersBody isOtherProfile />,
  };

  return !activeTabId || !contentMap[activeTabId]
    ? null
    : contentMap[activeTabId];
};

export default OtherProfileDesktopTabsChildrensV2;
