import { StyleSheet, Dimensions } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { colors } from '../../../../res/colors';

const ScreenWidth = Dimensions.get('window').width;


export const styles = StyleSheet.create({
    input_web: {
        width: hp(60),
        height: hp(20),
        padding: hp(2),
        color: colors.WHITE,
        borderRadius: wp(2),
        borderWidth: hp(0.2),
        borderColor: colors.WHITE
    },
    input: {
        width: wp(90),
        height: hp(20),
        borderRadius: wp(2)
    },
    backgroundVideo: {
        "object-fit": "cover",
        height: hp(100),
        width: ScreenWidth > 670 ? (ScreenWidth / 2) : ScreenWidth
    },
})