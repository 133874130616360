import React, { useEffect, useState } from 'react';
import ProfilePost from './profilePost/ProfilePost';
import ProfileReel from './profileReels/ProfileReel';
import useWindowSize from '../../../../hooks/useWindowSize';
import { useProfileApp } from '../../../../appState/hooks';
import BackBtnWithTitlePage from '../../../../components/reusable/btns/backBtn/BackBtnWithTitlePage';

const VisitorViewDesktop = props => {
  const { id, ispost } = props;
  const [currentPostId, setCurrentPostId] = useState(id);
  const [isPosts, setIsPost] = useState(ispost == "post" ? true : false);

  const { width } = useWindowSize();
  const { visitorPosts, visitorReels } = useProfileApp();

  return (
    <div className={'bg-[#faf7ff] flex-1'}>
      <BackBtnWithTitlePage
        isFixed
        btnText="Back"
        hideBottomBorder
      />
      <div className="max-w-3xl mx-auto">
        <ul
          className={
            isPosts ? 'profile-posts-list__column sm:pt-0 pt-24 z-[-1] bg-[#faf7ff] mb-10' : 'reels__list-column sm:pt-0 pt-24 z-[-1] bg-[#faf7ff] mb-10'
          }>
          {isPosts ? (
            visitorPosts && visitorPosts.length > 0 ? (
              visitorPosts.map(post => (
                <ProfilePost
                  key={post.id}
                  postData={post}
                  currentPostId={currentPostId}
                  type="post"
                  isMyPost={false}
                />
              ))
            ) : (
                <div style={{ textAlign: 'center', width: '100%' }}>
                  <h5 className="h5 mb-3 mb-lg-4" style={{ textAlign: 'center' }}>
                    {'No Post Found'}
                  </h5>
                </div>
              )
          ) : visitorReels && visitorReels.length > 0 ? (
            visitorReels.map((reel, index) => (
              <React.Fragment key={reel.id}>
                {width < 767 ? (
                  <ProfilePost
                    postData={reel}
                    currentPostId={currentPostId}
                    type="reel"
                    isMyPost={false}
                  />
                ) : (
                    <ProfileReel isMyPost={false} reel={reel} currentPostId={currentPostId} />
                  )}
              </React.Fragment>
            ))
          ) : (
                <div style={{ textAlign: 'center', width: '100%' }}>
                  <h5 className="h5 mb-3 mb-lg-4" style={{ textAlign: 'center' }}>
                    {'No Reel Found'}
                  </h5>
                </div>
              )}
        </ul>
      </div>
    </div>
  );
};

VisitorViewDesktop.propTypes = {
  isPosts: true,
};

export default VisitorViewDesktop;
