// noinspection ES6CheckImport

import {StyleSheet, Platform} from 'react-native';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import {fonts} from '../../../../res/fonts';
import {colors} from '../../../../res/colors';
import styled from 'styled-components/native';

const MainContainer = styled.View`
  flex: 1;
  justify-content: center;
  background-color: ${colors.WHITE};
`;
const HeaderShadowLine = styled.View`
  flex-direction: row;
  align-items: center;
  border-bottom-color: ${colors.LIGHT_GRAY};
  border-bottom-width: ${props => (props?.light ? 0.8 : 1.5)}px;
`;
const TitleText = styled.Text`
  text-align: center;
  color: ${colors.BLACK};
  font-family: ${fonts.BOLD};
  font-size: ${wp(4.5)};
`;
const RCBottomText = styled.Text`
  text-align: center;
  color: ${props => (props?.index == 0 ? colors.BLACK : colors.WHITE)};
  font-family: ${fonts.REGULAR};
  font-size: ${wp(2.7)};
`;
const RCInnerContainer = styled.View`
  width: 100%;
  height: 100%;
  align-items: center;
  border-radius: 12px;

  background-color: #efeef3;
`;
export {
  MainContainer,
  HeaderShadowLine,
  TitleText,
  RCInnerContainer,
  RCBottomText,
};
export default StyleSheet.create({
  icon_ic: {
    alignSelf: 'center',
    resizeMode: 'contain',
    width: wp(6),
    height: wp(6),
  },
  ic_image: {
    top: wp(2),
    right: wp(2),
    width: wp(5),
    height: wp(5),
    position: 'absolute',
    alignSelf: 'flex-end',
  },
  txt_regular: {
    color: colors.BLACK,
    fontFamily: fonts.REGULAR,
    fontSize: Platform.OS != 'web' ? wp(4) : hp(2.5),
  },
  wbWrapper: {
    width: '90%',
    alignSelf: 'center',
    borderRadius: wp(2),
    overflow: 'hidden',
    backgroundColor: colors.WHITE,
  },
  wbItem: {
    backgroundColor: colors.WHITE,
    width: wp(30),
    height: wp(24),
    justifyContent: 'center',
    alignItems: 'center',
  },
  wbSeperator: {
    height: '60%',
    width: 2,
    backgroundColor: colors.ATHENS_GRAY,
    alignSelf: 'center',
  },
  wbBalaceTxtWrapper: {
    flexDirection: 'row',
    alignSelf: 'center',
    alignItems: 'center',
  },
  wbImage: {height: wp(6), width: wp(6)},
  wbsubTitle: {
    textAlign: 'center',
    marginBottom: hp(1),
    fontFamily: fonts.MEDIUM,
  },
  earningBox: {
    width: '90%',
    alignSelf: 'center',
    marginTop: hp(1),
  },
  earningDataWrap: {
    width: '100%',
    alignSelf: 'center',
    borderRadius: wp(2),
    overflow: 'hidden',
    backgroundColor: colors.WHITE,
  },
  earningDataItem: {
    flexDirection: 'row',
    padding: wp(4),
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  erSeperator: {
    height: 2,
    width: '80%',
    backgroundColor: colors.ATHENS_GRAY,
    alignSelf: 'center',
  },
  RCContainerStyle: {
    borderRadius: 14,

    alignItems: 'center',
    paddingVertical: 1.8,
    paddingHorizontal: 1.8,
    justifyContent: 'center',
    width: wp('29%'),
    height: wp('30%'),
  },
  RCTopContainer: {
    width: '30%',
    height: '30%',
    borderBottomRightRadius: wp('10%'),
    borderBottomLeftRadius: wp('10%'),
    justifyContent: 'center',
    alignItems: 'center',
  },
  RCBottomContainer: {
    width: '60%',
    height: '22%',
    borderTopRightRadius: wp('4%'),
    borderTopLeftRadius: wp('4%'),
    justifyContent: 'center',
    alignItems: 'center',
  },
  RCCenterContainer: {
    flex: 1,
    // backgroundColor: 'red',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
  },
  RCWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
  },
  hexagon: {height: wp(4), width: wp(4)},
});
