import React from 'react';
import SimpleShowMore from '../../../../../../components/reusable/assets/showMore/simpleShowMore/SimpleShowMore';
import useWindowSize from '../../../../../../hooks/useWindowSize';

const ProfilePremiumContentAbout = ({description}) => {
  const {width} = useWindowSize();

  return (
    <div className="profile-content__about profile-content__block">
      <div className="profile-content__block-title">About program:</div>
      <SimpleShowMore
        content={description}
        lineClamp={width > 768 ? 4 : 3}
        sizes={{
          desktopHeight: 72,
          mobileHeight: 48,
        }}
        className="text-r"
        showMoreBtnContent="Read more"
      />
    </div>
  );
};

export default ProfilePremiumContentAbout;
