import React, {useState, useEffect} from 'react';
import BaseLayout from '../../../components/layouts/baseLayout/BaseLayout';
import {useNavigation} from '@react-navigation/native';
import Role from './items/Role.jsx';
import {getData, saveData} from '../../../res/asyncStorageHelper.web';
import {colors} from '../../../res/colors';
import Loading from 'react-fullscreen-loading';
import {GET_ROLES, GET_USERS, CATEGORY} from '../../../api_helper/Api';
import {GET, POST} from '../../../api_helper/ApiServices';
import Strings from '../../../string_key/Strings.web';
import {useEncryptedAtom} from '../../../appState/hooks/useEncryptedAtom';
import {_addRoleAtom} from '../../../appState/userState';
import {USER_CREATOR} from '../../../network/api/endpoints';
import MainLayoutV2 from '../../../components/layouts/mainLayout/MainLayoutV2.jsx';

const ChoseRole = () => {
  const {navigate} = useNavigation();
  const [isLoad, setIsLoad] = useState(false);
  const [roleData, setRoleData] = useState([]);
  const [creatersLength, setCreatersLength] = useState(0);
  const [interestsLength, setInterestsLength] = useState(0);
  const [userRoles, setAddRole] = useEncryptedAtom(_addRoleAtom);

  const onPress = id => {
    // saveData('add_role', [id]);
    setAddRole([id]);

    creatersLength >= 10
      ? navigate('AddCreators')
      : interestsLength >= 10
      ? navigate('ChooseInterest')
      : window.location.reload();
  };

  const onPostCreator = id => {
    getData('sessionToken', token => {
      let postData = {
        creator_id: id,
      };
      POST(USER_CREATOR, true, token, null, postData, (response, isError) => {
        console.log('response');
        console.log(response);
      });
    });
  };
  const getUserList = () => {
    setIsLoad(true);
    getData('sessionToken', token => {
      GET(GET_USERS + `?page=1&limit=20`, token, null)
        .then(res => {
          setIsLoad(false);
          console.log('res?.length', res?.data?.length);
          setCreatersLength(res?.data?.length);
        })
        .catch(e => {
          setIsLoad(false);
          console.log('INVITATION (error) => ', e.message);
        });
    });
  };
  const getInterestList = () => {
    setIsLoad(true);

    getData('sessionToken', token => {
      GET(CATEGORY + `?page=1&limit=20`, token, null)
        .then(res => {
          setIsLoad(false);
          console.log('res?.length', res?.data?.categories?.length);
          setInterestsLength(res?.data?.categories?.length);
        })
        .catch(e => {
          setIsLoad(false);
          console.log('INVITATION (error) => ', e.message);
        });
    });
  };

  useEffect(() => {
    getUserList();
    getInterestList();
  }, []);
  useEffect(() => {
    setIsLoad(true);
    getData('sessionToken', token => {
      GET(GET_ROLES, token, null)
        .then(res => {
          setIsLoad(false);
          setRoleData(res.data);
        })
        .catch(e => {
          setIsLoad(false), console.log('INVITATION (error) => ', e.message);
        });
    });
  }, []);

  return (
    //   <BaseLayout isHideBack>
    <MainLayoutV2>
      <div className="w-full max-w-[500px] md:max-w-[800px] px-4 py-20 mx-auto flex flex-col justify-center items-center text-center md:flex-column md:justify-start md:flex-0 sm:height-[calc(100vh-65px)] md:height-[calc(100vh-60px)] sm:px-4 md:px-0">
        <Loading
          loading={isLoad}
          background="transparent"
          loaderColor={colors.PRIMARY_COLOR}
        />
        <div className="h4">{Strings.choose_your_role}</div>
        <div className="text-r text-center role__intro">
          <div>{Strings.we_are_curios}</div>
          <div>{Strings.are_you}</div>
        </div>

        <ul className="role-list">
          {roleData.length > 0 && (
            <>
              {roleData.map((item, index) => (
                <li>
                  <Role
                    className="w-0"
                    img={`/images/role/${item.role.toLowerCase()}.png`}
                    title={item.role}
                    onPress={() => {
                      onPress(item?.id + '');
                      if (item.id == 1) {
                        onPostCreator(item.id);
                      }
                    }}
                  />
                </li>
              ))}
              <li>
                <Role
                  img="/images/role/both.png"
                  title="Both"
                  onPress={() => {
                    onPress('2,3');
                    onPostCreator('2,3');
                  }}
                />
              </li>
            </>
          )}
        </ul>
      </div>
    </MainLayoutV2>
  );
};

export default ChoseRole;
