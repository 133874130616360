import React from 'react';
import {Link, useNavigation} from '@react-navigation/native';
import {EllipsisVerticalIcon} from '@heroicons/react/24/outline';
import {useEventEmitter} from '../../../../hooks/useEventEmitter';
// import {useNavigation} from "@react-navigation/core";

const BackBtnWithTitlePage = ({
  title,
  btnText,
  lastItemLink,
  customCallbackRightButton,
  hideBottomBorder = false,
  isFixed = false,
  subtitle = undefined,
  isElipsicon = false,
  onElipseClick = undefined,
}) => {
  const navigation = useNavigation();

  const isShowRightButton =
    (lastItemLink && lastItemLink?.href && lastItemLink?.title) ||
    customCallbackRightButton;

  useEventEmitter('goBackTo-planActivities', () => navigation.goBack());
  return (
    <>
      <div
        className={
          hideBottomBorder
            ? `back-btn pb-3 mt-0 pt-2 z-10 ${isFixed ? 'fixed z-[2]' : ''}`
            : isFixed
            ? 'back-btn max-w-[1200px] back-btn_with-title border-b pb-3 mt-0 bg-[#FFF] pt-4 fixed z-[2]'
            : 'back-btn back-btn_with-title border-b pb-3 mt-0 pt-2'
        }>
        <button
          className="back-btn__content"
          onClick={() => navigation.goBack()}>
          <img
            className="ml-2 back-btn__icon"
            src="/images/assets/back.svg"
            alt="arrow left"
          />
          <span className="back-btn__text">{btnText}</span>
        </button>
        <div className={'text-center'}>
          {title && <span className="back-btn__title">{title}</span>}
          <div>
            {subtitle && (
              <span className="back-btn__title text-sm text-[#bcbec0]">
                {subtitle}
              </span>
            )}
          </div>
        </div>
        {isShowRightButton &&
          (customCallbackRightButton ? (
            <button
              className="back-btn__last-link text-alt-primary"
              onClick={() => {
                customCallbackRightButton();
              }}>
              {lastItemLink?.title}
            </button>
          ) : (
            <Link to={{screen: lastItemLink.href, params: lastItemLink.params}}>
              <div className="text-alt-primary back-btn__last-link">
                {lastItemLink.title}
              </div>
            </Link>
          ))}

        {isElipsicon && (
          <div className={'absolute right-1'} onClick={onElipseClick}>
            <EllipsisVerticalIcon className={'h-6 w-6 text-[#F13CB8]'} />
          </div>
        )}
      </div>
    </>
  );
};

export default BackBtnWithTitlePage;
