import React, { useEffect } from 'react';

const Checkbox = ({ setState, idChecked, defaultValue, is2fa = false, on2FAClick }) => {
  const toggle = e => {
    setState(e.target.checked);
  };

  return (
    <>
      {
        is2fa ?
          <input
            defaultChecked={defaultValue}
            onClick={on2FAClick}
            className="checkbox"
            type="checkbox"
            id={idChecked}
            checked={defaultValue}
          />
          :
          <input
            defaultChecked={defaultValue}
            onClick={toggle}
            className="checkbox"
            type="checkbox"
            id={idChecked}
          />
      }

      <label htmlFor={idChecked}>Toggle</label>
    </>
  );
};

export default Checkbox;
