import React, {useEffect, useRef} from 'react';
import PostContent from '../profilePost/items/postContent/PostContent';
import ReelAuthorInfo from './ReelAuthorInfo';
import LikesReelBtn from './reelBtns/LikesReelBtn';
import ShareReelBtn from './reelBtns/ShareReelBtn';
import EllipsisMenu from '../ellipsisMenu/EllipsisMenu';
import VideoReel from './VideoReel';

import PropTypes from 'prop-types';
import useWindowSize from '../../../../../hooks/useWindowSize';
import CommentsReelBtnV2 from './reelBtns/CommentsReelBtnV2';
import ShareReelBtnV2 from './reelBtns/ShareReelBtnV2';
import EllipsisMenuV2 from '../ellipsisMenu/EllipsisMenuV2';

const ProfileReelV2 = ({reel, currentPostId, isMyPost = false}) => {
  const {user, upvotes, summary, createdAt, comments} = reel?.object || {};
  const scrollRef = useRef([]);
  const {width} = useWindowSize();
  // useEffect(() => {
  //   if (reel?.id === currentPostId) {
  //     window.location.href = `#reel-${currentPostId ? currentPostId : 0}`;
  //   }
  // }, [reel]);
  console.log('reel==>', reel);

  useEffect(() => {
    if (reel?.id === currentPostId) {
      scrollRef.current[currentPostId].scrollIntoView();
    }
  }, [reel]);

  return (
    <li
      ref={ref => (scrollRef.current[reel?.id] = ref)}
      key={reel?.id}
      className="reels__item"
      id={`reel-${reel?.id}`}>
      <p className="reels__item-author-top">@{reel?.author?.nickname}</p>

      <VideoReel reel={reel} />

      <div className="reels__info">
        <ReelAuthorInfo
          userName={reel?.owner?.member?.name}
          userNickname={reel?.owner?.member.username}
          userPosition={''}
          userAvatar={reel?.owner?.member?.picture}
          isVerified={reel?.owner?.member.isVerified}
        />

        <PostContent
          city={''}
          country={''}
          nickname={reel?.owner?.member?.username}
          text={summary}
          tags={[]}
          date={new Date(createdAt)}
          song={''}
        />
      </div>

      <ul className="reels__buttons-list">
        <li className="reels__buttons-list-item">
          <LikesReelBtn
            isFromPost={width > 992 ? true : false}
            likes={reel?.reactionsCount}
            id={reel?.id}
            likesList={reel?.reactions}
          />
        </li>
        <li className="reels__buttons-list-item">
          <CommentsReelBtnV2
            isFromPost={width > 992 ? true : false}
            reel={reel}
          />
        </li>
        <li className="reels__buttons-list-item">
          <ShareReelBtnV2
            userName={reel?.owner?.member?.name}
            userAvatar={reel?.owner?.member?.picture}
            reelId={reel?.id}
            reel={reel}
          />
        </li>
        <li className="reels__buttons-list-item">
          <EllipsisMenuV2
            type="reel"
            post={reel}
            isReel
            isMyPost={isMyPost}
            Id={reel?.id}
          />
        </li>
      </ul>
    </li>
  );
};

ProfileReelV2.propTypes = {
  reel: PropTypes.shape({
    id: PropTypes.number.isRequired,
    video: PropTypes.objectOf(PropTypes.string).isRequired,
  }).isRequired,
  currentPostId: PropTypes.number.isRequired,
};

export default ProfileReelV2;
