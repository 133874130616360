import React from 'react';

const HeaderLogoSvg = () => {
  const rand1 = Math.random()
  const rand2 = Math.random()
  const rand3 = Math.random()
  return (
    <svg width="35" height="39" viewBox="0 0 35 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={`url(#paint0_linear_877_1856${rand1})`} d="M33.568 11.4061C31.9105 7.1633 28.6931 3.81634 24.5107 1.9863C20.3284 0.154521 15.6803 0.0571044 11.4247 1.70971C4.5833 4.36779 0.210811 11.0652 0.545814 18.3749C0.58769 19.2917 1.3746 19.9997 2.28713 19.9597C3.20665 19.9179 3.91853 19.1403 3.87665 18.2236C3.60621 12.3403 7.12548 6.94934 12.6356 4.80965C16.0624 3.47887 19.8033 3.55715 23.169 5.03231C26.5365 6.50574 29.124 9.20036 30.4605 12.6152C33.2156 19.6657 29.705 27.6382 22.6316 30.3867C20.8693 31.0704 19.0129 31.3835 17.1546 31.3192C17.3134 31.7749 17.1773 32.4064 16.7045 32.6099C15.8827 32.9631 15.3331 33.6502 14.8812 34.4295C15.7972 34.5791 16.7184 34.6557 17.638 34.6557C19.7457 34.6557 21.8447 34.2643 23.8425 33.4867C32.6293 30.0736 36.9914 20.1702 33.568 11.4096V11.4061Z"/>
      <path fill={`url(#paint1_linear_877_1856${rand2})`}  d="M30.4607 12.6137C33.2158 19.6643 29.7052 27.6368 22.6318 30.3853C20.8695 31.069 19.0131 31.3821 17.1548 31.3177C17.3136 31.7735 17.1775 32.405 16.7047 32.6085C15.8829 32.9616 15.3333 33.6488 14.8813 34.4281C15.7974 34.5777 16.7186 34.6543 17.6381 34.6543C19.7459 34.6543 21.8449 34.2628 23.8427 33.4853C32.6295 30.0722 36.9915 20.1687 33.5682 11.4082L30.4607 12.6155V12.6137Z"/>
      <path fill={`url(#paint2_linear_877_1856${rand3})`}  d="M10.5401 38.7544C10.1702 38.7544 9.79851 38.6552 9.46525 38.4517C8.64868 37.9542 8.28402 36.9713 8.58064 36.0632L11.805 26.2015H7.40987C6.78523 26.2015 6.20246 25.9249 5.80988 25.4413C5.4173 24.9577 5.2655 24.3315 5.39462 23.7226L5.41207 23.6426L5.43649 23.5643L9.7357 10.0286C9.97648 9.14319 10.7948 8.51172 11.723 8.51172H20.6948C21.3805 8.51172 22.0191 8.85094 22.403 9.41804C22.7869 9.98515 22.8619 10.7036 22.6019 11.3368L19.4962 18.9562H26.1718C27.0058 18.9562 27.7526 19.452 28.0736 20.2209C28.3947 20.9897 28.2219 21.8665 27.6322 22.4562L11.9952 38.1438C11.5957 38.5439 11.0687 38.7509 10.5348 38.7509L10.5401 38.7544ZM9.15468 22.8755H13.5621C14.2199 22.8755 14.8428 23.1921 15.2284 23.7226C15.614 24.2532 15.7239 24.9421 15.5197 25.5649L13.412 32.01L23.1079 22.2823H17.603C16.9156 22.2823 16.277 21.943 15.8949 21.3759C15.511 20.8088 15.4377 20.0904 15.696 19.4554L18.8 11.8361H12.66L9.15293 22.8737L9.15468 22.8755Z"/>
      <defs>
        <linearGradient id={`paint0_linear_877_1856${rand1}`} x1="11.8074" y1="1.56131" x2="24.1617" y2="33.3602" gradientUnits="userSpaceOnUse">
          <stop stopColor="white"/>
          <stop offset="1" stopColor={"#F7CFCF"}/>
        </linearGradient>
        <linearGradient id={`paint1_linear_877_1856${rand2}`} x1="31.8897" y1="21.303" x2="11.0816" y2="28.8299" gradientUnits="userSpaceOnUse">
          <stop offset="0.07" stopColor={"#EF8FD5"} stopOpacity="0"/>
          <stop offset="1" stopColor={"#FF15D2"} stopOpacity="0.4"/>
        </linearGradient>
        <linearGradient id={`paint2_linear_877_1856${rand3}`} x1="5.351" y1="23.6339" x2="28.2359" y2="23.6339" gradientUnits="userSpaceOnUse">
          <stop stopColor="white"/>
          <stop offset="1" stopColor={"#F7CFCF"}/>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default HeaderLogoSvg;