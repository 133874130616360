import React from 'react';
import { Link } from "react-router-dom";
import PrimaryButton from "../../../../components/reusable/form/items/buttons/PrimaryButton.jsx";
import UsersList from "../item/UsersList.jsx";
import SocialRows from "../item/SocialRows.jsx";
import SearchInput from "../../../../components/reusable/form/items/inputs/SearchInput.jsx";
import BaseLayout from '../../../../components/layouts/baseLayout/BaseLayout.jsx';

export const contacts = [
    {
        name: 'Jhon Doe',
        avatar: '/images/creators/1.jpg',
        description: 'Some short description text'
    },
    {
        name: 'Jhon Doe',
        avatar: '/images/creators/2.jpg',
        description: 'Some short description text'
    },
    {
        name: 'Jhon Doe',
        avatar: '/images/creators/3.jpg',
        description: 'Some short description text'
    },
    {
        name: 'Jhon Doe',
        avatar: '/images/creators/4.jpg',
        description: 'Some short description text'
    },
    {
        name: 'Jhon Doe',
        avatar: '/images/creators/1.jpg',
        description: 'Some short description text'
    },
    {
        name: 'Jhon Doe',
        avatar: '/images/creators/2.jpg',
        description: 'Some short description text'
    },
    {
        name: 'Jhon Doe',
        avatar: '/images/creators/3.jpg',
        description: 'Some short description text'
    },
    {
        name: 'Jhon Doe',
        avatar: '/images/creators/4.jpg',
        description: 'Some short description text'
    },
    {
        name: 'Jhon Doe',
        avatar: '/images/creators/1.jpg',
        description: 'Some short description text'
    },
    {
        name: 'Jhon Doe',
        avatar: '/images/creators/2.jpg',
        description: 'Some short description text'
    },
    {
        name: 'Jhon Doe',
        avatar: '/images/creators/3.jpg',
        description: 'Some short description text'
    },
    {
        name: 'Jhon Doe',
        avatar: '/images/creators/4.jpg',
        description: 'Some short description text'
    },
]


const AddFriends = () => {
    return (
        <BaseLayout>
            <div className='profile__wrapper mt-sm-7'>
                <div className='row align-items-center mb-md-6  mb-2 gx-0'>
                    <div className='d-flex align-items-center justify-content-center '>
                        <div className='d-none d-sm-block '>
                            <img src="/images/add-users/contact.png" alt="rocket" className='img-fluid' />
                        </div>
                        <div className='h4 ms-2 mb-2'>Invite friends</div>
                    </div>
                    <div className='mb-5'>
                        <SocialRows />
                        <div>
                            <SearchInput />
                        </div>
                        <div className='profile__content'>
                            <UsersList users={contacts} />
                        </div>
                        <div className='d-none d-sm-block'>
                            <Link to='/auth/invite' className='d-block my-sm-8'>
                                <PrimaryButton>Next</PrimaryButton>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </BaseLayout>
    );
};

export default AddFriends;