import React, {useState, Fragment, useEffect} from 'react';
import Box from '../../../../../components/reusable/cards/box/Box.jsx';
import {useHubApp} from '../../../../../appState/hooks/useHubApp';
import Strings from '../../../../../string_key/Strings.web.js';
import {useToast} from 'react-native-toast-notifications';
import {useLinkTo} from '@react-navigation/native';
import {Dialog, Transition} from '@headlessui/react';
import ModalBackdrop from '../../../../../components/layouts/mainLayout/componentsV2/ModalBackDrop/index.js';
import SendGems from '../../../Hub/SendGems/index.web.js';
import WalletDetail from '../../../Hub/WalletDetail/index.web.js';

const HubWalletTop = ({isFromWallet = false}) => {
  const toast = useToast();
  const linkTo = useLinkTo();

  return (
    <div className={'d-flex justify-content-between align-items-center'}>
      <h4 className="h4">
        {isFromWallet ? Strings.wallet_balance : Strings.wallet}
      </h4>
      {isFromWallet == false && (
        <button
          onClick={() => {
            linkTo('/hub/v2/wallet-earning');
          }}>
          <div className="text-alt-primary d-lg-block">See my earnings</div>
        </button>
      )}
    </div>
  );
};
const SeeMyEarnings = ({isFromWallet = false}) => {
  const toast = useToast();
  const linkTo = useLinkTo();

  return (
    <div
      onClick={() => {
        linkTo('/hub/v2/wallet-earning');
      }}>
      <div className="text-alt-primary text-xs text-center">
        See my earnings
      </div>
    </div>
  );
};

const HubWallet = ({isFromWallet}) => {
  const {walletInfo} = useHubApp();
  const [type, setType] = useState(0);
  const [open, setOpen] = useState(false);
  const linkTo = useLinkTo();

  const getWalletCoinByName = coin => {
    const filtered = walletInfo.filter(x => x.hasOwnProperty(coin));
    if (filtered.length > 0) {
      return filtered[0][coin]?.total;
    }
    return 0;
  };
  const WalletItems = ({vertical}) => {
    return (
      <div className={vertical ? '' : 'd-flex'}>
        <div
          className={
            'd-flex align-items-center me-7 pointer-events-none sm:pointer-events-auto'
          }
          onClick={() => {
            isFromWallet
              ? (setType(0),
                setTimeout(() => {
                  setOpen(!open);
                }, 250))
              : null;
          }}>
          <span className={'me-1'}>{getWalletCoinByName('gems')}</span>
          <img src="/images/hub/wallet/coin.svg" alt="" />
        </div>
        <div
          className={
            'd-flex align-items-center me-7  pointer-events-none sm:pointer-events-auto'
          }
          onClick={() => {
            isFromWallet
              ? (setType(1),
                setTimeout(() => {
                  setOpen(!open);
                }, 250))
              : null;
          }}>
          <span className={'me-1'}>{getWalletCoinByName('diamonds')}</span>
          <img src="/images/hub/wallet/diamond.svg" alt="" />
        </div>
        <div
          className={
            'd-flex align-items-center me-md-7  pointer-events-none sm:pointer-events-auto'
          }
          onClick={() => {
            isFromWallet
              ? (setType(2),
                setTimeout(() => {
                  setOpen(!open);
                }, 250))
              : null;
          }}>
          <span className={'me-1'}>{getWalletCoinByName('buzzcoins')}</span>
          <img src="/images/hub/wallet/energy.svg" alt="" />
        </div>
      </div>
    );
  };

  return (
    <>
      <Box
        topComponent={<HubWalletTop isFromWallet={isFromWallet} />}
        className={'mt-3'}
        paddingMode="big">
        <WalletItems />
      </Box>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <ModalBackdrop />
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full">
                  <Dialog.Panel
                    className="pointer-events-auto w-screen max-w-md"
                    style={{maxWidth: 640}}>
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      {type == 0 ? (
                        <SendGems />
                      ) : (
                        <WalletDetail
                          type={type == 1 ? 'Diamond' : 'Buzzcoin'}
                        />
                      )}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export const HubWalletItems = ({isFromWallet}) => {
  const {walletInfo} = useHubApp();
  const [type, setType] = useState(0);
  const [open, setOpen] = useState(false);
  const linkTo = useLinkTo();

  const getWalletCoinByName = coin => {
    const filtered = walletInfo.filter(x => x.hasOwnProperty(coin));
    if (filtered.length > 0) {
      return filtered[0][coin]?.total;
    }
    return 0;
  };
  const WalletItems = ({}) => {
    return (
      <>
        <div className="flex flex-col items-end">
          <div
            className={
              'd-flex align-items-center mb-1 pointer-events-none sm:pointer-events-auto'
            }
            onClick={() => {
              isFromWallet
                ? (setType(0),
                  setTimeout(() => {
                    setOpen(!open);
                  }, 250))
                : null;
            }}>
            <span className={'me-1'}>{getWalletCoinByName('gems')}</span>

            <img
              className="h-5 w-5 sm:h-6 sm:w-6 md:h-8 md:w-8 lg:h-10 lg:w-10 xl:h-12 xl:w-12"
              src="/images/hub/wallet/coin.svg"
              alt=""
            />
          </div>
          <div
            className={
              'd-flex align-items-center mb-1  pointer-events-none sm:pointer-events-auto'
            }
            onClick={() => {
              isFromWallet
                ? (setType(1),
                  setTimeout(() => {
                    setOpen(!open);
                  }, 250))
                : null;
            }}>
            <span className={'me-1'}>{getWalletCoinByName('diamonds')}</span>
            <img
              className="h-5 w-5 sm:h-6 sm:w-6 md:h-8 md:w-8 lg:h-10 lg:w-10 xl:h-12 xl:w-12"
              src="/images/hub/wallet/diamond.svg"
              alt=""
            />
          </div>
          <div
            className={
              'd-flex align-items-center mb-1  pointer-events-none sm:pointer-events-auto'
            }
            onClick={() => {
              isFromWallet
                ? (setType(2),
                  setTimeout(() => {
                    setOpen(!open);
                  }, 250))
                : null;
            }}>
            <span className={'me-1'}>{getWalletCoinByName('buzzcoins')}</span>
            <img
              className="h-5 w-5 sm:h-6 sm:w-6 md:h-8 md:w-8 lg:h-10 lg:w-10 xl:h-12 xl:w-12"
              src="/images/hub/wallet/energy.svg"
              alt=""
            />
          </div>
        </div>
        <SeeMyEarnings />
      </>
    );
  };

  return (
    <>
      {/* <Box topComponent={<HubWalletTop isFromWallet={isFromWallet} />} className={'mt-3'} paddingMode="big"> */}
      <WalletItems />
      {/* </Box> */}

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <ModalBackdrop />
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full">
                  <Dialog.Panel
                    className="pointer-events-auto w-screen max-w-md"
                    style={{maxWidth: 640}}>
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      {type == 0 ? (
                        <SendGems />
                      ) : (
                        <WalletDetail
                          type={type == 1 ? 'Diamond' : 'Buzzcoin'}
                        />
                      )}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default HubWallet;
