import {useMutation} from 'react-query';
import {createBaseActivity} from '../../../network/api/app';

export const useCreateBaseActivity = () => {
  const {
    mutate: mutateCreateBaseActivity,
    isLoading: isCreateBaseActivityLoading,
    isError: isCreateBaseActivityError,
    data: CreateBaseActivityResponse,
    error: CreateBaseActivityErrorResponse,
  } = useMutation(createBaseActivity);

  const postCreateBaseActivity = (params) => mutateCreateBaseActivity(params);

  return {
    isCreateBaseActivityLoading,
    CreateBaseActivityResponse,
    CreateBaseActivityErrorResponse,
    postCreateBaseActivity,
  };
};
