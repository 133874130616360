import cn from 'classnames';

const oldItemsData = {
  all: {
    name: 'All',
    className: 'badge badge_all',
  },
  presets: {
    name: 'Presets',
    className: 'badge badge_presets',
  },
  workouts: {
    name: 'Workouts',
    className: 'badge badge_workouts',
  },
  habits: {
    name: 'Habits',
    className: 'badge badge_habits',
  },
  program: {
    name: 'Program',
    className: 'badge badge_program',
  },
  challenges: {
    name: 'Challenges',
    className: 'badge badge_challenges',
  },
  buzzmiTasks: {
    name: 'Buzzmi Tasks',
    className: 'badge badge_buzzmi-tasks',
  },
  completed: {
    name: 'Completed',
    className: 'badge badge_completed badge_one-color',
    icon: '/images/assets/check-circle.svg',
  },
  secondary: {
    className: 'badge  badge_buzzmi-tasks',
  },
};
const itemsData = [
  {
    name: 'All',
    className: 'badge badge_all',
  },

  {
    name: 'Presets',
    className: 'badge badge_presets',
  },
  {
    name: 'Workouts',
    className: 'badge badge_workouts',
  },
  {
    name: 'Habits',
    className: 'badge badge_habits',
  },
  {
    name: 'Program',
    className: 'badge badge_program',
  },
  {
    name: 'Challenges',
    className: 'badge badge_challenges',
  },
  {
    name: 'Buzzmi Tasks',
    className: 'badge badge_buzzmi-tasks',
  },
  {
    name: 'Completed',
    className: 'badge badge_completed badge_one-color',
    // icon: '/images/assets/check-circle.svg',
  },
  {
    className: 'badge  badge_buzzmi-tasks',
  },
];

const Badge = ({
  type,
  count,
  active,
  onClick,
  title,
  className,
  index,
  isRemove = false,
  onRemoveClick,
}) => {
  const clName = className
    ? className
    : type == 'Completed'
    ? oldItemsData?.[type.toLowerCase()]?.className
    : itemsData[index % itemsData.length].className;

  return (
    <span
      onClick={() => (onClick ? onClick() : null)}
      className={cn(`${clName} badge__prevent-select`, {
        badge__active: active,
      })}>
      {oldItemsData?.[type?.toLowerCase()]?.icon && (
        <img
          src={oldItemsData?.[type?.toLowerCase()]?.icon}
          alt="icon"
          className="me-1 badge__icon"
        />
      )}

      {!!title ? title : oldItemsData?.[type?.toLowerCase()]?.name}
      {/* {!!type ? type : itemsData?.[index]?.name} */}
      {count && <span className="badge_count"> &nbsp; ({count})</span>}
      {isRemove && (
        <div
          className="close_icon"
          style={{marginLeft: 10}}
          onClick={onRemoveClick}>
          <i class="fa-solid fa-xmark"></i>
        </div>
      )}
    </span>
  );
};

export default Badge;
