import React from 'react';
import EditProfileBoxForm from '../EditProfileBoxForm';
import EditProfileSetSkills from './items/editProfileSetSkills/EditProfileSetSkills';
import EditProfileSetTags from './items/editProfileSetTags/EditProfileSetTags';

const EditProfileInterests = ({setValue, values, paddingMode}) => {
  return (
    <EditProfileBoxForm
      title="Skills and interests"
      className="text-center"
      paddingMode={paddingMode}>
      <EditProfileSetTags setValue={setValue} values={values?.tags} />
      <EditProfileSetSkills setValue={setValue} values={values?.skills} />
    </EditProfileBoxForm>
  );
};

export default EditProfileInterests;
