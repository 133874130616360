import React from 'react';
import PostContent from '../items/PostContentList/PostContent';
import FollowingPostContent from '../items/FollowingPostContentList/FollowingPostContent';
import DiscoverList from '../items/DiscoverList/DiscoverList';


const SocialTabContent = ({ activeTabId, ishide }) => {
    const contentMap = {
        ForYou: <div><p className={"font-bold my-[16px] py-[5px] border-b-4 border-solid border-[#fc3ba7] w-max text-lg"}>For You</p> <PostContent /></div>,
        Following: <div><p className={"font-bold my-[16px] py-[5px] border-b-4 border-solid border-[#fc3ba7] w-max text-lg"}>Following</p><FollowingPostContent /></div>,
        Discover: <div><p className={"font-bold my-[16px] py-[5px] border-b-4 border-solid border-[#fc3ba7] w-max text-lg"}>Discover</p><DiscoverList /></div>,
    };

    return !activeTabId || !contentMap[activeTabId]
        ? null
        : contentMap[activeTabId];
};

export default SocialTabContent;
