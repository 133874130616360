import React from 'react';
import DeleteTaskDesktopAlert from './DeleteTaskDesktopAlert';
import DeleteTaskMobileAlert from './DeleteTaskMobileAlert';

const DeleteTaskAlert = props => {
  /**
   * Окей значит функции можем определять тут и передавать их ниже
   */

  const handleDeleteTaskFromAllUsers = () => {
    // console.log("handleDeleteTaskFromAllUsers", taskId)

    props?.closeModal();
  };
  const handleDeleteTaskOnlyBaseTask = () => {
    // console.log("handleDeleteTaskOnlyBaseTask", taskId)
    props?.deleteCallback();
  };

  return (
    <>
      <DeleteTaskDesktopAlert
        handleDeleteTaskFromAllUsers={handleDeleteTaskFromAllUsers}
        handleDeleteTaskOnlyBaseTask={handleDeleteTaskOnlyBaseTask}
      />
      <DeleteTaskMobileAlert
        handleDeleteTaskFromAllUsers={handleDeleteTaskFromAllUsers}
        handleDeleteTaskOnlyBaseTask={handleDeleteTaskOnlyBaseTask}
      />
    </>
  );
};

export default DeleteTaskAlert;
