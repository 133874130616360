import ProfileInformationDesktop from './tabs/ProfileInformationDesktop';
import ProfileAboutDesktop from './tabs/ProfileAboutDesktop';
import ProfileContentTabs from '../profileContentTabs/ProfileContentTabs';
import React from 'react';
import ProfileUsersBody from '../../profileMobileUsers/ProfileUsersBody';
import ProfileContentListV2 from '../ProfileContentListV2';
import ProfileMembers from '../profileMembers/ProfileMembers';

const ProfileDesktopTabsChildrensV2 = ({ activeTabId, ishide }) => {
  const contentMap = {
    'Premium Members': <ProfileMembers />,
    Posts: <ProfileContentListV2 isPosts={true} ishide={ishide} />,
    Reel: <ProfileContentListV2 isPosts={false} />,
    About: <ProfileAboutDesktop />,
    // Information: <ProfileInformationDesktop />,
    Friends: <ProfileUsersBody hideheader={true} />,
  };

  return !activeTabId || !contentMap[activeTabId]
    ? null
    : contentMap[activeTabId];
};

export default ProfileDesktopTabsChildrensV2;
