import React from "react"

const ProfilePremiumContentHighlights = ({ highlights }) => (
  <div className="profile-content__highlights profile-content__block">
    <div className="profile-content__block-title">Highlights</div>
    <ul>
      {highlights.map((item) => (
        <li key={item.id}>
          <img src={item.icon.src} alt={item.icon.alt} />
          <span className="text-r d-inline-block ms-2">{item.text}</span>
        </li>
      ))}
    </ul>
  </div>
)

export default ProfilePremiumContentHighlights
