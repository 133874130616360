import React from 'react';
import CustomRatingGold from '../../../../components/reusable/assets/customRating/CustomRatingGold';

const ReviewsRating = ({
  reviewsQuantity,
  averageRating,
  ratingDistribution,
}) => {
  return (
    <div className="premium-content-reviews__rating">
      <h4 className="h4">Average rating</h4>

      <div className="d-flex flex-row justify-content-between">
        <div className="d-flex flex-row">
          <p className="h3">{averageRating}</p>

          <div className="d-flex flex-column premium-content-reviews__stars-wrapper">
            <CustomRatingGold initialValue={averageRating} readonly />
            <p className="text-s">Based on {reviewsQuantity} ratings</p>
          </div>
        </div>

        <ul className="premium-content-reviews__rating-list">
          {Object.keys(ratingDistribution)
            .reverse()
            .map(rating => (
              <li key={rating} className="d-flex flex-row align-items-center">
                <p className="text-s">{rating} stars</p>
                <div className="premium-content-reviews__progress-bar gradient-border">
                  <div
                    className="progress"
                    style={{width: ratingDistribution[rating]}}></div>
                </div>
                <p className="h6 ms-auto">{ratingDistribution[rating]}</p>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default ReviewsRating;
