import {useMutation} from 'react-query';
import {useBuzzmiAuth} from '../useBuzzmiAuth/index';
import {patchEditPlanActivity} from '../../../network/api/app';
import {EventRegister} from 'react-native-event-listeners';
import {useHubApp} from '../useHubApp/index.web';
export const useUpdatePlanActivity = () => {
  const {userData} = useBuzzmiAuth();
  const {getNextRankInfoDetail, getWalletData, getRankInfoDetail} = useHubApp();
  const {
    mutate: mutateUpdatePlanActivity,
    isLoading: isUpdatePlanActivityLoading,
    isError: isUpdatePlanActivityError,
    data: UpdatePlanActivityResponse,
    error: UpdatePlanActivityErrorResponse,
  } = useMutation({
    mutationFn: param => {
      return patchEditPlanActivity(param.id)({
        params: {isAuth: true, token: userData?.token},
        body: {...param?.body},
      });
    },
    onSuccess: data => {
      console.log('edited ==> ', data);
      EventRegister.emit('refresh-planActivities');
      EventRegister.emit('closeEditPlanPanel');
      getWalletData(userData?.token),
        getRankInfoDetail(userData?.id, userData?.token);
      getNextRankInfoDetail(userData?.id, userData?.token);
      EventRegister.emit('wallet-history-update');
    },
  });
  const postUpdatePlanActivity = async (id, body) =>
    mutateUpdatePlanActivity({id, body});

  return {
    isUpdatePlanActivityLoading,
    UpdatePlanActivityResponse,
    UpdatePlanActivityErrorResponse,
    postUpdatePlanActivity,
  };
};
