import React from "react"

const AddComponent = ({ callback }) => {
  return (
    <button type={"button"} className="add-component" onClick={callback}>
      <img src="/images/assets/plus.svg" />
    </button>
  )
}

export default AddComponent
