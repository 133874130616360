import React, {useEffect, useState} from 'react';
import {useHubApp} from '../../../../../../../appState/hooks/useHubApp';
import CircleChart from '../../../../../../../components/reusable/assets/charts/circleChart/CircleChart.jsx';
import Strings from '../../../../../../../string_key/Strings.web';

const RankRow = ({withAvatar}) => {
  const {rankInfo} = useHubApp();

  return (
    <div className="rank-row">
      <div className="rank-row__label">
        <div className="text-r">{Strings.your_current_rank}</div>
        <div>
          <img
            className="rank-row__img"
            src={rankInfo?.currentRankBadge}
            alt="current rank icon"
          />
        </div>
        <div className={'h6'}>{rankInfo?.currentRank}</div>
      </div>
      <div>
        <CircleChart progress={rankInfo?.progress} withAvatar={withAvatar} />
      </div>
      <div className="rank-row__label">
        <div className="text-r">{Strings.next_rank}</div>
        <div>
          <img
            className="rank-row__img"
            src={rankInfo?.nextRankBadge}
            alt="current rank icon"
          />
        </div>
        <div className={'h6'}>{rankInfo?.nextRank}</div>
      </div>
    </div>
  );
};

export default RankRow;
