// noinspection ES6CheckImport
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState, useRef } from 'react';
import CodeInputs from '../../../components/reusable/form/items/inputs/codeInputs/CodeInputs';
import MyAuthLayout from '../../../components/layouts/myAuthLayout/MyAuthLayout';
import Logo from '../../../components/reusable/assets/logo/Logo';
import { ShowError, ShowSuccess } from '../../HOC/MessageWeb';
import { useToast } from 'react-native-toast-notifications';
import { useForm } from 'react-hook-form';
import { NEW_VERIFY_OTP, SEND_EMAIL_VERIFICATION, VERIFY_EMAIL_CODE } from '../../../api_helper/Api';
import { POST } from '../../../api_helper/ApiServices';
import Loading from 'react-fullscreen-loading';
import { colors } from '../../../res/colors';
import { getData, saveData } from '../../../res/asyncStorageHelper.web';
import Strings from '../../../string_key/Strings.web';
import { useBuzzmiAuth } from "../../../appState/hooks";
import { useEncryptedAtom } from "../../../appState/hooks/useEncryptedAtom";
import { _fSecretAtom } from "../../../appState/userState";

const EnterOtp = props => {
  const toast = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { emailError } = useBuzzmiAuth();
  const [isLoad, setIsLoad] = useState(false);

  const [fSecret, setFSecret] = useEncryptedAtom(_fSecretAtom);

  let refUrlParams = useRef(props.route.params)

  // useEffect(() => {
  //   setTimeout(() => {
  //     window.history.replaceState(null, 'Title', '/EnterOtp')
  //   }, 100);
  // }, [])

  const onSubmit = data => {

    if (refUrlParams.current.param.phone) {
      getData("f_uuid", (f_uuid) => {
        setIsLoad(true)
        let postData = { "otp": data.code, "uniqueUUID": f_uuid };
        POST(NEW_VERIFY_OTP, false, null, null, postData, (response, isError) => {
          setIsLoad(false)
          if (isError) {
            ShowError(response, toast)
          } else {
            props.navigation.navigate('UpdatePasswordScreen', { param: Object.assign({ "phone": refUrlParams.current.param.phone }) });
            setTimeout(() => {
              ShowSuccess(response?.message, toast)
            }, 250);
          }
        });

      })
    } else {
      let postData = { "code": data.code };
      setIsLoad(true)
      POST(VERIFY_EMAIL_CODE, false, null, null, postData, (response, isError) => {
        setIsLoad(false)
        if (isError) {
          ShowError(response, toast)
        } else {
          props.navigation.navigate('UpdatePasswordScreen', { param: Object.assign({ "email": refUrlParams.current.param.email }) });
          setTimeout(() => {
            ShowSuccess(response?.message, toast)
          }, 250);
        }
      });
    }
  };

  const handleResend = () => {
    setIsLoad(true)
    let postData = { email: refUrlParams.current.param.email };
    POST(SEND_EMAIL_VERIFICATION, false, null, null, postData, (response, isError) => {
      setIsLoad(false)
      if (isError) {
        console.log(isError)
      } else {
        setTimeout(() => {
          // saveData('f_secret', response?.secret);
          setFSecret(response?.secret);
          ShowSuccess(response?.message, toast)
        }, 250);
      }
    });
  };

  useEffect(() => {
    if (emailError != null) {
      ShowError(emailError, toast);
    }
  }, [emailError]);

  const maskEmail = (email) => {
    return email.replace(/(\w{1})[\w.-]+@([\w.]+\w)/, "$1***@$2")
  }

  const maskPhone = (phone) => {
    return phone.slice(0, 2) + "*".repeat(phone.length - 5) + phone.slice(-3)
  }

  return (
    <MyAuthLayout>
      <Loading loading={isLoad} background="transparent" loaderColor={colors.PRIMARY_COLOR} />

      <div className="forgot-password__logo">
        <Logo subtitle="Welcome to" />
      </div>
      <div className="h3 content-auth__title">{Strings.enter_otp}</div>
      {refUrlParams?.current &&
        <div className="content-auth__text text-r">
          {Strings.foreget_password_masked_text.replace('${email}', refUrlParams.current?.param?.phone ? maskPhone(refUrlParams.current?.param?.phone) : maskEmail(refUrlParams.current?.param?.email))}
        </div>
      }
      <CodeInputs
        identifier={refUrlParams?.current?.param.phone ? refUrlParams?.current?.param.phone : refUrlParams?.current?.param.email}
        pressSubmit={onSubmit}
        codelength={refUrlParams?.current?.param.phone ? 4 : 6}
        label={Strings.verification_code}
        handleResend={handleResend}
        formClassName="form content-auth__form"
      />
    </MyAuthLayout>
  );
};
export default EnterOtp;
