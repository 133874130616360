import { useAtom } from 'jotai';
import { userRaings } from '../hubState';
import { getUserRattings } from '../../../network/api/app';

export const useUserRatings = () => {
    const [userRating, setUsersRating] = useAtom(userRaings);

    const getOnUserRatings = async (id, token) => {
        try {
            const response = await getUserRattings(id)({ token });
            setUsersRating(response);
        } catch (e) {
            console.log('error => ', e.message);
        }
    };

    return {
        userRating,
        getOnUserRatings,
    };
};
