import OtherServicesModal from './modals/otherServicesModal/OtherServicesModal.jsx';
import Report from './modals/report/Report.jsx';
import ChoseImageModal from './modals/choseImageModal/ChoseImageModal.jsx';
import ChoseMultipleImageModal from './modals/choseMultipleImageModal/ChoseMultipleImageModal.jsx';
import AddTags from './modals/addTags/AddTags.jsx';
import AddProfileTags from './modals/addProfileTags/AddProfileTags.jsx';

import renderAlert from './modals/alert/renderAlert.jsx';
import ShareModal from './modals/shareModal/ShareModal.jsx';
import RatingCourseModal from './modals/ratingCourseModal/RatingCourseModal.jsx';
import AddLinksToBio from './modals/addLinksToBio/AddLinksToBio.jsx';
import AddSocialMediaConnection from './modals/addSocialMediaConnection/AddSocialMediaConnection.jsx';
import CommentsModal from './modals/commentsModal/CommentsModal.jsx';
import JoinToCourse from './modals/joinToCourse/JoinToCourse.jsx';
import QrCodeModal from './modals/qrCodeModal/QrCodeModal.jsx';
import QrDisableModal from './modals/qrDisableModal/QrDisableModal.jsx';

const renderContent = (name, props) => {
  const contentMap = {
    OtherServicesModal,
    Report,
    ChoseImageModal,
    ChoseMultipleImageModal,
    AddTags,
    AddProfileTags,
    renderAlert,
    ShareModal,
    RatingCourseModal,
    AddLinksToBio,
    QrCodeModal,
    QrDisableModal,
    AddSocialMediaConnection,
    CommentsModal,
    JoinToCourse,
  };

  const Component = contentMap[name];
  return <Component {...props} /> ?? null;
};

export default renderContent;
