import React, {useEffect, useState} from 'react';
import ProfilePostV2 from './profilePost/ProfilePostV2';
import ProfileReelV2 from './profileReels/ProfileReelV2';
import useWindowSize from '../../../../hooks/useWindowSize';
import {useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useProfileApp} from '../../../../appState/hooks';
import {useBuzzmiAuth} from '../../../../appState/hooks/useBuzzmiAuth/index.web';

const ProfileViewContentListV2 = props => {
  const {isPosts, currentId} = props;
  //   const [isTableActive, setIsTableActive] = useState(true);
  const [currentPostId, setCurrentPostId] = useState(currentId);

  const {width} = useWindowSize();
  const location = useLocation();
  const {userReels, userPosts, postUploading} = useProfileApp();
  const {userData} = useBuzzmiAuth();

  useEffect(() => {
    console.log(' /// userReels ///');
    console.log(userReels);
  }, [userReels]);

  return (
    <>
      <ul
        className={
          isPosts ? 'profile-posts-list__column' : 'reels__list-column'
        }>
        {isPosts ? (
          userPosts && userPosts.length > 0 ? (
            userPosts.map(post => (
              <ProfilePostV2
                key={post.id}
                postData={post}
                currentPostId={currentPostId}
                type="post"
                isMyPost={true}
              />
            ))
          ) : (
            <div className={'flex justify-center mt-5 w-100'}>
              <img
                src="/images/assets/empty_pic.png"
                alt="No Post Found"
                style={{width: 180}}
              />
            </div>
          )
        ) : userReels && userReels.length > 0 ? (
          userReels.map((reel, index) => (
            <React.Fragment key={reel.id}>
              {width < 767 ? (
                <ProfilePostV2
                  postData={reel}
                  currentPostId={currentPostId}
                  type="reel"
                  isMyPost={true}
                />
              ) : (
                <ProfileReelV2
                  reel={reel}
                  isMyPost={true}
                  currentPostId={currentPostId}
                />
              )}
            </React.Fragment>
          ))
        ) : (
          <div className={'flex justify-center mt-5 w-100'}>
            <img
              src="/images/assets/empty_pic.png"
              alt="No Reel Found"
              style={{width: 180}}
            />
          </div>
        )}
      </ul>
    </>
  );
};

ProfileViewContentListV2.propTypes = {
  isPosts: PropTypes.bool.isRequired,
};

export default ProfileViewContentListV2;
