import React, {useEffect} from 'react';
import useWindowSize from '../../../../../../../hooks/useWindowSize';
import {useLinkTo, useNavigation} from '@react-navigation/native';
import {PlayIcon} from '@heroicons/react/24/outline';
import {useDispatch} from 'react-redux';
import {
  closeModal,
  openModal,
} from '../../../../../../../store/slices/modalSlice/modalSlice';

const PostVideo = ({srcVideo, posterVideo, post, type, isMyPost}) => {
  const {width} = useWindowSize();
  const linkTo = useLinkTo();
  const dispatch = useDispatch();

  const handleOpenVideoFullScreen = e => {
    if (type === 'post' && width > 767) {
      e?.preventDefault();
    }
  };

  const onUnderDevelopment = () => {
    dispatch(
      openModal({
        modalName: 'renderAlert',
        modalType: 'alert',

        modalProps: {
          nameAlert: 'constructionAlert',
          propsAlert: {
            closeModal: () => {
              dispatch(closeModal());
            },
          },
        },
      }),
    );
  };

  return (
    <a
      className={'flex relative cursor-pointer'}
      onClick={() => {
        type == 'post' && posterVideo != ''
          ? ((global.videoPost = {poster: srcVideo, original: srcVideo}),
            linkTo(`/profile/visitor-fullscreen`))
          : (handleOpenVideoFullScreen(),
            linkTo(
              isMyPost
                ? `/profile/reels-fullscreen/${post.id}`
                : `/profile/visitor-reels-fullscreen/${post.id}`,
            ));
      }}>
      <video
        className="content-post__image content-post__video rounded-xl sm:rounded-none"
        controls={false}
        poster={posterVideo}>
        <source src={srcVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {type == 'post' && posterVideo != '' && (
        <div className={'absolute z-999 self-center left-[calc(100%/2.2)]'}>
          <PlayIcon className={'sm:w-16 sm:h-16 w-10 h-10 text-[#FFF]'} />
        </div>
      )}

      {isMyPost == false && (
        <div
          className={
            'absolute sm:top-[5px] sm:left-auto sm:right-[5px] bottom-[5px] left-[5px] z-[1]'
          }>
          <img
            onClick={() => {
              onUnderDevelopment();
            }}
            src={'/images/social/send_gift.png'}
            className="h-8 w-8 sm:h-10 sm:w-10"
          />
        </div>
      )}
    </a>
  );
};

export default PostVideo;
