import React, {useState} from 'react';
import BackBtnWithTitlePage from '../../../../components/reusable/btns/backBtn/BackBtnWithTitlePage.jsx';
import PeriodList from '../../../../components/reusable/periodList/PeriodList.jsx';
import PersonalCard from '../../../../components/reusable/cards/personalCard/PersonalCard.jsx';

//mocks
import {persons} from '../../../../mock-data/hub/hub.js';
import MainLayout from '../../../../components/layouts/mainLayout/MainLayout.jsx';
import MainLayoutWithAspectSide from '../../../../components/layouts/mainLayout/MainLayoutWithAspectSide.jsx';
const leaders = {
  0: persons[0],
  1: persons[1],
  2: persons[2],
};

const periodList = [
  {
    id: 0,
    text: 'Last week',
  },
  {
    id: 1,
    text: 'This week',
  },
  {
    id: 2,
    text: 'Last Month',
  },
  {
    id: 3,
    text: 'This month',
  },
  {
    id: 4,
    text: 'Year',
  },
];
const leaderboardDetails = {
  leaderboardname: 'Personal Plan Leaders',
  topLeader: {
    name: 'John',
    isVerified: true,
    handle: '@Johndoe',
    title: 'Elite creator',
    profile:
      'https://onesay-backend-dev.s3.amazonaws.com/9a766477-9815-421e-b0ff-ab2f109a6bac-12.jpg',
    wallet: {
      diamonds: '100,500',
      buzzcoins: '100,500',
    },
  },
  secondLeader: {
    name: 'John',
    isVerified: true,
    handle: '@Johndoe',
    title: 'Elite creator',
    profile:
      'https://onesay-backend-dev.s3.amazonaws.com/ef3669c2-bc25-499a-b4a1-be6b8d08d4cc-8.jpg',
    wallet: {
      diamonds: '100,500',
      buzzcoins: '100,500',
    },
  },
  thirdLeader: {
    name: 'John',
    isVerified: true,
    handle: '@Johndoe',
    title: 'Elite creator',
    profile:
      'https://onesay-backend-dev.s3.amazonaws.com/854b630e-7cf5-4290-b043-5f4cbc22cf4d-5.jpg',
    wallet: {
      diamonds: '100,500',
      buzzcoins: '100,500',
    },
  },
  lastMonth: [
    {
      name: 'John',
      isVerified: true,
      handle: '@Johndoe',
      title: 'Elite creator',
      profile:
        'https://onesay-backend-dev.s3.amazonaws.com/1dc82f64-1193-4d46-93b9-84dd3917effc-9.jpg',
      wallet: {
        diamonds: '100,500',
        buzzcoins: '100,500',
      },
    },
    {
      name: 'Jenny',
      isVerified: true,
      handle: '@junnydoe',
      title: 'Elite creator',
      profile:
        'https://onesay-backend-dev.s3.amazonaws.com/a906973b-e77d-4dee-aa22-cea24feff1db-11.jpg',
      wallet: {
        diamonds: '100,500',
        buzzcoins: '100,500',
      },
    },
  ],
  thisWeek: [
    {
      name: 'John',
      isVerified: true,
      handle: '@Johndoe',
      title: 'Elite creator',
      profile:
        'https://onesay-backend-dev.s3.amazonaws.com/a906973b-e77d-4dee-aa22-cea24feff1db-11.jpg',
      wallet: {
        diamonds: '100,500',
        buzzcoins: '100,500',
      },
    },
    {
      name: 'Jenny',
      isVerified: true,
      handle: '@junnydoe',
      title: 'Elite creator',
      profile:
        'https://onesay-backend-dev.s3.amazonaws.com/1dc82f64-1193-4d46-93b9-84dd3917effc-9.jpg',
      wallet: {
        diamonds: '100,500',
        buzzcoins: '100,500',
      },
    },
  ],
  lastWeek: [
    {
      name: 'Jane',
      isVerified: true,
      handle: '@janedoe',
      title: 'Elite creator',
      profile:
        'https://onesay-backend-dev.s3.amazonaws.com/854b630e-7cf5-4290-b043-5f4cbc22cf4d-5.jpg',
      wallet: {
        diamonds: '100,500',
        buzzcoins: '100,500',
      },
    },
    {
      name: 'Kate',
      isVerified: false,
      handle: '@katedoe',
      title: 'Elite creator',
      profile:
        'https://onesay-backend-dev.s3.amazonaws.com/ef3669c2-bc25-499a-b4a1-be6b8d08d4cc-8.jpg',
      wallet: {
        diamonds: '100,500',
        buzzcoins: '100,500',
      },
    },
  ],
};
const HubLeadersv2 = ({id}) => {
  // const {id} = props.params;
  const [period, setPeriod] = useState(0);
  const tabs = {
    0: 'lastWeek',
    1: 'thisWeek',
    2: 'lastMonth',
    3: 'thisMonth',
    4: 'year',
  };

  return (
    // <MainLayoutWithAspectSide showTitleButton={false} showRankInfo={false}>
    <div className="hub">
      <div className="hub__content">
        {/* <BackBtnWithTitlePage title="Leaderboard" btnText="Back " /> */}
        <div className="hub__leaders">
          <div className="hub__leaders-item hub__leaders-item--first">
            <div className="hub__leaders-item-img">
              <img
                src={leaderboardDetails.topLeader.profile}
                alt={leaderboardDetails.topLeader.name}
              />
            </div>
            <div className="hub__leaders-item-text">
              {leaderboardDetails.topLeader.handle}
            </div>
          </div>
          <div className="hub__leaders-item hub__leaders-item--second">
            <div className="hub__leaders-item-img">
              <img
                src={leaderboardDetails.secondLeader.profile}
                alt={leaderboardDetails.secondLeader.name}
              />
            </div>
            <div className="hub__leaders-item-text">
              {leaderboardDetails.secondLeader.handle}
            </div>
          </div>
          <div className="hub__leaders-item hub__leaders-item--third">
            <div className="hub__leaders-item-img">
              <img
                src={leaderboardDetails.thirdLeader.profile}
                alt={leaderboardDetails.thirdLeader.name}
              />
            </div>
            <div className="hub__leaders-item-text">
              {leaderboardDetails.thirdLeader.handle}
            </div>
          </div>
          <span className="hub__leaders-text">
            {/* {id && id?.replaceAll('-', ' ')} leaders */}
            {leaderboardDetails.leaderboardname}
          </span>
        </div>
        <PeriodList items={periodList} value={period} onClick={setPeriod} />
        <div className="personal-card__list">
          {leaderboardDetails?.[tabs?.[period]]?.map((person, index) => {
            return <PersonalCard {...person} key={index} />;
          })}
        </div>
      </div>
    </div>
    // </MainLayoutWithAspectSide>
  );
};

export default HubLeadersv2;
