import React from 'react';
import ShowMore from '../../../../../components/reusable/assets/showMore/ShowMore.jsx';
import Discount from './Discount.jsx';
import {useBuzzmiAuth} from '../../../../../appState/hooks/useBuzzmiAuth/index.web.js';

const SubscriptionCard = ({
  title,
  icon,
  discount,
  text,
  content,
  specialOffer,
}) => {
  const {language, userData} = useBuzzmiAuth();
  console.log('userData in subscription card', userData);
  return (
    <div className="subscription-card">
      <div className="subscription-card__top">
        <div className="subscription-card__top_left">
          <img className="subscription-card__icon" src={icon} alt={'icon'} />
          <div className="h5 text-alt-primary">{title}</div>
        </div>
        {typeof discount === 'number' && discount > 0 && (
          <Discount discount={discount} specialOffer={specialOffer} />
        )}
      </div>
      <ShowMore text={text} content={content} />
    </div>
  );
};

export default SubscriptionCard;
