import React from 'react';
import HeaderLogoSvg from "./HeaderLogoSVG.jsx";
import { Link } from '@react-navigation/native';

const HeaderLogo = () => {
  return (
    <Link to={{ screen: 'Login' }} >
      <div className='logo-h'>
        <HeaderLogoSvg />
        <span className='h5'>
          BUZZMI
      </span>
      </div>
    </Link>
  );
};

export default HeaderLogo;