import { useState, useEffect } from "react";
import { decryptData, encryptData } from '../../../utils/encryptdecryptoriginal';
import { useAtom } from "jotai";

const salt = /*process.env.SALT ||*/ '6d090796-ecdf-11ea-adc1-0242ac112345';

export const useEncryptedAtom = (initialValue) => {
  const [storedValue, setValue] = useAtom(initialValue);
  const [originalValue, setStateValue] = useState(null);

  useEffect(() => {
    try {
      const data = decryptData(storedValue, salt);
      setStateValue(() => JSON.parse(data));
    } catch (e) {
      // console.log('decrypt error ==> ', e.message, storedValue);
    }
  }, [storedValue]);

  const setData = (data) => setValue(encryptData(JSON.stringify(data), salt));

  return [originalValue, setData];
}
