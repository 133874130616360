import React from 'react';
import { useLinkTo } from '@react-navigation/native';

const CustomLink = ({ onClick, to, children, className, ...props }) => {
  const linkTo = useLinkTo();

  return (
    <a style={{ fontFamily: '"Montserrat", sans-serif', cursor: "pointer" }} onClick={() => { onClick != undefined ? onClick() : to && linkTo(to) }} className={'button ' + className} {...props}>
      {children}
    </a>
  );
};

export default CustomLink;
