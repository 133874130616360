import React, { useState, Fragment, useEffect } from 'react';
import Box from '../../../../../components/reusable/cards/box/Box.jsx';
import RankRowV2 from './items/RankRow/RankRowV2.jsx';
import { Link, useNavigation } from '@react-navigation/native';
import Strings from '../../../../../string_key/Strings.web.js';
import { useBuzzmiAuth } from '../../../../../appState/hooks/useBuzzmiAuth/index.web.js';
import { Dialog, Menu, Transition } from '@headlessui/react';
import ModalBackdrop from '../../../../../components/layouts/mainLayout/componentsV2/ModalBackDrop/index.js';
import { useLeaderBoard } from '../../../../../appState/hooks/useLeaderBoard/index.js';
import { useGetAllRankInfo } from '../../../../../appState/hooks/useGetAllRankInfo/index.js';
import { useHubApp } from '../../../../../appState/hooks/useHubApp/index.web.js';
import { EyeDropperIcon, XMarkIcon, PlusIcon } from '@heroicons/react/24/outline';


const RankTop = ({ withRankBtn = true, onclick }) => (
  <div
    className={'d-flex justify-content-between align-items-center mb-1 ml-1'}>
    <h3 className="text-base font-semibold leading-6 text-gray-900">
      {Strings.your_rank}
    </h3>
    <div className="flex-shrink-0">
      <button
        type="button"
        className="relative inline-flex items-center rounded-md sm:bg-[#eb4dac] sm:px-3 py-2 text-sm sm:font-semibold sm:text-white text-[#eb4dac] sm:shadow-sm sm:hover:bg-[#eb4dac] sm:focus-visible:outline focus-visible:outline-2 sm:focus-visible:outline-offset-2 sm:focus-visible:outline-pink-600"
        onClick={() => {
          onclick()
        }}>
        View rank ladder
        </button>
    </div>
  </div>
);

const RankV2 = ({ withAvatar, withRankBtn = true, small = false }) => {
  const [open, setOpen] = useState(false);
  const { userData, logout, language } = useBuzzmiAuth();


  const { useGetAllRankInfoList } = useGetAllRankInfo();

  const { rankInfo } = useHubApp();
  const { data: leaderBoardata } = useLeaderBoard(userData && userData?.token,);
  const { data: rankinfoList } = useGetAllRankInfoList(
    userData && userData?.token,
  );

  const RankLadder = () => {
    return (
      <div className="rank-loader">

        <p className="px-1 pb-0 text-center text-sm font-medium">
          Track your progress, climb the ranks, earn more rewards and unlock
          exclusive perks. Start earning and climb your way to the top of the
          Buzzmi community!
        </p>
        <div className={'flex justify-around m-4'}>
          <div className={'flex'}>
            <div className={'bg-[#148E19] p-[5px] m-[5px]'} />
            <p className={'text-sm'}>Achieved rank</p>
          </div>
          <div className={'flex'}>
            <div className={'bg-[#fbc02d] p-[5px] m-[5px]'} />
            <p className={'text-sm'}>Current rank</p>
          </div>
          <div className={'flex'}>
            <div className={'bg-[#EBE9F0] p-[5px] m-[5px]'} />
            <p className={'text-sm'}>Next rank</p>
          </div>
        </div>
        <div className="sm:pl-[12px] pl-[8px]">
          <div>
            {rankinfoList?.userRanks &&
              rankinfoList?.userRanks.length > 0 &&
              rankinfoList?.userRanks.map(litem => (
                <div className="buzz-rookie">
                  <div className="flex items-center gap-x-5">
                    <div>
                      <img
                        className={`sm:w-[60px] w-[40px] sm:h-[60px] h-[40px] p-1  ${litem?.isAchived
                          ? litem?.title == rankInfo?.currentRank
                            ? 'bg-[#fbc02d]'
                            : 'bg-[#129200]'
                          : 'bg-[#efefef]'
                          } rounded-full`}
                        src={litem?.image}
                      />
                    </div>
                    <h2
                      className={
                        litem?.title == rankInfo?.currentRank
                          ? 'font-bold'
                          : 'font-medium'
                      }>
                      {litem?.title}
                    </h2>

                  </div>
                  <div className="content sm:pl-[36px] pl-[26px]">
                    <p
                      className={`text-xs sm:text-sm sm:text-left text-slate-600 relative  before:absolute before:content-'' before:w-[3px] sm:before:h-[25px] before:h-[18px] ${litem?.isAchived
                        ? litem?.title == rankInfo?.currentRank
                          ? 'before:bg-[#fbc02d]'
                          : 'before:bg-[#129200]'
                        : 'before:bg-[#dcdcdc]'
                        } before:left-[-8px] before:top-[0px]`}>
                      <p
                        className={
                          litem?.title == rankInfo?.currentRank
                            ? 'border-[#fbc02d] border-x-[3px] border-t-[3px] bg-[#FBF8FF] ml-6 p-[10px] text-black rounded-ss-xl rounded-se-xl'
                            : 'bg-[#FBF8FF]  ml-6 p-[10px] text-black rounded-ss-xl rounded-se-xl'
                        }>
                        {litem?.content}
                      </p>
                    </p>
                    <p
                      className={`text-xs sm:text-sm sm:text-left text-slate-600 relative  before:absolute before:content-'' before:w-[3px] sm:before:h-[20px] before:h-[18px] ${litem?.isAchived
                        ? litem?.title == rankInfo?.currentRank
                          ? 'before:bg-[#fbc02d]'
                          : 'before:bg-[#129200]'
                        : 'before:bg-[#dcdcdc]'
                        } before:left-[-8px] before:top-[0px]`}>
                      <p
                        className={
                          litem?.title == rankInfo?.currentRank
                            ? 'border-[#fbc02d] border-x-[3px] bg-[#FBF8FF] ml-6 px-[10px] p-[5px] text-black'
                            : 'bg-[#FBF8FF] px-[10px] ml-6 p-[5px] text-black'
                        }>
                        <div className={'flex text-xs'}>
                          <p className={'font-medium mr-2'}>Buzzcoins: </p>
                          {litem?.description['Buzzcoins']}
                        </div>
                      </p>
                    </p>
                    <p
                      className={`text-xs sm:text-sm sm:text-left text-slate-600 relative  before:absolute before:content-'' before:w-[3px] sm:before:h-[20px] before:h-[18px] ${litem?.isAchived
                        ? litem?.title == rankInfo?.currentRank
                          ? 'before:bg-[#fbc02d]'
                          : 'before:bg-[#129200]'
                        : 'before:bg-[#dcdcdc]'
                        } before:left-[-8px] before:top-[0px]`}>
                      <p
                        className={
                          litem?.title == rankInfo?.currentRank
                            ? 'border-[#fbc02d] border-x-[3px] bg-[#FBF8FF]  ml-6 px-[10px] p-[5px] text-black'
                            : 'bg-[#FBF8FF] px-[10px] ml-6 p-[5px] text-black'
                        }>
                        <div className={'flex text-xs'}>
                          <p className={'font-medium mr-2'}>Gems: </p>
                          {litem?.description['Gems']}
                        </div>
                      </p>
                    </p>
                    <p
                      className={`text-xs sm:text-sm sm:text-left text-slate-600 relative  before:absolute before:content-'' before:w-[3px] sm:before:h-[20px] before:h-[18px] ${litem?.isAchived
                        ? litem?.title == rankInfo?.currentRank
                          ? 'before:bg-[#fbc02d]'
                          : 'before:bg-[#129200]'
                        : 'before:bg-[#dcdcdc]'
                        } before:left-[-8px] before:top-[0px]`}>
                      <p
                        className={
                          litem?.title == rankInfo?.currentRank
                            ? 'border-[#fbc02d] border-x-[3px] border-b-[3px] bg-[#FBF8FF]  ml-6 px-[10px] p-[5px] text-black rounded-ee-xl rounded-es-xl'
                            : 'bg-[#FBF8FF] px-[10px]  ml-6 p-[5px] text-black rounded-ee-xl rounded-es-xl'
                        }>
                        <div className={'flex text-xs'}>
                          <p className={'font-medium mr-2'}>
                            Gifted Diamonds:{' '}
                          </p>
                          {litem?.description['Gifted Diamonds']}
                        </div>
                      </p>
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  };


  return (

    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <ModalBackdrop />
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full">
                  <Dialog.Panel
                    className="pointer-events-auto w-screen max-w-md"
                    style={{ maxWidth: 640 }}>
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="p-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            View Rank Ladder
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-pink-500"
                              onClick={() => setOpen(false)}>
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* <div className="border-b border-gray-200">
                        <div className="px-6">
                          <nav className="-mb-px flex space-x-6">
                            {tabs.map(tab => (
                              <a
                                key={tab.name}
                                href={tab.href}
                                className={classNames(
                                  tab.current
                                    ? 'border-pink-500 text-pink-600'
                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                  'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium',
                                )}>
                                {tab.name}
                              </a>
                            ))}
                          </nav>
                        </div>
                      </div> */}
                      <div className="pt-0 px-4 py-4">
                        {/* {console.log(
                          'leaderBoardata?.listOfLeaderboards',
                          leaderBoardata?.listOfLeaderboards,
                        )} */}
                        {/* <Board items={leaderBoardata?.listOfLeaderboards} />
                         */}
                        <RankLadder />
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Box paddingMode="none">
        <RankTop withRankBtn={withRankBtn} onclick={() => { setOpen(!open) }} />
        <RankRowV2 withAvatar={withAvatar} withRankBtn={withRankBtn} />
      </Box>
    </>
  );
};

export default RankV2;
