import React from 'react';
import MemberShipUpgradeAlert from './memberShipUpgradeAlert';

const upgradeAlert = props => {
  const handleOkay = () => {
    props?.closeModal();
  };

  return (
    <>
      <MemberShipUpgradeAlert handleOkay={handleOkay} contentType={props?.contentType} />
    </>
  );
};

export default upgradeAlert;
