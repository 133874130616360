import React, {useEffect, useState} from 'react';
import ProfileReel from './ProfileReel';
import BackBtnWithTitlePage from '../../../../../components/reusable/btns/backBtn/BackBtnWithTitlePage';
import {useSwipeable} from 'react-swipeable';
import {useProfileApp} from '../../../../../appState/hooks';
import {useBuzzmiAuth} from '../../../../../appState/hooks/useBuzzmiAuth/index.web';

const ReelsFullscreen = props => {
  const {id} = props.route.params;
  const [reels, setReels] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const {userReels} = useProfileApp();
  const {userData} = useBuzzmiAuth();

  useEffect(() => {
    setReels(userReels);
  }, [userReels]);

  useEffect(() => {
    if (reels.length > 0) {
      const foundIndex = reels.findIndex(reel => reel?.id === id);
      setCurrentIndex(foundIndex);
    }
  }, [id, reels]);

  const handlers = useSwipeable({
    onSwipedUp: () => swipeUp(),
    onSwipedDown: () => swipeDown(),
  });

  const swipeUp = () => {
    const nextIndex = (currentIndex + 1) % reels.length;
    setCurrentIndex(nextIndex);
  };

  const swipeDown = () => {
    const prevIndex = (currentIndex - 1 + reels.length) % reels.length;
    setCurrentIndex(prevIndex);
  };
  console.log(
    'reels[currentIndex]',
    reels[currentIndex]?.owner?.member?.id,
    userData?.bettermodeUserId,
  );
  return (
    <div className="reels__fullscreen" {...handlers}>
      <BackBtnWithTitlePage btnText="" title="Reels" />
      {reels.length > 0 && (
        <ProfileReel
          reel={reels[currentIndex]}
          currentPostId={null}
          isMyPost={
            reels[currentIndex]?.owner?.member?.id == userData?.bettermodeUserId
          }
        />
      )}
    </div>
  );
};

export default ReelsFullscreen;
