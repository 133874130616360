import React, {useState, Fragment} from 'react';

import {Dialog, Transition} from '@headlessui/react';
import {XMarkIcon} from '@heroicons/react/24/outline';

import Loading from 'react-fullscreen-loading';

import {colors} from '../../../../res/colors.js';
import ModalBackdrop from '../../../layouts/mainLayout/componentsV2/ModalBackDrop/index.js';
import {sortableContainer, sortableElement} from 'react-sortable-hoc';

import ChoseMultipleImageModalV2 from '../choseMultipleImageModal/ChoseMultipleImageModalV2.jsx';
import dragpng from '../../../../assets/ic_drag.png';
import editPencil from '../../../../assets/ic_grad_pencil.png';

import {useBuzzmiAuth} from '../../../../appState/hooks/useBuzzmiAuth/index.web.js';
import {useEditQuickAccess} from '../../../../appState/hooks/useEditQuickAccess/index.js';

const RenderImage = ({src, addImage}) => {
  const [openImageModal, setOpenImageModal] = useState(false);
  return (
    <>
      <div
        style={{position: 'relative'}}
        onClick={() => setOpenImageModal(true)}>
        <img
          // onClick={() => setOpenImageModal(true)}
          className="sm:h-16 sm:w-16 h-[45px] w-[45px]  object-contain hub-access__img"
          src={src}
          alt="Transistor"
        />
        <div
          className="bg-gray-200 rounded-full p-1 absolute z-10"
          style={{position: 'absolute', bottom: -4, right: -4}}>
          <img
            src={editPencil}
            alt={'edit'}
            className="w-4 h-4 sm:h-5 sm:w-5  transform rotate-[270deg]"
          />
        </div>
      </div>
      {openImageModal && (
        <ChoseMultipleImageModalV2
          show={openImageModal}
          setShow={setOpenImageModal}
          selectedMedia={addImage}
          onlyImages={true}
          showSave={true}
          pickSingle={true}
        />
      )}
    </>
  );
};

const QuickAccessModal = ({is_open, set_is_open}) => {
  const {patchBaseEditQuickAccess, isEditQuickAccessLoading} =
    useEditQuickAccess();

  const {qaItems, otherQaItems, setOtherQaItems, setQaItems} = useBuzzmiAuth();
  const Loader = () => (
    <Loading
      loading={loading}
      background="transparent"
      loaderColor={colors.PRIMARY_COLOR}
    />
  );

  const EditableTitle = ({title, onSaveTitle}) => {
    const [isEdit, setIsEdit] = useState(false);
    const [editTitle, setEditTitle] = useState(title);

    return (
      <>
        {!isEdit ? (
          <div
            onClick={() => setIsEdit(true)}
            className="flex flex-row items-center gap-3">
            <h3 className="text-sm font-semibold text-gray-900">{title}</h3>
            <img
              src={editPencil}
              alt={'edit'}
              className="h-4 w-4 transform rotate-[270deg]"
            />
          </div>
        ) : (
          <>
            <form
              onSubmit={e => {
                e.preventDefault();
                onSaveTitle(editTitle);
                setIsEdit(false);
              }}>
              <input
                placeholder="Title"
                value={editTitle}
                onChange={e => setEditTitle(e.target.value)}
                className="rounded-md mr-2 sm:max-w-full max-w-[115px]"
              />
              <button
                type="submit"
                className="rounded-md bg-pink-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pink-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600">
                save
              </button>
            </form>
          </>
        )}
      </>
    );
  };

  const SortableItem = sortableElement(
    ({item, key, onClickDelete, onAddImage, onUpdateTitile}) => {
      return (
        <div className="relative">
          <button
            className="p-1.5 bg-gray-300  rounded-md absolute right-2 top-2 z-10"
            onClick={onClickDelete}>
            <img
              src="/images/assets/delete.svg"
              alt="delete"
              className="h-4 w-4"
            />
          </button>
          <div
            // onClick={() => item.handler()}
            // key={index}
            class="col-span-1 px-4 py-4 mb-3 rounded-lg bg-white text-xs font-semibold text-gray-900 border-slate-200 border hover:bg-gray-50">
            <div className="flex items-center gap-[5px] sm:gap-3">
              <img src={dragpng} className="h-7 w-2" />

              <RenderImage src={item?.image.src} addImage={onAddImage} />
              <EditableTitle title={item.title} onSaveTitle={onUpdateTitile} />
            </div>
          </div>
        </div>
      );
    },
  );
  const SortableContainer = sortableContainer(({children}) => {
    return <div className="container pr-0 pl-0">{children}</div>;
  });

  const onSortEnd = ({oldIndex, newIndex}) => {
    setQaItems(prevState => {
      const newItems = [...prevState];
      newItems
        .splice(newIndex, 0, newItems.splice(oldIndex, 1)[0])
        .forEach((item, index) => {
          item.order = index;
        });

      return newItems;
    });
  };

  const onSubmit = e => {
    e.preventDefault();
    console.log('value', e.target.value);
  };

  const addItem = (it, index) => {
    const item = {...it, order: qaItems?.length};
    setQaItems(prevState => [...prevState, item]);
    setOtherQaItems(prev => prev.filter((i, ind) => ind !== index));
  };
  const removeItem = (it, index) => {
    const item = {...it, order: qaItems?.length};
    setOtherQaItems(prevState => [...prevState, item]);

    setQaItems(prev => prev.filter((i, ind) => ind !== index));
  };
  const updateImageSrc = (index, newSrc) => {
    setQaItems(prevItems => {
      const updatedItems = [...prevItems];

      updatedItems[index].image.src = newSrc;
      return updatedItems;
    });
  };
  const updateTitle = (index, newTitle) => {
    setQaItems(prevItems => {
      const updatedItems = [...prevItems];

      updatedItems[index].title = newTitle;
      return updatedItems;
    });
  };
  const onCloseModal = (e, set_is_open) => {
    set_is_open(false);

    if (isEditQuickAccessLoading) return;

    let param_data = Object.assign({
      qaItems: qaItems,
      qaOtherItems: otherQaItems,
    });

    patchBaseEditQuickAccess(param_data);
  };

  return (
    <Transition.Root show={is_open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={e => onCloseModal(e, set_is_open)}>
        <div className="fixed inset-0" />
        <ModalBackdrop />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full">
                <Dialog.Panel
                  className="pointer-events-auto w-screen max-w-md"
                  style={{maxWidth: 640}}>
                  <div className="flex h-full flex-col overflow-y-scroll bg-[#FBF8FF] shadow-xl">
                    <div className="p-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                          Edit Quick Access
                        </Dialog.Title>

                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-white text-pink-400 hover:text-pink-500 focus:ring-2 focus:ring-pink-500"
                            onClick={e => onCloseModal(e, set_is_open)}>
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-1 mb-6">
                        <p className="text-sm text-black-300">
                          Drag items to set order
                        </p>
                      </div>
                      <div>
                        <SortableContainer
                          onSortEnd={onSortEnd}
                          hideSortableGhost={false}
                          axis={'y'}
                          lockAxis={'y'}
                          helperClass="opacity-100 z-10"
                          pressThreshold={20}
                          pressDelay={200}>
                          {qaItems?.map((item, index) => (
                            <SortableItem
                              key={`item-${index}`}
                              index={index}
                              item={item}
                              onClickDelete={() => removeItem(item, index)}
                              onAddImage={im => updateImageSrc(index, im[0])}
                              onUpdateTitile={i => updateTitle(index, i)}
                            />
                          ))}
                        </SortableContainer>

                        {otherQaItems.length > 0 && (
                          <div class="grid grid-cols-2 gap-3 md:grid-cols-3 lg:mt-0 lg:grid-cols-2">
                            {otherQaItems.map((item, index) => (
                              <div
                                onClick={() => addItem(item, index)}
                                key={index}
                                class="cursor-pointer col-span-1 justify-center flex items-center sm:flex-col px-1.5 py-1.5 sm:px-8 sm:py-8 rounded-lg bg-white text-xs font-semibold text-gray-900 border-slate-200 border hover:bg-gray-50">
                                <img
                                  className="sm:h-12 sm:w-12 w-8 h-8 object-contain hub-access__img"
                                  src={item?.image.src}
                                  alt="Transistor"
                                />

                                <div className="sm:mt-2 mt-0 flex flex-auto flex-col-reverse">
                                  <h3 className="sm:text-sm text-xs font-normal sm:font-semibold text-gray-900 sm:text-center text-left sm:ml-0 ml-2">
                                    {item.title}
                                  </h3>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default QuickAccessModal;
