import React from 'react';

// const LightBox = ({children, isOpened, onClose}) => {
//   return isOpened ? (
//     <div className="light-box">
//       <span className="light-box__overlay" onClick={onClose} />
//       <div className="light-box__body">{children}</div>
//       <button className="light-box__close" onClick={onClose}>
//         <img src="/images/assets/close-icon.svg" alt="close icon" />
//       </button>
//     </div>
//   ) : null;
// };

import {Fragment} from 'react';
import {Dialog, Transition} from '@headlessui/react';

const LightBox = ({isOpened, onClose, children}) => {
  return (
    <Transition.Root show={isOpened} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg  bg-transparent md:px-[70px] pb-4 pt-5 text-left  transition-all ">
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default LightBox;
