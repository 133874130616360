import React, { useEffect, useState } from 'react';

import BackBtnWithTitlePage from '../../../../components/reusable/btns/backBtn/BackBtnWithTitlePage';
import ReviewsRating from './ReviewsRating';
import { reviewsPremiumData } from '../../../../mock-data/reviews-premium/reviewsPremiumData';
import { calculateAverageRating } from './utils/calculateAverageRating';
import { calculateRatingDistribution } from './utils/calculateRatingDistribution';
import ReviewsList from './ReviewsList';

import Button from '../../../../components/reusable/btns/buttons/Button';
import { useDispatch } from 'react-redux';
import { openModal } from '../../../../store/slices/modalSlice/modalSlice';
import MainLayoutContent from '../../../../components/layouts/mainLayout/mainLayoutContent/MainLayoutContent';
import AddComment from '../items/comments/AddComment';
import MainLayout from '../../../../components/layouts/mainLayout/MainLayout';
import MainLayoutV2 from '../../../../components/layouts/mainLayout/MainLayoutV2';
import MainLayoutContentV2 from '../../../../components/layouts/mainLayout/mainLayoutContentV2/MainLayoutContentV2';

const PremiumContentReviewsPage = () => {
  const [isTrainer, setIsTrainer] = useState(false);
  const [reviewsData, setReviewsData] = useState([]);

  // todo: change user to user's profile data
  const [user, setUser] = useState({
    nickname: 'janedoe',
    name: 'John Doe',
    position: 'Elite creator',
    isVerified: true,
    avatar: {
      src: '/images/post/mock/jane.jpg',
    },
  });

  useEffect(() => {
    setReviewsData(reviewsPremiumData);
  }, []);

  const dispatch = useDispatch();

  const handleOpenModal = () => {
    dispatch(
      openModal({
        modalName: 'RatingCourseModal',
        modalType: 'swipe-review',
        modalProps: { user },
      }),
    );
  };

  const averageRating = calculateAverageRating(reviewsData);
  const ratingDistribution = calculateRatingDistribution(reviewsData);

  return (
    <MainLayoutV2>
      <MainLayoutContentV2 iscenter>
        <BackBtnWithTitlePage btnText="Back to post" title={'Reviews'} />
        <ReviewsRating
          reviewsQuantity={reviewsData.length}
          averageRating={averageRating}
          ratingDistribution={ratingDistribution}
        />

        <ReviewsList
          reviewsQuantity={reviewsData.length}
          reviewsData={reviewsData}
          isTrainer={isTrainer}
        />

        {isTrainer ? (
          <AddComment userAvatar={user?.avatar} />
        ) : (
            <Button
              style={{ width: "100%", maxWidth: 420, minheight: 40 }}
              className="button-leave-rating _fw button_primary mx-auto d-block !p-2"
              onClick={handleOpenModal}>
              Leave a rating
            </Button>
          )}
      </MainLayoutContentV2>
    </MainLayoutV2>
  );
};

export default PremiumContentReviewsPage;
