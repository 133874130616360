import { widthPercentageToDP as wp } from 'react-native-responsive-screen';
import { IS_IPHONE_X } from '../../../constants.ts';
import styled from 'styled-components/native';
import { colors } from '../../../res/colors';
import { fonts } from '../../../res/fonts';

const Container = styled.Pressable`
  align-items: center;
`;
const MenuIcon = styled.Image`
  width: 2rem;
  height: 2rem;
  margin-top: 0.7rem;
  tint-color: ${props => props.focused ? colors.PRIMARY_COLOR : colors.REGULAR_TEXT_COLOR};
`;

const MenuTitle = styled.Text`
  text-align: center;
  align-self: center;
  font-family: ${fonts.REGULAR};
  font-size: 1rem;
  color: ${colors.REGULAR_TEXT_COLOR};
  font-size: ${props => (props.focused ? '1.1rem' : '1rem')};
  font-family: ${props => (props.focused ? fonts.MEDIUM : fonts.REGULAR)};
  margin-vertical: 0.3rem;
  color: ${props =>
    props.focused ? colors.PRIMARY_COLOR : colors.REGULAR_TEXT_COLOR};
`;

export { Container, MenuIcon, MenuTitle };
