import { ChatBubbleBottomCenterIcon, ShoppingBagIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useDispatch } from 'react-redux';
import CustomLink from '../../../../../../components/reusable/btns/links/CustomLink';
import { closeModal, openModal } from '../../../../../../store/slices/modalSlice/modalSlice';

const UserBtnsRowDesktop = () => {

  const dispatch = useDispatch();
  const onUnderDevelopment = () => {
    dispatch(
      openModal({
        modalName: 'renderAlert',
        modalType: 'alert',

        modalProps: {
          nameAlert: 'constructionAlert',
          propsAlert: {
            closeModal: () => { dispatch(closeModal()) },
          },
        },
      }),
    );
  };


  return (
    <div className="mt-4 profile-user__btns-row_d">
      <CustomLink className="_fw text-sm button_secondary button_s">
        Follow
      </CustomLink>

      <CustomLink onClick={() => { onUnderDevelopment() }} className="_fw text-sm button_secondary button_s">
        Subscribe
      </CustomLink>
      <CustomLink onClick={() => { onUnderDevelopment() }} className="_fw text-sm button_secondary button_s">
        <ChatBubbleBottomCenterIcon className="h-4 w-4 mr-1" style={{ color: '#e536af' }} aria-hidden="true" />
      Message
      </CustomLink>
      <CustomLink className="_fw text-sm button_secondary button_s" >
        <ShoppingBagIcon className="h-4 w-4 mr-1" style={{ color: '#e536af' }} aria-hidden="true" />
        Shop
      </CustomLink>
    </div>
  );
};

export default UserBtnsRowDesktop;
