import React from 'react';
import AssetsCard from './assetsCard/AssetsCard.jsx';

const AssetsCards = ({list = [], onClickItem}) => {
  if (list?.length === 0) return null;

  return (
    <div className="assets-card__list">
      {list.map((item, index) => (
        <AssetsCard
          key={item?.id || index + 'assets-card'}
          title={item?.title}
          img={item?.image}
          onClick={() => onClickItem(item)}
        />
      ))}
    </div>
  );
};

export default AssetsCards;
