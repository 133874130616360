/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useRef} from 'react';
import {View, FlatList, TouchableOpacity, Platform} from 'react-native';
import {BackHeader} from '../../../../components/BackHeader';
import {GlobalFlex} from '../../../../res/globalStyles';
import styles, {HeaderShadowLine, BorderContainer, Text} from './style';
import {Spacer} from '../../../../res/spacer';
import {fonts} from '../../../../res/fonts';
import {colors} from '../../../../res/colors';
import {GET_DATA} from '../../../../api_helper/ApiServices';
import {ACTIVITIES} from '../../../../api_helper/Api';

import deviceInfoModule from 'react-native-device-info';
// import DatePicker from 'react-native-date-picker';
import moment from 'moment';
import {heightPercentageToDP as hp, widthPercentageToDP as wp} from 'react-native-responsive-screen';
import Loading from '../../../../components/Loading.tsx';
import {useBuzzmiAuth} from "../../../../appState/hooks";


const WalletActivityScreen = props => {
  // const { title } = props?.route?.params;
  const {userData} = useBuzzmiAuth();
  const {wallet_type} = props.route.params;
  const [isLoading, setIsLoading] = useState(false);
  const refTodayDate = useRef(new Date());
  // const todayDatePlusOneMinute = new Date(new Date().getTime() + 60 * 1000);
  const todayDatePlusOneMinute = new Date();
  const [openDatePicker, setOpenDatePicker] = useState(false);

  const [endTs, setEndTs] = useState(refTodayDate?.current.addDays(6));
  const [startTs, setStartTs] = useState(todayDatePlusOneMinute);


  const [walletData, setWalletData] = useState([]);
  const refStartTs = useRef(refTodayDate?.current);
  const refSelection = useRef(1);
  const [selection, setSelection] = useState(1);

  //const WALLET_TYPE = ["Gifted Diamonds", "Gems", "Buzzcoin"]

  useEffect(() => {
    getWalletActivityList(startTs, endTs);
  }, [userData]);

  const getWalletActivityList = async (starDatetTs, endDateTs) => {
    setIsLoading(true);
    const uniqueID = await deviceInfoModule.getUniqueId();
    console.log(starDatetTs);
    //console.log(moment(starDatetTs).add(moment.duration("23:59:59").toISOString))
    console.log(ACTIVITIES + `?startDate=${moment(starDatetTs).toISOString()}&endDate=${moment(endDateTs).toISOString()}&wallet-type=${wallet_type}`);
    GET_DATA(ACTIVITIES + `?startDate=${moment(starDatetTs).toISOString()}&endDate=${moment(endDateTs).toISOString()}&wallet-type=${wallet_type}`, true, userData?.token, uniqueID, (data) => {
      setWalletData(data);
      setIsLoading(false);
    });
  };

  return (
    <GlobalFlex>
      <View>
        <BackHeader
          is_center_text
          title={'Wallet Activity'}
          onBackPress={() => props.navigation.goBack()}
        />
        <Spacer space={hp(1.5)}/>
        <HeaderShadowLine/>
      </View>

      {/*<DatePicker
        modal
        mode={'date'}
        open={openDatePicker}
        date={refSelection.current == 1 ? new Date(startTs) : new Date(endTs)}
        onConfirm={date => {
          console.log(' ======= date');
          // console.log(moment(date).add(moment.duration("00:00:00").format('MM/DD/YYYY hh:mm A')))
          // console.log(moment(moment(date).toObject()).format())

          if (refSelection.current === 1) {
            setStartTs(date);
            getWalletActivityList(date, endTs);
          } else {
            setEndTs(date);
            getWalletActivityList(startTs, date);
          }
          setOpenDatePicker(false);
        }}
        onCancel={() => setOpenDatePicker(false)}
        // minimumDate={refSelection.current == 1 ? new Date() : new Date(startTs)}
        // maximumDate={refSelection.current == 1 ? new Date(endTs) : new Date(startTs).addDays(6)}
      />*/}


      <View style={styles.rowItemContainerStyle}>

        <View style={styles.timeChipsContainerStyle}>
          <TouchableOpacity
            onPress={() => {
              setSelection(() => {
                refSelection.current = 1;
                return 1;
              });
              refTodayDate.current = new Date();
              setTimeout(() => {
                document.getElementById('startdatepicker').showPicker()
              }, 350);
            }}
            style={styles.dateContainerStyle}>
            <Text>{moment(startTs).format('DD-MMM-YYYY')}</Text>
          </TouchableOpacity>
        </View>

        <Text style={[{alignSelf: 'center'}]}>{'To'}</Text>

        <View style={styles.timeChipsContainerStyle}>
          <TouchableOpacity
            onPress={() => {
              setSelection(() => {
                refSelection.current = 2;
                return 2;
              });
              refTodayDate.current = new Date();
              setTimeout(() => {
                document.getElementById('enddatepicker').showPicker()
              }, 350);
            }}
            style={styles.dateContainerStyle}>
            <Text>{moment(endTs).format('DD-MMM-YYYY')}</Text>
          </TouchableOpacity>

        </View>
      </View>


      <View style={{...styles.rowItemContainerStyle, paddingVertical: 0}}>

        <View style={{opacity: 0, alignSelf: "center", height: 0}}>
          <div class="input-group date">
            <input style={{alignSelf: "center"}} type="date" onChange={() => {
              if (refSelection.current === 1) {
                setStartTs(document.getElementById('startdatepicker').value);
                getWalletActivityList(document.getElementById('startdatepicker').value, endTs);
              } else {
                setEndTs(document.getElementById('enddatepicker').value);
                getWalletActivityList(startTs, document.getElementById('enddatepicker').value);
              }
            }} id="startdatepicker"/>
          </div>
        </View>


        <View style={{opacity: 0, alignSelf: "center", height: 0}}>
          <div class="input-group date">
            <input style={{alignSelf: "center"}} type="date" onChange={() => {
              if (refSelection.current === 1) {
                setStartTs(document.getElementById('startdatepicker').value);
                getWalletActivityList(document.getElementById('startdatepicker').value, endTs);
              } else {
                setEndTs(document.getElementById('enddatepicker').value);
                getWalletActivityList(startTs, document.getElementById('enddatepicker').value);
              }
            }} id="enddatepicker"/>
          </div>
        </View>
      </View>
      <BorderContainer/>
      {walletData.length > 0 ?
        <>
          <FlatList
            showsVerticalScrollIndicator={false}
            data={walletData}
            renderItem={({item}) => (
              <>
                <Spacer space={hp(0.2)}/>
                <View
                  style={{
                    flexDirection: 'row',
                    alignSelf: "center",
                    paddingVertical: hp(2),
                    paddingHorizontal: hp(4),
                    width: Platform.OS != 'web' ? wp(90) : hp(100)
                  }}>
                  <View style={{width: Platform.OS != 'web' ? wp(90) : hp(90)}}>
                    <Text style={styles.postTitle}>{'Points : '} <Text style={{
                      fontFamily: fonts.BOLD,
                      fontSize: hp(2.2),
                      color: item?.trasactionType == 'Credited' ? colors.DARK_GREEN : colors.DARK_RED
                    }}>{item?.trasactionType == 'Credited' ? '+ ' : '- '}{item?.amount}</Text></Text>
                    <Text style={styles.postTitle}>{'Currency : '} <Text
                      style={{fontFamily: fonts.REGULAR, fontSize: hp(2.2)}}>{item?.currency}</Text></Text>
                    <Text style={styles.postTitle}>{'Awarded On : '} <Text style={{
                      fontFamily: fonts.REGULAR,
                      fontSize: hp(2.2)
                    }}>{moment(item?.modifiedOn).format('DD-MMM-YYYY')}</Text>
                    </Text>
                    <Text style={styles.postTitle}>{'Awarded Type : '} <Text
                      style={{fontFamily: fonts.REGULAR, fontSize: hp(2.2)}}>{item?.behaviour}</Text></Text>
                  </View>
                </View>
                <Spacer space={hp(0.2)}/>
                <BorderContainer/>
              </>
            )}
          />
          <Spacer space={hp(2)}/>
        </>
        :
        <View style={{flex: 1, JustifyContent: 'center'}}>
          <Text style={{alignSelf: 'center'}}>{'No activity Found'}</Text>
        </View>
      }
      <Loading visible={isLoading}/>
    </GlobalFlex>
  );
};
export default WalletActivityScreen;
