import React, {useState} from 'react';
// import {Link} from 'react-router-dom';
import {Link} from '@react-navigation/native';
import CommentsIcon from '../../icons/CommentsIcon';
import useWindowSize from '../../../../../../hooks/useWindowSize';
import {useDispatch} from 'react-redux';
import {openModal} from '../../../../../../store/slices/modalSlice/modalSlice';
// import { useNavigation } from '@react-navigation/core';
import {useEventEmitter} from '../../../../../../hooks/useEventEmitter';
import CommentsModal from '../../../ProfileMain/componentsV2/CommentsModal';

const CommentsReelBtnV2 = ({reel, isFromPost}) => {
  const [openCommentModal, setOpenCommentssModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const {width} = useWindowSize();
  const dispatch = useDispatch();
  // const navigation = useNavigation();
  const [refreshContent, setRefreshContent] = useState(null);

  const {user, comments} = reel?.object || {};

  useEventEmitter('post_comments_length', data => setRefreshContent(data));

  const handleMouseEnter = () => setIsHovered(true);

  const handleMouseLeave = () => setIsHovered(false);

  const openCommentsModal = e => {
    // console.log(reel)
    // if (width < 767) {
    //   e.preventDefault();
    //   dispatch(
    //     openModal({
    //       modalName: 'CommentsModal',
    //       modalType: 'swipe',
    //       modalProps: {
    //         comments: comments,
    //         userAvatar: user?.profile?.picture,
    //         entity: "reel",
    //         id: reel?.id
    //       },
    //     }),
    //   );
    //   return;
    // }
    // navigation.navigate('CommentsScreen', { id: reel?.id, entity: 'reel' });
  };

  return (
    <>
      {/* <Link
        to={`/profile/reel/${reel?.id}/comments`}
        onClick={e => {
          e.preverntDefault();
          setOpenCommentssModal(() => true);
        }}> */}
      <div
        onClick={e => {
          e.preventDefault();
          setOpenCommentssModal(() => true);
        }}
        className="reels__buttons-list-button"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        // onClick={openCommentsModal}
      >
        <CommentsIcon isHovered={isHovered} />
      </div>
      {/* </Link> */}
      <p
        className="reels__buttons-list-text"
        style={{color: isFromPost ? '#000' : '#FFF'}}>
        {/* {refreshContent && refreshContent?.post_id == reel?.id
          ? refreshContent?.data?.length
          : reel?.object?.comments?.length} */}
        {reel?.replies?.nodes.length}
      </p>
      <CommentsModal
        data={{entity: 'reel', id: reel?.id}}
        open={openCommentModal}
        setOpen={setOpenCommentssModal}
      />
    </>
  );
};

export default CommentsReelBtnV2;
