import React from "react"
import MainLayout from "../../../../components/layouts/mainLayout/MainLayout.jsx"
import BackBtnWithTitlePage from "../../../../components/reusable/btns/backBtn/BackBtnWithTitlePage.jsx"
import SmallCardDispatcher from "../../../../components/reusable/cards/smallCard/SmallCardDispatcher.jsx"
import { tasksData } from "../../../../mock-data/hub/hub.js"


const FinishAccountSetup = () => {
  return (
    <MainLayout>
      <div className="hub">
        <div className="hub__content">
          <BackBtnWithTitlePage title="Finish your account setup" />
          <div className="present-preview__cards">
            <SmallCardDispatcher list={tasksData} />
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default FinishAccountSetup
