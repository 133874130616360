import React, { useEffect, useState } from 'react';
import EditProfileBoxForm from '../EditProfileBoxForm';
import ProfileContactsInfo from './items/profileContactsInfo/ProfileContactsInfo';

import Input from '../../../../../../components/reusable/form/items/inputs/Input';
import CustomSelect from '../../../../../../components/reusable/form/select/customSelect/CustomSelect';
import DateInput from '../../../../../../components/reusable/form/items/inputs/dateInputs/DateInput';

import Checkbox from '../../../../../../components/reusable/assets/checkbox/Checkbox';
import useWindowSize from '../../../../../../hooks/useWindowSize';
import { useBuzzmiAuth } from '../../../../../../appState/hooks/useBuzzmiAuth/index.web';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../../../../../store/slices/modalSlice/modalSlice';
import { useQrData } from '../../../../../../appState/hooks/useQrData';

const gender = [
  {
    label: 'Man',
    value: 'man',
  },
  {
    label: 'Woman',
    value: 'woman',
  },
];

const placeholder = {
  desktopPlaceholder: {
    university: 'Start to enter your University name',
    college: 'Start to enter your College name',
    organization: 'Start to enter your Working organization name',
  },
  mobilePlaceholder: {
    university: 'University',
    college: 'College',
    organization: 'Working organization',
  },
};

const displayPlaceholder = (isDesktop, inputName) =>
  isDesktop
    ? placeholder.desktopPlaceholder[inputName]
    : placeholder.mobilePlaceholder[inputName];

const EditProfileAccountInformation = ({
  register,
  values,
  setValue,
  paddingMode,
}) => {

  const [selectedGender, setSelectedGender] = useState({
    label: 'Man',
    value: 'man',
  });

  const { width } = useWindowSize();
  const { userData } = useBuzzmiAuth();
  const dispatch = useDispatch();
  const { qrInfo, getQrInfoDetail } = useQrData();


  const { isOpen } = useSelector(state => state.modal);

  const [twoFactorAuth, setTwoFactorAuth] = useState(userData?.twoFactorAuthEnabled);

  useEffect(() => {
    getQrInfoDetail(userData?.id, userData?.token);
    setValue('university', userData?.university);
    setValue('college', userData?.college);
    setValue('organization', userData?.workOrganization);
    setSelectedGender(userData?.gender == 'M' ? gender[0] : gender[1]);
  }, [userData]);


  useEffect(() => {
    if (isOpen == false) {
      setTwoFactorAuth(userData?.twoFactorAuthEnabled)
    }
  }, [isOpen])


  const handlerQrCode = () => {
    dispatch(
      openModal({
        modalName: 'QrCodeModal',
        modalType: 'swipe',
        callback: code => {

        },
      }),
    );
  };


  const handlerDisableQrCode = () => {
    dispatch(
      openModal({
        modalName: 'QrDisableModal',
        modalType: 'swipe',
        callback: code => {

        },
      }),
    );
  };

  return (
    <EditProfileBoxForm
      paddingMode={paddingMode}
      title="Account information and settings"
      className="profile-settings">
      <ProfileContactsInfo
        values={values}
        register={register}
        setValue={setValue}
      />
      <div className="d-flex justify-content-between align-items-center profile-settings__checkbox">
        <div>2FA authentification</div>
        <Checkbox
          is2fa
          on2FAClick={() => { userData?.twoFactorAuthEnabled ? handlerDisableQrCode() : handlerQrCode() }}
          setState={setTwoFactorAuth}
          defaultValue={userData?.twoFactorAuthEnabled}
          idChecked={"2FA"}

        />
      </div>
      <CustomSelect
        className="select__secondary profile-settings__gender"
        placeholder="Gender"
        options={gender}
        selected={selectedGender}
        setSelected={val => {
          setSelectedGender(val), (global.EGender = val);
        }}
      />
      <DateInput setValue={() => { }} placeholder="Date of Birth" />
      <Input
        label="University"
        type="text"
        name="university"
        placeholder={displayPlaceholder(width > 768, 'university')}
        register={register}
      />
      <Input
        label="College"
        type="text"
        name="college"
        placeholder={displayPlaceholder(width > 768, 'college')}
        register={register}
      />
      <Input
        label="Working organization"
        type="text"
        name="organization"
        placeholder={displayPlaceholder(width > 768, 'organization')}
        register={register}
      />
    </EditProfileBoxForm>
  );
};

export default EditProfileAccountInformation;
