const IconAssets = {
  ic_sort_follow: require('./ic_sort_follow.png'),
  ic_note_big: require('./ic_note_big.png'),
  ic_settings_big: require('./ic_settings_big.png'),
  ic_calendar_big: require('./ic_calendar_big.png'),
  ic_check_puzzle: require('./ic_check_puzzle.png'),
  ic_delete_checklist: require('./ic_delete_checklist.png'),
  ic_buzzmi_target: require('./ic_buzzmi_target.png'),
  ic_film_cut: require('./ic_film_cut.png'),
  ic_buzzmi_coin: require('./ic_buzzmi_coin.png'),
  ic_warning_red: require('./ic_warning_red.png'),
  ic_shield_green: require('./ic_shield_green.png'),
  ic_big_star: require('./ic_big_star.png'),
  ic_three_stars: require('./ic_three_stars.png'),
  ic_entertain_laptop: require('./ic_entertain_laptop.png'),
  ic_dimond_blue: require('./ic_dimond_blue.png'),
  ic_three_cards: require('./ic_three_cards.png'),
  ic_free_member: require('./ic_free_member.png'),
  ic_offer_banner: require('./ic_offer_banner.png'),
  ic_vec_up: require('./ic_vec_up.png'),
  ic_plus_day: require('./ic_plus_day.png'),
  ic_check_day: require('./ic_check_day.png'),
  ic_clock_alarm: require('./ic_clock_alarm.png'),
  ic_goal_vec: require('./ic_goal_vec.png'),
  ic_repeat_vec: require('./ic_repeat_vec.png'),
  ic_vec_down: require('./ic_vec_down.png'),
  ic_fancy_calendar: require('./ic_fancy_calendar.png'),
  ic_yoga_checklist: require('./ic_yoga_checklist.png'),
  ic_yoga_tags: require('./ic_yoga_tags.png'),
  ic_yoga_photos: require('./ic_yoga_photos.png'),
  ic_yoga_note: require('./ic_yoga_note.png'),
  ic_user_4: require('./ic_user_4.png'),
  ic_user_3: require('./ic_user_3.png'),
  ic_user_2: require('./ic_user_2.png'),
  ic_user_1: require('./ic_user_1.png'),
  ic_leader_details: require('./ic_leader_details.png'),
  ic_blue_tick: require('./ic_blue_tick.png'),
  ic_rookie: require('./ic_rookie.png'),
  ic_social_butterfly: require('./ic_social_butterfly.png'),
  ic_lead_pic_1: require('./ic_lead_pic_1.png'),
  ic_lead_pic_2: require('./ic_lead_pic_2.png'),
  ic_lead_pic_3: require('./ic_lead_pic_3.png'),
  ic_leaderboard_5: require('./ic_leaderboard_5.png'),
  ic_leaderboard_4: require('./ic_leaderboard_4.png'),
  ic_leaderboard_3: require('./ic_leaderboard_3.png'),
  ic_leaderboard_2: require('./ic_leaderboard_2.png'),
  ic_leaderboard_1: require('./ic_leaderboard_1.png'),
  ic_leaderboard_cup: require('./ic_leaderboard_cup.png'),
  ic_next_week: require('./ic_next_week.png'),
  ic_prev_week: require('./ic_prev_week.png'),
  ic_treadmill_plan: require('./ic_treadmill_plan.png'),
  ic_leaderboard_banner: require('./ic_leaderboard_banner.png'),
  ic_dismiss_cross: require('./ic_dismiss_cross.png'),
  ic_cn_flag: require('./ic_cn_flag.png'),
  ic_en_flag: require('./ic_en_flag.png'),
  ic_es_flag: require('./ic_es_flag.png'),
  ic_pl_flag: require('./ic_pl_flag.png'),
  ic_ng_flag: require('./ic_ng_flag.png'),
  ic_in_flag: require('./ic_in_flag.png'),
  ic_id_flag: require('./ic_id_flag.png'),
  ic_gb_flag: require('./ic_gb_flag.png'),
  ic_dropdown_arrow: require('./ic_dropdown_arrow.png'),
  ic_right_arrow: require('./ic_right.png'),
  ic_dots_vertical: require('./dots_vertical.png'),
  ic_check_circle: require('./ic_check_circle.png'),
  ic_close_modal: require('./ic_close_modal.png'),
  ic_back_grad: require('./ic_back_grad.png'),
  bgGradientShadowBtn: require('./bg_gradient_shadow_btn.png'),
  bgGradientInputBorder: require('./bg_gradient_input_border.png'),
  icHelpInfo: require('./ic_help_info.png'),
  bgGradientHeader: require('./bg_gradient_header.png'),
  icHeaderLogo: require('./ic_header_logo.png'),
  btnAudioOff: require('./btnAudioOff.png'),
  btnAudioOffDisabled: require('./btnAudioOffDisabled.png'),
  btnAudioOffSelected: require('./btnAudioOffSelected.png'),
  btnBluetooth: require('./btnBluetooth.png'),
  btnBluetoothDisabled: require('./btnBluetoothDisabled.png'),
  btnBluetoothSelected: require('./btnBluetoothSelected.png'),
  btnCallChangeAudio: require('./btnCallChangeAudio.png'),
  ic_settings: require('./settings.png'),
  btnCallChangeVideo: require('./btnCallChangeVideo.png'),
  btnCallDecline: require('./btnCallDecline.png'),
  btnCallEnd: require('./btnCallEnd.png'),
  btnCallVideo: require('./btnCallVideo.png'),
  btnCallVideoAccept: require('./btnCallVideoAccept.png'),
  btnCallVideoDisabled: require('./btnCallVideoDisabled.png'),
  btnCallVideoPressed: require('./btnCallVideoPressed.png'),
  btnCallVideoTertiary: require('./btnCallVideoTertiary.png'),
  btnCallVideoTertiaryDisabled: require('./btnCallVideoTertiaryDisabled.png'),
  btnCallVideoTertiaryPressed: require('./camera_ic.png'),
  btnCallVideoFillTertiaryPressed: require('./camera_fill.png'),
  btnCallVoice: require('./btnCallVoice.png'),
  btnCallVoiceAccept: require('./btnCallVoiceAccept.png'),
  btnCallVoiceDisabled: require('./btnCallVoiceDisabled.png'),
  btnCallVoicePressed: require('./btnCallVoicePressed.png'),
  btnCallVoiceTertiary: require('./btnCallVoiceTertiary.png'),
  btnCallVoiceTertiaryDisabled: require('./btnCallVoiceTertiaryDisabled.png'),
  btnCallVoiceTertiaryPressed: require('./call_ic.png'),
  ic_call_outline: require('./ic_call_outline.png'),
  btnCallVoiceTertiaryfillPressed: require('./call_fill.png'),
  ic_verified_green: require('./ic_verified_green.png'),
  ic_two_factor: require('./ic_two_factor.png'),
  ic_email_new: require('./ic_email_new.png'),
  ic_not_verified_yellow: require('./ic_not_verified_yellow.png'),
  btnCameraFlipIos: require('./btnCameraFlipIos.png'),
  btnMoreNor: require('./btnMoreNor.png'),
  btnSpeaker: require('./btnSpeaker.png'),
  btnSpeakerDisabled: require('./btnSpeakerDisabled.png'),
  btnSpeakerSelected: require('./btnSpeakerSelected.png'),
  btnVideoOff: require('./btnVideoOff.png'),
  btnVideoOffDisabled: require('./btnVideoOffDisabled.png'),
  btnVideoOffSelected: require('./btnVideoOffSelected.png'),
  Call: require('./icCall.png'),
  CallFilled: require('./icCallFilled.png'),
  CallFilled_2: require('./icCallFilled_2.png'),
  CallFilled_3: require('./icCallFilled_3.png'),
  ic_done: require('./ic_done.png'),
  Done: require('./icDone.png'),
  Done_2: require('./icDone_2.png'),
  LogoHorizontal: require('./icLogoHorizontal.png'),
  LogoSymbol: require('./icLogoSymbol.png'),
  LogoSymbolInverse: require('./icLogoSymbolInverse.png'),
  Sendbird: require('./icSendbird.png'),
  User: require('./icUser.png'),
  ic_plus: require('./ic_plus.png'),
  VideoThumbnailFilled: require('./icVideoThumbnailFilled.png'),
  VideoThumbnailFilled_2: require('./icVideoThumbnailFilled_2.png'),
  VideoThumbnailFilled_3: require('./icVideoThumbnailFilled_3.png'),
  AudioOff: require('./iconAudioOff.png'),
  Avatar: require('./iconAvatar.png'),
  ic_avatar: require('./avatar.png'),
  Back: require('./iconBack.png'),
  Bluetooth: require('./iconBluetooth.png'),
  CallHistory: require('./iconCallHistory.png'),
  CallVideoFilled: require('./iconCallVideoFilled.png'),
  CallVideoIncomingFilled: require('./iconCallVideoIncomingFilled.png'),
  CallVideoOutgoingFilled: require('./iconCallVideoOutgoingFilled.png'),
  CallVoice: require('./iconCallVoice.png'),
  CallVoiceFilled: require('./iconCallVoiceFilled.png'),
  CallVoiceIncomingFilled: require('./iconCallVoiceIncomingFilled.png'),
  CallVoiceOutgoingFilled: require('./iconCallVoiceOutgoingFilled.png'),
  CameraFlipIos: require('./iconCameraFlipIos.png'),
  CheckboxOff: require('./iconCheckboxOff.png'),
  CheckboxOn: require('./iconCheckboxOn.png'),
  Copy: require('./iconCopy.png'),
  Decline: require('./iconDecline.png'),
  Info: require('./iconInfo.png'),
  Join: require('./iconJoin.png'),
  Leave: require('./iconLeave.png'),
  RoomAdd: require('./iconRoomAdd.png'),
  Rooms: require('./iconRooms.png'),
  RoomsFilled: require('./iconRoomsFilled.png'),
  Settings: require('./iconSettings.png'),
  SettingsFilled: require('./iconSettingsFilled.png'),
  ShevronRight: require('./iconShevronRight.png'),
  Speaker: require('./iconSpeaker.png'),
  SpinnerLarge: require('./iconSpinnerLarge.png'),
  VideoOff: require('./iconVideoOff.png'),
  chatOn: require('./chat_ic.png'),
  chatFillOn: require('./chat_fill.png'),
  PhoneMissed: require('./bxs_phone-outgoing.png'),
  fbLogin: require('./ic_fb_login.png'),
  googleLogin: require('./ic_google_login.png'),
  appleLogin: require('./apple_ic.png'),
  subtract_ic: require('./subtract_ic.png'),
  ic_one_say_sign: require('./ic_one_say_sign.png'),
  ic_cancel: require('./ic_cancel.png'),
  ic_add_operator: require('./ic_add_operator.png'),
  ic_calendar: require('./ic_calendar.png'),
  ic_smile_emoji: require('./ic_smile_emoji.png'),
  ic_camera: require('./ic_camera.png'),
  ic_checkbox_unfilled: require('./ic_checkbox_unfilled.png'),
  ic_checkbox_filled: require('./ic_checkbox_filled.png'),
  diamond: require('./membership/diamond.png'),
  buzz_coin: require('./buzz_coin.png'),
  gems_coin: require('./gems.png'),
  ic_sync: require('./ic_sync.png'),
  ic_bin: require('./ic_bin.png'),
  ic_edit_plan: require('./ic_edit_plan.png'),
  ic_complete_plan: require('./ic_complete_plan.png'),
  ic_delete_plan: require('./ic_delete_plan.png'),

  plan1: require('./plan1.png'),
  plan2: require('./plan2.png'),
  plan3: require('./plan3.png'),
  QAchat: require('./quickAccess/chat.png'),
  QAbook: require('./quickAccess/book.png'),
  QAchallenges: require('./quickAccess/challenges.png'),
  QAshare: require('./quickAccess/share.png'),
  FA_prof_pic: require('./finishAccountSetup/acc_setup_profile_pic.png'),
  FA_ver_email: require('./finishAccountSetup/acc_setup_ver_email.png'),
  FA_ver_phone: require('./finishAccountSetup/acc_setup_ver_phone.png'),
  FA_two_factor: require('./finishAccountSetup/acc_setup_two_factor.png'),
  FA_def_payout: require('./finishAccountSetup/acc_setup_def_payout.png'),
  test_img: require('./ph_2.png'),
  ic_play_round: require('./ic_play_round.png'),
  image_upload_placeholder: require('./image_upload_placeholder.png'),
  image_upload_placeholder_1: require('./image_upload_placeholder_1.png'),
  audio_upload_placeholder: require('./audio_upload_placeholder.png'),
  audio_upload_placeholder_1: require('./audio_upload_placeholder_1.png'),
  video_upload_placeholder: require('./video_upload_placeholder.png'),
  Info_circle: require('./Info_circle.png'),
  board_check: require('./board_check.png'),
  ic_search: require('./ic_search.png'),
  ic_bin_2: require('./bin.png'),
  ic_minus_gradient: require('./ic_minus_gradient.png'),
  ic_plus_gradient: require('./ic_plus_gradient.png'),
  ic_drag: require('./ic_drag.png'),
  ic_availability_hub: require('./ic_availability_hub.png'),
  ic_notifications_hub: require('./ic_notifications_hub.png'),
  ic_account_hub: require('./ic_account_hub.png'),
  ic_diamond_black: require('./ic_diamond_black.png'),
  ic_buzz_black: require('./ic_buzz_black.png'),
  ic_filter: require('./ic_filter.png'),
  ic_tick_steps: require('./ic_tick_steps.png'),

  ic_it_flag: require('./ic_it_flag.png'),
  ic_sk_flag: require('./ic_sk_flag.png'),
  ic_youtube: require('./ic_youtube.png'),
  ic_calender_grad: require('./ic_calendar_gradient.png'),
  ic_trash_can: require('./ic_trash_can.png'),
  ic_clock_grad: require('./ic_clock_grad.png'),
  ic_grad_pencil: require('./ic_grad_pencil.png'),
  ic_add: require('./add_icon.png'),
};
export default IconAssets;
