import React from "react"

const ProfilePremiumContentCoins = ({ price }) => (
  <div className="profile-content__coins">
    <img src="/images/assets/coin.png" alt="coin" />
    <span>{price}</span>
    <span>BuzzMi Coins</span>
  </div>
)

export default ProfilePremiumContentCoins
