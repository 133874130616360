import {useAtom} from 'jotai';
import {userTagsList} from '../hubState';
import {getAcitivityTags, postAcitivityTags} from '../../../network/api/app';
import {useMutation} from 'react-query';
export const useTagsList = () => {
  const [tagsList, setTagsList] = useAtom(userTagsList);

  const getTagsList = async token => {
    try {
      let response = await getAcitivityTags({token});
      // console.log('response in tags', response);
      const allIndex = response.findIndex(item => item.tagName === 'All');

      // If 'All' exists and is not at the 0 index, move it to the 0 index
      if (allIndex !== -1 && allIndex !== 0) {
        const allObject = response.splice(allIndex, 1)[0]; // Remove 'All' from its current position
        response.splice(0, 0, allObject); // Insert 'All' at the 0 index
      }
      setTagsList(response);
    } catch (e) {
      console.log('error => ', e.message);
    }
  };
  const {
    mutate: mutatePostAcitivityTags,
    isLoading: isPostAcitivityTagsLoading,
    isError: isPostAcitivityTagsError,
    data: PostAcitivityTagsResponse,
    error: PostAcitivityTagsErrorResponse,
  } = useMutation(postAcitivityTags);

  const PostAcitivityTags = params => mutatePostAcitivityTags(params);

  return {
    tagsList,
    getTagsList,
    isPostAcitivityTagsLoading,
    isPostAcitivityTagsError,
    PostAcitivityTagsResponse,
    PostAcitivityTagsErrorResponse,
    PostAcitivityTags,
  };
};
