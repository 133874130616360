import {useAtom} from 'jotai';
import {userRechageAmounts} from '../hubState';
import {getUserRechageAmounts} from '../../../network/api/app';

export const useGetRechargeAmounts = () => {
  const [rechageAmounts, setRechageAmounts] = useAtom(userRechageAmounts);

  const getRechageAmounts = async (token, setLoading = () => {}, params) => {
    setLoading(true);
    try {
      const response = await getUserRechageAmounts(params)({token});
      setRechageAmounts(response);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log('error => ', e.message);
    }
  };

  return {
    getRechageAmounts,
    rechageAmounts,
  };
};
