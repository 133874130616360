/* eslint-disable react-hooks/exhaustive-deps */
// noinspection ES6CheckImport

import React, {useState, useEffect} from 'react';
import { ScrollView, Text, View, Pressable, Alert } from 'react-native';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import {LoadingSpinner, Palette} from '@sendbird/uikit-react-native-foundation';
import styles, {
  Button,
  CheckIcon,
  DeleteIcon,
  SelectedText,
  ItemTitleText,
  SelectedContainer,
} from './styles';
import {BackHeader} from '../../../../components/BackHeader';
import {GlobalFlex} from '../../../../res/globalStyles';
import {colors} from '../../../../res/colors';
import {Spacer} from '../../../../res/spacer';
import IconAssets from '../../../../assets/index.ts';
import moment from 'moment-timezone';
import Loading from '../../../../components/Loading.tsx';
import {
  ClearButton, ClearIcon,
  SearchBarContainer,
  SearchIcon,
  SearchInput,
} from '../../../../uikit-app/GroupChannelTabs/styles';
import { images } from '../../../../res/images';

export const SearchComponent = props => {
  const {onQuery} = props;
  const [query, setQuery] = useState('');

  useEffect(() => onQuery(query), [query]);

  return (
    <SearchBarContainer>
      <SearchIcon style={styles.searchIconStyle} source={images.search_ic} />
      <SearchInput
        value={query}
        placeholder={'Search'}
        placeholderTextColor={'#7b7d83'}
        onChangeText={val => setQuery(() => val)}
      />
      <ClearButton
        disabled={query.length <= 0}
        isActive={query.length > 0}
        onPress={() => setQuery(() => '')}>
        <ClearIcon source={images.iconDecline_3x} />
      </ClearButton>
    </SearchBarContainer>
  );
};

export const underDevelopment = (message?: string) =>
  alert(message || 'Under Development!');

const TimezonePicker = props => {
  const {currentTimezone, onTimezoneSelected} = props.route.params;
  const [timezoneList, setTimezoneList] = useState([]);
  const [selected, setSelected] = useState(currentTimezone);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState('');

  const fetchList = async () =>
    moment?.tz?.names().map(s => ({
      title: s,
      timeText: moment().tz(s).format('hh:mmA'),
      ui: isSelected => (
        <Button onPress={() => setSelected(() => s)}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            {isSelected === s && (
              <CheckIcon source={IconAssets.ic_verified_green} />
            )}
            <ItemTitleText>{s}</ItemTitleText>
          </View>
          <ItemTitleText>
            {moment().tz(s).format('hh:mmA')}
          </ItemTitleText>
        </Button>
      ),
    }));

  useEffect(() => {
    setTimeout(() =>
      fetchList().then(list => {
        setLoading(() => false);
        setTimezoneList(() => list);
      }, 200),
    );
  }, []);

  return (
    <GlobalFlex>
      <BackHeader
        isModal
        isRightText
        is_center_text
        rightText={'Save'}
        title={'Time zone'}
        onNextPress={() => {
          if (selected.length < 1) {
            underDevelopment('Please select timezone');
            return;
          }
          onTimezoneSelected(selected);
          props.navigation.goBack();
        }}
        nextTextStyle={{color: colors.PRIMARY_COLOR}}
        onBackPress={() => props.navigation.goBack()}
      />
      <Spacer space={hp(1.5)} />
      <SearchComponent onQuery={setQuery} />
      {selected.length > 0 && (
        <SelectedContainer>
          <SelectedText>{selected}</SelectedText>
          <Pressable onPress={() => setSelected(() => '')}>
            <DeleteIcon source={IconAssets.btnCallDecline} />
          </Pressable>
        </SelectedContainer>
      )}
      <Spacer space={hp(1)} />
      <ScrollView>
        {timezoneList
          .filter(d => d.title.toLowerCase().includes(query.toLowerCase()))
          .map(d => d.ui(selected))}
      </ScrollView>
      <Loading visible={loading} />
      {/*{loading && (
        <LoadingSpinner
          style={{
            position: 'absolute',
            justifyContent: 'center',
            alignSelf: 'center',
            width: wp(100),
            height: hp(90),
          }}
          size={40}
          color={Palette.primary300}
        />
      )}*/}
    </GlobalFlex>
  );
};
export default TimezonePicker;
