import React, {useState} from 'react';
import CustomRating from '../../../reusable/assets/customRating/CustomRating';
import TextareaWithoutBorder from '../../../reusable/form/items/textarea/TextareaWithoutBorder';
import Button from '../../../reusable/btns/buttons/Button';
import CustomRatingGold from '../../../reusable/assets/customRating/CustomRatingGold';

const RatingCourseModal = ({user}) => {
  const [reviewText, setReviewText] = useState('');
  const [ratingValue, setRatingValue] = useState(0);

  // todo change training to real training
  const [training, setTraining] = useState({
    title: "Beginner's Strength",
    author: 'Daniel Cors',
    image: '/images/interests/2.png',
    review: [],
  });

  const postReview = () => {
    // todo POST request with object
    const newReview = {
      id: 3,
      author: {
        nickname: user?.nickname,
        name: user?.name,
        position: user?.position,
        isVerified: user?.isVerified,
        avatar: {
          src: user?.avatar,
        },
      },
      date: new Date(),
      rating: ratingValue,
      text: reviewText,
      replies: null,
    };
    console.log(newReview);
    setReviewText('');
  };

  return (
    <div className="premium-content-reviews__rating-modal">
      <h5 className="h5">Leave a rating</h5>

      <div className="d-flex flex-row align-items-center training-card">
        <img
          src={training?.image}
          alt="workout's start screen"
          className="training-card-image"
        />
        <div>
          <h6 className="h6">{training?.title}</h6>
          <p className="text-r">By {training?.author}</p>
        </div>
      </div>

      <div className="rating-wrapper">
        <h6 className="h6">Rate the course</h6>
        <CustomRatingGold
          initialValue={0}
          onClick={value => {
            setRatingValue(value);
          }}
        />
      </div>

      <div className="textarea-wrapper">
        <h6 className="h6">Care to share more?</h6>
        <TextareaWithoutBorder
          placeHolder="Leave a comment"
          value={reviewText}
          onChange={event => setReviewText(event.target.value)}
        />
      </div>

      <Button
        className="button-send-rating _fw button_primary mx-auto d-block"
        onClick={postReview}>
        Send
      </Button>
    </div>
  );
};

export default RatingCourseModal;
