import {useEffect, useState} from 'react';
import {
  GET_MEMBERSHIPS,
  GET_NEW_MEMBERSHIPS,
  MEMBSHIP_REMAINING_TIME,
} from '../../../../../api_helper/Api';
import {GET} from '../../../../../api_helper/ApiServices';
import {useEncryptedAtom} from '../../../../../appState/hooks/useEncryptedAtom';
import {
  _sessionTokenAtom,
  _userDetailsAtom,
} from '../../../../../appState/userState';
import {useBuzzmiAuth} from '../../../../../appState/hooks/useBuzzmiAuth/index.web';

const useMemberships = setLoading => {
  const [memberships, setMemberships] = useState([]);
  const [sessiontoken, setSessionToken] = useEncryptedAtom(_sessionTokenAtom);
  const [userDetails, setUserDetails] = useEncryptedAtom(_userDetailsAtom);
  const {userData, updateUserData} = useBuzzmiAuth();

  const getMemberships = async () => {
    setLoading(true);
    try {
      // Assuming GET and GET_MEMBERSHIPS() are defined elsewhere

      if (userData?.token) {
        const response = await GET(GET_NEW_MEMBERSHIPS, userData?.token, '123');

        setMemberships([...response.data]);
      } else {
        const response = await GET(GET_MEMBERSHIPS(), sessiontoken, '123');

        setMemberships([
          {
            benifits: null,
            createdAt: '2023-01-17T10:00:08.867Z',
            description: 'Free user',
            discount: 0,
            id: '9ae94f92-cb43-47db-84dd-9e2215eb6438',
            price: 0,
            product_id: null,
            title: 'Free User',
            type: 'free_member',
            updatedAt: '2023-01-17T10:00:08.867Z',
          },
          ...response.data,
        ]);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('error => ', error.message);
    }
  };
  const getMembershipsDays = async () => {
    try {
      // Assuming GET and GET_MEMBERSHIPS() are defined elsewhere
      const response = await GET(MEMBSHIP_REMAINING_TIME, sessiontoken, '123');

      console.log('response IN MEMBSHIP_REMAINING_TIME', response);
    } catch (error) {
      setLoading(false);
      console.log('error => ', error.message);
    }
  };

  useEffect(() => {
    getMemberships();
    getMembershipsDays();
  }, [userDetails]);

  return {memberships};
};

export default useMemberships;
