import React, {useRef} from 'react';
import InfoCard from './InfoCard.jsx';
import {Dots} from 'react-activity';
import 'react-activity/dist/library.css';
import {colors} from '../../../../res/colors.js';
const InfoCardList = ({
  isStat = false,
  infoList,
  showLoadMore,
  onLoadMoreClick,
  loading,
  PageLoading,
  navTo,
  taskType,
  onItemClick,
  isMainActivity = false,
}) => {
  return (
    <div className={'xl:px-6 sm:px-3 px-0'}>
      {infoList?.length > 0 ? (
        <>
          {infoList?.map((item, i) => (
            <div
              className={`${
                item?.isBase
                  ? 'border-l-4 border-red-500'
                  : item?.isBuzzmi
                  ? 'border-l-4 border-green-500'
                  : item?.isShare
                  ? 'border-l-4 border-blue-500'
                  : ''
              } mb-2.5 box `}
              key={item?.id || i}>
              <InfoCard
                isStat={isStat}
                isMainActivity={isMainActivity}
                item={item}
                navTo={navTo}
                noNav={onItemClick !== null}
                taskType={taskType}
                onClickItem={e => {
                  e ? e?.preventDefault() : null;
                  onItemClick ? onItemClick(item) : null;
                }}
              />
            </div>
          ))}
          {showLoadMore ? (
            <>
              {!PageLoading && (
                <div
                  className="text-alt-primary cursor-pointer pb-2"
                  style={{
                    display: 'flex',
                    justifyContent: 'center', // Horizontally center content
                    alignItems: 'center', // Vertically center content
                    width: '100%', // Set the width to 100% if needed
                  }}
                  onClick={onLoadMoreClick}>
                  Load More
                </div>
              )}
              {PageLoading && (
                <div
                  className="pb-2"
                  style={{
                    display: 'flex',
                    justifyContent: 'center', // Horizontally center content
                    alignItems: 'center', // Vertically center content
                    width: '100%', // Set the width to 100% if needed
                    // Add any other inline styles you want
                  }}>
                  <Dots color={colors.PRIMARY_COLOR} />
                </div>
              )}
            </>
          ) : null}
        </>
      ) : (
        <>
          {!loading && (
            <div className="profile-top__row px-6 py-6">
              <div
                className="text-r text-alt-primary text-center"
                style={{flex: 1}}>
                No activity to show
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default InfoCardList;
