import {useState} from 'react';
import {useAtom} from 'jotai';
import {userSharedMediaList} from '../hubState';
import {getSharedMediaList} from '../../../network/api/app';

export const useSharedImageList = () => {
  const [sharedMediaList, setSharedMediaList] = useAtom(userSharedMediaList);
  const [sharedImageListLoader, setsharedImageListLoader] = useState(false);

  const getsharedImageList = async token => {
    setsharedImageListLoader(true);
    try {
      const response = await getSharedMediaList({token});

      setsharedImageListLoader(false);
      setSharedMediaList(response?.imageList);
    } catch (e) {
      setsharedImageListLoader(false);
      console.log('error => ', e.message);
    }
  };

  return {
    sharedMediaList,
    sharedImageListLoader,
    getsharedImageList,
  };
};
