import React, {useState, useEffect} from 'react';
import BackBtnWithTitlePage from '../../../components/reusable/btns/backBtn/BackBtnWithTitlePage.jsx';
import DescriptionList from '../../../components/reusable/descriptionList/DescriptionList.jsx';
import DesktopLayout from './components/desktopLayout/DesktopLayout.jsx';
import ResizeComponent from '../../../components/reusable/assets/resizeComponent/ResizeComponent.jsx';
import SubscriptionCard from './components/subscriptionCard/index.web';
import MainLayout from '../../../components/layouts/mainLayout/MainLayout.jsx';
import Strings from '../../../string_key/Strings.web.js';
import {useBuzzmiAuth} from '../../../appState/hooks/useBuzzmiAuth/index.web.js';
import useMemberships from './hooks/useMemberships';
import Loading from 'react-fullscreen-loading';
import {colors} from '../../../res/colors.js';
import {useNavigation} from '@react-navigation/native';

const MembershipScreen = () => {
  const {navigate} = useNavigation();
  const [current, setCurrent] = useState(userData?.membership);
  const [expanded, setExpanded] = useState(null);
  const {language, userData} = useBuzzmiAuth();
  const [loading, setLoading] = useState(false);
  // console.log('userData', userData);

  useEffect(() => {
    setCurrent(userData?.membership);
  }, [userData]);

  const {memberships} = useMemberships(setLoading);
  // console.log('memberships==>', memberships);
  // mocks
  const descriptionList = [
    {
      id: 0,
      img: '/images/hub/subscription/description/01.png',
      text: Strings.msg_premium_access,
    },
    {
      id: 1,
      img: '/images/hub/subscription/description/02.png',
      text: Strings.msg_elite_badge,
    },
    {
      id: 2,
      img: '/images/hub/subscription/description/03.png',
      text: Strings.msg_hide_boosted_contect,
    },
    {
      id: 3,
      img: '/images/hub/subscription/description/04.png',
      text: Strings.msg_verify_your_channel,
    },
    {
      id: 4,
      img: '/images/hub/subscription/description/05.png',
      text: Strings.msg_create_diff_subscription,
    },
    {
      id: 5,
      img: '/images/hub/subscription/description/06.png',
      text: Strings.msg_publish_content,
    },
    {
      id: 6,
      img: '/images/hub/subscription/description/07.png',
      text: Strings.msg_access_free_content,
    },
    {
      id: 7,
      img: '/images/hub/subscription/description/08.png',
      text: Strings.msg_be_part_diamond,
    },
    {
      id: 8,
      img: '/images/hub/subscription/description/09.png',
      text: Strings.msg_earn_engagement_on_app,
    },
  ];

 
  const icons = [
    {
      type: 'free_member',
      img: '/images/hub/subscription/free.png',
    },
    {
      type: 'super_member',
      img: '/images/hub/subscription/premium.png',
    },
    {
      type: 'premium_member',
      img: '/images/hub/subscription/elite.png',
    },
    {
      type: 'elite_membership',
      img: '/images/hub/subscription/elite.png',
    },
  ];


  const Loader = () => (
    <Loading
      loading={loading}
      background="transparent"
      loaderColor={colors.PRIMARY_COLOR}
    />
  );

  const handleSubscribeEvent = product_id => {
    // setLoading(() => true);

    navigate('Subscription', {
      id: product_id,
      token: userData.token,
      from: 'home',
    });
    // POST(
    //   POST_ORDER,
    //   true,
    //   userData.token,
    //   '',
    //   {
    //     product_id: product_id,
    //   },
    //   (res, e) => {
    //     // setLoading(() => false);
    //     // setUpdating(() => false);
    //     if (!e) {
    //       console.log('res ===> ', res);
    //       window.location.replace(res?.payment_link);
    //       // navigate('StripePaymentScreen', {
    //       //   siteLink: res?.payment_link,
    //       //   orderId: res?.order?.order_id,
    //       //   productId: product_id,
    //       //   // membership: selected?.data,
    //       //   isMembershipFlow: true,
    //       // });
    //     } else {
    //       console.log('PATCH_UPDATE_PROFILE (error)2 => ', res);
    //     }
    //   },
    // );
  };

  const desktopLayout = (
    <>
      <Loader />

      <DesktopLayout
        openers={memberships}
        current={current}
        setCurrent={setCurrent}
        mySub={userData?.membership}
        icons={icons}
        onSubscribe={id => handleSubscribeEvent(id)}
      />
    </>
  );

  return (
    <MainLayout>
      <div className="hub">
        <div className="hub__content">
          <BackBtnWithTitlePage title={Strings.membership_level} />
          <div className="hub__subscription">
            <p className="hub__subscription-text text-center">
              {Strings.membership_description}
            </p>

            <ResizeComponent
              desktopComponent={desktopLayout}
              mobileComponent={
                <>
                  <Loader />
                  {memberships.map((el, index) => {
                    return (
                      <div className="hub__subscription-item" key={el.id}>
                        <SubscriptionCard
                          title={el.title}
                          icon={icons.find(it => it.type === el.type).img}
                          discount={el?.discount}
                          hideBtn={
                            el.benifits == null
                              ? true
                              : el?.benifits.length < 4
                              ? true
                              : false
                          }
                          current={current.id === el?.id}
                          specialOffer={
                            el?.specialOffer
                              ? Strings.str_and_special_offer
                              : null
                          }
                          onShowMore={() =>
                            setExpanded(expanded === el?.id ? null : el?.id)
                          }
                          content={
                            <DescriptionList
                              items={
                                expanded === el?.id
                                  ? el.benifits
                                  : el.benifits?.slice(0, 3)
                              }
                            />
                          }
                        />
                      </div>
                    );
                  })}
                </>
              }
            />

            <span className="hub__subscription-sub-text">
              {Strings.subscription_alert_message}
            </span>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default MembershipScreen;
