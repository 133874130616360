const EmptyStar = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
    <path
      d="M6.20372 0.547997C6.21358 0.526618 6.22457 0.517212 6.23359 0.511718C6.24461 0.505011 6.26024 0.5 6.27872 0.5C6.2972 0.5 6.31283 0.505011 6.32384 0.511718C6.33287 0.517212 6.34385 0.526618 6.35371 0.547997L7.77012 3.61876C7.92781 3.96064 8.25182 4.19605 8.6257 4.24038L11.9839 4.63854C12.0072 4.64131 12.0196 4.64885 12.0276 4.65574C12.0374 4.66414 12.047 4.67745 12.0527 4.69503C12.0584 4.71261 12.0585 4.72902 12.0555 4.74157C12.053 4.75185 12.0475 4.7652 12.0302 4.78119L9.54744 7.07719C9.27102 7.33281 9.14726 7.7137 9.22063 8.08299L9.87969 11.3998C9.88427 11.4229 9.88092 11.437 9.87685 11.4467C9.87188 11.4586 9.86218 11.4719 9.84723 11.4827C9.83227 11.4936 9.81668 11.4987 9.80383 11.4998C9.7933 11.5006 9.77888 11.4995 9.75834 11.488L6.8075 9.83623C6.47896 9.65233 6.07847 9.65233 5.74994 9.83623L2.79909 11.488C2.77855 11.4995 2.76414 11.5006 2.75361 11.4998C2.74075 11.4987 2.72516 11.4936 2.71021 11.4827C2.69526 11.4719 2.68556 11.4586 2.68059 11.4467C2.67652 11.437 2.67316 11.4229 2.67775 11.3998L3.3368 8.08299C3.41018 7.7137 3.28642 7.33281 3.01 7.07719L0.527224 4.78119C0.509939 4.7652 0.504387 4.75185 0.501951 4.74157C0.498976 4.72902 0.49904 4.71261 0.504751 4.69503C0.510463 4.67745 0.520058 4.66414 0.529841 4.65574C0.537854 4.64885 0.550194 4.64131 0.573573 4.63854L3.93173 4.24038C4.30562 4.19605 4.62962 3.96064 4.78732 3.61876L6.20372 0.547997Z"
      stroke="#C0BACB"
    />
  </svg>
)

export default EmptyStar
