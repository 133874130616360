import React, {Suspense, lazy, useEffect, useState} from 'react';
import {useNavigation} from '@react-navigation/native';
import PrimaryButton from '../../../components/reusable/form/items/buttons/PrimaryButton.jsx';
import BaseLayout from '../../../components/layouts/baseLayout/BaseLayout';
import AddCreatorsMobileBtns from './items/AddCreatorsMobileBtns.jsx';
import Strings from '../../../string_key/Strings.web.js';
import Loading from 'react-fullscreen-loading';
import {colors} from '../../../res/colors.js';
import MainLayoutV2 from '../../../components/layouts/mainLayout/MainLayoutV2.jsx';
import {CATEGORY} from '../../../api_helper/Api.js';
import {getData} from '../../../res/asyncStorageHelper.web.js';
import {GET} from '../../../api_helper/ApiServices.js';
import BackBtnWithTitlePage from '../../../components/reusable/btns/backBtn/BackBtnWithTitlePage.jsx';

const ProfileItemsCollection = lazy(() =>
  import('../items/ProfileItemsCollection'),
); // Lazy-loaded

const AddCreators = () => {
  const {navigate} = useNavigation();
  const [interestsLength, setInterestsLength] = useState(0);
  const [interestsLoad, setinterestsLoad] = useState(false);
  const getInterestList = () => {
    setinterestsLoad(true);

    getData('sessionToken', token => {
      GET(CATEGORY + `?page=1&limit=20`, token, null)
        .then(res => {
          setinterestsLoad(false);
          console.log('res?.length', res?.data?.categories?.length);
          setInterestsLength(res?.data?.categories?.length);
        })
        .catch(e => {
          setinterestsLoad(false);
          console.log('INVITATION (error) => ', e.message);
        });
    });
  };
  useEffect(() => {
    getInterestList();
  }, []);

  const handleContinue = () => {
    interestsLength >= 10
      ? navigate('ChooseInterest')
      : window.location.reload();
  };

  const handleInvite = () => {
    navigate('AddPeople');
  };

  return (
    // <BaseLayout showLogin>
    <MainLayoutV2 className=" px-10">
      <BackBtnWithTitlePage hideBottomBorder={true} isFixed={true} />
      <div className="w-full max-w-[500px] md:max-w-[800px] px-4 py-10 mx-auto flex flex-col justify-center items-center text-center md:flex-column md:justify-start md:flex-0 sm:height-[calc(100vh-65px)] md:height-[calc(100vh-60px)] sm:px-4 md:px-0">
        <div className="profile__wrapper">
          <div className="row align-items-center mb-md-6 mb-2 gx-0 mt-sm-7">
            <div className="col-4 col-sm-3">
              <img
                src="/images/assets/rocket.png"
                alt="rocket"
                className="img-fluid"
              />
            </div>
            <div className="col text-left">
              <div className="h4 mb-2">{Strings.the_spot_light_on_you}</div>
              <div className="text-r">{Strings.top_3_creator_select}</div>
            </div>
          </div>
          <Suspense
            fallback={
              <Loading
                loading={interestsLoad}
                background="transparent"
                loaderColor={colors.PRIMARY_COLOR}
              />
            }>
            <ProfileItemsCollection />
          </Suspense>
          <AddCreatorsMobileBtns handleContinue={handleContinue} />

          <div className="d-none d-sm-block">
            <button onClick={handleContinue} className="d-block my-sm-8 w-100">
              <PrimaryButton>{Strings.next}</PrimaryButton>
            </button>
          </div>
        </div>
      </div>
    </MainLayoutV2>
    //</BaseLayout>
  );
};

export default AddCreators;
