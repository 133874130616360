import React, {useEffect, useState, useRef, Fragment} from 'react';

import {Dialog, Transition} from '@headlessui/react';
import {XMarkIcon} from '@heroicons/react/24/outline';
import HubCreateTaskv2 from '../CreateTaskv2/index.web';
import ModalBackdrop from '../../../../components/layouts/mainLayout/componentsV2/ModalBackDrop';
import ShareActivityModal from '../../Profile/ProfileMain/componentsV2/ShareActivityModal';
import AssignActivityModal from '../../Profile/ProfileMain/componentsV2/AssignActivityModal';
import ProgessSteps from '../../../../components/ProgressSteps';
import {EventRegister} from 'react-native-event-listeners';

function _renderSuccessModal(open, setOpen, isPlanner) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-32 w-32 items-center justify-center rounded-full bg-green-100">
                    {/* <CheckIcon
                      className="h-6 w-6 text-green-500"
                      aria-hidden="true"
                    /> */}
                    <div className="hub__cup mt-4">
                      <img src="/images/assets/cup.png" alt="cup -img" />
                    </div>
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900">
                      The activity configured successfully
                    </Dialog.Title>

                    {/* <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Consequatur amet labore.
                      </p>
                    </div> */}
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-pink-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pink-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-500"
                    onClick={() => {
                      setOpen(false);
                      isPlanner &&
                        EventRegister.emit('goBackTo-planActivities');
                      // EventRegister.emit('openBaseActivityModal', responseItem);
                    }}>
                    OK
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
const BaseActivityModal = ({activityModal, showActivityModal, item}) => {
  const [open, setOpen] = useState(false);
  const [openActivityModal, setOpenActivityModal] = useState(false);
  const [openAssignActivityModal, setOpenAssignActivityModal] = useState(false);
  const [isPlanner, setIsPlanner] = useState(false);
  const [activityToShareAssign, setActivityToShareAssign] = useState(null);
  const handleOpenModals = (item, tag) => {
    setActivityToShareAssign(item);
    if (tag?.length === 0) return;
    tag == 'Share' ? setOpenActivityModal(true) : null;
    tag == 'Assign' ? setOpenAssignActivityModal(true) : null;
  };

  return (
    <>
      <Transition.Root show={activityModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={showActivityModal}>
          <ModalBackdrop />
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-0 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full">
                  <Dialog.Panel
                    className="pointer-events-auto w-screen max-w-md"
                    style={{maxWidth: 640}}>
                    <div
                      className={`${
                        item.isPreview ? '' : 'custom-hub-height'
                      } flex h-full flex-col overflow-y-scroll bg-[#FBF8FF] shadow-xl`}>
                      <div className="p-6 pb-4">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base text-center w-100 font-semibold leading-6 text-gray-900">
                            {item?.title}
                          </Dialog.Title>

                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-pink-500"
                              onClick={() => showActivityModal(() => false)}>
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      {!item.isPreview && (
                        <div className=" mx-5">
                          <ProgessSteps DoneFirst />
                        </div>
                      )}
                      {/* <div className="flex-row self-end mr-5 mb-1">
                        <button
                          onClick={() => setOpenAssignActivityModal(true)}
                          className="text-r text-alt-primary mr-5">
                          Assign
                        </button>
                        <button
                          onClick={() => setOpenActivityModal(true)}
                          className="text-r text-alt-primary ">
                          Share
                        </button>
                      </div> */}
                      <div className="px-4">
                        <HubCreateTaskv2
                          item={item}
                          showActivityModal={showActivityModal}
                          setOpen={setOpen}
                          handleOpenModals={handleOpenModals}
                          isPlanner={isPlanner}
                          setIsPlanner={setIsPlanner}
                        />
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {_renderSuccessModal(open, setOpen, isPlanner)}
      <AssignActivityModal
        open={openAssignActivityModal}
        setOpen={setOpenAssignActivityModal}
        item={activityToShareAssign}
      />
      <ShareActivityModal
        open={openActivityModal}
        setOpen={setOpenActivityModal}
        item={activityToShareAssign}
      />
    </>
  );
};
export default BaseActivityModal;
