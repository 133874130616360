import {useMutation} from 'react-query';
import {patchEditProfileAvatar} from '../../../network/api/app';
import {useBuzzmiAuth} from '../useBuzzmiAuth/index.web';
import {useEffect} from 'react';

export const useEditProfileAvatar = () => {
  const {userData, updateUserData} = useBuzzmiAuth();

  const {
    mutate: mutateEditProfileAvatar,
    isLoading: isEditProfileAvatarLoading,
    isError: isEditProfileAvatarError,
    data: EditProfileAvatarResponse,
    error: EditProfileAvatarErrorResponse,
  } = useMutation({
    mutationFn: param => {
      console.log('param?.body', param);

      return patchEditProfileAvatar({
        params: {isAuth: true, token: userData?.token},
        body: {...param?.body},
      });
    },
    onSuccess: data => {
      console.log('edited profile ==> ', data);
      if (data?.id) {
        updateUserData(data);
      }
    },
  });
  const patchBaseEditProfileAvatar = async body =>
    mutateEditProfileAvatar({body});

  return {
    isEditProfileAvatarLoading,
    isEditProfileAvatarError,
    EditProfileAvatarResponse,
    EditProfileAvatarErrorResponse,
    patchBaseEditProfileAvatar,
  };
};
