// noinspection JSUnresolvedReference

import React, {useEffect} from 'react';
import {
  _companyIdAtom,
  _isMembershipSkippedAtom,
  _phoneVerifySkippedAtom,
  // _showLastSeenAtom,
  userDataAtom,
  _useLanguageAtom,
  _userFcmToken,
  _userFcmTokenSavedOnBE,
} from '../../userState';
import {
  snoozeAtom,
  disturbAtom,
  languageAtom,
  emailLoadAtom,
  emailErrorAtom,
} from '../../../appState';
import {actionForgetPassword} from '../../api/index.web.js';
import AuthManager from '../../../libs/AuthManager.ts';
import Strings from '../../../string_key/Strings.web';
import jwt_decode from 'jwt-decode';
import {useAtom} from 'jotai';
import {_userDetailsAtom} from '../../userState';
import {useEncryptedAtom} from '../useEncryptedAtom/index.web.js';
import {useMutation} from 'react-query';
import {
  sendOTP,
  sendEmailVerification,
  verifyEmail,
  verifyOTP,
  login as loginAPI,
  socialLogin as socialLoginAPI,
  socialRegister as socialRegisterAPI,
  validateUserInfo as validateUserInfoAPI,
} from '../../../network/api/auth';
import AsyncStorage from '@react-native-community/async-storage';
import {QAItems, QAOtherItems} from '../../quickAccessState';
import {usePostFcmToken} from '../usePostFcmToken/index.js';

export const useBuzzmiAuth = () => {
  const [language, setLanguage] = useAtom(languageAtom);

  const [atomLanguage, setAtomLanguage] = useEncryptedAtom(_useLanguageAtom);

  const [emailload, setEmailLoad] = useAtom(emailLoadAtom);
  const [isSnooze, setIsSnooze] = useAtom(snoozeAtom);
  const [isDisturb, setIsDisturb] = useAtom(disturbAtom);
  const [userData, setLoginSuccess] = useAtom(userDataAtom);
  const [emailError, setEmailError] = useAtom(emailErrorAtom);
  const [userDetails, setUserDetails] = useEncryptedAtom(_userDetailsAtom);
  const [companyId, setCompanyId] = useEncryptedAtom(_companyIdAtom);
  const [qaItems, setQaItems] = useAtom(QAItems);
  const [otherQaItems, setOtherQaItems] = useAtom(QAOtherItems);
  const [userFcmToken, setUserFcmToken] = useAtom(_userFcmToken);
  const [userFcmTokenSavedOnBE, setUserFcmTokenSavedOnBE] = useAtom(
    _userFcmTokenSavedOnBE,
  );

  const {postSendFcmToken, postFcmTokenResponse} = usePostFcmToken();

  // const [showLastSeen, setShowLastSeen] = useEncryptedAtom(_showLastSeenAtom);
  const [isMemberSkipped, setIsMemberSkipped] = useEncryptedAtom(
    _isMembershipSkippedAtom,
  );
  const [phoneVerifySkipped, setPhoneVerifySkipped] = useEncryptedAtom(
    _phoneVerifySkippedAtom,
  );

  const {
    mutate: mutateMobileVerification,
    isLoading: isMobileVerifyLoading,
    data: mobileVerifyResponse,
    error: mobileVerifyErrorResponse,
  } = useMutation(sendOTP);

  const {
    mutate: mutateEmailVerification,
    isLoading: isEmailVerifyLoading,
    data: emailVerifyResponse,
    error: emailVerifyErrorResponse,
  } = useMutation(sendEmailVerification);

  const {
    mutate: mutateVerifyOTP,
    isLoading: isVerifyOTPLoading,
    data: verifyOTPResponse,
    error: verifyOTPErrorResponse,
  } = useMutation(verifyOTP);

  const {
    mutate: mutateVerifyEmail,
    isLoading: isVerifyEmailLoading,
    data: verifyEmailResponse,
    error: verifyEmailErrorResponse,
  } = useMutation(verifyEmail);

  // const {
  //   mutate: mutateLogin,
  //   isLoading: isLoginLoading,
  //   isError: isLoginError,
  //   data: loginResponse,
  //   error: loginErrorResponse,
  // } = useMutation(loginAPI);
  const {
    mutate: mutateLogin,
    isLoading: isLoginLoading,
    isError: isLoginError,
    data: loginResponse,
    error: loginErrorResponse,
  } = useMutation({
    mutationFn: param => {
      console.log('data in mutation', param);

      return loginAPI(param);
    },
    onSuccess: data => {
      console.log('login success', data);
    },
  });

  const {
    mutate: mutateVerifyValidation,
    isLoading: isValidateLoading,
    isError: isValidateError,
    data: validateResponse,
    error: validateErrorResponse,
  } = useMutation(validateUserInfoAPI);

  // const {
  //   mutate: mutateRegister,
  //   isLoading: isRegisterLoading,
  //   isError: isRegisterError,
  //   data: registerResponse,
  //   error: registerErrorResponse,
  // } = useMutation(registerAPI);
  const {
    mutate: mutateSocial,
    isLoading: isSocialLoading,
    isError: isSocialError,
    data: socialResponse,
    error: socialErrorResponse,
    reset: resetSocial,
  } = useMutation(socialLoginAPI);
  const {
    mutate: mutateSocialRegister,
    isLoading: isSocialRegisterLoading,
    isError: isSocialRegisterError,
    data: socialRegisterResponse,
    error: socialRegisterErrorResponse,
    reset: resetSocialRegister,
  } = useMutation(socialRegisterAPI);

  useEffect(() => {
    if (!isLoginLoading && loginResponse && !isLoginError) {
      const userProfile = {
        ...loginResponse.user,
        userId: loginResponse.user.id,
        token: loginResponse.sessionToken,
      };

      setQaItems(loginResponse.user.qaItems);
      setOtherQaItems(loginResponse.user.qaOtherItems);

      setTimeout(() => {
        console.log('this is here', loginResponse);

        AuthManager.authenticate({userId: userProfile.id}).then(item => {
          setLoginSuccess(userProfile);
          AsyncStorage.setItem('show-last-seen', JSON.stringify(true));
          // setShowLastSeen(true);
          // saveData('company_id', userProfile?.vendorId);
          setCompanyId(userProfile?.vendorId);
          // saveData('userDetails', userProfile);
          setUserDetails(userProfile);
        });
      }, 2000);
    }
  }, [loginResponse, isLoginLoading, isLoginError]);

  // useEffect(() => {
  //   console.log('userData?.token before', userData?.token);
  //   console.log('userFcmTokenSavedOnBE?.token before', userFcmTokenSavedOnBE);

  //   if (userFcmToken && !userFcmTokenSavedOnBE && userData?.token) {
  //     console.log('userData?.token after', userData?.token);
  //     postSendFcmToken(
  //       Object.assign(
  //         {
  //           fcmToken: userFcmToken,
  //         },
  //         {
  //           params: {isAuth: true, token: userData?.token},
  //         },
  //       ),
  //     );
  //   }
  // }, [userFcmToken]);

  // useEffect(() => {
  //   if (!isRegisterLoading && registerResponse && !isRegisterError) {
  //     Snackbar.dismiss();
  //     const userProfile = {
  //       ...registerResponse.user,
  //       userId: registerResponse.user.id,
  //       token: registerResponse.sessionToken,
  //     };
  //     authenticateSendbirdUser(userProfile).then();
  //   }
  // }, [registerResponse, isRegisterLoading, isRegisterError]);

  useEffect(() => {
    if (!isSocialLoading && socialResponse && !isSocialError) {
      const res: any = jwt_decode(socialResponse?.token);
      const userProfile: any = {
        ...res,
        userId: res.id,
        token: 'social-' + socialResponse?.token,
      };
      console.log('res in user login==>', res);
      console.log('socialResponse in user login==>', socialResponse);
      AuthManager.authenticate({userId: userProfile.id}).then(item => {
        setLoginSuccess(userProfile);
        AsyncStorage.setItem('show-last-seen', JSON.stringify(true));
        // setShowLastSeen(true);
        // saveData('company_id', userProfile?.vendorId);
        setCompanyId(userProfile?.vendorId);
        // saveData('userDetails', userProfile);
        setUserDetails(userProfile);
      });
    }
  }, [socialResponse, isSocialLoading, isSocialError]);

  useEffect(() => {
    if (
      !isSocialRegisterLoading &&
      socialRegisterResponse &&
      !isSocialRegisterError
    ) {
      const res: any = jwt_decode(socialRegisterResponse?.token);
      console.log(
        'socialRegisterResponse in user reg login==>',
        socialRegisterResponse,
      );
      console.log('res in user reg login==>', res);

      const userProfile: any = {
        ...res,
        userId: res.id,
        token: 'social-' + socialRegisterResponse?.token,
      };
      AuthManager.authenticate({userId: userProfile.id}).then(item => {
        setLoginSuccess(userProfile);
        AsyncStorage.setItem('show-last-seen', JSON.stringify(true));
        // setShowLastSeen(true);
        // saveData('company_id', userProfile?.vendorId);
        setCompanyId(userProfile?.vendorId);
        // saveData('userDetails', userProfile);
        setUserDetails(userProfile);
      });
    }
  }, [socialRegisterResponse, isSocialRegisterLoading, isSocialRegisterError]);

  const validateUserInfo = (email, username, mobile) =>
    mutateVerifyValidation({email: email, username: username, mobile: mobile});

  const defaultLogin = success => setLoginSuccess(success);
  const login = (email, password) =>
    mutateLogin({identifier: email, password: password});
  const socialLogin = (socialId, loginType) =>
    mutateSocial({socialId: socialId, loginType: loginType});
  // TODO: Not being used in the project. Might as well remove...
  const socialRegister = params => mutateSocialRegister(params);
  const updateLanguage = value => {
    setLanguage(() => value);
    setAtomLanguage(value);
    Strings.setLanguage(value);
  };
  const onforgetPassword = async (email, callback) => {
    setEmailError(null);
    setEmailLoad(true);

    // return;
    actionForgetPassword(email, (res, e) => {
      setEmailLoad(false);
      if (!e) {
        callback(res.flowId, res?.message);
      } else {
        setEmailError(res);
      }
    });
  };

  const onSendMobileEmailOtp = async identifier => {
    if (identifier.includes('@')) {
      mutateEmailVerification(
        Object.assign(
          {email: identifier, flag: false},
          {
            params: {isAuth: true, token: userData?.token},
          },
        ),
      );
    } else {
      mutateMobileVerification(
        Object.assign(
          {mobile: identifier, flag: false},
          {
            params: {isAuth: true, token: userData?.token},
          },
        ),
      );
    }
  };

  const onValidateOTP = async (identifier, code, UUID) => {
    if (identifier.includes('@')) {
      mutateVerifyEmail(
        Object.assign(
          {code: code},
          {
            params: {isAuth: true, token: userData?.token},
          },
        ),
      );
    } else {
      mutateVerifyOTP(
        Object.assign(
          {otp: code, uniqueUUID: UUID},
          {
            params: {isAuth: true, token: userData?.token},
          },
        ),
      );
    }
  };

  const updateUserData = updateData => {
    setLoginSuccess(prevData => {
      const _profile = {
        ...updateData,
        token: prevData.token,
        userId: userData.userId,
      };
      // saveData('userDetails', _profile);
      setUserDetails(_profile);
      return _profile;
    });
  };
  const clear = () => {
    setEmailError(null);
    setLoginSuccess(null);
  };
  const logout = () => {
    // try {
    //   fbLogout().then();
    // } catch (e) {
    //   console.log('Facebook logout error', e.message);
    // }
    // try {
    //   GoogleSignin.signOut().then();
    // } catch (e) {
    //   console.log('Google logout error', e.message);
    // }
    setLoginSuccess(null);
    // saveData('isMembershipSkipped', null);
    setIsMemberSkipped(null);
    // saveData('phone-verify-skipped', null);
    // AsyncStorage.setItem('phone-verify-skipped', null);
    setPhoneVerifySkipped(null);
  };
  const updateDND = value => setIsDisturb(() => value);
  const updateSnooze = value => setIsSnooze(() => value);

  return {
    emailmobload:
      isEmailVerifyLoading ||
      isMobileVerifyLoading ||
      isVerifyEmailLoading ||
      isVerifyOTPLoading,
    emailMobResponse: emailVerifyResponse || mobileVerifyResponse,
    emailmobError:
      emailVerifyErrorResponse?.message ||
      mobileVerifyErrorResponse?.message ||
      verifyEmailErrorResponse?.message ||
      verifyOTPErrorResponse?.message,
    verifyCodeResponse: verifyOTPResponse || verifyEmailResponse,
    isSnooze,
    userData,
    language,
    emailload,
    isDisturb,
    resetSocial,
    resetSocialRegister,
    loginError: loginErrorResponse?.message || socialErrorResponse?.message,
    emailError,
    isLoginPending: isLoginLoading || isSocialLoading,
    registrationError:
      socialRegisterErrorResponse?.message /* || registerErrorResponse?.message*/,
    isRegistrationPending: isSocialRegisterLoading /* || isRegisterLoading*/,
    login,
    clear,
    logout,
    updateDND,
    socialLogin,
    updateSnooze,
    defaultLogin,
    socialRegister,
    updateLanguage,
    updateUserData,
    onforgetPassword,
    onSendMobileEmailOtp,
    onValidateOTP,
    qaItems,
    otherQaItems,
    setQaItems,
    setOtherQaItems,
    validateUserInfo,
    isValidateLoading,
    isValidateError,
    validateResponse,
    validateErrorResponse,
  };
};
