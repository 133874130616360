import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../../../store/slices/modalSlice/modalSlice';
import PrimaryButton from '../../../reusable/btns/buttons/PrimaryButton';
import { useEffect, useState } from 'react';
import mocTags from './mocTags';
import cn from 'classnames';
import useSetParenComponentProps from '../../../../hooks/useSetParenComponentProps';
import PlanSearch from '../../../reusable/assets/additionalInformation/items/additionalInformationPlan/items/planSearch/PlanSearch';
import { useHubApp } from '../../../../appState/hooks/useHubApp/index.web';
import { useBuzzmiAuth } from '../../../../appState/hooks/useBuzzmiAuth/index.web';
import Bounce from 'react-activity/dist/Bounce';
import 'react-activity/dist/Bounce.css';
import { PlusCircleIcon } from '@heroicons/react/24/outline';

const changeSpacesToUnderscore = value => value.replace(/\s/g, '_');

const AddTags = props => {
  const validationNewTag = value => {
    if (value.length > 20) {
      return 'Tag should be less than 20 characters';
    }
    if (value.length < 3) {
      return 'Tag should be more than 3 characters';
    }
    return false;
  };
  const { modalTitle } = useSelector(state => state.modal);

  const [tags, setTags] = useState([]);
  const [tagsSearchValue, setTagsSearchValue] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [newTag, setNewTag] = useState('');
  const [error, setError] = useState('');
  const setProps = useSetParenComponentProps();
  const { callback } = useSelector(state => state.modal);
  const dispatch = useDispatch();
  const {
    getTagsList,
    isPostAcitivityTagsLoading,
    isPostAcitivityTagsError,
    PostAcitivityTagsResponse,
    PostAcitivityTagsErrorResponse,
    PostAcitivityTags,
    tagsList,
    mutateProfileTag,
    isTagLoading,
    tagResponse,
    getUserTags,
    userTags,
  } = useHubApp();

  const { userData } = useBuzzmiAuth();

  useEffect(() => {
    if (PostAcitivityTagsResponse) {
      getTagsList(userData?.token);
    }
  }, [PostAcitivityTagsResponse]);

  useEffect(() => {
    if (props?.value?.length > 0) {
      setSelectedTags(props.value);
    }
    // console.log('first props.value', props.value);
  }, []);

  useEffect(() => {
    if (tagResponse) {
      getUserTags(userData?.id, userData?.token);
      setTags([]);
      setNewTag('');
    }
  }, [tagResponse]);

  const save = e => {
    e.preventDefault();
    // console.log('selectedTags', selectedTags);
    // setProps('CheckTags', {tags: selectedTags});
    // if (callback) {
    //   callback(selectedTags);
    // }
    if (props?.setTags) {
      props?.setTags(selectedTags);
      props?.setOpen(false);
    }

    // dispatch(closeModal());
  };

  const handleTagClick = tag => {
    if (selectedTags.map(({ id }) => id).includes(tag.id)) {
      setSelectedTags(selectedTags.filter(t => t.id !== tag.id));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  useEffect(() => {
    if (tagsList.length) {
      setTags(
        tagsList
          ?.filter(item => item.tagName !== 'All')
          ?.map(item => ({
            id: item.id,
            title: item.tagName,
            value: item.tagName,
          })),
      );
    }
  }, [tagsList]);

  const handleAddNew = (newTag) => {
    // setNewTag()
    const error = validationNewTag(newTag);
    if (error) {
      setError(error);
      return;
    }
    setNewTag('');
    PostAcitivityTags(
      Object.assign(
        { tagName: newTag },
        {
          params: { isAuth: true, token: userData?.token },
        },
      ),
    );
  };

  const handleNewTagChange = e => setNewTag(e.target.value);

  return (
    <>
      <div className="mt-2">
        <PlanSearch setSearchValue={setTagsSearchValue} />
        {tags.filter(item => item.title.toLowerCase().includes(tagsSearchValue.toLowerCase())).length == 0 &&
          <PlusCircleIcon
            onClick={() => { handleAddNew(tagsSearchValue) }}
            className={"w-6 h-6 self-center absolute text-[#aaa] right-[32px] top-[68px]"} />}
        <div className="text-error">{error}</div>
      </div>
      <div className="add-tags__wrapper overflow-y-auto">
        <div className="mt-3 add-tags__block max-h-80">
          {tags
            .filter(item => item.title.toLowerCase().includes(tagsSearchValue.toLowerCase()))
            .map(tag => (
              <div
                onClick={() => handleTagClick(tag)}
                className={cn('add-tags__item', {
                  'add-tags__item_selected': selectedTags
                    .map(({ id }) => id)
                    .includes(tag.id),
                })}
                key={tag.id}>
                {tag.title}
              </div>
            ))}
        </div>
      </div>

      <div className="modal__bottom">
        {/* {!isPostAcitivityTagsLoading ? (
          <div className="d-flex mb-4 mb-md-21 justify-content-center">
            <input
              placeholder="Add your tag"
              type="text"
              onChange={handleNewTagChange}
              className="input input_simple-text rounded-md"
              value={newTag}
            />
            <button onClick={handleAddNew} className="ms-3 text-alt-primary">
              Add
            </button>
          </div>
        ) : (
          <div className="d-flex mb-6 justify-content-center">
            <Bounce color={colors.PRIMARY_COLOR} size={20} />
          </div>
        )} */}

        {/* <div className="d-flex justify-content-center">
          <PrimaryButton className="button_m" onClick={save} type="button">
            Save
          </PrimaryButton>
        </div> */}
        <div className="mt-5 sm:mt-6">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md bg-pink-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pink-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600"
            onClick={save}>
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default AddTags;
