import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { setItemHeight } from '../../../../Profile/items/profilePost/utils/setItemHeight';

const discoverData = [
    {
        id: 1,
        imageUrl: "/images/social/rect_1.png",
        isVideo: false,
        ismultiple: true,
    }, {
        id: 2,
        imageUrl: "/images/social/rect_2.png",
        isVideo: false,
        ismultiple: false,
    }, {
        id: 3,
        imageUrl: "/images/social/rect_3.png",
        isVideo: false,
        ismultiple: true,
    }, {
        id: 4,
        imageUrl: "/images/social/rect_4.png",
        isVideo: true,
        ismultiple: false,
    }, {
        id: 5,
        imageUrl: "/images/social/rect_5.png",
        isVideo: false,
        ismultiple: false,
    }
]


const DiscoverList = () => {

    const itemRef = useRef(null);
    useEffect(() => {
        setItemHeight(itemRef.current);
        const handleResize = () => {
            setItemHeight(itemRef.current);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <ul
            className={
                'profile-posts-list__table'
            }>
            {
                discoverData.map(post => (
                    <li
                        className="profile-posts-list__table-item"
                        ref={itemRef}>
                        <div className="video-wrapper">
                            {post?.imageUrl ? (
                                <img src={post?.imageUrl} alt={post?.imageUrl} />
                            ) : (
                                    <img src={"https://coffective.com/wp-content/uploads/2018/06/default-featured-image.png.jpg"} />
                                )
                            }
                            {post?.ismultiple && (
                                <img src="/images/post/multicontent-label.svg" alt="multicontent label" className="video-label" />
                            )}
                            {post?.isVideo && (
                                <img src="/images/post/video-label.svg" alt="multicontent label" className="video-label" />
                            )}
                        </div>
                    </li>
                ))}
        </ul>
    );
};

// ProfilePostTile.propTypes = {
//   post: PropTypes.shape({
//     id: PropTypes.number.isRequired,
//     image: PropTypes.objectOf(PropTypes.string),
//     video: PropTypes.objectOf(PropTypes.string),
//   }).isRequired,
//   handleSwitchAnchor: PropTypes.func.isRequired,
// };

export default DiscoverList;
