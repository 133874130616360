import React from "react"

const UnfollowIcon = ({ isHovered }) => {
  return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.5 12.5537C12.2546 12.5537 14.4626 10.3171 14.4626 7.52684C14.4626 4.73663 12.2546 2.5 9.5 2.5C6.74543 2.5 4.53737 4.73663 4.53737 7.52684C4.53737 10.3171 6.74543 12.5537 9.5 12.5537ZM9.5 15.0152C5.45422 15.0152 2 15.6621 2 18.2464C2 20.8298 5.4332 21.5 9.5 21.5C13.5448 21.5 17 20.8531 17 18.2687C17 15.6844 13.5668 15.0152 9.5 15.0152ZM20.2794 10.488L21.1301 11.3387C21.4803 11.6889 21.4763 12.2639 21.1212 12.619C20.7661 12.9741 20.1911 12.9781 19.8409 12.6279L18.9902 11.7772L18.1577 12.6097C17.8027 12.9648 17.2284 12.9695 16.8775 12.6186C16.5273 12.2685 16.5313 11.6934 16.8864 11.3384L17.7189 10.5059L16.8696 9.65664C16.5187 9.30575 16.5235 8.73145 16.8786 8.37636C17.2336 8.02127 17.808 8.01653 18.1588 8.36742L19.0081 9.21667L19.8398 8.38494C20.1949 8.02985 20.7699 8.02584 21.1201 8.376C21.471 8.72689 21.4663 9.30119 21.1112 9.65628L20.2794 10.488Z"
          fill={isHovered ? "url(#gradient)" : "#C0BACB"}
        />
        <defs>
          <linearGradient id="gradient" gradientTransform="rotate(45)">
            <stop offset="0%" stopColor="#EF8ED5" />
            <stop offset="50%" stopColor="#EC44D0" />
            <stop offset="100%" stopColor="#C1169C" />
          </linearGradient>
        </defs>
      </svg>
  )
}

export default UnfollowIcon
