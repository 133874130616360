import React, {useEffect, useState} from 'react';
import {useLinkTo} from '@react-navigation/native';
// import ProfileContentTabsV2 from '../../Profile/items/profileContentTabs/ProfileContentTabsV2';
import useWindowSize from '../../../../hooks/useWindowSize';
import {useProfileApp} from '../../../../appState/hooks';
import {useBuzzmiAuth} from '../../../../appState/hooks/useBuzzmiAuth/index.web';
import {useProfileInfo} from '../../../../appState/hooks/useProfileInfo';
import SocialDashboardContentTab from './SocialDashboardContentTab';
import {useEventEmitter} from '../../../../hooks/useEventEmitter';

const ProfileMainMobileV2 = ({
  setOpenFriendsModal,
  setOpenActivityModal,
  setOpenBadgesModal,
}) => {
  const linkTo = useLinkTo();
  const {width} = useWindowSize();
  const {getInformationContacts, getUsersActivity, userContactInfo} =
    useProfileApp();
  const {userData} = useBuzzmiAuth();
  const {profileInfo} = useProfileInfo();
  const {
    getUserPostLists,
    getUserReelLists,
    getBuzzmiUserPostLists,
    getFollowingPostLists,
  } = useProfileApp();
  const [openEditProfileModal, setOpenEditProfileModal] = useState(false);
  const [open, setOpen] = useState(false);
  let mocDataContacts = [];

  // useEffect(() => {
  //   if (userData) {
  //     getBuzzmiUserPostLists(userData?.token, { page: 1, limit: 20 });
  //     getFollowingPostLists(userData?.token, { page: 1, limit: 20 });
  //     getUserPostLists(userData?.token, userData?.id, { page: 1, limit: 20 });
  //     getUserReelLists(userData?.token, userData?.id, { page: 1, limit: 20 });
  //   }
  // }, [userData]);

  useEffect(() => {
    getInformationContacts(userData?.token);
    getUsersActivity(userData?.token);
  }, [userData]);

  useEventEmitter('refresh-buzzmiUser-list', data => {
    getBuzzmiUserPostLists(userData?.token, data);
  });

  useEventEmitter('refresh-following-list', data => {
    getFollowingPostLists(userData?.token, data);
  });

  return (
    <>
      <SocialDashboardContentTab />
    </>
  );
};

export default ProfileMainMobileV2;
