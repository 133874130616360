import {useState} from 'react';
import {useAtom} from 'jotai';
import {userCoverList} from '../hubState';
import {getCoverList} from '../../../network/api/app';

export const useCoverImageList = () => {
  const [coverList, setCoverList] = useAtom(userCoverList);
  const [coverImageListLoader, setCoverImageListLoader] = useState(false);

  const getCoverImageList = async (token) => {
    setCoverImageListLoader(true);
    try {
      const response = await getCoverList({token});
      setCoverImageListLoader(false);
      setCoverList(response?.imageList);
    } catch (e) {
      setCoverImageListLoader(false);
      console.log('error => ', e.message);
    }
  };

  return {
    coverList,
    coverImageListLoader,
    getCoverImageList,
  };
};
