import React, { useRef, useState } from 'react';
import { Dimensions, FlatList } from 'react-native';
import { widthPercentageToDP as wp } from 'react-native-responsive-screen';
import { IS_IPHONE_X } from '../../../constants.ts';

import ReelCard from './ReelCard';
const ScreenHeight = Dimensions.get('window').height;
function Reels({
  videos,
  backgroundColor = 'black',
  headerTitle,
  headerIconName,
  headerIconColor,
  headerIconSize,
  headerIcon,
  headerComponent,
  onHeaderIconPress,
  optionsComponent,
  pauseOnOptionsShow,
  onSharePress,
  onCommentPress,
  onLikePress,
  onDislikePress,
  onFinishPlaying,
  minimumTrackTintColor,
  maximumTrackTintColor,
  thumbTintColor,
  timeElapsedColor,
  totalTimeColor,
  headerHeight,
  screenLayoutHeight,
  my_reels,
  isSearch,
  onRefresh,
  onLoadMore
}) {
  const FlatlistRef = useRef(null);
  const [ViewableItem, SetViewableItem] = useState('');
  const viewConfigRef = useRef({ viewAreaCoveragePercentThreshold: 70 });
  const applyProps = {
    backgroundColor: backgroundColor,
    headerTitle: headerTitle,
    headerIconName: headerIconName,
    headerIconColor: headerIconColor,
    headerIconSize: headerIconSize,
    headerIcon: headerIcon,
    headerComponent: headerComponent,
    onHeaderIconPress: onHeaderIconPress,
    optionsComponent: optionsComponent,
    pauseOnOptionsShow: pauseOnOptionsShow,
    onSharePress: onSharePress,
    onCommentPress: onCommentPress,
    onLikePress: onLikePress,
    onDislikePress: onDislikePress,
    onFinishPlaying: onFinishPlaying,
    minimumTrackTintColor: minimumTrackTintColor,
    maximumTrackTintColor: maximumTrackTintColor,
    thumbTintColor: thumbTintColor,
    timeElapsedColor: timeElapsedColor,
    totalTimeColor: totalTimeColor,
    headerHeight: headerHeight,
    my_reels: my_reels,
    isSearch: isSearch,
    onRefresh: onRefresh,
    screenLayoutHeight: screenLayoutHeight,
  };

  const onViewRef = React.useRef((viewableItems) => {
    let Check = [];
    for (var i = 0; i < viewableItems.viewableItems.length; i++) {
      Check.push(viewableItems.viewableItems[i].item._id || 0);
    }
    SetViewableItem(Check);
  });

  return (
    <FlatList
      ref={FlatlistRef}
      data={videos}
      extraData={videos}
      keyExtractor={item => item._id.toString()}
      showsVerticalScrollIndicator={false}
      renderItem={({ item, index }) => (
        <ReelCard
          {...item}
          index={index}
          ViewableItem={ViewableItem}
          onFinishPlaying={index => {
            if (index !== videos.length - 1) {
              FlatlistRef.current.scrollToIndex({
                index: index + 1,
              });
            }
          }}
          {...applyProps}
        />
      )}
      getItemLayout={(_data, index) => (
        {
          length: screenLayoutHeight,
          offset: (screenLayoutHeight * index),
          index,
        })}
      pagingEnabled
      decelerationRate={0.9}
      onViewableItemsChanged={onViewRef.current}
      viewabilityConfig={viewConfigRef.current}
      extraData={videos}
      onEndReached={onLoadMore}
      onEndReachedThreshold={0.2}
    />
  );
}

export default Reels;
