import {useAtom} from 'jotai';
import {userRankAtom} from '../hubState';
import {getRankInfo} from '../../../network/api/app';

export const useRankInfo = () => {
  const [rankInfo, setRankInfo] = useAtom(userRankAtom);

  const getRankInfoDetail = async (id, token) => {
    try {
      const response = await getRankInfo(id)({token});
      setRankInfo(response);
    } catch (e) {
      console.log('error => ', e.message);
    }
  };

  return {
    rankInfo,

    getRankInfoDetail,
  };
};
