import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useBuzzmiAuth } from "../../../../appState/hooks/useBuzzmiAuth/index.web";
import { closeModal } from "../../../../store/slices/modalSlice/modalSlice";
import Button from "../../../reusable/btns/buttons/Button"

const AddSocialMediaConnection = () => {
    const { userData } = useBuzzmiAuth();
    const { callback, modalProps } = useSelector(state => state.modal);
    const [newLink, setNewLink] = useState(
        modalProps?.title == "Facebook" ?  (userData?.facebookLink || '') : modalProps?.title == "Twitter" ?  (userData?.twitterLink || '') : modalProps?.title == "Youtube" ?  (userData?.youtubeLink || '') :  modalProps?.title == "LinkedIn" ? (userData?.linkedInLink || '') : '');
    const dispatch = useDispatch();

    const handleChange = e => setNewLink(e.target.value);

    return (
        <div className="">
            <p className={"mb-2 font-bold"}>{modalProps.title || ''}</p>
            <ul>
                <li className="d-flex align-items-center">
                    <input
                        type="text"
                        value={newLink}
                        className="input input_simple-text"
                        onChange={handleChange}
                        placeholder={'Enter your ' + modalProps.title + ' profile link'}
                    />
                </li>
            </ul>
            <div className="d-flex align-items-center justify-content-center mt-4">
                <Button style={{ color: '#eb4dac' }} type={"button"} className="_fw button_primary custom_fw_btn" onClick={() => {
                    if (newLink == '') {
                        alert("Please enter add new link")
                    } else {
                        if (callback) {
                            callback(newLink);
                        }
                        dispatch(closeModal());
                    }
                }}>
                    Submit
                </Button>
            </div>
        </div>
    )
}

export default AddSocialMediaConnection
