import React, {useEffect, useState} from 'react';
import styles from './plan-search.module.scss';
import useDebounceSearch from '../../../../../../../../hooks/useDebounceSearch';

const PlanSearch = ({
  setSearchValue = () => {},
  placholderExt = '',
  className = '',
}) => {
  const handleSearch = () => {
    //TODO: search logic
    // console.log(value);
  };

  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounceSearch(searchTerm, 600); // Adjust the delay as needed

  const handleValue = event => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    setSearchValue(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  return (
    <div className={styles['plan-search'] + ` ${className} mb-3`}>
      <input
        onChange={handleValue}
        type="text"
        className="input input_simple-text rounded-lg"
        placeholder={'Search' + ' ' + placholderExt}
      />
      <img
        onClick={handleSearch}
        src="/images/assets/search.svg"
        alt="search icon"
      />
    </div>
  );
};

export default PlanSearch;
