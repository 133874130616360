import React from "react"
import Comment from "./Comment"
import RepliesList from "./RepliesList"

const CommentsList = ({ comments }) => {
  return (
    <ul className="comments-post__comments-list">
      {comments?.map((comment) => (
        <li key={comment.id}>
          <Comment comment={comment} />

          {comment.replies && <RepliesList replies={comment.replies} />}
        </li>
      ))}
    </ul>
  )
}

export default CommentsList
