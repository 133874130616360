import { combineReducers, configureStore } from '@reduxjs/toolkit';
import userAPI from "./services/UserService.js";
import modalSlice from "./slices/modalSlice/modalSlice.js";

const rootReducer = combineReducers({
  modal: modalSlice,
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware(
      {
        serializableCheck: false,
      }
    ).concat(userAPI.middleware),
});
