import React from "react"
import { Link } from "react-router-dom"

const ProfileContentItemMenu = () => {
  return (
    <ul className="info-desktop-menu d-block">
      <li className="mb-3">
        <button className="d-flex align-items-center ">
          <img className="me-2" src="/images/assets/info-card/subtract_s.svg" alt="" />
          <span>Complete</span>
        </button>
      </li>
      <li className="mb-3">
        <Link to={"/hub/edit-task/"} className="d-flex align-items-center">
          <img className="me-2" src="/images/assets/info-card/edit_s.svg" alt="" />
          <span>Edit</span>
        </Link>
      </li>
      <li>
        <button className="d-flex align-items-center">
          <img className="me-2" src="/images/assets/info-card/delete_s.svg" alt="" />
          <span>Delete</span>
        </button>
      </li>
    </ul>
  )
}

export default ProfileContentItemMenu
