import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { POST_QR_CODE } from "../../../../api_helper/Api";
import { POST_DATA } from "../../../../api_helper/ApiServices";
import { useBuzzmiAuth } from "../../../../appState/hooks/useBuzzmiAuth/index.web";
import { useQrData } from "../../../../appState/hooks/useQrData";
import { closeModal } from "../../../../store/slices/modalSlice/modalSlice";
import Button from "../../../reusable/btns/buttons/Button"
import { useToast } from 'react-native-toast-notifications';
import { ShowError, ShowSuccess } from "../../../../container/HOC/MessageWeb";
import { usePost2FAVerification } from "../../../../appState/hooks/usePost2FAVerification";

const QrDisableModal = () => {
    const { userData, updateUserData } = useBuzzmiAuth();
    const [qrCode, setQrCode] = useState();
    const dispatch = useDispatch();
    const { callback } = useSelector(state => state.modal);
    const { qrInfo } = useQrData();
    const handleChange = e => setQrCode(e.target.value);
    const toast = useToast();
    const { post2FAVerification, faResponse, faErrorResponse } = usePost2FAVerification()

    useEffect(() => {
        if (faResponse != undefined) {
            if (faResponse?.otpValid) {
                dispatch(closeModal());
                const updateUser = {
                    ...userData,
                    twoFactorAuthEnabled: faResponse?.isTFAEnabled,
                }
                updateUserData(updateUser);
                setTimeout(() => {
                    ShowSuccess('2FA disable successfully', toast);
                }, 350);
            }
        }
    }, [faResponse])

    useEffect(() => {
        if (faErrorResponse) {
            ShowError("Invalid code. Please try again", toast);
        }
    }, [faErrorResponse])

    return (
        <div>
            <h5 className={"font-bold text-lg"}>{"Disable 2FA"}</h5>
            <p className={'mt-4 text-sm'}>{'To disable two-factor authentication, enter 2FA code below'}</p>

            <ul className={'mt-6'}>
                <li className="d-flex align-items-center">
                    <input
                        type="text"
                        value={qrCode}
                        className="input input_simple-text"
                        onChange={handleChange}
                        placeholder="Enter the code that was generated"
                    />
                </li>
            </ul>
            <div className="m-auto d-flex align-items-center justify-content-center mt-4">
                <Button type={"button"} className="_fw border-pink-500 text-pink-600 border-2 flex items-center w-32 justify-center rounded-md px-3 py-2 text-sm font-semibold  shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  focus-visible:outline-pink-600  text-pink-600 bg-pink-500 text-white hover:bg-pink-600" onClick={() => {
                    if (qrCode == undefined || qrCode == '') {
                        alert("Please enter code")
                    } else {
                        let postData = {
                            userId: userData?.id,
                            totp: qrCode,
                            isTFAEnabled: false,
                        };

                        post2FAVerification(
                            Object.assign(postData, {
                                params: { isAuth: true, token: userData?.token },
                            }),
                        );

                        // POST_DATA(POST_QR_CODE, userData?.token, postData, (data, flag) => {
                        //     console.log(data)
                        //     if (flag == false && data?.otpValid) {
                        //         dispatch(closeModal());
                        //         const updateUser = {
                        //             ...userData,
                        //             twoFactorAuthEnabled: data?.isTFAEnabled,
                        //         }
                        //         updateUserData(updateUser);
                        //         setTimeout(() => {
                        //             ShowSuccess('2FA disable successfully', toast);
                        //         }, 350);
                        //     } else {
                        //         ShowError(data, toast);
                        //     }
                        // });
                    }
                }}>
                    Disable
                </Button>
            </div>
        </div>
    )
}

export default QrDisableModal
