import React from "react"
import formatRelativeDate from "../../utils/dateUtils"
import TagLink from "./TagLink"

const PostContent = ({ city, country, nickname, text, tags, date, song }) => {
  return (
    <>
      <div className="content-post__wrapper d-flex flex-column">
        <div className="d-flex flex-row align-items-center justify-content-between">
          {/* <div className="content-post__gap d-flex flex-row align-items-center">
            <svg width="12" height="12" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.75 5.65888C1.75 3.35895 3.67194 1.5 5.99672 1.5C8.32806 1.5 10.25 3.35895 10.25 5.65888C10.25 6.81785 9.8285 7.89381 9.13475 8.80579C8.3694 9.81176 7.42608 10.6882 6.36427 11.3762C6.12126 11.5352 5.90194 11.5472 5.63522 11.3762C4.56737 10.6882 3.62404 9.81176 2.86525 8.80579C2.17099 7.89381 1.75 6.81785 1.75 5.65888ZM4.59711 5.78838C4.59711 6.55885 5.22583 7.16483 5.99672 7.16483C6.76812 7.16483 7.40289 6.55885 7.40289 5.78838C7.40289 5.0239 6.76812 4.38842 5.99672 4.38842C5.22583 4.38842 4.59711 5.0239 4.59711 5.78838Z"
              />
            </svg>
            <p className="text-s">
              {city}, {country}
            </p>
          </div> */}

          <div className="content-post__gap d-flex flex-row align-items-center">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.64699 3.64553C8.64699 5.11404 7.46955 6.29155 6 6.29155C4.53095 6.29155 3.35301 5.11404 3.35301 3.64553C3.35301 2.17701 4.53095 1 6 1C7.46955 1 8.64699 2.17701 8.64699 3.64553ZM6 11C3.83119 11 2 10.6475 2 9.28748C2 7.92697 3.84269 7.58697 6 7.58697C8.16931 7.58697 10 7.93947 10 9.29948C10 10.66 8.15731 11 6 11Z"
              />
            </svg>

            <p className="text-s">@{nickname}</p>
          </div>
        </div>

        <div className="content-post__text-wrapper">
          <p className="content-post__text">{text}</p>

          <ul className="content-post__tags-list">
            {tags?.map((tag) => (
              <TagLink text={tag} link={`/search-tags/${tag}`} key={tag} />
            ))}
          </ul>
        </div>

        {song && (
          <div className="content-post__music d-flex flex-row align-items-center">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 1.5C7.72385 1.5 7.5 1.72386 7.5 2V4.66667C7.5 4.66658 7.5 4.66676 7.5 4.66667V9.50634C7.00585 9.08658 6.36583 8.83333 5.66666 8.83333C4.10185 8.83333 2.83333 10.1019 2.83333 11.6667C2.83333 13.2315 4.10185 14.5 5.66666 14.5C7.23147 14.5 8.5 13.2315 8.5 11.6667C8.5 11.5875 8.49675 11.509 8.49037 11.4315C8.49668 11.3997 8.5 11.3669 8.5 11.3333L8.5 5.16667H12C13.0125 5.16667 13.8333 4.34586 13.8333 3.33333C13.8333 2.32081 13.0125 1.5 12 1.5H8Z" />
            </svg>
            <p className="text-s">{song}</p>
          </div>
        )}

        <p className="content-post__date text-s">{formatRelativeDate(date)}</p>
      </div>
    </>
  )
}

export default PostContent
