import React, {useEffect, useRef} from 'react';
import PostContent from '../profilePost/items/postContent/PostContent';
import ReelAuthorInfo from './ReelAuthorInfo';
import LikesReelBtn from './reelBtns/LikesReelBtn';
import CommentsReelBtn from './reelBtns/CommentsReelBtn';
import ShareReelBtn from './reelBtns/ShareReelBtn';
import EllipsisMenu from '../ellipsisMenu/EllipsisMenu';
import VideoReel from './VideoReel';

import PropTypes from 'prop-types';
import useWindowSize from '../../../../../hooks/useWindowSize';
import EllipsisMenuV2 from '../ellipsisMenu/EllipsisMenuV2';
import {
  categorizeUrlsFromArray,
  extractFieldValues,
} from '../profilePost/ProfilePostV2';

const ProfileReel = ({reel, currentPostId, isMyPost = false}) => {
  const {user, upvotes, summary, createdAt, comments} = reel?.object || {};
  const scrollRef = useRef([]);
  const {width} = useWindowSize();
  // useEffect(() => {
  //   if (reel?.id === currentPostId) {
  //     window.location.href = `#reel-${currentPostId ? currentPostId : 0}`;
  //   }
  // }, [reel]);
  // console.log('reel===>', reel);
  useEffect(() => {
    if (reel?.id === currentPostId) {
      scrollRef.current[currentPostId].scrollIntoView();
    }
  }, [reel]);

  const avatar = extractFieldValues(reel?.owner?.member?.fields)?.avatar;

  return (
    <li
      ref={ref => (scrollRef.current[reel?.id] = ref)}
      key={reel?.id}
      className="reels__item bg-black"
      id={`reel-${reel?.id}`}>
      <p className="reels__item-author-top">@{reel?.owner?.member?.username}</p>

      <VideoReel reel={categorizeUrlsFromArray(reel?.fields)?.video?.[0]} />

      <div className="reels__info">
        <ReelAuthorInfo
          userName={reel?.owner?.member?.name}
          userNickname={reel?.owner?.member?.username}
          userPosition={''}
          userAvatar={avatar}
          isVerified={reel?.owner?.member?.status === 'VERIFIED'}
        />

        <PostContent
          city={''}
          country={''}
          nickname={reel?.owner?.member?.username}
          text={reel?.content || ''}
          tags={[]}
          date={new Date(reel?.publishedAt)}
          song={''}
        />
      </div>

      <ul className="reels__buttons-list">
        <li className="reels__buttons-list-item">
          <LikesReelBtn
            isFromPost={width > 992 ? true : false}
            likes={reel?.reactionsCount}
            id={reel?.id}
            likesList={reel?.reactions}
          />
        </li>
        <li className="reels__buttons-list-item">
          <CommentsReelBtn
            isFromPost={width > 992 ? true : false}
            reel={reel}
          />
        </li>
        <li className="reels__buttons-list-item">
          <ShareReelBtn
            userName={reel?.owner?.member?.name}
            userAvatar={extractFieldValues(reel?.owner?.member?.fields)?.avatar}
            reelId={reel?.id}
            reel={reel}
          />
        </li>
        <li className="reels__buttons-list-item">
          <EllipsisMenuV2
            type="reel"
            post={reel}
            isReel
            isMyPost={isMyPost}
            Id={reel?.id}
          />
        </li>
      </ul>
    </li>
  );
};

ProfileReel.propTypes = {
  reel: PropTypes.shape({
    id: PropTypes.number.isRequired,
    video: PropTypes.objectOf(PropTypes.string).isRequired,
  }).isRequired,
  currentPostId: PropTypes.number.isRequired,
};

export default ProfileReel;
