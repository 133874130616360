import React, {Fragment, useState, useEffect, useRef} from 'react';
import {useNavigation} from '@react-navigation/native';
import {useBuzzmiAuth} from '../../../../../../appState/hooks/useBuzzmiAuth/index.web';
import {Dialog, Transition} from '@headlessui/react';
import {XMarkIcon} from '@heroicons/react/24/outline';
import {useGetNotifications} from '../../../../../../appState/hooks/useGetNotifications';
import {useHubApp} from '../../../../../../appState/hooks/useHubApp/index.web';
import {Dots} from 'react-activity';
import {colors} from '../../../../../../res/colors';
import Loading from 'react-fullscreen-loading';
import {usePostNotificationStatus} from '../../../../../../appState/hooks/usePostNotificationStatus';
import {EventRegister} from 'react-native-event-listeners';
import {ShowError, ShowSuccess} from '../../../../../HOC/MessageWeb';
import {useToast} from 'react-native-toast-notifications';

const NotificationsModal = ({setOpen, open}) => {
  const {userData} = useBuzzmiAuth();
  const {navigate} = useNavigation();
  const {getNotificationsList, notificationsList} = useHubApp();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setpageLoading] = useState(false);
  const toast = useToast();
  const [notifications, setNotifications] = useState([]);
  const [currPage, setCurrPage] = useState(1);
  const scrollDivRef = useRef(null);
  const limit = 10;
  const [showLoadMore, setShowLoadMore] = useState(true);

  const loadNotifications = setLoading => {
    getNotificationsList(userData?.token, setLoading, {
      page: currPage,
      limit: limit,
    });
  };
  useEffect(() => {
    if (userData) {
      if (currPage == '1') {
        loadNotifications(setLoading);
      } else {
        loadNotifications(setpageLoading);
      }
    }
  }, [userData, currPage]);

  useEffect(() => {
    if (currPage == '1') {
      setNotifications(notificationsList);
    } else {
      setNotifications([...notifications, ...notificationsList]);
      notificationsList?.length < limit ? setShowLoadMore(false) : null;
    }
    // console.log('notifications==>', notifications);
  }, [notificationsList]);
  const onLoadMoreClick = () => setCurrPage(String(Number(currPage) + 1));

  const NotificationItem = ({item, index, onPress}) => {
    const options =
      item?.feedType == 'assignTask'
        ? [
            {title: 'Accept', onPress: () => onPress('Accept')},
            {title: 'Reject', onPress: () => onPress('Reject')},
            {title: 'Ignore', onPress: () => onPress('Ignore')},
          ]
        : [];

    return (
      <div className="pointer-events-auto flex w-full mb-3 divide-x divide-gray-200 rounded-lg bg-white shadow-sm ring-1 ring-black ring-opacity-5">
        <div className="flex w-0 flex-1 items-center p-4">
          <div className="flex-shrink-0 pt-0.5">
            <img
              className="h-10 w-10 rounded-full"
              src={item?.senderUser?.avatar}
              alt=""
            />
          </div>
          <div className="w-full ml-3">
            <p className="text-sm font-medium text-gray-900">
              {item?.senderUser?.firstName + ' ' + item?.senderUser?.lastName}
            </p>
            <p className="mt-1 text-sm text-gray-500">{item?.title}</p>
          </div>
        </div>
        <div className="flex">
          <div className="flex flex-col divide-y divide-gray-200">
            {options.map((item, index) => (
              <div className="flex h-0 flex-1">
                <button
                  type="button"
                  className={`flex w-full items-center justify-center rounded-none ${
                    index == 0
                      ? 'rounded-tr-lg'
                      : index == 1
                      ? ''
                      : 'rounded-br-lg '
                  }border border-transparent px-4 py-2 text-sm font-medium text-pink-500 hover:text-pink-500 focus:z-10 focus:outline-none focus:ring-2 focus:ring-pink-500`}
                  onClick={item?.onPress}>
                  {item?.title}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const {
    postMyNotificationStatus,
    postResponse,
    isNotificationStatusLoading,
    postErrorResponse,
  } = usePostNotificationStatus();

  const onPress = (title, item) => {
    console.log('title', title, item, userData?.token);

    let postData = {status: title, AssignTaskId: item?.itemId};
    postMyNotificationStatus(
      Object.assign(postData, {
        params: {isAuth: true, token: userData?.token},
      }),
    );
  };
  useEffect(() => {
    if (postResponse) {
      ShowSuccess(postResponse?.message ? postResponse?.message.toString() : postResponse.toString(), toast);
      loadNotifications(setLoading);
      EventRegister.emit('refresh-planActivities');
    }
    if (postErrorResponse) {
      ShowError(postErrorResponse.toString(), toast);
    }
  }, [postResponse, postErrorResponse]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full">
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                          Notifications
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2"
                            onClick={() => setOpen(false)}>
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <Loading
                      loading={loading || isNotificationStatusLoading}
                      background="transparent"
                      loaderColor={colors.PRIMARY_COLOR}
                    />
                    {/* {console.log('notifications', notifications)} */}
                    <div
                      ref={scrollDivRef}
                      // style={{overflowY: 'auto'}}
                      className="relative mt-6 flex-1 px-4 sm:px-6">
                      {notifications.map((item, index) => {
                        return (
                          <NotificationItem
                            item={item}
                            index={index}
                            onPress={i => onPress(i, item)}
                          />
                        );
                      })}
                    </div>
                    {showLoadMore ? (
                      <>
                        {!pageLoading && (
                          <div
                            className="text-alt-primary cursor-pointer pb-2"
                            style={{
                              display: 'flex',
                              justifyContent: 'center', // Horizontally center content
                              alignItems: 'center', // Vertically center content
                              width: '100%', // Set the width to 100% if needed
                            }}
                            onClick={onLoadMoreClick}>
                            Load More
                          </div>
                        )}
                        {pageLoading && (
                          <div
                            className="pb-2"
                            style={{
                              display: 'flex',
                              justifyContent: 'center', // Horizontally center content
                              alignItems: 'center', // Vertically center content
                              width: '100%', // Set the width to 100% if needed
                              // Add any other inline styles you want
                            }}>
                            <Dots color={colors.PRIMARY_COLOR} />
                          </div>
                        )}
                      </>
                    ) : null}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default NotificationsModal;
