import React from 'react';

const GoogleSvg = () => {
  return (
    <svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M33.32 17.8869C33.32 16.6815 33.2118 15.5224 33.0109 14.4097H17V20.9856H26.1491C25.755 23.1106 24.5573 24.911 22.7568 26.1165V30.3819H28.2509C31.4654 27.4224 33.32 23.0642 33.32 17.8869Z" fill="#4285F4"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M16.9999 34.5C21.5899 34.5 25.4381 32.9777 28.2508 30.3813L22.7567 26.1159C21.2345 27.1359 19.2872 27.7386 16.9999 27.7386C12.5722 27.7386 8.82447 24.7482 7.48765 20.73H1.80811V25.1345C4.60538 30.6904 10.3545 34.5 16.9999 34.5Z" fill="#34A853"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.48773 20.7298C7.14773 19.7098 6.95455 18.6202 6.95455 17.4998C6.95455 16.3793 7.14773 15.2898 7.48773 14.2698V9.86523H1.80818C0.656818 12.1602 0 14.7566 0 17.4998C0 20.2429 0.656818 22.8393 1.80818 25.1343L7.48773 20.7298Z" fill="#FBBC05"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M16.9999 7.26136C19.4958 7.26136 21.7367 8.11909 23.4985 9.80363L28.3745 4.92772C25.4304 2.18454 21.5822 0.5 16.9999 0.5C10.3545 0.5 4.60538 4.30954 1.80811 9.86545L7.48765 14.27C8.82447 10.2518 12.5722 7.26136 16.9999 7.26136Z" fill="#EA4335"/>
    </svg>
  );
};

export default GoogleSvg;