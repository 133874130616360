import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useBuzzmiAuth } from '../../../../../../appState/hooks/useBuzzmiAuth/index.web';
import { openModal } from '../../../../../../store/slices/modalSlice/modalSlice';
import EditProfileBoxForm from '../EditProfileBoxForm';
import { useAtom } from 'jotai';
import { userSocialLinkAtom } from '../../../../../../appState/hooks/ProfileState'


const socials = [
  {
    name: 'Facebook',
    linked: false,
  },
  {
    name: 'Twitter',
    linked: false,
  },
  {
    name: 'Youtube',
    linked: false,
  },
  {
    name: 'LinkedIn',
    linked: false,
  },
];

const EditProfileSocial = ({ paddingMode, setValue }) => {
  const dispatch = useDispatch();
  const { userData } = useBuzzmiAuth();
  const [socialLinks, setSocialLink] = useAtom(userSocialLinkAtom);
  let objassign = socialLinks;

  useEffect(() => {
    userData?.isFacebookConnect && (objassign.facebook = userData?.facebookLink)
    userData?.isTwitterConnect&& (objassign.twitter = userData?.twitterLink)
    userData?.isYoutubeConnect && (objassign.youtube = userData?.youtubeLink)
    userData?.isLinkedInConnect && (objassign.linkedin = userData?.linkedInLink)
    setSocialLink(objassign)
  }, [userData])

  const handlerThroghLink = (name) => {
    dispatch(
      openModal({
        modalName: 'AddSocialMediaConnection',
        modalType: 'swipe',
        modalProps: { title: name },
        callback: Links => {
          name == "Facebook" && (objassign.facebook = Links)
          name == "Twitter" && (objassign.twitter = Links)
          name == "Youtube" && (objassign.youtube = Links)
          name == "LinkedIn" && (objassign.linkedin = Links)
          setSocialLink(objassign)
          console.log(socialLinks)
          setValue('socialLink', {
            "facebook": socialLinks?.facebook || null,
            "twitter": socialLinks?.twitter || null,
            "youtube": socialLinks?.youtube || null,
            "linkedin": socialLinks?.linkedin || null,
          });
        },
      }),
    );
  };

  return (
    <EditProfileBoxForm
      title="Social media"
      className="profile-social"
      paddingMode={paddingMode}>
      {socials.map(({ name, linked }, index) => (
        <div className="profile-social__item" key={index + name}>
          <h5 className="profile-social__title h5">{name} </h5>
          {socialLinks[name.toLowerCase()] != null ? (
            <div className="profile-social__btn profile-social__btn_active">
              <button type={'button'} onClick={() => { handlerThroghLink(name) }}>
                <span>CONNECTED</span>
              </button>
            </div>
          ) : (
              <div className="profile-social__btn ">
                <button type={'button'} onClick={() => { handlerThroghLink(name) }}>
                  <span className="text-alt-primary">CONNECT</span>
                </button>
              </div>
            )}
        </div>
      ))}
    </EditProfileBoxForm>
  );
};

export default EditProfileSocial;
