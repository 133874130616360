import React from 'react';
import Button from '../../../../../reusable/btns/buttons/Button';

const LogoutDesktopAlert = ({
  handleLogoutFromAllUsers,
  handleLogoutOnlyBaseTask,
}) => {
  return (
    <div className="d-none d-md-block">
      <div className="delete-tasks__text">
        <div className="h4 mb-21">Are you sure?</div>
        <div className="text-r mb-6">You want to logout</div>
      </div>

      <div className="delete-tasks__buttons">
        <div className="d-flex justify-content-center mb-3">
          <Button
            className="_fw button_secondary button_m double-secondary"
            onClick={handleLogoutOnlyBaseTask}>
            {/* Delete only base task */}
            Logout
          </Button>
        </div>
        <div className="d-flex justify-content-center">
          <Button
            className="_fw button_secondary button_m double-secondary"
            onClick={handleLogoutFromAllUsers}>
            {/* Delete from all */}
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LogoutDesktopAlert;
