import { useAtom } from 'jotai';
import { userBaseActivitiesList } from '../hubState';
import { getBaseActivities } from '../../../network/api/app';

export const useBaseActivities = () => {
  const [baseActivitiesList, setBaseActivitiesList] = useAtom(
    userBaseActivitiesList,
  );

  const getBaseActivitiesList = async (token, setLoading, params) => {
    // console.log('log here in ', token, params);
    setLoading(true);
    try {
      console.log("getBaseActivities params")
      console.log(params)
      console.log(token)
      const response = await getBaseActivities(params)({ token });
      // console.log('response in base ', response);
      setBaseActivitiesList(response);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log('error => ', e.message);
    }
  };

  return {
    baseActivitiesList,
    getBaseActivitiesList,
  };
};
