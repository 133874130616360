import React, {useEffect} from 'react';
import {useBuzzmiAuth} from '../../../../../../appState/hooks/useBuzzmiAuth/index.web';
import {useHubApp} from '../../../../../../appState/hooks/useHubApp/index.web';
import Badge from '../../../../../../components/reusable/badge/Badge.jsx';

import Box from '../../../../../../components/reusable/cards/box/Box';
import cn from 'classnames';

const UserProfileTags = () => {
  const {getUserTags, userTags} = useHubApp();
  const {userData} = useBuzzmiAuth();

  useEffect(() => {
    getUserTags(userData?.id, userData?.token);
  }, [userData])

  return (
    <Box paddingMode="big" className="profile-user__info-block">
      <div className="profile-user__block-title">Tags</div>

      <div
        className={cn('badge__row', {
          badge__row_wrap: true,
        })}>
        {userTags?.length > 0 ? (
          userTags.map((tag, index) => (
            <Badge
              active={false}
              // type={tag}
              title={tag?.tagName}
              index={index}
              key={index}
              onClick={() => {
                console.log(tag);
              }}
            />
          ))
        ) : (
          <h6 className="h6 mb-3 mb-lg-4">{'No Tag Found'}</h6>
        )}
      </div>
    </Box>
  );
};

export default UserProfileTags;
