import React from "react"

const EllipsisIcon = ({ isHovered }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      {isHovered && (
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#FF41B3" />
            <stop offset="100%" stopColor="#7E00E0" />
          </linearGradient>
        </defs>
      )}
      <path
        d="M4.99999 11.6673C4.54166 11.6673 4.14944 11.504 3.82333 11.1773C3.49666 10.8512 3.33333 10.459 3.33333 10.0007C3.33333 9.54232 3.49666 9.14982 3.82333 8.82315C4.14944 8.49704 4.54166 8.33398 4.99999 8.33398C5.45833 8.33398 5.85055 8.49704 6.17666 8.82315C6.50333 9.14982 6.66666 9.54232 6.66666 10.0007C6.66666 10.459 6.50333 10.8512 6.17666 11.1773C5.85055 11.504 5.45833 11.6673 4.99999 11.6673ZM9.99999 11.6673C9.54166 11.6673 9.14944 11.504 8.82333 11.1773C8.49666 10.8512 8.33333 10.459 8.33333 10.0007C8.33333 9.54232 8.49666 9.14982 8.82333 8.82315C9.14944 8.49704 9.54166 8.33398 9.99999 8.33398C10.4583 8.33398 10.8508 8.49704 11.1775 8.82315C11.5036 9.14982 11.6667 9.54232 11.6667 10.0007C11.6667 10.459 11.5036 10.8512 11.1775 11.1773C10.8508 11.504 10.4583 11.6673 9.99999 11.6673ZM15 11.6673C14.5417 11.6673 14.1492 11.504 13.8225 11.1773C13.4964 10.8512 13.3333 10.459 13.3333 10.0007C13.3333 9.54232 13.4964 9.14982 13.8225 8.82315C14.1492 8.49704 14.5417 8.33398 15 8.33398C15.4583 8.33398 15.8508 8.49704 16.1775 8.82315C16.5036 9.14982 16.6667 9.54232 16.6667 10.0007C16.6667 10.459 16.5036 10.8512 16.1775 11.1773C15.8508 11.504 15.4583 11.6673 15 11.6673Z"
        fill={isHovered ? "url(#gradient)" : "#C0BACB"}
      />
    </svg>
  )
}

export default EllipsisIcon
