import React, { useEffect, useState } from 'react';
import ProfilePost from './profilePost/ProfilePost';
import ProfilePostTile from './profilePost/ProfilePostTile';
import ProfileReel from './profileReels/ProfileReel';
import ProfileReelTile from './profileReels/ProfileReelTile';
import useWindowSize from '../../../../hooks/useWindowSize';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useProfileApp } from '../../../../appState/hooks';
import { useBuzzmiAuth } from '../../../../appState/hooks/useBuzzmiAuth/index.web';

const ProfileViewContentList = props => {
  const { isPosts, currentId, isTableActive } = props;
  //   const [isTableActive, setIsTableActive] = useState(true);
  const [currentPostId, setCurrentPostId] = useState(currentId);

  const { width } = useWindowSize();
  const { userReels, userPosts, postUploading } = useProfileApp();


  useEffect(() => {
    console.log("****userReels****")
    console.log(userReels)
  }, [userReels])

  return (
    <>
      <ul
        className={
          isPosts ? 'profile-posts-list__column' : 'reels__list-column'
        }>
        {isPosts ? (
          userPosts && userPosts.length > 0 ? (
            userPosts.map(post => (
              <ProfilePost
                key={post.id}
                postData={post}
                currentPostId={currentPostId}
                type="post"
                isMyPost={true}
              />
            ))
          ) : (
              <div style={{ textAlign: 'center', width: '100%' }}>
                <h5 className="h5 mb-3 mb-lg-4" style={{ textAlign: 'center' }}>
                  {'No Post Found'}
                </h5>
              </div>
            )
        ) : userReels && userReels.length > 0 ? (
          userReels.map((reel, index) => (
            <React.Fragment key={reel.id}>
              {width < 767 ? (
                <ProfilePost
                  postData={reel}
                  currentPostId={currentPostId}
                  type="reel"
                  isMyPost={true}
                />
              ) : (
                  <ProfileReel reel={reel} isMyPost={true} currentPostId={currentPostId} />
                )}
            </React.Fragment>
          ))
        ) : (
              <div style={{ textAlign: 'center', width: '100%' }}>
                <h5 className="h5 mb-3 mb-lg-4" style={{ textAlign: 'center' }}>
                  {'No Reel Found'}
                </h5>
              </div>
            )}
      </ul>
    </>
  );
};

ProfileViewContentList.propTypes = {
  isPosts: PropTypes.bool.isRequired,
};

export default ProfileViewContentList;
