import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

const SmallMenu = ({children, showMenu, setShowMenu, btn}) => {
  const menuRef = useRef(null);

  const handleMenu = event => {
    event.preventDefault();
    event.stopPropagation();
    if (!menuRef.current.contains(event.target)) {
      setShowMenu(!showMenu);
    }
  };

  useEffect(() => {
    if (showMenu) {
      document.addEventListener('click', handleMenu);
    } else {
      document.removeEventListener('click', handleMenu);
    }
    return () => {
      document.removeEventListener('click', handleMenu);
    };
  }, [showMenu]);

  useEffect(() => {
    const handleMenu = event => {
      event.preventDefault();
      event.stopPropagation();
      if (!menuRef?.current?.contains(event?.target)) {
        setShowMenu(false);
      }
    };

    if (showMenu) {
      document.addEventListener('click', handleMenu);
    } else {
      document.removeEventListener('click', handleMenu);
    }
  }, [showMenu]);

  return (
    <div className={`menu-card menu-card_reusable ${showMenu ? 'open' : ''}`}>
      <button onClick={handleMenu}>
        {!btn ? (
          <img src="/images/assets/info-card/dots_menu.svg" alt="dots menu" />
        ) : (
          btn
        )}
      </button>
      <div
        ref={menuRef}
        className={`menu-card__wrapper ${showMenu ? 'open' : ''}`}>
        {children}
      </div>
    </div>
  );
};

SmallMenu.propTypes = {
  children: PropTypes.node,
  showMenu: PropTypes.bool,
  setShowMenu: PropTypes.func,
  btn: PropTypes.node,
};

export default SmallMenu;
