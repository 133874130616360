import {useAtom} from 'jotai';
import {
  userPlannedActivities,
  userPlannedActivitiesGetTime,
  userPlannedActivitiesStats,
  userTodayTasks,
} from '../hubState';
import {
  getPlannedActivitiesList,
  getPlannedActivitiesListStats,
  getTodayPlannedTasksList,
} from '../../../network/api/app';

export const getCurrentTime = () => {
  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const amOrPm = hours >= 12 ? 'pm' : 'am';

  // Format hours to be in 12-hour format
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

  // Add leading zeros to minutes if needed
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${formattedHours}:${formattedMinutes} ${amOrPm}`;
};

export const usePlannedActivities = () => {
  const [plannedActivities, setPlannedActivities] = useAtom(
    userPlannedActivities,
  );
  const [plannedActivitiesStats, setPlannedActivitiesStats] = useAtom(
    userPlannedActivitiesStats,
  );
  const [userTodayPlanTasks, setUserTodayPlanTasks] = useAtom(userTodayTasks);
  const [plannedActivitiesGetTime, setPlannedActivitiesGetTime] = useAtom(
    userPlannedActivitiesGetTime,
  );

  const getPlannedActivities = async (token, setLoading = () => {}, params) => {
    // console.log('getPlannedActivities==>', params);
    setLoading(true);
    try {
      const response = await getPlannedActivitiesList(params)({token});
      setPlannedActivities(response);
      setPlannedActivitiesGetTime(getCurrentTime());
      setLoading(false);
    } catch (e) {
      console.log('error => ', e.message);
      setLoading(false);
    }
  };

  const getTodayTasks = async (token, setLoading = () => {}, params) => {
    setLoading(true);
    try {
      const response = await getTodayPlannedTasksList(params)({token});
      setUserTodayPlanTasks(response);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log('error => ', e.message);
    }
  };
  const getPlannedActivitiesStat = async (
    token,
    setLoading = () => {},
    params,
  ) => {
    // console.log('getPlannedActivities stats==>', params);
    setLoading(true);
    try {
      const response = await getPlannedActivitiesListStats(params)({token});
      // console.log('response in planned stats', response);
      setPlannedActivitiesStats(response);
      setLoading(false);
    } catch (e) {
      console.log('error => ', e.message);
      setLoading(false);
    }
  };
  return {
    plannedActivities,
    getPlannedActivities,
    getTodayTasks,
    userTodayPlanTasks,
    getPlannedActivitiesStat,
    plannedActivitiesStats,
  };
};
