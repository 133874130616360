import React from 'react';

export const options = [
  {
    label: (
      <div className={'select__item'}>
        <img
          className={'select__icon'}
          src="https://hatscripts.github.io/circle-flags/flags/us.svg"
          width="48"
        />
        <span>English</span>
      </div>
    ),
    value: 'en',
  },
  {
    label: (
      <div className={'select__item'}>
        <img
          className={'select__icon'}
          src="https://hatscripts.github.io/circle-flags/flags/es.svg"
          width="48"
        />
        <span>Spain</span>
      </div>
    ),
    value: 'es',
  },
  {
    label: (
      <div className={'select__item'}>
        <img
          className={'select__icon'}
          src="https://hatscripts.github.io/circle-flags/flags/it.svg"
          width="48"
        />
        <span>Italy</span>
      </div>
    ),
    value: 'it',
  },
  {
    label: (
      <div className={'select__item'}>
        <img
          className={'select__icon'}
          src="https://hatscripts.github.io/circle-flags/flags/sk.svg"
          width="48"
        />
        <span>Slovak</span>
      </div>
    ),
    value: 'sk',
  },
];

export const phoneNumbersCode = [
  {
    label: (
      <div className={'select__item'}>
        US &nbsp;<span>+1</span>
      </div>
    ),
    value: '+1',
  },
  {
    label: (
      <div className={'select__item'}>
        UA &nbsp;<span>+38</span>
      </div>
    ),
    value: '+38',
  },
  {
    label: (
      <div className={'select__item'}>
        PL &nbsp;<span>+48</span>
      </div>
    ),
    value: '+48',
  },
  {
    label: (
      <div className={'select__item'}>
        CN &nbsp;<span>+86</span>
      </div>
    ),
    value: '+86',
  },
  {
    label: (
      <div className={'select__item'}>
        GB &nbsp;<span>+44</span>
      </div>
    ),
    value: '+44',
  },
  {
    label: (
      <div className={'select__item'}>
        ID &nbsp;<span>+62</span>
      </div>
    ),
    value: '+62',
  },
  {
    label: (
      <div className={'select__item'}>
        IN &nbsp;<span>+91</span>
      </div>
    ),
    value: '+91',
  },
  {
    label: (
      <div className={'select__item'}>
        PK &nbsp;<span>+92</span>
      </div>
    ),
    value: '+92',
  },
  {
    label: (
      <div className={'select__item'}>
        LK &nbsp;<span>+94</span>
      </div>
    ),
    value: '+94',
  },
];

export const genderOptions = [
  {
    label: (
      <div className={'select__item'}>
        <span>Male</span>
      </div>
    ),
    value: 'M',
  },
  {
    label: (
      <div className={'select__item'}>
        <span>Female</span>
      </div>
    ),
    value: 'F',
  },
];
