import React from 'react';
import UnderDevelopmentScreen from '../../container/App/Account/UnderDevelopmentScreen';
import WalletActivityScreen from '../../container/App/Account/WalletActivityScreen';
import AccountVerification from '../../container/App/Account/AccountVerification';
// import ProfileEventTypes from '../../container/App/Events/ProfileEventTypes';
import EventAvailability from '../../container/App/Events/EventAvailability';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import StripePaymentScreen from '../../container/App/StripePaymentScreen';
import TimezonePicker from '../../container/App/Events/TimezonePicker';
// import TimeSlotPicker from '../../container/App/Events/TimeSlotPicker';
import EventSettings from '../../container/App/Events/EventSettings';
import WalletScreen from '../../container/App/Account/WalletScreen';
import DoNotDisturb from '../../container/App/Account/DoNotDisturb';
import Notifications from '../../container/App/Home/Notifications';
import BookedEvents from '../../container/App/Events/BookedEvents';
import EventDetails from '../../container/App/Events/EventDetails';
import VerifyOTPScreen from '../../container/Auth/VerifyOTPScreen';
// import EditProfile from '../../container/App/Account/EditProfile';
import CreateEvent from '../../container/App/Events/CreateEvent';
import EventTypes from '../../container/App/Events/EventTypes';
// import BookEvent from '../../container/App/Events/BookEvent';
import Setting from '../../container/App/Account/Setting';
import HubScreen from '../../container/App/Hub/HubScreen';
import Snooze from '../../container/App/Account/Snooze';
import Account from '../../container/App/Account';
import ChatFontSetting from '../../container/App/Account/ChatFontSetting';
import AddFriend from '../../container/App/Account/AddFriend';
import Contributors from '../../container/App/Contributors';
import { options, options_modal } from './utils';
import FinishAccountSetup from '../../container/App/Account/FinishAccountSetup';
import HubTasks from '../../container/App/Hub/HubTasks';
import HubTasksV2 from '../../container/App/Hub/HubTasksV2';

import AddActivities from '../../container/App/Hub/AddActivities';

import AddActivitiesv2 from '../../container/App/Hub/AddActivitiesv2';

import AddTask from '../../container/App/Hub/AddTask';

import HubTaskDetails from '../../container/App/Hub/HubTaskDetails';
import AddTaskTwo from '../../container/App/Hub/AddTaskTwo';
import ChooseCoverImage from '../../container/App/Hub/ChooseCoverImage';
import HubPresetDetails from '../../container/App/Hub/HubPresetDetails';
import HubTaskImagePreview from '../../container/App/Hub/HubTaskVideoPreview';
import HubTaskVideoPreview from '../../container/App/Hub/HubTaskVideoPreview';
import ProfileMain from '../../container/App/Profile/ProfileMain/ProfileMain';
import ProfileMainV2 from '../../container/App/Profile/ProfileMain/ProfileMainV2';
import CommentsScreen from '../../container/App/Profile/items/comments/CommentsScreen';
import ProfilePremiumContent from '../../container/App/Profile/profilePremiumContent/ProfilePremiumContent';
import EditProfile from '../../container/App/Profile/editProfile/EditProfile';
import PremiumContentReviewsPage from '../../container/App/Profile/premiumContentReviews/PremiumContentReviewsPage';
import VideoSessions from '../../container/App/Profile/videoSessions/VideoSessions';
import GemsPostScreen from '../../container/App/Profile/items/profilePost/GemsPostScreen';
import GiftsPostScreen from '../../container/App/Profile/items/profilePost/GiftsPostScreen';
import AddPosts from '../../container/App/Profile/AddPosts/index.web';
import ProfileUser from '../../container/App/Profile/ProfileUser/ProfileUser';
import ProfileMobileAboutV2 from '../../container/App/Profile/profileMobileAbout/ProfileMobileAboutV2';
import ProfileMainV3 from '../../container/App/Profile/ProfileMain/ProfileMainV3';

import ReelsFullscreen from '../../container/App/Profile/items/profileReels/ReelsFullscreen';
import VisitorReelsFullscreen from '../../container/App/Profile/items/profileReels/VisitorReelsFullscreen';

import VideoFullscreen from '../../container/App/Profile/items/profileReels/VideoFullscreen';


import VisiorMembers from '../../container/App/Profile/items/visitorShop/VisiorMembers';
import VisitorViewContentList from '../../container/App/Profile/items/VisitorViewContentList';
import UserProfileViewContentList from '../../container/App/Profile/items/UserProfileViewContentList';
import BookmarkViewContentList from '../../container/App/Profile/items/BookmarkViewContentList';
import ProfileRattings from '../../container/App/Profile/items/ProfileRattings';
import UserReviewContentPage from '../../container/App/Profile/premiumContentReviews/UserReviewContentPage';

const Stack = createNativeStackNavigator();

const ProfileNavigator = () => {
  return (
    <Stack.Navigator initialRouteName={'ProfileV3'}>

      <Stack.Screen
        name={'ProfileV3'}
        component={ProfileMainV3}
        options={options}
      />

      <Stack.Screen
        name={'ProfileRattings'}
        component={ProfileRattings}
        options={options}
      />

      <Stack.Screen
        name={'UserRattings'}
        component={UserReviewContentPage}
        options={options}
      />

      <Stack.Screen
        name={'ProfileV2'}
        component={ProfileMainV2}
        options={options}
      />
      <Stack.Screen
        name={'Profile'}
        component={ProfileMain}
        options={options}
      />
      <Stack.Screen
        name={'CommentsScreen'}
        component={CommentsScreen}
        options={options}
      />
      <Stack.Screen
        name={'EditProfile'}
        component={EditProfile}
        options={options}
      />
      <Stack.Screen
        name={'ProfilePremiumContent'}
        component={ProfilePremiumContent}
        options={options}
      />
      <Stack.Screen
        name={'PremiumContentReviews'}
        component={PremiumContentReviewsPage}
        options={options}
      />
      <Stack.Screen
        name={'VideoSessions'}
        component={VideoSessions}
        options={options}
      />
      <Stack.Screen
        name={'GemsPostScreen'}
        component={GemsPostScreen}
        options={options}
      />
      <Stack.Screen
        name={'GiftsPostScreen'}
        component={GiftsPostScreen}
        options={options}
      />

      <Stack.Screen
        options={options}
        name={'Notifications'}
        component={Notifications}
      />
      <Stack.Screen
        options={options}
        name={'Contributors'}
        component={Contributors}
      />
      <Stack.Screen
        options={options}
        name={'UnderDevelopmentScreen'}
        component={UnderDevelopmentScreen}
      />


      <Stack.Screen
        name={'AddFriend'}
        component={AddFriend}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        options={options}
        name={'Wallet'}
        component={WalletScreen}
      />

      <Stack.Screen
        options={options}
        name={'ProfileUser'}
        component={ProfileUser}
      />


      <Stack.Screen
        options={options}
        name={'ProfileMembers'}
        component={VisiorMembers}
      />

      <Stack.Screen
        options={options}
        name={'VisitorViewContentList'}
        component={VisitorViewContentList}
      />

      <Stack.Screen
        options={options}
        name={'BookMarkLists'}
        component={BookmarkViewContentList}
      />


      <Stack.Screen
        options={options}
        name={'UserProfileViewContentList'}
        component={UserProfileViewContentList}
      />

      {/* <Stack.Screen
        options={options}
        name={'ProfileMobileAbout'}
        component={profileMobileAbout}
      /> */}

      <Stack.Screen
        options={options}
        name={'ProfileMobileAboutV2'}
        component={ProfileMobileAboutV2}
      />

      <Stack.Screen
        options={options}
        name={'FinishAccountSetup'}
        component={FinishAccountSetup}
      />
      <Stack.Screen options={options} name={'HubTasks'} component={HubTasks} />
      <Stack.Screen options={options} name={'HubTasksV2'} component={HubTasksV2} />

      <Stack.Screen options={options} name={'AddPosts'} component={AddPosts} />

      <Stack.Screen
        options={options}
        name={'AddActivities'}
        component={AddActivities}
      />

      <Stack.Screen
        options={options}
        name={'AddActivitiesv2'}
        component={AddActivitiesv2}
      />

      <Stack.Screen options={options} name={'AddTask'} component={AddTask} />
      <Stack.Screen
        options={options}
        name={'AddTaskTwo'}
        component={AddTaskTwo}
      />
      <Stack.Screen
        options={options}
        name={'ChooseCoverImage'}
        component={ChooseCoverImage}
      />
      <Stack.Screen
        options={options}
        name={'HubTaskDetails'}
        component={HubTaskDetails}
      />
      <Stack.Screen
        options={options}
        name={'HubPresetDetails'}
        component={HubPresetDetails}
      />
      <Stack.Screen
        options={options}
        name={'HubTaskImagePreview'}
        component={HubTaskImagePreview}
      />
      <Stack.Screen
        options={options}
        name={'HubTaskVideoPreview'}
        component={HubTaskVideoPreview}
      />

      <Stack.Screen
        options={options}
        name={'BookedEvents'}
        component={BookedEvents}
      />
      <Stack.Screen
        options={options}
        name={'HubScreen'}
        component={HubScreen}
      />
      <Stack.Screen
        options={options}
        name={'EventAvailability'}
        component={EventAvailability}
      />
      <Stack.Screen name={'Account'} component={Account} options={options} />
      <Stack.Screen
        name={'CreateEvent'}
        options={options_modal}
        component={CreateEvent}
      />
      <Stack.Screen
        options={options_modal}
        name={'TimezonePicker'}
        component={TimezonePicker}
      />
      <Stack.Screen
        options={options}
        name={'EventTypes'}
        component={EventTypes}
      />
      <Stack.Screen
        options={options}
        name={'EventSettings'}
        component={EventSettings}
      />
      <Stack.Screen
        name={'Setting'}
        component={Setting}
        options={options_modal}
      />
      <Stack.Screen
        options={options}
        name={'ChatFont'}
        component={ChatFontSetting}
      />
      <Stack.Screen
        options={options}
        name={'AccountVerification'}
        component={AccountVerification}
      />
      <Stack.Screen
        name={'DoNotDisturb'}
        options={options_modal}
        component={DoNotDisturb}
      />
      <Stack.Screen
        name={'Snooze'}
        component={Snooze}
        options={options_modal}
      />
      <Stack.Screen
        name={'VerifyOTPScreen'}
        component={VerifyOTPScreen}
        options={options}
      />
      <Stack.Screen
        name={'WalletActivity'}
        component={WalletActivityScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name={'StripePaymentScreen'}
        options={options_modal}
        component={StripePaymentScreen}
      />
      <Stack.Screen
        name={'EventDetails'}
        options={options_modal}
        component={EventDetails}
      />

      <Stack.Screen
        name={'ReelsFullscreen'}
        component={ReelsFullscreen}
        options={options}
      />

      <Stack.Screen
        name={'VisitorReelsFullscreen'}
        component={VisitorReelsFullscreen}
        options={options}
      />

      <Stack.Screen
        name={'VideoFullscreen'}
        component={VideoFullscreen}
        options={options}
      />

      {/*
      <Stack.Screen
        options={options}
        name={'ProfileEventTypes'}
        component={ProfileEventTypes}
      />
      <Stack.Screen
        options={options_modal}
        name={'TimeSlotPicker'}
        component={TimeSlotPicker}
      />

      <Stack.Screen
        name={'BookEvent'}
        options={options_modal}
        component={BookEvent}
      />
      */}
    </Stack.Navigator>
  );
};

export default ProfileNavigator;
