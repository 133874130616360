import React from 'react';
import StepByStep from '../../../../../../../../components/reusable/stepByStep/StepByStep';
import ProfileSessionItem from '../../../../../items/profileSessionItem/ProfileSessionItem';
import {useNavigate} from 'react-router-dom';
import {Link} from '@react-navigation/native';

const mocData = {
  current: 2,
  sessions: [
    {
      id: 1,
      title: "Beginner's Strength",
      info: '25 Kcal / 10 min/ L1 Newbie',
      image: {
        src: '/images/pages/profile/moc/content/2.jpg',
        alt: 'Session 1',
      },
      isLikes: false,
    },
    {
      id: 2,
      title: "Beginner's Strength",
      info: '25 Kcal / 10 min/ L1 Newbie',
      image: {
        src: '/images/pages/profile/moc/content/2.jpg',
        alt: 'Session 1',
      },
      isLikes: false,
    },
    {
      id: 3,
      title: "Beginner's Strength",
      info: '25 Kcal / 10 min/ L1 Newbie',
      image: {
        src: '/images/pages/profile/moc/content/2.jpg',
        alt: 'Session 1',
      },
      isLikes: false,
    },
    {
      id: 4,
      title: "Beginner's Strength",
      info: '25 Kcal / 10 min/ L1 Newbie',
      image: {
        src: '/images/pages/profile/moc/content/2.jpg',
        alt: 'Session 1',
      },
      isLikes: false,
    },
    {
      id: 5,
      title: "Beginner's Strength",
      info: '25 Kcal / 10 min/ L1 Newbie',
      image: {
        src: '/images/pages/profile/moc/content/2.jpg',
        alt: 'Session 1',
      },
      isLikes: false,
    },
  ],
};

const ProfilePremiumContentEpisodes = () => {
  const navigate = useNavigate();

  const handleClick = id => navigate(`/profile/video-sessions/${id}`);

  return (
    <div className="mt-3 mt-md-6">
      <StepByStep count={mocData.sessions.length} step={mocData.current - 1} />
      <ul className="profile-session__list">
        {mocData.sessions.map((item, index) => (
          <Link to={`/profile/video-sessions/${item.id}`}>
            <li key={item.id}>
              <ProfileSessionItem
                id={item.id}
                session={index + 1}
                title={item.title}
                info={item.info}
                image={item.image}
                isLikes={item.isLikes}
                isActive={index + 1 < mocData.current}
                // callback={() => handleClick(item.id)}
              />
            </li>
          </Link>
        ))}
      </ul>
    </div>
  );
};

export default ProfilePremiumContentEpisodes;
