import {useMutation} from 'react-query';
import {deleteFcmToken} from '../../../network/api/app';
import {useAtom} from 'jotai';
import {_userFcmToken, _userFcmTokenSavedOnBE} from '../../userState';

export const useDeleteFcmToken = () => {
  const [fcm, setfcm] = useAtom(_userFcmToken);
  const [userFcmTokenSavedOnBE, setUserFcmTokenSavedOnBE] = useAtom(
    _userFcmTokenSavedOnBE,
  );
  const {
    mutate: mutateDeleteFcm,
    isLoading: isDeleteFcmLoading,
    data: DeleteFcmResponse,
    error: DeleteFcmErrorResponse,
  } = useMutation({
    mutationFn: param => {
      console.log('data in mutation', param.token, param.body);
      return deleteFcmToken({
        isAuth: true,
        token: param.token,
        body: param.body,
      });
    },
    onSuccess: data => {
      console.log('deleted  fcm', data);
      setUserFcmTokenSavedOnBE(false);
      setfcm(null);
    },
    onError: data => {
      console.log('deleted  fcm', data);
      setUserFcmTokenSavedOnBE(false);
      setfcm(null);
    },
  });

  const deleteFcm = async (data, token) => mutateDeleteFcm(data);

  return {deleteFcm};
};
