import React, { useEffect } from 'react';
import UserProfileTags from '../../../ProfileUser/items/userProfileTags/UserProfileTags';
import UserProfileSocial from '../../../ProfileUser/items/userProfileSocial/UserProfileSocial';
import ProfileText from '../../profileText/ProfileText';
import UserSkills from '../../../ProfileUser/items/userSkills/UserSkills';
import { useBuzzmiAuth } from '../../../../../../appState/hooks/useBuzzmiAuth/index.web';
import { useProfileInfo } from '../../../../../../appState/hooks/useProfileInfo';
const mockLinks = [
  {
    title: '@janedoe',
    type: 'facebook',
    link: 'https://facebook.com',
  },
  {
    title: 'Jane Doe',
    type: 'instagram',
    link: 'https://instagram.com',
  },
  {
    title: 'Jane Doe',
    type: 'linkedin',
    link: 'https://linkedin.com',
  },
  {
    title: 'Jane Doe',
    type: 'youtube',
    link: 'https://youtube.com',
  },
];

const ProfileAboutDesktop = ({ isOtherProfile }) => {
  const { userData } = useBuzzmiAuth();
  const { profileInfo } = useProfileInfo();
  
  return (
    <>
      <UserProfileTags />
      <div className="mt-3 link-wrqapper">
        <ProfileText
          text={isOtherProfile ? (profileInfo?.bio || '-') : (userData?.bio || '-')}
          title="Bio"
        />
      </div>
      <div className="mt-3">
        <UserSkills skill={isOtherProfile ? (profileInfo?.skill || []) : (userData?.skill || [])} />
      </div>
      <div className="mt-3">
        <ProfileText text={isOtherProfile ? (profileInfo?.college || '-') : (userData?.college || '-')} title="Education" />
      </div>
      <div className="mt-3">
        <ProfileText text={isOtherProfile ? (profileInfo?.workOrganization || '-') : (userData?.workOrganization || '-')} title="Company" />
      </div>
      <UserProfileSocial links={mockLinks} />
    </>
  );
};

export default ProfileAboutDesktop;
