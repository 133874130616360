import React, {useEffect, useState} from 'react';
import SocialTabContent from './TabContent/SocialTabContent';
import {useBuzzmiAuth} from '../../../../appState/hooks/useBuzzmiAuth/index.web';
import {useProfileInfo} from '../../../../appState/hooks/useProfileInfo';
import {useProfileApp} from '../../../../appState/hooks';
import {useAtom} from 'jotai';
import {socialSideBarNavAtom} from '../../../../appState/navState';
import {useEventEmitter} from '../../../../hooks/useEventEmitter';

const SocialDashboardDesktopTab = () => {
  //   return null;
  const [activeTabId, setActiveTabId] = useState('ForYou');
  const [activeTab, setActiveTab] = useAtom(socialSideBarNavAtom);
  const onChange = id => setActiveTabId(id);
  const {userData} = useBuzzmiAuth();
  const {
    getUserPostLists,
    getBuzzmiUserPostLists,
    getUserReelLists,
    getFollowingPostLists,
  } = useProfileApp();

  useEffect(() => {
    if (userData) {
      getFollowingPostLists(userData?.token, {page: 1, limit: 20});
      getBuzzmiUserPostLists(userData?.token, {page: 1, limit: 20});
      getUserPostLists(userData?.token, userData?.id, {page: 1, limit: 20});
      getUserReelLists(userData?.token, userData?.id, {page: 1, limit: 20});
    }
  }, [userData]);

  useEventEmitter('refresh-buzzmiUser-list', data => {
    getBuzzmiUserPostLists(userData?.token, data);
  });

  useEventEmitter('refresh-following-list', data => {
    getFollowingPostLists(userData?.token, data);
  });

  useEffect(() => {
    document.body.style.overflowY = 'scroll';
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);

  useEffect(() => {
    setActiveTabId(activeTab);
  }, [activeTab]);

  return (
    <div className="max-w-3xl mx-auto">
      <SocialTabContent activeTabId={activeTabId} />
    </div>
  );
};

export default SocialDashboardDesktopTab;
