import React from 'react';
import style from './chose-image.module.scss';
import ImageUploading from 'react-images-uploading';
import useSetParenComponentProps from '../../../../hooks/useSetParenComponentProps.js';
import {useDispatch, useSelector} from 'react-redux';
import {
  closeModal,
  clearParentComponent,
} from '../../../../store/slices/modalSlice/modalSlice';
import Strings from '../../../../string_key/Strings.web';

const UploadField = () => {
  const dispatch = useDispatch();

  let image = [];
  const setProps = useSetParenComponentProps();
  const {callback} = useSelector(state => state.modal);
  const onChange = (imageList, addUpdateIndex) => {
    image = imageList;
    console.log('cove image', image);
    dispatch(clearParentComponent());
    setTimeout(() => {
      setProps('ChoseImage', {image: image[0]});
    }, 150);
    // if (callback) {
    //   callback(image[0]);
    // }
    // dispatch(closeModal());
  };

  return (
    <ImageUploading
      multiple
      value={image}
      onChange={onChange}
      maxNumber={1}
      dataURLKey="data_url"
      // acceptType={['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp', 'jfif']}
      acceptType={[
        'jpg',
        'gif',
        'png',
        'jpeg',
        'svg',
        'webp',
        'jfif',
        'pjpeg',
        'pjp',
        'avif',
        'apng',
        'bmp',
        'ico',
        'cur',
      ]}>
      {({onImageUpload, dragProps}) => (
        <div
          className={style['chose-image__upload-field']}
          onClick={onImageUpload}
          {...dragProps}>
          <h4 className="h4 text-center">{Strings.upload_your_image}</h4>
        </div>
      )}
    </ImageUploading>
  );
};

export default UploadField;
