import React, {useEffect, useState} from 'react';
import CustomSelect from '../../../../../../components/reusable/form/select/customSelect/CustomSelect';

const options = [
  {
    label: 'Sort by name',
    value: 'name',
  },
  {
    label: 'Sort by rating',
    value: 'rating',
  },
  {
    label: 'Sort by followers',
    value: 'followers',
  },
  {
    label: 'Sort by subscribers',
    value: 'subscribers',
  },
];

const ProfileMobileUsersSort = () => {
  const [descAsc, setDescAsc] = useState('desc');
  const [selected, setSelected] = useState({
    label: 'Sort by name',
    value: 'name',
  });

  const handleDescAsc = () => {
    setDescAsc(descAsc === 'desc' ? 'asc' : 'desc');
  };

  useEffect(() => {
    // handle sort
  }, [selected, descAsc]);

  return (
    <div className="d-flex justify-content-between align-items-center mb-2 mb-md-21 mt-21 mt-md-5">
      <CustomSelect
        className="select__secondary profile-contacts__sort-select"
        options={options}
        selected={selected}
        setSelected={setSelected}
      />
      <button onClick={handleDescAsc}>
        <img src="/images/post/sort.svg" alt="sort" />
      </button>
    </div>
  );
};

export default ProfileMobileUsersSort;
