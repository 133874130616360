import React, {useEffect, useState} from 'react';
import LinkTabs from '../../../../../components/reusable/assets/linkTabs/LinkTabs';
// import {Link} from 'react-router-dom';
import {Link} from '@react-navigation/native';
import {tabs} from './profileDesktopTabsData';
import ProfileDesktopTabsChildrens from './ProfileDesktopTabsChildrens';
import OtherProfileDesktopTabsChildrens from './OtherProfileDesktopTabsChildrens';
import {useBuzzmiAuth} from '../../../../../appState/hooks/useBuzzmiAuth/index.web';
import {useProfileInfo} from '../../../../../appState/hooks/useProfileInfo';
import {useProfileApp} from '../../../../../appState/hooks';

const ProfileDesktopTabs = ({isOtherProfile}) => {
  const [activeTabId, setActiveTabId] = useState('Post');
  const onChange = id => setActiveTabId(id);
  const {userData} = useBuzzmiAuth();
  const {profileInfo} = useProfileInfo();
  const {getUserPostLists, getUserReelLists} = useProfileApp();

  useEffect(() => {
    if (profileInfo && isOtherProfile == true) {
      getUserPostLists(userData?.token, profileInfo?.id, {page: 1, limit: 20});
      getUserReelLists(userData?.token, profileInfo?.id, {page: 1, limit: 20});
    }
  }, [profileInfo, isOtherProfile]);

  useEffect(() => {
    if (userData && isOtherProfile == undefined) {
      getUserPostLists(userData?.token, userData?.id, {page: 1, limit: 20});
      getUserReelLists(userData?.token, userData?.id, {page: 1, limit: 20});
    }
  }, [userData, isOtherProfile]);

  useEffect(() => {
    document.body.style.overflowY = 'scroll';
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);

  return (
    <div className="max-w-3xl mx-auto">
      <div className="profile-tabs">
        <LinkTabs activeTabId={activeTabId} list={tabs} onChange={onChange} />
        {/* <div className="profile-tabs__select">
          <div className="profile-tabs__select-title">More</div>
          <ul className="profile-tabs__select-list">
            <li>
              <Link to="/">Link 1</Link>
            </li>
            <li>
              <Link to="/">Link 2</Link>
            </li>
            <li>
              <Link to="/">Link 3</Link>
            </li>
            <li>
              <Link to="/">Link 4</Link>
            </li>
            <li>
              <Link to="/">Link 5</Link>
            </li>
            <li>
              <Link to="/">Link 6</Link>
            </li>
          </ul>
        </div> */}
      </div>
      {isOtherProfile ? (
        <OtherProfileDesktopTabsChildrens
          activeTabId={activeTabId}
          isOtherProfile={true}
        />
      ) : (
        <ProfileDesktopTabsChildrens activeTabId={activeTabId} />
      )}
    </div>
  );
};

export default ProfileDesktopTabs;
