import React, { useEffect, useState } from 'react';
import Calendar from '../../../../../../../../components/reusable/calendar/Calendar.jsx';
import moment from 'moment';
import 'react-calendar/dist/Calendar.css';

const HubRepeatCalendar = ({ values, setValues }) => {

  // console.log("values")
  // console.log(values)
  const [calendarValue, setCalendarValue] = useState(
    values?.value
      ? moment().set('date', values?.value).format('LL')
      : moment(new Date()).format('LL'),
  );

  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);

  // useEffect(() => {
  //   if (values?.value !== calendarValue) {
  //     setValues({ ...values, value: moment(calendarValue).format('LL') });
  //   }
  // }, [calendarValue]);


  const handleDateChange = (date) => {
    let multipledates = []
    let substringToRemove = date.split("-")[2]
    if (selectedDates.includes(date)) {
      setSelectedDates(selectedDates.filter((date) => !date.includes(substringToRemove)));
      setSelectedDays(selectedDays.filter((d) => d !== substringToRemove));

    } else {
      for (let i = 1; i <= values?.every; i++) {
        multipledates.push(date.split("-")[0] + "-" + (i > 10 ? i : '0' + i) + "-" + date.split("-")[2])
      }
      setSelectedDates([...selectedDates, ...multipledates]);
      setSelectedDays([...selectedDays, date.split("-")[2]]);

    }
  };

  useEffect(() => {
    setValues({ ...values, value: selectedDays });
  }, [selectedDays])

  return (
    <Calendar
      onChange={handleDateChange}
      value={calendarValue}
      isMultipleSelection={true}
      selectedDates={selectedDates}
      noIndent
      period="month"
    />
  );
};

export default HubRepeatCalendar;
