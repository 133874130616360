import React, { useEffect } from 'react';

// import {Link} from 'react-router-dom';
import { Link } from '@react-navigation/native';
import Box from '../../../../../../components/reusable/cards/box/Box';
import { useBuzzmiAuth } from '../../../../../../appState/hooks/useBuzzmiAuth/index.web';


const UserProfileSocial = ({ links }) => {

  const { userData } = useBuzzmiAuth();

  if (!links) return null;
  return (
    <Box paddingMode="big" className="profile-user__info-block mt-3 mt-md-4">
      <div className="profile-user__block-title">Social media</div>
      <div className="profile-user__social">

        {userData?.isFacebookConnect &&
          <div >
            <a target="blank" href={userData?.facebookLink} className="profile-user__social-link">
              <img src={"/images/pages/profile/facebook.svg"} alt="" />
            </a>
          </div>
        }
        {userData?.isLinkedInConnect &&
          <div>
            <a target="blank" href={userData?.linkedInLink} className="profile-user__social-link">
              <img src={"/images/pages/profile/linkedin.svg"} alt="" />
            </a>
          </div>
        }

        {userData?.isYoutubeConnect &&
          <div>
            <a target="blank" href={userData?.youtubeLink} className="profile-user__social-link">
              <img src={"/images/pages/profile/youtube.svg"} alt="" />
            </a>
          </div>
        }

        {userData?.isTwitterConnect &&
          <div>
            <a target="blank" href={userData?.twitterLink} className="profile-user__social-link">
              <img src={"/images/pages/profile/x.svg"} alt="" />
            </a>
          </div>
        }
      </div>
    </Box>
  );
};

export default UserProfileSocial;
