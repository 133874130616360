import React, { useState } from 'react';
import ProfileMobileUsersItem from './profileMobileUsersItem/ProfileMobileUsersItem';
import {
  FollowButton,
  FollowingButton,
} from './profileMobileUsersButtons/ProfileMobileUsersButtons';
import { Dots } from 'react-activity';
import { colors } from '../../../../../res/colors';
import { EventRegister } from 'react-native-event-listeners';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import ConfirmationModal from '../../ProfileMain/componentsV2/ConfirmationModal';

const ProfileMobileUsersFollowers = ({
  data,
  onPressfollow,
  onPressUnfollow,
  lodaingFollowers,
  followPage,
  userContactInfo,
  isVisitorProfile = false
}) => {

  const [open, setOpen] = useState(false);
  const [uItem, setUItem] = useState(null);

  return (
    <>
      <ul className="profile-contacts__list">

        {(data?.users?.length == 0 && !lodaingFollowers) ?
          <p className={"mt-4 text-center"}>{"No users found"}</p>
          :
          data.users.map(item => (
            <li key={item.id}>
              <ProfileMobileUsersItem
                item={item}
                button={
                  <FollowingButton
                    id={item.id}
                    onPress={() => { isVisitorProfile ? null : (setUItem(item), setTimeout(() => { setOpen(!open) }, 100)) }}
                    isFollowing={false}
                    isVisitorProfile={isVisitorProfile}
                  />
                }
              />
            </li>
          ))}

        {isVisitorProfile == false && !lodaingFollowers && userContactInfo?.followers >= 5 &&
          <div className={"d-flex justify-between mx-[8px] mt-[5px]"}>
            <div className={"cursor-pointer d-flex self-center"} onClick={() => {
              if (followPage > 1) {
                EventRegister.emit('refresh-followers-user-list', {
                  page: followPage - 1,
                  limit: 5,
                });
              }
            }}>
              <ArrowLeftIcon className="h-5 w-5 sm:h-8 sm:w-8" style={{ color: '#DA31B1' }} aria-hidden="true" />
              <p className={"ml-2 text-sm self-center text-[#DA31B1]"}>Prev</p>
            </div>
            <div className={"cursor-pointer d-flex self-center"} onClick={() => {
              if (userContactInfo?.followers >= (followPage * 5)) {
                EventRegister.emit('refresh-followers-user-list', {
                  page: followPage + 1,
                  limit: 5,
                });
              }
            }}>
              <p className={"mr-2 text-sm self-center text-[#DA31B1]"}>Next</p>
              <ArrowRightIcon className="h-5 w-5 sm:h-8 sm:w-8" style={{ color: '#DA31B1' }} aria-hidden="true" />
            </div>
          </div>
        }
        {lodaingFollowers && (
          <div
            className="pb-2"
            style={{
              display: 'flex',
              justifyContent: 'center', // Horizontally center content
              alignItems: 'center', // Vertically center content
              width: '100%', // Set the width to 100% if needed
              // Add any other inline styles you want
            }}>
            <Dots color={colors.PRIMARY_COLOR} />
          </div>
        )}
      </ul>

      {data.suggestedForYou.length > 0 && (
        <>
          <h4 className="h4 mt-4 mb-3 mt-md-9 mb-md-21">Suggested for you</h4>
          <ul className="profile-contacts__list">
            {data.suggestedForYou.map(item => (
              <li key={item.id}>
                <ProfileMobileUsersItem
                  item={item}
                  button={
                    <FollowButton
                      id={item.id}
                      onPress={() => onPressfollow(item)}
                    />
                  }
                />
              </li>
            ))}
          </ul>
        </>
      )}

      <ConfirmationModal setOpen={setOpen} open={open} onPressUnfollow={() => { onPressUnfollow(uItem) }} />

    </>
  );
}

export default ProfileMobileUsersFollowers;
