// noinspection ES6CheckImport

import React, { useContext, useState, useEffect, useRef } from 'react';
import PrimaryButton from "../../../components/reusable/form/items/buttons/PrimaryButton";
import MyAuthLayout from '../../../components/layouts/myAuthLayout/MyAuthLayout';
import FormResetPassword from '../EnterOtp/items/FormResetPassword';
import Logo from '../../../components/reusable/assets/logo/Logo';
import { ShowError, ShowSuccess } from '../../HOC/MessageWeb';
import { NEW_RESET_PASSWORD } from '../../../api_helper/Api';
import { useToast } from 'react-native-toast-notifications';
import { POST } from '../../../api_helper/ApiServices';
import { useForm } from 'react-hook-form';
import { getData } from '../../../res/asyncStorageHelper.web';
import Loading from 'react-fullscreen-loading';
import { colors } from '../../../res/colors';
import Strings from '../../../string_key/Strings.web';

const passSpecialChar = /[#?!@$%^&*-]/;
const passCapitalLetter = /[A-Z]/;
const passOneNumber = /[0-9]/;
// const passConsecutiveTwoChar = /(.).*\1/;
const passwordReg =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,15}$/;
const UpdatePasswordScreen = props => {
  // const { params } = useRoute();
  const [isValid, setIsValid] = useState(false)
  const [loading, setLoading] = useState(false);

  let refUrlParams = useRef(props.route.params)


  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const toast = useToast();

  // useEffect(() => {
  //   setTimeout(() => {
  //     window.history.replaceState(null, 'Title', '/UpdatePassword')
  //   }, 100);
  // }, [])


  const submitCallback = async (data) => {
    try {
      if (data.password.length > 0 && data.confirmPassword.length > 0) {
        if (data.password !== data.confirmPassword) {
          ShowError('Passwords do not match', toast);
          return;
        }
        if (!passwordReg.test(data.password)) {
          if (data.password.length < 8 || data.password.length > 14) {
            ShowError('Password Length to be 8 minimum and 15 maximum', toast);
            return;
          } else if (!passSpecialChar.test(data.password)) {
            ShowError('At least one Special Char\n', toast);
            return;
          } else if (!passCapitalLetter.test(data.password)) {
            ShowError('At least one Capital Letter\n', toast);
            return;
          } else if (!passOneNumber.test(data.password)) {
            ShowError('At least one Number\n', toast);
            return;
          } else {
            ShowError('No more than two consecutive characters', toast);
            return;
          }
        } else {
          // ShowError('Password not valid', toast);
        }
      } else {
        if (data.password.length == 0) {
          ShowError('Password cannot be empty', toast);
        } else { ShowError('Confirm Password cannot be empty', toast); }
        return;
      }
      setLoading(() => true);

      getData('f_secret', secret => {
        let requestBody = {
          password: data.password,
          secret: secret,
        }
        refUrlParams.current.param?.email ? requestBody.email = refUrlParams.current.param?.email : requestBody.mobile = refUrlParams.current.param?.phone
        POST(
          NEW_RESET_PASSWORD,
          false,
          '',
          '',
          requestBody,
          (res, e) => {
            setLoading(() => false);
            if (!e) {
              props.navigation.replace("Auth")
              setTimeout(() => {
                ShowSuccess(res?.message, toast)
              }, 250);
            } else {
              ShowError(res, toast)
            }
          },
        );
      })
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <MyAuthLayout>
      <Loading loading={loading} background="transparent" loaderColor={colors.PRIMARY_COLOR} />
      <div className="forgot-password__logo">
        <Logo subtitle="Welcome to" />
      </div>
      <div className="h3 content-auth__title">{Strings.reset_your_password}</div>
      <FormResetPassword submitHandler={submitCallback} passwordLabel={Strings.new_password} setValid={setIsValid} />
      <PrimaryButton type='submit' className={!isValid ? 'button_inactive' : ''} form={'create-password'}>{Strings.confirm.toUpperCase()}</PrimaryButton>
    </MyAuthLayout>
  );
};
export default UpdatePasswordScreen;
