import React, { useEffect, useState } from 'react';
import CustomLink from '../../../../../components/reusable/btns/links/CustomLink';
import Rank from '../../../Hub/Items/rank/Rank';
// import {Link} from 'react-router-dom';
import { useLinkTo } from '@react-navigation/native';
import ProfileInfoMobile from './items/profileInfoMobile/ProfileInfoMobileV2';
import ProfileHeaderMobile from './items/profileHeaderMobile/ProfileHeaderMobileV2';
import ProfileDataBlocks from '../items/profileDataBlocks/ProfileDataBlocks';
import ProfileContentTabsV2 from '../../items/profileContentTabs/ProfileContentTabsV2';
import ProfileStories from '../../items/profileStories/ProfileStories';
import useWindowSize from '../../../../../hooks/useWindowSize';
import ProfileDataTextInfo from '../items/profileDataBlocks/items/ProfileDataTextInfo';
import { useProfileApp } from '../../../../../appState/hooks';
import { useBuzzmiAuth } from '../../../../../appState/hooks/useBuzzmiAuth/index.web';
import CreatePostInput from '../componentsV2/CreatePostInput';
import Box from '../../../../../components/reusable/cards/box/Box';
import RankV2 from '../../../Hub/Items/rank/RankV2';
import { useProfileInfo } from '../../../../../appState/hooks/useProfileInfo';
import SimpleShowMore from '../../../../../components/reusable/assets/showMore/simpleShowMore/SimpleShowMore';
import CreatePostModal from '../componentsV2/CreatePostModal';

const ProfileMainMobileV2 = ({
  setOpenFriendsModal,
  setOpenActivityModal,
  setOpenBadgesModal,
}) => {
  const linkTo = useLinkTo();
  const { width } = useWindowSize();
  const { getInformationContacts, getUsersActivity, userContactInfo } =
    useProfileApp();
  const { userData } = useBuzzmiAuth();
  const { profileInfo } = useProfileInfo();
  const { getUserPostLists, getUserReelLists, getBuzzmiUserPostLists } = useProfileApp();
  const [openEditProfileModal, setOpenEditProfileModal] = useState(false);
  const [open, setOpen] = useState(false);
  let mocDataContacts = [];

  useEffect(() => {
    if (userData) {
      getBuzzmiUserPostLists(userData?.token, { page: 1, limit: 20 });
      getUserPostLists(userData?.token, userData?.id, { page: 1, limit: 20 });
      getUserReelLists(userData?.token, userData?.id, { page: 1, limit: 20 });
    }
  }, [userData]);

  useEffect(() => {
    getInformationContacts(userData?.token);
    getUsersActivity(userData?.token);
  }, [userData]);

  useEffect(() => {
    mocDataContacts = [
      {
        title: 'Following',
        value: userContactInfo?.following,
        link: '/profile/mobile-users/1',
      },
      {
        title: 'Followers',
        value: userContactInfo?.followers,
        link: '/profile/mobile-users/1',
      },
      {
        title: 'Subscribers',
        value: userContactInfo?.friends,
        link: '/profile/mobile-users/1',
      },
    ];
  }, [userContactInfo]);

  return (
    <>
      {/* <ProfileHeaderMobile /> */}
      {/* <div className="pt-4 md:pt-0" /> */}
      <ProfileInfoMobile />

      <div className="profile-user__info-block mt-3 mt-md-4">
        <SimpleShowMore
          content={userData?.bio || ''}
          lineClamp={2}
          sizes={{
            desktopHeight: 60,
            mobileHeight: 54,
          }}
        />
      </div>

      <div className="pt-4 md:pt-0" />
      <RankV2 />

      <div className="mt-5 d-flex justify-content-between align-items-center">
        <CustomLink to="/profile/edit" className="alt-btn button_m">
          Edit profile
        </CustomLink>
        <div />
        {/* <Link to="/profile/user-mobile-about/1" > */}
        <a
          style={{ cursor: 'pointer' }}
          className="text-alt-primary profile__edit-profile-m"
          onClick={() => {
            linkTo('/profile/user-mobile-about/1');
          }}>
          Profile preview
        </a>
      </div>
      <ProfileDataBlocks
        setOpenActivityModal={setOpenActivityModal}
        setOpenBadgesModal={setOpenBadgesModal}
        setOpenFriendsModal={setOpenFriendsModal}
      />

      {/* {width > 992 ? null : (
          <ProfileDataTextInfo
            infostyle={{justifyContent: 'space-around'}}
            data={[
              {
                title: 'Following',
                value: userContactInfo?.following,
                link: '/profile/mobile-users/1',
              },
              {
                title: 'Followers',
                value: userContactInfo?.followers,
                link: '/profile/mobile-users/1',
              },
              {
                title: 'Friends',
                value: userContactInfo?.friends,
                link: '/profile/mobile-users/1',
              },
            ]}
          />
        )} */}
      {/* <ProfileStories /> */}
      {/* <div style={{height: '10px'}}></div> */}
      {/* <Box paddingMode="big">
        <CreatePostInput />
      </Box> */}
      {/* <div className="mt-5 d-flex justify-content-between align-items-center">
        <div />
        <a
          style={{ cursor: 'pointer' }}
          className="text-alt-primary profile__edit-profile-m"
          onClick={e => {
            e.preventDefault();
            setOpen(() => true);
          }}>
          Add Your Post
        </a>
      </div> */}
      <div className="mt-5">
        <ProfileContentTabsV2 />
      </div>
      <CreatePostModal
        setOpen={setOpen}
        open={open}
        post={null}
        is_edit={false}
      />
    </>
  );
};

export default ProfileMainMobileV2;
