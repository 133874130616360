import React, { useEffect, useState } from 'react';
import CustomLink from '../../../../../components/reusable/btns/links/CustomLink';
import Rank from '../../../Hub/Items/rank/Rank';
// import {Link} from 'react-router-dom';
import { useLinkTo } from '@react-navigation/native';
import ProfileInfoMobile from './items/profileInfoMobile/ProfileInfoMobileV3';
import ProfileHeaderMobile from './items/profileHeaderMobile/ProfileHeaderMobileV2';
import ProfileDataBlocks from '../items/profileDataBlocks/ProfileDataBlocks';
import ProfileContentTabsV2 from '../../items/profileContentTabs/ProfileContentTabsV2';
import ProfileStories from '../../items/profileStories/ProfileStories';
import useWindowSize from '../../../../../hooks/useWindowSize';
import ProfileDataTextInfo from '../items/profileDataBlocks/items/ProfileDataTextInfo';
import { useProfileApp } from '../../../../../appState/hooks';
import { useBuzzmiAuth } from '../../../../../appState/hooks/useBuzzmiAuth/index.web';
import CreatePostInput from '../componentsV2/CreatePostInput';
import Box from '../../../../../components/reusable/cards/box/Box';
import RankV2 from '../../../Hub/Items/rank/RankV2';
import { useProfileInfo } from '../../../../../appState/hooks/useProfileInfo';
import SimpleShowMore from '../../../../../components/reusable/assets/showMore/simpleShowMore/SimpleShowMore';
import CreatePostModal from '../componentsV2/CreatePostModal';
import VisitorAvatar from '../../../../../components/reusable/assets/avatar/VisitorAvatar';
import { IdentificationIcon } from '@heroicons/react/24/outline';
import SharePostModal from '../componentsV2/SharePostModel';
import { useUserRatings } from '../../../../../appState/hooks/useUserRatings';

const ProfileMainMobileV3 = ({
  setOpenFriendsModal,
  setOpenActivityModal,
  setOpenBadgesModal,
}) => {
  const linkTo = useLinkTo();
  const { width } = useWindowSize();
  const { getInformationContacts, getUsersActivity, userContactInfo, followingUsers, followerUsers } = useProfileApp();
  const { userData } = useBuzzmiAuth();
  const { getUserPostLists, getUserReelLists, getBuzzmiUserPostLists, userPosts, userReels } = useProfileApp();
  const [open, setOpen] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const { profileShareData, getProfileShareDetail } = useProfileApp();
  const { getOnUserRatings } = useUserRatings();

  useEffect(() => {
    if (userData) {
      getBuzzmiUserPostLists(userData?.token, { page: 1, limit: 20 });
      getUserPostLists(userData?.token, userData?.id, { page: 1, limit: 20 });
      getUserReelLists(userData?.token, userData?.id, { page: 1, limit: 20 });
      getOnUserRatings(userData?.id, userData?.token)
    }
  }, [userData]);

  useEffect(() => {
    getProfileShareDetail(userData?.token, null);
  }, [userData]);

  const handleShare = () => {
    setModalData({
      userName: `@${userData?.displayName}`,
      userShareDetails: profileShareData,
      userAvatar: userData?.avatar
        ? userData?.avatar
        : '/images/moc/avatar-big.png',
      typeSharingItem: 'Profile',
    });
    setOpenShareModal(true);
  };

  useEffect(() => {
    getInformationContacts(userData?.token);
    getUsersActivity(userData?.token);
  }, [userData]);

  return (
    <>

      <div className="profile-top__mobile-wrap justify-around">
        <VisitorAvatar mode="small" isOtherProfile={false} />
        <div className={"ml-2 flex justify-around flex-1"}>
          <div>
            <p className={"text-lg text-center font-semibold"}>{(userPosts?.length + userReels?.length) || 0}</p>
            <p className={"text-sm text-center"}>Posts</p>
          </div>
          <div onClick={() => { setOpenFriendsModal(true), global.friendIndex = 1 }}>
            <p className={"text-lg text-center font-semibold"}>{userContactInfo?.followers || 0}</p>
            <p className={"text-sm text-center"}>Followers</p>
          </div>
          <div onClick={() => { setOpenFriendsModal(true), global.friendIndex = 0 }}>
            <p className={"text-lg text-center font-semibold"}>{userContactInfo?.following || 0}</p>
            <p className={"text-sm text-center"}>Following</p>
          </div>
        </div>
      </div>
      <div className="profile-top__mobile-right">
        <ProfileInfoMobile />
      </div>

      <div className="mt-5 gap-3 d-flex justify-content-between align-items-center">
        <CustomLink to="/profile/edit" className="alt-btn button_m text-[#e536af] capitalize">
          Edit Profile
        </CustomLink>
        <CustomLink onClick={() => { handleShare() }} className="alt-btn button_m text-[#e536af] capitalize">
          Share Profile
        </CustomLink>
        <CustomLink to={'/profile/user-mobile-about/1'} className="alt-btn button_m">
          <IdentificationIcon style={{ color: '#e536af' }} className={"cursor-pointer w-6 h-6"} />
        </CustomLink>
      </div>
      <div className="pt-4 md:pt-0" />
      <RankV2 />

      {/* <ProfileDataBlocks
        setOpenActivityModal={setOpenActivityModal}
        setOpenBadgesModal={setOpenBadgesModal}
        setOpenFriendsModal={setOpenFriendsModal}
      /> */}
      <div className="mt-5">
        <ProfileContentTabsV2 />
      </div>
      <CreatePostModal
        setOpen={setOpen}
        open={open}
        post={null}
        is_edit={false}
      />

      <SharePostModal
        open={openShareModal}
        data={{ ...modalData }}
        setOpen={setOpenShareModal}
      />
    </>
  );
};

export default ProfileMainMobileV3;
