import React from 'react';
import Button from '../../../../../reusable/btns/buttons/Button';

const LogoutMobileAlert = ({
  handleLogoutFromAllUsers,
  handleLogoutOnlyBaseTask,
}) => {
  return (
    <div className="d-md-none alert-mobile p-10">
      <div className="alert__body">
        <div className="delete-tasks__text">
          <div className="h4 mb-21">Are you sure?</div>
          <div className="text-r mb-6">You want to logout</div>
        </div>
        {/* <div className="h3">Are you sure?</div>
        <div className="h7">
          The task will be deleted from all users without the possibility of
          recovery.
        </div> */}
        {/* <div>
          <div>Delete only base task</div>
          <div>Delete from all users</div>
      
        </div> */}
        <div className="delete-tasks__buttons">
          <div className="d-flex justify-content-center mb-3">
            <Button
              className="_fw button_secondary button_m double-secondary"
              onClick={handleLogoutOnlyBaseTask}>
              {/* Delete only base task */}
              Logout
            </Button>
          </div>
          <div className="d-flex justify-content-center">
            <Button
              className="_fw button_secondary button_m double-secondary"
              onClick={handleLogoutFromAllUsers}>
              {/* Delete from all */}
              Cancel
            </Button>
          </div>
        </div>
      </div>
      {/* <button className="alert__bottom text-error h4">Cancel</button> */}
    </div>
  );
};

export default LogoutMobileAlert;
