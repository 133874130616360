import React from 'react';
import DeleteTaskAlert from './alerts/deleteTaskAlert/DeleteTaskAlert';
import VerifyPhoneAlert from './alerts/verifyPhoneAlert/VerifyPhoneAlert';
import LogoutAlert from './alerts/logoutAlert/LogoutAlert';
import AddTaskAlert from './alerts/addTaskAlert/AddTaskAlert';
import constructionAlert from './alerts/underConstruction/constructionAlert';
import upgradeAlert from './alerts/upGradePopup/upgradeAlert';


const renderAlert = ({ nameAlert, propsAlert }) => {
  const contentMap = {
    DeleteTaskAlert,
    VerifyPhoneAlert,
    LogoutAlert,
    AddTaskAlert,
    constructionAlert,
    upgradeAlert
  };

  const Component = contentMap[nameAlert];
  if (!Component) return null;
  return <Component {...propsAlert} />;
};

export default renderAlert;
