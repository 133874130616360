import React, {useRef} from 'react';

import PropTypes from 'prop-types';
import {useBuzzmiAuth} from '../../../../../appState/hooks/useBuzzmiAuth/index.web';
import {categorizeUrlsFromArray} from '../profilePost/ProfilePostV2';
import { getVideoPosterURL } from '../types';

const ProfileReelTile = ({reel, handleSwitchAnchor, contentType}) => {
  const itemRef = useRef(null);
  const {userData} = useBuzzmiAuth();
  const vid = categorizeUrlsFromArray(reel?.fields)?.video?.[0];

  return (
    <li
      className={`reels__list-table-item ${
        contentType != 'all' && userData?.membership?.type == 'free_member'
          ? 'blur-[2px]'
          : 'blur-none'
      }`}
      onClick={e => {
        e.preventDefault();
        handleSwitchAnchor(reel?.id);
      }}
      ref={itemRef}>
      <video src={vid} poster={getVideoPosterURL(vid)} />
      <img src="/images/post/play.svg" alt="play" className="video-play" />
    </li>
  );
};

ProfileReelTile.propTypes = {
  reel: PropTypes.shape({
    id: PropTypes.number.isRequired,
    video: PropTypes.objectOf(PropTypes.string).isRequired,
  }).isRequired,
  handleSwitchAnchor: PropTypes.func.isRequired,
};

export default ProfileReelTile;
