import {useMutation} from 'react-query';
import {postVerifyOtp} from '../../../network/api/app';

export const usePost2FAVerification = () => {
  const {
    mutate: mutatePost2FAVerification,
    isLoading: is2FALoading,
    isError: is2FAError,
    data: faResponse,
    error: faErrorResponse,
  } = useMutation(postVerifyOtp);

  const post2FAVerification = params => mutatePost2FAVerification(params);

  return {
    faErrorResponse,
    is2FAError,
    is2FALoading,
    faResponse,
    post2FAVerification,
  };
};
