import React, { useEffect, useState } from 'react';
import { useHubApp } from '../../../../../appState/hooks/useHubApp/index.web.js';
import { useBuzzmiAuth } from '../../../../../appState/hooks/useBuzzmiAuth/index.web.js';
import Box from '../../../../../components/reusable/cards/box/Box.jsx';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Ranking from './Rankings/index.web.js';
import { useUserBadges } from '../../../../../appState/hooks/useUserBadges/index.js';
import Badges from './Badges/index.web.js';
import RankRowV2 from '../../Items/rank/items/RankRow/RankRowV2.jsx';
import HubNextLevelInfo from '../../../../../container/App/Home/hubMainMobile/items/hubNextLevelInfo/HubNextLevelInfo.jsx';

const Awards = props => {
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const { getOnUserBadges } = useUserBadges()
    const { userData } = useBuzzmiAuth();
    const { rankInfo } = useHubApp();

    const handleTabSelect = index => {
        setActiveTabIndex(index);
    };

    useEffect(() => {
        console.log(rankInfo)
    }, [rankInfo])

    useEffect(() => {
        getOnUserBadges(userData?.id, userData?.token);
    }, [userData])

    return (
        <>
            <div className='mt-6'>
                <p className='text-xs sm:text-[14px]  text-center' style={{ color: "#584F68" }}>Welcome to your Buzzmi trophy case! Here, you'll find your collection of badges, ranks, and avatars that show off your Buzzmi journey. Keep up the good work and keep pushing for more!</p>
            </div>

            <Box className={"mt-4 mb-4"}>
                <RankRowV2 />
            </Box>

            <div className='text-center'>
                <h4 className='text-lg mb-3'>How to get <b>{rankInfo?.nextRank}</b></h4>
                <HubNextLevelInfo />
            </div>

            <Tabs
                className="profile-section"
                selectedIndex={activeTabIndex}
                onSelect={handleTabSelect}>
                <TabList>
                    <Tab style={{ color: "#000", fontWeight: 700 }}>
                        <img src="/images/tab_ranking.png" class="w-[60px] m-auto mb-2.5"></img>
                        Rankings</Tab>
                    <Tab style={{ color: "#000", fontWeight: 700 }}>
                        <img src="/images/tab_badges.png" class="w-[60px] m-auto mb-2.5"></img>
                        Badges</Tab>
                    <Tab style={{ color: "#000", fontWeight: 700 }}>
                        <img src="/images/tab_avatar.png" class="w-[60px] m-auto mb-2.5"></img>
                        Avatars</Tab>
                </TabList>

                <TabPanel key="rankings">
                    <Ranking />
                </TabPanel>
                <TabPanel key="badges">
                    <Badges />
                </TabPanel>


                <TabPanel key="avatars">

                    <Box className={"flex items-center mt-6 pt-2 pr-4 pb-2 pl-2 mb-2 h-24"}>
                        <div className='mr-4'>
                            <img src="/images/buzz_coin.png" class="w-[80px]"></img>
                        </div>
                        <div>
                            <h6 className='text[15px] font-bold mb-4'>Social Lion</h6>
                            <p className='text-xs' style={{ color: "#584F68" }}>Score more than 10,000 Buzzmi Coins</p>
                        </div>
                    </Box>

                    <Box className={"flex items-center pt-2 pr-4 pb-2 pl-2 mb-2 h-24"}>
                        <div className='mr-4'>
                            <img src="/images/buzz_coin.png" class="w-[80px]"></img>
                        </div>
                        <div>
                            <h6 className='text[15px] font-bold mb-4'>Friendly Tigger</h6>
                            <p className='text-xs' style={{ color: "#584F68" }}>Achive 1000 friends in list</p>
                        </div>
                    </Box>

                    <Box className={"flex items-center pt-2 pr-4 pb-2 pl-2 mb-2 h-24"}>
                        <div className='mr-4'>
                            <img src="/images/buzz_coin.png" class="w-[80px]"></img>
                        </div>
                        <div>
                            <h6 className='text[15px] font-bold mb-4'>Cool Hunter</h6>
                            <p className='text-xs' style={{ color: "#584F68" }}>Earn 50 diamonds</p>
                        </div>
                    </Box>
                </TabPanel>
            </Tabs>
        </>
    )
}
export default Awards