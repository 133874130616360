import {useMutation} from 'react-query';
import {patchEditProfileDetails} from '../../../network/api/app';
import {useBuzzmiAuth} from '../useBuzzmiAuth/index.web';
import {useEffect} from 'react';

export const useEditProfile = () => {
  const {userData} = useBuzzmiAuth();
  const {
    mutate: mutateEditProfile,
    isLoading: isEditProfileLoading,
    isError: isEditProfileError,
    data: EditProfileResponse,
    error: EditProfileErrorResponse,
  } = useMutation({
    mutationFn: param => {
      console.log('param?.body');
      console.log(param?.body);
      return patchEditProfileDetails(param.id)({
        params: {isAuth: true, token: userData?.token},
        body: {...param?.body},
      });
    },
    onSuccess: data => {
      console.log('edited profile ==> ', data);
    },
  });
  const patchBaseEditProfile = async (id, body) =>
    mutateEditProfile({id, body});

  // useEffect(() => {
  //   console.log('EditProfileResponse', EditProfileResponse);
  //   console.log('EditProfileResponse error', EditProfileErrorResponse);
  // }, [EditProfileResponse, EditProfileErrorResponse]);

  return {
    isEditProfileLoading,
    isEditProfileError,
    EditProfileResponse,
    EditProfileErrorResponse,
    patchBaseEditProfile,
  };
};
