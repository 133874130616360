export default (time) => {
  const parsedTime = Math.round(time)

  if (isNaN(parsedTime)) {
    return "00:00"
  }

  const hours = Math.floor(parsedTime / 3600)
  const minutes = Math.floor((parsedTime % 3600) / 60)
  const seconds = parsedTime % 60

  const formatTimeComponent = (value) => value.toString().padStart(2, "0")

  const timeComponents = [
    hours === 0 ? null : formatTimeComponent(hours),
    formatTimeComponent(minutes),
    formatTimeComponent(seconds),
  ].filter((component) => component !== null)

  return timeComponents.join(":")
}
