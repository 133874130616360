import React from 'react';
import Box from '../../../../../../components/reusable/cards/box/Box';
import SimpleShowMore from '../../../../../../components/reusable/assets/showMore/simpleShowMore/SimpleShowMore';
import { useBuzzmiAuth } from '../../../../../../appState/hooks/useBuzzmiAuth/index.web';

const UserSkillsList = ({ skills }) => (
  <ul className="profile-user__skills-list">
    {skills?.length &&
      skills?.map((skill, index) => (
        <li key={index} className="d-flex align-items-center">
          <img src="/images/assets/flash.svg" alt="flash" className="me-2" />
          <div>{skill}</div>
        </li>
      ))}
  </ul>
);

const UserSkills = ({ skill }) => {
  return (
    <Box paddingMode="big" className="profile-user__info-block mt-3 mt-md-4">
      <div className="profile-user__block-title">Skills</div>
      {skill && skill.length > 0 ? (
        <SimpleShowMore
          content={
            <UserSkillsList
              skills={skill}
            />
          }
          sizes={{
            desktopHeight: 92,
            mobileHeight: 80,
          }}
        />
      ) : (
          <h6 className="h6 mb-3 mb-lg-4">{'No Skill Found'}</h6>
        )}
    </Box>
  );
};

export default UserSkills;
