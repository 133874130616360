import React, {useState, useEffect, Fragment} from 'react';
import SearchInput from '../../../reusable/form/items/inputs/SearchInput.jsx';
import PrimaryButton from '../../../reusable/btns/buttons/PrimaryButton.jsx';
import style from './chose-image.module.scss';
import UploadField from './UploadField.jsx';
import useSetParenComponentProps from '../../../../hooks/useSetParenComponentProps.js';
import {useDispatch, useSelector} from 'react-redux';
import {
  clearParentComponent,
  closeModal,
} from '../../../../store/slices/modalSlice/modalSlice.js';
import Strings from '../../../../string_key/Strings.web.js';
import {useBuzzmiAuth} from '../../../../appState/hooks/useBuzzmiAuth/index.web.js';
import {useUploadImages} from '../../../../appState/hooks/useUploadImages/index.js';
import {userCoverList} from '../../../../appState/hooks/hubState.js';
import {useAtom} from 'jotai';
import {useHubApp} from '../../../../appState/hooks/useHubApp/index.web.js';
import {useUploadCoverList} from '../../../../appState/hooks/useUploadCoverList/index.js';
import {Dialog, Transition} from '@headlessui/react';
import {XMarkIcon} from '@heroicons/react/24/outline';
import {Tabs, TabList, Tab} from 'react-tabs';
import ModalBackdrop from '../../../layouts/mainLayout/componentsV2/ModalBackDrop/index.js';
import {isImage, isVideo} from '../../../../types/media.js';
import LightBox from '../../../lightBox/LightBox.jsx';
import GalleryCarousel from '../../../reusable/carousel/GalleryCarousel.jsx';
import {Dots} from 'react-activity';
import {colors} from '../../../../res/colors.js';
import Loading from 'react-fullscreen-loading';

export const tabs = [
  {
    id: 'My',
    title: 'My',
  },
  {
    id: 'Shared',
    title: 'Shared',
  },
];

const MediaViewer = ({
  coverList,
  activeGalleryItemId,
  setActiveGalleryItemId,
}) => {
  const [overlineText, setOverlineText] = useState(null);
  // const [activeGalleryItemId, setActiveGalleryItemId] = useState(id);

  const onSwiperChange = ({realIndex}) => {
    const text = coverList[realIndex];
    if (text) {
      setOverlineText(text);
    }
  };

  const goToActiveSlide = activeGalleryItemId
    ? coverList.findIndex(el => el === activeGalleryItemId)
    : null;

  // console.log('goToActiveSlide', goToActiveSlide);
  return (
    <LightBox
      isOpened={activeGalleryItemId !== null}
      onClose={() => setActiveGalleryItemId(null)}>
      <GalleryCarousel
        list={coverList}
        overlineText={overlineText}
        onSwiperChange={onSwiperChange}
        goToActiveSlide={goToActiveSlide}
      />
    </LightBox>
  );
};

const ListView = ({
  selectedItems,

  onlyImages,

  _onChange,
  onChange,
  showSave,
  coverList,
  loading,
}) => {
  const [activeGalleryItemId, setActiveGalleryItemId] = useState(null);
  if (loading) return null;
  if (coverList === null || coverList?.length === 0)
    return (
      <div className={' justify-center mt-5 w-100'}>
        <img src="/images/assets/empty_pic_no_text.png" alt="No Post Found" />
        <div className="mt-3 text-xs sm:text-sm text-center sm:text-left text-stone-400">
          No media to display
        </div>
      </div>
    );

  return (
    <>
      {coverList?.map((creator, index) => {
        const isSelected = selectedItems.includes(creator);
        const isImageType = isImage(creator);
        const isVideoType = isVideo(creator);
        if (!isImageType && !isVideoType) {
          return null; // Skip rendering for non-image types when onlyImages is true
        }
        if (onlyImages && !isImageType) {
          return null; // Skip rendering for non-image types when onlyImages is true
        }
        return (
          <label className="col-3" key={index}>
            <input
              type="radio"
              name="image"
              value={creator}
              onClick={e =>
                !showSave
                  ? setActiveGalleryItemId(creator)
                  : _onChange(e, creator)
              }
              onChange={onChange}
              checked={isSelected}
            />
            <div className={`${style[`chose-image__img`]}`}>
              {isImageType && (
                // Display image if it's an image type or onlyImages is true
                <img
                  onDragStart={e => {
                    e.preventDefault();
                  }}
                  src={creator}
                  className={`img-fluid bg-white rounded-md ${
                    isSelected ? 'border-fuchsia-500 border-4 ' : ''
                  }`}
                />
              )}
              {!onlyImages && isVideoType && (
                // Display video thumbnail with play icon
                <div className="video-thumbnail-container h-100">
                  <video
                    onDragStart={e => {
                      e.preventDefault();
                    }}
                    src={creator}
                    className={`img-fluid h-100 w-full  rounded-md bg-white ${
                      isSelected ? 'border-fuchsia-500 border-4' : ''
                    }`}></video>
                  <div className="play-icon absolute bottom-0 left-2 text-4xl">
                    ▶
                  </div>
                </div>
              )}
            </div>
          </label>
        );
      })}

      <MediaViewer
        coverList={coverList}
        activeGalleryItemId={activeGalleryItemId}
        setActiveGalleryItemId={setActiveGalleryItemId}
      />
    </>
  );
};

const ChoseMultipleImageModalV2 = ({
  show,
  setShow,

  showSave,
  title,
  onlyImages,
  indexToDelete,
  setImages,
  pickSingle,
  images,
  selectedMedia,
  stopSelect,
}) => {
  const dispatch = useDispatch();
  // const setProps = useSetParenComponentProps();
  const [coverList, setCoverList] = useAtom(userCoverList);
  let selectedImage = '';
  const {callback} = useSelector(state => state.modal);
  const {userData} = useBuzzmiAuth();
  const {UploadFiles, isFileUploadError, isFileUploadLoading} =
    useUploadImages();
  const {UploadCoverFiles} = useUploadCoverList();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const maxPickSize = pickSingle ? 1 : 4;
  const [selectedItems, setSelectedItems] = useState([]);
  const {
    getCoverImageList,
    coverImageListLoader,
    sharedMediaList,
    sharedImageListLoader,
    getsharedImageList,
  } = useHubApp();

  // console.log('onlyImages', onlyImages);

  // useEffect(() => {
  //   function handleContextMenu(e) {
  //     e.preventDefault(); // prevents the default right-click menu from appearing
  //   }
  //   // add the event listener to the component's root element
  //   const rootElement = document.getElementById('redirectForm');
  //   rootElement.addEventListener('contextmenu', handleContextMenu);
  //   // remove the event listener when the component is unmounted

  //   return () => {
  //     rootElement.removeEventListener('contextmenu', handleContextMenu);
  //   };
  // }, []);

  useEffect(() => {
    getCoverImageList(userData?.token);
    getsharedImageList(userData?.token);
  }, []);

  const save = e => {
    e.preventDefault();
    console.log('selectedItems', selectedItems);
    // dispatch(clearParentComponent());
    // // setTimeout(() => {
    // //   setProps('ChoseImage', {image: selectedImage});
    // // }, 150);
    if (selectedItems.length == 0) return;
    if (setImages) {
      // mediaCall(selectedItems);
      setImages(prev => [...prev, ...selectedItems]);
    }
    if (selectedMedia) {
      selectedMedia(selectedItems);
    }
    setSelectedItems([]);
    setShow(false);
    // console.log('selectedImage', selectedImage);
    // if (setMedia) {
    //   setMedia(selectedItems);
    // }

    // dispatch(closeModal());
  };
  const onChange = (imageList, index) => {
    if (imageList?.length > 0) {
      // setIndex(index);
      // console.log('imageList', imageList, imageList.length);
      let params = [];
      imageList.forEach((image, index) => {
        const fd = new FormData();
        fd.append('file', imageList[index].file);

        params.push(
          Object.assign(fd, {
            params: {
              isAuth: true,
              type: 'cover',
              token: userData?.token,
            },
          }),
        );
      });

      UploadFiles(params, response => {
        // if (images?.length < 4) {
        //   const imagesToAdd = response.slice(0, 4 - images.length);
        //   setImages(prev => [...prev, ...imagesToAdd]);
        // }
        let arr = [];
        response.forEach((image, index) => {
          arr.push(
            Object.assign(
              {imageUrl: image},
              {
                params: {
                  isAuth: true,
                  token: userData?.token,
                },
              },
            ),
          );
        });

        console.log('response in uploading', arr);
        UploadCoverFiles(arr, response => {
          getCoverImageList(userData?.token);
          console.log('uploading covers response', response);
        });
      });
    }
  };

  const _onChange = (e, value) => {
    const valueExists = selectedItems.includes(value);
    if (pickSingle) {
      setSelectedItems([value]);
      return;
    }
    if (valueExists) {
      setSelectedItems(() => selectedItems.filter(item => item !== value));
    } else if (selectedItems.length < maxPickSize) {
      setSelectedItems(selectedItems => [...selectedItems, value]);
    } else {
      e.preventDefault();
    }
  };

  const handleTabSelect = index => setActiveTabIndex(index);

  return (
    <>
      <Transition.Root
        show={show}
        as={Fragment}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0">
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setShow(false)}>
          <ModalBackdrop />
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full">
                  <Dialog.Panel
                    className="pointer-events-auto w-screen max-w-md"
                    style={{maxWidth: 640}}>
                    <div className="flex h-full flex-col overflow-y-scroll bg-[#FBF8FF] shadow-xl">
                      <div className="p-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            {title}
                          </Dialog.Title>

                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-pink-500"
                              onClick={() => setShow(false)}>
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <Tabs
                        className="profile-section_v2 "
                        selectedIndex={activeTabIndex}
                        onSelect={handleTabSelect}>
                        <TabList>
                          {tabs.map(tab => (
                            <Tab>{tab.title}</Tab>
                          ))}
                        </TabList>
                      </Tabs>
                      <div className="pt-4 px-4 py-4">
                        <div className={style['chose-image']}>
                          {activeTabIndex == 0 && (
                            <>
                              {/* {isFileUploadLoading ? ( */}
                              {isFileUploadLoading ? (
                                <div class="flex justify-center items-center w-full mb-[17px] h-[6rem]">
                                  <Dots color={colors.PRIMARY_COLOR} />
                                </div>
                              ) : (
                                <UploadField
                                  callback={imgList => onChange(imgList)}
                                  onlyImages={onlyImages}
                                />
                              )}
                            </>
                          )}

                          {/* <SearchInput /> */}
                          {activeTabIndex == 0 && (
                            <form
                              id="redirectForm"
                              className={`row g-21 ${style['chose-image__contentv2']}`}>
                              <Loading
                                loading={coverImageListLoader}
                                background="transparent"
                                loaderColor={colors.PRIMARY_COLOR}
                              />

                              <ListView
                                onlyImages={onlyImages}
                                selectedItems={selectedItems}
                                onChange={onChange}
                                _onChange={_onChange}
                                showSave={showSave}
                                coverList={coverList}
                                loading={coverImageListLoader}
                              />
                            </form>
                          )}
                          {activeTabIndex == 1 && (
                            <form
                              id="redirectForm"
                              className={`row g-21 ${style['chose-image__contentv2']}`}>
                              <Loading
                                loading={sharedImageListLoader}
                                background="transparent"
                                loaderColor={colors.PRIMARY_COLOR}
                              />
                              <ListView
                                onlyImages={onlyImages}
                                selectedItems={selectedItems}
                                onChange={onChange}
                                _onChange={_onChange}
                                showSave={showSave}
                                coverList={sharedMediaList}
                                loading={sharedImageListLoader}
                              />
                            </form>
                          )}
                          {showSave && (
                            <div className={style['chose-image__button']}>
                              <button
                                type="button"
                                className="flex items-center justify-center rounded-md bg-pink-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pink-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600"
                                style={{
                                  backgroundColor: '#eb4dac',
                                  width: '100%',
                                }}
                                onClick={save}>
                                {Strings.save}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default ChoseMultipleImageModalV2;
