import {atom} from 'jotai';
import {atomWithStorage} from 'jotai/utils';

// const QAItems = atom([
//   {
//     image: {
//       src: '/images/hub/main/access/calendar.png',
//       alt: 'placeholder',
//     },
//     title: 'Gallery',
//     id: 'gallery',

//     order: 3,
//   },
// ]);
// const QAOtherItems = atom([
//   {
//     image: {
//       src: '/images/hub/main/access/challenges.png',
//       alt: 'placeholder',
//     },
//     title: 'Challenges',
//     id: 'challenges',
//     order: 0,
//   },
//   {
//     image: {
//       src: '/images/hub/main/access/calendar.png',
//       alt: 'placeholder',
//     },
//     title: 'Book 1-2-1',
//     id: 'book',

//     order: 1,
//   },
//   {
//     image: {
//       src: '/images/hub/main/access/sharing.png',
//       alt: 'placeholder',
//     },
//     title: 'Sharing',
//     id: 'sharing',

//     order: 2,
//   },
//   {
//     image: {
//       src: '/images/hub/main/access/plus-circle.png',
//       alt: 'placeholder',
//     },
//     title: 'Add',
//     id: 'add',

//     order: 4,
//   },

//   {
//     image: {
//       src: '/images/hub/main/access/chat.png',
//       alt: 'placeholder',
//     },
//     title: '...',
//     id: '...',

//     order: 5,
//   },
// ]);
const QAItems = atomWithStorage('QAItems', []);
const QAOtherItems = atomWithStorage('QAOtherItems', []);
// const QAItems = atom([]);
// const QAOtherItems = atom([]);
export {QAItems, QAOtherItems};
