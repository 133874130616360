import React, {useEffect, useState, useMemo} from 'react';
import ProfilePostTile from './profilePost/ProfilePostTile';
import ProfileReelTile from './profileReels/ProfileReelTile';
import useWindowSize from '../../../../hooks/useWindowSize';
import PropTypes from 'prop-types';
import {useProfileApp} from '../../../../appState/hooks';
import Loading from 'react-fullscreen-loading';
import {colors} from '../../../../res/colors';
import ProfileViewContentListV2 from './ProfileViewContentListV2';
import {useLinkTo} from '@react-navigation/native';
import {Node, getContentValue} from './types';

const ProfileContentListV2 = ({isPosts, ishide}) => {
  const [isTableActive, setIsTableActive] = useState(ishide ? false : true);
  const [currentPostId, setCurrentPostId] = useState(null);
  const {userReels, userPosts, postUploading} = useProfileApp();
  const linkTo = useLinkTo();
  // console.log('userReels===>', userReels);
  // const parseReels = data => {
  //   var arr = [];
  //   if (data?.length > 0) {
  //     data.forEach((arrayItem: Node, index: any) => {
  //       if (getContentValue(arrayItem.fields).includes('http')) {
  //         arr.push(
  //           Object.assign({
  //             content: getContentValue(arrayItem.fields, 'title'),
  //             _id: arrayItem.id,
  //             postItem: [],
  //             counts: arrayItem.repliesCount,
  //             comments: [],
  //             uri: {uri: getContentValue(arrayItem.fields)},
  //             dataItem: arrayItem,
  //           }),
  //         );
  //       }
  //     });
  //   }
  //   return arr;
  // };

  // const userReels = useMemo(() => parseReels(), [_userReels]);

  // useEffect(() => {
  //   if (_userReels?.length) {
  //     parseReels(_userReels);
  //   }
  // }, [userReels]);

  const handleSwitchAnchor = id => {
    setCurrentPostId(id);
    setTimeout(() => {
      linkTo(`/profile/user-view/${id}/${isPosts ? 'post' : 'reel'}`);
    }, 500);
  };

  return (
    <>
      <Loading
        loading={postUploading}
        background="transparent"
        loaderColor={colors.PRIMARY_COLOR}
      />

      {isTableActive ? (
        <ul
          className={
            isPosts ? 'profile-posts-list__table' : 'reels__list-table'
          }>
          {isPosts ? (
            userPosts && userPosts.length > 0 ? (
              userPosts.map(post => (
                <ProfilePostTile
                  key={post?.id}
                  post={post}
                  handleSwitchAnchor={handleSwitchAnchor}
                  contentType={post?.contentType ? post?.contentType : 'all'}
                />
              ))
            ) : (
              <div className={'flex justify-center mt-5 w-100'}>
                <img
                  src="/images/assets/empty_pic.png"
                  alt="No Post Found"
                  style={{width: 180}}
                />
              </div>
            )
          ) : userReels && userReels.length > 0 ? (
            userReels.map(reel => (
              <ProfileReelTile
                key={reel?.id}
                reel={reel}
                handleSwitchAnchor={handleSwitchAnchor}
                contentType={reel?.contentType ? reel?.contentType : 'all'}
              />
            ))
          ) : (
            <div className={'flex justify-center mt-5 w-100'}>
              <img
                src="/images/assets/empty_pic.png"
                alt="No Reel Found"
                style={{width: 180}}
              />
            </div>
          )}
        </ul>
      ) : (
        <ProfileViewContentListV2
          isTableActive={isTableActive}
          isPosts={isPosts}
          currentId={currentPostId}
        />
      )}
    </>
  );
};

ProfileContentListV2.propTypes = {
  isPosts: PropTypes.bool.isRequired,
};

export default ProfileContentListV2;
