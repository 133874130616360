// noinspection ES6CheckImport

import React, { useContext, useEffect, useState } from 'react';
import MyAuthLayout from '../../../components/layouts/myAuthLayout/MyAuthLayout';
import Logo from '../../../components/reusable/assets/logo/Logo';
import { useForm } from 'react-hook-form';
import { useToast } from "react-native-toast-notifications";
import FormForgotPassword from './items/FormForgotPassword.jsx';
import Strings from '../../../string_key/Strings.web';


const ForgetPassword = props => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isLoad, setIsLoad] = useState(false);
  const toast = useToast();

  return (
    <MyAuthLayout>
      <div className="content-auth__column">
        <div className="forgot-password__logo">
          <Logo subtitle="Welcome to" />
        </div>
        <div className="h3 content-auth__title">{Strings.forgot_your_password}</div>
        <div className="content-auth__text text-r">
          {Strings.forgot_password_description}
        </div>
        <FormForgotPassword props={props} />
      </div>
    </MyAuthLayout>
  );
};
export default ForgetPassword;
