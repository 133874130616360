import React, { useEffect, useState } from 'react';
import Input from '../../../../../../../../components/reusable/form/items/inputs/Input';
import ProfilePhoneSelect from '../profilePhoneSelect/ProfilePhoneSelect';
import { useDispatch } from 'react-redux';
import { openModal } from '../../../../../../../../store/slices/modalSlice/modalSlice';
import { useBuzzmiAuth } from '../../../../../../../../appState/hooks/useBuzzmiAuth/index.web';
import { useIsFocused } from '@react-navigation/native';

const VerifyButton = ({ isValid, onClick }) =>
  isValid ? (
    <div className="text-success d-flex align-items-center profile-settings__verify">
      <img
        src="/images/assets/contact-verefi.svg"
        alt="contact-verefied icon"
      />
      <span> Verified</span>
    </div>
  ) : (
      <button onClick={onClick} type={"button"} className="text-alt-primary text-r profile-settings__verify">
        {' '}
      Verify
      </button>
    );

const ProfileContactsInfo = ({ values, register, setValue }) => {

  const {
    userData,
    emailMobResponse,
    emailmobload,
    emailError,
    onSendMobileEmailOtp,
  } = useBuzzmiAuth();

  const isFocused = useIsFocused();

  const [isValidEmail, setIsValidEmail] = useState(userData?.isEmailVerified || false);
  const [isValidPhone, setIsValidPhone] = useState(userData?.isMobileVerified || false);

  const [mobResponse, setMobResopnse] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    setValue('email', userData?.email)
  }, [userData])

  const emailVerify = () => {
    dispatch(
      openModal({
        modalName: 'renderAlert',
        modalType: 'alert',
        modalProps: {
          nameAlert: 'VerifyPhoneAlert',
          propsAlert: {
            phone: values.email,
            propfocus: isFocused
          },
        },
      }),
    );
  };
  const phoneVerify = () => {
    dispatch(
      openModal({
        modalName: 'renderAlert',
        modalType: 'alert',
        modalProps: {
          nameAlert: 'VerifyPhoneAlert',
          propsAlert: {
            phone: values.phone,
            propfocus: isFocused
          },
        },
      }),
    );
  };

  return (
    <div className="profile-settings__contacts">
      <div className="d-flex justify-content-between">
        {/* <Loading
          loading={emailmobload}
          background="transparent"
          loaderColor={colors.PRIMARY_COLOR}
        /> */}
        <Input
          label="Email address"
          type="email"
          name="email"
          placeholder="E-mail"
          register={register}
          disabled={userData?.isEmailVerified}
          classNameWrapper="profile-settings__contact-input"
          pattern={/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/}
        />
        <VerifyButton onClick={emailVerify} isValid={isValidEmail} />
      </div>
      <div>
        <div className="input-label label-text">Your phone number</div>
        <div className="d-flex justify-content-between">
        
            <ProfilePhoneSelect
              customSetValues={tel => setValue('phone', tel)}
              setIsValid={() => { }}
              isMobileVerified={userData?.isMobileVerified}
            />
  
          <VerifyButton onClick={phoneVerify} isValid={isValidPhone} />
        </div>
      </div>
    </div>
  );
};

export default ProfileContactsInfo;
