import React from 'react';

const ModalBackdrop = ({sidebarFix = false}) => {
  return (
    <div
      className={
        'fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' +
        ` ${sidebarFix ? 'z-[3]' : ''}`
      }
    />
  );
};

export default ModalBackdrop;
