import {useMutation} from 'react-query';
import {createPlanActivity} from '../../../network/api/app';

export const useCreatePlanActivity = () => {
  const {
    mutate: mutateCreatePlanActivity,
    isLoading: isCreatePlanActivityLoading,
    isError: isCreatePlanActivityError,
    data: CreatePlanActivityResponse,
    error: CreatePlanActivityErrorResponse,
  } = useMutation(createPlanActivity);

  const postCreatePlanActivity = params => mutateCreatePlanActivity(params);

  return {
    isCreatePlanActivityLoading,
    CreatePlanActivityResponse,
    CreatePlanActivityErrorResponse,
    postCreatePlanActivity,
  };
};
