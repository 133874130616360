import React, { useState } from 'react';
import { Link } from '@react-navigation/native';
import PrimaryButton from "../../../../components/reusable/form/items/buttons/PrimaryButton.jsx";
import PhoneSelect from "../../../../components/reusable/form/select/PhoneSelect.jsx";
import MobileLayout from "../../../../components/layouts/mobileLayout/MobileLayout.jsx";
import BaseLayout from '../../../../components/layouts/baseLayout/BaseLayout.jsx';
import Strings from '../../../../string_key/Strings.web.js';

const ProvidePhone = () => {
  const [isValid, setIsValid] = useState(false)

  return (
    <BaseLayout>
      <MobileLayout>
        <div className='mb-4'>
          <div className='h4'>
            {Strings.provide_your_contact_information}
          </div>
          <div className='mt-9' style={{ justifyContent: "center", display: "flex" }}>
            <img src="/images/assets/phone.png" alt="" />
          </div>
          <div className='h6 mt-9 mb-sm-3 mb-2'>
            {Strings.your_phone_number}
          </div>
          <PhoneSelect setIsValid={setIsValid} />
          <Link to={{
            screen: 'ProvideEmail', params: { userData: Object.assign({ loginType: 'Email' }) }
          }}>
            <div className='h6 text-alt-primary mt-5 d-inline-block mb-6'>
              {Strings.or_sign_up_with_email}
            </div>
          </Link>
          <div className='text-r text-secondary'>
            {Strings.contact_email_description}
          </div>
        </div>
        <div>
          <PrimaryButton type='submit' className={`mb-7 ${!isValid ? 'button_inactive' : ''}`}
            form='phone-code'>{Strings.next}</PrimaryButton>
          <div className='text-s text-secondary'>
            By continuing, you agree to Buzzmi’s Terms of Service and confirm that you have read Buzzmi’s Privacy
            Policy.
          </div>
        </div>
      </MobileLayout>
    </BaseLayout>
  );
};

export default ProvidePhone;
