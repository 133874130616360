import React, { useEffect, useState, useRef, Fragment } from 'react';
import { useFormValidator } from '../../../../form-validator/hooks/index.js';
import ValidationSchema from '../../../../form-validator/ValidationSchema.js';
import MainLayout from '../../../../components/layouts/mainLayout/MainLayout.jsx';
import HubTaskForm from './HubTaskForm.jsx';
import { useNavigation } from '@react-navigation/core';
import { useHubApp } from '../../../../appState/hooks/useHubApp/index.web.js';
import { useBuzzmiAuth } from '../../../../appState/hooks/useBuzzmiAuth/index.web.js';
import Loading from 'react-fullscreen-loading';
import { colors } from '../../../../res/colors.js';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { EventRegister } from 'react-native-event-listeners';
import { useDispatch } from 'react-redux';

import { CheckIcon } from '@heroicons/react/24/outline';
import {
  closeModal,
  openModal,
} from '../../../../store/slices/modalSlice/modalSlice.js';
import MainLayoutWithAspectSide from '../../../../components/layouts/mainLayout/MainLayoutWithAspectSide.jsx';
import ChoseMultipleImageModal from '../../../../components/modal/modals/choseMultipleImageModal/ChoseMultipleImageModal.jsx';
import ChoseMultipleImageModalV2 from '../../../../components/modal/modals/choseMultipleImageModal/ChoseMultipleImageModalV2.jsx';
import ModalBackdrop from '../../../../components/layouts/mainLayout/componentsV2/ModalBackDrop/index.js';
import { useToast } from 'react-native-toast-notifications';
import { ShowError } from '../../../HOC/MessageWeb.js';

const validationSchema = new ValidationSchema({
  name: [
    { rule: 'required' },
    { rule: 'minLength', value: 3 },
    { rule: 'maxLength', value: 40 },
  ],

  // description: [{rule: 'required'}],
  // coverImage: [{rule: 'required'}],
  // audio: [{rule: 'required'}],

  // images: [{rule: 'required'}, {rule: 'minArray', value: 1}],
  // tags: [{rule: 'required'}, {rule: 'minArray', value: 1}],
});

const data = {
  initPopularNames: [
    'Meditate',
    'Workout',
    'Drink water',
    'Dance',
    'Sleep better',
  ],
};

function _renderSuccessModal(open, setOpen, responseItem) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-32 w-32 items-center justify-center rounded-full bg-green-100">
                    {/* <CheckIcon
                      className="h-6 w-6 text-green-500"
                      aria-hidden="true"
                    /> */}
                    <div className="hub__cup mt-4">
                      <img src="/images/assets/cup.png" alt="cup -img" />
                    </div>
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900">
                      The activity has been successfully created
                    </Dialog.Title>

                    {/* <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Consequatur amet labore.
                      </p>
                    </div> */}
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-pink-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pink-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-500"
                    onClick={() => {
                      setOpen(false);
                      EventRegister.emit('openBaseActivityModal', responseItem);
                    }}>
                    OK
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

const HubAddTaskv2 = ({ param, set_is_open, is_open }) => {
  const params = param;
  const [showInTask, setShowInTask] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const { navigate, goBack } = useNavigation();
  const formRef = useRef(null);
  const toast = useToast();
  const {
    postCreateBaseActivity,
    CreateBaseActivityResponse,
    CreateBaseActivityErrorResponse,
    isCreateBaseActivityLoading,
    patchBaseActivity,
    EditBaseActivityResponse,
    EditBaseActivityErrorResponse,
    isEditBaseActivityLoading,
    getWalletData,
    getRankInfoDetail,
    getNextRankInfoDetail,
  } = useHubApp();
  const { userData } = useBuzzmiAuth();
  const dispatch = useDispatch();
  //TODO example function to send request

  useEffect(() => {
    if (CreateBaseActivityErrorResponse) {
      console.log(CreateBaseActivityErrorResponse)
      ShowError(CreateBaseActivityErrorResponse.toString().replace("Error: Error:", ""), toast);
    }
    if (CreateBaseActivityResponse) {
      // navigate('AddActivities');
      setOpen(true);
      EventRegister.emit('refresh-baseActivities');
      // dispatch(
      //   openModal({
      //     modalName: 'renderAlert',
      //     modalType: 'alert',
      //     modalProps: {
      //       nameAlert: 'AddTaskAlert',
      //       propsAlert: {
      //         handleOK: () => {
      //           // goBack();
      //           dispatch(closeModal());
      //           setTimeout(() => {
      //             EventRegister.emit(
      //               'openBaseActivityModal',
      //               CreateBaseActivityResponse,
      //             );
      //           }, 800);
      //         },
      //         isPlan: false,
      //       },
      //     },
      //   }),
      // );
    }
  }, [CreateBaseActivityResponse, CreateBaseActivityErrorResponse]);

  const sendRequest = async formData => {
    try {
      console.log('formData', formData);

      let param_data = Object.assign({
        title: formData?.name,
        // description:
        // formData?.description?.length > 0 ? formData?.description : '',
        imageURL:
          formData.coverImage?.length > 0
            ? formData.coverImage
            : 'https://onesay-backend-dev.s3.amazonaws.com/2aeb4c73-5e0d-4a45-a700-52a156bdb143-buzz_logo.png',
      });

      formData?.description?.length > 0 &&
        Object.assign(param_data, {
          description: formData?.description,
        });
      formData?.images &&
        Object.assign(param_data, {
          descriptionMedia: Object.values(formData?.images),
        });
      formData?.audio &&
        Object.assign(param_data, {
          descriptionAudio: formData?.audio,
        });
      // formData?.video &&
      //   Object.assign(param_data, {
      //     descriptionVideo: formData?.video,
      //   });
      formData?.tags?.length > 0 &&
        Object.assign(param_data, {
          tags: formData?.tags?.map(item => item.id),
        });

      formData?.progimgTitles?.length > 0 &&
        Object.assign(param_data, {
          imagesTitles: formData?.progimgTitles,
        });
      formData?.progimgDes?.length > 0 &&
        Object.assign(param_data, {
          imagesDescriptions: formData?.progimgDes,
        });
      formData?.AudioTitle?.length > 0 &&
        Object.assign(param_data, {
          audioTitle: formData?.AudioTitle,
        });

      formData?.mediaUrl?.length > 0 &&
        Object.assign(param_data, {
          mediaUrl: formData?.mediaUrl,
        });

      formData?.AudioDescription?.length > 0 &&
        Object.assign(param_data, {
          audioDescription: formData?.AudioDescription,
        });

      postCreateBaseActivity(
        Object.assign(param_data, {
          params: { isAuth: true, token: userData?.token },
        }),
      );
    } catch (e) {
      setError(e);
    }
  };
  const sendEditRequest = async formData => {
    try {
      let param_data = Object.assign({
        title: formData?.name,
        description: formData?.description,
        imageURL: formData.coverImage,
      });
      formData?.images &&
        Object.assign(param_data, {
          descriptionMedia: Object.values(formData?.images),
        });

      formData?.mediaUrl &&
        Object.assign(param_data, {
          mediaUrl: formData?.mediaUrl,
        });

      formData?.progimgDes?.length > 0 &&
        Object.assign(param_data, {
          imagesDescriptions: formData?.progimgDes,
        });
      formData?.progimgTitles?.length > 0 &&
        Object.assign(param_data, {
          imagesTitles: formData?.progimgTitles,
        });

      formData?.audio &&
        Object.assign(param_data, {
          descriptionAudio: formData?.audio,
        });
      // formData?.video &&
      //   Object.assign(param_data, {
      //     descriptionVideo: formData?.video,
      //   });
      formData?.tags?.length > 0 &&
        Object.assign(param_data, {
          tags: formData?.tags?.map(item => item.id),
          // tags: [],
        });

      patchBaseActivity(params?.item?.id, param_data);
    } catch (e) {
      setError(e);
    }
  };

  // useEffect(() => {
  //   console.log("params?.item yagnesh")
  //   console.log(params?.item)
  // }, [params?.item])

  const defaultValues = {
    coverImage: params?.item?.image,
    name: params?.item?.title,
    description: params?.item?.description,
    images: params?.item?.descriptionMedia?.values,
    progimgTitles: params?.item?.imagesTitles,
    progimgDes: params?.item?.imagesDescriptions,
    mediaUrl: params?.item?.mediaUrl || [''],
    // video: params?.item?.descriptionVideo,
    audio: params?.item?.descriptionAudio,
    tags:
      params?.item?.tags?.length > 0
        ? params?.item?.tags
        : params?.item?.tags?.values?.length
          ? params?.item?.tags?.values.map(str => ({
            title: str,
            id: str,
            value: str,
          }))
          : [],
    showInTask: showInTask,
  };


  useEffect(() => {
    setValue('images', params?.item?.descriptionMedia?.values || []);
    setValue('coverImage', params?.item?.image || '');
  }, [params?.item, is_open])


  const {
    register,
    handleSubmit,
    setValue,
    isValid,
    values,
    setValues,
    resetForm,
  } = useFormValidator(
    validationSchema,
    params?.isEdit ? sendEditRequest : sendRequest,
    { defaultValues },
  );
  useEffect(() => {
    if (EditBaseActivityResponse) {


      // navigate('AddActivities');
      // navigate(
      //   params?.fromPath ? params?.fromPath : 'HubTasks',
      //   EditBaseActivityResponse,
      // );
      // setValues({});
      set_is_open(false);
      EventRegister.emit('refresh-baseActivities');
      getWalletData(userData?.token),
        getRankInfoDetail(userData?.id, userData?.token);
      getNextRankInfoDetail(userData?.id, userData?.token);
    }
  }, [EditBaseActivityResponse, EditBaseActivityErrorResponse]);

  const handleClose = () => {
    resetForm();
    set_is_open(false);
  };

  return (
    // <MainLayoutWithAspectSide showTitleButton={false} showRankInfo={false}>
    //   <Loading
    //     loading={isCreateBaseActivityLoading || isEditBaseActivityLoading}
    //     background="transparent"
    //     loaderColor={colors.PRIMARY_COLOR}
    //   />
    //   {/* {console.log('values==>', values)}s */}
    //   <HubTaskForm
    //     error={error}
    //     handleSubmit={handleSubmit}
    //     setValue={setValue}
    //     values={values}
    //     isValid={isValid}
    //     register={register}
    //     data={data}
    //     isEdit={params?.isEdit}
    //   />
    // </MainLayoutWithAspectSide>
    <>
      <Transition.Root show={is_open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={handleClose}>
          <ModalBackdrop />
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full">
                  <Dialog.Panel
                    className="pointer-events-auto w-screen max-w-md"
                    style={{ maxWidth: 640 }}>
                    <div className="flex h-full flex-col overflow-y-scroll bg-[#FBF8FF] shadow-xl">
                      <div className="p-6 pb-0">
                        <div className="flex items-start justify-between">
                          <div className="ml-3 flex h-3 items-center invisible" />
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            {params?.isEdit ? 'Edit Activity' : 'Add Activity'}
                          </Dialog.Title>

                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-pink-500"
                              onClick={handleClose}>
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="pt-4 px-4 py-4">
                        <>
                          <Loading
                            loading={
                              isCreateBaseActivityLoading ||
                              isEditBaseActivityLoading
                            }
                            background="transparent"
                            loaderColor={colors.PRIMARY_COLOR}
                          />
                          {/* {console.log('values==>', values)}s */}
                          <HubTaskForm
                            hideTitle={true}
                            error={error}
                            handleSubmit={handleSubmit}
                            setValue={setValue}
                            values={values}
                            isValid={isValid}
                            register={register}
                            data={data}
                            isEdit={params?.isEdit}
                            item={params?.item}
                          />
                        </>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {_renderSuccessModal(open, setOpen, CreateBaseActivityResponse)}
    </>
  );
};

export default HubAddTaskv2;
