import React, {useEffect} from 'react';
import {useProfileApp} from '../../../../../../appState/hooks';
import {useBuzzmiAuth} from '../../../../../../appState/hooks/useBuzzmiAuth/index.web';
import useWindowSize from '../../../../../../hooks/useWindowSize';
import {profileDataBlocks, profileMobileDataBlocks} from './constants';
import ProfileDataBlock from './ProfileDataBlock';

const mocDataBadges = [
  {
    id: 1,
    icon: {
      src: '/images/pages/profile/moc/pig.png',
      alt: 'Pig',
    },
  },
  {
    id: 2,
    icon: {
      src: '/images/pages/profile/moc/like.png',
      alt: 'Like',
    },
  },
  {
    id: 3,
    icon: {
      src: '/images/pages/profile/moc/gym.png',
      alt: 'Gym',
    },
  },
  {
    id: 4,
    icon: {
      src: '/images/pages/profile/moc/chef.png',
      alt: 'Chef',
    },
  },
  {
    id: 5,
    icon: {
      src: '/images/pages/profile/moc/bird.png',
      alt: 'Bird',
    },
  },
  {
    id: 6,
    icon: {
      src: '/images/pages/profile/moc/chef.png',
      alt: 'Chef',
    },
  },
  {
    id: 7,
    icon: {
      src: '/images/pages/profile/moc/bird.png',
      alt: 'Bird',
    },
  },
];
let mocDataContacts = [];
let mocDataActivity = [];

const ProfileDataBlocks = ({
  setOpenActivityModal,
  setOpenBadgesModal,
  setOpenFriendsModal,
}) => {
  const {width} = useWindowSize();
  const {
    getInformationContacts,
    userContactInfo,
    getUsersActivity,
    userActivityInfo,
  } = useProfileApp();
  const {userData} = useBuzzmiAuth();

  useEffect(() => {
    getInformationContacts(userData?.token);
    getUsersActivity(userData?.token);
  }, [userData]);

  useEffect(() => {
    mocDataContacts = [
      {
        title: 'Following',
        value: userContactInfo?.following,
        link: '/profile/mobile-users/1',
      },
      {
        title: 'Followers',
        value: userContactInfo?.followers,
        link: '/profile/mobile-users/1',
      },
      {
        title: 'Subscribers',
        value: userContactInfo?.friends,
        link: '/profile/mobile-users/1',
      },
    ];
  }, [userContactInfo]);

  useEffect(() => {
    mocDataActivity = [
      {
        title: 'Posts',
        value: userActivityInfo?.posts || 0,
      },
      {
        title: 'Views',
        value: userActivityInfo?.views || 0,
      },
      {
        title: 'Listens',
        value: userActivityInfo?.listens || 0,
      },
    ];
  }, [userActivityInfo]);

  const data = {
    badges: mocDataBadges,
    contacts: mocDataContacts,
    activity: mocDataActivity,
  };
  const handleOnPress = item => {
    console.log('pressed', item.title);
    if (item?.title == 'Badges') setOpenBadgesModal(true);
    if (item?.title == 'Activity') setOpenActivityModal(true);
    if (item?.title == 'Contacts') setOpenFriendsModal(true);
  };

  return (
    <>
      {width > 992
        ? profileDataBlocks.map(item => (
            <ProfileDataBlock
              data={data}
              item={item}
              key={item.title}
              onPress={() => handleOnPress(item)}
            />
          ))
        : profileMobileDataBlocks.map(item => (
            <ProfileDataBlock
              data={data}
              item={item}
              key={item.title}
              onPress={() => handleOnPress(item)}
            />
          ))}
    </>
  );
};

export default ProfileDataBlocks;
