import React, {useEffect, useState, useRef} from 'react';
import MobileLayout from '../../../../components/layouts/mobileLayout/MobileLayout.jsx';
import PrimaryButton from '../../../../components/reusable/form/items/buttons/PrimaryButton.jsx';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import CodeInput from '../../../../components/reusable/form/items/inputs/codeInputs/CodeInput.jsx';
import ResendCode from '../../../../components/reusable/form/items/buttons/ResendCode';
import FinallyMessage from '../../../../components/reusable/form/items/validation/FinallyMessage.jsx';
import * as yup from 'yup';
import BaseLayout from '../../../../components/layouts/baseLayout/BaseLayout.jsx';
import {getData} from '../../../../res/asyncStorageHelper.web.js';
import {useNavigation} from '@react-navigation/core';
import Loading from 'react-fullscreen-loading';
import {colors} from '../../../../res/colors.js';
import {POST} from '../../../../api_helper/ApiServices.js';
import {NEW_VERIFY_OTP, SEND_OTP} from '../../../../api_helper/Api.js';
import Strings from '../../../../string_key/Strings.web.js';
import {useEncryptedAtom} from '../../../../appState/hooks/useEncryptedAtom/index.web.js';
import {_sSecretAtom, _sUuidAtom} from '../../../../appState/userState.js';
import {useToast} from 'react-native-toast-notifications';
import {ShowSuccess} from '../../../HOC/MessageWeb.js';

const ProvidePhoneCode = props => {
  const {navigate} = useNavigation();
  const [code, setCode] = useState([]);
  const [valid, setValid] = useState(null);
  const [isLoad, setIsLoad] = useState(false);
  let refUrlParams = useRef(props.route.params);
  const [secret_s, setSSecret] = useEncryptedAtom(_sSecretAtom);
  const [s_uuid, setUUID] = useEncryptedAtom(_sUuidAtom);
  const toast = useToast();
  const [showResend, setShowResend] = useState(true);
  const confirmCodeSchema = yup.object({
    code: yup
      .string()
      .required('Please enter your code.')
      .min(6, 'Length must be 6 characters'),
  });

  // useEffect(() => {
  //   setTimeout(() => {
  //     window.history.replaceState(null, 'Title', '/provide-phone-code')
  //   }, 100);
  // }, [])

  const {handleSubmit, register, setValue, getValues} = useForm({
    resolver: yupResolver(confirmCodeSchema),
  });

  const onSubmit = () => {
    navigate('Bio', {user: refUrlParams.current?.param});
  };

  const handleResendCode = async () => {
    setValid(null);
    setCode([]);
    setValue('code', null);
    setIsLoad(true);
    let postData = {
      mobile:
        refUrlParams.current?.param?.countryCode +
        refUrlParams.current?.param?.phone,
      flag: true,
    };
    POST(SEND_OTP, false, null, null, postData, (response, isError) => {
      setIsLoad(false);
      if (isError) {
        console.log(isError);
      } else {
        setSSecret(response?.secret);
        setUUID(response?.uniqueUUID);
        setTimeout(() => {
          ShowSuccess(response?.message, toast);
        }, 250);
      }
    });
  };

  // todo remove on production
  useEffect(() => {
    if (getValues('code')?.length === 4) {
      getData('s_uuid', s_uuid => {
        setIsLoad(true);
        let postData = {otp: getValues('code'), uniqueUUID: s_uuid};
        POST(
          NEW_VERIFY_OTP,
          false,
          null,
          null,
          postData,
          (response, isError) => {
            setIsLoad(false);
            if (isError) {
              setValid('invalid');
            } else {
              setShowResend(false);
              setValid('valid');
            }
          },
        );
      });
    }
  }, [code, getValues]);

  return (
    <BaseLayout>
      <Loading
        loading={isLoad}
        background="transparent"
        loaderColor={colors.PRIMARY_COLOR}
      />
      <MobileLayout>
        <div className="mb-4">
          <div className="h4">{Strings.provide_your_contact_information}</div>
          <div className="mt-9 mb-9 mb-sm-20" style={{ justifyContent: "center", display: "flex" }}>
            <img src="/images/assets/phone.png" alt="" />
          </div>
          <form id={'input-phone-code'}>
            <label className={'input-label'}>
              <div>{Strings.verify_your_phone_number}</div>
              <div className={'input-code-wrapper '}>
                <input
                  className="input-code-wrapper__hide p-0"
                  {...register('code')}
                  type="text"
                />
                {[...Array(4)].map((_, i) => (
                  <CodeInput
                    success={valid === 'valid'}
                    error={valid === 'invalid'}
                    setCode={setCode}
                    key={i}
                    index={i}
                    register={register}
                    label={'Input Label'}
                    setValue={setValue}
                    regex={/^[0-9]*$/}
                  />
                ))}
              </div>
            </label>
            {(!valid || getValues('code')?.length < 4) && (
              <div className="text-r text-secondary mt-9">
                {Strings.verification_code_sent_description
                  .replace('{digit}', 4)
                  .replace(
                    '{email}',
                    `${
                      refUrlParams.current?.param?.countryCode +
                      refUrlParams.current?.param?.phone
                    }`,
                  )}
              </div>
            )}
            <FinallyMessage
              show={valid && getValues('code')?.length === 4}
              isValid={valid === 'valid'}
              successTitle={Strings.success}
              successMessage={Strings.your_phone_has_been_verified}
              wrongTitle={Strings.wrong_verification_number}
              wrongMessage={Strings.re_enter_the_code}
            />
          </form>
        </div>

        <div>
          {showResend && <ResendCode handleResendCode={handleResendCode} />}
          <PrimaryButton
            className={`mb-7 ${
              valid !== 'valid' || getValues('code')?.length !== 4
                ? 'button_inactive'
                : ''
            }`}
            type="button"
            onClick={onSubmit}
            form="input-phone-code">
            {Strings.next}
          </PrimaryButton>
        </div>
      </MobileLayout>
    </BaseLayout>
  );
};

export default ProvidePhoneCode;
