import React, {useState} from 'react';
import PlanSearch from './items/planSearch/PlanSearch.jsx';
import Box from '../../../../cards/box/Box.jsx';
import PlanBio from './items/planBio/PlanBio.jsx';
import PlanInfo, {NewPlanInfo} from './items/planInfo/PlanInfo.jsx';

const AdditionalInformationPlan = () => {
  const [open, setOpen] = useState(false);
  const [myBenifits, setMyBenifits] = useState(false);
  return (
    <Box
      topComponent={
        // <PlanBio />
        <>
          <div className=" flex flex-wrap items-center justify-between sm:flex-nowrap ">
            <div>
              <h3 className="sm:text-xl text-md font-semibold leading-6 text-gray-900">
                Membership
              </h3>
            </div>

            <div className="flex-shrink-0">
              <button
                type="button"
                className="relative inline-flex items-center rounded-md sm:px-3 py-2 text-sm sm:font-semibold text-[#eb4dac]  sm:bg-[#eb4dac] sm:text-white sm:shadow-sm sm:hover:bg-[#eb4dac] sm:focus-visible:outline focus-visible:outline-2 sm:focus-visible:outline-offset-2 sm:focus-visible:outline-[#eb4dac]"
                // style={{backgroundColor: '#eb4dac'}}
                onClick={() => {
                  setMyBenifits(true);
                  setOpen(!open);
                }}>
                See my Benifits
              </button>
            </div>
          </div>
        </>
      }
      // bottomComponent={<PlanSearch />}
      paddingMode="big">
      <PlanInfo
        pressedOpen={open}
        myBenifits={myBenifits}
        setMyBenifits={setMyBenifits}
      />
    </Box>
  );
};

export const NewAdditionalInformationPlan = () => {
  const [open, setOpen] = useState(false);
  const [myBenifits, setMyBenifits] = useState(false);
  return (
    // <Box
    //   topComponent={
    //     // <PlanBio />
    //     <>
    //       <div className=" flex flex-wrap items-center justify-between sm:flex-nowrap ">
    //         <div>
    //           <h3 className="sm:text-xl text-md font-semibold leading-6 text-gray-900">
    //             Membership
    //           </h3>
    //         </div>

    //         <div className="flex-shrink-0">
    //           <button
    //             type="button"
    //             className="relative inline-flex items-center rounded-md sm:px-3 py-2 text-sm sm:font-semibold text-[#eb4dac]  sm:bg-[#eb4dac] sm:text-white sm:shadow-sm sm:hover:bg-[#eb4dac] sm:focus-visible:outline focus-visible:outline-2 sm:focus-visible:outline-offset-2 sm:focus-visible:outline-[#eb4dac]"
    //             // style={{backgroundColor: '#eb4dac'}}
    //             onClick={() => {
    //               setMyBenifits(true);
    //               setOpen(!open);
    //             }}>
    //             See my Benifits
    //           </button>
    //         </div>
    //       </div>
    //     </>
    //   }
    //   bottomComponent={<PlanSearch />}
    //   paddingMode="big">
    <NewPlanInfo
      pressedOpen={open}
      myBenifits={myBenifits}
      setMyBenifits={setMyBenifits}
      setOpenMyBenifits={setOpen}
    />
    // </Box>
  );
};

export default AdditionalInformationPlan;
