import React, { useEffect, useState } from 'react';
import useWindowSize from '../../../../hooks/useWindowSize';
import { useBuzzmiAuth } from '../../../../appState/hooks/useBuzzmiAuth/index.web';
import CustomRatingGold from '../../../../components/reusable/assets/customRating/CustomRatingGold';
import TextareaWithoutBorder from '../../../../components/reusable/form/items/textarea/TextareaWithoutBorder';
import Button from '../../../../components/reusable/btns/buttons/Button';
import BackBtnWithTitlePage from '../../../../components/reusable/btns/backBtn/BackBtnWithTitlePage';
import { useProfileInfo } from '../../../../appState/hooks/useProfileInfo';
import { useProfileApp } from '../../../../appState/hooks';
import { ShowError } from '../../../HOC/MessageWeb';
import { useToast } from 'react-native-toast-notifications';
import { useNavigation } from '@react-navigation/core';
import { useUserRatings } from '../../../../appState/hooks/useUserRatings';

const ProfileRattings = ({ props }) => {
  const navigation = useNavigation();
  const [reviewText, setReviewText] = useState('');
  const [ratingValue, setRatingValue] = useState(0);
  const toast = useToast();
  const { getOnUserRatings } = useUserRatings();
  const { profileInfo } = useProfileInfo();
  const { postRattingsOnUser, PostRatingResponse } = useProfileApp();
  const { userData } = useBuzzmiAuth();

  useEffect(() => {
    if (PostRatingResponse) {
      navigation.goBack()
      getOnUserRatings(profileInfo?.id, userData?.token)
    }
  }, [PostRatingResponse])

  return (
    <>
      <div className={'bg-[#faf7ff] flex-1'}>
        <BackBtnWithTitlePage
          title={"Leave a rating"}
          isFixed
          btnText="Back"
        />


        <div className="pt-24 px-4 premium-content-reviews__rating-modal">
          <div className="d-flex flex-row align-items-center training-card">
            <img
              src={profileInfo?.avatar ? profileInfo?.avatar : "/images/moc/avatar-big.png"}
              alt="workout's start screen"
              className="training-card-image"
            />
            <div>
              <p>Review to</p>
              <h6 className="h6">{profileInfo?.firstName + ' ' + profileInfo?.lastName}</h6>
            </div>
          </div>

          <div className="rating-wrapper">
            <h6 className="h6">Overall rating</h6>
            <CustomRatingGold
              initialValue={ratingValue}
              onClick={value => {
                setRatingValue(value);
              }}
            />
          </div>

          <div className="textarea-wrapper mt-5">
            <h6 className="h6">Your review</h6>
            <TextareaWithoutBorder
              placeHolder="Leave a comment"
              value={reviewText}
              onChange={event => setReviewText(event.target.value)}
            />
          </div>


          <Button
            className="p-3 w-full _fw button_secondary button_m double-secondary"
            onClick={() => {
              if (ratingValue == 0) {
                ShowError('Please enter rating', toast)
              } else if (reviewText == '') {
                ShowError('Please enter your review', toast)
              } else {
                postRattingsOnUser(ratingValue, reviewText, profileInfo?.id, userData?.token)
              }
            }}>
            Send
        </Button>
        </div>
      </div>
    </>
  );
};


export default ProfileRattings;
