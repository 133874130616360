import React, {useEffect, useState} from 'react';
import BackBtnWithTitlePage from '../../../../components/reusable/btns/backBtn/BackBtnWithTitlePage.jsx';
import InfoCard from '../../../../components/reusable/cards/infoCard/InfoCard.jsx';
import Calendar from '../../../../components/reusable/calendar/Calendar.jsx';
import GoalList from '../../../../components/reusable/goalList/GoalList.jsx';
import PageNavigation from '../../../../components/reusable/pageNavigation/PageNavigation.jsx';
import ProgressHeader from './header/ProgressHeader.jsx';
import {
  dayList,
  goalList,
  infoCardData,
  pageNav,
  viewTypeList,
} from '../../../../mock-data/hub/hub.js';
import MainLayout from '../../../../components/layouts/mainLayout/MainLayout.jsx';
import moment from 'moment/moment';
import {useStatTaskById} from '../../../../appState/hooks/useStatTaskById/index.js';
import {useBuzzmiAuth} from '../../../../appState/hooks/useBuzzmiAuth/index.web.js';
import {usePlannedActivityStat} from '../../../../appState/hooks/usePlannedActivityStat/index.js';
import {
  getCurrentMonthDates,
  getLastMonthDates,
} from '../../../../utils/utilitiesFunctions.js';

const getCurrentType = (list, id) => list.filter(el => el.id === id)[0]?.text;

const HubTread = props => {
  const {item} = props;
  const {
    getOnePlannedActivityStatsApi,
    setPlannedActivityStats,
    plannedActivityStats,
  } = usePlannedActivityStat();
  // const [calendarValue, setCalendarValue] = useState(dayList[0].date);
  const [calendarValue, setCalendarValue] = useState(
    moment(item?.date_created).format('YYYY-MM-DD'),
  );
  const [viewTypeId, setViewTypeId] = useState(0);
  const [lodaing, setLoading] = useState(false);
  const [currentType, setCurrentType] = useState(getCurrentType(pageNav, 0));

  const onPageNavChange = id => {
    setViewTypeId(id);
    if (getCurrentType) {
      setCurrentType(getCurrentType(pageNav, id));
    }
  };
  const {getMyTaskStat, taskStat} = useStatTaskById();
  const {userData} = useBuzzmiAuth();
  useEffect(() => {
    getMyTaskStat(userData.token, setLoading, {
      id: item?.id,
      time: pageNav[viewTypeId].uid,
    });
  }, [viewTypeId]);

  useEffect(() => {
    console.log('called here', calendarValue);
    getOnePlannedActivityStatsApi(
      {
        id: item?.mainTaskId,
        startDate: getLastMonthDates().startDate,
        endDate: getCurrentMonthDates().endDate,
      },
      userData.token,
    );
    return () => {
      setPlannedActivityStats([]);
    };
  }, []);

  return (
    // <MainLayout>
    <div className="">
      {/* <div className="hub__content"> */}
      {/* <BackBtnWithTitlePage
          title={timing?.charAt(0)?.toUpperCase() + timing?.slice(1)}
          btnText="Back to stats"
        /> */}
      <div className="mb-4">
        <InfoCard item={props?.item || infoCardData} noMenu noNav />
      </div>
      <ProgressHeader text="2 completed" />
      <div className="mb-4">
        <PageNavigation
          list={pageNav}
          value={viewTypeId}
          onChange={onPageNavChange}
        />
      </div>
      <div className="box box_big mb-4">
        {/* <div className="goal-list__row-name text-sm">Goal:</div>
        <div className="">Test</div>
        <div className="goal-list__row-name text-sm mt-2">Repeat:</div>
        <div className="">Test</div> */}
        <div className="goal-list__row-name text-sm flex w-max">
          Goal: <div className="ml-2 fw-normal">{item?.goal}</div>
        </div>
        <div className="goal-list__row-name text-sm flex w-max">
          Repeat:{' '}
          <div className="ml-2 fw-normal">
            Every {item?.repeatInterval} {item?.repeatIntervalCategory}
          </div>
        </div>
        <Calendar
          value={calendarValue}
          onChange={setCalendarValue}
          dayList={plannedActivityStats?.dayList}
          hasFooter
          period={
            currentType === viewTypeList.doneLastWeek ||
            currentType === viewTypeList.doneThisWeek
              ? 'week'
              : 'month'
          }
        />
        {/* <GoalList list={goalList} /> */}
        {taskStat?.data?.length && <GoalList list={taskStat.data} />}
      </div>
    </div>
    // </div>
    // </MainLayout>
  );
};

export default HubTread;
