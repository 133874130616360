import React from 'react';
import Info from '../../assets/info/Info.jsx';
import Box from '../box/Box.jsx';

import cn from 'classnames';

const MultiBox = ({
  icon,
  info,
  children,
  title,
  rightSide,
  isHideMode,
  text,
  miniMode,
  spaceMode,
  className,
  noTitle = false,
  isLeft = false
}) => {
  return (
    <Box className={`multi-box hub-form-box ${className}`}>
      {!noTitle && (
        <div
          //   className={
          //     (title === 'Note' ||
          //       title === 'Tags' ||
          //       title === 'Checklist' ||
          //       title === 'Add photos of your progress' ||
          //       title === 'When?' ||
          //       title === 'Add 4 description images' ||
          //       title === 'Add description video' ||
          //       title === 'Add description audio') &&
          //     cn('multi-box__wrapper', {
          //       'multi-box__wrapper_hide-mode': isHideMode,
          //       'multi-box__wrapper_mini-mode': miniMode,
          //       'multi-box__wrapper_space-mode': spaceMode,
          //     })
          // }
          className={cn('multi-box__wrapper', {
            'multi-box__wrapper_hide-mode': isHideMode,
            'multi-box__wrapper_mini-mode': miniMode,
            'multi-box__wrapper_space-mode': spaceMode,
          })}>
          <div className="multi-box__title">
            {icon && <img src={icon} alt="icon" className="multi-box__icon" />}
            <div className="me-21 me-lg-3">
              <div className="h4 mb-1 text-sm">{title}</div>
              <div className="text-r">{text}</div>
            </div>

            {info && <Info text={info} isLeft={isLeft}/>}
          </div>
          {rightSide}
        </div>
      )}

      {children}
    </Box>
  );
};

export default MultiBox;
