import React, { useEffect, useRef, useState } from 'react';
import Form from "../../../../components/reusable/form/Form.jsx";
import { useFormValidator } from '../../../../form-validator/hooks/index.js';
import ValidationSchema from '../../../../form-validator/ValidationSchema.js';
import Input from '../../../../components/reusable/form/items/inputs/Input.jsx';
import Strings from '../../../../string_key/Strings.web.js';

const validationSchema = new ValidationSchema(
  {
    password: [
      { rule: 'required' },
      { rule: 'password', value: 3 },
      { rule: 'leastOneUpperCase' },
      { rule: 'leastOneSpecialCharacter', value: 3 },
    ],
    confirmPassword: [
      { rule: 'passwordConfirmation', value: 'password' },
    ],
  }
);

const FormResetPassword = ({ passwordLabel = 'Create your Password', submitHandler, setValid }) => {
  const { register, handleSubmit, getFieldMessages, isValid, values } = useFormValidator(validationSchema, submitHandler, {
    showErrorsOnSubmit: false
  });
  const [showPass, setShowPass] = useState(false)
  const [confirmshowPass, setConfirmShowPass] = useState(false)
  const inputPassRef = useRef(null);
  const inputConfirmPassRef = useRef(null);

  useEffect(() => setValid && setValid(isValid), [isValid])

  return (
    <Form onSubmit={handleSubmit} formClassName={'w-100'} id='create-password'>
      <div class="pass_input">
        <Input
          label={passwordLabel}
          multiValidation={getFieldMessages('password')}
          name='password'
          register={register}
          createPasswordForm={true}
        />

      </div>
      <div class="pass_input">
        <Input
          showError={Object.keys(values).includes('password')}
          label={Strings.confirm_password}
          multiValidation={getFieldMessages('confirmPassword')}
          name='confirmPassword'
          register={register}
          isValid={isValid}
          createPasswordForm={true}
        />
      </div>
    </Form>
  );
};

export default FormResetPassword;
