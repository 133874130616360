import React, {useEffect, useState} from 'react';
import {Platform, View} from 'react-native';

import {createNativeStackNavigator} from '@react-navigation/native-stack';

import ChoseRole from '../../container/Auth/ChoseRole/index';
import AddCreators from '../../container/Auth/AddCreators/index';
import ChooseInterest from '../../container/Auth/ChooseInterest/index';

import AddPeople from '../../container/Auth/AddPeople/index.web';
import AddContacts from '../../container/Auth/AddContacts/index.web';
import AddFriends from '../../container/Auth/AddFriends/index.web';

import {useBuzzmiAuth} from '../../appState/hooks';
import {getData} from '../../res/asyncStorageHelper.web';

const Stack = createNativeStackNavigator();

export const options = {
  headerShown: false,
  gestureEnabled: Platform.OS !== 'android',
};

const ChooseInfoNavigator = () => {
  const [initialPageName, setInitialPageName] = useState('LoginV2');
  const {updateLanguage} = useBuzzmiAuth();

  useEffect(() => {
    getData('useLanguage', useLanguage => {
      updateLanguage(useLanguage ? useLanguage : 'en');
    });
  }, []);

  return initialPageName === '' ? null : (
    <Stack.Navigator initialRouteName={'ChoseRole'}>
      <Stack.Screen
        component={ChoseRole}
        name={'ChoseRole'}
        options={options}
      />
      <Stack.Screen
        component={ChooseInterest}
        name={'ChooseInterest'}
        options={options}
      />
      <Stack.Screen
        component={AddCreators}
        name={'AddCreators'}
        options={options}
      />
      {/* <Stack.Screen
        component={AddPeople}
        name={'AddPeople'}
        options={options}
      />
      <Stack.Screen
        component={AddContacts}
        name={'AddContacts'}
        options={options}
      />
      <Stack.Screen
        component={AddFriends}
        name={'AddFriends'}
        options={options}
      /> */}
    </Stack.Navigator>
  );
};

export default ChooseInfoNavigator;
