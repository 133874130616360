import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useLinkTo } from '@react-navigation/native';
import { Link } from '@react-navigation/native';
import { useLocation } from 'react-router-dom';

const MainLayoutMenuItemProfile = ({ src, navigation, selectedMenu, onHandelClick }) => {
  const linkTo = useLinkTo();


  return (
    <li className="main-layout__menu-item menu-item menu-item_mobile-hide">
      <a style={{ cursor: "pointer" }} onClick={() => { onHandelClick('/profile'), linkTo('/profile') }} className={global.activeMenu == "/profile" ? "menu-item__link active" : "menu-item__link"}>
          <img className="menu-item__avatar" src={src} alt={'menu-icon'} />
          <span className="menu-item__name text-s">Profile</span>
      </a>
    </li>
  );
};

export default MainLayoutMenuItemProfile;
