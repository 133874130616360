import React, {useEffect, useState} from 'react';
import MainLayoutContent from '../../../../../components/layouts/mainLayout/mainLayoutContent/MainLayoutContent';
import BackBtnWithTitlePage from '../../../../../components/reusable/btns/backBtn/BackBtnWithTitlePage';
import MainLayout from '../../../../../components/layouts/mainLayout/MainLayout';
import PostAuthorInfo from '../profilePost/items/postHeader/PostAuthorInfo';
import CommentsListV2 from './CommentsListV2';
import AddCommentV2 from './AddCommentV2';
// import { useRoute } from '@react-navigation/native';
// import { useNavigate, useParams } from 'react-router-dom';
import {useProfileApp} from '../../../../../appState/hooks';
import {EventRegister} from 'react-native-event-listeners';
import TagLink from '../profilePost/items/postContent/TagLink';
import Box from '../../../../../components/reusable/cards/box/Box';
import {ChatBubbleOvalLeftIcon} from '@heroicons/react/24/outline';
import {extractFieldValues} from '../profilePost/ProfilePostV2';
import {useBuzzmiAuth} from '../../../../../appState/hooks/useBuzzmiAuth/index.web';

const CommentsScreenV2 = ({entity, id}) => {
  const [item, setItem] = useState(null);
  const [commentData, setCommentData] = useState([]);
  const {userData} = useBuzzmiAuth();
  const {userReels, userPosts, buzzmiuserPosts, followingUserPosts} =
    useProfileApp();

  useEffect(() => {
    let foundItem = null;
    switch (entity) {
      case 'post':
        foundItem =
          userPosts?.find(post => post.id === id) ||
          (buzzmiuserPosts?.length > 0
            ? buzzmiuserPosts?.find(post => post.id === id)
            : buzzmiuserPosts?.nodes?.find(post => post.id === id)) ||
          followingUserPosts?.find(post => post.id === id);
        break;
      case 'reel':
        foundItem = userReels?.find(reel => reel.id === id);
        break;
      default:
        break;
    }

    setItem(foundItem);
    setCommentData(foundItem?.replies?.nodes);
  }, [entity, id]);

  return (
    <>
      <Box paddingMode={'none'}>
        <div className="comments-post__wrapper_v2">
          <PostAuthorInfo
            userName={item?.owner?.member?.name}
            userNickname={item?.owner?.member?.username}
            userPosition={''}
            userAvatar={
              item?.owner?.member?.fields?.length > 0
                ? extractFieldValues(item?.owner?.member?.fields)?.avatar
                : ''
            }
            isVerified={item?.owner?.member?.status === 'VERIFIED'}
          />

          <div className="content-post__text-wrapper">
            <p className="content-post__text">
              {item?.content
                ?.replace(/<\/?[^>]+(>|$)/g, '')
                ?.replace(/&nbsp;/g, ' ')}
            </p>

            {/* <ul className="content-post__tags-list">
              {item?.tags?.map(tag => (
                <TagLink text={tag} link={`/search-tags/${tag}`} key={tag} />
              ))}
            </ul> */}
          </div>
        </div>
      </Box>

      {commentData?.length > 0 ? (
        <CommentsListV2 comments={commentData} />
      ) : (
        <div className={'text-center'} style={{color: '#f867bd', padding: 16}}>
          <ChatBubbleOvalLeftIcon
            className="h-32 w-32 m-auto"
            aria-hidden="true"
          />
          <p>{'No comment found'}</p>
        </div>
      )}

      <div className="sm:h-20 h-32" />

      <Box
        paddingMode={'none'}
        className="position-fixed bottom-0 left-0 right-0 shadow-2xl shadow-black">
        <AddCommentV2
          callback={(data, item) => {
            console.log('data of my comment', data);
            setCommentData([...commentData, data]);
            setTimeout(() => {
              EventRegister.emit('post_comments_length', {
                data: [...commentData, data],
                post_id: item?.id,
              });
            }, 200);
          }}
          item={item}
          userAvatar={userData?.avatar}
        />
      </Box>
    </>
  );
};

export default CommentsScreenV2;
