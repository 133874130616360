import React, {useEffect, useState, useRef, Fragment} from 'react';

import {Dialog, Transition} from '@headlessui/react';
import {XMarkIcon} from '@heroicons/react/24/outline';
import ModalBackdrop from '../../../../components/layouts/mainLayout/componentsV2/ModalBackDrop';
import {useFormValidator} from '../../../../form-validator/hooks';
import Box from '../../../../components/reusable/cards/box/Box';
import ValidationSchema from '../../../../form-validator/ValidationSchema';
import Form from '../../../../components/reusable/form/Form';
import {mocData} from '../HubTaskDetailsv2/mocData.js';
import SubmitButton from '../../../../components/reusable/btns/buttons/submitButton/SubmitButton';
import {useCompletePlannedActivity} from '../../../../appState/hooks/useCompletePlannedActivity';
import MultiBox from '../../../../components/reusable/cards/multiBox/MultiBox';
import PictureUploaderList from '../../../../components/reusable/uploader/pictureUploader/PictureUploaderList';
import addImg from '../../../../assets/add_icon.png';
import {useBuzzmiAuth} from '../../../../appState/hooks/useBuzzmiAuth/index.web';
import {useHubApp} from '../../../../appState/hooks/useHubApp/index.web';

const UpdateProgressModal = ({activityModal, showActivityModal, item}) => {
  const {userData} = useBuzzmiAuth();
  const validationSchema = new ValidationSchema({});
  const refAddMedia = useRef(null);
  const [btnValid, setBtnValid] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  // const { getTodayTasks, getPlannedActivities } = useHubApp()
  const [error, setError] = useState(null);
  const {
    patchCompletePlannedActivity,
    isCompletePlannedActivityError,
    isCompletePlannedActivityLoading,
    CompletePlannedActivityResponse,
  } = useCompletePlannedActivity();

  // console.log('item in proh', item);
  const sendRequest = async formData => {
    try {
      let param_data = {};
      formData?.images?.length > 0 &&
        Object.assign(param_data, {
          isProgressImageEnable: true,
          progressMedia: Object.values(formData?.images).map((it, ind) => {
            return {
              title: formData?.progimgTitles?.[ind] || '',
              description: formData?.progimgDes?.[ind] || '',
              src: it || '',
            };
          }),
        });
      patchCompletePlannedActivity(item?.id, param_data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (CompletePlannedActivityResponse) {
      showActivityModal(() => false);
    }
  }, [CompletePlannedActivityResponse]);

  let limages =
    item?.progressMedia?.media.length > 0 &&
    item?.progressMedia?.media.map(value => value.src);
  const {handleSubmit, setValue, values, isValid} = useFormValidator(
    validationSchema,
    sendRequest,
    {
      defaultValues: {
        image: item?.image,
        title: item?.title,
        description: item?.description,
        images: limages || [],
        progimgDes:
          (item?.progressMedia?.media.length > 0 &&
            item?.progressMedia?.media.map(value => value.description)) ||
          [],
        progimgTitles:
          (item?.progressMedia?.media.length > 0 &&
            item?.progressMedia?.media.map(value => value.title)) ||
          [],
      },
    },
  );

  useEffect(() => {
    if (values?.images?.length > 0) {
      if (
        values?.images?.length !== values?.progimgDes?.length ||
        values?.images?.length !== values?.progimgTitles?.length
      ) {
        setBtnValid(false);
      } else {
        setBtnValid(true);
      }
    }
  }, [
    values?.images?.length,
    values?.progimgDes?.length,
    values?.progimgTitles?.length,
  ]);

  return (
    <Transition.Root show={activityModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={showActivityModal}>
        <ModalBackdrop />
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full">
                <Dialog.Panel
                  className="pointer-events-auto w-screen max-w-md"
                  style={{maxWidth: 640}}>
                  <div className="flex h-full flex-col overflow-y-scroll bg-[#FBF8FF] shadow-xl">
                    <div className="p-4">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900 text-center w-100">
                          {item?.title + ' Update Progress'}
                        </Dialog.Title>

                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-pink-500"
                            onClick={() => showActivityModal(() => false)}>
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className=" px-3 ">
                      <>
                        <Form error={error} onSubmit={handleSubmit}>
                          <div className="mb-4">
                            <MultiBox
                              icon="/images/hub/form/photo.png"
                              title="Add photos of your progress"
                              miniMode
                              rightSide={
                                !!setValue && (
                                  <button
                                    onClick={e => {
                                      e.preventDefault();
                                      refAddMedia?.current?.handleAdd();
                                    }}
                                    type="button ">
                                    <img
                                      src={addImg}
                                      className="h-8 w-8 sm:w-10 sm:h-10 md:w-10 md:h-10 lg:w-12 lg:h-12 xl:w-12 xl:h-12 mr-2"
                                    />
                                  </button>
                                )
                              }>
                              <PictureUploaderList
                                classNames="picture-uploader__wrapper_mod"
                                setValue={setValue}
                                defaultValues={
                                  item?.progressMedia?.media.length > 0 &&
                                  item?.progressMedia?.media.map(
                                    value => value.src,
                                  )
                                }
                                defaultValuesTitles={
                                  values?.progimgTitles ||
                                  (item?.progressMedia?.media.length > 0 &&
                                    item?.progressMedia?.media.map(
                                      value => value.title,
                                    ))
                                }
                                defaultValuesDes={
                                  values?.progimgDes ||
                                  (item?.progressMedia?.media.length > 0 &&
                                    item?.progressMedia?.media.map(
                                      value => value.description,
                                    ))
                                }
                                picture_type={'progress_photos'}
                                isBig
                                isProgress={true}
                                ref={refAddMedia}
                              />
                            </MultiBox>
                          </div>

                          <div className="d-flex justify-content-center my-3 my-md-3 rounded-full overflow-hidden shadow-lg">
                            <SubmitButton
                              form="task-form"
                              isValid={btnValid}
                              title={'UPDATE'}
                            />
                          </div>
                        </Form>
                      </>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default UpdateProgressModal;
