import {useEffect, useState, useRef} from 'react';

import TickImage from '../../assets/ic_tick_steps.png';

const GradCircle = ({label, isEnabled, showTick}) => {
  return (
    <div class="w-8 h-8 rounded-full bg-gradient-to-br from-[#f53cb7] to-[#b51ccd] flex items-center justify-center">
      <div
        class={`w-7 h-7 rounded-full flex items-center justify-center font-bold ${
          isEnabled
            ? 'bg-gradient-to-br from-[#f53cb7] to-[#b51ccd]  text-white'
            : ' bg-white text-[#b51ccd]'
        }`}>
        {showTick ? <img src={TickImage} className="h-4 w-4" /> : label}
      </div>
    </div>
  );
};

const ProgessSteps = ({DoneFirst}) => {
  return (
    <div className="flex justify-between flex-row mb-2">
      <GradCircle label={'1'} isEnabled showTick={DoneFirst} />
      <div class="flex flex-1 items-center">
        <div class="w-full h-[1px] bg-gradient-to-br from-[#f53cb7] to-[#b51ccd]"></div>
      </div>
      <GradCircle label={'2'} isEnabled={DoneFirst} />
    </div>
  );
};
export default ProgessSteps;
