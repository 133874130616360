import React, {useState} from 'react';
import Strings from '../../../../../../../string_key/Strings.web';

function HubTaskInput({addTask}) {
  const [inputValue, setInputValue] = useState('');

  const handleChange = event => {
    setInputValue(event.target.value);
  };

  const handleClick = event => {
    event.preventDefault();
    if (inputValue.length > 0) {
      addTask(inputValue);
      setInputValue('');
    }
  };

  return (
    <div className="checklist__task-input">
      <div className="d-flex justify-content-between align-items-center">
        <input
          type="text"
          value={inputValue}
          onChange={handleChange}
          placeholder="Add your item"
          className="input input_simple-text rounded-full"
        />
        <button
          onClick={handleClick}
          className="text-alt-primary text-r no-wrap">
          {Strings.add}
        </button>
      </div>
    </div>
  );
}

export default HubTaskInput;
