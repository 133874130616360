import React from 'react';
// import InfoCardList from '../../../../../../../components/reusable/cards/infoCard/InfoCardList.jsx';
import InfoCardList from '../../../../../../../components/reusable/cards/infoCardv2/InfoCardList.jsx';
import SecondaryButton from '../../../../../../../components/reusable/btns/links/SecondaryLink.jsx';
import MobileAddActivity from '../mobileAddActivity/MobileAddActivity.jsx';
import Strings from '../../../../../../../string_key/Strings.web.js';
import { useLinkTo } from '@react-navigation/native';
import { EventRegister } from 'react-native-event-listeners';

const FilterItems = ({ isStat = false, item, isFirst }) => {
  const linkTo = useLinkTo();

  return (
    <div className="filter-tags__items">
      {/* <MobileAddActivity /> */}
      <div className="d-flex justify-content-between align-items-center mb-2 mb-md-3">
        {/* {console.log('item?.title', item?.title)} */}
        {item?.title !== 'Undefined' && <h4 className="h4">{item?.title}</h4>}
        {/* {console.log('item?.title', item?.title)} */}
        {/* {isFirst && (
          <SecondaryButton
            to={{screen: 'AddActivitiesv2'}}
            className="py-21 px-15 d-none d-lg-inline-flex">
            {Strings.add_activity}
          </SecondaryButton>
        )} */}
        {isFirst && (
          <div className="flex-shrink-0 custom-brn-v2">
            <button
              type="button"
              className="custom-btn relative inline-flex items-center rounded-md bg-rose-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pink-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600"
              style={{ backgroundColor: '#eb4dac' }}
              onClick={() => {
                linkTo('/hub/v2/add-activities');
              }}>
              {Strings.add_activity}
            </button>
          </div>
        )}
      </div>
      <InfoCardList
        isStat={isStat}
        infoList={item?.list}
        taskType={'plan'}
        onItemClick={e =>
          isStat
            ? EventRegister.emit('openPlanActStatModal', e)
            : EventRegister.emit('openPlanActivityModal', e)
        }
        isMainActivity={true}
      />
    </div>
  );
};

export default FilterItems;
