import React from 'react';
import {useNavigate} from 'react-router-dom';
import Strings from '../../../../string_key/Strings.web';
const BackBtn = ({hideArrow = false, title = Strings.to_back}) => {
  const navigate = useNavigate();

  return (
    <div className="back-btn back-btn_simple back-btn_d">
      <button className="back-btn__content" onClick={() => navigate(-1)}>
        {!hideArrow && (
          <i className="back-btn__icon fa-solid fa-arrow-left sm:hidden"></i>
        )}
        <span className="text-primary" style={{backgroundColor: '#d12fac'}}>
          {title}
        </span>
      </button>
    </div>
  );
};

export default BackBtn;
