import React from "react"
import PropTypes from "prop-types"

const GradientBorder = ({ children, className = "" }) => {
  return (
    <div className={"gradient-border " + className}>
      <div className="gradient-border__wrapper">
        {children}
      </div>
    </div>
  )
}

GradientBorder.propTypes = {
  children: PropTypes.node.isRequired,
  padding: PropTypes.string,
  className: PropTypes.string,
}

export default GradientBorder
