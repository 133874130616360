import { deleteBaseActivity } from '../../../network/api/app';

export const useDeleteBaseActivity = () => {
  const deleteBase = async (id, query = null, token, callback) => {
    try {
      console.log(query)
      console.log(token)

      const response = await deleteBaseActivity(id, query)({ token });
      callback(response);
    } catch (e) {
      console.log('error => ', e.message);
    }
  };

  return { deleteBase };
};
