import React from "react"
import PropTypes from "prop-types"

const RadioBtn = ({ name, value, checked, labelText, className = "" }) => {
  return (
    <label className={"radio " + className}>
      <input type="radio" name={name} value={value} checked={checked} />
      <div className="radio-block">
        <div></div>
      </div>
      <span className="radio__label text-r">{labelText}</span>
    </label>
  )
}

RadioBtn.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  checked: PropTypes.bool,
}

export default RadioBtn
