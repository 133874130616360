/* eslint-disable react-hooks/exhaustive-deps */
// noinspection ES6CheckImport

import React, {useState, useEffect} from 'react';
import {View, SafeAreaView} from 'react-native';
import {
  heightPercentageToDP as hp,
  widthPercentageToDP as wp,
} from 'react-native-responsive-screen';

import {BackHeader} from '../../../../components/BackHeader';
import {GlobalFlex} from '../../../../res/globalStyles';
import styles, {HeaderShadowLine, TitleText} from './style';
import {Spacer} from '../../../../res/spacer';

import {ScrollView} from 'react-native-gesture-handler';
import {MenuItem} from '../../../../components/MenuItem/MenuItem';
import Strings from "../../../../string_key/Strings.web";
// import IconAssets from '../../../../assets';

// const AccountSetup = [
//   {
//     label: 'Profile picture upload ',
//     image: IconAssets.FA_prof_pic,
//   },
//   {
//     label: 'Verify account email',
//     image: IconAssets.FA_ver_email,
//   },
//   {
//     label: 'Verify Phone number ',
//     image: IconAssets.FA_ver_phone,
//   },
//   {
//     label: 'Setup two factor authentication',
//     image: IconAssets.FA_two_factor,
//   },

//   {
//     label: 'Define Payout account/s',
//     image: IconAssets.FA_def_payout,
//   },
// ];

const AddTaskTwo = props => {
  // const { title } = props?.route?.params;

  return (
    <GlobalFlex>
      <SafeAreaView />
      <View>
        <BackHeader
          is_center_text
          title={Strings.finish_account_setup}
          onBackPress={() => props.navigation.goBack()}
        />
        <Spacer space={wp(1.5)} />
        {/* <HeaderShadowLine /> */}
      </View>
      <ScrollView style={{flex: 1}}>
        <Spacer space={hp(1)} />
        {/* {AccountSetup.map((item, index) => (
          <>
            <MenuItem
              key={index}
              icon={IconAssets.ic_right_arrow}
              iconStyle={{height: wp(6), width: wp(4)}}
              onPressIcon={() => {}}
              image={item.image}
              itemStyle={{borderRadius: 15, paddingVertical: wp(3)}}
              label={item.label}
              labelStyle={{fontSize: wp(3.4)}}
            />
            <Spacer space={wp(1.5)} />
          </>
        ))} */}
      </ScrollView>
    </GlobalFlex>
  );
};

export default AddTaskTwo;
