// noinspection JSUnresolvedReference
import {useEffect, useState} from 'react';

import {
  getFollowers,
  getFollowing,
  getMutualFollowing,
  postFollowUserApi,
  postUnfollowUserApi,
  postHidePost,
  postReviewRattings,
  postUnfollowFollwerUserApi,
  getFollowerSuggestions,
  getPostLists,
  getBuzzmiUserPostList,
  getFollowingPostList,
  getReelLists,
  postFilesBeforeUploades,
  postVideoFilesBeforeUploades,
  postLikePostApi,
  deleteDislikePostApi,
  getProfileShare,
  getProfileShareById,
  deleteProfileTag,
  deleteReelById,
  deletePostById,
  deleteBookMark,
  getReelPostLink,
  getUserInformationContacts,
  getCommunityActivity,
  postLikeCommentApi,
  deleteDislikeCommentApi,
} from '../../../network/api/app';
import {useAtom} from 'jotai';
import {useMutation} from 'react-query';

import {
  suggestedUsersAtom,
  followerUsersAtom,
  followingUsersAtom,
  userPostsBuzzmiUsersAtom,
  userFollowingListAtom,
  userPostsUsersAtom,
  userReelsUsersAtom,
  profileShareAtom,
  contactInfoAtom,
  activityInfoAtom,
  isPostLoading,
  messageFollowAction,
  visitorPostsUsersAtom,
  visitorReelsUsersAtom,
  visitorprofileShareAtom,
  isSuggestedUserLoading,
} from '../ProfileState';
import {useBuzzmiAuth} from '../useBuzzmiAuth/index';
import {useCreatePost} from '../useCreatePost';
import {useNavigation} from '@react-navigation/core';
import {useEditProfile} from '../useEditProfile';
import {usePostComment} from '../usePostComment';
import {useCreateReel} from '../useCreateReel';
import {profileSideBarNavAtom} from '../../navState';
import Strings from '../../../string_key/Strings.web';
import {useEditProfileAvatar} from '../useEditProfileAvatar';
import {useHubApp} from '../useHubApp/index.web';
import {useToast} from 'react-native-toast-notifications';
import {ShowSuccess} from '../../../container/HOC/MessageWeb';

export const useProfileApp = () => {
  const [suggestedUsers, setSuggestedUsers] = useAtom(suggestedUsersAtom);
  const [profileShareData, setProfileShareData] = useAtom(profileShareAtom);
  const [visitorprofileShareData, setVisitorProfileShareData] = useAtom(
    visitorprofileShareAtom,
  );

  const toast = useToast();

  const [userContactInfo, setUserContactInfo] = useAtom(contactInfoAtom);
  const [userActivityInfo, setUserActivityInfo] = useAtom(activityInfoAtom);

  const [followerUsers, setFollowerUsers] = useState([]);

  const [followingUsers, setFollowingUsers] = useState([]);

  const [getDynamicLink, setDynamicLink] = useState(null);
  const [linkLoad, setLinkLoad] = useState(false);

  const [suggestedUserLoad, setSuggestedUserLoad] = useAtom(
    isSuggestedUserLoading,
  );

  const [mutualFollowingUsers, setMutualFollowingUsers] = useState([]);
  const [userPosts, setUserPosts] = useAtom(userPostsUsersAtom);
  const [userReels, setUserReels] = useAtom(userReelsUsersAtom);

  const [visitorPosts, setVisitorPosts] = useAtom(visitorPostsUsersAtom);
  const [visitorReels, setVisitorReels] = useAtom(visitorReelsUsersAtom);

  const [buzzmiuserPosts, setBuzzmiUserPosts] = useAtom(
    userPostsBuzzmiUsersAtom,
  );
  const [followingUserPosts, setFollowingUserPosts] = useAtom(
    userFollowingListAtom,
  );
  const [postLoading, setPostLoading] = useAtom(isPostLoading);

  const [messageDisplay, setMessageDisaply] = useAtom(messageFollowAction);

  const {userData} = useBuzzmiAuth();
  const [postUploading, setPostUploading] = useState(false);
  const [reelUploading, setReelUploading] = useState(false);
  const {goBack} = useNavigation();
  const [activeSideItem, setActiveSideItem] = useAtom(profileSideBarNavAtom);

  const {getNextRankInfoDetail, getWalletData} = useHubApp();

  const {
    postErrorResponse,
    isCreatePostError,
    isCreatePostLoading,
    postResponse,
    postCreatePost,
  } = useCreatePost();

  const {
    reelErrorResponse,
    isCreateReelError,
    isCreateReelLoading,
    reelResponse,
    postCreateReel,
  } = useCreateReel();

  const {
    isEditProfileLoading,
    EditProfileResponse,
    EditProfileErrorResponse,
    patchBaseEditProfile,
  } = useEditProfile();
  const {
    isEditProfileAvatarLoading,
    EditProfileAvatarResponse,
    EditProfileAvatarErrorResponse,
    patchBaseEditProfileAvatar,
  } = useEditProfileAvatar();

  const {
    isCommentPostLoading,
    isCommentPostError,
    commentResponse,
    commentErrorResponse,
    postCommentOnComments,
  } = usePostComment();

  useEffect(() => {
    if (postResponse) {
      console.log(postResponse);
      getNextRankInfoDetail(userData?.id, userData?.token);
      getWalletData(userData?.token);
      getUserPostLists(userData.token, userData?.id, {page: 1, limit: 20});
      setActiveSideItem(Strings.post);
      setTimeout(() => {
        setActiveSideItem(Strings.post + 's');
      }, 2000);
    }
  }, [postResponse, isCreatePostLoading, isCreatePostError]);

  useEffect(() => {
    if (reelResponse) {
      getNextRankInfoDetail(userData?.id, userData?.token);
      getWalletData(userData?.token);
      getUserReelLists(userData.token, userData?.id, {page: 1, limit: 20});
      setActiveSideItem(Strings.post);
      setTimeout(() => {
        setActiveSideItem(Strings.reel);
      }, 2000);
    }
  }, [reelResponse, isCreateReelLoading, isCreateReelError]);

  const {
    mutate: mutateFilesUpload,
    isLoading: isFileUploadLoading,
    isError: isFileUploadError,
    data: fileUploadResponse,
    error: fileUploadErrorResponse,
  } = useMutation(postFilesBeforeUploades);

  const {
    mutate: mutateVideoFilesUpload,
    isLoading: isVideoFileUploadLoading,
    isError: isVideoFileUploadError,
    data: videoFileUploadResponse,
    error: videoFileUploadErrorResponse,
  } = useMutation(postVideoFilesBeforeUploades);

  const getPostReelDynamicLink = async (type, id, token) => {
    setLinkLoad(true);
    try {
      const response = await getReelPostLink(type, id)({token});
      setDynamicLink(response);
      setLinkLoad(false);
    } catch (e) {
      setLinkLoad(false);
      console.log('error => ', e.message);
    }
  };

  const getSuggestedUsers = async (token, body) => {
    try {
      setSuggestedUserLoad(true);
      const response = await getFollowerSuggestions({token, body});
      setSuggestedUsers(response);
      if (response) {
        setSuggestedUserLoad(false);
      }
    } catch (e) {
      setSuggestedUserLoad(false);
      console.log('error => ', e.message);
    }
  };

  const getInformationContacts = async token => {
    try {
      const response = await getUserInformationContacts({token});
      setUserContactInfo(response);
    } catch (e) {
      console.log('error => ', e.message);
    }
  };

  const getUsersActivity = async token => {
    try {
      const response = await getCommunityActivity(userData.id)({token});

      setUserActivityInfo(response);
    } catch (e) {
      console.log('error => ', e.message);
    }
  };

  const getProfileShareDetail = async (token, body) => {
    try {
      const response = await getProfileShare({token, body});
      setProfileShareData(response);
    } catch (e) {
      console.log('error => ', e.message);
    }
  };

  const getProfileShareDetailByID = async (id, token, body) => {
    try {
      const response = await getProfileShareById(id)({token, body});
      setVisitorProfileShareData(response);
    } catch (e) {
      console.log('error => ', e.message);
    }
  };

  const getFollowerUsers = async (
    token,
    id,
    query,
    pageination,
    setLoading,
  ) => {
    setLoading ? setLoading(true) : null;
    try {
      const response = await getFollowers(id)({
        token,
        body: {id, query, ...pageination},
      });
      setFollowerUsers(response?.followers);
      setLoading ? setLoading(false) : null;
    } catch (e) {
      setLoading ? setLoading(false) : null;
    }
  };

  const getFollowingUsers = async (
    token,
    id,
    query,
    pageination,
    setLoading,
  ) => {
    setLoading ? setLoading(true) : null;
    try {
      const response = await getFollowing(id)({
        token,
        body: {id, query, ...pageination},
      });
      setFollowingUsers(response?.following);
      setLoading ? setLoading(false) : null;
    } catch (e) {
      setLoading ? setLoading(false) : null;
      console.log('error => ', e.message);
    }
  };

  const getBuzzmiUserPostLists = async (token, body) => {
    setPostLoading(true);
    try {
      const response = await getBuzzmiUserPostList({token, body});
      setPostLoading(false);
      setBuzzmiUserPosts(response);
    } catch (e) {
      setPostLoading(false);
      console.log('error => ', e.message);
    }
  };

  const getFollowingPostLists = async (token, body) => {
    setPostLoading(true);
    try {
      const response = await getFollowingPostList({token, body});
      setFollowingUserPosts(response);
      setPostLoading(false);
    } catch (e) {
      setPostLoading(false);
      console.log('error => ', e.message);
    }
  };

  const getVisitorPostLists = async (token, id, body) => {
    setPostUploading(true);
    try {
      const response = await getPostLists(id)({token, body});

      setPostUploading(false);
      setVisitorPosts(response);
    } catch (e) {
      setPostUploading(false);
      console.log('error => ', e.message);
    }
  };

  const getVisitorReelLists = async (token, id, body) => {
    setReelUploading(true);
    try {
      const response = await getReelLists(id)({token, body});
      setReelUploading(false);
      setVisitorReels(response);
    } catch (e) {
      setReelUploading(false);
      console.log('error => ', e.message);
    }
  };

  const getUserPostLists = async (token, id, body) => {
    setPostUploading(true);
    try {
      const response = await getPostLists(id)({token, body});

      setPostUploading(false);
      setUserPosts(response);
    } catch (e) {
      setPostUploading(false);
      console.log('error => ', e.message);
    }
  };

  const getUserReelLists = async (token, id, body) => {
    setReelUploading(true);
    try {
      const response = await getReelLists(id)({token, body});

      setReelUploading(false);

      setUserReels(response);
    } catch (e) {
      setReelUploading(false);
      console.log('error => ', e.message);
    }
  };

  const getMutualFollowingUsers = async (token, id, query) => {
    try {
      const response = await getMutualFollowing(id)({token, body: {query}});
      setMutualFollowingUsers(response);
    } catch (e) {
      console.log('error => ', e.message);
    }
  };

  const {
    mutate: mutateFollowUser,
    isLoading: isFollowUserLoading,
    data: FollowUserResponse,
    error: FollowUserErrorResponse,
  } = useMutation({
    mutationFn: param => {
      return postFollowUserApi(param.id)({
        params: {isAuth: true, token: param.token},
      });
    },
    onSuccess: data => {
      ShowSuccess('Follow successfully', toast);
      getInformationContacts(userData?.token);
      getFollowingUsers(userData?.token, userData?.id, '', {page: 1, limit: 5});
      getFollowerUsers(userData?.token, userData?.id, '', {page: 1, limit: 5});
      getMutualFollowingUsers(userData?.token, userData?.id, '');
      getSuggestedUsers(userData.token, {page: 1, limit: 10});
      getBuzzmiUserPostLists(userData.token, {page: 1, limit: 10});
      getFollowingPostLists(userData.token, {page: 1, limit: 10});
    },
  });
  const postFollowUser = async (id, token) => mutateFollowUser({id, token});

  const {
    mutate: mutateUnfollowUser,
    isLoading: isUnfollowUserLoading,
    data: UnfollowUserResponse,
    error: UnfollowUserErrorResponse,
  } = useMutation({
    mutationFn: param => {
      console.log('param inside unfollow', param);
      return postUnfollowUserApi(param.id)({
        params: {isAuth: true, token: param.token},
      });
    },
    onSuccess: data => {
      // setMessageDisaply("Unfollow successfully")
      ShowSuccess('Unfollow successfully', toast);
      getInformationContacts(userData?.token);
      getFollowingUsers(userData?.token, userData?.id, '', {page: 1, limit: 5});
      getFollowerUsers(userData?.token, userData?.id, '', {page: 1, limit: 5});
      getMutualFollowingUsers(userData?.token, userData?.id, '');
      getSuggestedUsers(userData.token, {page: 1, limit: 10});
    },
  });

  const postUnfollowUser = async (id, token) => mutateUnfollowUser({id, token});

  const {
    mutate: mutateHidePost,
    isLoading: isHidePostLoading,
    data: HidePostResponse,
    error: HidePostErrorResponse,
  } = useMutation({
    mutationFn: param => {
      return postHidePost({
        params: {isAuth: true, token: param.token},
        postId: param.id,
        type: param.type,
      });
    },
    onSuccess: data => {
      ShowSuccess(data?.message, toast);
      getBuzzmiUserPostLists(userData.token, {page: 1, limit: 10});
      getFollowingPostLists(userData.token, {page: 1, limit: 10});
    },
  });

  const postHideSpecificPost = async (type, id, token) =>
    mutateHidePost({type, id, token});

  const {
    mutate: mutatePostRating,
    isLoading: isPostRatingLoading,
    data: PostRatingResponse,
    error: PostRatingErrorResponse,
  } = useMutation({
    mutationFn: param => {
      return postReviewRattings(
        Object.assign(
          {
            userId: param.userId,
            ratting: param.ratting,
            review: param.review,
          },
          {params: {isAuth: true, token: param.token}},
        ),
      );
    },
    onSuccess: data => {
      ShowSuccess(data?.message, toast);
    },
  });

  const postRattingsOnUser = async (ratting, review, userId, token) =>
    mutatePostRating({ratting, review, userId, token});

  const {
    mutate: mutateUnfollowerUser,
    isLoading: isUnfollowerUserLoading,
    data: UnfollowerUserResponse,
    error: UnfollowerUserErrorResponse,
  } = useMutation({
    mutationFn: param => {
      console.log('param inside unfollow', param);
      return postUnfollowFollwerUserApi(param.id)({
        params: {isAuth: true, token: param.token},
      });
    },
    onSuccess: data => {
      // setMessageDisaply("Unfollow successfully")
      ShowSuccess('Unfollow successfully', toast);
      getInformationContacts(userData?.token);
      getFollowingUsers(userData?.token, userData?.id, '', {page: 1, limit: 5});
      getFollowerUsers(userData?.token, userData?.id, '', {page: 1, limit: 5});
      getMutualFollowingUsers(userData?.token, userData?.id, '');
      getSuggestedUsers(userData.token, {page: 1, limit: 10});
    },
  });

  const postUnfollowerUser = async (id, token) =>
    mutateUnfollowerUser({id, token});

  const {
    mutate: mutatePostLike,
    isLoading: isPostLikeLoading,
    data: PostLikeResponse,
    error: PostLikeErrorResponse,
  } = useMutation({
    mutationFn: param => {
      return postLikePostApi(param.id)({
        params: {isAuth: true, token: param.token},
      });
    },
    onSuccess: data => {
      console.log('liked post', data);
    },
  });
  const postLikePost = async (id, token) => mutatePostLike({id, token});

  const {
    mutate: mutatePostDislike,
    isLoading: isPostDislikeLoading,
    data: PostDislikeResponse,
    error: PostDislikeErrorResponse,
  } = useMutation({
    mutationFn: param => {
      return deleteDislikePostApi(param.id)({isAuth: true, token: param.token});
    },
    onSuccess: data => {
      console.log('Disliked post', data);
    },
  });

  const postDislikePost = async (id, token) => mutatePostDislike({id, token});

  const {
    mutate: mutateCommentLike,
    isLoading: isCommentLikeLoading,
    data: CommentLikeResponse,
    error: CommentLikeErrorResponse,
  } = useMutation({
    mutationFn: param => {
      return postLikeCommentApi(param.id)({
        params: {isAuth: true, token: param.token},
      });
    },
    onSuccess: data => {
      console.log('liked post', data);
    },
  });
  const postLikeComment = async (id, token) => mutateCommentLike({id, token});

  const {
    mutate: mutateCommentDislike,
    isLoading: isCommentDislikeLoading,
    data: CommentDislikeResponse,
    error: CommentDislikeErrorResponse,
  } = useMutation({
    mutationFn: param => {
      return deleteDislikeCommentApi(param.id)({
        params: {isAuth: true, token: param.token},
      });
    },
    onSuccess: data => {
      console.log('Disliked post', data);
    },
  });

  const postDislikeComment = async (id, token) =>
    mutateCommentDislike({id, token});

  const {
    mutate: mutateDeletePostTag,
    isLoading: isDeleteTagLoading,
    data: DeleteTagReasponse,
    error: DeleteTagErrorReasponse,
  } = useMutation({
    mutationFn: param => {
      console.log(param);
      return deleteProfileTag(param.id)({
        isAuth: true,
        token: param.token,
      });
    },
    onSuccess: data => {},
  });

  const deletePostTag = async (id, token) => mutateDeletePostTag({id, token});

  const {
    mutate: mutateDeleteBookMark,
    isLoading: isDeleteBookmarkLoading,
    data: DeleteBookmarkReasponse,
    error: DeleteBookmarkErrorReasponse,
  } = useMutation({
    mutationFn: param => {
      return deleteBookMark(param.id)({
        isAuth: true,
        token: param.token,
      });
    },
    onSuccess: data => {},
  });

  const deleteBookMarks = async (id, token) =>
    mutateDeleteBookMark({id, token});

  const {
    mutate: mutateDeleteReel,
    isLoading: isDeleteReelLoading,
    data: DeleteReelResponse,
    error: DeleteReelErrorReasponse,
  } = useMutation({
    mutationFn: param => {
      return deleteReelById(param.id)({isAuth: true, token: param.token});
    },
    onSuccess: data => {
      ShowSuccess('Reel Deleted successfully', toast);
      getUserReelLists(userData.token, userData?.id, {page: 1, limit: 20});
      getBuzzmiUserPostLists(userData.token, {
        page: 1,
        limit: 20,
      });
    },
  });

  const deleteReelFromList = async (id, token) => mutateDeleteReel({id, token});

  const {
    mutate: mutateDeletePost,
    isLoading: isDeletePostLoading,
    data: DeletePostResponse,
    error: DeletePostErrorReasponse,
  } = useMutation({
    mutationFn: param => {
      console.log('delete Post id ', param.id);
      console.log(param.id);
      return deletePostById(param.id)({isAuth: true, token: param.token});
    },
    onSuccess: data => {
      ShowSuccess('Post Deleted successfully', toast);
      getUserPostLists(userData.token, userData?.id, {page: 1, limit: 20});
      getBuzzmiUserPostLists(userData.token, {
        page: 1,
        limit: 20,
      });
    },
  });

  const deletePostFromList = async (id, token) => mutateDeletePost({id, token});

  useEffect(() => {}, [
    FollowUserResponse,
    FollowUserErrorResponse,
    isFollowUserLoading,
  ]);
  useEffect(() => {
    // console.log(
    //   'UNfollow user',
    //   UnfollowUserErrorResponse,
    //   UnfollowUserResponse,
    //   isUnfollowUserLoading,
    // );
  }, [UnfollowUserErrorResponse, UnfollowUserResponse, isUnfollowUserLoading]);

  return {
    getSuggestedUsers,
    getFollowers,
    getFollowing,
    suggestedUsers,
    postFollowUser,
    followerUsers,
    followingUsers,
    mutualFollowingUsers,
    getFollowerUsers,
    getFollowingUsers,
    getMutualFollowingUsers,
    FollowUserResponse,
    FollowUserErrorResponse,
    isFollowUserLoading,
    postUnfollowUser,
    userReels,
    getUserReelLists,
    reelUploading,
    userPosts,
    getUserPostLists,
    getBuzzmiUserPostLists,
    buzzmiuserPosts,
    postUploading,
    mutateFilesUpload,
    mutateVideoFilesUpload,
    isFileUploadLoading,
    isVideoFileUploadLoading,
    isFileUploadError,
    isVideoFileUploadError,
    fileUploadResponse,
    videoFileUploadResponse,
    fileUploadErrorResponse,
    videoFileUploadErrorResponse,
    postCreatePost,
    postLikePost,
    postDislikePost,
    isEditProfileLoading,
    EditProfileResponse,
    EditProfileErrorResponse,
    patchBaseEditProfile,
    isCommentPostLoading,
    isCommentPostError,
    commentResponse,
    commentErrorResponse,
    postCommentOnComments,
    getProfileShareDetail,
    getProfileShareDetailByID,
    visitorprofileShareData,
    profileShareData,
    deletePostTag,
    DeleteTagReasponse,
    getPostReelDynamicLink,
    getDynamicLink,
    linkLoad,
    getInformationContacts,
    userContactInfo,
    postErrorResponse,
    reelErrorResponse,
    isCreateReelError,
    isCreateReelLoading,
    reelResponse,
    postCreateReel,
    isDeleteReelLoading,
    DeleteReelResponse,
    DeleteReelErrorReasponse,
    deleteReelFromList,
    isDeletePostLoading,
    DeletePostResponse,
    DeletePostErrorReasponse,
    deletePostFromList,
    getUsersActivity,
    userActivityInfo,
    //////
    isEditProfileAvatarLoading,
    EditProfileAvatarResponse,
    EditProfileAvatarErrorResponse,
    patchBaseEditProfileAvatar,
    ///////
    getFollowingPostLists,
    followingUserPosts,
    postLoading,
    messageDisplay,
    getVisitorReelLists,
    getVisitorPostLists,
    visitorPosts,
    visitorReels,
    suggestedUserLoad,
    setSuggestedUserLoad,
    deleteBookMarks,
    isDeleteBookmarkLoading,
    DeleteBookmarkReasponse,
    DeleteBookmarkErrorReasponse,
    postUnfollowerUser,
    isUnfollowerUserLoading,
    UnfollowerUserResponse,
    UnfollowerUserErrorResponse,
    postHideSpecificPost,
    isHidePostLoading,
    HidePostResponse,
    HidePostErrorResponse,
    postRattingsOnUser,
    isPostRatingLoading,
    PostRatingResponse,
    PostRatingErrorResponse,

    setVisitorPosts,
    setVisitorReels,
    postLikeComment,
    postDislikeComment,
  };
};
