import React, { useEffect, useState } from "react"
import CodeInputsFour from "../../../../../reusable/form/items/inputs/codeInputs/CodeInputsFour"
import PrimaryButton from "../../../../../reusable/btns/buttons/PrimaryButton"
import { useBuzzmiAuth } from "../../../../../../appState/hooks/useBuzzmiAuth/index.web";

const VerifyPhoneAlertDesktop = ({ phone, propfocus }) => {
  const [verifyCode, setVerifyCode] = useState([])
  const {
    emailMobResponse,
    verifyCodeResponse,
    onValidateOTP,
    emailmobError,
    onSendMobileEmailOtp
  } = useBuzzmiAuth();

  useEffect(() => {
    onSendMobileEmailOtp(phone)
  }, [propfocus])

  useEffect(() => {
    if (verifyCodeResponse) {
      
    }
  }, [verifyCodeResponse, emailmobError])


  const onVerifyButton = () => {
    console.log(emailMobResponse)
    let code = verifyCode.join('')
    if (code.length == (phone.includes('@') ? 6 : 4)) {
      onValidateOTP(phone, code, emailMobResponse?.uniqueUUID);
    } else {
      alert("please enter otp")
    }
  }

  return (
    <div className="d-none d-md-block">
      <div className="phone-alert">
        <div className="phone-alert__title">OTP Verification</div>
        <div className=" mb-3">
          Enter the OTP sent to <span className="phone-alert__phone">{phone}</span>
        </div>
        <div>
          <CodeInputsFour
            codeLength={phone.includes('@') ? 6 : 4}
            getCode={(code) => { setVerifyCode(code) }}
            label={undefined}
            formClassName={undefined}
            identifier={verifyCode}
            hideMessage
            hideBtn
          />
        </div>
        <div className="mt-3 mt-md-4">
          <span>Don`t receive the OTP</span>
          <button type={"button"} onClick={() => { onSendMobileEmailOtp(phone) }} className="text-uppercase text-alt-primary phone-alert__resend ms-21">RESEND OTP</button>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-17">
        <PrimaryButton type={"button"} onClick={() => { onVerifyButton() }}>VERIFY & PROCEED</PrimaryButton>
      </div>

      {emailmobError && <button className="alert__bottom text-error h4">{emailmobError}</button>}
    </div>
  )
}

export default VerifyPhoneAlertDesktop
