import React, {useEffect, useState, Fragment} from 'react';
import {useAtom} from 'jotai';
import {Dialog, Transition} from '@headlessui/react';
import {XMarkIcon} from '@heroicons/react/24/outline';
import Strings from '../../../string_key/Strings.web.js';
import {userDataAtom} from '../../../appState/userState.js';
import useMemberships from '../MembershipScreen/hooks/useMemberships/index.web.js';
import Loading from 'react-fullscreen-loading';
import {colors} from '../../../res/colors.js';
import DesktopLayout from '../MembershipScreen/components/desktopLayout/DesktopLayout.jsx';
import {useNavigation} from '@react-navigation/core';
import SubscriptionCard from '../MembershipScreen/components/subscriptionCard/index.web.js';
import DescriptionList from '../../../components/reusable/descriptionList/DescriptionList.jsx';
import ResizeComponent from '../../../components/reusable/assets/resizeComponent/ResizeComponent.jsx';
import ModalBackdrop from '../../../components/layouts/mainLayout/componentsV2/ModalBackDrop/index.js';

const NewMembershipScreenLayout = ({
  is_open,
  set_is_open,
  myBenifits,
  setMyBenifits,
}) => {
  const [userData, setLoginSuccess] = useAtom(userDataAtom);
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(userData?.membership);
  const [expanded, setExpanded] = useState(null);
  const {navigate} = useNavigation();

  useEffect(() => {
    setCurrent(userData?.membership);
  }, [userData]);

  const {memberships} = useMemberships(setLoading);

  const descriptionList = [
    {
      id: 0,
      img: '/images/hub/subscription/description/01.png',
      text: Strings.msg_premium_access,
    },
    {
      id: 1,
      img: '/images/hub/subscription/description/02.png',
      text: Strings.msg_elite_badge,
    },
    {
      id: 2,
      img: '/images/hub/subscription/description/03.png',
      text: Strings.msg_hide_boosted_contect,
    },
    {
      id: 3,
      img: '/images/hub/subscription/description/04.png',
      text: Strings.msg_verify_your_channel,
    },
    {
      id: 4,
      img: '/images/hub/subscription/description/05.png',
      text: Strings.msg_create_diff_subscription,
    },
    {
      id: 5,
      img: '/images/hub/subscription/description/06.png',
      text: Strings.msg_publish_content,
    },
    {
      id: 6,
      img: '/images/hub/subscription/description/07.png',
      text: Strings.msg_access_free_content,
    },
    {
      id: 7,
      img: '/images/hub/subscription/description/08.png',
      text: Strings.msg_be_part_diamond,
    },
    {
      id: 8,
      img: '/images/hub/subscription/description/09.png',
      text: Strings.msg_earn_engagement_on_app,
    },
  ];

  const icons = [
    {
      type: 'free_member',
      img: '/images/hub/subscription/free.png',
    },
    {
      type: 'super_member',
      img: '/images/hub/subscription/premium.png',
    },
    {
      type: 'premium_member',
      img: '/images/hub/subscription/elite.png',
    },
    {
      type: 'elite_membership',
      img: '/images/hub/subscription/elite.png',
    },
  ];

  const Loader = () => (
    <Loading
      loading={loading}
      background="transparent"
      loaderColor={colors.PRIMARY_COLOR}
    />
  );

  const desktopLayout = (
    <>
      <Loader />

      <DesktopLayout
        openers={memberships}
        current={current}
        setCurrent={setCurrent}
        mySub={userData?.membership}
        icons={icons}
        onSubscribe={id => handleSubscribeEvent(id)}
      />
    </>
  );

  const handleSubscribeEvent = product_id => {
    setLoading(() => true);
    // console.log('product_id', product_id);
    navigate('Subscription', {
      id: product_id,
      token: userData.token,
      from: 'home',
    });
  };

  const MembershipItem = ({el, index, onSubscribe}) => {
    return (
      <div className="hub__subscription-item" key={el.id}>
        <SubscriptionCard
          title={el.title}
          icon={icons.find(it => it.type === el.type).img}
          discount={el?.discount}
          hideBtn={
            el.benifits == null ? true : el?.benifits.length < 4 ? true : false
          }
          onSubscribe={onSubscribe}
          current={current.id === el?.id}
          specialOffer={el?.specialOffer ? Strings.str_and_special_offer : null}
          onShowMore={() => setExpanded(expanded === el?.id ? null : el?.id)}
          content={
            <DescriptionList
              items={
                expanded === el?.id ? el.benifits : el.benifits?.slice(0, 3)
              }
            />
          }
        />
      </div>
    );
  };
  const onClose = () => {
    set_is_open(false);
    setTimeout(() => setMyBenifits(false), 500);
  };

  return (
    <>
      <Transition.Root show={is_open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onClose}>
          <div className="fixed inset-0" />
          <ModalBackdrop />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full">
                  <Dialog.Panel
                    className="pointer-events-auto w-screen max-w-md"
                    style={{maxWidth: 640}}>
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="p-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            Membership Level
                          </Dialog.Title>

                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-pink-500"
                              onClick={onClose}>
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-black-300">
                            {Strings.membership_description}
                          </p>
                        </div>
                      </div>

                      <div className="pt-4 px-4 py-4">
                        <ResizeComponent
                          desktopComponent={
                            <>
                              <Loader />

                              <DesktopLayout
                                openers={memberships}
                                current={current}
                                setCurrent={setCurrent}
                                mySub={userData?.membership}
                                icons={icons}
                                onSubscribe={id => {
                                  set_is_open(false);
                                  handleSubscribeEvent(id);
                                }}
                              />
                            </>
                          }
                          mobileComponent={
                            <>
                              <Loader />

                              {memberships.map((el, index) => {
                                if (myBenifits && el.id !== current.id) {
                                  return false;
                                }

                                return (
                                  <MembershipItem
                                    el={el}
                                    index={index}
                                    key={index}
                                    onSubscribe={id => {
                                      set_is_open(false);
                                      handleSubscribeEvent(el.product_id);
                                    }}
                                  />
                                );
                              })}
                            </>
                          }
                        />
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default NewMembershipScreenLayout;
