import ProfileInformationDesktop from './tabs/ProfileInformationDesktop';
import ProfileAboutDesktop from './tabs/ProfileAboutDesktop';
import ProfileContentTabs from '../profileContentTabs/ProfileContentTabs';
import React from 'react';
import ProfileUsersBody from '../../profileMobileUsers/ProfileUsersBody';

const ProfileDesktopTabsChildrens = ({activeTabId}) => {
  const contentMap = {
    Post: <ProfileContentTabs />,
    About: <ProfileAboutDesktop />,
    Information: <ProfileInformationDesktop />,
    Friends: <ProfileUsersBody />,
  };

  return !activeTabId || !contentMap[activeTabId]
    ? null
    : contentMap[activeTabId];
};

export default ProfileDesktopTabsChildrens;
