import {Fragment} from 'react';
import {Popover, Transition} from '@headlessui/react';
import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
} from '@heroicons/react/20/solid';
import {
  ArrowPathIcon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
} from '@heroicons/react/24/outline';
import Strings from '../../../../../string_key/Strings.web';

const solutions = [
  {
    name: 'Analytics',
    description: 'Get a better understanding of your traffic',
    href: '#',
    icon: ChartPieIcon,
  },
  {
    name: 'Engagement',
    description: 'Speak directly to your customers',
    href: '#',
    icon: CursorArrowRaysIcon,
  },
  {
    name: 'Security',
    description: "Your customers' data will be safe and secure",
    href: '#',
    icon: FingerPrintIcon,
  },
  {
    name: 'Integrations',
    description: 'Connect with third-party tools',
    href: '#',
    icon: SquaresPlusIcon,
  },
  {
    name: 'Automations',
    description: 'Build strategic funnels that will convert',
    href: '#',
    icon: ArrowPathIcon,
  },
];
export const MoreItems = [
  {
    iconSrc: '/images/layout/main-layout/subscription.svg',
    activeIconSrc: '/images/layout/main-layout/active-link/subscription.svg',
    name: Strings.subscription,
    to: '/subscription',
    showOnMobile: true,
  },
  {
    iconSrc: '/images/layout/main-layout/challenges.svg',
    activeIconSrc: '/images/layout/main-layout/active-link/challenges.svg',
    name: Strings.challenges,
    to: '/challenges',
    showOnMobile: true,
  },
  {
    iconSrc: '/images/layout/main-layout/calendar.svg',
    activeIconSrc: '/images/layout/main-layout/active-link/calendar.svg',
    name: Strings.calendar,
    to: '/calendar',
  },
  {
    iconSrc: '/images/layout/main-layout/wallet.svg',
    activeIconSrc: '/images/layout/main-layout/active-link/wallet.svg',
    name: Strings.wallet,
    to: '/wallet',
  },
];
const callsToAction = [
  {name: 'Watch demo', href: '#', icon: PlayCircleIcon},
  {name: 'Contact sales', href: '#', icon: PhoneIcon},
];

export default function MoreDropDown() {
  return (
    <Popover className="relative pt-1">
      <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
        <span className={`inline-block pl-2 text-sm font-medium text-gray-900`}>
          More
        </span>
        <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1">
        <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
          <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
            <div className="p-4">
              {MoreItems.map(item => (
                <div
                  key={item.name}
                  className="group relative flex items-center gap-x-6 rounded-lg p-2 hover:bg-gray-50 cursor-pointer">
                  <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                    <img
                      className="menu-item__icon"
                      src={item.iconSrc}
                      alt={'menu-icon'}
                    />
                  </div>
                  <div>
                    <a className="font-semibold text-gray-900">{item.name}</a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
