import React, {useEffect, useRef, useState} from 'react';
import formatRelativeDate from '../items/profilePost/utils/dateUtils';

import ReplyBtn from '../items/comments/ReplyBtn';
import CustomRatingGold from '../../../../components/reusable/assets/customRating/CustomRatingGold';
import ViewMoreBtn from '../../../../components/reusable/btns/view-more/ViewMoreBtn';
import moment from 'moment';

const Review = ({review, isTrainer}) => {


  return (
    <li>
      <div className="premium-content-reviews__review-card">
        <div className="d-flex flex-row align-items-center">
          <img
            src={review?.image ? review?.image :  "/images/moc/avatar-big.png"}
            alt="avatar"
            className="premium-content-reviews__review-card-avatar"
          />
          <div>
            <p className="premium-content-reviews__review-name h6">
              {review?.name}
            </p>
            <CustomRatingGold initialValue={review?.ratting} readonly />
          </div>
          <p className="premium-content-reviews__review-date text-s">
            {formatRelativeDate(new Date(review?.createdAt))}
          </p>
        </div>
        <p className="premium-content-reviews__review-text text-r">
          {review?.description}
        </p>
        {/* {isTrainer && <ReplyBtn comment={review} />} */}
      </div>

      {/* {review?.replies && (
        <ul className="premium-content-reviews__review-replies-list">
          {review?.replies.map(reply => (
            <li
              className="premium-content-reviews__review-card"
              key={reply?.id}>
              <div className="trainer-answer-title d-flex flex-row align-items-center justify-content-between">
                <div className="d-flex flex-row align-items-center">
                  <img
                    src={reply?.author?.avatar?.src}
                    alt="avatar"
                    className="premium-content-reviews__review-card-avatar"
                  />
                  <p className="h6">Instructor's reply</p>
                </div>

                <ViewMoreBtn
                  text="Read"
                  showAll={showReplyText}
                  toggleShowAll={toggleReplyText}
                />
              </div>

              <p
                className="premium-content-reviews__review-text text-r"
                style={{
                  height: `${replyHeight}px`,
                  overflow: 'hidden',
                  transition: 'height 0.3s ease',
                }}
                ref={replyRef}>
                {reply?.text}
              </p>
            </li>
          ))}
        </ul>
      )} */}
    </li>
  );
};

export default Review;
