import React from 'react';
import image3 from '../../../assets/reload.png';

const UpdateInfo = ({text, onBtnClick}) => {
  return (
    <div className="update-info mb-md-4 mb-1 pt-2 d-flex justify-content-between">
      <span className="update-info__text">{text}</span>
      {onBtnClick && (
        <button className="update-info__btn" onClick={onBtnClick}>
          {/* <i className="fa-regular fa-arrow-rotate-right" />
           */}
          <img src={image3} style={{height: '14px', width: '14px'}} />
        </button>
      )}
    </div>
  );
};

export default UpdateInfo;
