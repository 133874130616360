import { useMutation } from 'react-query';
import { createPost } from '../../../network/api/app';

export const useCreatePost = () => {
    const {
        mutate: mutateCreatePost,
        isLoading: isCreatePostLoading,
        isError: isCreatePostError,
        data: postResponse,
        error: postErrorResponse,
    } = useMutation(createPost);

    const postCreatePost = params => mutateCreatePost(params);

    return {
        postErrorResponse,
        isCreatePostError,
        isCreatePostLoading,
        postResponse,
        postCreatePost,
    };
};
