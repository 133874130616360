import React, {useEffect, useRef} from 'react';
import {setItemHeight} from './utils/setItemHeight';
import PropTypes from 'prop-types';
import {useBuzzmiAuth} from '../../../../../appState/hooks/useBuzzmiAuth/index.web';
import {useDispatch} from 'react-redux';
import {
  closeModal,
  openModal,
} from '../../../../../store/slices/modalSlice/modalSlice';
import {LockClosedIcon} from '@heroicons/react/24/outline';
import {categorizeUrlsFromArray} from './ProfilePostV2';
import {getVideoPosterURL} from '../types';
const ProfilePostTile = ({post, handleSwitchAnchor, contentType = 'all'}) => {
  const itemRef = useRef(null);
  const {userData} = useBuzzmiAuth();
  const dispatch = useDispatch();

  const onUpgradtion = () => {
    dispatch(
      openModal({
        modalName: 'renderAlert',
        modalType: 'alert',
        modalProps: {
          nameAlert: 'upgradeAlert',
          propsAlert: {
            closeModal: () => {
              dispatch(closeModal());
            },
            contentType: post?.contentType,
          },
        },
      }),
    );
  };

  useEffect(() => {
    setItemHeight(itemRef.current);
    const handleResize = () => {
      setItemHeight(itemRef.current);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const contentTypeImage = () => {
    return contentType == 'all' ? null : (
      <div
        className={
          'absolute z-[9] bottom-1 left-1 bg-[#00000099] p-[2px] rounded-lg'
        }>
        {contentType == 'exclusive' ? (
          <img
            src={'/images/social/exclusive_white.png'}
            className="!h-5 !w-5"
          />
        ) : (
          <img src={'/images/social/premium_white.png'} className="!h-5 !w-5" />
        )}
      </div>
    );
  };
  const postMedia = categorizeUrlsFromArray(post?.fields);

  return (
    <>
      <li
        className="profile-posts-list__table-item "
        onClick={e => {
          if (
            contentType != 'all' &&
            userData?.membership?.type == 'free_member'
          ) {
            onUpgradtion();
          } else {
            handleSwitchAnchor(post.id);
          }
        }}
        ref={itemRef}>
        <div className="relative w-100 h-100 flex justify-center items-center">
          <div
            className={`video-wrapper ${
              contentType != 'all' &&
              userData?.membership?.type == 'free_member'
                ? 'blur-[2px]'
                : 'blur-none'
            }`}>
            {postMedia?.image?.[0] || postMedia?.video?.[0] ? (
              <img
                src={
                  postMedia?.image?.[0] ||
                  getVideoPosterURL(postMedia?.video?.[0])
                }
                alt={postMedia?.image?.[0] || postMedia?.video?.[0]}
              />
            ) : (
              <img
                src={
                  'https://coffective.com/wp-content/uploads/2018/06/default-featured-image.png.jpg'
                }
              />
            )}
            {postMedia?.image?.length > 1 && (
              <img
                src="/images/post/multicontent-label.svg"
                alt="multicontent label"
                className="video-label"
              />
            )}

            {postMedia?.video?.[0] && (
              <img
                // src="/images/post/video-label.svg"
                src={getVideoPosterURL(postMedia?.video?.[0])}
                alt="video label"
                className="video-label"
              />
            )}

            {contentTypeImage()}
          </div>
          {contentType != 'all' &&
            userData?.membership?.type == 'free_member' && (
              <div
                className={
                  'absolute top-0 w-100 h-100 flex justify-center items-center'
                }>
                <LockClosedIcon className={'h-6 w-6 text-[#FFF]'} />
              </div>
            )}
        </div>
      </li>
    </>
  );
};

ProfilePostTile.propTypes = {
  post: PropTypes.shape({
    id: PropTypes.number.isRequired,
    image: PropTypes.objectOf(PropTypes.string),
    video: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
  handleSwitchAnchor: PropTypes.func.isRequired,
};

export default ProfilePostTile;
