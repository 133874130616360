import { getAllRankInfo } from '../../../network/api/app';
import { useQuery } from 'react-query';

export const useGetAllRankInfo = () => {
  const getAllRank = async token => {
    try {
      const response = await getAllRankInfo({ token });
      return response;
    } catch (e) {
      console.log('error => ', e.message);
    }
  };

  const useGetAllRankInfoList = token =>
    useQuery(['rankInfoList', token], () => getAllRank(token), {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      cacheTime: 0,
      refetchInterval: 0,
    });

  return {
    useGetAllRankInfoList,
  };
};
