import React, { useEffect, useState, useRef } from 'react';
import Form from '../../../../components/reusable/form/Form.jsx';
import { useNavigation, useRoute } from '@react-navigation/native';
import Loading from 'react-fullscreen-loading';
import { useToast } from 'react-native-toast-notifications';

import Input from '../../../../components/reusable/form/items/inputs/Input.jsx';
import DateInput from '../../../../components/reusable/form/items/inputs/dateInputs/DateInput.jsx';
import InvitationInputs from '../../../../components/reusable/form/items/inputs/codeInputs/InvitationInputs.jsx';
import ValidationSchema from '../../../../form-validator/ValidationSchema.js';
import { useFormValidator } from '../../../../form-validator/hooks/index.js';
import Policy from '../../Registration/items/Policy.jsx';
import PrimaryButton from '../../../../components/reusable/form/items/buttons/PrimaryButton.jsx';
import CustomSelect from '../../../../components/reusable/form/select/customSelect/CustomSelect.jsx';
import {
  genderOptions,
  phoneNumbersCode,
} from '../../../../components/reusable/form/select/mocData.jsx';
import { POST } from '../../../../api_helper/ApiServices.js';
import { REGISTER_SOCIAL } from '../../../../api_helper/Api.js';
import { colors } from '../../../../res/colors.js';
import { ShowError, ShowSuccess } from '../../../HOC/MessageWeb.js';
import { saveData } from '../../../../res/asyncStorageHelper.web.js';
import Strings from '../../../../string_key/Strings.web.js';
import Select from 'react-select';
import { DropdownIndicator } from '../../../../components/reusable/form/select/customSelect/CustomSelect.jsx';
import { useEncryptedAtom } from '../../../../appState/hooks/useEncryptedAtom/index.web.js';
import {
  _sessionTokenAtom,
  _userEmailAtom,
} from '../../../../appState/userState.js';
import { useBuzzmiAuth } from '../../../../appState/hooks/useBuzzmiAuth/index.web.js';

const validationSchema = new ValidationSchema({
  name: [{ rule: 'required' }],
  firstname: [{ rule: 'required' }],
  lastname: [{ rule: 'required' }],
  email: [{ rule: 'required' }, { rule: 'email' }],
  countryCode: [{ rule: 'required' }, { rule: 'countryPhoneCode' }],
  mobileno: [{ rule: 'required', rule: 'phone' }],
  invitation: [],
  birthday: [{ rule: 'required' }, { rule: 'dateOfBirth' }, { rule: 'age18Plus' }],
});

const BioPageForm = props => {
  const { navigate } = useNavigation();
  const { params } = useRoute();
  let refUrlParams = useRef(params);
  const toast = useToast();

  const [code, setCode] = useState([]);
  const [error, setError] = useState(null);
  const [isInvitation, setIsInvitation] = useState(true);
  const [isEmail, setIsEmail] = useState(true);
  const [selected, setSelected] = useState({
    label: (
      <div className={'select__item'}>
        <span>Male</span>
      </div>
    ),
    value: 'M',
  });
  const [isLoad, setIsLoad] = useState(false);

  const [emailOrPhone, setEmailOrPhone] = useState(false);
  const [fData, setFData] = useState(null);

  const [mselected, setMSelected] = useState(
    phoneNumbersCode.filter(
      o => o.value == refUrlParams?.current?.user?.countryCode,
    ) || null,
  );
  const [focused, setFocused] = useState(false);
  const [sessiontoken, setSessionToken] = useEncryptedAtom(_sessionTokenAtom);
  const [userRoles, setUserEmail] = useEncryptedAtom(_userEmailAtom);

  const { validateUserInfo, isValidateLoading, isValidateError, validateResponse, validateErrorResponse } = useBuzzmiAuth();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const type = urlParams.get('type');
    if (type === 'invite') {
      setIsInvitation(true);
    }
  }, []);

  const { register, handleSubmit, errors, setValue, isValid } = useFormValidator(
    validationSchema,
    async formData => {
      onSubmit(formData);
    },
    { showErrorsOnSubmit: false },
  );


  useEffect(() => {
    if (validateErrorResponse != null) {
      ShowError(validateErrorResponse?.message, toast);
    }
    if (validateResponse != undefined) {
      if (validateResponse?.message == "success") {
        if (refUrlParams?.current?.user.loginType != 'Email') {
          onSocialLoginRegister(fData);
        } else {
          console.log(
            Object.assign(fData, {
              isEmailVerified: isEmail,
              isMobileVerified: !isEmail,
              gender: selected?.value,
            }),
          );
          navigate('CreatePassword', {
            param: Object.assign(fData, {
              isEmailVerified: isEmail,
              isMobileVerified: !isEmail,
              gender: selected?.value,
            }),
          });
        }
      }
    }
  }, [validateErrorResponse, validateResponse, fData])

  
  const onSubmit = async data => {
    validateUserInfo(data?.email, data?.name, data?.countryCode + data?.mobileno)
    setFData(data)
  };

  const onSocialLoginRegister = data => {
    setIsLoad(true);
    let postData = {
      gender: selected?.value,
      firstName: data.firstname,
      lastName: data.lastname,
      dob: new Date(data.birthday).toISOString(),
      mobile: data?.countryCode + data.mobileno,
      email: data.email,
      referralCode: data.code ? data.code : '',
      username: data.name,
      socialId: refUrlParams?.current?.user.sub,
      loginType: refUrlParams?.current?.user.loginType,
    };
    POST(REGISTER_SOCIAL, false, null, null, postData, (response, isError) => {
      setIsLoad(false);
      if (isError) {
        ShowError(response, toast);
      } else {
        setUserEmail(data.email);
        setSessionToken('social-' + response?.token);
        navigate('ChoseRole');
      }
    });
  };

  useEffect(() => {
    setEmailOrPhone(
      refUrlParams?.current?.user?.countryCode ||
        refUrlParams?.current?.user?.email
        ? true
        : false,
    );
    if (refUrlParams?.current?.user?.countryCode) {
      setValue('mobileno', refUrlParams?.current?.user?.phone);
      setValue('countryCode', refUrlParams?.current?.user?.countryCode);
      setIsEmail(false);
    } else {
      setValue('email', refUrlParams?.current?.user?.email);
      setIsEmail(true);
      if (refUrlParams?.current?.user.loginType != 'Email') {
        setValue('firstname', refUrlParams?.current?.user?.firstName);
        setValue('lastname', refUrlParams?.current?.user?.lastName);
      }
    }
    register();
  }, [refUrlParams?.current]);

  const handleSelect = e => {
    setMSelected(e);
    setValue('countryCode', e.value);
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  return (
    <>
      <Loading
        loading={isLoad}
        background="transparent"
        loaderColor={colors.PRIMARY_COLOR}
      />
      <Form
        error={error}
        formClassName="mb-9"
        onSubmit={handleSubmit}
        id="input-bio">
        <div
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            display: 'flex',
            gap: 10,
          }}>
          <Input
            label={Strings.first_name}
            name="firstname"
            register={register}
          />
          <Input
            label={Strings.last_name}
            name="lastname"
            register={register}
          />
        </div>

        <div
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            display: 'flex',
            gap: 10,
          }}>
          <Input label={Strings.user_name} name="name" register={register} />
          <Input
            label={Strings.email}
            name="email"
            register={register}
            readOnly={emailOrPhone && isEmail ? true : false}
            style={{
              backgroundColor: emailOrPhone && isEmail ? '#F2F2F2' : '#ffffff',
            }}
          />
        </div>

        <div class="input-label" style={{ marginTop: '-20px' }}>
          <div>{Strings.gender}</div>
          <CustomSelect
            selected={selected}
            setSelected={setSelected}
            options={genderOptions}
          />
        </div>

        <DateInput
          register={register}
          label={Strings.when_your_birhtday}
          setValue={setValue}
          errors={errors.birthday}
        />

        <div class="input-label">
          <div>{Strings.mobile_number}</div>
          <div
            style={{
              backgroundColor: emailOrPhone && !isEmail ? '#F2F2F2' : '#ffffff',
            }}
            className={`phone-select ${focused ? 'phone-select_active' : ''} ${errors.countryCode || errors.mobileno ? 'phone-select_error' : ''
              }`}
            onClick={handleFocus}
            onBlur={handleBlur}>
            <Select
              components={{ DropdownIndicator }}
              options={phoneNumbersCode}
              value={mselected}
              onChange={handleSelect}
              hasSelectAll={false}
              labelledBy="Select"
              isDisabled={emailOrPhone && !isEmail}
              className={`phone-select__select`}
              disableSearch={true}
              classNamePrefix="phone-select"
            />
            <input
              readOnly={emailOrPhone && !isEmail ? true : false}
              className="phone-select__input"
              placeholder={Strings.phone_number}
              type="text"
              {...register('mobileno')}
              style={{
                backgroundColor:
                  emailOrPhone && !isEmail ? '#F2F2F2' : '#ffffff',
              }}
            />
          </div>
          <div className="mt-2">
            {errors.countryCode && (
              <div className={'text-error text-center'}>
                {errors.countryCode[0]}
              </div>
            )}
            {errors.mobileno && (
              <div className={'text-error text-center'}>
                {errors.mobileno[0]}
              </div>
            )}
          </div>
        </div>

        {isInvitation && (
          <InvitationInputs
            setValue={setValue}
            setCode={setCode}
            register={register}
            name={'invitation'}
          />
        )}
      </Form>
      <div>
        <PrimaryButton
          className={`mb-5 ${!isValid ? 'button_inactive' : ''}`}
          type="submit"
          form="input-bio">
          {Strings.next}
        </PrimaryButton>
        <Policy />
      </div>
    </>
  );
};

export default BioPageForm;
