import React, {useRef, useState, useEffect} from 'react';
import {
  AnothersPostMenuData,
  MyPostMenuData,
  FriendCardMenuData,
} from './PostMenuData';
import PostMenuItem from './PostMenuItem';
import useOnClickOutside from '../../../../../hooks/useOnClickOutside';
import EllipsisIcon from '../icons/EllipsisIcon';
import PropTypes from 'prop-types';
import UnfollowIcon from '../icons/UnfollowIcon';
import SaveIcon from '../icons/SaveIcon';

const EllipsisMenu = ({
  type,
  isMyPost,
  Id,
  post,
  modalmenu = '',
  is_from_modal = false,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const menuPostRef = useRef(null);

  const handleMenu = event => {
    event.stopPropagation();
    setShowMenu(!showMenu);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useOnClickOutside(menuPostRef, showMenu, setShowMenu);

  const getAltName = item => {
    if (item.text == 'Save') {
      return post?.object?.isBookmarked == true ||
        post?.post?.isBookmarked == true
        ? 'saved'
        : item.alt;
    } else if (item.text == 'Follow') {
      return post?.object?.followed == true || post?.post?.followed == true
        ? 'unfollowed'
        : 'followed';
    } else {
      return item.alt;
    }
  };

  const getTextName = item => {
    if (item.text == 'Save') {
      return post?.object?.isBookmarked == true ||
        post?.post?.isBookmarked == true
        ? 'Saved'
        : item.text;
    } else if (item.text == 'Follow') {
      return post?.object?.followed == true || post?.post?.followed == true
        ? 'Unfollow'
        : item.text;
    } else {
      return item.text;
    }
  };

  const getIcon = item => {
    if (item.text == 'Save') {
      return post?.post?.isBookmarked == true ? (
        <SaveIcon isBooked={true} isHovered={false} />
      ) : (
        item.icon
      );
    } else if (item.text == 'Follow') {
      return post?.post?.followed == true ? (
        <UnfollowIcon isHovered={false} />
      ) : (
        item.icon
      );
    } else {
      return item.icon;
    }
  };

  return (
    <>
      {showMenu && <div className="ellipsis-menu__overlay"></div>}

      {type === 'reel' ? (
        <button
          className="reels__buttons-list-button"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleMenu}>
          <EllipsisIcon isHovered={isHovered} />
        </button>
      ) : (
        <button
          className={`ellipsis-menu__dots ${is_from_modal ? modalmenu : ''}`}
          onClick={handleMenu}>
          <img src="/images/assets/info-card/dots_menu.svg" alt="dots menu" />
        </button>
      )}

      <div
        ref={menuPostRef}
        className={`ellipsis-menu__menu-wrapper ${showMenu ? 'opened' : ''}`}>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <h4 className="h4">Options</h4>

          <button className="ellipsis-menu__close-menu" onClick={handleMenu}>
            <img src="/images/assets/close-icon.svg" alt="close menu" />
          </button>
        </div>

        <ul className="ellipsis-menu__menu-list d-flex flex-column">
          {isMyPost
            ? post &&
              MyPostMenuData.map(item => (
                <PostMenuItem
                  key={item.id}
                  text={item.text}
                  icon={item.icon}
                  alt={item.alt}
                  id={Id}
                  type={type}
                  item={Object.assign(post, {post: post?.post || post?.object})}
                />
              ))
            : AnothersPostMenuData.map(item => (
                <PostMenuItem
                  key={item.id}
                  text={getTextName(item)}
                  icon={getIcon(item)}
                  alt={getAltName(item)}
                  id={Id}
                  item={
                    post &&
                    Object.assign(post, {post: post?.post || post?.object})
                  }
                  onClose={() => {
                    close();
                  }}
                />
              ))}
        </ul>
      </div>
    </>
  );
};

EllipsisMenu.propTypes = {
  type: PropTypes.string,
  isMyPost: PropTypes.bool,
};

export default EllipsisMenu;
