import React from 'react';
import LikesPostBtn from './LikesPostBtn';
import CommentsPostBtnV2 from './CommentsPostBtnV2';
import GemsPostBtn from './GemsPostBtnV2';
import GiftsPostBtn from './GiftsPostBtnV2';
import SharePostBtn from './SharePostBtn';
import UpdatePostBtn from './UpdatePostBtn';
import CommentsReelBtnV2 from '../../../profileReels/reelBtns/CommentsReelBtnV2';
import LikesReelBtn from '../../../profileReels/reelBtns/LikesReelBtn';
import ShareReelBtn from '../../../profileReels/reelBtns/ShareReelBtn';
import SharePostBtnV2 from './SharePostBtnV2';
import ShareReelBtnV2 from '../../../profileReels/reelBtns/ShareReelBtnV2';

const PostBtnsV2 = ({
  likes,
  comments,
  gems,
  gifts,
  postId,
  userName,
  userAvatar,
  post,
  type,
}) => {
  return (
    <div
      className="post-buttons d-flex align-items-center justify-content-between"
      style={{maxWidth: type == 'reel' ? '60%' : '100%'}}>
      {type == 'reel' ? (
        <LikesReelBtn
          isFromPost
          likes={likes}
          id={postId}
          likesList={post?.reactions}
        />
      ) : (
        <LikesPostBtn likes={likes} id={postId} likesList={post?.reactions} />
      )}

      {type == 'reel' ? (
        <CommentsReelBtnV2 reel={post} isFromPost />
      ) : (
        <CommentsPostBtnV2 comments={comments} postId={postId} />
      )}
      {type == 'post' && <GemsPostBtn gems={gems} postId={postId} />}
      {type == 'post' && <GiftsPostBtn gifts={gifts} postId={postId} />}
      {type == 'post' ? (
        <SharePostBtnV2
          userName={userName}
          userAvatar={userAvatar}
          postId={postId}
          post={post}
        />
      ) : (
        <ShareReelBtnV2
          userName={userName}
          userAvatar={userAvatar}
          reelId={post?.id}
          reel={post}
        />
      )}
      {/* {type == 'post' && <UpdatePostBtn />} */}
    </div>
  );
};

export default PostBtnsV2;
