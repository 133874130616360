import React, {useContext, useState, useEffect} from 'react';
import {
  View,
  Image,
  Text,
  TouchableOpacity,
  Alert,
  TextInput,
  Platform,
} from 'react-native';
import {Spacer} from '../../../../res/spacer';
import {GlobalFlex} from '../../../../res/globalStyles';
import {BackHeader} from '../../../../components/BackHeader';
import {colors} from '../../../../res/colors';
import {images} from '../../../../res/images';
import {styles} from './style';
// import realm from '../../../../realmStore';
import {GET} from '../../../../api_helper/ApiServices';
import {fonts} from '../../../../res/fonts';
import {INVITE_LINK} from '../../../../api_helper/Api';
// import AlphabetList from 'react-native-flatlist-alphabet';
import {heightPercentageToDP as hp, widthPercentageToDP as wp} from 'react-native-responsive-screen';
import SendSMS from 'react-native-sms';
import {useBuzzmiAuth} from "../../../../appState/hooks";

let selectedPhones = [];
const Invitations = props => {
  const refContactsData = React.useRef([
    // ...realm.objects('Contact').sorted('givenName'),
  ]);
  const [mathRandom, setMathRandom] = useState(Math.random());
  const [selectedContact, setSelectedContacts] = useState([]);
  const {userData} = useBuzzmiAuth();
  const [search, setSearchQuery] = useState('');
  const [contactData, setContactData] = useState(refContactsData?.current);

  // const [CheckBox, setCheckBox] = React.useState(() => NullComponent);

  // if (!Platform.isTV) {
  //   import('react-native-ad-manager')
  //     .then(({Banner: AdBanner}) => {
  //       setBanner(() => AdBanner);
  //     })
  // }


  const onSendInvitation = async () => {
    const api = INVITE_LINK(userData?.userId);
    GET(api, userData?.token, '')
      .then(res => {
        SendSMS.send(
          {
            body: res.data?.link,
            recipients: selectedContact.map(a => a.phoneNumber),
            successTypes: ['sent', 'queued'],
            allowAndroidSendWithoutReadPermission: true,
          },
          (completed, cancelled, error) => {
            if (completed) {
              props.navigation.goBack();
            }
            console.log(
              'SMS Callback: completed: ' +
              completed +
              ' cancelled: ' +
              cancelled +
              'error: ' +
              error,
            );
          },
        );
      })
      .catch(e => console.log('INVITATION (error) => ', e.message));
  };

  const getUserContactList = () => {
    let arr = [];
    // const _contact = JSON.parse(
    //   JSON.stringify(realm.objects('Contact').sorted('givenName')),
    // );
    const _contact = [];

    _contact.map(item => {
      arr.push({
        key: item.key,
        value: item.value,
        is_checked: false,
        recordID: item.recordID,
        givenName: item.givenName,
        familyName: item.familyName,
        phoneNumber: item.phoneNumber,
        hasThumbnail: item.hasThumbnail,
        thumbnailPath: item.thumbnailPath,
      });
    });
    setContactData(arr);
    return () => {
      selectedPhones = [];
    };
  };

  useEffect(() => {
    getUserContactList();
  }, []);

  const renderListItem = item => {
    return (
      <TouchableOpacity
        key={mathRandom}
        style={styles.listItemContainer}
        onPress={() => {
          item.is_checked = !item.is_checked;
          if (selectedPhones.includes(item.phoneNumber)) {
            selectedPhones.splice(
              selectedPhones.findIndex(a => a == item.phoneNumber),
              1,
            );
            setSelectedContacts(prevState => {
              return prevState.filter(a => a.recordID !== item.recordID);
            });
          } else {
            setSelectedContacts(prevState => {
              return [...prevState, item];
            });
            selectedPhones.push(item.phoneNumber);
          }
          setMathRandom(Math.random());
        }}>
        {Platform.OS != 'web' &&
          <View style={{alignSelf: 'center'}}>
            {/*<CheckBox
              boxType={'circle'}
              disabled={false}
              value={selectedPhones.includes(item.phoneNumber)}
              onFillColor={colors.DARK_GREEN}
              onTintColor={colors.DARK_GREEN}
              onCheckColor={colors.WHITE}
              tintColor={colors.LIGHT_GRAY}
              animationDuration={0.2}
              lineWidth={1}
              tintColors={{ true: colors.DARK_GREEN, false: colors.LIGHT_GRAY }}
              onValueChange={newValue => (item.is_checked = newValue)}
              style={styles.checkbox}
            />*/}
          </View>
        }
        <Image
          source={item.hasThumbnail ? {uri: item.thumbnailPath} : images.avatar}
          style={{width: wp(8), height: wp(8), borderRadius: wp(8)}}
        />
        <Text style={styles.listItemLabel}>{item.value}</Text>
      </TouchableOpacity>
    );
  };

  const renderSectionHeader = section => {
    return (
      <View style={styles.sectionHeaderContainer}>
        <Text style={styles.sectionHeaderLabel}>{section.title}</Text>
      </View>
    );
  };

  return (
    <GlobalFlex>
      <BackHeader
        is_center_text
        title={'Invite'}
        onBackPress={() => props.navigation.goBack()}
        isRightText
        rightText={'Clear'}
        onNextPress={() => {
          // setSelectedContacts([]),
          // getUserContactList()
          setSelectedContacts([]);
          selectedPhones.splice(0, selectedPhones.length);
          setMathRandom(Math.random());
        }}
      />
      <Spacer space={hp(1)}/>
      <View style={styles.search_wrapper}>
        <Image source={images.search_ic} style={styles.search_ic}/>
        <TextInput
          placeholder={'Search'}
          placeholderTextColor={colors.OSLO_GRAY}
          value={search}
          onChangeText={val => {
            setSearchQuery(val);
            refContactsData.current = null;
            refContactsData.current = JSON.parse(
              JSON.stringify(
                // realm
                //   .objects('Contact')
                //   .sorted('givenName')
                //   .filtered(
                //     'givenName CONTAINS[c] $0 OR familyName CONTAINS[c] $0 ',
                //     val,
                //   ),
                []
              ),
            );
            setContactData(() => refContactsData.current);
          }}
          style={styles.Input}
          clearButtonMode={'always'}
        />
      </View>

      <Spacer space={hp(1)}/>
      <View style={{flex: 1, backgroundColor: colors.WHITE}}>
        {contactData && contactData.length > 0 && mathRandom && (
          <>
            {/*<AlphabetList
              style={{ flex: 1 }}
              data={contactData}
              renderItem={renderListItem}
              renderSectionHeader={renderSectionHeader}
              letterItemStyle={{ height: 0 }}
              indexLetterColor={colors.PRIMARY_COLOR}
            />*/}
            <Spacer space={hp(1)}/>
          </>
        )}
      </View>
      <TouchableOpacity
        style={styles.float_wrapper}
        disabled={selectedContact.length > 0 ? false : true}
        onPress={() => onSendInvitation()}>
        <View
          style={{
            backgroundColor:
              selectedContact.length > 0
                ? colors.PRIMARY_COLOR
                : colors.LIGHT_PRIMARY_COLOR,
            paddingHorizontal: wp(5),
            paddingVertical: wp(3),
            borderRadius: wp(12),
          }}>
          <Text style={{color: colors.WHITE, fontFamily: fonts.BOLD}}>
            {'Invite'}
          </Text>
        </View>
      </TouchableOpacity>
    </GlobalFlex>
  );
};
export default Invitations
