/* eslint-disable react-hooks/exhaustive-deps */
// import React, {FC, useEffect, useRef} from 'react';
import React from 'react';
import {
  ActivityIndicator,
  // Animated,
  // Easing,
  // Modal,
  // StyleProp,
  View,
  Platform,
  // ViewStyle,
} from 'react-native';
import {colors} from '../res/colors';

import Palette from '../styles/palette.ts';
// import SBIcon from './SBIcon.tsx';
// import SBText from './SBText.tsx';

// type Props = {visible: boolean};

const Loading = ({visible}) => {
  if (!visible) {
    return null;
  }

  return (
    <View
      style={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(255,255,255,0.3)',
        zIndex: 100,
      }}>
      <ActivityIndicator
        color={colors.PRIMARY_COLOR}
        size={Platform.OS === 'web' ? 50 : 'large'}
      />
    </View>
  );
};

/*const Loading: FC<Props> = ({visible}) => {
  return (
    <Modal
      visible={visible}
      style={{margin: 0}}
      transparent
      animationType={'fade'}>
      <View
        style={{
          flex: 1,
          backgroundColor: Palette.overlay02,
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <View
          style={{
            borderRadius: 12,
            alignItems: 'center',
            justifyContent: 'center',
            padding: 16,
            backgroundColor: Palette.onBackgroundLight01,
          }}>
          <Spin style={{marginBottom: 8}}>
            <SBIcon
              icon={'SpinnerLarge'}
              size={40}
              color={Palette.onBackgroundDark01}
            />
          </Spin>
          <SBText subtitle2 color={Palette.onBackgroundDark01}>
            {'Loading...'}
          </SBText>
        </View>
      </View>
    </Modal>
  );
};*/

// const useLoopAnimated = (
//   input: [number, number],
//   duration: number,
//   useNativeDriver = true,
// ) => {
//   const animated = useRef(new Animated.Value(input[0])).current;
//
//   useEffect(() => {
//     Animated.loop(
//       Animated.timing(animated, {
//         toValue: input[1],
//         duration,
//         useNativeDriver,
//         easing: Easing.inOut(Easing.linear),
//       }),
//       {resetBeforeIteration: true},
//     ).start();
//
//     return () => {
//       animated.stopAnimation();
//       animated.setValue(0);
//     };
//   }, []);
//
//   return animated;
// };

// const Spin: FC<{children: React.ReactNode; style?: StyleProp<ViewStyle>}> = ({
//   children,
//   style,
// }) => {
//   const inputRange = [0, 1] as [number, number];
//   const loop = useLoopAnimated(inputRange, 1000);
//   const rotate = loop.interpolate({
//     inputRange,
//     outputRange: ['0deg', '360deg'],
//   });
//   return (
//     <Animated.View style={[style, {transform: [{rotate}]}]}>
//       {children}
//     </Animated.View>
//   );
// };

export default Loading;
