
import React, {useEffect, useState} from 'react';
import Form from '../../../../components/reusable/form/Form.jsx';
import Input from '../../../../components/reusable/form/items/inputs/Input.jsx';
import ValidationSchema from '../../../../form-validator/ValidationSchema.js';
import {useFormValidator} from '../../../../form-validator/hooks/index.js';
import Strings from '../../../../string_key/Strings.web.js';

const validationSchema = new ValidationSchema({
  password: [
    {rule: 'required'},
    {rule: 'password', value: 3},
    {rule: 'leastOneUpperCase'},
    {rule: 'leastOneSpecialCharacter', value: 3},
  ],
  confirmPassword: [{rule: 'passwordConfirmation', value: 'password'}],
});

const CreatePasswordForm = ({
  passwordLabel = 'Create your Password',
  submitHandler,
  setValid,
}) => {
  const {register, handleSubmit, getFieldMessages, isValid, values} = useFormValidator(
    validationSchema,
    submitHandler,
    {
      showErrorsOnSubmit: false,
    },
  );

  useEffect(() => setValid && setValid(isValid), [isValid]);

  return (
    <Form formClassName={'w-100'} onSubmit={handleSubmit} id="create-password">
      <div className="pass_input">
        <Input
          label={Strings.your_password}
          multiValidation={getFieldMessages('password')}
          name="password"
          register={register}
          createPasswordForm={true}
        />
      </div>
      <div className="pass_input">
        <Input
          showError={Object.keys(values).includes('password')}
          isValid={isValid}
          label={Strings.confirm_password}
          multiValidation={getFieldMessages('confirmPassword')}
          name="confirmPassword"
          register={register}
          createPasswordForm={true}
        />
      </div>
    </Form>
  );
};

export default CreatePasswordForm;
