import React, {Fragment, useState} from 'react';
import {useNavigation} from '@react-navigation/native';
import {useBuzzmiAuth} from '../../../../../../appState/hooks/useBuzzmiAuth/index.web';
import CreatePostModal from '../CreatePostModal';

export default function CreatePostInput({editable = false, onChange, value}) {
  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState('');
  const {userData} = useBuzzmiAuth();
  const {navigate} = useNavigation();

  const properties = !editable && {
    onClick: e => {
      e.preventDefault();
      setOpen(() => true);
    },
  };

  return (
    <div className="flex items-center space-x-4">
      <div className="flex-shrink-0">
        <img
          className="inline-block h-10 w-10 rounded-full"
          src={userData?.avatar ? userData?.avatar : '/images/moc/avatar-big.png'}
          alt=""
        />
      </div>
      <div className="min-w-0 flex-1">
        <form action="#" className="relative">
          <div className="overflow-hidden rounded-lg">
            <label htmlFor="comment" className="sr-only">
              Whats on your mind?
            </label>
            <textarea
              name="comment"
              id="comment"
              value={value}
              onChange={onChange}
              className={`block w-full resize-none border-0 bg-gray-100 py-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ${
                editable ? 'rounded-md' : 'rounded-full'
              }`}
              placeholder="Whats on your mind?"
              rows={editable ? 5 : 1}
              defaultValue={''}
              {...properties}
            />
          </div>
        </form>
      </div>
      <CreatePostModal setOpen={setOpen} open={open} post={null} is_edit={false}/>
    </div>
  );
}
