import {useAtom} from 'jotai';
import {userPlannedActivityStats} from '../hubState';
import {getPlannedActivityStats} from '../../../network/api/app';

export const usePlannedActivityStat = () => {
  const [plannedActivityStats, setPlannedActivityStats] = useAtom(
    userPlannedActivityStats,
  );

  const getOnePlannedActivityStatsApi = async (id, token) => {
    console.log('param planned activities', id, token);
    try {
      const response = await getPlannedActivityStats(id)({token});
      setPlannedActivityStats(response);
      // console.log('response in single planned activities', response);
    } catch (e) {
      console.log('error response in single planned activities => ', e.message);
    }
  };

  return {
    plannedActivityStats,
    setPlannedActivityStats,
    getOnePlannedActivityStatsApi,
  };
};
