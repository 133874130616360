import React from 'react';
import Box from '../../../../../components/reusable/cards/box/Box';
import CustomLink from '../../../../../components/reusable/btns/links/CustomLink';
import ProfileDesktopTabs from '../../items/profileDesktopTabs/ProfileDesktopTabs';
import ProfileBio from '../../items/ProfileBio';
import ProfileStories from '../../items/profileStories/ProfileStories';
import Avatar from '../../../../../components/reusable/assets/avatar/Avatar';
import CreatePostInput from '../componentsV2/CreatePostInput';
import image1 from '../../../../../assets/ic_grad_pencil.png';

const ProfileMainDesktop = () => {
  return (
    <>
      <Box paddingMode="big" className="profile-top__desktop-wrap">
        <Avatar />
        <ProfileBio />
        <div>
          <CustomLink
            className="_fw button_primary button_s"
            to="/hub/add-activities">
            Add activity
          </CustomLink>
          <CustomLink
            className="_fw button_secondary  button_s"
            to="/profile/edit">
            Edit Profile
            {/* <img src={image1} className="h-10, w-10" /> */}
          </CustomLink>
        </div>
      </Box>
      <ProfileStories />
      <ProfileDesktopTabs />
    </>
  );
};

export default ProfileMainDesktop;
