import React, {useEffect, useState, useRef, Fragment} from 'react';

import {Dialog, Transition} from '@headlessui/react';
import {XMarkIcon} from '@heroicons/react/24/outline';
import ModalBackdrop from '../../../../components/layouts/mainLayout/componentsV2/ModalBackDrop';
import CustomizableCircleChartWrapper from '../../../../components/reusable/assets/charts/customizableCircleChart/CustomizableCircleChartWrapper';
import {useFormValidator} from '../../../../form-validator/hooks';
import Box from '../../../../components/reusable/cards/box/Box';
import ValidationSchema from '../../../../form-validator/ValidationSchema';
import Form from '../../../../components/reusable/form/Form';
import {mocData} from '../HubTaskDetailsv2/mocData.js';
import SubmitButton from '../../../../components/reusable/btns/buttons/submitButton/SubmitButton';
import {useCompletePlannedActivity} from '../../../../appState/hooks/useCompletePlannedActivity';

const GoalProgressModal = ({activityModal, showActivityModal, item}) => {
  const validationSchema = new ValidationSchema({});
  const isComplete = useRef(false);
  const [error, setError] = useState(null);
  const data = mocData[0];
  const {patchCompletePlannedActivity, CompletePlannedActivityResponse} =
    useCompletePlannedActivity();

  const sendRequest = async formData => {
    try {
      let param_data = {};
      // isComplete.current &&
      Object.assign(param_data, {
        isCompleted: isComplete.current,
      });

      item?.goal &&
        Object.assign(param_data, {
          goalProgress:
            formData.progressInfo.type == 'time'
              ? `${formData.progressInfo.currentValue / 60000} mins`
              : `${formData.progressInfo.currentValue} km`,
        });
      console.log('item', item);
      item?.progressMedia?.media?.length > 0 &&
        Object.assign(param_data, {
          progressMedia: item?.progressMedia?.media,
        });
      patchCompletePlannedActivity(item?.id, param_data);
    } catch (error) {}
  };

  useEffect(() => {
    if (CompletePlannedActivityResponse) {
      showActivityModal(() => false);
    }
  }, [CompletePlannedActivityResponse]);

  const {handleSubmit, setValue, values, isValid} = useFormValidator(
    validationSchema,
    sendRequest,
    {
      defaultValues: {
        progressInfo: {
          ...data.progressInfo,
          ...(item?.goal?.includes('mins') && {
            currentValue:
              item?.goalProgress?.length > 0
                ? Number(item?.goalProgress.split(' ')[0]) * 60000
                : 0,
            initValue: Number(item?.goal.split(' ')[0]) * 60000,
            type: 'time',
          }),
          ...(item?.goal?.includes('km') && {
            currentValue:
              item?.goalProgress?.length > 0
                ? Number(item?.goalProgress.split(' ')[0])
                : 0,
            initValue: Number(item?.goal.split(' ')[0]),
            type: 'distanceMetric',
          }),

          ...(item?.goal?.includes('glasses') && {
            currentValue:
              item?.goalProgress?.length > 0
                ? Number(item?.goalProgress.split(' ')[0])
                : 0,
            initValue: Number(item?.goal.split(' ')[0]),
            type: 'glasses',
          }),
          ...(item?.goal?.includes('times') && {
            currentValue:
              item?.goalProgress?.length > 0
                ? Number(item?.goalProgress.split(' ')[0])
                : 0,
            initValue: Number(item?.goal.split(' ')[0]),
            type: 'times',
          }),
        },
      },
    },
  );

  return (
    <Transition.Root show={activityModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={showActivityModal}>
        <ModalBackdrop />
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full">
                <Dialog.Panel
                  className="pointer-events-auto w-screen max-w-md"
                  style={{maxWidth: 640}}>
                  <div className="flex h-full flex-col overflow-y-scroll bg-[#FBF8FF] shadow-xl">
                    <div className="p-4">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900 text-center w-100">
                          {item?.title + ' Goal Progress'}
                        </Dialog.Title>

                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-pink-500"
                            onClick={() => showActivityModal(() => false)}>
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className=" px-3 ">
                      <>
                        <Form error={error} onSubmit={handleSubmit}>
                          {item?.goal && values?.progressInfo && (
                            <Box className=" hub-form-box mb-4">
                              <CustomizableCircleChartWrapper
                                values={values?.progressInfo}
                                setValues={value =>
                                  setValue('progressInfo', value)
                                }
                                handleCompleteClick={e => {
                                  console.log(e);
                                  isComplete.current = true;
                                  e.handleComplete();
                                  handleSubmit(e.e);
                                }}
                                hideComplete={true}
                              />
                            </Box>
                          )}
                          {!item?.isCompleted && (
                            <div className="d-flex justify-content-center my-3 my-md-3 rounded-full overflow-hidden shadow-lg">
                              <SubmitButton
                                form="task-form"
                                title={'UPDATE'}
                                isValid={true}
                              />
                            </div>
                          )}
                        </Form>
                      </>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default GoalProgressModal;
