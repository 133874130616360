import React, { useRef } from 'react';
import InfoCard from './InfoCard.jsx';
import { Dots } from 'react-activity';
import 'react-activity/dist/library.css';
import { colors } from '../../../../res/colors.js';
const InfoCardList = ({
  isStat = false,
  infoList,
  showLoadMore,
  onLoadMoreClick,
  loading,
  navTo,
  taskType,
}) => {
  return (
    <div>
      {infoList?.length > 0 ? (
        <>
          {infoList.map((item, i) => (
            <div className="mb-2 mb-lg-3" key={item?.id || i}>
              <InfoCard
                isStat={isStat}
                item={item}
                navTo={navTo}
                taskType={taskType}
              />
            </div>
          ))}
          {showLoadMore ? (
            <>
              {!loading && (
                <div
                  className="text-alt-primary cursor-pointer pb-2"
                  style={{
                    display: 'flex',
                    justifyContent: 'center', // Horizontally center content
                    alignItems: 'center', // Vertically center content
                    width: '100%', // Set the width to 100% if needed
                    // Add any other inline styles you want
                  }}
                  onClick={onLoadMoreClick}>
                  Load More
                </div>
              )}
              {loading && (
                <div
                  className="pb-2"
                  style={{
                    display: 'flex',
                    justifyContent: 'center', // Horizontally center content
                    alignItems: 'center', // Vertically center content
                    width: '100%', // Set the width to 100% if needed
                    // Add any other inline styles you want
                  }}>
                  <Dots color={colors.PRIMARY_COLOR} />
                </div>
              )}
            </>
          ) : null}
        </>
      ) : (
          <>
            {!loading && (
              <div className="profile-top__row">
                <div
                  className="text-r text-alt-primary text-center"
                  style={{ flex: 1 }}>
                  No activity to show
              </div>
              </div>
            )}
          </>
        )}
    </div>
  );
};

export default InfoCardList;
