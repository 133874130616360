import React, { Fragment, useState, useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useBuzzmiAuth } from '../../../../../../appState/hooks/useBuzzmiAuth/index.web';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import ProfileMobileUsersItem from '../../../profileMobileUsers/items/profileMobileUsersItem/ProfileMobileUsersItem';
import { FollowButton } from '../../../profileMobileUsers/items/profileMobileUsersButtons/ProfileMobileUsersButtons';
import { useProfileApp } from '../../../../../../appState/hooks';
import { EventRegister } from 'react-native-event-listeners';
import { Dots } from 'react-activity';
import { colors } from '../../../../../../res/colors';

const SuggestedUsersModal = ({ setOpen, open }) => {
  const { userData } = useBuzzmiAuth();
  const { navigate } = useNavigation();
  const [suggesUsers, setSuggestedUsers] = useState([]);
  const [pageLimit, setPageLimit] = useState(20);
  const { suggestedUsers, postFollowUser, setSuggestedUserLoad, suggestedUserLoad } = useProfileApp();

  useEffect(() => {
    setSuggestedUsers(suggestedUsers)
  }, [suggestedUsers])

  useEffect(() => {
    if (pageLimit > 20) {
      setSuggestedUserLoad(true)

      EventRegister.emit('refresh-suggested-list', {
        page: 1,
        limit: pageLimit,
      });
    }
  }, [pageLimit]);

  const handleValue = event => {
    if (event.target.value == '') {
      setSuggestedUsers(suggestedUsers)
    } else {
      setSuggestedUsers(suggesUsers.filter((data) => data.displayName.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1))
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full">
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                          Suggested for you
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2"
                            onClick={() => setOpen(false)}>
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-3 sm:px-6">
                      <input
                        onChange={handleValue}
                        type="text"
                        className="input input_simple-text rounded-lg mb-4"
                        placeholder={'Search'}
                      />

                      {suggesUsers.length > 0 && (
                        <>
                          <ul className="profile-contacts__list">
                            {suggesUsers.map(item => (
                              <li key={item.id}>
                                <ProfileMobileUsersItem
                                  item={item}
                                  button={
                                    <FollowButton
                                      id={item.id}
                                      onPress={() => {
                                        postFollowUser(item.id, userData?.token)
                                      }}
                                    />
                                  }
                                />
                              </li>
                            ))}
                          </ul>
                          {!suggestedUserLoad &&
                            <div
                              className="mt-4 text-alt-primary cursor-pointer pb-2"
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%'
                              }}
                              onClick={() => { setPageLimit(pageLimit + 20) }}>
                              Load More
                            </div>
                          }
                          {suggestedUserLoad && (
                            <div
                              className="mt-4 pb-2"
                              style={{
                                display: 'flex',
                                justifyContent: 'center', // Horizontally center content
                                alignItems: 'center', // Vertically center content
                                width: '100%', // Set the width to 100% if needed
                                // Add any other inline styles you want
                              }}>
                              <Dots color={colors.PRIMARY_COLOR} />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SuggestedUsersModal;
