import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import {phoneNumbersCode} from './mocData.jsx';
import {DropdownIndicator} from './customSelect/CustomSelect.jsx';
import Form from '../Form.jsx';
import {useNavigation} from '@react-navigation/core';
import Loading from 'react-fullscreen-loading';
import {useToast} from 'react-native-toast-notifications';
import {colors} from '../../../../res/colors.js';
import {POST} from '../../../../api_helper/ApiServices.js';
import {SEND_OTP} from '../../../../api_helper/Api.js';
import {saveData} from '../../../../res/asyncStorageHelper.web.js';
import {ShowError, ShowSuccess} from '../../../../container/HOC/MessageWeb.js';
import {useFormValidator} from '../../../../form-validator/hooks/index.js';
import ValidationSchema from '../../../../form-validator/ValidationSchema.js';
import Strings from '../../../../string_key/Strings.web.js';
import {useEncryptedAtom} from '../../../../appState/hooks/useEncryptedAtom/index.web';
import {_sSecretAtom, _sUuidAtom} from '../../../../appState/userState';

const validationSchema = new ValidationSchema({
  countryCode: [{rule: 'required'}, {rule: 'countryPhoneCode'}],
  phone: [{rule: 'required'}, {rule: 'phone'}],
});

const PhoneSelect = ({setIsValid}) => {
  const {navigate} = useNavigation();

  const toast = useToast();
  const [isLoad, setIsLoad] = useState(false);

  const [selected, setSelected] = useState(null);
  const [focused, setFocused] = useState(false);
  const [sSecret, setSSecret] = useEncryptedAtom(_sSecretAtom);
  const [sUuid, setSUuid] = useEncryptedAtom(_sUuidAtom);

  const {register, handleSubmit, setValue, errors, isValid} = useFormValidator(
    validationSchema,
    async formData => {
      onSubmit(formData);
    },
  );

  useEffect(() => setIsValid && setIsValid(isValid), [isValid]);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const onSubmit = data => {
    setIsLoad(true);
    let postData = {mobile: data?.countryCode + data?.phone, flag: true};
    POST(SEND_OTP, false, null, null, postData, (response, isError) => {
      setIsLoad(false);
      if (isError) {
        setTimeout(() => {
          ShowError(response, toast);
        }, 250);
      } else {
        setSSecret(response?.secret);
        setSUuid(response?.uniqueUUID);
        navigate('ProvidePhoneCode', {
          param: Object.assign(data, {loginType: 'Email'}),
        });

        setTimeout(() => {
          ShowSuccess(response?.message, toast);
        }, 250);
      }
    });
  };

  const handleSelect = e => {
    setSelected(e);
    setValue('countryCode', e.value);
  };

  return (
    <Form id="phone-code" onSubmit={handleSubmit}>
      <Loading
        loading={isLoad}
        background="transparent"
        loaderColor={colors.PRIMARY_COLOR}
      />
      <div
        className={`phone-select ${focused ? 'phone-select_active' : ''} ${
          errors.countryCode || errors.phone ? 'phone-select_error' : ''
        }`}
        onClick={handleFocus}
        onBlur={handleBlur}>
        <Select
          components={{DropdownIndicator}}
          options={phoneNumbersCode}
          value={selected}
          onChange={handleSelect}
          hasSelectAll={false}
          labelledBy="Select"
          className={`phone-select__select`}
          disableSearch={true}
          classNamePrefix="phone-select"
        />
        <input
          className="phone-select__input"
          placeholder={Strings.phone_number}
          type="text"
          {...register('phone')}
        />
      </div>
      <div className="mt-2">
        {errors.countryCode && (
          <div className={'text-error text-center'}>
            {errors.countryCode[0]}
          </div>
        )}
        {errors.phone && (
          <div className={'text-error text-center'}>{errors.phone[0]}</div>
        )}
      </div>
    </Form>
  );
};

export default PhoneSelect;
