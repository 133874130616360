import React from "react"
import ProfileMobileUsersItem from "./profileMobileUsersItem/ProfileMobileUsersItem"
import { SubscribeButton } from "./profileMobileUsersButtons/ProfileMobileUsersButtons"

const ProfileMobileUsersSubscribes = ({ data }) => {
  return (
    <>
      <ul className="profile-contacts__list">
        {data.users.map((item) => (
          <li key={item.id}>
            <ProfileMobileUsersItem item={item} button={<SubscribeButton id={item.id} initState={item?.state} />} />
          </li>
        ))}
      </ul>
    </>
  )
}

export default ProfileMobileUsersSubscribes
