import React, { useEffect, useState } from 'react';
import { useHubApp } from '../../../../../appState/hooks/useHubApp/index.web.js';
import { useBuzzmiAuth } from '../../../../../appState/hooks/useBuzzmiAuth/index.web.js';
import Box from '../../../../../components/reusable/cards/box/Box.jsx';
import { useLinkTo } from '@react-navigation/native';
import { useGetRechargeAmounts } from '../../../../../appState/hooks/useGetRechargeAmounts/index.js';

const MyWallet = props => {
  const { getRechageAmounts, rechageAmounts } = useGetRechargeAmounts();
  const { userData } = useBuzzmiAuth();

  useEffect(() => {
    getRechageAmounts(userData?.token, () => { }, { type: 'Gems' });
  }, []);


  const walletBalance = [
    {
      id: 1,
      title: 'Buzzcoins',
      img: '/images/buzz_coin.png',
    },
    {
      id: 2,
      title: 'Gems',
      img: '/images/gems.png',
    },
    {
      id: 3,
      title: 'Diamonds',
      img: '/images/diamonds.png',
    },
  ];

  const rechargeAmount = [
    {
      id: 1,
      coin: 42,
      amount: '3.69 USD',
      img: '/images/frame_1.png',
      is_current: true,
    },
    {
      id: 2,
      coin: 210,
      amount: '17.98 USD',
      img: '/images/frame_2.png',
      is_current: false,
    },
    {
      id: 3,
      coin: 682,
      amount: '54.99 USD',
      img: '/images/frame_3.png',
      is_current: false,
    },
  ];

  const Earning = [
    {
      title: 'Personal Earnings',
      description: [
        {
          id: 1,
          coin_name: 'Buzzcoins',
          coin: '20,900',
          amount: 0,
          image: '/images/buzz_coin.png',
        },
        {
          id: 2,
          coin_name: 'Diamonds',
          coin: '20,900',
          amount: 400,
          image: '/images/diamonds.png',
        },
      ],
    },
    {
      title: 'Indirect Invite Earnings',
      description: [
        {
          id: 1,
          coin_name: 'Buzzcoins',
          coin: '20,900',
          amount: 0,
          image: '/images/buzz_coin.png',
        },
        {
          id: 2,
          coin_name: 'Diamonds',
          coin: '20,900',
          amount: 400,
          image: '/images/diamonds.png',
        },
      ],
    },
    {
      title: 'Invite Earnings',
      description: [
        {
          id: 1,
          coin_name: 'Buzzcoins',
          coin: '20,900',
          amount: 0,
          image: '/images/buzz_coin.png',
        },
        {
          id: 2,
          coin_name: 'Diamonds',
          coin: '20,900',
          amount: 400,
          image: '/images/diamonds.png',
        },
      ],
    },
    {
      title: 'Indirect Invite Earnings',
      description: [
        {
          id: 1,
          coin_name: 'Buzzcoins',
          coin: '20,900',
          amount: 0,
          image: '/images/buzz_coin.png',
        },
        {
          id: 2,
          coin_name: 'Diamonds',
          coin: '20,900',
          amount: 0,
          image: '/images/diamonds.png',
        },
      ],
    },
    {
      title: 'Badge Earnings',
      description: [
        {
          id: 1,
          coin_name: 'Badge Collected',
          coin: '10',
          amount: 0,
          image: '/images/gems.png',
        },
      ],
    },
    {
      title: 'Total Earnings',
      description: [
        {
          id: 1,
          coin_name: 'Buzzcoins',
          coin: '1,10,000',
          amount: 0,
          image: '/images/buzz_coin.png',
        },
        {
          id: 2,
          coin_name: 'Diamonds',
          coin: '10,000',
          amount: 2000,
          image: '/images/diamonds.png',
        },
      ],
    },
  ];

  const { walletInfo } = useHubApp();
  const linkTo = useLinkTo();

  const getWalletCoinByName = coin => {
    const filtered = walletInfo.filter(x => x.hasOwnProperty(coin));
    if (filtered.length > 0) {
      return filtered[0][coin]?.total;
    }
    return 0;
  };

  return (
    <>
      <div className={"sm:hidden"}>
        <h5 className="text-sm font-bold text-center my-4">Wallet Balance</h5>

        <Box className={'py-4 px-8 flex justify-between'}>
          {walletBalance.map((item, index) => (
            <>
              <div
                className="text-center"
                key={index}
                onClick={() => {
                  index == 1
                    ? linkTo('/hub/v2/purchase-wallet')
                    : linkTo(
                      `/hub/v2/wallet-detail/${index == 0 ? 'Buzzcoin' : 'Diamond'
                      }`,
                    );
                }}>
                <div>
                  <h5 className="font-sm font-bold">{item?.title}</h5>
                </div>
                <div className="flex justify-center mt-2">
                  <p>
                    {index == 0
                      ? getWalletCoinByName('buzzcoins')
                      : index == 1
                        ? getWalletCoinByName('gems')
                        : getWalletCoinByName('diamonds')}
                  </p>
                  <img src={item?.img} className="w-6 h-6 ms-1" />
                </div>
              </div>
              {index != 2 && <div className={'w-[2px] bg-gray-100'} />}
            </>
          ))}
        </Box>
      </div>

      <div>
        <h5 className="text-sm sm:text-[20px] font-bold text-center mt-6 mb-4">
          Recharge Amount
        </h5>

        {rechageAmounts.length > 0 && (
          <div>
            <div className="grid grid-cols-3 gap-2">
              {rechageAmounts.map((item, index) => (
                <Box
                  key={index}
                  className={`px-2.5 sm:w-[203px] w-[110px]  rounded-md border-[2px]`}
                  style={{
                    borderColor: item?.is_current ? '#EECCFF' : '#EB4DAC',
                  }}>
                  <div className="relative">
                    <div className="relative top-[-8px] sm:top[-10px] flex justify-center">
                      <img
                        src={rechargeAmount[index]?.img}
                        className="w-8 sm:w-[75px] sm:h-[65px] h-8 ms-1"
                      />
                    </div>
                    <div className="mt-2 mb-4">
                      <h3 className="text-lg sm:text-[30px] font-bold text-center">
                        {item?.quantity}
                      </h3>
                    </div>

                    <div className="text-center w-[65px] sm:w-[119px] m-auto">
                      <h5
                        className="relative bottom-[-10px] text-[10px] sm:text-[17px] py-2"
                        style={{
                          borderTopRightRadius: 10,
                          borderTopLeftRadius: 10,
                          backgroundColor: '#ECF',
                        }}>
                        {item?.price} USD
                      </h5>
                    </div>
                  </div>
                </Box>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* Data is static */}

      {/* {Earning.map((item, index) => (
        <div>
          <h5 className="text-sm font-bold text-center mt-6 mb-2">
            {item?.title}
          </h5>

          <div>
            <Box className={'p-4'}>
              {item?.description.map((_item, index) => (
                <>
                  <div className="flex justify-between items-center">
                    <div>
                      <h5 className="text-xs sm:text-[14px]">{_item?.coin_name}</h5>
                    </div>
                    <div className="flex">
                      <div>
                        <h5 className="text-sm sm:text-[16px] font-bold">{_item?.coin}</h5>
                        {_item.amount > 0 && (
                          <p className="text-[10px] font-normal">
                            {_item.amount} USD
                          </p>
                        )}
                      </div>
                      <img src={_item?.image} class="w-6 sm:w-[30px] h-6 sm:h-[30px] ml-2"></img>
                    </div>
                  </div>
                  {index < item?.description?.length - 1 && (
                    <div className="border border-solid my-4"></div>
                  )}
                </>
              ))}
            </Box>
          </div>
        </div>
      ))} */}
    </>
  );
};
export default MyWallet;
