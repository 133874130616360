export const reviewsPremiumData = [
  {
    id: 1,
    author: {
      nickname: "alexandra",
      name: "Alexandra Johnson",
      position: "Elite creator",
      isVerified: true,
      avatar: {
        src: "/images/post/mock/matilda.jpg",
      },
    },
    date: new Date("2023-07-22"),
    rating: 5,
    text: "I recently completed an online sport course and I must say, it exceeded all my expectations. The course was well-structured and covered a wide range of sports - related topics. The instructor were knowledgeable and engaging, making the learning experience enjoyable.",
    replies: [
      {
        id: 1,
        author: {
          nickname: "janedoe",
          name: "John Doe",
          position: "Elite creator",
          isVerified: true,
          avatar: {
            src: "/images/post/mock/jane.jpg",
          },
        },
        text: "Thank you!",
        date: new Date("2023-07-23"),
      },
    ],
  },
  {
    id: 2,
    author: {
      nickname: "max",
      name: "Max Johnson",
      position: "Elite creator",
      isVerified: true,
      avatar: {
        src: "/images/post/mock/jarvis.jpg",
      },
    },
    date: new Date("2023-08-09"),
    rating: 4,
    text: "I recently completed an online sport course and I must say, it exceeded all my expectations. The course was well-structured and covered a wide range of sports - related topics. The instructor were knowledgeable and engaging, making the learning experience enjoyable.",
    replies: null,
  },
]
