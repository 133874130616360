import React from "react"
import { Link } from "react-router-dom"

const ProfilePremiumContentCoach = ({ author }) => {
  const handleChat = () => {
    console.log("chat")
  }

  return (
    <div className="profile-content__coach">
      <div className="d-flex align-items-center align-self-stretch">
        <div className="profile-content__coach-avatar">
          <img src={author.avatar.src} alt={author.avatar.alt} />
        </div>
        <div>
          <div className="profile-content__coach-name">{author.name}</div>
          <div className="profile-content__coach-nickname">@{author.nickname}</div>
          <div className="profile-content__coach-profession">{author.profession}</div>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <Link to={`profile/user/${author.id}`}>
            <img src="/images/assets/info-card/arrow-r.svg" alt="arrow-right" />
          </Link>
        </div>
      </div>
      <div className="profile-content__chat">
        <button onClick={handleChat}>
          <img src="/images/assets/chat.svg" alt="chat" />
          <span>Personal chats</span>
        </button>
      </div>
    </div>
  )
}

export default ProfilePremiumContentCoach
