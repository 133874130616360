import React, { useEffect } from "react"
import ResizeComponent from "../../../../components/reusable/assets/resizeComponent/ResizeComponent"
import ProfileUserDesktop from "./profileUserDesktop/ProfileUserDesktop"
import ProfileUserMobile from "./profileUserMobile/ProfileUserMobile"
import { useProfileInfo } from "../../../../appState/hooks/useProfileInfo"
import { useBuzzmiAuth } from "../../../../appState/hooks/useBuzzmiAuth/index.web"
import MainLayoutV2 from "../../../../components/layouts/mainLayout/MainLayoutV2"
import MainLayoutContentV2 from "../../../../components/layouts/mainLayout/mainLayoutContentV2/MainLayoutContentV2"
import Loading from 'react-fullscreen-loading';
import { colors } from "../../../../res/colors"
import { useProfileApp } from "../../../../appState/hooks"
import { useUserRatings } from "../../../../appState/hooks/useUserRatings"

const ProfileUser = (props) => {
  const { id } = props.route.params;
  const { userData } = useBuzzmiAuth();
  const { getProfileInfoDetail, profileInfoLoad, } = useProfileInfo();
  const { getOnUserRatings } = useUserRatings();
  const { setVisitorPosts, setVisitorReels } = useProfileApp();

  useEffect(() => {
    getProfileInfoDetail(id, userData?.token)
    getOnUserRatings(id, userData?.token)
  }, [id, userData])

  useEffect(() => {
    return () => {
      setVisitorPosts([])
      setVisitorReels([])
    }
  }, [])

  return (
    <MainLayoutV2 centerSpace={true} hideFooter={true}>
      <MainLayoutContentV2 iscenter rightColumnContent={<></>}>
        <ResizeComponent desktopComponent={<ProfileUserDesktop isOtherProfile />} mobileComponent={<ProfileUserMobile isOtherProfile />} />
      </MainLayoutContentV2>
      <Loading
        loading={profileInfoLoad}
        background="transparent"
        loaderColor={colors.PRIMARY_COLOR}
      />
    </MainLayoutV2>
  )
}

export default ProfileUser
