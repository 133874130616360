import React, {useEffect, useState} from 'react';
import PrimaryButton from '../../../../components/reusable/form/items/buttons/PrimaryButton.jsx';
import {HardCode} from '../../../../components/reusable/assets/showMore/ShowMore.jsx';
import BaseLayout from '../../../../components/layouts/baseLayout/BaseLayout';
import Timer from '../../../../components/reusable/assets/timer/Timer.jsx';
import SubscriptionCard from './items/SubscriptionCard.jsx';
import {Link} from 'react-router-dom';
import {useNavigation} from '@react-navigation/native';
import Strings from '../../../../string_key/Strings.web.js';
import useMemberships from '../../../App/MembershipScreen/hooks/useMemberships';
import {Membership} from '../../../App/MembershipScreen/types';
import {POST} from '../../../../api_helper/ApiServices';
import {POST_ORDER} from '../../../../api_helper/Api';
import {useBuzzmiAuth} from '../../../../appState/hooks';
import {useEncryptedAtom} from '../../../../appState/hooks/useEncryptedAtom';
import {
  _sessionTokenAtom,
  _userDetailsAtom,
  userDataAtom,
} from '../../../../appState/userState';
import {fetchUserData} from '../../../../network/api/auth';
import {useAtom} from 'jotai';
import Loading from 'react-fullscreen-loading';
import {colors} from '../../../../res/colors.js';

const MembershipV2 = props => {
  const {navigate} = useNavigation();
  const [loading, setLoading] = useState(false);
  const {memberships} = useMemberships(setLoading);
  // const {userData} = useBuzzmiAuth();
  const [sessiontoken, setSessionToken] = useEncryptedAtom(_sessionTokenAtom);

  const [userData, setLoginSuccess] = useAtom(userDataAtom);
  const [userDetails, setUserDetails] = useEncryptedAtom(_userDetailsAtom);

  const updateUserData = async () => {
    try {
      const response = await fetchUserData(sessiontoken);
      setLoginSuccess(prev => {
        return {
          ...prev,
          membership: response.membership,
        };
      });
      setUserDetails(prev => {
        return {
          ...prev,
          membership: response.membership,
        };
      });
    } catch (e) {
      console.log('fetchUserData ==> ', e.message);
    }
  };

  useEffect(() => {
    // updateUserData().then();
    if (typeof props?.route?.params?.paymentSuccess === 'string') {
      if (props?.route?.params?.paymentSuccess === 'true') {
        updateUserData().then(() => navigate('FinishRegister'));
      } else {
        window.alert('Payment failed. Please try again.');
      }
    }
  }, [props?.route?.params]);

  const handleSubscribeEvent = product_id => {
    // setLoading(() => true);

    navigate('Subscription', {id: product_id, token: sessiontoken});
    // POST(
    //   POST_ORDER,
    //   true,
    //   sessiontoken,
    //   '',
    //   {
    //     product_id: product_id,
    //   },
    //   (res, e) => {
    //     // setLoading(() => false);
    //     // setUpdating(() => false);
    //     if (!e) {
    //       console.log('res ===> ', res);
    //       window.location.replace(res?.payment_link);
    //       // navigate('StripePaymentScreen', {
    //       //   siteLink: res?.payment_link,
    //       //   orderId: res?.order?.order_id,
    //       //   productId: product_id,
    //       //   // membership: selected?.data,
    //       //   isMembershipFlow: true,
    //       // });
    //     } else {
    //       console.log('PATCH_UPDATE_PROFILE (error)2 => ', res);
    //     }
    //   },
    // );
  };

  return (
    <BaseLayout>
      <Loading
        loading={loading}
        background="transparent"
        loaderColor={colors.PRIMARY_COLOR}
      />
      <div className="subscription">
        <div className="h5">{Strings.one_subscription_endless_possblities}</div>
        <div className="text-r">
          {
            Strings.unlimited_access_to_100s_of_creators_and_all_premium_features
          }
        </div>
        <div className="subscription__img">
          <img src="/images/sale/girl.png" alt="img" />
          <div className="subscription__counter-circle circle-counter">
            <div className="circle-counter__top">+1K</div>
            <div className="circle-counter__bottom">creators</div>
          </div>
        </div>
        <div className="h6">{Strings.get_more_of_the_app_and}</div>
        <div className="h2">{Strings.save_40}</div>
        <div className="text-alt-primary subscription__timer">
          <Timer eventDate={new Date('2023-03-22T12:00:00')} />
        </div>
        <div className="test-s text-secondary ">
          {Strings.your_first_7_days_free_no_commitment_cancel_anytime}
        </div>
        <div className="subscription__promotion">
          <div className="subscription__cards">
            {memberships.map((m: Membership) => (
              <SubscriptionCard
                title={m.title}
                text={m.description}
                discount={m.discount}
                // text={Strings.elte_creator_description}
                icon={m.membershipBadge || '/images/sale/star.png'}
                content={
                  <HardCode
                    benefits={m.benifits}
                    onPressSubscribe={() => handleSubscribeEvent(m?.product_id)}
                  />
                }
              />
            ))}
            {/* <SubscriptionCard
              title="Elite Creator"
              discount={20}
              icon={'/images/sale/star.png'}
              text={Strings.elte_creator_description}
              content={<HardCode />}
            />
            <SubscriptionCard
              title="Premium Member"
              discount={40}
              icon={'/images/sale/target.png'}
              text={Strings.premium_description}
              content={<HardCode />}
              specialOffer="And Special offer"
            />*/}
          </div>
        </div>

        <div className="subscription__btn">
          <PrimaryButton onClick={() => navigate('Pay')}>
            {Strings.start_my_7_days_trial}
          </PrimaryButton>
        </div>
        <button
          onClick={() => navigate('SubscriptionV2')}
          className="text-secondary  text-uppercase h6 text-r">
          {Strings.skip_trial}
        </button>
      </div>
    </BaseLayout>
  );
};

export default MembershipV2;
