import React, { useState, useRef, useEffect } from 'react';
import EditProfileBoxForm from '../EditProfileBoxForm';
import Input from '../../../../../../components/reusable/form/items/inputs/Input';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../../../../../store/slices/modalSlice/modalSlice';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useBuzzmiAuth } from '../../../../../../appState/hooks/useBuzzmiAuth/index.web';

const EditProfileInformation = ({ values, register, setValue, paddingMode }) => {
  const { props, name } = useSelector(state => state.modal.parentComponent);
  const dispatch = useDispatch();
  const { userData } = useBuzzmiAuth();

  const [highlightedText, setHighlightedText] = useState({
    value: null,
    index: 0,
    length: 0,
  });
  const [maxWidth, setMaxWidth] = useState(0);
  const textRef = useRef(null);

  const handlerAddLink = () => {
    dispatch(
      openModal({
        modalName: 'AddLinksToBio',
        modalType: 'swipe',
        callback: Links => {
          setValue('bioLink', Links);
        },
      }),
    );
  };

  const handleChangeSelection = (range, source, editor) => {
    if (range === null) {
      return;
    }

    let highlightedText = editor.getText(range);

    setHighlightedText({
      value: highlightedText,
      index: range.index,
      length: range.length,
    });
  };

  useEffect(() => {
    // console.log("userData?.tagLine");
    // console.log(userData?.tagLine);
    setValue('full-name', userData?.firstName + ' ' + userData?.lastName);
    setValue('user-name', userData?.displayName);
    setValue('bio', userData?.bio);
    setValue('bioLink', userData?.bioLink);
    setValue('tagLine', userData?.tagLine);
  }, [userData]);

  useEffect(() => {
    if (name === 'addLink' && !!props) {
      const { link } = props;
      const { index, length, value } = highlightedText;

      const indexes = [];
      let i = 0;
      while (i !== -1) {
        i = values.bio.indexOf(value, i);
        if (i !== -1) {
          indexes.push(i);
          i++;
        }
      }

      const closestIndex = indexes.reduce((prev, curr) => {
        return Math.abs(curr - index) < Math.abs(prev - index) ? curr : prev;
      });

      const wrappedLink = `<a href="${link}" target="_blank">${value}</a>`;
      const newBio =
        values.bio.slice(0, closestIndex) +
        wrappedLink +
        values.bio.slice(closestIndex + length);
      setValue('bio', newBio);
    }
  }, [name, props]);

  useEffect(() => {
    if (textRef.current && textRef?.current?.clientWidth > 0) {
      setMaxWidth(textRef.current.clientWidth + 'px');
    } else {
      setMaxWidth('100%');
    }
  }, [textRef.current]);

  const handleChange = value => {
    setValue('bio', value);
  };

  return (
    <EditProfileBoxForm
      title="Profile information"
      className="text-center"
      paddingMode={paddingMode}>
      <Input
        label="Your Full Name"
        name="full-name"
        placeholder="Write your full name"
        register={register}
      />
      <Input
        label="Display Name"
        name="user-name"
        placeholder="Create your display name"
        register={register}
      />
      <Input
        label="Your TagLine"
        name="tagLine"
        placeholder="Write your tag line"
        register={register}
      />
      <div>
        <div className="input-label">
          <div className="label-text">Your bio</div>
        </div>
        <div ref={textRef} style={{ maxWidth }}>
          <ReactQuill
            theme="snow"
            value={values?.bio || ''}
            className="profile-form__bio link-wrqapper"
            onChange={handleChange}
            modules={{
              toolbar: [[]],
            }}
            onChangeSelection={handleChangeSelection}
          />
        </div>
      </div>
      <button
        type={'button'}
        onClick={handlerAddLink}
        className="profile-form__add-link mt-md-21 mt-3 text-alt-primary">
        Add Link
      </button>
    </EditProfileBoxForm>
  );
};

export default EditProfileInformation;
