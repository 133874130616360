import React, { useEffect, useState } from 'react';
import { useBuzzmiAuth } from '../../../../../../../appState/hooks/useBuzzmiAuth/index.web';
import { useRankInfo } from '../../../../../../../appState/hooks/useRankInfo';
import SimpleShowMore from '../../../../../../../components/reusable/assets/showMore/simpleShowMore/SimpleShowMore';
import Verified from '../../../../../../../components/reusable/bio/verified/Verified';
import CustomRating from '../../../../../../../components/reusable/assets/customRating/CustomRating';
import cn from 'classnames';
import { TrophyIcon } from 'lucide-react';
import BadgesModal from '../../../componentsV2/BadgesModal';
import { ChevronDoubleRightIcon } from '@heroicons/react/24/outline';
import { useLinkTo } from '@react-navigation/native';
import { useUserRatings } from '../../../../../../../appState/hooks/useUserRatings';

const ProfileInfoMobileV3 = ({ showAchievement = false }) => {
  const { userData } = useBuzzmiAuth();
  const { rankInfo } = useRankInfo();
  const [openBadgesModal, setOpenBadgesModal] = useState(false);
  const { userRating } = useUserRatings();
  const [ProfileRattings, setProfileRating] = useState(null)

  const linkTo = useLinkTo();

  useEffect(() => {
    setProfileRating(userRating?.averageRating ? (userRating?.averageRating / userRating?.totalReviews) : 0)
  }, [userRating])

  return (

    <div className="ml-3 mt-3">
      <div className="plan-bio__info profile-top__bio gap-0">
        <div className="h3">
          <div className="profile_name">
            <div className="user_title text-base font-semibold">
              {userData && userData?.firstName + ' ' + userData?.lastName}
            </div>
            <Verified isVerified={userData?.isVerified} />
            {userData && userData?.tagLine != null && <div className=" line-clamp-1 user_title text-base font-semibold">
              {"| " + userData?.tagLine}
            </div>
            }
          </div>
        </div>
        <div className={"flex items-center justify-between w-full"}>


          <div className={"flex flex-col mr-4"}>


            {rankInfo?.currentRank &&
              <div className="profile-achievement-icon my-[8px]">
                <img className={"!w-8 !h-8"} src={rankInfo?.currentRankBadge} alt={rankInfo?.currentRankBadge} />
                <div className={"text-lg font-medium"}>{rankInfo?.currentRank}</div>
              </div>
            }
          </div>
          <div className={"border-[#c6cbcf] border-l-2 flex flex-1 justify-center items-center"} onClick={() => { setOpenBadgesModal(!openBadgesModal) }}>
            <TrophyIcon className={"w-8 h-8 text-[#EB4DAC]"} />
            <p className={"mx-3 text-lg"}>3</p>
            <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" style={{ color: '#da31b1' }} />

          </div>
        </div>

        <div className="profile-top__row">
          <span className="text-base">
            @{userData && userData?.displayName}
          </span>
          <div onClick={() => { linkTo(`/profile/user-ratting/user`) }} className="d-flex align-items-center flex-direction-column">
            <CustomRating initialValue={ProfileRattings} readonly />
            <span
              className={cn('text-r ms-2 mt-1', {
                'text-secondary': ProfileRattings === 0,
              })}>
              {userRating?.totalReviews || 0} reviews
           </span>
          </div>
        </div>

        {userData?.bio != null &&
          <div className="profile-user__info-block mt-2 mt-md-4">
            <SimpleShowMore
              content={userData?.bio || ''}
              lineClamp={2}
              sizes={{
                desktopHeight: 40,
                mobileHeight: 40,
              }}
            />
          </div>
        }
      </div>

      <BadgesModal open={openBadgesModal} setOpen={setOpenBadgesModal} />
    </div>
  );
};

export default ProfileInfoMobileV3;