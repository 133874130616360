import { useMutation } from 'react-query';
import { patchPlannedTasksComplete } from '../../../network/api/app';
import { useBuzzmiAuth } from '../useBuzzmiAuth/index.web';
import { ShowError, ShowSuccess } from '../../../container/HOC/MessageWeb';
import { useToast } from 'react-native-toast-notifications';

import { useState } from 'react';
import { useHubApp } from '../useHubApp/index.web';

export const useCompletePlannedTasks = () => {
  const { userData } = useBuzzmiAuth();
  const [loadingList, setLoadingList] = useState(false);

  const toast = useToast();
  const {
    getTodayTasks,
    getPlannedActivities,
  } = useHubApp();
  const [activityComplete, setActivityComplete] = useState(false);
  const {
    mutate: mutateCompletePlannedTasks,
    isLoading: isCompletePlannedTasksLoading,
    isError: isCompletePlannedTasksError,
    data: CompletePlannedTasksResponse,
    error: CompletePlannedTasksErrorResponse,
  } = useMutation({
    mutationFn: param => {
      console.log('param?.body', param);
      if (param?.body?.isCompleted) setActivityComplete(true);
      return patchPlannedTasksComplete(param.id)({
        params: { isAuth: true, token: userData?.token },
        body: { ...param?.body },
      });
    },
    onSuccess: data => {
      // EventRegister.emit('refresh-planActivities');
      getPlannedActivities(
        userData?.token,
        setLoadingList,
        {
          page: '1',
          limit: '10',
        }
      );

      getTodayTasks(userData?.token, setLoadingList, {
        page: '1',
        limit: '10',
      });
      ShowSuccess(
        `Task ${activityComplete ? 'completed' : 'updated'} successfully`,
        toast,
      );
    },
    onError: data => {
      console.log('edited quick error ==> ', data);
      ShowError('Activity completed update failed', toast);
    },
  });

  const patchCompletePlannedTasks = async (id, body) =>
    mutateCompletePlannedTasks({ id, body });

  return {
    isCompletePlannedTasksLoading,
    isCompletePlannedTasksError,
    CompletePlannedTasksResponse,
    CompletePlannedTasksErrorResponse,
    patchCompletePlannedTasks,
  };
};
