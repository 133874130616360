import React, {useEffect, useState} from 'react';
import LikeIcon from '../../icons/LikeIcon';
import {useProfileApp} from '../../../../../../appState/hooks';
import {useBuzzmiAuth} from '../../../../../../appState/hooks/useBuzzmiAuth/index.web';

const LikesReelBtn = ({likes, id, isFromPost, likesList}) => {
  const [likesCount, setLikesCount] = useState(null);
  const [isLiked, setIsLiked] = useState(false);
  const [hovered, setHovered] = useState(false);
  const {postLikePost, postDislikePost} = useProfileApp();
  const {userData} = useBuzzmiAuth();
  useEffect(() => {
    setLikesCount(Number(likes));
  }, [likes]);
  useEffect(() => {
    setIsLiked(
      likesList?.length > 0 &&
        likesList?.[0].participants?.nodes?.length &&
        likesList?.[0].participants.nodes.filter(
          o => o?.participant?.id === userData?.bettermodeUserId,
        ).length > 0,
    );
  }, [likesList]);
  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleClick = () => {
    if (isLiked) {
      console.log('like', id);

      postDislikePost(id, userData.token);
    } else {
      console.log('like', id);
      postLikePost(id, userData.token);
    }

    setIsLiked(prevIsLiked => !prevIsLiked);
    setHovered(false);
    setLikesCount(prevLikes => prevLikes + (isLiked ? -1 : 1));
  };

  return (
    <>
      <button
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="reels__buttons-list-button">
        <LikeIcon isHovered={isLiked || hovered} />
      </button>
      <p
        className="reels__buttons-list-text"
        style={{color: isFromPost ? '#000' : '#fff'}}>
        {likesCount}
      </p>
    </>
  );
};

export default LikesReelBtn;
