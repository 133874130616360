import {useEffect, useRef, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {FreeMode, Navigation, Thumbs} from 'swiper/modules';
import 'swiper/css';
import {isImage, isVideo} from '../../../types/media';
import cn from 'classnames';

const GalleryCarousel = ({
  list,
  overlineText,
  overlineDescription = null,
  goToActiveSlide,
  onSwiperChange,
}) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const carouselNav = useRef(null);

  const carouselPrevBtn = useRef(null);
  const carouselNextBtn = useRef(null);
  const [activeThumb, setActiveThumb] = useState(0);

  const handleThumbClick = index => {
    thumbsSwiper.slideTo(index);
  };
  useEffect(() => {
    if (carouselNav && carouselNav.current) {
      setThumbsSwiper(carouselNav.current);
    }
  }, [carouselNav]);

  const _renderListSliderLayout = () => {
    return (
      <Swiper
        ref={carouselNav}
        onSwiper={setThumbsSwiper}
        spaceBetween={0}
        slidesPerView={4}
        watchSlidesProgress={true}
        freeMode={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper">
        {list.map((el, index) => {
          return (
            <SwiperSlide key={index}>
              {({isActive}) => (
                <div
                  onClick={() => {
                    // handleThumbClick(index);
                    setActiveThumb(index);
                  }}
                  className={cn('gallery__item', {
                    'border-2 border-fuchsia-500': activeThumb == index,
                  })}>
                  {isImage(el) && (
                    <img src={el} alt={el.description || 'description'} />
                  )}
                  {/* {isVideo(el) && <video src={el} className="flex-1"></video>} */}
                  {isVideo(el) && (
                    // Display video thumbnail with play icon
                    <div className="video-thumbnail-container h-100 z-[2]">
                      <video src={el}></video>
                      {/* <div className="play-icon absolute bottom-0 left-2 text-4xl">
                        ▶
                      </div> */}
                      {/* <img
                        src="/images/ic_youtube.png"
                        className="shadow-md play-icon absolute self-center bottom-[15%] left-[20%] text-4xl z-[2]"
                        style={{
                          maxWidth: '60%',
                          height: 'auto',
                        }}></img> */}
                    </div>
                  )}
                </div>
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>
    );
  };

  return (
    <div className="gallery__carousel">
      {/*{overlineText && <span className="gallery__carousel-overline">{overlineText}</span>}*/}
      {/*{overlineDescription && <p className="h6 text-secondary text-center mb-3">{overlineDescription}</p>}*/}

      {thumbsSwiper && (
        <div className="gallery__carousel-body">
          <Swiper
            initialSlide={goToActiveSlide}
            style={{
              '--swiper-navigation-color': '#fff',
              '--swiper-pagination-color': '#fff',
            }}
            navigation={{
              prevEl: carouselPrevBtn.current,
              nextEl: carouselNextBtn.current,
            }}
            spaceBetween={10}
            slidesPerView={1}
            thumbs={{swiper: carouselNav.current}}
            modules={[Navigation, Thumbs]}
            className="mySwiper2"
            onSlideChange={e => {
              setActiveThumb(e.activeIndex);
              onSwiperChange(e);
            }}
            onSwiper={onSwiperChange}
            followFinger={true}
            momentumbounceratio={0.5}>
            {list.map((el, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="gallery__carousel-item">
                    {isImage(el) && (
                      <img
                        key={index}
                        src={el}
                        alt={el.description || 'description'}
                      />
                    )}
                    {isVideo(el) && (
                      <video
                        key={index}
                        src={el}
                        controls
                        className="flex-1"></video>
                    )}
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <button
            className="gallery__btn gallery__btn--prev"
            ref={carouselPrevBtn}>
            <img src="/images/assets/back.svg" alt="arrow icon" />
          </button>
          <button
            className="gallery__btn gallery__btn--next"
            ref={carouselNextBtn}>
            <img src="/images/assets/back.svg" alt="arrow icon" />
          </button>
        </div>
      )}
      {/* <Swiper
        ref={carouselNav}
        initialSlide={goToActiveSlide}
        onSwiper={setThumbsSwiper}
        spaceBetween={0}
        slidesPerView={4}
        watchSlidesProgress={true}
        // loop={true}
        freeMode={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper gallery__carousel-nav">
        {list.map((el, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="gallery__item">
                {isImage(el) && (
                  <img src={el} alt={el.description || 'description'} />
                )}
                 {isVideo(el) && <video src={el} className="flex-1"></video>} 
                {isVideo(el) && (
                  // Display video thumbnail with play icon
                  <div className="video-thumbnail-container h-100 z-[2]">
                    <video src={el}></video>
                    <div className="play-icon absolute bottom-0 left-2 text-4xl">
                      ▶
                    </div>
                  </div>
                )}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper> */}

      {_renderListSliderLayout()}
    </div>
  );
};

export default GalleryCarousel;
