import React, { useEffect, useState } from 'react';
import { useBuzzmiAuth } from '../../../../appState/hooks/useBuzzmiAuth/index.web';
import { useGetWalletHistory } from '../../../../appState/hooks/useGetWalletHistory';
import { useHubApp } from '../../../../appState/hooks/useHubApp/index.web';
import moment from 'moment';
import DetailHeader from './DetailHeader';
import Loading from 'react-fullscreen-loading';
import { colors } from '../../../../res/colors';

const WalletDetail = props => {
    const { type } = props?.route?.params || props;
    const { userData } = useBuzzmiAuth();
    const { walletInfo } = useHubApp();
    const [pageLimit, setPageLimit] = useState(10);
    const [loader, setLoader] = useState(false);


    const getWalletCoinByName = coin => {
        const filtered = walletInfo.filter(x => x.hasOwnProperty(coin));
        if (filtered.length > 0) {
            return filtered[0][coin]?.total;
        }
        return 0;
    };

    const { getrWalletHistory, walletHistory, clearWalletHistory } = useGetWalletHistory();

    useEffect(() => {
        console.log("walletHistory")
        console.log(walletHistory)
    }, [walletHistory])

    useEffect(() => {
        getrWalletHistory(
            userData?.token,
            (data) => { setLoader(data) },
            {
                page: 1,
                limit: pageLimit,
                walletType: type == "Buzzcoin" ? "Buzzcoins" : "Gifted Diamonds"
            }
        );
        return () => {
            clearWalletHistory()
        }
    }, [pageLimit])

    const getDisplayDate = (year, month, day) => {
        let today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);
        let compDate = new Date(year, month, day); // month - 1 because January == 0
        let diff = today.getTime() - compDate.getTime(); // get the difference between today(at 00:00:00) and the date
        if (compDate.getTime() == today.getTime()) {
            return "Today";
        } else if (diff <= (24 * 60 * 60 * 1000)) {
            return "Yesterday";
        } else {
            //return compDate.toDateString(); // or format it what ever way you want
            let year = compDate.getFullYear();
            let month = compDate.getMonth();
            let months = new Array('Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec');
            let day = compDate.getDate();
            let d = compDate.getDay();
            let days = new Array('Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat');

            var formattedDate = days[d] + " " + day;
            return formattedDate;
        }
    }
    return (
        <>
            <DetailHeader
                rightImage={type == "Buzzcoin" ? "/images/buzz_vector.png" : "/images/diamonds_vector.png"}
                title={type == "Buzzcoin" ? "Buzz Coins Wallet" : "Diamonds Wallet"}
                coin={type == "Buzzcoin" ? getWalletCoinByName('buzzcoins') : getWalletCoinByName('diamonds')}
                amount={0}
            />

            <Loading
                loading={loader}
                background="transparent"
                loaderColor={colors.PRIMARY_COLOR}
            />

            <div>
                <h5 className='text-sm font-bold text-center mt-6 mb-2'>Transaction History</h5>
                {walletHistory?.length > 0 && walletHistory.map((item, index) => (
                    <>
                        <h5 className='text-sm px-3 font-bold mt-2 mb-2'>{getDisplayDate(moment(item?.modifiedOn).year(), moment(item?.modifiedOn).month(), moment(item?.modifiedOn).date())}</h5>
                        <div className={"flex justify-between py-3 px-3 pt-0"}>
                            <div className={"flex"}>
                                <img src={item?.image ? item?.image : "/images/task_ic.png"} className={"w-12 h-12"} />
                                <div className={"self-center"}>
                                    <p className={"ml-2 text-sm self-center font-bold"}>{item?.behaviour}</p>
                                    <p className={"ml-2 text-gray-400 text-xs"}>{moment(item?.modifiedOn).format("hh:mm a")}</p>
                                </div>
                            </div>
                            <div className={"flex self-center"}>
                                <p className={"mr-2 text-sm self-center font-bold"}>{item.trasactionType == "Credited" ? "+ " : '- '}{item?.amount}</p>
                                <img src={"/images/Star.png"} className={"w-6 h-6"} />
                            </div>
                        </div>


                    </>
                ))}
                {walletHistory?.length > 0 &&
                    <div
                        className="flex justify-center items-center w-full text-alt-primary cursor-pointer mb-4"
                        onClick={() => { setPageLimit(pageLimit + 20) }}>
                        Load More
                    </div>
                }
                {
                    walletHistory?.length == 0 &&
                    <h5 className='mt-5 text-sm text-center mt-6 mb-2'>No Data Found</h5>
                }
            </div>

        </>
    );
};
export default WalletDetail;
