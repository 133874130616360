// noinspection JSUnresolvedReference
import {useEffect, useState} from 'react';

import {
  useUploadType,
  useUploadedImageLink,
  useUploadedVideoLink,
  useUploadedAudioLink,
  useProgressUploadedImageLink,
  useUploadedAvatarLink,
} from '../hubState';
import {useAtom} from 'jotai';
import {useMutation} from 'react-query';
import {useDispatch} from 'react-redux';
import {useRankInfo} from '../useRankInfo';
import {useTagsList} from '../useTagsList';
import {useUserTasks} from '../useUserTasks';
import {useWalletData} from '../useWalletData';
import {useCreateTask} from '../useCreateTask';
import {useLeaderBoard} from '../useLeaderBoard';
import {useCoverImageList} from '../useCoverImageList';
import {useBaseActivities} from '../useBaseActivities';
import {useBuzzmiAuth} from '../useBuzzmiAuth/index.web';
import {usePlannedActivities} from '../usePlannedActivities';
import {useCreateBaseActivity} from '../useCreateBaseActivity';
import {useEditBaseActivity} from '../useEditBaseActivity';
import {useCreatePlanActivity} from '../useCreatePlanActivity';
import {closeModal} from '../../../store/slices/modalSlice/modalSlice';
import {
  postVideoFilesBeforeUploades,
  postfileUpload,
  postCoverImage,
  addProfileTag,
  getProfileTag,
} from '../../../network/api/app';
import useSetParenComponentProps from '../../../hooks/useSetParenComponentProps';
import {userTagsAtom} from '../ProfileState';
import {useNextRankInfo} from '../useNextRankInfo';
import {useSharedBaseActivities} from '../useSharedBaseActivities';
import {useGetNotifications} from '../useGetNotifications';
import {usePostShareActivity} from '../usePostShareActivity';
import {useSharedImageList} from '../useSharedImageList';
import {useGetSuggestedTasks} from '../useGetSuggestedTasks';
import {useGetPopularNames} from '../useGetPopularNames';
import {usePostAssignActivity} from '../usePostAssignActivity';

export const useHubApp = () => {
  const {walletInfo, getWalletData} = useWalletData();
  const {rankInfo, getRankInfoDetail} = useRankInfo();
  const {getNextRankInfoDetail, nextRankInfo} = useNextRankInfo();
  const {coverList, coverImageListLoader, getCoverImageList} =
    useCoverImageList();
  const {sharedMediaList, sharedImageListLoader, getsharedImageList} =
    useSharedImageList();
  const {suggestedTasks, getMySuggestedTasks} = useGetSuggestedTasks();
  const {popularNames, popularNamesListLoader, getpopularNamesList} =
    useGetPopularNames();
  const [userTags, setUserTags] = useAtom(userTagsAtom);

  const {
    tagsList,
    getTagsList,
    isPostAcitivityTagsLoading,
    isPostAcitivityTagsError,
    PostAcitivityTagsResponse,
    PostAcitivityTagsErrorResponse,
    PostAcitivityTags,
  } = useTagsList();
  const {taskList, getMyTaskList} = useUserTasks();

  const {
    plannedActivities,
    getPlannedActivities,
    getTodayTasks,
    userTodayPlanTasks,
    getPlannedActivitiesStat,
    plannedActivitiesStats,
  } = usePlannedActivities();

  const {baseActivitiesList, getBaseActivitiesList} = useBaseActivities();
  const {sharedbaseActivitiesList, getSharedBaseActivitiesList} =
    useSharedBaseActivities();
  const {getNotificationsList, notificationsList} = useGetNotifications();
  const {ShareActivity, isSharePostLoading} = usePostShareActivity();
  const {AssignActivity, isAssignPostLoading,AssignPostResponse} = usePostAssignActivity();
  const {
    mutate: mutateProfileTag,
    isLoading: isTagLoading,
    isError: isTagError,
    data: tagResponse,
    error: tagErrorResponse,
  } = useMutation(addProfileTag);

  const {
    taskErrorResponse,
    isCreateTaskError,
    isCreateTaskLoading,
    taskResponse,
    postCreateTask,
  } = useCreateTask();

  const {
    isCreateBaseActivityLoading,
    CreateBaseActivityResponse,
    CreateBaseActivityErrorResponse,
    postCreateBaseActivity,
  } = useCreateBaseActivity();
  const {
    isEditBaseActivityLoading,
    EditBaseActivityResponse,
    EditBaseActivityErrorResponse,
    patchBaseActivity,
  } = useEditBaseActivity();

  const {
    isCreatePlanActivityLoading,
    CreatePlanActivityResponse,
    CreatePlanActivityErrorResponse,
    postCreatePlanActivity,
  } = useCreatePlanActivity();

  const {useLeaderBoardList} = useLeaderBoard();

  const [uploadType, setUploadType] = useAtom(useUploadType);
  const [uploadedImageLink, setUploadedImageLink] =
    useAtom(useUploadedImageLink);

  const [uploadedProgressImageLink, setUploadedProgressImageLink] = useAtom(
    useProgressUploadedImageLink,
  );

  const [uploadedVideoLink, setUploadedVideoLink] =
    useAtom(useUploadedVideoLink);

  const [uploadedAvatarLink, setUploadedAvatarLink] = useAtom(
    useUploadedAvatarLink,
  );

  const [uploadedAudioLink, setUploadedAudioLink] =
    useAtom(useUploadedAudioLink);

  const {userData} = useBuzzmiAuth();
  const setProps = useSetParenComponentProps();
  const dispatch = useDispatch();

  const {
    mutateAsync: mutateFileUploadAsync,
    mutate: mutateFileUpload,
    isLoading: isFileUploadLoading,
    isError: isFileUploadError,
    data: fileUploadResponse,
    error: fileUploadErrorResponse,
  } = useMutation(postfileUpload);

  const {
    mutate: mutateCoverImageUpload,
    isLoading: isCoverUploaing,
    isError: isCoverUploadError,
    data: coverUploadResponse,
    error: coverUploadErrorResponse,
  } = useMutation(postCoverImage);

  useEffect(() => {
    if (isCreateTaskError) {
      console.log(taskErrorResponse);
    } else if (!isCreateTaskLoading && taskResponse) {
      window.location.reload();
    }
  }, [taskResponse, isCreateTaskLoading, isCreateTaskError]);

  useEffect(() => {
    if (isFileUploadError) {
      console.log(fileUploadErrorResponse);
    } else if (!isFileUploadLoading && fileUploadResponse) {
      if (uploadType == 'description_audio') {
        setUploadedAudioLink(fileUploadResponse?.link);
      } else if (uploadType == 'avatar') {
        setUploadedAvatarLink(fileUploadResponse?.link);
      } else if (uploadType == 'progress_photos') {
        console.log(fileUploadResponse?.link);
        setUploadedProgressImageLink(fileUploadResponse?.link);
      } /* else if (uploadType == 'description_image') {
        setUploadedImageLink(fileUploadResponse?.link);
      } */ else if (uploadType == 'description_video') {
        setUploadedVideoLink(fileUploadResponse?.link);
      } else if (uploadType == 'cover') {
        setProps('ChoseImage', {image: fileUploadResponse?.link});
        onUploadCoverFiles(
          Object.assign(
            {imageUrl: fileUploadResponse?.link},
            {
              params: {
                isAuth: true,
                token: userData?.token,
              },
            },
          ),
        );
      }
    }
  }, [fileUploadResponse, isFileUploadLoading, isFileUploadError]);

  useEffect(() => {
    if (isCoverUploadError) {
      console.log(coverUploadErrorResponse);
    } else if (!isCoverUploaing && coverUploadResponse) {
      getCoverImageList(userData?.token);
      dispatch(closeModal());
    }
  }, [coverUploadResponse, isCoverUploaing, isCoverUploadError]);

  const getUserTags = async (id, token) => {
    try {
      const response = await getProfileTag(id)({token});
      setUserTags(response);
    } catch (e) {
      console.log('error => ', e.message);
    }
  };

  const onUploadFiles = params => {
    mutateFileUpload(params);
    setUploadType(params?.params?.type);
  };
  const onUploadCoverFiles = params => mutateCoverImageUpload(params);

  return {
    rankInfo,
    taskList,
    tagsList,
    coverList,
    walletInfo,
    getTagsList,
    taskResponse,
    getMyTaskList,
    onUploadFiles,
    getWalletData,
    postCreateTask,
    isCoverUploaing,
    getRankInfoDetail,
    getNextRankInfoDetail,
    nextRankInfo,
    plannedActivities,
    uploadedVideoLink,
    uploadedImageLink,
    setUploadedImageLink,
    getCoverImageList,
    uploadedAudioLink,
    onUploadCoverFiles,
    baseActivitiesList,
    fileUploadResponse,
    useLeaderBoardList,
    isFileUploadLoading,
    coverUploadResponse,
    isCreateTaskLoading,
    coverImageListLoader,
    getPlannedActivities,
    getBaseActivitiesList,
    postCreateBaseActivity,
    postCreatePlanActivity,
    uploadedProgressImageLink,
    setUploadedProgressImageLink,
    CreatePlanActivityResponse,
    CreateBaseActivityResponse,
    isCreateBaseActivityLoading,
    isCreatePlanActivityLoading,
    CreateBaseActivityErrorResponse,
    CreatePlanActivityErrorResponse,
    isEditBaseActivityLoading,
    EditBaseActivityResponse,
    EditBaseActivityErrorResponse,
    patchBaseActivity,
    isPostAcitivityTagsLoading,
    isPostAcitivityTagsError,
    PostAcitivityTagsResponse,
    PostAcitivityTagsErrorResponse,
    PostAcitivityTags,
    setUploadedVideoLink,
    setUploadedAudioLink,
    uploadedAvatarLink,
    getUserTags,
    userTags,
    mutateProfileTag,
    isTagLoading,
    isTagError,
    tagResponse,
    tagErrorResponse,
    getSharedBaseActivitiesList,
    sharedbaseActivitiesList,
    getNotificationsList,
    notificationsList,
    ShareActivity,
    isSharePostLoading,
    sharedMediaList,
    sharedImageListLoader,
    getsharedImageList,
    suggestedTasks,
    getMySuggestedTasks,
    popularNames,
    popularNamesListLoader,
    getpopularNamesList,
    getTodayTasks,
    userTodayPlanTasks,
    getPlannedActivitiesStat,
    plannedActivitiesStats,
    AssignActivity,
    isAssignPostLoading,
    AssignPostResponse
  };
};
