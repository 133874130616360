import React, { useEffect, useState } from "react"
import CodeInput from "./CodeInput"
import PrimaryButton from "../../../../btns/buttons/PrimaryButton.jsx"
import Form from "../../../Form.jsx"
import userAPI from "../../../../../../store/services/UserService.js"
import ResendCode from "../../../../btns/buttons/ResendCode.jsx"
import { useNavigate } from "react-router-dom"
import ValidationSchema from "../../../../../../form-validator/ValidationSchema.js"
import { useFormValidator } from "../../../../../../form-validator/hooks/index.js"
import PropTypes from "prop-types"

const CodeInputsFour = ({ label, codeLength, formClassName, getCode, identifier, hideMessage, hideBtn }) => {


  const validationSchema = new ValidationSchema({
    code: [{ rule: "required" }, { rule: "countDigits", value: codeLength }],
  })
  const navigate = useNavigate()
  const [code, setCode] = useState([])
  const [sendCode] = userAPI.useForgotPasswordMutation()
  const [confirmCode] = userAPI.useConfirmForgotPasswordMutation()
  const { register, handleSubmit, setValue, values } = useFormValidator(validationSchema, async (formData) => {
    //TODO Place for sending data to API use confirmCode()


    const res = {}
    if (res.error) {
      console.error(res.error)
    } else {
      navigate("/auth/reset-password")
    }
  })

  const handleResendCode = async () => {
    console.log(identifier)
    const res = await sendCode({ identifier })
    if (res.error) {
      console.error(res.error)
    } else {
      console.log(res)
    }
  }

  useEffect(() => {
    getCode && getCode(code)
  }, [code])


  return (
    <Form formClassName={formClassName} >
      <label className={"input-label"}>
        <div>{label}</div>
        <div className={"input-code-wrapper "}>
          <input className="input-code-wrapper__hide" {...register("code")} type="text" />
          {[...Array(codeLength)].map((_, i) => (
            <CodeInput
            regex={/^[0-9a-zA-Z_]$/}
              setCode={setCode}
              key={i}
              index={i}
              register={register}
              label={"Input Label"}
              setValue={setValue}
            />
          ))}
        </div>
      </label>
      <div>
        {!hideMessage && <ResendCode handleResendCode={handleResendCode} />}
        {!hideBtn && (
          <PrimaryButton className={values?.code?.length !== codeLength ? "button_inactive" : ""} type="submit">
            Continue
          </PrimaryButton>
        )}
      </div>
    </Form>
  )
}

CodeInputsFour.propTypes = {
  label: PropTypes.string,
  formClassName: PropTypes.string,
  identifier: PropTypes.string,
  hideMessage: PropTypes.bool,
  hideBtn: PropTypes.bool,
}

export default CodeInputsFour
