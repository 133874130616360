import React, {useState, Fragment} from 'react';
import Badge from '../../../../badge/Badge.jsx';
import InfoCardMenu from '../infoCardMenu/InfoCardMenu.jsx';
import {useSwipeable} from 'react-swipeable';
import Strings from '../../../../../../string_key/Strings.web';
import {Dialog, Menu, Transition} from '@headlessui/react';
import {EllipsisHorizontalIcon} from '@heroicons/react/24/outline';
import {ClockIcon} from '@heroicons/react/24/outline';
import {CheckCircleIcon} from '@heroicons/react/24/outline';

import {useCompletePlannedTasks} from '../../../../../../appState/hooks/useCompletePlannedTasks/index.js';
import UpdateProgressModal from '../../../../../../container/App/Hub/UpdateProgressModal/index.js';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const InfoCardMain = ({
  noMenu = false,
  image,
  badges,
  info,
  type,
  id,
  item,
  taskType,
  onClickItem,
  isMainActivity = false,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [updateProgressModal, showUpdateProgressModal] = useState(false);

  const {patchCompletePlannedTasks} = useCompletePlannedTasks();

  const swipeLeft = () => {
    setShowMenu(true);

    //create handler if click outside show-menu area then setShowMenu(false)
    window.addEventListener('click', function (e) {
      if (!e.target.closest('.show-menu')) {
        setShowMenu(false);
      }
    });

    //create this for swipe another card
    window.addEventListener('touchstart', function (e) {
      if (!e.target.closest('.show-menu')) {
        setShowMenu(false);
      }
    });
  };

  const handlers = useSwipeable({
    onSwipedLeft: eventData => swipeLeft(),
    onSwipedRight: eventData => setShowMenu(false),
  });

  const handlersState = type === 'menu' ? handlers : null;

  return (
    <div {...handlersState} className={` ${showMenu ? 'show-menu' : ''}`}>
      <li key={id} className="relative flex xl:static">
        <div className="relative flex sm:space-x-6 space-x-3 sm:py-4 py-1 w-100">
          {image && (
            <div className="sm:me-1 me-0" onClick={onClickItem}>
              <img
                className="h-14 w-14 sm:h-16 sm:w-16 md:h-20 md:w-20 lg:h-24 lg:w-24 xl:h-24 xl:w-24 flex-none rounded-md"
                src={
                  image?.includes('http')
                    ? image
                    : 'https://onesay-backend-dev.s3.amazonaws.com/4bc1d577-1b3d-43ba-ad9b-86c29780c99f-drink.png'
                }
                alt={image?.alt}
              />
            </div>
          )}
          <div
            className={`info-card__content flex-column ${
              !badges?.length > 0 ? 'justify-content-center' : ''
            }`}>
            <div className={'info-card__badges'}>
              {badges?.length > 0 &&
                badges?.map((badge, index) => (
                  <span className={'me-2'} key={index + `${badge.type}`}>
                    <Badge type={badge.type} />
                  </span>
                ))}
            </div>

            <div className="flex-auto self-center">
              <h3
                onClick={onClickItem}
                className="text-base pr-10 font-semibold text-gray-900 xl:pr-0">
                {info?.title}
              </h3>
              <dl
                onClick={onClickItem}
                className="flex flex-col text-gray-500 xl:flex-row">
                <div className="flex items-start space-x-3">
                  <h3
                    className="pr-10 text-gray-600 xl:pr-0"
                    style={{
                      overflow: 'hidden',
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2,
                      maxHeight: '3.5em',
                    }}>
                    {info?.text}
                  </h3>
                </div>
              </dl>
              {isMainActivity == true && (
                <div className={'mt-[4px] sm:mt-[12px] flex'}>
                  {/* {item?.isCompleted == false && <span onClick={() => { patchCompletePlannedTasks(item?.id, { isCompleted: true, goalProgress: item?.goal }) }} className="border-[1px] border-[#EB4DAC] p-[4px] sm:p-[10px] !pt-[2px] sm:!pt-[8px] rounded-md	text-[#EB4DAC] font-medium mr-4"></span>} */}
                  {/* <span onClick={() => { showUpdateProgressModal(() => true); }} className="border-[1px] border-[#EB4DAC] p-[4px] sm:p-[10px] !pt-[2px] sm:!pt-[8px] rounded-md	text-[#EB4DAC] font-medium">{"Update Progress"}</span> */}

                  {item?.isCompleted == false && item?.goal?.length > 0 && (
                    <CheckCircleIcon
                      onClick={() => {
                        patchCompletePlannedTasks(item?.id, {
                          isCompleted: true,
                          goalProgress: item?.goal,
                        });
                      }}
                      className={
                        'w-7 h-7 sm:w-10 sm:h-10 mr-4 rounded-md border-[1px] border-[#e9e5e5] sm:p-[8px] p-[4px] py-0 self-center text-[#1ba51b]'
                      }
                    />
                  )}

                  {/* {item?.isProgressImageEnable && (
                    <ClockIcon
                      onClick={() => {
                        showUpdateProgressModal(() => true);
                      }}
                      className={
                        'w-7 h-7 sm:w-10 sm:h-10 rounded-md	border-[1px] border-[#e9e5e5] sm:p-[8px] p-[4px] py-0 self-center text-[#efbc10]'
                      }
                    />
                  )} */}
                </div>
              )}
            </div>
          </div>
        </div>
        {type !== null && (
          <>
            {!noMenu && type === 'menu' && isMainActivity == false && (
              <InfoCardMenu
                showMenu={showMenu}
                setShowMenu={setShowMenu}
                id={id}
                taskType={taskType}
                item={item}
                isMainActivity={isMainActivity}
              />
            )}
            {type === 'link' && (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img
                  src="/images/assets/info-card/arrow-r.svg"
                  alt="arrow-right"
                />
              </div>
            )}
          </>
        )}
      </li>

      <UpdateProgressModal
        item={item}
        activityModal={updateProgressModal}
        showActivityModal={showUpdateProgressModal}
      />
    </div>
  );
};

export default InfoCardMain;
