import {useMutation} from 'react-query';
import {patchEditBaseActivity} from '../../../network/api/app';

import {useBuzzmiAuth} from '../useBuzzmiAuth/index.web';

export const useEditBaseActivity = () => {
  const {userData} = useBuzzmiAuth();
  const {
    mutate: mutateEditBaseActivity,
    isLoading: isEditBaseActivityLoading,
    isError: isEditBaseActivityError,
    data: EditBaseActivityResponse,
    error: EditBaseActivityErrorResponse,
  } = useMutation({
    mutationFn: param => {
      return patchEditBaseActivity(param.id)({
        params: {isAuth: true, token: userData?.token},
        body: {...param?.body},
      });
    },
    onSuccess: data => {
      console.log('edited ==> ', data);
    },
  });
  const patchBaseActivity = async (id, body) =>
    mutateEditBaseActivity({id, body});

  return {
    isEditBaseActivityLoading,
    EditBaseActivityResponse,
    EditBaseActivityErrorResponse,
    patchBaseActivity,
  };
};
