import React from 'react';
import ProfileMobileUsersFollowers from './ProfileMobileUsersFollowers';
import ProfileMobileUsersFollowing from './ProfileMobileUsersFollowing';
import ProfileMobileUsersSubscribes from './ProfileMobileUsersSubscribes';
import { useBuzzmiAuth } from '../../../../../appState/hooks';

const ProfileMobileUsersTabs = ({
  activeTabId,
  following,
  followers,
  subscribers,
  postUnfollowUser,
  postFollowUser,
  lodaingFollow,
  lodaingFollowers,
  showLoadMoreFollow,
  showLoadMoreFollower,
  onPressFollowLoadMore,
  onPressFollowerLoadMore,
  followPage,
  userContactInfo,
  isVisitorProfile = false,
  postUnfollowerUser,
}) => {
  const { userData } = useBuzzmiAuth();

  const contentMap = {
    following: (
      <ProfileMobileUsersFollowing
        data={following}
        followPage={followPage}
        userContactInfo={userContactInfo}
        onPressUnfollow={item =>
          postUnfollowUser(item?.id, userData.token)
        }
        onPressfollow={item =>
          postFollowUser(item?.id, userData.token)
        }
        lodaingFollow={lodaingFollow}
        showLoadMoreFollow={showLoadMoreFollow}
        onPressFollowLoadMore={onPressFollowLoadMore}
        isVisitorProfile={isVisitorProfile}
      />
    ),
    followers: (
      <ProfileMobileUsersFollowers
        data={followers}
        followPage={followPage}
        userContactInfo={userContactInfo}
        onPressfollow={item =>
          postFollowUser(item?.id, userData.token, { page: 1, limit: 10 })
        }
        onPressUnfollow={item =>
          postUnfollowerUser(item?.id, userData.token, { page: 1, limit: 10 })
        }
        lodaingFollowers={lodaingFollowers}
        showLoadMoreFollower={showLoadMoreFollower}
        onPressFollowerLoadMore={onPressFollowerLoadMore}
        isVisitorProfile={isVisitorProfile}
      />
    ),
    subscribers: <ProfileMobileUsersSubscribes data={subscribers} />,
  };

  return !activeTabId || !contentMap[activeTabId]
    ? null
    : contentMap[activeTabId];
};

export default ProfileMobileUsersTabs;
