import {useState} from 'react';
import InfoCard from '../../cards/infoCard/InfoCard';
import {isImage, isVideo} from '../../../../types/media';
import pencil from '../../../../assets/ic_grad_pencil.png';
const Description = ({
  item,
  isEdit,
  setValue,
  onChangeTitle,
  onChangeDes,
  showDelete,
  onDelete,
  isSmall,
  showTitle,
  setIsEditName,
}) => {
  // const [data, setData] = useState({
  //   image: {
  //     ...item?.image,
  //   },
  //   info: {
  //     title: item?.title,
  //     text: item?.description,
  //   },
  //   type: null,
  // });

  const data = {...item, type: null};

  // console.log('item ===> ', item);
  const sizeOfImage = isSmall
    ? 'w-20 h-20 md:w-20 md:h-20 lg:w-24 lg:h-24 xl:w-28 xl:h-28'
    : 'w-28 h-28 md:w-28 md:h-28 lg:w-32 lg:h-32 xl:w-32 xl:h-32';
  return (
    <>
      {/* <div className="d-none d-md-block">
        <InfoCard noMenu item={data} />
      </div> */}
      <div className="description__mobile">
        <div className="relative">
          {isImage(item?.image) && (
            <img
              src={item?.image}
              alt={item?.image?.alt}
              className={
                sizeOfImage + ' rounded-lg  overflow-hidden object-cover'
              }
            />
          )}

          {isVideo(item?.image) && (
            <video
              src={item?.image}
              alt={item?.image?.alt}
              className={sizeOfImage + 'rounded-lg  overflow-hidden'}
            />
          )}
          {showDelete && (
            <button
              className="absolute top-0 right-0 bg-opacity-20 bg-white backdrop-blur-md shadow-md rounded-md w-6 h-6 flex items-center justify-center self-center"
              onClick={onDelete}>
              <img src="/images/assets/delete.svg" alt="delete" />
            </button>
          )}
        </div>

        <div
          className="description__column-m max-w-[70%]"
          style={{flexGrow: 1}}>
          {/* <div className="description__title-m">Description</div> */}
          {/* <textarea
            onChange={e => console.log('description', e.target.value)}
            placeholder="Add a short description of the preset"
            className="description__title-m"
            value={item?.title || ''}
          /> */}
          {/* <div className="description__text-m">
            <div>{item?.description}</div>
          </div> */}
          {/* <textarea
            onChange={e => console.log('description', e.target.value)}
            placeholder="Add a short description of the preset"
            className="description__text-m"
            value={item?.description || ''}
          /> */}
          {isEdit ? (
            <>
              <textarea
                onChange={onChangeTitle}
                placeholder="Enter a name"
                className="description__text-m1 rounded-md border-[#C0BACB]"
                value={item?.title || ''}
                rows={1}
              />
              <textarea
                onChange={onChangeDes}
                placeholder="If necessary, leave a short description for this"
                className="description__text-m rounded-md border-[#C0BACB]"
                value={item?.description || ''}
              />
            </>
          ) : (
            <>
              {/* <div className="description__title-m">{item?.title}</div> */}
              <div className="flex items-center justify-between">
                <div
                  className={`description__title-m  ${
                    showTitle ? 'ml-2' : ''
                  }`}>
                  {showTitle ? item?.title : 'Description'}
                </div>
                {/* {!!setIsEditName && (
                  <img
                    src={pencil}
                    className="h-4 w-4"
                    onClick={() => setIsEditName(true)}
                  />
                )} */}
              </div>
              <div className="description__text-m max-h-28 overflow-y-auto">
                <div>{item?.description}</div>
              </div>
            </>
          )}
        </div>
        {/* {showDelete && (
          <button
            className="bg-opacity-20 bg-white backdrop-blur-md shadow-md rounded-md w-6 h-6 flex items-center justify-center self-center"
            onClick={onDelete}>
            <img src="/images/assets/delete.svg" alt="delete" />
          </button>
        )} */}
      </div>
    </>
  );
};

export default Description;
