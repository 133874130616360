import React from 'react';

const AppleSvg = () => {
  return (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.100098" y="0.5" width="34" height="34" rx="17" fill="#2E3240"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M20.3087 8.95314C21.1536 8.03799 21.6188 6.78121 21.5454 5.5C20.3945 5.5 19.0599 6.17111 18.2151 7.15947C17.5539 7.97698 16.8437 9.25818 17.1008 10.5394C18.2885 10.6858 19.5741 9.94149 20.3087 8.95314ZM21.4352 10.82C19.6476 10.82 18.0681 11.8084 17.2233 11.8084C16.3417 11.8084 15.0561 10.7712 13.6113 10.82C11.7258 10.8566 9.9994 11.967 9.03215 13.602C8.34649 14.7734 8.07711 16.1523 8.10162 17.5555C8.13834 20.2033 9.17905 22.9609 10.4034 24.718C11.3462 26.0602 12.4114 27.5 13.9174 27.5C15.2765 27.5 15.7785 26.5971 17.5539 26.5971C19.2068 26.5971 19.721 27.5 21.1536 27.5C22.6596 27.5 23.6513 26.1334 24.5451 24.779C25.6715 23.2049 26.0511 21.7041 26.1001 21.6431C26.0511 21.6431 23.2105 20.4961 23.1003 17.226C23.1003 14.3586 25.4389 13.0652 25.5369 13.0042C24.2635 11.0519 22.2065 10.82 21.4352 10.82Z" fill="white"/>
    </svg>
  );
};

export default AppleSvg;