import React, {useEffect, useState} from 'react';
import cn from 'classnames';

const RadioMode = ({
  variants,
  name,
  value,
  setValue,
  disabled = false,
  setShowSpecificTime,
}) => {
  const handleChange = event => {
    console.log('when', event.target.value);
    setValue(name, event.target.value);

    setValue('specificTime', undefined);
    setShowSpecificTime(false);
  };

  return (
    <div
      //  style={{opacity: disabled ? 0.4 : 1}}
      className="radio-mode">
      {variants.map(item => (
        <label
          className={cn('react-tabs__tab p-0', {
            'react-tabs__tab--selected': item.value === value,
          })}
          key={item.value}>
          <span>{item.title}</span>

          <input
            // disabled={disabled}
            onChange={handleChange}
            type="checkbox"
            value={item.value}
            name={name}
          />
        </label>
      ))}
    </div>
  );
};

export default RadioMode;
