import React, { useEffect, useRef, useState } from "react"
import useWindowSize from "../../../../../hooks/useWindowSize"
import cn from "classnames";

const SimpleShowMore = ({
  onShowMore,
  sizes,
  content,
  showMoreBtnContent = "Read more",
  hideMoreBtnContent = "Hide",
  lineClamp,
}) => {
  const { width } = useWindowSize()
  const [showMore, setShowMore] = useState(false)
  const contentRef = useRef(null)
  const [maxHeight, setMaxHeight] = useState(0)

  const handleShowMore = () => {
    onShowMore ? onShowMore() : setShowMore(!showMore)
  }

  useEffect(() => {
    if (width > 767) {
      setMaxHeight(sizes.desktopHeight)
    } else {
      setMaxHeight(sizes.mobileHeight)
    }
  }, [width])

  return (
    <div className={"relative"}>
      <div
        ref={contentRef}
        className={cn("show-more__content text-sm font-normal", {
          "show-more__content_active": showMore,
          "show-more__line-clamp ": lineClamp,
        })}
        style={{
          maxHeight: showMore ? `max-content` : `${maxHeight}px`,
        }}
      >
        {content}
      </div>

      {/* bg-[#faf7ff] right-[4px] bottom-0	pl-[12px] */}
      {/* {!showMore && "... "} */}
      <div className="show-more__btn  ">
         {maxHeight < contentRef.current?.scrollHeight && (
          <button onClick={handleShowMore}>
            {!showMore ? showMoreBtnContent : hideMoreBtnContent}
            <span>
              <img src="/images/assets/arrow-down.svg" alt="arrow-down" />
            </span>
          </button>
        )}
      </div>
    </div>
  )
}

export default SimpleShowMore
