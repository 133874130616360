import './styles/style.scss';
import 'react-tabs/style/react-tabs.css';
import { StrictMode } from 'react';
import './index.css';
import App from './navigator/AppNavigator';
import Ionicons from 'react-native-vector-icons/Fonts/Ionicons.ttf';
import FontAwesome from 'react-native-vector-icons/Fonts/FontAwesome.ttf';
import AntDesign from 'react-native-vector-icons/Fonts/AntDesign.ttf';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/store.js';

import { GoogleOAuthProvider } from '@react-oauth/google';

const IoniconsStyles = `@font-face {
  src: url(${Ionicons});
  font-family: Ionicons;
}`;

const FontAwesomeiconsStyles = `@font-face {
  src: url(${FontAwesome});
  font-family: FontAwesome;
}`;

const AntDesigniconsStyles = `@font-face {
  src: url(${AntDesign});
  font-family: AntDesign;
}`;

const style = document.createElement('style');
style.type = 'text/css';

if (style.styleSheet) {
  style.styleSheet.cssText = IoniconsStyles;
  style.styleSheet.cssText = FontAwesomeiconsStyles;
  style.styleSheet.cssText = AntDesigniconsStyles;
} else {
  style.appendChild(document.createTextNode(IoniconsStyles));
  style.appendChild(document.createTextNode(FontAwesomeiconsStyles));
  style.appendChild(document.createTextNode(AntDesigniconsStyles));
}

document.head.appendChild(style);


ReactDOM.render(
  <StrictMode>
    <GoogleOAuthProvider clientId="239868943110-f8mjsm25fvajefv90t61enqtv845k2uk.apps.googleusercontent.com">
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </GoogleOAuthProvider>
  </StrictMode>,
  document.getElementById('root'),
);
