import React, {
  useEffect,
  useState,
  Fragment,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useBuzzmiAuth } from '../../../../appState/hooks/useBuzzmiAuth/index.web.js';
import PictureUploader from './PictureUploader.jsx';
import cn from 'classnames';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useUploadImages } from '../../../../appState/hooks/useUploadImages';
import { useDispatch } from 'react-redux';
import { openModal } from '../../../../store/slices/modalSlice/modalSlice.js';
import { useHubApp } from '../../../../appState/hooks/useHubApp/index.web.js';
import ChoseMultipleImageModalV2 from '../../../modal/modals/choseMultipleImageModal/ChoseMultipleImageModalV2.jsx';
import addImg from '../../../../assets/add_icon.png';
import Description from '../../assets/description/Description.jsx';
import { useEventEmitter } from '../../../../hooks/useEventEmitter.js';

const PictureUploaderList = forwardRef((props, ref) => {
  const {
    setValue,
    classNames = '',
    defaultValues = [],
    locked,
    picture_type = '',
    isBig,
    isProgress = false,
    defaultValuesTitles,
    defaultValuesDes,
    withToggle,
    isEdit = false,
    isCreate,
  } = props;
  const [errors, setErrors] = useState(defaultValues || []);
  const [isOpen, setIsOpen] = useState(false);
  const [images, setImages] = useState(defaultValues || []);
  const [imageTitles, setImageTitles] = useState(defaultValuesTitles || []);
  const [imageDescriptions, setImageDescriptions] = useState(
    defaultValuesDes || [],
  );
  const [renderKey, setRenderKey] = useState(885599);
  const [indexToDelete, setindexToDelete] = useState();
  const { fileUploadResponse, isFileUploadLoading, coverList } = useHubApp();
  const dispatch = useDispatch();
  const [media, setMedia] = useState([]);

  const { userData } = useBuzzmiAuth();
  const { UploadFiles } = useUploadImages();

  const handleOtherServicesClick = () => {
    setIsOpen(() => true);

    // dispatch(
    //   openModal({
    //     modalName: 'ChoseMultipleImageModal',
    //     modalType: 'big',
    //     modalProps: {coverList},
    //     callback: im => {
    //       console.log('coverImage', im);
    //       // setValue('coverImage', im);
    //     },
    //   }),
    // );
  };
  useImperativeHandle(
    ref,
    () => ({
      handleAdd: () => setIsOpen(true),
    }),
    [],
  );
  useEventEmitter('setSuggestedMedia', data => {
    console.log('date in setSuggestedMedia event', data);
    data?.length > 0 ? setImages(data) : null;
    data?.value?.length > 0 ? setImages(data?.value) : null;
  });
  const onChange = (imageList, index) => {
    if (imageList?.length > 0) {
      let params = [];
      imageList.forEach((image, index) => {
        const fd = new FormData();
        fd.append('file', imageList[index].file);

        params.push(
          Object.assign(fd, {
            params: {
              isAuth: true,
              type: picture_type,
              token: userData?.token,
            },
          }),
        );
      });
      UploadFiles(params, response => {
        if (images?.length < 4) {
          const imagesToAdd = response.slice(0, 4 - images.length);
          setImages(prev => [...prev, ...imagesToAdd]);
        }
      });
    }
  };

  useEffect(() => {
    if (setValue) {
      setValue('images', images);
    }

    return () => {
      setValue('images', []);
    };
  }, [images]);

  // const handleInputChange = (index, newValue, inputArray, setInputArray) => {
  //   if (newValue.trim() === '') {
  //     // Remove the element at the specified index from the array
  //     const filteredArray = inputArray.filter((_, i) => i !== index);

  //     // Update the state with the new array
  //     setInputArray(filteredArray);
  //   } else {
  //     // Create a new array with the updated value at the specified index
  //     const updatedArray = [...inputArray];
  //     updatedArray[index] = newValue;

  //     // Update the state with the new array
  //     setInputArray(updatedArray);
  //   }
  // };
  const handleDeleteInputChange = (index, inputArray, setInputArray) => {
    // Remove the element at the specified index from the array

    const filteredArray = inputArray.filter((_, i) => i !== index);

    // // Update the state with the new array
    setInputArray(filteredArray);
  };
  const handleInputChange = (index, newValue, inputArray, setInputArray) => {
    // Create a new array with the updated value at the specified index
    const updatedArray = [...inputArray];
    updatedArray[index] = newValue.trim() === '' ? '' : newValue;

    // Update the state with the new array
    setInputArray(updatedArray);
  };

  useEffect(() => {
    imageDescriptions.length > 0
      ? setValue('progimgDes', imageDescriptions)
      : setValue('progimgDes', null);
    imageTitles.length > 0
      ? setValue('progimgTitles', imageTitles)
      : setValue('progimgTitles', null);
  }, [imageDescriptions, imageTitles]);

  const [initLength, setInitLength] = useState(0);
  useEffect(() => {
    setInitLength(images?.length);
  }, []);

  return (
    <>
      <div
        key={renderKey}
        className={`picture-uploader__wrapper mt-lg-3` + classNames}>
        {!!errors?.maxNumber && (
          <div className="text-b text-error">Max 4 images </div>
        )}

        {isProgress ? (
          <div className="w-full overflow-y-auto max-h-[300px]">
            {images.map((img, index) => {
              // let isPreview = index > initLength;
              let isPreview = isCreate ? false : index < initLength;
              console.log('isCreate===>', isPreview);
              return (
                <div className="mb-2 w-full ">
                  <Description
                    isSmall={true}
                    item={{
                      image: img,
                      title: imageTitles[index],
                      description: imageDescriptions[index],
                    }}
                    isEdit={isEdit ? isEdit : !isPreview}
                    showTitle={true}
                    onChangeTitle={e =>
                      handleInputChange(
                        index,
                        e.target.value,
                        imageTitles,
                        setImageTitles,
                      )
                    }
                    onChangeDes={e =>
                      handleInputChange(
                        index,
                        e.target.value,
                        imageDescriptions,
                        setImageDescriptions,
                      )
                    }
                    showDelete={isEdit ? isEdit : !isPreview}
                    onDelete={e => {
                      e?.preventDefault();
                      setImages(prev => {
                        prev.splice(index, 1);
                        setValue('images', prev);
                        return prev;
                      });
                      setindexToDelete(index);
                      handleDeleteInputChange(
                        index,
                        imageDescriptions,
                        setImageDescriptions,
                      );
                      handleDeleteInputChange(
                        index,
                        imageTitles,
                        setImageTitles,
                      );
                      setRenderKey(prev => prev + 1);
                    }}
                  />
                </div>
              );
            })}
          </div>
        ) : (
            <div
              className={cn('picture-uploader__row', {
                'picture-uploader__row_big': isBig,
                'max-[320px]:max-w-[15rem] max-[375px]:max-w-[17rem] max-[425px]:max-w-[20rem] max-[700px]:max-w-[30rem] min-[700px]:max-w-[35rem] overflow-x-auto': true,
              })}>
              {images.map((img, index) => {
                return (
                  <PictureUploader
                    defaultValue={img}
                    parentSetterState={imageList => onChange(imageList, index)}
                    locked={locked}
                    key={index}
                    index={index}
                    maxNumber={4}
                    setErrors={setErrors}
                    onDelete={imageIndexToDelete => {
                      setImages(prev => {
                        prev.splice(imageIndexToDelete, 1);
                        setValue('images', prev);
                        return prev;
                      });
                      setindexToDelete(imageIndexToDelete);

                      setRenderKey(prev => prev + 1);
                    }}
                  />
                );
              })}
            </div>
          )}
        {/* {images.length == 0 && (
          <button
            onClick={() => handleOtherServicesClick()}
            type="button"
            className="media-wrapperv2"
            disabled={locked}>
            <img
              src={addImg}
              className="h-8 w-8 sm:w-10 sm:h-10 md:w-10 md:h-10 lg:w-12 lg:h-12 xl:w-12 xl:h-12 mr-2"
              onClick={() => {}}
            />
            <h4 className="h4 text-center">{'Upload your media'}</h4>
          </button>
        )} */}
        {withToggle && (
          <div className="flex justify-end w-100 ">
            <img
              src={addImg}
              className="h-8 w-8 sm:w-10 sm:h-10 md:w-10 md:h-10 lg:w-12 lg:h-12 xl:w-12 xl:h-12"
              onClick={() => handleOtherServicesClick()}
            />
          </div>
        )}
      </div>
      <ChoseMultipleImageModalV2
        show={isOpen}
        setShow={setIsOpen}
        showSave={true}
        onlyImages={false}
        title={'Task Media (videos/images)'}
        setImages={setImages}
        images={images}
      />
      {/* {_renderImagePickerDialog()} */}
    </>
  );
});

export default PictureUploaderList;
