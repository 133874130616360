import { useEffect, useState, useRef } from 'react';
import BackBtnWithTitlePage from '../../../../components/reusable/btns/backBtn/BackBtnWithTitlePage.jsx';
import Form from '../../../../components/reusable/form/Form.jsx';
import AddName from '../Items/form/addName/AddName.jsx';
import ChoseImage from '../Items/choseImage/ChoseImage.jsx';
import MultiBox from '../../../../components/reusable/cards/multiBox/MultiBox.jsx';
import FormToggleDropdown from '../../../../components/reusable/assets/dropdown/formToggleDropdown/FormToggleDropdown.jsx';
import PictureUploaderList from '../../../../components/reusable/uploader/pictureUploader/PictureUploaderList.jsx';
import VideoUploader from '../../../../components/reusable/uploader/videoUploader/VideoUploader.jsx';
import AudioUploader from '../../../../components/reusable/uploader/audioUploader/AudioUploader.jsx';
import Checkbox from '../../../../components/reusable/assets/checkbox/Checkbox.jsx';
import SubmitButton from '../../../../components/reusable/btns/buttons/submitButton/SubmitButton.jsx';
import Strings from '../../../../string_key/Strings.web.js';
import { useBuzzmiAuth } from '../../../../appState/hooks/useBuzzmiAuth/index.web.js';
import CheckTags from '../Items/form/checkTags/CheckTags.jsx';
import { useHubApp } from '../../../../appState/hooks/useHubApp/index.web.js';
import addImg from '../../../../assets/add_icon.png';
import MediaWrapper from '../../../../components/reusable/assets/mediaWrapper/MediaWrapper.jsx';
import { EventRegister } from 'react-native-event-listeners';
import cn from 'classnames';
import ProgessSteps from '../../../../components/ProgressSteps/index.js';
const HubTaskForm = ({
  hideTitle = false,
  error,
  handleSubmit,
  setValue,
  values,
  isValid,
  register,
  data = {},
  isEdit,
}) => {
  // const [showInTask, setShowInTask] = useState(false);
  // const {language} = useBuzzmiAuth();
  const refAddMedia = useRef(null);

  const refCheckTags = useRef(null);
  const refAudioUploader = useRef(null);
  const [validIm, setvalidIm] = useState(true);
  const [validvid, setvalidvid] = useState(true);
  const [validAud, setvalidAud] = useState(true);

  const [isLinkClick, setIsLinkClick] = useState((!values?.mediaUrl?.includes('') && values?.mediaUrl?.length > 0) ? true : false);

  const handleAddLink = (e, index) => {
    const newValues = [...values?.mediaUrl];
    newValues[index] = e.target.value;
    setValue('mediaUrl', newValues);
  };

  const handleAddNewLink = () => {
    const newValues = [...values?.mediaUrl, ''];
    setValue('mediaUrl', newValues);
  };

  const removeExistLink = index => {
    if (index > -1) {
      values?.mediaUrl.splice(index, 1);
    }
    const newValues = [...values?.mediaUrl];
    setValue('mediaUrl', newValues);
  };

  const { setUploadedAudioLink } = useHubApp();

  useEffect(() => {
    EventRegister.emit('refresh-tasks-forms');
  }, [])


  // useEffect(() => {
  //   if (values.images) {
  //     setvalidIm(true);
  //   }
  //   if (values.video) {
  //     setvalidvid(true);
  //   }
  //   if (values.audio) {
  //     setvalidAud(true);
  //   }
  // }, [values]);
  const handleOnClickSuggestedItem = item => {
    setValue('name', item?.title);
    if (item?.description.length > 0)
      setValue('description', item?.description);
    if (item?.image) setValue('coverImage', item?.image);
    if (item?.descriptionAudio) setUploadedAudioLink(item?.descriptionAudio);
    if (item?.tags) setValue('tags', item?.tags);

    if (
      item?.descriptionMedia?.value?.length > 0 ||
      item?.descriptionMedia?.length > 0
    ) {
      EventRegister.emit('setSuggestedMedia', item?.descriptionMedia);
    }
    if (item?.descriptionAudio) setValue('audio', item?.descriptionAudio);
  };

  return (
    <div className="main-layout__single-container">
      {hideTitle == false && (
        <BackBtnWithTitlePage
          title={isEdit ? Strings.edit_task : Strings.add_task}
          backLink={isEdit ? '/hub/create-activity' : '/hub/add-activities'}
        />
      )}
      <ProgessSteps />
      <Form
        error={error}
        onSubmit={handleSubmit}
        formClassName="hub-form-wrapper">
        <AddName
          setValue={setValue}
          title={Strings.add_task_name + ' *'}
          placeholder={Strings.enter_name}
          name="name"
          register={register}
          value={values?.name || ''}
          showSuggestedTask
          handleOnClickSuggestedItem={handleOnClickSuggestedItem}
        />
        <ChoseImage values={values} setValue={setValue} />
        <MultiBox title={Strings.add_description} miniMode>
          <textarea
            onChange={e => setValue('description', e.target.value)}
            placeholder={Strings.short_description_task}
            className="textarea"
            value={values.description || ''}
          />
          <div class="wrap">
            <div class="pull-tab"></div>
          </div>
        </MultiBox>
        <MultiBox
          setValue={setValue}
          miniMode
          title={'Add Tags'}
          name="tags"
          rightSide={
            !!setValue && (
              <div className="d-flex justify-content-between align-items-center gap-1">
                <MediaWrapper
                  onClick={() => refCheckTags?.current?.handleAdd()}
                  iconSrc={addImg}
                  bordered={false}
                  style={{ height: '32px', width: '32px' }}
                />
              </div>
            )
          }>
          <CheckTags
            ref={refCheckTags}
            value={values?.tags || []}
            setValue={setValue}
            valueName="tags"
          />
        </MultiBox>
        {/* {!isEdit && ( */}
        {/* {console.log('values?.images?=?>?>?>?>', values?.images?.length > 0)} */}
        {/* <FormToggleDropdown
          setValue={setValue}
          title={'Add description media'}
          info={'Add description media'}
          name="images"
          defaultValue={values?.images}
          miniMode
          setvalidB={setvalidIm}>
          <PictureUploaderList
            defaultValues={values?.images}
            setValue={setValue}
            picture_type={'description_image'}
          />
        </FormToggleDropdown> */}
        {/* {console.log('values', values)} */}
        {/* {console.log('values here', values)} */}
        <MultiBox
          title={'Add media'}
          info={
            <>
              <span className="text-r">
                <span className="font-bold">Video Requirements:</span> <br />
                <li> Max Size: 500 MB</li>
                <li> Maximum Length: 90 Min</li>
                <li> Resolution: 1080p</li>
              </span>
              <span className="text-r">
                <span className="font-bold">File Type Image:</span> <br />
                JPEG, BMP, PNG, GIF, TIFF, SVG, WEBP, JFIF
              </span>
            </>
          }
          isLeft={true}
          miniMode
          rightSide={
            <div className="d-flex justify-content-between align-items-center gap-1">
              <MediaWrapper
                onClick={() => refAddMedia?.current?.handleAdd()}
                iconSrc={addImg}
                bordered={false}
                style={{ height: '32px', width: '32px' }}
              />
            </div>
          }>
          <PictureUploaderList
            classNames="picture-uploader__wrapper_mod"
            setValue={setValue}
            defaultValues={values.images}
            defaultValuesTitles={values?.progimgTitles}
            defaultValuesDes={values?.progimgDes}
            picture_type={'progress_photos'}
            isBig
            ref={refAddMedia}
            isProgress={true}
            isEdit={isEdit}
          />
        </MultiBox>
        {/* <FormToggleDropdown
          defaultValue={values?.video}
          setValue={setValue}
          title={Strings.add_description_video}
          info={Strings.add_description_video}
          name="video"
          miniMode
          setvalidB={setvalidvid}>
          <VideoUploader
            title={values?.name || ''}
            defaultValue={values?.video}
            setValue={setValue}
          />
        </FormToggleDropdown> */}


        <MultiBox
          title={'Add media url'}
          info={
            // <div className="flex justify-between w-100">
            <>
              <span className="text-r">
                <span className="font-bold">Requirements:</span> <br />
                <li> Youtube Link</li>
              </span>
            </>
          }
          isLeft={true}
          miniMode
          rightSide={
            <div className="d-flex justify-content-between align-items-center gap-1">

              <button
                onClick={() => { setIsLinkClick(!isLinkClick) }}
                type="button"
                style={{ height: '32px', width: '32px' }}
                className={`media-wrapper border-0`}
              >
                <img src={addImg} alt="media-icon" className="media-wrapper__icon" />
              </button>
            </div>
          }>
          {
            isLinkClick &&
            <div className="input-label text-right">
              <div className="mb-3 mb-md-4">
                {values?.mediaUrl?.length > 0 &&
                  values?.mediaUrl?.map((el, i) => (
                    <div className="input_skil">
                      <input
                        key={i + 'link'}
                        className={cn(
                          'input input_simple-text input-wrapper d-inline-block pr-[35px]',
                          {
                            'mb-0': i === values?.mediaUrl.length - 1,
                          },
                        )}
                        placeholder="Write your media link"
                        onChange={e => handleAddLink(e, i)}
                        value={values?.mediaUrl[i]}
                      />
                      <div
                        className="close_icon !top-[10px]"
                        onClick={() => {
                          removeExistLink(i);
                        }}>
                        <i class="fa-solid fa-xmark"></i>
                      </div>
                    </div>
                  ))}
              </div>
              <button
                className="text-alt-primary text-r"
                type={'button'}
                onClick={handleAddNewLink}>
                + Add link
              </button>
            </div>
          }

        </MultiBox>

        <MultiBox
          title={Strings.add_description_audio}
          // info={Strings.add_description_audio}
          info={
            <div className="d-flex justify-content-between align-items-center">
              <ul className="text-r">
                <li> Max Size: 500 MB</li>
                <li> Maximum length: 90 Min</li>
              </ul>
            </div>
          }
          isLeft={true}
          rightSide={
            <div className="d-flex justify-content-between align-items-center gap-1">
              <MediaWrapper
                onClick={() => refAudioUploader?.current?._uploadAudio()}
                iconSrc={addImg}
                bordered={false}
                style={{ height: '32px', width: '32px' }}
              />
            </div>
          }
          miniMode>
          <AudioUploader
            ref={refAudioUploader}
            defaultValue={values?.audio}
            defaultValueTitle={values?.AudioTitle}
            defaultValueDes={values?.AudioDescription}
            setValue={setValue}
          />
        </MultiBox>
        {/* <FormToggleDropdown
          defaultValue={values?.audio}
          setValue={setValue}
          title={Strings.add_description_audio}
          info={Strings.add_description_audio}
          name="audio"
          miniMode
          setvalidB={setvalidAud}>
          <AudioUploader defaultValue={values?.audio} setValue={setValue} />
        </FormToggleDropdown> */}
        {/* <FormToggleDropdown
          defaultValue={[]}
          setValue={setValue}
          icon="/images/hub/form/hash.png"
          title={Strings.tags}
          name="tags"
          values={values.tags}>
          <CheckTags
            tags={tagsList}
            value={values.tags}
            setValue={setValue}
            valueName="tags"
          />
        </FormToggleDropdown> */}

        {/* )} */}

        {/* <MultiBox
          icon="/images/assets/forms/task-center.png"
          title={Strings.display_in_task_center}
          rightSide={<Checkbox defaultValue={values?.showInTask} setState={setShowInTask} idChecked="showInTask" />}
        >
          <div>
            <h6 className="h6 mb-2 text-dark-secondary">Task view: Preset-based</h6>
            <p className="text-r">
              {Strings.toggle_description}
            </p>
          </div>
        </MultiBox> */}
        <div className="d-flex justify-content-center mt-5">
          <SubmitButton
            isValid={isValid}
            // isValid={isValid && validIm && validvid && validAud}
            title={Strings.save_and_continue}
          />
        </div>
      </Form>
    </div>
  );
};

export default HubTaskForm;
