import React, { useEffect, useState } from 'react';
import ValidationSchema from '../../../../../../../../form-validator/ValidationSchema';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { useFormValidator } from '../../../../../../../../form-validator/hooks';
import { DropdownIndicator } from '../../../../../../../../components/reusable/form/select/customSelect/CustomSelect';
import { phoneNumbersCode } from '../../../../../../../../components/reusable/form/select/mocData';
import { useBuzzmiAuth } from '../../../../../../../../appState/hooks/useBuzzmiAuth/index.web';

const validationSchema = new ValidationSchema({
  countryCode: [{ rule: 'required' }, { rule: 'countryPhoneCode' }],
  phone: [{ rule: 'required' }, { rule: 'phone' }],
});

const ProfilePhoneSelect = ({ setIsValid, customSetValues, isMobileVerified }) => {
  const { userData } = useBuzzmiAuth();
  const navigate = useNavigate();
  const { register, setValue, errors, isValid, values } = useFormValidator(
    validationSchema,
    async formData => {
      //TODO Place for sending data to API
      const res = {};
      if (res.error) {
        console.error(res.error);
      } else {
        localStorage.setItem(
          'identifier',
          formData.countryCode + formData.phone,
        );
        navigate('/auth/provide-phone-code');
      }
    },
  );
  const [selected, setSelected] = useState(
    phoneNumbersCode.filter(
      o => o.value == '+' + userData?.countryCode,
    ) || null,
  );
  const [focused, setFocused] = useState(false);

  useEffect(() => setIsValid && setIsValid(isValid), [isValid]);


  const removePrefix = (value, prefix) => value.startsWith(prefix) ? value.slice(prefix.length) : value;

  useEffect(() => {
    setValue('countryCode', '+' + userData?.countryCode);
    setValue('phone', removePrefix(userData?.mobile, '+' + userData?.countryCode))
  }, [userData])

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const handleSelect = e => {
    setSelected(e);
    setValue('countryCode', e.value);
  };

  useEffect(() => {
    customSetValues &&
      customSetValues(values.countryCode + (values?.phone || ''));
  }, [values]);

  return (
    <>
      <div
        style={{ pointerEvents: isMobileVerified ? "none" : "auto",  }}
        className={`phone-select ${focused ? 'phone-select_active' : ''} ${(!selected && errors?.countryCode) || errors?.phone
          ? 'phone-select_error'
          : ''
          }`}
        onClick={handleFocus}
        onBlur={handleBlur}>
        <Select
          components={{ DropdownIndicator }}
          options={phoneNumbersCode}
          value={selected}
          onChange={handleSelect}
          hasSelectAll={false}
          labelledBy="Select"
          placeholder="AE +971"
          className="phone-select__select select__secondary"
          disableSearch={true}
          classNamePrefix="phone-select"
        />
        <input
          className="phone-select__input"
          // pattern="[0-9]*"
          maxLength={17}
          placeholder="Phone number"
          type="text"
          {...register('phone')}
        />
      </div>

      <div className="mt-2">
        {!selected && errors?.countryCode && (
          <div className={'text-error text-center'}>
            {errors.countryCode.map((item, index) => (
              <div key={index + 'countryCode'}>Country code: {item}</div>
            ))}
          </div>
        )}
        {errors?.phone && (
          <div className={'text-error text-center'}>
            {errors.phone.map((item, index) => (
              <div key={index + 'phone'}>Phone: {item}</div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default ProfilePhoneSelect;
