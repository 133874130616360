import {atom} from 'jotai';
import {atomWithStorage, createJSONStorage} from 'jotai/utils';
import AsyncStorage from '@react-native-community/async-storage';

const storage = {...createJSONStorage(() => AsyncStorage), delayInit: true};
// const currentUserAtom = atom({});
// const userDataAtom = atomWithStorage('userDataNew', null);
const userWalletDetailAtom = atom([]);

const userRankAtom = atom([]);

const useSavedLists = atom([]);

const visitorRankAtom = atom([]);

const userBadges = atom([]);
const userRaings = atom([]);

const userQRdata = atom(null);

const userDynamicCaption = atom([]);
const userNextRankAtom = atom([]);
const userCoverList = atom([]);
const userSharedMediaList = atom([]);
const userPopularNames = atom([]);

const userTaskList = atom([]);
const userPlannedActivities = atom([]);
const userPlannedActivitiesStats = atom([]);
const userPlannedActivityStats = atom([]);

const useWalletHistory = atom([]);
const userRechageAmounts = atom([]);

const userTodayTasks = atom([]);
const userPlannedActivitiesGetTime = atom('');
const userPlannedActivitiesStatsGetTime = atom('');
const userBaseActivitiesList = atom([]);
const userBuzzmiActivitiesList = atom([]);
const userSharedBaseActivitiesList = atom([]);
const userNotificationsList = atom([]);

const userStatListing = atom([]);
const userSuggestedTasks = atom([]);

const userTagsList = atom([]);
const useUploadType = atom(null);
const useUploadedImageLink = atom(null);
const useProgressUploadedImageLink = atom(null);
const useUploadedVideoLink = atom(null);
const useUploadedAvatarLink = atom(null);

const useUploadedAudioLink = atom(null);
// const uploadedCoverImageLinkAtom = atom(null);

const userProfileDetail = atom(null);
const userProfileDetailLoading = atom(false);
const userBuzzmiActivity = atom([]);

export {
  userWalletDetailAtom,
  userRankAtom,
  userNextRankAtom,
  userCoverList,
  userTaskList,
  userPlannedActivities,
  userTagsList,
  useUploadType,
  useUploadedImageLink,
  useUploadedVideoLink,
  useUploadedAvatarLink,
  useUploadedAudioLink,
  useProgressUploadedImageLink,
  userBaseActivitiesList,
  userStatListing,
  userPlannedActivitiesGetTime,
  userProfileDetail,
  userPlannedActivitiesStatsGetTime,
  userDynamicCaption,
  userQRdata,
  userSharedBaseActivitiesList,
  userNotificationsList,
  userSharedMediaList,
  userPopularNames,
  userSuggestedTasks,
  visitorRankAtom,
  userTodayTasks,
  useWalletHistory,
  userRechageAmounts,
  // uploadedCoverImageLinkAtom,
  userBadges,
  userRaings,
  useSavedLists,
  userProfileDetailLoading,
  userPlannedActivitiesStats,
  userPlannedActivityStats,
  userBuzzmiActivity,
  userBuzzmiActivitiesList,
};
