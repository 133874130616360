import React from 'react';
import AddFavouritesIcon from '../icons/AddFavouritesIcon';
import HideIcon from '../icons/HideIcon';
import QuestionIcon from '../icons/QuestionIcon';
import SaveIcon from '../icons/SaveIcon';
import UnfollowIcon from '../icons/UnfollowIcon';
import FollowIcon from '../icons/FollowIcon';
import InformationIcon from '../icons/informationIcon';
import DeleteIcon from '../icons/DeleteIcon';
import EditIcon from '../icons/EditIcon';
import ShareIcon from '../icons/ShareIcon';
import NotificationIcon from '../icons/NotificationIcon';
import MuteIcon from '../icons/MuteIcon';
import SendMessageIcon from '../icons/SendMessageIcon';
import CopyLinkIcon from '../icons/CopyLinkIcon';

// If user opens menu in own post
export const MyPostMenuData = [
  {
    id: 1,
    alt: 'edit',
    text: 'Edit',
    icon: <EditIcon isHovered={false} />,
  },
  {
    id: 2,
    alt: 'delete',
    text: 'Delete',
    icon: <DeleteIcon isHovered={false} />,
  },
];



export const ProfileMenuData = [
  {
    id: 1,
    alt: 'share',
    text: 'Share',
    icon: <ShareIcon isHovered={false} />,
  },
  {
    id: 2,
    alt: 'report',
    text: 'Report',
    icon: <HideIcon isHovered={false} />,
  },
  {
    id: 3,
    alt: 'unfollow',
    text: 'Unfollow',
    icon: <UnfollowIcon isHovered={false} />,
  },
  {
    id: 5,
    alt: 'information',
    text: 'About this account',
    icon: <InformationIcon isHovered={false} />,
  },
  {
    id: 6,
    alt: 'question',
    text: 'Why you seen this content?',
    icon: <QuestionIcon isHovered={false} />,
  },
];


// If user opens menu in another's post
export const AnothersPostMenuData = [
  {
    id: 1,
    alt: 'save',
    text: 'Save',
    icon: <SaveIcon isHovered={false} />,
  },
  // {
  //   id: 2,
  //   alt: 'add favourites',
  //   text: 'Add favourites',
  //   icon: <AddFavouritesIcon isHovered={false} />,
  // },
  {
    id: 3,
    alt: 'follow',
    text: 'Follow',
    icon: <FollowIcon isHovered={false} />,
  },
  {
    id: 4,
    alt: 'hide',
    text: 'Hide',
    icon: <HideIcon isHovered={false} />,
  },
  {
    id: 5,
    alt: 'information',
    text: 'About this account',
    icon: <InformationIcon isHovered={false} />,
  },
  {
    id: 6,
    alt: 'question',
    text: 'Why you seen this content?',
    icon: <QuestionIcon isHovered={false} />,
  },
];

// If user opens friend's card
export const FriendCardMenuData = [
  {
    id: 1,
    alt: 'notfication',
    text: 'Manage notification',
    icon: <NotificationIcon isHovered={false} />,
  },
  {
    id: 2,
    alt: 'mute',
    text: 'Mute',
    icon: <MuteIcon isHovered={false} />,
  },
  {
    id: 3,
    alt: 'send a message',
    text: 'Send a message',
    icon: <SendMessageIcon isHovered={false} />,
  },
  {
    id: 4,
    alt: 'copy link',
    text: 'Copy link',
    icon: <CopyLinkIcon isHovered={false} />,
  },
  {
    id: 5,
    alt: 'share this profile',
    text: 'Share this profile',
    icon: <ShareIcon isHovered={false} />,
  },
];
