import {
  ClosedCaptionButton,
  ControlBar,
  Player,
  ProgressControl,
} from 'video-react';
import React, {useEffect, useRef, useState} from 'react';
import parsedTime from '../parsedTime';

const VideoPlayerOld = ({data}) => {
  const [playerState, setPlayerState] = useState({});
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [currentSource, setCurrentSource] = useState(data.sources[0]);
  const playerRef = useRef(null);
  const ccRef = useRef(null);
  const settingMenuRef = useRef(null);

  const play = () => {
    if (playerRef.current) {
      playerRef.current.play();
    }
  };

  const handleToggleFullscreen = () => {
    if (playerRef.current) {
      playerRef.current.toggleFullscreen();
    }
  };

  const handleStateChange = state => {
    setPlayerState(state);
  };

  const handleTextTrackChange = () => {
    if (ccRef.current) {
      ccRef.current.getTextTrackItems(data.tracks);
    }
  };

  const handleSettingsChange = () => {
    setMenuVisible(!isMenuVisible);
  };

  const handleDocumentClick = event => {
    if (
      isMenuVisible &&
      settingMenuRef.current &&
      !settingMenuRef.current?.contains(event.target)
    ) {
      setMenuVisible(false);
    }
  };

  const handleChangeSource = source => {
    setCurrentSource(source);
  };

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.subscribeToStateChange(handleStateChange);
    }

    setCurrentSource(data.sources[data.sources.length - 1]);

    document.addEventListener('mousedown', handleDocumentClick);

    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, []);

  useEffect(
    () => setCurrentSource(data.sources[data.sources.length - 1]),
    [data],
  );

  return (
    <div className="video">
      <Player ref={playerRef} src={currentSource.src}>
        {data.tracks.length > 0 &&
          data.tracks?.map((track, index) => (
            <track
              kind="captions"
              src={track.src}
              srcLang={track.lang}
              label={track.label}
              key={index + 'video'}
            />
          ))}

        {/* {playerState?.paused && (
          <button onClick={play} className="video__play ">
            <img src="/images/assets/video/play.svg" alt="play video" />
          </button>
        )} */}
        <div className="video__custom-controller video-react-control-bar">
          <button className="video__settings video-react-menu-button-popup video-react-button video-react-menu-button">
            <img
              onClick={handleSettingsChange}
              src="/images/assets/video/settings.svg"
              alt="settings"
            />
            <div className="video__settings-wrapper">
              {isMenuVisible && (
                <div
                  ref={settingMenuRef}
                  className="video-react-menu video-react-lock-showing">
                  <ul className="video-react-menu-content">
                    <li className="video__main-item">
                      {currentSource.quality}
                      <ul className="video__sub-menu">
                        {data.sources.map((source, index) => (
                          <li
                            key={index + 'video'}
                            onClick={() => handleChangeSource(source)}>
                            {source.quality}
                          </li>
                        ))}
                      </ul>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </button>
          <button onClick={handleToggleFullscreen} className="video__expand ">
            <img src="/images/assets/video/expand.svg" alt="expand video" />
          </button>
          <div className="video__time">
            {parsedTime(playerState?.currentTime)} /{' '}
            <span>{parsedTime(playerState?.duration)}</span>
          </div>
          <div className="video__info">
            {data?.title && <div className="h6 mb-1">{data.title}</div>}
            {data?.subtitle && <div className="text-r">{data.subtitle}</div>}
          </div>
          <button onClick={handleTextTrackChange}>Text track</button>
        </div>

        <ControlBar className="video-control" disableDefaultControls>
          <ProgressControl className="video-control__progress" />
          <ClosedCaptionButton ref={ccRef} />
        </ControlBar>
      </Player>
    </div>
  );
};

export default VideoPlayerOld;
