import React from "react"

const ShareIcon = ({ isHovered }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.8627 3.15161C16.4458 2.72379 15.8289 2.56441 15.2537 2.73218L1.83983 6.6329C1.23292 6.80151 0.802743 7.28553 0.686862 7.90042C0.568481 8.52621 0.981983 9.32062 1.5222 9.65281L5.71642 12.2306C6.1466 12.4949 6.70182 12.4286 7.0578 12.0696L11.8606 7.23688C12.1024 6.98522 12.5025 6.98522 12.7443 7.23688C12.9861 7.48015 12.9861 7.87441 12.7443 8.12607L7.93316 12.9596C7.57635 13.3178 7.50966 13.8756 7.77226 14.3085L10.335 18.5448C10.6351 19.0481 11.152 19.3333 11.7189 19.3333C11.7856 19.3333 11.8606 19.3333 11.9273 19.3249C12.5776 19.241 13.0944 18.7964 13.2862 18.1673L17.2628 4.77062C17.4379 4.20019 17.2795 3.57943 16.8627 3.15161"
        fill={isHovered ? "url(#shareGradient)" : "#C0BACB"}
      />
      {isHovered && (
        <defs>
          <linearGradient id="shareGradient" gradientTransform="rotate(45)">
            <stop offset="0%" stopColor="#EF8ED5" />
            <stop offset="50%" stopColor="#EC44D0" />
            <stop offset="100%" stopColor="#C1169C" />
          </linearGradient>
        </defs>
      )}
    </svg>
  )
}

export default ShareIcon
