import { Squares2X2Icon, VideoCameraIcon, ViewColumnsIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ProfileMembers from '../profileMembers/ProfileMembers';
import VisitorProfileContentList from '../VisitorProfileContentList';

const VisitorProfileContentTabs = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleTabSelect = index => {
    setActiveTabIndex(index);
  };

  return (
    <Tabs
      className="profile-section"
      selectedIndex={activeTabIndex}
      onSelect={handleTabSelect}>
      <TabList>
        <Tab style={{ display: 'flex', justifyContent: "center", color: '#e536af', opacity: activeTabIndex == 0 ? 1 : 0.4 }}><img src={"/images/social/grid.png"} className="h-8 w-8" /></Tab>
        <Tab style={{ display: 'flex', justifyContent: "center", color: '#e536af', opacity: activeTabIndex == 1 ? 1 : 0.4 }}><img src={"/images/social/vid.png"} className="h-7 w-7" /></Tab>
        <Tab style={{ display: 'flex', justifyContent: "center", color: '#e536af', opacity: activeTabIndex == 2 ? 1 : 0.4 }}><img src={"/images/social/premium.png"} className="h-7 w-7" /></Tab>
        <Tab style={{ display: 'flex', justifyContent: "center", color: '#e536af', opacity: activeTabIndex == 3 ? 1 : 0.4 }}><img src={"/images/social/exclusive.png"} className="h-7 w-7" /></Tab>
      </TabList>

      <TabPanel key="posts">
        <VisitorProfileContentList isPosts={true} />
      </TabPanel>
      <TabPanel key="reel">
        <VisitorProfileContentList isPosts={false} />
      </TabPanel>
      <TabPanel key="premium">
        <ProfileMembers />
      </TabPanel>
      <TabPanel key="exclusive">
        <ProfileMembers />
      </TabPanel>
    </Tabs>
  );
};

export default VisitorProfileContentTabs;
