export const colors = {
  TRIPLET_PLACEHOLDER: '#c0bacb',
  PRIMARY_COLOR: '#EB4DAC',
  //'#6529e2',
  FIELD_BORDER_COLOR: '#6529e2',
  DARK_GRAY: '#192137',
  LIGHT_GRAY: '#AFC1D6',
  BLACK: '#000',
  WHITE: '#FFF',
  HAWKES_BLUE: '#c6d2e3',
  HUSKY: '#DFEBF9',
  REGULAR_TEXT_COLOR: '#192137',

  BORDER_SECONDARY: '#C0BACB',

  BLUE_ACTION: '#4285F4',

  DARK_GREEN: '#2FAA53',
  LIGHT_GREEN: '#E6F8EB',
  DARK_RED: '#E1353C',
  BORDER_COLOR: '#E9F1FA',
  BORDER_SPACE_COLOR: '#f4f6fc',

  DARK_GRAY_91: '#8191ab',
  LIGHT_PRIMARY_COLOR: '#af8bf5',
  DARK_THUMB: '#99999a',
  DARK_AUDIO_WAVE: '#BDBDBD',

  SERACH_BACK_COLOR: '#e1e6ec',
  OSLO_GRAY: '#80838a',
  GHOST_WHITE: '#f5f6fb',
  GHOST_DARK: '#8592a2',

  COTTON_BALL: '#E8F1FB',
  LIGHT_GRAY_BG: '#f2f2f6',
  DARK_BORDER_GRAY: '#aaaaaa',
  DARK_GRAY_93: '#828fa2',

  TRANS: 'transparent',
  WHITE2: '#EAEFFF',
  WHITE3: '#F5F5F5',
  BLACK2: '#131313',
  GRAY2: '#DADADA',
  TINT_BORDER: '#C4B3E7',
  PAINT_BORDER: '#E0E8EF',
  Error: 'rgb(234,0,79)',

  MUZLI_BLACK: '#584F68',

  DARK_YELLOW: '#F2C438',

  ACCENT_COLOR: '#EB4DAC'
};
