export interface PostResponse {
  id: string;
  slug: string;
  mappingFields: MappingField[];
  fields: PostResponseField[];
  followersCount: number;
  postTypeId: string;
  reactionsCount: number;
  hasMoreContent: boolean;
  isAnonymous: boolean;
  isHidden: boolean;
  shortContent: string;
  createdAt: Date;
  publishedAt: Date;
  ownerId: string;
  createdById: string;
  status: string;
  spaceId: string;
  imageIds: any[];
  pinnedInto: any[];
  repliesCount: number;
  totalRepliesCount: number;
  locked: boolean;
  repliedToIds: any[];
  repliedToId: null;
  topicIds: any[];
  title: string;
  thumbnail: null;
  embedIds: any[];
  mentionedMembers: any[];
  primaryReactionType: string;
  lastActivityAt: Date;
  language: string;
  customSeoDetail: CustomSEODetail;
  relativeUrl: string;
  url: string;
  owner: PostResponseOwner;
  reactions: any[];
  replies: Replies;
}

export interface CustomSEODetail {
  description: null;
  noIndex: null;
  thumbnail: null;
  thumbnailId: null;
  title: null;
}

export interface PostResponseField {
  key: string;
  value: string;
  relationEntities: null;
}

export interface MappingField {
  key: string;
  type: string;
  value: string;
}

export interface PostResponseOwner {
  __typename: string;
  member: Member;
}

export interface Member {
  displayName: null;
  name: string;
  id: string;
  locale: string;
  profilePictureId: null | string;
  bannerId: null;
  status: string;
  username: string;
  email: string;
  emailStatus: string;
  newEmail: null;
  tagline: null;
  lastSeenAt: Date | null;
  createdAt: Date;
  updatedAt: Date;
  relativeUrl: string;
  url: string;
  externalId: string;
  roleId: string;
  fields?: MemberField[];
}

export interface MemberField {
  key: string;
  value: string;
}

export interface Replies {
  nodes: Node[];
  pageInfo: PageInfo;
  totalCount: number;
}

export interface Node {
  id: string;
  slug: string;
  mappingFields: MappingField[];
  fields: PostResponseField[];
  followersCount: number;
  postTypeId: string;
  reactionsCount: number;
  hasMoreContent: boolean;
  isAnonymous: boolean;
  isHidden: boolean;
  shortContent: string;
  createdAt: Date;
  publishedAt: Date;
  ownerId: string;
  createdById: string;
  status: string;
  spaceId: string;
  imageIds: any[];
  pinnedInto: any[];
  repliesCount: number;
  totalRepliesCount: number;
  locked: boolean;
  repliedToIds: string[];
  repliedToId: string;
  topicIds: any[];
  title: string;
  thumbnail: null;
  embedIds: any[];
  mentionedMembers: any[];
  primaryReactionType: string;
  lastActivityAt: Date;
  language: string;
  customSeoDetail: CustomSEODetail;
  relativeUrl: string;
  url: string;
  owner: NodeOwner;
}

export interface NodeOwner {
  __typename: string;
  member: Member;
  role: null;
  space: null;
}

export interface PageInfo {
  endCursor: string;
  hasNextPage: boolean;
}

export const getVideoPosterURL = url => {
  try {
    const count = url?.split('.');
    const extension = count[count?.length - 1];

    return url.replace(extension, 'png');
  } catch (e) {
    return url;
  }
};

export function getContentValue(arr, key = 'content') {
  const contentObj = arr?.find(obj => obj.key === key);
  return contentObj ? JSON.parse(contentObj.value) : null;
}

// export function extractUrlsFromArray(arr) {
//   const urls = [];
//
//   arr.forEach(obj => {
//     const {value} = obj;
//     if (typeof value === 'string') {
//       const regex = /https?:\/\/[^\s,]+/g;
//       const matches = value.match(regex);
//       if (matches && matches.length > 0) {
//         urls.push(...matches);
//       }
//     }
//   });
//
//   return urls;
// }

export function extractUrlsFromArray(arr, initKey = 'content') {
  const urls = [];

  arr.forEach(obj => {
    const {key, value} = obj;
    if (key === initKey && typeof value === 'string') {
      const urlRegex = /(https?:\/\/[^\s,\\"]+)/g;
      const matches = value.match(urlRegex);
      if (matches && matches.length > 0) {
        urls.push(...matches);
      }
    }
  });

  return urls;
}

export function extractAvatarUrlsFromArray(arr) {
  const urls = [];

  arr.forEach(obj => {
    const {value} = obj;
    if (typeof value === 'string') {
      const regex = /https?:\/\/[^\s,\\"]+/g;
      const matches = value.match(regex);
      if (matches && matches.length > 0) {
        urls.push(...matches);
      }
    }
  });

  return urls;
}
