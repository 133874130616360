const formatRelativeDate = (date) => {
  const currentDate = new Date()
  const timeDifference = currentDate.getTime() - date?.getTime()
  const seconds = Math.floor(timeDifference / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const weeks = Math.floor(days / 7)
  const months = Math.floor(days / 30)
  const years = Math.floor(days / 365)

  if (years >= 1) {
    return `${years} year${years > 1 ? "s" : ""} ago`
  } else if (months >= 1) {
    return `${months} month${months > 1 ? "s" : ""} ago`
  } else if (weeks >= 1) {
    return `${weeks} week${weeks > 1 ? "s" : ""} ago`
  } else if (days >= 1) {
    return `${days} day${days > 1 ? "s" : ""} ago`
  } else if (hours >= 1) {
    return `${hours} hour${hours > 1 ? "s" : ""} ago`
  } else if (minutes >= 1) {
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`
  } else {
    return `${seconds} second${seconds !== 1 ? "s" : ""} ago`
  }
}

export default formatRelativeDate
