import {useAtom} from 'jotai';
import {userPlannedActivitiesStatsGetTime, userStatListing} from '../hubState';
import {getStatListing} from '../../../network/api/app';
import {getCurrentTime} from '../usePlannedActivities';

export const useStatListing = () => {
  const [statListing, setStatListing] = useAtom(userStatListing);
  const [plannedActivitiesStatsGetTime, setPlannedActivitiesStatsGetTime] =
    useAtom(userPlannedActivitiesStatsGetTime);

  const getMyStatListing = async (token, setLoading, params) => {
    setLoading(true);
    try {
      // console.log('params ===> ', params);
      const response = await getStatListing(params)({token});
      setStatListing(response);
      setPlannedActivitiesStatsGetTime(getCurrentTime());
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log('error => ', e.message);
    }
  };

  return {
    statListing,
    getMyStatListing,
    plannedActivitiesStatsGetTime,
  };
};
