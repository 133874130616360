export function calculateAverageRating(reviews) {
  let totalRating = 0
  let totalReviews = reviews.length

  for (const review of reviews) {
    totalRating += review.rating
  }

  const averageRating = totalRating / totalReviews
  return averageRating
}
