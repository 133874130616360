import axios from 'axios';
import {
  BASE_URL,
  HeaderParams,
  getAuthHeader,
  getMultipartAuthHeader,
} from './constants';

const GET = async (url, params) => {
  try {
    const response = await axios.get(
      url.includes('http') ? url : `${BASE_URL}${url}`,
      {
        headers: url.includes('http')
          ? HeaderParams
          : getAuthHeader(params.token),
        params: params?.body ? params?.body : null,
      },
    );
    if (url.includes('http')) {
      return response;
    } else {
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 401 || err?.response?.status === 400) {
      throw new Error(err?.response?.data.message);
    } else {
      throw new Error(err?.message);
    }
  }
};

const POST = async (url, is_auth, token, postData) => {
  try {
    const response = await axios.post(
      url.includes('http') ? url : `${BASE_URL}${url}`,
      postData,
      {
        headers: is_auth ? getAuthHeader(token) : HeaderParams,
      },
    );
    if (url.includes('http')) {
      return response;
    } else {
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 401 || err?.response?.status === 400) {
      throw new Error(err?.response?.data.message);
    } else {
      throw new Error(err?.message);
    }
  }
};
const PATCH = async (url, is_auth, token, postData) => {
  try {
    // console.log(token);

    const response = await axios.patch(
      url.includes('http') ? url : `${BASE_URL}${url}`,
      postData,
      {
        headers: is_auth ? getAuthHeader(token) : HeaderParams,
      },
    );

    if (url.includes('http')) {
      return response;
    } else {
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 401 || err?.response?.status === 400) {
      throw new Error(err?.response?.data.message);
    } else {
      throw new Error(err?.message);
    }
  }
};

const PUT = async (url, is_auth, token, postData) => {
  try {
    const response = await axios.put(
      url.includes('http') ? url : `${BASE_URL}${url}`,
      postData,
      {
        headers: is_auth ? getAuthHeader(token) : HeaderParams,
      },
    );

    if (url.includes('http')) {
      return response;
    } else {
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 401 || err?.response?.status === 400) {
      throw new Error(err?.response?.data.message);
    } else {
      throw new Error(err?.message);
    }
  }
};

const DELETE = async (url, params) => {
  try {
    const fullURL = url.includes('http') ? url : `${BASE_URL}${url}`;
    const response = await axios.delete(fullURL, {
      headers: url.includes('http')
        ? HeaderParams
        : getAuthHeader(params.token),
      params: params?.body ? params?.body : null,
    });

    return url.includes('http') ? response : response.data;
  } catch (err) {
    if (err?.response?.status === 401 || err?.response?.status === 400) {
      throw new Error(err?.response?.data.message);
    } else {
      throw new Error(err?.message);
    }
  }
};
const DELETE_WITH_BODY = async (url, params) => {
  try {
    const response = await axios.delete(
      url.includes('http') ? url : `${BASE_URL}${url}`,
      {
        headers: url.includes('http')
          ? HeaderParams
          : getAuthHeader(params.token),
        data: params?.body ? params?.body : null,
      },
    );

    if (url.includes('http')) {
      return response;
    } else {
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 401 || err?.response?.status === 400) {
      throw new Error(err?.response?.data.message);
    } else {
      throw new Error(err?.message);
    }
  }
};

const POST_MULTIPART = async (url, is_auth, token, postData) => {
  try {
    console.log(token);

    const response = await axios.post(
      url.includes('http') ? url : `${BASE_URL}${url}`,
      postData,
      {
        headers: is_auth ? getMultipartAuthHeader(token) : HeaderParams,
      },
    );

    if (url.includes('http')) {
      return response;
    } else {
      return response.data;
    }
  } catch (err) {
    if (err?.response?.status === 401 || err?.response?.status === 400) {
      throw new Error(err?.response?.data.message);
    } else {
      throw new Error(err?.message);
    }
  }
};

export {GET, POST, POST_MULTIPART, PATCH, DELETE, PUT, DELETE_WITH_BODY};
