import React, { useState, useEffect, useRef } from 'react';

import { pageNav } from '../../../../mock-data/hub/hub.js';
import HubTaskList from '../Items/form/checkList/items/HubTaskList.jsx';
import PictureUploaderList from '../../../../components/reusable/uploader/pictureUploader/PictureUploaderList.jsx';
import CustomizableCircleChartWrapper from '../../../../components/reusable/assets/charts/customizableCircleChart/CustomizableCircleChartWrapper.jsx';
import Box from '../../../../components/reusable/cards/box/Box.jsx';
import HubBasicInfo from './items/HubBasicInfo.jsx';
import HubNote from './items/HubNote.jsx';
import { useFormValidator } from '../../../../form-validator/hooks/index.js';
import Form from '../../../../components/reusable/form/Form.jsx';
import ValidationSchema from '../../../../form-validator/ValidationSchema.js';
import HubMedia from '../Items/hubMedia/HubMedia.jsx';
import addImg from '../../../../assets/add_icon.png';

import MultiBox from '../../../../components/reusable/cards/multiBox/MultiBox.jsx';
import { mocData } from './mocData.js';

import Description from '../../../../components/reusable/assets/description/Description.jsx';
import { useDeletePlannedActivity } from '../../../../appState/hooks/useDeletePlannedActivity';
import { useBuzzmiAuth } from '../../../../appState/hooks/useBuzzmiAuth/index.web.js';
import { EventRegister } from 'react-native-event-listeners';
import Loading from 'react-fullscreen-loading';
import { colors } from '../../../../res/colors.js';
import { useNavigation } from '@react-navigation/core';
import { useToast } from 'react-native-toast-notifications';
import { ShowSuccess } from '../../../HOC/MessageWeb.js';
import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/24/outline';

import { useUpdatePlanActivity } from '../../../../appState/hooks/useUpdatePlanActivity';

import DeleteActivityModal from '../../../../components/modal/modals/DeleteActivity/index.js';
import SubmitButton from '../../../../components/reusable/btns/buttons/submitButton/SubmitButton.jsx';
import moment from 'moment';
import { useCompletePlannedActivity } from '../../../../appState/hooks/useCompletePlannedActivity/index.js';
import { usePlannedActivityStat } from '../../../../appState/hooks/usePlannedActivityStat/index.js';
import {
  getCurrentMonthDates,
  getCurrentWeekDates,
  getLastMonthDates,
  getLastWeekDates,
} from '../../../../utils/utilitiesFunctions.js';
import { useCompletePlannedTasks } from '../../../../appState/hooks/useCompletePlannedTasks/index.js';
import Badge from '../../../../components/reusable/badge/Badge.jsx';
import LightBox from '../../../../components/lightBox/LightBox.jsx';
import VideoPlayer from '../../../../components/reusable/videoPlayer/VideoPlayer.jsx';
import { Tooltip } from 'react-tooltip'

const validationSchema = new ValidationSchema({});

export const isToday = inputDate => {
  // Create a moment object for the input date
  var inputMoment = moment(inputDate, 'YYYY-MM-DD');

  // Create a moment object for the current date
  var todayMoment = moment();

  // Check if the input date is the same as today (considering only the day part)
  return inputMoment.isSame(todayMoment, 'day');
};

function isTodayOrPast(inputDate) {
  // Create a moment object for the input date
  var inputMoment = moment(inputDate, 'YYYY-MM-DD');

  // Create a moment object for the current date
  var todayMoment = moment().startOf('day'); // Consider only the date part

  // Check if the input date is the same as today or in the past
  return inputMoment.isSameOrBefore(todayMoment);
}

const getCurrentType = (list, id) => list?.filter(el => el.id === id)[0]?.text;
const HubTaskInfov2 = props => {
  // let { id } = useParams()
  const { navigate } = useNavigation();
  const data = mocData[0];
  const refCheckTags = useRef(null);
  const refAddMedia = useRef(null);
  const { deletePlanned } = useDeletePlannedActivity();
  const { postUpdatePlanActivity, isUpdatePlanActivityLoading } =
    useUpdatePlanActivity();
  const [btnValid, setBtnValid] = useState(false);

  const [calendarValue, setCalendarValue] = useState(
    getCurrentWeekDates().startDate,
  );
  const { userData } = useBuzzmiAuth();
  const [viewTypeId, setViewTypeId] = useState(1);
  const [currentType, setCurrentType] = useState(getCurrentType(pageNav, 1));
  const [error, setError] = useState(null);
  const [deleteLoading, setdeleteLoading] = useState(false);
  const toast = useToast();
  const { item, showModal } = props;
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const [youtubelink, setYoutubeLink] = useState(false);

  const {
    getOnePlannedActivityStatsApi,
    setPlannedActivityStats,
    plannedActivityStats,
  } = usePlannedActivityStat();

  const { patchCompletePlannedTasks, CompletePlannedTasksResponse } =
    useCompletePlannedTasks();

  useEffect(() => {
    getOnePlannedActivityStatsApi(
      {
        id: item?.mainTaskId,
        startDate: getLastMonthDates().startDate,
        endDate: getCurrentMonthDates().endDate,
      },
      userData.token,
    );
    return () => {
      setPlannedActivityStats([]);
    };
  }, []);
  useEffect(() => {
    if (currentType === data.viewTypeList.doneLastWeek) {
      setCalendarValue(getLastWeekDates().startDate);
    }
    if (currentType === data.viewTypeList.doneThisWeek) {
      setCalendarValue(getCurrentWeekDates().startDate);
    }
    if (currentType === data.viewTypeList.doneThisMonth) {
      setCalendarValue(getCurrentMonthDates().startDate);
    }
    if (currentType === data.viewTypeList.doneLastMonth) {
      setCalendarValue(getLastMonthDates().startDate);
    }
  }, [currentType]);

  // useEffect(() => {
  //   console.log('planned item===>', item);
  // }, [item]);

  const isComplete = useRef(false);
  // const sendRequest = async formData => {
  //   try {
  //     //TODO: send request
  //     console.log('formData', formData, item);
  //     // postUpdatePlanActivity(item.id, {
  //     //   goal: `${formData.progressInfo.currentValue / 60000} mins`,
  //     // });
  //   } catch (e) {
  //     setError(e);
  //   }
  // };
  const {
    patchCompletePlannedActivity,
    isCompletePlannedActivityError,
    isCompletePlannedActivityLoading,
    CompletePlannedActivityResponse,
  } = useCompletePlannedActivity();
  const sendCompleteRequest = async onCompleteTask => {
    try {
      let param_data = Object.assign({
        isCompleted: true,
        goal: item?.goal,
      });
      // console.log('item', item);
      // console.log('onCompleteTask', onCompleteTask);

      // if (isTodayOrPast(item.endDate)) {

      // if (isToday(item.endDate)) {
      //   patchCompletePlannedActivity(item?.id, param_data);
      //   if (onCompleteTask) {
      //     onCompleteTask();
      //   }
      // } else {
      //   window.alert(
      //     'You can mark tasks as completed only if they are planned for today',
      //   );
      // }
    } catch (e) {
      setError(e);
    }
  };
  useEffect(() => {
    if (CompletePlannedActivityResponse || CompletePlannedTasksResponse) {
      showModal(false);
    }
  }, [CompletePlannedActivityResponse, CompletePlannedTasksResponse]);

  const getGoalType = it => {
    if (it?.type == 'time') return 'mins';
    if (it?.type == 'distanceMetric') return 'km';
    return '';
  };

  const sendRequest = async formData => {
    try {
      let param_data = {};
      !item?.isCompleted &&
        Object.assign(param_data, {
          isCompleted: isComplete.current,
        });

      !item?.isCompleted &&
        item?.goal &&
        formData.progressInfo.currentValue > 0 &&
        Object.assign(param_data, {
          goalProgress:
            formData.progressInfo.type == 'time'
              ? `${formData.progressInfo.currentValue / 60000} ${item?.goal?.includes('seconds') ? 'seconds' : 'mins'
              }`
              : formData.progressInfo.type == 'distanceMetric'
                ? `${formData.progressInfo.currentValue} km`
                : `${formData.progressInfo.currentValue} ${formData.progressInfo.type}`,
        });

      !isComplete.current &&
        formData?.images?.length > 0 &&
        Object.assign(param_data, {
          progressMedia: Object.values(formData?.images).map((it, ind) => {
            return {
              title: formData?.progimgTitles?.[ind] || '',
              description: formData?.progimgDes?.[ind] || '',
              src: it || '',
            };
          }),
        });
      console.log('goalProgress', param_data);
      if (
        param_data?.isCompleted ||
        param_data?.goalProgress != item?.goalProgress ||
        param_data?.progressMedia?.length
      ) {
        if (param_data?.isCompleted) {
          console.log('call');
          patchCompletePlannedTasks(item?.id, {
            isCompleted: true,
            goalProgress: item?.goal,
          });
          return;
        }
      }
      if (formData?.tasks?.length > 0) {
        postUpdatePlanActivity(item?.mainTaskId, {
          checkList: formData?.tasks.length > 0 ? formData?.tasks : null,
        });
      }

      if (
        param_data?.isCompleted ||
        param_data?.goalProgress != item?.goalProgress ||
        param_data?.progressMedia?.length
      ) {
        console.log('param_data', param_data);
        if (param_data?.isCompleted) {
          patchCompletePlannedTasks(item?.id, {
            isCompleted: true,
            goalProgress: item?.isCustomGoal ? item?.customGoal : item?.goal,
          });
        } else {
          patchCompletePlannedActivity(item?.id, param_data);
        }
      }
    } catch (e) {
      setError(e);
    }
  };

  // console.log('item----===>', item);

  const { handleSubmit, setValue, values, isValid } = useFormValidator(
    validationSchema,
    sendRequest,
    {
      defaultValues: {
        image: item?.image,
        title: item?.title,
        description: item?.description,
        images:
          item?.progressMedia?.media?.length > 0
            ? item?.progressMedia?.media.map(item => item.src)
            : item?.progressImages?.images?.length > 0
              ? item?.progressImages?.images
              : [],
        progressInfo: {
          ...data.progressInfo, // TODO: need to remove dummy data after adding all types...
          ...(item?.goal?.includes('mins') && {
            currentValue:
              item?.goalProgress?.length > 0
                ? Number(item?.goalProgress.split(' ')[0]) * 60000
                : 0,
            initValue: Number(item?.goal.split(' ')[0]) * 60000,
            type: 'time',
          }),
          ...(item?.goal?.includes('seconds') && {
            currentValue:
              item?.goalProgress?.length > 0
                ? Number(item?.goalProgress.split(' ')[0]) * 1000
                : 0,
            initValue: Number(item?.goal.split(' ')[0]) * 1000,
            type: 'time',
          }),
          ...(item?.goal?.includes('km') && {
            currentValue:
              item?.goalProgress?.length > 0
                ? Number(item?.goalProgress.split(' ')[0])
                : 0,
            initValue: Number(item?.goal.split(' ')[0]),
            type: 'distanceMetric',
          }),
          ...(item?.goal?.includes('meter') && {
            currentValue:
              item?.goalProgress?.length > 0
                ? Number(item?.goalProgress.split(' ')[0])
                : 0,
            initValue: Number(item?.goal.split(' ')[0]),
            type: 'meter',
          }),
          ...(item?.goal?.includes('ft') && {
            currentValue:
              item?.goalProgress?.length > 0
                ? Number(item?.goalProgress.split(' ')[0])
                : 0,
            initValue: Number(item?.goal.split(' ')[0]),
            type: 'ft',
          }),
          ...(item?.goal?.includes('cm') && {
            currentValue:
              item?.goalProgress?.length > 0
                ? Number(item?.goalProgress.split(' ')[0])
                : 0,
            initValue: Number(item?.goal.split(' ')[0]),
            type: 'cm',
          }),
          ...(item?.goal?.includes('inch') && {
            currentValue:
              item?.goalProgress?.length > 0
                ? Number(item?.goalProgress.split(' ')[0])
                : 0,
            initValue: Number(item?.goal.split(' ')[0]),
            type: 'inch',
          }),
          ...(item?.goal?.includes('glasses') && {
            currentValue:
              item?.goalProgress?.length > 0
                ? Number(item?.goalProgress.split(' ')[0])
                : 0,
            initValue: Number(item?.goal.split(' ')[0]),
            type: 'glasses',
          }),
          ...(item?.goal?.includes('times') && {
            currentValue:
              item?.goalProgress?.length > 0
                ? Number(item?.goalProgress.split(' ')[0])
                : 0,
            initValue: Number(item?.goal.split(' ')[0]),
            type: 'times',
          }),
          ...(item?.goal?.includes('sets') && {
            currentValue:
              item?.goalProgress?.length > 0
                ? Number(item?.goalProgress.split(' ')[0])
                : 0,
            initValue: Number(item?.goal.split(' ')[0]),
            type: 'sets',
          }),
          ...(item?.goal?.includes('cups') && {
            currentValue:
              item?.goalProgress?.length > 0
                ? Number(item?.goalProgress.split(' ')[0])
                : 0,
            initValue: Number(item?.goal.split(' ')[0]),
            type: 'cups',
          }),
          ...(item?.goal?.includes('oz') && {
            currentValue:
              item?.goalProgress?.length > 0
                ? Number(item?.goalProgress.split(' ')[0])
                : 0,
            initValue: Number(item?.goal.split(' ')[0]),
            type: 'oz',
          }),
          ...(item?.goal?.includes('liter') && {
            currentValue:
              item?.goalProgress?.length > 0
                ? Number(item?.goalProgress.split(' ')[0])
                : 0,
            initValue: Number(item?.goal.split(' ')[0]),
            type: 'liter',
          }),
          ...(item?.goal?.includes('ml') && {
            currentValue:
              item?.goalProgress?.length > 0
                ? Number(item?.goalProgress.split(' ')[0])
                : 0,
            initValue: Number(item?.goal.split(' ')[0]),
            type: 'ml',
          }),
          ...(item?.goal?.includes('gram') && {
            currentValue:
              item?.goalProgress?.length > 0
                ? Number(item?.goalProgress.split(' ')[0])
                : 0,
            initValue: Number(item?.goal.split(' ')[0]),
            type: 'gram',
          }),
          ...(item?.goal?.includes('calories') && {
            currentValue:
              item?.goalProgress?.length > 0
                ? Number(item?.goalProgress.split(' ')[0])
                : 0,
            initValue: Number(item?.goal.split(' ')[0]),
            type: 'calories',
          }),
        },
        tasks: item?.checkList?.list,
        progimgDes:
          item?.progressMedia?.media?.length > 0
            ? item?.progressMedia?.media.map(item => item.description)
            : item?.progressImagesDescriptions?.length > 0
              ? item?.progressImagesDescriptions
              : [],
        progimgTitles:
          item?.progressMedia?.media?.length > 0
            ? item?.progressMedia?.media.map(item => item.title)
            : item?.progressImagesTitles?.length > 0
              ? item?.progressImagesTitles
              : [],
        tags: item?.tags,
      },
    },
  );

  const handleDeletePlannedActivity = id => {
    setdeleteLoading(true);
    showModal(false);
    deletePlanned(
      id,
      userData.token,

      deleteResponse => {
        // console.log('deleteResponseHeres', deleteResponse);
        setdeleteLoading(false);
        ShowSuccess('Activity deleted successfully', toast);
        EventRegister.emit('refresh-planActivities');
        // navigate('HubTasks');
      },
    );
  };

  const onPageNavChange = id => {
    setViewTypeId(id);
    if (getCurrentType) {
      setCurrentType(getCurrentType(pageNav, id));
    }
  };
  // console.log('item here===>>>', item);
  useEffect(() => {
    if (values?.images?.length > 0) {
      if (
        values?.images?.length !== values?.progimgDes?.length ||
        values?.images?.length !== values?.progimgTitles?.length
      ) {
        setBtnValid(false);
      } else {
        setBtnValid(true);
      }
    }
  }, [
    values?.images?.length,
    values?.progimgDes?.length,
    values?.progimgTitles?.length,
  ]);

  useEffect(() => {
    if (values?.tasks?.length > 0) {
      setBtnValid(true);
    }
  }, [values?.tasks]);

  useEffect(() => {
    if (youtubelink) {
      console.log(youtubelink);
      setShowVideoPlayer(!showVideoPlayer);
    }
  }, [youtubelink]);

  let validBtn =
    values?.tasks?.length > 0 || values?.images?.length > 0
      ? isValid && btnValid
      : isValid;
  const viewOnly = true;
  return (
    // <MainLayoutWithAspectSide showRankInfo={false} showTitleButton={false}>
    <>
      <Loading
        loading={
          deleteLoading ||
          isUpdatePlanActivityLoading ||
          isCompletePlannedActivityLoading
        }
        background="transparent"
        loaderColor={colors.PRIMARY_COLOR}
      />
      <div className="hub">
        <div className="hub__content pt-1">
          {/* <BackBtnWithTitlePage
            title={item?.title || 'Treadmill'}
            btnText="Back to stats"
            // lastItemLink={{href: `/hub`, title: 'Edit Activity'}}
            lastItemLink={{href: `/hub`, title: ''}}
          /> */}
          <Form error={error} onSubmit={handleSubmit}>
            <div className="mb-2">
              {/*<InfoCard item={data.infoCardData} /> */}
              <Description
                item={{
                  image: values?.image,
                  title: values?.title,
                  description: values?.description,
                }}
                onChangeTitle={e => setValue('title', e.target.value)}
                onChangeDes={e => setValue('description', e.target.value)}
                isEdit={false}
                setValue={setValue}
              />
            </div>

            <HubMedia
              galleryList={item?.descriptionMedia?.values || []}
              audioSrcLink={item?.descriptionAudio}
              item={item}
            />
            {item?.mediaUrl?.length > 0 && (
              <>
                {item?.mediaUrl?.length > 0 && item?.mediaUrl[0] != '' && <h2 className={'text-sm mt-2 mb-2'}>{'Youtube content'}</h2>}

                <div className={'gallery mb-3 mb-md-8  justify-start gap-2'}>
                  {item?.mediaUrl.map((it, index) => {
                    return (
                      <>
                        {it != '' &&
                          <div
                            onClick={() => window.open(it, '_blank')}
                            className="gallery__item shadow-sm  rounded-md ">
                            <div className="overflow-hidden shadow-sm video-thumbnail-container items-center flex h-16 w-16 sm:h-20 sm:w-20  md:h-24 md:w-24 lg:h-24 lg:w-24 xl:h-24 xl:w-24 relative rounded-lg">
                              <img
                                src={`https://img.youtube.com/vi/${it.split('v=')[1]
                                  }/hqdefault.jpg`}
                                className={'w-15 h-15'}
                              />
                              <img
                                src="/images/ic_youtube.png"
                                className=" play-icon absolute self-center bottom-[15%] left-[15%] text-4xl z-[2]"
                                style={{
                                  maxWidth: '70%',
                                  height: 'auto',
                                }}></img>
                            </div>
                          </div>
                        }
                      </>
                    );
                  })}
                </div>
              </>
            )}

            {/* 
            <LightBox
              isOpened={showVideoPlayer}
              onClose={() => setShowVideoPlayer(false)}>
              <div className="video-gallery__player-body">
                <div className="video-gallery__player-holder">
                  <VideoPlayer
                    url={youtubelink}
                    title={"title"}
                    autoplayVideo={false}
                  />
                </div>
              </div>
            </LightBox> */}

            <>
              {/* <ProgressHeader
                text={
                  plannedActivityStats?.dayList?.length
                    ? plannedActivityStats?.dayList?.filter(
                        task => task.type === 'completed',
                      )?.length + ' completed'
                    : 0 + ' completed'
                }
              />
              <div className="mb-4">
                <PageNavigation
                  list={data.pageNav}
                  value={viewTypeId}
                  onChange={onPageNavChange}
                />
              </div> */}
              {/* <div className="box box_big mb-4">
                <Calendar
                  value={calendarValue}
                  onChange={setCalendarValue}
                  dayList={plannedActivityStats?.dayList}
                  period={
                    currentType === data.viewTypeList.doneLastMonth ||
                    currentType === data.viewTypeList.doneThisMonth
                      ? 'month'
                      : 'week'
                  }
                  noIndent
                  hasFooter
                  // buttonProps={{
                  //   text: 'View all stats',
                  //   onClick: () => null,
                  // }}
                />
              </div> */}
            </>

            <HubBasicInfo
              reminder={item?.remindingTime}
              repeat={
                item?.repeatIntervalDay?.days
                  ? `Repeat every ${item?.repeatInterval?.days} day`
                  : null
              }
              when={item?.timing}
            />

            {/* {values?.tags?.length > 0 && (
              <MultiBox
                setValue={setValue}
                // icon="/images/hub/form/hash.png"
                icon=""
                title={viewOnly ? Strings.tags : 'Add ' + Strings.tags}
                name="tags"
                className={'mb-3'}
                rightSide={
                  viewOnly
                    ? null
                    : !!setValue && (
                        <button
                          onClick={e => {
                            e.preventDefault();
                            refCheckTags?.current?.handleAdd();
                          }}
                          type="button ">
                          <img
                            src={addImg}
                            className="h-8 w-8 sm:w-10 sm:h-10 md:w-10 md:h-10 lg:w-12 lg:h-12 xl:w-12 xl:h-12 mr-2"
                            // onClick={() => console.log('khali')}
                          />
                        </button>
                      )
                }>
                <CheckTags
                  ref={refCheckTags}
                  value={values?.tags || []}
                  setValue={setValue}
                  valueName="tags"
                  viewOnly={viewOnly}
                />
              </MultiBox>
            )} */}
            {/*TODO: Need to pass this data while creating the planned-activty first... */}

            <>
              {item?.isCustomGoal ? (
                <Box className=" hub-form-box mb-4 flex justify-between">
                  <div className="me-21 me-lg-3">
                    <div className="h4 mb-1 text-sm">Goal</div>
                    <div className="text-r">{item?.customGoal}</div>
                  </div>
                  {item?.isCompleted ? (
                    <>
                      <Badge type={'Completed'}></Badge>
                    </>
                  ) : (
                    <div>
                      <div
                        onClick={e => {
                          e?.preventDefault();
                          isComplete.current = true;
                          handleSubmit(e);
                        }}
                        className={
                          'bg-[#F5EEFE] rounded-md ml-2 p-2 inline-block'
                        }>

                        <CheckCircleIcon className={'w-6 h-6 text-[#E739BD]'} />
                      </div>

                      <a onClick={() => { console.log("click") }} data-tooltip-id="my-tooltip" data-tooltip-content="Click to complete the goal progress" className={'ml-0 p-0 h-100 inline-block'}>
                        <InformationCircleIcon className={'w-4 h-4 text-[#1919d9] ml-1'} />
                      </a>

                      <Tooltip id="my-tooltip" />
                    </div>
                  )}
                </Box>
              ) : (
                <>
                  {item?.goal && (
                    <Box className=" hub-form-box mb-4">
                      <CustomizableCircleChartWrapper
                        values={values?.progressInfo}
                        item={item}
                        setValues={value => setValue('progressInfo', value)}
                        handleCompleteClick={e => {
                          isComplete.current = true;
                          e.handleComplete();
                          handleSubmit(e.e);
                        }}
                        isPreview={item?.isCompleted}
                      />
                    </Box>
                  )}
                </>
              )}
            </>

            {item?.isProgressImageEnable && (
              <div className="mb-4">
                <MultiBox
                  icon="/images/hub/form/photo.png"
                  title="Add photos of your progress"
                  miniMode
                  rightSide={
                    !!setValue && (
                      <button
                        onClick={e => {
                          e.preventDefault();
                          refAddMedia?.current?.handleAdd();
                        }}
                        type="button ">
                        <img
                          src={addImg}
                          className="h-8 w-8 sm:w-10 sm:h-10 md:w-10 md:h-10 lg:w-12 lg:h-12 xl:w-12 xl:h-12 mr-2"
                        // onClick={() => console.log('khali')}
                        />
                      </button>
                    )
                  }>
                  <PictureUploaderList
                    classNames="picture-uploader__wrapper_mod"
                    setValue={setValue}
                    defaultValues={values.images}
                    defaultValuesTitles={values?.progimgTitles}
                    defaultValuesDes={values?.progimgDes}
                    picture_type={'progress_photos'}
                    isBig
                    isProgress={true}
                    ref={refAddMedia}
                    isPreview={false}
                  />
                </MultiBox>
              </div>
            )}
            {item?.note?.trim()?.length > 0 && <HubNote value={item.note} />}

            {/* {item?.isCompleted == false && ( */}
            <>
              {values?.tasks?.length > 0 && (
                <div className="mb-4">
                  <MultiBox
                    icon="/images/hub/form/check-list.png"
                    title="Checklist"
                    miniMode>
                    <HubTaskList
                      value={values.tasks}
                      setValues={value => setValue('tasks', value)}
                    // viewOnly={viewOnly}
                    />
                  </MultiBox>
                </div>
              )}
            </>
            {/* )} */}

            {item?.createdBy == userData.id && (
              <>
                <div className="d-flex justify-content-center mt-5 mt-md-7 rounded-full overflow-hidden shadow-lg">
                  {/* <button
                    onClick={e => {
                      e.preventDefault();
                      setOpenDeleteModal(true);
                    }}
                    // className="flex items-center w-full justify-center rounded-md bg-pink-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pink-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600">
                    className="flex items-center w-full justify-center px-3 py-2 text-sm font-semibold text-pink-500 hover:bg-pink-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600">
                    <div className="flex items-center justify-center">
                      <img
                        src="/images/ic_trash.png"
                        class="w-[20px] m-auto mb-2.5"></img>
                      DELETE
                    </div>
                  </button> */}
                </div>

                {(item?.progressImages?.images?.length > 0 ||
                  item?.isProgressImageEnable == true ||
                  item?.checkList?.list?.length) > 0 && (
                    <div className="d-flex justify-content-center my-3 my-md-3 rounded-full overflow-hidden shadow-lg">
                      <SubmitButton
                        form="task-form"
                        isValid={validBtn}
                        // title={'SAVE'}
                        title={'UPDATE'}
                      />
                    </div>
                  )}
              </>
            )}
          </Form>
        </div>
      </div>
      {DeleteActivityModal(openDeleteModal, setOpenDeleteModal, () =>
        handleDeletePlannedActivity(item.id),
      )}
    </>
    // </MainLayoutWithAspectSide>
  );
};

export default HubTaskInfov2;
