export default {
  title: "Cycling",
  tags: [
    {
      title: "Cycling",
      value: "cycling",
    },
    {
      title: "Running",
      value: "running",
    },
    {
      title: "Swimming",
      value: "swimming",
    },
    {
      title: "Walking",
      value: "walking",
    },
    {
      title: "Other",
      value: "other",
    },
    {
      title: "Yoga",
      value: "yoga",
    },
    {
      title: "Pilates",
      value: "pilates",
    },
    {
      title: "Gym",
      value: "gym",
    },
  ],
}
