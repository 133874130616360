import React, { useEffect, useRef } from 'react';
import RegularButton from "../../../../components/reusable/form/items/buttons/RegularButton.jsx";
import GoogleSvg from "./icons/GoogleSVG.jsx";
import AppleSvg from "./icons/AppleSVG.jsx";
import FacebookSvg from "./icons/FacebookSVG.jsx";
import { useGoogleLogin } from '@react-oauth/google';
import { GET_USER_DATA } from '../../../../api_helper/Api.js';
import { useBuzzmiAuth } from "../../../../appState/hooks";
import { GET_DATA_WITHOUT_TOKEN } from '../../../../api_helper/ApiServices.js';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import AppleLogin from 'react-apple-login';
import jwt_decode from 'jwt-decode';

const QuadServicesButtons = (props) => {
    const refSocialUser = useRef(null);
    const refSocialType = useRef(null);
    const refSocialToken = useRef(null);
    const { socialLogin, resetSocial, loginError } = useBuzzmiAuth();

    useEffect(() => {
        if (loginError) {
            if (loginError?.includes('A222')) {
                console.log('Login Error (A222) => ', loginError);
            } else {
                if (refSocialType.current === 'Apple') {
                    const { user, userData } = refSocialUser?.current;
                    const tokenId = refSocialToken?.current;

                    props.props.navigation.navigate('Bio', {
                        user: Object.assign(userData, {
                            firstName: user?.name?.firstName || '',
                            lastName: user?.name?.lastName || '',
                            name: user?.name?.firstName + ' ' + user?.name?.lastName,
                            token: tokenId,
                            loginType: 'Apple'
                        }),
                    });
                } else if (refSocialType.current === 'Google') {
                    const data = refSocialUser?.current;
                    const tokenId = refSocialToken?.current;

                    props.props.navigation.navigate('Bio', {
                        user: Object.assign(data, {
                            token: tokenId,
                            firstName: data?.given_name || '',
                            lastName: data?.family_name || '',
                            loginType: 'Google'
                        }),
                    });
                } else if (refSocialType.current === 'Facebook') {
                    const tokenId = refSocialToken.current;
                    const data = refSocialUser.current;
                    console.log("facebook data")

                    if (data?.name) {
                        props.props.navigation.navigate('Bio', {
                            user: Object.assign(data, {
                                firstName: data?.name.split(" ")[0] || '',
                                lastName: data?.name.split(" ")[1] || '',
                                name: data?.name,
                                token: tokenId,
                                loginType: 'Facebook',
                                sub: data.id
                            }),
                        });
                    }
                } else {
                    console.log('Social login failed. :(');
                }
                resetSocial();
            }
        }
    }, [loginError]);

    const responseFacebook = (data) => {
        refSocialUser.current = data;
        refSocialType.current = 'Facebook';
        refSocialToken.current = data?.accessToken;
        socialLogin(data.id, 'Facebook')
    }

    const handleAppleClick = (apple_data) => {
        let data = jwt_decode(apple_data?.authorization?.id_token);

        refSocialType.current = 'Apple';
        refSocialUser.current = {
            user: apple_data?.user,
            userData: data,
        };
        refSocialToken.current = apple_data?.authorization?.id_token;

        socialLogin(data.sub, 'Apple');
    }

    const handleGoogleClick = useGoogleLogin({
        onSuccess: codeResponse => getUserInfo(codeResponse),
        flow: 'implicit',
        scope: 'profile',
    });

    const getUserInfo = async codeResponse => {
        GET_DATA_WITHOUT_TOKEN(GET_USER_DATA + "?access_token=" + codeResponse?.access_token,
            data => {
                refSocialUser.current = data;
                refSocialType.current = 'Google';
                refSocialToken.current = codeResponse?.access_token;
                socialLogin(data.sub, 'Google');
            },
        );
    };

    return (
        <div className="mt-6 grid-cols-3 gap-4 flex">
            <a style={{ backgroundColor: "#F4F1F8" }} onClick={handleGoogleClick} href="#" className="flex w-full items-center justify-center gap-3 rounded-md bg-[#F4F1F8] px-3 py-2 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#F4F1F8]" >
                <GoogleSvg />
            </a>


            <FacebookLogin
                appId="487893239992061"
                callback={responseFacebook}
                autoLoad={false}
                fields="name,email,picture"
                render={renderProps => (
                    // <RegularButton icon={<FacebookSvg />} className='_fw'
                    //     onClick={renderProps.onClick}>{Strings.continue_with_facebook}</RegularButton>
                    <a  style={{ backgroundColor: "#F4F1F8" }} onClick={renderProps.onClick} href="#" className="flex w-full items-center justify-center gap-3 rounded-md bg-[#F4F1F8] px-3 py-2 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#F4F1F8]">
                        <FacebookSvg />
                    </a>
                )}
            />
            <AppleLogin
                callback={handleAppleClick}
                clientId="com.buzzmi.app"
                responseType={'code'}
                responseMode={'form_post'}
                usePopup={true}
                scope={"name email"}
                redirectURI={"https://buzzmi.app"}
                render={renderProps => (
                    // <RegularButton icon={<AppleSvg />} className='_fw'
                    //     onClick={renderProps.onClick}>{Strings.continue_with_apple}</RegularButton>
                    <a style={{ backgroundColor: "#F4F1F8" }} onClick={renderProps.onClick} href="#" className="flex w-full items-center justify-center gap-3 rounded-md bg-[#F4F1F8] px-3 py-2 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#F4F1F8]">
                        <AppleSvg />
                    </a>

                )}
            />
        </div>
    );
};

export default QuadServicesButtons;
