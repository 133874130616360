import React, {useEffect, useState} from 'react';
// import {Link} from 'react-router-dom';
import {Link} from '@react-navigation/native';
import PrimaryButton from '../../../components/reusable/form/items/buttons/PrimaryButton.jsx';

import SearchInput from '../../../components/reusable/form/items/inputs/SearchInput.jsx';
import BaseLayout from '../../../components/layouts/baseLayout/BaseLayout';
import {useNavigation} from '@react-navigation/core';

import UsersList from '../items/UsersList.jsx';
import Button from '../../../components/reusable/form/items/buttons/Button.jsx';
// import {contacts} from '../items/mocData.js';

const contacts = [
  {
    name: 'Jhon Doe',
    avatar: '/images/creators/1.jpg',
    description: 'Some short description text',
  },
  {
    name: 'Jhon Doe',
    avatar: '/images/creators/2.jpg',
    description: 'Some short description text',
  },
  {
    name: 'Jhon Doe',
    avatar: '/images/creators/3.jpg',
    description: 'Some short description text',
  },
  {
    name: 'Jhon Doe',
    avatar: '/images/creators/4.jpg',
    description: 'Some short description text',
  },
  {
    name: 'Jhon Doe',
    avatar: '/images/creators/1.jpg',
    description: 'Some short description text',
  },
  {
    name: 'Jhon Doe',
    avatar: '/images/creators/2.jpg',
    description: 'Some short description text',
  },
  {
    name: 'Jhon Doe',
    avatar: '/images/creators/3.jpg',
    description: 'Some short description text',
  },
  {
    name: 'Jhon Doe',
    avatar: '/images/creators/4.jpg',
    description: 'Some short description text',
  },
  {
    name: 'Jhon Doe',
    avatar: '/images/creators/1.jpg',
    description: 'Some short description text',
  },
  {
    name: 'Jhon Doe',
    avatar: '/images/creators/2.jpg',
    description: 'Some short description text',
  },
  {
    name: 'Jhon Doe',
    avatar: '/images/creators/3.jpg',
    description: 'Some short description text',
  },
  {
    name: 'Jhon Doe',
    avatar: '/images/creators/4.jpg',
    description: 'Some short description text',
  },
];

const AddContacts = () => {
  const {navigate} = useNavigation();

  //   const [contacts, setContacts] = useState([]);

  //   useEffect(() => {
  //     //TODO Place for get data from API

  //     setContacts(contacts);
  //   }, []);
  const handleContinue = () => {
    navigate('ChooseInterest');
  };
  return (
    <BaseLayout>
      <div className="profile__wrapper mt-sm-7">
        <div className="row align-items-center mb-md-6  mb-2 gx-0">
          <div className="d-flex align-items-center justify-content-center ">
            <div className="col-3 d-none d-sm-block">
              <img
                src="/images/add-users/contact.png"
                alt="rocket"
                className="img-fluid"
              />
            </div>
            <div className="col-7 text-left">
              <div className="h4 mb-2">Your contacts</div>
            </div>
          </div>

          <div className="mb-5">
            <div>
              <SearchInput />
            </div>
            <div className="profile__content">
              <UsersList users={contacts} />
            </div>
          </div>
          <div className="d-none d-sm-block">
            <Button onClick={handleContinue} className="d-block my-sm-8 w-100">
              <PrimaryButton>Next</PrimaryButton>
            </Button>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default AddContacts;
