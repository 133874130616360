import React from 'react';
import Box from '../../../../../components/reusable/cards/box/Box';

const EditProfileBoxForm = ({title, children, className = '', paddingMode}) => {
  const header = <h4 className="h4">{title}</h4>;
  return (
    <Box
      className={'mb-3 profile-form__block'}
      topComponent={header}
      paddingMode={paddingMode}>
      <div className={className}>{children}</div>
    </Box>
  );
};

export default EditProfileBoxForm;
