// import {Outlet} from 'react-router-dom';

import MainLayoutSidebarV2 from './mainLayoutSidebarV2/MainLayoutSidebarV2.jsx';
import MainNewLayoutSidebar from './mainLayoutSidebarV2/MainNewLayoutSidebar.jsx';
import MainLayoutFooter from './mainLayoutFooter/MainLayoutFooter.jsx';
import Header from './componentsV2/Header';
import {useEffect, useState} from 'react';
import MainLayoutCollapSidebar from './MainLayoutCollapSidebar/index.js';
import classNames from 'classnames';

// import Image from 'next/image';
// import {defaultNavItems, NavItem} from './defaultNavItems';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/24/outline';
import useWidthListener from '../../../hooks/useWidthListner..js';
import Footer from './componentsV2/Footer/index.jsx';
import ModalBackdrop from './componentsV2/ModalBackDrop/index.js';

const MainLayoutV2 = props => {
  const {
    hideQuickAccess = false,
    hideMembership = false,
    centerSpace = false,
    hideFooter = false,
  } = props;
  const windowWidth = useWidthListener();
  const [menuOpen, setMenuOpen] = useState(false);

  const [collapsed, setSidebarCollapsed] = useState(true);
  useEffect(() => {
    if (collapsed == false) {
      if (windowWidth < 1050) {
        setSidebarCollapsed(!collapsed);
      }
    }
  }, [windowWidth]);
  let webView = windowWidth > 575;

  return (
    <>
      <div className="wrapper">
        <div className="main-layout">
          <Header
            collapsed={collapsed}
            onMenuClick={open => {
              setMenuOpen(open);
            }}
          />

          <>
            <div className="main-layout__wrapper min-layout-wrapper-v2">
              {/* <MainNewLayoutSidebar showLogo={false} /> */}
              <MainLayoutCollapSidebar
                collapsed={collapsed}
                setCollapsed={setSidebarCollapsed}
                shown={true}
                windowWidth={windowWidth}
              />
              <div
                className={
                  centerSpace
                    ? 'main-layout__contentFull'
                    : 'main-layout__contentV2'
                }>
                {props?.children}
                {!collapsed && <ModalBackdrop sidebarFix />}
              </div>
            </div>
          </>

          {!webView && <Footer />}
          {webView && <MainLayoutFooter />}
        </div>
      </div>
    </>
  );
};

export default MainLayoutV2;
