import { useEffect, useState } from 'react';
import moment from 'moment';
import CalendarComponent from 'react-calendar';
import { triggerClickOnNext, triggerClickOnPrev } from './customListener.js';

const monthNames = {
  0: 'jan',
  1: 'feb',
  2: 'mar',
  3: 'apr',
  4: 'may',
  5: 'jun',
  6: 'jul',
  7: 'aug',
  8: 'sep',
  9: 'oct',
  10: 'nov',
  11: 'dec',
};

const dayIncrement = 6;

const dateFormat = 'YYYY-MM-DD';

const getType = ({ dayList, type, date }) => {
  if (!dayList || dayList.length === 0) {
    return;
  }
  return dayList.filter(
    el =>
      moment(el.date, dateFormat).format() ===
      moment(date, dateFormat).format() && el.type === type,
  )[0];
};

const renderCellContent = ({ date, dayList, selectedDates, isMultipleSelection }) => {
  if (isMultipleSelection && selectedDates && selectedDates.length > 0) {
    return selectedDates.some((d) => new Date(d).toDateString() === date.toDateString()) && (
      <span className="calendar__marker calendar__marker--pink" />
    )
  }

  if (!dayList || dayList.length === 0) {
    return;
  }

  const currentGoalIsCompleted = getType({ dayList, date, type: 'completed' });
  const currentGoalIsNotCompleted = getType({
    dayList,
    date,
    type: 'uncompleted',
  });

  if (currentGoalIsCompleted) {
    return <span className="calendar__marker calendar__marker--pink" />;
  }
  if (currentGoalIsNotCompleted) {
    return <span className="calendar__marker calendar__marker--gray" />;
  }


};

const Calendar = ({
  dayList,
  onChange,
  value,
  noNavigation,
  buttonProps,
  period,
  hasFooter,
  noIndent,
  selectedDates = null,
  className,
  view = 'month',
  isMultipleSelection = false
}) => {
  const isForWeek = period === 'week';
  const [currentMonth, setCurrentMonth] = useState(moment(value).get('month'));
  const [firstDayOfCurrentWeek, setFirstDayOfCurrentWeek] = useState(0);
  const [redrew, setRedrew] = useState(false);

  const setDefaultFirstDayIndex = () => {
    const targetWeek = moment(value).isoWeekday('Monday').dayOfYear();
    setFirstDayOfCurrentWeek(targetWeek);
  };

  useEffect(() => {
    if (isForWeek) {
      setDefaultFirstDayIndex();
    }
  }, []);

  useEffect(() => {
    if (redrew) {
      setRedrew(false);
    }
  }, [redrew]);

  const onChangeWeek = (decrement = 0) => {
    console.log('weeek change called');
    setDefaultFirstDayIndex(firstDayOfCurrentWeek + decrement);
    setFirstDayOfCurrentWeek(firstDayOfCurrentWeek + decrement);
  };

  const onViewChange = ({ activeStartDate }) => {
    setCurrentMonth(moment(activeStartDate).get('month'));
  };

  useEffect(() => {
    if (period !== 'week') {
      return;
    }
    if (
      moment()
        .dayOfYear(Number(firstDayOfCurrentWeek + 6))
        .get('date') !== 7
    ) {
      triggerClickOnPrev();
    }
    if (moment().dayOfYear(Number(firstDayOfCurrentWeek)).get('date') === 1) {
      triggerClickOnNext();
    }
  }, [firstDayOfCurrentWeek]);

  const getLabelText = ({ direction }) => {
    if (direction === 'down') {
      return (
        <span
          onClick={() => (isForWeek ? onChangeWeek(-(dayIncrement + 1)) : null)}
          className="calendar__btn-text calendar__btn-text--left">
          <i className="fa-solid fa-arrow-left" />
          {period === 'week' ? (
            <div>Prev</div>
          ) : (
            <>
              {currentMonth - 1 >= 0
                ? monthNames[currentMonth - 1]
                : monthNames[11]}
            </>
          )}
        </span>
      );
    }
    if (direction === 'up') {
      return (
        <span
          onClick={() => (isForWeek ? onChangeWeek(dayIncrement + 1) : null)}
          className="calendar__btn-text calendar__btn-text--right">
          <i className="fa-solid fa-arrow-right" />
          {period === 'week' ? (
            <div>Next</div>
          ) : (
            <>
              {currentMonth + 1 <= 11
                ? monthNames[currentMonth + 1]
                : monthNames[0]}
            </>
          )}
        </span>
      );
    }
  };

  const minAndMaxDate = {
    min: new Date(
      moment(value).dayOfYear(Number(firstDayOfCurrentWeek)).format(dateFormat),
    ),
    max: new Date(
      moment(value)
        .dayOfYear(Number(firstDayOfCurrentWeek) + dayIncrement)
        .format(dateFormat),
    ),
  };

  const onReset = () => {
    setRedrew(true);
    setDefaultFirstDayIndex();
  };

  useEffect(() => {
    onReset();
  }, [value]);

  return (
    <div
      className={
        'calendar' +
        (noNavigation ? ' calendar--no-navigation' : '') +
        (noIndent ? ' calendar--no-indent' : '')
      }>
      {noNavigation && (
        <div className="calendar__header d-flex justify-content-between">
          <span className="calendar__header-title">Calendar</span>
        </div>
      )}
      <div className="calendar__body">
        {!redrew && (
          <>
            {isForWeek && (
              <div className=" relative">
                <div className="flex justify-end absolute  right-0   me-2 mb-2">
                  <button
                    className={`react-calendar__navigation__arrow react-calendar__navigation__prev-button me-2`}
                    type="button">
                    {getLabelText({ direction: 'down' })}
                  </button>
                  <button
                    className={`react-calendar__navigation__arrow react-calendar__navigation__prev-button`}
                    type="button">
                    {getLabelText({ direction: 'up' })}
                  </button>
                </div>
              </div>
            )}
            <CalendarComponent
              defaultActiveStartDate={new Date(value)}
              value={value}
              onChange={current => onChange(moment(current).format(dateFormat))}
              onActiveStartDateChange={onViewChange}
              tileContent={({ date }) => renderCellContent({ isMultipleSelection, date, dayList, selectedDates })}
              prevLabel={isForWeek ? null : getLabelText({ direction: 'down' })}
              nextLabel={isForWeek ? null : getLabelText({ direction: 'up' })}
              locale="en-EN"
              view={view}
              allowPartialRange={true}
              minDate={isForWeek ? minAndMaxDate['min'] : null}
              maxDate={isForWeek ? minAndMaxDate['max'] : null}
              className={className}
            />
          </>
        )}
      </div>
      {buttonProps && (
        <div className="calendar__btn-wrapper text-center">
          <button
            className="calendar__btn"
            onClick={() =>
              buttonProps.isDateAnchor ? onReset() : buttonProps.handler()
            }>
            {buttonProps.text}
          </button>
        </div>
      )}
      {hasFooter && (
        <div className="calendar__footer d-flex mt-2">
          <span className="calendar__footer-item calendar__footer-item--pink-marker">
            Completed
          </span>
          <span className="calendar__footer-item calendar__footer-item--gray-marker">
            Uncompleted
          </span>
        </div>
      )}
    </div>
  );
};

export default Calendar;
