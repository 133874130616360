// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app';
import {getAnalytics} from 'firebase/analytics';
import {getMessaging, getToken, onMessage} from 'firebase/messaging';
import {usePostFcmToken} from './appState/hooks/usePostFcmToken';
import {useBuzzmiAuth} from './appState/hooks/useBuzzmiAuth/index.web';
import {useAtom} from 'jotai';
import {_userFcmToken} from './appState/userState';
import {useEffect} from 'react';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const vapidKey =
  'BLR-B3whCJ8YWiF9n4z1Jt_zCLgn3OAikx-3O5fe5Z1EA6W0BuKBiO6h7Jtsjohxyb_x1CTPRXF6672dgGcEb2k';
const firebaseConfig = {
  apiKey: 'AIzaSyAfMFtmSkpIrFtabAMjasFLMQ-h-Tv0cUE',
  authDomain: 'buzzmi-8eeb0.firebaseapp.com',
  projectId: 'buzzmi-8eeb0',
  storageBucket: 'buzzmi-8eeb0.appspot.com',
  messagingSenderId: '239868943110',
  appId: '1:239868943110:web:7902bf7b0233bf151b9c87',
  measurementId: 'G-MCZW9185PG',
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
const messaging = getMessaging(firebaseApp);

const requestNotificationPermission = async () => {
  try {
    await Notification.requestPermission();
    console.log('Notification permission granted.');
  } catch (error) {
    console.error('Error requesting notification permission:', error);
  }
};

export const getFBToken = async setTokenFound => {
  try {
    await requestNotificationPermission();

    const currentToken = await getToken(messaging, {vapidKey});
    console.log('Current token for client:', currentToken);

    if (currentToken) {
      console.log('Current token for client:', currentToken);
      setTokenFound(currentToken);
    } else {
      console.log(
        'No registration token available. Request permission to generate one.',
      );
    }
  } catch (error) {
    console.log('An error occurred while retrieving token.', error);
  }
};

export const NotificationListener = onNotificationReceived => {
  useEffect(() => {
    // Set up a notification listener
    const unsubscribe = onMessage(messaging, payload => {
      console.log('Notification received:', payload);
      // Handle the notification payload here or pass it to a callback
      if (onNotificationReceived) {
        onNotificationReceived(payload);
      }
    });

    // Cleanup function to unsubscribe when the component unmounts
    return () => {
      unsubscribe();
    };
  }, []); // Empty dependency array means this effect runs once on mount
};
