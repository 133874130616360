import { useMutation } from 'react-query';
import { postUserBookMark } from '../../../network/api/app';
import { useBuzzmiAuth } from '../useBuzzmiAuth/index.web';

export const usePostBookmark = () => {
    const { userData } = useBuzzmiAuth();
    const {
        mutate: mutatePostBookmark,
        isLoading: isBookmarkPostLoading,
        isError: isBookmarkPostError,
        data: bookmarkResponse,
        error: bookmarkErrorResponse,
    } = useMutation({
        mutationFn: param => {
            // console.log("param?.body")
            // console.log(param?.body)
            return postUserBookMark(param.id)(Object.assign(param?.body, {
                params: { isAuth: true, token: userData?.token },
                // body: { ...param?.body },
            }));
        },
        onSuccess: data => {
            console.log('edited ==> ', data);
        },
    });
    const postOnBookMark = async (id, body) => mutatePostBookmark({ id, body });

    return {
        bookmarkResponse,
        bookmarkErrorResponse,
        isBookmarkPostLoading,
        isBookmarkPostError,
        postOnBookMark,
    };
};
