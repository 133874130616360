const { useBuzzmiAuth } = require("../../../../../../appState/hooks/useBuzzmiAuth/index.web");
const { useGetAllRankInfo } = require("../../../../../../appState/hooks/useGetAllRankInfo");
const { useHubApp } = require("../../../../../../appState/hooks/useHubApp/index.web");

const Ranking = () => {

    const { userData } = useBuzzmiAuth();
    const { useGetAllRankInfoList } = useGetAllRankInfo();
    const { rankInfo } = useHubApp();
    const { data: rankinfoList } = useGetAllRankInfoList(
        userData && userData?.token,
    );

    return (
        <div className="rank-loader mt-4 relative">

            <div className="sm:pl-[12px] pl-[8px]">
                <div>
                    {rankinfoList?.userRanks &&
                        rankinfoList?.userRanks.length > 0 &&
                        rankinfoList?.userRanks.map(litem => (
                            <div className="buzz-rookie">
                                <div className="flex items-center gap-x-5">
                                    <div>
                                        <img
                                            className={`sm:w-[60px] w-[50px] sm:h-[60px] h-[50px] p-1  ${litem?.isAchived
                                                ? litem?.title == rankInfo?.currentRank
                                                    ? 'bg-[#fbc02d]'
                                                    : 'bg-[#129200]'
                                                : 'bg-[#efefef]'
                                                } rounded-full`}
                                            src={litem?.image}
                                        />
                                    </div>
                                    <h2
                                        className={
                                            litem?.title == rankInfo?.currentRank
                                                ? 'font-bold'
                                                : 'font-medium'
                                        }>
                                        {litem?.title}
                                    </h2>

                                    {litem?.title == rankInfo?.currentRank &&
                                        <div className="w-[121px] h-[27px] absolute right-0 flex justify-center items-center">
                                            <div className="relative flex justify-center items-center ">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="120" height="27" viewBox="0 0 120 27" fill="none">
                                                    <path d="M7.50084 2.02386C8.44384 0.750816 9.93437 0 11.5186 0H120V27H11.5186C9.93437 27 8.44384 26.2492 7.50084 24.9761L1.20455 16.4761C-0.105072 14.7082 -0.105072 12.2918 1.20455 10.5239L7.50084 2.02386Z" fill="url(#paint0_linear_1100_3844)" />
                                                    <defs>
                                                        <linearGradient id="paint0_linear_1100_3844" x1="6.07837" y1="13.5" x2="30.0841" y2="59.9596" gradientUnits="userSpaceOnUse">
                                                            <stop stop-color="#FF41B3" />
                                                            <stop offset="1" stop-color="#7E00E0" />
                                                        </linearGradient>
                                                    </defs>
                                                </svg>
                                                <h5 className="text-sm font-bold text-white absolute top-0 mt-0.5" >Your Level</h5>
                                            </div>
                                        </div>
                                    }
                                </div>
                               
                                <div className="content sm:pl-[36px] pl-[32px]">

                                  
                                    <p
                                        className={`text-xs  sm:text-sm sm:text-left text-slate-600 relative  before:absolute before:content-'' before:w-[3px] sm:before:h-[25px] before:h-[18px] ${litem?.isAchived
                                            ? litem?.title == rankInfo?.currentRank
                                                ? 'before:bg-[#fbc02d]'
                                                : 'before:bg-[#129200]'
                                            : 'before:bg-[#dcdcdc]'
                                            } before:left-[-8px] before:top-[0px]`}>
                                        <p
                                            className={
                                                litem?.title == rankInfo?.currentRank
                                                    ? 'border-[#fbc02d] border-x-[3px] border-t-[3px] bg-[#FFF] ml-6 p-[10px] text-black rounded-ss-xl rounded-se-xl'
                                                    : 'bg-[#FFF]  ml-6 p-[10px] text-black rounded-ss-xl rounded-se-xl'
                                            }>
                                            {litem?.content}
                                        </p>
                                    </p>
                                    <p
                                        className={`text-xs sm:text-sm sm:text-left text-slate-600 relative  before:absolute before:content-'' before:w-[3px] sm:before:h-[20px] before:h-[18px] ${litem?.isAchived
                                            ? litem?.title == rankInfo?.currentRank
                                                ? 'before:bg-[#fbc02d]'
                                                : 'before:bg-[#129200]'
                                            : 'before:bg-[#dcdcdc]'
                                            } before:left-[-8px] before:top-[0px]`}>
                                        <p
                                            className={
                                                litem?.title == rankInfo?.currentRank
                                                    ? 'border-[#fbc02d] border-x-[3px] bg-[#FFF] ml-6 px-[10px] p-[5px] text-black'
                                                    : 'bg-[#FFF] px-[10px] ml-6 p-[5px] text-black'
                                            }>
                                            <div className={'flex text-xs'}>
                                                <p className={'font-medium mr-2'}>Buzzcoins: </p>
                                                {litem?.description['Buzzcoins']}
                                            </div>
                                        </p>
                                    </p>
                                    <p
                                        className={`text-xs sm:text-sm sm:text-left text-slate-600 relative  before:absolute before:content-'' before:w-[3px] sm:before:h-[20px] before:h-[18px] ${litem?.isAchived
                                            ? litem?.title == rankInfo?.currentRank
                                                ? 'before:bg-[#fbc02d]'
                                                : 'before:bg-[#129200]'
                                            : 'before:bg-[#dcdcdc]'
                                            } before:left-[-8px] before:top-[0px]`}>
                                        <p
                                            className={
                                                litem?.title == rankInfo?.currentRank
                                                    ? 'border-[#fbc02d] border-x-[3px] bg-[#FFF]  ml-6 px-[10px] p-[5px] text-black'
                                                    : 'bg-[#FFF] px-[10px] ml-6 p-[5px] text-black'
                                            }>
                                            <div className={'flex text-xs'}>
                                                <p className={'font-medium mr-2'}>Gems: </p>
                                                {litem?.description['Gems']}
                                            </div>
                                        </p>
                                    </p>
                                    <p
                                        className={`text-xs sm:text-sm sm:text-left text-slate-600 relative  before:absolute before:content-'' before:w-[3px] sm:before:h-[20px] before:h-[18px] ${litem?.isAchived
                                            ? litem?.title == rankInfo?.currentRank
                                                ? 'before:bg-[#fbc02d]'
                                                : 'before:bg-[#129200]'
                                            : 'before:bg-[#dcdcdc]'
                                            } before:left-[-8px] before:top-[0px]`}>
                                        <p
                                            className={
                                                litem?.title == rankInfo?.currentRank
                                                    ? 'border-[#fbc02d] border-x-[3px] border-b-[3px] bg-[#FFF]  ml-6 px-[10px] p-[5px] text-black rounded-ee-xl rounded-es-xl'
                                                    : 'bg-[#FFF] px-[10px]  ml-6 p-[5px] text-black rounded-ee-xl rounded-es-xl'
                                            }>
                                            <div className={'flex text-xs'}>
                                                <p className={'font-medium mr-2'}>
                                                    Gifted Diamonds:{' '}
                                                </p>
                                                {litem?.description['Gifted Diamonds']}
                                            </div>
                                        </p>
                                    </p>

                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default Ranking;