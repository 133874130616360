import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import { colors } from '../../res/colors';
import styled from 'styled-components/native';
import { fonts } from '../../res/fonts';
import { Platform, StyleSheet } from 'react-native';

const ButtonContainer =
  Platform.OS === 'web'
    ? styled.Pressable`
        align-items: center;
        justify-content: center;
        min-width: 40%;
        align-self: center;
        opacity: ${props => (props?.disabled ? 0.2 : 1)};
        padding-horizontal: 1rem;
        padding-vertical: 1rem;
        background-color: ${props =>
        !props.isLoading ? colors.PRIMARY_COLOR : 'transparent'};
        border-radius: ${wp(8)};
      `
    : styled.Pressable`
        align-items: center;
        justify-content: center;
        align-self: center;
        opacity: ${props => (props?.disabled ? 0.2 : 1)};
        background-color:  transparent;
        border-radius: ${wp(8)};
        shadowColor: '#efefef';
        shadowOffset: { width: 10, height: 2 };
        shadowOpacity: 0.2;
        shadowRadius: 10;
      `;

const TextWrapper =
  Platform.OS === 'web'
    ? styled.Text`
        font-size: 1.5rem;
        text-align: center;
        color: ${colors.WHITE};
        font-family: ${fonts.BOLD};
      `
    : styled.Text`
        font-size: ${wp(4.2)};
        text-align: center;
        color: ${colors.WHITE};
        font-family: ${fonts.BOLD};
      `;

export { ButtonContainer, TextWrapper };


export const styles = StyleSheet.create({
  linear_color: {
    justifyContent: "center",
    width: wp(88),
    height: wp('14%'),
    borderRadius: wp(8),

  }
})
