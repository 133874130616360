import {Platform, StyleSheet} from 'react-native';
import {getStatusBarHeight, isIphoneX} from 'react-native-iphone-x-helper';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import {fonts} from '../../res/fonts';

export const styles = StyleSheet.create({
  rightIconWrap: {
    marginHorizontal: '1rem',
  },
  image_ic: {
    width: '1.5rem',
    height: '1.5rem',
  },
  imageWrapper: {
    paddingTop: '0.5rem',
    // height: hp(14),
    width: '97%',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  icon_ic: {
    width: wp(7),
    height: wp(7),
    alignSelf: 'center',
    resizeMode: 'contain',
  },
  flexWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  flexInnerWrapper: {
    flexDirection: 'row',
    alignSelf: 'center',
    // backgroundColor: 'red',
  },
  profile_ic: {
    width: '2rem',
    height: '2rem',
    borderRadius: '3rem',
  },
  icBackStyle: {
    width: '1rem',
    height: '1rem',
    marginEnd: '1rem',
  },
});
