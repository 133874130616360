import React from 'react';

const Discount = ({discount, specialOffer}) => {
  return (
    <div className="subscription-card__discount-wrapper">
      {discount > 0 && (
        <div className="subscription-card__discount">
          <span>{discount}% off</span>
        </div>
      )}
      <div className="text-alt-primary text-s">{specialOffer}</div>
    </div>
  );
};

export default Discount;
