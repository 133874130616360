import React, {useEffect} from 'react';
import LogoSVG from './LogoSVG.jsx';
import {Link} from '@react-navigation/native';
import {useBuzzmiAuth} from '../../../../appState/hooks/useBuzzmiAuth/index.web.js';

const Logo = ({subtitle}) => {
  const {userData} = useBuzzmiAuth();

  return (
    <Link to={{screen: userData == null ? 'LoginV2' : 'Homev2'}}>
      <div className="logo">
        <div>
          <LogoSVG />
        </div>
        <div className="logo__text">
          {subtitle && <div className={'h4'}>{subtitle}</div>}
          <div className={'h2'}>BUZZMI</div>
        </div>
      </div>
    </Link>
  );
};

export default Logo;
