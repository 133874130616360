import React, { useEffect, useState } from 'react';
import CustomizableCircleChartButton from './items/CustomizableCircleChartButton.jsx';
import CustomizableCircleChart from './CustomizableCircleChart.jsx';
import styles from './customizable-circle.module.scss';
import PrimaryButton from '../../../btns/buttons/PrimaryButton.jsx';
import useHandlerChartData from './useHandlerChartData/useHandlerChartData.js';
import { isToday } from '../../../../../container/App/Hub/HubTaskDetailsv2/index.web.js';
import { useCompletePlannedActivity } from '../../../../../appState/hooks/useCompletePlannedActivity/index.js';
import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import Badge from '../../../badge/Badge.jsx';
import { Tooltip } from 'react-tooltip'


const calculatePercentage = (initValue, currentValue) => {
  if (initValue === 0) {
    return 0;
  }

  return Math.round((currentValue / initValue) * 100);
};
let vcount = 0;

const CustomizableCircleChartWrapper = props => {
  const {
    values,
    showTypeValue = true,
    setValues,
    hideComplete = false,
    isPreview,
    item = null,
  } = props;

  const {
    patchCompletePlannedActivity,
    isCompletePlannedActivityError,
    isCompletePlannedActivityLoading,
    CompletePlannedActivityResponse,
  } = useCompletePlannedActivity();

  const { type, initValue = 0, currentValue = 0 } = values;

  const [progress, setProgress] = useState(
    calculatePercentage(initValue, currentValue),
  );
  const { parsedInitValue, parsedCurrentValue } = useHandlerChartData(
    type,
    initValue,
    currentValue,
    showTypeValue,
  );

  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

  const delayApiCall = values => {
    let param_data = {};
    delay(1000).then(() => {
      vcount = vcount + 1;
      if (vcount == 1) {
        delay(8000).then(() => {
          vcount = 0;
          !item?.isCompleted &&
            item?.goal &&
            values?.currentValue > 0 &&
            (param_data = {
              goalProgress:
                values?.type == 'time'
                  ? `${values.currentValue / 60000} ${item?.goal?.includes('seconds') ? 'seconds' : 'mins'
                  }`
                  : values?.type == 'distanceMetric'
                    ? `${values.currentValue} km`
                    : `${values.currentValue} ${values?.type}`,
            });
          console.log('param_data==>', param_data);
          if (param_data?.goalProgress != item?.goalProgress) {
            patchCompletePlannedActivity(item?.id, param_data);
          }
        });
      }
    });
  };

  useEffect(() => {
    setProgress(calculatePercentage(initValue, currentValue));
  }, [currentValue]);

  const handleChangingValue = e => {
    let value = e.target.value ? e.target.value : 0;
    if (type === 'time') {
      value = value * 1000;
    }

    if (value > initValue) {
      value = initValue;
    }
    if (value < 0 || typeof +value !== 'number') {
      value = 0;
    }

    setValues({ ...values, currentValue: value });
    setProgress(calculatePercentage(initValue, value));
  };

  const handleAddProgress = () => {
    if (type === 'time') {
      setValues({ ...values, currentValue: currentValue + 1000 });
      if (item != null && count == 0) {
        delayApiCall({ ...values, currentValue: currentValue + 1000 });
      }
      return;
    }
    let result = currentValue + initValue / 100;
    if (result > initValue) result = initValue;
    setValues({ ...values, currentValue: result });
    if (item != null) {
      delayApiCall({ ...values, currentValue: result });
    }
  };

  const handleSubtractProgress = () => {
    if (type === 'time') {
      setValues({ ...values, currentValue: Math.max(0, currentValue - 1000) });
      if (item != null) {
        delayApiCall({
          ...values,
          currentValue: Math.max(0, currentValue - 1000),
        });
      }
      return;
    }
    let result = Math.round(currentValue - initValue / 100);
    if (result < 0) result = 0;
    setValues({ ...values, currentValue: result });
    if (item != null) {
      delayApiCall({ ...values, currentValue: result });
    }
  };

  useEffect(() => {
    setProgress(calculatePercentage(initValue, currentValue));
  }, [currentValue]);
  const handleSetCompleteValue = () => {
    setValues({ ...values, currentValue: initValue });
    setProgress(calculatePercentage(initValue, initValue));
  };

  useEffect(() => {
    // if (currentValue === initValue) {
    // console.log('init', initValue);
    // console.log('currentValue', currentValue);
    // }
  }, [currentValue, initValue]);
  //////Timer//////////////////////////////////////////////

  const [count, setCount] = useState(0);
  const [running, setRunning] = useState(false);
  const [limitReached, setLimitReached] = useState(false);
  const limit = initValue;
  useEffect(() => {
    let timer;

    if (running && count < limit) {
      timer = setTimeout(() => {
        setCount(prevCount => prevCount + 1000);

        // Check if the limit is reached
        if (count + 1000 >= limit) {
          setLimitReached(true);
          setRunning(false);
        }
      }, 1000);
    }

    return () => clearTimeout(timer);
  }, [running, count, limitReached]);

  function stopTimer() {
    setRunning(false);
    setCount(0);
    setLimitReached(false);
  }
  function ResetTimer() {
    setRunning(false);
    setCount(0);
    setLimitReached(false);
    setValues({ ...values, currentValue: 0 });
  }

  function playPause() {
    setRunning(prevRunning => !prevRunning);
    running ? delayApiCall({ ...values, currentValue: currentValue + 1000 }) : '';
  }
  useEffect(() => {
    if (type === 'time') {
      if (count !== 0) {
        console.log('count', count);
        handleAddProgress();
      }
    }
  }, [count]);

  ////////Timer//////////////////////////////////////////////

  return (
    <>
      {isPreview ? (
        <div className="flex justify-end">
          <Badge type={'Completed'}></Badge>
        </div>
      ) : (
        <>
          <div className={'flex-row justify-end flex'}>
            {type === 'time' ? (
              <>
                {currentValue == initValue && (
                  <>
                    <div
                      onClick={e => {
                        e?.preventDefault();
                        props.handleCompleteClick({
                          e: e,
                          handleComplete: () => handleSetCompleteValue(),
                        });
                      }}
                      className={'bg-[#F5EEFE] rounded-md ml-2 p-2 inline-block'}>
                      <CheckCircleIcon className={'w-6 h-6 text-[#E739BD]'} />
                    </div>

                    <a onClick={() => { console.log("click") }} data-tooltip-id="my-tooltip" data-tooltip-content="Click to complete the goal progress" className={'ml-0 p-0 inline-block'}>
                      <InformationCircleIcon className={'w-4 h-4 text-[#1919d9] ml-1'} />
                    </a>

                    <Tooltip id="my-tooltip" />
                  </>
                )}
              </>
            ) : (
              <>
                {hideComplete == false && (
                  <>
                    <div className={'bg-[#F5EEFE] rounded-md ml-2 p-2 inline-block'}
                      onClick={e => {
                        e?.preventDefault();
                        props.handleCompleteClick({
                          e: e,
                          handleComplete: () => handleSetCompleteValue(),
                        });
                      }}
                    >
                      <CheckCircleIcon className={'w-6 h-6 text-[#E739BD]'} />
                    </div>

                    <a onClick={() => { console.log("click") }} data-tooltip-id="my-tooltip" data-tooltip-content="Click to complete the goal progress" className={'ml-0 p-0 inline-block'}>
                      <InformationCircleIcon className={'w-4 h-4 text-[#1919d9] ml-1'} />
                    </a>

                    <Tooltip id="my-tooltip" />
                  </>
                )}
              </>
            )}
          </div>
        </>
      )}

      <div className={styles['customizable-circle__wrapper']}>
        <div className={styles['customizable-circle__row']}>
          {!isPreview && (
            <>
              {type === 'time' ? (
                <CustomizableCircleChartButton
                  symbol={<i className="fa-solid fa-minus" />}
                  onClick={
                    !currentValue <= 0 && !running
                      ? handleSubtractProgress
                      : () => { }
                  }
                />
              ) : (
                <CustomizableCircleChartButton
                  symbol={<i className="fa-solid fa-minus" />}
                  onClick={
                    !currentValue <= 0 ? handleSubtractProgress : () => { }
                  }
                />
              )}
            </>
          )}
          <CustomizableCircleChart
            progress={progress}
            parsedCurrentValue={parsedCurrentValue}
            parsedInitValue={parsedInitValue}
            type={type}
          />

          {!isPreview && (
            <>
              {type === 'time' ? (
                <CustomizableCircleChartButton
                  symbol={<i className="fa-solid fa-plus" />}
                  onClick={
                    currentValue !== initValue && !running
                      ? handleAddProgress
                      : () => { }
                  }
                />
              ) : (
                <CustomizableCircleChartButton
                  symbol={<i className="fa-solid fa-plus" />}
                  onClick={
                    currentValue !== initValue ? handleAddProgress : () => { }
                  }
                />
              )}
            </>
          )}
        </div>
        {/* <input
        onChange={handleChangingValue}
        type="number"
        placeholder="Add a value manually"
        className="input input_simple-text rounded-full"
      /> */}
        {!isPreview && (
          <>
            {type === 'time' ? (
              <>
                <div className="flex">
                  <button
                    onClick={e => {
                      e?.preventDefault();
                      currentValue == initValue ? null : playPause();
                    }}
                    className="flex-1 items-center justify-center rounded-full overflow-hidden shadow-lg bg-pink-500 px-3 py-2 text-sm font-semibold text-white hover:bg-pink-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600 mr-2">
                    {running ? 'Pause' : 'Start'}
                  </button>
                  <button
                    onClick={e => {
                      e?.preventDefault();
                      ResetTimer();
                    }}
                    className="flex-1 items-center justify-center rounded-full overflow-hidden shadow-lg bg-pink-500 px-3 py-2 text-sm font-semibold text-white hover:bg-pink-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600 ml-2">
                    Reset
                  </button>
                </div>

                <input
                  onChange={handleChangingValue}
                  type="number"
                  placeholder="Enter a goal manually"
                  className="input input_simple-text rounded-full"
                  disabled={running}
                />

                {/* // <button
                //   onClick={e => {
                //     e?.preventDefault();
                //     props.handleCompleteClick({
                //       e: e,
                //       handleComplete: () => handleSetCompleteValue(),
                //     });
                //   }}
                //   className="flex items-center w-full justify-center rounded-full overflow-hidden shadow-lg bg-pink-500 px-3 py-2 text-sm font-semibold text-white hover:bg-pink-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600">
                //   Complete
                // </button> */}
                {/* {currentValue == initValue && (
                  <div
                    onClick={e => {
                      e?.preventDefault();
                      props.handleCompleteClick({
                        e: e,
                        handleComplete: () => handleSetCompleteValue(),
                      });
                    }}
                    className={"bg-[#F5EEFE] rounded-md ml-4 p-2"}>
                    <CheckCircleIcon className={"w-6 h-6 text-[#E739BD]"} />
                  </div>
                )} */}
              </>
            ) : (
              <>
                <div className={'flex items-center'}>
                  <input
                    onChange={handleChangingValue}
                    type="number"
                    placeholder="Enter a goal manually"
                    className="input input_simple-text rounded-full"
                  />
                </div>
                {/* {hideComplete == false && (
                  <button
                    onClick={e => {
                      e?.preventDefault();
                      props.handleCompleteClick({
                        e: e,
                        handleComplete: () => handleSetCompleteValue(),
                      });
                    }}
                    className="flex items-center w-full justify-center rounded-full overflow-hidden shadow-lg bg-pink-500 px-3 py-2 text-sm font-semibold text-white hover:bg-pink-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600">
                    Complete
                  </button>
                )} */}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CustomizableCircleChartWrapper;
