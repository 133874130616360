import React, {useEffect, useState} from 'react';
import BaseLayout from '../../../../components/layouts/baseLayout/BaseLayout';
import {useNavigation} from '@react-navigation/native';
import useMemberships from '../../../App/MembershipScreen/hooks/useMemberships';
import {POST} from '../../../../api_helper/ApiServices';
import {POST_ORDER} from '../../../../api_helper/Api';
import {useEncryptedAtom} from '../../../../appState/hooks/useEncryptedAtom';
import {
  _sessionTokenAtom,
  _userDetailsAtom,
  userDataAtom,
} from '../../../../appState/userState';
import {fetchUserData} from '../../../../network/api/auth';
import {useAtom} from 'jotai';

const Subscription = props => {
  const {navigate, goBack} = useNavigation();
  const [loading, setLoading] = useState(false);
  const {memberships} = useMemberships(setLoading);
  const [sessiontoken, setSessionToken] = useEncryptedAtom(_sessionTokenAtom);

  const [userData, setLoginSuccess] = useAtom(userDataAtom);
  const [userDetails, setUserDetails] = useEncryptedAtom(_userDetailsAtom);

  const updateUserData = async () => {
    try {
      const response = await fetchUserData(
        props?.route?.params?.token || sessiontoken,
      );
      setLoginSuccess(prev => {
        return {
          ...prev,
          membership: response.membership,
        };
      });
      setUserDetails(prev => {
        return {
          ...prev,
          membership: response.membership,
        };
      });
    } catch (e) {
      console.log('fetchUserData ==> ', e.message);
    }
  };

  useEffect(() => {
    console.log('props?.route?.params', props?.route?.params);
    if (typeof props?.route?.params?.paymentSuccess === 'string') {
      if (props?.route?.params?.paymentSuccess === 'true') {
        updateUserData().then(() => {
          if (props?.route?.params?.from === 'home') {
            goBack();
          } else {
            navigate('FinishRegister');
          }
        });
      } else {
        window.alert('Payment failed. Please try again.');
      }
    }

    if (typeof props?.route?.params?.paymentSuccess !== 'string') {
      if (props?.route?.params?.id) {
        handleSubscribeEvent(props.route.params.id);
      } else {
        goBack();
      }
    }
  }, [props?.route?.params]);

  const handleSubscribeEvent = product_id => {
    setLoading(() => true);

    POST(
      POST_ORDER,
      true,
      props?.route?.params?.token || sessiontoken,
      '',
      {
        product_id: product_id,
      },
      (res, e) => {
        setLoading(() => false);
        if (!e) {
          window.location.replace(res?.payment_link);
        } else {
          console.log('PATCH_UPDATE_PROFILE (error)2 => ', res);
        }
      },
    );
  };

  return (
    <BaseLayout noBack>
      <div className="subscription">
        <div className="h5">Redirecting...</div>
        <div className="text-r"></div>
      </div>
    </BaseLayout>
  );
};

export default Subscription;
