import React from 'react';
import SubscriptionOpener from '../subscriptionOpener/subscriptionOpener.jsx';
import DescriptionList from '../../../../../components/reusable/descriptionList/DescriptionList.jsx';
import Button from '../../../../../components/reusable/btns/buttons/Button.jsx';
import Discount from '../../../../../components/reusable/assets/discount/Discount.jsx';
import Strings from '../../../../../string_key/Strings.web.js';

const DesktopLayout = ({
  openers,
  mySub,
  current,
  setCurrent,
  icons,
  onSubscribe,
}) => {
  const selected = openers.find(it => it.type === current.type);

  return (
    <>
      <div className="hub__subscription-openers">
        {openers.map(subscription => {
          return (
            <div className="hub__subscription-opener" key={subscription.id}>
              <SubscriptionOpener
                mySub={mySub}
                openers={openers}
                active={current.type == subscription.type}
                onChange={setCurrent}
                isCurrent={mySub.id === subscription.id}
                subscription={subscription}
                icon={icons.find(it => it.type === subscription.type).img}
              />
            </div>
          );
        })}
      </div>

      <div className="hub__subscription-info">
        <DescriptionList items={selected?.benifits} />

        {mySub.id !== current.id ? (
          <div className="hub__subscription-bottom">
            <Button
              onClick={() => onSubscribe(selected?.product_id)}
              className="button_primary button_m double-secondary px-6 px-lg-14">
              {Strings.subscribe}
            </Button>
            <div className="hub__subscription-discount">
              <Discount
                discount={selected?.discount}
                specialOffer={selected?.specialOffer ? 'And Special offer' : ''}
              />
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default DesktopLayout;
