import React from 'react';

const EditIcon = ({isHovered}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      {isHovered && (
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#EF8ED5" />
            <stop offset="50%" stopColor="#EC44D0" />
            <stop offset="100%" stopColor="#C1169C" />
          </linearGradient>
        </defs>
      )}
      <path
        d="M9.29893 18.026L16.2991 11.0259L12.9741 7.70091L5.97398 14.7011C5.87761 14.7976 5.80915 14.9183 5.77587 15.0506L5 19L8.94865 18.2241C9.08123 18.191 9.20251 18.1224 9.29893 18.026ZM18.5589 8.76604C18.8413 8.48352 19 8.10039 19 7.70091C19 7.30143 18.8413 6.9183 18.5589 6.63579L17.3642 5.4411C17.0817 5.15866 16.6986 5 16.2991 5C15.8996 5 15.5165 5.15866 15.234 5.4411L14.0393 6.63579L17.3642 9.96073L18.5589 8.76604Z"
        fill={isHovered ? 'url(#gradient)' : '#C0BACB'}
      />
    </svg>
  );
};

export default EditIcon;
