import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

const ProfileSessionItem = ({
  session,
  title,
  info,
  image,
  isLikes,
  isActive,
  id,
  callback,
}) => {
  const handleLike = () => {};

  return (
    <div
      onClick={callback}
      className={cn('profile-item session-item', {
        'session-item_active': isActive,
      })}>
      <div className="profile-item__icon">
        <img src={image.src} alt={image.alt} className="profile-item__img" />
        <button
          onClick={handleLike}
          className={cn('profile-item__like', {
            'profile-item__like_active': isLikes,
          })}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
            stroke="#eb4dac">
            <path d="M2 9.1371C2 14 6.01943 16.5914 8.96173 18.9109C10 19.7294 11 20.5 12 20.5C13 20.5 14 19.7294 15.0383 18.9109C17.9806 16.5914 22 14 22 9.1371C22 4.27416 16.4998 0.825464 12 5.50063C7.50016 0.825464 2 4.27416 2 9.1371Z"></path>
          </svg>
        </button>
        <img
          src="/images/pages/profile/play.svg"
          alt="play"
          className="profile-item__play"
        />
      </div>
      <div>
        <img
          src="/images/assets/tick.png"
          alt="lock"
          className={cn('session-item__checked', {
            'session-item__checked_active': isActive,
          })}
        />
        <div className="session-item__session">{session} session</div>
        <div className="session-item__title">{title}</div>
        <div className="session-item__info">{info}</div>
      </div>
    </div>
  );
};

ProfileSessionItem.propTypes = {
  session: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  info: PropTypes.string,
  image: PropTypes.object,
  isLikes: PropTypes.bool,
  isActive: PropTypes.bool,
};

export default ProfileSessionItem;
