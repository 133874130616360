import { useAtom } from 'jotai';
import { visitorRankAtom } from '../hubState';
import { getVisitorRankInfo } from '../../../network/api/app';

export const useVisitorRankInfo = () => {
  const [visitorRankInfo, setVisitorRankInfo] = useAtom(visitorRankAtom);

  const getVisitorRankInfoDetail = async (id, token) => {
    try {
      const response = await getVisitorRankInfo(id)({ token });
      setVisitorRankInfo(response);
    } catch (e) {
      console.log('error => ', e.message);
    }
  };

  return {
    visitorRankInfo,
    getVisitorRankInfoDetail,
  };
};
