import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../../../store/slices/modalSlice/modalSlice';
import { useEffect, useState } from 'react';
import cn from 'classnames';
import useSetParenComponentProps from '../../../../hooks/useSetParenComponentProps';
import PlanSearch from '../../../reusable/assets/additionalInformation/items/additionalInformationPlan/items/planSearch/PlanSearch';
import { useHubApp } from '../../../../appState/hooks/useHubApp/index.web';
import { useBuzzmiAuth } from '../../../../appState/hooks/useBuzzmiAuth/index.web';
import 'react-activity/dist/Bounce.css';


const validationNewTag = value => {
    if (value.length > 20) {
        return 'Tag should be less than 20 characters';
    }
    if (value.length < 3) {
        return 'Tag should be more than 3 characters';
    }
    return false;
};


const AddProfileTags = props => {

    const [tags, setTags] = useState([]);
    const [tagsSearchValue, setTagsSearchValue] = useState('');
    const [selectedTags, setSelectedTags] = useState([]);
    const [newTag, setNewTag] = useState('');
    const [error, setError] = useState('');
    const dispatch = useDispatch();
    const {
        tagsList,
        mutateProfileTag,
        tagResponse,
        getUserTags,
        userTags,
    } = useHubApp();
    const { userData } = useBuzzmiAuth();

    useEffect(() => {
        if (props?.value?.length > 0) {
            setSelectedTags(props.value);
        }
    }, []);

    useEffect(() => {
        getUserTags(userData?.id, userData?.token);
    }, [userData]);

    useEffect(() => {
        if (tagResponse) {
            getUserTags(userData?.id, userData?.token);
            setTags([]);
            setNewTag('');
        }
    }, [tagResponse]);

    const handleTagClick = tag => {
        if (selectedTags.includes(tag)) {
            setSelectedTags(selectedTags.filter(t => t !== tag));
        } else {
            setSelectedTags([...selectedTags, tag]);
        }
    };

    useEffect(() => {
        if (tagsList.length) {
            setTags(
                tagsList
                    ?.filter(item => item.tagName !== 'All')
                    ?.map(item => ({
                        id: item.id,
                        title: item.tagName,
                        value: item.tagName,
                    })),
            );
        }
    }, [tagsList]);

    const handleAddNewProfileTag = () => {
        const error = validationNewTag(newTag);
        if (error) {
            setError(error);
            return;
        }
        mutateProfileTag(
            Object.assign(
                { tagName: newTag },
                {
                    params: { isAuth: true, token: userData?.token },
                },
            ),
        );
        dispatch(closeModal());
    };


    const handleNewTagChange = e => setNewTag(e.target.value);


    return (
        <>
            <div className="mt-2">
                <PlanSearch setSearchValue={setTagsSearchValue} />
                <div className="text-error">{error}</div>
            </div>
            <div className="add-tags__wrapper">
                <div className="mt-3 add-tags__block">
                    {
                        userTags
                            .filter(item => item.tagName.includes(tagsSearchValue))
                            .map(tag => (
                                <div
                                    onClick={() => handleTagClick(tag)}
                                    className={cn('add-tags__item', {
                                        'add-tags__item_selected': selectedTags.includes(tag),
                                    })}
                                    key={tag.id}>
                                    {tag.tagName}
                                </div>
                            ))
                    }
                </div>
            </div>

            <div className="modal__bottom">
                <div className="d-flex mb-4 mb-md-21 justify-content-center">
                    <input
                        placeholder="Add your tag"
                        type="text"
                        onChange={handleNewTagChange}
                        className="input input_simple-text"
                        value={newTag}
                    />
                    <button
                        onClick={handleAddNewProfileTag}
                        className="ms-3 text-alt-primary">
                        Add
                    </button>
                </div>
            </div>
        </>
    );
};

export default AddProfileTags;
