import {useAtom} from 'jotai';
import {useMutation} from 'react-query';
import {ShowSuccess} from '../../../container/HOC/MessageWeb';
import {putEditPosts} from '../../../network/api/app';
import Strings from '../../../string_key/Strings.web';
import {profileSideBarNavAtom} from '../../navState';
import {useBuzzmiAuth} from '../useBuzzmiAuth/index.web';
import {useProfileApp} from '../useProfileApp';
import {useToast} from 'react-native-toast-notifications';
import {EventRegister} from 'react-native-event-listeners';

export const useUpdatePost = () => {
  const {userData} = useBuzzmiAuth();
  const toast = useToast();
  const {getUserPostLists, getUserReelLists, getBuzzmiUserPostLists} =
    useProfileApp();
  const [activeSideItem, setActiveSideItem] = useAtom(profileSideBarNavAtom);
  const {
    mutate: mutateEditPost,
    isLoading: isEditPostLoading,
    isError: isEditPostError,
    data: editpostResponse,
    error: editpostErrorResponse,
  } = useMutation({
    mutationFn: param => {
      return putEditPosts(
        param.id,
        param.type,
      )({
        params: {isAuth: true, token: userData?.token},
        body: {...param?.body},
      });
    },
    onSuccess: data => {
      console.log('the success post called ');
      try {
        getBuzzmiUserPostLists(userData?.token, {page: 1, limit: 20});
        getUserPostLists(userData.token, userData?.id, {page: 1, limit: 20});
        getUserReelLists(userData.token, userData?.id, {page: 1, limit: 20});
        setActiveSideItem(Strings.post);
        setTimeout(() => {
          setActiveSideItem(
            activeSideItem == 'Reel' ? Strings.reel : Strings.post + 's',
          );
          setTimeout(() => {
            ShowSuccess('Post Updated', toast);
          }, 350);
        }, 2000);
      } catch (error) {
        console.log('error happend in post refresh', error);
      }
    },
  });

  const postEditpost = async (id, type, body) =>
    mutateEditPost({id, type, body});

  return {
    editpostErrorResponse,
    isEditPostError,
    isEditPostLoading,
    editpostResponse,
    postEditpost,
  };
};
