import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import CodeInput from './CodeInput';
import PrimaryButton from '../../buttons/PrimaryButton.jsx';
import Form from '../../../Form.jsx';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
// import {confirmCodeSchema} from '../../../../../../validationsSchems/confirmCode.js';

// import userAPI from '../../../../../../store/services/UserService.js';
import ResendCode from '../../buttons/ResendCode.web';
import { useNavigate } from 'react-router-dom';
import Strings from '../../../../../../string_key/Strings.web';

const CodeInputs = ({
  label,
  formClassName,
  identifier,
  handleResend,
  pressSubmit,
  codelength
}) => {
  const navigate = useNavigate();
  const [code, setCode] = useState([]);
  // const [sendCode] = userAPI.useForgotPasswordMutation();
  // const [confirmCode] = userAPI.useConfirmForgotPasswordMutation();

  // const {handleSubmit, register, setValue, getValues} = useForm({
  //   resolver: yupResolver(confirmCodeSchema),
  // });
  const { handleSubmit, register, setValue, getValues } = useForm();

  const onSubmit = async code => {
    // console.log('code', code);
    pressSubmit(code);
    // const res = await confirmCode({identifier, code});
    // if (res.error) {
    //   console.error(res.error);
    // } else {
    //   navigate('/auth/reset-password');
    // }
  };

  const handleResendCode = async () => {
    console.log('rresend');
    setCode([]);
    setValue('code', '');
    handleResend();
    // const res = await sendCode({identifier});
    // if (res.error) {
    //   console.error(res.error);
    // } else {
    //   console.log(res);
    // }
  };

  return (
    <Form formClassName={formClassName} onSubmit={handleSubmit(onSubmit)}>
      <label className={'input-label'}>
        <div>{label}</div>
        <div className={'input-code-wrapper'}>
          <input
            className="input-code-wrapper__hide p-0"
            {...register('code')}
            type="text"
          />
          {[...Array(codelength)].map((_, i) => (
            <CodeInput
              regex={/^[0-9a-zA-Z_]$/}
              setCode={setCode}
              key={i}
              index={i}
              register={register}
              label={'Input Label'}
              setValue={setValue}
            />
          ))}
        </div>
      </label>
      <div>
        <ResendCode handleResendCode={handleResendCode} />
        <PrimaryButton
          className={getValues('code')?.length !== codelength ? 'button_inactive' : ''}
          type="submit">
          {Strings.continue}
        </PrimaryButton>
      </div>
    </Form>
  );
};

export default CodeInputs;
