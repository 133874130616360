import React from 'react';
import Box from '../box/Box.jsx';
import InfoCardMain from './items/infoCardMain/InfoCardMain.jsx';
import {Link} from '@react-navigation/native';

const InfoCard = ({
  noMenu = false,
  isStat = false,
  noNav = false,
  item,
  navTo,
  taskType,
  onClickItem,
  isMainActivity = false,
}) => {
  const _renderContent = () => {
    return (
      <ol className="divide-y divide-gray-200 text-sm leading-6 lg:col-span-6 xl:col-span-7 ">
        <InfoCardMain
          onClickItem={onClickItem}
          noMenu={noMenu}
          info={{
            title: item?.title,
            text: item?.description,
          }}
          image={item.image}
          badges={item.badges}
          id={item.id}
          item={item}
          taskType={taskType}
          isMainActivity={isMainActivity}
          // type={item.type !== null ? (isMenu ? 'menu' : 'link') : null}
          type={
            isStat
              ? 'link'
              : item.title !== 'Finish your account setup'
              ? 'menu'
              : 'link'
          }
        />
      </ol>
    );
  };

  if (noNav) {
    return <div>{_renderContent()}</div>;
  }

  return (
    <Link
      to={{
        screen: navTo ? navTo : isStat ? 'HubTread' : 'HubTaskInfov2',
        params: {item},
      }}>
      {_renderContent()}
    </Link>
  );
};

export default InfoCard;
