import React from 'react';
// noinspection ES6CheckImport
import { ActivityIndicator } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
// style component
import { ButtonContainer, TextWrapper, styles } from './style';
import { colors } from '../../res/colors';

export const Button = props => {
  const { isLoading, btnstyle, txtstyle, buttonText, disabled, buttonPress, linearstyle } =
    props;
  return (
    <ButtonContainer
      style={[btnstyle]}
      disabled={disabled}
      isLoading={isLoading}
      onPress={() =>
        disabled === true ? console.log('disabled') : buttonPress()
      }>
      <LinearGradient
        start={{ x: 1, y: 1 }}
        end={{ x: 0, y: 0.5 }}
        colors={isLoading ? ["transparent", "transparent"] : ["#EB4DAC", "#EB4DAC"]}
        // colors={["#C600CA", "#E3397D", "#EF5B50"]}
        style={[styles.linear_color, linearstyle]}>
        {isLoading ? (
          <ActivityIndicator size={'large'} color={colors.PRIMARY_COLOR} />
        ) : (
            <TextWrapper style={[txtstyle]}>{buttonText}</TextWrapper>
          )}
      </LinearGradient>
    </ButtonContainer >
  );
};
