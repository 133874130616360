import {atom} from 'jotai';
import {atomWithStorage, createJSONStorage} from 'jotai/utils';
import AsyncStorage from '@react-native-community/async-storage';

const storage = {...createJSONStorage(() => AsyncStorage), delayInit: true};
const currentUserAtom = atom({});
const userDataAtom = atomWithStorage('userDataNew', null);
// sessionToken===> saveData(“sessionToken”, ‘social-’ + response?.token)
// 				saveData(“sessionToken”, params?.userData.sessionToken)
const _sessionTokenAtom = atomWithStorage('sessionToken', null, storage);

// fontSize (m,s,l) // cannot move to jotai
const _fontSizeAtom = atomWithStorage('fontSize', null, storage);

// add_role
const _addRoleAtom = atomWithStorage('add_role', null, storage);

// company_id
const _companyIdAtom = atomWithStorage('company_id', null, storage);

// user_email
const _userEmailAtom = atomWithStorage('user_email', null, storage);

// userDetails
const _userDetailsAtom = atomWithStorage('userDetails', null, storage);
const _userJustSignupAtom = atomWithStorage('userJustSignup', false);
const _userFcmToken = atomWithStorage('userFcmToken', null);
const _userFcmTokenSavedOnBE = atomWithStorage('userFcmTokenSavedOnBE', false);

// isMembershipSkipped
const _isMembershipSkippedAtom = atomWithStorage(
  'isMembershipSkipped',
  null,
  storage,
);

// phone-verify-skipped
const _phoneVerifySkippedAtom = atomWithStorage(
  'phone-verify-skipped',
  null,
  storage,
);

// call_history // cannot move to jotai
// const _callHistoryAtom = atomWithStorage('call_history', null, storage);

// useLanguage
const _useLanguageAtom = atomWithStorage('useLanguage', 'en', storage);

// s_secret
const _sSecretAtom = atomWithStorage('s_secret', null, storage);

// s_uuid
const _sUuidAtom = atomWithStorage('s_uuid', null, storage);

// f_secret
const _fSecretAtom = atomWithStorage('f_secret', null, storage);

// f_uuid
const _fUuidAtom = atomWithStorage('f_uuid', null, storage);

// choose_interest
const _chooseInterestAtom = atomWithStorage('choose_interest', null, storage);

// add_creator
const _AddCreatorAtom = atomWithStorage('add_creator', null, storage);

// isSeenWalkThrogh // saveData(‘isSeenWalkThrogh’, ‘yes’);
const _IsSeenWalktrough = atomWithStorage('isSeenWalkThrogh', null, storage);

// show-last-seen
const _showLastSeenAtom = atomWithStorage('show-last-seen', null, storage);

export {
  _sUuidAtom,
  _fUuidAtom,
  userDataAtom,
  _addRoleAtom,
  _fSecretAtom,
  _sSecretAtom,
  // _fontSizeAtom,
  _userEmailAtom,
  _companyIdAtom,
  currentUserAtom,
  _AddCreatorAtom,
  // _callHistoryAtom,
  _userDetailsAtom,
  _IsSeenWalktrough,
  _showLastSeenAtom,
  _sessionTokenAtom,
  _chooseInterestAtom,
  _phoneVerifySkippedAtom,
  _isMembershipSkippedAtom,
  _useLanguageAtom,
  _userJustSignupAtom,
  _userFcmToken,
  _userFcmTokenSavedOnBE,
};
