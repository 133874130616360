import {StyleSheet, Platform} from 'react-native';
// Styles
import {colors} from './colors';
import {fonts} from './fonts';
// Third Party Lib
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import {
  getBottomSpace,
  getStatusBarHeight,
  ifIphoneX,
  isIphoneX,
} from 'react-native-iphone-x-helper';
import styled from 'styled-components/native';
import {IS_IPHONE_X, isWeb} from '../constants.ts';

const ImgBackGroundgContainer = isWeb
  ? styled.ImageBackground`
      width: 100%;
      height: 3rem;
    `
  : styled.ImageBackground`
      width: ${wp(100)};
      height: ${isIphoneX ? hp(14) : Platform.OS === 'ios' ? hp(12) : hp(14)};
    `;

const SubContainer = styled.View`
  flex: 1;
  width: ${isWeb ? '100%' : wp(90)};
  align-self: center;
`;

const GlobalFlex = styled.View`
  flex: 1;
  background-color: ${colors.WHITE};
`;

const GlobalHeader = styled.View`
  padding-bottom: 5px;
  padding-top: 1rem;
  background-color: ${colors.WHITE};
`;

const GlobalSubHeader = styled.View`
  flex-direction: row;
  width: 100%;
  align-self: center;
`;

const GlobalBorder = styled.View`
  background-color: ${colors.BORDER_COLOR};
  height: ${hp(0.15)};
  width: ${wp(100)};
`;

const MainTitle = styled.Text`
  color: black;
  font-weight: 800;
  font-size: 2.4rem;
  text-align: center;
  // font-family: ${fonts.BOLD};
`;

const MainSubTitle = styled.Text`
  color: black;
  font-weight: 500;
  font-size: 1.4rem;
  text-align: center;
`;

const SubTitle = styled.Text`
  font-size: 1.2rem;
  text-align: center;
  align-self: center;
  font-family: ${fonts.REGULAR};
  color: ${colors.REGULAR_TEXT_COLOR};
`;

const MenuTitle = isWeb
  ? styled.Text`
      text-align: center;
      align-self: center;
      font-size: 1rem;
      font-family: ${fonts.MEDIUM};
      margin-top: 0.8rem;
      color: ${colors.REGULAR_TEXT_COLOR};
    `
  : styled.Text`
      text-align: center;
      align-self: center;
      font-size: ${wp(3.5)};
      font-family: ${fonts.MEDIUM};
      margin-top: ${hp('1%')};
      color: ${colors.REGULAR_TEXT_COLOR};
    `;

const ActivityDot = isWeb
  ? styled.View`
      position: absolute;
      right: 0;
      background-color: #f32808;
      border-radius: 1rem;
      height: 0.4rem;
      width: 0.4rem;
      z-index: 100;
    `
  : styled.View`
      position: absolute;
      right: 0;
      background-color: #f32808;
      border-radius: 90px;
      height: ${wp(2)};
      width: ${wp(2)};
    `;

export {
  MainSubTitle,
  ActivityDot,
  SubContainer,
  GlobalFlex,
  GlobalHeader,
  GlobalSubHeader,
  MainTitle,
  SubTitle,
  GlobalBorder,
  MenuTitle,
  ImgBackGroundgContainer,
};

const globalStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.WHITE,
    ...ifIphoneX(
      {
        paddingTop: getStatusBarHeight() + 10,
      },
      {
        paddingTop: getStatusBarHeight(),
      },
    ),
  },
  width: {
    width: wp(90),
    alignSelf: 'center',
  },
  oneFlex: {
    flex: 1,
  },
  bottomSpace: {
    ...ifIphoneX(
      {
        marginBottom: getBottomSpace(),
      },
      {
        marginBottom: 10,
      },
    ),
  },
  lg_bottomSpace: {
    ...ifIphoneX(
      {
        marginBottom: getBottomSpace(),
      },
      {
        marginBottom: 16,
      },
    ),
  },
  lg_topSpace: {
    marginTop: 16,
  },
  hideIconsRNPicker: {
    chevronDown: {
      display: 'none',
    },
    chevronUp: {
      display: 'none',
    },
  },
});

export default globalStyles;
