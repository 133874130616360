export const postsData = [
  {
    id: 1,
    author: {
      nickname: "janedoe",
      name: "John Doe",
      position: "Elite creator",
      isVerified: true,
      avatar: {
        src: "/images/post/mock/jane.jpg",
      },
    },
    image: {
      src: "/images/post/mock/post-image1.jpg",
      alt: "yoga",
    },
    video: null,
    location: {
      city: "Berlin",
      country: "Germany",
      latitude: 52.52,
      longitude: 13.405,
    },
    date: new Date("2023-07-20"),
    text: "Drink up! Increasing your daily water intake is vital as you to stay healthy. Dehydration can cause fatigue, indigestion, and memory lapse.",
    tags: ["sport", "yoga", "health"],
    likes: "175",
    comments: [
      {
        id: 1,
        author: {
          nickname: "matilda",
          name: "Matilda Johnson",
          position: "Elite creator",
          isVerified: true,
          avatar: {
            src: "/images/post/mock/matilda.jpg",
          },
        },
        text: "Great work!",
        likes: 2,
        date: new Date("2023-07-22"),
        replies: [
          {
            id: 1,
            author: {
              nickname: "janedoe",
              name: "John Doe",
              position: "Elite creator",
              isVerified: true,
              avatar: {
                src: "/images/post/mock/jane.jpg",
              },
            },
            text: "Thank u for comment!",
            likes: 1,
            date: new Date("2023-07-22"),
            replies: [
              {
                id: 1,
                author: {
                  nickname: "janedoe",
                  name: "John Doe",
                  position: "Elite creator",
                  isVerified: true,
                  avatar: {
                    src: "/images/post/mock/jane.jpg",
                  },
                },
                text: "Thank u for comment!",
                likes: 1,
                date: new Date("2023-07-22"),
              },
            ],
          },
          {
            id: 2,
            author: {
              nickname: "janedoe",
              name: "John Doe",
              position: "Elite creator",
              isVerified: true,
              avatar: {
                src: "/images/post/mock/jane.jpg",
              },
            },
            text: "Thank u for comment!!",
            likes: 2,
            date: new Date("2023-07-22"),
            replies: null,
          },
          {
            id: 3,
            author: {
              nickname: "janedoe",
              name: "John Doe",
              position: "Elite creator",
              isVerified: true,
              avatar: {
                src: "/images/post/mock/jane.jpg",
              },
            },
            text: "Thank u for comment!!!",
            likes: 3,
            date: new Date("2023-07-22"),
            replies: null,
          },
        ],
      },
      {
        id: 2,
        author: {
          nickname: "jarvis",
          name: "Jarvis Anderson",
          position: "",
          isVerified: true,
          avatar: {
            src: "/images/post/mock/jarvis.jpg",
          },
        },
        text: "Excellent post, love it.",
        likes: 2,
        date: new Date("2023-07-23"),
        replies: [
          {
            id: 1,
            author: {
              nickname: "janedoe",
              name: "John Doe",
              position: "Elite creator",
              isVerified: true,
              avatar: {
                src: "/images/post/mock/jane.jpg",
              },
            },
            text: "Thank u for comment!",
            likes: 1,
            date: new Date("2023-07-22"),
            replies: null,
          },
        ],
      },
    ],
    gems: [
      {
        id: 1,
        date: new Date("2023-07-20"),
        author: {
          name: "Jarvis Anderson",
          avatar: {
            src: "/images/post/mock/jarvis.jpg",
          },
        },
        quantity: 50,
      },
      {
        id: 2,
        date: new Date("2023-08-01"),
        author: {
          name: "Matilda Johnson",
          avatar: {
            src: "/images/post/mock/matilda.jpg",
          },
        },
        quantity: 150,
      },
      {
        id: 3,
        date: new Date("2023-08-02"),
        author: {
          name: "Matilda Johnson",
          avatar: {
            src: "/images/post/mock/matilda.jpg",
          },
        },
        quantity: 100,
      },
    ],
    gifts: [
      {
        id: 1,
        date: new Date("2023-07-20"),
        author: {
          name: "Matilda Johnson",
          avatar: {
            src: "/images/post/mock/matilda.jpg",
          },
        },
        quantity: 1,
        present: {
          src: "/images/post/gifts/present-violet-box.webp",
        },
      },
      {
        id: 2,
        date: new Date("2023-07-20"),
        author: {
          name: "Matilda Johnson",
          avatar: {
            src: "/images/post/mock/matilda.jpg",
          },
        },
        quantity: 5,
        present: {
          src: "/images/post/gifts/present-flower.webp",
        },
      },
      {
        id: 3,
        date: new Date("2023-07-20"),
        author: {
          name: "Matilda Johnson",
          avatar: {
            src: "/images/post/mock/matilda.jpg",
          },
        },
        quantity: 3,
        present: {
          src: "/images/post/gifts/present-yellow-box.webp",
        },
      },
      {
        id: 4,
        date: new Date("2023-07-20"),
        author: {
          name: "Matilda Johnson",
          avatar: {
            src: "/images/post/mock/matilda.jpg",
          },
        },
        quantity: 2,
        present: {
          src: "/images/post/gifts/present-star.webp",
        },
      },
    ],
  },
  {
    id: 2,
    author: {
      nickname: "janedoe",
      name: "John Doe",
      position: "Elite creator",
      isVerified: true,
      avatar: {
        src: "/images/post/mock/jane.jpg",
      },
    },
    image: null,
    video: {
      src: "../../../public/video/1.mp4",
      poster: "/images/post/mock/post-image2.jpg",
      song: "We will Rock You - Queen",
    },
    location: {
      city: "Berlin",
      country: "Germany",
      latitude: 52.52,
      longitude: 13.405,
    },
    date: new Date("2023-07-20"),
    text: "Enjoying the thrill of gliding down snowy slopes on skis, embracing the winter wonderland. ",
    tags: ["skiLife", "winterAdventures"],
    likes: "120",
    comments: [
      {
        id: 1,
        author: {
          nickname: "matilda",
          name: "Matilda Johnson",
          position: "Elite creator",
          isVerified: true,
          avatar: {
            src: "/images/post/mock/matilda.jpg",
          },
        },
        text: "Great work!",
        likes: 2,
        date: new Date("2023-07-22"),
        replies: null,
      },
      {
        id: 2,
        author: {
          nickname: "jarvis",
          name: "Jarvis Anderson",
          position: "",
          isVerified: true,
          avatar: {
            src: "/images/post/mock/jarvis.jpg",
          },
        },
        text: "Excellent post, love it.",
        likes: 2,
        date: new Date("2023-07-23"),
        replies: null,
      },
    ],
    gems: [
      {
        id: 1,
        date: new Date("2023-07-20"),
        author: {
          name: "Jarvis Anderson",
          avatar: {
            src: "/images/post/mock/jarvis.jpg",
          },
        },
        quantity: 50,
      },
      {
        id: 2,
        date: new Date("2023-08-01"),
        author: {
          name: "Matilda Johnson",
          avatar: {
            src: "/images/post/mock/matilda.jpg",
          },
        },
        quantity: 150,
      },
    ],
    gifts: [
      {
        id: 1,
        date: new Date("2023-07-20"),
        author: {
          name: "Matilda Johnson",
          avatar: {
            src: "/images/post/mock/matilda.jpg",
          },
        },
        quantity: 1,
        present: {
          src: "/images/post/gifts/present-violet-box.webp",
        },
      },
      {
        id: 2,
        date: new Date("2023-07-20"),
        author: {
          name: "Matilda Johnson",
          avatar: {
            src: "/images/post/mock/matilda.jpg",
          },
        },
        quantity: 5,
        present: {
          src: "/images/post/gifts/present-flower.webp",
        },
      },
      {
        id: 3,
        date: new Date("2023-07-20"),
        author: {
          name: "Matilda Johnson",
          avatar: {
            src: "/images/post/mock/matilda.jpg",
          },
        },
        quantity: 3,
        present: {
          src: "/images/post/gifts/present-yellow-box.webp",
        },
      },
      {
        id: 4,
        date: new Date("2023-07-20"),
        author: {
          name: "Matilda Johnson",
          avatar: {
            src: "/images/post/mock/matilda.jpg",
          },
        },
        quantity: 2,
        present: {
          src: "/images/post/gifts/present-star.webp",
        },
      },
    ],
  },
  {
    id: 3,
    author: {
      nickname: "janedoe",
      name: "John Doe",
      position: "Elite creator",
      isVerified: true,
      avatar: {
        src: "/images/post/mock/jane.jpg",
      },
    },
    image: null,
    video: {
      src: "../../../public/video/1.mp4",
      poster: null,
      song: "We will Rock You - Queen",
    },
    location: {
      city: "Berlin",
      country: "Germany",
      latitude: 52.52,
      longitude: 13.405,
    },
    date: new Date("2023-07-20"),
    text: "I love sport!",
    tags: [],
    likes: "100",
    comments: [
      {
        id: 1,
        author: {
          nickname: "matilda",
          name: "Matilda Johnson",
          position: "Elite creator",
          isVerified: true,
          avatar: {
            src: "/images/post/mock/matilda.jpg",
          },
        },
        text: "Great work!",
        likes: 2,
        date: new Date("2023-07-22"),
        replies: null,
      },
      {
        id: 2,
        author: {
          nickname: "jarvis",
          name: "Jarvis Anderson",
          position: "",
          isVerified: true,
          avatar: {
            src: "/images/post/mock/jarvis.jpg",
          },
        },
        text: "Excellent post, love it.",
        likes: 2,
        date: new Date("2023-07-23"),
        replies: null,
      },
    ],
    gems: [
      {
        id: 1,
        date: new Date("2023-07-20"),
        author: {
          name: "Jarvis Anderson",
          avatar: {
            src: "/images/post/mock/jarvis.jpg",
          },
        },
        quantity: 50,
      },
      {
        id: 2,
        date: new Date("2023-08-01"),
        author: {
          name: "Matilda Johnson",
          avatar: {
            src: "/images/post/mock/matilda.jpg",
          },
        },
        quantity: 150,
      },
    ],
    gifts: [
      {
        id: 1,
        date: new Date("2023-07-20"),
        author: {
          name: "Matilda Johnson",
          avatar: {
            src: "/images/post/mock/matilda.jpg",
          },
        },
        quantity: 1,
        present: {
          src: "/images/post/gifts/present-violet-box.webp",
        },
      },
      {
        id: 2,
        date: new Date("2023-07-20"),
        author: {
          name: "Matilda Johnson",
          avatar: {
            src: "/images/post/mock/matilda.jpg",
          },
        },
        quantity: 5,
        present: {
          src: "/images/post/gifts/present-flower.webp",
        },
      },
      {
        id: 3,
        date: new Date("2023-07-20"),
        author: {
          name: "Matilda Johnson",
          avatar: {
            src: "/images/post/mock/matilda.jpg",
          },
        },
        quantity: 3,
        present: {
          src: "/images/post/gifts/present-yellow-box.webp",
        },
      },
      {
        id: 4,
        date: new Date("2023-07-20"),
        author: {
          name: "Matilda Johnson",
          avatar: {
            src: "/images/post/mock/matilda.jpg",
          },
        },
        quantity: 2,
        present: {
          src: "/images/post/gifts/present-star.webp",
        },
      },
    ],
  },
]
