import React, { useEffect, useState, Fragment } from 'react';
import mocData from './mocData.js';
import { useFormValidator } from '../../../../form-validator/hooks/index.js';
import ValidationSchema from '../../../../form-validator/ValidationSchema.js';
import HubTaskForm from '../Items/form/hubTaskForm/HubTaskForm.jsx';
import BackBtnWithTitlePage from '../../../../components/reusable/btns/backBtn/BackBtnWithTitlePage.jsx';
import MainLayout from '../../../../components/layouts/mainLayout/MainLayout.jsx';
import { useHubApp } from '../../../../appState/hooks/useHubApp';
import { useBuzzmiAuth } from '../../../../appState/hooks/useBuzzmiAuth/index.web.js';
import Strings from '../../../../string_key/Strings.web.js';
import Description from '../../../../components/reusable/assets/description/Description.jsx';
import HubMedia from '../Items/hubMedia/HubMedia.jsx';
import { useNavigation } from '@react-navigation/core';
import { EventRegister } from 'react-native-event-listeners';
import moment from 'moment';
import MainLayoutWithAspectSide from '../../../../components/layouts/mainLayout/MainLayoutWithAspectSide.jsx';
import { colors } from '../../../../res/colors.js';
import Loading from 'react-fullscreen-loading';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useAtom } from 'jotai';
import { userBuzzmiActivity } from '../../../../appState/hooks/hubState.js';
function parseDurationString(durationString) {
  const regex = /^(\d+)\s*(\w+)$/; // Regular expression to match the format "number unit"
  const match = durationString.match(regex);

  if (!match) {
    throw new Error('Invalid duration string format');
  }

  const number = parseInt(match[1], 10);
  const type = match[2].toLowerCase(); // Convert to lowercase for case-insensitivity

  return { type, number };
}
function parseTimeString(timeString) {
  const regex = /^(\d{1,2}):(\d{2})([APMapm]{2})$/; // Regular expression to match the format "hh:mmAM/PM"
  const match = timeString.match(regex);

  if (!match) {
    throw new Error('Invalid time string format');
  }

  const hours = parseInt(match[1], 10);
  const minutes = match[2];
  const ampm = match[3].toUpperCase(); // Convert to uppercase for consistency

  return { hours, minutes, ampm };
}
function padWithZero(number) {
  const paddedNumber = number < 10 ? `0${number}` : `${number}`;
  return paddedNumber;
}
function createDayObject(selectedDays) {
  if (!selectedDays?.length > 0)
    return {
      monday: true,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    };
  const allDays = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];

  const result = allDays.reduce((acc, day) => {
    acc[day] = selectedDays?.some(
      selectedDay => selectedDay.toLowerCase() === day,
    );
    return acc;
  }, {});

  return result;
}
const validationSchema = new ValidationSchema({
  timing: [{ rule: 'required' }],
  // note: [
  //   // {rule: 'required'},
  //   {rule: 'minLength', value: 3},
  //   {rule: 'maxLength', value: 40},
  // ],
  // tasks: [{rule: 'required'}, {rule: 'minArray', value: 1}],
  // photos: [{ rule: "required" }, { rule: "minObjects", value: 1 }],
  // tags: [{rule: 'required'}, {rule: 'minArray', value: 1}],
  // images: [{rule: 'required'}, {rule: 'minArray', value: 1}],
});

const HubCreateTaskv2 = props => {
  // console.log("props?.item")
  // console.log(props?.item?.id)
  // console.log(props);
  const data = mocData;
  const [error, setError] = useState(null);
  const [btnValid, setBtnValid] = useState(false);
  const [isEditName, setIsEditName] = useState(false);
  const [isProgressImageEnabled, setIsProgressImageEnabled] = useState(false);

  const [isShare, setIsShare] = useState(false);
  const [isAssign, setIsAssign] = useState(false);
  const [buzzmiActivity, setUserBuzzmiActivity] = useAtom(userBuzzmiActivity);
  const {
    postCreatePlanActivity,
    CreatePlanActivityResponse,
    isCreatePlanActivityLoading,
    CreatePlanActivityErrorResponse,
  } = useHubApp();
  const { userData } = useBuzzmiAuth();
  const { navigate } = useNavigation();
  const d = props?.item ? props?.item : [props];
  const baseData = { ...d };
  // console.log('baseData', baseData);
  useEffect(() => {
    if (CreatePlanActivityResponse) {
      console.log(
        'CreatePlanActivityResponse====>',
        CreatePlanActivityResponse,
      );
      // navigate('HubTasks');
      props?.showActivityModal(false);
      isAssign || isShare ? null : props?.setOpen(true);

      props?.handleOpenModals(
        isAssign
          ? CreatePlanActivityResponse
          : isShare
            ? props?.item
            : props?.item,
        isAssign ? 'Assign' : isShare ? 'Share' : '',
      );
      // EventRegister.emit('goBackTo-planActivities');
      EventRegister.emit('refresh-planActivities');
    }
  }, [CreatePlanActivityResponse, CreatePlanActivityErrorResponse]);
  function formatTime(hours, minutes, ampm) {
    // Pad single-digit hours and minutes with leading zeros

    const paddedHours = hours < 10 ? `0${hours}` : hours;
    const paddedMinutes = minutes;

    // Concatenate the formatted time and am/pm
    const formattedTime = `${paddedHours}:${paddedMinutes}${ampm}`;

    return formattedTime;
  }
  function getDateFromString(dateString) {
    // Create a Date object from the input string
    const dateObject = new Date(dateString);

    // Get the day of the month
    const dayOfMonth = dateObject.getDate();

    return [dayOfMonth + ''];
  }
  function getWeekdayNames(obj) {
    // Define an array of all days of the week
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const weekdays = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
    ];

    const x = Object.keys(obj)
      .filter(key => weekdays.includes(key))
      .map(key => capitalizeFirstLetter(key));

    console.log('xxx', x);
    return x;
  }
  const sendRequest = async formData => {
    try {
      let param_data = Object.assign({
        title: formData?.title,
        description: formData?.description,
        imageURL: formData?.image,

        timing: formData?.timing,
        preset: false,
        tags: [],
        specificTime: '',
        // repeatInterval: [0],
        // repeatWeekDays: [''],
        repeatIntervalCategory: '',
        activityDays: [],
        goal: '',
        reminderTime: '',
        isProgressImageEnable: isProgressImageEnabled,
        // progressImages: [''],
        audioDescription:
          formData?.audioDescription?.length > 0
            ? formData?.audioDescription
            : '',
        audioTitle:
          formData?.audioTitle?.length > 0 ? formData?.audioTitle : '',
        isPlanner: props?.isPlanner,
        isCustomGoal: formData?.isCustomGoal,
        customGoal: formData?.customGoal,
        // endDate: '2023-11-14T10:09:53.357Z',
      });

      console.log(formData)
      // return
      formData?.specificTime &&
        Object.assign(param_data, {
          timing: 'Specific time',
          specificTime: formatTime(
            formData.specificTime.hours,
            formData.specificTime.minutes,
            formData.specificTime.ampm,
          ),
        });

      baseData?.id &&
        Object.assign(param_data, {
          baseTaskId: baseData?.id,
        });

      baseData?.mediaUrl && baseData?.mediaUrl.length > 0 &&
        Object.assign(param_data, {
          mediaUrl: baseData?.mediaUrl,
        });

      baseData?.descriptionMedia?.values?.length > 0 &&
        Object.assign(param_data, {
          descriptionMedia: baseData?.descriptionMedia?.values,
        });

      baseData?.imagesDescriptions?.length > 0 &&
        Object.assign(param_data, {
          imagesDescriptions: baseData?.imagesDescriptions,
        });
      baseData?.imagesTitles?.length > 0 &&
        Object.assign(param_data, {
          imagesTitles: baseData?.imagesTitles,
        });

      // console.log('baseData?.descriptionMedia?', baseData?.descriptionMedia);
      baseData?.descriptionAudio &&
        Object.assign(param_data, {
          descriptionAudio: baseData?.descriptionAudio,
        });
      // baseData?.descriptionVideo &&
      //   Object.assign(param_data, {
      //     descriptionVideo: baseData?.descriptionVideo,
      //   });

      formData?.tags?.length > 0 &&
        Object.assign(param_data, {
          tags: formData?.tags?.map(item => item.id),
        });
      formData?.images?.length > 0 &&
        Object.assign(param_data, {
          progressImages: Object.values(formData?.images),
        });
      formData?.note && Object.assign(param_data, { note: formData?.note });

      formData?.reminder &&
        Object.assign(param_data, {
          reminderTime:
            formData?.reminder?.hours +
            ':' +
            formData?.reminder?.minutes +
            formData?.reminder?.ampm,
        });

      formData?.repeat &&
        Object.assign(param_data, {
          repeatIntervalCategory: formData?.repeat?.type,
        });
      formData?.repeat &&
        Object.assign(param_data, {
          repeatInterval: parseInt(formData?.repeat?.every, 10),
        });
      console.log('formData?.repeat?.value', formData?.repeat?.value);
      formData?.repeat &&
        Object.keys(formData?.repeat?.value)?.length > 0 &&
        Object.assign(param_data, {
          activityDays:
            formData?.repeat?.type === 'Week'
              ? getWeekdayNames(formData?.repeat?.value)
              : formData?.repeat?.type === 'Month'
                //  ? getDateFromString(formData?.repeat?.value)
                ? formData?.repeat?.value
                : [],
          // activityDays: [],
        });

      formData?.tasks &&
        Object.assign(param_data, {
          checkList: formData?.tasks,
        });
      formData?.goal &&
        formData?.goal?.number > 0 &&
        Object.assign(param_data, {
          goal: formData?.goal?.number + ' ' + formData?.goal?.type,
        });

      Object.assign(param_data, {
        startDate: moment(new Date()).format('YYYY-MM-DD'),
      });
      //ToDO send start date and end date
      //   formData?.startDate &&
      //   Object.assign(param_data, {
      //     startDate: formData?.startDate
      //   });

      formData?.endDate &&
        Object.assign(param_data, {
          endDate: moment(formData?.repeat?.endDate).format('YYYY-MM-DD'),
        });

      formData?.progimgDes?.length > 0 &&
        Object.assign(param_data, {
          progressImagesDescriptions: formData?.progimgDes,
        });
      formData?.progimgTitles?.length > 0 &&
        Object.assign(param_data, {
          progressImagesTitles: formData?.progimgTitles,
        });
      console.log('param_data', param_data);
      postCreatePlanActivity(
        Object.assign(param_data, {
          params: { isAuth: true, token: userData?.token },
        }),
      );
    } catch (e) {
      console.log('API error => ', e.message);
      setError(e);
    }
  };
  // console.log('itemhere==>', props?.item);
  const { handleSubmit, setValue, values, isValid } = useFormValidator(
    validationSchema,
    sendRequest,
    {
      defaultValues: {
        title: props?.item?.title,
        description: props?.item?.description,
        image: props?.item?.image,

        tags: props?.item?.tags,
        progimgDes: [],
        progimgTitles: [],
        images: [],
        photos: false,
        timing: props?.item?.timing?.length > 0 ? props?.item?.timing : null,
        isCustomGoal: props?.item?.isCustomGoal,
        customGoal: props?.item?.customGoal,
        specificTime:
          props?.item?.specificTime?.length > 0
            ? parseTimeString(props?.item?.specificTime)
            : null,
        goal: props?.item?.isCustomGoal
          ? { type: 'mins', number: 0 }
          : props?.item?.goal?.length > 0
            ? parseDurationString(props?.item?.goal)
            : { type: 'mins', number: 0 },
        note: props?.item?.note?.length > 0 ? props?.item?.note : null,
        tasks:
          props?.item?.checkList?.list?.length > 0
            ? props?.item?.checkList?.list
            : null,
        reminder:
          props?.item?.remindingTime?.length > 0
            ? parseTimeString(props?.item?.remindingTime)
            : null,
        progimgDes:
          props?.item?.progressImagesDescriptions?.length > 0
            ? props?.item?.progressImagesDescriptions
            : [],
        progimgTitles:
          props?.item?.progressImagesTitles?.length > 0
            ? props?.item?.progressImagesTitles
            : [],
        images:
          props?.item?.progressImages?.images?.length > 0
            ? props?.item?.progressImages?.images
            : [],
        repeat:
          props?.item?.repeatIntervalCategory?.length > 0
            ? {
              type: props?.item?.repeatIntervalCategory, // day/week/month
              every: padWithZero(props?.item?.repeatInterval), // will be required for day/week category only
              value:
                props?.item?.repeatIntervalCategory === 'Week'
                  ? createDayObject(props?.item?.activityDays?.days)
                  : props?.item?.repeatIntervalCategory === 'Month'
                    ? props?.item?.activityDays?.days
                    : [], // will contain all the dates (array) selected from the calendar
              endDate: moment().add(1, 'days').format('LL'),
            }
            : null,
      },
    },
  );

  useEffect(() => {
    console.log(
      'values?.images?.length',
      values?.images?.length,
      values?.progimgDes?.length,
      values?.progimgTitles?.length,
    );
    if (
      values?.images?.length !== values?.progimgDes?.length ||
      values?.images?.length !== values?.progimgTitles?.length
    ) {
      setBtnValid(false);
    } else {
      setBtnValid(true);
    }
  }, [
    values?.images?.length,
    values?.progimgDes?.length,
    values?.progimgTitles?.length,
  ]);

  return (
    // <MainLayoutWithAspectSide showRankInfo={false} showTitleButton={false}>
    <>
      <div
        className="main-layout__single-container pt-1"
        style={{ maxWidth: 1000 }}>
        {/* <BackBtnWithTitlePage
        title={baseData?.title}
        lastItemLink={{
          href: 'HubAddTask',
          title: 'Edit Activity',
          params: {item: baseData, isEdit: true, fromPath: 'HubCreateTask'},
        }}
      /> */}
        <Loading
          loading={isCreatePlanActivityLoading}
          background="transparent"
          loaderColor={colors.PRIMARY_COLOR}
        />
        {/* {console.log('baseData', baseData)} */}

        <div className="mb-4">
          <Description
            item={values}
            onChangeTitle={e => setValue('title', e.target.value)}
            onChangeDes={e => setValue('description', e.target.value)}
            isEdit={isEditName}
            setIsEditName={setIsEditName}
          />
        </div>

        <HubMedia
          galleryList={baseData?.descriptionMedia?.values}
          videoSrcLink={baseData?.descriptionVideo}
          audioSrcLink={baseData?.descriptionAudio}
          item={baseData}
        />
        <HubTaskForm
          values={values}
          error={error}
          handleSubmit={handleSubmit}
          setValue={setValue}
          data={data}
          btnTitle={Strings.save}
          // isValid={isValid && btnValid}
          isValid={isValid}
          item={baseData}
          setIsProgressImageEnabled={setIsProgressImageEnabled}
          isPlanner={props?.isPlanner}
          setIsPlanner={props?.setIsPlanner}
          setIsShare={setIsShare}
          setIsAssign={setIsAssign}
        />
      </div>
    </>
    // </MainLayoutWithAspectSide>
  );
};

export default HubCreateTaskv2;
