import { useEffect } from "react";
import { useBuzzmiAuth } from "../../../../../../appState/hooks/useBuzzmiAuth/index.web";
import { useUserBadges } from "../../../../../../appState/hooks/useUserBadges";
import Box from "../../../../../../components/reusable/cards/box/Box";

const Badges = () => {

    const { userData } = useBuzzmiAuth();
    const { usersBadges } = useUserBadges();

    return (
        <div className={"mt-4"}>
            {usersBadges?.length > 0 && usersBadges.map((item, index) => (
                <Box key={index} className={"flex items-center mt-[10px] pt-2 pr-4 pb-2 pl-2 mb-2 h-24 rounded-xl"}>
                    <div className='mr-4'>
                        <img src={item?.imageUrl} className={item?.isAchived ? "w-[100px]" : "from-black opacity-40 w-[100px]"}></img>
                    </div>
                    <div>
                        <h6 className='text[15px] font-bold mb-2'>{item?.name}</h6>
                        <p className='text-xs' style={{ color: "#584F68" }}>{item?.content}</p>
                    </div>
                </Box>
            ))}
            {
                usersBadges?.length == 0 &&
                <h5 className='mt-5 text-sm text-center mt-6 mb-2'>No Badges Found</h5>
            }
        </div>
    );
};

export default Badges;