import React, {useState, Fragment} from 'react';
import {useProfileApp} from '../../../../../appState/hooks';
import {useBuzzmiAuth} from '../../../../../appState/hooks/useBuzzmiAuth/index.web';
import Loading from 'react-fullscreen-loading';
import {colors} from '../../../../../res/colors';
import CreatePostModal from '../../ProfileMain/componentsV2/CreatePostModal';
import {useEffect} from 'react';
import {usePostBookmark} from '../../../../../appState/hooks/usePostBookmark';
import {useBookMarkLists} from '../../../../../appState/hooks/useBookMarkLists';
import {useToast} from 'react-native-toast-notifications';
import {ShowSuccess} from '../../../../HOC/MessageWeb';
import {EventRegister} from 'react-native-event-listeners';
import {Dialog, Menu, Transition} from '@headlessui/react';

const DeletePostModal = ({open, setOpen, onDelete}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        // initialFocus={rootButtonRef}
        onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900">
                      Are you sure you want to delete?
                    </Dialog.Title>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-red-500 text-white px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={onDelete}>
                    {'Delete'}
                  </button>

                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={e => {
                      setOpen(false);
                    }}>
                    {'Cancel'}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const PostMenuItem = ({key, text, alt, icon, id, type, item, onClose}) => {
  const [isHovered, setIsHovered] = useState(false);
  const {userData} = useBuzzmiAuth();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [types, setTypes] = useState('post');
  const toast = useToast();

  const {
    postHideSpecificPost,
    deleteReelFromList,
    isDeleteReelLoading,
    deletePostFromList,
    isDeletePostLoading,
    deleteBookMarks,
    DeleteBookmarkReasponse,
    postFollowUser,
    postUnfollowUser,
  } = useProfileApp();
  const {bookmarkResponse, postOnBookMark} = usePostBookmark();
  const {getAllBookMarkList} = useBookMarkLists();

  useEffect(() => {
    if (bookmarkResponse) {
      console.log('bookmarkResponse', bookmarkResponse);
      onClose();
      ShowSuccess(bookmarkResponse?.message, toast);
      getAllBookMarkList(userData?.token);
      EventRegister.emit(
        'refresh-bookmark-list',
        Object.assign(item, {isBookmarked: true}),
      );
    }
  }, [bookmarkResponse, userData]);

  useEffect(() => {
    if (DeleteBookmarkReasponse) {
      console.log('DeleteBookmarkReasponse', DeleteBookmarkReasponse);
      onClose();
      ShowSuccess(DeleteBookmarkReasponse?.message, toast);
      getAllBookMarkList(userData?.token);
      EventRegister.emit(
        'refresh-bookmark-list',
        Object.assign(item, {isBookmarked: false}),
      );
    }
  }, [DeleteBookmarkReasponse]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    setTypes(type);
  }, [type]);

  return (
    <>
      <Loading
        loading={isDeleteReelLoading || isDeletePostLoading}
        background="transparent"
        loaderColor={colors.PRIMARY_COLOR}
      />
      <li
        className="ellipsis-menu__menu-item d-flex flex-row align-items-center"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          if (alt == 'delete') {
            setOpenDelete(true);

            // if (type == 'reel') {
            //   deleteReelFromList(id, userData.token);
            // } else {
            //   deletePostFromList(id, userData.token);
            // }
          }
          if (alt == 'save') {
            let param_data = {};
            postOnBookMark(item?.id, param_data);
          }
          if (alt == 'saved') {
            deleteBookMarks(item?.id, userData.token);
          }
          if (alt == 'edit') {
            setOpen(() => true);
          }
          if (alt == 'unfollowed') {
            postUnfollowUser(item?.owner?.member?.externalId, userData.token);
            onClose();
            EventRegister.emit(
              'refresh-bookmark-list',
              Object.assign(item, {followed: false}),
            );
          }
          if (alt == 'followed') {
            postFollowUser(item?.owner?.member?.externalId, userData.token);
            onClose();
            EventRegister.emit(
              'refresh-bookmark-list',
              Object.assign(item, {followed: true}),
            );
          }
          if (alt == 'hide') {
            postHideSpecificPost(type, item?.id, userData.token);
          } else {
            console.log(item);
          }
        }}>
        {React.cloneElement(icon, {
          isHovered,
          alt,
          className: 'ellipsis-menu__menu-icon',
        })}
        <p className="h6">{text}</p>
      </li>
      <DeletePostModal
        open={openDelete}
        setOpen={setOpenDelete}
        onDelete={() => {
          if (type == 'reel') {
            deleteReelFromList(id, userData.token);
          } else {
            deletePostFromList(id, userData.token);
          }
          setOpenDelete(false);
          // onClose();
        }}
      />
      <CreatePostModal
        setOpen={setOpen}
        closeAll={() => onClose()}
        open={open}
        post={item}
        stypes={types}
        is_edit={true}
      />
    </>
  );
};

export default PostMenuItem;
