import {Link} from 'react-router-dom';

const ProfileHeaderMobile = () => {
  return (
    <div className="profile-top__header-row">
      <Link to="/">
        <img src="/images/info/small-header-logo.svg" alt="logo" />
      </Link>

      <div className="profile-top__header-btns">
        <Link className="profile-top__header-link" to="/">
          <img src="/images/info/message.svg" alt="logo" />
        </Link>
        <Link className="profile-top__header-link" to="/">
          <img src="/images/info/notification.svg" alt="logo" />
        </Link>
        <Link className="profile-top__header-link" to="/">
          <img src="/images/info/add.svg" alt="logo" />
        </Link>
        <Link className="profile-top__header-link" to="/">
          <img src="/images/info/like.svg" alt="logo" />
        </Link>
        {/* <Link className="profile-top__header-link" to="/">
          <img src="/images/info/search.svg" alt="logo" />
        </Link> */}
      </div>
    </div>
  );
};

export default ProfileHeaderMobile;
