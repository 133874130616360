import { Rating } from 'react-simple-star-rating';
import { FullStar } from './FullStar';
import EmptyStar from './EmptyStar';

const CustomRating = props => {
  // return null;
  return (
    <div>
      <Rating
        fillIcon={<FullStar />}
        emptyIcon={<EmptyStar />}
        className={"rating"}
        {...props}
      />
    </div>
  );
};

export default CustomRating;
