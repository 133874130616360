import React, { useState, useRef } from 'react';
import LinkTabs from '../../../../components/reusable/assets/linkTabs/LinkTabs';
import ProfileMobileUsersTabs from './items/ProfileMobileUsersTabs';
import { useProfileApp } from '../../../../appState/hooks';
import { useProfileInfo } from '../../../../appState/hooks/useProfileInfo';

export const tabsInit = [
  {
    id: 'following',
    title: 'Following',
  },
  {
    id: 'followers',
    title: 'Followers',
  }
];

const VisitorProfileUsersBody = ({ hideheader }) => {
  const [tabs, setTabs] = useState(tabsInit);
  const [activeTabIndex, setActiveTabIndex] = useState(global.vFriendIndex || 0);

  const [lodaingFollow, setlodaingFollow] = useState(false);
  const [lodaingFollowers, setlodaingFollowers] = useState(false);

  const [followPage, setFollowPage] = useState(1);
  const [followersPage, setFollowersPage] = useState(1);

  const [showLoadMoreFollow, setShowLoadMoreFollow] = useState(false);
  const [showLoadMoreFollower, setShowLoadMoreFollower] = useState(false);
  const {
    postFollowUser,
    postUnfollowUser,
    postUnfollowerUser,
    userContactInfo,
  } = useProfileApp();
  const { profileInfo } = useProfileInfo();


  const handleTabSelect = (index) => {
    setActiveTabIndex(index)
    setFollowPage(1)
  }

  return (
    <div className="profile-mobile-users">
      <LinkTabs
        activeTabId={tabsInit[activeTabIndex].id}
        list={tabs}
        onChange={(i) => {
          handleTabSelect(tabsInit.findIndex(item => item.id === i))
        }}
      />
      <div className="mt-3">
        <ProfileMobileUsersTabs
          postFollowUser={postFollowUser}
          postUnfollowUser={postUnfollowUser}
          postUnfollowerUser={postUnfollowerUser}
          activeTabId={tabs[activeTabIndex].id}
          lodaingFollow={lodaingFollow}
          lodaingFollowers={lodaingFollowers}
          showLoadMoreFollow={showLoadMoreFollow}
          showLoadMoreFollower={showLoadMoreFollower}
          onPressFollowLoadMore={() => console.log('onPressFollowLoadMore')}
          onPressFollowerLoadMore={() => console.log('onPressFollowerLoadMore')}
          followersPageNum={followersPage}
          followers={{
            users: profileInfo?.followers,
            suggestedForYou: [],
          }}
          following={{
            users: profileInfo?.following,
            suggestedForYou: [],
          }}
          subscribers={[]}
          followPage={followPage}
          userContactInfo={userContactInfo}
          isVisitorProfile={true}
        />
      </div>
    </div>
  );
};

export default VisitorProfileUsersBody;
