import React from 'react';
import AddProfileStories from './items/addProfileStories/AddProfileStories';
import ProfileStoriesIcon from './items/profileStoriesIcon/ProfileStoriesIcon';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

const mock = [
  {
    icon: {
      src: '/images/creators/3.jpg',
      alt: 'Profile picture',
    },
  },
  {
    icon: {
      src: '/images/creators/3.jpg',
      alt: 'Profile picture',
    },
  },
  {
    icon: {
      src: '/images/creators/3.jpg',
      alt: 'Profile picture',
    },
  },
  {
    icon: {
      src: '/images/creators/3.jpg',
      alt: 'Profile picture',
    },
  },
  {
    icon: {
      src: '/images/creators/3.jpg',
      alt: 'Profile picture',
    },
  },
  {
    icon: {
      src: '/images/creators/3.jpg',
      alt: 'Profile picture',
    },
  },
  {
    icon: {
      src: '/images/creators/3.jpg',
      alt: 'Profile picture',
    },
  },
  {
    icon: {
      src: '/images/creators/3.jpg',
      alt: 'Profile picture',
    },
  },
  {
    icon: {
      src: '/images/creators/3.jpg',
      alt: 'Profile picture',
    },
  },
  {
    icon: {
      src: '/images/creators/3.jpg',
      alt: 'Profile picture',
    },
  },
  {
    icon: {
      src: '/images/creators/3.jpg',
      alt: 'Profile picture',
    },
  },
  {
    icon: {
      src: '/images/creators/3.jpg',
      alt: 'Profile picture',
    },
  },
  {
    icon: {
      src: '/images/creators/3.jpg',
      alt: 'Profile picture',
    },
  },
  {
    icon: {
      src: '/images/creators/3.jpg',
      alt: 'Profile picture',
    },
  },
  {
    icon: {
      src: '/images/creators/3.jpg',
      alt: 'Profile picture',
    },
  },
  {
    icon: {
      src: '/images/creators/3.jpg',
      alt: 'Profile picture',
    },
  },
  {
    icon: {
      src: '/images/creators/3.jpg',
      alt: 'Profile picture',
    },
  },
  {
    icon: {
      src: '/images/creators/3.jpg',
      alt: 'Profile picture',
    },
  },
];

const ProfileStories = () => {
  return (
    <div className="mt-5 profile-stories">
      <Swiper
        spaceBetween={12}
        slidesPerView="auto"
        navigation={true}
        slidesPerGroupSkip={3}
        grabCursor={true}
        freeMode
        style={{
          '--swiper-navigation-size': '12px',
          '--swiper-pagination-color': '#000',
          '--swiper-theme-color': '#000',
        }}
        modules={[Navigation]}
        className="profile-stories__swiper">
        {/* <SwiperSlide>
          <AddProfileStories />
        </SwiperSlide> */}

        {mock.map((item, index) => (
          <SwiperSlide key={index}>
            <ProfileStoriesIcon item={item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ProfileStories;
