import styled from 'styled-components/native';
import {IS_IPHONE_X} from '../../../constants.ts';
import {widthPercentageToDP as wp} from 'react-native-responsive-screen';

const BannerContainer = styled.View`
  width: 100%;
  flex-direction: row;
  align-items: center;
  background-color: white;
  align-self: center;
  border-top-width: 0.5px;
  justify-content: space-evenly;
  border-top-color: rgba(0, 0, 0, 0.18);
  padding-vertical: 0.2rem;
`;
const Container = styled.View`
  border-top-width: 0.5px;
  flex-direction: row;
  background-color: white;
  width: 100%;
  justify-content: space-evenly;
  border-top-color: rgba(0, 0, 0, 0.18);
`;

export {Container, BannerContainer};
