import React, { useState } from "react"
import BackBtnWithTitlePage from "../../../../components/reusable/btns/backBtn/BackBtnWithTitlePage.jsx"
import InfoCard from "../../../../components/reusable/cards/infoCard/InfoCard.jsx"
import SmallCardDispatcher from "../../../../components/reusable/cards/smallCard/SmallCardDispatcher.jsx";

import Gallery from "../../../../components/reusable/gallary/Gallery.jsx"
import AudioPlayer from "../../../../components/reusable/audioPlayer/AudioPlayer.jsx"
import VideoGallery from "../../../../components/reusable/videoGallery/VideoGallery.jsx"
import MainLayout from "../../../../components/layouts/mainLayout/MainLayout.jsx";

// mock data
import { infoCardData, tasksData } from "../../../../mock-data/hub/hub.js"
import { galleryList, videoSrcLink } from "../../../../mock-data/gallery/gallery.js"

const HubPresetPreview = () => {
  return (
    <MainLayout>

      <div className="hub">
        <div className="hub__content">
          <BackBtnWithTitlePage
            title="Energetic Morning"
            btnText="Cancel"
            lastItemLink={{
              href: "HubEditPreset",
              title: "Edit",
            }}
          />

          <div className="mb-4">
            <InfoCard item={infoCardData} />
          </div>
          <div className="hub__holder">
            <Gallery list={galleryList} isSmall />
            <div className="hub__media">
              <div className="hub__video mb-3">
                <VideoGallery title={"Energetic Morning"} src={videoSrcLink} />
              </div>
              <div className="hub__audio">
                <AudioPlayer src="/sounds/test-track.mp3" />
              </div>
            </div>
          </div>
          <div className="present-preview__cards">
            <h2 className="h4 mb-2">Tasks</h2>
            <SmallCardDispatcher list={tasksData} dnd={true} />
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default HubPresetPreview

