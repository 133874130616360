export const FullStar = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.24169 0.50786C8.54016 -0.169287 9.45984 -0.169287 9.75831 0.50786L11.7886 5.114C11.9102 5.38998 12.1602 5.58 12.4486 5.61578L17.2622 6.21303C17.9699 6.30083 18.2541 7.21612 17.7309 7.72242L14.172 11.1664C13.9588 11.3728 13.8633 11.6802 13.9199 11.9783L14.8646 16.9536C15.0035 17.685 14.2595 18.2507 13.6377 17.8864L9.40789 15.4088C9.15447 15.2604 8.84553 15.2604 8.59211 15.4088L4.36233 17.8864C3.74051 18.2507 2.99648 17.685 3.13536 16.9536L4.08005 11.9783C4.13665 11.6802 4.04119 11.3728 3.82796 11.1664L0.269121 7.72242C-0.254063 7.21612 0.0301315 6.30083 0.737782 6.21303L5.55141 5.61578C5.83982 5.58 6.08975 5.38998 6.2114 5.114L8.24169 0.50786Z"
      fill="url(#paint0_linear_2142_63793)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2142_63793"
        x1="6.13618"
        y1="-2.14286"
        x2="15.0081"
        y2="20.2985"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFE81B" />
        <stop offset="1" stop-color="#E7A94D" />
      </linearGradient>
    </defs>
  </svg>
)
