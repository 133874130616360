import React, { useEffect, useRef, useState } from "react"
import UserBtnsRowMobile from "../items/userBtnsRow/UserBtnsRowMobile"
import { useProfileInfo } from "../../../../../appState/hooks/useProfileInfo"
import VisitorProfileInfoMobile from "../../ProfileMain/profileMainMobile/items/visitorProfileInfoMobile/VisitorProfileInfoMobile"
import VisitorProfileContentTabs from "../../items/visitorProfileContentTabs/VisitorProfileContentTabs"
import { useProfileApp } from "../../../../../appState/hooks"
import { useBuzzmiAuth } from "../../../../../appState/hooks/useBuzzmiAuth/index.web"
import BackBtnWithTitlePage from "../../../../../components/reusable/btns/backBtn/BackBtnWithTitlePage"
import VisitorFriendsModal from "../../ProfileMain/componentsV2/VisitorFriendsModal"
import { useVisitorRankInfo } from "../../../../../appState/hooks/useVisitorRankInfo"
import useOnClickOutside from "../../../../../hooks/useOnClickOutside"
import { ProfileMenuData } from "../../items/ellipsisMenu/PostMenuData"
import SharePostModal from "../../ProfileMain/componentsV2/SharePostModel"
import { EventRegister } from "react-native-event-listeners"

const ProfileUserMobile = ({ isOtherProfile }) => {
  const { userData } = useBuzzmiAuth();
  const { profileInfo } = useProfileInfo();
  const { getVisitorPostLists, getVisitorReelLists } = useProfileApp();
  const [openFriendsModal, setOpenFriendsModal] = useState(false);
  const { getVisitorRankInfoDetail } = useVisitorRankInfo()
  const menuPostRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const [isHovered, setIsHovered] = useState(false)
  const [openShareModal, setOpenShareModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const { visitorprofileShareData, getProfileShareDetailByID, postUnfollowUser, postFollowUser } = useProfileApp();

  useEffect(() => {
    if (profileInfo && isOtherProfile == true) {
      getVisitorPostLists(userData?.token, profileInfo?.id, { page: 1, limit: 20 });
      getVisitorReelLists(userData?.token, profileInfo?.id, { page: 1, limit: 20 });
      getVisitorRankInfoDetail(profileInfo?.id, userData?.token);
      getProfileShareDetailByID(profileInfo?.id, userData.token, null);
    }
  }, [profileInfo, isOtherProfile]);


  const handleMenu = event => {
    event.stopPropagation();
    setShowMenu(!showMenu);
  };

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const handleShare = () => {
    setModalData({
      userName: `@${profileInfo?.displayName}`,
      userShareDetails: visitorprofileShareData,
      userAvatar: profileInfo?.avatar
        ? profileInfo?.avatar
        : '/images/moc/avatar-big.png',
      typeSharingItem: 'Profile',
    });
    setOpenShareModal(true);
  };

  useOnClickOutside(menuPostRef, showMenu, setShowMenu);
  return (
    <>
      <BackBtnWithTitlePage
        title={profileInfo && profileInfo?.displayName}
        className="mt-0"
        btnText="Back"
        isElipsicon
        onElipseClick={handleMenu}
      />

      {/* Menu overlay */}
      <>
        {showMenu && <div className="ellipsis-menu__overlay"></div>}
        <div
          ref={menuPostRef}
          className={`ellipsis-menu__menu-wrapper ${showMenu ? 'opened' : ''}`}>
          <div className="d-flex flex-row align-items-center justify-content-between">
            <h4 className="h4">Options</h4>

            <button className="ellipsis-menu__close-menu" onClick={handleMenu}>
              <img src="/images/assets/close-icon.svg" alt="close menu" />
            </button>
          </div>

          <ul className="ellipsis-menu__menu-list d-flex flex-column">
            {ProfileMenuData.map(item => (
              <li
                className="ellipsis-menu__menu-item d-flex flex-row align-items-center"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() => {
                  console.log(item?.alt)
                  if (item?.alt == "share") {
                    handleShare()
                  } if (item?.alt == "unfollow") {
                    profileInfo?.isFollowing ? postUnfollowUser(profileInfo?.id, userData.token) : postFollowUser(profileInfo?.id, userData.token)

                    EventRegister.emit('is_follow_unfollow', {
                      data: profileInfo?.isFollowing ? "unfollow" : "follow"
                    });
                    setShowMenu(!showMenu);
                  }
                }}
              >
                {React.cloneElement(item.icon, { className: "ellipsis-menu__menu-icon" })}
                <p className="h6">{item.text == "Unfollow" ? profileInfo?.isFollowing ? "Unfollow" : "Follow" : item.text}</p>
              </li>

            ))}
          </ul>
        </div>
      </>

      <VisitorProfileInfoMobile showAchievement isOtherProfile onPress={() => setOpenFriendsModal(true)} />
      <div className="mt-4">
        <UserBtnsRowMobile />
      </div>
      <div className="mt-4">
        <VisitorProfileContentTabs />
      </div>
      <VisitorFriendsModal open={openFriendsModal} setOpen={setOpenFriendsModal} />
      {/* <ProfileStories /> */}

      <SharePostModal
        open={openShareModal}
        data={modalData}
        setOpen={setOpenShareModal}
      />
    </>
  )
}

export default ProfileUserMobile
