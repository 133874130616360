import React from "react"
import MainLayoutContent from "../../../../components/layouts/mainLayout/mainLayoutContent/MainLayoutContent"
import ProfileMobileAboutBody from "./ProfileMobileAboutBody"
import ResizeComponent from "../../../../components/reusable/assets/resizeComponent/ResizeComponent"
import MainLayout from "../../../../components/layouts/mainLayout/MainLayout"

const ProfileMobileAboutV2 = () => {
    return (
        <MainLayout hidelayout>
            <MainLayoutContent>
                <ResizeComponent mobileComponent={<ProfileMobileAboutBody />} />
            </MainLayoutContent>
        </MainLayout>
    )
}

export default ProfileMobileAboutV2
