import React from "react"

const TextareaWithoutBorder = ({ placeHolder, value, onChange }) => {
  return (
    <textarea
      className="textarea textarea-without-border"
      placeholder={placeHolder}
      value={value}
      onChange={onChange}
    ></textarea>
  )
}

export default TextareaWithoutBorder
