import React, {useState} from 'react';
import GiftsIcon from '../../../icons/GiftsIcon';
// import { Link } from "react-router-dom"
import {Link} from '@react-navigation/native';
import GiftsModal from '../../../../ProfileMain/componentsV2/GiftsModal';
const GiftsPostBtn = ({gifts, postId}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [openGiftsModal, setOpenGiftsModal] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      {/* <Link to={`/profile/post/${postId}/gifts`}> */}

      <div
        onClick={e => {
          e.preventDefault();
          setOpenGiftsModal(() => true);
        }}
        className="post-buttons__item d-flex align-items-center"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <GiftsIcon isHovered={isHovered} />
        <p className={`post-buttons__item-text ${isHovered ? 'hovered' : ''}`}>
          {gifts}
        </p>
      </div>
      {/* </Link> */}
      <GiftsModal
        open={openGiftsModal}
        setOpen={setOpenGiftsModal}
        data={{entity: 'post', id: postId}}
      />
    </>
  );
};

export default GiftsPostBtn;
