import styled from 'styled-components/native';
import {fonts} from '../../../../../../res/fonts';
import {colors} from '../../../../../../res/colors';
import {widthPercentageToDP as wp} from 'react-native-responsive-screen';

const TabTitle = styled.Text`
  text-align: center;
  font-family: ${props => (props.isSelected ? fonts.REGULAR : fonts.BOLD)};
  font-size: 1rem;
  color: ${props => (props.isSelected ? colors.PRIMARY_COLOR : colors.WHITE)};
`;
const TabItem = styled.Pressable`
  // flex: 1;
  align-items: center;
  justify-content: center;
  border-radius: 0.4rem;

  margin-vertical: 0.2rem;
  padding-horizontal: 10%;
  background-color: ${props =>
    props.isSelected ? colors.WHITE : colors.PRIMARY_COLOR};
`;

const TabContainer = styled.View`
  overflow: hidden;
margin-left:2.3rem
  flex-direction: row;
  border-radius: 9px;
  border-width: 0.5px;
  border-color: #7e8da6;
  justify-content: space-evenly;
  width:55%;
  height: 3rem;
  margin-vertical: 1rem;
  background-color: ${colors.PRIMARY_COLOR};
`;

export {TabItem, TabTitle, TabContainer};
