export function calculateRatingDistribution(reviews) {
  let ratingCounts = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 }
  let totalReviews = reviews.length

  for (const review of reviews) {
    ratingCounts[review.rating]++
  }

  let ratingPercentages = {}
  for (const rating in ratingCounts) {
    const percentage = (ratingCounts[rating] / totalReviews) * 100
    ratingPercentages[rating] = Math.round(percentage) + "%"
  }

  return ratingPercentages
}
