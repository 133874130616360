import React from "react"

const LikeIcon = ({ isHovered }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.33317 17.5003H4.1665V6.66699H3.33317C2.89114 6.66699 2.46722 6.84259 2.15466 7.15515C1.8421 7.46771 1.6665 7.89163 1.6665 8.33366V15.8337C1.6665 16.2757 1.8421 16.6996 2.15466 17.0122C2.46722 17.3247 2.89114 17.5003 3.33317 17.5003ZM16.6665 6.66699H10.8332L11.7682 3.86033C11.8516 3.60984 11.8743 3.34311 11.8345 3.08212C11.7946 2.82113 11.6934 2.57334 11.539 2.35915C11.3846 2.14497 11.1816 1.97053 10.9466 1.85019C10.7116 1.72985 10.4514 1.66706 10.1873 1.66699H9.99984L5.83317 6.19866V17.5003H14.9998L18.2598 10.337L18.3332 10.0003V8.33366C18.3332 7.89163 18.1576 7.46771 17.845 7.15515C17.5325 6.84259 17.1085 6.66699 16.6665 6.66699Z"
        fill={isHovered ? "url(#likeGradient)" : "#C0BACB"}
      />
      {isHovered && (
        <defs>
          <linearGradient
            id="likeGradient"
            x1="9.02761"
            y1="1.66699"
            x2="20.6724"
            y2="16.9891"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF55C6" />
            <stop offset="0.96875" stopColor="#7E01E1" />
          </linearGradient>
        </defs>
      )}
    </svg>
  )
}

export default LikeIcon
