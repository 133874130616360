


import React from 'react';

import ProfileBio from '../../../../items/ProfileBio';
import ProfileAchievementIcon from '../../../../items/profileAchievementIcon/ProfileAchievementIcon';
import Avatar from '../../../../../../../components/reusable/assets/avatar/Avatar';
import RankRowV2 from '../../../../../Hub/Items/rank/items/RankRow/RankRowV2';

const ProfileInfoMobileV3 = 
({showAchievement = false}) => {
  return (
    <div className="profile-top__mobile-wrap">
      <Avatar mode="border" />
      <RankRowV2 onlyImageWithProg />
      <div className="profile-top__mobile-right">
        <ProfileBio />
        {showAchievement && <ProfileAchievementIcon />}
      </div>
    </div>
  );
};

export default ProfileInfoMobileV3;
