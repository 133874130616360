import React, { useEffect, useState } from 'react';
import MainLayoutV2 from '../../../../components/layouts/mainLayout/MainLayoutV2';
import MainLayoutContentV2 from '../../../../components/layouts/mainLayout/mainLayoutContentV2/MainLayoutContentV2';
import ResizeComponent from '../../../../components/reusable/assets/resizeComponent/ResizeComponent';
import useWindowSize from '../../../../hooks/useWindowSize';
import BookmarkUserProfile from './BookmarkUserProfile.jsx'

const BookmarkViewContentList = props => {
  const { width } = useWindowSize();


  return (
    <>
      <MainLayoutV2 centerSpace={true}>
        <MainLayoutContentV2 iscenter={true} rightColumnContent={<div />}>
          <ResizeComponent
            desktopComponent={<BookmarkUserProfile isFromMobile={false} />}
            mobileComponent={<BookmarkUserProfile isFromMobile={true} />}
          />
        </MainLayoutContentV2>
      </MainLayoutV2>

    </>
  );
};

export default BookmarkViewContentList;
