import React from 'react';
// import {Outlet} from 'react-router-dom';
import HeaderLogo from '../../reusable/assets/logo/headerLogo/HeaderLogo.jsx';
import BackBtn from '../../reusable/btns/backBtn/BackBtn.jsx';
import BackBtnMobile from '../../reusable/btns/backBtn/BackBtnMobile.jsx';
import SupportBtn from '../../reusable/btns/supportBtn/SupportBtn.jsx';
import RequireAuth from '../RequireAuth.jsx';
import {ArrowRightOnRectangleIcon} from '@heroicons/react/24/outline';

const BaseLayout = ({bigDesk, children, noBack = false, showLogin = false}) => {
  return (
    <div className="auth-layout">
      {!noBack && (
        <>
          <div className="auth-layout__header_m">
            <BackBtnMobile />
            {/* */}
            {showLogin ? (
              <ArrowRightOnRectangleIcon
                onClick={() => {
                  window.location.reload(false);
                }}
                className="h-6 w-8"
                aria-hidden="true"
              />
            ) : (
              <SupportBtn />
            )}
          </div>

          <div className="auth-layout__header">
            <div className="auth-layout__header-container">
              <div></div>
              <div className="auth-layout__logo h4">
                Welcome to <HeaderLogo />
              </div>
              <div>
                <SupportBtn isOppositeColor />
              </div>
            </div>
          </div>
        </>
      )}
      <div
        className={`auth-layout__container ${
          bigDesk ? 'auth-layout__container_b' : ''
        }`}>
        {/*<Outlet />*/}
        {children}
        {!noBack && <BackBtn />}
      </div>
    </div>
  );
};

export default BaseLayout;
