import {useMutation} from 'react-query';
import {patchEditQuickAccess} from '../../../network/api/app';
import {useBuzzmiAuth} from '../useBuzzmiAuth/index.web';
import {ShowError, ShowSuccess} from '../../../container/HOC/MessageWeb';
import {useToast} from 'react-native-toast-notifications';
export const useEditQuickAccess = () => {
  const {userData, updateUserData} = useBuzzmiAuth();
  const toast = useToast();
  const {
    mutate: mutateEditQuickAccess,
    isLoading: isEditQuickAccessLoading,
    isError: isEditQuickAccessError,
    data: EditQuickAccessResponse,
    error: EditQuickAccessErrorResponse,
  } = useMutation({
    mutationFn: param => {
      console.log('param?.body', param);

      return patchEditQuickAccess({
        params: {isAuth: true, token: userData?.token},
        body: {...param?.body},
      });
    },
    onSuccess: data => {
      console.log('edited quick access ==> ', data);
      ShowSuccess('Quick access updated successfully', toast);
    },
    onError: data => {
      console.log('edited quick error ==> ', data);
      ShowError('Quick access update failed', toast);
    },
  });
  const patchBaseEditQuickAccess = async body => mutateEditQuickAccess({body});

  return {
    isEditQuickAccessLoading,
    isEditQuickAccessError,
    EditQuickAccessResponse,
    EditQuickAccessErrorResponse,
    patchBaseEditQuickAccess,
  };
};
