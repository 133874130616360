import React, {useState} from 'react';
import {colors} from '../../../../../../../res/colors';

function HubTask({
  id,
  name,
  completed,
  deleteTask,
  editTask,
  toggleTask,
  viewOnly = false,
}) {
  const [isEditing, setIsEditing] = useState(false);

  const [newName, setNewName] = useState(name);

  const handleChange = event => {
    setNewName(event.target.value);
  };

  const handleEdit = () => {
    editTask(id, newName);
    setIsEditing(false);
  };

  return (
    <div className="checklist__task-row">
      <div className="checklist__task">
        <input
          type="checkbox"
          checked={completed}
          onChange={() => toggleTask(id)}
          className="checklist__checkbox"
          id={`task-${id}`}
          style={{
            position: 'absolute',
            width: '24px',
            height: '24px',
            borderRadius: '5px',
            padding: '0',
            overflow: 'hidden',
            color: colors.PRIMARY_COLOR,
            border: '0',
            left: '3px',
          }}
        />
        <label htmlFor={`task-${id}`} className="checklist__label" />
        {isEditing ? (
          <input
            type="text"
            value={newName}
            onChange={handleChange}
            onBlur={handleEdit}
            autoFocus
            className="text-r text-dark-secondary "
          />
        ) : (
          <span
            onDoubleClick={() => setIsEditing(true)}
            // className={completed ? 'task__completed' : ''}
            className="text-r text-dark-secondary ">
            {name}
          </span>
        )}
      </div>

      {!viewOnly && (
        <button
          className="text-alt-primary checklist__delete"
          onClick={() => deleteTask(id)}>
          <img
            src="/images/ic_delete_checklist.png"
            class="w-[35px] m-auto mb-2.5"></img>
        </button>
      )}
    </div>
  );
}

export default HubTask;
