export const friendsData = [
  {
    id: "1",
    nickname: "jarvis",
    name: "Jarvis Anderson",
    position: "Elite creator",
    isVerified: true,
    avatar: {
      src: "/images/post/mock/jarvis.jpg",
    },
    socialLink: {
      name: "BuzzMi",
      src: "/images/post/buzzmi-label.webp",
    },
  },
  {
    id: "2",
    nickname: "matilda",
    name: "Matilda Johnson",
    position: "Elite creator",
    isVerified: true,
    avatar: {
      src: "/images/post/mock/matilda.jpg",
    },
    socialLink: {
      name: "WhatsApp",
      src: "/images/post/whatsapp-label.webp",
    },
  },
  {
    id: "3",
    nickname: "clara",
    name: "Clara Galle",
    position: "",
    isVerified: true,
    avatar: {
      src: "/images/post/mock/clara.jpg",
    },
    socialLink: {
      name: "Facebook",
      src: "/images/post/facebook-label.webp",
    },
  },
  {
    id: "4",
    nickname: "jarvis",
    name: "Jarvis Anderson",
    position: "Elite creator",
    isVerified: true,
    avatar: {
      src: "/images/post/mock/jarvis.jpg",
    },
    socialLink: {
      name: "Instagram",
      src: "/images/post/instagram-label.webp",
    },
  },
  {
    id: "5",
    nickname: "matilda",
    name: "Matilda Johnson",
    position: "Elite creator",
    isVerified: true,
    avatar: {
      src: "/images/post/mock/matilda.jpg",
    },
    socialLink: {
      name: "Gmail",
      src: "/images/post/gmail-label.webp",
    },
  },
  {
    id: "6",
    nickname: "clara",
    name: "Clara Galle",
    position: "",
    isVerified: true,
    avatar: {
      src: "/images/post/mock/clara.jpg",
    },
    socialLink: {
      name: "Telegram",
      src: "/images/post/telegram-label.webp",
    },
  },
]
