// noinspection ES6CheckImport

import React, {useState, useRef, useContext, useEffect, useMemo} from 'react';
import {View, Alert, Dimensions} from 'react-native';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import {
  BText,
  Button,
  TitleText,
  LabelText,
  LabelIcon,
  VerifyIcon,
  TitleContainer,
  MainTitleContainer,
} from './style';
import {
  sendOtp,
  sendVerifyEmail,
  CONST_FLOW_TYPES,
} from '../../../Auth/VerifyOTPScreen';
import {GET_QR_CODE, PATCH_UPDATE_PROFILE} from '../../../../api_helper/Api';
import {PATCH, GET_DATA} from '../../../../api_helper/ApiServices';
import {getLanguageValueFromKey} from '../../../../commonAction';
import {BackHeader} from '../../../../components/BackHeader';
import Loading from '../../../../components/Loading.tsx';
import {GlobalFlex} from '../../../../res/globalStyles';
import IconAssets from '../../../../assets/index.ts';
import {Input} from '../../../../components/Input';
import DeviceInfo from 'react-native-device-info';
import {Spacer} from '../../../../res/spacer';
import {colors} from '../../../../res/colors';
import {getData} from '../../../../res/asyncStorageHelper.web';
import {useBuzzmiAuth} from '../../../../appState/hooks';
import {useAtom} from 'jotai';
import {_userDetailsAtom} from '../../../../appState/userState';

const underDevelopment = (message?: string) =>
  alert(message || 'Under Development!');

export const StatusComponent = ({isVerified}) => {
  const iconStyle = useMemo(() => ({width: hp(4), height: hp(4)}), []);

  if (isVerified === null) {
    return null;
  } else if (isVerified) {
    return (
      <VerifyIcon style={iconStyle} source={IconAssets.ic_verified_green} />
    );
  } else {
    return (
      <VerifyIcon
        style={iconStyle}
        source={IconAssets.ic_not_verified_yellow}
      />
    );
  }
};

export const TitleComponent = props => {
  const {title, icon, isVerified, onPress, is2FA, isFrom2FA} = props;

  return (
    <MainTitleContainer
      style={[{width: hp(70), alignSelf: 'center', paddingHorizontal: hp(1)}]}>
      <TitleContainer>
        <LabelIcon
          style={{
            height: hp(4),
            width: hp(4),
          }}
          resizeMode={'contain'}
          source={icon}
        />
        <TitleText style={{fontSize: hp(3)}}>{title}</TitleText>
        {!isFrom2FA && <StatusComponent isVerified={isVerified} />}
      </TitleContainer>
      <Button onPress={() => onPress(!props?.isEditable)}>
        {isFrom2FA ? (
          <BText style={[{fontSize: hp(2)}]}>
            {' '}
            {is2FA == true ? 'Disable Now' : 'Enable Now'}
          </BText>
        ) : (
          <BText style={[{fontSize: hp(2)}]}>
            {props?.isEditable === null
              ? 'Verify'
              : isVerified === null
              ? 'Enable Now'
              : props?.isEditable
              ? 'Save'
              : isVerified
              ? 'Change'
              : 'Verify'}
          </BText>
        )}
      </Button>
    </MainTitleContainer>
  );
};

const phoneValidator = require('phone').phone;
const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

const AccountVerification = props => {
  const {userData} = useBuzzmiAuth();
  const refPhoneField = useRef(null);
  const refEmailField = useRef(null);
  const [isPhoneEditable, setPhoneEditable] = useState(false);
  const [isEmailEditable, setEmailEditable] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [phone, setPhone] = useState(userData?.mobile);
  const [email, setEmail] = useState(userData?.email);
  // const [userDatas, setUserData] = useState(null);
  const [userDatas, setUserData] = useAtom(_userDetailsAtom);

  // useEffect(() => {
  //   getData('userDetails', userEData => {
  //     setUserData(userEData);
  //   });
  // }, []);

  React.useEffect(() => {
    refPhoneField?.current?.setFocus(false);
    setEmailEditable(() => false);
    refEmailField?.current?.setFocus(false);
    setPhoneEditable(() => false);
  }, [userData]);

  const onSubmitCode = async () => {
    const uniqueID = await DeviceInfo.getUniqueId();
    GET_DATA(
      GET_QR_CODE(userData?.userId),
      true,
      userData?.token,
      uniqueID,
      data => {
        props.navigation.navigate(
          'FAVerification',
          Object.assign(data, {
            twoFactorAuthEnabled: userDatas.twoFactorAuthEnabled,
          }),
        );
      },
    );
  };

  const updateUserProfile = async (updatePhone, callback) => {
    const url = PATCH_UPDATE_PROFILE(userData?.userId);

    let params;

    if (updatePhone) {
      if (phone.trim() === userData?.mobile) {
        callback();
        setPhone(() => userData?.mobile);
        return;
      } else if (phone === '') {
        Alert.alert('Please enter mobile number');
        setPhone(() => userData?.mobile);
        return;
      } else if (!phone?.includes('+')) {
        Alert.alert(getLanguageValueFromKey('please_enter_valid_phone'));
        setPhone(() => userData?.mobile);
        return;
      } else if (!phoneValidator(phone).isValid) {
        Alert.alert(getLanguageValueFromKey('please_enter_valid_phone'));
        setPhone(() => userData?.mobile);
        return;
      }
      params = {
        mobile: phone,
      };
    } else {
      if (email.trim() === userData?.email) {
        callback();
        setEmail(() => userData?.email);
        return;
      } else if (email === '') {
        Alert.alert(getLanguageValueFromKey('please_enter_email'));
        setEmail(() => userData?.email);
        return;
      } else if (!emailReg.test(email)) {
        Alert.alert(getLanguageValueFromKey('please_enter_valid_email'));
        setEmail(() => userData?.email);
        return;
      }
      params = {
        email: email,
      };
    }
    console.log('params==>>>', params, url, userData?.token);
    setLoading(() => true);
    PATCH(url, userData?.token, '', params, (response, e) => {
      setLoading(() => false);
      if (!e) {
        // const _user = {
        //   ...userData,
        //   email: email,
        //   mobile: phone,
        // };
        //
        // updateUserData(_user);
        callback(response);
        console.log('response (update phone/email) ===> ', response);
      } else {
        const errorMessage = response.includes(':')
          ? response.split(':')[1].trim()
          : response;
        underDevelopment(errorMessage);
        // setPhone(() => userData?.mobile);
        setEmail(() => userData?.email);
        console.log('PATCH_UPDATE_PROFILE (error) => ', response);
      }
    });
  };

  const onDisableSubmitCode = async () => {
    const uniqueID = await DeviceInfo.getUniqueId();
    GET_DATA(
      GET_QR_CODE(userData.userId),
      true,
      userData.token,
      uniqueID,
      data => {
        props.navigation.navigate('Disable2FA');
      },
    );
  };

  return (
    <GlobalFlex>
      <BackHeader
        is_center_text
        title={'Verify Account'}
        onBackPress={() => props.navigation.goBack()}
      />
      <Spacer space={hp(1)} />
      <View style={{height: 2, backgroundColor: '#d9dbdf', width: wp(100)}} />
      <Spacer space={hp(1)} />
      <TitleComponent
        title={'Mobile Number'}
        onPress={flag => {
          if (!flag || userData?.isMobileVerified) {
            console.log('Save phone flow...');
            updateUserProfile(true, response => {
              if (userData?.mobile !== phone) {
                refPhoneField?.current?.setFocus(false);
                props.navigation.navigate('VerifyOTPScreen', {
                  type: CONST_FLOW_TYPES.VERIFY_PHONE,
                  uniqueUUID: response.uniqueUUID,
                  flowId: '', // Not required for this flow...
                  expireTime: 10,
                  email: phone,
                  onVerified: () => {
                    if (userData?.isMobileVerified) {
                      refPhoneField?.current?.setFocus(true);
                      setEmailEditable(() => false);
                      setPhoneEditable(() => flag);
                    }
                  },
                });
                return;
              }
              setLoading(() => true);
              sendOtp().then(({success, response}) => {
                setLoading(() => false);
                if (success) {
                  console.log('response ===> ', response);
                  refPhoneField?.current?.setFocus(false);
                  props.navigation.navigate('VerifyOTPScreen', {
                    type: CONST_FLOW_TYPES.VERIFY_PHONE,
                    uniqueUUID: response.uniqueUUID,
                    flowId: '', // Not required for this flow...
                    expireTime: 10,
                    email: phone,
                    onVerified: () => {
                      if (userData?.isMobileVerified) {
                        refPhoneField?.current?.setFocus(true);
                        setEmailEditable(() => false);
                        setPhoneEditable(() => flag);
                      }
                    },
                  });
                }
              });
            }).then();
            return;
          }
          if (flag) {
            refPhoneField?.current?.setFocus(true);
          }
          setEmailEditable(() => false);
          setPhoneEditable(() => flag);
        }}
        isEditable={isPhoneEditable}
        icon={IconAssets.ic_call_outline}
        isVerified={userData?.isMobileVerified}
      />
      <View style={{width: hp(70), alignSelf: 'center'}}>
        {/* <LabelText style={[{ fontSize: hp(2.5), marginStart: 10 }]}>Mobile Number</LabelText> */}
        <Input
          value={phone}
          onChange={setPhone}
          ref={refPhoneField}
          returnKeyType={'done'}
          keyboardType={'phone-pad'}
          editable={isPhoneEditable}
          placeholder={'Mobile Number'}
          placeholderTextColor={colors.TRIPLET_PLACEHOLDER}
          onFocus={() => refPhoneField?.current?.setFocus(true)}
          onBlur={() => refPhoneField?.current?.setFocus(false)}
        />
      </View>
      <Spacer space={hp(1)} />
      <TitleComponent
        icon={IconAssets.ic_email_new}
        isVerified={userData?.isEmailVerified}
        isEditable={isEmailEditable}
        title={'Email'}
        onPress={flag => {
          if (!flag || userData?.isEmailVerified) {
            console.log('Save email flow...');
            updateUserProfile(false, response => {
              if (userData?.email !== email) {
                refEmailField?.current?.setFocus(false);
                props.navigation.navigate('VerifyOTPScreen', {
                  type: CONST_FLOW_TYPES.VERIFY_EMAIL,
                  flowId: '', // Not required for this flow...
                  expireTime: 10,
                  uniqueUUID: '',
                  email: email,
                  onVerified: () => {
                    if (userData?.isEmailVerified) {
                      refEmailField?.current?.setFocus(true);
                      setPhoneEditable(() => false);
                      setEmailEditable(() => flag);
                    }
                  },
                });
                return;
              }
              setLoading(() => true);
              sendVerifyEmail().then(({success, response}) => {
                setLoading(() => false);
                if (success) {
                  refEmailField?.current?.setFocus(false);
                  props.navigation.navigate('VerifyOTPScreen', {
                    type: CONST_FLOW_TYPES.VERIFY_EMAIL,
                    flowId: '', // Not required for this flow...
                    expireTime: 10,
                    uniqueUUID: '',
                    email: email,
                    onVerified: () => {
                      if (userData?.isEmailVerified) {
                        refEmailField?.current?.setFocus(true);
                        setPhoneEditable(() => false);
                        setEmailEditable(() => flag);
                      }
                    },
                  });
                }
              });
            }).then();
            return;
          }

          if (flag) {
            refEmailField?.current?.setFocus(true);
          }
          setPhoneEditable(() => false);
          setEmailEditable(() => flag);
        }}
      />
      <View style={{width: hp(70), alignSelf: 'center'}}>
        {/* <LabelText style={[{ fontSize: hp(2.5) }]}>Email</LabelText> */}
        <Input
          value={email}
          ref={refEmailField}
          onChange={setEmail}
          placeholder={'Email'}
          editable={isEmailEditable}
          keyboardType={'email-address'}
          style={{paddingBottom: hp(2)}}
          placeholderTextColor={colors.TRIPLET_PLACEHOLDER}
          onFocus={() => refEmailField?.current?.setFocus(true)}
          onBlur={() => refEmailField?.current?.setFocus(false)}
        />

        <Spacer space={hp(1)} />
        <TitleComponent
          icon={IconAssets.ic_two_factor}
          is2FA={userDatas?.twoFactorAuthEnabled}
          title={'Two-factor \nauthentication'}
          onPress={() =>
            userDatas?.twoFactorAuthEnabled
              ? onDisableSubmitCode()
              : onSubmitCode()
          }
          isFrom2FA={true}
        />
        {/* props.navigation.navigate("FAVerification") */}
        <LabelText style={{marginStart: hp(10), fontSize: hp(2.5)}}>
          Add an extra layer of security to your{'\n'}account by enabling
          two-factor{'\n'}authentication.
        </LabelText>
        {/*<Loading visible={isLoading} />*/}
        <Loading visible={isLoading} />
      </View>
    </GlobalFlex>
  );
};
export default AccountVerification;
