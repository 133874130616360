import React from "react"
import VerifyPhoneAlertMobile from "./VerifyPhoneAlertMobile"
import VerifyPhoneAlertDesktop from "./VerifyPhoneAlertDesktop"

const VerifyPhoneAlert = ({ phone }) => {
  return (
    <>
      <VerifyPhoneAlertDesktop phone={phone} />
      <VerifyPhoneAlertMobile phone={phone} />
    </>
  )
}

export default VerifyPhoneAlert
