/* eslint-disable react-hooks/exhaustive-deps */

import React, {useEffect, useState} from 'react';
import {
  ActivityIndicator,
  Pressable,
  View,
  ImageBackground,
} from 'react-native';
import deviceInfoModule from 'react-native-device-info';
import {GET_WALLETS} from '../../../../../api_helper/Api';
import {GET_DATA} from '../../../../../api_helper/ApiServices';
import {colors} from '../../../../../res/colors';
import styles, {
  // Item,
  // SubItem,
  // Divider,
  // Container,
  // LabelText,
  DetailText,
  // DiamondIcon,
  // ClickableText,
  // ClickableTextContainer,
  MembershipContainer,
  MembershipIcon,
  MembershipTitle,
  MembershipButton,
  MembershipButtonLabel,
} from './styles';
import {useIsFocused} from '@react-navigation/core';
import {useBuzzmiAuth} from '../../../../../appState/hooks';
import style, {UsernameText} from '../../style';
// import GradientText from '../../../../../components/GradientText';
import {Line} from './styles';
import {heightPercentageToDP as hp} from 'react-native-responsive-screen';
import {Spacer} from '../../../../../res/spacer';
import {WalletItemsContainer} from './styles';
// import {WalletIcon} from './styles';
// import IconAssets from '../../../../../assets';
import {MenuButtonContainer} from '../../../../../components/MenuItem/styles';
import {RankHeader} from '../../../../../components/RanksView/styles';
import Strings from '../../../../../string_key/Strings.web';

const BasicInfoComponent = props => {
  const {userData, logout} = useBuzzmiAuth();
  const [balance, setBalance] = useState(0);
  const [data, setData] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const isFocused = useIsFocused();
  const colorArray = ['#f48dc3', '#b341a0', '#b341a0'];

  const getWalletList = async () => {
    setIsLoading(true);
    await GET_DATA(GET_WALLETS, true, userData?.token, '', data => {
      if (!Array.isArray(data)) {
        setBalance('Unavailable');
      } else {
        console.log('wallet data', data);
        setData(data);
        setBalance(
          data.map(item => item?.balance).reduce((prev, next) => prev + next),
        );
      }
      setIsLoading(false);
    });
  };
  useEffect(() => {
    if (userData?.token && isFocused) {
      getWalletList();
    }
  }, [userData, isFocused]);

  return (
    <>
      <MenuButtonContainer>
        <RankHeader style={{marginBottom: hp(1)}}>
          <UsernameText>{Strings.membership}</UsernameText>
          {/* <GradientText style={styles.ViewRankText} colors={colorArray}>
            See my benefits
          </GradientText> */}
        </RankHeader>
        <Line />
        {userData?.membership?.title.length > 0 && (
          <MembershipContainer>
            <View style={styles.membershipStatusBox}>
              {/* <MembershipIcon source={IconAssets.diamond} /> */}
              <MembershipTitle>
                {userData?.membership?.title || Strings.free_user}
              </MembershipTitle>
            </View>
            <MembershipButton selected={isSelected}>
              <MembershipButtonLabel selected={isSelected}>
                {isSelected
                  ? Strings.your_current_level.toUpperCase()
                  : Strings.upgrade_now.toUpperCase()}
              </MembershipButtonLabel>
            </MembershipButton>
          </MembershipContainer>
        )}
      </MenuButtonContainer>
      <Spacer space={hp(0.8)} />
      <MenuButtonContainer>
        <RankHeader style={{marginBottom: hp(1)}}>
          <UsernameText>{Strings.wallet}</UsernameText>
          {/* <GradientText style={styles.ViewRankText} colors={colorArray}>
            See my earnings
          </GradientText> */}
        </RankHeader>
        <Line />
        <WalletItemsContainer>
          {data.map(item => (
            <>
              <DetailText>{item?.total}</DetailText>
              {/* <WalletIcon source={IconAssets.diamond} /> */}
            </>
          ))}
        </WalletItemsContainer>
      </MenuButtonContainer>
      {/* <Spacer space={hp(0.8)} /> */}
      {/* <Container style={styles.shadowStyle}>
        <Item>
          {!props?.onWalletPress && <LabelText>Wallet</LabelText>}
          <SubItem>
            <Pressable
              onPress={() => {
                if (props?.onWalletPress) {
                  props?.onWalletPress();
                }
              }}>
              <DetailText>{!props?.onWalletPress ? '0' : 'MY WALLET'}</DetailText>

              {isloading ? (
                <ActivityIndicator size={20} color={colors.PRIMARY_COLOR} />
              ) : (
                <DetailText>{balance}</DetailText>
              )}
            </Pressable>
          </SubItem>
        </Item>
         <Divider />
        <Item>
          <LabelText>Membership Level</LabelText>
          <SubItem>
            <DetailText>
              {userData?.membership?.title || 'Free User'}
            </DetailText>
          </SubItem>
          <ClickableTextContainer
            onPress={() => {
              if (!props?.onWalletPress) {
                if (props?.onManagePress) {
                  props?.onManagePress();
                }
              } else {
                if (props?.onBenefitsPress) {
                  props?.onBenefitsPress();
                }
              }
            }}>
            <ClickableText>
              {props?.onWalletPress
                ? 'SEE YOUR BENEFITS'
                : userData?.membership !== null
                ? 'Update'
                : 'Manage'}
            </ClickableText>
          </ClickableTextContainer>
        </Item>
      </Container> */}
    </>
  );
};

export default BasicInfoComponent;
