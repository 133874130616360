import React, {useState} from 'react';
import MainLayoutContent from '../../../../components/layouts/mainLayout/mainLayoutContent/MainLayoutContent';
import BackBtnWithTitlePage from '../../../../components/reusable/btns/backBtn/BackBtnWithTitlePage';
import VideoPlayerOld from '../../../../components/reusable/videoPlayer/VideoPlayer.old';
import ProfileSessionItem from '../items/profileSessionItem/ProfileSessionItem';
import MainLayout from '../../../../components/layouts/mainLayout/MainLayout';

const videos = [
  {
    id: 1,
    title: "Beginner's Strength",
    info: '25 Kcal / 10 min/ L1 Newbie',
    image: {
      src: '/images/pages/profile/moc/content/2.jpg',
      alt: 'Session 1',
    },
    isLikes: false,
    video: {
      id: 1,
      title: "Beginner's Strength",
      subtitle: '1 session',
      tracks: [
        {
          kind: 'descriptions',
          src: '/video/descriptions.en.vtt',
          srcLang: 'en',
          label: 'English',
        },
        {
          kind: 'descriptions',
          src: '/video/descriptions.en.vtt',
          srcLang: 'en',
          label: 'English2',
        },
      ],
      sources: [
        {
          src: '/video/1.mp4',
          quality: '720',
        },
        {
          src: '/video/2.mp4',
          quality: '480',
        },
        {
          src: '/video/3.mp4',
          quality: '360',
        },
        {
          src: '/video/4.mp4',
          quality: '240',
        },
      ],
    },
  },
  {
    id: 2,
    title: "Beginner's Strength",
    info: '25 Kcal / 10 min/ L1 Newbie',
    image: {
      src: '/images/pages/profile/moc/content/2.jpg',
      alt: 'Session 1',
    },
    isLikes: false,
    video: {
      id: 2,
      title: "Beginner's Strength",
      subtitle: '2 session',
      tracks: [
        {
          kind: 'descriptions',
          src: '/video/descriptions.en.vtt',
          srcLang: 'en',
          label: 'English',
        },
        {
          kind: 'descriptions',
          src: '/video/descriptions.en.vtt',
          srcLang: 'en',
          label: 'English2',
        },
      ],
      sources: [
        {
          src: '/video/4.mp4',
          quality: '720',
        },
        {
          src: '/video/3.mp4',
          quality: '480',
        },
        {
          src: '/video/2.mp4',
          quality: '360',
        },
        {
          src: '/video/1.mp4',
          quality: '240',
        },
      ],
    },
  },
  {
    id: 3,
    title: "Beginner's Strength",
    info: '25 Kcal / 10 min/ L1 Newbie',
    image: {
      src: '/images/pages/profile/moc/content/2.jpg',
      alt: 'Session 1',
    },
    isLikes: false,
    video: {
      id: 3,
      title: "Beginner's Strength",
      subtitle: '3 session',
      tracks: [
        {
          kind: 'descriptions',
          src: '/video/descriptions.en.vtt',
          srcLang: 'en',
          label: 'English',
        },
        {
          kind: 'descriptions',
          src: '/video/descriptions.en.vtt',
          srcLang: 'en',
          label: 'English2',
        },
      ],
      sources: [
        {
          src: '/video/3.mp4',
          quality: '720',
        },
        {
          src: '/video/2.mp4',
          quality: '480',
        },
        {
          src: '/video/4.mp4',
          quality: '360',
        },
        {
          src: '/video/1.mp4',
          quality: '240',
        },
      ],
    },
  },
  {
    id: 4,
    title: "Beginner's Strength",
    info: '25 Kcal / 10 min/ L1 Newbie',
    image: {
      src: '/images/pages/profile/moc/content/2.jpg',
      alt: 'Session 1',
    },
    isLikes: false,
    video: {
      id: 4,
      title: "Beginner's Strength",
      subtitle: '4 session',
      tracks: [
        {
          kind: 'descriptions',
          src: '/video/descriptions.en.vtt',
          srcLang: 'en',
          label: 'English',
        },
        {
          kind: 'descriptions',
          src: '/video/descriptions.en.vtt',
          srcLang: 'en',
          label: 'English2',
        },
      ],
      sources: [
        {
          src: '/video/1.mp4',
          quality: '720',
        },
        {
          src: '/video/2.mp4',
          quality: '480',
        },
        {
          src: '/video/3.mp4',
          quality: '360',
        },
        {
          src: '/video/4.mp4',
          quality: '240',
        },
      ],
    },
  },
  {
    id: 5,
    title: "Beginner's Strength",
    info: '25 Kcal / 10 min/ L1 Newbie',
    image: {
      src: '/images/pages/profile/moc/content/2.jpg',
      alt: 'Session 1',
    },
    isLikes: false,
    video: {
      id: 5,
      title: "Beginner's Strength",
      subtitle: '5 session',
      tracks: [
        {
          kind: 'descriptions',
          src: '/video/descriptions.en.vtt',
          srcLang: 'en',
          label: 'English',
        },
        {
          kind: 'descriptions',
          src: '/video/descriptions.en.vtt',
          srcLang: 'en',
          label: 'English2',
        },
      ],
      sources: [
        {
          src: '/video/1.mp4',
          quality: '720',
        },
        {
          src: '/video/2.mp4',
          quality: '480',
        },
        {
          src: '/video/3.mp4',
          quality: '360',
        },
        {
          src: '/video/4.mp4',
          quality: '240',
        },
      ],
    },
  },
];

const VideoSessions = () => {
  const [currentVideo, setCurrentVideo] = useState(videos[0].video);

  return (
    <MainLayout>
      <MainLayoutContent>
        <BackBtnWithTitlePage
          className="mt-0"
          btnText="Back to post"
          title="Video sessions"
        />
        <div className="profile-session__video-container">
          <VideoPlayerOld data={currentVideo} />
        </div>
        <div className="profile-session__list-wrapper">
          <div className="profile-session__play-next">Play next</div>
          <ul className="profile-session__list">
            {videos.map((item, index) => (
              <li key={item.id}>
                <ProfileSessionItem
                  id={item.id}
                  session={index + 1}
                  title={item.title}
                  info={item.info}
                  image={item.image}
                  isLikes={item.isLikes}
                  callback={() => setCurrentVideo(item.video)}
                />
              </li>
            ))}
          </ul>
        </div>
      </MainLayoutContent>
    </MainLayout>
  );
};

export default VideoSessions;
