import React, {useEffect, useState} from 'react';
import {useBuzzmiAuth} from '../../../../appState/hooks/useBuzzmiAuth/index.web';
import {useGetWalletHistory} from '../../../../appState/hooks/useGetWalletHistory';
import {useHubApp} from '../../../../appState/hooks/useHubApp/index.web';
import moment from 'moment';
import Loading from 'react-fullscreen-loading';
import {colors} from '../../../../res/colors';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import BackBtnWithTitlePage from '../../../../components/reusable/btns/backBtn/BackBtnWithTitlePage';
import {useEventEmitter} from '../../../../hooks/useEventEmitter';

const WalletHistory = props => {
  const {userData} = useBuzzmiAuth();
  const {walletInfo} = useHubApp();
  const [pageLimit, setPageLimit] = useState(10);
  const [loader, setLoader] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const {getrWalletHistory, walletHistory, clearWalletHistory} =
    useGetWalletHistory();

  const handleTabSelect = index => {
    setActiveTabIndex(index);
  };

  useEventEmitter('wallet-history-update', data => {
    getrWalletHistory(
      userData?.token,
      data => {
        setLoader(data);
      },
      {
        page: 1,
        limit: pageLimit,
        walletType: 'Gems',
      },
    );
  });
  useEffect(() => {
    getrWalletHistory(
      userData?.token,
      data => {
        setLoader(data);
      },
      {
        page: 1,
        limit: pageLimit,
        walletType: 'Gems',
      },
    );
    return () => {
      clearWalletHistory();
    };
  }, [pageLimit]);

  const getDisplayDate = (year, month, day) => {
    let today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);
    let compDate = new Date(year, month, day); // month - 1 because January == 0
    let diff = today.getTime() - compDate.getTime(); // get the difference between today(at 00:00:00) and the date
    if (compDate.getTime() == today.getTime()) {
      return 'Today';
    } else if (diff <= 24 * 60 * 60 * 1000) {
      return 'Yesterday';
    } else {
      //return compDate.toDateString(); // or format it what ever way you want
      let year = compDate.getFullYear();
      let month = compDate.getMonth();
      let months = new Array(
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      );
      let day = compDate.getDate();
      let d = compDate.getDay();
      let days = new Array('Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat');

      var formattedDate = days[d] + ' ' + day;
      return formattedDate;
    }
  };
  return (
    <div className={'flex-1 bg-[#FBF8FF]'}>
      <div className={'mt-4 ml-2'}>
        <BackBtnWithTitlePage
          hideBottomBorder={true}
          title={'Transaction History'}
          btnText={''}
        />
      </div>
      <Loading
        loading={loader}
        background="transparent"
        loaderColor={colors.PRIMARY_COLOR}
      />
      <div className={'flex-1 p-3'}>
        <Tabs
          className="profile-section_v2"
          selectedIndex={activeTabIndex}
          onSelect={handleTabSelect}>
          <TabList>
            <Tab style={{color: '#000', fontWeight: 700, fontSize: 14}}>
              All
            </Tab>
            <Tab style={{color: '#000', fontWeight: 700, fontSize: 14}}>
              Received
            </Tab>
            <Tab style={{color: '#000', fontWeight: 700, fontSize: 14}}>
              Sent
            </Tab>
          </TabList>

          <TabPanel key="all">
            <div>
              {walletHistory?.length > 0 &&
                walletHistory.map((item, index) => (
                  <>
                    <h5 className="text-sm px-3 font-bold mt-2 mb-2">
                      {getDisplayDate(
                        moment(item?.modifiedOn).year(),
                        moment(item?.modifiedOn).month(),
                        moment(item?.modifiedOn).date(),
                      )}
                    </h5>
                    <div className={'flex justify-between py-3 px-3 pt-0'}>
                      <div className={'flex'}>
                        <img
                          src={'/images/task_ic.png'}
                          className={'w-12 h-12'}
                        />
                        <div className={'self-center'}>
                          <p className={'ml-2 text-sm self-center font-bold'}>
                            {item?.behaviour}
                          </p>
                          <p className={'ml-2 text-gray-400 text-xs'}>
                            {moment(item?.modifiedOn).format('hh:mm a')}
                          </p>
                        </div>
                      </div>
                      <div className={'flex self-center'}>
                        <p className={'mr-2 text-sm self-center font-bold'}>
                          {item.trasactionType == 'Credited' ? '+ ' : '- '}
                          {item?.amount}
                        </p>
                        <img src={'/images/Star.png'} className={'w-6 h-6'} />
                      </div>
                    </div>
                  </>
                ))}
              {walletHistory?.length > 0 && (
                <div
                  className="flex justify-center items-center w-full text-alt-primary cursor-pointer mb-4"
                  onClick={() => {
                    setPageLimit(pageLimit + 20);
                  }}>
                  Load More
                </div>
              )}
              {walletHistory?.length == 0 && (
                <h5 className="mt-5 text-sm text-center mt-6 mb-2">
                  No Data Found
                </h5>
              )}
            </div>
          </TabPanel>
          <TabPanel key="received">
            <div>
              {walletHistory?.length > 0 &&
                walletHistory.map((item, index) => (
                  <>
                    <h5 className="text-sm px-3 font-bold mt-2 mb-2">
                      {getDisplayDate(
                        moment(item?.modifiedOn).year(),
                        moment(item?.modifiedOn).month(),
                        moment(item?.modifiedOn).date(),
                      )}
                    </h5>
                    <div className={'flex justify-between py-3 px-3 pt-0'}>
                      <div className={'flex'}>
                        <img
                          src={'/images/task_ic.png'}
                          className={'w-12 h-12'}
                        />
                        <div className={'self-center'}>
                          <p className={'ml-2 text-sm self-center font-bold'}>
                            {item?.behaviour}
                          </p>
                          <p className={'ml-2 text-gray-400 text-xs'}>
                            {moment(item?.modifiedOn).format('hh:mm a')}
                          </p>
                        </div>
                      </div>
                      <div className={'flex self-center'}>
                        <p className={'mr-2 text-sm self-center font-bold'}>
                          {item.trasactionType == 'Credited' ? '+ ' : '- '}
                          {item?.amount}
                        </p>
                        <img src={'/images/Star.png'} className={'w-6 h-6'} />
                      </div>
                    </div>
                  </>
                ))}
              {walletHistory?.length > 0 && (
                <div
                  className="flex justify-center items-center w-full text-alt-primary cursor-pointer mb-4"
                  onClick={() => {
                    setPageLimit(pageLimit + 20);
                  }}>
                  Load More
                </div>
              )}
              {walletHistory?.length == 0 && (
                <h5 className="mt-5 text-sm text-center mt-6 mb-2">
                  No Data Found
                </h5>
              )}
            </div>
          </TabPanel>
          <TabPanel key="sent">
            <div>
              {walletHistory?.length > 0 &&
                walletHistory.map((item, index) => (
                  <div key={index}>
                    <h5 className="text-sm px-3 font-bold mt-2 mb-2">
                      {getDisplayDate(
                        moment(item?.modifiedOn).year(),
                        moment(item?.modifiedOn).month(),
                        moment(item?.modifiedOn).date(),
                      )}
                    </h5>
                    <div className={'flex justify-between py-3 px-3 pt-0'}>
                      <div className={'flex'}>
                        <img
                          src={'/images/task_ic.png'}
                          className={'w-12 h-12'}
                        />
                        <div className={'self-center'}>
                          <p className={'ml-2 text-sm self-center font-bold'}>
                            {item?.behaviour}
                          </p>
                          <p className={'ml-2 text-gray-400 text-xs'}>
                            {moment(item?.modifiedOn).format('hh:mm a')}
                          </p>
                        </div>
                      </div>
                      <div className={'flex self-center'}>
                        <p className={'mr-2 text-sm self-center font-bold'}>
                          {item.trasactionType == 'Credited' ? '+ ' : '- '}
                          {item?.amount}
                        </p>
                        <img src={'/images/Star.png'} className={'w-6 h-6'} />
                      </div>
                    </div>
                  </div>
                ))}
              {walletHistory?.length > 0 && (
                <div
                  className="flex justify-center items-center w-full text-alt-primary cursor-pointer mb-4"
                  onClick={() => {
                    setPageLimit(pageLimit + 20);
                  }}>
                  Load More
                </div>
              )}
              {walletHistory?.length == 0 && (
                <h5 className="mt-5 text-sm text-center mt-6 mb-2">
                  No Data Found
                </h5>
              )}
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};
export default WalletHistory;
