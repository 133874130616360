import {useEffect, useRef, useState} from 'react';
import ReactPortal from './ReactPortal.jsx';
import {CSSTransition} from 'react-transition-group';
import {useDispatch, useSelector} from 'react-redux';
import renderContent from './renderContent.jsx';
import {closeModal} from '../../store/slices/modalSlice/modalSlice.js';
import cn from 'classnames';
import {useSwipeable} from 'react-swipeable';
//TODO ref modals
const Modal = () => {
  const {isOpen, modalName, modalProps, modalType, modalTitle} = useSelector(
    state => state.modal,
  );
  const dispatch = useDispatch();
  const nodeRef = useRef(null);
  const [modalClassName, setIsMounted] = useState('modal__wrapper');
  const [component, setComponent] = useState(null);
  const [type, setType] = useState(null);

  const handleClose = () => {
    modalProps?.propsAlert?.handleOK
      ? modalProps?.propsAlert?.handleOK()
      : dispatch(closeModal());
  };

  useEffect(() => {
    const closeOnEscapeKey = e => (e.key === 'Escape' ? handleClose() : null);
    document.body.addEventListener('keydown', closeOnEscapeKey);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey);
    };
  }, [handleClose]);

  useEffect(() => {
    if (isOpen) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
  }, [isOpen]);

  useEffect(() => {
    //TODO need refactoring this code
    if (modalClassName !== 'modal__wrapper' && !modalType) {
      setTimeout(() => {
        setIsMounted('modal__wrapper');
      }, 300);
    } else {
      switch (modalType) {
        case 'video':
          setIsMounted('modal__wrapper modal__wrapper_video');
          break;
        case 'big':
          setIsMounted('modal__wrapper modal__wrapper_big');
          break;
        case 'alert':
          setIsMounted('modal__wrapper modal__wrapper_alert');
          break;
        case 'swipe':
          setIsMounted(
            'modal__wrapper modal__wrapper_swipe modal__wrapper_big',
          );
          break;
        case 'swipe-pink':
          setIsMounted(
            'modal__wrapper modal__wrapper_swipe modal__wrapper_big modal__wrapper_pink',
          );
          break;
        case 'swipe-review':
          setIsMounted(
            'modal__wrapper modal__wrapper_swipe modal__wrapper_big modal__wrapper_review',
          );
          break;
        default:
          setIsMounted('modal__wrapper');
      }
    }
  }, [modalType]);

  useEffect(() => {
    if (!!modalName) {
      setType(modalType);
      const component = renderContent(modalName, modalProps);
      setComponent(component);
    }
    if (!modalName && !!component) {
      setTimeout(() => {
        setType(null);
        setComponent(null);
      }, 30000);
    }
  }, [modalName, modalProps]);

  const handlers = useSwipeable({
    onSwipedDown: () => handleClose(),
  });

  return (
    <ReactPortal wrapperId="react-portal-modal-container">
      <CSSTransition
        in={isOpen}
        timeout={{entry: 300, exit: 300}}
        unmountOnExit
        classNames="modal"
        nodeRef={nodeRef}>
        <div
          className={cn('modal', {
            modal_swiped: type === 'swipe' || 'swipe-pink' || 'swipe-review',
            modal_alert: type === 'alert',
          })}
          ref={nodeRef}>
          <div className={modalClassName}>
            <div {...handlers} className="modal__close">
              <div></div>
              <div className="h3">{modalTitle}</div>
              <button onClick={handleClose} className="modal__close-icon" />
            </div>

            <div className="modal__content">{component && component}</div>
          </div>
          <div onClick={handleClose} className="modal__overlay" />
        </div>
      </CSSTransition>
    </ReactPortal>
  );
};

export default Modal;
