import AsyncStorage from '@react-native-community/async-storage';
import { decryptData, encryptData } from '../utils/encryptdecryptoriginal';

const salt =/*process.env.SALT || */'6d090796-ecdf-11ea-adc1-0242ac112345';

export function saveData(key, detail, success, failure) {
  const encryptedData = encryptData(JSON.stringify(detail), salt);
  AsyncStorage.setItem(key, encryptedData).then(
    data => (success ? success(data) : null),
    fail => (failure ? failure(fail) : null),
  );
}

export function getData(key, success, failure) {
  AsyncStorage.getItem(key).then(
    data => {
      if (data != null) {
        //const originalData = decryptData(data, salt);
        const originalData = decryptData(JSON.parse(data), salt);
        success(JSON.parse(originalData))
      } else {
        success(null)
      }
    },
    fail => failure(fail),
  );
}

export async function getDataAsync(key) {
  try {
    const data = await AsyncStorage.getItem(key);
    if (data !== null) {
      const originalData = decryptData(data, salt);
      return JSON.parse(originalData);
    } else {
      return null;
    }
  } catch (error) {
    throw new Error(error);
  }
}

export function removeData(key, success, failure) {
  AsyncStorage.removeItem(key).then(
    data => success(data),
    fail => failure(fail),
  );
}

export function clearAllData(success, failure) {
  AsyncStorage.getAllKeys()
    .then(keys => AsyncStorage.multiRemove(keys))
    .then(
      data => success(data),
      fail => failure(fail),
    );
}
