import React from "react"

const GemsIcon = ({ isHovered }) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1669 6.25L10.4485 2.5L3.72998 6.25V13.75L10.4485 17.5L17.1669 13.75V6.25ZM10.4485 4.36054L15.5003 7.18027V12.8197L10.4485 15.6395L5.39665 12.8197V7.18027L10.4485 4.36054ZM14.0548 7.9874L10.4463 5.97331L6.83789 7.9874V12.0156L10.4463 14.0297L14.0548 12.0156V7.9874Z"
        fill={isHovered ? "url(#gamesGradient)" : "#C0BACB"}
      />
      {isHovered && (
        <defs>
          <linearGradient id="gamesGradient" gradientTransform="rotate(45)">
            <stop offset="0%" stopColor="#EF8ED5" />
            <stop offset="50%" stopColor="#EC44D0" />
            <stop offset="100%" stopColor="#C1169C" />
          </linearGradient>
        </defs>
      )}
    </svg>
  )
}

export default GemsIcon
