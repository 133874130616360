// packages Imports
import React, { useEffect, useMemo, useRef, useState, useContext } from 'react';
import {
  View,
  StyleSheet,
  Dimensions,
  Text,
  Pressable,
  Alert,
  Image,
} from 'react-native';
import {
  heightPercentageToDP as hp,
  widthPercentageToDP as wp,
} from 'react-native-responsive-screen';
import { useNavigation, useIsFocused } from '@react-navigation/native';
import { useBuzzmiAuth, useReels } from '../../../appState/hooks';
import { colors } from '../../../res/colors';
import { fonts } from '../../../res/fonts';
import Buttons from './Buttons';
import Video from './Videoweb';
import { useEventEmitter } from '../../../hooks/useEventEmitter';

// Screen Dimensions
const ScreenWidth = Dimensions.get('window').width;
const ScreenHeight = Dimensions.get('window').height;

function ReelCard({
  uri,
  _id,
  content,
  comments,
  counts,
  postItem,
  dataItem,
  ViewableItem,
  liked = false,
  disliked = false,
  index,

  // Container Props
  backgroundColor = 'black',
  my_reels = false,
  isSearch = false,
  // Header Props
  headerTitle = 'Reels',
  headerIconName,
  headerIconColor,
  headerIconSize,
  headerIcon,
  headerComponent,
  onHeaderIconPress = () => { },
  // Options Props
  optionsComponent,
  pauseOnOptionsShow = true,
  onSharePress = () => { },
  onCommentPress = () => { },
  onLikePress = () => { },
  onDislikePress = () => { },
  headerHeight,
  // Player Props
  onFinishPlaying = () => { },
  onRefresh = () => { },

  // Slider Props
  minimumTrackTintColor = 'white',
  maximumTrackTintColor = 'grey',
  thumbTintColor = 'white',

  // Time Props
  timeElapsedColor = 'white',
  totalTimeColor = 'white',
}) {
  // ref for Video Player
  const VideoPlayer = useRef(null);
  const { navigate } = useNavigation();

  const { userData } = useBuzzmiAuth();

  const {
    actionGetUserReels,
    actionPostLike,
    actionPostDisLike,
    actionReelDelete,
    actionGetReelList,
  } = useReels();

  // States
  const [VideoDimensions, SetVideoDimensions] = useState({
    width: ScreenWidth,
    height: ScreenWidth,
  });
  const [Progress, SetProgress] = useState(0);
  const [Duration, SetDuration] = useState(0);
  const [Paused, SetPaused] = useState(false);
  const [muteSound, setMuteSound] = useState(false);
  const [videoVolume, setVideoVolume] = useState(1.0);
  const [ShowOptions, SetShowOptions] = useState(true);
  const screenIsFocused = useIsFocused();
  const toast = null;
  const [userAction, setUserAction] = useState(false);
  const [loading, setLoading] = useState(false);

  // const { fileService } = usePlatformService();
  // const { STRINGS } = useLocalization();
  // const { openSheet } = useBottomSheet();

  console.log(dataItem);

  const [likeVideo, setLikeVideo] = useState(
    (dataItem?.reactions?.length > 0 &&
      dataItem?.reactions[0].participants.nodes.length &&
      dataItem?.reactions[0].participants.nodes.filter(
        o => o.participant.id === userData?.bettermodeUserId,
      ).length > 0) ||
    false,
  );

  const [likeCount, setLikeCount] = useState(dataItem?.reactionsCount || 0);
  const [commentCount, setCommentCount] = useState(
    dataItem?.totalRepliesCount || 0,
  );

  useEventEmitter('increment_reel_comment_count', data => {
    if (data && dataItem?.id == data?.id) {
      setCommentCount(data.count);
    }
  });

  useEffect(() => {
    setLikeVideo(
      (dataItem?.reactions?.length > 0 &&
        dataItem?.reactions[0].participants.nodes.length &&
        dataItem?.reactions[0].participants.nodes.filter(
          o => o.participant.id === userData?.bettermodeUserId,
        ).length > 0) ||
      false,
    );
    setLikeCount(dataItem?.reactionsCount || 0);
    setCommentCount(dataItem?.totalRepliesCount || 0);
  }, [dataItem, userData]);

  // Play/Pause video according to viisibility
  // useEffect(() => {
  //     if (ViewableItem == _id) SetPaused(false);
  //     else SetPaused(true);
  // }, [ViewableItem]);

  // // Pause when use toggle options to True
  // useEffect(() => {
  //     if (pauseOnOptionsShow) {
  //         if (ShowOptions) SetPaused(true);
  //         else SetPaused(false);
  //     }
  // }, [ShowOptions, pauseOnOptionsShow]);

  // Callback for PlayBackStatusUpdate
  const PlayBackStatusUpdate = playbackStatus => {
    try {
      let currentTime = Math.round(playbackStatus.currentTime);
      let duration = Math.round(playbackStatus.seekableDuration);
      if (currentTime)
        if (duration) SetProgress((currentTime / duration) * 100);
    } catch (error) { }
  };

  // function for getting video dimensions on load complete
  const onLoadComplete = event => {
    const { naturalSize } = event;

    try {
      const naturalWidth = naturalSize.width;
      const naturalHeight = naturalSize.height;
      if (naturalWidth > naturalHeight) {
        SetVideoDimensions({
          width: ScreenWidth,
          height: ScreenWidth * (naturalHeight / naturalWidth),
        });
      } else {
        SetVideoDimensions({
          width: ScreenHeight * (naturalWidth / naturalHeight),
          height: ScreenHeight,
        });
      }
      SetDuration(event.duration * 1000);
    } catch (error) { }
  };

  // function for showing options
  const onMiddlePress = async () => {
    try {
      SetShowOptions(!ShowOptions);
    } catch (error) { }
  };

  // Manage error here
  const videoError = error => { };

  // const startRecorder = () => {
  //   SetPaused(true);
  //   if (videoRecorder && videoRecorder.current) {
  //     videoRecorder.current.open(
  //       {
  //         maxDuration: 30,
  //         quality: RNCamera.Constants.VideoQuality['480p'],
  //       },
  //       data => {
  //         setTimeout(() => {
  //           navigate('PostViewer', {
  //             is_reel: true,
  //             file_url: data.uri,
  //             fileType: 'video',
  //           });
  //         }, 150);
  //       },
  //     );
  //   }
  // };

  const onReelDelete = async () => {
    Alert.alert('Delete Reel', 'Are you sure you want to delete this reel?', [
      {
        text: 'Yes',
        onPress: () => {
          actionReelDelete(userData?.token, postItem?._id, (data, flag) => {
            if (flag == false) {
              if (data.success) {
                actionGetUserReels();
                actionGetReelList();
                setTimeout(() => {
                  onRefresh();
                }, 1500);
              }
            }
          });
        },
      },
      {
        text: 'No',
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
    ]);
  };

  // useMemo for Options
  const GetButtons = useMemo(
    () => (
      <View
        style={[
          styles.OptionsContainer,
          {
            bottom: isSearch ? hp(17) : hp(15),
          },
        ]}>
        {optionsComponent ? null : (
          <>
            {/* {my_reels && (
                            <Buttons
                                name={'ios-ellipsis-vertical'}
                                text={' '}
                                isFontIonic
                                size={32}
                                onPress={() => {
                                    setUserAction(!userAction);
                                }}
                            />
                        )}
 */}

            <Buttons
              name="message1"
              text={comments?.length}
              size={34}
              onPress={() => {
                navigate('PostComment', { item: postItem });
              }}
            />

            <Buttons
              name={'dollar'}
              text={likeCount}
              pressStyle={{
                borderColor: likeVideo ? colors.PRIMARY_COLOR : colors.WHITE,
                borderWidth: hp(0.5),
                padding: hp(1),
                paddingHorizontal: hp(1.6),
                borderRadius: hp(4),
              }}
              isFontAwesome
              color={likeVideo ? colors.PRIMARY_COLOR : colors.WHITE}
              onPress={() => {
                if (likeVideo) {
                  actionPostDisLike(postItem?._id, (data, flag) => {
                    if (flag == false) {
                      setLikeVideo(false);
                      postItem.counts.upvotes = postItem.counts.upvotes - 1;
                      setLikeCount(likeCount - 1);
                      toast.show('Reel downvoted successfully');
                    }
                  });
                } else {
                  actionPostLike(postItem?._id, (data, flag) => {
                    if (flag == false) {
                      setLikeVideo(true);
                      postItem.counts.upvotes = postItem.counts.upvotes + 1;
                      setLikeCount(likeCount + 1);
                      toast.show('Reel upvoted successfully');
                    }
                  });
                }
              }}
              size={18}
            />

            <Buttons
              pressStyle={{
                backgroundColor: colors.PRIMARY_COLOR,
                padding: hp(1.8),
                borderRadius: hp(4),
              }}
              name={'video-camera'}
              text="Create"
              isFontAwesome
              // onPress={() => startRecorder()}
              onPress={() => {
                document.getElementById('selectedFile').click();
              }}
              size={18}
            />
          </>
        )}
      </View>
    ),
    [
      optionsComponent,
      likeVideo,
      likeCount,
      comments,
      my_reels,
      isSearch,
      userAction,
      postItem,
      navigate,
      actionPostDisLike,
      actionPostLike,
    ],
  );

  // open create with camera and gallery option
  const onPressAttachment = () => {
    // openSheet({
    //   sheetItems: [
    //     {
    //       title: STRINGS.GROUP_CHANNEL.DIALOG_ATTACHMENT_CAMERA,
    //       icon: 'camera',
    //       onPress: () => startRecorder(),
    //     },
    //     {
    //       title: STRINGS.GROUP_CHANNEL.DIALOG_ATTACHMENT_PHOTO_LIBRARY,
    //       icon: 'photo',
    //       onPress: async () => {
    //         const photo = await fileService.openMediaLibrary({
    //           selectionLimit: 1,
    //           mediaType: 'video',
    //           onOpenFailure: (error) => {
    //             if (error.code === SBUError.CODE.ERR_PERMISSIONS_DENIED) {
    //               alert({
    //                 title: STRINGS.DIALOG.ALERT_PERMISSIONS_TITLE,
    //                 message: STRINGS.DIALOG.ALERT_PERMISSIONS_MESSAGE('device storage', 'UIKitSample'),
    //                 buttons: [{ text: STRINGS.DIALOG.ALERT_PERMISSIONS_OK, onPress: () => SBUUtils.openSettings() }],
    //               });
    //             } else {
    //               toast.show(STRINGS.TOAST.OPEN_PHOTO_LIBRARY_ERROR, 'error');
    //             }
    //           },
    //         });
    //         if (photo && photo[0]) {
    //           setLoading(true)
    //           console.log(photo[0].uri)
    //           const result = await CompressVideo.compress(
    //             photo[0].uri,
    //             { compressionMethod: 'auto' },
    //             (progress) => {
    //               console.log('Compression Progress: ', progress);
    //             }
    //           );
    //           if (result) {
    //             setLoading(false)
    //             setTimeout(() => {
    //               console.log("result viewer")
    //               console.log(result)
    //               navigate('PostViewer', {
    //                 is_reel: true,
    //                 file_url: Platform.OS == "android" ? "file:///" + result : result,
    //                 fileType: 'video',
    //               });
    //             }, 150);
    //           }
    //           // onSendFileMessage(photo[0]).catch(() => toast.show(STRINGS.TOAST.SEND_MSG_ERROR, 'error'));
    //         }
    //       },
    //     },
    //     {
    //       title: 'Cancel',
    //       titleColor: '#6c2dcd',
    //       // onPress: () => console.log('Cancel pressed!'),
    //     },
    //   ],
    // });
  };

  const onChangeFile = event => {
    console.log(event.target.files[0]);

    navigate('PostViewer', {
      is_reel: true,
      file_data: event.target.files[0],
    });
  };

  const user_avatars = extractAvatarUrlsFromArray(
    dataItem?.owner?.member?.fields || [],
  );

  return (
    <Pressable
      style={[
        styles.container,
        {
          height: ScreenHeight - (headerHeight + hp(8)),
          backgroundColor: backgroundColor,
        },
      ]}
      onPress={() => {
        onMiddlePress();
      }}>
      <Video
        source={uri?.uri}
        style={styles.backgroundVideo}
        ref={VideoPlayer}
        playing={true}
      />

      <View style={{ alignSelf: 'center', display: 'none' }}>
        <input
          type="file"
          onChange={onChangeFile}
          accept="video/*"
          id="selectedFile"
        />
      </View>

      {ShowOptions ? (
        <>
          <View
            style={[
              styles.SliderContainer,
              {
                bottom: isSearch ? hp(8) : hp(10),
              },
            ]}>
            <View style={{ flexDirection: 'row', marginLeft: wp(6) }}>
              <TouchableOpacity>
                <Image
                  source={
                    user_avatars.length > 0
                      ? {
                        uri: user_avatars[0],
                      }
                      : getInitialsAvatar(dataItem?.owner?.member?.username)
                  }
                  style={styles.card_profile}
                />
              </TouchableOpacity>

              <View style={{ marginLeft: wp(4) }}>
                <Text
                  style={{
                    color: colors.WHITE,
                    fontSize: hp(2),
                    fontFamily: fonts.BOLD,
                    width: wp(62),
                    marginBottom: hp(1),
                    alignSelf: 'center',
                  }}>
                  {dataItem?.owner?.member?.username || 'unknown'}
                </Text>

                <Text
                  style={{
                    color: colors.WHITE,
                    fontSize: hp(2),
                    fontFamily: fonts.REGULAR,
                    width: wp(62),
                  }}
                  numberOfLines={10}>
                  {content}
                </Text>
              </View>
            </View>
          </View>
          {GetButtons}
        </>
      ) : null}
    </Pressable>
  );
}

// Exports
export default ReelCard;

// Stylesheet
const styles = StyleSheet.create({
  card_profile: {
    width: hp(5),
    height: hp(5),
    borderRadius: wp(10),
  },
  container: {
    width: ScreenWidth > 670 ? ScreenWidth / 2 : ScreenWidth,
    justifyContent: 'center',
    alignSelf: 'center',
  },
  SliderContainer: {
    position: 'absolute',
    width: ScreenWidth,
    zIndex: 100,
    // bottom: hp(32)
  },
  TimeOne: {
    color: 'grey',
    position: 'absolute',
    left: 15,
    fontSize: 13,
    bottom: 5,
  },
  TimeTwo: {
    color: 'grey',
    position: 'absolute',
    right: 15,
    fontSize: 13,
    bottom: 5,
  },
  OptionsContainer: {
    position: 'absolute',
    right: 10,
    zIndex: 100,
  },
  HeaderContainer: {
    position: 'absolute',
    width: ScreenWidth,
    top: 0,
    height: 50,
    zIndex: 100,
  },

  backgroundVideo: {
    'object-fit': 'cover',
    height: hp(100),
    width: ScreenWidth > 670 ? ScreenWidth / 2 : ScreenWidth,
  },
});
