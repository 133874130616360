import React from 'react';
import AddTaskDesktopAlert from './AddTaskDesktopAlert';
import AddTaskMobileAlert from './AddTaskMobileAlert';

const AddTaskAlert = props => {
  /**
   * Окей значит функции можем определять тут и передавать их ниже
   */

  const handleOK = () => {
    // console.log("handleAddTaskOnlyBaseTask", taskId)
    props?.handleOK();
  };

  return (
    <>
      <AddTaskDesktopAlert handleOK={handleOK} isPlan={props?.isPlan} />
      <AddTaskMobileAlert handleOK={handleOK} isPlan={props?.isPlan} />
    </>
  );
};

export default AddTaskAlert;
