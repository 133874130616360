import React, {useState} from 'react';
import ProfileMobileUsersItem from './profileMobileUsersItem/ProfileMobileUsersItem';
import {
  FollowButton,
  FollowingButton,
} from './profileMobileUsersButtons/ProfileMobileUsersButtons';
import {colors} from '../../../../../res/colors';
import {Dots} from 'react-activity';
import {ArrowLeftIcon, ArrowRightIcon} from '@heroicons/react/24/outline';
import {EventRegister} from 'react-native-event-listeners';
import {Disclosure, Menu, Popover, Dialog, Transition} from '@headlessui/react';
import ConfirmationModal from '../../ProfileMain/componentsV2/ConfirmationModal';

const ProfileMobileUsersFollowing = ({
  data,
  onPressUnfollow,
  onPressfollow,
  lodaingFollow,
  showLoadMoreFollow,
  onPressFollowLoadMore,
  onPressSuggestedLoadMore,
  showLoadMoreSuggested,
  lodaingSuggested,
  followPage,
  userContactInfo,
  isVisitorProfile = false,
}) => {
  const [open, setOpen] = useState(false);
  const [uItem, setUItem] = useState(null);

  return (
    <>
      <ul className="profile-contacts__list">
        {data?.users?.length == 0 && !lodaingFollow ? (
          <p className={'mt-4 text-center'}>{'No users found'}</p>
        ) : (
          data.users.map(item => (
            <li key={item.id}>
              <ProfileMobileUsersItem
                item={item}
                button={
                  <FollowingButton
                    id={item.id}
                    onPress={() => {
                      isVisitorProfile
                        ? null
                        : (setUItem(item),
                          setTimeout(() => {
                            setOpen(!open);
                          }, 100));
                    }}
                    isFollowing={true}
                    isVisitorProfile={isVisitorProfile}
                  />
                }
              />
            </li>
          ))
        )}
        {isVisitorProfile == false &&
          !lodaingFollow &&
          userContactInfo?.following >= 5 && (
            <div className={'d-flex justify-between mx-[8px] mt-[5px]'}>
              <div
                className={'cursor-pointer d-flex self-center'}
                onClick={() => {
                  if (followPage > 1) {
                    EventRegister.emit('refresh-following-user-list', {
                      page: followPage - 1,
                      limit: 5,
                    });
                  }
                }}>
                {followPage > 1 && (
                  <>
                    <ArrowLeftIcon
                      className="h-5 w-5 sm:h-8 sm:w-8"
                      style={{color: '#DA31B1'}}
                      aria-hidden="true"
                    />
                    <p className={'ml-2 text-sm self-center text-[#DA31B1]'}>
                      Prev
                    </p>
                  </>
                )}
              </div>
              <div
                className={'cursor-pointer d-flex self-center'}
                onClick={() => {
                  if (userContactInfo?.following >= followPage * 5) {
                    EventRegister.emit('refresh-following-user-list', {
                      page: followPage + 1,
                      limit: 5,
                    });
                  }
                }}>
                {userContactInfo?.following >= followPage * 5 && (
                  <>
                    <p className={'mr-2 text-sm self-center text-[#DA31B1]'}>
                      Next
                    </p>
                    <ArrowRightIcon
                      className="h-5 w-5 sm:h-8 sm:w-8"
                      style={{color: '#DA31B1'}}
                      aria-hidden="true"
                    />
                  </>
                )}
              </div>
            </div>
          )}
        {lodaingFollow && (
          <div
            className="pb-2"
            style={{
              display: 'flex',
              justifyContent: 'center', // Horizontally center content
              alignItems: 'center', // Vertically center content
              width: '100%', // Set the width to 100% if needed
              // Add any other inline styles you want
            }}>
            <Dots color={colors.PRIMARY_COLOR} />
          </div>
        )}
        {showLoadMoreFollow && (
          <div
            className="text-alt-primary cursor-pointer pb-2"
            style={{
              display: 'flex',
              justifyContent: 'center', // Horizontally center content
              alignItems: 'center', // Vertically center content
              width: '100%', // Set the width to 100% if needed
            }}
            onClick={onPressFollowLoadMore}>
            Load More
          </div>
        )}
      </ul>

      {data.suggestedForYou.length > 0 && (
        <>
          <h4 className="h4 mt-4 mb-3 mt-md-9 mb-md-21">Suggested for you</h4>
          <ul className="profile-contacts__list">
            {data.suggestedForYou.map(item => (
              <li key={item.id}>
                <ProfileMobileUsersItem
                  item={item}
                  button={
                    <FollowButton
                      id={item.id}
                      onPress={() => onPressfollow(item)}
                    />
                  }
                />
              </li>
            ))}
            {lodaingSuggested && false && (
              <div
                className="pb-2"
                style={{
                  display: 'flex',
                  justifyContent: 'center', // Horizontally center content
                  alignItems: 'center', // Vertically center content
                  width: '100%', // Set the width to 100% if needed
                  // Add any other inline styles you want
                }}>
                <Dots color={colors.PRIMARY_COLOR} />
              </div>
            )}
            {showLoadMoreSuggested && false && (
              <div
                className="text-alt-primary cursor-pointer pb-2"
                style={{
                  display: 'flex',
                  justifyContent: 'center', // Horizontally center content
                  alignItems: 'center', // Vertically center content
                  width: '100%', // Set the width to 100% if needed
                }}
                onClick={onPressSuggestedLoadMore}>
                Load More
              </div>
            )}
          </ul>
        </>
      )}

      <ConfirmationModal
        setOpen={setOpen}
        open={open}
        onPressUnfollow={() => {
          onPressUnfollow(uItem);
        }}
      />
    </>
  );
};

export default ProfileMobileUsersFollowing;
