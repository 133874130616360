import React, {useEffect, useState} from 'react';
import MainLayoutContent from '../../../../../components/layouts/mainLayout/mainLayoutContent/MainLayoutContent';
import BackBtnWithTitlePage from '../../../../../components/reusable/btns/backBtn/BackBtnWithTitlePage';
import MainLayout from '../../../../../components/layouts/mainLayout/MainLayout';
import CommentsList from './CommentsList';
import AddComment from './AddComment';
import {useRoute} from '@react-navigation/native';
// import { useNavigate, useParams } from 'react-router-dom';
import {useProfileApp} from '../../../../../appState/hooks';
import {EventRegister} from 'react-native-event-listeners';
import CommentsListV2 from './CommentsListV2';
import AddCommentV2 from './AddCommentV2';
import {useBuzzmiAuth} from '../../../../../appState/hooks/useBuzzmiAuth/index.web';

const CommentsScreen = props => {
  const route = useRoute();
  const {entity, id} = route?.params;
  const [item, setItem] = useState(null);
  const [commentData, setCommentData] = useState([]);
  const {userData} = useBuzzmiAuth();

  // const navigate = useNavigate();
  const {userReels, userPosts} = useProfileApp();

  useEffect(() => {
    let foundItem = null;
    switch (entity) {
      case 'post':
        foundItem = userPosts.find(post => post.id === id);
        break;
      case 'reel':
        foundItem = userReels.find(reel => reel.id === id);
        break;
      default:
        break;
    }
    setItem(foundItem);

    setCommentData(foundItem?.replies?.nodes);
  }, [entity, id]);

  // const handleBackButtonClick = () => {
  //   let backUrl = null;

  //   switch (entity) {
  //     case 'post':
  //       backUrl = `/profile#post-${id}`;
  //       break;
  //     case 'reel':
  //       backUrl = `/profile#reel-${id}`;
  //       break;
  //     default:
  //       break;
  //   }
  //   navigate(backUrl);
  // };

  return (
    <MainLayout hidelayout>
      <MainLayoutContent>
        <BackBtnWithTitlePage
          btnText="Back to post"
          title={`Comments (${commentData?.length || 0})`}
          //  customCallback={handleBackButtonClick}
        />
        {/* <div className="comments-post__wrapper">
          <PostAuthorInfo

            userName={item?.object?.user?.profile?.name}
            userNickname={item?.object?.user?.profile.username}
            userPosition={''}
            userAvatar={item?.object?.Whauser?.profile?.picture}
            isVerified={item?.object?.user?.profile.isVerified}
          />

          <div className="content-post__text-wrapper">
            <p className="content-post__text">{item?.text}</p>

            <ul className="content-post__tags-list">
              {item?.tags?.map(tag => (
                <TagLink text={tag} link={`/search-tags/${tag}`} key={tag} />
              ))}
            </ul>
          </div>

        </div> */}
        {/* <CommentsList comments={commentData} /> */}
        <CommentsListV2 comments={commentData} />
        {/* <AddComment
          callback={(data, item) => {
            console.log('data after commets', data);
            setCommentData([...commentData, data]);
            setTimeout(() => {
              EventRegister.emit('post_comments_length', {
                data: [...commentData, data],
                post_id: item?.id,
              });
            }, 200);
          }}
          item={item}
          userAvatar={item?.object?.user?.profile?.picture}
        /> */}

        <AddCommentV2
          callback={(data, item) => {
            setCommentData([...commentData, data]);
            setTimeout(() => {
              EventRegister.emit('post_comments_length', {
                data: [...commentData, data],
                post_id: item?.id,
              });
            }, 200);
          }}
          item={item}
          userAvatar={userData?.avatar}
        />
      </MainLayoutContent>
    </MainLayout>
  );
};

export default CommentsScreen;
