import React, {useState} from 'react';
import classNames from 'classnames';
import {
  UsersIcon,
  PlayIcon,
  IdentificationIcon,
  NewspaperIcon,
  TrophyIcon,
  RectangleStackIcon,
  PresentationChartLineIcon,
  QueueListIcon,
  FolderPlusIcon,
  CubeTransparentIcon,
} from '@heroicons/react/24/outline';
import {useLinkTo} from '@react-navigation/native';

import LogoSvg from '../../../reusable/assets/logo/LogoSVG';
import Strings from '../../../../string_key/Strings.web';
import {
  profileSideBarNavAtom,
  socialSideBarNavAtom,
} from '../../../../appState/navState';
import {useAtom} from 'jotai';
import LogoSvgMob from '../componentsV2/Header/LogoSVGMob';
import useWidthListener from '../../../../hooks/useWidthListner.';
import useWindowSize from '../../../../hooks/useWindowSize';

const Hub_Nav_items = [
  {
    name: 'Activity',
    href: '#',
    icon: <RectangleStackIcon className="w-6 h-6" />,
    current: false,
    to: '/hub/v2/tasks',
  },
  {
    name: 'Stats',
    href: '#',
    icon: <PresentationChartLineIcon className="w-6 h-6" />,
    current: false,
    to: '/hub/v2/tasks:1',
  },
  {
    name: 'LeaderBoard',
    href: '#',
    icon: <TrophyIcon className="w-6 h-6" />,
    current: false,
    to: '/hub/v2/leaderboard',
  },
];

const Discover_Nav_Items = [
  {
    icon: <QueueListIcon className="w-6 h-6" />,
    name: 'For you',
    current: true,
    to: 'ForYou',
    showOnMobile: true,
  },
  {
    icon: <FolderPlusIcon className="w-6 h-6" />,
    name: 'Following',
    current: false,
    to: 'Following',
    showOnMobile: true,
  },
  {
    icon: <CubeTransparentIcon className="w-6 h-6" />,
    name: 'Discover',
    current: false,
    to: 'Discover',
    showOnMobile: true,
  },
];

const Profile_Nav_Items = [
  {
    icon: <NewspaperIcon className="w-6 h-6" />,
    name: Strings.post + 's',
    to: '/hub/home',
    current: true,
    showOnMobile: true,
  },
  {
    icon: <PlayIcon className="w-6 h-6" />,
    name: Strings.reel,
    current: false,
    to: '/hub/hub-activities',

    showOnMobile: true,
  },
  {
    icon: <UsersIcon className="w-6 h-6" />,
    name: 'Premium',
    to: '/hub/hub-activitie',
    current: false,
    showOnMobile: true,
  },

  {
    icon: <IdentificationIcon className="w-6 h-6" />,
    name: 'Exclusive',
    to: '/hub/hub-actvities',
    current: false,
    showOnMobile: true,
  },
];

const MainLayoutCollapSidebar = ({
  collapsed,
  // navItems = defaultNavItems,
  shown,
  setCollapsed,
  windowWidth,
}) => {
  const [activeSideItem, setActiveSideItem] = useAtom(profileSideBarNavAtom);
  const [activeSocialSideItem, setActiveSocialSideItem] =
    useAtom(socialSideBarNavAtom);

  const linkTo = useLinkTo();
  const {width} = useWindowSize();

  let SideItemsArray =
    global.assignMenu == 'Profile'
      ? Profile_Nav_Items
      : global.assignMenu == 'Hub'
      ? Hub_Nav_items
      : global.assignMenu == 'Home'
      ? Discover_Nav_Items
      : [];
  const SideItemsList = ({onClick, active}) => {
    const onHandelHubMenuClick = to => {
      // global.assignMenu = 'Hub';
      // global.activeMenu = to;
      // linkTo(to);
      console.log('first', to);
    };
    const onhandleHomeClick = name => {
      linkTo('/profile/v2');
      global.assignMenu = 'Profile';
      setActiveSideItem(name);
    };
    const handleClick = item => {
      global.assignMenu == 'Profile'
        ? setActiveSideItem(item.name)
        : global.assignMenu == 'Hub'
        ? onHandelHubMenuClick(item?.to)
        : global.assignMenu == 'Home'
        ? setActiveSocialSideItem(item.to)
        : //onhandleHomeClick(item.name)
          '';
    };
    const ItemName = React.memo(({name, collapsed}) => {
      // console.log('collapsed', collapsed);
      const [showContent, setShowContent] = useState(true);
      return <>{showContent && <span>{!collapsed && name}</span>}</>;
    });

    const SideItem = ({item, index}) => {
      let active =
        global.assignMenu == 'Profile'
          ? item?.name == activeSideItem
          : global.assignMenu == 'Hub'
          ? item?.to == global.activeMenu
          : item?.to == activeSocialSideItem;
      return (
        <li
          onClick={() => handleClick(item)}
          key={index}
          className={classNames({
            'text-black  hover:bg-gradiant-800 flex cursor-pointer': true, //colors
            'bg-gradiant-800 text-white': active,
            'transition-colors duration-0': true, //animation
            'rounded-md p-2 mx-3 gap-4 ': !collapsed,
            'rounded-full p-2 mx-3 w-10 h-10': collapsed,
          })}>
          <div className="flex gap-2">
            {item.icon}
            {/* <span>{!collapsed && item.name}</span> */}

            <ItemName name={item.name} collapsed={collapsed} />
          </div>
        </li>
      );
    };

    return SideItemsArray.map((item, index) => (
      <SideItem key={`side-item-${index}`} item={item} index={index} />
    ));
  };

  const SideBarWrapper = props => {
    return (
      <div
        onClick={() =>
          windowWidth < 1050 && !collapsed ? setCollapsed(!collapsed) : null
        }
        className={
          windowWidth < 1050 && !collapsed
            ? 'w-full bg-red fixed inset-0 bg-transparent z-10'
            : ''
        }>
        {props?.children}
      </div>
    );
  };

  return (
    <SideBarWrapper>
      <div
        className={classNames({
          'mobile-side-v2 bg-white text-zinc-50 fixed z-20 ': true,
          'mobile-side-v2': true,
          'transition-all duration-300 ease-in-out': true,
          'w-[300px]': !collapsed,
          'sm:w-16 w-0': collapsed,
          '-translate-x-full': !shown,
        })}>
        <div
          className={classNames({
            'flex flex-col justify-between h-screen sticky inset-0 w-full': true,
          })}>
          <div
            className={classNames({
              'flex items-center transition-none border-b sm:pb-[14px] pb-[24px]': true,
              'p-3.5 justify-between': windowWidth < 576 ? false : true,
              'pt-2.5 pb-[14px].5 justify-center':
                windowWidth < 576 ? false : true,
            })}>
            <button
              className="flex flex-shrink-0 items-center"
              onClick={() => setCollapsed(!collapsed)}>
              <div className="main-layout__logo mb-0">
                {windowWidth < 576 ? (
                  <div className="h-10 w-16 ml-2">
                    <LogoSvgMob />
                  </div>
                ) : (
                  <div className="logo">
                    <LogoSvg />
                  </div>
                )}
              </div>
            </button>
          </div>
          <nav
            className={classNames({
              'flex-grow nav-custom-mob-v2': true,
              'active-custom-mob-v2': !collapsed,
            })}>
            <ul
              className={classNames({
                'my-2 flex flex-col gap-2 items-stretch': true,
              })}>
              <SideItemsList />
            </ul>
          </nav>
          {/* <div
          className={classNames({
            'grid place-content-stretch p-4 ': true,
          })}>
          <div className="flex gap-4 items-center h-11 overflow-hidden">
            <Image
                src={
                  'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
                }
                height={36}
                width={36}
                alt="profile image"
                className="rounded-full"
              />
            {!collapsed && (
              <div className="flex flex-col ">
                <span className="text-pink-50 my-0">Tom Cook</span>
                <Link href="/" className="text-pink-200 text-sm">
                    View Profile
                  </Link>
              </div>
            )}
          </div>
        </div> */}
        </div>
      </div>
    </SideBarWrapper>
  );
};
export default MainLayoutCollapSidebar;
