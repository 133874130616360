import React, {useState, useEffect} from 'react';
import {Dimensions, View, FlatList, TouchableOpacity} from 'react-native';
import {
  heightPercentageToDP as hp,
  widthPercentageToDP as wp,
} from 'react-native-responsive-screen';
import {RegularText} from '../style';
import {BackHeader} from '../../../../components/BackHeader';
import {GlobalFlex} from '../../../../res/globalStyles';
import styles, {HeaderShadowLine} from './style';
import {Spacer} from '../../../../res/spacer';
import {colors} from '../../../../res/colors';
import {GET_DATA} from '../../../../api_helper/ApiServices';
import deviceInfoModule from 'react-native-device-info';
import {GET_WALLETS} from '../../../../api_helper/Api';
import {useBuzzmiAuth} from "../../../../appState/hooks";

const ScreenWidth = Dimensions.get('window').width;

const WalletScreen = props => {
  // const { title } = props?.route?.params;
  const {userData} = useBuzzmiAuth();
  const [walletData, setWalletData] = useState([]);

  const getWalletList = async () => {
    const uniqueID = await deviceInfoModule.getUniqueId();
    GET_DATA(GET_WALLETS, true, userData?.token, uniqueID, data => {
      setWalletData(data);
    });
  };


  useEffect(() => {
    getWalletList();
  });

  return (
    <GlobalFlex>
      <View>
        <BackHeader
          is_center_text
          title={'My Wallet'}
          onBackPress={() => props.navigation.goBack()}
        />
        <Spacer space={hp(1.5)}/>
        <HeaderShadowLine/>
      </View>
      <Spacer space={hp(1)}/>

      <FlatList
        style={{flex: 1}}
        bounces={false}
        data={walletData}
        renderItem={({item, index}) => (
          <TouchableOpacity
            onPress={() => {
              props.navigation.navigate('WalletActivity', {
                wallet_type: item?.name,
              });
            }}>
            <View
              style={{
                width: ScreenWidth > 670 ? hp(75) : wp(92),
                borderRadius: hp(2),
                alignSelf: 'center',
                backgroundColor: colors.BORDER_COLOR,
                borderColor: colors.PRIMARY_COLOR,
                borderWidth: wp(0.1),
              }}>
              <View style={{padding: hp(1)}}>
                <RegularText style={{textAlign: 'left', fontSize: hp(2.2)}}>
                  {item?.name}
                </RegularText>
                <Spacer space={hp(1)}/>
                <>
                  <RegularText style={[styles.txt_regular, {fontSize: hp(2.8)}]}>
                    {item?.balance}
                  </RegularText>
                  <RegularText
                    style={{fontSize: hp(2.5), color: colors.PRIMARY_COLOR}}>
                    {' Points'}
                  </RegularText>
                </>
                <Spacer space={hp(1)}/>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}>
                  <RegularText style={{textAlign: 'left', fontSize: hp(2.2)}}>
                    {'Total : ' + item?.total}
                  </RegularText>
                  <RegularText style={{textAlign: 'left', fontSize: hp(2.2)}}>
                    {'Spent : ' + item?.spent}
                  </RegularText>
                </View>
              </View>
            </View>
            <Spacer space={hp(1)}/>
          </TouchableOpacity>
        )}
        keyExtractor={(item, index) => index.toString()}
      />
    </GlobalFlex>
  );
};

export default WalletScreen;
