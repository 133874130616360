import React, {Fragment} from 'react';
import {useNavigation} from '@react-navigation/native';
import {useBuzzmiAuth} from '../../../../../../appState/hooks/useBuzzmiAuth/index.web';
import {Dialog, Transition} from '@headlessui/react';
import {XMarkIcon, ExclamationTriangleIcon} from '@heroicons/react/24/outline';
import ShareModalV2 from '../../../../../../components/modal/modals/shareModal/ShareModalV2';
import ShareActivityModalV2 from '../../../../../../components/modal/modals/shareModal/ShareActivityModalV2';

const AssignActivityModal = ({item, setOpen, open}) => {
  const {userData} = useBuzzmiAuth();
  const {navigate} = useNavigation();
  // console.log('data in share modal', data);
  const data = {
    ...item,
    entity: 'post',

    userShareDetails: {
      link: 'https://buzzmi.page.link/9DddV9o8r5SQPwMv7',
      thumbnail:
        'https://app-us-east-1.t-cdn.net/637e7659c38ca4d56de49d13/posts/6569c1ee9a10331006d89a72/6569c1ee9a10331006d89a72_64513.png',
    },
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:h-1/2 sm:max-w-4xl sm:w-2/3 sm:my-8 sm:p-6">
                <div className="absolute right-0 top-0 pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}>
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div> */}
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900">
                      Assign
                    </Dialog.Title>
                    {/* <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to deactivate your account? All of your data will be permanently removed
                        from our servers forever. This action cannot be undone.
                      </p>
                    </div> */}
                  </div>
                </div>
                <div className="relative mt-6 flex-1">
                  <ShareActivityModalV2
                    forV2={true}
                    isShare={false}
                    isAssign={true}
                    data={data}
                  />
                  {/* <div className="content-area max-h-[850px] overflow-y-auto">

                  </div> */}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default AssignActivityModal;
