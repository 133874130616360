import React from "react"

const ProfilePremiumContentEquipment = ({ equipment }) => (
  <div className="profile-content__equipment profile-content__block">
    <div className="profile-content__block-title d-flex justify-content-between align-items-center">
      <span>Equipment</span> <span className="text-s">{equipment.length} items</span>
    </div>
    <ul>
      {equipment.map((item) => (
        <li key={item.id}>
          <img src={item.image.src} alt={item.image.alt} />
          <span>{item.title}</span>
        </li>
      ))}
    </ul>
  </div>
)

export default ProfilePremiumContentEquipment
