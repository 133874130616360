export const hours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
export const minutes = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
  60,
]
export const ampm = ["AM", "PM"]

export const hoursWithLabel = [
  {
    label: 1,
    value: 1,
  },
  {
    label: 2,
    value: 2,
  },
  {
    label: 3,
    value: 3,
  },
  {
    label: 4,
    value: 4,
  },
  {
    label: 5,
    value: 5,
  },
  {
    label: 6,
    value: 6,
  },
  {
    label: 7,
    value: 7,
  },
  {
    label: 8,
    value: 8,
  },
  {
    label: 9,
    value: 9,
  },
  {
    label: 10,
    value: 10,
  },
  {
    label: 11,
    value: 11,
  },
  {
    label: 12,
    value: 12,
  },
]

export const minutesWithLabel = [
  {
    label: "00",
    value: "00",
  },
  {
    label: "01",
    value: "01",
  },
  {
    label: "02",
    value: "02",
  },
  {
    label: "03",
    value: "03",
  },
  {
    label: "04",
    value: "04",
  },
  {
    label: "05",
    value: "05",
  },
  {
    label: "06",
    value: "06",
  },
  {
    label: "07",
    value: "07",
  },
  {
    label: "08",
    value: "08",
  },
  {
    label: "09",
    value: "09",
  },
  {
    label: 10,
    value: 10,
  },
  {
    label: 11,
    value: 11,
  },
  {
    label: 12,
    value: 12,
  },
  {
    label: 13,
    value: 13,
  },
  {
    label: 14,
    value: 14,
  },
  {
    label: 15,
    value: 15,
  },
  {
    label: 16,
    value: 16,
  },
  {
    label: 17,
    value: 17,
  },
  {
    label: 18,
    value: 18,
  },
  {
    label: 19,
    value: 19,
  },
  {
    label: 20,
    value: 20,
  },
  {
    label: 21,
    value: 21,
  },
  {
    label: 22,
    value: 22,
  },
  {
    label: 23,
    value: 23,
  },
  {
    label: 24,
    value: 24,
  },
  {
    label: 25,
    value: 25,
  },
  {
    label: 26,
    value: 26,
  },
  {
    label: 27,
    value: 27,
  },
  {
    label: 28,
    value: 28,
  },
  {
    label: 29,
    value: 29,
  },
  {
    label: 30,
    value: 30,
  },
  {
    label: 31,
    value: 31,
  },
  {
    label: 32,
    value: 32,
  },
  {
    label: 33,
    value: 33,
  },
  {
    label: 34,
    value: 34,
  },
  {
    label: 35,
    value: 35,
  },
  {
    label: 36,
    value: 36,
  },
  {
    label: 37,
    value: 37,
  },
  {
    label: 38,
    value: 38,
  },
  {
    label: 39,
    value: 39,
  },
  {
    label: 40,
    value: 40,
  },
  {
    label: 41,
    value: 41,
  },
  {
    label: 42,
    value: 42,
  },
  {
    label: 43,
    value: 43,
  },
  {
    label: 44,
    value: 44,
  },
  {
    label: 45,
    value: 45,
  },
  {
    label: 46,
    value: 46,
  },
  {
    label: 47,
    value: 47,
  },
  {
    label: 48,
    value: 48,
  },
  {
    label: 49,
    value: 49,
  },
  {
    label: 50,
    value: 50,
  },
  {
    label: 51,
    value: 51,
  },
  {
    label: 52,
    value: 52,
  },
  {
    label: 53,
    value: 53,
  },
  {
    label: 54,
    value: 54,
  },
  {
    label: 55,
    value: 55,
  },
  {
    label: 56,
    value: 56,
  },
  {
    label: 57,
    value: 57,
  },
  {
    label: 58,
    value: 58,
  },
  {
    label: 59,
    value: 59,
  },
  {
    label: 60,
    value: 60,
  },
]

export const ampmWithLabel = [
  {
    label: "AM",
    value: "AM",
  },
  {
    label: "PM",
    value: "PM",
  },
]
