// noinspection JSUnresolvedReference

import React, { useState, useEffect } from 'react';
import { Keyboard } from 'react-native';
import { useEncryptedAtom } from '../../../appState/hooks/useEncryptedAtom';
import Logo from '../../../components/reusable/assets/logo/Logo.jsx';
import { getLanguageValueFromKey } from '../../../commonAction';
import { Link, useNavigation } from '@react-navigation/native';
import { _userDetailsAtom } from '../../../appState/userState';
import { useToast } from 'react-native-toast-notifications';
import { useBuzzmiAuth } from '../../../appState/hooks';
import { ShowError } from '../../HOC/MessageWeb';
import Loading from 'react-fullscreen-loading';
import { colors } from '../../../res/colors.js';
import * as yup from 'yup';
import { useFormValidator } from '../../../form-validator/hooks';
import ValidationSchema from '../../../form-validator/ValidationSchema';
import QuadServicesButtons from './serviceButtons/QuadServicesButtons';
import LoginForms from './items/loginForms/LoginForms';

const validationSchema = new ValidationSchema({
  identifier: [{ rule: 'required' }],
  password: [{ rule: 'required' }],
});

const Login = props => {
  const { navigate } = useNavigation();
  const toast = useToast();
  const [userDetails, setUserDetails] = useEncryptedAtom(_userDetailsAtom);
  const { login, loginError, isLoginPending } = useBuzzmiAuth();

  useEffect(() => {
    if (userDetails) {
      setTimeout(
        () => window.location.reload(),
        props.navigation.replace('Tab'),
        150,
      );
    }
  }, [userDetails]);

  useEffect(() => {
    if (loginError != null) {
      Keyboard.dismiss();
      ShowError(loginError, toast);
    }
  }, [loginError, toast]);

  const onSubmit = data => {
    if (data?.identifier === '') {
      ShowError(getLanguageValueFromKey('please_enter_email_or_user'), toast);
    } else if (data?.password === '') {
      ShowError(getLanguageValueFromKey('please_enter_password'), toast);
    } else {
      login(data?.identifier, data?.password);
    }
  };

  return (
    <>
      <Loading
        loading={isLoginPending}
        background="transparent"
        loaderColor={colors.PRIMARY_COLOR}
      />
      <div className="flex min-h-full flex-1 m-auto w-full md:w-9/12">
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-contain"
            src={'../images/login/intro.png'}
            alt=""
          />
        </div>
        <div className="flex flex-1 flex-col justify-center px-4 sm:py-12 py-3 ">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <Logo subtitle={'Welcome to'} />
            </div>

            <div className="mt-10">
              <LoginForms onSubmit={onSubmit} props={props} />

              <div className="mt-8">
                <div className="relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true">
                    <div className="w-full border-t border-gray-200" />
                  </div>
                  <div className="relative flex justify-center text-sm font-medium leading-6">
                    <span className="bg-white px-6 text-gray-900">
                      Or continue with
                    </span>
                  </div>
                </div>

                <QuadServicesButtons props={props} />
              </div>
            </div>
          </div>
          <div>
            <p className="text-center text-sm leading-6 text-gray-500 mt-4">
              Don't have an account?{' '}
              <Link to={{ screen: 'Registration' }}>
                <span className="font-semibold text-rose-600 hover:text-rose-500">
                  Sign up
                </span>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
