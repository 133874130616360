import { useNavigation } from '@react-navigation/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useBuzzmiAuth } from '../../../../../../appState/hooks/useBuzzmiAuth/index.web';
import useMemberships from '../../../../../../container/App/MembershipScreen/hooks/useMemberships/index.web';
import { closeModal } from '../../../../../../store/slices/modalSlice/modalSlice';
import Button from '../../../../../reusable/btns/buttons/Button';

const MemberShipUpgradeAlert = ({
    handleOkay,
    contentType
}) => {
    const [loading, setLoading] = useState(false);
    const [membershipID, setMemberShipID] = useState(null);
    const { memberships } = useMemberships(setLoading);
    const { navigate } = useNavigation();
    const { userData } = useBuzzmiAuth();
    const dispatch = useDispatch();

    useEffect(() => {
        if (memberships?.length > 0) {
            setMemberShipID(memberships?.filter(m => m?.type === (contentType == "premium" ? "premium_member" : "elite_membership"))[0]?.id)
        }
    }, [memberships])

    return (
        <div className="p-10">
            <div className="alert__body bg-transparent">
                <div className="delete-tasks__text">
                    <div className={"mb-4 flex self-center mb-4 justify-center"}>
                        <img src={contentType == "premium" ? "/images/social/premium.png" : "/images/social/exclusive.png"} className={"animate-bounce h-[80px] w-[80px] self-center"} />
                    </div>
                    <p className={"text-sm mb-4 text-center font-semibold"}>{`Please Upgrade the membership to unlock all the ${contentType} content`}</p>
                </div>
                <div className="delete-tasks__buttons">
                    <div className="d-flex justify-content-center">
                        <Button
                            className="_fw button_secondary button_m  w-full"
                            onClick={() => {
                                dispatch(closeModal())
                                setTimeout(() => {
                                    navigate('Subscription', {
                                        id: membershipID,
                                        token: userData.token,
                                        from: 'home',
                                    });
                                }, 1000);

                            }}>
                            {"Upgrade Now"}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MemberShipUpgradeAlert;
