import React from 'react';
import CustomRatingGold from '../../../../../../components/reusable/assets/customRating/CustomRatingGold';
import formatRelativeDate from '../../../items/profilePost/utils/dateUtils';

const IntroReview = ({review}) => {
  return (
    <div className="premium-content-reviews__review-card">
      <div className="d-flex flex-row align-items-center">
        <img
          src={review?.author?.avatar?.src}
          alt="avatar"
          className="premium-content-reviews__review-card-avatar"
        />
        <div>
          <p className="premium-content-reviews__review-name h6">
            {review?.author?.name}
          </p>
          <CustomRatingGold initialValue={review?.rating} readonly />
        </div>
        <p className="premium-content-reviews__review-date text-s">
          {formatRelativeDate(review?.date)}
        </p>
      </div>
      <p className="premium-content-reviews__review-text text-r">
        {review?.text}
      </p>
    </div>
  );
};

export default IntroReview;
