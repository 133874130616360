// noinspection ES6CheckImport

import React, {useContext, useEffect, useState} from 'react';
import {
  TouchableOpacity,
  AppState,
  Image,
  Platform,
  Text,
  View,
  Dimensions,
  Alert,
  ScrollView,
  KeyboardAvoidingView,
} from 'react-native';
import {
  heightPercentageToDP as hp,
  widthPercentageToDP as wp,
} from 'react-native-responsive-screen';
import AsyncStorage from '@react-native-community/async-storage';
import {POST_QR_CODE} from '../../../../api_helper/Api';
import {BackHeader} from '../../../../components/BackHeader';
import {GlobalFlex} from '../../../../res/globalStyles';
import {POST_DATA} from '../../../../api_helper/ApiServices';
import {Spacer} from '../../../../res/spacer';
import {colors} from '../../../../res/colors';
import {MainTitle, SubContainer} from './style';
// import { useSendbirdChat } from '@sendbird/uikit-react-native';
import {fonts} from '../../../../res/fonts';
import {Input} from '../../../../components/Input';
import {Button} from '../../../../components/Button';
import {useAppNavigation} from '../../../../hooks/useAppNavigation.web.ts';
import {saveData} from '../../../../res/asyncStorageHelper';
import {useToast} from 'react-native-toast-notifications';
import {ShowError, ShowSuccess} from '../../../HOC/MessageWeb';
import {getData} from '../../../../res/asyncStorageHelper.web';
import {useAtom} from 'jotai';
import {_userDetailsAtom} from '../../../../appState/userState';
import {useEncryptedAtom} from "../../../../appState/hooks/useEncryptedAtom";

const ScreenWidth = Dimensions.get('window').width;

const FAVerification = props => {
  const {navigation, params} = useAppNavigation();
  const [code, setCode] = useState('');
  const [userDetails, setUserDetails] = useEncryptedAtom(_userDetailsAtom);

  const toast = useToast();
  // const MessageShow = new HOC.MessageShow();

  const onVerifyOtp = async () => {
    if (code == '') {
      ShowError('Please enter code', toast);
    } else {
      // getData('userDetails', userEData => {
      let postData = {
        // 'userId': userEData.userId,
        userId: userDetails.userId,
        totp: code,
        isTFAEnabled: true,
      };
      POST_DATA(POST_QR_CODE, userDetails.token, postData, (data, flag) => {
        if (flag == false && data?.otpValid) {
          setUserDetails({
            ...userDetails,
            twoFactorAuthEnabled: data?.isTFAEnabled,
          });
          props.navigation.goBack();
          setTimeout(() => {
            ShowSuccess('2FA enable successfully', toast);
          }, 350);
        } else {
          ShowError(data, toast);
        }
      });
      // })
    }
  };

  const copyToClipboard = () => {
    alert('Under Development!');
    // Clipboard.setString(params?.secret);
    // setTimeout(() => {
    //   MessageShow.showToast('Secret key copied');
    // }, 200);
  };

  return (
    <GlobalFlex>
      <BackHeader
        isLeftText={true}
        background={{
          backgroundColor: colors.PRIMARY_COLOR,
          paddingTop: hp(2),
          paddingBottom: hp(1.5),
        }}
        textColor={{color: colors.WHITE}}
        onBackPress={() => {
          props.navigation.goBack();
        }}
        is_center_text
        title={''}
      />

      <SubContainer>
        <ScrollView showsVerticalScrollIndicator={false}>
          <Spacer space={hp(1)} />
          <View
            style={{
              width: ScreenWidth > 670 ? hp(70) : wp(90),
              alignSelf: 'center',
            }}>
            <MainTitle style={{fontSize: hp(3)}}>
              {'Enable Two-factor authentication'}
            </MainTitle>
            <Spacer space={hp(1)} />
            <Text
              style={{
                fontSize: hp(2.5),
                fontFamily: fonts.REGULAR,
                color: colors.DARK_GRAY_91,
              }}>
              {
                'An authenticator app lets you generate security codes on your phone without needing to receive text messages. If you don`t already have one, we support e. g. these apps.'
              }
            </Text>
            <Spacer space={hp(1)} />
            <Text
              style={{
                fontSize: hp(2.5),
                fontFamily: fonts.REGULAR,
                color: colors.DARK_GRAY_91,
              }}>
              {'To configure your authenticator app:'}
            </Text>
            <Spacer space={hp(1)} />
            <Text
              style={{
                fontSize: hp(2.5),
                fontFamily: fonts.REGULAR,
                color: colors.DARK_GRAY_91,
              }}>
              {
                'Add a new time-based token \nUse your app to scan the QR-code below'
              }
            </Text>
            <Spacer space={hp(1)} />

            <TouchableOpacity
              style={{alignSelf: 'center'}}
              onPress={() => {
                copyToClipboard();
              }}>
              <Image
                source={{uri: params?.qrcode}}
                style={{width: hp(30), height: hp(30)}}
              />
            </TouchableOpacity>
            <Spacer space={hp(1)} />
            <MainTitle style={{fontSize: hp(3)}}>
              {'Enter the code that was generated'}
            </MainTitle>
            <Spacer space={hp(0.5)} />
            <Input
              value={code}
              onChange={setCode}
              placeholder={'Code'}
              placeholderTextColor={colors.TRIPLET_PLACEHOLDER}
              style={{flex: 1, paddingBottom: hp(2)}}
              mainstyle={{flexDirection: 'row'}}
            />
            <Spacer space={hp(1.5)} />
            <Button buttonText={'Validate'} buttonPress={() => onVerifyOtp()} />
            <Spacer space={hp(1.5)} />
          </View>
        </ScrollView>
        {Platform.OS === 'ios' && (
          <KeyboardAvoidingView
            behavior={'padding'}
            keyboardVerticalOffset={120}
          />
        )}
      </SubContainer>
    </GlobalFlex>
  );
};
export default FAVerification;
