import React, {useState} from 'react';
import {
  WhatsappIcon,
  WhatsappShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  InstapaperIcon,
  InstapaperShareButton,
  EmailIcon,
  EmailShareButton,
} from 'react-share';

const ShareModalBottomMenuV2 = userShareDetails => {
  const [isCopied, setIsCopied] = useState(false);

  const socialLinks = [
    {
      id: '1',
      src: '/images/post/copy-link-label.webp',
      alt: 'copy link',
      text: isCopied ? 'Сopied!' : 'Copy link',
      onClick: () => {
        const currentURL = window.location.href;
        navigator.clipboard
          .writeText(currentURL)
          .then(() => {
            setIsCopied(true);
          })
          .catch(error => {
            console.error('Copy failed', error);
          });
      },
    },
    {
      id: '2',
      src: '/images/post/buzzmi-label.webp',
      alt: 'Buzzmi label',
      text: 'BuzzMi',
      onClick: () => {
        console.log('Clicked Buzzmi label');
      },
    },
    {
      id: '3',
      src: '/images/post/whatsapp-label.webp',
      alt: 'whatsapp label',
      text: 'WhatsApp',
      onClick: () => {
        console.log('Clicked WhatsApp label');
      },
    },
    {
      id: '4',
      src: '/images/post/facebook-label.webp',
      alt: 'facebook label',
      text: 'Facebook',
      onClick: () => {
        console.log('Clicked Facebook label');
      },
    },
    {
      id: '5',
      src: '/images/post/instagram-label.webp',
      alt: 'instagram label',
      text: 'Instagram',
      onClick: () => {
        console.log('Clicked Instagram label');
      },
    },
    {
      id: '6',
      src: '/images/post/gmail-label.webp',
      alt: 'gmail label',
      text: 'Gmail',
      onClick: () => {
        console.log('Clicked Gmail label');
      },
    },
    {
      id: '7',
      src: '/images/post/telegram-label.webp',
      alt: 'telegram label',
      text: 'Telegram',
      onClick: () => {
        console.log('Clicked Telegram label');
      },
    },
  ];

  return (
    <ul className="share-modal__bottom-menu_v2">
      <li
        className="share-modal__bottom-menu-item"
        key={socialLinks[0].id}
        onClick={() => {
          navigator.clipboard
            .writeText(
              userShareDetails?.userShareDetails.link ||
                userShareDetails?.userShareDetails?.deeplink,
            )
            .then(() => {
              setIsCopied(true);
            })
            .catch(error => {
              console.error('Copy failed', error);
            });
        }}>
        <button className="share-modal__bottom-menu-button" type="button">
          <img src={socialLinks[0].src} alt={socialLinks[0].alt} />
        </button>
        <p className="text-s">{isCopied ? 'Сopied!' : 'Copy link'}</p>
      </li>

      <li className="share-modal__bottom-menu-item" style={{marginLeft: 16}}>
        <div className={'share-modal__bottom-menu-button'}>
          <FacebookShareButton
            url={
              userShareDetails?.userShareDetails.link ||
              userShareDetails?.userShareDetails?.deeplink
            }>
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>
        </div>
        <p className="text-s">{'Facebook'}</p>
      </li>
      <li className="share-modal__bottom-menu-item">
        <div className={'share-modal__bottom-menu-button'}>
          <TwitterShareButton
            url={
              userShareDetails?.userShareDetails.link ||
              userShareDetails?.userShareDetails?.deeplink
            }>
            <TwitterIcon size={32} round={true} />
          </TwitterShareButton>
        </div>
        <p className="text-s">{'Twitter'}</p>
      </li>
      <li className="share-modal__bottom-menu-item">
        <div className={'share-modal__bottom-menu-button'}>
          <WhatsappShareButton
            url={
              userShareDetails?.userShareDetails.link ||
              userShareDetails?.userShareDetails?.deeplink
            }>
            <WhatsappIcon size={32} round={true} />
          </WhatsappShareButton>
        </div>
        <p className="text-s">{'WhatsApp'}</p>
      </li>
      <li className="share-modal__bottom-menu-item">
        <div className={'share-modal__bottom-menu-button'}>
          <InstapaperShareButton
            url={
              userShareDetails?.userShareDetails.link ||
              userShareDetails?.userShareDetails?.deeplink
            }>
            <InstapaperIcon size={32} round={true} />
          </InstapaperShareButton>
        </div>
        <p className="text-s">{'Instagram'}</p>
      </li>
      <li className="share-modal__bottom-menu-item">
        <div className={'share-modal__bottom-menu-button'}>
          <EmailShareButton
            url={
              userShareDetails?.userShareDetails.link ||
              userShareDetails?.userShareDetails?.deeplink
            }>
            <EmailIcon size={32} round={true} />
          </EmailShareButton>
        </div>
        <p className="text-s">{'Email'}</p>
      </li>
    </ul>
  );
};

export default ShareModalBottomMenuV2;
