import React, { useEffect, useRef, useState } from 'react';

import WaveSurfer from 'wavesurfer.js';
import moment from 'moment/moment.js';

const formWaveSurferOptions = ref => ({
  container: ref,
  waveColor: '#918b9c',
  progressColor: '#EF8FD5',
  cursorColor: '#EF8FD5',
  barWidth: 3,
  barRadius: 3,
  responsive: true,
  height: 30,
  // If true, normalize by the maximum peak instead of 1.0.
  normalize: true,
  // Use the PeakCache to improve rendering speed of large waveforms.
  partialRender: true,
  xhr: {
    cache: "default",
    mode: 'no-cors',
    method: "GET",
    credentials: "include",
    headers: [
      { key: "cache-control", value: "no-cache" },
      { key: "pragma", value: "no-cache" },
      { key: 'Access-Control-Allow-Origin', value: 'origin-list' }
    ]
  }
});

const AudioPlayer = ({ src }) => {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const timelineRef = useRef(null);
  const [playing, setPlay] = useState(false);
  const [volume, setVolume] = useState(0.5);

  useEffect(() => {
    if (src) {
      setPlay(false);

      const options = formWaveSurferOptions(waveformRef.current);
      wavesurfer.current = WaveSurfer.create(options);

      // console.log('audio src ===> ', src);

      // wavesurfer.current.load(proxyUrl + src);
       const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
      // console.log(src)
      // fetch(src)
      //   .then(response => response.blob())
      //   .then(blob => {
      //     // Load the audio from the blob into WaveSurfer
      //     const blobUrl = URL.createObjectURL(blob);
      //     wavesurfer.current.load(blobUrl);
      //     console.log(blob)
      //   })
      //   .catch(error => console.error('Error fetching audio:', error));


      fetch(proxyUrl + src)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then(blob => {
          const blobUrl = URL.createObjectURL(blob);
          wavesurfer.current.load(blobUrl);
        })
        .catch(error => console.error('Error fetching audio:', error));

      wavesurfer.current.on('ready', () => {
        if (wavesurfer.current) {
          wavesurfer.current.setVolume(volume);
          setVolume(volume);
        }
      });

      wavesurfer.current.on('timeupdate', seconds => {
        if (wavesurfer.current && timelineRef.current) {
          const duration = moment.duration(seconds, 'seconds');
          timelineRef.current.innerHTML = moment
            .utc(duration.asMilliseconds())
            .format('mm:ss');
        }
      });
    }

    return () => (src ? wavesurfer.current.destroy() : null);
  }, [src]);

  const handlePlayPause = () => {
    setPlay(!playing);
    wavesurfer.current.playPause();
  };

  const onVolumeChange = ({ target }) => {
    const newVolume = +target.value;

    if (newVolume) {
      setVolume(newVolume);
      wavesurfer.current.setVolume(newVolume === undefined || 1);
    }
  };

  return src ? (
    <div className="audio-player">
      <button
        className={'audio-player__btn audio-player__btn--'}
        onClick={handlePlayPause}
        type="button">
        {!playing ? (
          <i className="fa-solid fa-play w-4 h-4" />
        ) : (
            <i className="fa-solid fa-pause w-4 h-4" />
          )}
      </button>
      <div className="audio-player__wave" id="waveform" ref={waveformRef} />
      <div className="audio-player__right">
        <span className="audio-player__timeline" ref={timelineRef}>
          00:00
        </span>
        <div className="audio-player__volume">
          <button className="audio-player__volume-btn">
            <i className="fa-solid fa-volume-high"></i>
          </button>
          <input
            className="audio-player__volume-range"
            type="range"
            id="volume"
            name="volume"
            min="0.01"
            max="1"
            step=".025"
            onChange={onVolumeChange}
            defaultValue={volume}
          />
        </div>
      </div>
    </div>
  ) : null;
};

export default AudioPlayer;
