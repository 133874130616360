import React, {Fragment, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Disclosure, Menu, Popover, Dialog, Transition} from '@headlessui/react';
// import {MagnifyingGlassIcon} from '@heroicons/react/20/solid';
// import LogoSVG from '../../../../../components/reusable/assets/logo/LogoSVG.jsx';
// import Logo from '../../../../reusable/assets/logo/Logo.jsx';
import {
  Bars3Icon,
  PlusCircleIcon,
  BellIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import {useBuzzmiAuth} from '../../../../../appState/hooks/useBuzzmiAuth/index.web.js';

import {
  closeModal,
  // openModal,
} from '../../../../../store/slices/modalSlice/modalSlice';
import {removeData} from '../../../../../res/asyncStorageHelper.web';
import {Link, useLinkTo, useNavigation} from '@react-navigation/native';
import Strings from '../../../../../string_key/Strings.web.js';
import MoreDropDown, {MoreItems} from '../MoreDropDown/index.jsx';
import NotificationsModal from '../../../../../container/App/Profile/ProfileMain/componentsV2/NotificationsModal';
import LogoSvg from './LogoSVG.jsx';
// import LogoSvgMob from './LogoSVGMob.jsx';
import {
  profileSideBarNavAtom,
  socialDashboardNavAtom,
} from '../../../../../appState/navState.js';
import {useAtom} from 'jotai';
// import useWidthListener from '../../../../../hooks/useWidthListner..js';
import {
  QAItems,
  QAOtherItems,
} from '../../../../../appState/quickAccessState.js';
import CreatePostModal from '../../../../../container/App/Profile/ProfileMain/componentsV2/CreatePostModal/index.jsx';
import {
  _userFcmToken,
  _userJustSignupAtom,
} from '../../../../../appState/userState.js';
import {useDeleteFcmToken} from '../../../../../appState/hooks/useDeleteFcmToken/index.js';

global.assignMenu = 'Social';
const hub_items = [
  {
    id: 1,
    iconSrc: '/images/layout/main-layout/home.svg',
    activeIconSrc: '/images/layout/main-layout/active-link/home.svg',
    name: Strings.home,
    to: '/hub/v2/social',
    showOnMobile: true,
  },
  {
    id: 2,
    iconSrc: '/images/layout/main-layout/hub.svg',
    activeIconSrc: '/images/layout/main-layout/active-link/hub.svg',
    name: Strings.hub,
    to: '/hub/v2/home',
    showOnMobile: true,
  },
  // {
  //   id: 3,
  //   iconSrc: '/images/layout/main-layout/messages.svg',
  //   activeIconSrc: '/images/layout/main-layout/active-link/messages.svg',
  //   name: Strings.messages,
  //   to: '/messages',
  // },
  // {
  //   id: 4,
  //   iconSrc: '/images/layout/main-layout/space.svg',
  //   activeIconSrc: '/images/layout/main-layout/active-link/space.svg',
  //   name: Strings.space,
  //   to: '/space',
  //   showOnMobile: true,
  // },
];
// s
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Header = ({collapsed, onMenuClick}) => {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(Strings.home);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [activeSideItem, setActiveSideItem] = useAtom(profileSideBarNavAtom);
  const [activeSocialSideItem, setActiveSocialSideItem] = useAtom(
    socialDashboardNavAtom,
  );
  const [userFcmToken, setUserFcmToken] = useAtom(_userFcmToken);

  const cancelButtonRef = useRef(null);
  const {userData, logout} = useBuzzmiAuth();
  const dispatch = useDispatch();
  const {replace} = useNavigation();
  const [qaItems, setQa] = useAtom(QAItems);
  const [justSignUP, setJustSignUP] = useAtom(_userJustSignupAtom);
  const [qaoItems, setOthQa] = useAtom(QAOtherItems);
  const [openPostModal, setOpenPostModal] = useState(false);
  const {deleteFcm} = useDeleteFcmToken();
  const _logout = () => {
    console.log('logging out here', userFcmToken);
    dispatch(closeModal());
    if (userFcmToken) {
      deleteFcm(
        {
          body: {fcmToken: userFcmToken},
          token: userData.token,
        },

        x => console.log('response on deletion', x),
      );
    }

    setTimeout(() => {
      removeData('userDetails');
      removeData('company_id');
      setQa([]);
      setOthQa([]);
      setJustSignUP(false);
      logout();

      setTimeout(() => {
        window.location.reload();
        replace('Auth');
      }, 150);
    }, 2000);
  };
  const handleLogout = () => {
    _logout();
    // dispatch(
    //   openModal({
    //     modalName: 'renderAlert',
    //     modalType: 'alert',

    //     modalProps: {
    //       nameAlert: 'LogoutAlert',
    //       propsAlert: {
    //         logoutCallback: _logout,
    //         closeModal: () => dispatch(closeModal()),
    //       },
    //     },
    //   }),
    // );
    // console.log('logged ouet');
  };

  const linkTo = useLinkTo();
  const onHandelClick = nav => {
    if (
      nav.to.includes('messages') ||
      nav.to.includes('space') ||
      nav.to.includes(Strings.subscription.toLowerCase()) ||
      nav.to.includes(Strings.challenges.toLowerCase()) ||
      nav.to.includes(Strings.calendar.toLowerCase()) ||
      nav.to.includes(Strings.wallet.toLowerCase())
    ) {
      // console.log('in ture');
      return;
    }
    // console.log('in fast');

    global.assignMenu = nav.name;

    global.activeMenu = nav.to;

    linkTo(nav?.to);
    setActive(nav.name);
    setActiveSideItem('Posts');
    setActiveSocialSideItem('Posts');
  };

  return (
    <>
      <Disclosure
        as="nav"
        className={`bg-white shadow fixed top-0 w-100  z-10 left-0`}>
        {({open}) => (
          <>
            <div className="mx-auto px-4 sm:px-6 lg:px-8 py-2">
              <div className="relative flex h-12 justify-start">
                <div className="absolute inset-y-0 z-10 right-0 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button
                    onClick={onMenuClick(open)}
                    className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-pink-500">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-shrink-0 items-center">
                  <div className="main-layout__logo mb-0 main-logo-custom">
                    <div className="logo">
                      <div>
                        <LogoSvg />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-1 items-center justify-start lg:ml-56 ml-8 sm:items-stretch">
                  <div className="hidden sm:ml-6 sm:flex items-center sm:space-x-8">
                    {/* Current: "border-pink-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                    {hub_items.map((nav, index) => (
                      <a
                        key={index}
                        onClick={() => onHandelClick(nav)}
                        className={`inline-flex cursor-pointer items-center border-pink-500 px-1 pt-1 `}>
                        <img
                          className={
                            nav?.name == global.assignMenu
                              ? 'menu-item__icon_active'
                              : 'menu-item__icon'
                          }
                          src={
                            nav?.name == global.assignMenu
                              ? nav.activeIconSrc
                              : nav.iconSrc
                          }
                          alt={
                            nav?.name == global.assignMenu
                              ? ' menu-icon_active'
                              : 'menu-icon'
                          }
                        />

                        <span
                          className={`inline-block pl-2 text-sm font-medium text-gray-900 ${
                            nav?.name == global.assignMenu
                              ? 'text-fuchsia-500'
                              : ''
                          }`}>
                          {nav.name}
                        </span>
                      </a>
                    ))}
                    {/* <MoreDropDown /> */}
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-14 sm:pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <Menu as="div" className="relative sm:ml-5 ml-2 mr-2">
                    <div>
                      <Menu.Button
                        className={`sm:hidden relative flex rounded-full bg-white text-sm text-[#aaa]`}>
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Add Actions</span>
                        <PlusCircleIcon
                          className="sm:h-10 h-8 sm:w-10 w-8"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95">
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({active}) => (
                            <a
                              onClick={() => {
                                setActiveSocialSideItem('Posts'),
                                  setOpenPostModal(!openPostModal);
                              }}
                              className={classNames(
                                active ? 'bg-gray-100 cursor-pointer' : '',
                                'block px-4 py-2 text-sm text-gray-700',
                              )}>
                              <div className={'flex'}>
                                <img
                                  className="h-5-6 w-6 mr-[16px]"
                                  src={'/images/social/add_post_gray.png'}
                                  alt=""
                                />
                                <p className={'self-center'}>Add New Post</p>
                              </div>
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({active}) => (
                            <a
                              onClick={() => {
                                setActiveSocialSideItem('Reel'),
                                  setOpenPostModal(!openPostModal);
                              }}
                              className={classNames(
                                active ? 'bg-gray-100 cursor-pointer' : '',
                                'block px-4 py-2 text-sm text-gray-700',
                              )}>
                              <div className={'flex'}>
                                <img
                                  className="h-5-6 w-6 mr-[16px]"
                                  src={'/images/social/add_reel_gray.png'}
                                  alt=""
                                />
                                <p className={'self-center'}>Add New Reel</p>
                              </div>
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({active}) => (
                            <a
                              onClick={() => {}}
                              className={classNames(
                                active ? 'bg-gray-100 cursor-pointer' : '',
                                'block px-4 py-2 text-sm text-gray-700',
                              )}>
                              <div className={'flex'}>
                                <img
                                  className="h-5-6 w-6 mr-[16px]"
                                  src={'/images/social/add_story_gray.png'}
                                  alt=""
                                />
                                <p className={'self-center'}>Add New Story</p>
                              </div>
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({active}) => (
                            <a
                              onClick={() => {}}
                              className={classNames(
                                active ? 'bg-gray-100 cursor-pointer' : '',
                                'block px-4 py-2 text-sm text-gray-700',
                              )}>
                              <div className={'flex'}>
                                <img
                                  className="h-5-6 w-6 mr-[16px]"
                                  src={'/images/social/add_live_gray.png'}
                                  alt=""
                                />
                                <p className={'self-center'}>Add Live</p>
                              </div>
                            </a>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>

                  <button
                    onClick={() => setOpenNotifications(true)}
                    type="button"
                    className=" mr-2 relative rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2">
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">View notifications</span>
                    <BellIcon
                      className="sm:h-10 h-8 sm:w-10 w-8"
                      aria-hidden="true"
                    />
                  </button>

                  {/* Profile dropdown */}
                  <Menu as="div" className="relative sm:ml-5 ml-2">
                    <div>
                      <Menu.Button
                        className={`relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-offset-2 ${
                          global.assignMenu == 'Profile'
                            ? 'border-[#bf28ca] border-[3px]'
                            : ''
                        }`}>
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-10 w-10 rounded-full"
                          src={
                            userData?.avatar
                              ? userData?.avatar
                              : '/images/moc/avatar-big.png'
                          }
                          alt=""
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95">
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({active}) => (
                            <a
                              onClick={() => {
                                setActiveSocialSideItem('');
                                linkTo('/profile/v2');
                                global.assignMenu = 'Profile';
                              }}
                              className={classNames(
                                active ? 'bg-gray-100 cursor-pointer' : '',
                                'block px-4 py-2 text-sm text-gray-700',
                              )}>
                              Your Profile
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({active}) => (
                            <a
                              onClick={() => {
                                setActiveSocialSideItem('');
                                linkTo('/profile/bookmark-lists');
                                global.assignMenu = 'Saved';
                              }}
                              className={classNames(
                                active ? 'bg-gray-100 cursor-pointer' : '',
                                'block px-4 py-2 text-sm text-gray-700',
                              )}>
                              Saved
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({active}) => (
                            <a
                              // href="#"
                              onClick={e => {
                                setOpen(() => true);
                              }}
                              className={classNames(
                                active ? 'bg-gray-100 cursor-pointer' : '',
                                'block px-4 py-2 text-sm text-gray-700',
                              )}>
                              Sign out
                            </a>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 pb-4 pt-2">
                {[...hub_items].map((it, ind) => (
                  <Disclosure.Button
                    key={ind}
                    onClick={() => onHandelClick(it)}
                    className={`w-full relative flex items-center    pl-3 pr-4  rounded-lg mx-0.5 `}>
                    <div
                      className={`${
                        it?.name == global.assignMenu
                          ? 'bg-gradiant-800'
                          : 'bg-transparent'
                      }  w-full relative flex items-center gap-x-6  py-2 pl-3 pr-4  rounded-lg mx-0.5 `}>
                      <img
                        className={'menu-item__icon'}
                        src={it.iconSrc}
                        alt={''}
                      />
                      <div
                        as="a"
                        href="#"
                        className={`text-base font-medium ${
                          it?.name == global.assignMenu
                            ? 'text-white'
                            : 'text-gray-500'
                        } hover:text-gray-700`}>
                        {it.name}
                      </div>
                    </div>
                  </Disclosure.Button>
                  // <Disclosure.Button
                  //   key={it.name}
                  //   className="group relative flex items-center gap-x-6 rounded-lg p-2 hover:bg-gray-50 cursor-pointer bg-gradiant-800">
                  //   <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                  //     <img
                  //       className="menu-item__icon"
                  //       src={it.iconSrc}
                  //       alt={'menu-icon'}
                  //     />
                  //   </div>
                  //   <div>
                  //     <a className="font-semibold text-gray-900">{it.name}</a>
                  //   </div>
                  // </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div> */}
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900">
                        Are you sure you want to logout?
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Logging out will end your current session. You'll need
                          to sign in again to access your account. If you have
                          any unsaved work, please make sure to save it before
                          logging out.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={() => {
                        handleLogout();
                        setOpen(false);
                      }}>
                      Logout
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}>
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <NotificationsModal
        open={openNotifications}
        setOpen={setOpenNotifications}
      />
      <CreatePostModal
        open={openPostModal}
        setOpen={setOpenPostModal}
        post={null}
        is_edit={false}
      />
    </>
  );
};

export default Header;
