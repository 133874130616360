import React from "react"
import PropTypes from "prop-types"
import GradientBorder from "../gradientBorder/GradientBorder"
import cn from "classnames"

const LineProgressBar = ({ progress, className = "" }) => {
  return (
    <GradientBorder
      padding="1px"
      className={cn("line-progress", {
        "line-progress_secondary": progress === 0,
      })}
    >
      <div className={"line-progress__wrapper " + className}>
        <div className="line-progress__fill" style={{ width: `${progress}%` }}>
          <div className="line-progress__percentage">{progress}% completed</div>
        </div>
      </div>
    </GradientBorder>
  )
}

LineProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  className: PropTypes.string,
}

export default LineProgressBar
