import React, { useEffect, useState } from 'react';

import cn from 'classnames';
import CustomRating from '../../../../components/reusable/assets/customRating/CustomRating';
import Verified from '../../../../components/reusable/bio/verified/Verified';
import { useBuzzmiAuth, useProfileApp } from '../../../../appState/hooks';
import { useNavigation } from '@react-navigation/native';
import { useDispatch } from 'react-redux';
import { useProfileInfo } from '../../../../appState/hooks/useProfileInfo';
import SimpleShowMore from '../../../../components/reusable/assets/showMore/simpleShowMore/SimpleShowMore';
import { useVisitorRankInfo } from '../../../../appState/hooks/useVisitorRankInfo';
import { useLinkTo } from '@react-navigation/native';
import { useUserRatings } from '../../../../appState/hooks/useUserRatings';

const VisitorProfilebio = () => {
  const { logout, userData } = useBuzzmiAuth();
  const { profileInfo } = useProfileInfo();
  const { visitorRankInfo } = useVisitorRankInfo();

  const dispatch = useDispatch();
  const [user, setUser] = useState({});
  const linkTo = useLinkTo();
  const { userRating } = useUserRatings();
  const [ProfileRattings, setProfileRating] = useState(null)

  useEffect(() => {
    setProfileRating(userRating?.averageRating ? (userRating?.averageRating / userRating?.totalReviews) : 0)
  }, [userRating])

  return (
    <div className="ml-3 mt-3">
      <div className="plan-bio__info profile-top__bio gap-0">

        <div className="h3">
          <div className="profile_name">
            <div className="user_title text-base font-semibold">
              {profileInfo && profileInfo?.firstName + ' ' + profileInfo?.lastName}
            </div>
            <Verified isVerified={user?.isVerified} />
            {profileInfo && profileInfo?.tagLine != null && <div className="max-w-[150px] user_title text-base font-semibold line-clamp-1">
              {"| " + profileInfo?.tagLine}
            </div>
            }
          </div>
        </div>

        <div className={"flex items-center justify-between w-full"}>
          <div className={"flex flex-col mr-4"}>

            {visitorRankInfo?.currentRank &&
              <div className="profile-achievement-icon my-[8px]">
                <img className={"!w-8 !h-8"} src={visitorRankInfo?.currentRankBadge} alt={visitorRankInfo?.currentRankBadge} />
                <div className={"text-lg font-medium"}>{visitorRankInfo?.currentRank}</div>
              </div>
            }
          </div>

        </div>

        <div className="profile-top__row">
          <span className="text-base">
            @{profileInfo && profileInfo?.displayName}
          </span>
          <div onClick={() => { linkTo(`/profile/user-ratting/visitor`) }} className="d-flex align-items-center flex-direction-column cursor-pointer">
            <CustomRating initialValue={ProfileRattings} readonly />
            <span
              className={cn('text-r ms-2 mt-1', {
                'text-secondary': ProfileRattings === 0,
              })}>
              {userRating?.totalReviews || 0} reviews
            </span>
          </div>
        </div>

        {profileInfo?.bio != null &&
          <div className="profile-user__info-block mt-2 mt-md-4">
            <SimpleShowMore
              content={profileInfo?.bio || ''}
              lineClamp={2}
              sizes={{
                desktopHeight: 40,
                mobileHeight: 40,
              }}
            />
          </div>
        }
      </div>
    </div>
  );
};

export default VisitorProfilebio;
