import React from "react"

const TagLink = ({ text, link }) => {
  return (
    <a href={link} className="content-post__text content-post__tag-link">
      #{text}
    </a>
  )
}

export default TagLink
