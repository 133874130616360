import React, {useEffect, useState} from 'react';

import ValidationSchema from '../../../../form-validator/ValidationSchema';
import moment from 'moment';

import EditProfileForm from './items/EditProfileForm';
import EditProfileFormV2 from './items/EditProfileFormV2';

import MainLayoutContent from '../../../../components/layouts/mainLayout/mainLayoutContent/MainLayoutContent';
import BackBtnWithTitlePage from '../../../../components/reusable/btns/backBtn/BackBtnWithTitlePage';
import {useFormValidator} from '../../../../form-validator/hooks';
import MainLayout from '../../../../components/layouts/mainLayout/MainLayout';
import {useBuzzmiAuth} from '../../../../appState/hooks/useBuzzmiAuth/index.web';
import {useProfileApp} from '../../../../appState/hooks';
import {useHubApp} from '../../../../appState/hooks/useHubApp/index.web';
import Loading from 'react-fullscreen-loading';
import {colors} from '../../../../res/colors';
import {useNavigation} from '@react-navigation/core';
import {useToast} from 'react-native-toast-notifications';
import {ShowSuccess} from '../../../HOC/MessageWeb';
import {useIsFocused} from '@react-navigation/native';
import MainLayoutV2 from '../../../../components/layouts/mainLayout/MainLayoutV2';

const validationSchema = new ValidationSchema({
  'full-name': [
    {rule: 'minLength', value: 3},
    {rule: 'maxLength', value: 100},
  ],
  'tagLine': [
    { rule: 'minLength', value: 3 },
    { rule: 'maxLength', value: 50 },
  ],
  'user-name': [
    {rule: 'minLength', value: 3},
    {rule: 'maxLength', value: 100},
  ],
  bio: [{rule: 'maxLength', value: 1000}],
  height: [{rule: 'maxValue', value: 500}],
  weight: [{rule: 'maxValue', value: 500}],
});

const data = {};

const EditProfile = () => {
  const [error, setError] = useState(null);
  const [getFormData, setFormData] = useState(null);
  const toast = useToast();
  const {navigate, goBack} = useNavigation();
  const isFocused = useIsFocused();
  const {userData, updateUserData} = useBuzzmiAuth();

  const {
    isEditProfileLoading,
    EditProfileResponse,
    EditProfileErrorResponse,
    patchBaseEditProfile,
    getUserPostLists,
  } = useProfileApp();

  const {uploadedAvatarLink} = useHubApp();

  useEffect(() => {
    console.log(isFocused);
  }, [isFocused]);

  useEffect(() => {
    if (EditProfileResponse) {
      if (getFormData) {
        const updateUser = {
          ...userData,
          avatar: getFormData?.avatar,
          bio: getFormData?.bio,
          dob: getFormData?.dob,
          firstName: getFormData?.firstName,
          college: getFormData?.college,
          gender: getFormData?.gender,
          height: getFormData?.height,
          lastName: getFormData?.lastName,
          university: getFormData?.university,
          weight: getFormData?.weight,
          skill: getFormData?.skill,
          tagLine: getFormData?.tagLine,
          workOrganization: getFormData?.workOrganization,
          displayName: getFormData?.displayName,
          bioLink: getFormData?.bioLink[0],
          isFacebookConnect: getFormData?.isFacebookConnect,
          facebookLink: getFormData?.facebookLink,
          isYoutubeConnect: getFormData?.isYoutubeConnect,
          youtubeLink: getFormData?.youtubeLink,
          isTwitterConnect: getFormData?.isTwitterConnect,
          twitterLink: getFormData?.twitterLink,
          isLinkedInConnect: getFormData?.isLinkedInConnect,
          linkedInLink: getFormData?.linkedInLink,
        };
        updateUserData(updateUser);
        getUserPostLists(userData.token, userData?.id, {page: 1, limit: 20});
        goBack();
        setTimeout(() => {
          ShowSuccess(EditProfileResponse?.message, toast);
        }, 350);
      }
    }
  }, [EditProfileResponse, getFormData]);

  //TODO example function to send request
  const sendRequest = async formData => {
    let link_data = [formData?.bioLink]
    try {
      const regex = /(<([^>]+)>)/gi;
      let param_data = Object.assign({
        firstName: formData['full-name'].split(' ')[0],
        lastName: formData['full-name'].split(' ')[1],
        avatar: uploadedAvatarLink || userData?.avatar,
        displayName: formData['user-name'],
        bioLink: link_data,
        workOrganization: formData.organization,
        height: formData.height == 0 ? '' : formData.height,
        weight: formData.weight == 0 ? '' : formData.weight,
        college: formData.college,
        skill: formData.skills,
        tagLine: formData?.tagLine,
        bio: formData.bio.replace(regex, ''),
        university: formData.university,
        dob:
          global.EBirthday && global.EBirthday != undefined
            ? global.EBirthday
            : moment(userData?.dob).format('MM/DD/YYYY'),
        gender: global?.EGender?.value == 'man' ? 'M' : 'F',

        isFacebookConnect: formData.socialLink?.facebook != null ? true : false,
        facebookLink:
          formData.socialLink?.facebook != null
            ? formData.socialLink?.facebook
            : null,
        isYoutubeConnect: formData.socialLink?.youtube != null ? true : false,
        youtubeLink:
          formData.socialLink?.youtube != null
            ? formData.socialLink?.youtube
            : null,
        isTwitterConnect: formData.socialLink?.twitter != null ? true : false,
        twitterLink:
          formData.socialLink?.twitter != null
            ? formData.socialLink?.twitter
            : null,
        isLinkedInConnect: formData.sociaLink?.linkedin != null ? true : false,
        linkedInLink:
          formData.socialLink?.linkedin != null
            ? formData.socialLink?.linkedin
            : null,
      });
      setFormData(param_data);
      patchBaseEditProfile(userData?.id, param_data);
    } catch (e) {
      setError(e);
    }
  };

  const {handleSubmit, setValue, values, isValid, register} = useFormValidator(
    validationSchema,
    sendRequest,
    {
      showErrorsOnSubmit: false,
      defaultValues: {
        skills: userData?.skill || '',
        avatar: userData?.avatar
          ? userData?.avatar
          : '/images/moc/avatar-big.png',
        tags: [],
      },
    },
  );

  return (
    <MainLayoutV2>
      <MainLayoutContent>
        <Loading
          loading={isEditProfileLoading}
          background="transparent"
          loaderColor={colors.PRIMARY_COLOR}
        />
        <BackBtnWithTitlePage
          title="Edit profile"
          className="mt-0"
          btnText="Back"
        />
        {/* <EditProfileForm
          error={error}
          handleSubmit={handleSubmit}
          setValue={setValue}
          values={values}
          isValid={isValid}
          register={register}
          data={data}
          title="Add task"
        /> */}
        <EditProfileFormV2
          error={error}
          handleSubmit={handleSubmit}
          setValue={setValue}
          values={values}
          isValid={isValid}
          register={register}
          data={data}
          title="Add task"
        />
      </MainLayoutContent>
    </MainLayoutV2>
  );
};

export default EditProfile;
