import React, {Suspense, lazy} from 'react';
import SupportBtn from '../../../reusable/btns/supportBtn/SupportBtn.jsx';
import Tooltip from '../../../reusable/assets/tooltip/Tooltip.jsx';
import LayoutConstants from './mocData.web';
import Loading from 'react-fullscreen-loading';
import {colors} from '../../../../res/colors.js';

const Slider = lazy(() => import('../../../reusable/slider/index')); // Lazy-loaded

const DoubleLayoutImage = ({reverse, imageUrl}) => {
  const layoutData = LayoutConstants();

  return (
    <div
      className={`double-layout__intro ${
        imageUrl ? 'double-layout__intro_bg' : ''
      }`}>
      <Tooltip
        position={!reverse ? 'left' : 'right'}
        tooltipText={'Any problems? Report Please  '}>
        <SupportBtn />
      </Tooltip>
      {imageUrl ? (
        <div className="double-layout__bg-wrapper">
          <img src={imageUrl} alt="bg" />
        </div>
      ) : (
        <Suspense
          fallback={
            <Loading
              loading={true}
              background="transparent"
              loaderColor={colors.PRIMARY_COLOR}
            />
          }>
          <Slider components={layoutData?.layoutData} interval={5000} />
        </Suspense>
      )}
    </div>
  );
};

export default DoubleLayoutImage;
