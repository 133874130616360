
export const ShowError = (message, toast) => {
    toast.show(message, {
        type: "danger",
        placement: "bottom",
        duration: 2000,
        offset: 30,
        animationType: "slide-in",
    })
};


export const ShowSuccess = (message, toast) => {
    toast.show(message, {
        type: "success",
        placement: "bottom",
        duration: 2000,
        offset: 30,
        animationType: "slide-in",
    })
};
