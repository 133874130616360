import {useAtom} from 'jotai';
import {userNotificationsList} from '../hubState';
import {getNotifications} from '../../../network/api/app';

export const useGetNotifications = () => {
  const [notificationsList, setNotificationsList] = useAtom(
    userNotificationsList,
  );

  const getNotificationsList = async (token, setLoading, params) => {
    setLoading(true);
    try {
      const response = await getNotifications(params)({token});

      setNotificationsList(response);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log('error => ', e.message);
    }
  };

  return {
    notificationsList,
    getNotificationsList,
    setNotificationsList,
  };
};
