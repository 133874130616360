import React, {useState} from 'react';
import Badge from '../../../../badge/Badge.jsx';
import InfoCardMenu from '../infoCardMenu/InfoCardMenu.jsx';
import {useSwipeable} from 'react-swipeable';
import Strings from '../../../../../../string_key/Strings.web';

const InfoCardMain = ({
  noMenu = false,
  image,
  badges,
  info,
  type,
  id,
  item,
  taskType,
}) => {
  const [showMenu, setShowMenu] = useState(false);

  const swipeLeft = () => {
    setShowMenu(true);

    //create handler if click outside show-menu area then setShowMenu(false)
    window.addEventListener('click', function (e) {
      if (!e.target.closest('.show-menu')) {
        setShowMenu(false);
      }
    });

    //create this for swipe another card
    window.addEventListener('touchstart', function (e) {
      if (!e.target.closest('.show-menu')) {
        setShowMenu(false);
      }
    });
  };

  const handlers = useSwipeable({
    onSwipedLeft: eventData => swipeLeft(),
    onSwipedRight: eventData => setShowMenu(false),
  });

  const handlersState = type === 'menu' ? handlers : null;

  return (
    <div
      {...handlersState}
      className={`d-flex info-card ${showMenu ? 'show-menu' : ''}`}>
      {image && (
        <div className="me-2">
          <img
            className="info-card__image"
            src={
              image?.includes('http')
                ? image
                : 'https://onesay-backend-dev.s3.amazonaws.com/4bc1d577-1b3d-43ba-ad9b-86c29780c99f-drink.png'
            }
            alt={image?.alt}
          />
        </div>
      )}
      <div
        className={`d-flex info-card__content flex-column ${
          !badges?.length > 0 ? 'justify-content-center' : ''
        }`}>
        {console.log('badges', badges)}
        {/* <div className={'info-card__badges'}>
          {badges?.length > 0 &&
            badges?.map((badge, index) => (
              <span className={'me-2'} key={index + `${badge.type}`}>
                <Badge type={badge.type} />
              </span>
            ))}
        </div> */}
        <div>
          <div className={'h4 my-2'}>{info?.title}</div>
          <div className={'info-card__text text-r'}>{info?.text}</div>
        </div>
      </div>
      {type !== null && (
        <>
          {!noMenu && type === 'menu' && (
            <InfoCardMenu
              showMenu={showMenu}
              setShowMenu={setShowMenu}
              id={id}
              taskType={taskType}
              item={item}
            />
          )}
          {type === 'link' && (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img
                src="/images/assets/info-card/arrow-r.svg"
                alt="arrow-right"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default InfoCardMain;
