import React, { useEffect, useState } from 'react';
import { useBuzzmiAuth } from '../../../../../appState/hooks/useBuzzmiAuth/index.web.js';
import Box from '../../../../../components/reusable/cards/box/Box.jsx';

const WalletEarning = props => {
    const { userData } = useBuzzmiAuth();

    return (
        <>
            <div>
                <h2 className='text-lg sm:text-[20px] font-bold mt-6 mb-1.5 text-center' style={{ color: "#EB4DAC" }}>Welcome, {userData?.firstName + ' ' + userData?.lastName}</h2>
                <p className='text-xs sm:text-[14px]  text-center'>Explore and claim rewards!</p>
            </div>

            <div className='flex justify-between items-center mt-6 mb-[9px]'>
                <div>
                    <h6 className='text-[13px] sm:text-[16px] font-bold'>Daily Projected Earnings</h6>
                </div>
                <div className='flex'>
                    <img src="/images/diamonds.png" class="w-6 sm:w-8 sm:h-8 h-6 mr-4"></img>
                    <img src="/images/buzz_coin.png" class="w-6 sm:w-8 sm:h-8 h-6 mr-4"></img>
                    <img src="/images/gems.png" class="w-6 h-6 sm:w-8 sm:h-8"></img>
                </div>
            </div>

            <div>
                <Box className={"p-4"}>
                    <div>
                        <h5 className='text-xs sm:text-[14px]'>Daily Login Streak</h5>
                    </div>
                    <div class="border border-solid my-4 border-slate-100"></div>
                    <div>
                        <h5 className='text-xs sm:text-[14px]'>Engagement Earnings</h5>
                    </div>
                    <div class="border border-solid my-4 border-slate-100"></div>
                    <div>
                        <h5 className='text-xs sm:text-[14px]'>Invitation Earnings</h5>
                    </div>
                    <div class="border border-solid my-4 border-slate-100"></div>
                    <div>
                        <h5 className='text-xs sm:text-[14px] font-bold'>Today's Total Earning</h5>
                    </div>
                </Box>


                <div>
                    <h6 className='text-[13px] sm:text-[16px] font-bold text-center mt-6 mb-[9px]'>Daily Projected Score</h6>
                </div>

                <Box className={"p-4"}>
                    <div>
                        <h5 className='text-xs sm:text-[14px]'>Engagement Score</h5>
                    </div>
                    <div class="border border-solid my-4 border-slate-100"></div>
                    <div>
                        <h5 className='text-xs sm:text-[14px]'>Pro Creator Score</h5>
                    </div>
                    <div class="border border-solid my-4 border-slate-100"></div>
                    <div>
                        <h5 className='text-xs sm:text-[14px]'>Network Score</h5>
                    </div>

                </Box>
            </div>

            <div>
                <div className='flex items-center p-2.5 mt-3.5'>
                    <div>
                        <img src="/images/ic_buzzmi_target.png" class="w-[50px] mr-2"></img>
                    </div>
                    <div>
                        <h2 className='text-lg font-bold' style={{ color: "#EB4DAC" }}>Premium-Member Earning</h2>
                    </div>
                </div>

                <div className='grid grid-cols-2 gap-2'>
                    <Box className={"px-2.5 w-[156px] h-[176px] rounded-md border border-solid"} style={{ borderColor: "#ECF" }}>
                        <div className='relative'>
                            <div className='relative top-[-8px] flex justify-center'>
                                <img src={"/images/1x.png"} className='w-8 h-8 ms-1' />
                            </div>
                            <div className='mt-3'>
                                <h3 className='text-[15px] font-bold text-center'>Free User</h3>
                            </div>

                            <div className='text-center w-[108px] m-auto'>
                                <div className='py-[8px] px-[20px] relative bottom-[-24px] text-[10px] ' style={{ borderTopRightRadius: 10, borderTopLeftRadius: 10, backgroundColor: "#ECF" }}>
                                    <div className='flex justify-center items-center mb-2'>
                                        <h5>200</h5>
                                        <img src={"/images/diamonds.png"} className='w-6 h-6 ml-1' />
                                    </div>

                                    <div className='flex justify-center items-center'>
                                        <h5>1000</h5>
                                        <img src={"/images/buzz_coin.png"} className='w-6 h-6 ml-1' />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Box>


                    <Box className={"px-2.5 w-[156px] h-[176px] rounded-md"} style={{ borderColor: "#ECF", background: "linear-gradient(#FF41B3, #7E00E0)" }}>
                        <div className='relative'>
                            <div className='relative top-[-8px] flex justify-center'>
                                <img src={"/images/2x.png"} className='w-8 h-8 ms-1' />
                            </div>
                            <div className='mt-3'>
                                <h3 className='text-[15px] font-bold text-center'>Premium Member</h3>
                            </div>

                            <div className='text-center w-[108px] m-auto'>
                                <div className='py-[8px] px-[20px] relative bottom-[-5px] text-[10px] ' style={{ borderTopRightRadius: 10, borderTopLeftRadius: 10, backgroundColor: "#ECF" }}>
                                    <div className='flex justify-center items-center mb-2'>
                                        <h5>400</h5>
                                        <img src={"/images/diamonds.png"} className='w-6 h-6 ml-1' />
                                    </div>

                                    <div className='flex justify-center items-center'>
                                        <h5>20000</h5>
                                        <img src={"/images/buzz_coin.png"} className='w-6 h-6 ml-1' />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Box>

                </div>

                <div className='flex justify-center mt-5'>
                    <button style={{ backgroundColor: "#FF41B3", color: "#fff", fontSize: 16, fontWeight: 800, borderRadius: 30 }} className='py-3.5 pl-7 w-full max-w-md'>
                        Become a premium member
                    </button>
                </div>
            </div>


            <div>
                <div className='flex items-center p-2.5 mt-3.5'>
                    <div>
                        <img src="/images/ic_big_star.png" class="w-[50px] mr-2"></img>
                    </div>
                    <div>
                        <h2 className='text-lg font-bold' style={{ color: "#EB4DAC" }}>Elite Creator Earning</h2>
                    </div>
                </div>

                <div className='grid grid-cols-2 gap-2'>
                    <Box className={"px-2.5 w-[156px] h-[176px] rounded-md border border-solid"} style={{ borderColor: "#ECF" }}>
                        <div className='relative'>
                            <div className='relative top-[-8px] flex justify-center'>
                                <img src={"/images/1x.png"} className='w-8 h-8 ms-1' />
                            </div>
                            <div className='mt-3'>
                                <h3 className='text-[15px] font-bold text-center'>Free User</h3>
                            </div>

                            <div className='text-center w-[108px] m-auto'>
                                <div className='py-[8px] px-[20px] relative bottom-[-24px] text-[10px] ' style={{ borderTopRightRadius: 10, borderTopLeftRadius: 10, backgroundColor: "#ECF" }}>
                                    <div className='flex justify-center items-center mb-2'>
                                        <h5>200</h5>
                                        <img src={"/images/diamonds.png"} className='w-6 h-6 ml-1' />
                                    </div>

                                    <div className='flex justify-center items-center'>
                                        <h5>1000</h5>
                                        <img src={"/images/buzz_coin.png"} className='w-6 h-6 ml-1' />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Box>


                    <Box className={"px-2.5 w-[156px] h-[176px] rounded-md"} style={{ borderColor: "#ECF", background: "linear-gradient(#FF41B3, #7E00E0)" }}>
                        <div className='relative'>
                            <div className='relative top-[-8px] flex justify-center'>
                                <img src={"/images/2x.png"} className='w-8 h-8 ms-1' />
                            </div>
                            <div className='mt-3'>
                                <h3 className='text-[15px] font-bold text-center'>Premium Member</h3>
                            </div>

                            <div className='text-center w-[108px] m-auto'>
                                <div className='py-[8px] px-[20px] relative bottom-[-5px] text-[10px] ' style={{ borderTopRightRadius: 10, borderTopLeftRadius: 10, backgroundColor: "#ECF" }}>
                                    <div className='flex justify-center items-center mb-2'>
                                        <h5>400</h5>
                                        <img src={"/images/diamonds.png"} className='w-6 h-6 ml-1' />
                                    </div>

                                    <div className='flex justify-center items-center'>
                                        <h5>20000</h5>
                                        <img src={"/images/buzz_coin.png"} className='w-6 h-6 ml-1' />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Box>

                </div>


                  <div className='flex justify-center mt-5'>
                    <button style={{ backgroundColor: "#FF41B3", color: "#fff", fontSize: 16, fontWeight: 800, borderRadius: 30 }} className='py-3.5 pl-7 w-full max-w-md'>
                        Become a elite creator
                    </button>
                </div>
            </div>


            <div>
                <div>
                    <h6 className='text-[13px] font-bold text-center mt-6 mb-[9px]'>Network Level Score</h6>
                </div>

                <Box className={"p-4"}>
                    <div className='flex justify-between items-center'>
                        <h5 className='text-xs sm:text-[14px]'>Level 1</h5>
                        <p className='text-xs sm:text-[14px]'>00000</p>
                    </div>
                    <div class="border border-solid my-4 border-slate-100"></div>
                    <div className='flex justify-between items-center'>
                        <h5 className='text-xs sm:text-[14px]'>Level 2</h5>
                        <p className='text-xs sm:text-[14px]'>00000</p>
                    </div>
                    <div class="border border-solid my-4 border-slate-100"></div>
                    <div className='flex justify-between items-center'>
                        <h5 className='text-xs sm:text-[14px]'>Level 3</h5>
                        <p className='text-xs sm:text-[14px]'>00000</p>
                    </div>
                    <div class="border border-solid my-4 border-slate-100"></div>
                    <div className='flex justify-between items-center'>
                        <h5 className='text-xs sm:text-[14px]'>Level 4</h5>
                        <p className='text-xs sm:text-[14px]'>00000</p>
                    </div>

                    <div class="border border-solid my-4 border-slate-100"></div>
                    <div className='flex justify-between items-center'>
                        <h5 className='text-xs sm:text-[14px]'>Level 5</h5>
                        <p className='text-xs sm:text-[14px]'>00000</p>
                    </div>
                    <div class="border border-solid my-4 border-slate-100"></div>


                    <div className='flex justify-center'>
                        <div className='mr-12'>
                            <button style={{ backgroundColor: "#FF41B3", color: "#fff",  borderRadius: 30 }} className='py-1.5 px-[26px] text-[13px] sm:text-[16px]'>
                                Invite Users
                            </button>
                        </div>
                        <div>
                            <button style={{ backgroundColor: "#FF41B3", color: "#fff",  borderRadius: 30 }} className='py-1.5 px-[26px] text-[13px] sm:text-[16px]'>
                                View Details
                            </button>
                        </div>
                    </div>

                </Box>
            </div>


            <div>
                <div>
                    <h6 className='text-[13px] sm:text-[16px] font-bold text-center mt-6 mb-[9px]'>Your Activities</h6>
                    <p className='text-xs sm:text-[12px] text-center mt-2 mb-6'>Complete the tasks as much as you can and get amazing rewards!</p>
                </div>

                <div className='flex justify-between pt-[7px] pr-0 pb-[7px] pl-[16px] items-center rounded-md mb-2' style={{ background: "rgba(255, 173, 193, 0.50)" }}>
                    <div className='flex items-center'>
                        <img src={"/images/activite1.png"} className='w-[55px] mr-4' />
                        <h5 className='text-sm font-bold'>Personal plan</h5>
                    </div>
                    <div className='py-[7px] px-[20px] rounded-l-lg' style={{ background: "#FFADC1" }}>
                        <p className='text-[10px] sm:text-[12px]' style={{ color: "#584F68" }}>10 completed</p>
                    </div>
                </div>

                <div className='flex justify-between pt-[7px] pr-0 pb-[7px] pl-[16px] items-center rounded-md mb-2' style={{ background: "rgba(185, 240, 255, 0.50)" }}>
                    <div className='flex items-center'>
                        <img src={"/images/activite2.png"} className='w-[55px] mr-4' />
                        <h5 className='text-sm font-bold'>Habits</h5>
                    </div>
                    <div className='py-[7px] px-[20px] rounded-l-lg' style={{ background: "#ADFFB4" }}>
                        <p className='text-[10px] sm:text-[12px]' style={{ color: "#584F68" }}>10 completed</p>
                    </div>
                </div>

                <div className='flex justify-between pt-[7px] pr-0 pb-[7px] pl-[16px] items-center rounded-md mb-2' style={{ background: "rgba(185, 240, 255, 0.60)" }}>
                    <div className='flex items-center'>
                        <img src={"/images/activite3.png"} className='w-[55px] mr-4' />
                        <h5 className='text-sm font-bold'>Workouts</h5>
                    </div>
                    <div className='py-[7px] px-[20px] rounded-l-lg' style={{ background: "#99E4FD" }}>
                        <p className='text-[10px] sm:text-[12px]' style={{ color: "#584F68" }}>10 completed</p>
                    </div>
                </div>

                <div className='flex justify-between pt-[7px] pr-0 pb-[7px] pl-[16px] items-center rounded-md mb-2' style={{ background: "rgba(255, 242, 170, 0.50)" }}>
                    <div className='flex items-center'>
                        <img src={"/images/activite4.png"} className='w-[55px] mr-4' />
                        <h5 className='text-sm font-bold'>Workouts</h5>
                    </div>
                    <div className='py-[7px] px-[20px] rounded-l-lg' style={{ background: "#FFEC85" }}>
                        <p className='text-[10px] sm:text-[12px]' style={{ color: "#584F68" }}>10 completed</p>
                    </div>
                </div>

                <div className='flex justify-between pt-[7px] pr-0 pb-[7px] pl-[16px] items-center rounded-md mb-2' style={{ background: "rgba(193, 211, 255, 0.50)" }}>
                    <div className='flex items-center'>
                        <img src={"/images/activite5.png"} className='w-[55px] mr-4' />
                        <h5 className='text-sm font-bold'>Programs</h5>
                    </div>
                    <div className='py-[7px] px-[20px] rounded-l-lg' style={{ background: "#9AB6FF" }}>
                        <p className='text-[10px] sm:text-[12px]' style={{ color: "#584F68" }}>10 completed</p>
                    </div>
                </div>

                <div className='flex justify-between pt-[7px] pr-0 pb-[7px] pl-[16px] items-center rounded-md mb-2' style={{ background: "#E4CFFF" }}>
                    <div className='flex items-center'>
                        <img src={"/images/activite6.png"} className='w-[55px] mr-4' />
                        <h5 className='text-sm font-bold'>Buzzmi Tasks</h5>
                    </div>
                    <div className='py-[7px] px-[20px] rounded-l-lg' style={{ background: "#E4AFFF" }}>
                        <p className='text-[10px] sm:text-[12px]' style={{ color: "#584F68" }}>10 completed</p>
                    </div>
                </div>

            </div>

        </>
    );
};
export default WalletEarning;