export const fonts = {
  BOLD: 'Montserrat-Bold',
  MEDIUM: 'Montserrat-Medium',
  REGULAR: 'Montserrat-Regular',
  MON_REGULAR: 'Montserrat-Regular',
  MON_BOLD: 'Montserrat-Bold',
  MON_MEDIUM: 'Montserrat-Medium',
  MON_SEMI_BOLD: 'Montserrat-SemiBold',
  // MON_BOLD: 'Montserrat-Bold',
  // MON_EXTRA_BOLD: 'Montserrat-ExtraBold',
};
