import React, {useEffect} from 'react';
import Icon from '../../../../../assets/logo_mob.png';

const LogoSvgMob = () => {
  return <img src={Icon} />;
  const rand1 = Math.random();
  const rand2 = Math.random();
  const rand3 = Math.random();
  return (
    <svg
      width="100"
      height="75"
      viewBox="100 100 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_105_56)">
        <rect
          x="30"
          y="104"
          width="440"
          height="293.333"
          rx="146.667"
          fill="#F7EFFE"
        />
        <rect
          x="31.1"
          y="105.1"
          width="437.8"
          height="291.133"
          rx="145.567"
          stroke="black"
          stroke-opacity="0.06"
          stroke-width="2.2"
        />
      </g>
      <path
        d="M280.87 203.57C270.816 179.302 251.327 160.169 225.966 149.685C200.617 139.212 172.445 138.648 146.649 148.107C105.177 163.315 78.6725 201.612 80.7096 243.424C80.9602 248.671 85.7316 252.719 91.2656 252.483C96.8432 252.248 101.157 247.8 100.896 242.553C99.2616 208.899 120.591 178.072 153.981 165.826C174.744 158.212 197.425 158.663 217.829 167.097C238.233 175.531 253.93 190.934 262.024 210.466C278.724 250.793 257.438 296.396 214.571 312.107C203.896 316.021 192.632 317.815 181.378 317.436C182.337 320.039 181.509 323.656 178.644 324.814C173.666 326.833 170.332 330.768 167.587 335.216C173.143 336.077 178.72 336.507 184.298 336.507C197.065 336.507 209.789 334.263 221.903 329.826C275.162 310.303 301.601 253.662 280.849 203.559L280.87 203.57Z"
        fill={`url(#paint0_linear_105_56${rand1})`}
      />
      <path
        d="M262.032 210.478C278.732 250.804 257.445 296.408 214.579 312.118C203.903 316.033 192.639 317.826 181.386 317.447C182.344 320.05 181.516 323.667 178.651 324.825C173.673 326.844 170.339 330.78 167.594 335.227C173.15 336.088 178.727 336.518 184.305 336.518C197.072 336.518 209.796 334.274 221.91 329.837C275.169 310.314 301.608 253.673 280.856 203.57L262.021 210.478H262.032Z"
        fill={`url(#paint0_linear_105_56${rand2})`}
      />
      <path
        d="M141.287 359.984C139.054 359.984 136.799 359.411 134.772 358.252C129.816 355.404 127.615 349.788 129.413 344.592L148.956 288.187H122.321C118.541 288.187 115 286.598 112.626 283.841C110.24 281.074 109.325 277.498 110.109 274.013L110.207 273.563L110.36 273.122L136.417 195.708C137.877 190.646 142.834 187.038 148.466 187.038H202.847C207.009 187.038 210.876 188.975 213.207 192.224C215.527 195.472 215.985 199.572 214.416 203.199L195.592 246.784H236.051C241.106 246.784 245.638 249.623 247.577 254.02C249.516 258.416 248.47 263.437 244.908 266.809L150.133 356.531C147.714 358.816 144.522 360.005 141.287 360.005V359.984ZM132.888 269.166H159.61C163.597 269.166 167.377 270.98 169.709 274.013C172.051 277.047 172.715 280.982 171.473 284.548L158.695 321.411L217.467 265.774H184.099C179.938 265.774 176.07 263.837 173.739 260.589C171.419 257.34 170.961 253.241 172.53 249.613L191.354 206.038H154.141L132.888 269.166Z"
        fill={`url(#paint0_linear_105_56${rand3})`}
      />
      <path
        d="M344.025 233.067L380.692 268.985L417.359 233.067"
        stroke="#151619"
        stroke-width="11"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <filter
          id="filter0_d_105_56"
          x="3.6"
          y="84.0736"
          width="492.8"
          height="346.133"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6.47356" />
          <feGaussianBlur stdDeviation="13.2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.435294 0 0 0 0 0.113725 0 0 0 0 0.956863 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_105_56"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_105_56"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_105_56"
          x1="148.972"
          y1="147.266"
          x2="216.614"
          y2="331.785"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#EF8FD5" />
          <stop offset="1" stop-color="#EB33CF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_105_56"
          x1="270.692"
          y1="260.17"
          x2="146.338"
          y2="307.839"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.07" stop-color="#EF8FD5" stop-opacity="0" />
          <stop offset="1" stop-opacity="0.4" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_105_56"
          x1="109.826"
          y1="273.501"
          x2="248.546"
          y2="273.501"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#EF8FD5" />
          <stop offset="1" stop-color="#EB33CF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LogoSvgMob;
