import { useAtom } from 'jotai';
import { userProfileDetail, userProfileDetailLoading } from '../hubState';
import { getUserProfileDetail } from '../../../network/api/app';

export const useProfileInfo = () => {
    const [profileInfo, setProfileInfo] = useAtom(userProfileDetail);
    const [profileInfoLoad, setProfileInfoLoad] = useAtom(userProfileDetailLoading);

    const getProfileInfoDetail = async (id, token) => {
        setProfileInfoLoad(true)
        try {
            const response = await getUserProfileDetail(id)({ token });
            if (response) {
                setProfileInfoLoad(false)
                setProfileInfo(response);
            }
        } catch (e) {
            setProfileInfoLoad(false)
            console.log('error => ', e.message);
        }
    };

    return {
        profileInfoLoad,
        profileInfo,
        getProfileInfoDetail,
    };
};
