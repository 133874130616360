import React from 'react';
import Button from '../../../../../reusable/btns/buttons/Button';

const UnderConstructionAlert = ({
    handleOkay,
}) => {
    return (
        <div className="p-10">
            <div className="alert__body bg-transparent">
                <div className="delete-tasks__text">
                    <div className={"mb-4 flex self-center"}>
                        <img src={"/images/construct.png"} className={"h-30 w-30 "} />
                    </div>
                </div>
                <div className="delete-tasks__buttons">
                    <div className="d-flex justify-content-center">
                        <Button
                            className="_fw button_secondary button_m  w-full"
                            onClick={handleOkay}>
                            Okay
                         </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UnderConstructionAlert;
