import React from 'react';
import PrimaryButton from "../../../../../components/reusable/form/items/buttons/PrimaryButton.jsx";
import Input from "../../../../../components/reusable/form/items/inputs/Input.jsx";
import ValidationSchema from "../../../../../form-validator/ValidationSchema.js";
import { useFormValidator } from "../../../../../form-validator/hooks/index.js";
import Form from "../../../../../components/reusable/form/Form.jsx";
import { useNavigation } from "@react-navigation/native";
import { Link } from "react-router-dom";
import Strings from '../../../../../string_key/Strings.web.js';

const validationSchema = new ValidationSchema(
  {
    cardholderName: [
      { rule: 'required' },
      { rule: 'minLength', value: 3 },
      { rule: 'maxLength', value: 40 },
    ],
    cardNumber: [
      { rule: 'required' },
      { rule: 'minLength', value: 16 },
      { rule: 'maxLength', value: 16 },
    ],
    expiration: [
      { rule: 'required' },
    ],
    cvv: [
      { rule: 'required' },
      { rule: 'minLength', value: 3 },
      { rule: 'maxLength', value: 3 },
    ],
  }
);

const PayForm = ({ subtotal, discount, tax, total }) => {
  const { navigate } = useNavigation();

  const { register, handleSubmit, isValid } = useFormValidator(validationSchema, async (formData) => {
    //TODO Place for sending data to API
    const res = {}
    if (res.error) {
      console.error(res.error)
    } else {
      navigate('FinishRegister');
    }
  }, { showErrorsOnSubmit: false });


  return (
    <Form onSubmit={handleSubmit} formClassName='pay-form'>
      <div>
        <Input name='cardholderName' register={register} label={Strings.cardholder_Name} />
        <Input name='cardNumber' register={register} label={Strings.card_Number} />
        <div className='pay-form__row-input'>
          <Input name='expiration' register={register} label={Strings.expiration} />
          <Input name='cvv' register={register} label='CVV' />
        </div>
        <ul className='text-r pay-form__count'>
          <li><span className='text-secondary'>{Strings.subtotal}</span><span>${subtotal}</span></li>
          <li><span className='text-secondary'>{Strings.discount}</span><span>${discount}</span></li>
          <li><span className='text-secondary'>{Strings.tax}</span><span>${tax}</span></li>
        </ul>
      </div>

      <div>
        <PrimaryButton className={!isValid ? 'button_inactive' : ''} type='submit'><span>{Strings.checkout}</span><span className='pay-form__total'>${total}</span></PrimaryButton>
        <div className='text-s text-secondary text-center pay-form__note'>
          <p>
            By continuing, you agree to Buzzmi’s <Link to='/terms-of-service' className='text-alert'>Terms of Service</Link> and confirm that you have read Buzzmi’s <Link to='/pilicy' className='text-alert'>Privacy Policy</Link>.
          </p>
        </div>
      </div>
    </Form>
  );
};

export default PayForm;
