import {useAtom} from 'jotai';
import {useWalletHistory} from '../hubState';
import {getUserWalletHistory} from '../../../network/api/app';

export const useGetWalletHistory = () => {
  const [walletHistory, setWalletHistory] = useAtom(useWalletHistory);

  const getrWalletHistory = async (token, setLoading = () => {}, params) => {
    setLoading(true);
    try {
      const response = await getUserWalletHistory(params)({token});
      setWalletHistory(response);
      setLoading(false);
    } catch (e) {
      setLoading(false);

      console.log('error => ', e.message);
    }
  };

  const clearWalletHistory = () => {
    setWalletHistory([]);
  };

  return {
    clearWalletHistory,
    getrWalletHistory,
    walletHistory,
  };
};
