import React from 'react';

import EditProfileChoseAvatar from './editProfileChoseAvatar/EditProfileChoseAvatar';
import EditProfileSocial from './editProfileSocial/EditProfileSocial';
import EditProfileInformation from './editProfileInformation/EditProfileInformation';
import EditProfileHealthInformation from './editProfileHealthInformation/EditProfileHealthInformation';
import EditProfileAccountInformation from './editProfileAccountInformation/EditProfileAccountInformation';
import EditProfileInterests from './editProfileInterests/EditProfileInterests';
import EditProfileBusinessInformation from './editProfileBusinessInformation/EditProfileBusinessInformation';
import SubmitButton from '../../../../../components/reusable/btns/buttons/submitButton/SubmitButton';
import Form from '../../../../../components/reusable/form/Form';

const EditProfileFormV2 = ({
  error,
  handleSubmit,
  setValue,
  values,
  isValid,
  register,
  data = {},
  title,
}) => {
  return (
    <div>
      <Form
        error={error}
        onSubmit={handleSubmit}
        formClassName="hub-form-wrapper profile-form">
        <EditProfileChoseAvatar
          value={values.avatar}
          setValue={img => setValue('avatar', img)}
          paddingMode={'none'}
        />
        <EditProfileInformation
          setValue={setValue}
          values={values}
          register={register}
          paddingMode={'none'}
        />
        <EditProfileInterests
          setValue={setValue}
          values={values}
          paddingMode={'none'}
        />
        <EditProfileHealthInformation
          register={register}
          setValue={setValue}
          paddingMode={'none'}
        />
        <EditProfileBusinessInformation
          register={register}
          setValue={setValue}
          paddingMode={'none'}
        />
        <EditProfileAccountInformation
          setValue={setValue}
          values={values}
          register={register}
          paddingMode={'none'}
        />
        <EditProfileSocial paddingMode={'none'} register={register} setValue={setValue} />
        <div className="d-flex justify-content-center mt-5">
          <SubmitButton isValid={isValid} title="Save Changes" />
        </div>
      </Form>
    </div>
  );
};

export default EditProfileFormV2;
