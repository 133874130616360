import React from "react"
import Button from "../../../reusable/btns/buttons/Button"
import Calendar from "../../../reusable/calendar/Calendar"
import Box from "../../../reusable/cards/box/Box"

const dayList = [
  {
    date: "2023-08-01",
    type: "light-blue",
  },
  {
    date: "2023-08-02",
    type: null,
  },
  {
    date: "2023-08-03",
    type: null,
  },
  {
    date: "2023-08-04",
    type: "light-blue",
  },
  {
    date: "2023-08-05",
    type: "uncompleted",
  },
  {
    date: "2023-08-06",
    type: null,
  },
  {
    date: "2023-08-07",
    type: "light-blue",
  },
  {
    date: "2023-08-08",
    type: null,
  },
  {
    date: "2023-08-09",
    type: "uncompleted",
  },
  {
    date: "2023-08-10",
    type: "light-blue",
  },
  {
    date: "2023-08-11",
    type: null,
  },
  {
    date: "2023-08-12",
    type: null,
  },
  {
    date: "2023-08-13",
    type: "light-blue",
  },
  {
    date: "2023-08-14",
    type: null,
  },
  {
    date: "2023-08-15",
    type: null,
  },
  {
    date: "2023-08-16",
    type: "light-blue",
  },
  {
    date: "2023-08-17",
    type: "uncompleted",
  },
  {
    date: "2023-08-18",
    type: null,
  },
  {
    date: "2023-08-19",
    type: "light-blue",
  },
  {
    date: "2023-08-20",
    type: null,
  },
  {
    date: "2023-08-21",
    type: "uncompleted",
  },
  {
    date: "2023-08-22",
    type: "light-blue",
  },
  {
    date: "2023-08-23",
    type: null,
  },
  {
    date: "2023-08-24",
    type: null,
  },
  {
    date: "2023-08-25",
    type: "light-blue",
  },
  {
    date: "2023-08-26",
    type: null,
  },
  {
    date: "2023-08-27",
    type: null,
  },
  {
    date: "2023-08-28",
    type: "light-blue",
  },
  {
    date: "2023-08-29",
    type: "uncompleted",
  },
  {
    date: "2023-08-30",
    type: null,
  },
  {
    date: "2023-08-31",
    type: "light-blue",
  },
]

const JoinToCourse = () => {
  return (
    <div className="modal-course">
      <div className="modal-course__title">Add this program to your task center</div>
      <Box className={"mb-4"}>
        <Calendar onChange={() => { }} period="week" noIndent value={"2023-07-21"} />
      </Box>
      <div className="modal-course__button-column">
        <Button className="_fw button_primary button_m custom_fw_btn" onClick={() => { }}>
          Start
        </Button>
        <Button className="_fw button_secondary button_m custom_fw_btn !bg-[#fff] !text-[#eb4dac]" onClick={() => { }}>
          View community
        </Button>
      </div>
    </div>
  )
}

export default JoinToCourse
