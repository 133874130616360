import { EyeIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';
import Box from '../../../../../../components/reusable/cards/box/Box';
import ProfileDataBadges from './items/ProfileDataBadges';
import ProfileDataTextInfo from './items/ProfileDataTextInfo';

const ProfileDataBlockComponent = ({ icon, title }) => (
  <div className="profile-info__header flex justify-between	">
    <div className={"flex justify-around self-center"}>
      <img src={icon.src} alt={icon.alt} />
      <div className={"ml-2 self-center"}>{title}</div>
    </div>
    <EyeIcon className="h-5 w-5" style={{ color: '#DA31B1' }} aria-hidden="true" />
  </div>
);

const ProfileDataBlock = ({ item, data, onPress }) => {
  return (
    <Box
      onPress={onPress}
      className="mt-5"
      paddingMode="big"
      topComponent={
        item.isHide ? null :
          <ProfileDataBlockComponent icon={item.icon} title={item.title} />
      }>
      {item.title === 'Activity' && (
        <ProfileDataTextInfo data={data.activity} />
      )}
      {item.title === 'Badges' && <ProfileDataBadges data={data.badges} />}
      {item.title === 'Contacts' && (
        <ProfileDataTextInfo isLinks data={data.contacts} />
      )}
    </Box>
  );
};

export default ProfileDataBlock;
