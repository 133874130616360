import React from 'react';
import CommentV2 from './CommentV2';
import RepliesListV2 from './RepliesListV2';

const CommentsListV2 = ({comments}) => {
  return (
    <ul className="comments-post__comments-list_v2">
      {comments?.map(comment => (
        <li key={comment.id}>
          <CommentV2 comment={comment} />

          {/* {comment.replies && <RepliesListV2 replies={comment.replies} />} */}
        </li>
      ))}
    </ul>
  );
};

export default CommentsListV2;
