import React, {useEffect, useState} from 'react';
import Input from '../../../../../components/reusable/form/items/inputs/Input.jsx';
import ValidationSchema from '../../../../../form-validator/ValidationSchema.js';
import {useFormValidator} from '../../../../../form-validator/hooks/index.js';
import Form from '../../../../../components/reusable/form/Form.jsx';
import {Dots} from 'react-activity';
import 'react-activity/dist/library.css';
import {colors} from '../../../../../res/colors.js';
const validationSchema = new ValidationSchema({
  identifier: [{rule: 'required'}],
  password: [{rule: 'required'}],
});

const LoginForms = props => {
  const {onSubmit, onForgotPass} = props;
  // const [showPass, setShowPass] = useState(true);
  const {register, handleSubmit} = useFormValidator(
    validationSchema,
    formData => {
      onSubmit(formData);
    },
  );
  // useEffect(() => {
  //   setTimeout(() => {
  //     setShowPass(false);
  //   }, 3000);
  // }, []);

  return (
    <div>
      <Form onSubmit={handleSubmit} formClassName="space-y-6">
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium leading-6 text-gray-900">
            Your Username or Email
          </label>
          <div className="mt-2">
            <Input
              label={''}
              required
              name="identifier"
              register={register}
              className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-pink-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div>
          <label
            htmlFor="password"
            className="block text-sm font-medium leading-6 text-gray-900">
            Your Password
          </label>
          <div className="mt-2 pass_input">
            <Input
              label={''}
              required
              diffPasswordForm={true}
              name="password"
              register={register}
              className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-pink-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <input
              id="remember-me"
              name="remember-me"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-pink-600 focus:ring-pink-600"
            />
            <label
              htmlFor="remember-me"
              className="ml-3 block text-sm leading-6 text-gray-700">
              Remember me
            </label>
          </div>

          <div className="text-sm leading-6">
            <a
              onClick={() => {
                props.props.navigation.navigate('ForgetPassword');
              }}
              href="#"
              className="font-semibold text-pink-600 hover:text-pink-500">
              Forgot password?
            </a>
          </div>
        </div>

        <div>
          {/* {showPass ? (
            <div
              className="pb-2"
              style={{
                display: 'flex',
                justifyContent: 'center', // Horizontally center content
                alignItems: 'center', // Vertically center content
                width: '100%', // Set the width to 100% if needed
                // Add any other inline styles you want
              }}>
              <Dots color={colors.PRIMARY_COLOR} />
            </div>
          ) : ( */}
          <button
            style={{backgroundColor: '#de3bbe'}}
            type="submit"
            className="mt-4 flex w-full justify-center rounded-md bg-pink-600 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-pink-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600">
            LOG IN
          </button>
          {/* )} */}
        </div>
      </Form>
    </div>
  );
};

export default LoginForms;
