import React, {useEffect, useState, useRef} from 'react';
import PostHeader from './items/postHeader/PostHeader';
import PostImage from './items/postImageVideo/PostImage';
import PostVideo from './items/postImageVideo/PostVideo';
import PostContent from './items/postContent/PostContent';
import PostBtns from './items/postBtns/PostBtns';
import PropsTypes from 'prop-types';
import {useEventEmitter} from '../../../../../hooks/useEventEmitter';
import PostBtnsV2 from './items/postBtns/PostBtnsV2';
import {useBuzzmiAuth} from '../../../../../appState/hooks/useBuzzmiAuth/index.web';
import {LockClosedIcon} from '@heroicons/react/24/outline';
import {useDispatch} from 'react-redux';
import {
  closeModal,
  openModal,
} from '../../../../../store/slices/modalSlice/modalSlice';
import {categorizeUrlsFromArray, extractFieldValues} from './ProfilePostV2';
import {getVideoPosterURL} from '../types';

const ProfilePost = ({postData, currentPostId, type, isMyPost = true}) => {
  const [post, setPost] = useState(null);
  const [refreshContent, setRefreshContent] = useState(null);
  const {userData} = useBuzzmiAuth();
  const scrollRef = useRef([]);
  const dispatch = useDispatch();

  const onUpgradtion = () => {
    dispatch(
      openModal({
        modalName: 'renderAlert',
        modalType: 'alert',

        modalProps: {
          nameAlert: 'upgradeAlert',
          propsAlert: {
            closeModal: () => {
              dispatch(closeModal());
            },
            contentType: post?.contentType,
          },
        },
      }),
    );
  };

  useEffect(() => {
    setPost(postData);
  }, [postData]);

  const {thumbnail, imageUrl, id} = post || {};

  const {user, upvotes, summary, createdAt, comments, images} =
    post?.object || post || {};

  useEventEmitter('post_comments_length', data => {
    setRefreshContent(data);
  });

  useEffect(() => {
    if (id === currentPostId) {
      scrollRef.current[currentPostId].scrollIntoView();
    }
  }, [post]);
  let postMedia =
    post?.fields?.length > 0 ? categorizeUrlsFromArray(post?.fields) : [];

  return post ? (
    <div className="relative w-100 h-100 justify-center items-center">
      <div
        ref={ref => (scrollRef.current[id] = ref)}
        className={`profile-post ${
          post?.contentType &&
          post?.contentType != 'all' &&
          userData?.membership?.type == 'free_member'
            ? 'blur-[2px]'
            : 'blur-none'
        }`}
        id={type === 'post' ? `post-${id}` : `reel-${id}`}>
        <PostHeader
          userName={post?.owner?.member?.name}
          userNickname={post?.owner?.member?.username}
          userPosition={''}
          userAvatar={extractFieldValues(post?.owner?.member?.fields)?.avatar}
          isVerified={post?.owner?.member?.status === 'VERIFIED'}
          post={post}
          type={type}
          isMyPost={isMyPost}
        />

        {type === 'reel' && (
          <PostVideo
            srcVideo={postMedia?.video?.[0]}
            posterVideo={getVideoPosterURL(postMedia?.video?.[0])}
            post={post}
            type={type}
            isMyPost={isMyPost}
          />
        )}

        {type === 'post' && postMedia?.video?.length > 0 && (
          <PostVideo
            srcVideo={postMedia?.video?.[0]}
            posterVideo={getVideoPosterURL(postMedia?.video?.[0])}
            post={post}
            type={type}
            isMyPost={isMyPost}
          />
        )}

        {type === 'post' && postMedia?.image.length > 0 && (
          <PostImage images={postMedia?.image} />
        )}

        <PostContent
          city={''}
          country={''}
          nickname={post.owner.member.username}
          text={post?.title}
          tags={[]}
          date={new Date(post?.publishedAt)}
          song={''}
        />

        <PostBtnsV2
          postId={id}
          likes={post?.reactionsCount}
          comments={
            refreshContent && refreshContent?.post_id == id
              ? refreshContent?.data?.length
              : post?.repliesCount
          }
          gems={2}
          gifts={4}
          userName={post.owner.member.username}
          userAvatar={extractFieldValues(post?.owner?.member?.fields)?.avatar}
          post={post}
          type={type}
        />
      </div>
      {post?.contentType != 'all' &&
        userData?.membership?.type == 'free_member' && (
          <div
            onClick={() => {
              onUpgradtion();
            }}
            className={
              'absolute top-0 w-100 h-100 flex justify-center items-center'
            }>
            <div className={'flex flex-col'}>
              <img
                src={
                  post?.contentType == 'premium'
                    ? '/images/social/premium_white.png'
                    : '/images/social/exclusive_white.png'
                }
                className={'h-12 w-12 self-center mb-4'}
              />
              <p className={'text-[#fff]'}>
                Unlock {post?.contentType} content
              </p>
              <LockClosedIcon
                className={'h-6 w-6 mt-2 self-center text-[#FFF]'}
              />
            </div>
          </div>
        )}
    </div>
  ) : null;
};

ProfilePost.propTypes = {
  postData: PropsTypes.object,
  currentPostId: PropsTypes.number,
  type: PropsTypes.string,
};

export default ProfilePost;
