import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import MediaWrapper from '../../assets/mediaWrapper/MediaWrapper.jsx';
import {useAudioRecorder} from 'react-audio-voice-recorder';
import AudioPlayer from '../../audioPlayer/AudioPlayer.jsx';
import handlerMediaURL from '../../../handlerMediaURL.js';
import {useBuzzmiAuth} from '../../../../appState/hooks/useBuzzmiAuth/index.web.js';
import {useHubApp} from '../../../../appState/hooks/useHubApp';
import {ArrowUpTrayIcon} from '@heroicons/react/24/outline';
const maxSize = 500 * 1024 * 1024;
const maxDuration = 90 * 60 * 1000;
import addImg from '../../../../assets/add_icon.png';
import {Dots} from 'react-activity';
import {colors} from '../../../../res/colors.js';

const AudioUploader = forwardRef((props, ref) => {
  const {setValue, defaultValue, defaultValueTitle, defaultValueDes} = props;
  MediaWrapper;
  const [audio, setAudio] = useState(defaultValue);
  const playerRef = useRef(null);
  const pickerRef = useRef(null);
  const [iconSrc, setIconSrc] = useState('/images/assets/forms/voice.svg');
  const {userData} = useBuzzmiAuth();
  const {
    onUploadFiles,
    uploadedAudioLink,
    setUploadedAudioLink,
    isFileUploadLoading,
  } = useHubApp();
  const maxSize = 500 * 1024 * 1024;
  const {startRecording, stopRecording, recordingBlob, isRecording} =
    useAudioRecorder();

  useImperativeHandle(
    ref,
    () => ({
      _recordAudio: () => recordAudio(),
      _uploadAudio: () => uploadAudio(),
    }),
    [],
  );

  const recordAudio = () => {
    if (isRecording) {
      stopRecording();
      setIconSrc('/images/assets/forms/voice.svg');
    } else {
      startRecording();
      setIconSrc('/images/assets/forms/voice_r.svg');
    }
  };
  useEffect(() => {
    setAudio(defaultValue);
  }, [defaultValue]);
  const removeAudio = () => {
    setAudio(null);
    setValue('audio', null);
    setUploadedAudioLink(null);
  };

  const setAndUploadAudio = fileOfBlob => {
    const fd = new FormData();
    fd.append('file', fileOfBlob);
    onUploadFiles(
      Object.assign(fd, {
        params: {
          isAuth: true,
          token: userData?.token,
          type: 'description_audio',
        },
      }),
    );

    // setAudio(recordingBlob);
    setAudio(fileOfBlob);
  };

  useEffect(() => {
    // console.log('recordingBlob', recordingBlob);
    if (!!recordingBlob) {
      var fileOfBlob = new File(
        [recordingBlob],
        `${Math.random().toString(36).slice(2, 7)}.webm`,
        {
          type: 'audio/webm',
        },
      );
      setAndUploadAudio(fileOfBlob);
      // setValue('audio', recordingBlob);
    }
  }, [recordingBlob]);

  useEffect(() => {
    if (uploadedAudioLink) {
      setValue('audio', uploadedAudioLink);
    }
    return () => setUploadedAudioLink(null);
  }, [uploadedAudioLink]);

  useEffect(() => {
    //add listener to video duration
    if (playerRef.current) {
      playerRef.current.addEventListener('loadedmetadata', () => {
        if (
          isFinite(playerRef.current.duration) &&
          playerRef.current.duration > maxDuration
        ) {
          setAudio(null);
          setValue('audio', null);
        } else if (audio?.size > maxSize) {
          setAudio(null);
          setValue('audio', null);
        }
      });
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.removeEventListener('loadedmetadata', () => {});
      }
    };
  }, [audio]);

  const onChangeAudioPicker = event => {
    const file = event.target.files[0];

    const maxSizeInBytes = 500 * 1024 * 1024; // 500 MB
    if (file?.size > maxSizeInBytes) {
      alert('File size exceeds the maximum limit of 500MB.');
      return;
    }

    // Check file duration (assuming duration is in seconds)
    const maxDurationInSeconds = 90 * 60; // 90 minutes
    const audio = new Audio();
    audio.src = URL.createObjectURL(file);
    audio.onloadedmetadata = () => {
      if (audio.duration > maxDurationInSeconds) {
        alert('File duration exceeds the maximum limit of 90 minutes.');
        return;
      }

      // File meets requirements, update the state
      // setSelectedFile(file);
      setAndUploadAudio(file);
      // Additional processing if needed
    };
    // setSelectedFile(file);
  };
  const uploadAudio = () => {
    pickerRef.current.click();
  };
  return (
    <>
      {/* <div className="d-flex justify-content-between align-items-center mt-lg-3"> */}
      <div className="d-flex justify-content-between align-items-center">
        <ul style={{opacity: 0}} className="text-r">
          <li> Max Size: 500 MB</li>
          <li> Maximum length: 90 Min</li>
        </ul>

        {isFileUploadLoading ? (
          <div>
            <Dots color={colors.PRIMARY_COLOR} />
          </div>
        ) : (
          <div className="d-flex justify-content-between align-items-center gap-1">
            <MediaWrapper
              onClick={recordAudio}
              iconSrc={iconSrc}
              style={{height: '40px', width: '40px', padding: 5}}
            />
            {/* <MediaWrapper
              onClick={uploadAudio}
              iconSrc={addImg}
              bordered={false}
              style={{height: '25px', width: '25px'}}
            /> */}
          </div>
        )}
      </div>
      <input
        className="d-none"
        onChange={onChangeAudioPicker}
        type="file"
        ref={pickerRef}
        id="audioFile"
        multiple
        accept="audio/*"
      />

      {audio && (
        <>
          <div className="audio-uploader__player">
            <AudioPlayer src={handlerMediaURL(audio)} />
            <button
              className="picture-uploader__delete audio-uploader__delete"
              onClick={removeAudio}>
              <img src="/images/assets/delete.svg" alt="delete" />
            </button>
          </div>
          <div className="flex flex-col mt-2">
            <textarea
              onChange={e => setValue('AudioTitle', e.target.value)}
              placeholder="Enter a name"
              className="description__text-m1"
              value={defaultValueTitle}
              rows={1}
            />
            <textarea
              onChange={e => setValue('AudioDescription', e.target.value)}
              placeholder="If necessary, leave a short description for this"
              className="description__text-m"
              value={defaultValueDes}
            />
          </div>
        </>
      )}
    </>
  );
});

export default AudioUploader;
