import React, {useState} from 'react';
import CommentsIcon from '../../../icons/CommentsIcon';
import {Link} from '@react-navigation/native';
import GemsIcon from '../../../icons/GemsIcon';
import CommentsModal from '../../../../ProfileMain/componentsV2/CommentsModal';

const CommentsPostBtnV2 = ({comments, postId}) => {
  const [openCommentModal, setOpenCommentssModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  // const [commenLength, setCommentLength] = useState(comments);

  const handleMouseEnter = () => setIsHovered(true);

  const handleMouseLeave = () => setIsHovered(false);

  return (
    <>
      
      <div
        onClick={e => {
          e.preventDefault();
          setOpenCommentssModal(() => true);
        }}
        className="post-buttons__item d-flex align-items-center"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <CommentsIcon isHovered={isHovered} />
        <p className={`post-buttons__item-text ${isHovered ? 'hovered' : ''}`}>
          {comments}
        </p>{' '}
      </div>
     
      <CommentsModal
        open={openCommentModal}
        data={{entity: 'post', id: postId}}
        setOpen={setOpenCommentssModal}
      />
    </>
  );
};

export default CommentsPostBtnV2;
