import React, { useContext, useState, useEffect } from 'react';
import { Switch, View, Image, TouchableOpacity, Dimensions } from 'react-native';
import { Spacer } from '../../../../res/spacer';
// style themes and components
import {
  GlobalFlex,
  MainTitle,
  SubContainer,
  SubTitle,
} from '../../../../res/globalStyles';
import { BackHeader } from '../../../../components/BackHeader';
import { colors } from '../../../../res/colors';
// Third Party library
import AsyncStorage from '@react-native-community/async-storage';
import {
  heightPercentageToDP as hp,
  widthPercentageToDP as wp,
} from 'react-native-responsive-screen';
import { ActionWrapper, BorderContainer, styles, Text } from './style';
import { fonts } from '../../../../res/fonts';
import { images } from '../../../../res/images';
const ScreenWidth = Dimensions.get('window').width;

const Setting = props => {
  const [isLastseen, setIsLastSeen] = useState(false);

  const toggleSwitchLastSeen = value => {
    console.log('value => ', value);
    AsyncStorage.setItem('show-last-seen', JSON.stringify(value));
    setIsLastSeen(() => value);
  };

  useEffect(() => {
    AsyncStorage.getItem('show-last-seen')
      .then(s => (typeof s !== 'string' || s == 'true' ? true : JSON.parse(s)))
      .then(show => setIsLastSeen(() => show));
  }, []);

  return (
    <GlobalFlex>
      <BackHeader
        isModal
        onBackPress={() => props.navigation.goBack()}
        is_center_text
        title={'Settings'}
      />
      <Spacer space={hp(1)} />

      <TouchableOpacity
        style={{ flexDirection: 'row', padding: hp(4), width: ScreenWidth > 670 ? hp(75) : '100%', alignSelf: "center", }}
        onPress={() => {
          props.navigation.pop(), props.navigation.navigate('ChatFont');
        }}>
        <Image
          resizeMode={'contain'}
          source={images.chat_ic}
          style={{
            width: hp(6),
            alignSelf: 'center',
            height: hp(6),
            tintColor: colors.PRIMARY_COLOR,
          }}></Image>
        <View>
          <Text
            style={{
              fontSize: hp(3),
              marginLeft: hp(4),
              fontFamily: fonts.REGULAR,
              textAlign: 'left',
            }}>
            {'Chats'}
          </Text>
          <Text
            style={{
              marginLeft: hp(4),
              fontFamily: fonts.REGULAR,
              textAlign: 'left',
              fontSize: hp(2.4),
              color: colors.DARK_GRAY_91,
              marginTop: hp(1),
            }}>
            {'Font size'}
          </Text>
        </View>
      </TouchableOpacity>

      <View style={{ flex: 1 }}>
        <View
          onPress={() => {
            setIsLastSeen(!isLastseen);
          }}
          style={{
            paddingVertical: hp(2),
            alignSelf: "center",
            backgroundColor: colors.BORDER_SPACE_COLOR,
            width: ScreenWidth > 670 ? hp(75) : '100%',
          }}>
          <Text
            style={{
              textAlign: 'left',
              fontSize: hp(3),
              marginLeft: hp(5),
              fontFamily: fonts.BOLD,
            }}>
            Privacy
          </Text>
        </View>
        <ActionWrapper
          onPress={() => {
            setIsLastSeen(!isLastseen);
          }}>
          <Text style={[{ alignSelf: 'center', fontFamily: fonts.REGULAR, fontSize: hp(3), }]}>
            Show last seen
          </Text>

          <Switch
            trackColor={{
              false: colors.DARK_GRAY_91,
              true: colors.LIGHT_PRIMARY_COLOR,
            }}
            thumbColor={isLastseen ? colors.PRIMARY_COLOR : colors.DARK_THUMB}
            onValueChange={toggleSwitchLastSeen}
            value={isLastseen}
          />
        </ActionWrapper>
        <BorderContainer />
      </View>
    </GlobalFlex>
  );
};
export default Setting;
