import React from 'react';
import Rank from '../../../../Hub/Items/rank/Rank';
import ProfileDataBlocks from '../../../ProfileMain/items/profileDataBlocks/ProfileDataBlocks';

const ProfileInformationDesktop = () => {
  return (
    <>
      <Rank />
      <ProfileDataBlocks />
    </>
  );
};

export default ProfileInformationDesktop;
