const BE = {
  live: 'https://api-stg.trivacall.com/',
  stagging: 'https://api-demo.trivacall.com/',
  development: 'https://api-dev.onesay.app/',
};

const TOKEN = {
  live: 'a095bcd4c72aca4b857d9e16d08c8745c7523821',
  stagging: '5098447a3b258ba46cb0bb2e9f9899e20bcab273',
  development: '1ba7b10be3ac1bb8f0eab4e7237ee956dbc5a759',
};

// Api common variable
const serviceID = 'triva';
const apiVersion = '2';
const hash_key = 'sam_22@@';

// Base URL and API
const BASE_URL = BE.development;
const ACCESS_TOKEN = TOKEN.development;

const HeaderParams = {
  Accept: 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
};

const getAuthHeader = token => {
  // console.log('objHeader', token);
  let objHeader = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  if (token?.includes('social-')) {
    objHeader = {
      ...objHeader,
      Authorization: `Bearer ${token.split('social-')[1]}`,
    };
  } else {
    objHeader = {
      ...objHeader,
      'X-Session-Token': token,
    };
  }

  return objHeader;
};

const getMultipartAuthHeader = token => {
  let objHeader = {
    accept: 'application/json',
    'Content-Type': 'multipart/form-data',
  };

  if (token?.includes('social-')) {
    objHeader = {
      ...objHeader,
      Authorization: `Bearer ${token.split('social-')[1]}`,
    };
  } else {
    objHeader = {
      ...objHeader,
      'X-Session-Token': token,
    };
  }
  return objHeader;
};

export {BASE_URL, HeaderParams, getAuthHeader, getMultipartAuthHeader};
