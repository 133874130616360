export const tabs = [
  {
    id: 'Post',
    title: 'Profile',
  },
  {
    id: 'Friends',
    title: 'Friends',
  },
  {
    id: 'Information',
    title: 'Information',
  },
  {
    id: 'About',
    title: 'About',
  },
  // {
  //   id: "Photos",
  //   title: "Photos",
  // },
  // {
  //   id: "Videos",
  //   title: "Videos",
  // },
];
