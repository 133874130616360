import React from 'react';
import {Link} from 'react-router-dom';
import PrimaryButton from '../../../../components/reusable/form/items/buttons/PrimaryButton.jsx';

const AddCreatorsMobileBtns = ({handleContinue}) => {
  return (
    <div className="profile__mobile-btns p-5">
      <div>
        {/*<Link to='/auth/invite' className='text-primary h6 mb-5 d-inline-block'>
          or invite friends induvidualy
        </Link>*/}
        <button onClick={handleContinue} className="d-block my-sm-8 w-100">
          <PrimaryButton>Next</PrimaryButton>
        </button>
      </div>
    </div>
  );
};

export default AddCreatorsMobileBtns;
