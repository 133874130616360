// noinspection ES6CheckImport

import {Platform, StyleSheet} from 'react-native';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import {colors} from '../../../res/colors';
import {fonts} from '../../../res/fonts';
import {isWeb} from '../../../constants.ts';
import styled from 'styled-components/native';
import {getStatusBarHeight, isIphoneX} from 'react-native-iphone-x-helper';

const MainContainer = styled.View`
  flex: 1;
  //background-color: ${colors.WHITE};
  background-color: #fbf8ff;
  justify-content: center;
`;

const TrialTextContainer = styled.View`
  width: 100%;
  height: 100%;
  align-self: center;
  border-radius: 12px;
  justify-content: center;
  background-color: #fbf8ff;
  padding-start: ${wp('4%')};
`;

const Text = styled.Text`
  color: ${colors.BLACK};
  font-size: ${wp(4.5)};
  text-align: center;
  margin-horizontal: ${wp(2)};
  font-family: ${fonts.BOLD};
`;

const WelcomeText = styled.Text`
  text-align: center;
  color: ${colors.BLACK};
  font-family: ${fonts.MEDIUM};
  font-size: ${wp(4.5)};
  margin-horizontal: ${wp(2)};
`;
const InfoText = styled.Text`
  text-align: center;
  color: ${colors.BLACK};
  font-family: ${fonts.REGULAR};
  font-size: ${wp(3.3)};
  margin-horizontal: ${wp(2)};
`;

const TrialInfoText = styled.Text`
  //text-align: center;
  color: ${colors.BLACK};
  font-family: ${fonts.REGULAR};
  font-size: ${wp(3.3)};
  margin-horizontal: ${wp(1)};
`;

const LabelText = styled.Text`
  font-family: ${fonts.BOLD};
  color: ${colors.PRIMARY_COLOR};
  font-size: ${wp(4.5)};
  margin-left: ${wp(5)};
`;

const UsernameText = styled.Text`
  align-self: center;
  color: ${colors.BLACK};
  font-family: ${fonts.BOLD};
  font-size: ${isWeb ? '1.5rem' : wp(3.8)};
`;
const ErrorText = styled.Text`
  align-self: center;
  color: ${colors.Error};
  font-family: ${fonts.BOLD};
  font-size: ${isWeb ? '1.5rem' : wp(3.8)};
`;

const MenuContainer = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  alignself: center;
`;

const SectionHeader = styled.View`
  margin-horizontal: ${wp(5)};
`;

const AccessBox = styled.View`
  flex-direction: row;
  flex: 1;
  margin-horizontal: ${wp(3.5)};

  flex-wrap: wrap;
`;
const AccessItem = styled.View`
  width: ${wp(42.3)};
  background-color: ${colors.WHITE};
  flex-direction: row;
  align-items: center;
  padding: ${wp(3)}px;
  border-radius: ${wp(2)};
  margin: ${wp(2)}px;
`;
const AccessItemImage = styled.Image`
  height: ${wp(10)};
  width: ${wp(10)};
  margin-right: ${wp(2)};
`;
const NLPathsWrapper = styled.View`
  flex-direction: row;
  flex: 1;
  margin-top: ${hp(2)};
`;
const NLPathBox = styled.View`
  flex: 1;
  padding: ${wp(2)}px;
`;
const NLPathSubWrap = styled.View`
  flex-direction: row;
  align-items: center;
  margin-bottom: ${wp(2)};
`;
const NLPathSubTitleText = styled.Text`
  font-family: ${fonts.REGULAR};
  color: ${colors.BLACK};
  font-size: ${wp(3)};
`;
const NLPathSubTitleIcon = styled.Image`
  height: ${wp(5)};
  width: ${wp(5)};
  margin-right: ${wp(2)};
`;

const NLPathsDivider = styled.View`
  width: 2px;
  height: 35%;
  background-color: #c0bacb;
`;
const NLPathsDividerText = styled.Text`
  font-family: ${fonts.REGULAR};
  color: #c0bacb;
  font-size: ${wp(3)};
`;

const ButtonText = styled.Text`
  font-weight: 800;
  text-align: center;
  color: ${colors.WHITE};
  font-family: ${fonts.MON_BOLD};
  font-size: ${wp(4.5)};
  margin-horizontal: ${wp(2)};
`;

const PlanItem = styled.Pressable`
  width: 100%;
  border-radius: 10px;
  padding: ${wp(4)}px;
  background-color: ${colors.WHITE};
`;
const EventTitleText = styled.Text`
  color: ${colors.BLACK};
  font-family: ${fonts.MON_BOLD};
  font-size: ${wp(3.1)};
`;
const EventItemImage = styled.Image`
  border-radius: 16px;
  width: ${wp(12)};
  height: ${wp(12)};
`;
const ProfileIcon = styled.Image`
  width: ${wp(7)};
  height: ${wp(7)};
`;

export {
  EventItemImage,
  EventTitleText,
  PlanItem,
  ButtonText,
  MainContainer,
  TrialInfoText,
  TrialTextContainer,
  WelcomeText,
  InfoText,
  Text,
  LabelText,
  MenuContainer,
  UsernameText,
  SectionHeader,
  AccessBox,
  AccessItem,
  AccessItemImage,
  NLPathsWrapper,
  NLPathBox,
  NLPathSubTitleText,
  NLPathsDivider,
  NLPathsDividerText,
  NLPathSubTitleIcon,
  NLPathSubWrap,
  ProfileIcon,
  ErrorText,
};

export default StyleSheet.create({
  shadowStyle: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
  },
  dismissButtonStyle: {
    position: 'absolute',
    top: wp('2%'),
    right: wp('3.5%'),
  },
  trialContainerStyle: {
    borderRadius: 14,
    alignSelf: 'center',
    alignItems: 'center',
    paddingVertical: 1.8,
    paddingHorizontal: 1.8,
    justifyContent: 'center',
    width: wp('94%'),
    height: wp('14%'),
  },
  image_ic: {
    width: wp(6),
    height: wp(6),
    marginHorizontal: wp(1),
  },
  wrapper: {
    alignSelf: 'center',
  },
  item: {
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  header: {
    textAlign: 'center',
    textTransform: 'capitalize',
    fontWeight: fonts.REGULAR,
    fontSize: 30,
    color: colors.PRIMARY_COLOR,
    paddingVertical: 10,
  },
  post: {
    padding: 15,
    borderRadius: 10,
    margin: 4,
    width: wp(44),
    backgroundColor: '#EFEFEF',
  },
  postTitle: {color: colors.REGULAR_TEXT_COLOR, textTransform: 'capitalize'},
  imageWrapper: {
    paddingTop:
      Platform.OS == 'ios' ? (isIphoneX ? getStatusBarHeight() + 15 : 10) : 16,
    height: isIphoneX ? hp(14) : Platform.OS == 'ios' ? hp(12) : hp(14),
    width: wp(90),
    alignSelf: 'center',
    justifyContent: 'center',
  },
  icon_ic: {
    width: wp(7),
    height: wp(7),
    alignSelf: 'center',
    resizeMode: 'contain',
  },
  flexWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  flexInnerWrapper: {
    flexDirection: 'row',
    alignSelf: 'center',
  },
  profile_ic: {
    width: wp(12),
    height: wp(12),
    borderRadius: wp(10),
  },
  ViewRankText: {
    fontFamily: fonts.REGULAR,
    fontSize: wp(3.6),
  },
});
