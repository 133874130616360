import React, { useEffect, useRef, useState, Fragment } from 'react';
import InfoCardMenuMobile from './InfoCardMenuMobile.jsx';
import InfoCardMenuDesktop from './InfoCardMenuDesktop.jsx';
import { useDeleteBaseActivity } from '../../../../../../appState/hooks/useDeleteBaseActivity';
import { useAtom } from 'jotai';
import { userDataAtom } from '../../../../../../appState/userState';
import { useToast } from 'react-native-toast-notifications';
import { ShowSuccess } from '../../../../../../container/HOC/MessageWeb.js';
import { EventRegister } from 'react-native-event-listeners';
import { useDeletePlannedActivity } from '../../../../../../appState/hooks/useDeletePlannedActivity/index.js';
import { Link, useNavigation } from '@react-navigation/native';
import { useDispatch } from 'react-redux';
import {
  closeModal,
  openModal,
} from '../../../../../../store/slices/modalSlice/modalSlice.js';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import HubAddTaskv2 from '../../../../../../container/App/Hub/AddTaskv2/index.web.js';
import DeleteActivityModal from '../../../../../modal/modals/DeleteActivity/index.js';
import Loading from 'react-fullscreen-loading';
import { colors } from '../../../../../../res/colors.js';
import GoalProgressModal from '../../../../../../container/App/Hub/GoalProgressModal/index.js';
import UpdateProgressModal from '../../../../../../container/App/Hub/UpdateProgressModal/index.js';
import { useEventEmitter } from '../../../../../../hooks/useEventEmitter.js';

const InfoCardMenu = ({
  id,
  showMenu,
  isMainActivity = false,
  setShowMenu,
  taskType,
  item,
}) => {
  const [userData, setLoginSuccess] = useAtom(userDataAtom);
  const { deleteBase } = useDeleteBaseActivity();
  const { deletePlanned } = useDeletePlannedActivity();
  const toast = useToast();
  const navigation = useNavigation();
  const menuRef = useRef(null);
  const dispatch = useDispatch();
  const [isopen, setIsOpen] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [updateProgressModal, showUpdateProgressModal] = useState(false);
  const [baseItem, setBaseItem] = useState({});
  const rootButtonRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [queryParam, setQueryParam] = useState(null);



  const [selectedItem, setSelectedItem] = useState({});

  const handleMenu = event => {
    event.preventDefault();
    event.stopPropagation();
    if (!menuRef.current.contains(event.target)) {
      setShowMenu(!showMenu);
    }
  };

  const [activityModal, showActivityModal] = useState(false);


  // useEffect(() => {
  //   setBaseItem(item)
  // }, [item, isopen])

  useEffect(() => {
    if (showMenu) {
      document.addEventListener('click', handleMenu);
    } else {
      document.removeEventListener('click', handleMenu);
    }
    return () => {
      document.removeEventListener('click', handleMenu);
    };
  }, [showMenu]);

  const handleCompleteTask = () => { };
  const handleViewTask = e => {
    e.preventDefault();

    console.log('clickc', item);
    if (taskType === 'base') {
      EventRegister.emit('openBaseActivityModal', {...item, isPreview: true});
    }
    if (taskType === 'plan') {
      EventRegister.emit('openPlanActivityModal', item);
    }
  };


  useEventEmitter('refresh-tasks-forms', () => { })


  const handleEditTask = e => {
    e.preventDefault();

    // console.log('taskType', taskType);
    if (taskType === 'base') {
      // console.log('taskType', taskType);
      // navigation.navigate('HubAddTask', {
      //   item: item,
      //   isEdit: true,
      //   // fromPath: 'HubCreateTask',
      // });
      // console.log('first', item);
      setIsOpen(true);
      // EventRegister.emit('openEditBaseActivityModal', item);
    }
    if (taskType === 'plan') {
      EventRegister.emit('openPlanActivityModal', item);
      console.log('taskType', taskType);
    }
  };

  const deleteTask = () => {
    // dispatch(closeModal());
    setIsLoading(true);
    setOpenDeleteModal(false);
    if (taskType === 'base') {
      deleteBase(id, queryParam, userData.token, deleteResponse => {
        console.log('deleteResponse ===> ', JSON.stringify(deleteResponse));
        setIsLoading(false);
        // setdeleteLoading(false);
        ShowSuccess('Activity deleted successfully', toast);
        EventRegister.emit('refresh-baseActivities');

        // navigate('HubTasks');
      });
    }
    if (taskType === 'plan') {
      // deletePlanned(
      //   id,
      //   userData.token,
      //   deleteResponse => {
      //     console.log('deleteResponseHeres', deleteResponse);
      //     setIsLoading(false);
      //     ShowSuccess('Activity deleted successfully', toast);
      //     EventRegister.emit('refresh-planActivities');
      //   },
      // );
    }
  };

  const handleDeleteTask = e => {
    e.preventDefault();

    setOpenDeleteModal(true);
    // dispatch(
    //   openModal({
    //     modalName: 'renderAlert',
    //     modalType: 'alert',

    //     modalProps: {
    //       nameAlert: 'DeleteTaskAlert',
    //       propsAlert: {
    //         deleteCallback: deleteTask,
    //         closeModal: () => dispatch(closeModal()),
    //       },
    //     },
    //   }),
    // );
  };

  return (
    // <div className={`menu-card ${showMenu ? 'open' : ''}`}>
    //   <button onClick={handleMenu}>
    //     <img src="/images/assets/info-card/dots_menu.svg" alt="dots menu" />
    //   </button>
    //   <div
    //     ref={menuRef}
    //     className={`menu-card__wrapper ${showMenu ? 'open' : ''}`}>
    //     <InfoCardMenuDesktop
    //       handleEditTask={handleEditTask}
    //       handleCompleteTask={handleCompleteTask}
    //       handleDeleteTask={handleDeleteTask}
    //     />
    //     <InfoCardMenuMobile />
    //   </div>
    // </div>

    <>
      <Loading
        loading={loading}
        background="transparent"
        loaderColor={colors.PRIMARY_COLOR}
      />
      <Menu
        as="div"
        className="absolute right-0 top-2 xl:relative xl:right-auto xl:top-auto xl:self-center">
        <div>
          <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-500 hover:text-gray-600">
            <span className="sr-only">Open options</span>
            <img src="/images/assets/info-card/dots_menu.svg" alt="dots menu" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items className="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none w-48">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={handleViewTask}
                    href="#"
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm ',
                    )}>
                    Preview
                  </div>
                )}
              </Menu.Item>

              {taskType === 'base' && item?.createdById == userData?.id && (
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={handleEditTask}
                      href="#"
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm',
                      )}>
                      Edit
                    </div>
                  )}
                </Menu.Item>
              )}

              {item?.isCompleted == false &&
                isMainActivity == true &&
                item?.goal && (
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        onClick={() => {
                          showActivityModal(() => true);
                        }}
                        href="#"
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm',
                        )}>
                        Goal Progress
                      </div>
                    )}
                  </Menu.Item>
                )}

              {isMainActivity == true && item?.isProgressImageEnable && (
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={() => {
                        showUpdateProgressModal(() => true);
                      }}
                      href="#"
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm',
                      )}>
                      Update Progress
                    </div>
                  )}
                </Menu.Item>
              )}

              {taskType !== 'plan' && (
                <Menu.Item>
                  {({ active }) => (
                    <div
                      // onClick={handleDeleteTask}
                      onClick={() => {
                        setOpen(() => true);
                      }}
                      href="#"
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm',
                      )}>
                      Delete
                    </div>
                  )}
                </Menu.Item>
              )}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>



      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={rootButtonRef}
          onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">


                  <div className="sm:flex sm:items-start">

                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900">
                        Are you sure you want to?
                      </Dialog.Title>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">

                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={(e) => {
                        setQueryParam("onlyBaseTask")
                        setTimeout(() => {
                          handleDeleteTask(e)
                        }, 350);
                      }}
                      ref={rootButtonRef}>
                      {"Delete base activity only"}
                    </button>

                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={(e) => {
                        setQueryParam("baseAndAllPlannedTask")
                        setTimeout(() => {
                          handleDeleteTask(e)
                        }, 350);
                      }}
                      ref={rootButtonRef}>
                      {"Delete base activity and all related plan activities"}
                    </button>

                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={(e) => {
                        setQueryParam("onlyMyPlannedTask")
                        setTimeout(() => {
                          handleDeleteTask(e)
                        }, 350);
                      }}
                      ref={rootButtonRef}>
                      {"Delete all related plan activities"}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <GoalProgressModal
        item={item}
        activityModal={activityModal}
        showActivityModal={showActivityModal}
      />

      <UpdateProgressModal
        item={item}
        activityModal={updateProgressModal}
        showActivityModal={showUpdateProgressModal}
      />

      <HubAddTaskv2
        param={{ item: item, isEdit: true }}
        is_open={isopen}
        set_is_open={setIsOpen}
      />
      {DeleteActivityModal(openDeleteModal, setOpenDeleteModal, deleteTask)}
    </>
  );
};

export default InfoCardMenu;
