import React, {useEffect, useState} from 'react';

import cn from 'classnames';
import CustomRating from '../../../../components/reusable/assets/customRating/CustomRating';
import Verified from '../../../../components/reusable/bio/verified/Verified';
import {useBuzzmiAuth, useProfileApp} from '../../../../appState/hooks';
import {useNavigation} from '@react-navigation/native';
import {removeData} from '../../../../res/asyncStorageHelper.web';
import {useDispatch} from 'react-redux';
import {
  closeModal,
  openModal,
} from '../../../../store/slices/modalSlice/modalSlice';
import {useProfileInfo} from '../../../../appState/hooks/useProfileInfo';
import SharePostModal from '../ProfileMain/componentsV2/SharePostModel';

const ProfileBioV2 = ({isOtherProfile}) => {
  const {replace} = useNavigation();
  const {logout, userData} = useBuzzmiAuth();
  const {profileShareData, getProfileShareDetail} = useProfileApp();
  const {profileInfo} = useProfileInfo();
  const [openShareModal, setOpenShareModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const dispatch = useDispatch();
  const [user, setUser] = useState({});
  const [userProfileData, setuserProfileData] = useState(null);

  const {navigate} = useNavigation();

  useEffect(() => {
    getProfileShareDetail(userData.token, null);
  }, [userData]);

  useEffect(() => {
    setuserProfileData(profileShareData);
  }, [profileShareData]);

  const handleShare = () => {
    setModalData({
      userName: `@${userData?.displayName}`,
      userShareDetails: userProfileData,
      userAvatar: userData?.avatar
        ? userData?.avatar
        : '/images/moc/avatar-big.png',
      typeSharingItem: 'Profile',
    });
    setOpenShareModal(true);
    // dispatch(
    //   openModal({
    //     modalName: 'ShareModal',
    //     modalType: 'swipe-pink',
    //     modalProps: {
    //       userName: `@${userData?.displayName}`,
    //       userShareDetails: userProfileData,
    //       userAvatar: userData?.avatar
    //         ? userData?.avatar
    //         : '/images/moc/avatar-big.png',
    //       typeSharingItem: 'Profile',
    //     },
    //     modalTitle: 'Share',
    //   }),
    // );
  };
  const Logout = () => {
    dispatch(closeModal());
    setTimeout(() => {
      removeData('userDetails');
      removeData('company_id');
      logout();
      setTimeout(() => {
        window.location.reload();
        replace('Auth');
      }, 150);
    }, 500);
  };
  const handleLogout = () => {
    dispatch(
      openModal({
        modalName: 'renderAlert',
        modalType: 'alert',

        modalProps: {
          nameAlert: 'LogoutAlert',
          propsAlert: {
            logoutCallback: Logout,
            closeModal: () => dispatch(closeModal()),
          },
        },
      }),
    );
    console.log('logged ouet');
  };

  return (
    <>
      <div className="ml-3">
        <div className="plan-bio__info profile-top__bio">
          {/* <div className="h3">
          {userData?.firstName + " " + userData?.lastName} <Verified isVerified={user?.isVerified} />
        </div> */}

          <div className="h3">
            <div className="profile_name">
              <div className="user_title">
                {isOtherProfile && profileInfo
                  ? profileInfo?.firstName + ' ' + profileInfo?.lastName
                  : userData?.firstName + ' ' + userData?.lastName}{' '}
              </div>
              <Verified isVerified={user?.isVerified} />
            </div>
          </div>
          <div className="profile-top__row">
            <span className="text-b">
              @
              {isOtherProfile && profileInfo
                ? profileInfo?.displayName
                : userData?.displayName}
            </span>
            <button onClick={handleShare} className="text-r text-alt-primary">
              Share
            </button>
          </div>

          {/* <div className="d-flex align-items-center flex-direction-column">
          <CustomRating initialValue={user?.rating?.value} readonly />
          <span
          className={cn('text-r ms-2 mt-1', {
            'text-secondary': user?.rating?.count === 0,
          })}>
          {user?.rating?.count} reviews
          </span>
        </div> */}
          {/* <div className="profile-top__row">
          <button
          onClick={() => {
            navigate('AddPosts');
          }}
          className="text-r text-alt-primary">
          Create Post
          </button>
          </div>
          
          <div className="profile-top__row">
          <button onClick={handleLogout} className="text-r text-alt-primary">
          Logout
          </button>
        </div> */}
        </div>
      </div>
      <SharePostModal
        open={openShareModal}
        data={{...modalData}}
        setOpen={setOpenShareModal}
      />
    </>
  );
};

export default ProfileBioV2;
