import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useBuzzmiAuth } from "../../../../appState/hooks/useBuzzmiAuth/index.web";
import { closeModal } from "../../../../store/slices/modalSlice/modalSlice";
import Button from "../../../reusable/btns/buttons/Button"

const AddLinksToBio = () => {
  const { userData } = useBuzzmiAuth();
  const [newLink, setNewLink] = useState(userData?.bioLink || '');
  const dispatch = useDispatch();
  const { callback } = useSelector(state => state.modal);

  const handleChange = e => setNewLink(e.target.value);

  return (
    <div className="">
      <div className={"font-bold mb-2"}>Your bio link</div>
      <ul>
        <li className="d-flex align-items-center">
          <input
            type="text"
            value={newLink}
            className="input input_simple-text"
            onChange={handleChange}
            placeholder="Add your link"
          />
          {/* <span className="text-alt-primary ms-3">Add</span> */}
        </li>
      </ul>
      <div className="d-flex align-items-center justify-content-center mt-4">
        <Button style={{ color: '#eb4dac' }} type={"button"} className="_fw button_primary custom_fw_btn" onClick={() => {
          if (newLink == '') {
            alert("Please enter add new link")
          } else {
            if (callback) {
              callback(newLink);
            }
            dispatch(closeModal());
          }
        }}>
          Submit
        </Button>
      </div>
    </div>
  )
}

export default AddLinksToBio
