import React from 'react';
import {
  TrophyIcon,
  RectangleStackIcon,
  PresentationChartLineIcon,
} from '@heroicons/react/24/outline';
import MainNewLayoutMenuItem from './MainNewLayoutMenuItem';

global.activeMenu = '/hub/hub-activities';
const navigation = [
  {
    name: 'Activity',
    href: '#',
    icon: RectangleStackIcon,
    current: false,
    to: '/hub/v2/tasks',
  },
  {
    name: 'State',
    href: '#',
    icon: PresentationChartLineIcon,
    current: false,
    to: '/hub/v2/tasks:1',
  },
  {
    name: 'LeaderBoard',
    href: '#',
    icon: TrophyIcon,
    current: false,
    to: '/hub/v2/leaderboard',
  },
  // { name: 'Home', href: '#', icon: HomeIcon, current: false, to: '/hub/home' },
  // {
  //   name: 'Hub',
  //   href: '#',
  //   icon: ServerIcon,
  //   current: true,
  //   to: '/hub/hub-activities',
  // },
  // { name: 'Profile', href: '#', icon: UserIcon, current: false, to: '/profile' },
  // {
  //   name: 'Messages',
  //   href: '#',
  //   icon: ChatBubbleOvalLeftEllipsisIcon,
  //   current: false,
  //   to: '/messages',
  // },
  // {
  //   name: 'Space',
  //   href: '#',
  //   icon: RocketLaunchIcon,
  //   current: false,
  //   to: '/space',
  // },
  // {
  //   name: 'Subscription',
  //   href: '#',
  //   icon: BanknotesIcon,
  //   current: false,
  //   to: '/subscription',
  // },
  // {
  //   name: 'Challenges',
  //   href: '#',
  //   icon: RocketLaunchIcon,
  //   current: false,
  //   to: '/challenges',
  // },
  // {
  //   name: 'Calendar',
  //   href: '#',
  //   icon: CalendarDaysIcon,
  //   current: false,
  //   to: '/calendar',
  // },
  // { name: 'Wallet', href: '#', icon: WalletIcon, current: false, to: '/wallet' },
];

const onHandelClick = to => {
  global.assignMenu = 'Hub';
  global.activeMenu = to;
};

const MainNewLayoutSidebar = props => {
  return (
    <div className="hidden xl:fixed xl:z-50 xl:flex xl:w-72 xl:flex-col top-[65px] h-full">
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div
        className="flex grow flex-col gap-y-5 overflow-y-auto bg-black/10 px-6 ring-1 ring-white/5"
        style={{
          backgroundColor: '#FFF',
          borderColor: '#f9f6ff',
          borderRightWidth: 4,
        }}>
        <nav className="flex flex-1 flex-col mt-5">
          <ul role="list" className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" className="mx-2 space-y-1">
                {navigation.map(item => (
                  <MainNewLayoutMenuItem
                    item={item}
                    current={global.activeMenu}
                    onHandelClick={onHandelClick}
                  />
                ))}
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};
export default MainNewLayoutSidebar;
