/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext, useCallback, useRef } from 'react';
import { TouchableOpacity, Modal, Alert, Image, View, Pressable, Dimensions, StyleSheet, ScrollView, FlatList, ImageBackground, RefreshControl, ActivityIndicator } from 'react-native';
import { colors } from '../../../res/colors';
import { fonts } from '../../../res/fonts';
import { images } from '../../../res/images';
import { Spacer } from '../../../res/spacer';
import { MainContainer, Text, styles, SearchBarContainer, SearchIcon, SearchInput, ClearButton, ClearIcon } from './style';
import { GlobalBorder, GlobalFlex, GlobalHeader } from '../../../res/globalStyles';
import { newsData } from '../../../res/data';
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from 'react-native-responsive-screen';
import { useDirectNavigation } from '../../../navigations/useDirectNavigation.ts';
import deviceInfoModule from 'react-native-device-info';
import { COMMUNITY_POST, COMMUNITY_POST_SEARCH } from '../../../api_helper/Api';
import { GET_DATA } from '../../../api_helper/ApiServices';
import moment from 'moment';
import { useIsFocused } from '@react-navigation/native';
import { BorderContainer } from './CreatePost/style';
import Reels from '../../../components/ReelContainer/components/Reels';
import { ShowError, ShowSuccess } from '../../HOC/MessageWeb';
import { useToast } from 'react-native-toast-notifications';
import { useBuzzmiAuth, useReels } from "../../../appState/hooks";
import usePostList from '../../../hooks/usePostList';

const PAGE_LIMIT = 25;

const ScreenWidth = Dimensions.get('window').width;

const HomeDashboard = (props) => {
    const isFocused = useIsFocused();
    const toast = useToast();
    const [userAction, setUserAction] = useState(false);
    const [index, setIndex] = useState(0);
    const { navigation } = useDirectNavigation();
    const [isLoad, setIsLoading] = useState(false)
    const [is_search, setIsSearch] = useState(false);

    const [page, setPage] = useState(1);
    const [HeaderHeight, setHeaderHeight] = useState(0);

    const [v_post, setPost] = useState(null);

    const { userReelData, actionDeletePost, actionPostLike, actionPostDisLike } = useReels();

    const { userData } = useBuzzmiAuth();

    const [isRefreshing, setIsRefreshing] = useState(false);
    const [postData, setPostData] = useState([])
    const [postDataSearch, setPostDataSearch] = useState([])
    const [query, setQuery] = useState('');
    const [mathRandom, setMathRandom] = useState(Math.random());

    const flatListRef = useRef([])
    const flatSearchListRef = useRef([])
    const [showActionMenu, setShowActionMenu] = useState(false);
    const { data, isLoading, isFetching, error, refetch } = usePostList(userData && Object.assign(userData, { "page": page }));

    const [datamap, setDataMap] = useState([
        {
            id: 0,
            images: images.bg_gray,
            description: "Me"
        },
        {
            id: 1,
            images: images.story,
            description: "Films"
        },
        {
            id: 2,
            images: images.story,
            description: "Films2"
        },
        {
            id: 3,
            images: images.story,
            description: "Films3"
        }, {
            id: 4,
            images: images.story,
            description: "Films4"
        }, {
            id: 5,
            images: images.story,
            description: "Films5"
        },
    ])

    const getPostList = async (page_data) => {
        setIsLoading(true)
        console.log('token data ===> ', userData);
        const uniqueID = await deviceInfoModule.getUniqueId();
        GET_DATA(COMMUNITY_POST + `?page=${page_data}&limit=${PAGE_LIMIT}`, true, userData.token, uniqueID, (data) => {
            setIsLoading(false)
            if (data?.status && (data?.status == 401 || data?.status == 400)) {
                setPostData([]);
            } else {
                if (data.length > 0) {
                    if (page_data === 1) {
                        setPostData(data);
                    } else {
                        setPostData([...postData, ...data]);
                    }
                }
            }
        })
    }

    const getUserSearchPost = (query, page_data) => {
        setIsLoading(true)
        setTimeout(async () => {
            const uniqueID = await deviceInfoModule.getUniqueId();
            GET_DATA(COMMUNITY_POST_SEARCH + `?page=${page_data}&limit=${PAGE_LIMIT}&query=${query}&type=post`, true, userData.token, uniqueID, (data) => {
                setIsLoading(false)
                if (data.length > 0) {
                    setPostDataSearch(data);
                } else {
                    setPostDataSearch([]);
                }
            })
        }, 1000);
    }


    const postLike = async (post_id) => {
        actionPostLike(post_id, (data, flag) => {
            setIsLoading(false)
            if (flag == false) {
                postData.filter(x => x._id === post_id)[0].post?.upvotes.push({ "id": userData?.tribeUserId })
                getUserSearchPost(query, 1)
            }
        })
    }

    const postDisLike = async (post_id) => {
        setIsLoading(true)
        actionPostDisLike(post_id, (data, flag) => {
            setIsLoading(false)
            if (flag == false) {
                postData.filter(x => x._id === post_id)[0].post?.upvotes.splice(postData.filter(x => x._id === post_id)[0].post?.upvotes.findIndex(a => a.id === userData?.tribeUserId), 1)
                getUserSearchPost(query, 1)
            }
        })
    }


    const openActionModal = () => {
        return (
            <Modal visible={showActionMenu}
                animationType="slide"
                presentationStyle={'formSheet'}
                transparent={true}
            >
                <View style={pstyles.centeredView}>
                    <View style={pstyles.modalView}>

                        <Pressable style={[pstyles.button]} onPress={() => {
                            setShowActionMenu(!showActionMenu)
                            setTimeout(() => {
                                navigation.navigate("EditPost", { "post_data": v_post })
                            }, 100);

                        }} >
                            <Text style={pstyles.textStyle}>Edit</Text>
                        </Pressable>
                        <Spacer space={hp(1)} />
                        <Pressable style={[pstyles.button]} onPress={() => {
                            setShowActionMenu(!showActionMenu),
                                setTimeout(() => {
                                    onDeletePost()
                                }, 100);
                        }}>
                            <Text style={pstyles.textStyle}>Delete</Text>
                        </Pressable>
                        <Spacer space={hp(1)} />
                        <Pressable style={[pstyles.button, { backgroundColor: colors.GHOST_WHITE }]} onPress={() => {
                            setShowActionMenu(!showActionMenu)
                        }}>
                            <Text style={pstyles.textStyle}>Cancel </Text>
                        </Pressable>

                    </View>
                </View>
            </Modal>
        )
    }


    const onDeletePost = async () => {
        actionDeletePost(userData?.token, v_post._id, (data, flag) => {
            if (flag == false) {
                // getPostList(page)
                refetch()
                setTimeout(() => {
                    ShowSuccess("Post Deleted Successfully", toast)
                }, 100);
            }
        })
    }

    const wait = (timeout) => { // Defined the timeout function for testing purpose
        return new Promise(resolve => setTimeout(resolve, timeout));
    }

    const onRefresh = useCallback(() => {
        console.log("called 111")
        setIsRefreshing(true);
        wait(2000).then(() =>
            setIsRefreshing(false),
            refetch()
        );
    }, []);


    useEffect(() => {
        if (data?.status && (data?.status == 401 || data?.status == 400)) {
            setPostData([]);
        } else {
            if (data?.length > 0) {
                if (page === 1) {
                    setPostData(data);
                } else {
                    setMathRandom(Math.random());
                    setPostData([...postData, ...data]);
                }
            }
        }
    }, [data, isFetching]);


    //#region  News Route
    const Item = ({ item }) => (
        <>
            <View style={{ ...styles.flex_wrapper, width: wp(92) }}>
                <View style={styles.flex_start}>
                    <Text style={{ color: colors.TRIPLET_PLACEHOLDER, fontSize: wp(3.5), fontFamily: fonts.REGULAR }}>{"Trending"}</Text>
                    <Text style={{ fontSize: wp(3.8), fontFamily: fonts.MEDIUM }}>{item?.trending}</Text>
                    <Text style={{ color: colors.TRIPLET_PLACEHOLDER, fontSize: wp(3.5), fontFamily: fonts.REGULAR }}>{item?.tweets + ' tweets'}</Text>
                </View>
                <TouchableOpacity style={{ alignSelf: 'flex-start', marginTop: wp(2) }} >
                    <Image source={images.more_ic} style={{ ...styles.medium_card_ic }} />
                </TouchableOpacity>
            </View>
            <GlobalBorder />
        </>
    );

    const renderItem = ({ item }) => (
        <Item item={item} />
    );

    const loadMoreData = () => {
        setPage(page + 1)
        // getPostList(page + 1)
    }

    const NewsRouts = () => (
        <MainContainer style={{ marginTop: -5 }}>
            <ScrollView showsVerticalScrollIndicator={false} bounces={false}>
                <ImageBackground source={images.bg_search_page} style={{ height: hp(23), width: wp(100) }} resizeMode={"contain"}>
                    <View style={{ height: hp(23), }}>
                        <Text style={{ color: colors.WHITE, bottom: wp(12), left: wp(2), position: 'absolute', fontFamily: fonts.REGULAR, fontSize: wp(3.8) }}>Entertainment LIVE</Text>
                        <Text style={{ color: colors.WHITE, bottom: wp(5), left: wp(2), position: 'absolute' }}>Last news</Text>
                    </View>
                </ImageBackground>
                <Spacer space={hp(1)} />
                <View style={{ marginHorizontal: wp(4), marginBottom: wp(2) }}>
                    <Text style={{ fontSize: wp(5), textAlign: 'left' }}>{"Useful topics for you"}</Text>
                </View>
                <FlatList
                    data={newsData}
                    renderItem={renderItem}
                    keyExtractor={item => item.id}
                />
            </ScrollView>
        </MainContainer>
    )
    //#endregion


    //#region tranding route
    const FirstRoute = () => (
        <MainContainer style={[{ width: ScreenWidth > 670 ? hp(75) : wp(100), alignSelf: "center" }]}>

            {is_search == false ?
                (postData.length > 0 ?
                    <FlatList
                        data={postData}
                        bounces={true}
                        extraData={mathRandom}
                        showsHorizontalScrollIndicator={false}
                        showsVerticalScrollIndicator={false}
                        onEndReached={loadMoreData}
                        onEndReachedThreshold={0.2}
                        ListHeaderComponent={() =>
                            <>
                                <Spacer space={hp(0.5)} />
                            </>
                        }

                        renderItem={({ item }) => {
                            return (
                                <>
                                    <>
                                        <View style={{ ...styles.flex_wrapper, width: ScreenWidth > 670 ? hp(75) : wp(92) }}>
                                            <View style={{ flexDirection: "row" }}>

                                                {item?.post.user && <Image source={{ uri: item?.post.user.profile.picture }} resizeMode="cover" style={styles.card_profile} />}

                                                <View style={{ alignSelf: "center" }}>
                                                    <Text style={{ textAlign: 'left', fontFamily: fonts.MEDIUM, fontSize: hp(2) }} numberOfLines={1}>{item?.post?.user ? item?.post.user.profile.username : ''}</Text>
                                                    <Text style={{ textAlign: 'left', marginTop: wp(0.5), fontSize: hp(2.1), fontFamily: fonts.REGULAR, color: colors.TRIPLET_PLACEHOLDER }}>{moment(item?.publishedAt).format("ddd, Do MMM YYYY, h:mm a")}</Text>
                                                </View>
                                            </View>

                                            {item?.post.user._id == userData?.tribeUserId &&
                                                <View style={{ flexDirection: "row", alignSelf: "center" }}>
                                                    <TouchableOpacity onPress={() => {
                                                        setPost(item?.post)
                                                        setShowActionMenu(!showActionMenu)
                                                    }}>
                                                        <Image source={images.more_ic} style={{ ...styles.medium_card_ic }} />
                                                    </TouchableOpacity>
                                                </View>
                                            }
                                        </View>
                                        <Spacer space={hp(0.2)} />
                                        <Text style={{ textAlign: 'left', marginLeft: hp(12), fontFamily: fonts.REGULAR, fontSize: hp(2.4) }} >{item?.post.summary.replace(/<\/?[^>]+(>|$)/g, "")}</Text>
                                        <Spacer space={hp(0.5)} />
                                        {item.post.images.length > 0 &&
                                            <FlatList
                                                data={item.post.images}
                                                horizontal={true}
                                                bounces={false}
                                                initialScrollIndex={0}
                                                ref={flat => flatListRef.current[item?._id] = flat}
                                                keyExtractor={(item, index) => index.toString()}
                                                showsHorizontalScrollIndicator={false}
                                                renderItem={({ item: mainitem, index }) => {
                                                    return (
                                                        <>
                                                            <TouchableOpacity activeOpacity={0.7} key={index.toString()}
                                                                onPress={() => {
                                                                    setTimeout(() => {
                                                                        flatListRef.current[item?._id].scrollToIndex({ index: ((item.post.images.length - 1) == index) ? 0 : item.post.images.length > 0 ? index + 1 : index, animated: true }) // Scroll to day 10
                                                                    }, 500);
                                                                }}
                                                            >
                                                                <Image source={{ uri: mainitem.src ? mainitem.src : `https://app-us-east-1.t-cdn.net/637e7659c38ca4d56de49d13/posts/${mainitem.code}/${mainitem.meta.name}` }} style={styles.card_image} ></Image>
                                                            </TouchableOpacity>
                                                        </>
                                                    )
                                                }}
                                            />
                                        }
                                        {item.post.images.length > 0 &&
                                            <View style={{
                                                flexDirection: "row", alignItems: 'center', alignSelf: 'center'
                                            }}>
                                                {item.post.images.map((item, i) => (
                                                    <>
                                                        <View key={i} style={{ marginHorizontal: 5, borderRadius: 12, backgroundColor: colors.WHITE, height: 10, width: 10, zIndex: 1, bottom: hp(5) }} />
                                                    </>
                                                ))}
                                            </View>
                                        }

                                    </>
                                    <Spacer space={hp(0.3)} />
                                    <View style={{ ...styles.flex_wrapper, width: ScreenWidth > 670 ? hp(75) : wp(92), paddingVertical: hp(0.8), justifyContent: 'flex-start' }}>
                                        <TouchableOpacity activeOpacity={0.7} onPress={() => {
                                            item?.post.upvotes.filter(o => o.id === userData?.tribeUserId).length > 0 ? postDisLike(item?.post.id) : postLike(item?.post.id)
                                        }}>
                                            <Image source={item?.post.upvotes.filter(o => o.id === userData?.tribeUserId).length > 0 ? images.balance_ic : images.share_money_ic} style={{ width: hp(3), height: hp(3) }} />
                                        </TouchableOpacity>
                                        <Text style={[{ fontSize: hp(2), marginLeft: wp(0.2), color: colors.PRIMARY_COLOR, fontFamily: fonts.REGULAR, alignSelf: 'center' }]}>{item.post.upvotes?.length}</Text>

                                        <TouchableOpacity activeOpacity={0.7} onPress={() => { props.navigation.navigate("PostComment", { "item": item?.post }) }} style={{ alignSelf: "center", marginLeft: wp(1) }}>
                                            <Image source={images.message_ic} resizeMode={"contain"} style={{ width: hp(2.2), height: hp(2.2), alignSelf: "center" }} />
                                        </TouchableOpacity>
                                        <Text style={[{ fontSize: hp(2), marginLeft: wp(0.5), color: colors.PRIMARY_COLOR, fontFamily: fonts.REGULAR, alignSelf: 'center' }]}>{item.post.counts?.comments}</Text>
                                    </View>
                                    <Spacer space={hp(0.2)} />
                                    <BorderContainer style={[{ height: hp(0.2) }]} />
                                    <Spacer space={hp(0.5)} />
                                </>
                            )
                        }}
                        keyExtractor={(item, index) => index}
                    />
                    :
                    isLoad == false &&
                    <View style={{ flex: 1, justifyContent: "center" }}>
                        <Text>
                            {"No post found"}
                        </Text>
                    </View>
                )
                :
                postDataSearch.length > 0 ?
                    <FlatList
                        data={postDataSearch}
                        bounces={false}
                        extraData={postDataSearch}
                        showsHorizontalScrollIndicator={false}
                        showsVerticalScrollIndicator={false}
                        ListHeaderComponent={() =>
                            <>
                                <Spacer space={hp(0.5)} />
                            </>
                        }
                        renderItem={({ item }) => {
                            return (
                                <>
                                    <>
                                        <View style={{ ...styles.flex_wrapper, width: ScreenWidth > 670 ? hp(75) : wp(92) }}>
                                            <View style={{ flexDirection: "row" }}>

                                                {item?.user && <Image source={{ uri: item?.user.profile.picture }} style={styles.card_profile} />}

                                                <View style={{ alignSelf: "center" }}>
                                                    <Text style={{ textAlign: 'left', fontFamily: fonts.MEDIUM, }} numberOfLines={1}>{item?.user ? item?.user.profile.username : ''}</Text>
                                                    <Text style={{ textAlign: 'left', marginTop: wp(1), fontFamily: fonts.REGULAR, color: colors.TRIPLET_PLACEHOLDER }}>{moment(item?.publishedAt).format("ddd, Do MMM YYYY, h:mm a")}</Text>
                                                </View>
                                            </View>

                                            {item?.user._id == userData?.tribeUserId &&
                                                <View style={{ flexDirection: "row", alignSelf: "center" }}>
                                                    <TouchableOpacity onPress={() => {
                                                        setPost(item)
                                                        setUserAction(!userAction)
                                                    }}>
                                                        <Image source={images.more_ic} style={{ ...styles.medium_card_ic }} />
                                                    </TouchableOpacity>
                                                </View>
                                            }
                                        </View>
                                        <Spacer space={hp(0.2)} />
                                        <Text style={{ textAlign: 'left', marginLeft: hp(12), fontFamily: fonts.REGULAR, fontSize: hp(2.4) }} >{item?.summary.replace(/<\/?[^>]+(>|$)/g, "")}</Text>
                                        <Spacer space={hp(0.2)} />

                                        {item.images.length > 0 &&
                                            <FlatList
                                                initialScrollIndex={0}
                                                data={item.images}
                                                bounces={false}
                                                horizontal={true}
                                                ref={flat => flatSearchListRef.current[item?._id] = flat}
                                                extraData={postDataSearch}
                                                showsHorizontalScrollIndicator={false}
                                                renderItem={({ item: mitem, index }) => {
                                                    return (
                                                        <>
                                                            <TouchableOpacity activeOpacity={0.7} key={index.toString()}
                                                                onPress={() => {
                                                                    setTimeout(() => {
                                                                        flatSearchListRef.current[item?._id].scrollToIndex({ index: ((item.images.length - 1) == index) ? 0 : item.images.length > 0 ? index + 1 : index, animated: true }) // Scroll to day 10
                                                                    }, 500);
                                                                }}
                                                            >
                                                                <Image source={{ uri: mitem.src ? mitem.src : `https://app-us-east-1.t-cdn.net/637e7659c38ca4d56de49d13/posts/${mitem.code}/${mitem.meta.name}` }} style={styles.card_image} ></Image>
                                                            </TouchableOpacity>
                                                        </>
                                                    )
                                                }}
                                            />
                                        }
                                        {item.images.length > 0 &&
                                            <View style={{
                                                flexDirection: "row", alignItems: 'center', alignSelf: 'center'
                                            }}>
                                                {item.images.map((item, i) => (
                                                    <>
                                                        <View key={i} style={{ marginHorizontal: 5, borderRadius: 12, backgroundColor: colors.WHITE, height: 10, width: 10, zIndex: 1, bottom: hp(5) }} />
                                                    </>
                                                ))}
                                            </View>
                                        }

                                    </>
                                    <Spacer space={hp(0.3)} />
                                    <View style={{ ...styles.flex_wrapper, width: ScreenWidth > 670 ? hp(75) : wp(92), paddingVertical: hp(0.8), justifyContent: 'flex-start' }}>
                                        <TouchableOpacity activeOpacity={0.7} onPress={() => {
                                            console.log(item?.upvotes)
                                            item?.upvotes.filter(o => o.id === userData?.tribeUserId).length > 0 ? postDisLike(item?.id) : postLike(item?.id)
                                        }}>
                                            <Image source={item?.upvotes.filter(o => o.id === userData?.tribeUserId).length > 0 ? images.balance_ic : images.share_money_ic} style={{ width: hp(3), height: hp(3) }} />
                                        </TouchableOpacity>
                                        <Text style={[{ fontSize: hp(2), marginLeft: wp(0.2), color: colors.PRIMARY_COLOR, fontFamily: fonts.REGULAR, alignSelf: 'center' }]}>{item.upvotes?.length}</Text>

                                        <TouchableOpacity activeOpacity={0.7} onPress={() => { props.navigation.navigate("PostComment", { "item": item }) }} style={{ alignSelf: "center", marginLeft: wp(1) }}>
                                            <Image source={images.message_ic} resizeMode={"contain"} style={{ width: hp(2.2), height: hp(2.2), alignSelf: "center" }} />
                                        </TouchableOpacity>
                                        <Text style={[{ fontSize: hp(2), marginLeft: wp(0.5), color: colors.PRIMARY_COLOR, fontFamily: fonts.REGULAR, alignSelf: 'center' }]}>{item.counts?.comments}</Text>
                                    </View>
                                    <Spacer space={hp(0.2)} />
                                    <BorderContainer style={[{ height: hp(0.2) }]} />
                                    <Spacer space={hp(0.5)} />
                                </>
                            )
                        }}
                        keyExtractor={(item, index) => index}
                    />
                    :
                    <View style={{ flex: 1, justifyContent: "center" }}>
                        <Text>
                            {"No search found"}
                        </Text>
                    </View>
            }
        </MainContainer >
    );

    const ListItem = ({ item }) => {
        return (
            <TouchableOpacity style={{ paddingHorizontal: wp(3) }}>
                {item.id == 0 && <Image source={images.plus_small} style={styles.add_new} />}
                <Image source={item.images} style={{ width: wp(16), height: wp(16) }} />
                <Text style={{ fontSize: wp(3.5), marginTop: wp(2), fontFamily: fonts.REGULAR }}>{item.description}</Text>
            </TouchableOpacity>
        );
    };


    return (
        <>
            <GlobalHeader onLayout={(event) => {
                var { height } = event.nativeEvent.layout;
                setHeaderHeight(height)
            }}>
                {/* <div>{isFetching ? "Updating..." : ""}</div> */}
                {openActionModal()}
                <View style={[styles.HeaderWrapper, { width: !is_search ? wp(100) : hp(60) }]}>

                    {!is_search &&
                        <>
                            <TouchableOpacity activeOpacity={0.7} style={{ marginLeft: wp(5) }}>
                                <Image source={userData?.avatar ? { uri: userData?.avatar } : images.avatar} style={styles.menu_avatar} />
                            </TouchableOpacity>

                            <View style={styles.header_title}>
                                <Text>Discover</Text>
                            </View>
                            <View style={styles.header_flex_wrapper}>
                                <TouchableOpacity onPress={() => { setPostDataSearch([]), setTimeout(() => { setIsSearch(!is_search) }, 100) }} activeOpacity={0.7} style={{ alignSelf: 'center' }}>
                                    <Image style={styles.header_icon_ic} resizeMode={"contain"} source={images.search_blue} />
                                </TouchableOpacity>
                            </View>
                        </>
                    }

                    {is_search && (
                        <View style={{ marginHorizontal: hp(1.5), flexDirection: "row" }}>
                            <SearchBarContainer>
                                <View style={{ width: hp(8), alignSelf: "center" }}>
                                    <SearchIcon style={styles.searchIconStyle} source={images.search_ic} />
                                </View>
                                <SearchInput
                                    autoFocus={true}
                                    value={query}
                                    placeholder={'Search'}
                                    placeholderTextColor={'#7b7d83'}
                                    onChangeText={val => {
                                        setQuery(() => val),
                                            val.length > 0 && getUserSearchPost(val, 1)
                                    }}
                                />
                                <ClearButton
                                    disabled={query.length <= 0}
                                    isActive={query.length > 0}
                                    onPress={() => {
                                        setQuery(() => ''),
                                            setIsSearch(!is_search)
                                    }
                                    }>
                                    <ClearIcon source={images.iconDecline_3x} />
                                </ClearButton>
                            </SearchBarContainer>

                            <TouchableOpacity style={{ height: hp(4), alignSelf: "center" }} onPress={() => { setQuery(() => ''), setIsSearch(!is_search) }}>
                                <Text style={{ fontSize: hp(2), fontFamily: fonts.REGULAR, marginHorizontal: 0 }}>Clear</Text>
                            </TouchableOpacity>
                        </View>
                    )}
                </View>

                <Spacer space={hp(0.8)} />
                <View style={{ ...styles.flex_wrapper, borderBottomWidth: wp(0.2), borderBottomColor: colors.PAINT_BORDER, }}>
                    <TouchableOpacity onPress={() => { setIndex(0) }} style={{ ...styles.tab_wrapper, borderBottomColor: index == 0 ? colors.PRIMARY_COLOR : colors.PAINT_BORDER, borderBottomWidth: index == 0 ? wp(0.2) : 0 }}>
                        <Text style={[{ color: index == 0 ? colors.PRIMARY_COLOR : colors.BLACK }]}>For You</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => { setIndex(1) }} style={{ ...styles.tab_wrapper, borderBottomColor: index == 1 ? colors.PRIMARY_COLOR : colors.PAINT_BORDER, borderBottomWidth: index == 1 ? wp(0.2) : 0 }}>
                        <Text style={[{ color: index == 1 ? colors.PRIMARY_COLOR : colors.BLACK }]}>Video</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => { setIndex(2) }} style={{ ...styles.tab_wrapper, borderBottomColor: index == 2 ? colors.PRIMARY_COLOR : colors.PAINT_BORDER, borderBottomWidth: index == 2 ? wp(0.2) : 0 }}>
                        <Text style={[{ color: index == 2 ? colors.PRIMARY_COLOR : colors.BLACK }]}>News</Text>
                    </TouchableOpacity>
                </View>
            </GlobalHeader>



            <GlobalFlex>
                {index == 0 ?
                    (FirstRoute()) : index == 1 ?
                        userReelData.length > 0 ?
                            <Reels videos={userReelData} headerHeight={HeaderHeight} my_reels={true} isSearch={false} />
                            :
                            <View style={{
                                flex: 1,
                                justifyContent: 'center'
                            }}>
                                <Text style={[{ textAlign: "center" }]}>No Reels Found</Text>
                            </View>
                        : FirstRoute()}

                {index != 1 &&
                    <TouchableOpacity style={styles.float_wrapper} onPress={() => { navigation.navigate('CreatePost') }}>
                        <Image source={images.add_full} style={styles.float_add_ic} />
                    </TouchableOpacity>
                }


                {isFetching && (
                    <ActivityIndicator
                        style={{
                            position: 'absolute',
                            justifyContent: 'center',
                            alignSelf: 'center',
                            width: wp(100),
                            height: hp(60),
                        }}
                        size={40}
                        color={colors.PRIMARY_COLOR}
                    />
                )}
            </GlobalFlex>
        </>
    )
}
export default HomeDashboard



const pstyles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
    },
    modalView: {
        margin: 20,
        backgroundColor: colors.PRIMARY_COLOR,
        borderRadius: 20,
        width: hp(30),
        height: hp(30),
        alignItems: 'center',
        justifyContent: "center",
        shadowColor: colors.BLACK,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    button: {
        padding: 10,
        elevation: 2,
        width: hp(20),
        borderRadius: 10,
        flexDirection: "row",
        justifyContent: "center",
        backgroundColor: colors.WHITE,
    },
    textStyle: {
        color: colors.PRIMARY_COLOR,
        fontWeight: fonts.BOLD,
        textAlign: 'center',
        alignSelf: "center",
        marginHorizontal: hp(2)
    },
    modalText: {
        marginBottom: 15,
        textAlign: 'center',
    },
    icon_ic: {
        width: hp(3), height: hp(3), alignSelf: "center", resizeMode: "contain", tintColor: colors.PRIMARY_COLOR
    }
});
