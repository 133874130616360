import React, { useEffect, useState } from 'react';
import CommentsList from '../../../../container/App/Profile/items/comments/CommentsList';
import AddComment from '../../../../container/App/Profile/items/comments/AddComment';
import { EventRegister } from 'react-native-event-listeners';
import { useProfileApp } from '../../../../appState/hooks';

const CommentsModal = ({ comments, userAvatar, entity, id }) => {
  const [commentData, setCommentData] = useState([]);
  const [item, setItem] = useState(null);
  const { userReels, userPosts } = useProfileApp();

  useEffect(() => {
    let foundItem = null;
    switch (entity) {
      case 'post':
        foundItem = userPosts.find(post => post.id === id);
        break;
      case 'reel':
        foundItem = userReels.find(reel => reel.id === id);
        break;
      default:
        break;
    }
    setItem(foundItem);
    setCommentData(foundItem?.object?.comments)
  }, [entity, id]);

  return (
    <div className="comments-modal">
      <h3 className="h3">Comments</h3>

      <CommentsList comments={comments} />
      <AddComment userAvatar={userAvatar}
        callback={(data, item) => {
          setCommentData([...commentData, data]);
          setTimeout(() => {
            EventRegister.emit('post_comments_length', { data: [...commentData, data], post_id: item?.id })
          }, 200);
        }}
        item={item}
      />
    </div>
  );
};

export default CommentsModal;
