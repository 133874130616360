import React from 'react';
import GoogleSvg from "../../../Login/items/serviceButtons/icons/GoogleSVG.jsx";
import FacebookSvg from "../../../Login/items/serviceButtons/icons/FacebookSVG.jsx";
import AppleSvg from "../../../Login/items/serviceButtons/icons/AppleSVG.jsx";
import RegularButton from '../../../../../components/reusable/form/items/buttons/RegularButton.jsx';

const OtherServicesButtons = () => {

  const handleGoogleClick = () => {
    //TODO code for auth with Google
  }

  const handleFacebookClick = () => {
    //TODO code for auth with Facebook
  }

  const handleAppleClick = () => {
    //TODO code for auth with Apple
  }

  return (
    <div className='services'>
      <RegularButton icon={<GoogleSvg />} className='_fw' onClick={handleGoogleClick}>Continue with Google</RegularButton>
      <RegularButton icon={<FacebookSvg />} className='_fw' onClick={handleFacebookClick}>Continue with Facebook</RegularButton>
      <RegularButton icon={<AppleSvg />} className='_fw' onClick={handleAppleClick}>Continue with Apple</RegularButton>
      <RegularButton icon={<GoogleSvg />} className='_fw' onClick={handleGoogleClick}>Continue with Google</RegularButton>
      <RegularButton icon={<FacebookSvg />} className='_fw' onClick={handleFacebookClick}>Continue with Facebook</RegularButton>
      <RegularButton icon={<AppleSvg />} className='_fw' onClick={handleAppleClick}>Continue with Apple</RegularButton>
      <RegularButton icon={<GoogleSvg />} className='_fw' onClick={handleGoogleClick}>Continue with Google</RegularButton>
      <RegularButton icon={<FacebookSvg />} className='_fw' onClick={handleFacebookClick}>Continue with Facebook</RegularButton>
      <RegularButton icon={<AppleSvg />} className='_fw' onClick={handleAppleClick}>Continue with Apple</RegularButton>
    </div>
  );
};

export default OtherServicesButtons;
