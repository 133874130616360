import React, { useEffect } from 'react';
import Box from '../../../../../components/reusable/cards/box/Box.jsx';
import {
  clearParentComponent,
  openModal,
} from '../../../../../store/slices/modalSlice/modalSlice.js';
import { useDispatch, useSelector } from 'react-redux';
import MediaWrapper from '../../../../../components/reusable/assets/mediaWrapper/MediaWrapper.jsx';
import { useBuzzmiAuth } from '../../../../../appState/hooks/useBuzzmiAuth';
import { useHubApp } from '../../../../../appState/hooks/useHubApp';
import Strings from '../../../../../string_key/Strings.web.js';
import { useState } from 'react';

import ChoseMultipleImageModalV2 from '../../../../../components/modal/modals/choseMultipleImageModal/ChoseMultipleImageModalV2.jsx';

const parseImage = image => {
  if (typeof image === 'string') return image;
  if (typeof image === 'object') return image['data_url'];
};

const ChoseImage = ({ setValue, values }) => {
  const dispatch = useDispatch();
  const { userData } = useBuzzmiAuth();
  const { props, name } = useSelector(state => state.modal.parentComponent);
  const [isOpen, setIsOpen] = useState(false);

  const { onUploadFiles, fileUploadResponse, isFileUploadLoading, coverList } =
    useHubApp();

  const handleOtherServicesClick = () => {
    setIsOpen(true);
    // dispatch(
    //   openModal({
    //     modalName: 'ChoseImageModal',
    //     modalType: 'big',
    //     modalProps: {coverList},
    //     callback: im => {
    //       console.log('coverImage', im);
    //       setValue('coverImage', im);
    //     },
    //   }),
    // );
  };
  const setImages = imgs => {
    console.log('imgs');
    setValue('coverImage', imgs[0]);
  };

  const handleDelete = () => {
    dispatch(clearParentComponent());
    setValue('coverImage', '');
  };

  // useEffect(() => {
  //   if (name === 'ChoseImage' && !!props) {
  //     if (props?.image?.file) {
  //       const fd = new FormData();
  //       fd.append('file', props?.image?.file);
  //       onUploadFiles(
  //         Object.assign(fd, {
  //           params: {
  //             isAuth: true,
  //             token: userData?.token,
  //             type: 'cover',
  //           },
  //         }),
  //       );
  //       dispatch(clearParentComponent());
  //     } else {
  //       // setValue('coverImage', values['coverImage']);
  //       setValue('coverImage', props.image);
  //     }
  //   } else {
  //     setValue('coverImage', values['coverImage']);
  //   }
  // }, [name]);

  useEffect(() => {
    return () => {
      handleDelete();
    };
  }, []);

  return (
    <>
      <Box
        mx_auto={false}
        className="hub-form-box d-flex justify-content-between align-items-center">
        <h4 className="h4 text-sm">{Strings.choose_cover_image}</h4>
        <div className="picture-uploader">
          {!values['coverImage'] && (
            <MediaWrapper onClick={handleOtherServicesClick} />
          )}
          {!!values['coverImage'] && (
            <div className="image-item">
              <button
                type="button"
                onClick={handleOtherServicesClick}
                className="picture-uploader__img">
                <img
                  src={parseImage(values['coverImage'])}
                  alt="image upload icon"
                />
              </button>
              <button
                onClick={handleDelete}
                className="picture-uploader__delete">
                <img src="/images/assets/delete.svg" alt="delete" />
              </button>
            </div>
          )}
        </div>
      </Box>
      <ChoseMultipleImageModalV2
        show={isOpen}
        setShow={setIsOpen}
        showSave={true}
        onlyImages={true}
        title={Strings.choose_cover_image}
        selectedMedia={setImages}
        pickSingle={true}
      />
    </>
  );
};

export default ChoseImage;
