import React, {useState} from 'react';
import SearchInput from '../../../reusable/form/items/inputs/SearchInput.jsx';
import PrimaryButton from '../../../reusable/btns/buttons/PrimaryButton.jsx';
import style from './chose-image.module.scss';
import UploadField from './UploadField.jsx';
import useSetParenComponentProps from '../../../../hooks/useSetParenComponentProps.js';
import {useDispatch, useSelector} from 'react-redux';
import {
  clearParentComponent,
  closeModal,
} from '../../../../store/slices/modalSlice/modalSlice.js';
import Strings from '../../../../string_key/Strings.web.js';
import {useBuzzmiAuth} from '../../../../appState/hooks/useBuzzmiAuth/index.web.js';
import {useUploadImages} from '../../../../appState/hooks/useUploadImages/index.js';

const ChoseMultipleImageModal = props => {
  const dispatch = useDispatch();
  const setProps = useSetParenComponentProps();
  let selectedImage = '';
  const [selected, setSelected] = useState([]);
  const {callback} = useSelector(state => state.modal);
  const {userData} = useBuzzmiAuth();
  const {UploadFiles} = useUploadImages();
  // const onChange = e => {
  //   const {value} = e.target;
  //   selectedImage = value;
  // };

  const save = e => {
    e.preventDefault();
    dispatch(clearParentComponent());
    // setTimeout(() => {
    //   setProps('ChoseImage', {image: selectedImage});
    // }, 150);
    if (callback) {
      callback(selectedImage);
    }
    dispatch(closeModal());
  };
  const onChange = (imageList, index) => {
    if (imageList?.length > 0) {
      // setIndex(index);

      let params = [];
      imageList.forEach((image, index) => {
        const fd = new FormData();
        fd.append('file', imageList[index].file);

        params.push(
          Object.assign(fd, {
            params: {
              isAuth: true,
              type: 'progress_photos',
              token: userData?.token,
            },
          }),
        );
      });
      UploadFiles(params, response => {
        // if (images?.length < 4) {
        //   const imagesToAdd = response.slice(0, 4 - images.length);
        //   setImages(prev => [...prev, ...imagesToAdd]);
        // }
        console.log('response in uploading', response);
      });
    }
  };
  return (
    <div className={style['chose-image']}>
      <div className="h4 text-center other-services__title">
        {Strings.add_4_description_image}
      </div>
      <UploadField callback={imgList => onChange(imgList)} />
      <SearchInput />
      <form
        id="redirectForm"
        className={`row g-21 ${style['chose-image__content']}`}>
        {props?.coverList != null &&
          props?.coverList?.length > 0 &&
          props?.coverList?.map((creator, index) => (
            <label className="col-3" key={index}>
              <input
                type="radio"
                name="image"
                value={creator}
                onChange={onChange}
              />
              <div className={style['chose-image__img']}>
                <img src={creator} className="img-fluid" />
              </div>
            </label>
          ))}
      </form>
      <div className={style['chose-image__button']}>
        <PrimaryButton onClick={save} type="button">
          {Strings.save}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default ChoseMultipleImageModal;
