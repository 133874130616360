import React from "react"

const InformationIcon = ({ isHovered }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9995 11.998C21.9995 17.517 17.5195 21.998 11.9995 21.998C6.46951 21.998 1.99951 17.517 1.99951 11.998C1.99951 6.47705 6.46951 1.99805 11.9995 1.99805C17.5195 1.99805 21.9995 6.47705 21.9995 11.998ZM12.8795 15.788C12.8795 16.267 12.4795 16.668 11.9995 16.668C11.5195 16.668 11.1295 16.267 11.1295 15.788L11.1295 11.368C11.1295 10.887 11.5195 10.498 11.9995 10.498C12.4795 10.498 12.8795 10.887 12.8795 11.368L12.8795 15.788ZM11.9895 7.31705C12.4795 7.31705 12.8695 7.71705 12.8695 8.19705C12.8695 8.67705 12.4795 9.06705 11.9995 9.06705C11.5095 9.06705 11.1195 8.67705 11.1195 8.19705C11.1195 7.71705 11.5095 7.31705 11.9895 7.31705Z"
        fill={isHovered ? "url(#gradient)" : "#C0BACB"}
      />
      <defs>
        <linearGradient id="gradient" gradientTransform="rotate(45)">
          <stop offset="0%" stopColor="#EF8ED5" />
          <stop offset="50%" stopColor="#EC44D0" />
          <stop offset="100%" stopColor="#C1169C" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default InformationIcon
