import {Platform, StyleSheet} from 'react-native';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';

// import {isWeb} from '../../../constants.ts';
import styled from 'styled-components/native';
import {colors} from '../../res/colors';
import {fonts} from '../../res/fonts';
// import {getStatusBarHeight, isIphoneX} from 'react-native-iphone-x-helper';

const MenuButtonContainer = styled.Pressable`
  background-color: ${props => (props?.image ? 'transparent' : colors.WHITE)};
  margin-horizontal: ${props => (props?.image ? wp(1) : wp(5))};
  padding-vertical: ${wp(4)};
  padding-horizontal: ${wp(4)};
  border-radius: ${wp(2)};
`;
const MenuText = styled.Text`
  font-family: ${fonts.BOLD};
  color: ${colors.BLACK};
  font-size: ${wp(4)};
  flex: 1;
`;
const MenuIcon = styled.Image`
  height: ${props => props?.size || wp('10%')};
  width: ${props => props?.size || wp('10%')};
  tint-color: ${colors.DARK_BORDER_GRAY};
`;
export {MenuButtonContainer, MenuText, MenuIcon};
export default StyleSheet.create({
  image: {
    height: wp(12),
    width: wp(12),
    marginRight: wp(3),
    borderRadius: wp(2),
    overflow: 'hidden',
  },
  shadowStyle: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
  },
  dragIcon: {height: wp(10), width: wp(2.5), marginRight: wp(5)},
});
