import React, {useEffect, useState} from 'react';
import ProfilePost from './profilePost/ProfilePost';
import ProfileReel from './profileReels/ProfileReel';
import useWindowSize from '../../../../hooks/useWindowSize';
import {useProfileApp} from '../../../../appState/hooks';
import {useBuzzmiAuth} from '../../../../appState/hooks/useBuzzmiAuth/index.web';
import BackBtnWithTitlePage from '../../../../components/reusable/btns/backBtn/BackBtnWithTitlePage';

const UserProfileViewMobileView = props => {
  const {id, ispost} = props;
  const [currentPostId, setCurrentPostId] = useState(id);
  const [isPosts, setIsPost] = useState(ispost == 'post' ? true : false);
  const {userData} = useBuzzmiAuth();
  const {width} = useWindowSize();
  const {userPosts, userReels} = useProfileApp();

  useEffect(() => {
    console.log('== userReels ==');
    console.log(userReels);
  }, [userReels]);
  return (
    <div className={'bg-[#faf7ff] flex-1'}>
      <BackBtnWithTitlePage
        title={isPosts ? 'Posts' : 'Reels'}
        isFixed
        subtitle={'@' + userData?.displayName || undefined}
        btnText="Back"
      />
      <ul
        className={
          isPosts
            ? 'profile-posts-list__column pt-24 z-[-1] bg-[#faf7ff] mb-10'
            : 'reels__list-column  pt-24 z-[-1] bg-[#faf7ff] mb-10'
        }>
        {isPosts ? (
          userPosts && userPosts?.length > 0 ? (
            userPosts.map(post => (
              <ProfilePost
                key={post.id}
                postData={post}
                currentPostId={currentPostId}
                type="post"
                isMyPost={true}
              />
            ))
          ) : (
            <div style={{textAlign: 'center', width: '100%'}}>
              <h5 className="h5 mb-3 mb-lg-4" style={{textAlign: 'center'}}>
                {'No Post Found'}
              </h5>
            </div>
          )
        ) : userReels && userReels.length > 0 ? (
          userReels.map((reel, index) => (
            <React.Fragment key={reel.id}>
              {width < 767 ? (
                <ProfilePost
                  postData={reel}
                  currentPostId={currentPostId}
                  type="reel"
                  isMyPost={true}
                />
              ) : (
                <ProfileReel
                  isMyPost={true}
                  reel={reel}
                  currentPostId={currentPostId}
                />
              )}
            </React.Fragment>
          ))
        ) : (
          <div style={{textAlign: 'center', width: '100%'}}>
            <h5 className="h5 mb-3 mb-lg-4" style={{textAlign: 'center'}}>
              {'No Reel Found'}
            </h5>
          </div>
        )}
      </ul>
    </div>
  );
};

UserProfileViewMobileView.propTypes = {
  isPosts: true,
};

export default UserProfileViewMobileView;
