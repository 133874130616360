import React, { useEffect, useState } from 'react';
import { openModal } from '../../../../../../../../store/slices/modalSlice/modalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useHubApp } from '../../../../../../../../appState/hooks/useHubApp/index.web';
import { useBuzzmiAuth } from '../../../../../../../../appState/hooks/useBuzzmiAuth/index.web';
// import UserProfileTags from '../../../../../profileUser/items/userProfileTags/UserProfileTags';
import Box from '../../../../../../../../components/reusable/cards/box/Box';
import Badge from '../../../../../../../../components/reusable/badge/Badge';
import cn from 'classnames';
import { useProfileApp } from '../../../../../../../../appState/hooks';

const EditProfileSetTags = ({ setValue, values }) => {
  const dispatch = useDispatch();
  const { getUserTags, userTags } = useHubApp();
  const { deletePostTag, DeleteTagReasponse } = useProfileApp();

  const { userData } = useBuzzmiAuth();
  const [varTags, setVarTags] = useState([]);

  const { isOpen } = useSelector(
    state => state.modal,
  );

  useEffect(() => {
    getUserTags(userData?.id, userData?.token);
    if (isOpen == false) {
      getUserTags(userData?.id, userData?.token);
    }
  }, [userData, isOpen])

  useEffect(() => {
    DeleteTagReasponse && getUserTags(userData?.id, userData?.token);
  }, [DeleteTagReasponse])

  useEffect(() => { setVarTags(userTags) }, [userTags])

  const handleAddNewTags = () => {
    dispatch(
      openModal({
        modalName: 'AddProfileTags',
        modalType: 'swipe',
        modalTitle: 'Profile Tag'
      }),
    );
  };

  return (
    <div className="input-label text-right mb-3 mb-md-6">
      <div className="label-text">Your tags</div>
      <Box paddingMode="big" className="profile-user__info-block">
        <div
          className={cn('badge__row', {
            badge__row_wrap: true,
          })}>
          {varTags?.length > 0 ?
            varTags.map((tag, index) => (
              <Badge
                isRemove={true}
                active={false}
                title={tag?.tagName}
                index={index}
                key={index}
                onRemoveClick={() => {
                  console.log(tag?.id)
                  deletePostTag(tag?.id, userData.token);
                }}
              />
            )) :
            "No Tag Found"
          }
        </div>

      </Box>
      <button
        type={"button"}
        className="text-alt-primary text-r pb-1 "
        onClick={handleAddNewTags}>
        + Add tag
      </button>
    </div>
  );
};

export default EditProfileSetTags;
