import React, {useEffect} from 'react';
import CustomLink from '../../../../../components/reusable/btns/links/CustomLink';
import Rank from '../../../Hub/Items/rank/Rank';
// import {Link} from 'react-router-dom';
import {useLinkTo} from '@react-navigation/native';
import ProfileInfoMobile from './items/profileInfoMobile/ProfileInfoMobile';
import ProfileHeaderMobile from './items/profileHeaderMobile/ProfileHeaderMobile';
import ProfileDataBlocks from '../items/profileDataBlocks/ProfileDataBlocks';
import ProfileContentTabs from '../../items/profileContentTabs/ProfileContentTabs';
import ProfileStories from '../../items/profileStories/ProfileStories';
import useWindowSize from '../../../../../hooks/useWindowSize';
import ProfileDataTextInfo from '../items/profileDataBlocks/items/ProfileDataTextInfo';
import {useProfileApp} from '../../../../../appState/hooks';
import {useBuzzmiAuth} from '../../../../../appState/hooks/useBuzzmiAuth/index.web';
import CreatePostInput from '../componentsV2/CreatePostInput';
import Box from '../../../../../components/reusable/cards/box/Box';

const ProfileMainMobile = () => {
  const linkTo = useLinkTo();
  const {width} = useWindowSize();
  const {getInformationContacts, getUsersActivity,userContactInfo} = useProfileApp();
  const {userData} = useBuzzmiAuth();
  let mocDataContacts = [];

  useEffect(() => {
    getInformationContacts(userData?.token);
    getUsersActivity(userData?.token);
  }, [userData]);

  useEffect(() => {
    mocDataContacts = [
      {
        title: 'Following',
        value: userContactInfo?.following,
        link: '/profile/mobile-users/1',
      },
      {
        title: 'Followers',
        value: userContactInfo?.followers,
        link: '/profile/mobile-users/1',
      },
      {
        title: 'Subscribers',
        value: userContactInfo?.friends,
        link: '/profile/mobile-users/1',
      },
    ];
  }, [userContactInfo]);

  return (
    <>
      <ProfileHeaderMobile />
      <ProfileInfoMobile />
      {/* <ProfileAboutMe /> */}
      <div className="mt-5">
        {width > 992 ? null : (
          <ProfileDataTextInfo
            infostyle={{justifyContent: 'space-around'}}
            data={[
              {
                title: 'Following',
                value: userContactInfo?.following,
                link: '/profile/mobile-users/1',
              },
              {
                title: 'Followers',
                value: userContactInfo?.followers,
                link: '/profile/mobile-users/1',
              },
              {
                title: 'Subscribers',
                value: userContactInfo?.friends,
                link: '/profile/mobile-users/1',
              },
            ]}
          />
        )}
      </div>

      <div className="mt-5">
        <Rank />
      </div>
      {/* <div className="mt-5 d-flex justify-content-between align-items-center">
        <CustomLink to="/profile/edit" className="alt-btn button_m">
          Edit profile
        </CustomLink>
        <a
          style={{cursor: 'pointer'}}
          className="text-alt-primary profile__edit-profile-m"
          onClick={() => {
            linkTo('/profile/user-mobile-about/1');
          }}>
          Profile preview
        </a>
      </div> */}
      <ProfileDataBlocks />
      {/* <ProfileStories /> */}
      <div style={{height: '10px'}}></div>
      <Box paddingMode="big">
        <CreatePostInput />
      </Box>
      <div className="mt-5">
        <ProfileContentTabs />
      </div>
    </>
  );
};

export default ProfileMainMobile;
