export function getLanguages() {
  return {
    en: {
      example: 'Example',
      task_view_preset_based:
        'Activity view: Preset-based When the toggle is on, view activities as a preset; when off, see individual activities in the activities center.',
      max_size: '1. Max Size',
      max_length: '2. Maximum length',
      resolution: '3. Resolution',
      file_type_image: 'File type Image',
      add_description_images: 'Add 4 description images',
      short_description_message:
        'Short description of the activity in a few words',
      popular_names: 'Popular names',
      suggested_tasks: 'Suggested Activities',
      enter_a_name: 'Enter a name',
      social_challenges_award:
        'Awarded for completing a certain number of social challenges',
      rankings: 'Rankings',
      badges: 'Badges',
      avatars: 'Avatars',
      earn_more_coins_to_advance_1: 'Earn',
      earn_more_coins_to_advance_2: 'more BuzzMi Coins to advance to',
      earn_more_coins_and_diamonds_to_advance_1: 'Earn',
      earn_more_coins_and_diamonds_to_advance_2: 'more BuzzMi Coins and',
      earn_more_coins_and_diamonds_to_advance_3: 'Diamonds to advance to',
      _or: 'Or',
      trophy_case_message:
        "Welcome to your Buzzmi trophy case! Here, you'll find your collection of badges, ranks, and avatars that show off your Buzzmi journey. Keep up the good work and keep pushing for more!",
      str_completed: 'completed',
      invite_users: 'INVITE USERS',
      view_details: 'VIEW DETAILS',
      your_activities: 'Your Activities',
      task_completion_message:
        'Complete the activities as much as you can and get amazing rewards!',
      become_elite_creator: 'BECOME AN ELITE CREATOR',
      network_level_score: 'Network Level Score',
      elite_creator_earning: 'Elite Creator Earning',
      str_become_premium_member: 'BECOME A PREMIUM MEMBER',
      premium_member_earning: 'Premium-Member Earning',
      daily_projected_score: 'Daily Projected Score',
      daily_projected_earnings: 'Daily Projected Earnings',
      welcome: 'Welcome',
      explore_claim_rewards: 'Explore and claim rewards!',
      wallet_balance: 'Wallet Balance',
      recharge_amount: 'Recharge Amount',
      new_event_type: '+ NEW EVENT TYPE',
      search_event: 'Search an event',
      schedule_meetings_message:
        'Schedule meetings without the hassle. Never get double-booked. Allow work with your calendar to automate appointment scheduling.',
      auto_upgrade_message: 'will automatically upgrade to',
      trial_membership_message:
        'Your trial will end in 2 days and your membership',
      finish_account_setup: 'Finish your account setup',
      save_and_continue: 'Save and Continue',
      complete_task: 'Complete Activity',
      edit_task: 'Edit Activity',
      delete_task: 'Delete Activity',
      msg_premium_access:
        '<strong>Premium Access</strong> videos, blogs and photos from independent creators.',
      msg_elite_badge:
        '<strong>Receive an elite badge</strong> - Get a badge on your channel to increase your reputation and stand out.',
      msg_hide_boosted_contect:
        '<strong>Hide boosted content</strong> - Remove all boosted content from your feeds to keep your experience ad-free.',
      msg_verify_your_channel:
        '<strong>Verify your channel</strong> - Request to verify your digital identity and let people know you are real.',
      msg_create_diff_subscription: 'Create different subscription tier level',
      msg_publish_content: 'Publish content specific to premier members',
      msg_access_free_content: 'Access to free content',
      msg_be_part_diamond: 'Be part of the Diamond Pool',
      msg_earn_engagement_on_app: 'Earn scores for engagement on the app',
      str_and_special_offer: 'And Special offer',
      str_how_to_get_next_level: 'How to get next level?',
      your_username_or_email: 'Your Username or Email',
      comments: 'Comments',
      no_comment_found: 'No Comment Found',
      post_comment: 'Post Comment',
      Languages: 'Languages',
      Language: 'Language',
      ContactUs: 'Contact Us',
      Change_Language: 'Change Language',
      App_Language_Changed_to: 'The app language will be changed to',
      OK: 'OK',
      Cancel: 'Cancel',
      Invite: 'Invite',
      Edit_Profile: 'Edit Profile',
      Terms_Of_Use: 'Terms Of Use',
      Privacy_Policy: 'Privacy Policy',
      Settings: 'Settings',
      Delete_Account: 'Delete Account',
      Logout: 'Logout',
      Account_Verification: 'Account Verification',
      Do_not_disturb: 'Do not disturb',
      Snooze: 'Snooze',
      Chats: 'Chats',
      Font_size: 'Font size',
      Show_last_seen: 'Show last seen',
      Privacy: 'Privacy',
      view_profile: 'View Profile',
      membership_level: 'Membership Level',
      sa_str_wallet: 'Wallet',
      my_wallet: 'My Wallet',
      update: 'Update',
      manage: 'Manage',
      see_your_benefits: 'SEE YOUR BENEFITS',
      rank_ladder: 'Rank Ladder',
      my_missions: 'My Missions',
      buy_coins: 'Buy Coins',
      add_friends: 'Add Friends',
      market_place: 'Market Place',
      contributors: 'Contributors',
      invite_3_friends: 'Invite 3 friends',
      and_earn_5: 'and earn 5',
      cap_buzzmi: 'BUZZMI',
      invite: 'INVITE',
      no_caps_invite: 'Invite',
      home: 'Home',
      chat: 'Chat',
      trip: 'Trip',
      account: 'Account',
      select_membership: 'Select Membership',
      membership: 'Membership',
      back: 'Back',
      later: 'Later',
      get_this_membership: 'Get this membership',
      dismiss: 'Dismiss',
      go_back: 'Go back',
      notifications: 'Notifications',
      sa_str_verify_number_1: 'Verify your\nMobile Number',
      del_your_acc_will: 'Deleting your account will',
      del_your_acc_info_and_profile_photo:
        'Delete your account info and profile photo',
      del_you_from_all_ones_say_groups: 'Delete you from all One’s Say groups',
      del_your_mess_hist:
        'Delete your messages history on this phone and your backups',
      temp_inactive_your_acc_will: 'Temporary inactive your account will',
      temp_inact_your_ones_say_acc:
        "Temporarily inactivate your One's Say account",
      not_disp_your_prof_det_in_ones_say:
        "Not display your profile/contact details in One's Say",
      mark_login_cred_as_invalid:
        'Mark your current login credential as invalid',
      allow_to_activate_the_same_acc:
        "Allow to activate the same account when you wish throght the 'Forgot password' option",
      your_phone_number: 'Your phone number',
      delete_my_account: 'Delete My Account',
      inactive_my_account: 'Inactive My Account',
      write_reason: 'Write your reason please',
      please_des_problem: 'Please describe the problem in more detail',
      what_is_your_reason_for_leaving:
        'What is your primary reason for leaving?',
      are_you_sure_want_to_delete_account:
        'Are you sure you want to delete your account? This will permanently erase your account',
      delete_all_caps: 'DELETE',
      select_reason: 'Select Reason',
      inactive_account: 'Inactive Account',
      are_you_sure_inactive_account:
        "Are you sure you want to inactive your account? Please note you have an inactivated account with same Email/Mobile number in One's Say, visit 'Forget Password' to activate the same account or use different details to create a new account",
      inactive: 'InActive',
      done: 'Done',
      are_you_sure_logout: 'Are you sure want to logout?',
      no: 'No',
      yes: 'Yes',
      english: 'English',
      spanish: 'Spanish',
      account_details: 'Account Details',
      display_name: 'Display Name',
      user_Id: 'User ID',
      username: 'Username',
      first_name: 'First Name',
      last_name: 'Last Name',
      meeting_price: 'Meeting Price',
      meeting_duration: 'Meeting Duration',
      referral_code: 'Referral code',
      sponsor: 'Sponsor',
      date_of_birth: 'Date of Birth',
      choose_image: 'Choose image',
      gender: 'Gender',
      male: 'Male',
      female: 'Female',
      other: 'Other',
      sponsor_id: 'Sponsor ID',
      are_you_sure_you_want: 'Are you sure you want',
      as_your_sponsor: 'as your sponsor',
      okay: 'Okay',
      invalid_referral_code: 'Invalid referral code',
      please_enter_display_name: 'Please enter display name',
      display_name_should_not:
        'Display name should not contain any numbers, should be one word and should be between 3 and 20 characters long.',
      please_enter_first_name: 'Please enter first name',
      please_enter_valid_first_name:
        'First name should not contain any numbers and should be between 3 and 20 characters long',
      please_enter_last_name: 'Please enter last name',
      please_enter_valid_last_name:
        'Last name should not contain any numbers and should be between 3 and 20 characters long.',
      please_enter_dob: 'Please enter your birth date',
      please_enter_valid_dob: 'Please enter valid birth date',
      please_select_gender: 'Please specify your gender',
      meeting_price_cannot: 'Meeting price cannot be 0',
      take_photo: 'Take Photo',
      verify_account: 'Verify Account',
      mobile_number: 'Mobile Number',
      email: 'Email',
      verify: 'Verify',
      two_factor_authentication: 'Two-factor authentication',
      add_extra_layer:
        'Add an extra layer of security to your account by enabling two-factor authentication.',
      please_enter_mobile_number: 'Please enter mobile number',
      please_enter_valid_phone: 'Please enter a valid phone number',
      please_enter_email: 'Please enter email',
      please_enter_valid_email: 'Please enter valid email',
      enter_your_verification_code: 'Enter your verification code',
      we_sent_the_code: 'We sent the code to the',
      phone_small: 'phone',
      email_small: 'email',
      this_code_will_expire: 'This code will expire in',
      new_password: 'Your New Password',
      confirm_password: 'Confirm Password',
      submit: 'Submit',
      resend_code: 'Resend code',
      please_enter_received_code: 'Please enter the received code.',
      please_enter_password: 'Please enter password',
      invalid_password: 'Invalid Password',

      please_enter_confirm_password: 'Please enter confirm password',
      passwords_do_not_match: 'Passwords do not match',
      password_length_to_be:
        '- Password Length to be 8 minimum and 15 maximum\n',
      at_least_one_special_char: '- At least one Special Char\n',
      at_least_one_capital_letter: '- At least one Capital Letter\n',
      at_least_one_number: '- At least one Number\n',
      no_more_than_two_consecutive: '- No more than two consecutive characters',
      enable_now: 'Enable Now',
      enable_twofactor_auth: 'Enable Two-factor authentication',
      an_authenticator_app_lets_you:
        'An authenticator app lets you generate security codes on your phone without needing to receive text messages. If you don`t already have one, we support e. g. these apps.',
      to_configure_your: 'To configure your authenticator app:',
      add_a_new_timebased:
        'Add a new time-based token \nUse your app to scan the QR-code below',
      enter_the_code_generated: 'Enter the code that was generated',
      code: 'Code',
      validate: 'Validate',
      save: 'Save',
      change: 'Change',
      disable_now: 'Disable Now',
      enable_successfully: 'enable successfully',
      secret_key_copied: 'Secret key copied',
      contributionscore: 'ContributionScore',
      activity: 'Activity',
      gained_score: 'Gained_score',
      date_created: 'Date Created',
      contributor_not_found: 'Contributor not found',
      start_time: 'Start Time',
      end_time: 'End Time',
      this_option_routinely:
        'This option will routinely suspend notifications for set periods each day.',
      please_select_start_time: 'Please select start time!',
      please_select_end_time: 'Please select end time!',
      start_time_end_time_not_same: 'Start time and end time cannot be same!',
      please_select_valid_start_date: 'Please select a valid start date.',
      search: 'Search',
      feed: 'Feed',
      reels: 'Reels',
      myposts: 'MyPosts',
      choose_photo: 'Choose photo',
      confirm: 'Confirm',
      select_date: 'Select date',
      select_time: 'Select time',
      edit: 'Edit',
      delete: 'Delete',
      tap_options: 'Tap options',
      change_channel_image: 'Change channel image',
      view: 'View',
      choose_file: 'Choose File',
      error_open_photo_lib: "Couldn't open photo library.",
      error_open_camera: "Couldn't open camera.",
      allow_permission: 'Allow permission',
      cap_settings: 'SETTINGS',
      error_upload_image: 'Could not upload the image.',
      points: 'Points',
      total: 'Total',
      spent: 'Spent',
      wallet_activity: 'Wallet Activity',
      to: 'To',
      no_activity_found: 'No activity found',
      clear: 'Clear',
      categories: 'Categories',
      all_categories: 'All categories',
      no_product_found: 'No product found',
      store: 'Store',
      all_offers: 'All Offers',
      cash_back: 'Cash Back',
      get_deal: 'Get deal',
      error_only_edit_your_products: 'You can only edit your products',
      delete_category: 'Delete Category',
      warn_delete_category: 'Are you sure you want to delete this category?',
      warn_no_vendor_account:
        "Seems like you don't have vendor account access. Do you want to initiate singup process?",
      add_product: 'Add Product',
      product_name: 'Product Name',
      product_price: 'Product Price',
      select_category: 'Select Category',
      category_title: 'Category Title',
      suspicious: 'Suspicious',
      harassing: 'Harassing',
      inappropriate: 'Inappropriate',
      spam: 'Spam',
      edit_category: 'Edit Category',
      add_category: 'Add Category',
      all: 'All',
      group: 'Group',
      channel: 'Channel',
      contacts: 'Contacts',
      network: 'Network',
      missed: 'Missed',
      call_history: 'Call History',
      warn_no_record_found: 'No Record Found',
      following: 'Following',
      followers: 'Followers',
      views: 'Views',
      streamer_hours: 'Streamer hours',
      follow: 'Follow',
      mute: 'Mute',
      search_message: 'Search Message',
      block_contact: 'Block message',
      report: 'Report',
      report_type: 'Report type',
      calling: 'Calling',
      cancelled: 'Cancelled...',
      cap_cancelled: 'CANCELLED...',
      outgoing: 'Outgoing',
      incoming: 'Incoming',
      new_chat: 'New Chat',
      new_group: 'New group',
      new_contact: 'New contact',
      new_channel: 'New channel',
      unblock_contact: 'Unblock Contact',
      message: 'Message',
      warn_write_your_reason: 'Write your reason please',
      got_it: 'Got it',
      user_reported: 'User Reported',
      warn_report_noted: 'Your report is noted. we will review this',
      warn_select_reason: 'Please select the reason',
      channel_reported: 'Channel Reported',
      group_reported: 'Group Reported',
      sa_str_end_to_end_encrypted:
        'Messages and calls are end-to-end encrypted.\nNo one outside this chat, not even Buzzmi,\ncan read or listen to them.',
      channel_is_frozen: 'Channel is frozen',
      sa_str_last_seen: 'Last seen',
      sa_str_last_seen_yesterday: 'Last seen yesterday',
      sa_str_group_yesterday: 'Yesterday',
      last_seen_a_week_ago: 'Last seen 1 week ago',
      last_seen_few_week_ago: 'Last seen few weeks ago',
      warn_user_is_on_dnd: 'This user is currently on do not disturb mode.',
      warn_user_is_on_snooze: 'This user is currently on snooze mode.',
      Insufficient_permissions: 'Insufficient permissions!',
      warn_allow_camera_and_microphone_access:
        'To call, allow Buzzmi access to your camera and microphone',
      failed: 'Failed',
      online: 'Online',
      is_now_an_admin: 'is now an admin.',
      msg_channel_created: 'The channel is created.',
      msg_group_created: 'The group is created.',
      and: 'and',
      joined_dot: 'joined.',
      chat_is_unavailable: 'Chat is unavailable',
      write_a_message: 'Write a message',
      error_sending_message: "Couldn't send message.",
      msg_no_longer_admin: 'is no longer an admin.',
      error_opening_files: "Couldn't open files.",
      error_receiver_blocked:
        'Receiver is blocked. Unblock the user to send message',
      slide_to_cancel: 'Slide to cancel',
      camera: 'Camera',
      photo_library: 'Photo library',
      warn_need_mic_permission: 'Need microphone permission to proceed!',
      retry: 'Retry',
      remove: 'Eliminar',
      delete_message: 'Delete Message?',
      error_delete_message: "Couldn't delete message.",
      copy: 'Copy',
      copied: 'Copied',
      read_by: 'Read by',
      delivered_to: 'Delivered to',
      downloading: 'Downloading...',
      file_saved: 'File saved',
      error_download_file: "Couldn't download file.",
      files: 'Files',
      error_cam_not_available: 'Camera not available',
      photo: 'Photo',
      one_week_ago: '1 week ago',
      few_weeks_ago: 'few weeks ago',
      you: 'You',
      turn_off_notifications: 'Turn off notifications',
      turn_on_notifications: 'Turn on notifications',
      error_turning_on_notifications: "Couldn't turn on notifications.",
      error_turning_off_notifications: "Couldn't turn off notifications.",
      warn_sure_delete: 'Are you sure you want to delete?',
      error_leave_channel: "Couldn't leave channel.",
      leave_channel: 'Leave channel',
      members: 'Members',
      add_members: 'Add Members',
      invite_members: 'Invite Members',
      operators: 'Operators',
      operator: 'Operator',
      banned_members: 'Banned Members',
      muted_members: 'Muted Members',
      reported_members: 'Reported Members',
      report_channel: 'Report Channel',
      report_group: 'Report Group',
      freeze_channel: 'Freeze Channel',
      leave_group: 'Leave Group',
      admins: 'Admins',
      need_camera_permission: 'Need camera permission to proceed!',
      demote: 'Demote',
      are_you_sure_dismiss_memeber:
        'Are you sure you want to dismiss this member as a',
      no_name: 'No Name',
      dismiss_from: 'Dismiss From',
      owner: 'Owner',
      ban: 'Ban',
      are_you_sure_to_ban: 'Are you sure you want to ban this member?',
      unmute: 'Unmute',
      are_you_sure_want_unmute: 'Are you sure you want to Unmute this member?',
      are_you_sure_want_mute: 'Are you sure you want to mute this member?',
      dismiss_from_operator: 'Dismiss From Operator',
      promote_operator: 'Promote Operator',
      report_member: 'Report member',
      are_you_sure_want_report: 'Are you sure you want to report?',
      remove_from: 'Remove From',
      remove_from_admin: 'Remove From Admin',
      make_admin: 'Make Admin',
      admin: 'Admin',
      invite_user: 'Invite User',
      are_not_memeber_of_onesay:
        " are not memeber of one'say. Do you want to send invitation to them?",
      please_select_users: 'Please select users',
      create_channel: 'Create channel',
      create_group: 'Create group',
      create: 'Create',
      please_enter_channel_name: 'Please enter channel name',
      please_enter_group_name: 'Please enter group name',
      post: 'Post',
      reel: 'Reel',
      create_post: 'Create Post',
      whats_on_your_mind: "What's on your mind",
      upload: 'Upload',
      reels_updated_successfully: 'Reels updated Successfully',
      please_enter_content: 'Please enter content',
      reels_added_successfully: 'Reels added Successfully',
      error_while_uploading_reels: 'Error while uploading reels',
      content_should_be_at_least: 'Content should be at least 3 character',
      couldnt_reach_server:
        "Couldn't reach server. Check your internet connection",
      to_call_allow_buzzmi_access:
        'To call, allow Buzzmi access to your camera and microphone',
      to_answer_allow_buzzmi_access:
        'To answer, allow Buzzmi access to your camera and microphone',
      you_need_grant_storage_access:
        'You need to grant storage access permissions.',
      permissions_for_record_audio: 'Permissions for record audio',
      give_permission_record_audio:
        'Give permission to your device to record audio',
      You_need_grant_microphone:
        'You need to grant microphone access permissions.',
      send: 'Send',
      left_leave: 'left.',
      send_invitation: 'Send Invitation',
      mobile: 'Mobile',
      invitation_sent_success: 'Invitation send successfully',
      add_a_contact: 'Add a contact',
      to_a_channel: 'to a channel',
      add: 'Add',
      warn_invitation_link_sent: 'Invitation link send successfully',
      invite_to_channel_via_link: 'Invite to channel via link',
      invite_with_link_description:
        'Anyone with Buzzmi can follow this link to join this channel. Only share it with people you trust',
      send_link_via_buzzmi: 'Send link via Buzzmi',
      link_copied_with_success: 'Invitation link copied successfully',
      copy_link: 'Copy link',
      banned: 'Banned',
      msg_sure_to_un_ban: 'Are you sure you want to unbanned this member?',
      msg_sure_to_un_mute: 'Are you sure you want to unmute this member?',
      warn_no_banned_users: 'No Banned User Found',
      warn_no_mute_users: 'No Mute User Found',
      warn_no_reported_users: 'No Reported Member Found',
      msg_report_group:
        'The last 5 messages from this group will be forwarded to Buzzmi. If you exit this group and delete the chat, message will only be removed from the device and your devices on the newer version of Buzzmi.',
      msg_report_channel:
        'The last 5 messages from this channel will be forwarded to Buzzmi. If you exit this channel and delete the chat, message will only be removed from the device and your devices on the newer version of Buzzmi.',
      report_this: 'Report this',
      no_one_in_the: 'No one in the',
      will_be_notified: 'will be notified',
      exit_something: 'Exit',
      and_delete_chat: 'and delete chat',
      change_channel_name: 'Change channel name',
      change_group_name: 'Change group name',
      enter_name: 'Enter name',
      change_group_image: 'Change group image',
      channel_information: 'Channel information',
      no_travel_packages_available: 'No travel packages available',
      no_slots_available_for_date: 'No slots available for this date!',
      unable_to_get_user_availability: 'Unable to get user availability',
      please_enter_details: 'Please enter details',
      reel_downvoted_successfully: 'Reel downvoted successfully',
      reel_upvoted_successfully: 'Reel upvoted successfully',
      delete_reel: 'Delete Reel',
      delete_post: 'Delete Post',
      are_you_sure_delete_this_reel:
        'Are you sure you want to delete this reel?',
      are_you_sure_delete_this_post:
        'Are you sure you want to delete this post?',
      buzzmi_need_permission_to_access_your_device_storage:
        'Buzzmi need permission to access your device storage. Go to Settings to allow access',
      trending: 'Trending',
      tweets: 'tweets',
      post_update_success: 'Post updated successfully',
      edit_post: 'Edit Post',
      post_added_success: 'Post added successfully',
      report_description: 'Report description',
      report_by: 'Report By',
      report_details: 'Report Details',
      msg_you_can_add: (id, type) =>
        `You can add ${id} to help you for manage your ${type}. Press and hold to remove them. `,
      limit_not_reached: 'Limit Not Reached',
      voice_note_min_limit_2_sec: 'Voice note must be at least 2 seconds.',
      receiver_is_blocked:
        'Receiver is blocked. \n Unblocked the user to send message',
      alert_permissions_message: (permission, appName) =>
        `${appName} need permission to access your ${permission}. Go to Settings to allow access`,
      the_channels_image_was_updated: "The channel's image was updated.",
      the_groups_image_was_updated: "The group's image was updated.",
      edited: '(edited)',
      promote: 'promote',
      msg_are_you_sure_admin: (status, id) =>
        `Are you sure you want to ${status} this member as a ${id}`,
      added_success: 'added successfully.',
      at: 'at',
      msg_what_can_this_do: id => `What can this ${id} do?`,
      rights: 'Rights',
      change_channel_info: 'Change channel info',
      add_remove_members: 'Add/Remove Members',
      invite_users_via_link: 'Invite Users via Link',
      add_or_remove: 'Add/Remove',
      ban_members: 'Ban Members',
      mute_members: 'Mute Members',
      report_members: 'Report Members',
      delete_account: 'Delete Account',
      deleting_your_account_will: 'Deleting your account will',
      delete_your_account_profile: 'Delete your account info and profile photo',
      delete_from_buzzmi_groups: 'Delete you from all Buzzmi groups',
      delete_messages_and_backup:
        'Delete your messages history on this phone and your backups',
      temp_inactive_your_account: 'Temporary inactive your account will',
      your_buzzmi_acc_temp_inactive:
        'Temporarily inactivated your Buzzmi account',
      not_display_buzzmi_profile:
        'Not display your profile/contact details in Buzzmi',
      make_login_invalid: 'Mark your current login credential as invalid',
      allow_acc_active_with_forgot_pass:
        "Allow to activate the same account when you wish through the 'Forgot password' option",
      del_my_acc: 'Delete My Account',
      inactive_my_acc: 'Inactive My Account',
      msg_inactive_acc_confirm:
        "Are you sure you want to inactive your account? Please note you have an inactivated account with same Email/Mobile number in Buzzmi, visit 'Forget Password' to activate the same account or use different details to create a new account",
      currency: 'Currency',
      awarded_on: 'Awarded On',
      award_type: 'Awarded Type',
      share_link: 'Share Link',
      qr_code: 'QR Code',
      share_file: 'Share file',
      is_muted: 'is muted',
      voice: 'voice',
      call: 'call',
      completed: 'COMPLETED',
      missed_call: 'Missed Call',

      phone_number: 'Phone Number',
      boaring_vocabulary_title: "Boring isn't in our vocabulary!",
      boaring_vocabulary_description:
        'Welcome to the ultimate platform for socializing, fitness, and fun!',
      become_a_part_of_community_description:
        "Become a part of a community of like-minded individuals and access high-quality content on health and wellness, trendy events, and exciting challenges - all on your phone. Let's get buzzing!",
      make_money_with_buzzmi_title: 'Make money with Buzzmi',
      make_money_with_buzzmi_description: 'Where the real hustle happens!',
      ready_for_a_challenge_title: 'Ready for a challenge?',
      ready_for_a_challenge_description:
        'Take on your friends and win big on Buzzmi',
      fair_pay_for_fair_work_title: 'Fair pay for fair work.',
      fair_pay_for_fair_work_description:
        'Buzzmi creators earn 90% of every subscription, with no fees. We keep just 10% to keep things buzzing.',
      continue_with_facebook: 'Continue with Facebook',
      continue_with_apple: 'Continue with Apple',
      continue_with_google: 'Continue with Google',
      your_phone_or_email: 'Enter your email or phone number',
      your_password: 'Create Your Password',
      forgot_password: 'Forgot Password',
      log_in: 'LOG IN',
      login: 'Log in',
      or: 'or',
      dont_have_an_account: "Don't have an account?",
      sign_up: 'Sign up',
      lets_get_your_setup: "Let's get you set up",
      already_have_an_account: 'Already have an account?',
      signup_with_phone_or_email: 'SIGNUP WITH PHONE OR EMAIL',
      forgot_password_description:
        'Don’t worry! It happens. Please enter the address associated with your account.',
      forgot_your_password: 'Forgot Your Password?',
      continue: 'CONTINUE',
      provide_your_contact_information: 'Provide your contact \n information',
      enter_your_email: 'Enter your email',
      or_sign_up_with_phone: 'or sign up with phone',
      or_sign_up_with_email: 'or sign up with email',
      contact_email_description:
        'Accounts are required to provide a phone number or email for safety and privacy purposes. You can only have one account linked to your phone number',
      next: 'NEXT',
      verify_your_email: 'Verify your email',
      verify_your_phone_number: 'Verify your phone number',
      verification_code_sent_description:
        'We sent a {digit}-digit code to {email} Confirm it belongs to you to keep your account secure.',
      time_to_buzz_into_action: 'Time to buzz into action!',
      bio_description:
        'We just need a few more details to personalize your Buzzmi experience.',
      user_name: 'User Name',
      when_your_birhtday: 'When is your birthday?',
      birthday_not_shown_publicaly: 'Your birthday won’t be shown publicly',
      invitation_code: 'Invitation Code',
      password_priority_title:
        'Your security is our top priority. Make sure your password reflects that!',
      create_your_password: 'Create your password',
      password_requirement: 'Password requirements:',
      make_it_long: 'Make it long',
      from_8_to_20_characters: 'from 8 to 20 characters',
      make_it_diverse: 'Make it diverse',
      password_should_contain_one_special_character:
        'It should contain at least one upper case letter and at least one special character',
      field_is_required: 'Field is required',
      invalid_phone_or_email: 'Invalid phone or email',
      invalid_phone: 'Invalid phone',
      password_must_contain_at_least_1_lower_case_letter:
        'Password must contain at least 1 lower case letter',
      password_must_contain_at_least_1_upper_case_letter:
        'Password must contain at least 1 upper case letter',
      password_must_contain_at_least_1_number:
        'Password must contain at least 1 number',
      password_must_contain_at_least_1_special_character:
        'Password must contain at least 1 special character',
      minimum_required_length_8_characters:
        'Minimum required length 8 characters',
      userName_must_contain_at_least_1_lower_case_letter:
        'User Name must contain at least 1 lower case letter',
      userName_must_contain_at_least_1_upper_case_letter:
        'User Name must contain at least 1 upper case letter',
      userName_must_contain_at_least_1_number:
        'User Name must contain at least 1 number',
      firstName_should_not_contain_numbers:
        'First Name should not contain the numbers',
      lastName_should_not_contain_numbers:
        'Last Name should not contain the numbers',
      country_code_is_required: 'Country code is required',
      please_enter_valid_phone_number: 'Please enter a valid phone number',
      resend_code_text_timer:
        'You will be able to resend the code after (${time}) seconds',
      wrong_verification_number: 'Wrong verification number',
      your_email_has_been_verified: 'Your email has been verified',
      your_phone_has_been_verified: 'Your phone has been verified',
      re_enter_the_code: 'Re-enter the code',
      success: 'Success!',
      must_include_at_least_1_lower_case_letter:
        'Must include at least 1 lower case letter',
      must_include_at_least_1_upper_case_letter:
        'Must include at least 1 upper case letter',
      must_include_at_least_1_number: 'Must include at least 1 number',
      email_is_required: 'Email is required',
      invalid_email: 'Invalid Email',
      invalide_mobile: 'Invalide Mobile',
      invalid_dob: 'Invalid date of birth',
      age_18_puls_description:
        "Sorry, looks like you're not eligible for Buzzmi... But thanks for checking us out!",
      contain_at_least_one_upper_case_letter:
        'contain at least one upper case letter',
      contain_at_least_one_special_character:
        'contain at least one special character',
      confirm_password_must_be_the_same_as_password:
        'confirm password must be the same as password',
      password_must_match: 'Passwords must match',
      choose_your_role: 'Choose your role',
      we_are_curios: "We're curious:",
      are_you: 'Are you a....',
      the_spot_light_on_you: "The spotlight's on you!",
      top_3_creator_select: 'Who are your top 3 creators? Let us know!',
      or_invite_friend_individually: 'or invite friends induvidualy',
      we_are_all_ears: "We're all ears!",
      tell_us_what_your_interest: 'Tell us what sparks your interest.',
      verification_code: 'Verification code',
      enter_otp: 'Enter OTP',
      foreget_password_masked_text:
        'We want to make sure it’s really you. In order to further verify your identity, enter the verification code that was sent to ${email}',
      reset_your_password: 'Reset Your Password',
      one_subscription_endless_possblities:
        'One subscription, endless possibilities',
      unlimited_access_to_100s_of_creators_and_all_premium_features:
        'Unlimited access to 100s+ of creators and all premium features',
      get_more_of_the_app_and: 'Get more of the app and',
      save_40: 'SAVE 40%',
      your_first_7_days_free_no_commitment_cancel_anytime:
        'Your first 7 days free. No commitment. Cancel anytime',
      elte_creator_description:
        'You can start to expand your business using our advanced technology, user-friendly platform, and dedicated customer support team.',
      close: 'Close',
      learn_more: 'Learn more',
      premium_description:
        'Get instant access to the biggest offering of all of Playbooks 1000+ trainers, athletes and coaches.',
      start_my_7_days_trial: 'START MY 7-DAYS TRIAL',
      skip_trial: 'SKIP TRIAL',
      subscribe: 'SUBSCRIBE',
      become_premium_member: 'Become Premium \n Member',
      pay_with_credit_card: 'Pay with credit card',
      cardholder_Name: 'Cardholder Name',
      card_Number: 'Card Number',
      expiration: 'Expiration',
      subtotal: 'Subtotal',
      discount: 'Discount',
      tax: 'Tax',
      checkout: 'CHECKOUT',
      you_now_a_part_of_the_buzz_worthy_world_of_Buzzmi:
        "You're now a part of the buzz-worthy world of Buzzmi",
      lets_go_to_your_profile: 'Let’s go to your profile',
      go_to_start: 'Go to start',
      choose_your_payment_method: 'Choose your Payment method',
      choose_your_another_payment_method: 'Choose another Payment Method',
      to_back: 'To Back',
      your_rank: 'Your Rank',
      view_rank_ladder: 'View rank ladder',
      next_rank: 'Next Rank',
      rise_and_shine: 'Rise and Shine',
      my_plan_for_today: 'My plan for today',
      str_trial_message:
        'Your trial will end in 2 days and your membership will automatically upgraded to',
      see_all_tasks: 'See all activities',
      upgrade_now: 'Upgrade Now',
      see_my_earnings: 'See my earnings',
      wallet: 'Wallet',
      wallet_balance: 'Wallet Balance',
      quick_access: 'Quick access',
      your_current_rank: 'Your current rank',
      welcome_description:
        "We're here to help you power through your to-do's and make the most of your day.",
      your_current_level: 'Your current level',
      add_activity: 'Add Activities',
      tasks: 'Activities',
      stats: 'Stats',
      leaderboard: 'Leaderboard',
      any_time_of_the_day: 'Any time of the day',
      membership_description:
        'Take your Buzzmi journey to new heights! Upgrade to Premium and access all the best content, tools, and perks the app has to offer.',
      free_user: 'Free User',
      premium_member: 'Premium Member',
      elite_creator: 'Elite Creator',
      add_task_name: 'Add activity name',
      add_task: 'Add Activity',
      choose_cover_image: 'Choose cover image',
      add_description: 'Add description',
      add_4_description_image: 'Add 4 description images',
      add_description_video: 'Add description video',
      add_description_audio: 'Add audio',
      display_in_task_center: 'Display in Activity Center',
      short_description_task: 'Add a short description of the activity',
      upload_your_image: 'Upload Your Image',
      toggle_description:
        'When the toggle is on, view activities as a preset; when off, see individual activities in the activity center.',
      add_my_own_task: 'Add My Activity',
      when: 'When?',
      repeat: 'Repeat',
      repeat_on_every: 'Repeat on Every',
      choose_the_day_for_this_activity: 'Choose the days for this activity',
      end_date: 'End Date',
      set_a_goal: 'Set a goal',
      enter_a_number: 'Enter a number',
      reminder: 'Reminder',
      checklist: 'Checklist',
      note: 'Note',
      add_your_note_here: 'Add your note here',
      add_photos_of_your_progress: 'Add photos of your progress',
      tags: 'Tags',
      add_your_item: 'Add your item',
      my_task: 'My Activity',
      buzzmi_task: 'BuzzMi Activity',
      profile: 'Profile',
      messages: 'Messages',
      space: 'Space',
      subscription: 'Subscription',
      challenges: 'Challenges',
      hub: 'Hub',
      calendar: 'Calendar',
      subscription_alert_message:
        'Your subscription will automatically renew in 23 days. You can cancel it anytime',
      how_to_get_next_level: 'How to get next level?',
      see_my_beniftis: 'See my benefits',
      morning: 'Morning',
      subscribe_now: 'Subscribe now',
      bronze: 'Bronze',
      silver: 'Silver',
      gold: 'Gold',
      choose_subscription_level: 'Choose Subscription Level',
      add_event: 'Add Event',
      scheduled: 'Scheduled',
      event_types: 'Event Types',
      Shop: 'Shop',
    },
    es: {
      example: 'Example',
      task_view_preset_based:
        'Vista de actividad: Basada en preajustes. Cuando el interruptor está activado, ver las actividades como un preajuste; cuando está desactivado, ver las actividades individuales en el centro de actividades',
      max_size: '1. Tamaño Máximo',
      max_length: '2. Longitud Máxima',
      resolution: '3. Resolución',
      file_type_image: 'Tipo de archivo Imagen',
      add_description_images: 'Agregar 4 imágenes de descripción',
      short_description_message:
        'Breve descripción de la actividad en pocas palabras',
      popular_names: 'Nombres populares',
      suggested_tasks: 'Actividades Sugeridas',
      enter_a_name: 'Ingrese un nombre',
      social_challenges_award:
        'Premiado por completar cierto número de desafíos sociales',
      rankings: 'Clasificaciones',
      badges: 'Insignias',
      avatars: 'Avatares',
      earn_more_coins_to_advance_1: 'Gana',
      earn_more_coins_to_advance_2: 'monedas BuzzMi más para avanzar a',
      earn_more_coins_and_diamonds_to_advance_1: 'Gana',
      earn_more_coins_and_diamonds_to_advance_2: 'monedas BuzzMi y',
      earn_more_coins_and_diamonds_to_advance_3: 'diamantes más para avanzar a',
      _or: 'O',
      trophy_case_message:
        '¡Bienvenido a tu colección de trofeos de Buzzmi! Aquí encontrarás tu colección de insignias, rangos y avatares que muestran tu trayectoria en Buzzmi. ¡Sigue con el buen trabajo y sigue esforzándote por más!',
      str_completed: 'completado',
      invite_users: 'INVITAR USUARIOS',
      view_details: 'VER DETALLES',
      your_activities: 'Tus Actividades',
      task_completion_message:
        '¡Completa las actividades tanto como puedas y obtén increíbles recompensas!',
      become_elite_creator: 'CONVERTIRSE EN UN CREADOR DE ÉLITE',
      network_level_score: 'Puntuación de Nivel de Red',
      elite_creator_earning: 'Ganancias del Creador Élite',
      str_become_premium_member: 'CONVERTIRSE EN MIEMBRO PREMIUM',
      premium_member_earning: 'Ganancias de Miembros Premium',
      daily_projected_score: 'Puntuación Proyectada Diaria',
      daily_projected_earnings: 'Ganancias Proyectadas Diarias',
      welcome: 'Bienvenido',
      explore_claim_rewards: '¡Explora y reclama recompensas!',
      wallet_balance: 'Saldo de la Cartera',
      recharge_amount: 'Monto de Recarga',
      new_event_type: '+ NUEVO TIPO DE EVENTO',
      search_event: 'Buscar un evento',
      schedule_meetings_message:
        'Programa reuniones sin complicaciones. Nunca te sobrepongas con otras citas. Permite que el trabajo con tu calendario automatice la programación de citas.',
      auto_upgrade_message: 'se actualizará automáticamente a',
      trial_membership_message:
        'Su período de prueba finalizará en 2 días y su membresía',
      finish_account_setup: 'Termina la configuración de tu cuenta',
      complete_task: 'Completar Actividad',
      edit_task: 'Editar Actividad',
      delete_task: 'Eliminar Actividad',
      msg_premium_access:
        '<strong>Acceso Premium</strong> a videos, blogs y fotos de creadores independientes.',
      msg_elite_badge:
        '<strong>Recibe una insignia de élite</strong> - Obtén una insignia en tu canal para aumentar tu reputación y destacar.',
      msg_hide_boosted_contect:
        '<strong>Oculta contenido promocionado</strong> - Elimina todo el contenido promocionado de tus feeds para mantener tu experiencia sin anuncios.',
      msg_verify_your_channel:
        '<strong>Verifica tu canal</strong> - Solicita verificar tu identidad digital y dejar que la gente sepa que eres real.',
      msg_create_diff_subscription: 'Crea diferentes niveles de suscripción',
      msg_publish_content: 'Publica contenido específico para miembros premier',
      msg_access_free_content: 'Acceso a contenido gratuito',
      msg_be_part_diamond: 'Forma parte del Diamond Pool',
      msg_earn_engagement_on_app:
        'Gana puntos por la participación en la aplicación',
      str_and_special_offer: 'Y oferta especial',
      str_how_to_get_next_level: '¿Cómo llegar al siguiente nivel?',
      str_trial_message:
        'Su período de prueba finalizará en 2 días y su membresía se actualizará automáticamente a',
      missed_call: 'Llamada perdida',
      completed: 'TERMINADO',
      call: 'llamar',
      voice: 'voz',
      is_muted: 'Está silenciado',
      share_file: 'Recurso compartido de archivos',
      qr_code: 'Código QR',
      share_link: 'Compartir enlace',
      award_type: 'Tipo adjudicado',
      awarded_on: 'Otorgado el',
      currency: 'Divisa',
      msg_inactive_acc_confirm:
        "¿Está seguro de que desea inactivar su cuenta? Tenga en cuenta que tiene una cuenta inactiva con el mismo correo electrónico/número de teléfono móvil en Buzzmi, visite 'Olvidé mi contraseña' para activar la misma cuenta o use diferentes detalles para crear una nueva cuenta",
      inactive_my_acc: 'Mi cuenta inactiva',
      del_my_acc: 'Borrar mi cuenta',
      allow_acc_active_with_forgot_pass:
        "Permita activar la misma cuenta cuando lo desee a través de la opción 'Olvidé mi contraseña'",
      make_login_invalid:
        'Marque su credencial de inicio de sesión actual como no válida',
      not_display_buzzmi_profile:
        'No mostrar tu perfil/detalles de contacto en Buzzmi',
      your_buzzmi_acc_temp_inactive:
        'Desactivé temporalmente su cuenta de Buzzmi',
      temp_inactive_your_account: 'Temporalmente inactiva su cuenta será',
      delete_messages_and_backup:
        'Elimina tu historial de mensajes en este teléfono y tus copias de seguridad',
      delete_from_buzzmi_groups: 'Eliminarte de todos los grupos de Buzzmi',
      delete_your_account_profile:
        'Eliminar la información de su cuenta y la foto de perfil',
      deleting_your_account_will: 'Eliminar su cuenta',
      delete_account: 'Borrar cuenta',
      report_members: 'Reportar miembros',
      mute_members: 'Miembros mudos',
      ban_members: 'Prohibir Miembros',
      add_or_remove: 'Agregar/Eliminar',
      invite_users_via_link: 'Invitar usuarios a través de un enlace',
      add_remove_members: 'Agregar/eliminar miembros',
      change_channel_info: 'Cambiar información del canal',
      rights: 'Derechos',
      msg_what_can_this_do: id => `¿Qué puede hacer este ${id}?`,
      at: 'en',
      added_success: 'agregado exitosamente.',
      msg_are_you_sure_admin: (status, id) =>
        `¿Está seguro de que desea ${status} a este miembro como ${id}`,
      promote: 'promover',
      edited: '(editado)',
      the_groups_image_was_updated: 'La imagen del grupo fue actualizada.',
      the_channels_image_was_updated: 'La imagen del canal fue actualizada.',
      alert_permissions_message: (permission, appName) =>
        `${appName} necesita permiso para acceder a su ${permission}. Ve a Ajustes para permitir el acceso`,
      receiver_is_blocked:
        'El receptor está bloqueado.\n Desbloqueado el usuario para enviar mensaje',
      voice_note_min_limit_2_sec:
        'La nota de voz debe ser de al menos 2 segundos.',
      limit_not_reached: 'Límite no alcanzado',
      msg_you_can_add: (id, type) =>
        `Puede agregar ${id} para ayudarlo a administrar su ${type}. Mantenga presionado para eliminarlos. `,
      report_details: 'Detalles del informe',
      report_by: 'Informe por',
      report_description: 'Descripción del reporte',
      post_added_success: 'Post added successfully',
      edit_post: 'Edit Post',
      post_update_success: 'Post updated successfully',
      userListOperatorMessage:
        'You can add operator to help you for manage your channel. Press and hold to remove them.',
      userListGroupMessage:
        'You can add admin to help you for manage your group. Press and hold to remove them.',
      no_slots_available_for_date: 'No slots available for this date!',
      unable_to_get_user_availability: 'unable to get user availability',
      please_enter_details: 'Please enter details',
      reel_downvoted_successfully: 'Reel downvoted successfully',
      reel_upvoted_successfully: 'Reel upvoted successfully',
      delete_reel: 'Delete Reel',
      delete_post: 'Delete Post',
      are_you_sure_delete_this_reel:
        'Are you sure you want to delete this reel?',
      are_you_sure_delete_this_post:
        'Are you sure you want to delete this post?',
      buzzmi_need_permission_to_access_your_device_storage:
        'Buzzmi need permission to access your device storage. Go to Settings to allow access',
      trending: 'Trending',
      tweets: 'tweets',
      report_type: 'Tipo de informe',
      sa_str_last_seen_yesterday: 'Visto por última vez ayer',
      no_travel_packages_available: 'No hay paquetes de viaje disponibles',
      channel_information: 'información del canal',
      change_group_image: 'Cambiar imagen de grupo',
      enter_name: 'Ingrese su nombre',
      change_group_name: 'Cambiar nombre de grupo',
      change_channel_name: 'Cambiar el nombre del canal',
      and_delete_chat: 'y eliminar el chat',
      exit_something: 'Salir del',
      will_be_notified: 'será notificado',
      no_one_in_the: 'Nadie en el',
      report_this: 'Reporta esto',
      msg_report_channel:
        'Los últimos 5 mensajes de este canal se reenviarán a Buzzmi. Si sale de este canal y elimina el chat, el mensaje solo se eliminará del dispositivo y de sus dispositivos en la versión más reciente de Buzzmi.',
      msg_report_group:
        'Los últimos 5 mensajes de este grupo se reenviarán a Buzzmi. Si sale de este grupo y elimina el chat, el mensaje solo se eliminará del dispositivo y de sus dispositivos en la versión más reciente de Buzzmi.',
      warn_no_reported_users: 'No se ha encontrado ningún miembro reportado',
      warn_no_mute_users: 'No se encontró ningún usuario mudo',
      warn_no_banned_users: 'No se ha encontrado ningún usuario prohibido',
      msg_sure_to_un_mute:
        '¿Estás seguro de que quieres dejar de silenciar a este miembro?',
      msg_sure_to_un_ban:
        '¿Estás seguro de que quieres desbanear a este miembro?',
      banned: 'Prohibido',
      copy_link: 'Copiar link',
      link_copied_with_success: 'Enlace de invitación copiado con éxito',
      send_link_via_buzzmi: 'Enviar enlace a través de Buzzmi',
      invite_with_link_description:
        'Cualquier persona con Buzzmi puede seguir este enlace para unirse a este canal. Solo compártelo con personas en las que confíes.',
      invite_to_channel_via_link: 'Invitar al canal a través de un enlace',
      warn_invitation_link_sent: 'Enlace de invitación enviado con éxito',
      add: 'Agregar',
      to_a_channel: 'a un canal',
      add_a_contact: 'Añade un contacto',
      invitation_sent_success: 'Invitación enviada con éxito',
      mobile: 'Móvil',
      send_invitation: 'Enviar Invitacion',
      send: 'Enviar',
      comments: 'Comentarios',
      no_comment_found: 'Ningún comentario encontrado',
      post_comment: 'publicar comentario',
      admins: 'Administradores',
      leave_group: 'Abandonar grupo',
      report_group: 'Grupo de informes',
      add_members: 'Añadir miembros',
      operator: 'Operador',
      operators: 'Operadores',
      banned_members: 'Miembros prohibidos',
      muted_members: 'Miembros silenciados',
      reported_members: 'Miembros Reportados',
      report_channel: 'Canal de denuncia',
      freeze_channel: 'Congelar canal',
      members: 'Miembros',
      leave_channel: 'Abandonar canal',
      error_leave_channel: 'No podía salir del canal.',
      warn_sure_delete: '¿Estás seguro de que quieres eliminar?',
      error_turning_off_notifications:
        'No se pudieron desactivar las notificaciones.',
      error_turning_on_notifications:
        'No se pudieron activar las notificaciones.',
      turn_off_notifications: 'Desactivar las notificaciones',
      turn_on_notifications: 'Activar las notificaciones',
      you: 'Tú',
      few_weeks_ago: 'hace algunas semanas',
      one_week_ago: 'Hace 1 semana',
      photo: 'Foto',
      error_cam_not_available: 'Cámara no disponible',
      files: 'archivos',
      error_download_file: 'No se pudo descargar el archivo.',
      file_saved: 'Archivo guardado',
      downloading: 'Descargando...',
      delivered_to: 'Entregado a',
      read_by: 'Leído por',
      copied: 'Copiado',
      copy: 'Copiar',
      error_delete_message: 'No se pudo eliminar el mensaje.',
      delete_message: '¿Borrar mensaje?',
      remove: 'Remove',
      retry: 'Rever',
      warn_need_mic_permission:
        '¡Necesita permiso del micrófono para continuar!',
      photo_library: 'Librería fotográfica',
      camera: 'Cámara',
      slide_to_cancel: 'Deslizar para cancelar',
      error_receiver_blocked:
        'El receptor está bloqueado. Desbloquear al usuario para enviar mensaje',
      error_opening_files: 'No se pudieron abrir los archivos.',
      msg_no_longer_admin: 'ya no es un administrador.',
      error_sending_message: 'No se pudo enviar el mensaje.',
      write_a_message: 'Escribe un mensaje',
      chat_is_unavailable: 'El chat no está disponible',
      msg_group_created: 'Se crea el grupo.',
      and: 'y',
      joined_dot: 'unido.',
      is_now_an_admin: 'ahora es un administrador.',
      msg_channel_created: 'Se crea el canal.',
      online: 'En línea',
      failed: 'Fallido',
      warn_allow_camera_and_microphone_access:
        'Para llamar, permita que Buzzmi acceda a su cámara y micrófono',
      Insufficient_permissions: '¡Permisos insuficientes!',
      warn_user_is_on_snooze:
        'Este usuario está actualmente en modo de repetición.',
      warn_user_is_on_dnd: 'Este usuario está actualmente en modo no molestar.',
      last_seen_few_week_ago: 'Visto por última vez hace unas semanas',
      last_seen_a_week_ago: 'Visto por última vez hace 1 semana',
      sa_str_group_yesterday: 'Ayer',
      sa_str_last_seen: 'Ultima vez visto',
      channel_is_frozen: 'el canal esta congelado',
      sa_str_end_to_end_encrypted:
        'Los mensajes y las llamadas están encriptados de extremo a extremo. Nadie fuera de este chat, \nni siquiera Buzzmi, puede leerlos o escucharlos.',
      group_reported: 'Grupo Reportado',
      channel_reported: 'Canal Reportado',
      warn_select_reason: 'Por favor seleccione el motivo',
      user_reported: 'Usuario reportado',
      warn_report_noted: 'Se toma nota de su informe. revisaremos esto',
      got_it: 'Entiendo',
      warn_write_your_reason: 'Escribe tu razón por favor',
      message: 'Mensaje',
      unblock_contact: 'Desbloquear contacto',
      new_contact: 'Nuevo contacto',
      new_channel: 'Nuevo canal',
      new_group: 'Nuevo grupo',
      new_chat: 'Nueva conversación',
      incoming: 'Llamada entrante',
      outgoing: 'Llamada saliente',
      following: 'Siguiente',
      followers: 'Seguidores',
      views: 'Puntos de vista',
      streamer_hours: 'Horas de transmisión',
      follow: 'Seguir',
      mute: 'Mudo',
      search_message: 'Mensaje de búsqueda',
      block_contact: 'Bloquear mensaje',
      report: 'Informe',
      calling: 'Vocación',
      left_leave: 'dejado.',
      cancelled: 'Cancelado...',
      cap_cancelled: 'CANCELADO...',
      warn_no_record_found: 'Ningún record fue encontrado',
      call_history: 'Historial de llamadas',
      missed: 'Omitido',
      network: 'Red',
      contacts: 'Contactos',
      all: 'Todo',
      group: 'Grupo',
      channel: 'Canal',
      Languages: 'Idiomas',
      Language: 'Idioma',
      ContactUs: 'Contáctenos',
      Change_Language: 'Cambiar idioma',
      App_Language_Changed_to: 'El idioma de la aplicación cambiará a',
      OK: 'DE ACUERDO',
      Cancel: 'Cancelar',
      Invite: 'Invitar',
      Edit_Profile: 'Editar perfil',
      Terms_Of_Use: 'Condiciones de uso',
      Privacy_Policy: 'política de privacidad',
      Settings: 'Ajustes',
      Delete_Account: 'Borrar cuenta',
      Logout: 'Cerrar sesión',
      Account_Verification: 'Verificación de la cuenta',
      Do_not_disturb: 'No molestar',
      Snooze: 'Siesta',
      Chats: 'charlas',
      Font_size: 'Tamaño de fuente',
      Show_last_seen: 'Mostrar visto por última vez',
      Privacy: 'Privacidad',
      view_profile: 'Ver perfil',
      membership_level: 'nivel de membresía',
      sa_str_wallet: 'Cartera',
      my_wallet: 'Mi billetera',
      update: 'Actualizar',
      manage: 'Administrar',
      see_your_benefits: 'VEA SUS BENEFICIOS',
      rank_ladder: 'Escala de rango',
      my_missions: 'mis misiones',
      buy_coins: 'Comprar monedas',
      add_friends: 'Añade amigos',
      market_place: 'mercado',
      contributors: 'Colaboradores',
      invite_3_friends: 'Invita a 3 amigos',
      and_earn_5: 'y gana 5',
      cap_buzzmi: 'BUZZMI',
      invite: 'INVITAR',
      no_caps_invite: 'Invitar',
      home: 'Hogar',
      chat: 'Charlar',
      trip: 'Viaje',
      account: 'Cuenta',
      select_membership: 'Seleccione Membresía',
      membership: 'Afiliación',
      back: 'Atrás',
      later: 'Más tarde',
      get_this_membership: 'Obtén esta membresía',
      dismiss: 'Despedir',
      go_back: 'Regresa',
      notifications: 'Notificaciones',
      sa_str_verify_number_1: 'Verifique su \nnúmero de móvil',
      del_your_acc_will: 'Eliminar su cuenta',
      del_your_acc_info_and_profile_photo:
        'Eliminar la información de su cuenta y la foto de perfil',
      del_you_from_all_ones_say_groups:
        "Eliminarte de todos los grupos de One's Say",
      del_your_mess_hist:
        'Elimina tu historial de mensajes en este teléfono y tus copias de seguridad',
      temp_inactive_your_acc_will: 'Temporalmente inactiva su cuenta será',
      temp_inact_your_ones_say_acc:
        "Desactive temporalmente su cuenta de One's Say",
      not_disp_your_prof_det_in_ones_say:
        "No mostrar su perfil/detalles de contacto en One's Say",
      mark_login_cred_as_invalid:
        'Marque su credencial de inicio de sesión actual como no válida',
      allow_to_activate_the_same_acc:
        "Permita activar la misma cuenta cuando lo desee a través de la opción 'Olvidé mi contraseña'",
      your_phone_number: 'Su número de teléfono',
      delete_my_account: 'Borrar mi cuenta',
      inactive_my_account: 'Mi cuenta inactiva',
      write_reason: 'Escribe tu razón por favor',
      please_des_problem: 'Por favor, describa el problema con más detalle.',
      what_is_your_reason_for_leaving: '¿Cuál es su razón principal para irse?',
      are_you_sure_want_to_delete_account:
        '¿Está seguro de que desea eliminar su cuenta? Esto borrará permanentemente tu cuenta.',
      delete_all_caps: 'BORRAR',
      select_reason: 'Seleccionar motivo',
      inactive_account: 'Cuenta inactiva',
      are_you_sure_inactive_account:
        "¿Está seguro de que desea inactivar su cuenta? Tenga en cuenta que tiene una cuenta inactiva con el mismo correo electrónico/número de teléfono móvil en One's Say, visite 'Olvidé mi contraseña' para activar la misma cuenta o use detalles diferentes para crear una nueva cuenta",
      inactive: 'Inactiva',
      done: 'Hecho',
      are_you_sure_logout: '¿Estás seguro de que quieres cerrar sesión?',
      no: 'No',
      yes: 'Sí',
      english: 'Inglés',
      spanish: 'Español',
      account_details: 'detalles de la cuenta',
      display_name: 'Nombre para mostrar',
      user_Id: 'ID de usuario',
      username: 'Nombre de usuario',
      first_name: 'Nombre de pila',
      last_name: 'Apellido',
      meeting_price: 'Precio de reunión',
      meeting_duration: 'Duración de la reunión',
      referral_code: 'Código de referencia',
      sponsor: 'Patrocinador',
      date_of_birth: 'Fecha de nacimiento',
      gender: 'Género',
      choose_image: 'Elegir imagen',
      male: 'Masculino',
      female: 'femenina',
      other: 'otro',
      sponsor_id: 'Documento de identidad del patrocinador',
      are_you_sure_you_want: 'Estas seguro que quieres',
      as_your_sponsor: 'como tu patrocinador',
      okay: 'Bueno',
      invalid_referral_code: 'Código de referencia no válido',
      please_enter_display_name: 'Ingrese el nombre para mostrar',
      display_name_should_not:
        'El nombre para mostrar no debe contener números, debe ser una palabra y debe tener entre 3 y 20 caracteres.',
      please_enter_first_name: 'Por favor, introduzca el nombre',
      please_enter_valid_first_name:
        'El nombre no debe contener números y debe tener entre 3 y 20 caracteres',
      please_enter_last_name: 'Por favor ingrese apellido',
      please_enter_valid_last_name:
        'El apellido no debe contener números y debe tener entre 3 y 20 caracteres.',
      please_enter_dob: 'Por favor, introduce tu fecha de nacimiento',
      please_enter_valid_dob:
        'Por favor, introduzca una fecha de nacimiento válida',
      please_select_gender: 'Por favor especifica tu género',
      meeting_price_cannot: 'El precio de la reunión no puede ser 0',
      verify_account: 'Verificar Cuenta',
      take_photo: 'Tomar foto',
      mobile_number: 'Número de teléfono móvil',
      email: 'Correo electrónico',
      two_factor_authentication: 'Autenticación de dos factores',
      add_extra_layer:
        'Agregue una capa adicional de seguridad a su cuenta habilitando la autenticación de dos factores.',
      please_enter_mobile_number: 'Por favor, introduzca el número de móvil',
      please_enter_valid_phone:
        'Por favor ingrese un número de teléfono válido',
      please_enter_email: 'Por favor ingrese el correo electrónico',
      please_enter_valid_email:
        'Por favor introduzca un correo electrónico válido',
      enter_your_verification_code: 'Ingrese su código de verificación',
      we_sent_the_code: 'Nosotros enviamos el codigo a',
      phone_small: 'teléfono',
      email_small: 'correo electrónico',
      this_code_will_expire: 'Este código caducará en',
      new_password: 'Nueva contraseña',
      confirm_password: 'confirmar Contraseña',
      submit: 'Entregar',
      resend_code: 'Reenviar codigo',
      please_enter_received_code: 'Por favor, introduzca el código recibido.',
      please_enter_password: 'Por favor, ingrese contraseña',
      invalid_password: 'Contraseña invalida',
      please_enter_confirm_password: 'Por favor ingrese confirmar contraseña',
      passwords_do_not_match: 'Las contraseñas no coinciden',
      password_length_to_be:
        '- La longitud de la contraseña debe ser 8 mínimo y 15 máximo\n',
      at_least_one_special_char: '- Al menos un personaje especial\n',
      at_least_one_capital_letter: '- Al menos una mayúscula\n',
      at_least_one_number: '- Al menos un Número\n',
      no_more_than_two_consecutive: '- No más de dos caracteres consecutivos',
      enable_twofactor_auth: 'Habilitar la autenticación de dos factores',
      an_authenticator_app_lets_you:
        'Una aplicación de autenticación le permite generar códigos de seguridad en su teléfono sin necesidad de recibir mensajes de texto. Si aún no tiene uno, apoyamos e. gramo. estas aplicaciones.',
      to_configure_your: 'Para configurar su aplicación de autenticación:',
      add_a_new_timebased:
        'Agregar un nuevo token basado en el tiempo \nUse su aplicación para escanear el código QR a continuación',
      enter_the_code_generated: 'Ingrese el código que se generó',
      code: 'Código',
      validate: 'Validar',
      verify: 'Verificar',
      enable_now: 'Habilitado ahora',
      save: 'Ahorrar',
      change: 'Cambiar',
      disable_now: 'Deshabilitar ahora',
      enable_successfully: 'habilitar con éxito',
      secret_key_copied: 'Clave secreta copiada',
      contributionscore: 'Puntuación de contribución',
      activity: 'Actividad',
      gained_score: 'Puntuación_ganada',
      date_created: 'fecha de creacion',
      contributor_not_found: 'Colaborador no encontrado',
      start_time: 'Hora de inicio',
      end_time: 'Hora de finalización',
      this_option_routinely:
        'Esta opción suspenderá las notificaciones de forma rutinaria durante períodos establecidos cada día.',
      please_select_start_time: '¡Seleccione la hora de inicio!',
      please_select_end_time: '¡Seleccione la hora de finalización!',
      start_time_end_time_not_same:
        '¡La hora de inicio y la hora de finalización no pueden ser iguales!',
      please_select_valid_start_date: 'Seleccione una fecha de inicio válida.',
      search: 'Buscar',
      feed: 'Alimentar',
      reels: 'Bobinas',
      myposts: 'Mis publicaciones',
      choose_photo: 'Escoge una foto',
      confirm: 'Confirmar',
      select_date: 'Seleccione fecha',
      select_time: 'Seleccionar hora',
      edit: 'Editar',
      delete: 'Borrar',
      tap_options: 'Toca opciones',
      change_channel_image: 'Cambiar la imagen del canal',
      view: 'Vista',
      choose_file: 'Elija el archivo',
      error_open_photo_lib: 'No se pudo abrir la biblioteca de fotos.',
      error_open_camera: 'No se pudo abrir la cámara.',
      allow_permission: 'Permitir permiso',
      cap_settings: 'AJUSTES',
      error_upload_image: 'No se pudo cargar la imagen.',
      points: 'Puntos',
      total: 'Total',
      spent: 'gastado',
      wallet_activity: 'Actividad de la billetera',
      to: 'A',
      no_activity_found: 'No se encontró actividad',
      clear: 'Claro',
      categories: 'Categorías',
      all_categories: 'Todas las categorias',
      no_product_found: 'No se encontró ningún producto',
      store: 'Almacenar',
      all_offers: 'Todas las ofertas',

      cash_back: 'Devolución de dinero',
      get_deal: 'Obtener oferta',
      error_only_edit_your_products: 'Solo puedes editar tus productos',
      delete_category: 'Eliminar categoría',
      warn_delete_category:
        '¿Está seguro de que desea eliminar esta categoría?',
      warn_no_vendor_account:
        'Parece que no tienes acceso a la cuenta de proveedor. ¿Quieres iniciar el proceso de registro?',
      add_product: 'Agregar producto',
      product_name: 'Nombre del producto',
      product_price: 'Precio del producto',
      select_category: 'Selecciona una categoría',
      category_title: 'Título de la categoría',
      suspicious: 'Sospechoso',
      spam: 'Correo basura',
      inappropriate: 'Inadecuado',
      harassing: 'acosar',
      edit_category: 'Editar Categoria',
      add_category: 'Añadir Categoría',
      need_camera_permission:
        'Se necesita permiso para la cámara para continuar',
      are_you_sure_dismiss_memeber:
        '¿Está seguro de que desea descartar a este miembro como',
      demote: 'Degradar',
      no_name: 'Sin Nombre',
      dismiss_from: 'Descartar de',
      owner: 'Propietario',
      ban: 'Prohibición',
      are_you_sure_to_ban:
        '¿Estás seguro de que quieres prohibir a este miembro?',
      unmute: 'No silenciar',
      are_you_sure_want_unmute:
        '¿Estás seguro de que quieres dejar de silenciar a este miembro?',
      are_you_sure_want_mute: '¿Seguro que quieres silenciar a este miembro?',
      dismiss_from_operator: 'Descartar del operador',
      promote_operator: 'Promocionar Operador',
      are_you_sure_want_report: '¿Estás seguro de que quieres reportar?',
      report_member: 'Reportar miembro',
      remove_from: 'Remover de',
      remove_from_admin: 'Remover de los administradores',
      make_admin: 'Hacer administrador',
      admin: 'Administrador',
      invite_user: 'Invitar usuario',
      are_not_memeber_of_onesay:
        " no son miembros de one'say. ¿Quieres enviarles una invitación?",
      please_select_users: 'Por favor seleccione usuarios',
      create_channel: 'Crear canal',
      create_group: 'Crear grupo',
      create: 'Crear',
      invite_members: 'Invitar miembros',
      please_enter_channel_name: 'Por favor ingresa el nombre del canal',
      please_enter_group_name: 'Por favor ingresa el nombre del grupo',
      post: 'Correo',
      reel: 'carrete',
      create_post: 'Crear publicación',
      whats_on_your_mind: 'Qué tienes en mente',
      upload: 'Subir',
      reels_updated_successfully: 'Carretes actualizados con éxito',
      please_enter_content: 'Por favor ingrese el contenido',
      reels_added_successfully: 'Carretes agregados con éxito',
      error_while_uploading_reels: 'Error al cargar carretes',
      content_should_be_at_least:
        'El contenido debe tener al menos 3 caracteres.',
      couldnt_reach_server:
        "Couldn't reach server. Check your internet connection",
      to_call_allow_buzzmi_access:
        'Para llamar, permita que Buzzmi acceda a su cámara y micrófono',
      to_answer_allow_buzzmi_access:
        'Para responder, permita que Buzzmi acceda a su cámara y micrófono',
      you_need_grant_storage_access:
        'Debe otorgar permisos de acceso al almacenamiento.',
      permissions_for_record_audio: 'Permisos para grabar audio',
      give_permission_record_audio:
        'Dar permiso a su dispositivo para grabar audio',
      You_need_grant_microphone:
        'Debe otorgar permisos de acceso al micrófono.',
      your_username_or_email: 'Su nombre de usuario o correo electrónico',
      phone_number: 'Número de teléfono',
      boaring_vocabulary_title: '¡Aburrido no es en nuestro vocabulario!',
      boaring_vocabulary_description:
        '¡Bienvenido a la plataforma definitiva para socializar, fitness y diversión!',
      become_a_part_of_community_description:
        'Conviértase en parte de una comunidad de personas de ideas afines y acceda a contenido de alta calidad en salud y bienestar, eventos modernos y desafíos emocionantes, todo en su teléfono. ¡Deja que se vuelvan zumbando!',
      make_money_with_buzzmi_title: 'Gane dinero con Buzzmi',
      make_money_with_buzzmi_description: '¡Donde sucede el verdadero ajetreo!',
      ready_for_a_challenge_title: '¿Listo para un desafío?',
      ready_for_a_challenge_description:
        'Tómate a tus amigos y gane en grande en Buzzmi',
      fair_pay_for_fair_work_title: 'Salario justo por el trabajo justo.',
      fair_pay_for_fair_work_description:
        'Los creadores de Buzzmi ganan el 90% de cada suscripción, sin tarifas. Mantenemos solo el 10% para mantener las cosas zumbando.',
      continue_with_facebook: 'Continuar con Facebook',
      continue_with_apple: 'Continuar con Apple',
      continue_with_google: 'Continuar con Google',
      your_phone_or_email: 'Su teléfono o correo electrónico',
      your_password: 'Crea tu contraseña',
      forgot_password: 'Has olvidado tu contraseña',
      log_in: 'ACCESO',
      login: 'Acceso',
      or: 'o',
      dont_have_an_account: '¿No tienes una cuenta?',
      sign_up: 'Inscribirse',
      lets_get_your_setup: 'Vamos a configurarte',
      already_have_an_account: '¿Ya tienes una cuenta?',
      signup_with_phone_or_email:
        'Regístrese con teléfono o correo electrónico',
      forgot_password_description:
        '¡No te preocupes! Sucede. Ingrese la dirección asociada con su cuenta.',
      forgot_your_password: '¿Olvidaste tu contraseña?',
      continue: 'CONTINUAR',
      provide_your_contact_information:
        'Proporcione su información de contacto',
      enter_your_email: 'Introduce tu correo electrónico',
      or_sign_up_with_phone: 'o regístrese con el teléfono',
      or_sign_up_with_email: 'o regístrese con el correo electrónico',
      contact_email_description:
        'Las cuentas deben proporcionar un número de teléfono o correo electrónico con fines de seguridad y privacidad. Solo puede tener una cuenta vinculada a su número de teléfono',
      next: 'PRÓXIMO',
      verify_your_email: 'Verifique su correo electrónico',
      verify_your_phone_number: 'verifica tu numero de telefono',
      verification_code_sent_description:
        'Enviamos un código {digit}-digit a {email} confirmar que le pertenece a mantener su cuenta segura.',
      time_to_buzz_into_action: '¡Es hora de volar en acción!',
      bio_description:
        'Solo necesitamos algunos detalles más para personalizar su experiencia Buzzmi.',
      user_name: 'Nombre de usuario',
      when_your_birhtday: '¿Cuándo es tu cumpleaños?',
      birthday_not_shown_publicaly: 'Tu cumpleaños no se mostrará públicamente',
      invitation_code: 'código de invitación',
      password_priority_title:
        'Su seguridad es nuestra principal prioridad. ¡Asegúrese de que su contraseña refleje eso!',
      create_your_password: 'Crea tu contraseña',
      password_requirement: 'Requisitos de contraseña:',
      make_it_long: 'Hazlo',
      from_8_to_20_characters: 'De 8 a 20 caracteres',
      make_it_diverse: 'Hazlo diverso',
      password_should_contain_one_special_character:
        'Debe contener al menos una letra de mayúsculas y al menos un carácter especial',
      field_is_required: 'Se requiere campo',
      invalid_phone_or_email: 'Teléfono o correo electrónico inválido',
      invalid_phone: 'Teléfono inválido',
      password_must_contain_at_least_1_lower_case_letter:
        'La contraseña debe contener al menos 1 carta de minúsculas',
      password_must_contain_at_least_1_upper_case_letter:
        'La contraseña debe contener al menos 1 letra de mayúsculas',
      password_must_contain_at_least_1_number:
        'La contraseña debe contener al menos 1 número',
      password_must_contain_at_least_1_special_character:
        'La contraseña debe contener al menos 1 carácter especial',
      minimum_required_length_8_characters:
        'Mínimo longitud requerida 8 caracteres',
      userName_must_contain_at_least_1_lower_case_letter:
        'El nombre de usuario debe contener al menos 1 letra minúscula',
      userName_must_contain_at_least_1_upper_case_letter:
        'El nombre de usuario debe contener al menos 1 letra mayúscula',
      userName_must_contain_at_least_1_number:
        'El nombre de usuario debe contener al menos 1 número',
      firstName_should_not_contain_numbers:
        'El Nombre no debe contener números',
      lastName_should_not_contain_numbers:
        'El Apellido no debe contener números',
      country_code_is_required: 'Se requiere código de país',
      please_enter_valid_phone_number:
        'Por favor ingrese un número de teléfono válido',
      resend_code_text_timer:
        'Podrá reenviar el código después (${time}) segundos',
      wrong_verification_number: 'Número de verificación incorrecto',
      your_email_has_been_verified: 'Su correo electrónico ha sido verificado',
      your_phone_has_been_verified: 'Tu teléfono ha sido verificado',
      re_enter_the_code: 'Volver a ingresar el código',
      success: '¡Éxito!',
      must_include_at_least_1_lower_case_letter:
        'Debe incluir al menos 1 carta de minúsculas',
      must_include_at_least_1_upper_case_letter:
        'Debe incluir al menos 1 carta de mayúsculas',
      must_include_at_least_1_number: 'Debe incluir al menos 1 número',
      email_is_required: 'correo electronico es requerido',
      invalid_email: 'Email inválido',
      invalide_mobile: 'Móvil no válido',
      invalid_dob: 'fecha de nacimiento invalida',
      age_18_puls_description:
        'Lo sentimos, parece que no eres elegible para Buzzmi... ¡Pero gracias por visitarnos!',
      contain_at_least_one_upper_case_letter:
        'contener al menos una carta de mayúsculas',
      contain_at_least_one_special_character:
        'contener al menos un personaje especial',
      confirm_password_must_be_the_same_as_password:
        'confirmar la contraseña debe ser lo mismo que la contraseña',
      password_must_match: 'Las contraseñas deben coincidir',
      choose_your_role: 'Elige tu papel',
      we_are_curios: 'Tenemos curiosidad:',
      are_you: '¿eres un ...',
      the_spot_light_on_you: '¡El foco está en ti!',
      top_3_creator_select:
        '¿Quiénes son tus 3 mejores creadores? ¡Haznos saber!',
      or_invite_friend_individually: 'o invitar a amigos induvidualy',
      we_are_all_ears: '¡Todos somos oídos!',
      tell_us_what_your_interest: 'Cuéntanos qué desglose tu interés.',
      verification_code: 'Código de verificación',
      enter_otp: 'Ingrese OTP',
      foreget_password_masked_text:
        'Queremos asegurarnos de que realmente sea tú. Para verificar aún más su identidad, ingrese el código de verificación que se envió a ${email}',
      reset_your_password: 'Restablecer su contraseña',
      one_subscription_endless_possblities:
        'Una suscripción, infinitas posibilidades',
      unlimited_access_to_100s_of_creators_and_all_premium_features:
        'Acceso ilimitado a 100S+ de creadores y todas las características premium',
      get_more_of_the_app_and: 'Obtenga más de la aplicación y',
      save_40: 'AHORRAR 40%',
      your_first_7_days_free_no_commitment_cancel_anytime:
        'Tus primeros 7 días gratis. Sin compromiso. Cancelar en cualquier momento',
      elte_creator_description:
        'Puede comenzar a expandir su negocio utilizando nuestra tecnología avanzada, plataforma fácil de usar y un equipo de atención al cliente dedicado.',
      close: 'Cerca',
      learn_more: 'Aprende más',
      premium_description:
        'Obtenga acceso instantáneo a la mayor oferta de todos los libros de jugadas más de 1000 entrenadores, atletas y entrenadores.',
      start_my_7_days_trial: 'COMIENCE MI PRUEBA DE 7 DÍAS',
      skip_trial: 'SKIP TRIAL',
      subscribe: 'SUSCRIBIR',
      become_premium_member: 'Ser premium \n Miembro',
      pay_with_credit_card: 'Paga con tarjeta de crédito',
      cardholder_Name: 'Nombre del titular de la tarjeta',
      card_Number: 'Número de tarjeta',
      expiration: 'Vencimiento',
      subtotal: 'Total parcial',
      discount: 'Descuento',
      tax: 'Impuesto',
      checkout: 'VERIFICAR',
      you_now_a_part_of_the_buzz_worthy_world_of_Buzzmi:
        'Ahora eres parte del mundo digna de Buzzmi.',
      lets_go_to_your_profile: 'Vamos a tu perfil',
      go_to_start: 'Ir a empezar',
      choose_your_payment_method: 'Escoja su método de pago',
      choose_your_another_payment_method: 'Elija otro método de pago',
      to_back: 'Volver',
      your_rank: 'Su rango',
      view_rank_ladder: 'Ver escalera de rango',
      next_rank: 'Siguiente rango',
      rise_and_shine: 'Levántate y brilla',
      my_plan_for_today: 'Mi plan para hoy',
      see_all_tasks: 'Ver todas las actividades',
      upgrade_now: 'Actualizar ahora',
      see_my_earnings: 'Vea mis ganancias',
      wallet: 'Billetera',
      wallet_balance: 'Billetera Balance',
      quick_access: 'Acceso rapido',
      your_current_rank: 'Tu rango actual',
      welcome_description:
        'Estamos aquí para ayudarlo a impulsar sus tareas y aprovechar al máximo su día.',
      your_current_level: 'Tu nivel actual',
      add_activity: 'Agregar actividad',
      tasks: 'Actividades',
      stats: 'Estadísticas',
      leaderboard: 'Tabla de clasificación',
      any_time_of_the_day: 'En cualquier momento del día',
      membership_description:
        '¡Lleva tu viaje de Buzzmi a nuevas alturas! Actualice a Premium y acceda a los mejores contenidos, herramientas y ventajas que la aplicación tiene para ofrecer.',
      free_user: 'Usuario libre',
      premium_member: 'Miembro Premium',
      elite_creator: 'Creador de élite',
      add_task_name: 'Agregar nombre de la tarea',
      add_task: 'Agregar Actividad',
      choose_cover_image: 'Elija imagen de portada',
      add_description: 'Agregar descripción',
      add_4_description_image: 'Agregar 4 imágenes de descripción',
      add_description_video: 'Agregar Video de descripción',
      add_description_audio: 'Agregar Audio',
      display_in_task_center: 'Mostrar en el Centro de Actividades',
      short_description_task: 'Agregar una breve descripción de la actividad',

      upload_your_image: 'Sube tu imagen',
      toggle_description:
        'Cuando el interruptor está activado, ver las actividades como un preajuste; cuando está desactivado, ver las actividades individuales en el centro de actividades.',
      add_my_own_task: 'Agregar mi actividad',
      when: 'Cuando?',
      repeat: 'Repetir',
      repeat_on_every: 'Repita en cada',
      choose_the_day_for_this_activity: 'Elija los días para esta actividad',
      end_date: 'Fecha final',
      set_a_goal: 'Pon una meta',
      enter_a_number: 'Ingrese un numero',
      reminder: 'Recordatorio',
      checklist: 'Lista de Verificación',
      note: 'Nota',
      add_your_note_here: 'Agregue su nota aquí',
      add_photos_of_your_progress: 'Agrega fotos de tu progreso',
      tags: 'Etiquetas',
      add_your_item: 'Agrega tu artículo',
      my_task: 'Mi Actividad',
      buzzmi_task: 'Actividad BuzzMi',
      profile: 'Perfil',
      messages: 'Mensajes',
      space: 'Espacio',
      subscription: 'Sottoscrizione',
      challenges: 'Desafíos',
      hub: 'Centro',
      calendar: 'Calendario',
      subscription_alert_message:
        'Su suscripción se renovará automáticamente en 23 días. Puedes cancelarlo en cualquier momento',
      save_and_continue: 'Guardar y continuar',
      how_to_get_next_level: 'Cómo obtener el siguiente nivel?',
      see_my_beniftis: 'Ver mis beneficios',
      morning: 'Mañana',
      subscribe_now: 'Suscríbase ahora',
      bronze: 'Bronce',
      silver: 'Plata',
      gold: 'Oro',
      choose_subscription_level: 'Elija el nivel de suscripción',
      add_event: 'Añadir evento',
      scheduled: 'Programado',
      event_types: 'Tipos de eventos',
      Shop: 'Comercio',
    },
    it: {
      example: 'Example',
      task_view_preset_based:
        "Vista dell'attività: Basata su preset. Quando il pulsante è attivo, visualizza le attività come preset; quando è disattivo, visualizza le singole attività nel centro attività.",
      max_size: '1. Dimensione Massima',
      max_length: '2. Lunghezza Massima',
      resolution: '3. Risoluzione',
      file_type_image: 'Tipo di file Immagine',
      add_description_images: 'Aggiungi 4 immagini di descrizione',
      short_description_message:
        'Breve descrizione dell attività in poche parole',
      popular_names: 'Nomi popolari',
      suggested_tasks: 'Attività Suggerite',
      enter_a_name: 'Inserisci un nome',
      social_challenges_award:
        'Premiato per aver completato un certo numero di sfide sociali',
      rankings: 'Classifiche',
      badges: 'Badge',
      avatars: 'Avatar',
      earn_more_coins_to_advance_1: 'Guadagna',
      earn_more_coins_to_advance_2: 'monete BuzzMi in più per avanzare a',
      earn_more_coins_and_diamonds_to_advance_1: 'Guadagna',
      earn_more_coins_and_diamonds_to_advance_2: 'monete BuzzMi e',
      earn_more_coins_and_diamonds_to_advance_3:
        'diamanti in più per avanzare a',
      _or: 'O',
      trophy_case_message:
        'Benvenuto nella tua bacheca dei trofei di Buzzmi! Qui troverai la tua collezione di medaglie, ranghi e avatar che mostrano il tuo percorso in Buzzmi. Continua il buon lavoro e continua a spingerti per ottenere di più!',
      str_completed: 'completato',
      invite_users: 'INVITA UTENTI',
      view_details: 'VEDI DETTAGLI',
      your_activities: 'Le Tue Attività',
      task_completion_message:
        'Completa le attività il più possibile e ottieni fantastiche ricompense!',
      become_elite_creator: "DIVENTA UN CREATORE D'ELITE",
      network_level_score: 'Punteggio del Livello di Rete',
      elite_creator_earning: "Guadagni del Creatore d'Elite",
      str_become_premium_member: 'DIVENTA UN MEMBRO PREMIUM',
      premium_member_earning: 'Guadagni Membri Premium',
      daily_projected_score: 'Punteggio Proiettato Giornaliero',
      daily_projected_earnings: 'Guadagni Proiettati Giornalieri',
      welcome: 'Benvenuto',
      explore_claim_rewards: 'Esplora e richiedi ricompense!',
      wallet_balance: 'Saldo Portafoglio',
      recharge_amount: 'Importo Ricarica',
      new_event_type: '+ NUOVO TIPO DI EVENTO',
      search_event: 'Cerca un evento',
      schedule_meetings_message:
        'Programma riunioni senza problemi. Non sovrapporsi mai. Permetti al lavoro con il tuo calendario di automatizzare la pianificazione degli appuntamenti.',
      auto_upgrade_message: 'verrà automaticamente aggiornata a',
      trial_membership_message:
        'La tua prova terminerà tra 2 giorni e la tua iscrizione',
      finish_account_setup: 'Completa la configurazione del tuo account',
      complete_task: 'Completa Attività',
      edit_task: 'Modifica Attività',
      delete_task: 'Elimina Attività',
      msg_premium_access:
        '<strong>Accesso Premium</strong> a video, blog e foto da creator indipendenti.',
      msg_elite_badge:
        "<strong>Ricevi una medaglia d'elite</strong> - Ottieni una medaglia sul tuo canale per aumentare la tua reputazione e distinguerti.",
      msg_hide_boosted_contect:
        '<strong>Nascondi contenuti sponsorizzati</strong> - Rimuovi tutti i contenuti sponsorizzati dal tuo feed per mantenere la tua esperienza senza pubblicità.',
      msg_verify_your_channel:
        '<strong>Verifica il tuo canale</strong> - Richiedi di verificare la tua identità digitale e far sapere alle persone che sei reale.',
      msg_create_diff_subscription: 'Crea diversi livelli di abbonamento',
      msg_publish_content: 'Pubblica contenuti specifici per i membri premier',
      msg_access_free_content: 'Accesso a contenuti gratuiti',
      msg_be_part_diamond: 'Fai parte del Diamond Pool',
      msg_earn_engagement_on_app: "Guadagna punti per l'interazione nell'app",
      str_and_special_offer: 'E offerta speciale',
      str_how_to_get_next_level: 'Come passare al livello successivo?',
      str_trial_message:
        'La tua prova terminerà tra 2 giorni e la tua iscrizione verrà automaticamente aggiornata a',
      your_username_or_email: 'Il tuo nome utente o e -mail',
      comments: 'Commenti',
      no_comment_found: 'Nessun commento trovato',
      post_comment: 'Posta un commento',
      Languages: 'Le lingue',
      Language: 'Lingua',
      ContactUs: 'Contattaci',
      Change_Language: 'Cambia lingua',
      App_Language_Changed_to: "La lingua dell'app verrà modificata",
      OK: 'OK',
      Cancel: 'Annulla',
      Invite: 'Invitare',
      Edit_Profile: 'Modifica Profilo',
      Terms_Of_Use: 'Termini di utilizzo',
      Privacy_Policy: 'politica sulla riservatezza',
      Settings: 'Impostazioni',
      Delete_Account: "Eliminare l'account",
      Logout: 'Disconnettersi',
      Account_Verification: "Verifica dell'account",
      Do_not_disturb: 'Non disturbare',
      Snooze: 'Posticipare',
      Chats: 'Chat',
      Font_size: 'Dimensione del font',
      Show_last_seen: "Mostra l'ultima volta visto",
      Privacy: 'Privacy',
      view_profile: 'Vedi profilo',
      membership_level: 'Livello di appartenenza',
      sa_str_wallet: 'Portafoglio',
      my_wallet: 'Il mio portafoglio',
      update: 'Aggiornamento',
      manage: 'Maneggio',
      see_your_benefits: 'Guarda i tuoi benefici',
      rank_ladder: 'Scala di rango',
      my_missions: 'Le mie missioni',
      buy_coins: 'Comprare monete',
      add_friends: 'Aggiungere amici',
      market_place: 'Market Place',
      contributors: 'Contributori',
      invite_3_friends: 'Invita 3 amici',
      and_earn_5: 'e guadagnare 5',
      cap_buzzmi: 'Buzzmi',
      invite: 'INVITARE',
      no_caps_invite: 'Invitare',
      home: 'Casa',
      chat: 'Chiacchierata',
      trip: 'Viaggio',
      account: 'Account',
      select_membership: "Seleziona l'adesione",
      membership: 'Appartenenza',
      back: 'Indietro',
      later: 'Dopo',
      get_this_membership: 'Ottieni questo abbonamento',
      dismiss: 'Congedare',
      go_back: 'Torna indietro',
      notifications: 'Notifiche',
      sa_str_verify_number_1: 'Verifica il tuo\nNumero di cellulare',
      del_your_acc_will: 'Eliminare il tuo account lo farà',
      del_your_acc_info_and_profile_photo:
        'Elimina le informazioni sul tuo account e la foto del profilo',
      del_you_from_all_ones_say_groups: 'Eliminati da tutti i gruppi',
      del_your_mess_hist:
        'Elimina la cronologia dei tuoi messaggi su questo telefono e i tuoi backup',
      temp_inactive_your_acc_will: 'Temporaneo inattivo il tuo account lo farà',
      temp_inact_your_ones_say_acc: 'Inattiva temporaneamente il tuo account',
      not_disp_your_prof_det_in_ones_say:
        'Non visualizzare i dettagli del tuo profilo/contatto in proprio',
      mark_login_cred_as_invalid:
        'Segna le tue credenziali di accesso corrente come non valida',
      allow_to_activate_the_same_acc:
        "Consenti di attivare lo stesso account quando si desidera a Throght l'opzione Password dimenticata",
      your_phone_number: 'Il tuo numero di telefono',
      delete_my_account: 'Cancella il mio account',
      inactive_my_account: 'Inattivo il mio account',
      write_reason: 'Scrivi il tuo motivo per favore',
      please_des_problem: 'Descrivi il problema in modo più dettagliato',
      what_is_your_reason_for_leaving:
        'Qual è il tuo motivo principale per partire?',
      are_you_sure_want_to_delete_account:
        'Sei sicuro di voler eliminare il tuo account? Questo cancellerà permanentemente il tuo account',
      delete_all_caps: 'ELIMINARE',
      select_reason: 'Seleziona la ragione',
      inactive_account: 'Account inattivo',
      are_you_sure_inactive_account:
        'Sei sicuro di voler inattivo il tuo account? Si prega di notare che hai un account inattivato con lo stesso numero di e -mail/cellulare nel proprio detto, visitare dimentica la password per attivare lo stesso account o utilizzare dettagli diversi per creare un nuovo account',
      inactive: 'Inattivo',
      done: 'Fatto',
      are_you_sure_logout: 'Sei sicuro che vuoi logout?',
      no: 'NO',
      yes: 'SÌ',
      english: 'Inglese',
      spanish: 'spagnolo',
      account_details: 'Dettagli account',
      display_name: 'Nome da visualizzare',
      user_Id: 'ID utente',
      username: 'Nome utente',
      first_name: 'Nome di battesimo',
      last_name: 'Cognome',
      meeting_price: 'Prezzo di incontro',
      meeting_duration: 'Durata degli incontri',
      referral_code: 'Codice di riferimento',
      sponsor: 'Sponsor',
      date_of_birth: 'Data di nascita',
      choose_image: "Scegli l'immagine",
      gender: 'Genere',
      male: 'Maschio',
      female: 'Femmina',
      other: 'Altro',
      sponsor_id: 'sponsor ID',
      are_you_sure_you_want: 'Sei sicuro di volere',
      as_your_sponsor: 'come il tuo sponsor',
      okay: 'Va bene',
      invalid_referral_code: 'Codice di riferimento non valido',
      please_enter_display_name: 'Inserisci il nome di visualizzazione',
      display_name_should_not:
        'Il nome del display non deve contenere alcun numero, dovrebbe essere una parola e dovrebbe essere lungo compresa tra 3 e 20 caratteri.',
      please_enter_first_name: 'Inserisci il nome',
      please_enter_valid_first_name:
        'Il nome non deve contenere alcun numero e dovrebbe essere lungo compreso tra 3 e 20 caratteri',
      please_enter_last_name: 'Inserisci il cognome',
      please_enter_valid_last_name:
        'Il cognome non deve contenere alcun numero e dovrebbe essere lungo compreso tra 3 e 20 caratteri.',
      please_enter_dob: 'per favore inserisci LA TUA data di nascita',
      please_enter_valid_dob: 'Inserisci la data di nascita valida',
      please_select_gender: 'Seleziona il genere',
      meeting_price_cannot: 'Il prezzo della riunione non può essere 0',
      take_photo: 'Fare foto',
      verify_account: 'Verifica Account',
      mobile_number: 'Numero di cellulare',
      email: 'E-mail',
      verify: 'Verificare',
      two_factor_authentication: 'Autenticazione a due fattori',
      add_extra_layer:
        "Aggiungi un ulteriore livello di sicurezza al tuo account abilitando l'autenticazione a due fattori.",
      please_enter_mobile_number: 'Inserisci il numero di cellulare',
      please_enter_valid_phone:
        'Si prega di inserire un numero di telefono valido',
      please_enter_email: 'Inserisci e -mail',
      please_enter_valid_email: 'inserire una email valida, grazie',
      enter_your_verification_code: 'Inserisci il tuo codice di verifica',
      we_sent_the_code: 'Abbiamo inviato il codice a',
      phone_small: 'telefono',
      email_small: 'e-mail',
      this_code_will_expire: 'Questo codice scadrà in',
      new_password: 'nuova password',
      confirm_password: 'Conferma password',
      submit: 'Invia',
      resend_code: 'Codice di rispedizione',
      please_enter_received_code: 'Immettere il codice ricevuto.',
      please_enter_password: 'Per favore, inserisci la password',
      invalid_password: 'Password non valida',
      please_enter_confirm_password: 'Inserisci Conferma la password',
      passwords_do_not_match: 'le passwords non corrispondono',
      password_length_to_be:
        '- lunghezza della password per essere 8 minimo e 15 massimo\n',
      at_least_one_special_char: '- Almeno un carattere speciale\n',
      at_least_one_capital_letter: '- Almeno una lettera maiuscola\n',
      at_least_one_number: '- Almeno un numero\n',
      no_more_than_two_consecutive: '- non più di due caratteri consecutivi',
      enable_now: 'Abilita ora',
      enable_twofactor_auth: 'Abilita autenticazione a due fattori',
      an_authenticator_app_lets_you:
        "Un'app di autenticatore consente di generare codici di sicurezza sul telefono senza dover ricevere messaggi di testo. Se non ne hai già uno, supportiamo e. G. queste app.",
      to_configure_your: 'Per configurare la tua app di autenticatore:',
      add_a_new_timebased:
        'Aggiungi un nuovo token basato sul tempo\nUsa la tua app per scansionare il codice QR di seguito',
      enter_the_code_generated: 'Immettere il codice generato',
      code: 'Codice',
      validate: 'Convalidare',
      save: 'Salva',
      change: 'Modifica',
      disable_now: 'Disabilita ora',
      enable_successfully: 'Abilita con successo',
      secret_key_copied: 'Copiata chiave segreta',
      contributionscore: 'ContributionScore',
      activity: 'Attività',
      gained_score: 'Guadagnato_score',
      date_created: 'data di creazione',
      contributor_not_found: 'Collaboratore non trovato',
      start_time: 'Ora di inizio',
      end_time: 'Tempo scaduto',
      this_option_routinely:
        'Questa opzione sospenderà regolarmente le notifiche per periodi impostati ogni giorno.',
      please_select_start_time: "Seleziona l'ora di inizio!",
      please_select_end_time: "Seleziona l'ora di fine!",
      start_time_end_time_not_same:
        "L'ora di inizio e il tempo di fine non può essere lo stesso!",
      please_select_valid_start_date: 'Selezionare una data di inizio valida.',
      search: 'Ricerca',
      feed: 'Foraggio',
      reels: 'Bobine',
      myposts: 'Myposts',
      choose_photo: 'Scegli la foto',
      confirm: 'Confermare',
      select_date: 'Seleziona Data',
      select_time: 'Seleziona il tempo',
      edit: 'Modificare',
      delete: 'Eliminare',
      tap_options: 'Tocca le opzioni',
      change_channel_image: "Cambia l'immagine del canale",
      view: 'Visualizzazione',
      choose_file: 'Scegli il file',
      error_open_photo_lib: 'Non è riuscito a aprire la libreria di foto.',
      error_open_camera: 'Non poteva aprire la fotocamera.',
      allow_permission: 'Consenti il ​​permesso',
      cap_settings: 'IMPOSTAZIONI',
      error_upload_image: "Impossibile caricare l'immagine.",
      points: 'Punti',
      total: 'Totale',
      spent: 'Speso',
      wallet_activity: 'Attività del portafoglio',
      to: 'A',
      no_activity_found: 'Nessuna attività trovata',
      clear: 'Chiaro',
      categories: 'Categorie',
      all_categories: 'Tutte le categorie',
      no_product_found: 'Nessun prodotto trovato',
      store: 'Negozio',
      all_offers: 'Tutte le offerte',
      cash_back: 'Rimborso',
      get_deal: 'Ottieni un affare',
      error_only_edit_your_products: 'Puoi solo modificare i tuoi prodotti',
      delete_category: 'Elimina categoria',
      warn_delete_category: 'Sei sicuro di voler eliminare questa categoria?',
      warn_no_vendor_account:
        "Sembra che tu non abbia l'accesso all'account del fornitore. Vuoi iniziare il processo di canto?",
      add_product: 'Aggiungi prodotto',
      product_name: 'nome del prodotto',
      product_price: 'Prezzo del prodotto',
      select_category: 'Seleziona categoria',
      category_title: 'Titolo della categoria',
      suspicious: 'Sospettoso',
      harassing: 'Molestare',
      inappropriate: 'Non appropriato',
      spam: 'Spam',
      edit_category: 'Modifica categoria',
      add_category: 'Aggiungi categoria',
      all: 'Tutto',
      group: 'Gruppo',
      channel: 'Canale',
      contacts: 'Contatti',
      network: 'Rete',
      missed: 'perse',
      call_history: 'Cronologia delle chiamate',
      warn_no_record_found: 'Nessun record trovato',
      following: 'Seguente',
      followers: 'Seguaci',
      views: 'Visualizzazioni',
      streamer_hours: 'Ore di streamer',
      follow: 'Seguire',
      mute: 'Muto',
      search_message: 'Messaggio di ricerca',
      block_contact: 'Messaggio di blocco',
      report: 'Rapporto',
      report_type: 'Tipo di report',
      calling: 'Chiamata',
      cancelled: 'Annullato...',
      cap_cancelled: 'ANNULLATO...',
      outgoing: 'In uscita',
      incoming: 'In arrivo',
      new_chat: 'Nuova chat',
      new_group: 'Nuovo gruppo',
      new_contact: 'Nuovo contatto',
      new_channel: 'Nuovo canale',
      unblock_contact: 'Contatto sblocca',
      message: 'Messaggio',
      warn_write_your_reason: 'Scrivi il tuo motivo per favore',
      got_it: 'Fatto',
      user_reported: 'Utente segnalato',
      warn_report_noted: 'Il tuo rapporto è notato. Lo esamineremo',
      warn_select_reason: 'Seleziona il motivo',
      channel_reported: 'Canale riportato',
      group_reported: 'Gruppo riferito',
      sa_str_end_to_end_encrypted:
        'Messaggi e chiamate sono crittografati end-to-end.\nNessuno al di fuori di questa chat, nemmeno Buzzmi,\npuò leggerli o ascoltarli.',
      channel_is_frozen: 'Il canale è congelato',
      sa_str_last_seen: 'Ultima visualizzazione',
      sa_str_last_seen_yesterday: "Visto l'ultima volta ieri",
      sa_str_group_yesterday: 'Ieri',
      last_seen_a_week_ago: 'Ultimo visto 1 settimana fa',
      last_seen_few_week_ago: 'Ultimo visto poche settimane fa',
      warn_user_is_on_dnd:
        'Questo utente è attualmente in modalità non disturbata.',
      warn_user_is_on_snooze: 'Questo utente è attualmente in modalità snooze.',
      Insufficient_permissions: 'Permessi insufficienti!',
      warn_allow_camera_and_microphone_access:
        "Per chiamare, consentire l'accesso a Buzzmi alla fotocamera e al microfono",
      failed: 'Fallito',
      online: 'in linea',
      is_now_an_admin: 'ora è un amministratore.',
      msg_channel_created: 'Il canale è stato creato.',
      msg_group_created: 'Il gruppo è stato creato.',
      and: 'E',
      joined_dot: 'partecipato.',
      chat_is_unavailable: 'La chat non è disponibile',
      write_a_message: 'Scrivi un messaggio',
      error_sending_message: 'Non potevo inviare un messaggio.',
      msg_no_longer_admin: 'non è più un amministratore.',
      error_opening_files: 'Impossibile aprire i file.',
      error_receiver_blocked:
        "Il ricevitore è bloccato. Sblocca l'utente per inviare il messaggio",
      slide_to_cancel: 'Scivolo per annullare',
      camera: 'Telecamera',
      photo_library: 'Biblioteca di foto',
      warn_need_mic_permission:
        'Hai bisogno di autorizzazione al microfono per procedere!',
      retry: 'Riprovare',
      remove: 'Eliminar',
      delete_message: 'Cancella il messaggio?',
      error_delete_message: 'Non poteva eliminare il messaggio.',
      copy: 'copia',
      copied: 'Copiato',
      read_by: 'Letto da',
      delivered_to: 'Spedito a',
      downloading: 'Download ...',
      file_saved: 'File salvato',
      error_download_file: 'Non è stato possibile scaricare il file.',
      files: 'File',
      error_cam_not_available: 'Fotocamera non disponibile',
      photo: 'Foto',
      one_week_ago: '1 settimana fa',
      few_weeks_ago: 'poche settimane fa',
      you: 'Voi',
      turn_off_notifications: 'Spegni le notifiche',
      turn_on_notifications: 'Accendi le notifiche',
      error_turning_on_notifications: 'Non potevo attivare le notifiche.',
      error_turning_off_notifications: 'Non poteva disattivare le notifiche.',
      warn_sure_delete: 'Sei sicuro di voler eliminare?',
      error_leave_channel: 'Non poteva lasciare il canale.',
      leave_channel: 'Lascia il canale',
      members: 'Membri',
      add_members: 'Aggiungi membri',
      invite_members: 'Invitare i membri',
      operators: 'Operatori',
      operator: 'Operatore',
      banned_members: 'Membri vietati',
      muted_members: 'Membri silenziosi',
      reported_members: 'Membri segnalati',
      report_channel: 'Canale di report',
      report_group: 'Gruppo di report',
      freeze_channel: 'Canale di congelamento',
      leave_group: 'Lascia il gruppo',
      admins: 'Amministratori',
      need_camera_permission:
        'Hai bisogno del permesso della fotocamera per procedere!',
      demote: 'Degradare',
      are_you_sure_dismiss_memeber:
        'Sei sicuro di voler licenziare questo membro come un',
      no_name: 'Senza nome',
      dismiss_from: 'Respingere da',
      owner: 'Proprietario',
      ban: 'Bandire',
      are_you_sure_to_ban: 'Sei sicuro di voler vietare questo membro?',
      unmute: 'Sgraziato',
      are_you_sure_want_unmute: 'Sei sicuro di voler riattivare questo membro?',
      are_you_sure_want_mute: 'Sei sicuro di voler disattivare questo membro?',
      dismiss_from_operator: "Respingere dall'operatore",
      promote_operator: "Promuovere l'operatore",
      report_member: 'Membro del rapporto',
      are_you_sure_want_report: 'Sei sicuro di voler segnalare?',
      remove_from: 'Rimuovere da',
      remove_from_admin: 'Togliere da amministratore',
      make_admin: 'Rendere amministratore',
      admin: 'Amministratore',
      next: 'PROSSIMO',
      invite_user: "Invita l'utente",
      are_not_memeber_of_onesay:
        'non sono memeber di un solo. Vuoi inviare loro invito?',
      please_select_users: 'Seleziona gli utenti',
      create_channel: 'Crea canale',
      create_group: 'Creare un gruppo',
      create: 'Creare',
      please_enter_channel_name: 'Immettere il nome del canale',
      please_enter_group_name: 'Inserisci il nome del gruppo',
      post: 'Inviare',
      reel: 'Bobina',
      create_post: 'Crea post',
      whats_on_your_mind: "Cos'hai in mente",
      upload: 'Caricamento',
      reels_updated_successfully: 'Rulli aggiornati correttamente',
      please_enter_content: 'Inserisci il contenuto',
      reels_added_successfully: 'Bobine aggiunte con successo',
      error_while_uploading_reels: 'Errore durante il caricamento di bobine',
      content_should_be_at_least:
        'Il contenuto dovrebbe essere almeno 3 carattere',
      couldnt_reach_server:
        'Impossibile raggiungere il server. Controlla la tua connessione Internet',
      to_call_allow_buzzmi_access:
        "Per chiamare, consentire l'accesso a Buzzmi alla fotocamera e al microfono",
      to_answer_allow_buzzmi_access:
        "Per rispondere, consentire l'accesso a Buzzmi alla fotocamera e al microfono",
      you_need_grant_storage_access:
        'È necessario concedere le autorizzazioni di accesso allo stoccaggio.',
      permissions_for_record_audio: "Autorizzazioni per l'audio record",
      give_permission_record_audio:
        "Dai l'autorizzazione al tuo dispositivo per registrare l'audio",
      You_need_grant_microphone:
        'È necessario concedere le autorizzazioni di accesso al microfono.',
      send: 'Inviare',
      left_leave: 'Sinistra.',
      send_invitation: 'Spedire un invito',
      mobile: 'Mobile',
      invitation_sent_success: 'Invito Invia con successo',
      add_a_contact: 'Aggiungi un contatto',
      to_a_channel: 'a un canale',
      add: 'Aggiungere',
      warn_invitation_link_sent: 'Link di invito Invia correttamente',
      invite_to_channel_via_link: 'Invita al canale tramite link',
      invite_with_link_description:
        'Chiunque abbia Buzzmi può seguire questo link per unirsi a questo canale. Condividilo solo con persone di cui ti fidi',
      send_link_via_buzzmi: 'Invia link tramite Buzzmi',
      link_copied_with_success: 'Link di invito copiato con successo',
      copy_link: 'Copia link',
      banned: 'Vietato',
      msg_sure_to_un_ban: 'Sei sicuro di voler non suscitare questo membro?',
      msg_sure_to_un_mute: 'Sei sicuro di voler riattivare questo membro?',
      warn_no_banned_users: 'Nessun utente vietato trovato',
      warn_no_mute_users: 'Nessun utente muto trovato',
      warn_no_reported_users: 'Nessun membro riportato trovato',
      msg_report_group:
        'Gli ultimi 5 messaggi di questo gruppo saranno inoltrati a Buzzmi. Se esci da questo gruppo ed elimini la chat, il messaggio verrà rimosso solo dal dispositivo e dai dispositivi sulla versione più recente di Buzzmi.',
      msg_report_channel:
        'Gli ultimi 5 messaggi di questo canale saranno inoltrati a Buzzmi. Se esci da questo canale ed elimina la chat, il messaggio verrà rimosso solo dal dispositivo e dai dispositivi sulla versione più recente di Buzzmi.',
      report_this: 'Segnala questo',
      no_one_in_the: 'Nessuno nel',
      will_be_notified: 'verrà avvisato',
      exit_something: 'Uscita',
      and_delete_chat: 'ed eliminare la chat',
      change_channel_name: 'Cambia il nome del canale',
      change_group_name: 'Cambia nome del gruppo',
      enter_name: 'Inserisci il nome',
      change_group_image: "Cambia l'immagine del gruppo",
      channel_information: 'Informazioni sul canale',
      no_travel_packages_available: 'Nessun pacchetto da viaggio disponibili',
      no_slots_available_for_date: 'Nessun slot disponibile per questa data!',
      unable_to_get_user_availability:
        "Impossibile ottenere disponibilità dell'utente",
      please_enter_details: 'Inserisci i dettagli',
      reel_downvoted_successfully: 'Rullegare con successo',
      reel_upvoted_successfully: 'Rullegare con successo',
      delete_reel: 'Elimina bobina',
      delete_post: 'Elimina il post',
      are_you_sure_delete_this_reel:
        'Sei sicuro di voler eliminare questa bobina?',
      are_you_sure_delete_this_post:
        'Sei sicuro di voler eliminare questo post?',
      buzzmi_need_permission_to_access_your_device_storage:
        "Buzzmi Necessità dell'autorizzazione per accedere all'archiviazione del dispositivo. Vai alle impostazioni per consentire l'accesso",
      trending: 'Tendenza',
      tweets: 'Tweet',
      post_update_success: 'Pubblica aggiornato correttamente',
      edit_post: 'Modifica post',
      post_added_success: 'Post aggiunto con successo',
      report_description: 'Descrizione del report',
      report_by: 'Rapporto di',
      report_details: 'Dettagli del rapporto',
      limit_not_reached: 'Limite non raggiunto',
      voice_note_min_limit_2_sec:
        'La nota vocale deve essere almeno 2 secondi.',
      receiver_is_blocked:
        "Il ricevitore è bloccato.\n Non ha bloccato l'utente per inviare il messaggio",
      the_channels_image_was_updated:
        "L'immagine del canale è stata aggiornata.",
      the_groups_image_was_updated: "L'immagine del gruppo è stata aggiornata.",
      edited: '(modificato)',
      promote: 'promuovere',
      added_success: 'aggiunto con successo.',
      at: 'A',
      rights: 'Diritti',
      change_channel_info: 'Cambia informazioni sul canale',
      add_remove_members: 'Aggiungi/rimuovi i membri',
      invite_users_via_link: 'Invita gli utenti tramite link',
      add_or_remove: 'Aggiungi Rimuovi',
      ban_members: 'Ban membri',
      mute_members: 'Membri muti',
      report_members: 'Report Membri',
      delete_account: "Eliminare l'account",
      deleting_your_account_will: 'Eliminare il tuo account lo farà',
      delete_your_account_profile:
        'Elimina le informazioni sul tuo account e la foto del profilo',
      delete_from_buzzmi_groups: 'Elimina da tutti i gruppi Buzzmi',
      delete_messages_and_backup:
        'Elimina la cronologia dei tuoi messaggi su questo telefono e i tuoi backup',
      temp_inactive_your_account: 'Temporaneo inattivo il tuo account lo farà',
      your_buzzmi_acc_temp_inactive:
        'Ha inattivato temporaneamente il tuo account Buzzmi',
      not_display_buzzmi_profile:
        'Non visualizzare i dettagli del profilo/contatto in buzzmi',
      make_login_invalid:
        'Segna le tue credenziali di accesso corrente come non valida',
      allow_acc_active_with_forgot_pass:
        "Consenti di attivare lo stesso account quando si desidera tramite l'opzione Password dimenticata",
      del_my_acc: 'Cancella il mio account',
      inactive_my_acc: 'Inattivo il mio account',
      msg_inactive_acc_confirm:
        'Sei sicuro di voler inattivo il tuo account? Si prega di notare che hai un account inattivato con lo stesso numero di e -mail/cellulare in Buzzmi, visitare dimentica password" per attivare lo stesso account o utilizzare dettagli diversi per creare un nuovo account',
      currency: 'Valuta',
      awarded_on: 'Assegnato',
      award_type: 'Tipo assegnato',
      share_link: 'Condividi il link',
      qr_code: 'QR Code',
      share_file: 'File condividi',
      is_muted: 'è tenuto',
      voice: 'voce',
      call: 'chiamata',
      completed: 'COMPLETATO',
      missed_call: 'Chiamata persa',
      phone_number: 'Numero di telefono',
      boaring_vocabulary_title: 'Noioso non è nel nostro vocabolario!',
      boaring_vocabulary_description:
        'Benvenuti nella piattaforma finale per socializzare, fitness e divertimento!',
      become_a_part_of_community_description:
        'Diventa parte di una comunità di persone affini e accedi a contenuti di alta qualità su salute e benessere, eventi alla moda e sfide entusiasmanti, tutto sul tuo telefono. Facciamo ronzio!',
      make_money_with_buzzmi_title: 'Fare soldi con Buzzmi',
      make_money_with_buzzmi_description: 'Dove accade il vero trambusto!',
      ready_for_a_challenge_title: 'Pronto per una sfida?',
      ready_for_a_challenge_description:
        'Assumi i tuoi amici e vinci molto su Buzzmi',
      fair_pay_for_fair_work_title: 'Retribuzione equa per un lavoro equo.',
      fair_pay_for_fair_work_description:
        'I creatori di Buzzmi guadagnano il 90% di ogni abbonamento, senza commissioni. Manteniamo solo il 10% per mantenere le cose ronzanti.',
      continue_with_facebook: 'Continua con Facebook',
      continue_with_apple: 'Continua con Apple',
      continue_with_google: 'Continua con Google',
      your_phone_or_email: 'Il tuo telefono o e -mail',
      your_password: 'Crea la tua password',
      forgot_password: 'Ha dimenticato la password',
      log_in: 'LOGIN',
      login: 'Login',
      or: 'O',
      dont_have_an_account: 'Non hai un account?',
      sign_up: 'Iscrizione',
      lets_get_your_setup: 'Ti prendiamo di configurazione',
      already_have_an_account: 'Hai già un account?',
      signup_with_phone_or_email: 'Iscriviti con telefono o e -mail',
      forgot_password_description:
        "Non preoccuparti! Succede. Inserisci l'indirizzo associato al tuo account.",
      forgot_your_password: 'Hai dimenticato la password?',
      continue: 'CONTINUA',
      provide_your_contact_information:
        'Fornisci il tuo contatto\n informazione',
      enter_your_email: 'Inserisci il tuo indirizzo email',
      or_sign_up_with_phone: 'o iscriviti al telefono',
      or_sign_up_with_email: 'Oppure registrarsi con email',
      contact_email_description:
        'Gli account sono tenuti a fornire un numero di telefono o e -mail a fini di sicurezza e privacy. Puoi avere un solo account collegato al tuo numero di telefono',
      verify_your_email: 'verifica la tua email',
      verify_your_phone_number: 'Verifica il tuo numero di telefono',
      verification_code_sent_description:
        'Abbiamo inviato un codice {Digit} -Digit per {email} confermare che ti appartiene per mantenere il tuo account sicuro.',
      time_to_buzz_into_action: 'È ora di ronzare in azione!',
      bio_description:
        'Abbiamo solo bisogno di qualche altro dettaglio per personalizzare la tua esperienza Buzzmi.',
      user_name: 'Nome utente',
      when_your_birhtday: `Quand'è il tuo compleanno?`,
      birthday_not_shown_publicaly:
        'Il tuo compleanno non verrà mostrato pubblicamente',
      invitation_code: 'Codice di invito',
      password_priority_title:
        'La tua sicurezza è la nostra massima priorità. Assicurati che la tua password lo rifletta!',
      create_your_password: 'crea la tua password',
      password_requirement: 'Requisiti della password:',
      make_it_long: 'Rendilo lungo',
      from_8_to_20_characters: 'da 8 a 20 caratteri',
      make_it_diverse: 'Renderlo diverso',
      password_should_contain_one_special_character:
        'Dovrebbe contenere almeno una lettera maiuscola e almeno un carattere speciale',
      field_is_required: 'il campo è obbligatiorio',
      invalid_phone_or_email: 'Telefono o e -mail non valido',
      invalid_phone: 'Telefono non valido',
      password_must_contain_at_least_1_lower_case_letter:
        'La password deve contenere almeno 1 lettera minuscola',
      password_must_contain_at_least_1_upper_case_letter:
        'La password deve contenere almeno 1 lettera maiuscola',
      password_must_contain_at_least_1_number:
        'La password deve contenere almeno 1 numero',
      password_must_contain_at_least_1_special_character:
        'La password deve contenere almeno 1 carattere speciale',
      minimum_required_length_8_characters:
        'Lunghezza minima richiesta 8 caratteri',
      country_code_is_required: 'È richiesto il codice del paese',
      please_enter_valid_phone_number:
        'Si prega di inserire un numero di telefono valido',
      resend_code_text_timer:
        'Sarai in grado di ricredere il codice dopo ($ {time}) secondi',
      wrong_verification_number: 'Numero di verifica errato',
      your_email_has_been_verified: 'La tua email è stata verificata',
      your_phone_has_been_verified: 'Il tuo telefono è stato verificato',
      re_enter_the_code: 'Rientrare nel codice',
      success: 'Successo!',
      must_include_at_least_1_lower_case_letter:
        'Deve includere almeno 1 lettera minuscola',
      must_include_at_least_1_upper_case_letter:
        'Deve includere almeno 1 lettera maiuscola',
      must_include_at_least_1_number: 'Deve includere almeno 1 numero',
      email_is_required: "È richiesta l'e -mail",
      invalid_email: 'E-mail non valido',
      invalide_mobile: 'Mobile non valido',
      invalid_dob: 'Data di nascita non valida',
      age_18_puls_description:
        'Scusa, sembra che non tu non sia idoneo per Buzzmi ... ma grazie per averci controllato!',
      contain_at_least_one_upper_case_letter:
        'contenere almeno una lettera maiuscola',
      contain_at_least_one_special_character:
        'contenere almeno un carattere speciale',
      confirm_password_must_be_the_same_as_password:
        'Confermare che la password deve essere uguale alla password',
      password_must_match: 'Le password devono essere identiche',
      choose_your_role: 'Scegli il tuo ruolo',
      we_are_curios: 'Siamo curiosi:',
      are_you: 'Sei un....',
      the_spot_light_on_you: 'I riflettori sono su di te!',
      top_3_creator_select: 'Chi sono i tuoi primi 5 creatori? Facci sapere!',
      or_invite_friend_individually: 'o invita gli amici induvidual',
      we_are_all_ears: 'Siamo tutti orecchie!',
      tell_us_what_your_interest: 'Raccontaci cosa scintilla il tuo interesse.',
      verification_code: 'codice di verifica',
      enter_otp: 'Immettere OTP',
      foreget_password_masked_text:
        'Vogliamo assicurarci che tu sia davvero tu. Per verificare ulteriormente la tua identità, inserisci il codice di verifica inviato a $ {email}',
      reset_your_password: 'Reimposta la tua password',
      one_subscription_endless_possblities:
        'Un abbonamento, infinite possibilità',
      unlimited_access_to_100s_of_creators_and_all_premium_features:
        'Accesso illimitato a 100s+ di creatori e tutte le funzionalità premium',
      get_more_of_the_app_and: "Ottieni più dell'app e",
      save_40: 'Risparmia il 40%',
      your_first_7_days_free_no_commitment_cancel_anytime:
        'I tuoi primi 7 giorni gratuiti. Nessun impegno. Annulla in qualsiasi momento',
      elte_creator_description:
        'Puoi iniziare ad espandere la tua attività utilizzando la nostra tecnologia avanzata, la piattaforma intuitiva e il team di assistenza clienti dedicata.',
      close: 'Vicino',
      learn_more: 'Saperne di più',
      premium_description:
        "Ottieni l'accesso istantaneo alla più grande offerta di tutti i playbook oltre 1000 allenatori, atleti e allenatori.",
      start_my_7_days_trial: 'Inizia la mia prova di 7 giorni',
      skip_trial: 'Salta la prova',
      subscribe: 'SOTTOSCRIVI',
      become_premium_member: 'Diventare premium\n Membro',
      pay_with_credit_card: 'Pagare con la carta di credito',
      cardholder_Name: 'Nome del titolare',
      card_Number: 'Numero di carta',
      expiration: 'Scadenza',
      subtotal: 'totale parziale',
      discount: 'Sconto',
      tax: 'Imposta',
      checkout: 'GUARDARE',
      you_now_a_part_of_the_buzz_worthy_world_of_Buzzmi:
        'Ora sei parte del mondo degno di Buzzmi',
      lets_go_to_your_profile: 'Andiamo al tuo profilo',
      go_to_start: 'Vai a iniziare',
      choose_your_payment_method: 'Scegli il tuo metodo di pagamento',
      choose_your_another_payment_method: 'Scegli un altro metodo di pagamento',
      to_back: 'Per tornare',
      your_rank: 'Il tuo grado',
      view_rank_ladder: 'Visualizza la scala del rango',
      next_rank: 'Prossimo rango',
      rise_and_shine: 'Rise e brillare',
      my_plan_for_today: 'Il mio piano per oggi',
      see_all_tasks: 'Visualizza tutte le attività',
      upgrade_now: 'Aggiorna ora',
      see_my_earnings: 'Vedere i miei guadagni',
      wallet: 'Portafoglio',
      wallet_balance: 'Portafoglio Bilancia',
      quick_access: 'Accesso veloce',
      your_current_rank: 'Il tuo rango attuale',
      welcome_description:
        'Siamo qui per aiutarti a alimentare le tue cose da fare e sfruttare al meglio la tua giornata.',
      your_current_level: 'Il tuo livello attuale',
      add_activity: 'Aggiungi attività',
      tasks: 'Attività',
      stats: 'Statistiche',
      leaderboard: 'Classifica',
      any_time_of_the_day: 'Ogni momento della giornata',
      membership_description:
        "Fai il tuo viaggio Buzzmi a New Heights! Aggiorna a Premium e accedi a tutti i migliori contenuti, strumenti e vantaggi che l'app ha da offrire.",
      free_user: 'Utente libero',
      premium_member: 'Membro Premium',
      elite_creator: "Creatore d'élite",
      add_task_name: 'Aggiungi nome attività',
      add_task: 'Aggiungi Attività',
      choose_cover_image: "Scegli l'immagine di copertina",
      add_description: 'Aggiungi una descrizione',
      add_4_description_image: 'Aggiungi 4 immagini di descrizione',
      add_description_video: 'Aggiungi la descrizione video',
      add_description_audio: "Aggiungi l'audio ",
      display_in_task_center: 'Mostra nel Centro Attività',
      short_description_task: 'Aggiungi una breve descrizione dell attività',
      upload_your_image: 'Carica la tua immagine',
      toggle_description:
        'Quando il pulsante è attivo, visualizza le attività come preset; quando è disattivo, visualizza le singole attività nel centro attività.',
      add_my_own_task: 'Aggiungi la mia attività',
      when: 'Quando?',
      repeat: 'Ripetere',
      repeat_on_every: 'Ripeti su ogni',
      choose_the_day_for_this_activity: 'Scegli i giorni per questa attività',
      end_date: 'Data di fine',
      set_a_goal: 'Stabilire un obiettivo',
      enter_a_number: 'Immettere un numero',
      reminder: 'Promemoria',
      checklist: 'Elenco di controllo',
      note: 'Nota',
      add_your_note_here: 'Aggiungi la tua nota qui',
      add_photos_of_your_progress: 'Aggiungi foto dei tuoi progressi',
      tags: 'Tag',
      add_your_item: 'Aggiungi il tuo articolo',
      my_task: 'La Mia Attività',
      buzzmi_task: 'Attività BuzzMi',
      profile: 'Profilo',
      messages: 'Messaggi',
      space: 'Spazio',
      subscription: 'Suscripción',
      challenges: 'Sfide',
      hub: 'Centro',
      calendar: 'Calendario',
      subscription_alert_message:
        "L'abbonamento si rinnoverà automaticamente tra 23 giorni. Puoi annullarlo in qualsiasi momento",
      save_and_continue: 'Salva e continua',
      how_to_get_next_level: 'Come ottenere il livello successivo?',
      see_my_beniftis: 'Vedere i miei benefici',
      morning: 'Mattina',
      subscribe_now: 'Iscriviti ora',
      bronze: 'Bronzo',
      silver: 'Bronzo',
      gold: 'Oro',
      choose_subscription_level: 'Scegli il livello di abbonamento',
      add_event: 'Aggiungi evento',
      scheduled: 'Programmato',
      event_types: 'Tipi di eventi',
      Shop: 'Negozio',
    },
    sk: {
      example: 'Example',
      task_view_preset_based:
        'Zobrazenie aktivity: Založené na predvolených nastaveniach. Keď je prepínač zapnutý, zobrazujte aktivity ako predvolené nastavenie; keď je vypnutý, zobrazte individuálne aktivity v centre aktivít.',
      max_size: '1. Maximálna veľkosť',
      max_length: '2. Maximálna dĺžka',
      resolution: '3. Rozlíšenie',
      file_type_image: 'Typ súboru Obrázok',
      add_description_images: 'Pridajte 4 obrázky popisu',
      short_description_message: 'Stručný popis aktivity v niekoľkých slovách',
      popular_names: 'Populárne mená',
      suggested_tasks: 'Navrhované Aktivity',
      enter_a_name: 'Zadajte meno',
      social_challenges_award:
        'Ocenený za splnenie určitého počtu sociálnych výziev',
      rankings: 'Hodnotenia',
      badges: 'Odznaky',
      avatars: 'Avatary',
      earn_more_coins_to_advance_1: 'Zarobte si ďalších',
      earn_more_coins_to_advance_2: 'mincí BuzzMi a postúpte na',
      earn_more_coins_and_diamonds_to_advance_1: 'Zarobte si ďalších',
      earn_more_coins_and_diamonds_to_advance_2: 'mincí BuzzMi a',
      earn_more_coins_and_diamonds_to_advance_3: 'diamantov a postúpte na',
      _or: 'Alebo',
      trophy_case_message:
        'Vitajte vo vašej zbierke trofejí od Buzzmi! Tu nájdete vašu zbierku odznakov, hodností a avatarov, ktoré ukazujú vašu cestu s Buzzmi. Pokračujte v dobrej práci a stále sa snažte o viac!',
      str_completed: 'dokončené',
      invite_users: 'POZVAŤ POUŽÍVATEĽOV',
      view_details: 'ZOBRAZIŤ PODROBNOSTI',
      your_activities: 'Vaše Aktivity',
      task_completion_message:
        'Dokončite aktivity, koľko len môžete, a získajte úžasné odmeny!',
      become_elite_creator: 'STAŤ SA ELITNÝM TVORCOM',
      network_level_score: 'Skóre úrovne siete',
      elite_creator_earning: 'Zarobené Elitným Tvorcom',
      str_become_premium_member: 'STAŤ SA PREMIUM ČLENOM',
      premium_member_earning: 'Zarobené členstvo Premium',
      daily_projected_score: 'Denné Predpovedané Skóre',
      daily_projected_earnings: 'Denné Očakávané Zarábanie',
      welcome: 'Vitajte',
      explore_claim_rewards: 'Preskúmajte a získajte odmeny!',
      wallet_balance: 'Zostatok Peňaženky',
      recharge_amount: 'Suma dobitia',
      new_event_type: '+ NOVÝ TYP UDALOSTI',
      search_event: 'Vyhľadaj udalosť',
      schedule_meetings_message:
        'Plánujte stretnutia bez problémov. Nikdy sa nesprekryvajte. Umožnite spoluprácu s vašim kalendárom na automatizované plánovanie stretnutí.',
      auto_upgrade_message: 'sa automaticky aktualizuje na',
      trial_membership_message:
        'Vaša skúšobná verzia sa skončí o 2 dni a vaše členstvo',
      finish_account_setup: 'Dokončite nastavenie vášho účtu',
      complete_task: 'Dokončiť Aktivitu',
      edit_task: 'Upraviť Aktivitu',
      delete_task: 'Zmazať Aktivitu',
      msg_premium_access:
        '<strong>Premium prístup</strong> k videám, blogom a fotkám od nezávislých tvorcov.',
      msg_elite_badge:
        '<strong>Získaj elitnú odznaku</strong> - Získaj odznak na svojom kanáli na zvýšenie svojej reputácie a vyniknutie.',
      msg_hide_boosted_contect:
        '<strong>Skryj sponzorovaný obsah</strong> - Odstráň všetok sponzorovaný obsah z vášho zobrazenia a užívaj si reklamnú voľnosť.',
      msg_verify_your_channel:
        '<strong>Odošli žiadosť o overenie vášho kanála</strong> - Požiadaj o overenie svojej digitálnej identity a daj ľuďom vedieť, že si skutočný.',
      msg_create_diff_subscription: 'Vytvorte rôzne úrovne prihlásenia',
      msg_publish_content: 'Publikuj obsah špecifický pre premierových členov',
      msg_access_free_content: 'Prístup k voľnému obsahu',
      msg_be_part_diamond: 'Buď súčasťou Diamond Pool-u',
      msg_earn_engagement_on_app: 'Získaj body za angažovanosť v aplikácii',
      str_and_special_offer: 'A špeciálna ponuka',
      str_how_to_get_next_level: 'Ako sa dostať na ďalšiu úroveň?',
      str_trial_message:
        'Vaša skúšobná verzia sa skončí o 2 dni a vaše členstvo sa automaticky aktualizuje na',
      your_username_or_email: 'Vaše používateľské meno alebo e -mail',
      comments: 'Pripomienky',
      no_comment_found: 'Nenašiel sa žiadny komentár',
      post_comment: 'Komentár',
      Languages: 'Jazyky',
      Language: 'Jazyk',
      ContactUs: 'Kontaktuj nás',
      Change_Language: 'Zmeniť jazyk',
      App_Language_Changed_to: 'Jazyk aplikácie sa zmení na',
      OK: 'V poriadku',
      Cancel: 'Zrušiť',
      Invite: 'Pozvať',
      Edit_Profile: 'Upraviť profil',
      Terms_Of_Use: 'Podmienky používania',
      Privacy_Policy: 'Zásady ochrany osobných údajov',
      Settings: 'nastavenie',
      Delete_Account: 'Zmazať účet',
      Logout: 'Odhlásiť sa',
      Account_Verification: 'Overenie účtu',
      Do_not_disturb: 'Nevyrušujte',
      Snooze: 'Odložiť',
      Chats: 'Chatovanie',
      Font_size: 'Veľkosť písma',
      Show_last_seen: 'Show naposledy videný',
      Privacy: 'Súkromie',
      view_profile: 'Prezrieť profil',
      membership_level: 'Členstvo',
      sa_str_wallet: 'Peňaženka',
      my_wallet: 'Moja peňaženka',
      update: 'Aktualizácia',
      manage: 'Spravovať',
      see_your_benefits: 'Zobraziť svoje výhody',
      rank_ladder: 'Rebrík',
      my_missions: 'Moje misie',
      buy_coins: 'Kúpiť mince',
      add_friends: 'Pridať priateľov',
      market_place: 'Miesto trhu',
      contributors: 'Prispievatelia',
      invite_3_friends: 'Pozvite 3 priateľov',
      and_earn_5: 'a zarobiť 5',
      cap_buzzmi: 'Bzučiak',
      invite: 'Pozvať',
      no_caps_invite: 'Pozvať',
      home: 'Domov',
      chat: 'Chatovať',
      trip: 'Vyraziť',
      hub: 'Stredisko',
      account: 'Účtovať',
      select_membership: 'Vyberte členstvo',
      membership: 'Členstvo',
      back: 'späť',
      later: 'Neskôr',
      get_this_membership: 'Toto členstvo',
      dismiss: 'Prepustiť',
      go_back: 'Vráť sa',
      notifications: 'Oznámenia',
      sa_str_verify_number_1: 'Overte svoje číslo  nmobile',
      del_your_acc_will: 'Odstránenie vášho účtu bude',
      del_your_acc_info_and_profile_photo:
        'Odstráňte informácie o vašom účte a profilovú fotografiu',
      del_you_from_all_ones_say_groups: 'Odstráňte vás zo všetkých skupín.',
      del_your_mess_hist:
        'Odstráňte svoju históriu správ v tomto telefóne a zálohovaní',
      temp_inactive_your_acc_will: 'Dočasný neaktívny váš účet bude',
      temp_inact_your_ones_say_acc: 'Dočasne inaktivujte svoj účet Slove',
      not_disp_your_prof_det_in_ones_say:
        'Nezobrazujú svoj profil/kontaktné údaje vo vlastnom vyjadrení',
      mark_login_cred_as_invalid:
        'Označte svoje aktuálne prihlasovacie údaje ako neplatné',
      allow_to_activate_the_same_acc:
        'Nechajte aktivovať ten istý účet, keď si prajete, aby ste si mohli zahrať možnosť „Zabudnuté heslo“',
      your_phone_number: 'Vaše telefónne číslo',
      delete_my_account: 'Odstrániť môj účet',
      inactive_my_account: 'Neaktívne môj účet',
      write_reason: 'Napíšte svoj dôvod prosím',
      please_des_problem: 'Podrobnejšie opíšte problém',
      what_is_your_reason_for_leaving: 'Aký je váš hlavný dôvod odchodu?',
      are_you_sure_want_to_delete_account:
        'Ste si istí, že chcete odstrániť svoj účet? Týmto sa váš účet natrvalo vymaže',
      delete_all_caps: 'Vymazať',
      select_reason: 'Vybrať dôvod',
      inactive_account: 'Neaktívny účet',
      are_you_sure_inactive_account:
        'Ste si istí, že chcete neaktívne svoj účet? Vezmite prosím na vedomie, že máte inaktivovaný účet s rovnakým e -mailovým/mobilným číslom, navštívte „Zabudnite heslo“ a aktivujte ten istý účet alebo použite rôzne podrobnosti na vytvorenie nového účtu',
      inactive: 'Neaktívny',
      done: 'hotový',
      are_you_sure_logout: 'Určite sa chcete odhlásiť?',
      no: 'Nie',
      yes: 'Áno',
      english: 'Angličtina',
      spanish: 'Španielsky',
      account_details: 'detaily účtu',
      display_name: 'Zobraziť meno',
      user_Id: 'ID používateľa',
      username: 'Užívateľské meno',
      first_name: 'Krstné meno',
      last_name: 'Priezvisko',
      meeting_price: 'Cena',
      meeting_duration: 'Trvanie stretnutí',
      referral_code: 'Referenčný kód',
      sponsor: 'Sponzor',
      date_of_birth: 'Dátum narodenia',
      choose_image: 'Vyberte obrázok',
      gender: 'rod',
      male: 'Muž',
      female: 'Žena',
      other: 'Druhý',
      sponsor_id: 'ID sponzorov',
      are_you_sure_you_want: 'Si si istý, že chceš',
      as_your_sponsor: 'Ako váš sponzor',
      okay: 'V poriadku',
      invalid_referral_code: 'Neplatný sprostredkovací kód',
      please_enter_display_name: 'Zadajte názov zobrazenia',
      display_name_should_not:
        'Zobrazené meno by nemalo obsahovať žiadne čísla, malo by byť jedno slovo a malo by byť dlhé medzi 3 a 20 znakmi.',
      please_enter_first_name: 'Zadajte krstné meno',
      please_enter_valid_first_name:
        'Krstné meno by nemalo obsahovať žiadne čísla a malo by byť dlhé medzi 3 a 20 znakmi',
      please_enter_last_name: 'Zadajte priezvisko',
      please_enter_valid_last_name:
        'Priezvisko by nemalo obsahovať žiadne čísla a malo by byť dlhé medzi 3 a 20 znakmi.',
      please_enter_dob: 'Zadajte svoj dátum narodenia',
      please_enter_valid_dob: 'Zadajte platný dátum narodenia',
      please_select_gender: 'Vyberte Pohlavie',
      meeting_price_cannot: 'Spĺňajúca cena nemôže byť 0',
      take_photo: 'Odfoť',
      verify_account: 'Overiť účet',
      mobile_number: 'Telefónne číslo',
      email: 'E -mail',
      verify: 'Preveriť',
      two_factor_authentication: 'Dvojfaktorová autentifikácia',
      add_extra_layer:
        'Pridajte do svojho účtu ďalšiu vrstvu bezpečnosti tým, že umožní dvojfaktorové overenie.',
      please_enter_mobile_number: 'Zadajte mobilné číslo',
      please_enter_valid_phone: 'Prosím zadajte platné telefónne číslo',
      please_enter_email: 'Zadajte e -mail',
      please_enter_valid_email: 'Zadajte platný e -mail',
      enter_your_verification_code: 'Zadajte svoj overovací kód',
      we_sent_the_code: 'Poslali sme kód na',
      phone_small: 'telefonovať',
      email_small: 'e -mail',
      this_code_will_expire: 'Platnosť tohto kódu vyprší',
      new_password: 'Nové heslo',
      confirm_password: 'Potvrďte heslo',
      submit: 'Predložiť',
      resend_code: 'Opätovný kód',
      please_enter_received_code: 'Zadajte prijatý kód.',
      please_enter_password: 'Zadajte heslo',
      invalid_password: 'nesprávne heslo',
      please_enter_confirm_password: 'Zadajte potvrdenie hesla',
      passwords_do_not_match: 'Heslá sa nezhodujú',
      password_length_to_be: '- Dĺžka hesla je minimálne 8 a 15 maximálnych  n',
      at_least_one_special_char: '- Aspoň jeden špeciálny char  n',
      at_least_one_capital_letter: '- Aspoň jedno kapitálové písmeno  n',
      at_least_one_number: '- Aspoň jedno číslo  n',
      no_more_than_two_consecutive:
        '- Nie viac ako dva po sebe nasledujúce znaky',
      enable_now: 'Povoliť teraz',
      enable_twofactor_auth: 'Povoliť dvojfaktorové overenie',
      an_authenticator_app_lets_you:
        'Aplikácia Authenticator vám umožňuje vygenerovať bezpečnostné kódy v telefóne bez toho, aby ste museli prijímať textové správy. Ak ho už nemáte, podporujeme e. g. Tieto aplikácie.',
      to_configure_your: 'Konfigurácia aplikácie Authenticator:',
      add_a_new_timebased:
        'Pridajte nový časový token  nuse svoju aplikáciu na naskenovanie QR-kódu nižšie',
      enter_the_code_generated: 'Zadajte vygenerovaný kód',
      code: 'Kódovať',
      validate: 'Potvrdiť',
      save: 'Uložiť',
      change: 'Zmena',
      disable_now: 'Zakážte teraz',
      enable_successfully: 'umožniť úspešne',
      secret_key_copied: 'Skopírovaný',
      contributionscore: 'Príspevky',
      activity: 'Činnosť',
      gained_score: 'Získané_score',
      date_created: 'Dátum vytvorený',
      contributor_not_found: 'Prispievateľ sa nenašiel',
      start_time: 'Doba spustenia',
      end_time: 'Čas',
      this_option_routinely:
        'Táto možnosť bude bežne pozastaviť upozornenia na stanovené obdobia každý deň.',
      please_select_start_time: 'Vyberte čas začiatku!',
      please_select_end_time: 'Vyberte čas End End!',
      start_time_end_time_not_same:
        'Čas začiatku a konečný čas nemôže byť rovnaký!',
      please_select_valid_start_date: 'Vyberte platný dátum začiatku.',
      search: 'Vyhľadávanie',
      feed: 'Kŕmenie',
      reels: 'Kotúč',
      myposts: 'Myposts',
      choose_photo: 'Vyberte si fotografiu',
      confirm: 'Potvrdiť',
      select_date: 'Vyberte dátum',
      select_time: 'Vyberať čas',
      edit: 'Upraviť',
      delete: 'Vymazať',
      tap_options: 'Klepnite na možnosti',
      change_channel_image: 'Zmeňte obrázok kanála',
      view: 'vyhliadka',
      choose_file: 'Vyberte súbor',
      error_open_photo_lib: 'Nemohol som otvoriť knižnicu fotografií.',
      error_open_camera: 'Nemohol otvoriť fotoaparát.',
      allow_permission: 'Povoliť povolenie',
      cap_settings: 'NASTAVENIE',
      error_upload_image: 'Nepodarilo sa nahrať obrázok.',
      points: 'Body',
      total: 'Celkom',
      spent: 'Utratený',
      wallet_activity: 'Peňaženka',
      to: 'Do',
      no_activity_found: 'Nezžehnala sa žiadna aktivita',
      clear: 'jasný',
      categories: 'Kategórie',
      all_categories: 'Všetky kategórie',
      no_product_found: 'Nájdený žiadny produkt',
      store: 'Ukladať',
      all_offers: 'Všetky ponuky',
      cash_back: 'Vrátiť peniaze',
      get_deal: 'Získať dohodu',
      error_only_edit_your_products: 'Môžete upraviť iba svoje výrobky',
      delete_category: 'Vymazať kategóriu',
      warn_delete_category: 'Ste si istí, že chcete túto kategóriu odstrániť?',
      warn_no_vendor_account:
        'Zdá sa, že nemáte prístup k účtu dodávateľov. Chcete iniciovať proces SingUp?',
      add_product: 'Pridať produkt',
      product_name: 'Meno Produktu',
      product_price: 'Cena produktu',
      select_category: 'vybrať kategóriu',
      category_title: 'Názov kategórie',
      suspicious: 'Podozrivý',
      harassing: 'Obťažovanie',
      inappropriate: 'Nevhodný',
      spam: 'Nevyžiadaná pošta',
      edit_category: 'Kategória',
      add_category: 'Pridať kategóriu',
      all: 'Všetko',
      group: 'Zoskupenie',
      channel: 'Kanál',
      contacts: 'Kontakt',
      network: 'Sieť',
      missed: 'Zmeškaný',
      call_history: 'História hovoru',
      warn_no_record_found: 'Nebol nájdený žiadny záznam',
      following: 'Nasledujúci',
      followers: 'Sledovatelia',
      views: 'Názory',
      streamer_hours: 'Streamer',
      follow: 'Sledovať',
      mute: 'Nemý',
      search_message: 'Vyhľadávacia správa',
      block_contact: 'Bloková správa',
      report: 'správa',
      report_type: 'Typ hlásenia',
      calling: 'Volanie',
      cancelled: 'Zrušené ...',
      cap_cancelled: 'Zrušené ...',
      outgoing: 'Vychádzajúce',
      incoming: 'Prichádzajúci',
      new_chat: 'Nový rozhovor',
      new_group: 'Nový',
      new_contact: 'Nový kontakt',
      new_channel: 'Nový kanál',
      unblock_contact: 'Odblokovací kontakt',
      message: 'Správa',
      warn_write_your_reason: 'Napíšte svoj dôvod prosím',
      got_it: 'Mám to',
      user_reported: 'Nahlásený používateľ',
      warn_report_noted: 'Vaša správa je zaznamenaná. Preskúmame to',
      warn_select_reason: 'Vyberte dôvod',
      channel_reported: 'Hlásený kanál',
      group_reported: 'Uvádzaná skupina',
      sa_str_end_to_end_encrypted: 'Správy a hovory sú šifrované na konci',
      channel_is_frozen: 'Kanál je zamrznutý',
      sa_str_last_seen: 'Naposledy videný',
      sa_str_last_seen_yesterday: 'Naposledy včera',
      sa_str_group_yesterday: 'Včera',
      last_seen_a_week_ago: 'Naposledy vidieť pred 1 týždňom',
      last_seen_few_week_ago: 'Naposledy pred niekoľkými týždňami',
      warn_user_is_on_dnd:
        'Tento používateľ je momentálne zapnutý, neruší režim.',
      warn_user_is_on_snooze: 'Tento používateľ je momentálne v režime Snooze.',
      Insufficient_permissions: 'Nedostatočné povolenia!',
      warn_allow_camera_and_microphone_access:
        'Ak chcete zavolať, povoľte Buzzmi prístup k fotoaparátu a mikrofónu',
      failed: 'Neúspešný',
      online: 'Online',
      is_now_an_admin: 'je teraz správca.',
      msg_channel_created: 'Vytvorí sa kanál.',
      msg_group_created: 'Skupina je vytvorená.',
      and: 'a',
      joined_dot: 'pripojil sa.',
      chat_is_unavailable: 'Chat nie je k dispozícii',
      write_a_message: 'Napíšte správu',
      error_sending_message: 'Nemohol poslať správu.',
      msg_no_longer_admin: 'už nie je správcom.',
      error_opening_files: 'Nemohol otvoriť súbory.',
      error_receiver_blocked:
        'Prijímač je blokovaný. Odblokujte používateľa na odoslanie správy',
      slide_to_cancel: 'Skĺznuť na zrušenie',
      camera: 'fotoaparát',
      photo_library: 'Knižnica',
      warn_need_mic_permission:
        'Potrebujete povolenie na mikrofón na pokračovanie!',
      retry: 'Skúsiť znova',
      remove: 'Eliminárny',
      delete_message: 'Odstrániť správu?',
      error_delete_message: 'Nemohol vymazať správu.',
      copy: 'Kopírovať',
      copied: 'Skopírovaný',
      read_by: 'Prečítať',
      delivered_to: 'Doručené do',
      downloading: 'Sťahovanie ...',
      file_saved: 'Uložený súbor',
      error_download_file: 'Nemohol sťahovať súbor.',
      files: 'Súbory',
      error_cam_not_available: 'Fotoaparát nie je k dispozícii',
      photo: 'Fotografia',
      one_week_ago: 'Pred 1 týždňom',
      few_weeks_ago: 'pred niekoľkými týždňami',
      you: 'Vy',
      turn_off_notifications: 'Vypnúť upozornenia',
      turn_on_notifications: 'Zapnite upozornenia',
      error_turning_on_notifications: 'Nepodarilo sa zapnúť upozornenia.',
      error_turning_off_notifications: 'Nepodarilo sa vypnúť upozornenia.',
      warn_sure_delete: 'Ste si istý, že chcete odstrániť?',
      error_leave_channel: 'Nemohol opustiť kanál.',
      leave_channel: 'Kanál',
      members: 'Členovia',
      add_members: 'Pridať členov',
      invite_members: 'Pozvať členov',
      operators: 'Prevádzkovatelia',
      operator: 'Prevádzkovateľ',
      banned_members: 'Zakázané členovia',
      muted_members: 'Utlmené členovia',
      reported_members: 'Hlásení členovia',
      report_channel: 'Kanál',
      report_group: 'Skupina',
      freeze_channel: 'Zmrazený kanál',
      leave_group: 'Opustit skupinu',
      admins: 'Správcovia',
      need_camera_permission:
        'Potrebujete povolenie na fotoaparát na pokračovanie!',
      demote: 'Demotovať',
      are_you_sure_dismiss_memeber:
        'Ste si istí, že chcete tohto člena prepustiť ako a',
      no_name: 'Bez mena',
      dismiss_from: 'Zlúčiť',
      owner: 'Majiteľ',
      ban: 'Zakázať',
      are_you_sure_to_ban: 'Ste si istí, že chcete zakázať tohto člena?',
      unmute: 'Zaniknúť',
      are_you_sure_want_unmute: 'Ste si istí, že chcete tohto člena zrušiť?',
      are_you_sure_want_mute: 'Ste si istí, že chcete tohto člena stlmiť?',
      dismiss_from_operator: 'Prepustiť od operátora',
      promote_operator: 'Propagovať',
      report_member: 'Člen správy',
      are_you_sure_want_report: 'Ste si istý, že chcete nahlásiť?',
      remove_from: 'Odstrániť z',
      remove_from_admin: 'Odstráňte z admin',
      make_admin: 'Robiť admin',
      admin: 'Správca',
      next: 'ĎALŠIE',
      invite_user: 'Pozvať používateľa',
      are_not_memeber_of_onesay:
        "nie sú memeberom One'say. Chcete im poslať pozvanie?",
      please_select_users: 'Vyberte používateľov',
      create_channel: 'Create Channel',
      create_group: 'Create Group',
      create: 'Vytvárať',
      please_enter_channel_name: 'Zadajte názov kanála',
      please_enter_group_name: 'Zadajte názov skupiny',
      post: 'Post',
      reel: 'Navíjať sa',
      create_post: 'Vytvoriť príspevok',
      whats_on_your_mind: 'Na čo myslíš',
      upload: 'Nahrať',
      reels_updated_successfully: 'Kotúče sa úspešne aktualizovali',
      please_enter_content: 'Zadajte obsah',
      reels_added_successfully: 'Kotúče úspešne pridané',
      error_while_uploading_reels: 'Chyba pri nahrávaní cievok',
      content_should_be_at_least: 'Obsah by mal byť najmenej 3 znak',
      couldnt_reach_server:
        'Nemohol sa dostať k serveru. Skontrolujte svoje pripojenie na internet',
      to_call_allow_buzzmi_access:
        'Ak chcete zavolať, povoľte Buzzmi prístup k fotoaparátu a mikrofónu',
      to_answer_allow_buzzmi_access:
        'Na odpoveď, povoľte bzzmi prístup k fotoaparátu a mikrofónu',
      you_need_grant_storage_access:
        'Musíte udeliť povolenie na prístup k úložisku.',
      permissions_for_record_audio: 'Povolenia pre záznamový zvuk',
      give_permission_record_audio:
        'Dajte svojmu zariadeniu povolenie zaznamenať zvuk',
      You_need_grant_microphone:
        'Musíte udeliť povolenia na prístup k mikrofónu.',
      send: 'Odoslať',
      left_leave: 'vľavo.',
      send_invitation: 'Poslať pozvánku',
      mobile: 'Mobilné',
      invitation_sent_success: 'Pozvánka Poslať úspešne',
      add_a_contact: 'Pridať kontakt',
      to_a_channel: 'na kanál',
      add: 'Pridať',
      warn_invitation_link_sent: 'Odkaz na pozvánku Send.',
      invite_to_channel_via_link: 'Pozvite na kanál cez odkaz',
      invite_with_link_description:
        'Ktokoľvek s Buzzmi môže nasledovať tento odkaz, aby sa pripojil k tomuto kanálu. Zdieľajte ho iba s ľuďmi, ktorým dôverujete',
      send_link_via_buzzmi: 'Odoslať odkaz cez Buzzmi',
      link_copied_with_success: 'Odkaz na pozvanie skopíroval úspešne',
      copy_link: 'Skopírovať odkaz',
      banned: 'Zakázaný',
      msg_sure_to_un_ban: 'Ste si istí, že chcete tohto člena rozbaliť?',
      msg_sure_to_un_mute: 'Ste si istí, že chcete tohto člena zrušiť?',
      warn_no_banned_users: 'Nájdený žiadny zakázaný používateľ',
      warn_no_mute_users: 'Nenašiel sa žiadny používateľ MUTE',
      warn_no_reported_users: 'Nenašiel sa žiadny hlásený člen',
      msg_report_group:
        'Posledných 5 správ z tejto skupiny bude postúpené do Buzzmi. Ak opustíte túto skupinu a odstránite rozhovor, správa sa odstráni iba zo zariadenia a vašich zariadení v novšej verzii Buzzmi.',
      msg_report_channel:
        'Posledných 5 správ z tohto kanála bude preposlané do Buzzmi. Ak opustíte tento kanál a odstránite rozhovor, správa sa odstráni iba zo zariadenia a vašich zariadení v novšej verzii Buzzmi.',
      report_this: 'Toto nahlásiť',
      no_one_in_the: 'Nikto v',
      will_be_notified: 'bude informovaný',
      exit_something: 'VÝCHOD',
      and_delete_chat: 'a vymazať chat',
      change_channel_name: 'Zmena názvu kanála',
      change_group_name: 'Zmena názvu skupiny',
      enter_name: 'Zadaj meno',
      change_group_image: 'Zmeňte obrázok skupiny',
      channel_information: 'Informácie o kanáli',
      no_travel_packages_available:
        'Nie sú k dispozícii žiadne cestovné balíčky',
      no_slots_available_for_date:
        'Pre tento dátum nie sú k dispozícii žiadne automaty!',
      unable_to_get_user_availability:
        'Nie je možné získať dostupnosť používateľov',
      please_enter_details: 'Zadajte podrobnosti',
      reel_downvoted_successfully: 'Navijak úspešne',
      reel_upvoted_successfully: 'Navijak úspešne zvýšil',
      delete_reel: 'Vymazať navijak',
      delete_post: 'Vymazať príspevok',
      are_you_sure_delete_this_reel:
        'Ste si istí, že chcete odstrániť tento cievku?',
      are_you_sure_delete_this_post:
        'Ste si istí, že chcete tento príspevok vymazať?',
      buzzmi_need_permission_to_access_your_device_storage:
        'Buzzmi potrebuje povolenie na prístup k úložisku vášho zariadenia. Prejdite na nastavenia, aby ste umožnili prístup',
      trending: 'Trendy',
      tweets: 'tweety',
      post_update_success: 'Príspevok úspešne aktualizoval',
      edit_post: 'Úprav',
      post_added_success: 'Príspevok bol úspešne pridaný',
      report_description: 'Nahlasit popis',
      report_by: 'Uviesť',
      report_details: 'Nahlásiť podrobnosti',
      limit_not_reached: 'Limit nedosiahnutý',
      voice_note_min_limit_2_sec:
        'Hlasová poznámka musí byť najmenej 2 sekundy.',
      receiver_is_blocked:
        'Prijímač je blokovaný.  n odblokoval používateľa, aby odoslal správu',
      the_channels_image_was_updated: 'Obrázok kanála bol aktualizovaný.',
      the_groups_image_was_updated: 'Obrázok skupiny bol aktualizovaný.',
      edited: '(upravené)',
      promote: 'propagovať',
      added_success: 'Pridané úspešne.',
      at: 'pri',
      rights: 'Práva',
      change_channel_info: 'Zmeňte informácie o kanáli',
      add_remove_members: 'Pridať/Odstrániť členov',
      invite_users_via_link: 'Pozvite používateľov prostredníctvom odkazu',
      add_or_remove: 'Pridať/odstrániť',
      ban_members: 'Členovia',
      mute_members: 'Člen',
      report_members: 'Hlásiť členov',
      delete_account: 'Zmazať účet',
      deleting_your_account_will: 'Odstránenie vášho účtu bude',
      delete_your_account_profile:
        'Odstráňte informácie o vašom účte a profilovú fotografiu',
      delete_from_buzzmi_groups: 'Odstráňte vás zo všetkých skupín Buzzmi',
      delete_messages_and_backup:
        'Odstráňte svoju históriu správ v tomto telefóne a zálohovaní',
      temp_inactive_your_account: 'Dočasný neaktívny váš účet bude',
      your_buzzmi_acc_temp_inactive: 'Dočasne inaktivoval váš účet Buzzmi',
      not_display_buzzmi_profile:
        'Nezobrazujte svoj profil/kontaktné údaje v Buzzmi',
      make_login_invalid:
        'Označte svoje aktuálne prihlasovacie údaje ako neplatné',
      allow_acc_active_with_forgot_pass:
        'Nechajte aktivovať ten istý účet, keď si budete priať prostredníctvom možnosti „Zabudnuté heslo“',
      del_my_acc: 'Odstrániť môj účet',
      inactive_my_acc: 'Neaktívne môj účet',
      msg_inactive_acc_confirm:
        'Ste si istí, že chcete neaktívne svoj účet? Upozorňujeme, že máte inaktivovaný účet s rovnakým e -mailovým/mobilným číslom v Buzzmi, navštívte „Zabudnite heslo“ a aktivujte ten istý účet alebo pomocou rôznych podrobností vytvorte nový účet',
      currency: 'Mena',
      awarded_on: 'Udelený',
      award_type: 'Udelený typ',
      share_link: 'Zdieľať odkaz',
      qr_code: 'QR kód',
      share_file: 'Zdieľať súbor',
      is_muted: 'je tlmený',
      voice: 'hlas',
      call: 'zavolať',
      completed: 'Dokončený',
      missed_call: 'Zmeškaný hovor',
      phone_number: 'Telefónne číslo',
      boaring_vocabulary_title: 'Boring nie je v našej slovnej zásobe!',
      boaring_vocabulary_description:
        'Vitajte v konečnej platforme pre socializáciu, kondíciu a zábavu!',
      become_a_part_of_community_description:
        'Staňte sa súčasťou komunity rovnako zmýšľajúcich jednotlivcov a prístup k kvalitnému obsahu v oblasti zdravia a wellness, trendových udalostí a vzrušujúcich výziev-všetko v telefóne. Poďme bzučať!',
      make_money_with_buzzmi_title: 'Zarobte si peniaze s Buzzmi',
      make_money_with_buzzmi_description: 'Kde sa stane skutočný ruch!',
      ready_for_a_challenge_title: 'Ste pripravení na výzvu?',
      ready_for_a_challenge_description:
        'Vezmite si svojich priateľov a vyhrajte na Buzzmi Big',
      fair_pay_for_fair_work_title: 'Spravodlivá platba za spravodlivú prácu.',
      fair_pay_for_fair_work_description:
        'Tvorcovia Buzzmi zarábajú 90% každého predplatného bez poplatkov. Udržiavame iba 10%, aby sme veci bzučali.',
      continue_with_facebook: 'Pokračujte s Facebookom',
      continue_with_apple: 'Pokračujte s Apple',
      continue_with_google: 'Pokračujte s Google',
      your_phone_or_email: 'Váš telefón alebo e -mail',
      your_password: 'Vytvorte si heslo',

      forgot_password: 'Zabudol si heslo',
      log_in: 'PRIHLÁSIŤ SA',
      login: 'Prihlásiť sa',
      or: 'alebo',
      dont_have_an_account: 'Nemáte účet?',
      sign_up: 'Prihlásiť Se',
      lets_get_your_setup: 'Poďme na to, aby ste sa nastavili',
      already_have_an_account: 'Máte už účet?',
      signup_with_phone_or_email: 'Registrácia s telefónom alebo e -mailom',
      forgot_password_description:
        'Nerobte si starosti! To sa stáva. Zadajte adresu spojenú s vaším účtom.',
      forgot_your_password: 'Zabudol si heslo?',
      continue: 'ĎALEJ',
      provide_your_contact_information: 'Poskytnite svoj kontakt  n Informácie',
      enter_your_email: 'Zadajte svoj e-mail',
      or_sign_up_with_phone: 'alebo sa zaregistrujte s telefónom',
      or_sign_up_with_email: 'alebo sa zaregistrujte s e -mailom',
      contact_email_description:
        'Účty sú povinné poskytnúť telefónne číslo alebo e -mail na účely bezpečnosti a ochrany osobných údajov. Môžete mať iba jeden účet prepojený s telefónnym číslom',
      verify_your_email: 'Overte svoj e -mail',
      verify_your_phone_number: 'Overte svoje telefónne číslo',
      verification_code_sent_description:
        'Poslali sme kód {digit} -digit do {email} Potvrdzovanie, že patrí vám, aby ste udržali váš účet v bezpečí.',
      time_to_buzz_into_action: 'Čas na to, aby ste sa dostali do akcie!',
      bio_description:
        'Potrebujeme len niekoľko ďalších podrobností, aby sme si prispôsobili váš zážitok z Buzzmi.',
      user_name: 'Meno používateľa',
      when_your_birhtday: 'Kedy máš narodeniny?',
      birthday_not_shown_publicaly: 'Vaše narodeniny sa nezobrazia verejne',
      invitation_code: 'Pozvánka',
      password_priority_title:
        'Vaša bezpečnosť je našou najvyššou prioritou. Uistite sa, že to vaše heslo odráža!',
      create_your_password: 'Vytvorte si heslo',
      password_requirement: 'Požiadavky na heslo:',
      make_it_long: 'Urob to dlho',
      from_8_to_20_characters: 'od 8 do 20 znakov',
      make_it_diverse: 'Urobte to rôznorodý',
      password_should_contain_one_special_character:
        'Mal by obsahovať aspoň jedno písmeno s vyšším písmenom a aspoň jeden špeciálny znak',
      field_is_required: 'pole je povinné',
      invalid_phone_or_email: 'Neplatný telefón alebo e -mail',
      invalid_phone: 'Neplatný telefón',
      password_must_contain_at_least_1_lower_case_letter:
        'Heslo musí obsahovať najmenej 1 list s nižším prípadom',
      password_must_contain_at_least_1_upper_case_letter:
        'Heslo musí obsahovať aspoň 1 písmeno s vyšším písmenom',
      password_must_contain_at_least_1_number:
        'Heslo musí obsahovať najmenej 1 číslo',
      password_must_contain_at_least_1_special_character:
        'Heslo musí obsahovať najmenej 1 špeciálny znak',
      minimum_required_length_8_characters:
        'Minimálne požadovaná dĺžka 8 znakov',
      country_code_is_required: 'Vyžaduje sa kód krajiny',
      please_enter_valid_phone_number: 'Prosím zadajte platné telefónne číslo',
      resend_code_text_timer:
        'Po ($ {time}) sekundy budete môcť prepnúť kód ($ {time})',
      wrong_verification_number: 'Nesprávne overovacie číslo',
      your_email_has_been_verified: 'Váš e -mail bol overený',
      your_phone_has_been_verified: 'Váš telefón bol overený',
      re_enter_the_code: 'Znovu zadajte kód',
      success: 'Úspech!',
      must_include_at_least_1_lower_case_letter:
        'Musí obsahovať najmenej 1 list s nižším prípadom',
      must_include_at_least_1_upper_case_letter:
        'Musí obsahovať najmenej 1 list s veľkými prípadmi',
      must_include_at_least_1_number: 'Musí obsahovať najmenej 1 číslo',
      email_is_required: 'Je potrebný e -mail',
      invalid_email: 'Neplatný email',
      invalide_mobile: 'Invalidový mobilný',
      invalid_dob: 'Neplatný dátum narodenia',
      age_18_puls_description:
        'Ospravedlňujeme sa, vyzerá to, že nemáte nárok na Buzzmi ... ale vďaka za to, že ste nás skontrolovali!',
      contain_at_least_one_upper_case_letter:
        'obsahovať aspoň jeden list s veľkými písmenami',
      contain_at_least_one_special_character:
        'obsahovať aspoň jeden špeciálny znak',
      confirm_password_must_be_the_same_as_password:
        'Potvrďte heslo musí byť rovnaké ako heslo',
      password_must_match: 'heslá sa musia zhodovať',
      choose_your_role: 'Vyberte si svoju rolu',
      we_are_curios: 'Sme zvedaví:',
      are_you: 'Si....',
      the_spot_light_on_you: 'Zameranie sa na vás!',
      top_3_creator_select:
        'Kto je vašich 5 najlepších tvorcov? Dajte nám vedieť!',
      or_invite_friend_individually: 'Alebo pozvite priateľov indukany',
      we_are_all_ears: 'Všetci sme uši!',
      tell_us_what_your_interest: 'Povedzte nám, čo vyvoláva váš záujem.',
      verification_code: 'overovací kód',
      enter_otp: 'Zadajte OTP',
      foreget_password_masked_text:
        'Chceme sa ubezpečiť, že ste to skutočne vy. Ak chcete ďalej overiť svoju identitu, zadajte overovací kód, ktorý bol odoslaný na $ {email}',
      reset_your_password: 'Obnoviť heslo',
      one_subscription_endless_possblities:
        'Jedno predplatné, nekonečné možnosti',
      unlimited_access_to_100s_of_creators_and_all_premium_features:
        'Neobmedzený prístup k 100S+ tvorcov a všetkých prémiových funkcií',
      get_more_of_the_app_and: 'Získajte viac aplikácie a',
      save_40: 'Ušetrite 40%',
      your_first_7_days_free_no_commitment_cancel_anytime:
        'Váš prvých 7 dní zadarmo. Žiadny záväzok. Zrušiť kedykoľvek',
      elte_creator_description:
        'Môžete začať rozširovať svoju firmu pomocou našej pokročilej technológie, užívateľsky prívetivej platformy a špecializovaného tímu podpory zákazníkov.',
      close: 'Zavrieť',
      learn_more: 'Uč sa viac',
      premium_description:
        'Získajte okamžitý prístup k najväčšej ponuke všetkých príručiek 1 000+ trénerov, športovcov a trénerov.',
      start_my_7_days_trial: 'Začnite moju 7-dňovú skúšku',
      skip_trial: 'Skúška preskočenia',
      subscribe: 'Predplatiť',
      become_premium_member: 'Stať sa prémiovým členom',
      pay_with_credit_card: 'Platiť kreditnou kartou',
      cardholder_Name: 'Meno držiteľa karty',
      card_Number: 'Číslo karty',
      expiration: 'Expirácia',
      subtotal: 'Sklad',
      discount: 'Zľavu',
      tax: 'Zdanenie',
      checkout: 'ODHLÁSIŤ SA',
      you_now_a_part_of_the_buzz_worthy_world_of_Buzzmi:
        'Teraz ste súčasťou sveta Buzzmi Buzz.',
      lets_go_to_your_profile: 'Poďme do svojho profilu',
      go_to_start: 'Ísť začať',
      choose_your_payment_method: 'Vyberte si spôsob platby',
      choose_your_another_payment_method: 'Vyberte inú platobnú metódu',
      to_back: 'Dozadu',
      your_rank: 'Vaša hodnosť',
      view_rank_ladder: 'Zobraziť rebrík',
      next_rank: 'Najbližšia hodnota',
      rise_and_shine: 'Vstávať',
      my_plan_for_today: 'Môj plán na dnešok',
      see_all_tasks: 'Zobraziť všetky aktivity',
      upgrade_now: 'Vylepšiť teraz',
      see_my_earnings: 'Pozri moje zárobky',
      wallet: 'Peňaženka',
      wallet_balance: 'Peňaženka Equilíbrio',
      quick_access: 'Rýchly prístup',
      your_current_rank: 'Vaša súčasná hodnosť',
      welcome_description:
        'Sme tu, aby sme vám pomohli pri výkone vašich úloh a čo najlepšie využili váš deň.',
      your_current_level: 'Vaša súčasná úroveň',
      add_activity: 'Agregar actividad',
      tasks: 'Aktivity',
      stats: 'Štatistika',
      leaderboard: 'Rebríčka',
      any_time_of_the_day: 'Kedykoľvek dňa',
      membership_description:
        'Choďte po svojej Buzzmi Cesta do nových výšok! Inovujte na prémiu a získať prístup k všetkým najlepším obsahom, nástrojom a výhodami, ktoré aplikácia ponúka.',
      free_user: 'Užívateľ',
      premium_member: 'Prémiový člen',
      elite_creator: 'Elitný tvorca',
      add_task_name: 'Pridať názov úlohy',
      add_task: 'Pridať Aktivitu',
      choose_cover_image: 'Vyberte obrázok krytu',
      add_description: 'Pridať popis',
      add_4_description_image: 'Pridať 4 popisové obrázky',
      add_description_video: 'Pridajte popis videa',
      add_description_audio: 'Pridajte popis zvuku',
      display_in_task_center: 'Zobraziť v Centre Aktivít',
      short_description_task: 'Pridať krátky popis aktivity',
      upload_your_image: 'Nahrajte svoj obrázok',
      toggle_description:
        'Keď je prepínač zapnutý, zobrazujte aktivity ako predvolené nastavenie; keď je vypnutý, zobrazte individuálne aktivity v centre aktivít.',
      add_my_own_task: 'Pridať vlastnú aktivitu',
      when: 'Kedy?',
      repeat: 'Opakovať',
      repeat_on_every: 'Opakujte každé',
      choose_the_day_for_this_activity: 'Vyberte si dni pre túto aktivitu',
      end_date: 'Dátum ukončenia',
      set_a_goal: 'Dať gól',
      enter_a_number: 'Zadajte číslo',
      reminder: 'Pripomienka',
      checklist: 'Kontrolný zoznam',
      note: 'Poznámka',
      add_your_note_here: 'Pridajte svoju poznámku tu',
      add_photos_of_your_progress: 'Pridajte fotografie svojho pokroku',
      tags: 'Značky',
      add_your_item: 'Pridajte svoju položku',
      my_task: 'Moja Aktivita',
      buzzmi_task: 'BuzzMi Aktivita',
      profile: 'Profil',
      messages: 'Správy',
      space: 'Priestor',
      subscription: 'Predplatné',
      challenges: 'Výziev',
      calendar: 'Kalendár',
      subscription_alert_message:
        'Vaše predplatné sa automaticky obnoví za 23 dní. Môžete ho zrušiť kedykoľvek',
      save_and_continue: 'Ušetrite a pokračujte',
      how_to_get_next_level: 'Ako získať ďalšiu úroveň?s',
      see_my_beniftis: 'Pozri moje výhody',
      morning: 'Ráno',
      subscribe_now: 'Odoberaj teraz',
      bronze: 'Bronz',
      silver: 'Striebro',
      gold: 'Zlato',
      choose_subscription_level: 'Vyberte úroveň predplatného',
      add_event: 'Pridať udalosť',
      scheduled: 'Naplánovaný',
      event_types: 'Typy udalostí',
      Shop: 'Obchod',
    },
  };
}
