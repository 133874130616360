import { useAtom } from 'jotai';
import { userQRdata } from '../hubState';
import { getQRData } from '../../../network/api/app';

export const useQrData = () => {
    const [qrInfo, setQrInfo] = useAtom(userQRdata);

    const getQrInfoDetail = async (id, token) => {
        try {
            const response = await getQRData(id)({ token });
            setQrInfo(response);
        } catch (e) {
            console.log('error => ', e.message);
        }
    };

    return {
        qrInfo,
        getQrInfoDetail,
    };
};
