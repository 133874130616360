import React, {useEffect, useState, Fragment} from 'react';
import styles from './plan-info.module.scss';
import PrimaryButton from '../../../../../../btns/buttons/PrimaryButton.jsx';
import {Link} from '@react-navigation/native';
import Strings from '../../../../../../../../string_key/Strings.web';
import {useAtom} from 'jotai';
import {
  _userDetailsAtom,
  userDataAtom,
} from '../../../../../../../../appState/userState';
import PlaceholderLoading from 'react-placeholder-loading';
import {Dialog, Transition} from '@headlessui/react';
import {XMarkIcon} from '@heroicons/react/24/outline';
import NewMembershipScreenLayout from '../../../../../../../../container/App/NewMembershipScreenLayout/index.web';
import {useHubApp} from '../../../../../../../../appState/hooks/useHubApp/index.web.js';

const PlanInfo = ({pressedOpen, myBenifits, setMyBenifits}) => {
  const [planInfo, setPlanInfo] = useState(null);
  const [userData, setLoginSuccess] = useAtom(userDataAtom);
  const [open, setOpen] = useState(false);

  const getData = () => {
    setPlanInfo(userData?.membership);
  };

  useEffect(() => {
    getData();
  }, [userData]);

  useEffect(() => {
    if (pressedOpen) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [pressedOpen]);
  return (
    <>
      <NewMembershipScreenLayout
        is_open={open}
        set_is_open={setOpen}
        myBenifits={myBenifits}
        setMyBenifits={setMyBenifits}
      />
      <div className="d-flex d-lg-block justify-content-between">
        {planInfo && Object.keys(planInfo).length !== 0 ? (
          <>
            <div
              className={'d-flex align-items-center mb-lg-2'}
              onClick={() => {
                setOpen(true);
              }}>
              <div className={'me-2'}>
                <img
                  className={styles['plan-info__icon']}
                  src={planInfo?.membershipBadge || '/images/moc/free-user.png'}
                  alt={planInfo?.title}
                />
              </div>
              <span className={'h5'}>{planInfo?.title}</span>
            </div>

            {planInfo.title !== 'Elite Membership' && (
              <button
                type="button"
                className="relative inline-flex items-center px-3 py-1 rounded-full text-xs sm:font-semibold  text-white shadow-md bg-pink-500 sm:focus-visible:outline sm:focus-visible:outline-2 sm:focus-visible:outline-offset-2 sm:focus-visible:outline-pink-600"
                onClick={() => {
                  setOpen(true);
                }}>
                {Strings.upgrade_now.toUpperCase()}
              </button>
            )}

            {/* <PrimaryButton
              onClick={() => { setOpen(true) }}
              className={`${styles['plan-info__btn']} button_s mb-2`}>
              {Strings.upgrade_now.toUpperCase()}
            </PrimaryButton> */}
          </>
        ) : (
          <div className={'d-flex align-items-center'}>
            <PlaceholderLoading shape="circle" width={40} height={40} />
            <div style={{width: 10}}></div>
            <PlaceholderLoading shape="rect" width={'80%'} height={10} />
          </div>
        )}
      </div>
    </>
  );
};
export const NewPlanInfo = ({
  pressedOpen,
  myBenifits,
  setMyBenifits,
  setOpenMyBenifits,
}) => {
  const {rankInfo} = useHubApp();
  const [planInfo, setPlanInfo] = useState(null);
  const [userData, setLoginSuccess] = useAtom(userDataAtom);
  const [open, setOpen] = useState(false);

  const getData = () => {
    setPlanInfo(userData?.membership);
  };

  useEffect(() => {
    getData();
  }, [userData]);

  useEffect(() => {
    if (pressedOpen) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [pressedOpen]);

  const _renderTitleLayout = () => (
    <span className={`h6 ml-1 ${planInfo?.title.length > 9 ? 'w-[30px]' : ''}`}>
      {planInfo?.title}
    </span>
  );

  return (
    <>
      <NewMembershipScreenLayout
        is_open={open}
        set_is_open={setOpen}
        myBenifits={myBenifits}
        setMyBenifits={setMyBenifits}
      />

      {planInfo && Object.keys(planInfo).length !== 0 ? (
        <>
          <div className="d-flex d-lg-block justify-content-between">
            <div
              className={'d-flex align-items-center mb-2'}
              onClick={() => {
                setOpen(true);
              }}>
              <div className={''}>
                <img
                  // className={styles['plan-info__icon']}
                  className={'h-5 w-5'}
                  src={planInfo?.membershipBadge || '/images/moc/free-user.png'}
                  alt={planInfo?.title}
                />
              </div>
              {_renderTitleLayout()}
            </div>
          </div>
          {planInfo.title !== 'Elite Membership' && (
            <button
              type="button"
              className="relative inline-flex items-center  px-2 py-1 rounded-full text-[10px] sm:font-semibold  text-white shadow-md bg-pink-500 sm:focus-visible:outline sm:focus-visible:outline-2 sm:focus-visible:outline-offset-2 sm:focus-visible:outline-pink-600"
              onClick={() => {
                setOpen(true);
              }}>
              {Strings.upgrade_now.toUpperCase()}
            </button>
          )}
          <div
            className="mt-2"
            onClick={() => {
              setMyBenifits(true);
              setOpenMyBenifits(!pressedOpen);
            }}>
            <div className="text-alt-primary text-xs text-center">
              See my benifits
            </div>
            {/* <div className={'h4'}>
              <p
                className={
                  'bg-gradient-to-r mt-2 from-[#f1e9fc] to-[#bbb6b6] bg-clip-text text-transparent'
                }>
                {rankInfo?.nextRank}
              </p>
            </div> */}
          </div>
          {/* <PrimaryButton
              onClick={() => { setOpen(true) }}
              className={`${styles['plan-info__btn']} button_s mb-2`}>
              {Strings.upgrade_now.toUpperCase()}
            </PrimaryButton> */}
        </>
      ) : (
        <div className={'flex align-items-center justify-between'}>
          <PlaceholderLoading shape="circle" width={20} height={20} />
          {/* <div style={{width: 5}}></div> */}
          <PlaceholderLoading shape="rect" width={'80%'} height={10} />
        </div>
      )}
    </>
  );
};

export default PlanInfo;
