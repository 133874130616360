import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import HomeDashboard from '../../container/App/HomeDashboard';
import CreatePost from '../../container/App/HomeDashboard/CreatePost';
import PostComment from '../../container/App/HomeDashboard/PostComment';
import PostFileViewer from '../../container/App/HomeDashboard/PostFileViewer';
import EditPost from '../../container/App/HomeDashboard/EditPost';
import { options } from './utils';

const Stack = createNativeStackNavigator();

const DiscoverStackNavigator = () => {
    return (
        <Stack.Navigator initialRouteName={'HomeDashboard'} mode="card">
            <Stack.Screen
                name={'HomeDashboard'}
                options={options}
                component={HomeDashboard}
            />
            <Stack.Screen
                name={'CreatePost'}
                options={options}
                component={CreatePost}
            />
            <Stack.Screen
                name={'PostComment'}
                options={options}
                component={PostComment}
            />
            <Stack.Screen
                name={'PostViewer'}
                options={options}
                component={PostFileViewer}
            />
            <Stack.Screen
                name={'EditPost'}
                options={options}
                component={EditPost} />
        </Stack.Navigator>
    );
};

export default DiscoverStackNavigator;
