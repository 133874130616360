import React, {useEffect} from 'react';
import {getData} from '../../../res/asyncStorageHelper';
import {useBuzzmiAuth, useHubApp} from '../../../appState/hooks';
import HubMainDesktop from './hubMainDesktop/HubMainDesktop.jsx';
import HubMainMobile from './hubMainMobile/HubMainMobile.jsx';
import MainLayout from '../../../components/layouts/mainLayout/MainLayout.jsx';
import MainLayoutContent from '../../../components/layouts/mainLayout/mainLayoutContent/MainLayoutContent.jsx';
import ResizeComponent from '../../../components/reusable/assets/resizeComponent/ResizeComponent.jsx';
import AdditionalInformation from '../../../components/reusable/assets/additionalInformation/AdditionalInformation.jsx';
import {useEventEmitter} from '../../../hooks/useEventEmitter';

const Home = props => {
  const {userData} = useBuzzmiAuth();
  const {
    getWalletData,
    getRankInfoDetail,
    getNextRankInfoDetail,
    getCoverImageList,
    getPlannedActivities,
    plannedActivities,
    // getMyTaskList,
    getTagsList,
    getSharedMediaList,
    getPlannedActivitiesStat,
  } = useHubApp();

  useEffect(() => {
    userData && getTagsList(userData?.token),
      // getMyTaskList(userData?.token),
      // getPlannedActivities(userData.token),
      getPlannedActivities(userData?.token, () => {}, {
        page: '1',
        limit: '10',
      });
    // getPlannedActivitiesStat(userData?.token, () => {}, {
    //   startDate: getCurrentWeekDates().startDate,
    //   endDate: getCurrentWeekDates().endDate,
    // });

    getCoverImageList(userData?.token);
    getSharedMediaList(userData?.token);
    getWalletData(userData?.token);
    getRankInfoDetail(userData?.id, userData?.token);
    getNextRankInfoDetail(userData?.id, userData?.token);
  }, [userData]);

  useEventEmitter('refresh-planActivities', data => {
    // getPlannedActivities(userData.token);
    const par = {
      page: data?.page ? data?.page : '1',
      limit: data?.limit ? data?.limit : '10',
    };
    if (data?.title) {
      Object.assign(par, {title: data?.title});
    }
    if (data?.tag) {
      Object.assign(par, {tag: data?.tag});
    }
    if (data?.startDate) {
      Object.assign(par, {startDate: data?.startDate});
    }
    if (data?.endDate) {
      Object.assign(par, {endDate: data?.endDate});
    }

    console.log('data in planned refresh', data);
    getPlannedActivities(
      userData?.token,
      data?.setLoading ? data.setLoading : () => {},
      par,
    );
  });

  useEffect(() => {
    if (typeof userData !== 'undefined' && userData !== null) {
      if (userData?.membership === null) {
        getData('isMembershipSkipped', isMembershipSkipped => {
          if (
            typeof isMembershipSkipped !== 'boolean' ||
            !isMembershipSkipped
          ) {
            props.navigation.navigate('Membership');
          }
        });
      }
    }
  }, [userData]);

  return (
    <>
      <MainLayout>
        <MainLayoutContent rightColumnContent={<AdditionalInformation />}>
          <ResizeComponent
            desktopComponent={<HubMainDesktop />}
            mobileComponent={<HubMainMobile />}
          />
        </MainLayoutContent>
      </MainLayout>
    </>
  );
};
export default Home;
