import React, {useEffect, useState} from 'react';
import Rank from '../../../Items/rank/Rank.jsx';
import LinkTabs from '../../../../../../components/reusable/assets/linkTabs/LinkTabs.jsx';
import FilterList from '../../../Items/filterList/FilterList.jsx';
import Calendar from '../../../../../../components/reusable/calendar/Calendar.jsx';
import UpdateInfo from '../../../../../../components/reusable/updateInfo/UpdateInfo.jsx';
import Strings from '../../../../../../string_key/Strings.web.js';
import {useBuzzmiAuth} from '../../../../../../appState/hooks/useBuzzmiAuth/index.web.js';
import Leaderboard from '../Leaderboard';
import PlanSearch from '../../../../../../components/reusable/assets/additionalInformation/items/additionalInformationPlan/items/planSearch/PlanSearch.jsx';
import moment from 'moment';
import PeriodList from '../../../../../../components/reusable/periodList/PeriodList.jsx';
import {EventRegister} from 'react-native-event-listeners';
import Loading from 'react-fullscreen-loading';
import {colors} from '../../../../../../res/colors.js';
import {useStatListing} from '../../../../../../appState/hooks/useStatListing';
import {useAtom} from 'jotai';
import {userPlannedActivitiesGetTime} from '../../../../../../appState/hooks/hubState.js';
import MainLayoutWithAspectSide from '../../../../../../components/layouts/mainLayout/MainLayoutWithAspectSide.jsx';

const periodList = [
  {
    id: 0,
    text: 'Last week',
    _text: 'last_week',
  },
  {
    id: 1,
    text: 'This week',
    _text: 'this_week',
  },
  {
    id: 2,
    text: 'Last Month',
    _text: 'last_month',
  },
  {
    id: 3,
    text: 'This month',
    _text: 'this_month',
  },
  {
    id: 4,
    text: 'Last Year',
    _text: 'last_year',
  },
  {
    id: 5,
    text: 'This Year',
    _text: 'this_Year',
  },
];
const HubTasksDesktop = props => {
  const [activeTab, setActiveTab] = useState(props?.selectedTab);
  const [calendarValue, setCalendarValue] = useState(
    moment().format('YYYY-MM-DD'),
  );
  const [periodType, setPeriodType] = useState(0);
  const [searchActivity, setSearchActivity] = useState('');
  const [searchTimedActivity, setSearchTimedActivity] = useState('');
  const [tags, setTags] = useState([]);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingPlanActivityPage, setLoadingPlanActivityPage] = useState(false);
  const [planActPage, setPlanActPage] = useState(1);
  const [planActPageLimit, setPlanActPageLimit] = useState(5);

  const {language, userData} = useBuzzmiAuth();
  const {statListing, getMyStatListing, plannedActivitiesStatsGetTime} =
    useStatListing();
  const [plannedActivitiesGetTime, setPlannedActivitiesGetTime] = useAtom(
    userPlannedActivitiesGetTime,
  );

  const tabsLast = [
    {
      href: '/hub/tasks',
      title: Strings.tasks,
      id: 0,
    },
    {
      href: '/hub/stats',
      title: Strings.stats,
      id: 1,
    },
    {
      href: '/hub/leaderboard',
      title: Strings.leaderboard,
      id: 2,
    },
  ];

  const activityMap = {};

  statListing.forEach(item => {
    if (!activityMap[item?.when]) {
      activityMap[item?.when] = [];
    }
    activityMap[item?.when].push(item);
  });

  const dailyTasks = Object.keys(activityMap).map(when => ({
    title: when.charAt(0).toUpperCase() + when.slice(1),
    list: activityMap[when],
  }));

  useEffect(() => {
    const selectedTabName =
      activeTab == 2
        ? Strings.leaderboard
        : activeTab == 1
        ? Strings.stats
        : Strings.tasks;
    window.history.replaceState(
      null,
      'HubActivities',
      `/hub/v2/${selectedTabName.toLowerCase()}`,
    );

    console.log('activeTab');
    console.log(activeTab);
  }, [activeTab]);

  const onRefreshCalled = data => {
    EventRegister.emit('refresh-planActivities', data);
  };

  const RefreshPlanActivities = setLoader => {
    console.log('searchActivity', searchActivity);
    if (searchActivity.length > 0 || tags?.length > 0) {
      onRefreshCalled({
        page: planActPage,
        limit: planActPageLimit,
        title: searchActivity,
        tag: tags,
        setLoading: setLoader,
        startDate: moment(calendarValue).toISOString(),
        endDate: moment(calendarValue).toISOString(),
      });
    } else {
      onRefreshCalled({
        page: planActPage,
        limit: planActPageLimit,
        setLoading: setLoader,
      });
    }
  };
  useEffect(() => {
    console.log('tags out==>', tags, moment(calendarValue).toISOString());
    RefreshPlanActivities(setLoading);
  }, [searchActivity, tags, calendarValue]);
  useEffect(() => {
    console.log(' planActPage==>', planActPage);
    if (planActPageLimit > 10) {
      RefreshPlanActivities(setLoadingPlanActivityPage);
    }
  }, [planActPageLimit]);

  useEffect(() => {
    if (props?.plannedActivities?.length < planActPageLimit) {
      setShowLoadMore(false);
    }
  }, [props?.plannedActivities]);

  const getStatListing = () => {
    const _time = periodList[periodType]._text;
    getMyStatListing(userData.token, setLoading, {
      title: searchTimedActivity,
      page: 1,
      limit: 10,
      time: _time,
      tag: tags.toString(),
    });
  };

  useEffect(() => {
    if (activeTab === 1) {
      console.log(
        'tags ===> ',
        activeTab,
        tags.toString(),
        periodList[periodType]._text,
      );
      getStatListing();
    }
  }, [tags, periodType, searchTimedActivity, activeTab]);

  return (
    <div className="lg:px-15 py-5 pb-0 px-6">
      {/* <div className="mb-3">
        <Rank withAvatar withRankBtn={false} />
      </div> */}

      {/* <LinkTabs
        list={tabsLast}
        activeTabId={activeTab}
        onChange={setActiveTab}
      /> */}
      <div className="mt-3">
        <PlanSearch
          placholderExt={
            activeTab == 2
              ? Strings.leaderboard
              : activeTab == 1
              ? Strings.stats
              : Strings.tasks
          }
          setSearchValue={
            activeTab == 2
              ? () => {}
              : activeTab == 1
              ? setSearchTimedActivity
              : setSearchActivity
          }
        />
      </div>
      {activeTab == 0 ? (
        <>
          <Loading
            loading={loading}
            background="transparent"
            loaderColor={colors.PRIMARY_COLOR}
          />
          <UpdateInfo
            text={`Points last synced on Today, ${plannedActivitiesGetTime}`}
            onBtnClick={() => RefreshPlanActivities(setLoading)}
          />
          {activeTab === 0 && (
            <div className="box box_big mb-3">
              <Calendar
                onChange={setCalendarValue}
                value={calendarValue}
                noIndent
                period="week"
                view="week"
                // buttonProps={{text: 'See todays tasks', handler: () => null}}
              />
            </div>
          )}

          <FilterList
            isStat={activeTab === 1}
            data={props?.data || []}
            tagsData={props?.tagsData || []}
            setTags={setTags}
            showLoadMore={showLoadMore}
            onLoadMoreClick={() => setPlanActPageLimit(planActPageLimit + 5)}
            loading={loadingPlanActivityPage}
          />
        </>
      ) : activeTab == 1 ? (
        <>
          <Loading
            loading={loading}
            background="transparent"
            loaderColor={colors.PRIMARY_COLOR}
          />
          <UpdateInfo
            text={`Points last synced on Today, ${plannedActivitiesStatsGetTime}`}
            onBtnClick={() => getStatListing()}
          />
          <PeriodList
            ligntMode
            items={periodList}
            onClick={setPeriodType}
            value={periodType}
            lightFont
          />

          <FilterList
            isStat={activeTab === 1}
            data={dailyTasks || []}
            setTags={setTags}
            tagsData={props?.tagsData || []}
          />
        </>
      ) : (
        <Leaderboard />
      )}
      {/* </MainLayoutWithAspectSide> */}
    </div>
  );
};

export default HubTasksDesktop;
