import {useState} from 'react';
import {useAtom} from 'jotai';
import {userPopularNames} from '../hubState';
import {getPopularNames} from '../../../network/api/app';

export const useGetPopularNames = () => {
  const [popularNames, setPopularNames] = useAtom(userPopularNames);
  const [popularNamesListLoader, setPopularNamesListLoader] = useState(false);

  const getpopularNamesList = async token => {
    setPopularNamesListLoader(true);
    try {
      const response = await getPopularNames({token});
      // console.log(response);
      setPopularNamesListLoader(false);
      setPopularNames(response);
    } catch (e) {
      setPopularNamesListLoader(false);
      console.log('error => ', e.message);
    }
  };

  return {
    popularNames,
    popularNamesListLoader,
    getpopularNamesList,
  };
};
