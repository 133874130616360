import React, {useEffect} from 'react';
import AdditionalInformation from '../../../../../../components/reusable/assets/additionalInformation/AdditionalInformation.jsx';
import MainLayoutContent from '../../../../../../components/layouts/mainLayout/mainLayoutContent/MainLayoutContent.jsx';
import Rank from '../../../Items/rank/Rank.jsx';
import LinkTabs from '../../../../../../components/reusable/assets/linkTabs/LinkTabs.jsx';
import Board from '../../../../../../components/reusable/board/Board.jsx';
import MainLayout from '../../../../../../components/layouts/mainLayout/MainLayout.jsx';
import Loading from 'react-fullscreen-loading';
// mock data
import {useBuzzmiAuth} from '../../../../../../appState/hooks/useBuzzmiAuth/index.web.js';
import {colors} from '../../../../../../res/colors.js';
import Strings from '../../../../../../string_key/Strings.web.js';
import {useLeaderBoard} from '../../../../../../appState/hooks/useLeaderBoard';

const Leaderboard = () => {
  const {userData} = useBuzzmiAuth();
  const {useLeaderBoardList} = useLeaderBoard();
  const {data: leaderBoardata, isLoading} = useLeaderBoardList(
    userData && userData?.token,
  );
  const tabsLast = [
    {
      href: '/hub/tasks',
      title: Strings.tasks,
      id: 0,
    },
    {
      href: '/hub/stats',
      title: Strings.stats,
      id: 1,
    },
    {
      href: '/hub/v2/leaderboard',
      title: Strings.leaderboard,
      id: 2,
    },
  ];

  return (
    <>
      <Loading
        loading={isLoading}
        background="transparent"
        loaderColor={colors.PRIMARY_COLOR}
      />
      <div className="hub__cup mt-4">
        <img src="/images/assets/cup.png" alt="cup -img" />
      </div>

      <Board items={leaderBoardata?.listOfLeaderboards} />
    </>
  );
};

export default Leaderboard;
