import React, { useState } from 'react';
// import {Link} from 'react-router-dom';
import { Link } from '@react-navigation/native';
import CommentsIcon from '../../icons/CommentsIcon';
import useWindowSize from '../../../../../../hooks/useWindowSize';
import { useDispatch } from 'react-redux';
import { openModal } from '../../../../../../store/slices/modalSlice/modalSlice';
// import { useNavigation } from '@react-navigation/core';
import { useEventEmitter } from '../../../../../../hooks/useEventEmitter';

const CommentsReelBtn = ({ reel, isFromPost }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  // const navigation = useNavigation();
  const [refreshContent, setRefreshContent] = useState(null);

  const { user, comments } = reel?.object || {};

  useEventEmitter('post_comments_length', data => {
    setRefreshContent(data)
  })

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const openCommentsModal = e => {

    // console.log(reel)
    // if (width < 767) {
    //   e.preventDefault();
    //   dispatch(
    //     openModal({
    //       modalName: 'CommentsModal',
    //       modalType: 'swipe',
    //       modalProps: {
    //         comments: comments,
    //         userAvatar: user?.profile?.picture,
    //         entity: "reel",
    //         id: reel?.id
    //       },
    //     }),
    //   );
    //   return;
    // }

    // navigation.navigate('CommentsScreen', { id: reel?.id, entity: 'reel' });
  };

  return (
    <>
      <Link
        to={`/profile/reel/${reel?.id}/comments`}
      >
        <div
          className="reels__buttons-list-button"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={openCommentsModal}>
          <CommentsIcon isHovered={isHovered} />
        </div>
      </Link>
      <p className="reels__buttons-list-text" style={{ color: isFromPost ? '#000' : '#FFF' }}>{
        (refreshContent && refreshContent?.post_id == reel?.id) ? refreshContent?.data?.length : reel?.object?.comments?.length
      }</p>
    </>
  );
};

export default CommentsReelBtn;
