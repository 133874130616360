import React, { useState } from 'react';
import AltPrimaryButton from '../../../../components/reusable/form/items/buttons/AltPrimaryButton.jsx';
import PayForm from "./items/PayForm.jsx";
import MobilePaySystems from "./items/MobilePaySystems.jsx";
import Strings from '../../../../string_key/Strings.web.js';

const MobilePay = () => {

  const [showPayForm, setShowPayForm] = useState(false);

  const togglePayForm = () => setShowPayForm(!showPayForm);

  return (
    <div className='mobile-pay d-lg-none' >
      <div className='pay-form__header'>
        <div className={'h3 text-center mb-5'}>
          {Strings.become_premium_member}
        </div>
        <AltPrimaryButton onClick={togglePayForm} className='button_pay pay__card-btn w-100'>
          <div className='pay__card-btn_text'>
            <img src="/images/assets/pay-systems/pay-card.svg" alt="pay-system" />
            <span>{Strings.pay_with_credit_card}</span>
          </div>
          <i className={`fa-solid  text-alt-primary select__arrow pay__card-btn_arrow ${!showPayForm ? 'fa-chevron-right' : 'fa-chevron-left'}`} />
        </AltPrimaryButton>
      </div>
      { showPayForm ? <PayForm subtotal={144} discount={0} tax={6} total={150} /> : <MobilePaySystems />}
    </div >
  );
};

export default MobilePay;