import React, { useState, useRef } from 'react';
import MobileLayout from '../../../../components/layouts/mobileLayout/MobileLayout.jsx';
import PrimaryButton from '../../../../components/reusable/form/items/buttons/PrimaryButton.jsx';
import Input from '../../../../components/reusable/form/items/inputs/Input.jsx';
import Form from '../../../../components/reusable/form/Form.jsx';
import BaseLayout from '../../../../components/layouts/baseLayout/BaseLayout.jsx';
import { POST } from '../../../../api_helper/ApiServices.js';
import { SEND_EMAIL_VERIFICATION } from '../../../../api_helper/Api.js';
import { ShowSuccess, ShowError } from '../../../HOC/MessageWeb';
import { colors } from '../../../../res/colors.js';
import { saveData } from '../../../../res/asyncStorageHelper.web.js';
import { useFormValidator } from '../../../../form-validator/hooks/index.js';
import ValidationSchema from '../../../../form-validator/ValidationSchema.js';
import { useToast } from "react-native-toast-notifications";
import { useNavigation } from '@react-navigation/core';
import Loading from 'react-fullscreen-loading';
import { Link } from '@react-navigation/native';
import Strings from '../../../../string_key/Strings.web.js';
import { useEncryptedAtom } from '../../../../appState/hooks/useEncryptedAtom/index.web.js';
import { _sSecretAtom } from '../../../../appState/userState.js';

const validationSchema = new ValidationSchema(
    {
        email: [
            { rule: 'required' },
            { rule: 'email' },
        ]
    }
);

const ProvideEmail = (props) => {

    let refUrlParams = useRef(props.route.params)
    const { navigate } = useNavigation();
    const toast = useToast();
    const [isLoad, setIsLoad] = useState(false);
    const [secret_s, setSSecret] = useEncryptedAtom(_sSecretAtom);


    const { register, handleSubmit, isValid } = useFormValidator(validationSchema, async (formData) => {
        onSubmit(formData)
    });

    const onSubmit = data => {
        setIsLoad(true)
        let postData = { "email": data?.email, "flag": true };
        POST(SEND_EMAIL_VERIFICATION, false, null, null, postData, (response, isError) => {
            setIsLoad(false)
            if (isError) {
                setTimeout(() => {
                    ShowError(response, toast)
                }, 250);
            } else {
                setSSecret(data?.secret)
                navigate('ProvideEmailCode', { user: Object.assign(refUrlParams.current?.userData, { "email": data?.email }) })
                setTimeout(() => {
                    ShowSuccess(response?.message, toast)
                }, 250);
            }
        });
    };

    return (
        <BaseLayout>
            <Loading loading={isLoad} background="transparent" loaderColor={colors.PRIMARY_COLOR} />
            <MobileLayout>
                <div className="mb-4">
                    <div className="h4"> {Strings.provide_your_contact_information}</div>
                    <div className="mt-9" style={{ justifyContent: "center", display: "flex" }}>
                        <img src="/images/assets/mail.png" alt="" />
                    </div>
                    <Form id="email-code" onSubmit={handleSubmit}>
                        <Input
                            register={register}
                            name="email"
                            label={Strings.enter_your_email}
                        />
                    </Form>
                    <Link to="/provide-phone" >
                        <div className="h6 text-alt-primary mt-5 d-inline-block mb-6">
                            {Strings.or_sign_up_with_phone}
                        </div>
                    </Link>
                    <div className="text-r text-secondary">
                        {Strings.contact_email_description}
                    </div>
                </div>
                <div>
                    <PrimaryButton type="submit" form="email-code" className={`mb-7 ${!isValid ? 'button_inactive' : ''}`}>
                        {Strings.next}
                    </PrimaryButton>
                    <div className="text-s text-secondary">
                        By continuing, you agree to Buzzmi’s Terms of Service and confirm
                        that you have read Buzzmi’s Privacy Policy.
                    </div>
                </div>
            </MobileLayout>
        </BaseLayout>
    );
};

export default ProvideEmail;
