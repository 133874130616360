import {useDispatch} from 'react-redux';
import {
  Player,
  ControlBar,
  ClosedCaptionButton,
  FullscreenToggle,
} from 'video-react';

import useWindowSize from '../../../hooks/useWindowSize';
import {useRef} from 'react';
import {openModal} from '../../../store/slices/modalSlice/modalSlice';
import 'video-react/dist/video-react.css';
const VideoPlayer = ({url, title = '', tracks = [], autoplayVideo = false}) => {
  const dispatch = useDispatch();
  const {width} = useWindowSize();
  const ref = useRef(null);
  const handleAction = player => {
    const modalTitle = !!title
      ? title + ' Video Description'
      : 'Video Description';

    ref.current.actions.pause();
    dispatch(
      openModal({
        modalName: 'VideoModal',
        modalProps: {url: player.currentSrc, tracks, title},
        modalType: 'video',
        modalTitle,
      }),
    );
  };
  // console.log('autoplayVideo==>', autoplayVideo);
  return (
    <Player
      fluid={false}
      width="100%"
      height="100%"
      ref={ref}
      className="video-react-big-play-button-center"
      autoPlay={autoplayVideo}>
      <source src={url} />

      {tracks.length > 0 &&
        tracks?.map((track, index) => (
          <track
            kind="captions"
            src={track.src}
            srcLang={track.lang}
            label={track.label}
            key={index + 'video'}
          />
        ))}
      <ControlBar autoHide={false}>
        <ClosedCaptionButton order={7} />
        {width > 992 && (
          <FullscreenToggle
            actions={{
              order: 8,
              toggleFullscreen: handleAction,
            }}
          />
        )}
      </ControlBar>
    </Player>
  );
};

export default VideoPlayer;
