import React from "react"

const mocaAchievement = {
  title: "Buzz Rookie",
  img: {
    src: "/images/assets/achievement/rookie-m.svg",
    alt: "rookie",
  },
}

const ProfileAchievementIcon = () => {
  return (
    <div className="profile-achievement-icon">
      <img src={mocaAchievement.img.src} alt={mocaAchievement.img.alt} />
      <div>{mocaAchievement.title}</div>
    </div>
  )
}

export default ProfileAchievementIcon
