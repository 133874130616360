import React, { useEffect, useState } from 'react';
import MainLayoutV2 from '../../../../components/layouts/mainLayout/MainLayoutV2';
import MainLayoutContentV2 from '../../../../components/layouts/mainLayout/mainLayoutContentV2/MainLayoutContentV2';
import ResizeComponent from '../../../../components/reusable/assets/resizeComponent/ResizeComponent';
import UserProfileViewDesktop from './UserProfileViewDesktop.jsx'
import UserProfileViewMobileView from './UserProfileViewMobileView.jsx'
import useWindowSize from '../../../../hooks/useWindowSize';

const UserProfileViewContentList = props => {
  const { id, ispost } = props.route.params;
  const { width } = useWindowSize();
  return (
    <>
      {width > 992 ?
        <MainLayoutV2 centerSpace={true}>
          <MainLayoutContentV2 iscenter={true} rightColumnContent={<div />}>
            <ResizeComponent
              desktopComponent={<UserProfileViewDesktop id={id} ispost={ispost} />}
            />
          </MainLayoutContentV2>
        </MainLayoutV2>
        :
        <UserProfileViewMobileView id={id} ispost={ispost} />
      }
    </>
  );
};

UserProfileViewContentList.propTypes = {
  isPosts: true,
};

export default UserProfileViewContentList;
