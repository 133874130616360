export const info = [
  {
    title: 'Earn 20 Diamonds',
    svg: `
<svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.54624 3.08309C2.51809 3.00339 2.57623 2.91992 2.65925 2.91992H5.31921C5.40223 2.91992 5.46092 3.0023 5.43291 3.08206C5.19295 3.76535 4.39722 6.06273 4.10133 6.91737C4.06381 7.02575 3.91459 7.02508 3.87713 6.91668C3.58626 6.07479 2.81154 3.83415 2.54624 3.08309Z" />
<path d="M5.42338 2.14644C5.45617 2.22668 5.39809 2.31438 5.31289 2.31438H2.66666C2.58131 2.31438 2.52322 2.2264 2.5563 2.14611C2.72285 1.74193 3.15093 0.703333 3.41134 0.0743815C3.43002 0.0292646 3.47336 -3.06977e-05 3.52136 2.414e-08L4.46592 0.000604675C4.514 0.000635453 4.55737 0.0300844 4.57594 0.0753463C4.83217 0.699894 5.258 1.74172 5.42338 2.14644Z" />
<path d="M0.0293727 3.12337C-0.0378541 3.04429 0.0178236 2.92182 0.120406 2.92188C0.672602 2.92219 1.28447 2.92324 1.82135 2.92199C1.86841 2.92188 1.91052 2.94856 1.92956 2.99248C2.31653 3.88542 3.02112 5.81081 3.38875 6.82859C3.43511 6.95694 3.272 7.05348 3.18641 6.94821C2.45667 6.05069 0.982052 4.24396 0.0293727 3.12337Z" />
<path d="M7.97063 3.12337C8.03785 3.04429 7.98218 2.92182 7.87959 2.92188C7.3274 2.92219 6.71553 2.92324 6.17865 2.92199C6.13159 2.92188 6.08948 2.94856 6.07044 2.99248C5.68347 3.88542 4.97888 5.81081 4.61125 6.82859C4.56489 6.95694 4.728 7.05348 4.81359 6.94821C5.54333 6.05069 7.01795 4.24396 7.97063 3.12337Z" />
<path d="M6.1673 0.00585774C6.20383 0.00539869 6.23837 0.0214521 6.26182 0.0500428L7.95601 2.11581C8.02105 2.19512 7.9658 2.31584 7.86447 2.31584H6.20027C6.15378 2.31584 6.11151 2.28829 6.09196 2.24524L5.15281 0.17713C5.11597 0.0959879 5.17447 0.00329562 5.2621 0.00390928C5.51993 0.00571483 5.97261 0.00830431 6.1673 0.00585774Z" />
<path d="M1.91864 0.00585774C1.88211 0.00539869 1.84757 0.0214521 1.82412 0.0500428L0.129924 2.11581C0.0648854 2.19512 0.120135 2.31584 0.221468 2.31584H1.88566C1.93216 2.31584 1.97442 2.28829 1.99397 2.24524L2.93312 0.17713C2.96997 0.0959879 2.91147 0.00329562 2.82384 0.00390928C2.56601 0.00571483 2.11333 0.00830431 1.91864 0.00585774Z" />
</svg>

    `,
  },
  {
    title: 'Earn 500 BuzzMi Coins',
    svg: `
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.65625 9.0603C4.72258 9.10048 4.7964 9.12038 4.86951 9.12038V9.12109C4.97543 9.12109 5.07993 9.07986 5.1591 9.00058L8.26182 5.88825C8.37844 5.77129 8.41267 5.5971 8.34919 5.44459C8.28571 5.29209 8.13735 5.19361 7.97187 5.19361H6.64733L7.2636 3.68171C7.31495 3.55586 7.29998 3.41366 7.22401 3.30097C7.14769 3.18828 7.02109 3.12109 6.88485 3.12109H5.10454C4.92016 3.12109 4.75789 3.24623 4.7101 3.42184L3.85703 6.10723L3.85204 6.12252L3.84883 6.13816C3.82315 6.25902 3.85311 6.38309 3.93121 6.47907C4.00896 6.5747 4.12486 6.6298 4.24861 6.6298H5.12058L4.48078 8.58643C4.42194 8.76667 4.49398 8.96148 4.65625 9.0603Z" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.99922 10.3192C8.38509 10.3192 10.3192 8.38509 10.3192 5.99922C10.3192 3.61335 8.38509 1.67922 5.99922 1.67922C3.61335 1.67922 1.67922 3.61335 1.67922 5.99922C1.67922 8.38509 3.61335 10.3192 5.99922 10.3192ZM5.99922 10.7992C8.65019 10.7992 10.7992 8.65019 10.7992 5.99922C10.7992 3.34825 8.65019 1.19922 5.99922 1.19922C3.34825 1.19922 1.19922 3.34825 1.19922 5.99922C1.19922 8.65019 3.34825 10.7992 5.99922 10.7992Z" />
</svg>
    `,
  },
];
export const svgs = [
  {
    title: 'gems',
    svg: `
<svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.54624 3.08309C2.51809 3.00339 2.57623 2.91992 2.65925 2.91992H5.31921C5.40223 2.91992 5.46092 3.0023 5.43291 3.08206C5.19295 3.76535 4.39722 6.06273 4.10133 6.91737C4.06381 7.02575 3.91459 7.02508 3.87713 6.91668C3.58626 6.07479 2.81154 3.83415 2.54624 3.08309Z" />
<path d="M5.42338 2.14644C5.45617 2.22668 5.39809 2.31438 5.31289 2.31438H2.66666C2.58131 2.31438 2.52322 2.2264 2.5563 2.14611C2.72285 1.74193 3.15093 0.703333 3.41134 0.0743815C3.43002 0.0292646 3.47336 -3.06977e-05 3.52136 2.414e-08L4.46592 0.000604675C4.514 0.000635453 4.55737 0.0300844 4.57594 0.0753463C4.83217 0.699894 5.258 1.74172 5.42338 2.14644Z" />
<path d="M0.0293727 3.12337C-0.0378541 3.04429 0.0178236 2.92182 0.120406 2.92188C0.672602 2.92219 1.28447 2.92324 1.82135 2.92199C1.86841 2.92188 1.91052 2.94856 1.92956 2.99248C2.31653 3.88542 3.02112 5.81081 3.38875 6.82859C3.43511 6.95694 3.272 7.05348 3.18641 6.94821C2.45667 6.05069 0.982052 4.24396 0.0293727 3.12337Z" />
<path d="M7.97063 3.12337C8.03785 3.04429 7.98218 2.92182 7.87959 2.92188C7.3274 2.92219 6.71553 2.92324 6.17865 2.92199C6.13159 2.92188 6.08948 2.94856 6.07044 2.99248C5.68347 3.88542 4.97888 5.81081 4.61125 6.82859C4.56489 6.95694 4.728 7.05348 4.81359 6.94821C5.54333 6.05069 7.01795 4.24396 7.97063 3.12337Z" />
<path d="M6.1673 0.00585774C6.20383 0.00539869 6.23837 0.0214521 6.26182 0.0500428L7.95601 2.11581C8.02105 2.19512 7.9658 2.31584 7.86447 2.31584H6.20027C6.15378 2.31584 6.11151 2.28829 6.09196 2.24524L5.15281 0.17713C5.11597 0.0959879 5.17447 0.00329562 5.2621 0.00390928C5.51993 0.00571483 5.97261 0.00830431 6.1673 0.00585774Z" />
<path d="M1.91864 0.00585774C1.88211 0.00539869 1.84757 0.0214521 1.82412 0.0500428L0.129924 2.11581C0.0648854 2.19512 0.120135 2.31584 0.221468 2.31584H1.88566C1.93216 2.31584 1.97442 2.28829 1.99397 2.24524L2.93312 0.17713C2.96997 0.0959879 2.91147 0.00329562 2.82384 0.00390928C2.56601 0.00571483 2.11333 0.00830431 1.91864 0.00585774Z" />
</svg>

    `,
  },
  {
    title: 'coins',
    svg: `
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.65625 9.0603C4.72258 9.10048 4.7964 9.12038 4.86951 9.12038V9.12109C4.97543 9.12109 5.07993 9.07986 5.1591 9.00058L8.26182 5.88825C8.37844 5.77129 8.41267 5.5971 8.34919 5.44459C8.28571 5.29209 8.13735 5.19361 7.97187 5.19361H6.64733L7.2636 3.68171C7.31495 3.55586 7.29998 3.41366 7.22401 3.30097C7.14769 3.18828 7.02109 3.12109 6.88485 3.12109H5.10454C4.92016 3.12109 4.75789 3.24623 4.7101 3.42184L3.85703 6.10723L3.85204 6.12252L3.84883 6.13816C3.82315 6.25902 3.85311 6.38309 3.93121 6.47907C4.00896 6.5747 4.12486 6.6298 4.24861 6.6298H5.12058L4.48078 8.58643C4.42194 8.76667 4.49398 8.96148 4.65625 9.0603Z" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.99922 10.3192C8.38509 10.3192 10.3192 8.38509 10.3192 5.99922C10.3192 3.61335 8.38509 1.67922 5.99922 1.67922C3.61335 1.67922 1.67922 3.61335 1.67922 5.99922C1.67922 8.38509 3.61335 10.3192 5.99922 10.3192ZM5.99922 10.7992C8.65019 10.7992 10.7992 8.65019 10.7992 5.99922C10.7992 3.34825 8.65019 1.19922 5.99922 1.19922C3.34825 1.19922 1.19922 3.34825 1.19922 5.99922C1.19922 8.65019 3.34825 10.7992 5.99922 10.7992Z" />
</svg>
    `,
  },
];

export const generateMessages = (data) => {
  const messages = [];

  for (const key in data) {
    if (key !== 'name' && data[key] !== 0) {
      const svgIcon = svgs.find((svg) => svg.title.toLowerCase().includes(key.toLowerCase()));
      const icon = svgIcon ? svgIcon.svg : svgs[1].svg;

      messages.push({title: `Earn ${data[key]} ${key}`, svg: icon});
    }
  }

  return messages;
};
