import React from 'react';
import VisitorAvatar from '../../../../../../../components/reusable/assets/avatar/VisitorAvatar';
import { useProfileInfo } from '../../../../../../../appState/hooks/useProfileInfo';
import VisitorProfilebio from '../../../../items/VisitorProfilebio';
import { useProfileApp } from '../../../../../../../appState/hooks';

const VisitorProfileInfoMobile = ({ showAchievement = false, isOtherProfile = false, onPress }) => {

    const { profileInfo } = useProfileInfo();
    const { visitorReels, visitorPosts } = useProfileApp();

    return (
        <>
            <div className="profile-top__mobile-wrap justify-around">
                <VisitorAvatar mode="small" isOtherProfile />
                <div className={"ml-2 flex justify-around flex-1"}>
                    <div>
                        <p className={"text-lg text-center font-semibold"}>{(visitorReels?.length + visitorPosts?.length) || 0}</p>
                        <p className={"text-sm text-center"}>Posts</p>
                    </div>
                    <div onClick={() => { onPress(), global.vFriendIndex = 1 }}>
                        <p className={"text-lg text-center font-semibold"}>{profileInfo?.followers.length || 0}</p>
                        <p className={"text-sm text-center"}>Followers</p>
                    </div>
                    <div onClick={() => { onPress(), global.vFriendIndex = 0 }}>
                        <p className={"text-lg text-center font-semibold"}>{profileInfo?.following.length || 0}</p>
                        <p className={"text-sm text-center"}>Following</p>
                    </div>
                </div>
            </div>
            <div className="profile-top__mobile-right">
                <VisitorProfilebio />
            </div>
        </>
    );
};

export default VisitorProfileInfoMobile;
