import React, {useState} from 'react';
import Logo from '../../../components/reusable/assets/logo/Logo.jsx';
import PrimaryButton from '../../../components/reusable/form/items/buttons/PrimaryButton.jsx';
import {Link, StackActions} from '@react-navigation/native';
import {getData, saveData} from '../../../res/asyncStorageHelper.web.js';
import {GET_USER_PROFILE} from '../../../api_helper/Api.js';
import Loading from 'react-fullscreen-loading';
import {colors} from '../../../res/colors.js';
import {GET} from '../../../api_helper/ApiServices.js';
import Strings from '../../../string_key/Strings.web.js';
import {useAtom} from 'jotai';
import {
  _companyIdAtom,
  _userDetailsAtom,
  _userJustSignupAtom,
} from '../../../appState/userState.js';
import {useEncryptedAtom} from '../../../appState/hooks/useEncryptedAtom';

const FinishRegister = props => {
  const [isLoad, setIsLoad] = useState(false);
  const [userDetails, setUserDetails] = useEncryptedAtom(_userDetailsAtom);
  const [companyId, setCompanyId] = useEncryptedAtom(_companyIdAtom);
  const [justSignup, setjustSignup] = useAtom(_userJustSignupAtom);

  const onSubmitHome = () => {
    // setIsLoad(true);
    getData('sessionToken', token => {
      GET(GET_USER_PROFILE, token, null)
        .then(res => {
          setIsLoad(false);
          const userProfile = {
            ...res?.data,
            token: token,
            // justSignup: true,
          };

          setCompanyId(userProfile?.vendorId);

          setUserDetails(userProfile);
          // global.signUpFlow = true;
          setjustSignup(true);
          setTimeout(() => {
            // window.location.reload();

            props.navigation.replace('Tab');
          }, 150);
        })
        .catch(e => {
          setIsLoad(false), console.log('INVITATION (error) => ', e.message);
        });
    });
  };

  return (
    <div className="auth-layout">
      <Loading
        loading={isLoad}
        background="transparent"
        loaderColor={colors.PRIMARY_COLOR}
      />
      <div className="text-center auth-layout__container mobile-layout pt-6 pt-sm-0">
        <div>
          <Logo />
          <img
            src="/images/assets/finish.png"
            className="w-100 mb-3 mb-sm-6"
            alt=""
          />
          <div className="h5 mb-3 mb-sm-9">Hooray!</div>
          <div className="text-r mb-3 mb-sm-6 text-alert">
            {Strings.you_now_a_part_of_the_buzz_worthy_world_of_Buzzmi}
          </div>
        </div>
        <div>
          <PrimaryButton
            onClick={() => {
              onSubmitHome();
            }}
            className="mb-5">
            {Strings.lets_go_to_your_profile}
          </PrimaryButton>
          <Link to={{screen: 'Auth'}} action={StackActions.replace('Auth')}>
            <div className="text-alt-primary h6">{Strings.go_to_start}</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FinishRegister;
