import React, {useEffect, useState, useRef} from 'react';
import PostHeader from './items/postHeader/PostHeader';
import PostImage from './items/postImageVideo/PostImage';
import PostVideo from './items/postImageVideo/PostVideo';
import PostContent from './items/postContent/PostContent';
import PostBtnsV2 from './items/postBtns/PostBtnsV2';
import PropsTypes from 'prop-types';
import {useEventEmitter} from '../../../../../hooks/useEventEmitter';
import PostHeaderV2 from './items/postHeader/PostHeaderV2';
import VideoReel from '../profileReels/VideoReel';
import {useBuzzmiAuth} from '../../../../../appState/hooks/useBuzzmiAuth/index.web';
import {useDispatch} from 'react-redux';
import {
  closeModal,
  openModal,
} from '../../../../../store/slices/modalSlice/modalSlice';
import {LockClosedIcon} from '@heroicons/react/24/outline';
import {getVideoPosterURL} from '../types';

export function extractFieldValues(fields) {
  const values = {};

  fields.forEach(field => {
    values[field.key] = field.value === 'null' ? null : JSON.parse(field.value);
  });

  return values;
}
export function categorizeUrlsFromArray(arr, initKey = 'content') {
  const categorizedUrls = {
    image: [],
    video: [],
  };

  arr.forEach(obj => {
    const {key, value} = obj;

    if (key === initKey && typeof value === 'string') {
      const urlRegex = /(https?:\/\/[^\s,\\"]+)/g;
      const matches = value.match(urlRegex);

      if (matches && matches.length > 0) {
        matches.forEach(url => {
          // Check if the URL starts with the specified pattern
          if (url.startsWith('https://res.cloudinary.com/')) {
            categorizedUrls.video.push(url);
          } else {
            categorizedUrls.image.push(url);
          }
        });
      }
    }
  });

  return categorizedUrls;
}

const ProfilePostV2 = ({postData, currentPostId, type, isMyPost}) => {
  const [post, setPost] = useState(null);
  const [refreshContent, setRefreshContent] = useState(null);
  const {userData} = useBuzzmiAuth();
  const scrollRef = useRef([]);
  const dispatch = useDispatch();

  const onUpgradtion = () => {
    dispatch(
      openModal({
        modalName: 'renderAlert',
        modalType: 'alert',

        modalProps: {
          nameAlert: 'upgradeAlert',
          propsAlert: {
            closeModal: () => {
              dispatch(closeModal());
            },
            contentType: post?.contentType,
          },
        },
      }),
    );
  };

  useEffect(() => {
    setPost(postData);
  }, []);

  const {thumbnail, imageUrl} = post || {};

  useEventEmitter('post_comments_length', data => {
    setRefreshContent(data);
  });

  useEffect(() => {
    if (post?.id === currentPostId) {
      scrollRef.current[currentPostId].scrollIntoView();
    }
  }, [post]);
  const post_images =
    post?.fields?.length > 0
      ? categorizeUrlsFromArray(post?.fields)?.image
      : [];
  const post_video =
    post?.fields?.length > 0
      ? categorizeUrlsFromArray(post?.fields)?.video
      : [];
  return post ? (
    <div className="relative w-100 h-100 justify-center items-center">
      <div
        ref={ref => (scrollRef.current[post?.id] = ref)}
        className={`profile-post-v2  sm:px-[0px] py-[10px] px-[10px] ${
          post?.contentType &&
          post?.contentType != 'all' &&
          userData?.membership?.type == 'free_member'
            ? 'blur-[2px]'
            : 'blur-none'
        }`}
        id={type === 'post' ? `post-${post?.id}` : `reel-${post?.id}`}>
        <PostHeaderV2
          userName={post?.owner?.member?.name}
          userNickname={post?.owner?.member.username}
          userPosition={''}
          userAvatar={extractFieldValues(post?.owner?.member?.fields)?.avatar}
          isVerified={post?.owner?.member.isVerified}
          post={post}
          isMyPost={isMyPost}
        />

        {type === 'reel' && post_video?.length > 0 && (
          <PostVideo
            srcVideo={post_video?.[0]}
            posterVideo={getVideoPosterURL(post_video?.[0])}
            post={post}
            type={type}
            isMyPost={isMyPost}
          />
        )}

        {type === 'post' && post_video?.length > 0 && (
          <PostVideo
            srcVideo={post_video[0]}
            posterVideo={getVideoPosterURL(post_video?.[0])}
            post={post}
            type={type}
            isMyPost={isMyPost}
          />
        )}

        {type === 'post' && post_images?.length > 0 && (
          <PostImage isMyPost={isMyPost} images={post_images} />
        )}

        <PostContent
          city={''}
          country={''}
          nickname={post?.owner?.member?.username}
          text={post?.title}
          tags={[]}
          date={new Date(post?.publishedAt)}
          song={''}
        />

        <PostBtnsV2
          postId={post?.id}
          likes={post?.reactionsCount}
          comments={
            refreshContent && refreshContent?.post_id == post?.id
              ? refreshContent?.data?.length
              : post?.repliesCount
          }
          gems={2}
          gifts={4}
          userName={post?.owner?.member.username}
          userAvatar={post?.owner?.member?.picture}
          post={post}
          type={type}
        />
      </div>
      {post?.contentType != 'all' &&
        userData?.membership?.type == 'free_member' && (
          <div
            onClick={() => {
              onUpgradtion();
            }}
            className={
              'absolute top-0 w-100 h-100 flex justify-center items-center'
            }>
            <div className={'flex flex-col'}>
              <img
                src={
                  post?.contentType == 'premium'
                    ? '/images/social/premium_white.png'
                    : '/images/social/exclusive_white.png'
                }
                className={'h-12 w-12 self-center mb-4'}
              />
              <p className={'text-[#fff]'}>
                Unlock {post?.contentType} content
              </p>
              <LockClosedIcon
                className={'h-6 w-6 mt-2 self-center text-[#FFF]'}
              />
            </div>
          </div>
        )}
    </div>
  ) : null;
};

ProfilePostV2.propTypes = {
  postData: PropsTypes.object,
  currentPostId: PropsTypes.number,
  type: PropsTypes.string,
};

export default ProfilePostV2;
