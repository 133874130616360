import React, {useEffect, useState, useRef} from 'react';
import BackBtnWithTitlePage from '../../../../components/reusable/btns/backBtn/BackBtnWithTitlePage.jsx';
import MainLayout from '../../../../components/layouts/mainLayout/MainLayout.jsx';
import Loading from 'react-fullscreen-loading';
// mock data
import {useBuzzmiAuth} from '../../../../appState/hooks/useBuzzmiAuth';
import {colors} from '../../../../res/colors.js';
import PictureUploader from '../../../../components/reusable/uploader/pictureUploader/PictureUploader.jsx';
import Form from '../../../../components/reusable/form/Form.jsx';
import ValidationSchema from '../../../../form-validator/ValidationSchema.js';
import {useFormValidator} from '../../../../form-validator/hooks/index.js';
import Box from '../../../../components/reusable/cards/box/Box.jsx';
import SubmitButton from '../../../../components/reusable/btns/buttons/submitButton/SubmitButton.jsx';
import {useProfileApp} from '../../../../appState/hooks/index.js';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {useNavigation} from '@react-navigation/core';
import VideoUploader from '../../../../components/reusable/uploader/videoUploader/VideoUploader.jsx';

const validationSchema = new ValidationSchema({
  name: [
    {rule: 'required'},
    {rule: 'minLength', value: 3},
    {rule: 'maxLength', value: 400},
  ],
});

let f_array = [];
let file_data = [];
const AddPosts = props => {
  const {description} = props.route.params;
  // return null;
  const navigation = useNavigation();
  const [errors, setErrors] = useState([]);
  const [error, setError] = useState([]);
  const {userData} = useBuzzmiAuth();
  const [images, setImages] = useState([]);
  const [fileData, setFileData] = useState(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const {
    postCreatePost,
    mutateFilesUpload,
    fileUploadResponse,
    isFileUploadLoading,
    postCreateReel,
    isCreatePostLoading,
    isCreateReelLoading,
  } = useProfileApp();
  const [renderKey, setRenderKey] = useState(885599);

  const [highlightedText, setHighlightedText] = useState({
    value: null,
    index: 0,
    length: 0,
  });
  const [maxWidth, setMaxWidth] = useState(0);
  const textRef = useRef(null);

  const sendRequest = async formData => {
    try {
      if (activeTabIndex == 0) {
        let postData = {content: formData?.name, images: fileData};
        postCreatePost(
          Object.assign(postData, {
            params: {isAuth: true, token: userData?.token},
          }),
        );
      } else {
        let reelData = {content: formData?.name, videos: fileData};
        postCreateReel(
          Object.assign(reelData, {
            params: {isAuth: true, token: userData?.token},
          }),
        );
      }
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    if (fileUploadResponse) {
      var fileSuccess = new Promise((resolve, reject) => {
        file_data.push(
          Object.assign(fileUploadResponse, {
            src: fileUploadResponse.url,
            meta: {
              name: getFileName(fileUploadResponse.url),
              formatType: fileUploadResponse?.meta.formatType,
            },
          }),
        );
        f_array.push(fileUploadResponse.url);
        resolve();
      });
      fileSuccess.then(() => {
        setFileData([...f_array, file_data]);
        setImages(f_array);
      });
    }
  }, [fileUploadResponse, isFileUploadLoading]);

  const {register, handleSubmit, setValue, isValid, values, setValues} =
    useFormValidator(validationSchema, sendRequest, {
      defaultValues: {
        name: description,
      },
    });

  useEffect(() => {
    if (textRef.current && textRef?.current?.clientWidth > 0) {
      setMaxWidth(textRef.current.clientWidth + 'px');
    } else {
      setMaxWidth('100%');
    }
  }, [textRef.current]);

  const getFileName = url => {
    return url.substring(url.lastIndexOf('/') + 1);
  };

  const onChange = (imageList, index) => {
    if (imageList?.length > 0) {
      const fd = new FormData();
      fd.append('file', imageList[0].file);
      mutateFilesUpload(
        Object.assign(fd, {
          params: {
            isAuth: true,
            token: userData?.token,
          },
        }),
      );
    }
  };

  const handleChangeSelection = (range, source, editor) => {
    if (range === null) {
      return;
    }
    let highlightedText = editor.getText(range);
    setHighlightedText({
      value: highlightedText,
      index: range.index,
      length: range.length,
    });
  };

  const handleChange = value => {
    setValue('name', value);
  };

  const cancelHandler = () => {
    navigation.goBack();
  };

  const handleTabSelect = index => {
    setActiveTabIndex(index);
  };

  return (
    <MainLayout>
      <Loading
        loading={
          isFileUploadLoading || isCreateReelLoading || isCreatePostLoading
        }
        background="transparent"
        loaderColor={colors.PRIMARY_COLOR}
      />
      <div className="main-layout__single-container" key={renderKey}>
        <BackBtnWithTitlePage
          title={`New ${activeTabIndex == 0 ? 'Post' : 'Reel'}`}
          customCallbackRightButton={cancelHandler}
          lastItemLink={{
            title: (
              <span className="text-alt-primary d-none d-md-block">Cancel</span>
            ),
          }}
        />
        <Form
          error={null}
          onSubmit={handleSubmit}
          formClassName="hub-form-wrapper">
          <Box className="hub-form-box">
            <Tabs
              className="profile-section"
              selectedIndex={activeTabIndex}
              onSelect={handleTabSelect}>
              <TabList>
                <Tab>Posts</Tab>
                <Tab>Reel</Tab>
              </TabList>
            </Tabs>
          </Box>

          <Box className="hub-form-box">
            <div className="input-label">
              <h4 className="h4 mb-3 mb-lg-4" style={{textAlign: 'left'}}>
                {'Write Caption'}
              </h4>
            </div>
            <div ref={textRef} style={{maxWidth}}>
              <ReactQuill
                theme="snow"
                value={values?.name || ''}
                className="profile-form__bio link-wrqapper"
                onChange={handleChange}
                modules={{
                  toolbar: [[]],
                }}
                onChangeSelection={handleChangeSelection}
              />
            </div>
          </Box>

          {activeTabIndex == 0 && (
            <Box className="hub-form-box">
              <h4 className="h4 mb-3 mb-lg-4">{'Add Images'}</h4>
              <div className="picture-uploader__row">
                {new Array(4).fill('').map((_, index) => {
                  return (
                    <PictureUploader
                      defaultValue={images[index]}
                      parentSetterState={imageList =>
                        onChange(imageList, index)
                      }
                      locked={false}
                      key={index}
                      index={index}
                      maxNumber={4}
                      setErrors={setErrors}
                      onDelete={imageIndexToDelete => {
                        setImages(prev => {
                          prev.splice(imageIndexToDelete, 1);
                          return prev;
                        });
                        setRenderKey(prev => prev + 1);
                      }}
                    />
                  );
                })}
              </div>
            </Box>
          )}

          {activeTabIndex == 1 && (
            <Box className="hub-form-box">
              <h4 className="h4 mb-3 mb-lg-4">{'Add Video'}</h4>
              <VideoUploader
                title={values?.name || ''}
                defaultValue={values?.video}
                setValue={setValue}
                isReel={true}
                onGetResponse={UploadResponse => {
                  var fileSuccess = new Promise((resolve, reject) => {
                    file_data.push(
                      Object.assign(UploadResponse, {
                        src: UploadResponse.url,
                        meta: {
                          name: getFileName(UploadResponse.url),
                          formatType: UploadResponse?.meta.formatType,
                        },
                      }),
                    );
                    resolve();
                  });
                  fileSuccess.then(() => {
                    setFileData(file_data);
                  });
                }}
              />
            </Box>
          )}

          <div className="d-flex bottom-mobile-button justify-content-center mt-5">
            <SubmitButton isValid={isValid} title={'Save'} />
          </div>
        </Form>
      </div>
    </MainLayout>
  );
};

export default AddPosts;
