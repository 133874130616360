import React, { useEffect, useState } from 'react';
import background from "../../../../../src/assets/gems_back.png"
import BackBtnWithTitlePage from '../../../../components/reusable/btns/backBtn/BackBtnWithTitlePage';


const DetailHeader = ({ rightImage, title, coin, amount }) => {
    return (
        <>
            <div className={"bg-no-repeat bg-cover h-1/4"} style={{
                backgroundImage: `url(${background})`
            }}>
                <div className={"mt-4"}>
                    <BackBtnWithTitlePage hideBottomBorder={true} title={title} btnText={""} />
                </div>

                <div className={"flex p-8"}>
                    <img src={rightImage} className={"object-contain w-12 h-12"} />
                    <div className={"ml-4 self-center"}>
                        <p className={"text-2xl font-bold"}>{coin}</p>
                        {amount > 0 && <p className={"text-sm"}>{'$' + amount}</p>}
                    </div>
                </div>
            </div>
        </>
    );
};
export default DetailHeader;
