// noinspection ES6CheckImport

import {Platform, StyleSheet} from 'react-native';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import {fonts} from '../../../../res/fonts';
import {colors} from '../../../../res/colors';
import styled from 'styled-components/native';
import {RNSVGSymbol} from 'react-native-svg';

const EventNameText = styled.Text`
  color: ${colors.BLACK};
  font-family: ${fonts.MEDIUM};
  font-size: 1.5rem;
  margin-horizontal: 2rem;
  margin-bottom: 3px;
`;
const EventTypeText = styled.Text`
  color: ${colors.OSLO_GRAY};
  font-family: ${fonts.REGULAR};
  font-size: 1rem;
  margin-bottom: 3px;
  margin-horizontal: 2rem;
`;
const EventTimeText = styled.Text`
  color: ${colors.OSLO_GRAY};
  font-family: ${fonts.REGULAR};
  font-size: 1rem;
`;
const TitleText = styled.Text`
  color: ${colors.OSLO_GRAY};
  font-family: ${fonts.BOLD};
  font-size: 1rem;
`;
const SmTitleText = styled.Text`
  color: ${colors.OSLO_GRAY};
  font-family: ${fonts.BOLD};
  font-size: 1rem;
  margin-bottom: 5px;
`;
const DetailText = styled.Text`
  color: ${props => (props.link ? colors.BLUE_ACTION : colors.BLACK)};
  font-family: ${fonts.REGULAR};
  font-size: 1rem;
  max-width: ${props => (props.half ? '70%' : '90%')};
`;
const EventCreateText = styled.Text`
  color: ${colors.OSLO_GRAY};
  font-family: ${fonts.REGULAR};
  font-size: 1rem;
`;
const Line = styled.View`
  width: 95%;
  margin-vertical: 1rem;
  height: 0.5px;
  background-color: ${colors.OSLO_GRAY};
`;

export {
  EventNameText,
  EventTypeText,
  EventTimeText,
  DetailText,
  TitleText,
  SmTitleText,
  Line,
  EventCreateText,
};

export default StyleSheet.create({
  ////////
  BGContainer: {backgroundColor: 'rgb(250,250,250)', flex: 1},
  eventHeaderBox: {
    // backgroundColor: 'lightblue',
    flexDirection: 'row',
    paddingHorizontal: '3rem',
    alignItems: 'center',
  },
  eventStatusCircle: {
    height: '3.5rem',
    width: '3.5rem',
    borderRadius: '3.5rem',
    backgroundColor: colors.PRIMARY_COLOR,
  },
  eventTimingsBox: {
    // backgroundColor: 'lightblue',

    paddingHorizontal: '3rem',
  },
  titleBox: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
    paddingLeft: '3rem',
    borderBottomWidth: 0.5,
    borderColor: colors.OSLO_GRAY,
    // backgroundColor: 'lightgreen',
  },
  detailBox: {
    paddingVertical: '1rem',

    paddingHorizontal: '3rem',
    borderBottomWidth: 0.5,
    borderColor: colors.OSLO_GRAY,
    backgroundColor: colors.WHITE,
  },
  NameBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  footerBox: {
    paddingVertical: '2rem',

    paddingHorizontal: '3rem',
  },
});
