import React, { useEffect, useState, useRef } from 'react';
import FriendCard from './FriendCard';
import { friendsData } from '../../../../../../../mock-data/friends/friendsData';
import Input from '../../../../../../../components/reusable/form/items/inputs/Input';
import { useProfileApp } from '../../../../../../../appState/hooks';
import { useBuzzmiAuth } from '../../../../../../../appState/hooks/useBuzzmiAuth/index.web';
import { Dots } from 'react-activity';
import { colors } from '../../../../../../../res/colors';
import { useHubApp } from '../../../../../../../appState/hooks/useHubApp/index.native';

const FriendsList = ({
  actionComponent,
  isMultiple,
  onClickItem,
  multipleList,
  topComponent,
  AssignPostResponse
}) => {
  const [friends, setFriends] = useState([]);
  const [friendsLoading, setFriendsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [assignedIds, setAssignIds] = useState([]);

  useEffect(() => {
    setFriends(friendsData);
  }, []);
  const typingTimer = useRef(null);
  const [filterQuery, setFilterQuery] = useState('');

  useEffect(() => {
    if (AssignPostResponse?.message) {
      setAssignIds(multipleList)
    }
  }, [AssignPostResponse])

  const {
    getFollowerUsers,

    followerUsers,
  } = useProfileApp();
  const { userData } = useBuzzmiAuth();

  useEffect(() => {
    clearTimeout(typingTimer?.current);
    typingTimer.current = setTimeout(() => {
      // getFollowingUsers(userData?.token, userData?.id, filterQuery);

      getFollowerUsers(
        userData?.token,
        userData?.id,
        filterQuery,
        {
          page: 1,
          limit: 10,
        },
        setFriendsLoading,
      );
    }, 1000);
  }, [filterQuery]);

  return (
    <>
      <div className="friends-list__input-wrapper">
        <Input
          name="search-friends"
          placeholder="Search"
          register={() => { }}
          value={filterQuery}
          onChange={event => setFilterQuery(event.target.value)}
        />
        <img src="/images/post/search.svg" alt="search" />
      </div>
      {friendsLoading ? (
        <div
          className="pb-2"
          style={{
            display: 'flex',
            justifyContent: 'center', // Horizontally center content
            alignItems: 'center', // Vertically center content
            width: '100%', // Set the width to 100% if needed
            // Add any other inline styles you want
          }}>
          <Dots color={colors.PRIMARY_COLOR} />
        </div>
      ) : (
        <> {followerUsers?.length > 0 && topComponent()}</>
      )}

      <ul className="friends-list">
        {console.log('followerUsers', followerUsers)}

        {followerUsers
          .filter(friend =>
            friend.firstName.toLowerCase().includes(filterQuery.toLowerCase()),
          )
          .map(friend => (
            <FriendCard
              assignedIds={assignedIds}
              key={friend?.id}
              friend_id={friend?.id}
              avatar={friend?.avatar}
              name={friend?.firstName + ' ' + friend?.lastName}
              nickname={friend?.displayName}
              position={friend?.position}
              socialLink={friend?.socialLink}
              onClick={isMultiple ? assignedIds?.includes(friend?.id) ? null : () => onClickItem(friend?.id) : null}
              className={
                isMultiple
                  ? assignedIds?.includes(friend?.id)
                    ? 'border-1 border-slate-50'
                    : multipleList?.includes(friend?.id)
                      ? 'border-2 border-pink-500 '
                      : ''
                  : ''
              }>
              {actionComponent(friend)}
            </FriendCard>
          ))}
      </ul>
    </>
  );
};

export default FriendsList;
