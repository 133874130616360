import React, {useEffect, useState} from 'react';
import PostAuthorInfoV2 from '../profilePost/items/postHeader/PostAuthorInfoV2';
import formatRelativeDate from '../profilePost/utils/dateUtils';
import LikeIcon from '../icons/LikeIcon';
import ReplyBtn from './ReplyBtn';
import {extractFieldValues} from '../profilePost/ProfilePostV2';
import {POST_DATA} from '../../../../../api_helper/ApiServices';
import {COMMUNITY_COMMENT_VOTE} from '../../../../../network/api/endpoints';
import {useBuzzmiAuth} from '../../../../../appState/hooks/useBuzzmiAuth/index.web';
import {useProfileApp} from '../../../../../appState/hooks';

const CommentV2 = ({comment}) => {
  const [likesCount, setLikesCount] = useState(null);
  const [isLiked, setIsLiked] = useState(false);
  const [hovered, setHovered] = useState(false);
  const {userData} = useBuzzmiAuth();
  const {postLikeComment, postDislikeComment} = useProfileApp();
  // console.log('comment===>', comment);
  useEffect(() => {
    setLikesCount(Number(comment?.reactionsCount));
  }, [comment?.reactionsCount]);
  useEffect(() => {
    setIsLiked(
      comment?.reactions?.length > 0 &&
        // item?.reactions[0].participants != null &&
        comment?.reactions[0].participants?.nodes?.length &&
        comment?.reactions[0].participants.nodes.filter(
          o => o?.participant?.id === userData?.bettermodeUserId,
        ).length > 0,
    );
  }, [comment?.reactions]);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleClick = () => {
    setIsLiked(prevIsLiked => !prevIsLiked);
    setHovered(false);
    setLikesCount(prevLikes => prevLikes + (isLiked ? -1 : 1));
    postLikeComment(comment?.id, userData.token);
  };

  return (
    <div className="comments-post__wrapper_v2 bg-gray-100 mb-2 sm:mb-2">
      <PostAuthorInfoV2
        sizeSmall={true}
        userName={comment?.owner?.member?.name}
        userNickname={
          comment?.description || extractFieldValues(comment?.fields)?.content
        }
        userPosition={''}
        userAvatar={
          comment?.owner?.member?.fields?.length > 0
            ? extractFieldValues(comment?.owner?.member?.fields)?.avatar
            : ''
        }
        isVerified={comment?.owner?.member?.status === 'VERIFIED'}
      />

      <div className="d-flex flex-row align-items-center justify-content-between">
        <div className="comments-post__comment-actions d-flex flex-row">
          <p className="comments-post__comment-date text-s">
            {formatRelativeDate(new Date(comment?.publishedAt))}
          </p>
          <p className="text-s">
            {comment?.reactionsCount === 0 ? '' : `${likesCount} Likes`}
          </p>
        </div>

        <div
          className="comments-post__like"
          onClick={handleClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
          <LikeIcon isHovered={hovered || isLiked} />
        </div>
      </div>
    </div>
  );
};

export default CommentV2;
