import React, {useEffect, useState} from 'react';
import BackBtnWithTitlePage from '../../../../components/reusable/btns/backBtn/BackBtnWithTitlePage.jsx';
// import InfoCardList from '../../../../components/reusable/cards/infoCard/InfoCardList.jsx';
import InfoCardList from '../../../../components/reusable/cards/infoCardv2/InfoCardList.jsx';
import FilterTags from '../Items/filterList/items/filterTags/FilterTags.jsx';
import LinkTabs from '../../../../components/reusable/assets/linkTabs/LinkTabs.jsx';

import Loading from 'react-fullscreen-loading';

// mock data
import {
  mockItems,
  mockTags,
  presetList,
} from '../../../../mock-data/hub/hub.js';
import {useHubApp} from '../../../../appState/hooks/useHubApp';
import Strings from '../../../../string_key/Strings.web.js';
import {useBuzzmiAuth} from '../../../../appState/hooks/useBuzzmiAuth';

import {colors} from '../../../../res/colors.js';
import PlanSearch from '../../../../components/reusable/assets/additionalInformation/items/additionalInformationPlan/items/planSearch/PlanSearch.jsx';
import {useEventEmitter} from '../../../../hooks/useEventEmitter.js';
import MainLayoutWithAspectSide from '../../../../components/layouts/mainLayout/MainLayoutWithAspectSide.jsx';

import BaseActivityModal from '../BaseActivityModal/index.js';
import {EventRegister} from 'react-native-event-listeners';

import {useBuzzmiActivities} from '../../../../appState/hooks/useBuzzmiActivities/index.js';

const HubAddActivityContent = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [loadingBuzzmi, setLoadingBuzzmi] = useState(false);
  const [loadingBuzzmiActivityPage, setLoadingBuzzmiActivityPage] =
    useState(false);
  const [currBuzzmiActPage, setCurrBuzzmiActPage] = useState('1');
  const [LoadMoreBuzzmi, setLoadMoreBuzzmi] = useState(true);

  const [loadingBase, setLoadingBase] = useState(false);
  const [loadingBaseActivityPage, setLoadingBaseActivityPage] = useState(false);
  const [currBaseActPage, setCurrBaseActPage] = useState('1');
  const [LoadMoreBase, setLoadMoreBase] = useState(true);

  const [loadingShared, setLoadingShared] = useState(false);
  const [loadingSharedActivityPage, setLoadingSharedActivityPage] =
    useState(false);
  const [currSharedActPage, setCurrSharedActPage] = useState('1');
  const [LoadMoreShared, setLoadMoreShared] = useState(true);

  const [baseActList, setBaseActList] = useState([]);
  const [buzzmiActList, setBuzzmiActList] = useState([]);
  const [sharedBaseActList, setSharedBaseActList] = useState([]);
  const [tags, setTags] = useState([]);
  const {
    baseActivitiesList,
    tagsList,
    getBaseActivitiesList,
    getSharedBaseActivitiesList,
    sharedbaseActivitiesList,
  } = useHubApp();
  const {getBuzzmiActivitiesList, buzzmiActivitiesList} = useBuzzmiActivities();

  const {language, userData} = useBuzzmiAuth();

  const [currSharedBaseActPage, setCurrSharedBaseActPage] = useState('1');
  const [activityModal, showActivityModal] = useState(false);
  const baseActiPageLimit = 10;
  const [currActivity, setCurrActivity] = useState({});

  const loadBuzzmiActivity = setLoading => {
    getBuzzmiActivitiesList(userData?.token, setLoading, {
      page: currBuzzmiActPage,
      limit: baseActiPageLimit + '',
      tag: tags,
    });
  };
  const loadBaseActivity = setLoading => {
    getBaseActivitiesList(userData?.token, setLoading, {
      page: currBaseActPage,
      limit: baseActiPageLimit + '',

      tag: tags,
    });
  };
  const loadSharedBaseActivity = setLoading => {
    getSharedBaseActivitiesList(userData?.token, setLoading, {
      page: currSharedBaseActPage,
      limit: baseActiPageLimit + '',
      tag: tags,
    });
  };

  useEffect(() => {
    if (userData) {
      if (currSharedBaseActPage == '1') {
        loadSharedBaseActivity(setLoadingShared);
      } else {
        loadSharedBaseActivity(setLoadingSharedActivityPage);
      }
    }
  }, [userData, currSharedBaseActPage, tags]);
  useEffect(() => {
    if (sharedbaseActivitiesList?.length > 0) {
      if (sharedbaseActivitiesList?.length < baseActiPageLimit) {
        setLoadMoreShared(false);
      }
      if (currSharedActPage == '1') {
        setSharedBaseActList([
          ...sharedbaseActivitiesList.map(e => ({isShare: true, ...e})),
        ]);
      } else {
        setSharedBaseActList([
          ...sharedBaseActList,
          ...sharedbaseActivitiesList.map(e => ({isShare: true, ...e})),
        ]);
      }
    }
  }, [sharedbaseActivitiesList]);

  useEffect(() => {
    if (userData) {
      if (currBaseActPage == '1') {
        loadBaseActivity(setLoadingBase);
      } else {
        loadBaseActivity(setLoadingBaseActivityPage);
      }
    }
  }, [userData, currBaseActPage, tags]);
  useEffect(() => {
    if (baseActivitiesList?.length > 0) {
      if (baseActivitiesList?.length < baseActiPageLimit) {
        console.log(
          'called load more false',
          baseActivitiesList?.length,
          baseActiPageLimit,
        );
        setLoadMoreBase(false);
      }
      if (currBaseActPage == '1') {
        setBaseActList([
          ...baseActivitiesList.map(e => ({isBase: true, ...e})),
        ]);
      } else {
        setBaseActList([
          ...baseActList,
          ...baseActivitiesList.map(e => ({isBase: true, ...e})),
        ]);
      }
    }
  }, [baseActivitiesList]);

  useEffect(() => {
    if (userData) {
      if (currBuzzmiActPage == '1') {
        loadBuzzmiActivity(setLoadingBuzzmi);
      } else {
        loadBuzzmiActivity(setLoadingBuzzmiActivityPage);
      }
    }
  }, [userData, currBuzzmiActPage, tags]);

  useEffect(() => {
    if (buzzmiActivitiesList?.length > 0) {
      if (buzzmiActivitiesList?.length < baseActiPageLimit) {
        setLoadMoreBuzzmi(false);
      }
      if (currBuzzmiActPage == '1') {
        setBuzzmiActList([
          ...buzzmiActivitiesList.map(e => ({isBuzzmi: true, ...e})),
        ]);
      } else {
        setBuzzmiActList([
          ...buzzmiActList,
          ...buzzmiActivitiesList.map(e => ({isBuzzmi: true, ...e})),
        ]);
      }
    }
  }, [buzzmiActivitiesList]);

  useEventEmitter('refresh-baseActivities', () => {
    console.log('this emitted refresh-baseActivities====>');
    // window.location.reload();
    setCurrBaseActPage('1');
    loadBaseActivity(setLoadingBase);
    loadSharedBaseActivity(setLoadingShared);
  });
  useEventEmitter('openBaseActivityModal', e => {
    console.log('this emitted openBaseActivity====>', e);
    setCurrActivity(e);
    showActivityModal(() => true);
  });

  const tabsLast = [
    {
      title: Strings.all,
      id: 0,
    },
    {
      title: Strings.my_task,
      id: 1,
    },
    {
      title: Strings.buzzmi_task,
      id: 2,
    },
  ];

  const handleLoadmore = () => {
    if (activeTab == 0) {
      setCurrBaseActPage(Number(currBaseActPage) + 1 + '');
      setCurrBuzzmiActPage(Number(currBuzzmiActPage) + 1 + '');
      setCurrSharedBaseActPage(Number(currSharedBaseActPage) + 1 + '');
    }
    if (activeTab == 1) {
      setCurrBaseActPage(Number(currBaseActPage) + 1 + '');
    }
    if (activeTab == 2) {
      setCurrBuzzmiActPage(Number(currBuzzmiActPage) + 1 + '');
    }
  };

  return (
    <div>
      <Loading
        loading={
          activeTab == '0'
            ? loadingBase || loadingBuzzmi || loadingShared
            : activeTab == '1'
            ? loadingBase
            : loadingBuzzmi
        }
        background="transparent"
        loaderColor={colors.PRIMARY_COLOR}
      />
      <BackBtnWithTitlePage title={Strings.add_task} btnText={Strings.back} />

      <div className="pb-2">
        <LinkTabs
          list={tabsLast}
          activeTabId={activeTab}
          onChange={setActiveTab}
        />
      </div>
      <div className="mb-3">
        <PlanSearch
          placholderExt={
            activeTab == 2
              ? Strings.buzzmi_task
              : activeTab == 1
              ? Strings.my_task
              : Strings.all
          }
        />
      </div>
      <FilterTags
        wrapMode
        tags={tagsList?.map(item => item.tagName)}
        setTags={setTags}
      />
      {/* {console.log('currentList[activeTab]', currentList[activeTab])} */}
      {activeTab == '0' ? (
        <InfoCardList
          onItemClick={e => EventRegister.emit('openBaseActivityModal', e)}
          infoList={[...baseActList, ...sharedBaseActList, ...buzzmiActList]}
          showLoadMore={LoadMoreBase || LoadMoreBuzzmi || LoadMoreShared}
          onLoadMoreClick={handleLoadmore}
          Pageloading={
            loadingBaseActivityPage ||
            loadingBuzzmiActivityPage ||
            loadingSharedActivityPage
          }
          loading={loadingBase || loadingBuzzmi || loadingShared}
          navTo={'HubCreateTaskv2'}
          taskType={'base'}
          isMainActivity={false}
        />
      ) : activeTab == '1' ? (
        <InfoCardList
          onItemClick={e => EventRegister.emit('openBaseActivityModal', e)}
          infoList={baseActList?.filter(i => i.createdById == userData?.id)}
          showLoadMore={LoadMoreBase}
          onLoadMoreClick={handleLoadmore}
          Pageloading={loadingBaseActivityPage}
          loading={loadingBase}
          navTo={'HubCreateTaskv2'}
          taskType={'base'}
          isMainActivity={false}
        />
      ) : activeTab == '2' ? (
        <InfoCardList
          onItemClick={e => EventRegister.emit('openBaseActivityModal', e)}
          infoList={buzzmiActList}
          showLoadMore={LoadMoreBuzzmi}
          onLoadMoreClick={handleLoadmore}
          Pageloading={loadingBuzzmiActivityPage}
          loading={loadingBuzzmi}
          navTo={'HubCreateTaskv2'}
          taskType={'base'}
          isMainActivity={false}
        />
      ) : null}

      <BaseActivityModal
        item={currActivity}
        activityModal={activityModal}
        showActivityModal={showActivityModal}
      />
    </div>
  );
};

const AddActivitiesv2 = () => {
  // return null;
  return (
    <MainLayoutWithAspectSide
      showTitleButton={false}
      showRankInfo={false}
      onMyTaskBtn={true}>
      <div className="lg:px-15 py-5 pb-0 pt-0 px:0 ">
        <HubAddActivityContent />
        {/* <div className="d-flex bottom-mobile-button justify-content-center mt-5 d-lg-none">
          <div className="_fw">
            <PrimaryLink to="/hub/add-activity">
              {Strings.add_my_own_task}
            </PrimaryLink>
          </div>
        </div> */}
      </div>
    </MainLayoutWithAspectSide>
  );
};

export default AddActivitiesv2;
