import {getLeaderBoardList} from '../../../network/api/app';
import {useQuery} from 'react-query';

export const useLeaderBoard = () => {
  const getLeaderBoards = async token => {
    try {
      const response = await getLeaderBoardList({token});
      // console.log("leaderboard response")
      // console.log(response);

      return response;
    } catch (e) {
      console.log('error => ', e.message);
    }
  };

  const useLeaderBoardList = token =>
    useQuery(['leaderBoardList', token], () => getLeaderBoards(token), {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      cacheTime: 0,
      refetchInterval: 0,
    });

  return {
    useLeaderBoardList,
  };
};
