import MainLayoutRightColumn from './mainLayoutRightColumn/MainLayoutRightColumn.jsx';
import cn from 'classnames';

const MainLayoutContentV2 = ({ children, rightColumnContent, iscenter = false }) => {
  return (
    <div
      className={
        iscenter ?
          cn('main-layout__row-v2 px-4', {
            'main-layout__row-v2 justify-center px-4': !rightColumnContent,
          }) :
          cn('main-layout__row-v2 px-4', {
            'main-layout__row-v2 justify-start px-4': !rightColumnContent,
          })}>
      <div
        className={cn('main-layout__mid_v2', {
          'main-layout__mid_v2_mod': !rightColumnContent,
        })}>
        {children}
      </div>
      {!!rightColumnContent && (
        <div className={"main-layout__right-column_v2_relative mx-2"}>{rightColumnContent}</div>
      )}
    </div>
  );
};

export default MainLayoutContentV2;
