import React, {useContext, useState, useEffect} from 'react';
import {TouchableOpacity, View, Alert} from 'react-native';
import {Spacer} from '../../../../res/spacer';
// style themes and components
import {
  GlobalFlex,
  MainTitle,
  SubContainer,
} from '../../../../res/globalStyles';
import {BackHeader} from '../../../../components/BackHeader';
import {POST_DATA} from '../../../../api_helper/ApiServices';
import {styles, Text, BorderContainer} from './style';
import {colors} from '../../../../res/colors';
// Third Party library
import {
  heightPercentageToDP as hp,
  widthPercentageToDP as wp,
} from 'react-native-responsive-screen';
import {POST_QR_CODE} from '../../../../api_helper/Api';
import {fonts} from '../../../../res/fonts';
import {Input} from '../../../../components/Input';
import {saveData} from '../../../../res/asyncStorageHelper';
import {getData} from '../../../../res/asyncStorageHelper.web';
import {useToast} from 'react-native-toast-notifications';
import {ShowError, ShowSuccess} from '../../../HOC/MessageWeb';
import {useAtom} from 'jotai';
import {_userDetailsAtom} from '../../../../appState/userState';
import {useEncryptedAtom} from "../../../../appState/hooks/useEncryptedAtom";

const Disable2FA = props => {
  const [flexOneWidth, setFlexOneWidth] = useState(0);
  const [code, setCode] = useState('');
  const [userDetails, setUserDetails] = useEncryptedAtom(_userDetailsAtom);

  const toast = useToast();

  const onDisableOTP = async () => {
    if (code == '') {
      ShowError('Please enter code', toast);
    } else {
      // getData('userDetails', userEData => {

      let postData = {
        // "userId": userEData.userId,
        userId: userDetails.userId,
        totp: code,
        isTFAEnabled: false,
      };
      // POST_DATA(POST_QR_CODE, userEData.token, postData, (data, flag) => {
      POST_DATA(POST_QR_CODE, userDetails.token, postData, (data, flag) => {
        if (flag == false && data?.otpValid) {
          // saveData('userDetails', Object.assign(userEData, { twoFactorAuthEnabled: data?.isTFAEnabled }))
          setUserDetails({
            ...userDetails,
            twoFactorAuthEnabled: data?.isTFAEnabled,
          });
          props.navigation.goBack();
          setTimeout(() => {
            ShowSuccess('2FA disabled successfully', toast);
          }, 350);
        } else {
          ShowError(data, toast);
        }
      });
      // })
    }
  };

  return (
    <GlobalFlex>
      <>
        <BackHeader
          textColor={{color: colors.DARK_GRAY}}
          isRightText={false}
          onBackPress={() => {
            props.navigation.goBack();
          }}
          is_center_text
          title={'Disable 2FA'}
        />
        <Spacer space={hp(1)} />
        <View style={{...styles.borderView, borderBottomWidth: hp(0.2)}} />
      </>
      <SubContainer style={[{width: wp(90), alignSelf: 'center'}]}>
        <Spacer space={hp(1)} />
        <Text style={[{fontSize: hp(3.5), alignSelf: 'center'}]}>
          {'To disable two-factor authentication, enter 2FA code below'}
        </Text>
        <Spacer space={hp(0.5)}></Spacer>
        <Input
          value={code}
          onChange={setCode}
          placeholder={'Code'}
          placeholderTextColor={colors.TRIPLET_PLACEHOLDER}
          style={{flex: 1, paddingBottom: hp(2)}}
          mainstyle={{flexDirection: 'row'}}
        />
        <Spacer space={hp(3.5)} />
        <TouchableOpacity
          style={{alignSelf: 'center'}}
          onPress={() => {
            onDisableOTP();
          }}>
          <Text style={{fontFamily: fonts.BOLD, fontSize: hp(3.5)}}>
            {'Disable'}
          </Text>
        </TouchableOpacity>
      </SubContainer>
    </GlobalFlex>
  );
};
export default Disable2FA;
