import {useMutation} from 'react-query';
import {postNotificationStatus} from '../../../network/api/app';

export const usePostNotificationStatus = () => {
  const {
    mutate: mutateNotificationStatus,
    isLoading: isNotificationStatusLoading,
    isError: isNotificationStatusError,
    data: postResponse,
    error: postErrorResponse,
  } = useMutation(postNotificationStatus);

  const postMyNotificationStatus = params => mutateNotificationStatus(params);

  return {
    postErrorResponse,
    isNotificationStatusError,
    isNotificationStatusLoading,
    postResponse,
    postMyNotificationStatus,
  };
};
