import React from 'react';
import FilterTags from './items/filterTags/FilterTags.jsx';
import FilterItems from './items/filterItems/FilterItems.jsx';
import SecondaryButton from '../../../../../components/reusable/btns/links/SecondaryLink.jsx';
import Strings from '../../../../../string_key/Strings.web.js';
import {colors} from '../../../../../res/colors.js';
import {Dots} from 'react-activity';
import 'react-activity/dist/library.css';

import {useLinkTo} from '@react-navigation/native';
import CustomSelect from '../../../../../components/reusable/form/select/customSelect/CustomSelect.jsx';
import {InboxStackIcon} from '@heroicons/react/24/outline';
//todo mockData
const mockTags = [
  'all',
  'presets',
  'workouts',
  'habits',
  'program',
  'challenges',
];

const FilterList = ({
  isStat = false,
  data,
  tagsData,
  setTags,
  showLoadMore,
  loading,
  onLoadMoreClick,
  statusSelect,
  statusFilter,
  setStatusFilter,
}) => {
  const linkTo = useLinkTo();

  return (
    <div>
      <FilterTags
        tags={tagsData?.map(item => item.tagName)}
        setTags={setTags}
      />
      {statusSelect?.length && (
        <div className=" flex w-full  justify-end mb-0 mb-sm-2 ">
          <div className="w-34 ">
            <CustomSelect
              selected={statusFilter}
              setSelected={setStatusFilter}
              options={statusSelect}
              className="select__secondary set-goal__type set-goal__cus"
            />
          </div>
        </div>
      )}
      {/* {console.log('tagsData', tagsData)} */}
      {data.length > 0 ? (
        <>
          {data.map((item, index) => (
            <FilterItems
              item={item}
              isStat={isStat}
              isFirst={index === 0}
              key={index + 'FilterList'}
            />
          ))}
          {showLoadMore ? (
            <>
              {!loading && (
                <div
                  className="text-alt-primary cursor-pointer pb-2"
                  style={{
                    display: 'flex',
                    justifyContent: 'center', // Horizontally center content
                    alignItems: 'center', // Vertically center content
                    width: '100%', // Set the width to 100% if needed
                    // Add any other inline styles you want
                  }}
                  onClick={onLoadMoreClick}>
                  Load More
                </div>
              )}
              {loading && (
                <div
                  className="pb-2"
                  style={{
                    display: 'flex',
                    justifyContent: 'center', // Horizontally center content
                    alignItems: 'center', // Vertically center content
                    width: '100%', // Set the width to 100% if needed
                    // Add any other inline styles you want
                  }}>
                  <Dots color={colors.PRIMARY_COLOR} />
                </div>
              )}
            </>
          ) : null}
        </>
      ) : (
        <div className="d-flex justify-content-between align-items-center mb-2 mb-md-3">
          {!loading && (
            <div
              className={
                'flex justify-center flex-col	items-center w-full h-[120px]'
              }>
              <InboxStackIcon className={'h-10 w-10'} />
              <h4 className="h4 mt-2">{'No Records Found'}</h4>
            </div>
          )}
          <div className="flex-shrink-0 custom-brn-v2">
            <button
              type="button"
              className="custom-btn relative inline-flex items-center rounded-md bg-rose-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pink-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600"
              style={{backgroundColor: '#eb4dac'}}
              onClick={() => {
                linkTo('/hub/v2/add-activities');
              }}>
              {Strings.add_activity}
            </button>
          </div>
          {/* <SecondaryButton
            to={{screen: 'AddActivitiesv2'}}
            className="py-21 px-15 d-none d-lg-inline-flex">
            {Strings.add_activity}
          </SecondaryButton> */}
        </div>
      )}
    </div>
  );
};

export default FilterList;
