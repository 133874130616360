import React, { useEffect, useState } from 'react';
import MainLayoutContentV2 from '../../../../../components/layouts/mainLayout/mainLayoutContentV2/MainLayoutContentV2';
import MainLayoutV2 from '../../../../../components/layouts/mainLayout/MainLayoutV2';
import ResizeComponent from '../../../../../components/reusable/assets/resizeComponent/ResizeComponent';
import BackBtnWithTitlePage from '../../../../../components/reusable/btns/backBtn/BackBtnWithTitlePage';
import FilterTags from '../../../Hub/Items/filterList/items/filterTags/FilterTags';
import ProfileContentItem from '../profileContentItem/ProfileContentItem';

// const mockTags = [
//   {
//     title: 'Yoga',
//     type: 'sky-blue',
//   },
//   {
//     title: 'Meditation',
//     type: 'pink',
//   },
//   {
//     title: 'Workout',
//     type: 'light-blue',
//   },
//   {
//     title: 'Habits',
//     type: 'green',
//   },
//   {
//     title: 'Program',
//     type: 'blue',
//   },
//   {
//     title: 'Challenges',
//     type: 'yellow',
//   },
//   {
//     title: 'Fitness',
//     type: 'green',
//   },
// ];
const mockTags = [
  'all',
  'presets',
  'workouts',
  'habits',
  'program',
  'challenges',
];
const mockContent = [
  {
    id: 1,
    image: {
      src: '/images/pages/profile/moc/content/1.png',
      alt: 'image',
    },
    isLikes: true,
    progress: 32,
    title: "Beginner's Strength",
    rating: {
      value: 4.5,
      count: 12,
    },
    views: 100,
    time: '60 min',
  },
  {
    id: 1,
    image: {
      src: '/images/pages/profile/moc/content/2.jpg',
      alt: 'image',
    },
    isLikes: false,
    progress: 5,
    title: "Beginner's Strength",
    rating: {
      value: 4.5,
      count: 12,
    },
    views: 100,
    time: '60 min',
  },
  {
    id: 1,
    image: {
      src: '/images/pages/profile/moc/content/3.jpg',
      alt: 'image',
    },
    isLikes: true,
    progress: 2.5,
    title: "Beginner's Strength",
    rating: {
      value: 4.5,
      count: 12,
    },
    views: 100,
    time: '60 min',
  },
  {
    id: 1,
    image: {
      src: '/images/pages/profile/moc/content/4.png',
      alt: 'image',
    },
    isLikes: false,
    progress: 0,
    title: "Beginner's Strength",
    rating: {
      value: 4.5,
      count: 12,
    },
    views: 100,
    time: '60 min',
  },
];

const VisiorMembers = () => {
  const [content, setContent] = useState([]);
  const getContent = tags => {
    setContent(tags);
  };

  useEffect(() => {
    console.log('content', content);
  }, [content]);

  return (
    <MainLayoutV2 centerSpace={true}>
      <MainLayoutContentV2 iscenter>
        <ResizeComponent desktopComponent={
          <div>
            <BackBtnWithTitlePage
              title={"Products"}
              className="mt-0"
              btnText="Back"
            />
            <FilterTags
              tags={mockTags}
              wrapMode
              callbackForSelectedTags={getContent}
            />
            <ul className="profile-item__list">
              {mockContent.map((item, index) => (
                <li key={index}>
                  <ProfileContentItem item={item} />
                </li>
              ))}
            </ul>
          </div>
        }
          mobileComponent={
            <div>
              <BackBtnWithTitlePage
                title={"Products"}
                className="mt-0"
                btnText="Back"
              />
              <FilterTags
                tags={mockTags}
                wrapMode
                callbackForSelectedTags={getContent}
              />
              <ul className="profile-item__list">
                {mockContent.map((item, index) => (
                  <li key={index}>
                    <ProfileContentItem item={item} />
                  </li>
                ))}
              </ul>
            </div>
          }
        />
      </MainLayoutContentV2>
    </MainLayoutV2>
  );
};

export default VisiorMembers;
