import { useEffect } from "react"

const useOnClickOutside = (ref, showMenu, setShowMenu) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showMenu && ref.current && !ref.current.contains(event.target)) {
        setShowMenu(false)
      }
    }

    if (showMenu) {
      document.addEventListener("click", handleClickOutside)
      document.body.classList.add("no-scroll")
    } else {
      document.removeEventListener("click", handleClickOutside)
      document.body.classList.remove("no-scroll")
    }

    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [ref, showMenu])
}

export default useOnClickOutside
