import React, { useEffect, useState } from 'react';
import mocData from './mocData.js';
import { useFormValidator } from '../../../../form-validator/hooks/index.js';
import ValidationSchema from '../../../../form-validator/ValidationSchema.js';
import HubTaskForm from '../Items/form/hubTaskForm/HubTaskForm.jsx';
import BackBtnWithTitlePage from '../../../../components/reusable/btns/backBtn/BackBtnWithTitlePage.jsx';
import MainLayout from '../../../../components/layouts/mainLayout/MainLayout.jsx';
import { useHubApp } from '../../../../appState/hooks/useHubApp';
import { useBuzzmiAuth } from '../../../../appState/hooks/useBuzzmiAuth/index.web.js';
import Strings from '../../../../string_key/Strings.web.js';
import Description from '../../../../components/reusable/assets/description/Description.jsx';
import HubMedia from '../Items/hubMedia/HubMedia.jsx';
import { useNavigation } from '@react-navigation/core';
import { EventRegister } from 'react-native-event-listeners';
import moment from 'moment';

const validationSchema = new ValidationSchema({
  when: [{ rule: 'required' }],
  note: [
    { rule: 'required' },
    { rule: 'minLength', value: 3 },
    { rule: 'maxLength', value: 40 },
  ],
  tasks: [{ rule: 'required' }, { rule: 'minArray', value: 1 }],
  // photos: [{ rule: "required" }, { rule: "minObjects", value: 1 }],
  // tags: [{rule: 'required'}, {rule: 'minArray', value: 1}],
});

const HubCreateTask = props => {
  const data = mocData;
  const [error, setError] = useState(null);

  const {
    postCreatePlanActivity,
    CreatePlanActivityResponse,
    isCreatePlanActivityLoading,
    CreatePlanActivityErrorResponse,
    getWalletData,
    getRankInfoDetail,
    getNextRankInfoDetail,
  } = useHubApp();
  const { userData } = useBuzzmiAuth();
  const { navigate } = useNavigation();
  const d = props?.route?.params?.item
    ? props?.route?.params?.item
    : props?.route?.params;
  const baseData = { ...d };
  useEffect(() => {
    if (CreatePlanActivityResponse) {
      navigate('HubTasks');

      EventRegister.emit('refresh-planActivities');
      getWalletData(userData?.token),
        getRankInfoDetail(userData?.id, userData?.token);
      getNextRankInfoDetail(userData?.id, userData?.token);
    }
  }, [CreatePlanActivityResponse, CreatePlanActivityErrorResponse]);

  const sendRequest = async formData => {
    try {
      let param_data = Object.assign({
        title: baseData?.title,
        description: baseData?.description,
        imageURL: baseData?.image,
        timing: JSON.stringify(formData?.specificTime || formData?.when),
        preset: false,
        tags: [],
        baseTaskId: baseData?.id
      });

      baseData?.mediaUrl &&
        Object.assign(param_data, {
          mediaUrl: baseData?.mediaUrl,
        });

      baseData?.descriptionMedia &&
        Object.assign(param_data, {
          descriptionMedia: Object.values(baseData?.descriptionMedia),
        });
      baseData?.descriptionAudio &&
        Object.assign(param_data, {
          descriptionAudio: baseData?.descriptionAudio,
        });
      baseData?.descriptionVideo &&
        Object.assign(param_data, {
          descriptionVideo: baseData?.descriptionVideo,
        });

      formData?.tags?.length > 0 &&
        Object.assign(param_data, {
          tags: formData?.tags?.map(item => item.id),
        });
      formData?.images &&
        Object.assign(param_data, {
          progressImages: Object.values(formData?.images),
        });
      formData?.note && Object.assign(param_data, { note: formData?.note });
      formData?.reminder &&
        Object.assign(param_data, {
          reminderTime:
            formData?.reminder?.hours +
            ':' +
            formData?.reminder?.minutes +
            formData?.reminder?.ampm,
        });

      formData?.repeat &&
        Object.assign(param_data, {
          repeatIntervalCategory: formData?.repeat?.type,
        });
      formData?.repeat &&
        Object.assign(param_data, {
          repeatInterval: [formData?.repeat?.every],
        });
      formData?.tasks &&
        Object.assign(param_data, {
          checkList: formData?.tasks,
        });
      formData?.goal &&
        Object.assign(param_data, {
          goal: formData?.goal?.number + ' ' + formData?.goal?.type,
        });
      Object.assign(param_data, {
        startDate: moment(new Date()).format('YYYY-MM-DD'),
      });
      //ToDO send start date and end date
      //   formData?.startDate &&
      //   Object.assign(param_data, {
      //     startDate: formData?.startDate
      //   });

      formData?.endDate &&
        Object.assign(param_data, {
          endDate: moment(formData?.repeat?.endDate).format('YYYY-MM-DD'),
        });

      // console.log('param_data ===> ', JSON.stringify(param_data));
      // console.log('param_data ===> ', formData?.repeat);

      postCreatePlanActivity(
        Object.assign(param_data, {
          params: { isAuth: true, token: userData?.token },
        }),
      );
    } catch (e) {
      setError(e);
    }
  };

  const { handleSubmit, setValue, values, isValid } = useFormValidator(
    validationSchema,
    sendRequest,
    {
      defaultValues: {
        note: null,
      },
    },
  );

  return (
    <MainLayout>
      <div className="main-layout__single-container">
        <BackBtnWithTitlePage
          title={baseData?.title}
          lastItemLink={{
            href: 'HubAddTask',
            title: 'Edit Activity',
            params: { item: baseData, isEdit: true, fromPath: 'HubCreateTask' },
          }}
        />

        <div className="mb-4">
          <Description item={baseData} />
        </div>

        <HubMedia
          galleryList={baseData?.descriptionMedia?.values}
          videoSrcLink={baseData?.descriptionVideo}
          audioSrcLink={baseData?.descriptionAudio}
        />
        <HubTaskForm
          values={values}
          error={error}
          handleSubmit={handleSubmit}
          setValue={setValue}
          data={data}
          btnTitle={Strings.add_task}
          isValid={isValid}
          item={baseData}
        />
      </div>
    </MainLayout>
  );
};

export default HubCreateTask;
