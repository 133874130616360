import { useAtom } from 'jotai';
import { userNextRankAtom } from '../hubState';
import { getNextRankInfo } from '../../../network/api/app';

export const useNextRankInfo = () => {
  const [nextRankInfo, setNextRankInfo] = useAtom(userNextRankAtom);

  const getNextRankInfoDetail = async (id, token) => {
    try {
      const response = await getNextRankInfo(id)({ token });
      setNextRankInfo(response);
    } catch (e) {
      console.log('error => ', e.message);
    }
  };

  return {
    nextRankInfo,
    getNextRankInfoDetail
  };
};
