import React, { useState } from 'react';
import Select from "react-select";
import { useBuzzmiAuth } from '../../../../../appState/hooks/useBuzzmiAuth/index.web';

const options = [
  { label: "English", value: "en" },
  { label: "Spain", value: "es" },
  { label: "Italy", value: "it" },
  { label: "Slovak", value: "sk" },
];

export const DropdownIndicator = () => {
  return (
    <i className="fa-solid fa-chevron-down select__arrow text-secondary" />
  );
};

const MainLayoutFooterSelect = () => {
  const { updateLanguage, language } = useBuzzmiAuth();
  const [selected, setSelected] = useState(options.filter(o => o.value == language) || { label: "English", value: "en" });

  const handleSelect = (selectedOption) => {
    //TODO Handle change language
    updateLanguage(selectedOption?.value)
    setSelected(selectedOption);
  }

  return (
    <Select
      components={{ DropdownIndicator }}
      options={options}
      defaultValue={selected}
      onChange={handleSelect}
      hasSelectAll={false}
      labelledBy="Select"
      disableSearch={true}
      classNamePrefix='footer-main'
    />
  );
};

export default MainLayoutFooterSelect;