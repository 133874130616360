import React from 'react';
import GradientBorder from '../../../../../../../components/reusable/assets/gradientBorder/GradientBorder';

const ProfileStoriesIcon = ({item}) => {
  return (
    <button>
      <GradientBorder>
        <img
          className="profile-stories__btn"
          src={item?.icon?.src}
          alt={item?.icon?.alt}
        />
      </GradientBorder>
    </button>
  );
};

export default ProfileStoriesIcon;
