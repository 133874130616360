import React from "react"
import { Link } from "react-router-dom"

const ProfilePremiumContentSocial = ({ socialMedia }) => {
  return (
    <div className="profile-content__social profile-content__block">
      <div className="profile-content__block-title">Social media</div>
      <ul>
        {socialMedia.map((item) => (
          <li key={item.id}>
            <Link to={item.link}>
              <img src={item.icon.src} alt={item.icon.alt} />
              <span>{item.value}</span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ProfilePremiumContentSocial
