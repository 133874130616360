import React from 'react';
import PrimaryButton from '../PrimaryButton.jsx';
import classNames from 'classnames';

const SubmitButton = ({
  isValid,
  title = 'Continue',
  postButton = false,
  props,
}) => {
  return (
    <PrimaryButton
      className={
        // classNames({button_inactive: !isValid}) +
        // ` btn-primary btn px-3 h-2 rounded-lg text-base ${
        //   isValid &&
        //   'hover:bg-fuchsia-600 border-fuchsia-600 text-black hover:text-white'
        // }`

        `
           w-full rounded-full 
           ${isValid && 'bg-pink-500'}
          ${
            !isValid &&
            // 'bg-[#C0BACB] !text-pink-200 hover:!text-pink-200  hover:bg-[#C0BACB]  border-2 border-pink-200 '
            ' !text-pink-200 hover:!text-pink-200    border-2 border-pink-200 '
          }`
      }
      type="submit"
      {...props}>
      {title}
    </PrimaryButton>
  );
};

export default SubmitButton;
// className="flex items-center justify-center rounded-md bg-pink-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pink-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600"
// style={{
//   backgroundColor: '#eb4dac',
//   width: '100%',
// }}
