import React, {useState} from 'react';
import LinkTabs from '../../../../../../components/reusable/assets/linkTabs/LinkTabs';
import ProfilePremiumContentEpisodes from './items/profilePremiumContentEpisodes/ProfilePremiumContentEpisodes';
import ProfilePremiumContentResources from './items/profilePremiumContentResources/ProfilePremiumContentResources';

const tabs = [
  {
    id: 'Episodes',
    title: 'Episodes',
  },
  {
    id: 'Resources',
    title: 'Resources',
  },
];

const ProfilePremiumContentMid = () => {
  const [activeTabId, setActiveTabId] = useState('Episodes');

  const onChange = id => setActiveTabId(id);

  return (
    <div className="mt-4 mt-md-9">
      <LinkTabs activeTabId={activeTabId} list={tabs} onChange={onChange} />
      {activeTabId === 'Episodes' ? (
        <ProfilePremiumContentEpisodes />
      ) : (
        <ProfilePremiumContentResources />
      )}
    </div>
  );
};

export default ProfilePremiumContentMid;
