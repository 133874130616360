import React from "react"

const ViewMoreBtn = ({ text, showAll, toggleShowAll }) => {
  return (
    <button onClick={toggleShowAll} className="text-r text-alt-primary d-flex flex-row align-items-center ms-5">
      {showAll ? "Close" : text}
      <img
        src="/images/post/arrow-show-more.svg"
        alt="arrow"
        className={`arrow-show-more ${showAll ? "reverse" : ""}`}
      />
    </button>
  )
}

export default ViewMoreBtn
