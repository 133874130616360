import React from 'react';

import {parseImageSrc} from '../../../../../../components/reusable/parseImageSrc';
import Box from '../../../../../../components/reusable/cards/box/Box';
import {useHubApp} from '../../../../../../appState/hooks/useHubApp/index.web';
import {useBuzzmiAuth} from '../../../../../../appState/hooks/useBuzzmiAuth/index.web';
import Loading from 'react-fullscreen-loading';
import {colors} from '../../../../../../res/colors';

const EditProfileChoseAvatar = ({setValue, value, paddingMode}) => {
  const {onUploadFiles, isFileUploadLoading} = useHubApp();
  const {userData} = useBuzzmiAuth();

  const handleFileChange = e => {
    setValue(e.target.files[0]);
    const fd = new FormData();
    fd.append('file', e.target.files[0]);
    onUploadFiles(
      Object.assign(fd, {
        params: {
          isAuth: true,
          token: userData?.token,
          type: 'avatar',
        },
      }),
    );
  };

  return (
    <Box className="profile-avatar mb-3" paddingMode={paddingMode}>
      <Loading
        loading={isFileUploadLoading}
        background="transparent"
        loaderColor={colors.PRIMARY_COLOR}
      />
      <label>
        <div className="profile-avatar__img-wrapper">
          <img
            src={parseImageSrc(value)}
            alt="avatar"
            className="profile-avatar__img"
          />
        </div>
        <input
          onChange={handleFileChange}
          type="file"
          className="d-none"
          accept=".jpeg,.jpg,.bmp,.png,.gif,.tiff,.webp"
        />
      </label>
      <div>
        <h4 className="h4 profile-avatar__title">Choose cover image</h4>
        <div className="text-r">
          File type Image: JPEG, BMP, PNG, GIF, or TIFF
        </div>
      </div>
    </Box>
  );
};

export default EditProfileChoseAvatar;
