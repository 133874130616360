import React from 'react';
import Input from '../../../../../../../../components/reusable/form/items/inputs/Input';
import ProfilePhoneSelect from '../../../editProfileAccountInformation/items/profilePhoneSelect/ProfilePhoneSelect';

const BusinessURL = ({register}) => (
  <Input name="business-url" register={register} placeholder="URL" />
);
const BusinessEmail = ({register}) => (
  <Input
    name="business-email"
    type="email"
    register={register}
    placeholder="Email"
  />
);
const BusinessWebsite = ({register}) => (
  <Input name="business-website" register={register} placeholder="Website" />
);
const BusinessInstagram = ({register}) => (
  <Input
    name="business-instagram"
    register={register}
    placeholder="Instagram"
  />
);
const BusinessFacebook = ({register}) => (
  <Input name="business-facebook" register={register} placeholder="Facebook" />
);
const BusinessLinkedIn = ({register}) => (
  <Input name="business-linkedIn" register={register} placeholder="LinkedIn" />
);
const BusinessPhone = ({register}) => (
  <ProfilePhoneSelect setIsValid={() => {}} />
);

const EditProfileInputController = ({componentName, register}) => {
  const contentMap = {
    url: <BusinessURL register={register} />,
    email: <BusinessEmail register={register} />,
    website: <BusinessWebsite register={register} />,
    instagram: <BusinessInstagram register={register} />,
    facebook: <BusinessFacebook register={register} />,
    linkedIn: <BusinessLinkedIn register={register} />,
    phone: <BusinessPhone register={register} />,
  };

  return !componentName || !contentMap[componentName]
    ? null
    : contentMap[componentName];
};

export default EditProfileInputController;
