import {useMutation} from 'react-query';
import {patchPlannedActivityComplete} from '../../../network/api/app';
import {useBuzzmiAuth} from '../useBuzzmiAuth/index.web';
import {ShowError, ShowSuccess} from '../../../container/HOC/MessageWeb';
import {useToast} from 'react-native-toast-notifications';
import {EventRegister} from 'react-native-event-listeners';
import {useHubApp} from '../useHubApp/index.web';
import {useState} from 'react';
export const useCompletePlannedActivity = () => {
  const {userData, updateUserData} = useBuzzmiAuth();
  const toast = useToast();
  const {getNextRankInfoDetail, getWalletData, getRankInfoDetail} = useHubApp();
  const [activityComplete, setActivityComplete] = useState(false);
  const {
    mutate: mutateCompletePlannedActivity,
    isLoading: isCompletePlannedActivityLoading,
    isError: isCompletePlannedActivityError,
    data: CompletePlannedActivityResponse,
    error: CompletePlannedActivityErrorResponse,
  } = useMutation({
    mutationFn: param => {
      console.log('param?.body', param);
      console.log(userData);
      if (param?.body?.isCompleted) setActivityComplete(true);
      return patchPlannedActivityComplete(param.id)({
        params: {isAuth: true, token: userData?.token},
        body: {...param?.body},
      });
    },
    onSuccess: data => {
      EventRegister.emit('refresh-planActivities');
      getWalletData(userData?.token),
        getRankInfoDetail(userData?.id, userData?.token);
      getNextRankInfoDetail(userData?.id, userData?.token);
      ShowSuccess(
        `Activity ${activityComplete ? 'completed' : 'updated'} successfully`,
        toast,
      );
    },
    onError: data => {
      console.log('edited quick error ==> ', data);
      ShowError(
        `Activity ${activityComplete ? 'completed' : 'updated'} failed`,
        toast,
      );
    },
  });

  const patchCompletePlannedActivity = async (id, body) =>
    mutateCompletePlannedActivity({id, body});

  return {
    isCompletePlannedActivityLoading,
    isCompletePlannedActivityError,
    CompletePlannedActivityResponse,
    CompletePlannedActivityErrorResponse,
    patchCompletePlannedActivity,
  };
};
