import React, {useEffect, useState} from 'react';

const Box = ({
  mx_auto = true,
  paddingMode,
  topComponent,
  children,
  bottomComponent,
  className,
  style,
  onPress = () => {},
}) => {
  const [padding, setPadding] = useState('');

  useEffect(() => {
    if (paddingMode === 'rank') setPadding('box_rank');
    else if (paddingMode === 'big') setPadding('box_big');
    else if (paddingMode === 'mid') setPadding('box_mid');
    else if (paddingMode === 'small') setPadding('box_small');
  }, [paddingMode]);

  return (
    <div
      className={`box ${padding} ${className} ${
        paddingMode !== 'none' && (mx_auto ? 'mx-auto' : '')
      }`}
      onClick={onPress} style={style}>
      {topComponent && <div className={'box__top border-b-[1px]'}>{topComponent}</div>}

      {children}

      {bottomComponent && (
        <div className={'box__bottom'}>{bottomComponent}</div>
      )}
    </div>
  );
};

export default Box;
