import React from 'react';

const MediaWrapper = ({
  iconSrc = '/images/assets/forms/image.svg',
  onClick,
  locked,
  bordered = true,
  ...props
}) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className={`media-wrapper ${!bordered ? ' border-0' : ''}`}
      disabled={locked}
      {...props}>
      <img src={iconSrc} alt="media-icon" className="media-wrapper__icon" />
    </button>
  );
};

export default MediaWrapper;
