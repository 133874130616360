import { ShoppingBagIcon, ChatBubbleBottomCenterIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useProfileApp } from '../../../../../../appState/hooks';
import { useBuzzmiAuth } from '../../../../../../appState/hooks/useBuzzmiAuth/index.web';
import { useProfileInfo } from '../../../../../../appState/hooks/useProfileInfo';
import CustomLink from '../../../../../../components/reusable/btns/links/CustomLink';
import { useEventEmitter } from '../../../../../../hooks/useEventEmitter';
import { closeModal, openModal } from '../../../../../../store/slices/modalSlice/modalSlice';

const UserBtnsRowMobile = () => {
  const { profileInfo } = useProfileInfo();
  const { postFollowUser, FollowUserResponse } = useProfileApp();
  const { userData } = useBuzzmiAuth();
  const [isFollowings, setIsFollowing] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (FollowUserResponse?.message == "success") {
      setIsFollowing(true)
    }
  }, [FollowUserResponse])


  useEventEmitter('is_follow_unfollow', data => {
    setIsFollowing(data == "follow" ? false : true)
  });

  const onUnderDevelopment = () => {
    dispatch(
      openModal({
        modalName: 'renderAlert',
        modalType: 'alert',

        modalProps: {
          nameAlert: 'constructionAlert',
          propsAlert: {
            closeModal: () => { dispatch(closeModal()) },
          },
        },
      }),
    );
  };

  return (
    <div className="profile-user__btns-row_m">
      <div>
        <CustomLink onClick={() => { (profileInfo?.isFollowing || isFollowings) ? console.log("call") : postFollowUser(profileInfo?.id, userData.token) }} className="alt-btn button_s text-[#e536af]">
          {(profileInfo?.isFollowing || isFollowings) ? "Following" : "Follow"}
        </CustomLink>
        <CustomLink onClick={() => { onUnderDevelopment() }} className="alt-btn button_s text-[#e536af]">
          Subscribe
        </CustomLink>
        <CustomLink onClick={() => { onUnderDevelopment() }} className="alt-btn button_s w-100 text-[#e536af]">
          <ChatBubbleBottomCenterIcon className="h-5 w-5" style={{ color: '#e536af' }} aria-hidden="true" />
        </CustomLink>
        <CustomLink to="/profile/profile-shop" className="alt-btn button_s w-100 text-[#e536af]">
          <ShoppingBagIcon className="h-5 w-5" style={{ color: '#e536af' }} aria-hidden="true" />
        </CustomLink>
      </div>
    </div>
  );
};

export default UserBtnsRowMobile;
