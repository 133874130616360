import React, { useEffect, useState } from 'react';
import UserProfileTags from '../../../ProfileUser/items/userProfileTags/UserProfileTags';
import UserProfileSocial from '../../../ProfileUser/items/userProfileSocial/UserProfileSocial';
import ProfileText from '../../profileText/ProfileText';
import UserSkills from '../../../ProfileUser/items/userSkills/UserSkills';
import { useBuzzmiAuth } from '../../../../../../appState/hooks/useBuzzmiAuth/index.web';
import { useProfileInfo } from '../../../../../../appState/hooks/useProfileInfo';
import Box from '../../../../../../components/reusable/cards/box/Box';
import Avatar from '../../../../../../components/reusable/assets/avatar/Avatar';
import ProfileBio from '../../ProfileBio';
import CustomLink from '../../../../../../components/reusable/btns/links/CustomLink';
import image1 from '../../../../../../assets/ic_grad_pencil.png';
import ProfileBioV2 from '../../ProfileBioV2';
import EditProfileModal from '../../../ProfileMain/componentsV2/EditProfileModal';
const mockLinks = [
  {
    title: '@janedoe',
    type: 'facebook',
    link: 'https://facebook.com',
  },
  {
    title: 'Jane Doe',
    type: 'instagram',
    link: 'https://instagram.com',
  },
  {
    title: 'Jane Doe',
    type: 'linkedin',
    link: 'https://linkedin.com',
  },
  {
    title: 'Jane Doe',
    type: 'youtube',
    link: 'https://youtube.com',
  },
];

const ProfileAboutDesktop = ({ isOtherProfile }) => {
  const { userData } = useBuzzmiAuth();
  const { profileInfo } = useProfileInfo();
  const [openEditProfileModal, setOpenEditProfileModal] = useState(false);

  return (
    <>
      <Box paddingMode="none" className="profile-top__desktop-wrap">
        <Avatar />
        <ProfileBioV2 />
        <button className="" onClick={() => setOpenEditProfileModal(true)}>
          <img src={image1} className="h-5 w-5" />
        </button>
      </Box>
      <div className="h-3" />
      <UserProfileTags />
      <div className="mt-3 link-wrqapper">
        <ProfileText
          text={isOtherProfile ? profileInfo?.bio || '-' : userData?.bio || '-'}
          title="Bio"
        />
      </div>
      <div className="mt-3">
        <UserSkills
          skill={
            isOtherProfile ? profileInfo?.skill || [] : userData?.skill || []
          }
        />
      </div>
      <div className="mt-3">
        <ProfileText
          text={
            isOtherProfile
              ? profileInfo?.college || '-'
              : userData?.college || '-'
          }
          title="Education"
        />
      </div>
      <div className="mt-3">
        <ProfileText
          text={
            isOtherProfile
              ? profileInfo?.workOrganization || '-'
              : userData?.workOrganization || '-'
          }
          title="Company"
        />
      </div>
      <UserProfileSocial links={mockLinks} />
      <EditProfileModal
        open={openEditProfileModal}
        setOpen={setOpenEditProfileModal}
      />
    </>
  );
};

export default ProfileAboutDesktop;
