import Strings from "../../../../string_key/Strings.web";


const LayoutConstants = () => {
  const layoutData = [
    {
      title: Strings.boaring_vocabulary_title,
      description: Strings.boaring_vocabulary_description,
      image: '/images/assets/intro/1.png',
    },
    {
      description: Strings.become_a_part_of_community_description,
      image: '/images/assets/intro/2.png',
    },
    {
      title: Strings.make_money_with_buzzmi_title,
      description: Strings.make_money_with_buzzmi_description,
      image: '/images/assets/intro/3.png',
    },
    {
      title: Strings.ready_for_a_challenge_title,
      description: Strings.ready_for_a_challenge_description,
      image: '/images/assets/intro/4.png',
    },
    {
      title: Strings.fair_pay_for_fair_work_title,
      description: Strings.fair_pay_for_fair_work_description,
      image: '/images/assets/intro/5.png',
    },
  ]
  return { layoutData }
}

export default LayoutConstants
