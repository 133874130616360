// noinspection ES6CheckImport

import {StyleSheet} from 'react-native';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import {colors} from '../../../../res/colors';
import styled from 'styled-components/native';
import {fonts} from '../../../../res/fonts';

const TopTabText = styled.Text`
  color: ${colors.BLACK};
  font-size: 5rem;
  text-align: center;
  margin-horizontal: 2;
  font-family: ${fonts.BOLD};
`;

export {TopTabText};

export default StyleSheet.create({
  flex_wrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'center',
  },
  tab_wrapper: {
    paddingVertical: '0.5rem',
    marginLeft: '3rem',

    alignSelf: 'center',
  },
  profile_ic: {
    width: '3rem',
    height: '3rem',
    borderRadius: '3rem',
  },
  topTabWrapper: {
    flexDirection: 'row',
    paddingLeft: '5rem',
  },
});
