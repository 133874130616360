import React, {useEffect, useState} from 'react';
import Box from '../../../../../../components/reusable/cards/box/Box.jsx';
import Input from '../../../../../../components/reusable/form/items/inputs/Input.jsx';
import Badge from '../../../../../../components/reusable/badge/Badge.jsx';
import AssetsCards from '../../../../../../components/reusable/cards/assetsCards/AssetsCards.jsx';
import {useHubApp} from '../../../../../../appState/hooks/useHubApp/index.web.js';
import {useBuzzmiAuth} from '../../../../../../appState/hooks/useBuzzmiAuth/index.web.js';
import cn from 'classnames';
import {Dots} from 'react-activity';
import {colors} from '../../../../../../res/colors.js';

const AddName = ({
  title,
  register,
  placeholder,
  name,
  setValue,
  value,
  handleOnClickSuggestedItem,
  // initPopularNames = [],
}) => {
  const {userData} = useBuzzmiAuth();
  const {
    popularNames,
    popularNamesListLoader,
    getpopularNamesList,
    suggestedTasks,
    getMySuggestedTasks,
    setUploadedAudioLink,
  } = useHubApp();

  const [suggetedLoader, setSuggetedLoader] = useState(false);

  // const [popularNames, setPopularNames] = useState(initPopularNames);
  // const [popularNames, setPopularNames] = useState([
  //   {
  //     id: '0cf64f7f-b180-4657-b1c6-901e9c44985d',
  //     tagName: 'Meditate',
  //     user: '23a726cb-5411-497f-9d92-227130ae5aca',
  //   },
  //   {
  //     id: '18ecfc5d-106e-47f1-93f4-010642720229',
  //     tagName: 'Running',
  //     user: null,
  //   },
  //   {
  //     id: '19c39034-69ab-498c-8ca5-90607ee17672',
  //     tagName: 'Walk',
  //     user: '23a726cb-5411-497f-9d92-227130ae5aca',
  //   },
  //   {
  //     id: '19c39034-69ab-498c-8ca5-90607ee17672',
  //     tagName: 'Sleep',
  //     user: '23a726cb-5411-497f-9d92-227130ae5aca',
  //   },
  // ]);

  const [selectedNames, setSelectedNames] = useState('');
  const [suggestTasks, setSuggestTasks] = useState([]);

  const onBadgeClick = name => {
    if (selectedNames.includes(name)) {
      setSelectedNames('');
      setValue('name', '');
    } else {
      setSelectedNames(name);
      setValue('name', name);
    }
  };
  useEffect(() => {
    getpopularNamesList(userData.token);
  }, []);

  useEffect(() => {
    if (!!value) {
      //TODO get suggested tasks
      getMySuggestedTasks(userData.token, setSuggetedLoader, {
        query: value,
      });

      // const getedSuggestTasks = [
      //   {
      //     id: 1,
      //     title: 'Task 1',
      //     link: 'https://picsum.photos/200/300',
      //     img: 'https://picsum.photos/200/300',
      //   },
      //   {
      //     id: 2,
      //     title: 'Task 2',
      //     link: 'https://picsum.photos/200/300',
      //     img: 'https://picsum.photos/200/300',
      //   },
      //   {
      //     id: 3,
      //     title: 'Task 3',
      //     link: 'https://picsum.photos/200/300',
      //     img: 'https://picsum.photos/200/300',
      //   },
      // ];

      // setSuggestTasks(getedSuggestTasks);
    } else if (!value && suggestTasks?.length > 0) {
      setSuggestTasks([]);
    }
  }, [value]);

  return (
    <Box className="hub-form-box" mx_auto={false}>
      <h4 className="h4 mb-3 mb-lg-4 text-sm">{title}</h4>
      <div className="mb-3 mb-lg-4">
        <Input placeholder={placeholder} name={name} register={register} />
      </div>
      {suggestedTasks?.length > 0 ? (
        <div className="mb-3">
          <div className="h6 mb-3 mb-lg-4 flex flex-row  justify-between">
            <h6>Suggested Tasks</h6>
            {suggetedLoader && <Dots color={colors.PRIMARY_COLOR} />}
          </div>
          <AssetsCards
            list={suggestedTasks}
            onClickItem={handleOnClickSuggestedItem}
          />
        </div>
      ) : (
        <>
          {suggetedLoader && (
            <div
              className="pb-2"
              style={{
                display: 'flex',
                justifyContent: 'center', // Horizontally center content
                alignItems: 'center', // Vertically center content
                width: '100%', // Set the width to 100% if needed
                // Add any other inline styles you want
              }}>
              <Dots color={colors.PRIMARY_COLOR} />
            </div>
          )}
        </>
      )}
      {popularNames?.length > 0 && (
        <>
          <h6 className="h6 mb-3 mb-lg-4 text-xs font-bold">Popular names</h6>
          <div
            className={cn('badge__row badge__row_in-block mb-0', {
              // 'max-[320px]:max-w-[15rem] max-[375px]:max-w-[17rem] max-[425px]:max-w-[19rem] max-[700px]:max-w-[20.5rem] min-[700px]:max-w-[35rem] overflow-x-auto': true,
            })}>
            {popularNames?.length > 0 &&
              popularNames
                .map((i, index) => {
                  return {id: index, tagName: i};
                })
                .map((tag, index) => (
                  // <Badgeh
                  //   key={index}
                  //   onClick={() => onBadgeClick(name)}
                  //   title={name}
                  //   type={'secondary'}
                  //   // active={selectedNames.includes(name)}
                  // />
                  <>
                    <Badge
                      active={selectedNames.includes(tag.tagName)}
                      type={'secondary'}
                      title={tag.tagName}
                      index={index}
                      key={index}
                      onClick={() => onBadgeClick(tag.tagName)}
                      className={'badge badge_presets'}
                    />
                  </>
                ))}
          </div>
        </>
      )}
    </Box>
  );
};

export default AddName;
