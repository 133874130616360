// noinspection ES6CheckImport

import React, {useContext, useRef, useState} from 'react';
import {
  ScrollView,
  ImageBackground,
  Pressable,
  View,
  Image,
  Switch,
  Modal,
  StyleSheet,
  Dimensions,
} from 'react-native';
import {Spacer} from '../../../res/spacer';
import {
  Text,
  styles,
  RegularText,
  ItemDivider,
  SubtitleText,
  ActionWrapper,
  MainContainer,
  BorderContainer,
} from './style';
import {AppHeader} from '../../../components/AppHeader';
import {images} from '../../../res/images';
import {fonts} from '../../../res/fonts';
import {
  heightPercentageToDP as hp,
  widthPercentageToDP as wp,
} from 'react-native-responsive-screen';
import moment from 'moment';
import {colors} from '../../../res/colors';
import AuthManager from '../../../libs/AuthManager.ts';
import Loading from '../../../components/Loading.tsx';
import {removeData} from '../../../res/asyncStorageHelper';
import {useAtom} from 'jotai';
import {currentUserAtom} from '../../../appState/userState';
import {useBuzzmiAuth, useReels} from '../../../appState/hooks';

const ScreenWidth = Dimensions.get('window').width;

const Account = props => {
  // const {sdk, currentUser: sbUser} = useSendbirdChat();
  const actionRef = useRef(null);
  const {userData, logout, updateDND, updateSnooze} = useBuzzmiAuth();
  const [isLoading, setLoading] = useState(false);
  const [isSnooze, setIsSnooze] = useState(false);
  const [isDisturb, setIsDisturb] = useState(false);
  const [dndTimeText, setDndTimeText] = useState('');
  const [snoozeText, setSnoozeText] = useState('');
  const [currentUser, setCurrentUser] = useAtom(currentUserAtom);
  const {actionClearData} = useReels();
  const [showActionMenu, setShowActionMenu] = useState(false);

  // const {disconnect} = useConnection();

  const zeroPad = num => (num < 10 ? '0' + num : num);

  /*const checkMetadataForDND = () => {
    const metadate = sbUser.metaData;
    let dndData;

    const currentDate = new Date();
    if (metadate?.DNDData?.length > 0) {
      dndData = JSON.parse(metadate?.DNDData);

      const getHours = timestamp => parseInt(moment(timestamp).format('HH'));
      const getMinutes = timestamp => parseInt(moment(timestamp).format('mm'));

      const currentHours = getHours(currentDate.getTime());
      const currentMinutes = getMinutes(currentDate.getTime());
      const currentValue = currentHours + currentMinutes;

      const startHours = getHours(dndData?.startTime);
      const startMinutes = getMinutes(dndData?.startTime);
      const startValue = startHours + startMinutes;

      const endHours = getHours(dndData?.endTime);
      const endMinutes = getMinutes(dndData?.endTime);
      const endValue = endHours + endMinutes;

      return currentValue >= startValue && currentValue <= endValue;
    }

    return false;
  };

  const checkMetadataForSnooze = () => {
    const metadate = sbUser.metaData;
    let snoozeData;

    const currentDate = new Date();
    if (metadate?.snoozData?.length > 0) {
      snoozeData = JSON.parse(metadate?.snoozData);

      return (
        currentDate.getTime() > snoozeData?.startTimestamp &&
        currentDate.getTime() < snoozeData?.endTimestamp
      );
    }

    return false;
  };*/

  // function turnOffDND() {
  //   sdk.setDoNotDisturb(
  //     false,
  //     0,
  //     0,
  //     0,
  //     0,
  //     RNLocalize.getTimeZone(),
  //     (res, e) => {
  //       setIsDisturb(() => false);
  //       updateDND({
  //         state: false,
  //         dndText: '',
  //       });
  //       setDndTimeText(() => '');
  //       let meta = sbUser.metaData;
  //       console.log('meta ==> ', meta);
  //       sbUser.updateMetaData(
  //         {
  //           DNDData: '',
  //         },
  //         true,
  //       );
  //       console.log('setDoNotDisturb => ', res);
  //     },
  //   );
  // }
  //
  // function turnOffSnooze() {
  //   const date = new Date().getTime();
  //   sdk.setSnoozePeriod(false, date, date, (res, e) => {
  //     setIsSnooze(() => false);
  //     updateSnooze({
  //       state: false,
  //       snoozeText: '',
  //     });
  //     setSnoozeText(() => '');
  //     sbUser.updateMetaData(
  //       {
  //         snoozData: '',
  //       },
  //       true,
  //     );
  //     console.log('setSnoozePeriod => ', res);
  //   });
  // }
  //
  // useEffect(() => {
  //   setTimeout(() => setLoading(() => false), 2000);
  //   sdk.getDoNotDisturb((res, e) => {
  //     console.log('setDoNotDisturb in sdk useeff=> ', res);
  //
  //     if (res?.doNotDisturbOn) {
  //       setIsDisturb(() => true);
  //       const ET = `${zeroPad(res?.endHour)}:${zeroPad(res?.endMin)}`;
  //       const dndText = `${zeroPad(res?.startHour)}:${zeroPad(
  //         res?.startMin,
  //       )} to ${ET}`;
  //
  //       const endTimeText = moment(ET, 'HH:mm').format('DD-MMM-YYYY HH:mm');
  //       const currTimeText = moment(new Date()).format('DD-MMM-YYYY HH:mm');
  //       if (
  //         moment(currTimeText, 'DD-MMM-YYYY HH:mm').isAfter(
  //           moment(endTimeText, 'DD-MMM-YYYY HH:mm'),
  //         )
  //       ) {
  //         console.log('Time passed => ', endTimeText, currTimeText);
  //         turnOffDND();
  //         return;
  //       }
  //
  //       updateDND({
  //         state: true,
  //         dndText: dndText,
  //       });
  //       setDndTimeText(() => dndText);
  //     } else {
  //       setDndTimeText(() => '');
  //     }
  //   });
  // }, [isDisturb]);
  //
  // useEffect(() => {
  //   if (!refSnoozeChecked?.current) {
  //     refSnoozeChecked.current = true;
  //     let currDate = new Date();
  //     currDate = moment(currDate).format('DD-MMM-YYYY HH:mm');
  //     sdk.getSnoozePeriod((res, e) => {
  //       console.log('getSnoozePeriod => ', res);
  //
  //       if (res?.startTs === res?.endTs) {
  //         turnOffSnooze();
  //         return;
  //       }
  //
  //       if (res?.startTs) {
  //         const startTimeText = moment(res?.startTs).format(
  //           'DD-MMM-YYYY HH:mm',
  //         );
  //         const endTimeText = moment(res?.endTs).format('DD-MMM-YYYY HH:mm');
  //
  //         if (
  //           moment(currDate, 'DD-MMM-YYYY HH:mm').isAfter(
  //             moment(endTimeText, 'DD-MMM-YYYY HH:mm'),
  //           )
  //         ) {
  //           if (res?.isSnoozeOn) {
  //             turnOffSnooze();
  //           }
  //         } else {
  //           setIsSnooze(() => true);
  //           const _snoozeText = `${startTimeText} to ${endTimeText}`;
  //           updateSnooze({
  //             state: true,
  //             snoozeText: _snoozeText,
  //           });
  //           setSnoozeText(() => _snoozeText);
  //         }
  //       }
  //     });
  //   }
  // }, [isSnooze]);

  const deauthenticate = async () => {
    await Promise.all([
      AuthManager.deAuthenticate(),
      // SendbirdCalls.deauthenticate(),
    ]);
    setCurrentUser(undefined);
  };

  const openActionModal = () => {
    return (
      <Modal
        visible={showActionMenu}
        animationType="slide"
        presentationStyle={'formSheet'}
        transparent={true}>
        <View style={pstyles.centeredView}>
          <View style={pstyles.modalView}>
            <Pressable
              style={[pstyles.button]}
              onPress={() => {
                setShowActionMenu(!showActionMenu);
                setTimeout(() => {
                  props.navigation.navigate('ProfileNav', {screen: 'Account'});
                }, 500);
              }}>
              <Image style={pstyles.icon_ic} source={images.account_ic} />
              <Text style={pstyles.textStyle}>Account</Text>
            </Pressable>
            <Spacer space={hp(1)} />
            <Pressable
              style={[pstyles.button]}
              onPress={() => {
                setShowActionMenu(!showActionMenu);
                setTimeout(() => {
                  if (actionClearData) {
                    actionClearData();
                  }
                  removeData('userDetails');
                  removeData('company_id');
                  logout();
                  setTimeout(() => {
                    window.location.reload();
                    props.navigation.replace('Auth');
                  }, 150);
                }, 500);
              }}>
              <Image style={pstyles.icon_ic} source={images.logout_ic} />
              <Text style={pstyles.textStyle}>Logout</Text>
            </Pressable>
            <Spacer space={hp(1)} />
            <Pressable
              style={[pstyles.button, {backgroundColor: colors.GHOST_WHITE}]}
              onPress={() => {
                setShowActionMenu(!showActionMenu);
              }}>
              <Text style={pstyles.textStyle}>Cancel </Text>
            </Pressable>
          </View>
        </View>
      </Modal>
    );
  };

  return (
    <>
      <MainContainer>
        {openActionModal()}
        <AppHeader
          onBackPress={() => props.navigation.goBack()}
          onNotification={() =>
            props.navigation.navigate('ProfileNav', {screen: 'Notifications'})
          }
          action_open={() => setShowActionMenu(!showActionMenu)}
          userData={userData}
        />
        <Spacer space={'0.5rem'} />
        <View style={{flex: 1}}>
          <ScrollView showsVerticalScrollIndicator={false} bounces={false}>
            <View
              style={{
                width: ScreenWidth > 670 ? hp(75) : '100%',
                alignSelf: 'center',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
              }}>
              <Pressable
                onPress={() => props.navigation.navigate('Invitations')}>
                <ImageBackground
                  source={images.bg_elevation}
                  style={{height: '10rem', width: '10rem'}}>
                  <Image
                    style={styles.elevation_ic}
                    source={images.settings_acc}
                  />
                  <Text style={{fontSize: '1rem', marginTop: '1rem'}}>
                    Invite
                  </Text>
                </ImageBackground>
              </Pressable>
              <Pressable
                onPress={() =>
                  props.navigation.navigate('AccountVerification')
                }>
                <ImageBackground
                  source={images.bg_elevation}
                  style={{
                    height: '10rem',
                    width: '10rem',
                  }}>
                  <Image
                    style={styles.elevation_ic}
                    source={images.report_acc}
                  />
                  <Text style={{fontSize: '1rem', marginTop: '0.9rem'}}>
                    Account Verification
                  </Text>
                </ImageBackground>
              </Pressable>
              <Pressable
                onPress={() => props.navigation.navigate('EditProfile')}>
                <ImageBackground
                  source={images.bg_elevation}
                  style={{height: '10rem', width: '10rem'}}>
                  <Image
                    style={styles.elevation_ic}
                    source={images.preferences_acc}
                  />
                  <Text style={{fontSize: '1rem', marginTop: '1rem'}}>
                    Edit Profile
                  </Text>
                </ImageBackground>
              </Pressable>
            </View>
            <Spacer space={'0.5rem'} />
            <ActionWrapper
              disabled={isDisturb}
              onPress={() => {
                props.navigation.navigate('DoNotDisturb', {
                  onDndEnabled: (isEnabled, res) => {
                    if (isEnabled) {
                      setIsDisturb(() => isEnabled);
                      const dndText = `${zeroPad(res?.startHour)}:${zeroPad(
                        res?.startMin,
                      )} to ${zeroPad(res?.endHour)}:${zeroPad(res?.endMin)}`;
                      updateDND({
                        state: isEnabled,
                        dndText: dndText,
                      });
                      setDndTimeText(() => dndText);
                    }
                  },
                });
              }}
              activeOpacity={1}>
              <View style={{alignItems: 'flex-start'}}>
                <RegularText>Do not disturb</RegularText>
                {dndTimeText.length > 0 && (
                  <SubtitleText>{dndTimeText}</SubtitleText>
                )}
              </View>
              <View style={{flexDirection: 'row'}}>
                <Switch
                  disabled={!isDisturb}
                  trackColor={{
                    false: colors.DARK_GRAY_91,
                    true: colors.LIGHT_PRIMARY_COLOR,
                  }}
                  thumbColor={
                    isDisturb ? colors.PRIMARY_COLOR : colors.DARK_THUMB
                  }
                  onValueChange={val => {
                    if (!val) {
                      // turnOffDND();
                    }
                  }}
                  value={isDisturb}
                />
                <Image
                  style={{...styles.icon_ic, marginLeft: '1.2rem'}}
                  source={images.right_ic}
                />
              </View>
            </ActionWrapper>
            <BorderContainer />
            <ActionWrapper
              disabled={isSnooze}
              activeOpacity={1}
              onPress={() => {
                props.navigation.navigate('Snooze', {
                  onSnoozeEnabled: (isEnabled, res) => {
                    if (isEnabled) {
                      setIsSnooze(() => isEnabled);
                      const startTimeText = moment(res?.startTs).format(
                        'DD-MMM-YYYY HH:mm',
                      );
                      const endTimeText = moment(res?.endTs).format(
                        'DD-MMM-YYYY HH:mm',
                      );
                      const _snoozeText = `${startTimeText} to ${endTimeText}`;
                      updateSnooze({
                        state: isEnabled,
                        snoozeText: _snoozeText,
                      });
                      setSnoozeText(() => _snoozeText);
                    }
                  },
                });
              }}>
              <View style={{alignItems: 'flex-start'}}>
                <RegularText>Snooze</RegularText>
                {snoozeText.length > 0 && (
                  <SubtitleText>{snoozeText}</SubtitleText>
                )}
              </View>
              <View style={{flexDirection: 'row'}}>
                <Switch
                  disabled={!isSnooze}
                  trackColor={{
                    false: colors.DARK_GRAY_91,
                    true: colors.LIGHT_PRIMARY_COLOR,
                  }}
                  thumbColor={
                    isSnooze ? colors.PRIMARY_COLOR : colors.DARK_THUMB
                  }
                  onValueChange={val => {
                    if (!val) {
                      // turnOffSnooze();
                    }
                  }}
                  value={isSnooze}
                />
                <Image
                  style={{...styles.icon_ic, marginLeft: '1.2rem'}}
                  source={images.right_ic}
                />
              </View>
            </ActionWrapper>
            <BorderContainer />
            <ActionWrapper>
              <RegularText>Language</RegularText>
              <Image style={styles.icon_ic} source={images.right_ic} />
            </ActionWrapper>
            <BorderContainer />
            <ActionWrapper>
              <RegularText>Contact Us</RegularText>
              <Image style={styles.icon_ic} source={images.right_ic} />
            </ActionWrapper>
            <BorderContainer />
            <ActionWrapper>
              <RegularText>Terms Of Use</RegularText>
              <Image style={styles.icon_ic} source={images.right_ic} />
            </ActionWrapper>
            <BorderContainer />
            <ActionWrapper>
              <RegularText>Privacy Policy</RegularText>
              <Image style={styles.icon_ic} source={images.right_ic} />
            </ActionWrapper>
            {/* <BorderContainer /> */}
            {/*TODO: Added this screen for testing only...*/}
            {/*<ActionWrapper*/}
            {/*  onPress={() => props.navigation.navigate('EventSettings')}>*/}
            {/*  <RegularText>Event Settings</RegularText>*/}
            {/*  <Image style={styles.icon_ic} source={images.right_ic} />*/}
            {/*</ActionWrapper>*/}
            <BorderContainer />
            <ActionWrapper onPress={() => props.navigation.navigate('Setting')}>
              <RegularText>Settings</RegularText>
              <Image style={styles.icon_ic} source={images.right_ic} />
            </ActionWrapper>
            <BorderContainer />
            <ActionWrapper
              onPress={() => props.navigation.navigate('DeleteAccount')}>
              <RegularText>Delete Account</RegularText>
              <Image style={styles.icon_ic} source={images.right_ic} />
            </ActionWrapper>
            <BorderContainer />
            <ItemDivider />
            <BorderContainer />
            <ActionWrapper
              onPress={async () => {
                setTimeout(() => {
                  if (actionClearData) {
                    actionClearData();
                  }
                  removeData('userDetails');
                  removeData('company_id');
                  logout();
                  setTimeout(() => {
                    window.location.reload();
                    props.navigation.replace('Auth');
                  }, 150);
                }, 500);
              }}>
              <RegularText>Logout</RegularText>
              <Image style={styles.icon_ic} source={images.logout_ic} />
            </ActionWrapper>
            <BorderContainer />
          </ScrollView>
        </View>
        <Loading visible={isLoading} />
        {/*{isLoading && (
          <View
            style={{
              // position: 'absolute',
              justifyContent: 'center',

              width: wp(100),
              height: hp(60),
              backgroundColor: 'white',
            }}>
            <LoadingSpinner
              style={{
                position: 'absolute',
                justifyContent: 'center',
                alignSelf: 'center',
                width: wp(100),
                height: hp(90),
              }}
              size={40}
              color={Palette.primary300}
            />
          </View>
        )}*/}
      </MainContainer>
    </>
  );
};
export default Account;

const pstyles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: colors.PRIMARY_COLOR,
    borderRadius: 20,
    width: hp(30),
    height: hp(30),
    alignItems: 'center',
    justifyContent: 'center',
    shadowColor: colors.BLACK,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    padding: 10,
    elevation: 2,
    width: hp(20),
    borderRadius: 10,
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: colors.WHITE,
  },
  textStyle: {
    color: colors.PRIMARY_COLOR,
    fontWeight: fonts.BOLD,
    textAlign: 'center',
    alignSelf: 'center',
    marginHorizontal: hp(2),
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
  icon_ic: {
    width: hp(3),
    height: hp(3),
    alignSelf: 'center',
    resizeMode: 'contain',
    tintColor: colors.PRIMARY_COLOR,
  },
});
