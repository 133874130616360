import React, { useRef, useState, useEffect } from 'react';
import Strings from '../../../../../../string_key/Strings.web.js';
import Calendar from "../../../../calendar/Calendar.jsx"
// import CalendarSvg from "./CalendarSVG.jsx";
import { Tooltip } from "react-tooltip"
import 'react-tooltip/dist/react-tooltip.css';
import { XCircleIcon, CalendarDaysIcon } from '@heroicons/react/24/outline';

import moment from "moment"
import cn from "classnames"
import { useBuzzmiAuth } from '../../../../../../appState/hooks/useBuzzmiAuth/index.web.js';
import useWindowSize from '../../../../../../hooks/useWindowSize.js';

const DateInput = ({ label, setValue, errors }) => {
  const { userData } = useBuzzmiAuth();
  const [value, onChange] = useState(moment(userData?.dob).format("MM/DD/YYYY") || null)
  const [hidden, setHidden] = useState(false);
  // const tooltipRef = useRef();
  const { width } = useWindowSize();

  const handleDateChange = (date) => {
    onChange(date)
    global.EBirthday = date
    setValue('birthday', date)
  }


  return (
    <div className={'input-label date-input'}>
      <div>{label}</div>
      <div className={'date-input__field-wrapper'}>
        {/* <DatePicker
          className={`date-picker ${!value ? 'line-wrapper' : ''} ${errors ? '_form-error' : ''}`}
          onChange={handleDateChange}
          value={value}
          calendarIcon={<Arrow />}
          clearIcon={null}
          dayPlaceholder={'DD'}
          monthPlaceholder={'MM'}
          yearPlaceholder={'YYYY'}
          format={'dd/MM/yyyy'}
        /> */}
        <input
          type="text"
          onFocus={() => { setHidden(false) }}
          className="input input_simple-text date-input__field pl-[50px]"
          placeholder=""
          value={value ? moment(value).format("MM/DD/YYYY") : "MM/DD/YYYY"}
        />

        <CalendarDaysIcon
          className="h-6 w-6"
          aria-hidden="true"
        />

        {width > 992 ?
          null :
          <div
            onClick={() => { setHidden(true) }}
            style={{
              position: 'absolute',
              right: 50,
              bottom: 22
            }}>
            <XCircleIcon
              className="h-6 w-6"
              aria-hidden="true"
            />
          </div>
        }
      </div>

      <Tooltip
        hidden={hidden}
        className="date-input__tooltip" clickable anchorSelect=".date-input__field" style={{ zIndex: 1, pointerEvents: 'auto' }}>
        <div className="date-input__wrapper">
          <Calendar
            className={cn("date-picker date-picker_mode-switch", {
              "line-wrapper": !value,
              "_form-error": errors,
            })}
            onChange={handleDateChange}
            value={value || new Date()}
            view=""
          />
        </div>
      </Tooltip>

      <div> {errors && <div className={'text-error text-center text-r'}>{errors[0]}</div>}</div>
      <div className={'date-wrapper__subtitle text-r'}> {Strings.birthday_not_shown_publicaly}</div>
    </div>
  );
};

export default DateInput;