import React from 'react';
import { useLinkTo } from '@react-navigation/native';

const MainLayoutMenuItem = ({ iconSrc, activeIconSrc, name, to, showOnMobile, onHandelClick }) => {
  const linkTo = useLinkTo();
  return (
    <li className={`main-layout__menu-item menu-item ${!showOnMobile ? "menu-item_mobile-hide" : ""}`}>
      <a style={{ cursor: "pointer" }} onClick={() => { onHandelClick(to), to != '/hub/hub-activities' ? (to != '/hub/home' ? null : linkTo(to)) : linkTo(to) }} className={global.activeMenu == to ? "menu-item__link active" : "menu-item__link"}>
        <img className="menu-item__icon" src={iconSrc} alt={'menu-icon'} />
        <img className="menu-item__icon_active" src={activeIconSrc} alt={'menu-icon_active'} />
        <span className="menu-item__name text-s">{name}</span>
      </a>
    </li>
  );
};

export default MainLayoutMenuItem;
