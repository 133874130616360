import React, {useEffect, useState, useRef} from 'react';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import MobileLayout from '../../../../components/layouts/mobileLayout/MobileLayout.jsx';
import CodeInput from '../../../../components/reusable/form/items/inputs/codeInputs/CodeInput.jsx';
import FinallyMessage from '../../../../components/reusable/form/items/validation/FinallyMessage.jsx';
import ResendCode from '../../../../components/reusable/form/items/buttons/ResendCode';
import PrimaryButton from '../../../../components/reusable/form/items/buttons/PrimaryButton.jsx';
import * as yup from 'yup';
import BaseLayout from '../../../../components/layouts/baseLayout/BaseLayout.jsx';
import {useNavigation} from '@react-navigation/core';
import {
  VERIFY_EMAIL_CODE,
  SEND_EMAIL_VERIFICATION,
} from '../../../../api_helper/Api.js';
import Loading from 'react-fullscreen-loading';
import {colors} from '../../../../res/colors.js';
import {POST} from '../../../../api_helper/ApiServices.js';
import {ShowSuccess} from '../../../HOC/MessageWeb.js';
import {useToast} from 'react-native-toast-notifications';
import {saveData} from '../../../../res/asyncStorageHelper.web.js';
import Strings from '../../../../string_key/Strings.web.js';
import {useEncryptedAtom} from '../../../../appState/hooks/useEncryptedAtom/index.web.js';
import {_sSecretAtom} from '../../../../appState/userState.js';

const ProvideEmailCode = props => {
  let refUrlParams = useRef(props.route.params);
  const {navigate} = useNavigation();
  const [code, setCode] = useState([]);
  const [valid, setValid] = useState(null);
  const [isLoad, setIsLoad] = useState(false);
  const [showResend, setShowResend] = useState(true);
  const toast = useToast();
  const [secret_s, setSSecret] = useEncryptedAtom(_sSecretAtom);

  const confirmCodeSchema = yup.object({
    code: yup
      .string()
      .required('Please enter your code.')
      .min(6, 'Length must be 6 characters'),
  });

  const {handleSubmit, register, setValue, getValues} = useForm({
    resolver: yupResolver(confirmCodeSchema),
  });

  const onSubmit = async ({code}) => {
    navigate('Bio', {user: refUrlParams?.current?.user});
  };

  const handleResendCode = async () => {
    setValid(null);
    setCode([]);
    setValue('code', null);
    setIsLoad(true);
    let postData = {email: refUrlParams?.current?.user?.email, flag: true};
    POST(
      SEND_EMAIL_VERIFICATION,
      false,
      null,
      null,
      postData,
      (response, isError) => {
        setIsLoad(false);
        if (isError) {
          console.log(isError);
        } else {
          setSSecret(response?.secret);

          setTimeout(() => {
            ShowSuccess(response?.message, toast);
          }, 250);
        }
      },
    );
  };
  // todo remove on production
  useEffect(() => {
    if (getValues('code')?.length === 6) {
      setIsLoad(true);
      let postData = {code: getValues('code')};
      POST(
        VERIFY_EMAIL_CODE,
        false,
        null,
        null,
        postData,
        (response, isError) => {
          setIsLoad(false);
          if (isError) {
            setValid('invalid');
          } else {
            setShowResend(false);
            setValid('valid');
          }
        },
      );
    }
  }, [code, getValues]);

  return (
    <BaseLayout>
      <MobileLayout>
        <Loading
          loading={isLoad}
          background="transparent"
          loaderColor={colors.PRIMARY_COLOR}
        />

        <div className="mb-4">
          <div className="h4">{Strings.provide_your_contact_information}</div>
          <div className="mt-9 mb-9 mb-sm-20" style={{ justifyContent: "center", display: "flex" }}>
            <img src="/images/assets/mail.png" alt="" />
          </div>
          <form id={'input-phone-code'} onSubmit={handleSubmit(onSubmit)}>
            <label className={'input-label'}>
              <div>{Strings.verify_your_email}</div>
              <div className={'input-code-wrapper '}>
                <input
                  className="input-code-wrapper__hide p-0"
                  {...register('code')}
                  type="text"
                />
                {[...Array(6)].map((_, i) => (
                  <CodeInput
                    success={valid === 'valid'}
                    error={valid === 'invalid'}
                    setCode={setCode}
                    key={i}
                    index={i}
                    register={register}
                    label={'Input Label'}
                    setValue={setValue}
                    regex={/^[0-9a-zA-Z_]$/}
                  />
                ))}
              </div>
            </label>
            {(!valid || getValues('code')?.length < 6) && (
              <div className="text-r text-secondary mt-9">
                {Strings.verification_code_sent_description
                  .replace('{email}', `${refUrlParams?.current?.user?.email}`)
                  .replace('{digit}', 6)}
              </div>
            )}
            <FinallyMessage
              show={valid && getValues('code')?.length === 6}
              isValid={valid === 'valid'}
              successTitle={Strings.success}
              successMessage={Strings.your_email_has_been_verified}
              wrongTitle={Strings.wrong_verification_number}
              wrongMessage={Strings.re_enter_the_code}
            />
          </form>
        </div>

        <div>
          {showResend && <ResendCode handleResendCode={handleResendCode} />}
          <PrimaryButton
            className={`mb-7 ${
              valid !== 'valid' || getValues('code')?.length !== 6
                ? 'button_inactive'
                : ''
            }`}
            type="submit"
            form="input-phone-code">
            {Strings.next}
          </PrimaryButton>
        </div>
      </MobileLayout>
    </BaseLayout>
  );
};

export default ProvideEmailCode;
