import { useMutation } from 'react-query';
import { createReel } from '../../../network/api/app';

export const useCreateReel = () => {
    const {
        mutate: mutateCreateReel,
        isLoading: isCreateReelLoading,
        isError: isCreateReelError,
        data: reelResponse,
        error: reelErrorResponse,
    } = useMutation(createReel);

    const postCreateReel = params => mutateCreateReel(params);

    return {
        reelErrorResponse,
        isCreateReelError,
        isCreateReelLoading,
        reelResponse,
        postCreateReel,
    };
};
