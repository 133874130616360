//#region RN
import React, {useEffect} from 'react';
// noinspection ES6CheckImport
import {Alert} from 'react-native';
//#region third party libs
import NetInfo from '@react-native-community/netinfo';

export const Network = props => {
  useEffect(() => {
    NetInfo.addEventListener(state => {
      if (state.isConnected != true) {
        onNetworkAlert();
      }
    });
  }, []);

  // On network alert
  const onNetworkAlert = () => {
    Alert.alert(
      'Buzzmi',
      "Couldn't reach server. Check your internet connection",
      [
        {
          text: 'OK',
          onPress: () => {},
        },
      ],
      {
        cancelable: false,
      },
    );
  };

  return <></>;
};
