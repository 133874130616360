// noinspection ES6CheckImport

import {Platform, StyleSheet} from 'react-native';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import {colors} from '../../res/colors';
import {fonts} from '../../res/fonts';

import styled from 'styled-components/native';
const isWeb = Platform.OS == 'web';
const Icon = styled.Image`
  tint-color: ${colors.PRIMARY_COLOR};
  width: ${wp('6%')}px;
  height: ${wp('6%')}px;
`;

export {Icon};

export default StyleSheet.create({
  backIcon: {
    width: '1rem',
    height: '1rem',
    marginLeft: '1rem',
    alignSelf: 'center',
  },
  text_center: {
    zIndex: -1,
    alignSelf: 'center',
    fontSize: '20px',
    fontFamily: fonts.MEDIUM,
    position: 'absolute',
    // width: '90%',
    textAlign: 'center',
    color: 'black',
    // backgroundColor: 'red',
  },
});
