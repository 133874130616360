import React, { useEffect, useState } from 'react';
import { useBuzzmiAuth } from '../../../../appState/hooks/useBuzzmiAuth/index.web';
import Input from '../../../../components/reusable/form/items/inputs/Input';
import ValidationSchema from '../../../../form-validator/ValidationSchema';
import { useFormValidator } from '../../../../form-validator/hooks';
import Form from '../../../../components/reusable/form/Form';
import Box from '../../../../components/reusable/cards/box/Box';
import DetailHeader from '../WalletDetail/DetailHeader';
import { useHubApp } from '../../../../appState/hooks/useHubApp/index.web';
import { useLinkTo } from '@react-navigation/native';

const SendGems = props => {

    const validationSchema = new ValidationSchema({
        amount: [{ rule: 'minValue', value: 1 }],
    });

    const { userData } = useBuzzmiAuth();
    const { walletInfo } = useHubApp();
    const linkTo = useLinkTo();

    const sendAmount = async formData => {
        console.log(formData)
    }


    const getWalletCoinByName = coin => {
        const filtered = walletInfo.filter(x => x.hasOwnProperty(coin));
        if (filtered.length > 0) {
            return filtered[0][coin]?.total;
        }
        return 0;
    };


    const { handleSubmit, setValue, values, isValid, register } = useFormValidator(
        validationSchema,
        sendAmount,
        {
            showErrorsOnSubmit: false,
            defaultValues: {
                amount: 0
            },
        },
    );

    return (
        <>
            <DetailHeader
                rightImage={"/images/gems_vector.png"}
                title={"Gems Wallet"}
                coin={getWalletCoinByName('gems')}
                amount={0}
            />
            <Box className={"!m-0 p-4"}>

                <Form
                    error={null}
                    onSubmit={handleSubmit}
                    formClassName="hub-form-wrapper profile-form">

                    <div className={"flex justify-between"}>
                        <div className='relative mr-3'>
                            <Input
                                label=""
                                name="amount"
                                placeholder=""
                                register={register}
                                classNameWrapper={"!mb-0"}
                                className={"bg-[#F5EEFE] !rounded-3xl"}
                            />
                            <div className="absolute top-6 right-0 h-16 w-16">
                                <img src={"/images/gems_vector.png"} className={"w-4 h-4 opacity-25 m-auto object-contain"} />
                            </div>

                        </div>
                        <button className={"bg-[#EB4DAC] font-bold text-sm rounded-3xl text-[#FFF] px-6 py-[12px] self-center "} type={"submit"} onClick={() => { alert("test") }}>
                            BUY GEMS
                        </button>
                    </div>
                </Form>
            </Box>
            <div className={"flex-1 bg-[#FBF8FF]"} onClick={()=>{linkTo('/hub/v2/wallet-history')}}>
                <p className={"mt-4 text-sm text-center text-[#e646a0]"}>View Transaction History</p>
            </div>
            <Box className={"!m-0 p-4"}>
                <button className={"flex items-center justify-center bg-[#EB4DAC] font-bold text-lg rounded-full py-3 text-[#FFF] w-full self-center "} type={"submit"} onClick={() => { alert("test") }}>
                    <img src={"/images/gems_send.png"} className={"w-6 h-6 mr-2 self-center"} />SEND GEMS
               </button>
            </Box>
        </>
    );
};
export default SendGems;
