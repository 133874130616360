import React from 'react';
import Box from '../../../../../components/reusable/cards/box/Box';
import SimpleShowMore from '../../../../../components/reusable/assets/showMore/simpleShowMore/SimpleShowMore';

const ProfileText = ({ title, text, istwoCap = false }) => {
  return (
    <Box paddingMode="big" className="profile-user__info-block mt-3 mt-md-4">
      <div className="profile-user__block-title">{title}</div>
      <SimpleShowMore
        content={text}
        lineClamp={istwoCap ? 2 : 3}
        sizes={{
          desktopHeight: istwoCap ? 40 : 60,
          mobileHeight: istwoCap ? 40 : 60,
        }}
      />
    </Box>
  );
};

export default ProfileText;
