import {useEffect, useState, useRef} from 'react';
import BackBtnWithTitlePage from '../../../../components/reusable/btns/backBtn/BackBtnWithTitlePage.jsx';
import Form from '../../../../components/reusable/form/Form.jsx';
import AddName from '../Items/form/addName/AddName.jsx';
import ChoseImage from '../Items/choseImage/ChoseImage.jsx';
import MultiBox from '../../../../components/reusable/cards/multiBox/MultiBox.jsx';
import FormToggleDropdown from '../../../../components/reusable/assets/dropdown/formToggleDropdown/FormToggleDropdown.jsx';
import PictureUploaderList from '../../../../components/reusable/uploader/pictureUploader/PictureUploaderList.jsx';
import VideoUploader from '../../../../components/reusable/uploader/videoUploader/VideoUploader.jsx';
import AudioUploader from '../../../../components/reusable/uploader/audioUploader/AudioUploader.jsx';
import Checkbox from '../../../../components/reusable/assets/checkbox/Checkbox.jsx';
import SubmitButton from '../../../../components/reusable/btns/buttons/submitButton/SubmitButton.jsx';
import Strings from '../../../../string_key/Strings.web.js';
import {useBuzzmiAuth} from '../../../../appState/hooks/useBuzzmiAuth/index.web.js';
import CheckTags from '../Items/form/checkTags/CheckTags.jsx';
import {useHubApp} from '../../../../appState/hooks/useHubApp/index.web.js';
import addImg from '../../../../assets/add_icon.png';

const HubTaskForm = ({
  error,
  handleSubmit,
  setValue,
  values,
  isValid,
  register,
  data = {},
  isEdit,
}) => {
  // const [showInTask, setShowInTask] = useState(false);
  // const {language} = useBuzzmiAuth();
  const refCheckTags = useRef(null);
  const [validIm, setvalidIm] = useState(true);
  const [validvid, setvalidvid] = useState(true);
  const [validAud, setvalidAud] = useState(true);

  const {tagsList} = useHubApp();
  // useEffect(() => {
  //   setValue('showInTask', showInTask);
  // }, [showInTask]);

  useEffect(() => {
    if (values.images) {
      setvalidIm(true);
    }
    if (values.video) {
      setvalidvid(true);
    }
    if (values.audio) {
      setvalidAud(true);
    }
  }, [values]);

  return (
    <div className="main-layout__single-container">
      <BackBtnWithTitlePage
        title={isEdit ? Strings.edit_task : Strings.add_task}
        backLink={isEdit ? '/hub/create-activity' : '/hub/add-activities'}
      />
      <Form
        error={error}
        onSubmit={handleSubmit}
        formClassName="hub-form-wrapper">
        <AddName
          setValue={setValue}
          title={Strings.add_task_name}
          placeholder={Strings.enter_name}
          name="name"
          register={register}
          value={values?.name || ''}
          // initPopularNames={data?.initPopularNames}
          showSuggestedTask
        />
        <ChoseImage values={values} setValue={setValue} />
        <MultiBox title={Strings.add_description} miniMode>
          <textarea
            onChange={e => setValue('description', e.target.value)}
            placeholder={Strings.short_description_task}
            className="textarea"
            value={values.description || ''}
          />
        </MultiBox>
        {/* {!isEdit && ( */}
        {/* {console.log('values?.images?=?>?>?>?>', values?.images?.length > 0)} */}
        <FormToggleDropdown
          setValue={setValue}
          title={'Task Media (videos/images)'}
          info={Strings.add_4_description_image}
          name="images"
          defaultValue={values?.images}
          miniMode
          setvalidB={setvalidIm}>
          <PictureUploaderList
            defaultValues={values?.images}
            setValue={setValue}
            picture_type={'description_image'}
            validIm={validIm}
          />
        </FormToggleDropdown>
        <FormToggleDropdown
          defaultValue={values?.video}
          setValue={setValue}
          title={Strings.add_description_video}
          info={Strings.add_description_video}
          name="video"
          miniMode
          setvalidB={setvalidvid}>
          <VideoUploader
            title={values?.name || ''}
            defaultValue={values?.video}
            setValue={setValue}
          />
        </FormToggleDropdown>
        <FormToggleDropdown
          defaultValue={values?.audio}
          setValue={setValue}
          title={Strings.add_description_audio}
          info={Strings.add_description_audio}
          name="audio"
          miniMode
          setvalidB={setvalidAud}>
          <AudioUploader defaultValue={values?.audio} setValue={setValue} />
        </FormToggleDropdown>
        {/* <FormToggleDropdown
          defaultValue={[]}
          setValue={setValue}
          icon="/images/hub/form/hash.png"
          title={Strings.tags}
          name="tags"
          values={values.tags}>
          <CheckTags
            tags={tagsList}
            value={values.tags}
            setValue={setValue}
            valueName="tags"
          />
        </FormToggleDropdown> */}
        <MultiBox
          setValue={setValue}
          // icon="/images/hub/form/hash.png"
          icon=""
          title={'Add ' + Strings.tags}
          name="tags"
          rightSide={
            !!setValue && (
              <button
                onClick={() => refCheckTags?.current?.handleAdd()}
                type="button ">
                <img
                  src={addImg}
                  className="h-8 w-8 sm:w-10 sm:h-10 md:w-10 md:h-10 lg:w-12 lg:h-12 xl:w-12 xl:h-12 mr-2"
                  // onClick={() => console.log('khali')}
                />
              </button>
            )
          }>
          <CheckTags
            ref={refCheckTags}
            value={values?.tags || []}
            setValue={setValue}
            valueName="tags"
          />
        </MultiBox>

        {/* )} */}

        {/* <MultiBox
          icon="/images/assets/forms/task-center.png"
          title={Strings.display_in_task_center}
          rightSide={<Checkbox defaultValue={values?.showInTask} setState={setShowInTask} idChecked="showInTask" />}
        >
          <div>
            <h6 className="h6 mb-2 text-dark-secondary">Task view: Preset-based</h6>
            <p className="text-r">
              {Strings.toggle_description}
            </p>
          </div>
        </MultiBox> */}
        <div className="d-flex bottom-mobile-button justify-content-center mt-5">
          <SubmitButton
            isValid={isValid && validIm && validvid && validAud}
            title={Strings.save_and_continue}
          />
        </div>
      </Form>
    </div>
  );
};

export default HubTaskForm;
