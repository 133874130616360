import React, {useEffect, useState, useRef, Fragment} from 'react';

import {Dialog, Transition} from '@headlessui/react';
import {XMarkIcon} from '@heroicons/react/24/outline';
import ModalBackdrop from '../../../../components/layouts/mainLayout/componentsV2/ModalBackDrop';
import HubTaskInfov2 from '../HubTaskDetailsv2/index.web';
import {useEventEmitter} from '../../../../hooks/useEventEmitter';

const PlanActivityModal = ({activityModal, showActivityModal, item}) => {
  useEventEmitter('closeEditPlanPanel', () => {
    showActivityModal(false);
  });

  return (
    <Transition.Root show={activityModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={showActivityModal}>
        <ModalBackdrop />
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full">
                <Dialog.Panel
                  className="pointer-events-auto w-screen max-w-md"
                  style={{maxWidth: 640}}>
                  <div className="flex h-full flex-col overflow-y-scroll bg-[#FBF8FF] shadow-xl">
                    <div className="p-4">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900 text-center w-100">
                          {item?.title}
                        </Dialog.Title>

                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-pink-500"
                            onClick={() => showActivityModal(() => false)}>
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className=" px-3 ">
                      <>
                        {/* <Loading
                            loading={
                              isCreateBaseActivityLoading ||
                              isEditBaseActivityLoading
                            }
                            background="transparent"
                            loaderColor={colors.PRIMARY_COLOR}
                          /> */}
                        {/* {console.log('item over planned', item)} */}
                        <HubTaskInfov2
                          item={item}
                          showModal={showActivityModal}
                        />
                      </>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default PlanActivityModal;
