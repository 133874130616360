import React from 'react';

import ProfileBio from '../../../../items/ProfileBio';
import ProfileAchievementIcon from '../../../../items/profileAchievementIcon/ProfileAchievementIcon';
import Avatar from '../../../../../../../components/reusable/assets/avatar/Avatar';
import { useRankInfo } from '../../../../../../../appState/hooks/useRankInfo';
import { useBuzzmiAuth } from '../../../../../../../appState/hooks/useBuzzmiAuth/index.web';

const ProfileInfoMobile = ({ showAchievement = false, isOtherProfile = false }) => {
  const { userData } = useBuzzmiAuth();
  const { rankInfo } = useRankInfo();
  return (
    <div className="profile-top__mobile-wrap">
      <Avatar mode="border" isOtherProfile={isOtherProfile} />
      <div className="profile-top__mobile-right">
        <ProfileBio isOtherProfile={isOtherProfile} />
       
        {rankInfo?.currentRank &&
          <div className="profile-achievement-icon">
            <img className={"!w-6 !h-6"} src={rankInfo?.currentRankBadge} alt={rankInfo?.currentRankBadge} />
            <div>{rankInfo?.currentRank}</div>
          </div>
        }
      </div>
    </div>
  );
};

export default ProfileInfoMobile;
