import React from 'react';
import MainLayoutContent from '../../../../components/layouts/mainLayout/mainLayoutContent/MainLayoutContent';
import ResizeComponent from '../../../../components/reusable/assets/resizeComponent/ResizeComponent';
import ProfileMainMobile from './profileMainMobile/ProfileMainMobile';
import ProfileMainDesktop from './profileMainDesktop/ProfileMainDesktop';
import MainLayout from '../../../../components/layouts/mainLayout/MainLayout';

const ProfileMain = () => {
  return (
    <MainLayout>
      <MainLayoutContent>
        <ResizeComponent
          desktopComponent={<ProfileMainDesktop />}
          mobileComponent={<ProfileMainMobile />}
        />
      </MainLayoutContent>
    </MainLayout>
  );
};

export default ProfileMain;
