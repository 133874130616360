import React, {useContext, useState, useEffect} from 'react';
import {View, Image, TouchableOpacity, Alert, Dimensions} from 'react-native';
import {Spacer} from '../../../../res/spacer';
// style themes and components
import {GlobalFlex} from '../../../../res/globalStyles';
import {BackHeader} from '../../../../components/BackHeader';
import {images} from '../../../../res/images';
import {styles, BorderContainer, Text} from './style';
import {fonts} from '../../../../res/fonts';
// Third Party library
import {heightPercentageToDP as hp, widthPercentageToDP as wp} from 'react-native-responsive-screen';
import {colors} from '../../../../res/colors';
import {PATCH} from '../../../../api_helper/ApiServices';
import {DEACTIVATE_ACCOUNT} from '../../../../api_helper/Api';
import deviceInfoModule from 'react-native-device-info';
import {removeData} from '../../../../res/asyncStorageHelper';
import {unregisterToken} from '../../../../commonAction';
// import { useConnection } from '@sendbird/uikit-react-native';
import AuthManager from '../../../../libs/AuthManager.ts';
import {useBuzzmiAuth} from "../../../../appState/hooks";
// import { SendbirdCalls } from '@sendbird/calls-react-native';


const ScreenWidth = Dimensions.get('window').width;
const DeleteAccounts = props => {
  const [is_selected, setIsSelected] = useState(1);
  const {userData} = useBuzzmiAuth();
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  // const { disconnect } = useConnection();


  const deauthenticate = async () => {
    await Promise.all([
      AuthManager.deAuthenticate(),
      // SendbirdCalls.deauthenticate(),
    ]);
  };

  const InActiveAccount = async () => {
    const uniqueID = await deviceInfoModule.getUniqueId();
    PATCH(
      DEACTIVATE_ACCOUNT,
      userData?.token,
      uniqueID,
      {
        userID: userData?.userId,
      },
      (res, e) => {
        console.log(res);
        setLoading(() => false);
        setUpdating(() => false);
        if (!e) {

          Alert.alert(
            'Buzzmi',
            res?.message,
            [
              {
                text: 'Okay',
                onPress: async () => {
                  await unregisterToken();
                  await deauthenticate();
                  // await disconnect();
                  removeData('userDetails');
                  removeData('company_id');
                  setTimeout(() => {
                    props.navigation.replace('Auth');
                  }, 150);
                },
              },
            ],
            {cancelable: false},
          );

        } else {
          console.log('PATCH_UPDATE_PROFILE (error) => ', res);
        }
      },
    );

  };

  const onAlertConfirmation = () => {
    Alert.alert(
      'Inactive Account',
      "Are you sure you want to inactive your account? Please note you have an inactivated account with same Email/Mobile number in One's Say, visit 'Forget Password' to activate the same account or use different details to create a new account",
      [
        {
          text: 'Cancel',
          style: 'cancel',
        },
        {
          text: 'InActive',
          onPress: () => {
            InActiveAccount();
          },
        },
      ],
      {cancelable: false},
    );
  };


  return (
    <GlobalFlex style={[{backgroundColor: colors.BORDER_SPACE_COLOR}]}>
      <BackHeader
        is_center_text
        title={'Delete Account'}
        onBackPress={() => props.navigation.goBack()}
        background={{paddingBottom: hp(2)}}
      />
      <View style={[styles.borderView, {borderBottomWidth: hp(0.1)}]}/>
      <View style={[styles.viewWrapper, {
        opacity: is_selected == 2 ? 0.6 : 1,
        width: ScreenWidth > 670 ? hp(90) : wp(100)
      }]}>
        <Spacer space={hp(1.2)}/>
        <TouchableOpacity onPress={() => {
          setIsSelected(1);
        }} style={{flexDirection: 'row'}}>
          <Image source={is_selected == 1 ? images.radio_fill : images.radio_ring}
                 style={{width: hp(3), height: hp(3), marginRight: hp(2)}}/>
          <View style={{width: ScreenWidth > 670 ? hp(60) : wp(80)}}>
            <Text style={{fontFamily: fonts.MEDIUM, fontSize: hp(2.5), lineHeight: hp(2)}}>Deleting your account
              will:</Text>
            <Spacer space={hp(0.5)}/>
            <Text style={{
              fontSize: hp(2),
              lineHeight: hp(4)
            }}>{'\u2022' + ' Delete your account info and profile photo'}</Text>
            <Text
              style={{fontSize: hp(2), lineHeight: hp(4)}}>{'\u2022' + ' Delete you from all One’s Say groups'}</Text>
            <Text style={{
              fontSize: hp(2),
              lineHeight: hp(4)
            }}>{'\u2022' + ' Delete your messages history on this phone and your backups'}</Text>
          </View>
        </TouchableOpacity>
        <Spacer space={hp(1)}/>
      </View>
      <BorderContainer style={[{width: ScreenWidth > 670 ? hp(60) : wp(100), alignSelf: "center", height: 2}]}/>
      <View style={[styles.viewWrapper, {
        opacity: is_selected == 1 ? 0.6 : 1,
        width: ScreenWidth > 670 ? hp(90) : wp(100)
      }]}>
        <Spacer space={hp(1)}/>
        <TouchableOpacity onPress={() => {
          setIsSelected(2);
        }} style={{flexDirection: 'row'}}>
          <Image source={is_selected == 2 ? images.radio_fill : images.radio_ring}
                 style={{width: hp(3), height: hp(3), marginRight: hp(2)}}/>
          <View style={{width: ScreenWidth > 670 ? hp(60) : wp(80)}}>
            <Text style={{fontFamily: fonts.MEDIUM, fontSize: hp(2.5), lineHeight: hp(2)}}>Temporary inactive your
              account will</Text>
            <Spacer space={hp(0.5)}/>
            <Text style={{
              fontSize: hp(2),
              lineHeight: hp(4)
            }}>{'\u2022' + " Temporarily inactivated your One's Say account"}</Text>
            <Text style={{
              fontSize: hp(2),
              lineHeight: hp(4)
            }}>{'\u2022' + " Not display your profile/contact details in One's Say"}</Text>
            <Text style={{
              fontSize: hp(2),
              lineHeight: hp(4)
            }}>{'\u2022' + ' Mark your current login credential as invalid'}</Text>
            <Text style={{
              fontSize: hp(2),
              lineHeight: hp(4)
            }}>{'\u2022' + " Allow to activate the same account when you wish throght the 'Forgot password' option"}</Text>
          </View>
        </TouchableOpacity>
        <Spacer space={hp(0.5)}/>
      </View>
      <Spacer space={hp(1)}/>
      <View style={{...styles.viewWrapper, paddingVertical: hp(3), width: ScreenWidth > 670 ? hp(90) : wp(100)}}>
        <Text style={{color: '#aaa', fontSize: hp(2.8), lineHeight: hp(4)}}>Your phone number </Text>
        <Text style={{fontSize: hp(2.5), lineHeight: hp(4)}}>{userData?.mobile}</Text>
        <Spacer space={hp(0.2)}/>
        <View style={{backgroundColor: colors.DARK_GRAY_91, height: hp(0.2)}}/>
      </View>

      <Spacer space={hp(1.5)}/>
      {is_selected == 1 ?
        <TouchableOpacity style={{alignSelf: 'center'}} onPress={() => {
          props.navigation.navigate('ReasonModal');
        }}>
          <Text
            style={{fontFamily: fonts.REGULAR, fontSize: hp(3), color: colors.DARK_RED}}>{'Delete My Account'}</Text>
        </TouchableOpacity>
        :
        <TouchableOpacity style={{alignSelf: 'center'}} onPress={() => {
          onAlertConfirmation();
        }}>
          <Text style={{
            fontFamily: fonts.REGULAR,
            fontSize: hp(3),
            color: colors.PRIMARY_COLOR
          }}>{'Inactive My Account'}</Text>
        </TouchableOpacity>
      }
    </GlobalFlex>
  );
};
export default DeleteAccounts;
