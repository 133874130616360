import React, {useEffect, useState} from 'react';
import ImageUploading from 'react-images-uploading';
import MediaWrapper from '../../assets/mediaWrapper/MediaWrapper.jsx';
import {isCommunityImage} from '../../../../types/media.js';

const PictureUploader = ({
  parentSetterState,
  defaultValue,
  maxNumber = 1,
  locked,
  index,
  setErrors,
  onDelete,
}) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (defaultValue) {
      setImages([
        {
          data_url: defaultValue,
        },
      ]);
    }
  }, [defaultValue]);
  const onChange = imageList => {
    if (imageList.length === 0) {
      setImages([]);
      return;
    }

    if (imageList.length === 1) {
      // setImages(imageList);
      parentSetterState(imageList);
      return;
    }

    parentSetterState(imageList);
    // setImages([imageList[index]]);
  };
  const handleErrors = err => setErrors(err);

  return (
    <div>
      <ImageUploading
        onError={handleErrors}
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        // dataURLKey="file"
        acceptType={['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp', 'jfif']}>
        {({
          imageList,
          onImageUpload,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="picture-uploader">
            <div className="upload__image-wrapper">
              <MediaWrapper
                iconSrc="/images/assets/forms/image.svg"
                style={isDragging ? {color: 'red'} : undefined}
                locked={locked}
                {...dragProps}
                onClick={onImageUpload}
              />
              &nbsp;
              {imageList.map((image, _index) => (
                <div key={_index} className="image-item">
                  <button
                    onClick={() => onImageUpdate(_index)}
                    className="picture-uploader__img"
                    disabled={locked}>
                    {isCommunityImage(image['data_url']) && (
                      <img src={image['data_url']} alt="image upload icon" />
                    )}
                    {!isCommunityImage(image['data_url']) && (
                      // Display video thumbnail with play icon
                      <div className="video-thumbnail-container h-100">
                        <video src={image['data_url']}></video>
                        <div className="play-icon absolute bottom-0 left-2 text-4xl">
                          ▶
                        </div>
                      </div>
                    )}
                  </button>
                  {!locked && (
                    <button
                      className="picture-uploader__delete"
                      onClick={() => {
                        onImageRemove(_index);
                        onDelete(index);
                      }}>
                      <img src="/images/assets/delete.svg" alt="delete" />
                    </button>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </ImageUploading>
    </div>
  );
};

export default PictureUploader;
