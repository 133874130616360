import React from 'react';
import styles from './next-level.module.scss';
import {useHubApp} from '../../../../../../appState/hooks/useHubApp/index.web';
import {generateMessages, info} from './utils';

const HubNextLevelInfo = () => {
  const {nextRankInfo} = useHubApp();
  // console.log('nextRankInfo', nextRankInfo);
  const rankData = generateMessages(nextRankInfo);
  return (
    <ul className={styles['next-level']}>
      {rankData.length &&
        rankData.map((item, index) => (
          <li className={'d-flex align-items-center mb-1'} key={index + 'plan-info'}>
            <div
              className={styles['next-level__img']}
              dangerouslySetInnerHTML={{__html: item.svg}}
            />
            <span className={'text-s ms-2'}>{item.title}</span>
          </li>
        ))}
    </ul>
  );
};

export default HubNextLevelInfo;

{
  /* {info.length &&
        info.map((item, index) => ( */
}
