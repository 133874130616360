import React from 'react';
import {useBuzzmiAuth} from '../../../../../../appState/hooks/useBuzzmiAuth/index.web.js';
import CustomSelect from '../../../../../../components/reusable/form/select/customSelect/CustomSelect.jsx';
import Strings from '../../../../../../string_key/Strings.web.js';

const modeSelect = [
  {label: 'mins', value: 'mins'},
  {label: 'seconds', value: 'seconds'},
  {label: 'km', value: 'km'},
  {label: 'meter', value: 'meter'},
  {label: 'ft', value: 'ft'},
  {label: 'cm', value: 'cm'},
  {label: 'inch', value: 'inch'},
  {label: 'times', value: 'times'},
  {label: 'sets', value: 'sets'},
  {label: 'glasses', value: 'glasses'},
  {label: 'cups', value: 'cups'},
  {label: 'oz', value: 'oz'},
  {label: 'liter', value: 'liter'},
  {label: 'ml', value: 'ml'},
  {label: 'gram', value: 'gram'},
  {label: 'calories', value: 'calories'},
];

const SetGoal = ({values, setValues}) => {
  const {language} = useBuzzmiAuth();
  const handleChangingValue = e => {
    let number = e.target.value;
    setValues({...values, number});
  };
  const handleChangingGoalValue = e => {
    const number = e.target.value;
    setValues({...values, number});
  };
  const handleMode = value => {
    setValues({
      ...values,
      type: value.value,
    });
  };

  return (
    <div className="set-goal">
      <div className="set-goal__group">
        <input
          onChange={handleChangingValue}
          type="number"
          placeholder="Enter a number"
          className="input input_simple-text set-goal__number rounded-full"
          value={values?.number || ''}
        />
        <CustomSelect
          selected={
            !!values?.type
              ? {label: values.type, value: values.type}
              : modeSelect[0]
          }
          setSelected={handleMode}
          options={modeSelect}
          className="select__secondary set-goal__type"
        />
      </div>
      {/*<label className="set-goal__group set-goal__group--column">
        <strong className="set-goal__group-title">
          Add your goal manually
        </strong>
        <input
          onChange={handleChangingGoalValue}
          type="number"
          placeholder="Add your goal here"
          className="input input_simple-text"
        />
      </label>*/}
    </div>
  );
};

export default SetGoal;
