// noinspection ES6CheckImport

import React from 'react';
import { View } from 'react-native';

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import GroupChannelTabs from '../../uikit-app/GroupChannelTabs';
import DiscoverStackNavigator from './DiscoverStackNavigator';
import MyBottomTabBar from '../Components/MyBottomTabBar';
import HomeStackNavigator from './HomeStackNavigator';
import ReelStackNavigator from './ReelStackNavigator';
import { TAB_NAMES } from '../Components/Constants';
import HubstackNavigator from './HubstackNavigator';
import { getFocusedRouteNameFromRoute } from '@react-navigation/core';
import Subscriptions from "../../container/App/Subscriptions/index";
import {options} from "./utils";

const Tab = createBottomTabNavigator();



const TabNavigator = () => {
  return (
    <Tab.Navigator
      screenOptions={({ route }) => ({
        headerShown: false,
        tabBarStyle: getTabBarVisibility(route)
      })}
      initialRouteName={TAB_NAMES[0]}
      tabBar={props => (
        <MyBottomTabBar {...props} index={props?.state?.index} />
      )}>
      <Tab.Screen name={TAB_NAMES[0]} component={GroupChannelTabs} />

      <Tab.Screen name={TAB_NAMES[1]} component={DiscoverStackNavigator} />

      <Tab.Screen name={TAB_NAMES[2]} // component={GroupChannelTabs}
        component={Subscriptions} />

      {/*<Stack.Screen*/}
      {/*  name={'Subscriptions'}*/}
      {/*  component={Subscriptions}*/}
      {/*  options={options}*/}
      {/*/>*/}


      <Tab.Screen name={TAB_NAMES[3]} component={ReelStackNavigator} />

      <Tab.Screen name={TAB_NAMES[4]} component={HomeStackNavigator} />
    </Tab.Navigator>
  );
};

export default TabNavigator;

const getTabBarVisibility = (route) => {
  const routeName = getFocusedRouteNameFromRoute(route)
  const hiddenScren = ['Subscriptions', 'SendGems']
  if (hiddenScren.indexOf(routeName) > -1)
    return false;
  return true;
}
