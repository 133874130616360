import {deletePlannedActivity} from '../../../network/api/app';

export const useDeletePlannedActivity = () => {
  const deletePlanned = async (id, token, callback) => {
    try {
      const response = await deletePlannedActivity(id)({token});
      console.log('response => ', response);
      callback(response);
    } catch (e) {
      console.log('error => ', e.message);
    }
  };

  return {deletePlanned};
};
