import React, {useEffect, useState} from 'react';
// import { useBuzzmiAuth, useHubApp } from '../../../../appState/hooks';

import ResizeComponent from '../../../../components/reusable/assets/resizeComponent/ResizeComponent.jsx';
import AdditionalInformation from '../../../../components/reusable/assets/additionalInformation/AdditionalInformation.jsx';
import {useEventEmitter} from '../../../../hooks/useEventEmitter';
import {useBuzzmiAuth} from '../../../../appState/hooks/useBuzzmiAuth/index.web.js';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import MainLayoutV2 from '../../../../components/layouts/mainLayout/MainLayoutV2.jsx';
import MainLayoutContentV2 from '../../../../components/layouts/mainLayout/mainLayoutContentV2/MainLayoutContentV2.jsx';
import Box from '../../../../components/reusable/cards/box/Box.jsx';
import HubWalletWeb from './HubWalletweb.js';
import {useHubApp} from '../../../../appState/hooks/useHubApp/index.web.js';
// import WalletEarning from './WalletEarning/index.web.js';

const HomeWallet = props => {
  const {userData} = useBuzzmiAuth();
  const {getPlannedActivities} = useHubApp();

  useEventEmitter('refresh-planActivities', data => {
    const par = {
      page: data?.page ? data?.page : '1',
      limit: data?.limit ? data?.limit : '10',
    };
    if (data?.title) {
      Object.assign(par, {title: data?.title});
    }
    if (data?.tag) {
      Object.assign(par, {tag: data?.tag});
    }
    if (data?.startDate) {
      Object.assign(par, {startDate: data?.startDate});
    }
    if (data?.endDate) {
      Object.assign(par, {endDate: data?.endDate});
    }
    if (data?.status) {
      Object.assign(par, {endDate: data?.status});
    }

    getPlannedActivities(
      userData?.token,
      data?.setLoading ? data.setLoading : () => {},
      par,
    );
  });

  return (
    <>
      <MainLayoutV2 centerSpace={true}>
        <MainLayoutContentV2
          iscenter={true}
          rightColumnContent={<AdditionalInformation isFromWallet />}>
          <ResizeComponent
            desktopComponent={
              <div className="max-w-3xl mx-auto">
                <HubWalletWeb />
              </div>
            }
            mobileComponent={<HubWalletWeb />}
          />
        </MainLayoutContentV2>
      </MainLayoutV2>
    </>
  );
};
export default HomeWallet;
