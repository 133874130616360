// import {Outlet} from 'react-router-dom';
import MainNewLayoutSidebar from './mainLayoutSiderNew/MainNewLayoutSidebar.jsx';
import MainLayoutFooter from './mainLayoutFooter/MainLayoutFooter.jsx';
import Header from './componentsV2/Header';
import AdditionalInformation from '../../../components/reusable/assets/additionalInformation/AdditionalInformation.jsx';
import {EyeDropperIcon, XMarkIcon, PlusIcon} from '@heroicons/react/24/outline';
import Strings from '../../../string_key/Strings.web';
import {useBuzzmiAuth} from '../../../appState/hooks/useBuzzmiAuth/index.web.js';
import {useState, Fragment, useEffect} from 'react';
import {Dialog, Menu, Transition} from '@headlessui/react';
import Board from '../../../components/reusable/board/Board.jsx';
import {useLeaderBoard} from '../../../appState/hooks/useLeaderBoard';
import {useGetAllRankInfo} from '../../../appState/hooks/useGetAllRankInfo';

import {useHubApp} from '../../../appState/hooks/useHubApp/index.web.js';
import CircleChart from '../../../components/reusable/assets/charts/circleChart/CircleChart';
import MainLayoutV2 from './MainLayoutV2.jsx';
import PrimaryLink from '../../reusable/btns/links/PrimaryLink.jsx';
import {useLinkTo} from '@react-navigation/native';
import HubAddTaskv2 from '../../../container/App/Hub/AddTaskv2/index.web.js';
import GalleryModal from '../../modal/GalleryModal/index.js';
import ChoseMultipleImageModalV2 from '../../modal/modals/choseMultipleImageModal/ChoseMultipleImageModalV2.jsx';
import ModalBackdrop from './componentsV2/ModalBackDrop/index.js';
import {useEventEmitter} from '../../../hooks/useEventEmitter.js';
import {useDynamicCaption} from '../../../appState/hooks/useDynamicCaption/index.js';
import QuickAccessModal from '../../modal/modals/QuickAccessModal/index.js';
import {useAtom} from 'jotai';
import {QAItems} from '../../../appState/quickAccessState.js';
import PlanBio from '../../reusable/assets/additionalInformation/items/additionalInformationPlan/items/planBio/PlanBio.jsx';
import useWidthListener from '../../../hooks/useWidthListner..js';
import Box from '../../reusable/cards/box/Box.jsx';
import Dropdown from '../../reusable/assets/dropdown/Dropdown.jsx';
import HubNextLevelInfo from '../../../container/App/Home/hubMainMobile/items/hubNextLevelInfo/HubNextLevelInfo.jsx';
import dummyImage from '../../../assets/badge.png';
import {useRankInfo} from '../../../appState/hooks/useRankInfo/index.js';
import moment from 'moment';
import {HubWalletItems} from '../../../container/App/Home/items/hubWallet/HubWallet.jsx';
import {NewAdditionalInformationPlan} from '../../reusable/assets/additionalInformation/items/additionalInformationPlan/AdditionalInformationPlan.jsx';

const tabs = [{name: 'Current Rank', href: '#', current: true}];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const items = [
  {
    image: {
      src: '/images/hub/main/access/challenges.png',
      alt: 'placeholder',
    },
    title: 'Challenges',
    handler: () => {},
  },
  {
    image: {
      src: '/images/hub/main/access/calendar.png',
      alt: 'placeholder',
    },
    title: 'Book 1-2-1',
    handler: () => {},
  },
  {
    image: {
      src: '/images/hub/main/access/sharing.png',
      alt: 'placeholder',
    },
    title: 'Sharing',
    handler: () => {},
  },
  {
    image: {
      src: '/images/hub/main/access/chat.png',
      alt: 'placeholder',
    },
    title: '...',
    handler: () => {},
  },
  {
    image: {
      src: '/images/hub/main/access/plus-circle.png',
      alt: 'placeholder',
    },
    title: 'Add',
    handler: () => {},
  },
  {
    image: {
      src: '/images/hub/main/access/calendar.png',
      alt: 'placeholder',
    },
    title: 'Gallery',
    handler: () => console.log('gallery'),
  },
];

const MainLayoutWithAspectSide = props => {
  const [open, setOpen] = useState(false);
  const [iopen, setIOpen] = useState(false);
  const [openQuickAccess, setOpenQuickAccess] = useState(false);
  const [openGallery, setOpenGallery] = useState(false);
  const [openViewMore, setOpenViewMore] = useState(false);
  const [myBenifits, setMyBenifits] = useState(false);

  const [editItem, setEditItem] = useState({});
  const {getCaption, dynamicCaption} = useDynamicCaption();
  const {userData, logout} = useBuzzmiAuth();
  const {useLeaderBoardList} = useLeaderBoard();

  const {useGetAllRankInfoList} = useGetAllRankInfo();

  const {rankInfo} = useHubApp();
  const {data: leaderBoardata} = useLeaderBoardList(
    userData && userData?.token,
  );
  const {data: rankinfoList} = useGetAllRankInfoList(
    userData && userData?.token,
  );
  // const { rankInfo } = useRankInfo();

  const linkTo = useLinkTo();
  const resWidth = useWidthListener();
  const isMobile = resWidth < 576;
  const isXL = resWidth > 1279;

  const {qaItems} = useBuzzmiAuth();
  useEffect(() => {
    // console.log(dynamicCaption);
    // console.log(moment().hours());
    getCaption(userData.token);
  }, []);
  const handleQAItemPress = id => {
    console.log('id', id);
    id == 'gallery' ? setOpenGallery(true) : null;
  };

  const RankLadder = () => {
    return (
      <div className="rank-loader">
        {/* <div className="py-[10px] shadow-[rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;]">
          <p>Rank Ladder</p>
        </div> */}
        <p className="px-1 pb-0 text-center text-sm font-medium">
          Track your progress, climb the ranks, earn more rewards and unlock
          exclusive perks. Start earning and climb your way to the top of the
          Buzzmi community!
        </p>
        <div className={'flex justify-around m-4'}>
          <div className={'flex'}>
            <div className={'bg-[#148E19] p-[5px] m-[5px]'} />
            <p className={'text-sm'}>Achieved rank</p>
          </div>
          <div className={'flex'}>
            <div className={'bg-[#fbc02d] p-[5px] m-[5px]'} />
            <p className={'text-sm'}>Current rank</p>
          </div>
          <div className={'flex'}>
            <div className={'bg-[#EBE9F0] p-[5px] m-[5px]'} />
            <p className={'text-sm'}>Next rank</p>
          </div>
        </div>
        <div className="sm:pl-[12px] pl-[8px]">
          <div>
            {rankinfoList?.userRanks &&
              rankinfoList?.userRanks.length > 0 &&
              rankinfoList?.userRanks.map(litem => (
                <div className="buzz-rookie">
                  <div className="flex items-center gap-x-5">
                    <div>
                      <img
                        className={`sm:w-[60px] w-[40px] sm:h-[60px] h-[40px] p-1  ${
                          litem?.isAchived
                            ? litem?.title == rankInfo?.currentRank
                              ? 'bg-[#fbc02d]'
                              : 'bg-[#129200]'
                            : 'bg-[#efefef]'
                        } rounded-full`}
                        src={litem?.image}
                      />
                    </div>
                    <h2
                      className={
                        litem?.title == rankInfo?.currentRank
                          ? 'font-bold'
                          : 'font-medium'
                      }>
                      {litem?.title}
                    </h2>
                  </div>
                  <div className="content sm:pl-[36px] pl-[26px]">
                    <p
                      className={`text-xs sm:text-sm sm:text-left text-slate-600 relative  before:absolute before:content-'' before:w-[3px] sm:before:h-[25px] before:h-[18px] ${
                        litem?.isAchived
                          ? litem?.title == rankInfo?.currentRank
                            ? 'before:bg-[#fbc02d]'
                            : 'before:bg-[#129200]'
                          : 'before:bg-[#dcdcdc]'
                      } before:left-[-8px] before:top-[0px]`}>
                      <p
                        className={
                          litem?.title == rankInfo?.currentRank
                            ? 'border-[#fbc02d] border-x-[3px] border-t-[3px] bg-[#FBF8FF] ml-6 p-[10px] text-black rounded-ss-xl rounded-se-xl'
                            : 'bg-[#FBF8FF]  ml-6 p-[10px] text-black rounded-ss-xl rounded-se-xl'
                        }>
                        {litem?.content}
                      </p>
                    </p>
                    <p
                      className={`text-xs sm:text-sm sm:text-left text-slate-600 relative  before:absolute before:content-'' before:w-[3px] sm:before:h-[20px] before:h-[18px] ${
                        litem?.isAchived
                          ? litem?.title == rankInfo?.currentRank
                            ? 'before:bg-[#fbc02d]'
                            : 'before:bg-[#129200]'
                          : 'before:bg-[#dcdcdc]'
                      } before:left-[-8px] before:top-[0px]`}>
                      <p
                        className={
                          litem?.title == rankInfo?.currentRank
                            ? 'border-[#fbc02d] border-x-[3px] bg-[#FBF8FF] ml-6 px-[10px] p-[5px] text-black'
                            : 'bg-[#FBF8FF] px-[10px] ml-6 p-[5px] text-black'
                        }>
                        <div className={'flex text-xs'}>
                          <p className={'font-medium mr-2'}>Buzzcoins: </p>
                          {litem?.description['Buzzcoins']}
                        </div>
                      </p>
                    </p>
                    <p
                      className={`text-xs sm:text-sm sm:text-left text-slate-600 relative  before:absolute before:content-'' before:w-[3px] sm:before:h-[20px] before:h-[18px] ${
                        litem?.isAchived
                          ? litem?.title == rankInfo?.currentRank
                            ? 'before:bg-[#fbc02d]'
                            : 'before:bg-[#129200]'
                          : 'before:bg-[#dcdcdc]'
                      } before:left-[-8px] before:top-[0px]`}>
                      <p
                        className={
                          litem?.title == rankInfo?.currentRank
                            ? 'border-[#fbc02d] border-x-[3px] bg-[#FBF8FF]  ml-6 px-[10px] p-[5px] text-black'
                            : 'bg-[#FBF8FF] px-[10px] ml-6 p-[5px] text-black'
                        }>
                        <div className={'flex text-xs'}>
                          <p className={'font-medium mr-2'}>Gems: </p>
                          {litem?.description['Gems']}
                        </div>
                      </p>
                    </p>
                    <p
                      className={`text-xs sm:text-sm sm:text-left text-slate-600 relative  before:absolute before:content-'' before:w-[3px] sm:before:h-[20px] before:h-[18px] ${
                        litem?.isAchived
                          ? litem?.title == rankInfo?.currentRank
                            ? 'before:bg-[#fbc02d]'
                            : 'before:bg-[#129200]'
                          : 'before:bg-[#dcdcdc]'
                      } before:left-[-8px] before:top-[0px]`}>
                      <p
                        className={
                          litem?.title == rankInfo?.currentRank
                            ? 'border-[#fbc02d] border-x-[3px] border-b-[3px] bg-[#FBF8FF]  ml-6 px-[10px] p-[5px] text-black rounded-ee-xl rounded-es-xl'
                            : 'bg-[#FBF8FF] px-[10px]  ml-6 p-[5px] text-black rounded-ee-xl rounded-es-xl'
                        }>
                        <div className={'flex text-xs'}>
                          <p className={'font-medium mr-2'}>
                            Gifted Diamonds:{' '}
                          </p>
                          {litem?.description['Gifted Diamonds']}
                        </div>
                      </p>
                    </p>
                    {/* <p className="text-xs sm:text-sm sm:text-left text-slate-600 relative p-[14px] before:absolute before:content-'' before:w-[3px] sm:before:h-[48px] before:h-[60px] before:bg-slate-400 before:left-[-8px] before:top-[0px]">
                    
                  </p> */}
                    {/* <p className="text-xs sm:text-sm sm:text-left text-slate-600 relative p-[14px] before:absolute before:content-'' before:w-[3px] before:h-[58px] before:bg-black before:left-[-8px] before:top-[0px]">
                    Level 2 - <span>32,50 points</span>
                  </p>
                  <p className="text-xs sm:text-sm sm:text-left text-slate-600 relative p-[14px] before:absolute before:content-'' before:w-[3px] before:h-[58px] before:bg-black before:left-[-8px] before:top-[0px]">
                    Level 3 - <span>65,00 points</span>
                  </p> */}
                  </div>
                </div>
              ))}
            {/* <div className="mt-[10px]">
            <div className="flex items-center gap-x-5">
              <div>
                <img className="w-[50px] h-[50px]" src={dummyImage} />
              </div>
              <h2 className="font-bold">Soical Betturfly</h2>
            </div>
            <div className="content pl-[32px]">
              <p className="text-xs sm:text-sm sm:text-left text-slate-600 relative p-[14px] before:absolute before:content-'' before:w-[3px] before:h-[58px] before:bg-black before:left-[-8px] before:top-[0px]">
                Level 1 - <span>100 points</span>
              </p>
              <p className="text-xs sm:text-sm sm:text-left text-slate-600 relative p-[14px] before:absolute before:content-'' before:w-[3px] before:h-[58px] before:bg-black before:left-[-8px] before:top-[0px]">
                Level 2 - <span>32,50 points</span>
              </p>
              <p className="text-xs sm:text-sm sm:text-left text-slate-600 relative p-[14px] before:absolute before:content-'' before:w-[3px] before:h-[58px] before:bg-black before:left-[-8px] before:top-[0px]">
                Level 3 - <span>65,00 points</span>
              </p>
            </div>
          </div> */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <ModalBackdrop />
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full">
                  <Dialog.Panel
                    className="pointer-events-auto w-screen max-w-md"
                    style={{maxWidth: 640}}>
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="p-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            View Rank Ladder
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-pink-500"
                              onClick={() => setOpen(false)}>
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* <div className="border-b border-gray-200">
                        <div className="px-6">
                          <nav className="-mb-px flex space-x-6">
                            {tabs.map(tab => (
                              <a
                                key={tab.name}
                                href={tab.href}
                                className={classNames(
                                  tab.current
                                    ? 'border-pink-500 text-pink-600'
                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                  'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium',
                                )}>
                                {tab.name}
                              </a>
                            ))}
                          </nav>
                        </div>
                      </div> */}
                      <div className="pt-0 px-4 py-4">
                        {/* {console.log(
                          'leaderBoardata?.listOfLeaderboards',
                          leaderBoardata?.listOfLeaderboards,
                        )} */}
                        {/* <Board items={leaderBoardata?.listOfLeaderboards} />
                         */}
                        <RankLadder />
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <MainLayoutV2 page={'home'}>
        {/* <MainNewLayoutSidebar showLogo={false} /> */}
        <div>
          {props.showTitleButton && (
            <header className="border-b border-white/5 pb-0 px-0 sm:px-6 sm:py-6 lg:px-8">
              <h1 className="font-bold leading-4 sm:leading-7 text-base text-center sm:text-left sm:text-2xl mb-2">
                {dynamicCaption?.length > 0 &&
                  dynamicCaption.filter(
                    item =>
                      item?.startTime <= moment().hours() &&
                      item?.endTime >= moment().hours(),
                  )[0]?.caption + `, ${userData?.displayName}`}
              </h1>
              {/* <div className=" mb-2">
                <PlanBio center={true} />
              </div> */}
            </header>
          )}
        </div>
        <div className="">
          <main>
            {props.showRankInfo != undefined &&
            props.showRankInfo == false ? null : (
              <div className="bg-white box mx-auto px-3 sm:pb-4 lg:px-15">
                <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
                  <div>
                    <h3 className="sm:text-xl text-md font-semibold leading-6 text-gray-900">
                      Your Rank
                    </h3>
                  </div>
                  {props.showTitleButton && (
                    <div className="flex-shrink-0">
                      <button
                        type="button"
                        className="relative inline-flex items-center rounded-md sm:bg-[#eb4dac] sm:px-3 py-2 text-sm sm:font-semibold sm:text-white text-[#eb4dac] sm:shadow-sm sm:hover:bg-[#eb4dac] sm:focus-visible:outline focus-visible:outline-2 sm:focus-visible:outline-offset-2 sm:focus-visible:outline-pink-600"
                        // style={{backgroundColor: '#eb4dac'}}
                        onClick={() => {
                          setOpen(!open);
                        }}>
                        View rank ladder
                      </button>
                    </div>
                  )}
                </div>

                <div className="flex justify-between sm:justify-evenly items-center">
                  {/* <div className="rank-card-container pt-8">
                    <div className="rank-row__label justify-center rank-card-container-inner grid-temlate-apply">
                      <div className="u-profile">
                        <img
                          className="rank-row__img m-auto"
                          src={
                            rankInfo?.currentRankBadge
                              ? rankInfo?.currentRankBadge
                              : '/images/logo.ico'
                          }
                          alt="current rank"
                          style={{
                            backgroundColor: '#f3ecfd',
                            objectFit: 'contain',
                            borderRadius: 50,
                            border: '10px solid #f3ecfd',
                          }}
                        />
                      </div>
                      <div className={'h4'}>
                        <p
                          className={
                            'bg-gradient-to-r mt-2 from-[#eb4dac] to-[#c72bb7] bg-clip-text text-transparent'
                          }>
                          {rankInfo?.currentRank}
                        </p>
                      </div>
                    </div>
                  </div> */}
                  <div className="rank-card-container pt-2">
                    <HubWalletItems />
                  </div>
                  <li className="overflow-hiddenn text-center">
                    <CircleChart
                      progress={rankInfo?.progress ? rankInfo?.progress : 0}
                      // withAvatar={props?.isHome ? false : true}
                      withAvatar={true}
                      Image={
                        rankInfo?.currentRankBadge
                          ? rankInfo?.currentRankBadge
                          : '/images/logo.ico'
                      }
                    />
                  </li>

                  <div
                    className="rank-card-container px-0  pt-2"
                    // onClick={() => setOpenViewMore(!openViewMore)}
                  >
                    {/* <div className="rank-row__label justify-center rank-card-container-inner grid-temlate-apply">
                      <div className="u-profile">
                        <img
                          className="rank-row__img m-auto grayscale"
                          src={
                            rankInfo?.nextRankBadge
                              ? rankInfo?.nextRankBadge
                              : '/images/gem_hunter.png'
                          }
                          alt="next rank"
                          style={{
                            backgroundColor: '#f3ecfd',
                            objectFit: 'contain',
                            borderRadius: 50,
                            border: '10px solid #f3ecfd',
                          }}
                        />
                      </div>
                      <div className="text-r">{Strings.next_rank}</div>

                      <div className={'h4'}>
                        <p
                          className={
                            'bg-gradient-to-r mt-2 from-[#f1e9fc] to-[#bbb6b6] bg-clip-text text-transparent'
                          }>
                          {rankInfo?.nextRank}
                        </p>
                      </div>
                    </div> */}
                    <NewAdditionalInformationPlan />
                  </div>
                </div>
              </div>
              // </div>
            )}
            {props.showTitleButton && (
              <p className="my-3 text-xs sm:text-sm text-center sm:text-left text-slate-600">
                {dynamicCaption?.length > 0 &&
                  dynamicCaption.filter(
                    item =>
                      item?.startTime <= moment().hours() &&
                      item?.endTime >= moment().hours(),
                  )[0]?.subCaption}
              </p>
            )}
            {!isXL && props?.isHome && (
              <Box className="mt-3 px-3">
                <Dropdown
                  Content={HubNextLevelInfo}
                  title={Strings.str_how_to_get_next_level}
                  dropDownClass={'__toggle'}
                  openFromPar={openViewMore}
                  setOpenFromPar={setOpenViewMore}
                />
              </Box>
            )}
            {/* {!isXL && props?.isHome && (
              <div className="mt-3">
                <AdditionalInformation setMyBenifits={setMyBenifits} />
              </div>
            )} */}
            {/* {props?.children} */}
          </main>
          <div className="mt-2">{props?.children}</div>
          {/* Activity feed */}
          <aside
            style={{backgroundColor: '#faf7ff', marginTop: 16}}
            className="pt-0 sm:mt-0  xl:fixed xl:bottom-0 xl:right-0 xl:top-16 xl:w-96 xl:overflow-y-auto xl:border-l xl:border-white/5 xl:p-3  [&::-webkit-scrollbar]:hidden [-ms-overflow-style:'none'] [scrollbar-width:'none']">
            {props.onMyTaskBtn != undefined && props.onMyTaskBtn && (
              <div className="text-center mb-4 flex-shrink-0 custom-brn-v2">
                <button
                  onClick={() => {
                    setIOpen(true);
                  }}
                  type="button"
                  className="custom-btn2 inline-flex items-center gap-x-1.5 rounded-md bg-pink-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-pink-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-500 w-full justify-center">
                  <PlusIcon className="-mr-0.5 h-7 w-7" aria-hidden="true" />
                  {/* {Strings.add_my_own_task} */}
                  New Activity
                </button>
              </div>
            )}

            {isXL && <AdditionalInformation />}
            {isXL && (
              <Box className="mt-3 ">
                <Dropdown
                  Content={HubNextLevelInfo}
                  title={Strings.str_how_to_get_next_level}
                  dropDownClass={'__toggle'}
                />
              </Box>
            )}
            {/* <div className="d-flex bottom-mobile-button justify-content-center mt-5">
                <div className="_fw">
                  <PrimaryLink to="/hub/add-activity">
                    {Strings.add_my_own_task}
                  </PrimaryLink>
                </div>
              </div> */}
            {qaItems?.length > 0 && (
              <>
                {(props?.isHome || isXL) && (
                  <div className="mb-15 pt-2 text-center lg:col-span-4 xl:col-span-5">
                    <div
                      className={
                        isMobile
                          ? 'flex justify-between'
                          : 'flex items-center gap-x-4 '
                      }>
                      {!isMobile && (
                        <EyeDropperIcon
                          className="h-5 w-5 mb-4"
                          aria-hidden="true"
                          onClick={() => setOpenQuickAccess(true)}
                        />
                      )}
                      <h3
                        className="mb-4 font-bold text-gray-600 xl:pr-0"
                        onClick={() => setOpenQuickAccess(true)}>
                        {'Quick Access'}
                      </h3>
                      {isMobile && (
                        <p
                          onClick={() => setOpenQuickAccess(true)}
                          className={
                            'bg-gradient-to-r  from-[#eb4dac] to-[#7b1ac6] bg-clip-text text-transparent  text-sm sm:text-base'
                          }>
                          Edit
                        </p>
                      )}
                    </div>

                    <div class="grid grid-cols-2 gap-3 md:grid-cols-5 lg:mt-0 lg:grid-cols-2">
                      {qaItems?.map((item, index) => (
                        <div
                          onClick={() => handleQAItemPress(item?.id)}
                          key={index}
                          class="cursor-pointer col-span-1 justify-center flex items-center sm:flex-col px-1.5 py-1.5 sm:px-8 sm:py-8 rounded-lg bg-white text-xs font-semibold text-gray-900 border-slate-200 border hover:bg-gray-50">
                          <img
                            className="sm:h-12 sm:w-12 w-8 h-8 object-contain hub-access__img"
                            src={item?.image.src}
                            alt="Transistor"
                          />
                          <div className="sm:mt-2 mt-0 flex flex-auto flex-col-reverse">
                            <h3 className="sm:text-sm text-xs font-normal sm:font-semibold text-gray-900 sm:text-center text-left sm:ml-0 ml-2">
                              {item.title}
                            </h3>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </>
            )}
          </aside>
        </div>
      </MainLayoutV2>
      <ChoseMultipleImageModalV2
        show={openGallery}
        setShow={setOpenGallery}
        showSave={false}
        onlyImages={false}
        title={'Gallery'}
        stopSelect={true}
        // savedImages={savedImages => console.log('savedImages', savedImages)}
      />

      <HubAddTaskv2
        param={
          Object.keys(editItem).length > 0 ? {item: editItem, isEdit: true} : ''
        }
        is_open={iopen}
        set_is_open={setIOpen}
      />
      <QuickAccessModal
        is_open={openQuickAccess}
        set_is_open={setOpenQuickAccess}
      />
      {/* <GalleryModal open={openGallery} setOpen={setOpenGallery} /> */}
    </>
  );
};

export default MainLayoutWithAspectSide;
