export const profileDataBlocks = [
  {
    title: "Badges",
    icon: {
      src: "/images/pages/profile/badge.png",
      alt: "Badges",
    },
  },
  {
    title: "Contacts",
    icon: {
      src: "/images/pages/profile/contacts.png",
      alt: "Contacts",
    },
  },
  {
    title: "Activity",
    icon: {
      src: "/images/pages/profile/activity.png",
      alt: "Activity",
    },
  },
]

export const profileMobileDataBlocks = [
  {
    title: "Badges",
    icon: {
      src: "/images/pages/profile/badge.png",
      alt: "Badges",
    },
    isHide: true
  },
  // {
  //   title: "Contacts",
  //   icon: {
  //     src: "/images/pages/profile/contacts.png",
  //     alt: "Contacts",
  //   },
  // },
  // {
  //   title: "Activity",
  //   icon: {
  //     src: "/images/pages/profile/activity.png",
  //     alt: "Activity",
  //   },
  // },
]




