import React, {useMemo} from 'react';
import ResizeComponent from '../../../../components/reusable/assets/resizeComponent/ResizeComponent.jsx';
import HubTasksDesktop from './componants/hubTasksDesktop/HubTasksDesktop.jsx';
import HubTasksMobile from './componants/hubTasksMobile/HubTasksMobile.jsx';
import AdditionalInformation from '../../../../components/reusable/assets/additionalInformation/AdditionalInformation.jsx';
import MainLayoutContent from '../../../../components/layouts/mainLayout/mainLayoutContent/MainLayoutContent.jsx';
import MainLayout from '../../../../components/layouts/mainLayout/MainLayout.jsx';
import {useHubApp} from '../../../../appState/hooks';
import MainLayoutWithAspectSide from '../../../../components/layouts/mainLayout/MainLayoutWithAspectSide.jsx';

// const mockItems = [
//   {
//     title: 'Any time of the day',
//     list: [
//       {
//         id: 'dwqdsads',
//         href: '/hub/add-activities',
//         image:
//           'https://onesay-backend-dev.s3.amazonaws.com/398fe95b-cca1-4cdb-b5ac-f9802fc3df3b-gym.png',
//         badges: [],
//         title: 'Strength Traning',
//         description:
//           'Place your feet and hips apart, and grab a pair of dumbbells. Bend your knees slightly, and hinge at the',
//       },
//       {
//         id: 'kuykmt',
//         href: '/hub/add-activities',
//         image:
//           'https://onesay-backend-dev.s3.amazonaws.com/4bc1d577-1b3d-43ba-ad9b-86c29780c99f-drink.png',
//         badges: [],
//         title: 'Drink your water',
//         description:
//           'Drink up! Increasing your daily water intake is vital as you to stay healthy. Dehydration can cause fatigue, indigestion, a...',
//       },
//       {
//         id: 'fwebtrtt',
//         href: '/hub/add-activities',
//         image:
//           'https://onesay-backend-dev.s3.amazonaws.com/4bc1d577-1b3d-43ba-ad9b-86c29780c99f-drink.png',
//         badges: [],
//         title: 'Drink your water',
//         description:
//           'Drink up! Increasing your daily water intake is vital as you to stay healthy. Dehydration can cause fatigue, indigestion, a...',
//       },
//     ],
//   },
//   {
//     title: 'Any time of the day',
//     list: [
//       {
//         id: 'dwqdsads',
//         href: '/hub/add-activities',
//         image:
//           'https://onesay-backend-dev.s3.amazonaws.com/398fe95b-cca1-4cdb-b5ac-f9802fc3df3b-gym.png',
//         badges: [],
//         title: 'Strength Traning',
//         description:
//           'Place your feet and hips apart, and grab a pair of dumbbells. Bend your knees slightly, and hinge at the',
//       },
//       {
//         id: 'kuykmt',
//         href: '/hub/add-activities',
//         image:
//           'https://onesay-backend-dev.s3.amazonaws.com/4bc1d577-1b3d-43ba-ad9b-86c29780c99f-drink.png',
//         badges: [],
//         title: 'Drink your water',
//         description:
//           'Drink up! Increasing your daily water intake is vital as you to stay healthy. Dehydration can cause fatigue, indigestion, a...',
//       },
//       {
//         id: 'fwebtrtt',
//         href: '/hub/add-activities',
//         image:
//           'https://onesay-backend-dev.s3.amazonaws.com/4bc1d577-1b3d-43ba-ad9b-86c29780c99f-drink.png',
//         badges: [],
//         title: 'Drink your water',
//         description:
//           'Drink up! Increasing your daily water intake is vital as you to stay healthy. Dehydration can cause fatigue, indigestion, a...',
//       },
//     ],
//   },
//   {
//     title: 'Any time of the day',
//     list: [
//       {
//         id: 'dwqdsads',
//         href: '/hub/add-activities',
//         image:
//           'https://onesay-backend-dev.s3.amazonaws.com/398fe95b-cca1-4cdb-b5ac-f9802fc3df3b-gym.png',
//         badges: [],
//         title: 'Strength Traning',
//         description:
//           'Place your feet and hips apart, and grab a pair of dumbbells. Bend your knees slightly, and hinge at the',
//       },
//       {
//         id: 'kuykmt',
//         href: '/hub/add-activities',
//         image:
//           'https://onesay-backend-dev.s3.amazonaws.com/4bc1d577-1b3d-43ba-ad9b-86c29780c99f-drink.png',
//         badges: [],
//         title: 'Drink your water',
//         description:
//           'Drink up! Increasing your daily water intake is vital as you to stay healthy. Dehydration can cause fatigue, indigestion, a...',
//       },
//       {
//         id: 'fwebtrtt',
//         href: '/hub/add-activities',
//         image:
//           'https://onesay-backend-dev.s3.amazonaws.com/4bc1d577-1b3d-43ba-ad9b-86c29780c99f-drink.png',
//         badges: [],
//         title: 'Drink your water',
//         description:
//           'Drink up! Increasing your daily water intake is vital as you to stay healthy. Dehydration can cause fatigue, indigestion, a...',
//       },
//     ],
//   },
// ];

const HubTasks = props => {
  // const {
  //   // taskList,

  //   tagsList,
  //   plannedActivities,
  //   plannedActivitiesStats,
  // } = useHubApp();

  // const activityMap = {};

  // plannedActivities.forEach(item => {
  //   if (!activityMap[item.timing]) {
  //     activityMap[item.timing] = [];
  //   }
  //   activityMap[item.timing].push(item);
  // });

  // const sortedTimings = ['Morning', 'Afternoon', 'Evening', 'Any time'];

  // const dailyTasks = useMemo(
  //   () =>
  //     sortedTimings
  //       .map(timing => ({
  //         title: timing.charAt(0).toUpperCase() + timing.slice(1),
  //         list: activityMap[timing] || [],
  //       }))
  //       .filter(task => task.list.length > 0),
  //   [activityMap, plannedActivities],
  // );

  // console.log('props?.route?.params?.tab', props?.route?.params?.tab);
  const selectedTab =
    props?.route?.params?.tab === 'leaderboard'
      ? 2
      : props?.route?.params?.tab === 'tasks'
      ? 0
      : 0;
  // console.log('selectedTab' + selectedTab);
  return (
    <MainLayoutWithAspectSide showTitleButton={false} showRankInfo={false}>
      <HubTasksDesktop
        selectedTab={selectedTab}
        // data={dailyTasks}
        // tagsData={tagsList}
        // plannedActivities={plannedActivities}
        // plannedActivitiesStats={plannedActivitiesStats}
      />
    </MainLayoutWithAspectSide>
  );
};

export default HubTasks;
