import React, { useEffect, useRef, useState } from 'react';
import ShareIcon from '../../../icons/ShareIcon';
import { useDispatch } from 'react-redux';
import { openModal } from '../../../../../../../store/slices/modalSlice/modalSlice';
import { useProfileApp } from '../../../../../../../appState/hooks';
import { useBuzzmiAuth } from '../../../../../../../appState/hooks/useBuzzmiAuth/index.web';
import Loading from 'react-fullscreen-loading';
import { colors } from '../../../../../../../res/colors';
import SharePostModal from '../../../../ProfileMain/componentsV2/SharePostModel';

const SharePostBtnV2 = ({ userName, userAvatar, postId, post }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { getDynamicLink, getPostReelDynamicLink, linkLoad } = useProfileApp();
  const { userData } = useBuzzmiAuth();
  const [openShareModal, setOpenShareModal] = useState(false);
  const [modalData, setModalData] = useState({});


  useEffect(() => {
    if (getDynamicLink) {
      setModalData({
        userName,
        userAvatar,
        userShareDetails: Object.assign(getDynamicLink, {
          thumbnail: post?.imageUrl,
        }),
      });
      setOpenShareModal(true);

      // dispatch(
      //   openModal({
      //     modalName: 'ShareModal',
      //     modalType: 'swipe-pink',
      //     modalProps: {
      //       userName,
      //       userAvatar,
      //       userShareDetails: Object.assign(getDynamicLink, {
      //         thumbnail: post?.imageUrl,
      //       }),
      //     },
      //     modalTitle: 'Share',
      //   }),
      // );
    }
  }, [getDynamicLink]);

  const handleOpenModal = () => {
    getPostReelDynamicLink('post', postId, userData?.token);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <Loading
        loading={linkLoad}
        background="transparent"
        loaderColor={colors.PRIMARY_COLOR}
      />
      <div
        className="post-buttons__item d-flex align-items-center"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleOpenModal}>
        <ShareIcon isHovered={isHovered} />
      </div>
      <SharePostModal
        open={openShareModal}
        data={{ entity: 'post', id: postId, ...modalData }}
        setOpen={setOpenShareModal}
      />
    </>
  );
};

export default SharePostBtnV2;
