import React from "react"

const CommentsIcon = ({ isHovered }) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.8667 10.0128C1.8667 5.62293 5.37503 1.66699 10.2167 1.66699C14.95 1.66699 18.5334 5.54781 18.5334 9.98781C18.5334 15.1372 14.3334 18.3337 10.2 18.3337C8.83337 18.3337 7.3167 17.9664 6.10003 17.2487C5.67503 16.99 5.3167 16.798 4.85837 16.9482L3.17503 17.449C2.75003 17.5825 2.3667 17.2487 2.4917 16.798L3.05003 14.9286C3.1417 14.6698 3.12503 14.3944 2.9917 14.1774C2.27503 12.8588 1.8667 11.4149 1.8667 10.0128ZM6.3417 11.0895C5.75837 11.0895 5.27503 10.6054 5.27503 10.0212C5.27503 9.42863 5.75003 8.95292 6.3417 8.95292C6.93337 8.95292 7.40837 9.42863 7.40837 10.0212C7.40837 10.6054 6.93337 11.0811 6.3417 11.0895ZM9.1167 10.0132C9.1167 10.6057 9.5917 11.0814 10.1834 11.0898C10.775 11.0898 11.25 10.6057 11.25 10.0215C11.25 9.42895 10.775 8.95323 10.1834 8.95323C9.60003 8.94489 9.1167 9.42895 9.1167 10.0132ZM12.9584 10.0215C12.9584 10.6057 13.4334 11.0898 14.025 11.0898C14.6167 11.0898 15.0917 10.6057 15.0917 10.0215C15.0917 9.42895 14.6167 8.95323 14.025 8.95323C13.4334 8.95323 12.9584 9.42895 12.9584 10.0215Z"
        fill={isHovered ? "url(#commentsGradient)" : "#C0BACB"}
      />
      {isHovered && (
        <defs>
          <linearGradient id="commentsGradient" gradientTransform="rotate(45)">
            <stop offset="0%" stopColor="#EF8ED5" />
            <stop offset="50%" stopColor="#EC44D0" />
            <stop offset="100%" stopColor="#C1169C" />
          </linearGradient>
        </defs>
      )}
    </svg>
  )
}

export default CommentsIcon
