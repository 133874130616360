import React from 'react';
import GradientBorder from '../../../../../../../components/reusable/assets/gradientBorder/GradientBorder';
import { useLinkTo } from '@react-navigation/native';

const PostAuthorInfo = ({
  userName,
  userNickname,
  userPosition,
  userAvatar,
  isVerified,
  sizeSmall = false,
  isMyPost,
  post
}) => {

  const linkTo = useLinkTo();


  return (
    <div className="header-post__author-info-wrapper">
      {sizeSmall ? (
        <div onClick={() => { isMyPost ? null : linkTo(`/profile/user/${post?.post?.user?.id}`); }}>
          <img
            src={userAvatar ? userAvatar : '/images/moc/avatar-big.png'}
            className={'header-post__avatar_v2'}
          />
        </div>
      ) : (
          <div onClick={() => {
            isMyPost ? null : linkTo(`/profile/user/${post?.post?.user?.externalId}`);
          }}>
            <GradientBorder>
              <img
                src={userAvatar ? userAvatar : '/images/moc/avatar-big.png'}
                className={'header-post__avatar'}
              />
            </GradientBorder>
          </div>
        )}

      <div className="header-post__author-info" onClick={() => {
        isMyPost ? null : linkTo(`/profile/user/${post?.post?.user?.externalId}`);
      }}>
        <div className="d-flex flex-row align-items-center header-post__author-name">
          <p className="h6">{userName}</p>
          {isVerified ? (
            <img
              src="/images/post/profile-approved.svg"
              alt="profile approved"
            />
          ) : (
              ''
            )}
        </div>

        <p className="header-post__author-nickname text-r">@{userNickname}</p>
        <p className="header-post__author-job-title text-s">{userPosition}</p>
      </div>
    </div>
  );
};

export default PostAuthorInfo;
