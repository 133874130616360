import React from 'react';
import Timer from "../../../../components/reusable/assets/timer/Timer.jsx";
import SubscriptionCard from "./items/SubscriptionCard.jsx";
import { HardCode } from "../../../../components/reusable/assets/showMore/ShowMore.jsx";
import { Link } from "react-router-dom";
import PrimaryButton from "../../../../components/reusable/form/items/buttons/PrimaryButton.jsx";
import SubscriptionOffer from "./items/subscriptionOffer/SubscriptionOffer.jsx";
import BaseLayout from "../../../../components/layouts/baseLayout/BaseLayout";
import { useNavigation } from "@react-navigation/native";
import Strings from '../../../../string_key/Strings.web.js';

const SubscriptionV2 = () => {
  const { navigate } = useNavigation();
  return (
    <BaseLayout>
      <div className='subscription'>
        <div className='h5'>
          {Strings.one_subscription_endless_possblities}
        </div>
        <div className='text-r'>
          {Strings.unlimited_access_to_100s_of_creators_and_all_premium_features}
        </div>
        <div className='subscription__img'>
          <img src="/images/sale/girl.png" alt="img" />
          <div className='subscription__counter-circle circle-counter'>
            <div className='circle-counter__top'>
              +1K
            </div>
            <div className='circle-counter__bottom'>
              creators
            </div>
          </div>
        </div>
        <div className='h6'>
          {Strings.get_more_of_the_app_and}
        </div>
        <div className='h2'>
          {Strings.save_40}
        </div>
        <div className='text-alt-primary subscription__timer'>
          <Timer eventDate={new Date("2023-03-22T12:00:00")} />
        </div>
        <div className='test-s text-secondary '>
          {Strings.your_first_7_days_free_no_commitment_cancel_anytime}
        </div>
        <div className='subscription__promotion'>
          <div className='subscription__cards'>
            <SubscriptionCard
              title='Premium Member'
              discount={40}
              icon={'/images/sale/target.png'}
              text={Strings.premium_description}
              content={<HardCode />}
              specialOffer='And Special offer'
            />
          </div>
          <SubscriptionOffer />
        </div>

        <div className='text-s text-secondary subscription__note'>
          <p>
            Billed Yearly. Cancel Anytime.
            The subscription will automatically renew unless auto-renew is turned off at least 24 hours before the end
            of the current period. You can go to your Account settings to manage your subscription and turn off
            auto-renew.
          </p>
          <p>
            If any problem, Please Contact Us in “Profile - FAQ & Feedback”.
          </p>
        </div>

        <div className='subscription__btn'>
          <PrimaryButton onClick={() => navigate('Pay')}>{Strings.subscribe}</PrimaryButton>
        </div>
      </div>
    </BaseLayout>
  );
};

export default SubscriptionV2;
