// @ts-ignore
import React, {useState, useEffect} from 'react';
import {
  POST_VOTE,
  COMUNITY_POST_BY_ID,
  COMMUNITY_REEL_BY_ID,
  COMMUNITY_POST_LIST_REEL,
  COMMUNITY_USER_POST_LIST_REEL,
  COMMUNITY_POST_REEL_V2,
} from '../../../api_helper/Api';
import {
  DELETE_DATA,
  GET_DATA_ASYNC,
  POST_DATA,
} from '../../../api_helper/ApiServices';
import {getData, getDataAsync} from '../../../res/asyncStorageHelper';
import {globalReelsAtom, userReelsAtom} from '../../reelsState';
import deviceInfoModule from 'react-native-device-info';
import {useAtom} from 'jotai';
import {_userDetailsAtom} from '../../userState';
import {useEncryptedAtom} from '../useEncryptedAtom';
import {useBuzzmiAuth} from '../useBuzzmiAuth/index';
import AsyncStorage from '@react-native-community/async-storage';
import {saveData} from '../../../res/asyncStorageHelper';

export const useReels = () => {
  const {userData} = useBuzzmiAuth();
  const [postData, setPostData] = useState([]);
  const [userReelData, setUserReelData] = useAtom(userReelsAtom);
  const [globalReelData, setGlobalReelData] = useAtom(globalReelsAtom);

  const getItem = async key => {
    if (key === 'cache-posts') {
      try {
        const value = await AsyncStorage.getItem(key);
        if (value !== null) {
          // value exists
          const v = JSON.parse(value);
          // console.log('cached items', v?.length);
          v?.length ? setPostData(v) : postData;
        } else {
          // value does not exist
        }
      } catch (error) {
        // error retrieving data
        // console.log(error);
      }
    }
    if (key === 'cache-reels') {
      try {
        const value = await AsyncStorage.getItem(key);
        if (value !== null) {
          // value exists
          const v = JSON.parse(value);
          // console.log('cached items', v?.length);
          v?.length ? setGlobalReelData(v) : globalReelData;
        } else {
          // value does not exist
        }
      } catch (error) {
        // error retrieving data
        // console.log(error);
      }
    }
  };
  useEffect(() => {
    getItem('cache-posts');
    getItem('cache-reels');
  }, []);

  const actionGetUserReels = async () => {
    try {
      const uniqueID = await deviceInfoModule.getUniqueId();
      const data = await GET_DATA_ASYNC(
        COMMUNITY_USER_POST_LIST_REEL + '?page=1&limit=100',
        true,
        userData.token,
        uniqueID,
      );

      const videoArray = data?.filter(el => el.videos.length > 0);
      const arr = videoArray.map((arrayItem, index) => ({
        content: arrayItem.content,
        _id: index + 1,
        postItem: arrayItem,
        counts: arrayItem.counts,
        comments: arrayItem.comments,
        uri: {uri: arrayItem.videos[0].src[0].url},
      }));

      setUserReelData(arr);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUserReelList = async (userData, pageParam) => {
    const uniqueID = await deviceInfoModule.getUniqueId();
    const data = await GET_DATA_ASYNC(
      COMMUNITY_USER_POST_LIST_REEL + `?page=${pageParam}&limit=${PAGE_LIMIT}`,
      true,
      userData.token,
      uniqueID,
    );
    if (data?.length > 0) {
      const videoArray = data?.filter(el => el.videos.length > 0);
      const arr = videoArray.map((arrayItem, index) => ({
        content: arrayItem.content,
        _id: index + 1,
        postItem: arrayItem,
        counts: arrayItem.counts,
        comments: arrayItem.comments,
        uri: {uri: arrayItem.videos[0].src[0].url},
      }));
      return arr;
    }
  };

  const useUserReelList = userdata =>
    useQuery(
      ['userreelList', userdata],
      ({pageParam = userdata?.page}) => fetchUserReelList(userdata, pageParam),
      {
        keepPreviousData: false,
        refetchOnWindowFocus: false,
        staleTime: 0,
        cacheTime: 0,
        refetchInterval: 0,
      },
    );

  const fetchReelList = async (userData, pageParam) => {
    const uniqueID = await deviceInfoModule.getUniqueId();
    const data = await GET_DATA_ASYNC(
      COMMUNITY_POST_REEL_V2 + `?page=${pageParam}&limit=${PAGE_LIMIT}`,
      true,
      userData.token,
      uniqueID,
    );
    // if (data?.length > 0) {
    //   const videoArray = data?.filter(el => el.post.videos.length > 0);
    //   const arr = videoArray.map((arrayItem, index) => ({
    //     content: arrayItem.post.content,
    //     _id: arrayItem?._id,
    //     postItem: arrayItem.post,
    //     counts: arrayItem.post.counts,
    //     comments: arrayItem.post.comments,
    //     uri: { uri: arrayItem.post.videos[0].src[0].url },
    //   }));
    //   return arr
    // }
    if (data?.nodes.length > 0) {
      var arr = [];
      data?.nodes.forEach((arrayItem, index) => {
        if (getContentValue(arrayItem.fields).includes('http')) {
          arr.push(
            Object.assign({
              content: getContentValue(arrayItem.fields, 'title'),
              _id: arrayItem.id,
              postItem: [],
              counts: arrayItem.repliesCount,
              comments: [],
              uri: {uri: getContentValue(arrayItem.fields)},
              dataItem: arrayItem,
            }),
          );
        }
      });
      return arr;
    }
  };

  const useReelList = userdata =>
    useQuery(
      ['reelList', userdata],
      ({pageParam = userdata?.page}) => fetchReelList(userdata, pageParam),
      {
        keepPreviousData: false,
        refetchOnWindowFocus: false,
        staleTime: 0,
        cacheTime: 0,
        refetchInterval: 0,
      },
    );

  const actionGetReelList = async () => {
    try {
      const uniqueID = await deviceInfoModule.getUniqueId();
      const response = await GET_DATA_ASYNC(
        COMMUNITY_POST_LIST_REEL + '?page=1&limit=100',
        true,
        userData.token,
        uniqueID,
      );

      const videoArray = response.filter(el => el?.post?.videos?.length > 0);
      const arr = videoArray.map((arrayItem, index) => {
        return {
          content: arrayItem?.post?.content,
          _id: index + 1,
          postItem: arrayItem?.post,
          counts: arrayItem?.post?.counts,
          comments: arrayItem?.post?.comments,
          uri: {uri: arrayItem?.post?.videos?.[0].src?.[0]?.url},
        };
      });

      saveData('cache-reels', arr);
      setGlobalReelData(arr);
    } catch (error) {
      console.error(error);
    }
  };

  const actionAppendReelList = async (newData: any) => {
    setUserReelData(userReelData => [newData, ...userReelData]);
    setGlobalReelData(globalReelData => [newData, ...globalReelData]);
  };
  const actionPostLike = async (post_id: any, callback: any) => {
    let postData = {postId: post_id};
    // getData('userDetails', userData => {
    //   POST_DATA(POST_VOTE(post_id), userData.token, postData, (data: any, flag: any) => {
    //     callback(data, flag)
    //   })
    // })
    POST_DATA(
      POST_VOTE(post_id),
      userDetails.token,
      postData,
      (data: any, flag: any) => {
        callback(data, flag);
      },
    );
  };
  const actionPostDisLike = async (post_id: any, callback: any) => {
    // getData('userDetails', userData => {
    //   DELETE_DATA(POST_VOTE(post_id), userData.token, (data: any, flag: any) => {
    //     callback(data, flag)
    //   })
    // })
    DELETE_DATA(
      POST_VOTE(post_id),
      userDetails.token,
      (data: any, flag: any) => {
        callback(data, flag);
      },
    );
  };
  const actionDeletePost = async (
    user_token: any,
    post_id: any,
    callback: any,
  ) => {
    // console.log(COMUNITY_POST_BY_ID(post_id))
    DELETE_DATA(
      COMUNITY_POST_BY_ID(post_id),
      user_token,
      (data: any, flag: any) => {
        callback(data, flag);
      },
    );
  };
  const actionReelDelete = async (
    user_token: any,
    reel_id: any,
    callback: any,
  ) => {
    DELETE_DATA(
      COMMUNITY_REEL_BY_ID(reel_id),
      user_token,
      (data: any, flag: any) => {
        callback(data, flag);
      },
    );
  };
  const actionClearData = () => {
    setGlobalReelData([]);
    setUserReelData([]);
  };

  return {
    postData,
    setPostData,
    userReelData,
    globalReelData,
    actionPostLike,
    actionClearData,
    actionDeletePost,
    actionReelDelete,
    actionGetReelList,
    useReelList,
    useUserReelList,
    actionPostDisLike,
    actionGetUserReels,
    actionAppendReelList,
  };
};
