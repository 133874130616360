import React, { useEffect, useState } from 'react';
import ShareModalBottomMenuV2 from './ShareModalBottomMenuV2';
import FriendsList from '../../../../container/App/Profile/items/profilePost/items/friends/FriendsList';
import Button from '../../../reusable/btns/buttons/Button';
import { useHubApp } from '../../../../appState/hooks/useHubApp/index.web';
import PrimaryButton from '../../../reusable/btns/buttons/PrimaryButton';
import { PaperAirplaneIcon } from '@heroicons/react/24/solid';
import Loading from 'react-fullscreen-loading';
import { colors } from '../../../../res/colors';

const ShareActivityModalV2 = ({ data, isShare, isAssign }) => {
  console.log('data hete in shared modal', data);
  const {
    ShareActivity,
    isSharePostLoading,
    AssignActivity,
    AssignPostResponse,
    isAssignPostLoading,
  } = useHubApp();
  const [multipleIds, setmultipleIds] = useState([]);

  useEffect(() => {
    if (AssignPostResponse?.message) {
      setTimeout(() => {
        setmultipleIds([])
      }, 350);
    }
  }, [AssignPostResponse])

  const handleToggleId = id => {
    setmultipleIds(prevIds => {
      // Check if the ID is already in the array
      const isIdInArray = prevIds.includes(id);

      if (isIdInArray) {
        // If ID is in the array, remove it
        return prevIds.filter(existingId => existingId !== id);
      } else {
        // If ID is not in the array, add it
        return [...prevIds, id];
      }
    });
  };

  return (
    <div className="share-modal">
      <Loading
        loading={isAssignPostLoading}
        background="transparent"
        loaderColor={colors.PRIMARY_COLOR}
      />
      <div className="share-modal__object-to-share d-flex flex-row align-itmes-center mb-0 w-full">
        <img
          src={data?.image}
          alt="author avatar"
          className="share-modal__author-avatar"
        />

        <div className="d-flex flex-column share-modal__info">
          <p className="h6">{data?.title}</p>
          <p className="text-r">{data?.description}</p>
        </div>
      </div>
      {/* {userShareDetails?.QR && (
        <div
          style={{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}>
          <img
            className={'object-cover'}
            src={userShareDetails?.QR}
            alt="Red dot"
            style={{width: '100%', maxWidth: '340px'}}
          />
        </div>
      )}

      {userShareDetails?.thumbnail && (
        <div
          className="min-h-[500px] m-3"
          style={{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}>
          <img
            className={'object-cover'}
            src={userShareDetails?.thumbnail}
            alt="Red dot"
            style={{width: '100%', maxWidth: '340px'}}
          />
        </div>
      )} */}
      {/* {isAssign && (
        <div className="flex  justify-end mt-2">
          <button
            onClick={() => {
              multipleIds?.length > 0 &&
                AssignActivity({taskId: data?.id, receiverIds: multipleIds});
            }}
            className="items-center justify-center rounded-full overflow-hidden shadow-lg bg-pink-500  text-white px-2 py-1 text-xs font-semibold hover:bg-pink-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600 mr-2">
            Assign
          </button>
        </div>
      )} */}
      <FriendsList
        isMultiple={isAssign}
        onClickItem={handleToggleId}
        multipleList={multipleIds}
        AssignPostResponse={AssignPostResponse}
        topComponent={() => {
          if (!isAssign) return null;
          return (
            <div className="flex  justify-end mt-2">
              <button
                onClick={() => {
                  multipleIds?.length > 0 &&
                    AssignActivity({
                      taskId: data?.id,
                      receiverIds: multipleIds,
                    });
                }}
                className={`${multipleIds?.length > 0
                  ? ' bg-pink-500  text-white '
                  : ' bg-white  text-pink-500'
                  }  border-2 border-pink-500 items-center justify-center rounded-full overflow-hidden shadow-lg px-2 py-1 text-xs font-semibold hover:bg-pink-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600 mr-2`}>
                Assign
              </button>
            </div>
          );
        }}
        actionComponent={item => {
          if (isAssign) return null;
          return (
            <Button
              className="button_secondary button_thin btn-text border-pink-500 border-3"
              onClick={e => {
                e.preventDefault();
                console.log('item', item);
                isShare
                  ? ShareActivity({ taskId: data?.id, receiverId: item?.id })
                  : null;
              }}>
              {isShare ? 'Share' : ''}
            </Button>
          );
        }}
      />

      {isAssign && <div className="w-96"></div>}
      {!isAssign && (
        <ShareModalBottomMenuV2 userShareDetails={data?.userShareDetails} />
      )}
    </div>
  );
};

export default ShareActivityModalV2;
