import React from 'react';
import Box from '../../../../../components/reusable/cards/box/Box';
import CustomLink from '../../../../../components/reusable/btns/links/CustomLink';
// import ProfileDesktopTabs from '../../items/profileDesktopTabs/ProfileDesktopTabs';
import ProfileDesktopTabs from '../../items/profileDesktopTabsV2/ProfileDesktopTabs';
import ProfileBio from '../../items/ProfileBio';
import ProfileStories from '../../items/profileStories/ProfileStories';
import Avatar from '../../../../../components/reusable/assets/avatar/Avatar';
import CreatePostInput from '../componentsV2/CreatePostInput';

const ProfileMainDesktopV2 = () => {
  return (
    <>
      {/* <Box paddingMode="big" className="profile-top__desktop-wrap">
        <Avatar />
        <ProfileBio /> */}
      {/* <div className="profile-top__btns-d">
          <CustomLink
            className="_fw button_primary button_s"
            to="/hub/add-activities">
            Add activity
          </CustomLink>
          <CustomLink
            className="_fw button_secondary  button_s"
            to="/profile/edit">
            Edit profile
          </CustomLink>
        </div> */}
      {/* </Box> */}
      {/* <ProfileStories /> */}
      <Box paddingMode="small" className={'mb-4 max-w-3xl'}>
        <CreatePostInput />
      </Box>
      <ProfileDesktopTabs />
    </>
  );
};

export default ProfileMainDesktopV2;
