import React from 'react';
import cn from 'classnames';

const EditProfileSetSkills = ({setValue, values}) => {
  const handleAddSkill = (e, index) => {
    const newValues = [...values];
    newValues[index] = e.target.value;
    setValue('skills', newValues);
  };

  const handleAddNewSkill = () => {
    console.log(values)
    const newValues = [...values, ''];
    setValue('skills', newValues);
  };

  const removeExistSkill = index => {
    if (index > -1) {
      values.splice(index, 1);
    }
    const newValues = [...values];
    setValue('skills', newValues);
  };

  return (
    <div className="input-label text-right">
      <div className="label-text">Your skills</div>
      <div className="mb-3 mb-md-4">
        {values?.length > 0 &&
          values?.map((el, i) => (
            <div className="input_skil">
              <input
                key={i + 'skill'}
                className={cn(
                  'input input_simple-text input-wrapper d-inline-block',
                  {
                    'mb-0': i === values.length - 1,
                  },
                )}
                placeholder="Write your skill"
                onChange={e => handleAddSkill(e, i)}
                value={values[i]}
              />
              <div
                className="close_icon"
                onClick={() => {
                  removeExistSkill(i);
                }}>
                <i class="fa-solid fa-xmark"></i>
              </div>
            </div>
          ))}
      </div>
      <button
        className="text-alt-primary text-r"
        type={'button'}
        onClick={handleAddNewSkill}>
        + Add skill
      </button>
    </div>
  );
};

export default EditProfileSetSkills;
