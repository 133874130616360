import React from 'react';

const LogoSvg = () => {
  const rand1 = Math.random()
  const rand2 = Math.random()
  const rand3 = Math.random()
  return (
    <svg width="91" height="101" viewBox="0 0 91 101" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={`url(#paint0_linear_727_7581${rand1})`} d="M87.1509 28.5985C82.8101 17.4975 74.3965 8.74522 63.448 3.9495C52.5042 -0.841536 40.3424 -1.09937 29.2058 3.22756C11.3016 10.1844 -0.1407 27.7031 0.738754 46.8297C0.846922 49.2299 2.90682 51.0817 5.29592 50.9738C7.70384 50.866 9.56621 48.8315 9.45334 46.4313C8.74789 31.0362 17.9563 16.935 32.3709 11.3329C41.3347 7.84983 51.1263 8.0561 59.9349 11.9142C68.7435 15.7724 75.5205 22.8183 79.0148 31.7534C86.2244 50.2003 77.0349 71.0615 58.5287 78.2481C53.9198 80.0388 49.057 80.8592 44.1988 80.6858C44.6127 81.8765 44.2552 83.5313 43.0184 84.0611C40.8691 84.9846 39.43 86.7847 38.2449 88.8193C40.6434 89.2131 43.0513 89.41 45.4592 89.41C50.9711 89.41 56.4641 88.3833 61.6938 86.3534C84.6866 77.423 96.1007 51.513 87.1415 28.5938L87.1509 28.5985Z" />
      <path fill={`url(#paint1_linear_727_7581${rand2})`} d="M79.018 31.7583C86.2277 50.2052 77.0381 71.0663 58.5319 78.2529C53.923 80.0437 49.0602 80.8641 44.202 80.6906C44.6159 81.8813 44.2584 83.5362 43.0216 84.0659C40.8723 84.9894 39.4332 86.7896 38.248 88.8241C40.6466 89.2179 43.0545 89.4148 45.4624 89.4148C50.9743 89.4148 56.4673 88.3881 61.697 86.3583C84.6898 77.4278 96.1039 51.5178 87.1447 28.5986L79.0133 31.7583H79.018Z" />
      <path fill={`url(#paint2_linear_727_7581${rand3})`} d="M26.8908 100.15C25.9267 100.15 24.9532 99.8876 24.0785 99.3579C21.9386 98.0547 20.9886 95.4857 21.7646 93.1089L30.2017 67.3067H18.703C17.071 67.3067 15.5426 66.5801 14.5173 65.319C13.4874 64.0533 13.0923 62.4172 13.431 60.8233L13.4733 60.6171L13.5391 60.4155L24.7886 25.0031C25.4188 22.6873 27.5586 21.0371 29.9901 21.0371H53.4673C55.2638 21.0371 56.9333 21.9231 57.9398 23.4092C58.9415 24.8953 59.139 26.7704 58.4618 28.4299L50.3351 48.3676H67.8019C69.984 48.3676 71.9405 49.6661 72.7776 51.6772C73.6147 53.6884 73.1632 55.9854 71.6254 57.5277L30.7096 98.5703C29.6656 99.6157 28.2876 100.16 26.8908 100.16V100.15ZM23.2648 58.606H34.8012C36.5225 58.606 38.1544 59.4357 39.1608 60.8233C40.172 62.211 40.4589 64.0111 39.9227 65.6425L34.4062 82.5049L59.7786 57.0543H45.3735C43.5769 57.0543 41.9074 56.1683 40.9009 54.6822C39.8992 53.1961 39.7017 51.321 40.3789 49.6614L48.5056 29.7285H32.4403L23.2648 58.606Z" />
      <defs>
        <linearGradient i id={`paint0_linear_727_7581${rand1}`} x1="30.2086" y1="2.84291" x2="62.5253" y2="86.04" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EF8FD5" />
          <stop offset="1" stopColor="#EB33CF" />
        </linearGradient>
        <linearGradient id={`paint1_linear_727_7581${rand2}`} x1="82.7569" y1="54.4899" x2="28.3087" y2="74.1874" gradientUnits="userSpaceOnUse">
          <stop offset="0.07" stopColor="#EF8FD5" stopOpacity="0" />
          <stop offset="1" stopOpacity="0.4" />
        </linearGradient>
        <linearGradient id={`paint2_linear_727_7581${rand3}`} x1="13.3087" y1="60.5889" x2="73.1962" y2="60.5889" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EF8FD5" />
          <stop offset="1" stopColor="#EB33CF" />
        </linearGradient>
      </defs>
    </svg>
  );
};



export default LogoSvg;

