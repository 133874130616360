import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronDoubleRightIcon, EyeIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import Tooltip from "../../../../../../../components/reusable/assets/tooltip/Tooltip.jsx"


const ProfileDataBadges = ({ data, showAll }) => {
  const parseData = showAll ? data : data.length > 3 ? data.slice(0, 4) : data;

  return (
    <div className="profile-badges-info ">
      {parseData?.length > 0 &&
        parseData.map(item => (
          <Link to={`badges/${item.id}`} key={item.id + 'badge'}>
            <Tooltip hide position={"bottom"} tooltipText={item?.info}>
              <img
                src={item.icon.src}
                alt={item.icon.alt}
                style={{ height: 50, width: 50 }}
              />
            </Tooltip>
          </Link>
        ))}
      {!showAll &&
        <>
          {data.length > 4 && (
            <Link className="profile-badges-info__all-badges">
              <Tooltip hide position={"left"} tooltipText={"Click to view more badges"}>
                <ChevronDoubleRightIcon className="h-6 w-6" aria-hidden="true" style={{ color: '#da31b1' }} />
              </Tooltip>
            </Link>
          )}
        </>
      }

    </div >
  );
};

export default ProfileDataBadges;
