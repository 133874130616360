import React, { useEffect, useState } from 'react';
import BackBtnWithTitlePage from '../../../../components/reusable/btns/backBtn/BackBtnWithTitlePage';
import ReviewsRating from './ReviewsRating';
import { reviewsPremiumData } from '../../../../mock-data/reviews-premium/reviewsPremiumData';
import { calculateAverageRating } from './utils/calculateAverageRating';
import { calculateRatingDistribution } from './utils/calculateRatingDistribution';
import ReviewsList from './ReviewsList';
import { useDispatch } from 'react-redux';
import { openModal } from '../../../../store/slices/modalSlice/modalSlice';
import MainLayoutV2 from '../../../../components/layouts/mainLayout/MainLayoutV2';
import MainLayoutContentV2 from '../../../../components/layouts/mainLayout/mainLayoutContentV2/MainLayoutContentV2';
import { useUserRatings } from '../../../../appState/hooks/useUserRatings';
import { useLinkTo } from '@react-navigation/native';
import Button from '../../../../components/reusable/btns/buttons/Button';

const UserReviewContentPage = (props) => {
    const { type } = props.route.params;

    const [isTrainer, setIsTrainer] = useState(false);
    const [reviewsData, setReviewsData] = useState([]);
    const { userRating } = useUserRatings();
    const linkTo = useLinkTo();

    useEffect(() => {
        setReviewsData(reviewsPremiumData);
    }, []);


    const handleOpenModal = () => {
        linkTo(`/profile/profile-ratting`)
    };

    const averageRating = calculateAverageRating(reviewsData);
    const ratingDistribution = calculateRatingDistribution(reviewsData);

    return (
        <MainLayoutV2>
            <MainLayoutContentV2 iscenter>
                <BackBtnWithTitlePage btnText="Back to post" title={'Reviews'} />
                {/* <ReviewsRating
                    reviewsQuantity={reviewsData.length}
                    averageRating={averageRating}
                    ratingDistribution={ratingDistribution}
                /> */}

                {(userRating != null && userRating?.totalReviews > 0) ?
                    <ReviewsList
                        reviewsQuantity={userRating?.totalReviews}
                        reviewsData={userRating?.reviews}
                        isTrainer={isTrainer}
                    />
                    :
                    <p className={"justify-center flex"}>No Rating found</p>
                }

                {type == "visitor" &&
                    <Button
                        style={{ width: "100%", maxWidth: 420, minheight: 40 }}
                        className="button-leave-rating _fw button_primary mx-auto d-block !p-2"
                        onClick={handleOpenModal}>
                        Leave a rating
                </Button>
                }
            </MainLayoutContentV2>
        </MainLayoutV2>
    );
};

export default UserReviewContentPage;
