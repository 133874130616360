// noinspection ES6CheckImport

import {StyleSheet, Platform} from 'react-native';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import {fonts} from '../../../../res/fonts';
import {colors} from '../../../../res/colors';
import styled from 'styled-components/native';

const HeaderShadowLine = styled.View`
  flex-direction: row;
  align-items: center;
  border-bottom-color: ${colors.LIGHT_GRAY};
  border-bottom-width: ${props => (props?.light ? 0.8 : 1.5)}px;
`;
const TitleText = styled.Text`
  text-align: center;
  color: ${colors.BLACK};
  font-family: ${fonts.BOLD};
  font-size: ${wp(4.5)};
`;

export {HeaderShadowLine, TitleText};
export default StyleSheet.create({});
