import { useMutation } from 'react-query';
import { postComment } from '../../../network/api/app';
import { useBuzzmiAuth } from '../useBuzzmiAuth/index.web';

export const usePostComment = () => {
    const { userData } = useBuzzmiAuth();
    const {
        mutate: mutatePostComment,
        isLoading: isCommentPostLoading,
        isError: isCommentPostError,
        data: commentResponse,
        error: commentErrorResponse,
    } = useMutation({
        mutationFn: param => {
            // console.log("param?.body")
            // console.log(param?.body)
            return postComment(param.id)(Object.assign(param?.body, {
                params: { isAuth: true, token: userData?.token },
                // body: { ...param?.body },
            }));
        },
        onSuccess: data => {
            console.log('edited ==> ', data);
        },
    });
    const postCommentOnComments = async (id, body) => mutatePostComment({ id, body });

    return {
        commentResponse,
        commentErrorResponse,
        isCommentPostLoading,
        isCommentPostError,
        postCommentOnComments,
    };
};
