import React, { useEffect } from 'react';
import ShareModalBottomMenuV2 from './ShareModalBottomMenuV2';

const ShareModalV2 = ({
  userName,
  userAvatar,
  userShareDetails,
  forV2 = false,
}) => {
  return (
    <div className="share-modal">
      {userShareDetails?.thumbnail && (
        <div className="share-modal__object-to-share d-flex flex-row align-itmes-center mb-0">
          <img
            src={userAvatar}
            alt="author avatar"
            className="share-modal__author-avatar"
          />

          <div className="d-flex flex-column share-modal__info">
            <p className="h6">Post</p>
            <p className="text-r">by {userName}</p>
          </div>
        </div>
      )}

      {userShareDetails?.QR && (
        <div
          style={{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}>
          <img
            className={'object-cover'}
            src={userShareDetails?.QR}
            alt="Red dot"
            style={{ width: '100%', maxWidth: '340px' }}
          />
        </div>
      )}

      {userShareDetails?.thumbnail && (
        <div
          className=" m-3"
          style={{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}>
          <img
            className={'object-cover'}
            src={userShareDetails?.thumbnail}
            alt="Red dot"
            style={{ width: 250, height: 250, borderRadius: 10 }}
          />
        </div>
      )}

      {/* <FriendsList
        actionComponent={
          <Button
            className="button_secondary button_thin btn-text"
            onClick={() => {}}>
            Send
          </Button>
        }
      /> */}
      <ShareModalBottomMenuV2 userShareDetails={userShareDetails} />
    </div>
  );
};

export default ShareModalV2;
