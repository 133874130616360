import { atom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import AsyncStorage from '@react-native-community/async-storage';

const storage = { ...createJSONStorage(() => AsyncStorage), delayInit: true };
// const currentUserAtom = atom({});
// const userDataAtom = atomWithStorage('userDataNew', null);
const suggestedUsersAtom = atom([]);
const followingUsersAtom = atom([]);
const followerUsersAtom = atom([]);

const userPostsBuzzmiUsersAtom = atom([]);
const userFollowingListAtom = atom([]);

const userPostsUsersAtom = atom([]);
const visitorPostsUsersAtom = atom([]);

const userReelsUsersAtom = atom([]);
const visitorReelsUsersAtom = atom([]);

const userTagsAtom = atom([]);
const profileShareAtom = atom(null);
const visitorprofileShareAtom = atom(null);

const contactInfoAtom = atom(null);
const activityInfoAtom = atom(null);

const userSocialLinkAtom = atom({});

const isPostLoading = atom(false);
const isSuggestedUserLoading = atom(false);

const messageFollowAction = atom(null);


export {isSuggestedUserLoading,visitorprofileShareAtom, visitorPostsUsersAtom, visitorReelsUsersAtom, messageFollowAction, isPostLoading, suggestedUsersAtom, userFollowingListAtom, userPostsBuzzmiUsersAtom, followingUsersAtom, followerUsersAtom, userPostsUsersAtom, userReelsUsersAtom, userTagsAtom, profileShareAtom, contactInfoAtom, activityInfoAtom, userSocialLinkAtom };
