import React, { useState } from "react"
import Review from "./Review"

const ReviewsList = ({ reviewsQuantity, reviewsData, isTrainer }) => {
  const [sortByNewest, setSortByNewest] = useState(true)

  const getData = () => {
    setSortByNewest(!sortByNewest)
    const params = sortByNewest ? "params=desc" : "params=asc"
    //todo: GET data using this params
  }

  return (
    <div className="premium-content-reviews__reviews">
      <div className="d-flex flex-row align-items-center justify-content-between">
        <h4 className="premium-content-reviews__reviews-title">All reviews ({reviewsQuantity})</h4>
        {/* <button onClick={getData}>
          <img src="/images/post/sort.svg" alt="sort" />
        </button> */}
      </div>

      <ul className="premium-content-reviews__reviews-list">
        {reviewsData.map((review) => (
          <Review key={review.id} review={review} isTrainer={isTrainer} />
        ))}
      </ul>
    </div>
  )
}

export default ReviewsList
