import React, { useState } from 'react';
import PrimaryButton from "../../../../../components/reusable/form/items/buttons/PrimaryButton.jsx";
import Input from "../../../../../components/reusable/form/items/inputs/Input.jsx";
import ValidationSchema from "../../../../../form-validator/ValidationSchema.js";
import { useFormValidator } from "../../../../../form-validator/hooks/index.js";
import Form from "../../../../../components/reusable/form/Form.jsx";
import Strings from '../../../../../string_key/Strings.web';

const validationSchema = new ValidationSchema(
  {
    identifier: [
      { rule: 'required' },
    ],
    password: [
      { rule: 'required' },
    ],
  }
);

const LoginForms = props => {
  const { onSubmit, onForgotPass } = props;
  const [showPass, setShowPass] = useState(false)
  const { register, handleSubmit } = useFormValidator(validationSchema, (formData) => {
    onSubmit(formData)
  });

  return (
    <div >
      <Form onSubmit={handleSubmit} formClassName='login__form'>
        <Input label={Strings.your_username_or_email} name='identifier' register={register} />
        <div className="pass_input">
          <Input
            createPasswordForm={true}
            label={Strings.your_password}
            name='password'
            register={register}
          />
        </div>
        <div className={'login__forgot'}>
          <button type={"button"} onClick={() => { props.props.navigation.navigate("ForgetPassword") }} className={'login__assets'}>{Strings.forgot_password}</button>
        </div>
        <PrimaryButton className='login__btn' type='submit'>{Strings.log_in}</PrimaryButton>
      </Form>

    </div>
  );
};

export default LoginForms;
