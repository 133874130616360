import React, {useState} from 'react';
import {Image} from 'react-native';
import {Link, useNavigation} from '@react-navigation/native';
import image1 from '../../../assets/ic_not_verified_yellow.png';
import LeaderBoardDetailModal from '../../../container/App/Hub/ComponentsV2/LeaderBoardDetailModal';
const itemColors = [
  {
    itemBgColor: 'light-pink',
    iconBgColor: 'pink',
  },
  {
    itemBgColor: 'sky',
    iconBgColor: 'light-green',
  },
  {
    itemBgColor: 'sky',
    iconBgColor: 'light-blue',
  },
  {
    itemBgColor: 'light-yellow',
    iconBgColor: 'yellow',
  },
  {
    itemBgColor: 'blue',
    iconBgColor: 'dark-blue',
  },
];

const Board = ({items}) => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(0);
  return items && items.length > 0 ? (
    <div className="board">
      {items.map((item, index) => {
        const itemContent = (
          <>
            {item.icon && (
              <div
                className={
                  'board__item-img ' +
                  (itemColors[index].iconBgColor
                    ? itemColors[index].iconBgColor
                    : '')
                }>
                <img
                  src={item.icon}
                  alt={item.title}
                  onError={event => {
                    event.target.src = image1;
                    event.onerror = image1;
                  }}
                />
              </div>
            )}
            <div className="board__item-holder">
              <span className="board__item-text">{item.title}</span>
              {item.leaders && item.leaders.length > 0 && (
                <div className="board__item-additional">
                  {item.leaders.map((img, index) => {
                    return (
                      <div className="board__item-additional-img" key={index}>
                        <img src={img.image} alt="img" />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </>
        );
        // return item.link ? (
        //     <Link to={item.link} key={index} >
        //         <div className={"board__item " + (itemColors[index].itemBgColor ? itemColors[index].itemBgColor : "")} style={{ marginTop: 8 }} key={item.id}>
        //             {itemContent}
        //         </div>
        //     </Link>
        // ) : (
        //         <div className={"board__item " + (itemColors[index].itemBgColor ? itemColors[index].itemBgColor : "")} key={item.id}>{itemContent}</div>
        //     )
        return (
          // <Link to={'/hub/v2/leaders/personal-plan'} key={index}>
          <div
            onClick={() => {
              setId(item.id);
              setOpen(true);
            }}
            className={
              'board__item ' +
              (itemColors[index].itemBgColor
                ? itemColors[index].itemBgColor
                : '')
            }
            style={{marginTop: 8}}
            key={item.id}>
            {itemContent}
          </div>

          // </Link>
        );
      })}
      <LeaderBoardDetailModal open={open} setOpen={setOpen} id={id} />
    </div>
  ) : null;
};

export default Board;
