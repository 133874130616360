import {useAtom} from 'jotai';
import {userSharedBaseActivitiesList} from '../hubState';
import {getSharedBaseActivities} from '../../../network/api/app';

export const useSharedBaseActivities = () => {
  const [sharedbaseActivitiesList, setSharedBaseActivitiesList] = useAtom(
    userSharedBaseActivitiesList,
  );

  const getSharedBaseActivitiesList = async (token, setLoading, params) => {
    // console.log('log here in ', token, params);
    setLoading(true);
    try {
      const response = await getSharedBaseActivities(params)({token});
      console.log('response in Sharedbase ', response);
      setSharedBaseActivitiesList(response);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log('error => ', e.message);
    }
  };

  return {
    sharedbaseActivitiesList,
    getSharedBaseActivitiesList,
  };
};
