import { useSavedLists } from '../hubState';
import { useAtom } from 'jotai';
import { getUserBookMarkLists } from '../../../network/api/app';

export const useBookMarkLists = () => {
    const [savedLists, setSavedList] = useAtom(useSavedLists);

    const getAllBookMarkList = async (token) => {
        try {
            const response = await getUserBookMarkLists({ token });
            setSavedList(response);
        } catch (e) {
            console.log('error => ', e.message);
        }
    };

    return {
        getAllBookMarkList,
        savedLists
    };
};
