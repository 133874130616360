import React, {useEffect, useState} from 'react';
// import {useNavigate, useParams} from 'react-router-dom';
import {postsData} from '../../../../../mock-data/posts/postsData';
import formatRelativeDate from './utils/dateUtils';
import MainLayoutContent from '../../../../../components/layouts/mainLayout/mainLayoutContent/MainLayoutContent';
import BackBtnWithTitlePage from '../../../../../components/reusable/btns/backBtn/BackBtnWithTitlePage';
import {useNavigation, useRoute} from '@react-navigation/core';
import MainLayout from '../../../../../components/layouts/mainLayout/MainLayout';

const GiftsPostScreen = ({data}) => {
  const [post, setPost] = useState(null);
  const {navigate} = useNavigation();
  const route = useRoute();

  const {id} = data;
  useEffect(() => {
    // const foundPost = postsData.find(post => post.id === Number(id));
    const foundPost = postsData[Math.floor(Math.random() * postsData.length)];
    console.log('foundPost', foundPost, id);
    setPost(foundPost);
  }, [id]);

  const handleBackButtonClick = () => {
    const backUrl = `/profile#post-${id}`;
    navigate(backUrl);
  };

  return (
    <div>
      {/* <BackBtnWithTitlePage
        btnText="Back to post"
        title={`Gifts (${post?.gifts?.length || 0})`}
        customCallback={handleBackButtonClick}
      /> */}

      <ul className="gems-post__list d-flex flex-column pt-3">
        {post?.gifts?.map(gift => (
          <li
            key={gift?.id}
            className="gems-post__item d-flex flex-row align-items-center justify-content-between">
            <div className="gems-post__gem-info">
              <p className="gems-post__gem-date">
                {formatRelativeDate(gift?.date)}
              </p>
              <img
                src={gift?.author?.avatar?.src}
                alt="avatar"
                className="gems-post__gem-author-avatar"
              />
              <p className="h6">Sent by {gift?.author?.name}</p>
            </div>

            <div className="gems-post__gem-image-wrapper d-flex flex-row align-items-center">
              <span className="h5">+{gift?.quantity}</span>
              <img
                src={gift?.present?.src}
                alt="present"
                className="gift-image"
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default GiftsPostScreen;
