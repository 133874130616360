import { useState } from 'react';
import LightBox from '../../lightBox/LightBox.jsx';
import 'swiper/css/effect-fade';
import GalleryCarousel from '../carousel/GalleryCarousel.jsx';
import { isImage, isVideo } from '../../../types/media.js';
import Grid from '../../../assets/grid_dots.png';
import List from '../../../assets/list_lines.png';
import GridPruple from '../../../assets/grid_dots_purple.png';
import ListPurple from '../../../assets/list_lines_purple.png';

const Gallery = ({ list, isSmall, item }) => {
  const [activeGalleryItemId, setActiveGalleryItemId] = useState(null);
  const [overlineText, setOverlineText] = useState(null);
  const [isList, setIslist] = useState(list.length > 1 ? false : true);

  const onSwiperChange = ({ realIndex }) => {
    const text = list[realIndex];
    // console.log('realIndex', realIndex);
    if (text) {
      setOverlineText(text);
    }
  };

  const goToActiveSlide = activeGalleryItemId
    ? list.findIndex(el => el === activeGalleryItemId)
    : null;

  return list.length > 0 ? (
    <>
      {list.length > 1 &&
        <div className="flex  justify-end mb-1">
          <div className="flex flex-row gap-2">
            <div
              onClick={() => setIslist(true)}
              className={`w-8 h-8 rounded-lg flex items-center justify-center shadow-lg p-2 ${!isList ? 'bg-white' : 'bg-gradiant-800'
                }`}>
              <img src={!isList ? ListPurple : List} className="shadow-lg " />
            </div>
            <div
              onClick={() => setIslist(false)}
              className={`w-8 h-8 rounded-lg flex items-center justify-center shadow-lg mr-1 p-2  ${isList ? 'bg-white' : 'bg-gradiant-800'
                }`}>
              <img src={isList ? GridPruple : Grid} className="shadow-lg " />
            </div>
          </div>
        </div>
      }
      {!isList && (
        <div
          className={
            'gallery mb-3 mb-md-8' +
            (isSmall ? ' gallery--fixed-width' : '') +
            ' justify-start gap-2'
          }>
          {list.map(it => {
            return (
              <div
                className="gallery__item shadow-sm  rounded-md "
                onClick={() => setActiveGalleryItemId(it)}
                key={it}>
                {isImage(it) && <img src={it} alt={it || 'gallery img'} />}
                {isVideo(it) && (
                  // Display video thumbnail with play icon
                  <div className="video-thumbnail-container h-100 relative items-center flex ">
                    <video src={it}></video>
                    <img
                      src="/images/ic_youtube.png"
                      className=" play-icon absolute self-center bottom-[15%] left-[15%] text-4xl z-[2]"
                      style={{
                        maxWidth: '70%',
                        height: 'auto',
                      }}></img>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
      {isList && (
        <div className=" my-2">
          {list.map((it, index) => {
            return (
              <div
                onClick={() => setActiveGalleryItemId(it)}
                className="relative flex sm:space-x-4  space-x-2 sm:py-4 p-1 w-100 rounded-md bg-[#fff]">
                {isImage(it) && (
                  <div className=" gallery__item shadow-sm  sm:me-1 me-0 rounded-lg overflow-hidden  ">
                    <img
                      className=" h-20 w-20 sm:h-20 sm:w-20  md:h-24 md:w-24 lg:h-24 lg:w-24 xl:h-24 xl:w-24 flex-none "
                      src={it}
                      alt={it || 'gallery img'}
                    />
                  </div>
                )}
                {isVideo(it) && (
                  // Display video thumbnail with play icon
                  <div className="overflow-hidden shadow-sm video-thumbnail-container items-center flex h-20 w-20 sm:h-20 sm:w-20  md:h-24 md:w-24 lg:h-24 lg:w-24 xl:h-24 xl:w-24 relative rounded-lg">
                    <video src={it}></video>
                    <img
                      src="/images/ic_youtube.png"
                      className=" play-icon absolute self-center bottom-[15%] left-[15%] text-4xl z-[2]"
                      style={{
                        maxWidth: '70%',
                        height: 'auto',
                      }}></img>
                  </div>
                )}

                {/* {console.log('item ===> ', JSON.stringify(item))} */}
                <div className="flex-auto mt-1 self-center">
                  <h3 className="h6">
                    {item?.imagesTitles?.[index] || 'No title...'}
                  </h3>
                  <h3 className="text-r my-1">
                    {item?.imagesDescriptions?.[index] || 'No description...'}
                  </h3>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <LightBox
        isOpened={activeGalleryItemId !== null}
        onClose={() => setActiveGalleryItemId(null)}>
        <GalleryCarousel
          list={list}
          overlineText={overlineText}
          onSwiperChange={onSwiperChange}
          goToActiveSlide={goToActiveSlide}
        />
      </LightBox>
    </>
  ) : null;
};

export default Gallery;
