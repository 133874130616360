import React from "react"
import PropTypes from "prop-types"

const StepByStep = ({ count, step }) => {
  return (
    <div className="step-by-step">
      {Array(count)
        .fill()
        .map((_, i) => {
          return (
            <div className={`step-by-step__item ${i < step ? "step-by-step__item_active" : ""}`} key={i}>
              <span>{i + 1}</span>
            </div>
          )
        })}
    </div>
  )
}

StepByStep.propTypes = {
  count: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
}

export default StepByStep
