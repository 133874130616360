import React, {useEffect, useState} from 'react';
import {Platform, View} from 'react-native';
import UpdatePasswordScreen from '../../container/Auth/UpdatePasswordScreen';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import ForgetPassword from '../../container/Auth/ForgetPassword';
// import SignUp from '../../container/Auth/SignUp';
import Login from '../../container/Auth/Login';
import LoginV2 from '../../container/Auth/LoginV2';

// import VerifyOTPScreen from '../../container/Auth/VerifyOTPScreen';
import Registration from '../../container/Auth/Registration/index.web';
import EnterOtp from '../../container/Auth/EnterOtp/index.web';
import OtherServices from '../../container/Auth/OtherServices/index.web';
import SubscriptionV2 from '../../container/Auth/Sales/Subscription/SubscriptionV2';
import Pay from '../../container/Auth/Sales/pay/index';
import MobilePay from '../../container/Auth/Sales/pay/MobilePay';
import ProvideEmail from '../../container/Auth/provideContact/ProvideEmail';
import ProvidePhone from '../../container/Auth/provideContact/ProvidePhone';
import ProvidePhoneCode from '../../container/Auth/provideContact/ProvidePhoneCode';
import BioPage from '../../container/Auth/bioPage';
import CreatePassword from '../../container/Auth/CreatePassword/index';
import ProvideEmailCode from '../../container/Auth/provideContact/ProvideEmailCode';
import ChoseRole from '../../container/Auth/ChoseRole/index';
import AddCreators from '../../container/Auth/AddCreators/index';
import ChooseInterest from '../../container/Auth/ChooseInterest/index';

import AddPeople from '../../container/Auth/AddPeople/index.web';
import AddContacts from '../../container/Auth/AddContacts/index.web';
import AddFriends from '../../container/Auth/AddFriends/index.web';
import Subscription from '../../container/Auth/Sales/Subscription/index';
import FinishRegister from '../../container/Auth/FinishRegister/index';
import {useBuzzmiAuth} from '../../appState/hooks';
import {getData} from '../../res/asyncStorageHelper.web';
import MembershipV2 from '../../container/Auth/Sales/Subscription/MembershipV2';

const Stack = createNativeStackNavigator();

export const options = {
  headerShown: false,
  gestureEnabled: Platform.OS !== 'android',
};

const AuthStackNavigator = () => {
  const [initialPageName, setInitialPageName] = useState('LoginV2');
  const {updateLanguage} = useBuzzmiAuth();

  useEffect(() => {
    getData('useLanguage', useLanguage => {
      updateLanguage(useLanguage ? useLanguage : 'en');
    });
  }, []);

  return initialPageName === '' ? null : (
    <Stack.Navigator initialRouteName={initialPageName}>
      <Stack.Screen name={'Login'} component={Login} options={options} />

      <Stack.Screen name={'LoginV2'} component={LoginV2} options={options} />

      <Stack.Screen
        name={'ForgetPassword'}
        component={ForgetPassword}
        options={options}
      />
      <Stack.Screen
        component={UpdatePasswordScreen}
        name={'UpdatePasswordScreen'}
        options={options}
      />
      <Stack.Screen component={EnterOtp} name={'EnterOtp'} options={options} />
      <Stack.Screen
        component={ChoseRole}
        name={'ChoseRole'}
        options={options}
      />
      <Stack.Screen
        component={ChooseInterest}
        name={'ChooseInterest'}
        options={options}
      />
      <Stack.Screen
        component={AddCreators}
        name={'AddCreators'}
        options={options}
      />
      <Stack.Screen
        component={AddPeople}
        name={'AddPeople'}
        options={options}
      />
      <Stack.Screen
        component={AddContacts}
        name={'AddContacts'}
        options={options}
      />
      <Stack.Screen
        component={AddFriends}
        name={'AddFriends'}
        options={options}
      />

      {/*<Stack.Screen name={'SignUp'} component={SignUp} options={options} />*/}

      <Stack.Screen
        name={'Registration'}
        component={Registration}
        options={options}
      />
      <Stack.Screen
        name={'OtherServices'}
        component={OtherServices}
        options={options}
      />

      <Stack.Screen
        name={'ProvideEmail'}
        component={ProvideEmail}
        options={options}
      />
      <Stack.Screen
        name={'ProvidePhone'}
        component={ProvidePhone}
        options={options}
      />
      <Stack.Screen
        name={'ProvidePhoneCode'}
        component={ProvidePhoneCode}
        options={options}
      />
      <Stack.Screen
        name={'ProvideEmailCode'}
        component={ProvideEmailCode}
        options={options}
      />
      <Stack.Screen name={'Bio'} component={BioPage} options={options} />
      <Stack.Screen
        name={'CreatePassword'}
        component={CreatePassword}
        options={options}
      />

      <Stack.Screen
        name={'MembershipV2'}
        component={MembershipV2}
        options={options}
      />

      <Stack.Screen
        name={'Subscription'}
        component={Subscription}
        options={options}
      />

      <Stack.Screen
        name={'SubscriptionV2'}
        component={SubscriptionV2}
        options={options}
      />

      <Stack.Screen name={'Pay'} component={Pay} options={options} />
      <Stack.Screen
        name={'FinishRegister'}
        component={FinishRegister}
        options={options}
      />

      <Stack.Screen
        name={'MobilePay'}
        component={MobilePay}
        options={options}
      />
    </Stack.Navigator>
  );
};

export default AuthStackNavigator;
