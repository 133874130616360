import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import useWindowSize from '../../../../../../../hooks/useWindowSize';

const ProfileDataTextInfo = ({ data = [], isLinks, infostyle }) => {
  //todo fix-it
  const { width } = useWindowSize();

  return (
    <div className="profile-text-info" style={infostyle}>
      {data.length > 0 &&
        data.map((item, index) => (
          <>
            {isLinks && width < 768 ? (
              <Link to={item.link} key={item.title + index}>
                <div className="profile-text-info__value h4">
                  {item.value}
                </div>
                <div className="profile-text-info__title">{item.title}</div>
              </Link>
            ) : (
                <div key={item.title + index}>
                  <div className="profile-text-info__value h4">
                    {item.value}
                  </div>
                  <div className="profile-text-info__title">{item.title}</div>
                </div>
              )}
          </>
        ))}
    </div>
  );
};

ProfileDataTextInfo.propTypes = {
  data: PropTypes.array,
  isLinks: PropTypes.bool,
};

export default ProfileDataTextInfo;
