import React, {useEffect, useState} from 'react';

export const handleInputClassName = (
  isErrors,
  isSuccess,
  intermediateStage,
  inactive,
  className = '',
) => {
  className += ' input input_simple-text';
  if (inactive) className += ' _inactive';
  if (isErrors) className += ' _form-error';
  if (isSuccess && !isErrors) className += ' _form-success';
  if (intermediateStage) className += ' _form-warning';
  return className;
};

const MyTextInput = ({
  inactive,
  isErrors,
  isSuccess,
  intermediateStage,
  className,
  type,
  placeholder,
  createPasswordForm = false,
  diffPasswordForm = false,
  register,
  inputClassName,
  textAreaMode,
  name,
  isPaddingRight= false,
  ...props
}) => {
  const inputProps = register(name);
  const [showPass, setShowPass] = useState(false);
  // const inputRef = useRef(null);

  return (
    <>
      {textAreaMode ? (
        <textarea
          className={inputClassName + ' textarea'}
          placeholder={placeholder}
          {...inputProps}
          {...props}
        />
      ) : (
        <>
          {createPasswordForm && (
            <div className="show_pass">
              <button
                form=""
                onMouseDown={e => {
                  e.preventDefault();
                  // inputRef.current.focus();
                  setShowPass(!showPass);
                }}
                className={
                  showPass
                    ? 'eye-shadow fas fa-eye'
                    : 'eye-shadow fas fa-eye-slash'
                }></button>
            </div>
          )}

          {diffPasswordForm && (
            <div className="diff_pass">
              <button
                form=""
                onMouseDown={e => {
                  e.preventDefault();
                  // inputRef.current.focus();
                  setShowPass(!showPass);
                }}
                className={
                  showPass
                    ? 'eye-shadow fas fa-eye'
                    : 'eye-shadow fas fa-eye-slash'
                }></button>
            </div>
          )}
          <input
            // ref={inputRef}
            type={
              createPasswordForm || diffPasswordForm
                ? showPass
                  ? 'text'
                  : 'password'
                : type
            }
            className={`input input_simple-text
             ${inactive ? '_inactive' : ''}
             ${isPaddingRight ? 'pr-14' : ''}
              ${isErrors > 0 ? '_form-error' : ''}
               ${isSuccess > 0 && !isErrors ? '_form-success' : ''}
                ${intermediateStage ? '_form-warning' : ''}
                ${className} rounded-2xl border-[#c0bacb]`}
            placeholder={placeholder}
            {...inputProps}
            {...props}
          />
        </>
      )}
    </>
  );
};

const Input = args => {
  const {
    label,
    isValid = true,
    showError = true,
    isPaddingRight= false,
    placeholder,
    className,
    multiValidation,
    type = 'text',
    name,
    alterAction,
    inactive,
    register,
    textAreaMode,
    classNameWrapper = '',
    bottomLabel,
    createPasswordForm,
    diffPasswordForm,
    ...props
  } = args;

  const [inputClassName, setInputClassName] = useState(
    handleInputClassName(false, false, false, inactive),
  );

  const inputProps = register(name);
  const isErrors = inputProps?.errors?.length > 0;
  const isSuccess =
    multiValidation?.length > 0 && inputProps.value?.length > 0 && !isErrors;
  const intermediateStage =
    multiValidation?.length > 0 &&
    isErrors &&
    inputProps?.errors?.length < multiValidation?.length;

  useEffect(() => {
    const newClassName = handleInputClassName(
      isErrors,
      isSuccess,
      intermediateStage,
      inactive,
      className,
    );
    setInputClassName(newClassName);
  }, [isErrors, isSuccess, intermediateStage, inactive]);

  return (
    <div className={'input-wrapper ' + classNameWrapper}>
      <label className={'input-label'}>
        <div className="label-text">{label}</div>

        <MyTextInput
          textAreaMode={textAreaMode}
          inputClassName={inputClassName}
          inactive={inactive}
          isErrors={isErrors || (!isValid && showError)}
          isSuccess={isSuccess}
          intermediateStage={intermediateStage}
          className={className}
          type={type}
          name={name}
          register={register}
          placeholder={placeholder}
          createPasswordForm={createPasswordForm}
          diffPasswordForm={diffPasswordForm}
          isPaddingRight={isPaddingRight}
          {...props}
        />

        {multiValidation?.length > 0 ? (
          <ul className="validation-list text-s">
            {multiValidation.map((item, index) => (
              <li
                className={`validation-list__item ${
                  (isErrors &&
                    inputProps?.errors.find(errorName => errorName === item)) ||
                  !inputProps.value ||
                  (!isValid && showError)
                    ? 'validation-list__item_error'
                    : 'validation-list__item_success'
                }`}
                key={index + 'valid-item'}>
                {item}
              </li>
            ))}
          </ul>
        ) : (
          <div className={'text-error text-center'}>
            {isErrors ? inputProps?.errors[0] : ''}
          </div>
        )}
      </label>

      {alterAction && <div className="input-alter-action">{alterAction}</div>}
    </div>
  );
};

export default Input;
