import React from 'react';
// import PaySystems from "./items/PaySystems.jsx";
// import PayForm from "./items/PayForm.jsx";
import BaseLayout from "../../../../components/layouts/baseLayout/BaseLayout";
import DesktopPay from './DesktopPay.jsx';
import MobilePay from './MobilePay.jsx';

const Pay = () => {
  return (
    <>
      <BaseLayout bigDesk>
        <DesktopPay />
        <MobilePay />
      </BaseLayout>
    </>
  );
};

export default Pay;
