import React, { Fragment, useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useBuzzmiAuth } from '../../../../../../appState/hooks/useBuzzmiAuth/index.web';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import ProfileDataBadges from '../../items/profileDataBlocks/items/ProfileDataBadges';
import { useUserBadges } from '../../../../../../appState/hooks/useUserBadges';
import Tooltip from '../../../../../../components/reusable/assets/tooltip/Tooltip';
const mocDataBadges = [
  {
    id: 1,
    icon: {
      src: '/images/pages/profile/moc/pig.png',
      alt: 'Pig',
    },
  },
  {
    id: 2,
    icon: {
      src: '/images/pages/profile/moc/like.png',
      alt: 'Like',
    },
  },
  {
    id: 3,
    icon: {
      src: '/images/pages/profile/moc/gym.png',
      alt: 'Gym',
    },
  },
  {
    id: 4,
    icon: {
      src: '/images/pages/profile/moc/chef.png',
      alt: 'Chef',
    },
  },
  {
    id: 5,
    icon: {
      src: '/images/pages/profile/moc/bird.png',
      alt: 'Bird',
    },
  },
  {
    id: 6,
    icon: {
      src: '/images/pages/profile/moc/chef.png',
      alt: 'Chef',
    },
  },
  {
    id: 7,
    icon: {
      src: '/images/pages/profile/moc/bird.png',
      alt: 'Bird',
    },
  },
];
const BadgesModal = ({ setOpen, open }) => {
  const { userData } = useBuzzmiAuth();
  const { navigate } = useNavigation();
  const { usersBadges } = useUserBadges();

  useEffect(() => {
    console.log("usersBadges")
    console.log(usersBadges)
  }, [usersBadges])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full">
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                          Badges
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2"
                            onClick={() => setOpen(false)}>
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      {/* <ProfileDataBadges data={mocDataBadges} showAll={true} /> */}

                      <div className="profile-badges-info flex-wrap	">
                        {usersBadges?.length > 0 && usersBadges.map(item => (
                          // <Link to={`badges/${item.id}`}>
                          <Tooltip hide position={"bottom"} tooltipText={item?.name}>
                            <img
                              src={item?.imageUrl}
                              alt={item?.imageUrl}
                              style={{ height: 50, width: 50 }}
                            />
                          </Tooltip>
                          // </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default BadgesModal;
