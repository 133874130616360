import {useMutation} from 'react-query';
import {postFcmToken} from '../../../network/api/app';
import {useEffect} from 'react';
import {_userFcmToken, _userFcmTokenSavedOnBE} from '../../userState';
import {useAtom} from 'jotai';

export const usePostFcmToken = () => {
  const [userFcmToken, setUserFcmToken] = useAtom(_userFcmToken);
  const [userFcmTokenSavedOnBE, setUserFcmTokenSavedOnBE] = useAtom(
    _userFcmTokenSavedOnBE,
  );

  const {
    mutate: mutatePostFcmToken,
    isLoading: isPostFcmTokenLoading,
    isError: isPostFcmTokenError,
    data: postFcmTokenResponse,
    error: postFcmTokenErrorResponse,
  } = useMutation({
    mutationFn: param => {
      console.log('data in mutation', param);

      return postFcmToken(param);
    },
    onSuccess: data => {
      console.log('post fcm success===> success', data);
      setUserFcmTokenSavedOnBE(true);
    },
  });

  const postSendFcmToken = params => mutatePostFcmToken(params);

  return {
    isPostFcmTokenLoading,
    postFcmTokenResponse,
    postFcmTokenErrorResponse,
    postSendFcmToken,
  };
};
