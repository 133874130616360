import React from 'react';
import MainLayoutContentV2 from '../../../../components/layouts/mainLayout/mainLayoutContentV2/MainLayoutContentV2';
import BackBtnWithTitlePage from '../../../../components/reusable/btns/backBtn/BackBtnWithTitlePage';
import ProfilePremiumContentTop from './items/profilePremiumContentTop/ProfilePremiumContentTop';
import ProfilePremiumContentCoach from './items/profilePremiumContentCoach/ProfilePremiumContentCoach';
import ProfilePremiumContentAbout from './items/profilePremiumContentAbout/ProfilePremiumContentAbout';
import ProfilePremiumContentHighlights from './items/profilePremiumContentHighlights/ProfilePremiumContentHighlights';
import ProfilePremiumContentEquipment from './items/profilePremiumContentEquipment/ProfilePremiumContentEquipment';
import ProfilePremiumContentSocial from './items/profilePremiumContentSocial/ProfilePremiumContentSocial';
import ProfilePremiumContentCoins from './items/profilePremiumContentCoins/ProfilePremiumContentCoins';
import ProfilePremiumContentMid from './items/profilePremiumContentMid/ProfilePremiumContentMid';
import PrimaryButton from '../../../../components/reusable/btns/buttons/PrimaryButton';
import { openModal } from '../../../../store/slices/modalSlice/modalSlice';
import { useDispatch } from 'react-redux';
import ReviewsRating from './items/premiumContentReviews/ReviewsRating';
import { calculateAverageRating } from './items/premiumContentReviews/utils/calculateAverageRating';
import { calculateRatingDistribution } from './items/premiumContentReviews/utils/calculateRatingDistribution';
import IntroReview from './items/premiumContentReviews/IntroReview';
// import {Link} from 'react-router-dom';
import { Link } from '@react-navigation/native';
import { premiumContentData } from '../../../../mock-data/premium-content/premiumContentData';
import MultimediaDescription from './items/multimediaDescription/MultimediaDescription';
import MainLayout from '../../../../components/layouts/mainLayout/MainLayout';
import MainLayoutV2 from '../../../../components/layouts/mainLayout/MainLayoutV2';

const averageRating = calculateAverageRating(premiumContentData?.reviews || []);
const ratingDistribution = calculateRatingDistribution(
  premiumContentData?.reviews || [],
);

const ProfilePremiumContent = () => {
  const dispatch = useDispatch();

  const handleJoin = () => {
    dispatch(
      openModal({
        modalName: 'JoinToCourse',
      }),
    );
  };

  return (
    <MainLayoutV2 page={'profile'}>
      <MainLayoutContentV2 iscenter>
        <BackBtnWithTitlePage
          hideBottomBorder={true}
          title={premiumContentData.title}
          btnText="Back"
        />
        <div className="profile-content">

          <ProfilePremiumContentTop
            img={premiumContentData.img}
            reviewsQuantity={premiumContentData?.reviews?.length || 0}
            averageRating={averageRating}
            sold={premiumContentData.sold}
            likes={premiumContentData.likes}
          />
          {/* <h2 className="h2 d-md-none mt-21 mb-3 profile-content__mobile-title text-center">
            {premiumContentData.title}
          </h2> */}
          <ProfilePremiumContentCoach author={premiumContentData.author} />
          <ProfilePremiumContentCoins price={premiumContentData.price} />
          <ProfilePremiumContentAbout
            description={premiumContentData.description}
          />
          {/* <MultimediaDescription premiumContentData={premiumContentData} /> */}
          <ProfilePremiumContentHighlights
            highlights={premiumContentData.highlights}
          />
          <ProfilePremiumContentEquipment
            equipment={premiumContentData.equipment}
          />
          <ProfilePremiumContentSocial
            socialMedia={premiumContentData['social-media']}
          />
          <ProfilePremiumContentMid />
          {premiumContentData?.reviews && (
            <>
              <ReviewsRating
                reviewsQuantity={premiumContentData?.reviews?.length || 0}
                averageRating={averageRating}
                ratingDistribution={ratingDistribution}
              />
              <IntroReview review={premiumContentData?.reviews[0]} />
              <div className={"p-3 flex justify-end"}>
                <Link
                  to="/profile/premium-content/1/reviews"
                  className="p-5 flex text-r text-alt-primary premium-content-reviews__link">
                  See all {premiumContentData?.reviews?.length} reviews
              
                </Link>
                <img
                    src="/images/post/arrow-show-more.svg"
                    alt="arrow right"
                    className="-rotate-90 ml-2 arrow-right"
                  />
              </div>
            </>
          )}
          <div className="d-flex justify-content-center mt-7 mt-md-19">
            <PrimaryButton onClick={handleJoin} type="button">
              Join
            </PrimaryButton>
          </div>
        </div>
      </MainLayoutContentV2>
    </MainLayoutV2>
  );
};

export default ProfilePremiumContent;
