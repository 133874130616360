import { Rating } from "react-simple-star-rating"
import EmptyStar from "./EmptyStar"

const CustomRatingGold = props => {
  return (
    <div>
      <Rating
        className="rating"
        emptyIcon={<EmptyStar />}
        {...props}
      />
    </div>
  )
}

export default CustomRatingGold
