import React, { useEffect, useRef, useState } from 'react';
import InfoCardMenuMobile from './InfoCardMenuMobile.jsx';
import InfoCardMenuDesktop from './InfoCardMenuDesktop.jsx';
import { useDeleteBaseActivity } from '../../../../../../appState/hooks/useDeleteBaseActivity';
import { useAtom } from 'jotai';
import { userDataAtom } from '../../../../../../appState/userState';
import { useToast } from 'react-native-toast-notifications';
import { ShowSuccess } from '../../../../../../container/HOC/MessageWeb.js';
import { EventRegister } from 'react-native-event-listeners';
import { useDeletePlannedActivity } from '../../../../../../appState/hooks/useDeletePlannedActivity/index.js';
import { Link, useNavigation } from '@react-navigation/native';
import { useDispatch } from 'react-redux';
import {
  closeModal,
  openModal,
} from '../../../../../../store/slices/modalSlice/modalSlice.js';
const InfoCardMenu = ({ id, showMenu, setShowMenu, taskType, item }) => {
  const [userData, setLoginSuccess] = useAtom(userDataAtom);
  const { deleteBase } = useDeleteBaseActivity();
  const { deletePlanned } = useDeletePlannedActivity();
  const toast = useToast();
  const navigation = useNavigation();
  const menuRef = useRef(null);
  const dispatch = useDispatch();
  const handleMenu = event => {
    event.preventDefault();
    event.stopPropagation();
    if (!menuRef.current.contains(event.target)) {
      setShowMenu(!showMenu);
    }
  };

  useEffect(() => {
    if (showMenu) {
      document.addEventListener('click', handleMenu);
    } else {
      document.removeEventListener('click', handleMenu);
    }
    return () => {
      document.removeEventListener('click', handleMenu);
    };
  }, [showMenu]);

  const handleCompleteTask = () => { };

  const handleEditTask = e => {
    e.preventDefault();

    console.log('taskType', taskType);
    if (taskType === 'base') {
      console.log('taskType', taskType);
      navigation.navigate('HubAddTask', {
        item: item,
        isEdit: true,
        // fromPath: 'HubCreateTask',
      });
    }
    if (taskType === 'plan') {
      console.log('taskType', taskType);
    }
  };

  const deleteTask = () => {
    dispatch(closeModal());
    if (taskType === 'base') {
      deleteBase(id, null, userData.token, deleteResponse => {
        console.log('deleteResponse ===> ', JSON.stringify(deleteResponse));

        // setdeleteLoading(false);
        ShowSuccess('Activity deleted successfully', toast);
        EventRegister.emit('refresh-baseActivities');

        // navigate('HubTasks');
      });
    }
    if (taskType === 'plan') {
      deletePlanned(
        id,
        userData.token,

        deleteResponse => {
          console.log('deleteResponseHeres', deleteResponse);

          ShowSuccess('Activity deleted successfully', toast);
          EventRegister.emit('refresh-planActivities');
        },
      );
    }
  };

  const handleDeleteTask = e => {
    e.preventDefault();
    console.log('taskType', taskType);
    dispatch(
      openModal({
        modalName: 'renderAlert',
        modalType: 'alert',

        modalProps: {
          nameAlert: 'DeleteTaskAlert',
          propsAlert: {
            deleteCallback: deleteTask,
            closeModal: () => dispatch(closeModal()),
          },
        },
      }),
    );
  };

  return (
    <div className={`menu-card ${showMenu ? 'open' : ''}`}>
      <button onClick={handleMenu}>
        <img src="/images/assets/info-card/dots_menu.svg" alt="dots menu" />
      </button>
      <div
        ref={menuRef}
        className={`menu-card__wrapper ${showMenu ? 'open' : ''}`}>
        <InfoCardMenuDesktop
          handleEditTask={handleEditTask}
          handleCompleteTask={handleCompleteTask}
          handleDeleteTask={handleDeleteTask}
        />
        <InfoCardMenuMobile />
      </div>
    </div>
  );
};

export default InfoCardMenu;
