// noinspection ES6CheckImport

import React from 'react';
import {SafeAreaView} from 'react-native';
import {
  heightPercentageToDP as hp,
  widthPercentageToDP as wp,
} from 'react-native-responsive-screen';
import {BackHeader} from '../../../../components/BackHeader';
import {GlobalFlex} from '../../../../res/globalStyles';
import styles, {HeaderShadowLine} from './style';
import {Spacer} from '../../../../res/spacer';
import {RegularText} from '../style';

const UnderDevelopmentScreen = props => {
  return (
    <>
      <SafeAreaView style={{backgroundColor: '#FBF8FF'}} />
      <GlobalFlex>
        <BackHeader
          title={props?.route?.params?.title || 'Under Development'}
          is_center_text
          backgroundColor={'#FBF8FF'}
          onBackPress={() => props.navigation.goBack()}
        />
        <Spacer space={wp(1.5)}/>
        <HeaderShadowLine/>
        <Spacer space={hp(20)}/>
        <RegularText>Under Development</RegularText>
      </GlobalFlex>
    </>
  );
};
export default UnderDevelopmentScreen;
