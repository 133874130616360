import React, { useState, useEffect } from 'react';
import MobileLayout from '../../../components/layouts/mobileLayout/MobileLayout.jsx';
import BioPageForm from './items/BioPageForm.jsx';
import BaseLayout from '../../../components/layouts/baseLayout/BaseLayout.jsx';
import Strings from '../../../string_key/Strings.web.js';

const BioPage = (props) => {
  const [user, setUser] = useState(props.route.params?.user || null);

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  return (
    <BaseLayout>
      <MobileLayout>
        <div className="mb-4">
          <div className="mb-5 mb-sm-9">
            <div className="h4 mb-2"> {Strings.time_to_buzz_into_action}</div>
            <div className="text-r mb-6">
              {Strings.bio_description}
            </div>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <img src="/images/assets/smile.png" alt="smile" />
            </div>
          </div>
        </div>
        <BioPageForm user={user} />

      </MobileLayout>
    </BaseLayout>
  );
};

export default BioPage;
