import { StyleSheet } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { colors } from '../../../../../res/colors';
import { fonts } from '../../../../../res/fonts';
import styled from 'styled-components/native';

// const Container = styled.View`
//   height: 7rem;
//   width: 80%;
//   background-color: white;
//   flex-direction: row;
//   border-radius: 12px;
//   align-self: center;
// `;
const DiamondIcon = styled.Image`
  height: 24px;
  width: 24px;
  tint-color: ${colors.PRIMARY_COLOR};
`;
// const Divider = styled.View`
//   width: 2px;
//   height: 100%;
//   align-self: center;
//   border-radius: 90px;
//   background-color: #e8e8e8;
// `;
// const Item = styled.View`
//   width: 50%;
// `;
// const SubItem = styled.View`
//   position: absolute;
//   left: 0;
//   right: 0;
//   flex-direction: row;
//   align-items: center;
//   justify-content: center;
//   height: 105%;
// `;
// const LabelText = styled.Text`
//   color: #94989b;
//   font-family: ${fonts.MEDIUM};
//   font-size: 1.2rem;
//   margin-start: 1.2rem;
//   margin-top: 1.2rem;
// `;
const DetailText = styled.Text`
  color: #000000;
  font-family: ${fonts.REGULAR};
  font-size: 1rem;
`;
// const ClickableTextContainer = styled.TouchableOpacity`
//   position: absolute;
//   bottom: 0.5rem;
//   right: 1rem;
// `;
// const ClickableText = styled.Text`
//   color: ${colors.PRIMARY_COLOR};
//   font-family: ${fonts.REGULAR};
//   font-size: 1rem;
// `;
const Line = styled.View`
  height: 2px;
  width: 100%;

  border-radius: 90px;
  background-color: #e8e8e8;
`;
const WalletItemsContainer = styled.View`
  flex-direction: row;
  margin-top: ${hp(1.2)};
`;
const WalletIcon = styled.Image`
  height: 24px;
  width: 24px;
  margin-left: ${wp(1.5)};
  margin-right: ${wp(8)};
`;
const MembershipContainer = styled.View`
  flex-direction: row;
  align-items:center
  margin-top: ${hp(1.2)};
`;
const MembershipIcon = styled.Image`
  height: 40px;
  width: 40px;

  margin-right: ${wp(3)};
`;
const MembershipTitle = styled.Text`
  color: ${colors.BLACK};
  font-family: ${fonts.BOLD};
  font-size: ${hp(4)};
`;
const MembershipButton = styled.Pressable`
  border-radius: 20px;
  justify-content: center;
  width: ${hp(35)};
  background-color: ${colors.PRIMARY_COLOR};
  padding-horizontal: ${hp('5%')}px;
  align-self: center;
`;
const MembershipButtonLabel = styled.Text`
  color: ${props => (props?.selected ? colors.LIGHT_GRAY : colors.WHITE)};
  font-family: ${fonts.MEDIUM};
  font-size: ${hp(2.5)};
  alignSelf: center;
  margin-vertical: ${hp(2)};
`;
export {
  // Item,
  // Divider,
  // SubItem,
  // Container,
  // LabelText,
  DetailText,
  DiamondIcon,
  // ClickableText,
  // ClickableTextContainer,
  Line,
  WalletItemsContainer,
  WalletIcon,
  MembershipContainer,
  MembershipIcon,
  MembershipTitle,
  MembershipButton,
  MembershipButtonLabel,
};

export default StyleSheet.create({
  shadowStyle: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
  },
  membershipStatusBox: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
});
