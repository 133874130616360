import {NEW_RECOVER_PASSWORD} from '../../api_helper/Api';
import {POST} from '../../api_helper/ApiServices';

// New password recover
export const actionForgetPassword = (email, callBack) => {
  let postData = {identifier: email};
  POST(NEW_RECOVER_PASSWORD, false, null, null, postData, (data, e) =>
    callBack(data, e),
  );
};
