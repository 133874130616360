import React, {useRef} from 'react';
import style from './chose-image.module.scss';
import ImageUploading from 'react-images-uploading';
import useSetParenComponentProps from '../../../../hooks/useSetParenComponentProps.js';
import {useDispatch, useSelector} from 'react-redux';
import {
  closeModal,
  clearParentComponent,
} from '../../../../store/slices/modalSlice/modalSlice';
import Strings from '../../../../string_key/Strings.web';

const UploadField = ({callback, onlyImages}) => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  let image = [];
  const setProps = useSetParenComponentProps();
  // const {callback} = useSelector(state => state.modal);
  // const onChange = (event, addUpdateIndex) => {
  //   // image = imageList;
  //   dispatch(clearParentComponent());
  //   console.log('image', event.currentTarget.files);
  //   // callback(image);
  //   // setTimeout(() => {
  //   //   setProps('ChoseImage', {image: image[0]});
  //   // }, 150);
  //   // if (callback) {
  //   //   callback(image[0]);
  //   // }
  //   // dispatch(closeModal());
  // };

  const onChange = event => {
    const files = event.target.files;

    const filesArray = Array.from(files)
      .map(file => {
        if (file.size <= maxSize) {
          const dataUrl = URL.createObjectURL(file);
          return {data_url: dataUrl, file: file};
        } else {
          console.error('File size exceeds the maximum limit.');
          return null;
        }
      })
      .filter(Boolean); // Remove null entries

    // console.log('Files Array:', filesArray);
    callback(filesArray);
  };
  const uploadVideo = () => {
    ref.current.click();
  };
  const maxSize = 500 * 1024 * 1024;
  const maxDuration = 90 * 60 * 1000;

  const imgVidFormat = 'video/*,image/*';
  const imgFormat = 'image/*';

  return (
    <>
      <input
        size={maxSize}
        accept={onlyImages ? imgFormat : imgVidFormat}
        className="d-none"
        onChange={onChange}
        type="file"
        ref={ref}
        multiple
      />
      <div
        className={style['chose-image__upload-field']}
        onClick={uploadVideo}

        // {...dragProps}
      >
        <h4 className="h4 text-center">{'Upload your media'}</h4>
      </div>
    </>

    // <ImageUploading
    //   multiple
    //   value={image}
    //   onChange={onChange}
    //   maxNumber={4}
    //   dataURLKey="data_url"
    //   // acceptType={['jpg', 'gif', 'png', 'jpeg', 'svg', 'webp', 'jfif']}
    //   acceptType={[
    //     'jpg',
    //     'gif',
    //     'png',
    //     'jpeg',
    //     'svg',
    //     'webp',
    //     'jfif',
    //     'pjpeg',
    //     'pjp',
    //     'avif',
    //     'apng',
    //     'bmp',
    //     'ico',
    //     'cur',
    //     'mov',
    //   ]}>
    //   {({onImageUpload, dragProps}) => (
    //     <div
    //       className={style['chose-image__upload-field']}
    //       onClick={onImageUpload}
    //       {...dragProps}>
    //       <h4 className="h4 text-center">{Strings.upload_your_image}</h4>
    //     </div>
    //   )}
    // </ImageUploading>
  );
};

export default UploadField;
