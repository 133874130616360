import React, {useEffect, useState} from 'react';
import DropdownToggleComponent from '../dropdownToggle/DropdownToggleComponent.jsx';

const FormToggleDropdown = ({
  icon,
  title,
  children,
  info,
  defaultValue = null,
  isEditable = false,
  onEditBtn,
  editBtnText,
  setValue,
  name,
  values,
  text,
  miniMode,
  isFromMofidfy = false,
  setIsProgressImageEnabled = () => {},
  setvalidB = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(!!defaultValue);

  useEffect(() => {
    if (!!defaultValue && !values) {
      console.log('called');
      console.log(name);

      setValue(name, defaultValue);
    }
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setValue(name, null);
    } else if (isOpen && !!values) {
      setValue(name, values);
    } else {
      isFromMofidfy
        ? setValue(name, defaultValue || isOpen)
        : setValue(name, defaultValue || isOpen);
    }
    setvalidB(!isOpen);
    setIsProgressImageEnabled(isOpen);
  }, [isOpen]);

  return (
    <DropdownToggleComponent
      icon={icon}
      title={title}
      info={info}
      idChecked={name}
      defaultValue={defaultValue}
      isEditable={isEditable}
      onEditBtn={onEditBtn}
      editBtnText={editBtnText}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      text={text}
      miniMode={miniMode}>
      {children}
    </DropdownToggleComponent>
  );
};

export default FormToggleDropdown;
