import React from 'react';
import Avatar from '../../../../components/reusable/assets/avatar/Avatar.jsx';
import RankRow from '../../Hub/Items/rank/items/RankRow/RankRow.jsx';
import Box from '../../../../components/reusable/cards/box/Box.jsx';
import PlanInfo from '../../../../components/reusable/assets/additionalInformation/items/additionalInformationPlan/items/planInfo/PlanInfo.jsx';
import HubMembershipTop from './items/HubMembershipTop.jsx';
import Dropdown from '../../../../components/reusable/assets/dropdown/Dropdown.jsx';
import HubNextLevelInfo from './items/hubNextLevelInfo/HubNextLevelInfo.jsx';
import HubWallet from '../items/hubWallet/HubWallet.jsx';
import {Link} from '@react-navigation/native';
import Button from '../../../../components/reusable/btns/buttons/Button.jsx';
import InfoCardList from '../../../../components/reusable/cards/infoCard/InfoCardList.jsx';
import HubAccess from '../items/hubAccess/HubAccess.jsx';
import Message from '../../../../components/reusable/assets/message/Message.jsx';
import LinkTabs from '../../../../components/reusable/assets/linkTabs/LinkTabs.jsx';
import Strings from '../../../../string_key/Strings.web.js';
import {useBuzzmiAuth} from '../../../../appState/hooks/useBuzzmiAuth/index';
import {useHubApp} from '../../../../appState/hooks';

//TODO: mock data
/*const infoList = [
  {
    id: 'dwqdsads',
    href: '/hub/add-activities',
    image:
      'https://onesay-backend-dev.s3.amazonaws.com/398fe95b-cca1-4cdb-b5ac-f9802fc3df3b-gym.png',
    badges: [],
    title: 'Strength Traning',
    description:
      'Place your feet and hips apart, and grab a pair of dumbbells. Bend your knees slightly, and hinge at the',
  },
  {
    id: 'kuykmt',
    href: '/hub/add-activities',
    image:
      'https://onesay-backend-dev.s3.amazonaws.com/4bc1d577-1b3d-43ba-ad9b-86c29780c99f-drink.png',
    badges: [],
    title: 'Drink your water',
    description:
      'Drink up! Increasing your daily water intake is vital as you to stay healthy. Dehydration can cause fatigue, indigestion, a...',
  },
  {
    id: 'fwebtrtt',
    href: '/hub/add-activities',
    image:
      'https://onesay-backend-dev.s3.amazonaws.com/4bc1d577-1b3d-43ba-ad9b-86c29780c99f-drink.png',
    badges: [],
    title: 'Drink your water',
    description:
      'Drink up! Increasing your daily water intake is vital as you to stay healthy. Dehydration can cause fatigue, indigestion, a...',
  },
];*/

const tabs = [
  {
    href: '/hub',
    title: 'Hub',
    id: 0,
  },
  {
    href: '/calendar',
    title: 'Calendar',
    id: 1,
  },
];

const HubMainMobile = () => {
  const {userData} = useBuzzmiAuth();
  // const {taskList} = useHubApp();
  const taskList = [];

  return (
    <>
      <LinkTabs list={tabs} activeTabId={0} />
      <div className="d-flex flex-column align-items-center">
        <Message
          message={
            `${Strings.str_trial_message} ` +
            <a href="/">{Strings.premium_member}</a>
          }
        />
        <h1 className={'h3 mb-3'}>
          {Strings.rise_and_shine}, {userData?.firstName}!
        </h1>
        <Avatar mode="border" src="/images/moc/avatar-big.png" />
        <p className={'text-r my-3 text-center'}>
          {Strings.welcome_description}
        </p>
      </div>
      <Box paddingMode="rank">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="h4">{Strings.your_rank}</h4>
          <Link to="/hub/leaderboard">
            <div className="text-primary text-r">View rank ladder</div>
          </Link>
        </div>
        <RankRow />
      </Box>
      <Box paddingMode="rank" className="mt-3">
        <Dropdown
          Content={HubNextLevelInfo}
          title={Strings.str_how_to_get_next_level}
          dropDownClass={'__toggle'}
        />
      </Box>
      <Box
        paddingMode="rank"
        className="mt-3"
        topComponent={<HubMembershipTop />}>
        <PlanInfo />
      </Box>
      <HubWallet />
      <div
        className={
          'd-flex justify-content-between align-items-center mt-6 mt-lg-9 mb-lg-3 mb-5'
        }>
        <h4 className={'h4'}>{Strings.my_plan_for_today}</h4>
        <Link to={'/hub/activities'}>
          <Button
            className={
              'button_secondary button_m double-secondary px-6 px-lg-14'
            }>
            {Strings.see_all_tasks}
          </Button>
        </Link>
      </div>
      <InfoCardList infoList={taskList} isMainActivity={false}/>
      <div
        className={
          'd-flex justify-content-between align-items-center mt-6 mt-lg-9 mb-lg-3 mb-5'
        }>
        <h4 className={'h4'}>{Strings.quick_access}</h4>
        <Link to={'/hub/activities'} className={'text-alt-primary'}>
          {Strings.edit}
        </Link>
      </div>
      <HubAccess />
    </>
  );
};

export default HubMainMobile;
