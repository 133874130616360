import { images } from "./images";

export const WALKTHROGH_DATA = [
    {
        "Image": images.walkthrogh_1,
        "Title": "Discover your groups \n and create new \n communities",
        "Sub_Title": "Buzzmi has a community focused group sharing interest group that drives."
    }, {
        "Image": images.walkthrogh_2,
        "Title": "Reduce business \n communication \n friction",
        "Sub_Title": "Grow community and reward newcomes to your community."
    },
    {
        "Image": images.walkthrogh_3,
        "Title": "Message, Text, and \n Make Cheap Calls",
        "Sub_Title": "Message, Text and Make Calls to your Community together"
    }, {
        "Image": images.walkthrogh_4,
        "Title": "Save, earn \n cashback and redeem \n rewards",
        "Sub_Title": "Buzzmi is more than a messaging and social app - it is a lifestyle for users worldwide and a simple way to collect Spending Points and Cashback from our partners"
    }
];


export const interests = [
    {
        name: 'Fitness',
        avatar: require("../assets/interests/1.png")
    },
    {
        name: 'Yoga',
        avatar: require("../assets/interests/2.png")
    },
    {
        name: 'Workout',
        avatar: require("../assets/interests/3.png")
    },
    {
        name: 'Football',
        avatar: require("../assets/interests/4.png")
    },
    {
        name: 'Baseball',
        avatar: require("../assets/interests/5.png")
    },
    {
        name: 'Meditation',
        avatar: require("../assets/interests/6.png")
    },
    {
        name: 'Diet',
        avatar: require("../assets/interests/7.png")
    },
    {
        name: 'Running',
        avatar: require("../assets/interests/8.png")
    },
    {
        name: 'Football',
        avatar: require("../assets/interests/9.png")
    },
    {
        name: 'Running',
        avatar: require("../assets/interests/10.png")
    },
    {
        name: 'Challenges',
        avatar: require("../assets/interests/11.png")
    },
    {
        name: 'Fitness',
        avatar: require("../assets/interests/12.png")
    },
    {
        name: 'Fitness',
        avatar: require("../assets/interests/9.png")
    },
    {
        name: 'Arijit Singh',
        avatar: require("../assets/interests/1.png")
    },
    {
        name: 'Football',
        avatar: require("../assets/interests/2.png")
    },
    {
        name: 'Football',
        avatar: require("../assets/interests/5.png")
    },
]

export const MoreText = [
    {
        "title": "Premium-Only Content",
        "description": "Access to Exclusive Content from Hundreds of Independent Creators"
    },
    {
        "title": "Premium-Only Content",
        "description": "Participate in Premium-Member-Only Challenges and Contests to Win Rewards"
    },
    {
        "title": "Double Rewards",
        "description": "Get 2x Buzzcoin for Participating in Challenges and Contests"
    },
    {
        "title": "Premium Tools",
        "description": "Access to Premium Tools for Personal Growth, Wellness, and Productivity"
    },
    {
        "title": "Exclusive Deals and Offers",
        "description": "Access to Exclusive Content, Offers, and Deals"
    },
    {
        "title": "Premium-Member Badge",
        "description": "Premium-Member BadgePremium-Member BadgePremium-Member Badge"
    }
]

export const creators = [
    {
        value: 'Pritam',
        avatar: require("../assets/creators/1.jpg")
    },
    {
        value: 'Taylor Swift',
        avatar: require("../assets/creators/2.jpg")
    },
    {
        value: 'The Weekend',
        avatar: require("../assets/creators/3.jpg")
    },
    {
        value: 'BTS',
        avatar: require("../assets/creators/4.jpg")
    },
    {
        value: ' Ed Sheeran',
        avatar: require("../assets/creators/5.jpg")
    },
    {
        value: 'A.R. Rahman',
        avatar: require("../assets/creators/6.jpg")
    },
    {
        value: 'Drake',
        avatar: require("../assets/creators/7.jpg")
    },
    {
        value: 'Doja Cat',
        avatar: require("../assets/creators/8.jpg")
    },
    {
        value: 'Eminnem',
        avatar: require("../assets/creators/9.jpg")
    },
    {
        value: 'Arijit Singh',
        avatar: require("../assets/creators/10.jpg")
    },
    {
        value: 'BTS',
        avatar: require("../assets/creators/11.jpg")
    },
    {
        value: 'The Weekend',
        avatar: require("../assets/creators/12.jpg")
    },
    {
        value: 'Eminnem',
        avatar: require("../assets/creators/9.jpg")
    },
    {
        value: 'Arijit Singh',
        avatar: require("../assets/creators/1.jpg")
    },
    {
        value: 'BTS',
        avatar: require("../assets/creators/2.jpg")
    },
    {
        value: 'The Weekend',
        avatar: require("../assets/creators/5.jpg")
    },
]



export const STERIO_DATA = [
    {
        "ID": 1,
        "Background": "#ff9230",
        "Background_light": '#FFB67E',
        "Profile_image": "https://stereo-images.stereocdn.com/user-avatars/1607841879/small.png?1663555102",
        "Fullname": "CheckYaself",
        "ClosesTime": "CheckYaself",
        "Description": "Tell us about the most memorable moments of the Kings of Stereo award show",
        "IsInnerCard": true,
        "InnerImage": images.l_3,
        "InnerTitle": "👑KING OF STEREO AWARDS👑 #DMZ by Queen Donatella, Gg and 1 more on Stereo",
        "InnerWeb": "stereo.com",
        "Total_like": 13,
        "Total_comment": 18,
        "CommentBy": [
            {
                "Profile": "https://stereo-images.stereocdn.com/user-avatars/1607841879/small.png?1663555102",
                "Name": "Drago",
                "Stereo": "https://audiodemos.github.io/vctk_set0/ground_truth.wav"
            }, {
                "Profile": "https://stereo-images.stereocdn.com/user-avatars/1607889532/small.png?1663562074",
                "Name": "Drails",
                "Stereo": "https://audiodemos.github.io/vctk_set0/ground_truth.wav"
            }, {
                "Profile": "https://stereo-images.stereocdn.com/user-avatars/1611969845/small.png?1663438112",
                "Name": "Drails",
                "Stereo": "https://audiodemos.github.io/vctk_set0/ground_truth.wav"
            }, {
                "Profile": "https://stereo-images.stereocdn.com/user-avatars/1608031534/small.png?1663540199",
                "Name": "Jessica",
                "Stereo": "https://audiodemos.github.io/vctk_set0/ground_truth.wav"
            }, {
                "Profile": "https://stereo-images.stereocdn.com/user-avatars/1607563510/small.png?1663574788",
                "Name": "Miss Chess",
                "Stereo": "https://audiodemos.github.io/vctk_set0/ground_truth.wav"
            }
        ],
        "SoundFile": "https://audiodemos.github.io/vctk_set0/ground_truth.wav"
    },
    {
        "ID": 2,
        "Background": "#8095FF",
        "Background_light": '#ABB9FF',
        "Profile_image": "https://stereo-images.stereocdn.com/user-avatars/1613540670/orig.png?1648773757",
        "Fullname": "Xavier Sims",
        "ClosesTime": "CheckYaself",
        "Description": "Do u think women get more dms than men ? Or both 👀",
        "IsInnerCard": false,
        "Total_like": 3,
        "Total_comment": 8,
        "CommentBy": [
            {
                "Profile": "https://stereo-images.stereocdn.com/user-avatars/1613863560/small.png?1662643070",
                "Name": "Drago",
                "Stereo": "https://audiodemos.github.io/vctk_set0/ground_truth.wav"
            }, {
                "Profile": "https://stereo-images.stereocdn.com/user-avatars/1608437772/small.png?1663492535",
                "Name": "Drails",
                "Stereo": "https://audiodemos.github.io/vctk_set0/ground_truth.wav"
            }, {
                "Profile": "https://stereo-images.stereocdn.com/user-avatars/1612907064/small.png?1634770959",
                "Name": "Drails",
                "Stereo": "https://audiodemos.github.io/vctk_set0/ground_truth.wav"
            }, {
                "Profile": "https://stereo-images.stereocdn.com/user-avatars/1609034269/small.png?1663531461",
                "Name": "Jessica",
                "Stereo": "https://audiodemos.github.io/vctk_set0/ground_truth.wav"
            }
        ],
        "SoundFile": "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3"
    },
    {
        "ID": 3,
        "Background": "#97CDC8",
        "Background_light": '#BADDDA',
        "Profile_image": "https://stereo-images.stereocdn.com/user-avatars/1613540670/orig.png?1648773757",
        "Fullname": "KHIAM MINCEY",
        "ClosesTime": "CheckYaself",
        "Description": "After seeing every accident/tragedy that has happened on amusement park rides all over the world over time, would you ever think twice before getting on one, even if being pressured or criticized?",
        "IsInnerCard": false,
        "Total_like": 3,
        "Total_comment": 6,
        "CommentBy": [
            {
                "Profile": "https://stereo-images.stereocdn.com/user-avatars/1607750383/small.png?1663374325",
                "Name": "Drago",
                "Stereo": "https://audiodemos.github.io/vctk_set0/ground_truth.wav"
            }, {
                "Profile": "https://stereo-images.stereocdn.com/user-avatars/1609420192/small.png?1663528073",
                "Name": "Drails",
                "Stereo": "https://audiodemos.github.io/vctk_set0/ground_truth.wav"
            }, {
                "Profile": "https://stereo-images.stereocdn.com/user-avatars/1614321182/small.png?1663492999",
                "Name": "Drails",
                "Stereo": "https://audiodemos.github.io/vctk_set0/ground_truth.wav"
            }
        ],
        "SoundFile": "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-3.mp3"
    },
    {
        "ID": 4,
        "Background": "#FFB4CB",
        "Background_light": '#FFCDDB',
        "Profile_image": "https://stereo-images.stereocdn.com/user-avatars/1611587436/orig.png?1663564123",
        "Fullname": "Upgrade",
        "ClosesTime": "CheckYaself",
        "Description": "Overrated Food: “American” Edition",
        "IsInnerCard": false,
        "Total_like": 3,
        "Total_comment": 9,
        "CommentBy": [
            {
                "Profile": "https://stereo-images.stereocdn.com/user-avatars/1607750383/small.png?1663374325",
                "Name": "Drago",
                "Stereo": "https://audiodemos.github.io/vctk_set0/ground_truth.wav"
            }, {
                "Profile": "https://stereo-images.stereocdn.com/user-avatars/1609420192/small.png?1663528073",
                "Name": "Drails",
                "Stereo": "https://audiodemos.github.io/vctk_set0/ground_truth.wav"
            }, {
                "Profile": "https://stereo-images.stereocdn.com/user-avatars/1614321182/small.png?1663492999",
                "Name": "Drails",
                "Stereo": "https://audiodemos.github.io/vctk_set0/ground_truth.wav"
            }, {
                "Profile": "https://stereo-images.stereocdn.com/user-avatars/1612907064/small.png?1634770959",
                "Name": "Jessica",
                "Stereo": "https://audiodemos.github.io/vctk_set0/ground_truth.wav"
            }, {
                "Profile": "https://stereo-images.stereocdn.com/user-avatars/1613929375/small.png?1652883075",
                "Name": "Miss Chess",
                "Stereo": "https://audiodemos.github.io/vctk_set0/ground_truth.wav"
            }
        ],
        "SoundFile": "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-4.mp3"
    }
];



export const newsData = [
    {
        'trending': '#EleccionesCR',
        'tweets': '6,715',
    },
    {
        'trending': 'hailey',
        'tweets': '3,265',
    },
    {
        'trending': 'Saturday Night Live',
        'tweets': '1,567',
    },
    {
        'trending': 'Documentales',
        'tweets': '6,999',
    },
    {
        'trending': 'Business Advices',
        'tweets': '8,999'
    }
]


export const earning_data = [
    {
        id: 1,
        title: "Daily Login Streak",
        point: [null, 1, null]
    },
    {
        id: 2,
        title: "Engagement Earnings",
        point: [1, null, null]
    },
    {
        id: 3,
        title: "Invitation Earnings",
        point: [null, null, 1]
    },
    {
        id: 4,
        title: "Today's Total Earning",
        point: [1, 1, 1]
    }
]

export const project_score = [
    {
        id: 1,
        title: "Engagement Score",
    },
    {
        id: 2,
        title: "Pro Creator Score",
    },
    {
        id: 3,
        title: "Network Score",
    },
]

export const membership = [
    {
        price_diamond: "200",
        coin_diamond: "10,000",
        description: "Free User \n",
        one_x: images.one_x,
        isFree: true,
    },
    {
        price_diamond: "400",
        coin_diamond: "20,000",
        description: "Premium \n Member",
        one_x: images.two_x,
        isFree: false,
    }
]

export const membership_creator = [
    {
        price_diamond: "200",
        coin_diamond: "10,000",
        description: "Free User \n",
        one_x: images.one_x,
        isFree: true,
    },
    {
        price_diamond: "400",
        coin_diamond: "20,000",
        description: "Elite \n Creator",
        one_x: images.two_x,
        isFree: false,
    }
]

export const level_score = [
    {
        id: 1,
        title: "Level 1",
        score: "00000"
    }, {
        id: 2,
        title: "Level 2",
        score: "00000"
    }, {
        id: 3,
        title: "Level 3",
        score: "00000"
    }, {
        id: 4,
        title: "Level 4",
        score: "00000"
    }, {
        id: 5,
        title: "Level 5",
        score: "00000"
    }
]

export const activities = [
    {
        id: 1,
        title: "Personal plan",
        backgroundColor: "#FFD4E1",
        dark_backgroundcolor: "#FFADBB",
        image: images.personal_plan,
        completed: 10
    },
    {
        id: 2,
        title: "Habits",
        backgroundColor: "#D6FFD6",
        dark_backgroundcolor: "#ADFFB4",
        image: images.habits,
        completed: 1
    },
    {
        id: 3,
        title: "Workouts",
        backgroundColor: "#CCF4FF",
        dark_backgroundcolor: "#99E4FD",
        image: images.workouts_1,
        completed: 1
    },
    {
        id: 4,
        title: "Workouts",
        backgroundColor: "#FFF6C4",
        dark_backgroundcolor: "#FFEC85",
        image: images.workouts_2,
        completed: 1
    },
    {
        id: 5,
        title: "Programs",
        backgroundColor: "#C1D3FF",
        dark_backgroundcolor: "#9AB6FF",
        image: images.programs,
        completed: 0
    },
    {
        id: 6,
        title: "Buzzmi Tasks",
        backgroundColor: "#E4CFFF",
        dark_backgroundcolor: "#E4AFFF",
        image: images.buzzmi_task,
        completed: 0
    }
]

export const wallet_data = [
    {
        id: 1,
        coin: "Buzzcoins",
        coin_amount: "500",
        coin_ic: images.buzz_coin
    },
    {
        id: 2,
        coin: "Gems",
        coin_amount: "1500",
        coin_ic: images.gems
    },
    {
        id: 3,
        coin: "Diamonds",
        coin_amount: "1500",
        coin_ic: images.diamonds
    },
]

export const recharge_data = [
    {
        id: 1,
        amount: 42,
        price: 3.69,
        discount: 0,
        recharge_ic: images.recharge_2
    },
    {
        id: 2,
        amount: 210,
        price: 17.98,
        discount: 0,
        recharge_ic: images.recharge_3
    },
    {
        id: 3,
        amount: 686,
        price: 54.99,
        discount: 0.499,
        recharge_ic: images.recharge_1
    },
]

export const wallet_earning_data = [
    {
        "title": "Personal Earnings",
        "earn_data": [
            {
                "coin": "Buzzcoins",
                "amount": "20,900",
                "price": 0,
                "coin_ic": images.buzz_coin
            }, {
                "coin": "Diamonds",
                "amount": "1,200",
                "price": 400,
                "coin_ic": images.diamonds
            }
        ]
    },
    {
        "title": "Invite Earnings",
        "earn_data": [
            {
                "coin": "Buzzcoins",
                "amount": "20,900",
                "price": 0,
                "coin_ic": images.buzz_coin
            }, {
                "coin": "Diamonds",
                "amount": "1,200",
                "price": 400,
                "coin_ic": images.diamonds
            }
        ]
    },
    {
        "title": "Indirect Invite Earnings",
        "earn_data": [
            {
                "coin": "Buzzcoins",
                "amount": "20,900",
                "price": 0,
                "coin_ic": images.buzz_coin
            }, {
                "coin": "Diamonds",
                "amount": "1,200",
                "price": 400,
                "coin_ic": images.diamonds
            }
        ]
    },
    {
        "title": "Badge Earnings",
        "earn_data": [
            {
                "coin": "Badge Collected",
                "amount": "10",
                "price": 0,
                "coin_ic": images.badge
            }
        ]
    },
    {
        "title": "Total Earnings",
        "earn_data": [
            {
                "coin": "Buzzcoins",
                "amount": "1,00,000",
                "price": 0,
                "coin_ic": images.buzz_coin
            }, {
                "coin": "Diamonds",
                "amount": "10,000",
                "price": 2000,
                "coin_ic": images.diamonds
            }
        ]
    },
]
