import {Text, View} from 'react-native';
import React from 'react';

const ChooseCoverImage = () => {
  return (
    <View>
      <Text>ChooseCoverImage</Text>
    </View>
  );
};

export default ChooseCoverImage;
