// import {Outlet} from 'react-router-dom';

import MainLayoutSidebar from './mainLayoutSidebar/MainLayoutSidebar.jsx';
import MainLayoutFooter from './mainLayoutFooter/MainLayoutFooter.jsx';

const MainLayout = props => {
  return (
    <div className="wrapper">
      <div className="main-layout main-layout__container">
        <div className="main-layout__wrapper">
         {props.hidelayout ? null :  <MainLayoutSidebar />}
          <div className="main-layout__content">{props?.children}</div>
        </div>
        <MainLayoutFooter />
      </div>
    </div>
  );
};

export default MainLayout;
