import React, {useEffect, useState} from 'react';
import CustomSelect from '../../select/customSelect/CustomSelect';
import EditProfileInputController from '../../../../../container/App/Profile/editProfile/items/editProfileBusinessInformation/items/editProfileInputController/EditProfileInputController';

const InputKits = ({register, kit, callback}) => {
  const [selected, setSelected] = useState(kit[0]);

  const handleSelect = newSelected => {
    if (callback) callback(newSelected, selected);
    setSelected(newSelected);
  };

  useEffect(() => {
    if (callback) callback(selected);
  }, []);

  return (
    <div className="profile-form__kit">
      <CustomSelect
        className="select__secondary w-100"
        options={kit}
        placeholder="Selected"
        setSelected={handleSelect}
        selected={selected}
      />
      <EditProfileInputController
        componentName={selected.value}
        register={register}
      />
    </div>
  );
};

export default InputKits;
