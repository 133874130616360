import React, {useEffect} from 'react';
import Rank from '../../Hub/Items/rank/Rank.jsx';
import Button from '../../../../components/reusable/btns/buttons/Button.jsx';
import {Link} from '@react-navigation/native';
import InfoCardList from '../../../../components/reusable/cards/infoCard/InfoCardList.jsx';
import HubAccess from '../items/hubAccess/HubAccess.jsx';
import {useBuzzmiAuth} from '../../../../appState/hooks/useBuzzmiAuth/index';
import Strings from '../../../../string_key/Strings.web';
import {useHubApp, useProfileApp} from '../../../../appState/hooks';
import PlanSearch from '../../../../components/reusable/assets/additionalInformation/items/additionalInformationPlan/items/planSearch/PlanSearch.jsx';

const HubMainDesktop = () => {
  const {userData} = useBuzzmiAuth();
  const {plannedActivities} = useHubApp();

  // const { getUserPostLists, getUserReelLists } = useProfileApp();

  // useEffect(() => {
  //   getUserPostLists(userData?.token, userData?.id, { page: 1, limit: 20 });
  //   getUserReelLists(userData?.token, userData?.id, { page: 1, limit: 20 });
  // }, [userData]);

  return (
    <>
      <h1 className={'h3 mb-2'}>
        {Strings.rise_and_shine}, {userData?.firstName}!
      </h1>
      <p style={{color: '#584F68'}} className={'text-b mb-4'}>
        {Strings.welcome_description}
      </p>
      <Rank withAvatar />
      {/* <div className={'mt-4'}>
        <PlanSearch placholderExt={Strings.tasks} />
      </div> */}
      <div
        className={
          'd-flex justify-content-between align-items-center mt-6 mt-lg-9 mb-lg-3 mb-5'
        }>
        <h4 className={'h4'}>{Strings.my_plan_for_today}</h4>
        <Link to={'/hub/activities'}>
          <Button
            className={
              'button_secondary button_m double-secondary px-6 px-lg-14'
            }>
            {Strings.see_all_tasks}
          </Button>
        </Link>
      </div>
      <InfoCardList
        infoList={plannedActivities.slice(0, 3)}
        taskType={'plan'}
        isMainActivity={false}
      />
      <div
        className={
          'd-flex justify-content-between align-items-center mt-6 mt-lg-9 mb-lg-3 mb-5'
        }>
        <h4 className={'h4'}>{Strings.quick_access}</h4>
        <Link to={'/hub/activities'} className={'text-alt-primary'}>
          <div className="text-alt-primary d-none d-lg-block">
            {Strings.edit}
          </div>
        </Link>
      </div>
      <HubAccess />
    </>
  );
};

export default HubMainDesktop;
