import {useMutation} from 'react-query';
import {postAssignActivity} from '../../../network/api/app';
import {useBuzzmiAuth} from '../useBuzzmiAuth/index.web';
import {ShowError, ShowSuccess} from '../../../container/HOC/MessageWeb';
import {useToast} from 'react-native-toast-notifications';

export const usePostAssignActivity = () => {
  const {userData} = useBuzzmiAuth();
  const toast = useToast();

  const {
    mutate: mutateAssignActivity,
    isLoading: isAssignPostLoading,
    isError: isAssignPostError,
    data: AssignPostResponse,
    error: AssignPostErrorResponse,
  } = useMutation({
    mutationFn: param => {
      console.log('param?.body');
      console.log(param?.body);
      return postAssignActivity(
        Object.assign(param?.body, {
          params: {isAuth: true, token: userData?.token},
          // body: { ...param?.body },
        }),
      );
    },
    onSuccess: data => {
      console.log('success on share ==> ', data);
      ShowSuccess('Activity Assigned successfully', toast);
    },
    onError: data => {
      ShowError('Activity Assigned Failed', toast);
    },
  });
  const AssignActivity = async body => mutateAssignActivity({body});

  return {
    AssignPostResponse,
    AssignPostErrorResponse,
    isAssignPostLoading,
    isAssignPostError,
    AssignActivity,
  };
};
