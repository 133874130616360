import React, {useEffect, useState} from 'react';
import PostAuthorInfo from '../profilePost/items/postHeader/PostAuthorInfo';
import formatRelativeDate from '../profilePost/utils/dateUtils';
import LikeIcon from '../icons/LikeIcon';
import ReplyBtn from './ReplyBtn';

const Comment = ({comment}) => {
  const [likesCount, setLikesCount] = useState(null);
  const [isLiked, setIsLiked] = useState(false);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    setLikesCount(Number(comment?.upvotes?.length));
  }, [comment?.upvotes]);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleClick = () => {
    setIsLiked(prevIsLiked => !prevIsLiked);
    setHovered(false);
    setLikesCount(prevLikes => prevLikes + (isLiked ? -1 : 1));
  };

  return (
    <div className="comments-post__wrapper">
      <PostAuthorInfo
        userName={comment?.user?.profile?.name}
        userNickname={comment?.user?.profile.username}
        userPosition={''}
        userAvatar={comment?.user?.profile?.picture}
        isVerified={comment?.user?.profile.isVerified}
      />

      <p className="content-post__text">{comment?.body}</p>

      <div className="d-flex flex-row align-items-center justify-content-between">
        <div className="comments-post__comment-actions d-flex flex-row">
          <p className="comments-post__comment-date text-s">
            {formatRelativeDate(new Date(comment?.createdAt))}
          </p>
          <p className="text-s">
            {comment?.likes === 0 ? '' : `${likesCount} Likes`}
          </p>
          {/* <ReplyBtn comment={comment} /> */}
        </div>

        <div
          className="comments-post__like"
          onClick={handleClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
          <LikeIcon isHovered={hovered || isLiked} />
        </div>
      </div>
    </div>
  );
};

export default Comment;
