import React, { useEffect, useMemo, useState } from 'react';
import Rank from '../../../Items/rank/Rank.jsx';
import LinkTabs from '../../../../../../components/reusable/assets/linkTabs/LinkTabs.jsx';
import FilterList from '../../../Items/filterList/FilterList.jsx';
import Calendar from '../../../../../../components/reusable/calendar/Calendar.jsx';
import UpdateInfo from '../../../../../../components/reusable/updateInfo/UpdateInfo.jsx';
import Strings from '../../../../../../string_key/Strings.web.js';
import { useBuzzmiAuth } from '../../../../../../appState/hooks/useBuzzmiAuth/index.web.js';
import Leaderboard from '../Leaderboard';
import PlanSearch from '../../../../../../components/reusable/assets/additionalInformation/items/additionalInformationPlan/items/planSearch/PlanSearch.jsx';
import moment from 'moment';
import PeriodList from '../../../../../../components/reusable/periodList/PeriodList.jsx';
import { EventRegister } from 'react-native-event-listeners';
import Loading from 'react-fullscreen-loading';
import { colors } from '../../../../../../res/colors.js';
import { useStatListing } from '../../../../../../appState/hooks/useStatListing';
import { useAtom } from 'jotai';
import { userPlannedActivitiesGetTime } from '../../../../../../appState/hooks/hubState.js';
import MainLayoutWithAspectSide from '../../../../../../components/layouts/mainLayout/MainLayoutWithAspectSide.jsx';
import useWidthListener from '../../../../../../hooks/useWidthListner..js';
import BackBtnWithTitlePage from '../../../../../../components/reusable/btns/backBtn/BackBtnWithTitlePage.jsx';
import { useHubApp } from '../../../../../../appState/hooks/useHubApp/index.web.js';

const periodList = [
  {
    id: 0,
    text: 'Last week',
    _text: 'last_week',
  },
  {
    id: 1,
    text: 'This week',
    _text: 'this_week',
  },
  {
    id: 2,
    text: 'Last Month',
    _text: 'last_month',
  },
  {
    id: 3,
    text: 'This month',
    _text: 'this_month',
  },
  {
    id: 4,
    text: 'Last Year',
    _text: 'last_year',
  },
  {
    id: 5,
    text: 'This Year',
    _text: 'this_Year',
  },
];

const statusSelect = [
  { label: 'All', value: 'all' },
  { label: 'Completed', value: 'completed' },
  { label: 'In progress', value: 'uncompleted' },
];
const HubTasksDesktop = props => {
  const [activeTab, setActiveTab] = useState(props?.selectedTab);

  const sortedTimings = ['Morning', 'Afternoon', 'Evening', 'Any time'];
  useEffect(() => {
    const selectedTabName =
      activeTab == 2
        ? Strings.leaderboard
        : activeTab == 1
          ? Strings.stats
          : Strings.tasks;
    window.history.replaceState(
      null,
      'HubActivities',
      `/hub/v2/${selectedTabName.toLowerCase()}`,
    );

    // console.log('activeTab');
    // console.log(activeTab);
  }, [activeTab]);
  const resWidth = useWidthListener();
  const showTabs = resWidth < 576;
  const tabsLast = [
    {
      href: '/hub/tasks',
      title: Strings.tasks,
      id: 0,
    },
    {
      href: '/hub/stats',
      title: Strings.stats,
      id: 1,
    },
    {
      href: '/hub/leaderboard',
      title: Strings.leaderboard,
      id: 2,
    },
  ];

  const { language, userData } = useBuzzmiAuth();

  const [calendarValue, setCalendarValue] = useState(
    moment().format('YYYY-MM-DD'),
  );
  const [periodType, setPeriodType] = useState(0);
  const [searchActivity, setSearchActivity] = useState('');
  const [searchTimedActivity, setSearchTimedActivity] = useState('');
  const [tags, setTags] = useState([]);
  const [statusFilter, setStatusFilter] = useState(statusSelect[2]);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [showStatsLoadMore, setShowStatsLoadMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingPlanActivityPage, setLoadingPlanActivityPage] = useState(false);
  const [planAct, setPlanAct] = useState([]);

  const [planActPage, setPlanActPage] = useState(1);
  const [planActPageLimit, setPlanActPageLimit] = useState(10);
  const [planActStatsPage, setPlanActStatsPage] = useState(1);
  const [planActStatsPageLimit, setPlanActStatsPageLimit] = useState(5);
  const [dailyTasks, setDailyTasks] = useState([]);

  const [plannedActivitiesGetTime, setPlannedActivitiesGetTime] = useAtom(
    userPlannedActivitiesGetTime,
  );

  const { statListing, getMyStatListing, plannedActivitiesStatsGetTime } =
    useStatListing();
  const {
    // taskList,

    tagsList,
    plannedActivities,
    plannedActivitiesStats,
  } = useHubApp();

  const getStatListing = () => {
    const _time = periodList[periodType]._text;
    getMyStatListing(userData.token, setLoading, {
      title: searchTimedActivity,
      page: planActStatsPage,
      limit: planActStatsPageLimit,
      time: _time,
      tag: tags.toString(),
    });
  };

  useEffect(() => {
    if (statListing?.length > 0) {
      if (statListing?.length < planActPageLimit) {
        setShowStatsLoadMore(false);
      }
      if (planActStatsPage == '1') {
        setDailyTasks([...statListing]);
      } else {
        setDailyTasks([...dailyTasks, ...statListing]);
      }
    }
  }, [statListing]);
  let activityMap = {};
  dailyTasks?.forEach(item => {
    if (!activityMap[item?.when]) {
      activityMap[item?.when] = [];
    }
    activityMap[item?.when].push(item);
  });
  const plannedTasksStats = Object.keys(activityMap).map(when => ({
    title: when.charAt(0).toUpperCase() + when.slice(1),
    list: activityMap[when],
  }));

  const onRefreshCalled = data => {
    EventRegister.emit('refresh-planActivities', data);
  };

  const RefreshPlanActivities = setLoader => {
    // console.log('calendarValue', calendarValue);
    if (searchActivity.length > 0 || tags?.length > 0) {
      onRefreshCalled({
        page: planActPage,
        limit: planActPageLimit,
        title: searchActivity,
        tag: tags,
        setLoading: setLoader,
        // startDate: moment(calendarValue).toISOString(),
        startDate: calendarValue,
        endDate: moment(calendarValue).toISOString(),
        status: statusFilter.value,
      });
    } else {
      onRefreshCalled({
        page: planActPage,
        limit: planActPageLimit,
        setLoading: setLoader,
        startDate: calendarValue,
        endDate: moment(calendarValue).toISOString(),
        status: statusFilter.value,
      });
    }
  };
  useEffect(() => {
    if (activeTab === 0) RefreshPlanActivities(setLoading);
  }, [searchActivity, tags, calendarValue, statusFilter]);

  useEffect(() => {
    if (plannedActivities?.length > 0) {
      if (plannedActivities?.length < planActPageLimit) {
        setShowLoadMore(false);
      }
      if (planActPage == '1') {
        setPlanAct([...plannedActivities]);
      } else {
        setPlanAct([...planAct, ...plannedActivities]);
      }
    }
  }, [plannedActivities]);

  useEffect(() => {
    if (planActPage > 1) {
      RefreshPlanActivities(setLoadingPlanActivityPage);
    }
  }, [planActPage]);

  useEffect(() => {
    if (activeTab === 1) {
      setDailyTasks([]);
      getStatListing();
    }
  }, [tags, periodType, searchTimedActivity, activeTab]);

  /////////////Planned activity mapping/////////////
  const plannedActivityMap = {};

  planAct.forEach(item => {
    if (!plannedActivityMap[item.timing]) {
      plannedActivityMap[item.timing] = [];
    }
    plannedActivityMap[item.timing].push(item);
  });

  const plannedTasks = useMemo(
    () =>
      sortedTimings
        .map(timing => ({
          title: timing.charAt(0).toUpperCase() + timing.slice(1),
          list: plannedActivityMap[timing] || [],
        }))
        .filter(task => task.list.length > 0),
    [plannedActivityMap, planAct],
  );
  /////////////Planned activity mapping end/////////////
  return (
    <div className="lg:px-15 sm:py-5 py-0 px-0">
      {/* <div className="mb-3">
        <Rank withAvatar withRankBtn={false} />
      </div> */}
      <BackBtnWithTitlePage
        title={'Planned Activities'}
        btnText={Strings.back}
      />
      {showTabs && (
        <LinkTabs
          list={tabsLast}
          activeTabId={activeTab}
          onChange={setActiveTab}
        />
      )}
      <div className="mt-3">
        <PlanSearch
          placholderExt={
            activeTab == 2
              ? Strings.leaderboard
              : activeTab == 1
                ? Strings.stats
                : Strings.tasks
          }
          setSearchValue={
            activeTab == 2
              ? () => { }
              : activeTab == 1
                ? setSearchTimedActivity
                : setSearchActivity
          }
        />
      </div>
      {activeTab == 0 ? (
        <>
          <Loading
            loading={loading}
            background="transparent"
            loaderColor={colors.PRIMARY_COLOR}
          />
          <UpdateInfo
            text={`Points last synced on Today, ${plannedActivitiesGetTime}`}
            onBtnClick={() => RefreshPlanActivities(setLoading)}
          />
          {activeTab === 0 && (
            <div className="box box_big mb-2">
              <Calendar
                onChange={setCalendarValue}
                value={calendarValue}
                noIndent
                period="week"
                view="week"
                dayList={plannedActivitiesStats}
              // buttonProps={{text: 'See todays tasks', handler: () => null}}
              />
            </div>
          )}
          {/* {console.log('data ofplanned activities ,props?.data', props?.data)} */}
          <FilterList
            isStat={activeTab === 1}
            data={plannedTasks || []}
            tagsData={tagsList || []}
            setTags={setTags}
            showLoadMore={showLoadMore}
            onLoadMoreClick={() => setPlanActPage(() => planActPage + 1)}
            loading={loadingPlanActivityPage}
            statusSelect={statusSelect}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
          />
        </>
      ) : activeTab == 1 ? (
        <>
          <Loading
            loading={loading}
            background="transparent"
            loaderColor={colors.PRIMARY_COLOR}
          />
          <UpdateInfo
            text={`Points last synced on Today, ${plannedActivitiesStatsGetTime}`}
            onBtnClick={() => getStatListing()}
          />
          <PeriodList
            ligntMode
            items={periodList}
            onClick={setPeriodType}
            value={periodType}
            lightFont
          />

          <FilterList
            isStat={activeTab === 1}
            data={plannedTasksStats || []}
            setTags={setTags}
            tagsData={tagsList || []}
            showLoadMore={showStatsLoadMore}
            onLoadMoreClick={
              () => { }
              // setPlanActPageStaLimit(() => planActPageLimit + 5)
            }
          />
        </>
      ) : (
        // <Leaderboard />
        <div className="delete-tasks__text">
          <div className={"mb-4 flex self-center"}>
            <img src={"/images/construct.png"} className={"h-30 w-30 "} />
          </div>
        </div>
      )}
      {/* </MainLayoutWithAspectSide> */}
    </div>
  );
};

export default HubTasksDesktop;
