import React, {useEffect, useRef, useState} from 'react';
import {colors} from '../../../../../../res/colors';
import {useProfileApp} from '../../../../../../appState/hooks';
import ProfilePostV2 from '../../../../Profile/items/profilePost/ProfilePostV2';
import {Dots} from 'react-activity';
import {EventRegister} from 'react-native-event-listeners';
import {useBuzzmiAuth} from '../../../../../../appState/hooks/useBuzzmiAuth/index.web';

const PostContent = props => {
  const [currentPostId, setCurrentPostId] = useState(null);
  const {buzzmiuserPosts, postLoading} = useProfileApp();
  const [pageLimit, setPageLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [posts, setposts] = useState([]);
  const {userData} = useBuzzmiAuth();

  useEffect(() => {
    if (pageLimit > 20) {
      EventRegister.emit('refresh-buzzmiUser-list', {
        page: 1,
        limit: pageLimit,
      });
    }
  }, [pageLimit]);
  useEffect(() => {
    setposts([]);
    setTimeout(() => {
      setposts(buzzmiuserPosts?.nodes);
    }, 10);
  }, [buzzmiuserPosts]);

  //   console.log('buzzmiuserPosts===>', buzzmiuserPosts);
  return (
    <>
      <ul className={'profile-posts-list__column'}>
        {posts && posts.length > 0 ? (
          <div>
            {posts.map(post => (
              <ProfilePostV2
                key={post?._id}
                postData={post}
                currentPostId={currentPostId}
                type="post"
                isMyPost={post?.owner?.member?.externalId === userData?.id}
              />
            ))}
            {!postLoading && (
              <div
                className="text-alt-primary cursor-pointer pb-2"
                style={{
                  display: 'flex',
                  justifyContent: 'center', // Horizontally center content
                  alignItems: 'center', // Vertically center content
                  width: '100%', // Set the width to 100% if needed
                  // Add any other inline styles you want-
                }}
                onClick={() => {
                  setPageLimit(pageLimit + 20);
                }}>
                Load More
              </div>
            )}
          </div>
        ) : (
          <div className={'flex justify-center mt-5 w-100'}>
            <img
              src="/images/assets/empty_pic.png"
              alt="No Post Found"
              style={{width: 180}}
            />
          </div>
        )}
      </ul>

      {postLoading && (
        <div
          className="mt-2"
          style={{
            display: 'flex',
            justifyContent: 'center', // Horizontally center content
            alignItems: 'center', // Vertically center content
            width: '100%', // Set the width to 100% if needed
          }}>
          <Dots color={colors.PRIMARY_COLOR} />
        </div>
      )}
    </>
  );
};

export default PostContent;
