import React, {useEffect, useState} from 'react';
import {useBuzzmiAuth} from '../../../../appState/hooks/useBuzzmiAuth/index.web.js';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import MyWallet from './MyWallet/index.web.js';
import WalletEarning from './WalletEarning/index.web.js';
import Awards from './Awards/index.web.js';

const HubWalletWeb = props => {
  const {userData} = useBuzzmiAuth();
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleTabSelect = index => {
    setActiveTabIndex(index);
  };

  return (
    <>
      <div>
        <Tabs
          className="profile-section_v2"
          selectedIndex={activeTabIndex}
          onSelect={handleTabSelect}>
          <TabList>
            <Tab style={{color: '#000', fontWeight: 700, fontSize: 14}}>
              My Wallet
            </Tab>
            <Tab style={{color: '#000', fontWeight: 700, fontSize: 14}}>
              Earning
            </Tab>
            <Tab style={{color: '#000', fontWeight: 700, fontSize: 14}}>
              Awards
            </Tab>
          </TabList>

          <TabPanel key="mywallet">
            <MyWallet />
          </TabPanel>
          <TabPanel key="earning">
            <WalletEarning />
          </TabPanel>
          <TabPanel key="awards">
            <Awards />
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
};
export default HubWalletWeb;
