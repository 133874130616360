import { useAtom } from 'jotai';
import { userBadges } from '../hubState';
import { getUserBadges } from '../../../network/api/app';

export const useUserBadges = () => {
    const [usersBadges, setUsersBadges] = useAtom(userBadges);

    const getOnUserBadges = async (id, token) => {
        try {
            const response = await getUserBadges(id)({ token });
            setUsersBadges(response);
        } catch (e) {
            console.log('error => ', e.message);
        }
    };

    return {
        usersBadges,
        getOnUserBadges,
    };
};
