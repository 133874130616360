import React, { useEffect, useState } from 'react';
import ProfilePost from './profilePost/ProfilePost';
import ProfilePostTile from './profilePost/ProfilePostTile';
import ProfileReel from './profileReels/ProfileReel';
import ProfileReelTile from './profileReels/ProfileReelTile';
import useWindowSize from '../../../../hooks/useWindowSize';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useProfileApp } from '../../../../appState/hooks';
import { useBuzzmiAuth } from '../../../../appState/hooks/useBuzzmiAuth/index.web';
import BackBtnWithTitlePage from '../../../../components/reusable/btns/backBtn/BackBtnWithTitlePage';
import { useProfileInfo } from '../../../../appState/hooks/useProfileInfo';

const VisitorViewContentList = props => {
  const { id, ispost } = props;
  const [currentPostId, setCurrentPostId] = useState(id);
  const [isPosts, setIsPost] = useState(ispost == "post" ? true : false);
  const { profileInfo } = useProfileInfo();

  const { width } = useWindowSize();
  const { visitorPosts, visitorReels } = useProfileApp();


  return (
    <div className={'bg-[#faf7ff] flex-1'}>
      <BackBtnWithTitlePage
        title={isPosts ? "Posts" : "Reels"}
        isFixed
        subtitle={'@' + profileInfo?.displayName || undefined}
        btnText="Back"
      />
      <ul
        className={
          isPosts ? 'profile-posts-list__column pt-24 z-[-1] bg-[#faf7ff] mb-10' : 'reels__list-column pt-24 z-[-1] bg-[#faf7ff] mb-10'
        }>
        {isPosts ? (
          visitorPosts && visitorPosts.length > 0 ? (
            visitorPosts.map(post => (
              <ProfilePost
                key={post.id}
                postData={post}
                currentPostId={currentPostId}
                type="post"
                isMyPost={false}
              />
            ))
          ) : (
              <div style={{ textAlign: 'center', width: '100%' }}>
                <h5 className="h5 mb-3 mb-lg-4" style={{ textAlign: 'center' }}>
                  {'No Post Found'}
                </h5>
              </div>
            )
        ) : visitorReels && visitorReels.length > 0 ? (
          visitorReels.map((reel, index) => (
            <React.Fragment key={reel.id}>
              {width < 767 ? (
                <ProfilePost
                  postData={reel}
                  currentPostId={currentPostId}
                  type="reel"
                  isMyPost={false}
                />
              ) : (
                  <ProfileReel  isMyPost={false} reel={reel} currentPostId={currentPostId} />
                )}
            </React.Fragment>
          ))
        ) : (
              <div style={{ textAlign: 'center', width: '100%' }}>
                <h5 className="h5 mb-3 mb-lg-4" style={{ textAlign: 'center' }}>
                  {'No Reel Found'}
                </h5>
              </div>
            )}
      </ul>
    </div>
  );
};

VisitorViewContentList.propTypes = {
  isPosts: true,
};

export default VisitorViewContentList;
