import React from 'react';
import Button from '../../../../../reusable/btns/buttons/Button';

const AddTaskDesktopAlert = ({handleOK, isPlan}) => {
  return (
    <div className="d-none d-md-block">
      <div className="hub__cup mt-4">
        <img src="/images/assets/cup.png" alt="cup -img" />
      </div>
      <div className="delete-tasks__text">
        {isPlan ? (
          <div className="h4 mb-21">
            The task has been successfully added to your schedule
          </div>
        ) : (
          <div className="h4 mb-21">The task has been successfully created</div>
        )}
        {/* <div className="text-r mb-6">
          The task will be deleted from all users without the possibility of
          recovery.
        </div> */}
      </div>

      <div className="delete-tasks__buttons">
        <div className="d-flex justify-content-center">
          <Button
            className="_fw button_secondary button_m double-secondary"
            onClick={handleOK}>
            {/* Delete from all */}
            OK
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddTaskDesktopAlert;
