import {NavLink} from 'react-router-dom';
import cn from 'classnames';
import {Link, useLinkTo} from '@react-navigation/native';

const LinkTabs = ({list, onChange, activeTabId, lightUnchecked}) => {
  const linkTo = useLinkTo();
  return (
    <div
      className={cn(`react-tabs__tab-list`, {
        'react-tabs__tab-list--light-unchecked': lightUnchecked,
      })}
      role="tablist">
      {list.length > 0 &&
        list.map((item, index) => {
          return !!onChange ? (
            <button
              className={
                'react-tabs__tab text-[11px]' +
                (activeTabId === item.id ? ' react-tabs__tab--selected' : '')
              }
              onClick={() => {
                onChange(item.id);
              }}
              key={item?.id || index + 'linkTabs'}>
              {item.title}
            </button>
          ) : (
            <a
              style={{cursor: 'pointer'}}
              className={
                'react-tabs__tab' +
                (activeTabId === item.id ? ' react-tabs__tab--selected' : '')
              }
              key={index + 'linkTabs'}
              onClick={() => {
                linkTo(item?.href || index + 'linkTabs');
              }}>
              {item.title}
            </a>
          );
        })}
    </div>
  );
};

export default LinkTabs;
