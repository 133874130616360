import React, { useEffect, useRef, useState } from 'react';
import Badge from '../../../../../../../components/reusable/badge/Badge.jsx';
import cn from 'classnames';

const FilterTags = ({ tags, wrapMode = false, setTags }) => {
  const [selectedTags, setSelectedTags] = useState([]);
  const badgeRowRef = useRef(null);

  const scrollToRightEnd = () => {
    if (badgeRowRef.current) {
      const scrollWidth = badgeRowRef.current.scrollWidth;
      const currentScrollLeft = badgeRowRef.current.scrollLeft;
      const remainingScroll = scrollWidth - currentScrollLeft;

      const animationDuration = 500; // in milliseconds

      // Calculate the number of frames based on 60fps
      const frames = Math.ceil(animationDuration / (1000 / 60));
      const scrollStep = remainingScroll / frames;

      let currentFrame = 0;

      const scrollWithAnimation = () => {
        badgeRowRef.current.scrollLeft += scrollStep;

        currentFrame += 1;
        if (currentFrame < frames) {
          requestAnimationFrame(scrollWithAnimation);
        }
      };

      // Start the animation
      setTimeout(() => {
        scrollWithAnimation();
      }, 0);
    }
  };


  const onTagClick = tag => {
    console.log('this is inside include', tag);
    if (tag === 'All') {
      setSelectedTags(['All']);
      return;
    }

    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter(t => t !== tag));
      //TODO code for removing tag from url or another logic
    } else {
      let ta = selectedTags;
      if (tag !== 'All') {
        if (selectedTags.includes('All')) {
          console.log('all is here');
          ta = selectedTags.filter(t => {
            t !== 'All';
          });
        }
      }

      setSelectedTags([...ta, tag]);
      //TODO code for adding tag to url or another logic
    }
  };
  useEffect(() => {
    return () => {
      setSelectedTags([]);
    };
  }, []);
  useEffect(() => {
    setTags
      ? selectedTags.includes('All')
        ? setTags([])
        : setTags(selectedTags)
      : null;
  }, [selectedTags]);

  return (
    <div className="badge__row-container">
      <div className="badge__row" ref={badgeRowRef} >
        {tags?.length > 0 &&
          tags.map((tag, index) => (
            <Badge
              active={selectedTags.includes(tag)}
              title={tag}
              index={index}
              key={index}
              onClick={() => onTagClick(tag)}
            />

          ))}
        <p onClick={scrollToRightEnd} className='sticky right-0 bg-[#fff] px-[8px] rounded-3xl  text-[#e646a0]'>{">>"}</p>
      </div>
    </div>
  );
};

export default FilterTags;
