import React, {useEffect, useState} from 'react';
import Logo from '../../../reusable/assets/logo/Logo.jsx';
import MainLayoutMenuItemProfile from './mainLyoutMenuItem/MainLayoutMenuItemProfile.jsx';
import MainLayoutMenuItem from './mainLyoutMenuItem/MainLayoutMenuItem.jsx';
import {useBuzzmiAuth} from '../../../../appState/hooks/useBuzzmiAuth/index.web.js';
import Strings from '../../../../string_key/Strings.web.js';
import {useNavigation} from '@react-navigation/core';
import {useLocation} from 'react-router-dom';

const MainLayoutSidebar = ({showLogo = true}) => {
  const [avatar, setAvatar] = useState(null);
  const {userData, language} = useBuzzmiAuth();
  const navigation = useNavigation();
  const [activeMenu, setActiveMenu] = useState('');
  const location = useLocation();

  const hub_items = [
    {
      iconSrc: '/images/layout/main-layout/home.svg',
      activeIconSrc: '/images/layout/main-layout/active-link/home.svg',
      name: Strings.home,
      to: '/hub/home',
      showOnMobile: true,
    },
    {
      iconSrc: '/images/layout/main-layout/hub.svg',
      activeIconSrc: '/images/layout/main-layout/active-link/hub.svg',
      name: Strings.hub,
      to: '/hub/hub-activities',
      showOnMobile: true,
    },
  ];
  const items = [
    {
      iconSrc: '/images/layout/main-layout/notifications.svg',
      activeIconSrc: '/images/layout/main-layout/active-link/notifications.svg',
      name: Strings.notifications,
      to: '/notifications',
    },
    {
      iconSrc: '/images/layout/main-layout/messages.svg',
      activeIconSrc: '/images/layout/main-layout/active-link/messages.svg',
      name: Strings.messages,
      to: '/messages',
    },
    {
      iconSrc: '/images/layout/main-layout/space.svg',
      activeIconSrc: '/images/layout/main-layout/active-link/space.svg',
      name: Strings.space,
      to: '/space',
      showOnMobile: true,
    },
    {
      iconSrc: '/images/layout/main-layout/subscription.svg',
      activeIconSrc: '/images/layout/main-layout/active-link/subscription.svg',
      name: Strings.subscription,
      to: '/subscription',
      showOnMobile: true,
    },
    {
      iconSrc: '/images/layout/main-layout/challenges.svg',
      activeIconSrc: '/images/layout/main-layout/active-link/challenges.svg',
      name: Strings.challenges,
      to: '/challenges',
      showOnMobile: true,
    },
    {
      iconSrc: '/images/layout/main-layout/calendar.svg',
      activeIconSrc: '/images/layout/main-layout/active-link/calendar.svg',
      name: Strings.calendar,
      to: '/calendar',
    },
    {
      iconSrc: '/images/layout/main-layout/wallet.svg',
      activeIconSrc: '/images/layout/main-layout/active-link/wallet.svg',
      name: Strings.wallet,
      to: '/wallet',
    },
  ];

  const handleGetAvatar = async () => {
    try {
      //TODO: get avatar from server
      const avatar = '/images/moc/avatar.png';
      setAvatar(avatar);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    handleGetAvatar();
  }, []);

  const onHandelClick = to => {
    global.activeMenu = to;
  };

  return (
    <div className="main-layout__sidebar">
      {/* {showLogo && (
        <div className="main-layout__logo">
          <Logo />
        </div>
      )} */}
      <ul className="main-layout__menu">
        {hub_items.map(item => (
          <MainLayoutMenuItem
            to={item.to}
            key={item.to}
            name={item.name}
            iconSrc={item.iconSrc}
            navigation={navigation}
            showOnMobile={item?.showOnMobile}
            activeIconSrc={item.activeIconSrc}
            onHandelClick={onHandelClick}
          />
        ))}
        <MainLayoutMenuItemProfile
          navigation={navigation}
          src={userData?.avatar ? userData?.avatar : avatar}
          onHandelClick={onHandelClick}
        />
        {items.map(item => (
          <MainLayoutMenuItem
            to={item.to}
            key={item.to}
            name={item.name}
            iconSrc={item.iconSrc}
            navigation={navigation}
            showOnMobile={item?.showOnMobile}
            activeIconSrc={item.activeIconSrc}
            onHandelClick={onHandelClick}
          />
        ))}
      </ul>
    </div>
  );
};

export default MainLayoutSidebar;
