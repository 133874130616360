import React, { useEffect } from 'react';
import PrimaryButton from '../../../components/reusable/form/items/buttons/PrimaryButton';
import DoubleLayout from "../../../components/layouts/doubleLayout/DoubleLayout.jsx";
import QuadServicesButtons from "../Login/items/serviceButtons/QuadServicesButtons"
import { Link, useNavigation } from '@react-navigation/native';
import Strings from '../../../string_key/Strings.web';
import Policy from "./items/Policy.jsx";
import { useBuzzmiAuth } from '../../../appState/hooks/useBuzzmiAuth/index.web';
import { colors } from '../../../res/colors';

const Registration = (props) => {
  const { navigate } = useNavigation();
  const { language } = useBuzzmiAuth();

  return (
    <DoubleLayout reverse>
      <div className='registration form-wrapper'>
        <div className='h3 text-center registration__title'>{Strings.lets_get_your_setup}</div>
        <QuadServicesButtons props={props} />
        <div className='login__assets'>{Strings.or}</div>
        <PrimaryButton onClick={() => navigate('ProvidePhone')}
          className='registration__btn'>{Strings.signup_with_phone_or_email}</PrimaryButton>
        <Policy />
        <div className='text-center text-r registration__footer'>
          <span className={'text-secondary'}>{Strings.already_have_an_account}</span>&nbsp;
          <Link to='/v2/login'>
            <text  style={{ color: colors.PRIMARY_COLOR }}>{Strings.log_in}</text>
          </Link>
        </div>
      </div>
    </DoubleLayout>
  );
};

export default Registration;
