import React from 'react';

const PersonalCard = ({name, handle, title, values, profile, wallet}) => {
  const outputArray = [];

  for (const key in wallet) {
    if (wallet.hasOwnProperty(key)) {
      const value = wallet[key];
      const img =
        key == 'diamonds'
          ? `/images/hub/wallet/diamond.svg`
          : '/images/hub/wallet/energy.svg';

      outputArray.push({value, img});
    }
  }

  return (
    <div className="personal-card">
      <div className="personal-card__img">
        <img src={profile} alt={name} />
      </div>
      <div className="personal-card__description">
        <div className="personal-card__description-holder">
          <div className="personal-card__description-row">
            <span className="personal-card__name">{name}</span>
            <span className="personal-card__nick-name">{handle}</span>
          </div>
          {title && <p className="personal-card__info">{title}</p>}
        </div>
        {outputArray && outputArray.length > 0 && (
          <div className="personal-card__values">
            {outputArray.map((valuesItem, index) => {
              return (
                <div
                  className="personal-card__values-item"
                  key={valuesItem.value + index}>
                  <span className="personal-card__values-item-text">
                    {valuesItem.value}
                  </span>
                  <div className="personal-card__values-item-icon">
                    <img src={valuesItem.img} alt="icon" />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default PersonalCard;
