import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { socialSideBarNavAtom } from '../../../../appState/navState';
import DiscoverList from './items/DiscoverList/DiscoverList';
import FollowingPostContent from './items/FollowingPostContentList/FollowingPostContent';
import PostContent from './items/PostContentList/PostContent';

const SocialDashboardContentTab = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [activeSideItem, setActiveSideItem] = useAtom(
    socialSideBarNavAtom || 'ForYou',
  );

  const handleTabSelect = index => {
    setActiveTabIndex(index);
    setActiveSideItem(
      index == 1 ? 'Following' : index == 2 ? 'Discover' : 'ForYou',
    );
  };

  useEffect(() => {
    activeSideItem == 'ForYou'
      ? setActiveTabIndex(0)
      : activeSideItem == 'Following'
        ? setActiveTabIndex(1)
        : setActiveTabIndex(2);
  }, [activeSideItem]);

  return (
    <Tabs
      className="profile-section_v2 profile-stick-v2"
      selectedIndex={activeTabIndex}
      onSelect={handleTabSelect}>
      <TabList style={{ marginBottom: 16 }}>
        <Tab style={{ color: '#5f5e60', fontWeight: 600 }}>For you</Tab>
        <Tab style={{ color: '#5f5e60', fontWeight: 600 }}>Following</Tab>
        <Tab style={{ color: '#5f5e60', fontWeight: 600 }}>Discover</Tab>
      </TabList>
      <TabPanel key="foryou">
        <PostContent />
      </TabPanel>
      <TabPanel key="following">
        <FollowingPostContent />
      </TabPanel>
      <TabPanel key="discover">
        <DiscoverList />
      </TabPanel>
    </Tabs>
  );
};

export default SocialDashboardContentTab;
