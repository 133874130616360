import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import BackBtnWithTitlePage from '../../../../../components/reusable/btns/backBtn/BackBtnWithTitlePage';
import MainLayoutContent from '../../../../../components/layouts/mainLayout/mainLayoutContent/MainLayoutContent';
import {postsData} from '../../../../../mock-data/posts/postsData';
import formatRelativeDate from './utils/dateUtils';
import {useNavigation, useRoute} from '@react-navigation/core';
import MainLayout from '../../../../../components/layouts/mainLayout/MainLayout';
const GemsPostScreen = () => {
  // const {id} = useParams();
  // const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const {navigate} = useNavigation();
  const route = useRoute();
  const {id} = route?.params;

  useEffect(() => {
    const foundPost = postsData.find(post => post.id === Number(id));
    setPost(foundPost);
  }, [id]);

  const handleBackButtonClick = () => {
    const backUrl = `/profile#post-${id}`;
    navigate(backUrl);
  };

  return (
    <MainLayout>
      <MainLayoutContent>
        <BackBtnWithTitlePage
          btnText="Back to post"
          title={`Gems (${post?.gems?.length || 0})`}
          customCallback={handleBackButtonClick}
        />

        <ul className="gems-post__list d-flex flex-column">
          {post?.gems?.map(gem => (
            <li
              key={gem?.id}
              className="gems-post__item d-flex flex-row align-items-center justify-content-between">
              <div className="gems-post__gem-info">
                <p className="gems-post__gem-date">
                  {formatRelativeDate(gem?.date)}
                </p>
                <img
                  src={gem?.author?.avatar?.src}
                  alt="avatar"
                  className="gems-post__gem-author-avatar"
                />
                <p className="h6">Sent by {gem?.author?.name}</p>
              </div>

              <div className="gems-post__gem-image-wrapper d-flex flex-row align-items-center">
                <span className="h5">+{gem?.quantity}</span>
                <img
                  src="/images/post/gem-image.webp"
                  alt="gem image"
                  className="gem-image"
                />
              </div>
            </li>
          ))}
        </ul>
      </MainLayoutContent>
    </MainLayout>
  );
};

export default GemsPostScreen;
