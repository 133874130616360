import React from 'react';
import Tooltip from '../tooltip/Tooltip.jsx';

const Info = ({ text, isLeft }) => {
  return (
    <div className="info">
      <Tooltip position="top" tooltipText={text} isLeft={isLeft}>
        <div className="info__icon">i</div>
      </Tooltip>
    </div>
  );
};

export default Info;
