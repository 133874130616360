import React, { useEffect, useState } from 'react';
import ProfilePost from './profilePost/ProfilePost';
import ProfilePostTile from './profilePost/ProfilePostTile';
import ProfileReel from './profileReels/ProfileReel';
import ProfileReelTile from './profileReels/ProfileReelTile';
import useWindowSize from '../../../../hooks/useWindowSize';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useProfileApp } from '../../../../appState/hooks';
import { useBuzzmiAuth } from '../../../../appState/hooks/useBuzzmiAuth/index.web';
import Loading from 'react-fullscreen-loading';
import { colors } from '../../../../res/colors';
import ProfileViewContentList from './ProfileViewContentList';
import VisitorViewContentList from './VisitorViewContentList';
import { useLinkTo } from '@react-navigation/native';

const VisitorProfileContentList = ({ isPosts }) => {
  const [isTableActive, setIsTableActive] = useState(true);
  const [currentPostId, setCurrentPostId] = useState(null);
  const { visitorReels, visitorPosts, postUploading, reelUploading } = useProfileApp();
  const linkTo = useLinkTo();

  const handleSwitchAnchor = id => {
    setCurrentPostId(id);
    linkTo(`/profile/visitor-view/${id}/${isPosts ? 'post' : 'reel'}`)
  };

  return (
    <>
      <Loading
        loading={postUploading || reelUploading}
        background="transparent"
        loaderColor={colors.PRIMARY_COLOR}
      />
      <ul
        className={
          isPosts ? 'profile-posts-list__table' : 'reels__list-table'
        }>
        {isPosts ? (
          visitorPosts && visitorPosts.length > 0 ? (
            visitorPosts.map(post => (
              <ProfilePostTile
                key={post?.id}
                post={post}
                handleSwitchAnchor={handleSwitchAnchor}
                contentType={post?.contentType ? post?.contentType : 'all'}
              />
            ))
          ) : (
              <div className={"flex justify-center mt-5 w-100"}>
                <img
                  src="/images/assets/empty_pic.png"
                  alt="No Post Found"
                  style={{ width: 180 }}
                />
              </div>
            )
        ) : visitorReels && visitorReels.length > 0 ? (
          visitorReels.map(reel => (
            <ProfileReelTile
              key={reel?.id}
              reel={reel}
              handleSwitchAnchor={handleSwitchAnchor}
              contentType={reel?.contentType ? reel?.contentType : 'all'}
            />
          ))
        ) : (
              <div className={"flex justify-center mt-5 w-100"}>
                <img
                  src="/images/assets/empty_pic.png"
                  alt="No Reel Found"
                  style={{ width: 180 }}
                />
              </div>
            )}
      </ul>
    </>
  );
};

VisitorProfileContentList.propTypes = {
  isPosts: PropTypes.bool.isRequired,
};

export default VisitorProfileContentList;