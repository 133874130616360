import {useMutation} from 'react-query';
import {postCoverImage} from '../../../network/api/app';

export const useUploadCoverList = () => {
  const {
    mutateAsync: mutateCoverFileUploadAsync,
    // isLoading: isFileUploadLoading,
    // isError: isFileUploadError,
    // data: fileUploadResponse,
    // error: fileUploadErrorResponse,
  } = useMutation(postCoverImage);

  const UploadCoverFiles = (params, callback) => {
    console.log('params inside hooks', params);
    let promiseArray = [];
    params.forEach(param => {
      promiseArray.push(mutateCoverFileUploadAsync(param));
    });

    Promise.all(promiseArray)

      .then(res => {
        callback(res.map(response => response));
      })
      .catch(e => console.log('Error => ', e.message));
  };

  return {
    UploadCoverFiles,
    // fileUploadResponse,
    // fileUploadErrorResponse,
    // isFileUploadError,
    // isFileUploadLoading,
  };
};
