import React, { useEffect, useState } from "react"
import ProfileReel from "./ProfileReel"
import BackBtnWithTitlePage from "../../../../../components/reusable/btns/backBtn/BackBtnWithTitlePage"
import { useSwipeable } from "react-swipeable"
import { useProfileApp } from "../../../../../appState/hooks"

const VisitorReelsFullscreen = (props) => {
  const { id } = props.route.params;
  const [reels, setReels] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0)
  const { visitorReels } = useProfileApp();

  useEffect(() => {
    setReels(visitorReels)
  }, [visitorReels])

  useEffect(() => {
    if (reels.length > 0) {
      const foundIndex = reels.findIndex((reel) => reel?.id === id)
      setCurrentIndex(foundIndex)
    }
  }, [id, reels])

  const handlers = useSwipeable({
    onSwipedUp: () => swipeUp(),
    onSwipedDown: () => swipeDown(),
  })

  const swipeUp = () => {
    const nextIndex = (currentIndex + 1) % reels.length
    setCurrentIndex(nextIndex)
  }

  const swipeDown = () => {
    const prevIndex = (currentIndex - 1 + reels.length) % reels.length
    setCurrentIndex(prevIndex)
  }

  return (
    <div className="reels__fullscreen" {...handlers}>
      <BackBtnWithTitlePage btnText="" title="Reels" />
      {reels.length > 0 && <ProfileReel reel={reels[currentIndex]} currentPostId={null} />}
    </div>
  )
}

export default VisitorReelsFullscreen
