import React, {useState, useRef, useEffect} from 'react';
import MobileLayout from '../../../components/layouts/mobileLayout/MobileLayout.jsx';
import CreatePasswordForm from './items/CreatePasswordForm.jsx';
import PrimaryButton from '../../../components/reusable/form/items/buttons/PrimaryButton.jsx';
import BaseLayout from '../../../components/layouts/baseLayout/BaseLayout';
import {useNavigation, useRoute} from '@react-navigation/native';
import Loading from 'react-fullscreen-loading';
import {useToast} from 'react-native-toast-notifications';
import {ShowSuccess, ShowError} from '../../HOC/MessageWeb.js';
import {REGISTER} from '../../../api_helper/Api.js';
import {POST} from '../../../api_helper/ApiServices.js';
import {colors} from '../../../res/colors.js';
import {saveData} from '../../../res/asyncStorageHelper.web.js';
import Strings from '../../../string_key/Strings.web.js';
import {
  _sessionTokenAtom,
  _userEmailAtom,
} from '../../../appState/userState.js';
import {useEncryptedAtom} from '../../../appState/hooks/useEncryptedAtom/index.web.js';

const CreatePassword = () => {
  const {navigate} = useNavigation();
  const {params} = useRoute();
  let refUrlParams = useRef(params);
  const [isLoad, setIsLoad] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const toast = useToast();
  const [sessiontoken, setSessionToken] = useEncryptedAtom(_sessionTokenAtom);
  const [userRoles, setUserEmail] = useEncryptedAtom(_userEmailAtom);

  const submitCallback = async formData => {
   
    // if (sessiontoken) {
    //   navigate('MembershipV2');
    //   return;
    // }
    setIsLoad(true);
    let postData = {
      gender: refUrlParams?.current.param.gender,
      firstName: refUrlParams?.current.param.firstname,
      lastName: refUrlParams?.current.param.lastname,
      dob: new Date(refUrlParams?.current.param.birthday).toISOString(),
      mobile:
        refUrlParams?.current.param.countryCode +
        refUrlParams?.current.param.mobileno,
      email: refUrlParams?.current.param.email,
      referralCode: refUrlParams?.current.param.code
        ? refUrlParams?.current.param.code
        : '',
      username: refUrlParams?.current.param.name,
      password: formData?.confirmPassword,
      isEmailVerified: refUrlParams?.current.param.isEmailVerified,
      isMobileVerified: refUrlParams?.current.param.isMobileVerified,
    };
    console.log('postData', postData);
    POST(REGISTER, false, null, null, postData, (response, isError) => {
      console.log('response on register==>', response);
      setIsLoad(false);
      if (isError) {
        ShowError(response, toast);
      } else {
        setUserEmail(refUrlParams?.current.param.email);
        setSessionToken(response?.sessionToken);
        // saveData("sessionToken", response?.sessionToken)
        // saveData("user_email", refUrlParams?.current.param.email)
        // navigate('ChoseRole')
        navigate('MembershipV2');
        setTimeout(() => {
          ShowSuccess(response?.message, toast);
        }, 250);
      }
    });
  };

  useEffect(() => {
    const unloadCallback = event => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, []);

  return (
    <BaseLayout>
      <Loading
        loading={isLoad}
        background="transparent"
        loaderColor={colors.PRIMARY_COLOR}
      />

      <MobileLayout>
        <div className="mb-4">
          <div className="h5 mb-6">{Strings.password_priority_title}</div>
          <div
            className="mb-9"
            style={{justifyContent: 'center', display: 'flex'}}>
            <img src="/images/assets/shield.png" alt="shield" />
          </div>
          <CreatePasswordForm
            submitHandler={submitCallback}
            setValid={setIsValid}
          />
          <div className=" border=[#584F68] border rounded-3xl items-center justify-center py-4 px-3">
            <div className="mb-2 h6 text-secondary ">
              {Strings.password_requirement}
            </div>
            <div className="text-r text-secondary leading-5">
              <span className="fw-bold">{Strings.make_it_long}</span>{' '}
              {Strings.from_8_to_20_characters}.{' '}
            </div>
            <div className="text-r text-secondary leading-5">
              <span className="fw-bold">{Strings.make_it_diverse}</span>{' '}
              {Strings.password_should_contain_one_special_character}{' '}
              {Strings.example} {'@ % + /'}
            </div>
          </div>
        </div>

        <div>
          <PrimaryButton
            className={`mb-7 ${!isValid ? 'button_inactive' : ''}`}
            type="submit"
            form="create-password">
            {Strings.next}
          </PrimaryButton>
        </div>
      </MobileLayout>
    </BaseLayout>
  );
};

export default CreatePassword;
