import React, { useEffect } from "react"
import Box from "../../../../../components/reusable/cards/box/Box"
import CustomLink from "../../../../../components/reusable/btns/links/CustomLink"
import Avatar from "../../../../../components/reusable/assets/avatar/Avatar"
import ProfileBio from "../../items/ProfileBio"
import ProfileStories from "../../items/profileStories/ProfileStories"
import ProfileDesktopTabs from "../../items/profileDesktopTabs/ProfileDesktopTabs"
import UserBtnsRowDesktop from "../items/userBtnsRow/UserBtnsRowDesktop"
import { useBuzzmiAuth } from "../../../../../appState/hooks/useBuzzmiAuth/index.web"
import { useProfileInfo } from "../../../../../appState/hooks/useProfileInfo"
import { useProfileApp } from "../../../../../appState/hooks"

import { ChatBubbleBottomCenterIcon, ShoppingBagIcon } from "@heroicons/react/24/outline"
import VisitorProfileContentTabs from "../../items/visitorProfileContentTabs/VisitorProfileContentTabs"
import { useVisitorRankInfo } from "../../../../../appState/hooks/useVisitorRankInfo"

const ProfileUserDesktop = ({ isOtherProfile }) => {
  const { userData } = useBuzzmiAuth();
  const { profileInfo } = useProfileInfo();
  const { getVisitorPostLists, getVisitorReelLists, } = useProfileApp();
  const { getVisitorRankInfoDetail } = useVisitorRankInfo()

  useEffect(() => {
    if (profileInfo && isOtherProfile == true) {
      getVisitorPostLists(userData?.token, profileInfo?.id, { page: 1, limit: 20 });
      getVisitorReelLists(userData?.token, profileInfo?.id, { page: 1, limit: 20 });
      getVisitorRankInfoDetail(profileInfo?.id, userData?.token);
    }
  }, [profileInfo, isOtherProfile]);


  return (
    <div className="max-w-3xl mx-auto">
      <Box paddingMode="big" className="profile-top__desktop-wrap">
        <div>
          <Avatar isOtherProfile />
        </div>
        <ProfileBio isOtherProfile />
        {/* <div className=" d-flex flex-column justify-content-center">
          
        </div> */}
      </Box>
      <UserBtnsRowDesktop />
      <ProfileStories />
      {/* <ProfileDesktopTabs isOtherProfile /> */}
      <div className="mt-4">
        <VisitorProfileContentTabs />
      </div>
      </div>
  )
}

export default ProfileUserDesktop
