import React, { useEffect, useState } from 'react';
import ProfilePost from './profilePost/ProfilePost';
import ProfileReel from './profileReels/ProfileReel';
import useWindowSize from '../../../../hooks/useWindowSize';
import BackBtnWithTitlePage from '../../../../components/reusable/btns/backBtn/BackBtnWithTitlePage';
import { useBookMarkLists } from '../../../../appState/hooks/useBookMarkLists';
import ProfilePostV2 from './profilePost/ProfilePostV2';

const BookmarkUserProfile = props => {
  const { isFromMobile } = props;
  const [currentPostId, setCurrentPostId] = useState(null);
  const [isPosts, setIsPost] = useState(true);
  const { width } = useWindowSize();
  const { savedLists } = useBookMarkLists();

  return (

    <div className={'bg-[#faf7ff] flex-1'}>
      <BackBtnWithTitlePage
        isFixed={isFromMobile ? false : true}
        btnText="Back"
        hideBottomBorder

      />
      <div className="max-w-3xl mx-auto">
        <ul
          className={
            isPosts ? 'profile-posts-list__column sm:pt-0 pt-4 z-[-1] bg-[#faf7ff] mb-10' : 'reels__list-column sm:pt-0 pt-4 z-[-1] bg-[#faf7ff] mb-10'
          }>
          {isPosts ? (
            savedLists && savedLists.length > 0 ? (
              savedLists.map(post => (
                <ProfilePostV2
                  key={post?.id}
                  postData={post}
                  currentPostId={currentPostId}
                  type="post"
                  isMyPost={false}
                />
              ))
            ) : (
                <div style={{ textAlign: 'center', width: '100%' }}>
                  <h5 className="h5 mb-3 mb-lg-4" style={{ textAlign: 'center' }}>
                    {'No Post Found'}
                  </h5>
                </div>
              )
          ) : savedLists && savedLists.length > 0 ? (
            savedLists.map((reel, index) => (
              <React.Fragment key={reel.id}>
                {width < 767 ? (
                  <ProfilePost
                    postData={reel}
                    currentPostId={currentPostId}
                    type="reel"
                    isMyPost={false}
                  />
                ) : (
                    <ProfileReel reel={reel} isMyPost={false} currentPostId={currentPostId} />
                  )}
              </React.Fragment>
            ))
          ) : (
                <div style={{ textAlign: 'center', width: '100%' }}>
                  <h5 className="h5 mb-3 mb-lg-4" style={{ textAlign: 'center' }}>
                    {'No Reel Found'}
                  </h5>
                </div>
              )}
        </ul>
      </div>
    </div>
  );
};

BookmarkUserProfile.propTypes = {
  isPosts: true,
};

export default BookmarkUserProfile;
