import React from 'react';
import EllipsisMenu from '../../../ellipsisMenu/EllipsisMenu';
import EllipsisMenuV2 from '../../../ellipsisMenu/EllipsisMenuV2';
import PostAuthorInfo from './PostAuthorInfo';

const PostHeaderV2 = ({
  userName,
  userNickname,
  userPosition,
  userAvatar,
  isVerified,
  post,
  isMyPost = true
}) => {
  return (
    <div className="header-post flex justify-between items-start">
      <PostAuthorInfo
        userName={userName}
        userNickname={userNickname}
        userPosition={userPosition}
        userAvatar={userAvatar}
        isVerified={isVerified}
        isMyPost={isMyPost}
        post={post}
      />
      <EllipsisMenuV2 type="post" isMyPost={isMyPost} Id={post?.id} post={post}/>
    </div>
  );
};

export default PostHeaderV2;
