import React, { useEffect, useState } from 'react';
import MainLayoutV2 from '../../../../components/layouts/mainLayout/MainLayoutV2';
import MainLayoutContentV2 from '../../../../components/layouts/mainLayout/mainLayoutContentV2/MainLayoutContentV2';
import ResizeComponent from '../../../../components/reusable/assets/resizeComponent/ResizeComponent';
import VisitorViewDesktop from './VisitorViewDesktop.jsx'
import VisitorViewMobile from './VisitorViewMobile.jsx'
import useWindowSize from '../../../../hooks/useWindowSize';

const VisitorViewContentList = props => {
  const { id, ispost } = props.route.params;
  const { width } = useWindowSize();

  return (
    <>
      {width > 992 ?
        <MainLayoutV2 centerSpace={true}>
          <MainLayoutContentV2 iscenter={true} rightColumnContent={<div />}>
            <ResizeComponent
              desktopComponent={<VisitorViewDesktop id={id} ispost={ispost} />}
            />
          </MainLayoutContentV2>
        </MainLayoutV2>
        :
        <VisitorViewMobile id={id} ispost={ispost} />
      }
    </>
  );
};

VisitorViewContentList.propTypes = {
  isPosts: true,
};

export default VisitorViewContentList;
