import React, { useState, useRef } from 'react';
import BackBtnWithTitlePage from '../../../../components/reusable/btns/backBtn/BackBtnWithTitlePage';
import LinkTabs from '../../../../components/reusable/assets/linkTabs/LinkTabs';
import PlanSearch from '../../../../components/reusable/assets/additionalInformation/items/additionalInformationPlan/items/planSearch/PlanSearch';
import ProfileMobileUsersTabs from './items/ProfileMobileUsersTabs';
import ProfileMobileUsersSort from './items/profileMobileUsersSort/ProfileMobileUsersSort';
import { useEffect } from 'react';
import { useProfileApp } from '../../../../appState/hooks';
import { useBuzzmiAuth } from '../../../../appState/hooks';
import { Tabs, TabList, Tab } from 'react-tabs';
import { useEventEmitter } from '../../../../hooks/useEventEmitter';

const mockData = {
  name: 'Jane Doe',

  following: {
    users: [
      {
        id: 1,
        name: 'Matilda',
        nickname: 'matilda',
        avatar: '/images/creators/1.jpg',
        isElite: true,
        isFollow: true,
        isVerifi: true,
      },
      {
        id: 2,
        name: 'Jarvis',
        nickname: 'jarvis',
        avatar: '/images/creators/2.jpg',
        isFollow: true,
      },
      {
        id: 3,
        name: 'Clara',
        nickname: 'clara',
        avatar: '/images/creators/3.jpg',
        isElite: true,
        isFollow: true,
        isVerifi: true,
      },
    ],
    suggestedForYou: [
      {
        id: 4,
        name: 'Matilda',
        nickname: 'matilda',
        avatar: '/images/creators/1.jpg',
        isElite: true,
        isFollow: false,
      },
      {
        id: 5,
        name: 'Jarvis',
        nickname: 'jarvis',
        avatar: '/images/creators/2.jpg',
        isFollow: false,
      },
      {
        id: 6,
        name: 'Clara',
        nickname: 'clara',
        avatar: '/images/creators/3.jpg',
        isElite: true,
        isFollow: false,
      },
    ],
  },
  followers: {
    users: [
      {
        id: 1,
        name: 'Matilda',
        nickname: 'matilda',
        avatar: '/images/creators/1.jpg',
        isElite: true,
        isFollow: true,
      },
      {
        id: 2,
        name: 'Jarvis',
        nickname: 'jarvis',
        avatar: '/images/creators/2.jpg',
        isFollow: true,
      },
      {
        id: 3,
        name: 'Clara',
        nickname: 'clara',
        avatar: '/images/creators/3.jpg',
        isElite: true,
        isFollow: true,
      },
    ],
    suggestedForYou: [
      {
        id: 4,
        name: 'Matilda',
        nickname: 'matilda',
        avatar: '/images/creators/1.jpg',
        isElite: true,
        isFollow: false,
      },
      {
        id: 5,
        name: 'Jarvis',
        nickname: 'jarvis',
        avatar: '/images/creators/2.jpg',
        isFollow: false,
      },
      {
        id: 6,
        name: 'Clara',
        nickname: 'clara',
        avatar: '/images/creators/3.jpg',
        isElite: true,
        isFollow: false,
      },
    ],
  },
  subscribers: {
    users: [
      {
        id: 1,
        displayName: 'Matilda',
        username: 'matilda',
        avatar: '/images/creators/1.jpg',
        isElite: true,
        isFollow: true,
        state: true,
      },
      {
        id: 2,
        displayName: 'Jarvis',
        username: 'jarvis',
        avatar: '/images/creators/2.jpg',
        isFollow: true,
        state: false,
      },
      {
        id: 3,
        displayName: 'Clara',
        username: 'clara',
        avatar: '/images/creators/3.jpg',
        isElite: true,
        isFollow: true,
        state: true,
      },
    ],
  },
};

export const tabsInit = [
  {
    id: 'following',
    title: 'Following',
  },
  {
    id: 'followers',
    title: 'Followers',
  },
  {
    id: 'subscribers',
    title: 'Subscribers',
  },
];

const ProfileUsersBody = ({ hideheader }) => {
  const [activeTabId, setActiveTabId] = useState('following');
  const [tabs, setTabs] = useState(tabsInit);
  const [activeTabIndex, setActiveTabIndex] = useState(global.friendIndex || 0);

  const actionRef = useRef(null);
  const sortActionRef = useRef(null);
  const typingTimer = useRef(null);
  const [filterQuery, setFilterQuery] = useState('');
  const [lodaingFollow, setlodaingFollow] = useState(false);
  const [lodaingFollowers, setlodaingFollowers] = useState(false);
  const [lodaingSuggestions, setlodaingSuggestions] = useState('');

  const [followPage, setFollowPage] = useState(1);
  const [followersPage, setFollowersPage] = useState(1);
  const [followersData, setFollowersData] = useState([]);
  const [followingData, setFollowingData] = useState([]);

  const [showLoadMoreFollow, setShowLoadMoreFollow] = useState(false);
  const [showLoadMoreFollower, setShowLoadMoreFollower] = useState(false);
  const {
    getSuggestedUsers,
    suggestedUsers,
    getFollowingUsers,
    getFollowerUsers,
    followingUsers,
    followerUsers,
    postFollowUser,
    postUnfollowUser,
    postUnfollowerUser,
    userContactInfo,
  } = useProfileApp();

  const { userData } = useBuzzmiAuth();
  const pageLimit = 5;

  useEventEmitter('refresh-following-user-list', data => {
    getFollowingUsers(
      userData?.token,
      userData?.id,
      filterQuery,
      {
        page: data?.page,
        limit: data?.limit,
      },
      setlodaingFollow,
    );
    setFollowPage(data?.page)
  })


  useEventEmitter('refresh-followers-user-list', data => {
    getFollowerUsers(
      userData?.token,
      userData?.id,
      filterQuery,
      {
        page: data?.page,
        limit: data?.limit,
      },
      setlodaingFollowers,
    );
    setFollowPage(data?.page)
  })

  useEffect(() => {
    // alert("call")
    const tabs = tabsInit.map(({ title, id }) => {
      if (title === 'Subscribers')
        return { id, title: `(${mockData.subscribers.users.length}) ${title}` };
      if (title === 'Followers') {
        followerUsers.length > 0 &&
          // followersPage === 1
          //? 
          setFollowersData([...followerUsers])
        //: setFollowersData([...followersData, ...followerUsers]);
        followerUsers.length <= pageLimit
          ? setShowLoadMoreFollower(false)
          : setShowLoadMoreFollower(true);

        return { id, title: `(${userContactInfo?.followers}) ${title}` };
      }
      if (title === 'Following') {
        followingUsers.length > 0 &&
          followPage === 1
        //? 
        setFollowingData([...followingUsers])
        //: setFollowingData([...followingData, ...followingUsers]);
        followingUsers.length <= pageLimit
          ? setShowLoadMoreFollow(false)
          : setShowLoadMoreFollow(true);
        return { id, title: `(${userContactInfo?.following}) ${title}` };

      }
    });

    setTabs(tabs);
  }, [followerUsers, followingUsers]);

  useEffect(() => {
    clearTimeout(typingTimer?.current);
    // console.log("here is the index ==")
    // console.log(activeTabIndex)
    typingTimer.current = setTimeout(() => {
      if (activeTabIndex == 0) {
        getFollowingUsers(
          userData?.token,
          userData?.id,
          filterQuery,
          {
            page: followPage,
            limit: pageLimit,
          },
          setlodaingFollow,
        );
      } else if (activeTabIndex == 1) {
        getFollowerUsers(
          userData?.token,
          userData?.id,
          filterQuery,
          {
            page: followersPage,
            limit: pageLimit,
          },
          setlodaingFollowers,
        );
      } else {
        console.log('Hello test');
      }
    }, 1000);
  }, [filterQuery, followPage, followersPage, activeTabIndex]);

  useEffect(() => {
    getSuggestedUsers(userData.token, { page: 1, limit: 10 });
  }, []);

  const handleTabSelect = (index) => {
    setActiveTabIndex(index)
    setFollowPage(1)
  }


  return (
    <div className="profile-mobile-users">
      {!hideheader && (
        <BackBtnWithTitlePage
          title={userData.firstName + ' ' + userData.lastName}
          className="mt-0"
        />
      )}

      <LinkTabs
        activeTabId={tabsInit[activeTabIndex].id}
        list={tabs}
        onChange={(i) => {
          handleTabSelect(tabsInit.findIndex(item => item.id === i))
        }}
      />
      <PlanSearch setSearchValue={setFilterQuery} className="mt-3" />
      <ProfileMobileUsersSort />
      <div className="mt-3">
        <ProfileMobileUsersTabs
          postFollowUser={postFollowUser}
          postUnfollowUser={postUnfollowUser}
          postUnfollowerUser={postUnfollowerUser}
          activeTabId={tabs[activeTabIndex].id}
          lodaingFollow={lodaingFollow}
          lodaingFollowers={lodaingFollowers}
          showLoadMoreFollow={showLoadMoreFollow}
          showLoadMoreFollower={showLoadMoreFollower}
          onPressFollowLoadMore={() => console.log('onPressFollowLoadMore')}
          onPressFollowerLoadMore={() => console.log('onPressFollowerLoadMore')}
          pageLimit={pageLimit}
          followersPageNum={followersPage}
          followers={{
            users: followersData,
            suggestedForYou: suggestedUsers,
          }}
          following={{
            users: followingData,
            suggestedForYou: suggestedUsers,
          }}
          subscribers={mockData.subscribers}
          followPage={followPage}
          userContactInfo={userContactInfo}
        />
      </div>
    </div>
  );
};

export default ProfileUsersBody;
