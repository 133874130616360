/* eslint-disable react-hooks/exhaustive-deps */

import {useEffect, useState} from 'react';
import Strings from '../../../../../string_key/Strings.web';

const ResendCode = ({handleResendCode}) => {
  const [time, setTime] = useState(5);
  const [intervalState, setIntervalState] = useState(null);

  const startTimer = () => {
    const timer = setInterval(() => {
      setTime(prev => prev - 1);
    }, 1000);

    setIntervalState(timer);

    return timer;
  };

  useEffect(() => {
    const timer = startTimer();
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (time === 0 && intervalState) {
      clearInterval(intervalState);
    }
  }, [time]);
  const handleClick = () => {
    setTime(5);
    startTimer();
    handleResendCode();
  };

  return (
    <center>
      <button
        className="text-r text-primary content-auth__resend-code"
        onClick={handleClick}
        disabled={time !== 0}>
        {time !== 0
          ? Strings.resend_code_text_timer.replace('${time}', `${time}`)
          : Strings.resend_code}
      </button>
    </center>
  );
};

export default ResendCode;
