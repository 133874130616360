import React from "react"

const ReelAuthorInfo = ({ userName, userNickname, userPosition, userAvatar, isVerified }) => {
  return (
    <div className="reels__author-info-wrapper">
      <img src={userAvatar} alt="avatar" className="reels__author-avatar" />

      <div className="reels__author-info">
        <div className="d-flex flex-row align-items-center reels__author-name">
          <p className="h6 ">{userName}</p>
          {isVerified ? <img src="/images/post/profile-approved.svg" alt="profile approved" /> : ""}
          <p className="reels__author-nickname">@{userNickname}</p>
        </div>

        <p className="reels__author-job-title">{userPosition}</p>
      </div>
    </div>
  )
}

export default ReelAuthorInfo
