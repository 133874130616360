// noinspection JSUnresolvedReference
import {COMMUNITY_COMMENT_VOTE} from '../../api_helper/Api';
import {
  POST,
  GET,
  POST_MULTIPART,
  PATCH,
  DELETE,
  PUT,
  DELETE_WITH_BODY,
} from '../utils/apiClient';
import {
  CREATE_TASK,
  LEADER_BOARD_LIST,
  RANK_INFO,
  Next_RANK_INFO,
  WALLET_INFO,
  POST_FILE_UPLOAD,
  ADD_IMAGE_COVER,
  COVER_LIST,
  ACTIVITY_TAGS,
  GET_ALL_RANK_INFO,
  Users,
  FOLLOW_USER,
  Followers,
  GET_USER_BADGES,
  Following,
  UNFOLLOW_USER,
  EDIT_TASK,
  Mutual_Following,
  SuggestedUsers,
  CREATE_BASE_ACTIVITY,
  CREATE_PLAN_ACTIVITY,
  GET_PLAN_ACTIVITY,
  GET_BASE_ACTIVITY,
  COMMUNITY_USER_POSTS,
  COMMUNITY_USER_REELS,
  POST_VOTE,
  EDIT_BASE_ACTIVITY,
  DELETE_PLANNED_ACTIVITY,
  DELETE_BASE_ACTIVITY,
  POST_FILES,
  COMMUNITY_POST,
  PATCH_UPDATE_PROFILE,
  PROFILE_TAG,
  POST_PROFILE_TAG,
  POST_COMMENT,
  GET_STAT_LISTING,
  PROFILE_QR_SHARE,
  PROFILE_TAG_ID,
  SHARE_LINK,
  GET_PROFILE_BY_ID,
  UPDATE_PLAN_ACTIVITY,
  USER_CONTACTS,
  COMMUNITY_USER_ACTIVITIES,
  COMMUNITY_POST_REEL,
  COMMUNITY_REEL_BY_ID,
  COMUNITY_POST_BY_ID,
  DYNAMIC_CAPTION,
  PATCH_UPDATE_AVATAR,
  GET_QR_CODE,
  POST_QR_CODE,
  PATCH_UPDATE_QA,
  PATCH_COMPLETE_ACTIVITY,
  PATCH_COMPLETE_PLANNED_TASKS,
  GET_SHARED_BASE_ACTIVITY,
  GET_NOTIFICATIONS,
  POST_SHARE_ACTIVITY,
  POST_ASSIGN_ACTIVITY,
  FOLLOWING_COMMUNITY_POST,
  SHARED_MEDIA_LIST,
  POPULAR_NAMES,
  GET_SUGGESTED_TASKS,
  GET_TODAY_PLAN_ACTIVITY,
  PROFILE_QR_SHARE_BY_ID,
  GET_USER_WALLET_HISTORY,
  GET_USER_RECHARGE_AMOUNTS,
  POST_FCM_TOKEN,
  REMOVE_FCM_TOKEN,
  BOOK_MARK_LISTS,
  POST_BOOK_MARK_LISTS,
  UNFOLLOWER_USER,
  POST_HIDE_POST,
  GET_TASK_STAT_BY_ID,
  POST_REVIEW_RATTING,
  GET_REVIEW_RATTING,
  GET_PLAN_ACTIVITY_STATS,
  GET_ONE_PLAN_ACTIVITY_STATS,
  POST_NOTIFICATION_STATUS,
  GET_BUZZMI_ACTIVITY,
  POST_VIDEO_FILES,
} from './endpoints';

const postWithErrorHandling = apiEndpoint => {
  return async credentials => {
    console.log('credentials', credentials);
    let params = {isAuth: false, token: ''};

    if (credentials?.params) {
      params = credentials?.params;
    }

    try {
      return await POST(
        apiEndpoint,
        params?.isAuth,
        params?.token,
        credentials,
      );
    } catch (error) {
      if (typeof error?.message === 'string') {
        throw new Error(error);
      }
      throw new Error('Something went wrong...');
    }
  };
};

const patchWithErrorHandling = apiEndpoint => {
  return async credentials => {
    let params = {isAuth: false, token: ''};
    // console.log(credentials);
    if (credentials?.params) {
      params = credentials?.params;
    }

    try {
      return await PATCH(
        apiEndpoint,
        params?.isAuth,
        params?.token,
        credentials.body,
      );
    } catch (error) {
      if (typeof error?.message === 'string') {
        throw new Error(error);
      }
      throw new Error('Something went wrong...');
    }
  };
};

const putWithErrorHandling = apiEndpoint => {
  return async credentials => {
    let params = {isAuth: false, token: ''};
    // console.log(credentials);
    if (credentials?.params) {
      params = credentials?.params;
    }

    try {
      return await PUT(
        apiEndpoint,
        params?.isAuth,
        params?.token,
        credentials.body,
      );
    } catch (error) {
      if (typeof error?.message === 'string') {
        throw new Error(error);
      }
      throw new Error('Something went wrong...');
    }
  };
};

const postMultipartWithErrorHandling = apiEndpoint => {
  return async credentials => {
    let params = {isAuth: false, token: ''};
    // console.log(credentials);
    if (credentials?.params) {
      params = credentials?.params;
    }

    try {
      return await POST_MULTIPART(
        apiEndpoint,
        params?.isAuth,
        params?.token,
        credentials,
      );
    } catch (error) {
      if (typeof error?.message === 'string') {
        throw new Error(error);
      }
      throw new Error('Something went wrong...');
    }
  };
};

const getWithErrorHandling = apiEndpoint => {
  return async params => {
    // console.log('token in apid', params, apiEndpoint);
    try {
      return await GET(apiEndpoint, params);
    } catch (error) {
      if (apiEndpoint == 'v1//notification?page=1&limit=20') {
        console.log(error);
      }
      if (typeof error?.message === 'string') {
        throw new Error(error);
      }
      throw new Error('Something went wrong...');
    }
  };
};

const deleteWithErrorHandling = apiEndpoint => {
  return async params => {
    try {
      return await DELETE(apiEndpoint, params);
    } catch (error) {
      if (typeof error?.message === 'string') {
        throw new Error(error);
      }
      throw new Error('Something went wrong...');
    }
  };
};
const deleteBodyWithErrorHandling = apiEndpoint => {
  return async params => {
    try {
      return await DELETE_WITH_BODY(apiEndpoint, params);
    } catch (error) {
      if (typeof error?.message === 'string') {
        throw new Error(error);
      }
      throw new Error('Something went wrong...');
    }
  };
};

const fetchUserData = async token => {
  try {
    // TODO: Need to move this API to RQ (react-query)
    // return await queryClient.fetchQuery({
    //   queryKey: 'get_user_query',
    //   queryFn: getUserData({token})
    // });
    return await getUserData({token});
  } catch (error) {
    if (typeof error?.message === 'string') {
      throw new Error(error);
    }
    throw new Error('Something went wrong...');
  }
};

const deletePlannedActivity = id =>
  deleteWithErrorHandling(DELETE_PLANNED_ACTIVITY(id));
const deleteBaseActivity = (id, query) =>
  deleteWithErrorHandling(DELETE_BASE_ACTIVITY(id, query));
const getWalletInfo = getWithErrorHandling(WALLET_INFO);
const getCoverList = getWithErrorHandling(COVER_LIST);
const getSharedMediaList = getWithErrorHandling(SHARED_MEDIA_LIST);
const getPopularNames = getWithErrorHandling(POPULAR_NAMES);
const getSuggestedTasks = params =>
  getWithErrorHandling(GET_SUGGESTED_TASKS(params));

const getLeaderBoardList = getWithErrorHandling(LEADER_BOARD_LIST);
const getUserCaption = getWithErrorHandling(DYNAMIC_CAPTION);
const getRankInfo = id => getWithErrorHandling(RANK_INFO(id));

const getVisitorRankInfo = id => getWithErrorHandling(RANK_INFO(id));

const getQRData = id => getWithErrorHandling(GET_QR_CODE(id));

const getNextRankInfo = id => getWithErrorHandling(Next_RANK_INFO(id));

const getUserBadges = id => getWithErrorHandling(GET_USER_BADGES(id));
const getUserRattings = id => getWithErrorHandling(GET_REVIEW_RATTING(id));

const getAllRankInfo = getWithErrorHandling(GET_ALL_RANK_INFO);

const getReelPostLink = (type, id) =>
  getWithErrorHandling(SHARE_LINK(type, id));

const createTask = postWithErrorHandling(CREATE_TASK);

const postVerifyOtp = postWithErrorHandling(POST_QR_CODE);

const createPost = postWithErrorHandling(COMMUNITY_POST);
const createReel = postWithErrorHandling(COMMUNITY_POST_REEL);
const postNotificationStatus = postWithErrorHandling(POST_NOTIFICATION_STATUS);

const addProfileTag = postWithErrorHandling(PROFILE_TAG);
const getProfileTag = id => getWithErrorHandling(POST_PROFILE_TAG(id));

const postComment = id => postWithErrorHandling(POST_COMMENT(id));
const postShareActivity = postWithErrorHandling(POST_SHARE_ACTIVITY);
const postAssignActivity = postWithErrorHandling(POST_ASSIGN_ACTIVITY);

const patchEditProfileDetails = id =>
  patchWithErrorHandling(PATCH_UPDATE_PROFILE(id));
const patchPlannedActivityComplete = id =>
  patchWithErrorHandling(PATCH_COMPLETE_ACTIVITY(id));

const patchPlannedTasksComplete = id =>
  patchWithErrorHandling(PATCH_COMPLETE_PLANNED_TASKS(id));

const patchEditProfileAvatar = patchWithErrorHandling(PATCH_UPDATE_AVATAR);
const patchEditQuickAccess = patchWithErrorHandling(PATCH_UPDATE_QA);

const createBaseActivity = postWithErrorHandling(CREATE_BASE_ACTIVITY);
const patchEditBaseActivity = id =>
  patchWithErrorHandling(EDIT_BASE_ACTIVITY(id));

const patchEditPlanActivity = id =>
  patchWithErrorHandling(UPDATE_PLAN_ACTIVITY(id));
const createPlanActivity = postWithErrorHandling(CREATE_PLAN_ACTIVITY);
const postFcmToken = postWithErrorHandling(POST_FCM_TOKEN);
const deleteFcmToken = deleteBodyWithErrorHandling(REMOVE_FCM_TOKEN);

const getBaseActivities = (page, limit) =>
  getWithErrorHandling(GET_BASE_ACTIVITY(page, limit));
const getBuzzmiActivities = (page, limit) =>
  getWithErrorHandling(GET_BUZZMI_ACTIVITY(page, limit));

const getSharedBaseActivities = (page, limit) =>
  getWithErrorHandling(GET_SHARED_BASE_ACTIVITY(page, limit));
const getNotifications = (page, limit) =>
  getWithErrorHandling(GET_NOTIFICATIONS(page, limit));

const getStatListing = params => getWithErrorHandling(GET_STAT_LISTING(params));
const getStatByTaskId = params =>
  getWithErrorHandling(GET_TASK_STAT_BY_ID(params));

const getMyTask = getWithErrorHandling(CREATE_TASK);
const getPlannedActivitiesList = (
  page,
  limit,
  tag,
  title,
  startDate,
  endDate,
  status,
) =>
  getWithErrorHandling(
    GET_PLAN_ACTIVITY(page, limit, tag, title, startDate, endDate, status),
  );
const getPlannedActivitiesListStats = (startDate, endDate) =>
  getWithErrorHandling(GET_PLAN_ACTIVITY_STATS(startDate, endDate));
const getPlannedActivityStats = id =>
  getWithErrorHandling(GET_ONE_PLAN_ACTIVITY_STATS(id));
const getTodayPlannedTasksList = (page, limit) =>
  getWithErrorHandling(GET_TODAY_PLAN_ACTIVITY(page, limit));

const putEditPosts = (id, type = 'post') => putWithErrorHandling(COMUNITY_POST_BY_ID(id, type));

const patchEditTask = id => patchWithErrorHandling(EDIT_TASK(id));
const getAcitivityTags = getWithErrorHandling(ACTIVITY_TAGS);
const postAcitivityTags = postWithErrorHandling(ACTIVITY_TAGS);

// This API needs to upload photo/audio/video all for activities
const postfileUpload = postMultipartWithErrorHandling(POST_FILE_UPLOAD);
// const postfileUpload = postMultipartWithErrorHandling(POST_VIDEO_FILES);

const postFilesBeforeUploades = postMultipartWithErrorHandling(POST_FILES);
const postVideoFilesBeforeUploades =
  postMultipartWithErrorHandling(POST_VIDEO_FILES);

const postCoverImage = postWithErrorHandling(ADD_IMAGE_COVER);
const getFollowerSuggestions = getWithErrorHandling(SuggestedUsers);

const getProfileShare = getWithErrorHandling(PROFILE_QR_SHARE);
const getProfileShareById = id =>
  getWithErrorHandling(PROFILE_QR_SHARE_BY_ID(id));

const getFollowers = id => getWithErrorHandling(Followers(id));
const getFollowing = id => getWithErrorHandling(Following(id));

const getPostLists = id => getWithErrorHandling(COMMUNITY_USER_POSTS(id));

const getBuzzmiUserPostList = getWithErrorHandling(COMMUNITY_POST);
const getFollowingPostList = getWithErrorHandling(FOLLOWING_COMMUNITY_POST);

const getReelLists = id => getWithErrorHandling(COMMUNITY_USER_REELS(id));

const getMutualFollowing = id => getWithErrorHandling(Mutual_Following(id));
const postFollowUserApi = id => postWithErrorHandling(FOLLOW_USER(id));
const postUnfollowUserApi = id => postWithErrorHandling(UNFOLLOW_USER(id));

const postHidePost = postWithErrorHandling(POST_HIDE_POST);

const postReviewRattings = postWithErrorHandling(POST_REVIEW_RATTING);

const postUnfollowFollwerUserApi = id =>
  postWithErrorHandling(UNFOLLOWER_USER(id));

const postLikePostApi = id => postWithErrorHandling(POST_VOTE(id));
const deleteDislikePostApi = id => deleteWithErrorHandling(POST_VOTE(id));
const postLikeCommentApi = id =>
  postWithErrorHandling(COMMUNITY_COMMENT_VOTE(id));
const deleteDislikeCommentApi = id =>
  deleteWithErrorHandling(COMMUNITY_COMMENT_VOTE(id));

const deleteProfileTag = id => deleteWithErrorHandling(PROFILE_TAG_ID(id));

const deleteReelById = id => deleteWithErrorHandling(COMMUNITY_REEL_BY_ID(id));
const deletePostById = id => deleteWithErrorHandling(COMUNITY_POST_BY_ID(id));

const getUserProfileDetail = id => getWithErrorHandling(GET_PROFILE_BY_ID(id));

const getUserInformationContacts = getWithErrorHandling(USER_CONTACTS);
const getUserBookMarkLists = getWithErrorHandling(BOOK_MARK_LISTS);
const postUserBookMark = id => postWithErrorHandling(POST_BOOK_MARK_LISTS(id));
const deleteBookMark = id => deleteWithErrorHandling(POST_BOOK_MARK_LISTS(id));

const getCommunityActivity = id => getWithErrorHandling(COMMUNITY_USER_ACTIVITIES(id));

const getUserWalletHistory = (page, limit, startDate, endDate, walletType) =>
  getWithErrorHandling(
    GET_USER_WALLET_HISTORY(page, limit, startDate, endDate, walletType),
  );
const getUserRechageAmounts = type =>
  getWithErrorHandling(GET_USER_RECHARGE_AMOUNTS(type));

export {
  deletePlannedActivity,
  deleteBaseActivity,
  getWalletInfo,
  getLeaderBoardList,
  fetchUserData,
  getRankInfo,
  getQRData,
  createTask,
  postVerifyOtp,
  createPost,
  createReel,
  patchEditTask,
  postfileUpload,
  postFilesBeforeUploades,
  postVideoFilesBeforeUploades,
  postCoverImage,
  getCoverList,
  getMyTask,
  getPlannedActivitiesList,
  getPlannedActivitiesListStats,
  getPlannedActivityStats,
  getBaseActivities,
  getStatListing,
  getAcitivityTags,
  getFollowerSuggestions,
  postFollowUserApi,
  getFollowers,
  getFollowing,
  postUnfollowUserApi,
  postUnfollowFollwerUserApi,
  getMutualFollowing,
  createBaseActivity,
  patchEditBaseActivity,
  patchEditPlanActivity,
  createPlanActivity,
  getPostLists,
  getBuzzmiUserPostList,
  getReelLists,
  postAcitivityTags,
  postLikePostApi,
  deleteDislikePostApi,
  patchEditProfileDetails,
  addProfileTag,
  getProfileTag,
  postComment,
  getNextRankInfo,
  getProfileShare,
  getAllRankInfo,
  deleteProfileTag,
  deleteReelById,
  deletePostById,
  getReelPostLink,
  getUserProfileDetail,
  getUserInformationContacts,
  getCommunityActivity,
  putEditPosts,
  getUserCaption,
  patchEditProfileAvatar,
  patchEditQuickAccess,
  patchPlannedActivityComplete,
  patchPlannedTasksComplete,
  getSharedBaseActivities,
  getNotifications,
  postShareActivity,
  getFollowingPostList,
  getSharedMediaList,
  getPopularNames,
  getSuggestedTasks,
  getVisitorRankInfo,
  getTodayPlannedTasksList,
  getProfileShareById,
  getUserWalletHistory,
  getUserRechageAmounts,
  getUserBadges,
  postFcmToken,
  deleteFcmToken,
  getUserBookMarkLists,
  postUserBookMark,
  deleteBookMark,
  postHidePost,
  getStatByTaskId,
  postReviewRattings,
  getUserRattings,
  postAssignActivity,
  postNotificationStatus,
  getBuzzmiActivities,
  postLikeCommentApi,
  deleteDislikeCommentApi,
};
