export const numbers = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
]
export const numbersSelect = [
  { label: "01", value: "01" },
  { label: "02", value: "02" },
  {
    label: "03",
    value: "03",
  },
  { label: "04", value: "04" },
  { label: "05", value: "05" },
  { label: "06", value: "06" },
  {
    label: "07",
    value: "07",
  },
  { label: "08", value: "08" },
  { label: "09", value: "09" },
  { label: "10", value: 10 },
  {
    label: "11",
    value: 11,
  },
  { label: "12", value: 12 },
  { label: "13", value: 13 },
  { label: "14", value: 14 },
  { label: "15", value: 15 },
  {
    label: "16",
    value: 16,
  },
  { label: "17", value: 17 },
  { label: "18", value: 18 },
  { label: "19", value: 19 },
  { label: "20", value: 20 },
  {
    label: "21",
    value: 21,
  },
  { label: "22", value: 22 },
  { label: "23", value: 23 },
  { label: "24", value: 24 },
  { label: "25", value: 25 },
  {
    label: "26",
    value: 26,
  },
  { label: "27", value: 27 },
  { label: "28", value: 28 },
  { label: "29", value: 29 },
  { label: "30", value: 30 },
  {
    label: "31",
    value: 31,
  },
  { label: "32", value: 32 },
  { label: "33", value: 33 },
  { label: "34", value: 34 },
  { label: "35", value: 35 },
  {
    label: "36",
    value: 36,
  },
]

export const mode = ["Day", "Week", "Month"]

export const modeSelect = [
  { label: "Day", value: "Day" },
  { label: "Week", value: "Week" },
  { label: "Month", value: "Month" },
]
