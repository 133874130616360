import React, {useEffect, useState, useRef} from 'react';
import Loading from 'react-fullscreen-loading';
// mock data
import {useBuzzmiAuth} from '../../../../appState/hooks/useBuzzmiAuth';
import {colors} from '../../../../res/colors.js';
import PictureUploader from '../../../../components/reusable/uploader/pictureUploader/PictureUploader.jsx';
import Form from '../../../../components/reusable/form/Form.jsx';
import ValidationSchema from '../../../../form-validator/ValidationSchema.js';
import {useFormValidator} from '../../../../form-validator/hooks/index.js';
import Box from '../../../../components/reusable/cards/box/Box.jsx';
import SubmitButton from '../../../../components/reusable/btns/buttons/submitButton/SubmitButton.jsx';
import {useProfileApp} from '../../../../appState/hooks/index.js';
import 'react-quill/dist/quill.snow.css';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {useNavigation} from '@react-navigation/core';
import VideoUploader from '../../../../components/reusable/uploader/videoUploader/VideoUploader.jsx';
import CreatePostInput from '../ProfileMain/componentsV2/CreatePostInput/index.jsx';
import {useUpdatePost} from '../../../../appState/hooks/useUpdatePost/index.js';
import {
  profileSideBarNavAtom,
  socialDashboardNavAtom,
} from '../../../../appState/navState.js';
import {useAtom} from 'jotai';
import {VideoCameraIcon} from '@heroicons/react/24/outline';
import {PhotoIcon} from '@heroicons/react/24/outline';
import CustomSelect from '../../../../components/reusable/form/select/customSelect/CustomSelect';
import {categorizeUrlsFromArray} from '../items/profilePost/ProfilePostV2.jsx';
import {getVideoPosterURL} from '../items/types.js';

const validationSchema = new ValidationSchema({
  name: [
    {rule: 'required'},
    {rule: 'minLength', value: 3},
    {rule: 'maxLength', value: 400},
  ],
});

let f_array = [];
let file_data = [];

const options = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Premium',
    value: 'premium',
  },
  {
    label: 'Exclusive',
    value: 'exclusive',
  },
];

const AddPostsV2 = ({
  description = '',
  post,
  type,
  is_edit,
  setOpen,
  closeAll,
}) => {
  const navigation = useNavigation();
  const [errors, setErrors] = useState([]);
  const [error, setError] = useState([]);
  const {userData} = useBuzzmiAuth();
  const [images, setImages] = useState([]);
  const [fileData, setFileData] = useState([]);

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const [mediaType, setMediatype] = useState(0);

  const {
    postCreatePost,
    mutateFilesUpload, // postFilesBeforeUploades
    fileUploadResponse, // postFilesBeforeUploades
    isFileUploadLoading, // postFilesBeforeUploades
    postCreateReel,
    isCreatePostLoading,
    isCreateReelLoading,
    getUserPostLists,
    getUserReelLists,
  } = useProfileApp();

  const {postEditpost} = useUpdatePost();
  const [activeSideItem, setActiveSideItem] = useAtom(profileSideBarNavAtom);
  const [activeSocialSideItem, setActiveSocialSideItem] = useAtom(
    socialDashboardNavAtom,
  );
  const [renderKey, setRenderKey] = useState(885599);
  const [maxWidth, setMaxWidth] = useState(0);
  const textRef = useRef(null);

  const [selected, setSelected] = useState(
    post?.contentType
      ? options?.filter(item => item?.value == post?.contentType)[0]
      : {
          label: 'All',
          value: 'all',
        },
  );

  useEffect(() => {
    if (activeSideItem == 'Reel' || activeSocialSideItem == 'Reel') {
      setActiveTabIndex(1);
    } else {
      setActiveTabIndex(0);
    }
  }, [activeSideItem, activeSocialSideItem]);

  let videoURL = null;

  try {
    videoURL = categorizeUrlsFromArray(post?.fields)?.video?.[0];
  } catch (e) {
    console.log('Error ==> ', e.message);
  }

  useEffect(() => {
    if (post) {
      const _images = categorizeUrlsFromArray(post?.fields)?.image;
      const videoThumbnail = getVideoPosterURL(videoURL);
      setMediatype(videoThumbnail ? 1 : 0);
      _images.map(url => {
        f_array.push(url);
      });
      setImages(f_array);
      setFileData(_images || videoURL);
    }
    return () => {
      f_array = [];
    };
  }, [post]);

  const updateRequest = async formData => {
    try {
      let postData;

      let _fileData = [];
      console.log('formData===>', formData);
      if (activeTabIndex == 1) {
        console.log('fileData in reels', fileData?.video);
        if (fileData?.length > 0) {
          postData = {
            contentType: selected?.value,
            content: formData?.name,
            videos: [{src: formData?.video}],
          };
        } else {
          postData = {
            contentType: selected?.value,
            content: formData?.name,
          };
        }
      } else {
        if (fileData?.length > 0) {
          _fileData = fileData?.map(i => {
            return {
              src: i?.src ? i?.src : i,
            };
          });
        }
        if (mediaType == 0) {
          postData = {
            contentType: selected?.value,
            content: formData?.name,
            title: formData?.name,
            images: _fileData,
          };
        } else {
          console.log('called here', fileData);
          console.log('called formData', formData);
          fileData.length > 0
            ? (postData = {
                contentType: selected?.value,
                content: formData?.name,
                title: formData?.name,
                videos: _fileData,
              })
            : formData?.video !== null
            ? (postData = {
                contentType: selected?.value,
                content: formData?.name,
                title: formData?.name,
                videos: [{src: formData?.video}],
              })
            : (postData = {
                contentType: selected?.value,
                content: formData?.name,
                title: formData?.name,
              });
        }
      }

      postEditpost(post?.id, activeTabIndex == 1 ? 'reel' : 'post', postData);
      setOpen(false);
      closeAll();
    } catch (error) {
      console.log('error', error);
      setError(error);
      setOpen(false);
    }
  };

  const sendRequest = async formData => {
    try {
      if (activeTabIndex == 0) {
        let postData;
        if (mediaType == 0) {
          postData = {
            contentType: selected?.value,
            content: formData?.name,
            title: formData?.name,
            images: fileData,
            groupId: '1',
          };
        } else {
          postData = {
            contentType: selected?.value,
            content: formData?.name,
            title: formData?.name,
            videos: fileData,
            groupId: '1',
          };
        }

        const params = Object.assign(postData, {
          params: {isAuth: true, token: userData?.token},
        });

        postCreatePost(
          Object.assign(postData, {
            params: {isAuth: true, token: userData?.token},
          }),
        );
        setTimeout(() => {
          getUserPostLists(userData.token, userData?.id, {page: 1, limit: 20});
          setOpen(false);
        }, 1000);
      } else {
        let reelData = {
          contentType: selected?.value,
          content: formData?.name,
          videos: fileData,
          groupId: '1',
        };
        postCreateReel(
          Object.assign(reelData, {
            params: {isAuth: true, token: userData?.token},
          }),
        );
        setTimeout(() => {
          getUserReelLists(userData.token, userData?.id, {page: 1, limit: 20});
          setOpen(false);
        }, 1000);
      }
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    if (fileUploadResponse) {
      var fileSuccess = new Promise((resolve, reject) => {
        file_data.push({
          code: fileUploadResponse.mediaId,
          src: fileUploadResponse.mediaUrl,
          meta: {
            name: getFileName(fileUploadResponse?.mediaUrl),
            formatType: 'jpeg',
          },
        });
        f_array.push(fileUploadResponse.mediaUrl);

        setFileData([
          ...fileData,
          {
            code: fileUploadResponse.mediaId,
            src: fileUploadResponse.mediaUrl,
            meta: {
              name: getFileName(fileUploadResponse?.mediaUrl),
              formatType: 'jpeg',
            },
          },
        ]);
        resolve();
      });
      fileSuccess.then(() => {
        setImages(f_array);
      });
    }
  }, [fileUploadResponse, isFileUploadLoading]);
  // useEffect(() => {
  //   if (fileUploadResponse) {
  //     console.log('fileUploadResponse ==> ', fileUploadResponse);
  //     var fileSuccess = new Promise((resolve, reject) => {
  //       file_data.push({
  //         code: fileUploadResponse.code,
  //         src: fileUploadResponse.url,
  //         meta: {
  //           name: getFileName(fileUploadResponse.url),
  //           formatType: fileUploadResponse?.meta.formatType,
  //         },
  //       });
  //       f_array.push(fileUploadResponse.url);

  //       setFileData([
  //         ...fileData,
  //         {
  //           code: fileUploadResponse.code,
  //           src: fileUploadResponse.url,
  //           meta: {
  //             name: getFileName(fileUploadResponse.url),
  //             formatType: fileUploadResponse?.meta.formatType,
  //           },
  //         },
  //       ]);
  //       resolve();
  //     });
  //     fileSuccess.then(() => {
  //       setImages(f_array);
  //     });
  //   }
  // }, [fileUploadResponse, isFileUploadLoading]);

  const {register, handleSubmit, setValue, isValid, values, setValues} =
    useFormValidator(validationSchema, is_edit ? updateRequest : sendRequest, {
      defaultValues: {
        name:
          description ||
          post?.title.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;/g, ' '),
        video: videoURL || '',
      },
    });

  useEffect(() => {
    if (textRef.current && textRef?.current?.clientWidth > 0) {
      setMaxWidth(textRef.current.clientWidth + 'px');
    } else {
      setMaxWidth('100%');
    }
  }, [textRef.current]);

  const getFileName = url => {
    return url.substring(url.lastIndexOf('/') + 1);
  };

  const onChange = (imageList, index) => {
    if (imageList?.length > 0) {
      const fd = new FormData();
      fd.append('file', imageList[0].file);
      mutateFilesUpload(
        Object.assign(fd, {
          params: {
            isAuth: true,
            token: userData?.token,
          },
        }),
      );
    }
  };

  const handleTabSelect = index => {
    setActiveTabIndex(index),
      is_edit
        ? setValue('name', post?.object?.content || '')
        : setValue('name', '');
  };

  const handleMediaTypeSelect = index => {
    setMediatype(index);
  };

  return (
    <>
      <Loading
        loading={
          isFileUploadLoading || isCreateReelLoading || isCreatePostLoading
        }
        background="transparent"
        loaderColor={colors.PRIMARY_COLOR}
      />
      <div className="main-layout__single-container" key={renderKey}>
        <Form
          error={null}
          onSubmit={handleSubmit}
          formClassName="hub-form-wrapper">
          <div className={'pt-5'}>
            <p className={'text-base font-semibold mb-2'}>
              {'Membership Permission'}
            </p>
            <CustomSelect
              className="select__secondary profile-contacts__sort-select max-w-full"
              options={options}
              selected={selected}
              setSelected={setSelected}
            />
          </div>
          <Tabs
            className="profile-section_v2"
            selectedIndex={activeTabIndex}
            onSelect={handleTabSelect}>
            <TabList>
              <Tab style={{color: '#000'}} disabled={is_edit ? true : false}>
                Posts
              </Tab>
              <Tab style={{color: '#000'}} disabled={is_edit ? true : false}>
                Reel
              </Tab>
            </TabList>
          </Tabs>

          <Box paddingMode={'none'}>
            <CreatePostInput
              editable={true}
              value={values?.name || ''}
              onChange={e => setValue('name', e.target.value)}
            />
          </Box>

          {activeTabIndex == 0 && (
            <>
              <Tabs
                className="profile-section_v2 pt-5"
                selectedIndex={mediaType}
                onSelect={handleMediaTypeSelect}>
                <TabList>
                  <Tab
                    style={{
                      display: 'flex',
                      alignSelf: 'center',
                      justifyContent: 'center',
                      color: '#e536af',
                      opacity: mediaType == 0 ? 1 : 0.4,
                    }}>
                    <p className="self-center">Images</p>{' '}
                    <PhotoIcon className="h-7 w-7 ml-2" />
                  </Tab>
                  <Tab
                    style={{
                      display: 'flex',
                      alignSelf: 'center',
                      justifyContent: 'center',
                      color: '#e536af',
                      opacity: mediaType == 1 ? 1 : 0.4,
                    }}>
                    <p className="self-center">Video</p>{' '}
                    <VideoCameraIcon className="h-7 w-7 ml-2" />
                  </Tab>
                </TabList>
              </Tabs>

              {mediaType == 0 ? (
                <Box paddingMode={'none'}>
                  <h4 className="h6 mb-3 mb-lg-4">{'Add Images to Post'}</h4>
                  <div className="picture-uploader__row">
                    {new Array(4).fill('').map((_, index) => {
                      return (
                        <PictureUploader
                          defaultValue={images[index]}
                          parentSetterState={imageList =>
                            onChange(imageList, index)
                          }
                          locked={false}
                          key={index}
                          index={index}
                          maxNumber={4}
                          setErrors={setErrors}
                          onDelete={imageIndexToDelete => {
                            if (imageIndexToDelete > -1) {
                              // only splice array when item is found
                              fileData.splice(imageIndexToDelete, 1); // 2nd parameter means remove one item only
                            }
                            setImages(prev => {
                              prev.splice(imageIndexToDelete, 1);
                              return prev;
                            });
                            setRenderKey(prev => prev + 1);
                          }}
                        />
                      );
                    })}
                  </div>
                </Box>
              ) : (
                <div className={'mb-16'}>
                  <Box paddingMode={'none'}>
                    <h4 className="h6 mb-3 mb-lg-4">{'Add Video'}</h4>
                    <VideoUploader
                      title={values?.name || ''}
                      defaultValue={values?.video}
                      setValue={setValue}
                      isReel={true}
                      onGetResponse={UploadResponse => {
                        var fileSuccess = new Promise((resolve, reject) => {
                          file_data.push(
                            Object.assign(UploadResponse, {
                              src: UploadResponse?.secure_url,
                              meta: {
                                name: getFileName(UploadResponse?.secure_url),
                                formatType:
                                  'mp4' || UploadResponse?.meta?.formatType,
                              },
                            }),
                          );
                          resolve();
                        });
                        fileSuccess.then(() => {
                          setFileData(file_data);
                        });
                      }}
                    />
                  </Box>
                </div>
              )}
            </>
          )}

          {activeTabIndex == 1 && (
            <div>
              <Box paddingMode={'none'}>
                <h4 className="h6 mb-3 mb-lg-4">{'Add Video'}</h4>
                <VideoUploader
                  title={values?.name || ''}
                  defaultValue={values?.video}
                  setValue={setValue}
                  isReel={true}
                  onGetResponse={UploadResponse => {
                    var fileSuccess = new Promise((resolve, reject) => {
                      file_data.push(
                        Object.assign(UploadResponse, {
                          src: UploadResponse?.secure_url,
                          meta: {
                            name: getFileName(UploadResponse?.secure_url),
                            formatType:
                              'mp4' || UploadResponse?.meta?.formatType,
                          },
                        }),
                      );
                      resolve();
                    });
                    fileSuccess.then(() => {
                      setFileData(file_data);
                    });
                  }}
                />
              </Box>
            </div>
          )}

          <div
            className="d-flex bottom-mobile-button justify-center mt-5"
            style={{marginRight: 16}}>
            <SubmitButton
              isValid={isValid}
              title={is_edit ? 'Save' : 'Post'}
              postButton={true}
            />
          </div>
        </Form>
      </div>
    </>
  );
};

export default AddPostsV2;
