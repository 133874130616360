import React, {useEffect, useState} from 'react';
import ProfilePost from './profilePost/ProfilePost';
import ProfilePostTile from './profilePost/ProfilePostTile';
import ProfileReel from './profileReels/ProfileReel';
import ProfileReelTile from './profileReels/ProfileReelTile';
import useWindowSize from '../../../../hooks/useWindowSize';
import {useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useProfileApp} from '../../../../appState/hooks';
import {useBuzzmiAuth} from '../../../../appState/hooks/useBuzzmiAuth/index.web';
import Loading from 'react-fullscreen-loading';
import {colors} from '../../../../res/colors';
import ProfileViewContentList from './ProfileViewContentList';

const ProfileContentList = ({isPosts}) => {
  const [isTableActive, setIsTableActive] = useState(false);
  const [currentPostId, setCurrentPostId] = useState(null);
  const {userReels, postUploading} = useProfileApp();

  const handleSwitchAnchor = id => {
    setCurrentPostId(id);
    setTimeout(() => {
      setIsTableActive(false);
    }, 500);
  };

  useEffect(() => {
    console.log('ProfileContentList ????userReels');
    console.log(userReels);
  }, [userReels]);

  return (
    <>
      {/* <SwitchListMode
        isTableActive={isTableActive}
        setIsTableActive={setIsTableActive}
      /> */}
      <Loading
        loading={postUploading}
        background="transparent"
        loaderColor={colors.PRIMARY_COLOR}
      />
      {isTableActive ? (
        <ul
          className={
            isPosts ? 'profile-posts-list__table' : 'reels__list-table'
          }>
          {isPosts ? (
            userPosts && userPosts.length > 0 ? (
              userPosts.map(post => (
                <ProfilePostTile
                  key={post?.id}
                  post={post}
                  handleSwitchAnchor={handleSwitchAnchor}
                  contentType={post?.contentType ? post?.contentType : 'all'}
                />
              ))
            ) : (
              <div style={{textAlign: 'center', width: '100%'}}>
                <h5 className="h5 mb-3 mb-lg-4" style={{textAlign: 'center'}}>
                  {'No Post Found'}
                </h5>
              </div>
            )
          ) : userReels && userReels.length > 0 ? (
            userReels.map(reel => (
              <ProfileReelTile
                key={reel?.id}
                reel={reel}
                contentType={reel?.contentType ? reel?.contentType : 'all'}
                handleSwitchAnchor={handleSwitchAnchor}
              />
            ))
          ) : (
            <div style={{textAlign: 'center', width: '100%'}}>
              <h5 className="h5 mb-3 mb-lg-4" style={{textAlign: 'center'}}>
                {'No Reel Found'}
              </h5>
            </div>
          )}
        </ul>
      ) : (
        <ProfileViewContentList isPosts={isPosts} currentId={currentPostId} />
      )}
    </>
  );
};

ProfileContentList.propTypes = {
  isPosts: PropTypes.bool.isRequired,
};

export default ProfileContentList;
