import {useAtom} from 'jotai';
import {userBuzzmiActivitiesList} from '../hubState';
import {getBuzzmiActivities} from '../../../network/api/app';

export const useBuzzmiActivities = () => {
  const [buzzmiActivitiesList, setBuzzmiActivitiesList] = useAtom(
    userBuzzmiActivitiesList,
  );

  const getBuzzmiActivitiesList = async (token, setLoading, params) => {
    // console.log('log here in ', token, params);
    setLoading(true);
    try {
      const response = await getBuzzmiActivities(params)({token});
      // console.log('response in Buzzmi ', response);
      setBuzzmiActivitiesList(response);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log('error => ', e.message);
    }
  };

  return {
    buzzmiActivitiesList,
    getBuzzmiActivitiesList,
  };
};
