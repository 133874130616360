import {useEffect} from 'react';
import {useRef, useState} from 'react';
import {useBuzzmiAuth} from '../../../../appState/hooks/useBuzzmiAuth/index.web.js';
import {useHubApp} from '../../../../appState/hooks/useHubApp';
import MediaWrapper from '../../assets/mediaWrapper/MediaWrapper.jsx';
import VideoPlayer from '../../videoPlayer/VideoPlayer.jsx';

import {Spinner} from 'react-activity';
import 'react-activity/dist/library.css';
import {colors} from '../../../../res/colors.js';
import {useProfileApp} from '../../../../appState/hooks/index.js';
const VideoUploader = ({
  setValue,
  defaultValue = '',
  title = '',
  isReel = false,
  onGetResponse,
}) => {
  const [video, setVideo] = useState(defaultValue);
  const ref = useRef(null);
  const playerRef = useRef(null);
  const maxSize = 500 * 1024 * 1024;
  const maxDuration = 90 * 60 * 1000;
  const [uploading, setUploding] = useState(false);
  const {userData} = useBuzzmiAuth();
  const {
    onUploadFiles,
    uploadedVideoLink,
    isFileUploadLoading,
    setUploadedVideoLink,
  } = useHubApp();

  const {
    mutateVideoFilesUpload: mutateFilesUpload,
    videoFileUploadResponse: fileUploadResponse,
  } = useProfileApp();

  const onChange = event => {
    let file = event.target.files[0];

    const fd = new FormData();
    fd.append('file', file);
    setUploding(true);
    setValue('video', '');

    if (isReel) {
      mutateFilesUpload(
        Object.assign(fd, {
          params: {
            isAuth: true,
            token: userData?.token,
          },
        }),
      );
    } else {
      onUploadFiles(
        Object.assign(fd, {
          params: {
            isAuth: true,
            token: userData?.token,
            type: 'description_video',
          },
        }),
      );
    }
  };

  useEffect(() => {
    if (uploadedVideoLink) {
      setValue('video', uploadedVideoLink);
      setVideo(uploadedVideoLink);
      setUploding(false);
    }
    return () => {
      setUploadedVideoLink(null);
    };
  }, [uploadedVideoLink]);

  useEffect(() => {
    if (fileUploadResponse) {
      onGetResponse(fileUploadResponse);
      setValue('video', fileUploadResponse?.secure_url);
      setVideo(fileUploadResponse?.secure_url);
      setUploding(false);
    }
    return () => {
      setUploadedVideoLink(null);
    };
  }, [fileUploadResponse]);

  const uploadVideo = () => {
    ref.current.click();
  };

  const removeVideo = () => {
    setVideo(null);
    setValue('video', null);
    setUploadedVideoLink(null);
  };

  useEffect(() => {
    console.log('defaultValue of video ==>', defaultValue);
    setVideo(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mt-lg-3">
        {!uploading && (
          <ul className="text-r">
            <li> Max Size: 500 MB</li>
            <li> Maximum length: 90 Min</li>
            <li> Resolution: 1080p</li>
          </ul>
        )}

        {!uploading && (
          <MediaWrapper
            onClick={uploadVideo}
            iconSrc="/images/assets/forms/video.svg"
          />
        )}
        {uploading && (
          <div className={'d-flex p-8 flex-1 justify-center'}>
            {' '}
            <Spinner color={colors.PRIMARY_COLOR} size={30} />
          </div>
        )}
      </div>

      <input
        size={maxSize}
        accept="video/*,image/*"
        className="d-none"
        onChange={onChange}
        type="file"
        ref={ref}
      />

      {video && (
        <div className="video-uploader__player h-80">
          <VideoPlayer title={title} url={video} />
          <button className="picture-uploader__delete" onClick={removeVideo}>
            <img src="/images/assets/delete.svg" alt="delete" />
          </button>
        </div>
      )}
    </>
  );
};

export default VideoUploader;
