import React from 'react';

const Tooltip = ({
  children,
  tooltipText = 'Tooltip Text',
  position = 'right',
  hide = false,
  isLeft = false
}) => {
  return (
    <div className={hide ? `` : `tooltip-wrapper tooltip-wrapper_${position}`}>
      <div className={`tooltip-trigger tooltip-trigger-${position}`}>
        {children}
        <div className={`tooltip tooltip-${position} p-40 pointer-events-none ${isLeft ? "!left-[-16px] !p-[12px]" : ""}`}>
          {tooltipText}
        </div>
      </div>
    </div>
  );
};

export default Tooltip;
