import {Platform, StyleSheet} from 'react-native';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';

import {fonts} from '../../res/fonts';
import {isWeb} from '../../constants.ts';
import styled from 'styled-components/native';
import {getStatusBarHeight, isIphoneX} from 'react-native-iphone-x-helper';
import {colors} from '../../res/colors';

const RankHeader = styled.View`
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: ${hp(2)};
`;
const RankInnerContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const RankItem = styled.View`
  align-items: center;
  width: ${wp(25)};
`;
const RankStatusText = styled.Text`
  color: #584f68;
  text-align: center;
  min-height: ${hp(4)};
  font-size: ${wp(3.5)};
  font-family: ${fonts.MON_REGULAR};
`;
const RankTitleText = styled.Text`
  margin-top: 2px;
  text-align: center;
  color: ${colors.BLACK};
  width: ${wp(22)};
  font-family: ${fonts.MON_BOLD};
  font-size: ${wp(3.5)};
`;

const RankImage = styled.Image`
  height: ${wp(15)};
  width: ${wp(15)};
  margin-top: ${wp(4)};
`;

const UsernameText = styled.Text`
  align-self: center;
  color: ${colors.BLACK};
  font-family: ${fonts.BOLD};
  font-size: ${isWeb ? '1.5rem' : wp(4.2)};
`;

export {
  RankHeader,
  UsernameText,
  RankItem,
  RankStatusText,
  RankTitleText,
  RankImage,
  RankInnerContainer,
};

export default StyleSheet.create({
  shadowStyle: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
  },
  ViewRankText: {
    fontFamily: fonts.REGULAR,
    fontSize: wp(3.6),
  },
});
