import React from 'react';

const Role = ({img, title, onPress}) => {
  return (
    <button onClick={onPress} className="role w-100">
      <div className="role__img">
        <img className="w-auto" src={img} alt="role" />
      </div>

      <span className="h4">{title}</span>
    </button>
  );
};

export default Role;
