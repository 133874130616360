import React, { useEffect, useState } from 'react';
import Button from '../../../../../../components/reusable/btns/buttons/Button';
import Checkbox from '../../../../../../components/reusable/assets/checkbox/Checkbox';

export const FollowingButton = ({ onPress, isFollowing, isVisitorProfile }) => {
  const handleFollow = id => {
    // handle follow
  };

  return (
    <Button
      className="button_secondary  profile-contact__button shadow rounded-lg"
      onClick={onPress}>
      {isVisitorProfile ? "Follow" : isFollowing ? "Following" : "Unfollow"}
    </Button>
  );
};

export const FollowButton = ({ onPress }) => {
  const handleFollow = id => {
    // handle follow
  };

  return (
    <Button
      className="button_primary  profile-contact__button rounded-lg	 shadow"
      onClick={onPress}>
      Follow
    </Button>
  );
};

export const SubscribeButton = ({ id, initState }) => {
  const [state, setState] = useState(initState);

  useEffect(() => {
    // handle subscribe
  }, [state]);

  const handleState = () => setState(!state);

  return (
    <button
      onClick={handleState}
      className=" me-md-3 d-inline-flex align-items-center mr-5">
      <Checkbox defaultValue={state} />
    </button>
  );
};
