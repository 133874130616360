import React from 'react';
import UnderConstructionAlert from './underConstructionAlert';

const constructionAlert = props => {
  const handleOkay = () => {
    props?.closeModal();
  };

  return (
    <>
      <UnderConstructionAlert  handleOkay={handleOkay} />
    </>
  );
};

export default constructionAlert;
