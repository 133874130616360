import React, { useState } from 'react';
import cn from 'classnames';
import LineProgressBar from '../../../../../components/reusable/assets/lineProgressBar/LineProgressBar';
import SmallMenu from '../../../../../components/reusable/smallMenu/SmallMenu';
import ProfileContentItemMenu from './ProfileContentItemMenu';
import { useNavigate } from 'react-router-dom';
import { Link } from '@react-navigation/native';
import { closeModal, openModal } from '../../../../../store/slices/modalSlice/modalSlice';
import { useDispatch } from 'react-redux';

const ProfileContentItem = ({ item }) => {
  const [showMenu, setShowMenu] = useState(false);
  const navigation = useNavigate();
  const handleLike = () => { };
  const dispatch = useDispatch();

  const onUnderDevelopment = () => {
    dispatch(
      openModal({
        modalName: 'renderAlert',
        modalType: 'alert',

        modalProps: {
          nameAlert: 'constructionAlert',
          propsAlert: {
            closeModal: () => { dispatch(closeModal()) },
          },
        },
      }),
    );
  };


  const gotToContentPage = () =>
    navigation(`/profile/premium-content/${item.id}`);

  return (
    // <div className="profile-item" onClick={gotToContentPage}>
    // <Link to={`/profile/premium-content/${item.id}`}>
    <div onClick={() => { onUnderDevelopment() }} className="profile-item">
      <div className="profile-item__icon">
        <img
          src={item.image.src}
          alt={item.image.alt}
          className="profile-item__img"
        />
        <button
          onClick={handleLike}
          className={cn('profile-item__like', {
            'profile-item__like_active': item.isLikes,
          })}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20x"
            viewBox="0 0 24 24"
            stroke="#eb4dac">
            <path d="M2 9.1371C2 14 6.01943 16.5914 8.96173 18.9109C10 19.7294 11 20.5 12 20.5C13 20.5 14 19.7294 15.0383 18.9109C17.9806 16.5914 22 14 22 9.1371C22 4.27416 16.4998 0.825464 12 5.50063C7.50016 0.825464 2 4.27416 2 9.1371Z"></path>
          </svg>
        </button>
        <img
          src="/images/pages/profile/play.svg"
          alt="play"
          className="profile-item__play"
        />
      </div>
      <div className="profile-item__main">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="profile-item__title">{item.title}</h5>
          <div className="d-flex align-items-center profile-item__rating">
            <img src="/images/assets/star.svg" alt="star" className="me-2" />
            <span>
              {item.rating.value} ({item.rating.count})
              </span>
          </div>
        </div>
        <div className="d-flex align-items-center profile-item__mid">
          <div className="d-flex align-items-center">
            <img
              src="/images/pages/profile/eye.svg"
              alt="eye"
              className="me-1"
            />
            <span>{item.views}</span>
          </div>
          <div className="d-flex align-items-center">
            <img
              src="/images/pages/profile/clock.svg"
              alt="clock"
              className="me-1"
            />
            <span>{item.time}</span>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between w-100">
          <LineProgressBar progress={item.progress} />
          <SmallMenu
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            btn={
              <img src="/images/pages/profile/more.svg" alt="more-menu" />
            }>
            <ProfileContentItemMenu />
          </SmallMenu>
        </div>
      </div>
    </div>
    // </Link>
  );
};

export default ProfileContentItem;
