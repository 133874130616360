import React, { useEffect, useState, useRef } from 'react';
import { useFormValidator } from '../../../../form-validator/hooks/index.js';
import ValidationSchema from '../../../../form-validator/ValidationSchema.js';
import MainLayout from '../../../../components/layouts/mainLayout/MainLayout.jsx';
import HubTaskForm from './HubTaskForm.jsx';
import { useNavigation } from '@react-navigation/core';
import { useHubApp } from '../../../../appState/hooks/useHubApp/index.web.js';
import { useBuzzmiAuth } from '../../../../appState/hooks/useBuzzmiAuth/index.web.js';
import Loading from 'react-fullscreen-loading';
import { colors } from '../../../../res/colors.js';

import { EventRegister } from 'react-native-event-listeners';
import { useDispatch } from 'react-redux';
import {
  closeModal,
  openModal,
} from '../../../../store/slices/modalSlice/modalSlice.js';
const validationSchema = new ValidationSchema({
  name: [
    { rule: 'required' },
    { rule: 'minLength', value: 3 },
    { rule: 'maxLength', value: 40 },
  ],

  description: [{ rule: 'required' }],
  coverImage: [{ rule: 'required' }],
  // tags: [{rule: 'required'}, {rule: 'minArray', value: 1}],
});

const data = {
  initPopularNames: [
    'Meditate',
    'Workout',
    'Drink water',
    'Dance',
    'Sleep better',
  ],
};

const HubAddTask = props => {
  const params = props.route.params;

  const [showInTask, setShowInTask] = useState(false);
  const [error, setError] = useState(null);
  const { navigate, goBack } = useNavigation();
  const formRef = useRef(null);
  const {
    postCreateBaseActivity,
    CreateBaseActivityResponse,
    CreateBaseActivityErrorResponse,
    isCreateBaseActivityLoading,
    patchBaseActivity,
    EditBaseActivityResponse,
    EditBaseActivityErrorResponse,
    isEditBaseActivityLoading,
  } = useHubApp();
  const { userData } = useBuzzmiAuth();
  const dispatch = useDispatch();
  //TODO example function to send request
  useEffect(() => {
    if (CreateBaseActivityResponse) {
      // navigate('AddActivities');

      EventRegister.emit('refresh-baseActivities');
      dispatch(
        openModal({
          modalName: 'renderAlert',
          modalType: 'alert',
          modalProps: {
            nameAlert: 'AddTaskAlert',
            propsAlert: {
              handleOK: () => {
                // goBack();
                dispatch(closeModal());
                setTimeout(() => {
                  navigate('HubCreateTask', CreateBaseActivityResponse);
                }, 400);
              },
              isPlan: false,
            },
          },
        }),
      );
    }
  }, [CreateBaseActivityResponse, CreateBaseActivityErrorResponse]);

  const sendRequest = async formData => {
    try {
      console.log('formData', formData);

      let param_data = Object.assign({
        title: formData?.name,
        description: formData?.description,
        imageURL: formData.coverImage,
      });
      formData?.images &&
        Object.assign(param_data, {
          descriptionMedia: Object.values(formData?.images),
        });
      formData?.audio &&
        Object.assign(param_data, {
          descriptionAudio: formData?.audio,
        });
      formData?.video &&
        Object.assign(param_data, {
          descriptionVideo: formData?.video,
        });
      formData?.tags?.length > 0 &&
        Object.assign(param_data, {
          tags: formData?.tags?.map(item => item.id),
        });
      // console.log('formData', param_data);

      postCreateBaseActivity(
        Object.assign(param_data, {
          params: { isAuth: true, token: userData?.token },
        }),
      );
    } catch (e) {
      setError(e);
    }
  };
  const sendEditRequest = async formData => {
    try {
      let param_data = Object.assign({
        title: formData?.name,
        description: formData?.description,
        imageURL: formData.coverImage,
      });
      formData?.images &&
        Object.assign(param_data, {
          descriptionMedia: Object.values(formData?.images),
        });
      formData?.audio &&
        Object.assign(param_data, {
          descriptionAudio: formData?.audio,
        });
      formData?.video &&
        Object.assign(param_data, {
          descriptionVideo: formData?.video,
        });
      formData?.tags?.length > 0 &&
        Object.assign(param_data, {
          // tags: formData?.tags?.map(item => item.id),
          tags: [],
        });

      console.log('params', param_data);
      // console.log('formData?.tags', formData?.tags);
      patchBaseActivity(params?.item?.id, param_data);
    } catch (e) {
      setError(e);
    }
  };

  const defaultValues = {
    coverImage: params?.item?.image,
    name: params?.item?.title,
    description: params?.item?.description,
    images: params?.item?.descriptionMedia.values,
    video: params?.item?.descriptionVideo,
    audio: params?.item?.descriptionAudio,
    tags: params?.item?.tags?.values?.length
      ? params?.item?.tags?.values.map(str => ({
        title: str,
        id: str,
        value: str,
      }))
      : [],
    showInTask: showInTask,
  };

  const { register, handleSubmit, setValue, isValid, values, setValues } =
    useFormValidator(
      validationSchema,
      params?.isEdit ? sendEditRequest : sendRequest,
      { defaultValues },
    );
  useEffect(() => {
    if (EditBaseActivityResponse) {
      // navigate('AddActivities');
      navigate(
        params?.fromPath ? params?.fromPath : 'HubTasks',
        EditBaseActivityResponse,
      );
      // setValues({});
      EventRegister.emit('refresh-baseActivities');
    }
  }, [EditBaseActivityResponse, EditBaseActivityErrorResponse]);

  return (
    <MainLayout>
      <Loading
        loading={isCreateBaseActivityLoading || isEditBaseActivityLoading}
        background="transparent"
        loaderColor={colors.PRIMARY_COLOR}
      />
      {/* {console.log('values==>', values)}s */}
      <HubTaskForm
        error={error}
        handleSubmit={handleSubmit}
        setValue={setValue}
        values={values}
        isValid={isValid}
        register={register}
        data={data}
        isEdit={params?.isEdit}
        item={params?.item}
      />
    </MainLayout>
  );
};

export default HubAddTask;
