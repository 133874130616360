import React, {useEffect, useState} from 'react';
import BackBtnWithTitlePage from '../../../../components/reusable/btns/backBtn/BackBtnWithTitlePage.jsx';
import InfoCardList from '../../../../components/reusable/cards/infoCard/InfoCardList.jsx';
import FilterTags from '../Items/filterList/items/filterTags/FilterTags.jsx';
import LinkTabs from '../../../../components/reusable/assets/linkTabs/LinkTabs.jsx';
import MainLayoutContent from '../../../../components/layouts/mainLayout/mainLayoutContent/MainLayoutContent.jsx';
import MainLayout from '../../../../components/layouts/mainLayout/MainLayout.jsx';
import AdditionalInformation from '../../../../components/reusable/assets/additionalInformation/AdditionalInformation.jsx';
import Loading from 'react-fullscreen-loading';

// mock data
import {
  mockItems,
  mockTags,
  presetList,
} from '../../../../mock-data/hub/hub.js';
import {useHubApp} from '../../../../appState/hooks/useHubApp';
import Strings from '../../../../string_key/Strings.web.js';
import {useBuzzmiAuth} from '../../../../appState/hooks/useBuzzmiAuth';
import PrimaryLink from '../../../../components/reusable/btns/links/PrimaryLink.jsx';
import {colors} from '../../../../res/colors.js';
import PlanSearch from '../../../../components/reusable/assets/additionalInformation/items/additionalInformationPlan/items/planSearch/PlanSearch.jsx';
import {useEventEmitter} from '../../../../hooks/useEventEmitter.js';

const HubAddActivityContent = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [LoadMore, setLoadMore] = useState(true);
  const [loadingBaseActivityPage, setLoadingBaseActivityPage] = useState(false);
  const [baseActList, setBaseActList] = useState([]);
  const [tags, setTags] = useState([]);
  const {baseActivitiesList, tagsList, getBaseActivitiesList} = useHubApp();
  const {language, userData} = useBuzzmiAuth();
  const [currBaseActPage, setCurrBaseActPage] = useState('1');
  const baseActiPageLimit = 10;
  const loadBaseActivity = setLoading => {
    getBaseActivitiesList(userData?.token, setLoading, {
      page: currBaseActPage,
      limit: baseActiPageLimit + '',
      tag: tags,
    });
  };
  useEffect(() => {
    // console.log('userData', userData);
    if (userData) {
      if (currBaseActPage == '1') {
        loadBaseActivity(setLoading);
      } else {
        loadBaseActivity(setLoadingBaseActivityPage);
      }
    }
  }, [userData, currBaseActPage, tags]);

  useEffect(() => {
    console.log('baseActivitiesList loaded');
    if (baseActivitiesList?.length < baseActiPageLimit) {
      setLoadMore(false);
    }
    if (currBaseActPage == '1') {
      setBaseActList([...baseActivitiesList]);
    } else {
      setBaseActList([...baseActList, ...baseActivitiesList]);
    }
  }, [baseActivitiesList]);

  // useEventEmitter('refresh-baseActivities', () => {
  //   console.log('this emitted====>');
  //   // window.location.reload();
  //   setCurrBaseActPage(1);
  //   loadBaseActivity(setLoading);
  // });

  const tabsLast = [
    {
      title: Strings.all,
      id: 0,
    },
    {
      title: Strings.my_task,
      id: 1,
    },
    {
      title: Strings.buzzmi_task,
      id: 2,
    },
  ];

  const currentList = {
    0: baseActList,
    // 1: presetList,
    // 2: [...presetList, presetList[0]]
    1: baseActList?.filter(i => i.createdBy == userData?.id),
    2: baseActList?.filter(i => i.createdBy !== userData?.id),
  };
  const handleLoadmore = () => {
    setCurrBaseActPage(Number(currBaseActPage) + 1 + '');
  };
  return (
    <div>
      <Loading
        loading={loading}
        background="transparent"
        loaderColor={colors.PRIMARY_COLOR}
      />
      <BackBtnWithTitlePage title={Strings.add_task} btnText={Strings.back} />

      <div className="pb-2">
        <LinkTabs
          list={tabsLast}
          activeTabId={activeTab}
          onChange={setActiveTab}
        />
      </div>
      <div className="mb-3">
        <PlanSearch
          placholderExt={
            activeTab == 2
              ? Strings.buzzmi_task
              : activeTab == 1
              ? Strings.my_task
              : Strings.all
          }
        />
      </div>
      <FilterTags
        wrapMode
        tags={tagsList?.map(item => item.tagName)}
        setTags={setTags}
      />

      <InfoCardList
        infoList={currentList[activeTab]}
        showLoadMore={LoadMore}
        onLoadMoreClick={handleLoadmore}
        loading={loadingBaseActivityPage}
        navTo={'HubCreateTask'}
        taskType={'base'}
        isMainActivity={false}
      />
    </div>
  );
};

const AddActivities = () => {
  // return null;
  return (
    <MainLayout>
      <MainLayoutContent
        rightColumnContent={<AdditionalInformation onMyTaskBtn={true} />}>
        <HubAddActivityContent />
        <div className="d-flex bottom-mobile-button justify-content-center mt-5 d-lg-none">
          <div className="_fw">
            <PrimaryLink to="/hub/add-activity">
              {Strings.add_my_own_task}
            </PrimaryLink>
          </div>
        </div>
      </MainLayoutContent>
    </MainLayout>
  );
};

export default AddActivities;
