import React from 'react';
import Gallery from '../../../../../components/reusable/gallary/Gallery.jsx';
import VideoGallery from '../../../../../components/reusable/videoGallery/VideoGallery.jsx';
import AudioPlayer from '../../../../../components/reusable/audioPlayer/AudioPlayer.jsx';

const HubMedia = ({
  galleryList,
  videoSrcLink,
  audioSrcLink,
  title,
  autoplayVideo = true,
  item,
}) => {
  if (!audioSrcLink && !!videoSrcLink) {
    return (
      <div className="hub__holder hub-media hub-media__without-audio">
        <Gallery list={galleryList} isSmall item={item} />

        <div className="hub__media">
          <div className="hub__video mb-3">
            <VideoGallery title={title} src={videoSrcLink} />
          </div>
        </div>
      </div>
    );
  }

  if (!videoSrcLink && !!galleryList) {
    return (
      <div className="hub__holder hub-media hub-media__without-video">
        {galleryList && <Gallery list={galleryList} isSmall item={item} />}
        {audioSrcLink && (
          <div className="hub__audio">
            {item?.audioDescription && (
              <p className="h6">{item?.audioDescription}</p>
            )}
            {item?.audioTitle && (
              <p className="text-r my-1">{item?.audioTitle}</p>
            )}
            <AudioPlayer src={audioSrcLink} />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="hub__holder hub-media">
      {galleryList && <Gallery list={galleryList} isSmall item={item} />}
      <div className="hub__media">
        {videoSrcLink && (
          <div className="hub__video mb-3">
            <VideoGallery
              title={title}
              src={videoSrcLink}
              autoplayVideo={autoplayVideo}
            />
          </div>
        )}
        {audioSrcLink && (
          <div className="hub__audio">
            {item?.audioDescription && (
              <p className="h6">{item?.audioDescription}</p>
            )}
            {item?.audioTitle && (
              <p className="text-r mt-1">{item?.audioTitle}</p>
            )}
            <AudioPlayer src={audioSrcLink} />
          </div>
        )}
      </div>
    </div>
  );
};

export default HubMedia;
