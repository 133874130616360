import { StyleSheet, Dimensions } from 'react-native';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import { colors } from '../../../res/colors';
import { fonts } from '../../../res/fonts';
import styled from 'styled-components/native';
const ScreenWidth = Dimensions.get('window').width;
const MainContainer = styled.View`
  flex: 1;
  background-color: ${colors.WHITE};
  justify-content: center;
`;

const Text = styled.Text`
  color: ${colors.BLACK};
  font-size: ${hp(2)};
  text-align: center;
  margin-horizontal: ${wp(2)};
  font-family: ${fonts.BOLD};
`;

const RegularText = styled.Text`
  color: ${colors.BLACK};
  font-size: 1rem;
  text-align: center;
  margin-horizontal: 1rem;
  font-family: ${fonts.REGULAR};
`;

const SubtitleText = styled.Text`
  margin-top: 4px;
  align-self: flex-start;
  color: ${colors.OSLO_GRAY};
  font-family: ${fonts.REGULAR};
  width: ${wp('60%')};
  font-size: ${wp(3.5)};
  margin-horizontal: ${wp(2)};
`;

const ActionWrapper = styled.TouchableOpacity`
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  padding-vertical: 1rem;
  width: ${ScreenWidth > 670 ? hp(75) : wp(95)};
`;

const BorderContainer = styled.View`
  width: ${ScreenWidth > 670 ? hp(75) : wp(100)};
  alignSelf:center;
  height: 0.15rem;
  background-color: ${colors.BORDER_COLOR};
`;

const ItemDivider = styled.View`
  width: ${ScreenWidth > 670 ? hp(75) : wp(100)};
  height: ${hp(2.5)};
  align-self: center;
  background-color: ${colors.BORDER_SPACE_COLOR};
`;

export {
  Text,
  RegularText,
  ItemDivider,
  SubtitleText,
  MainContainer,
  ActionWrapper,
  BorderContainer,
};

export const styles = StyleSheet.create({
  item: {
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  icon_ic: {
    width: '1rem',
    height: '1rem',
    alignSelf: 'center',
    resizeMode: 'contain',
  },
  elevation_ic: {
    width: '3.5rem',
    height: '3.5rem',
    alignSelf: 'center',
    marginTop: '1.5rem',
  },
});
