import React, { useEffect, useState } from 'react';
import AdditionalInformation from '../../../../components/reusable/assets/additionalInformation/AdditionalInformation.jsx';
import MainLayoutContent from '../../../../components/layouts/mainLayout/mainLayoutContent/MainLayoutContent.jsx';
import Rank from '../Items/rank/Rank.jsx';
import LinkTabs from '../../../../components/reusable/assets/linkTabs/LinkTabs.jsx';
import Board from '../../../../components/reusable/board/Board.jsx';
import MainLayout from '../../../../components/layouts/mainLayout/MainLayout.jsx';
import Loading from 'react-fullscreen-loading';
// mock data
import { useBuzzmiAuth } from '../../../../appState/hooks/useBuzzmiAuth/index.web.js';
import { colors } from '../../../../res/colors.js';
import Strings from '../../../../string_key/Strings.web.js';
import { useLeaderBoard } from '../../../../appState/hooks/useLeaderBoard';
import MainLayoutWithAspectSide from '../../../../components/layouts/mainLayout/MainLayoutWithAspectSide.jsx';

const HubLeaderBoard = () => {
  const { userData } = useBuzzmiAuth();
  const { useLeaderBoardList } = useLeaderBoard();
  const { data: leaderBoardata, isLoading } = useLeaderBoardList(
    userData && userData?.token,
  );

  const tabsLast = [
    {
      href: '/hub/tasks',
      title: Strings.tasks,
      id: 0,
    },
    {
      href: '/hub/tasks:1',
      title: Strings.stats,
      id: 1,
    },
    {
      href: '/hub/leaderboard',
      title: Strings.leaderboard,
      id: 2,
    },
  ];

  return (
    <MainLayout>
      <MainLayoutContent rightColumnContent={<AdditionalInformation />}>
        <Loading
          loading={isLoading}
          background="transparent"
          loaderColor={colors.PRIMARY_COLOR}
        />
        <div className="lg:px-15 py-5 pb-0 px-6">
          <div className="grid grid-cols-1 text-sm sm:grid-cols-12 sm:grid-rows-1 sm:gap-x-6 md:gap-x-8 lg:gap-x-8">

            <div className="sm:col-span-4 md:col-span-5 md:row-span-2 md:row-end-2 self-center">
              <div className="aspect-h-2 overflow-hidden rounded-lg">
                <div className="hub__cup">
                  <img src="/images/assets/cup.png" alt="cup -img" />
                </div>
              </div>
            </div>
            <div className="sm:col-span-12 md:col-span-7">
              <Board items={leaderBoardata?.listOfLeaderboards} />
            </div>
          </div>
        </div>
      </MainLayoutContent>
    </MainLayout>
  );
};

export default HubLeaderBoard;
