import React from 'react';
import Box from '../../../../../components/reusable/cards/box/Box.jsx';
import RankRow from './items/RankRow/RankRow.jsx';
import {Link, useNavigation} from '@react-navigation/native';
import Strings from '../../../../../string_key/Strings.web.js';
import {useBuzzmiAuth} from '../../../../../appState/hooks/useBuzzmiAuth/index.web.js';

const RankTop = ({withRankBtn = true}) => (
  <div className={'d-flex justify-content-between align-items-center'}>
    <h4 className="h4">{Strings.your_rank}</h4>
    {withRankBtn && (
      <Link
        to="/hub/leaderboard"
        style={{fontFamily: '"Montserrat", sans-serif'}}>
        <div className="text-alt-primary d-none d-lg-block">
          {Strings.view_rank_ladder}
        </div>
      </Link>
    )}
  </div>
);

const Rank = ({withAvatar, withRankBtn = true, small = false}) => {
  const {language} = useBuzzmiAuth();

  return (
    <Box
      paddingMode="none"
      topComponent={<RankTop withRankBtn={withRankBtn} />}>
      <RankRow withAvatar={withAvatar} withRankBtn={withRankBtn} />
    </Box>
  );
};

export default Rank;
