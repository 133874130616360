import {useAtom} from 'jotai';
import {userWalletDetailAtom} from '../hubState';
import {getWalletInfo} from '../../../network/api/app';

export const useWalletData = () => {
  const [walletInfo, setWalletInfo] = useAtom(userWalletDetailAtom);

  const getWalletData = async token => {
    try {
      const response = await getWalletInfo({token});
      setWalletInfo(response);
    } catch (e) {
      console.log('error => ', e.message);
    }
  };

  return {
    walletInfo,
    getWalletData,
  };
};
