import React from 'react';
import {
  ServerIcon,
  UserIcon,
  HomeIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  RocketLaunchIcon,
  BanknotesIcon,
  CalendarDaysIcon,
  WalletIcon,
} from '@heroicons/react/24/outline';
import LogoSVG from './LogoSVG.jsx';
import {Link} from '@react-navigation/native';
import MainNewLayoutMenuItem from './mainLyoutMenuItem/MainNewLayoutMenuItem.jsx';
import {useAtom} from 'jotai';
import {profileSideBarNavAtom} from '../../../../appState/navState.js';
import Strings from '../../../../string_key/Strings.web.js';

global.activeMenu = '/hub/hub-activities';
const navigation = [
  {name: 'Home', href: '#', icon: HomeIcon, current: false, to: '/hub/home'},
  {
    name: 'Hub',
    href: '#',
    icon: ServerIcon,
    current: true,
    to: '/hub/hub-activities',
  },
  {name: 'Profile', href: '#', icon: UserIcon, current: false, to: '/profile'},
  {
    name: 'Messages',
    href: '#',
    icon: ChatBubbleOvalLeftEllipsisIcon,
    current: false,
    to: '/messages',
  },
  {
    name: 'Space',
    href: '#',
    icon: RocketLaunchIcon,
    current: false,
    to: '/space',
  },
  {
    name: 'Subscription',
    href: '#',
    icon: BanknotesIcon,
    current: false,
    to: '/subscription',
  },
  {
    name: 'Challenges',
    href: '#',
    icon: RocketLaunchIcon,
    current: false,
    to: '/challenges',
  },
  {
    name: 'Calendar',
    href: '#',
    icon: CalendarDaysIcon,
    current: false,
    to: '/calendar',
  },
  {name: 'Wallet', href: '#', icon: WalletIcon, current: false, to: '/wallet'},
];
const Profile_Nav_Items = [
  {
    icon: ServerIcon,
    name: Strings.post + 's',
    to: '/hub/home',
    current: true,
    showOnMobile: true,
  },
  {
    icon: ServerIcon,
    name: Strings.reel,
    current: false,
    to: '/hub/hub-activities',

    showOnMobile: true,
  },
  {
    icon: ServerIcon,
    name: 'Premium Members',
    to: '/hub/hub-activitie',
    current: false,
    showOnMobile: true,
  },

  {
    icon: ServerIcon,
    name: 'About',
    to: '/hub/hub-actvities',
    current: false,
    showOnMobile: true,
  },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const onHandelClick = to => {
  console.log(to);
  global.activeMenu = to;
};

const MainNewLayoutSidebar = props => {
  const [activeSideItem, setActiveSideItem] = useAtom(profileSideBarNavAtom);
  return (
    <div className="hidden xl:fixed xl:z-50 xl:flex xl:w-72 xl:flex-col top-[65px] h-full">
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6">
        {/* <div className="flex h-16 shrink-0 items-center">
          <div className="main-layout__fav_logo" style={{marginBottom: 0}}>
            <Link to={{screen: props.userData == null ? 'Login' : 'Home'}}>
              <div className="logo">
                <div>
                  <LogoSVG />
                </div>
              </div>
            </Link>
          </div>
        </div> */}
        <nav className="flex flex-1 flex-col">
          <ul role="list" className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" className="mx-2 space-y-1 pt-3">
                {Profile_Nav_Items.map(item => (
                  <MainNewLayoutMenuItem
                    onClick={() => setActiveSideItem(item.name)}
                    item={item}
                    current={activeSideItem}
                    onHandelClick={onHandelClick}
                  />
                ))}
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};
export default MainNewLayoutSidebar;
