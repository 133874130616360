import React from 'react';
import {Link} from 'react-router-dom';

const AssetsCard = ({link, title, img, onClick}) => {
  return (
    <div
      className="assets-card"
      onClick={onClick}
      // to={link}
    >
      <div className="assets-card__image">
        <img src={img} alt="assets-card-image" />
      </div>

      <div className="h5">{title}</div>
    </div>
  );
};

export default AssetsCard;
