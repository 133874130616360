import React from 'react';
import ProfileMobileUserMenu from '../profileMobileUserMenu/ProfileMobileUserMenu';
import EllipsisMenu from '../../../items/ellipsisMenu/EllipsisMenu';
// import { useNavigate } from 'react-router-dom';
import { useLinkTo } from '@react-navigation/native';
import image3 from '../../../../../../assets/creators/1.jpg';
const ProfileMobileUsersItem = ({ item, button }) => {
  const linkTo = useLinkTo();

  const handleOpenProfile = () => {
    // linkTo(`/profile/user/${item?.id}`);
  };

  return (
    <div className="profile-contact shadow !rounded-none !shadow-none !sm:p-[5px]	!p-[10px] !pr-0">
      <div className="d-flex align-items-center ">
        <div onClick={handleOpenProfile} className="profile-contact__avatar">
          <img src={item?.avatar ? item.avatar : image3} />
        </div>
        <div>
          <div className="profile-contact__name">
            {item.displayName}{' '}
            {item.isVerifi && (
              <img
                className="profile-contact__verifi"
                src="/images/assets/verified.svg"
                alt="verified"
              />
            )}
          </div>
          <div className="profile-contact__nickname">@{item.username}</div>
          {item?.isElite && (
            <div className="profile-contact__elite">Elite creator</div>
          )}
        </div>
      </div>

      <div className="d-flex align-items-center">
        {button}
        <EllipsisMenu type="friendCard" is_from_modal={true} modalmenu={"!relative !self-center  !top-0 !right-0 !sm:h-4 !h-8"} />
      </div>
    </div>
  );
};

export default ProfileMobileUsersItem;
