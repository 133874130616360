import React, {useState, useEffect} from 'react';
import ResizeComponent from '../../../../components/reusable/assets/resizeComponent/ResizeComponent';
import ProfileMainMobileV2 from '../profileMainMobileV2/ProfileMainMobileV2';
import MainLayoutV2 from '../../../../components/layouts/mainLayout/MainLayoutV2';
import MainLayoutContentV2 from '../../../../components/layouts/mainLayout/mainLayoutContentV2/MainLayoutContentV2';
import Box from '../../../../components/reusable/cards/box/Box';
import {useProfileApp} from '../../../../appState/hooks';
import {useBuzzmiAuth} from '../../../../appState/hooks/useBuzzmiAuth/index.web';
import FriendsModal from '../../Profile/ProfileMain/componentsV2/FriendsModal';
import ActivityModal from '../../Profile/ProfileMain/componentsV2/ActivityModal';
import BadgesModal from '../../Profile/ProfileMain/componentsV2/BadgesModal';
import PlanSearch from '../../../../components/reusable/assets/additionalInformation/items/additionalInformationPlan/items/planSearch/PlanSearch';
import Button from '../../../../components/reusable/form/items/buttons/Button';
import CreatePostModal from '../../Profile/ProfileMain/componentsV2/CreatePostModal';
import {socialDashboardNavAtom} from '../../../../appState/navState';
import {useAtom} from 'jotai';
import SocialDashboardDesktopTab from '../profileMainMobileV2/SocialDashboardDesktopTab';
import {useHubApp} from '../../../../appState/hooks/useHubApp/index.web';
import image3 from '../../../../assets/creators/1.jpg';
import {useBookMarkLists} from '../../../../appState/hooks/useBookMarkLists';
import SuggestedUsersModal from '../../Profile/ProfileMain/componentsV2/SuggestedUsersModal';
import {useEventEmitter} from '../../../../hooks/useEventEmitter';

let mocDataContacts;
let mocDataActivity;
export const profileMobileDataBlocks = [
  {
    title: 'Badges',
    icon: {
      src: '/images/pages/profile/badge.png',
      alt: 'Badges',
    },
  },
  {
    title: 'Activity',
    icon: {
      src: '/images/pages/profile/activity.png',
      alt: 'Activity',
    },
  },
];

const SocialDashboard = () => {
  const suggestedUser = [
    {
      id: 1,
      name: 'Jane',
      username: 'janedoe',
      membership: 'Elite creator',
    },
    {
      id: 2,
      name: 'Matilda',
      username: 'matilda',
      membership: 'Elite creator',
    },
    {
      id: 3,
      name: 'Jarvis',
      username: 'jarvis',
      membership: 'Elite creator',
    },
    {
      id: 4,
      name: 'Clara',
      username: 'clara',
      membership: 'Elite creator',
    },
  ];

  const {
    getInformationContacts,
    userContactInfo,
    getUsersActivity,
    userActivityInfo,
  } = useProfileApp();
  const {userData} = useBuzzmiAuth();
  const [openFriendsModal, setOpenFriendsModal] = useState(false);
  const [openSuggestedUserModal, setSuggestedUserModal] = useState(false);

  const [openActivityModal, setOpenActivityModal] = useState(false);
  const [openBadgesModal, setOpenBadgesModal] = useState(false);
  const [openPostModal, setOpenPostModal] = useState(false);
  const [activeSideItem, setActiveSideItem] = useAtom(socialDashboardNavAtom);
  const {
    postFollowUser,
    suggestedUsers,
    getSuggestedUsers,
    getUserPostLists,
    getBuzzmiUserPostLists,
    getUserReelLists,
    getFollowingPostLists,
  } = useProfileApp();

  const {getAllBookMarkList} = useBookMarkLists();
  const {getRankInfoDetail} = useHubApp();

  // useEffect(() => {
  //   if (userData) {
  //     getFollowingPostLists(userData?.token, { page: 1, limit: 20 });
  //     getBuzzmiUserPostLists(userData?.token, { page: 1, limit: 20 });
  //     getUserPostLists(userData?.token, userData?.id, { page: 1, limit: 20 });
  //     getUserReelLists(userData?.token, userData?.id, { page: 1, limit: 20 });
  //     getSuggestedUsers(userData?.token, { page: 1, limit: 10 });
  //     getAllBookMarkList(userData?.token)
  //   }
  // }, [userData]);

  useEventEmitter('refresh-suggested-list', data => {
    getSuggestedUsers(userData?.token, {page: data?.page, limit: data?.limit});
  });

  useEffect(() => {
    getInformationContacts(userData?.token);
    getUsersActivity(userData?.token);
  }, [userData]);

  useEffect(() => {
    mocDataContacts = [
      {
        title: 'Following',
        value: userContactInfo?.following,
        link: '/profile/mobile-users/1',
      },
      {
        title: 'Followers',
        value: userContactInfo?.followers,
        link: '/profile/mobile-users/1',
      },
      {
        title: 'Subscribers',
        value: userContactInfo?.friends,
        link: '/profile/mobile-users/1',
      },
    ];
  }, [userContactInfo]);

  useEffect(() => {
    mocDataActivity = [
      {
        title: 'Posts',
        value: userActivityInfo?.posts || 0,
      },
      {
        title: 'Views',
        value: userActivityInfo?.views || 0,
      },
      {
        title: 'Listens',
        value: userActivityInfo?.listens || 0,
      },
    ];
  }, [userActivityInfo]);

  return (
    <>
      <MainLayoutV2 page={'profile'} centerSpace={true}>
        <MainLayoutContentV2
          iscenter={true}
          rightColumnContent={
            <div className="fixed w-[384px]">
              <Box paddingMode="small" className={'p-[0px]'}>
                <div className="plan-bio p-[16px]">
                  <div className="plan-bio__avatar rounded-full">
                    <img src={userData?.avatar} alt={userData?.displayName} />
                  </div>
                  <div className="plan-bio__info block">
                    <div className="h5 text-base">
                      {userData?.firstName}{' '}
                      <span className={'font-normal text-xs'}>
                        {'@' + userData?.username}{' '}
                      </span>
                    </div>
                    <span className={'font-normal text-xs'}>
                      {userData?.membership?.title}{' '}
                    </span>
                  </div>
                </div>
                <div className={'p-[24px] border-y-2 pb-[8px]'}>
                  <PlanSearch />
                </div>
                <div className={'p-[20px] border-b-2 pb-[8px] pt-[8px]'}>
                  <a
                    className="plan-bio my-[12px] cursor-pointer"
                    onClick={() => {
                      setActiveSideItem('Posts'),
                        setOpenPostModal(!openPostModal);
                    }}>
                    <img
                      className={'w-6 h-6'}
                      src={'/images/social/add_post.png'}
                      alt={userData?.displayName}
                    />
                    <div className="plan-bio__info block">
                      <span className={'font-semibold text-sm '}>
                        {'Add New Post'}{' '}
                      </span>
                    </div>
                  </a>
                  <a
                    className="plan-bio my-[12px] cursor-pointer"
                    onClick={() => {
                      setActiveSideItem('Reel'),
                        setOpenPostModal(!openPostModal);
                    }}>
                    <img
                      className={'w-6 h-6'}
                      src={'/images/social/add_reel.png'}
                      alt={userData?.displayName}
                    />
                    <div className="plan-bio__info block">
                      <span className={'font-semibold text-sm '}>
                        {'Add New Reel'}{' '}
                      </span>
                    </div>
                  </a>
                  <a className="plan-bio my-[12px] cursor-pointer">
                    <img
                      className={'w-6 h-6'}
                      src={'/images/social/add_story.png'}
                      alt={userData?.displayName}
                    />
                    <div className="plan-bio__info block">
                      <span className={'font-semibold text-sm '}>
                        {'Add New Story'}{' '}
                      </span>
                    </div>
                  </a>
                  <a className="plan-bio my-[12px] cursor-pointer">
                    <img
                      className={'w-6 h-6'}
                      src={'/images/social/add_live.png'}
                      alt={userData?.displayName}
                    />
                    <div className="plan-bio__info block">
                      <span className={'font-semibold text-sm '}>
                        {'Add Live'}{' '}
                      </span>
                    </div>
                  </a>
                </div>

                <div className={'p-[20px] border-b-2 pb-[8px] pt-[8px]'}>
                  <div className={'flex justify-between py-[8px]'}>
                    <p className={'font-bold'}>Suggested for you</p>
                    <a
                      onClick={() => {
                        setSuggestedUserModal(!openSuggestedUserModal);
                      }}
                      className={'cursor-pointer text-[#e03f93]'}>
                      See all
                    </a>
                  </div>

                  {suggestedUsers.length > 0 && (
                    <>
                      {suggestedUsers.map(
                        (item, index) =>
                          index <= 3 && (
                            <div
                              key={`suggested-user-${index}`}
                              className={'flex justify-between py-[8px]'}>
                              <div
                                className="plan-bio p-[8px] pb-[5px]"
                                key={item?.id}>
                                <div className="plan-bio__avatar rounded-2xl w-14 h-14">
                                  <img
                                    src={item?.avatar ? item.avatar : image3}
                                  />
                                </div>
                                <div className="plan-bio__info block">
                                  <div className="h5 text-sm h5 text-sm text-ellipsis overflow-hidden truncate w-36">
                                    {item.displayName} {index}
                                    <span
                                      className={'font-normal text-xs flex'}>
                                      {'@' + item?.username}{' '}
                                    </span>
                                  </div>
                                  <span className={'font-normal text-xs'}>
                                    {item?.isElite ? 'Elite creator' : ''}
                                  </span>
                                </div>
                              </div>
                              <Button
                                className="w-auto p-2 cursor-pointer font-semibold text-white capitalize"
                                onClick={() => {
                                  postFollowUser(item?.id, userData.token);
                                }}>
                                <p
                                  className={
                                    'p-[6px] px-4 bg-[#e646a0] rounded-full text-base'
                                  }>
                                  Follow
                                </p>
                              </Button>
                            </div>
                          ),
                      )}
                    </>
                  )}
                </div>
              </Box>
            </div>
          }>
          <ResizeComponent
            desktopComponent={<SocialDashboardDesktopTab />}
            mobileComponent={
              <ProfileMainMobileV2
                setOpenFriendsModal={setOpenFriendsModal}
                setOpenActivityModal={setOpenActivityModal}
                setOpenBadgesModal={setOpenBadgesModal}
              />
            }
          />
        </MainLayoutContentV2>
      </MainLayoutV2>
      <SuggestedUsersModal
        open={openSuggestedUserModal}
        setOpen={setSuggestedUserModal}
      />

      <FriendsModal open={openFriendsModal} setOpen={setOpenFriendsModal} />
      <ActivityModal open={openActivityModal} setOpen={setOpenActivityModal} />
      <BadgesModal open={openBadgesModal} setOpen={setOpenBadgesModal} />
      <CreatePostModal
        open={openPostModal}
        setOpen={setOpenPostModal}
        post={null}
        is_edit={false}
      />
    </>
  );
};

export default SocialDashboard;
