import React, { useEffect, useState } from 'react';
import { colors } from '../../../../../../res/colors';
import { useProfileApp } from '../../../../../../appState/hooks';
import ProfilePostV2 from '../../../../Profile/items/profilePost/ProfilePostV2';
import { EventRegister } from 'react-native-event-listeners';
import { Dots } from 'react-activity';

const FollowingPostContent = props => {
    const [currentPostId, setCurrentPostId] = useState(null);
    const { followingUserPosts, postLoading } = useProfileApp();
    const [pageLimit, setPageLimit] = useState(20);

    useEffect(() => {
        if (pageLimit > 20) {
            EventRegister.emit('refresh-following-list', {
                page: 1,
                limit: pageLimit,
            });
        }
    }, [pageLimit]);

    return (
        <>
            <ul
                className={'profile-posts-list__column'}>
                {(
                    followingUserPosts && followingUserPosts.length > 0 ? (

                        <>
                            {(
                                followingUserPosts.map(post => (
                                    <ProfilePostV2
                                        key={post?._id}
                                        postData={post}
                                        currentPostId={currentPostId}
                                        type="post"
                                        isMyPost={false}
                                    />
                                ))
                            )}
                            {!postLoading &&
                                <div
                                    className="text-alt-primary cursor-pointer pb-2"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '100%'
                                    }}
                                    onClick={() => { setPageLimit(pageLimit + 20) }}>
                                    Load More
                            </div>
                            }
                        </>
                    ) : (
                            <div className={"flex justify-center mt-5 w-100"}>
                                <img
                                    src="/images/assets/empty_pic.png"
                                    alt="No Post Found"
                                    style={{ width: 180 }}
                                />
                            </div>
                        )
                )}
            </ul>

            {postLoading && (
                <div
                    className="mt-2"
                    style={{
                        display: 'flex',
                        justifyContent: 'center', // Horizontally center content
                        alignItems: 'center', // Vertically center content
                        width: '100%', // Set the width to 100% if needed
                    }}>
                    <Dots color={colors.PRIMARY_COLOR} />
                </div>
            )}
            {/* <Loading
                loading={postLoading}
                background="transparent"
                loaderColor={colors.PRIMARY_COLOR}
            /> */}
        </>
    );
};

export default FollowingPostContent;
