import React from "react"

const GiftsIcon = ({ isHovered }) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.34993 5.00033C3.19934 5.00033 2.2666 5.93307 2.2666 7.08366C2.2666 7.65146 2.49375 8.16621 2.86214 8.54199H18.3377C18.7061 8.16621 18.9333 7.65146 18.9333 7.08366C18.9333 5.93307 18.0005 5.00033 16.8499 5.00033H10.5999H4.34993Z"
        fill={isHovered ? "url(#presentsGradient)" : "#C0BACB"}
      />
      <path
        d="M10.5999 3.33366C10.5999 2.41318 11.3461 1.66699 12.2666 1.66699C13.1871 1.66699 13.9333 2.41318 13.9333 3.33366C13.9333 4.25413 13.1871 5.00033 12.2666 5.00033H10.5999V3.33366Z"
        fill={isHovered ? "url(#presentsGradient)" : "#C0BACB"}
      />
      <path
        d="M10.5999 3.33366C10.5999 2.41318 9.85374 1.66699 8.93327 1.66699C8.01279 1.66699 7.2666 2.41318 7.2666 3.33366C7.2666 4.25413 8.01279 5.00033 8.93327 5.00033H10.5999V3.33366Z"
        fill={isHovered ? "url(#presentsGradient)" : "#C0BACB"}
      />
      <path
        d="M9.97493 9.79199H3.08152L3.0841 9.89294C3.09131 10.1727 3.09993 10.507 3.09993 10.9527C3.09993 12.9866 3.09993 14.0035 3.4171 14.8057C3.83999 15.8753 4.65113 16.725 5.67208 17.1681C6.43779 17.5003 7.4085 17.5003 9.34991 17.5003H9.97493V9.79199Z"
        fill={isHovered ? "url(#presentsGradient)" : "#C0BACB"}
      />
      <path
        d="M11.2249 17.5003H11.8499C13.7913 17.5003 14.7621 17.5003 15.5278 17.1681C16.5487 16.725 17.3599 15.8753 17.7828 14.8057C18.0999 14.0035 18.0999 12.9866 18.0999 10.9527C18.0999 10.4521 18.0999 10.0921 18.0952 9.79199H11.2249V17.5003Z"
        fill={isHovered ? "url(#presentsGradient)" : "#C0BACB"}
      />
      {isHovered && (
        <defs>
          <linearGradient id="presentsGradient" gradientTransform="rotate(45)">
            <stop offset="0%" stopColor="#EF8ED5" />
            <stop offset="50%" stopColor="#EC44D0" />
            <stop offset="100%" stopColor="#C1169C" />
          </linearGradient>
        </defs>
      )}
    </svg>
  )
}

export default GiftsIcon
