import React, {useEffect, useState} from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import ProfileContentList from '../ProfileContentList';
import ProfileExclusive from '../profileExclusive/ProfileExclusive';
import ProfileMembers from '../profileMembers/ProfileMembers';

const ProfileContentTabs = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleTabSelect = index => {
    setActiveTabIndex(index);
  };

  return (
    <Tabs
      className="profile-section"
      selectedIndex={activeTabIndex}
      onSelect={handleTabSelect}>
      <TabList>
        <Tab>Posts</Tab>
        <Tab>Reel</Tab>
        <Tab>Premium</Tab>
        {/* <Tab>Exclusive</Tab> */}
      </TabList>

      <TabPanel key="posts">
        <ProfileContentList isPosts={true} />
      </TabPanel>
      <TabPanel key="reel">
        <ProfileContentList isPosts={false} />
      </TabPanel>
      <TabPanel key="members">
        <ProfileMembers />
      </TabPanel>
      <TabPanel key="exclusive">
        <ProfileExclusive />
      </TabPanel>
    </Tabs>
  );
};

export default ProfileContentTabs;
