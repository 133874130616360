// noinspection ES6CheckImport

import {StyleSheet} from 'react-native';
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import {fonts} from '../../../../res/fonts';
import {colors} from '../../../../res/colors';
import styled from 'styled-components/native';

const RegularText = styled.Text`
  text-align: center;
  color: ${colors.WHITE};
  font-family: ${fonts.BOLD};
  font-size: 1.7rem;
  margin-horizontal: 2rem;
`;
const HeaderText = styled.Text`
  color: ${colors.WHITE};
  font-family: ${fonts.BOLD};
  font-size: '1rem';
  margin-horizontal: 2rem;
`;
const ButtonText = styled.Text`
  text-align: center;
  color: ${colors.WHITE};
  font-family: ${fonts.MEDIUM};
  font-size: 1rem;
  margin-horizontal: 1rem;
`;
const MainContainer = styled.View`
  flex: 1;
  justify-content: center;
  background-color: ${colors.WHITE};
`;
const RoundButton = styled.Pressable`
  border-radius: 1rem;
  justify-content: center;
  background-color: #06bdc5;
  padding-vertical: 1rem;
  padding-horizontal: 1rem;
`;
const InviteContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 20rem;
  background-color: ${colors.PRIMARY_COLOR};
`;
const LoadingContainer = styled.View`
  // flex-direction: row;
  // align-items: center;
  justify-content: space-evenly;
  height: 8rem;
  background-color: ${colors.PRIMARY_COLOR};
`;
const HeaderShadowLine = styled.View`
  flex-direction: row;
  align-items: center;
  border-bottom-color: ${colors.LIGHT_GRAY};
  border-bottom-width: ${props => (props?.light ? 0.8 : 1.5)}px;
`;
export {
  MainContainer,
  HeaderShadowLine,
  InviteContainer,
  RegularText,
  ButtonText,
  RoundButton,
  LoadingContainer,
  HeaderText,
};
export default StyleSheet.create({
  icon_ic: {
    alignSelf: 'center',
    resizeMode: 'contain',
    width: wp(6),
    height: wp(6),
  },
});
