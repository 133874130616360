import React from "react"

const FollowIcon = ({ isHovered }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" enable-background="new 0 0 512 512" height="24" viewBox="0 0 24 24" width="24" fill={isHovered ? "url(#gradient)" : "#C0BACB"}><g id="Layer_2_00000150809447564471313360000017511645015593875105_"><g id="_07.add_friend"><path d="M5.878 9.356c-2.133 -2.156 -2.114 -5.634 0.042 -7.767C6.956 0.567 8.348 0 9.797 0h0.028C12.834 -0.005 15.281 2.433 15.286 5.447v0.047c0.023 3.009 -2.395 5.475 -5.409 5.498H9.797c-1.472 0 -2.883 -0.586 -3.919 -1.636z" /><path d="M18.802 22.58c-0.68 0.844 -1.716 1.336 -2.92 1.383 -0.708 0.028 -1.467 0.042 -2.386 0.042 -0.628 0 -1.27 -0.005 -1.884 -0.009 -0.469 -0.005 -0.947 -0.009 -1.425 -0.009v0.009h-0.45c-0.577 0 -1.153 0 -1.73 0.005s-1.153 0.005 -1.73 0.005c-0.811 0 -1.486 -0.005 -2.114 -0.009 -1.5 -0.019 -2.681 -0.52 -3.408 -1.453s-0.933 -2.203 -0.586 -3.666c0.483 -2.039 1.72 -3.905 3.488 -5.25 1.758 -1.341 3.923 -2.077 6.094 -2.077 0.094 0 0.188 0 0.277 0.005 4.734 0.136 8.738 3.436 9.52 7.852 0.206 1.186 -0.056 2.316 -0.745 3.173z" /><path d="M19.809 12.867c-0.82 0 -1.491 -0.666 -1.491 -1.491V10.172H17.109c-0.82 -0.014 -1.477 -0.694 -1.462 -1.514 0.014 -0.802 0.661 -1.448 1.462 -1.462h1.209v-1.209c0.014 -0.82 0.694 -1.477 1.514 -1.462 0.802 0.014 1.448 0.661 1.462 1.462v1.209h1.209c0.82 -0.014 1.5 0.642 1.514 1.462s-0.642 1.5 -1.462 1.514H21.295v1.209c0.005 0.82 -0.661 1.486 -1.486 1.486z" /></g></g>
<defs>
          <linearGradient id="gradient" gradientTransform="rotate(45)">
            <stop offset="0%" stopColor="#EF8ED5" />
            <stop offset="50%" stopColor="#EC44D0" />
            <stop offset="100%" stopColor="#C1169C" />
          </linearGradient>
        </defs>
    </svg>
  )
}

export default FollowIcon
