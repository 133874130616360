import React, {useState, useRef, useEffect} from 'react';
import useWindowSize from '../../../../../hooks/useWindowSize';
import {getVideoPosterURL} from '../types';

const VideoReel = ({reel}) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const videoRef = useRef(null);
  const {width} = useWindowSize();

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement && width < 767) {
      videoElement.play();
      setIsVideoPlaying(true);
    }
  }, [width]);

  const handleVideoClick = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      if (isVideoPlaying) {
        videoElement.pause();
      } else {
        videoElement.play();
      }
      setIsVideoPlaying(!isVideoPlaying);
    }
  };

  return (
    <video
      ref={videoRef}
      poster={getVideoPosterURL(reel)}
      className="reels__video"
      onClick={handleVideoClick}
      playsInline>
      <source src={reel} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

export default VideoReel;
