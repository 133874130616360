import React, {useEffect} from 'react';
import EditProfileBoxForm from '../EditProfileBoxForm';
import Input from '../../../../../../components/reusable/form/items/inputs/Input';
import {useBuzzmiAuth} from '../../../../../../appState/hooks/useBuzzmiAuth/index.web';

const EditProfileHealthInformation = ({register, setValue, paddingMode}) => {
  const {userData} = useBuzzmiAuth();

  useEffect(() => {
    setValue('height', userData?.height || 0);
    setValue('weight', userData?.weight || 0);
  }, [userData]);

  return (
    <EditProfileBoxForm title="Health Information" paddingMode={paddingMode}>
      <Input
        label="Your Current Height"
        type="number"
        name="height"
        placeholder="Write your current height"
        register={register}
      />
      <Input
        label="Your Current Weight"
        type="number"
        name="weight"
        placeholder="Write your current weight"
        register={register}
      />
    </EditProfileBoxForm>
  );
};

export default EditProfileHealthInformation;
