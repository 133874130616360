// import React from "react"

// const PostImage = ({ srcImg, altImg }) => {
//   return (
//     <div>
//       <img src={srcImg} alt={altImg} className="content-post__image" />
//     </div>
//   )
// }

// export default PostImage

import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.css'; // Import Swiper styles
import {Navigation, Pagination, Scrollbar, A11y} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {useDispatch} from 'react-redux';
import {
  closeModal,
  openModal,
} from '../../../../../../../store/slices/modalSlice/modalSlice';

const images = [
  {
    src: 'https://picsum.photos/200/300',
    alt: 'https://picsum.photos/200/300',
  },
  {
    src: 'https://picsum.photos/200/300',
    alt: 'https://picsum.photos/200/300',
  },
  {
    src: 'https://picsum.photos/200/300',
    alt: 'https://picsum.photos/200/300',
  },
];

const PostImage = ({images, isMyPost = true}) => {
  const dispatch = useDispatch();

  const onUnderDevelopment = () => {
    dispatch(
      openModal({
        modalName: 'renderAlert',
        modalType: 'alert',

        modalProps: {
          nameAlert: 'constructionAlert',
          propsAlert: {
            closeModal: () => {
              dispatch(closeModal());
            },
          },
        },
      }),
    );
  };

  return (
    <div className="content-post__image relative">
      <Swiper
        modules={[Pagination]}
        spaceBetween={50}
        slidesPerView={1}
        pagination={{clickable: true}}
        onSwiper={swiper => {}}
        onSlideChange={() => {}}>
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <img
              src={image}
              alt={image}
              className="content-post__image rounded-xl sm:rounded-none"
            />
          </SwiperSlide>
        ))}
      </Swiper>

      {/* //absolute top-[0] z-10 */}
      {isMyPost == false && (
        <div
          className={
            'absolute sm:top-[5px] sm:left-auto sm:right-[5px] bottom-[5px] left-[5px] z-[1]'
          }>
          <img
            onClick={() => {
              onUnderDevelopment();
            }}
            src={'/images/social/send_gift.png'}
            className="h-8 w-8 sm:h-10 sm:w-10"
          />
        </div>
      )}
    </div>
  );
};

export default PostImage;
