import React, {useState, useRef} from 'react';
import Form from '../../../../../../components/reusable/form/Form.jsx';
import RadioMode from '../radioMode/RadioMode.jsx';
import HubRepeat from '../hubRepeat/HubRepeat.jsx';
import HubReminder from '../hubReminder/HubReminder.jsx';
import HubTaskList from '../checkList/items/HubTaskList.jsx';
import PictureUploaderList from '../../../../../../components/reusable/uploader/pictureUploader/PictureUploaderList.jsx';
import CheckTags from '../checkTags/CheckTags.jsx';
import FormToggleDropdown from '../../../../../../components/reusable/assets/dropdown/formToggleDropdown/FormToggleDropdown.jsx';
import MultiBox from '../../../../../../components/reusable/cards/multiBox/MultiBox.jsx';
import SetGoal from '../setGoal/SetGoal.jsx';
import SpecificTime from '../../specificTime/SpecificTime.jsx';
import SubmitButton from '../../../../../../components/reusable/btns/buttons/submitButton/SubmitButton.jsx';
import Strings from '../../../../../../string_key/Strings.web.js';
import {useBuzzmiAuth} from '../../../../../../appState/hooks/useBuzzmiAuth/index.web.js';
import CustomLink from '../../../../../../components/reusable/btns/links/CustomLink.jsx';
import moment from 'moment';
import PrimaryButton from '../../../../../../components/reusable/btns/buttons/PrimaryButton.jsx';
import {useEffect} from 'react';
import {EventRegister} from 'react-native-event-listeners';
import addImg from '../../../../../../assets/add_icon.png';

const when = [
  {title: 'Morning', value: 'Morning'},
  {title: 'Afternoon', value: 'Afternoon'},
  {title: 'Evening', value: 'Evening'},
  {title: 'Any time', value: 'Any time'},
];

export const DisabledComponent = ({
  children,
  isDisabled = false,
  isHidden = false,
}) => {
  if (isHidden) {
    return null;
  }
  if (isDisabled) {
    return (
      <div className="relative">
        {children}
        <div
          style={{
            position: 'absolute',
            // backgroundColor: 'red', // For debug...
            opacity: 0.2,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        />
      </div>
    );
  }

  return children;
};

const HubTaskForm = ({
  values,
  error,
  handleSubmit,
  data,
  setValue,
  btnTitle,
  isValid,
  item,
  isPlanner,
  setIsPlanner = () => {},
  setIsShare = () => {},
  setIsAssign = () => {},
  setIsProgressImageEnabled = () => {},
}) => {
  const refCheckTags = useRef(null);
  const {language, userData} = useBuzzmiAuth();
  const [showSpecificTime, setShowSpecificTime] = useState(false);

  const isCreator =
    userData.id === item.createdById || userData.id === item.createdBy;

  const handleShowSpecificTime = () =>
    setShowSpecificTime(prev => {
      if (prev) {
        setValue('specificTime', undefined);
        setValue('timing', values.timing);
      }
      return !showSpecificTime;
    });
  useEffect(() => {
    if (item?.specificTime?.length > 0) {
      if (showSpecificTime == false) {
        setShowSpecificTime(true);
      }
    }
  }, []);

  function _renderButtonsLayout() {
    return (
      <div className="d-flex justify-content-center  mt-1 mb-1 bottom-mobile-button gap-1">
        {/* <div className="d-flex justify-content-center  mt-1  bottom-mobile-button">
          <PrimaryButton
            onClick={() => {
              EventRegister.emit('openPreviewActivityModal', {
                item: item,
                setValue: setValue,
                values: values,
              });
            }}
            className={'w-full items-center '}>
            Preview task
          </PrimaryButton>
        </div> */}
        <SubmitButton form="task-form" isValid={isValid} title={btnTitle} />
        {isCreator && (
          <>
            <button
              disabled={!isValid}
              onClick={e => {
                // e.preventDefault();

                setIsShare(false);
                setIsAssign(true);
                console.log('share call');
              }}
              class={
                `rounded-full ${
                  isValid
                    ? ' bg-pink-500 text-white hover:text-white'
                    : ' !text-pink-200 hover:!text-pink-200    border-2 border-pink-200 bg-white'
                }` + 'py-2 px-4 rounded inline-flex items-center'
              }>
              <svg
                class="w-4 h-4 mr-2 inline-block align-middle"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 20 20">
                <path d="M20 7.828a2 2 0 0 0-.586-1.414l-1.828-1.828A2 2 0 0 0 16.172 4H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.828z" />
                <path d="M14 4v4H8V4m0 16v-8h8v8" />
              </svg>

              <span> Assign</span>
            </button>
            <button
              disabled={!isValid}
              onClick={e => {
                // e.preventDefault();
                setIsAssign(false);

                setIsShare(true);
                console.log('assign call');
              }}
              class={
                `rounded-full ${
                  isValid
                    ? ' bg-pink-500 text-white hover:text-white'
                    : ' !text-pink-200 hover:!text-pink-200    border-2 border-pink-200 bg-white'
                }` + 'py-2 px-4 rounded inline-flex items-center'
              }>
              <svg
                class="w-4 h-4 mr-2"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 20 20">
                <path d="M20 7.828a2 2 0 0 0-.586-1.414l-1.828-1.828A2 2 0 0 0 16.172 4H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.828z" />
                <path d="M14 4v4H8V4m0 16v-8h8v8" />
              </svg>
              <span>Share</span>
            </button>
          </>
        )}
        {/* <PrimaryButton
          className={`rounded-full ${
            !isValid &&
            ' !text-pink-200 hover:!text-pink-200    border-2 border-pink-200 bg-white'
          }`}
          disabled={!isValid}
          onClick={e => {
            // e.preventDefault();

            setIsShare(false);
            setIsAssign(true);
            console.log('share call');
          }}>
      
          <svg
            class="w-4 h-4 mr-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20">
            <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
          </svg>
          Download
        </PrimaryButton> */}
        {/* <PrimaryButton
          className={`rounded-full ${
            !isValid &&
            ' !text-pink-200 hover:!text-pink-200    border-2 border-pink-200 bg-white'
          }`}
          disabled={!isValid}
          onClick={e => {
            // e.preventDefault();
            setIsAssign(false);

            setIsShare(true);
            console.log('assign call');
          }}>
    
          <svg
            class="w-4 h-4 mr-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20">
            <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
          </svg>
          Download
        </PrimaryButton> */}
      </div>
    );
  }

  return (
    <Form
      id="task-form"
      error={error}
      onSubmit={handleSubmit}
      formClassName="hub-form-wrapper">
      <DisabledComponent isDisabled={item.isPreview}>
        <MultiBox
          setValue={setValue}
          icon="/images/hub/form/calendar.png"
          defaultValue={values.timing}
          title={Strings.when + ' *'}
          name="timing">
          <RadioMode
            // disabled={showSpecificTime}
            setValue={setValue}
            value={values.timing}
            name="timing"
            variants={when}
            defaultValue={values.timing}
            setShowSpecificTime={setShowSpecificTime}
          />
          <SpecificTime
            values={values.specificTime}
            showSpecificTime={showSpecificTime}
            handleShowSpecificTime={handleShowSpecificTime}
            setValues={value => {
              setValue('timing', 'Specific time');
              setValue('specificTime', value);
            }}
          />
          {showSpecificTime && (
            <>
              <div style={{marginTop: 15}} />
              <HubReminder
                values={
                  values?.specificTime || {hours: 12, minutes: '00', ampm: 'AM'}
                }
                setValues={value => setValue('specificTime', value)}
              />
            </>
          )}
        </MultiBox>
      </DisabledComponent>
      <DisabledComponent isDisabled={item.isPreview}>
        <FormToggleDropdown
          defaultValue={values?.goal}
          setValue={setValue}
          icon="/images/hub/form/goal.png"
          title={Strings.set_a_goal}
          values={values?.goal}
          name="goal">
          <fieldset>
            <div className="space-y-1">
              <div className="relative flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    id="add-to-planner"
                    aria-describedby="comments-description"
                    name="add-to-planner"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-pink-500 focus:ring-pink-500"
                    defaultChecked={values?.isCustomGoal}
                    onClick={e => {
                      // console.log(e.target.value);
                      setValue('isCustomGoal', !values?.isCustomGoal);
                    }}
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label htmlFor="add-to-planner" className="h4 mb-1 text-xs">
                    Custom Goal
                  </label>{' '}
                  <span id="comments-description" className="text-gray-500">
                    <span className="sr-only"> Custom Goal </span>
                  </span>
                </div>
              </div>
            </div>
          </fieldset>
          {!values?.isCustomGoal ? (
            <SetGoal
              values={values?.goal}
              setValues={value => setValue('goal', value)}
            />
          ) : (
            <input
              onChange={e => setValue('customGoal', e.target.value)}
              placeholder="Enter Goal"
              className="input input_simple-text set-goal__number rounded-full mt-[0.7rem] w-full"
              value={values?.customGoal}
            />
          )}
        </FormToggleDropdown>
      </DisabledComponent>

      <DisabledComponent isDisabled={item.isPreview}>
        <FormToggleDropdown
          setValue={setValue}
          icon="/images/hub/form/repeat.png"
          title={Strings.repeat}
          name="repeat"
          text="set a cycle for your plan"
          defaultValue={
            item?.repeatIntervalCategory?.length > 0 ? values?.repeat : null
          }
          values={
            values?.repeat || {
              type: 'Week', // day/week/month
              every: '01', // will be required for day/week category only
              value: null, // will contain all the dates (array) selected from the calendar
              endDate: moment().add(1, 'days').format('LL'),
            }
          }
          // defaultValue={{
          //   type: 'Week',
          //   every: '01',
          //   value: null,
          // }}
        >
          <HubRepeat
            values={
              values?.repeat || {
                type: 'Week',
                every: '01',
                value: null,
                endDate: moment().add(1, 'days').format('LL'),
              }
            }
            setValues={value => setValue('repeat', value)}
          />
        </FormToggleDropdown>
      </DisabledComponent>

      <DisabledComponent isDisabled={item.isPreview} isHidden={!isCreator}>
        <FormToggleDropdown
          setValue={setValue}
          icon="/images/hub/form/clock.png"
          title={Strings.reminder}
          name="reminder"
          values={values?.reminder || {hours: 12, minutes: '00', ampm: 'AM'}}
          defaultValue={values?.reminder}>
          <HubReminder
            values={values?.reminder || {hours: 12, minutes: '00', ampm: 'AM'}}
            setValues={value => setValue('reminder', value)}
          />
        </FormToggleDropdown>
      </DisabledComponent>

      <DisabledComponent isDisabled={item.isPreview} isHidden={!isCreator}>
        <FormToggleDropdown
          defaultValue={values.tasks}
          setValue={setValue}
          icon="/images/hub/form/check-list.png"
          title={Strings.checklist}
          name="tasks">
          <HubTaskList
            value={values.tasks}
            setValues={value => setValue('tasks', value)}
          />
        </FormToggleDropdown>
      </DisabledComponent>

      <DisabledComponent isDisabled={item.isPreview} isHidden={!isCreator}>
        <FormToggleDropdown
          defaultValue={values.note}
          setValue={setValue}
          icon="/images/hub/form/notes.png"
          title={Strings.note}
          name="note">
          <input
            value={typeof values.note === 'string' ? values.note : ''}
            type="text"
            className="input input_simple-text rounded-full"
            placeholder={Strings.add_your_note_here}
            onChange={e => setValue('note', e.target.value)}
          />
        </FormToggleDropdown>
      </DisabledComponent>

      <DisabledComponent isDisabled={item.isPreview} isHidden={!isCreator}>
        <FormToggleDropdown
          setValue={setValue}
          defaultValue={values.images?.length > 0 ? values.images : null}
          icon="/images/hub/form/photo.png"
          title={Strings.add_photos_of_your_progress}
          name="photos"
          isFromMofidfy={true}
          setIsProgressImageEnabled={setIsProgressImageEnabled}>
          <PictureUploaderList
            classNames="picture-uploader__wrapper_mod"
            setValue={setValue}
            defaultValues={values.images}
            defaultValuesTitles={values?.progimgTitles}
            defaultValuesDes={values?.progimgDes}
            picture_type={'progress_photos'}
            isBig
            isProgress={true}
            withToggle={true}
            isCreate={true}
          />
        </FormToggleDropdown>
      </DisabledComponent>

      {values?.tags?.length > 0 && (
        <DisabledComponent isDisabled={item.isPreview} isHidden={!isCreator}>
          <MultiBox
            setValue={setValue}
            icon="/images/hub/form/hash.png"
            // icon=""
            title={Strings.tags}
            name="tags"
            // rightSide={
            //   !!setValue && (
            //     <button
            //       onClick={() => refCheckTags?.current?.handleAdd()}
            //       type="button ">
            //       <img
            //         src={addImg}
            //         className="h-8 w-8 sm:w-10 sm:h-10 md:w-10 md:h-10 lg:w-12 lg:h-12 xl:w-12 xl:h-12 mr-2"
            //         // onClick={() => console.log('khali')}
            //       />
            //     </button>
            //   )
            // }
          >
            <CheckTags
              ref={refCheckTags}
              value={values?.tags || []}
              setValue={setValue}
              valueName="tags"
            />
          </MultiBox>
        </DisabledComponent>
      )}
      {!item.isPreview && (
        <fieldset>
          <div className="space-y-5">
            <div className="relative flex items-start">
              <div className="flex h-6 items-center">
                <input
                  id="add-to-planner"
                  aria-describedby="comments-description"
                  name="add-to-planner"
                  type="checkbox"
                  className="h-5 w-5 rounded border-gray-300 text-pink-500 focus:ring-pink-500"
                  onClick={e => {
                    // console.log(e.target.value);
                    setIsPlanner(!isPlanner);
                  }}
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label htmlFor="add-to-planner" className="h4 mb-1 text-sm">
                  Add to My planner
                </label>{' '}
                <span id="comments-description" className="text-gray-500">
                  <span className="sr-only"> Add to My planner </span>
                </span>
              </div>
            </div>
          </div>
        </fieldset>
      )}

      {!item.isPreview ? _renderButtonsLayout() : <div className="mb-2" />}
    </Form>
  );
};

export default HubTaskForm;
