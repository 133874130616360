import React from 'react';
import GradientBorder from '../../../../../../../components/reusable/assets/gradientBorder/GradientBorder';

const PostAuthorInfoV2 = ({
  userName,
  userNickname,
  userPosition,
  userAvatar,
  isVerified,
  sizeSmall = false,
}) => {
  return (
    <div className="header-post__author-info-wrapper">
      {sizeSmall ? (
        <img
          src={userAvatar ? userAvatar : '/images/moc/avatar-big.png'}
          className={'header-post__avatar_v2'}
        />
      ) : (
        <GradientBorder>
          <img
            src={userAvatar ? userAvatar : '/images/moc/avatar-big.png'}
            className={'header-post__avatar'}
          />
        </GradientBorder>
      )}

      <div className="header-post__author-info">
        <div className="d-flex flex-row align-items-center header-post__author-name">
          <p className="h6">{userName}</p>
          {isVerified ? (
            <img
              src="/images/post/profile-approved.svg"
              alt="profile approved"
            />
          ) : (
            ''
          )}
        </div>

        <p className="header-post__author-nickname text-r">{userNickname}</p>
        <p className="header-post__author-job-title text-s">{userPosition}</p>
      </div>
    </div>
  );
};

export default PostAuthorInfoV2;
