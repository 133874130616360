import React, {useState} from 'react';
import Comment from './Comment';
import ViewMoreBtn from '../../../../../components/reusable/btns/view-more/ViewMoreBtn';

const RepliesList = ({replies}) => {
  const [showAllReplies, setShowAllReplies] = useState(false);

  const toggleReplies = () => {
    setShowAllReplies(!showAllReplies);
  };

  const visibleReplies = showAllReplies ? replies : replies.slice(0, 1);

  return (
    <ul className="comments-post__replies-list">
      {visibleReplies.map(reply => (
        <li key={reply.id}>
          <Comment comment={reply} />

          {reply.replies && <RepliesList replies={reply.replies} />}
        </li>
      ))}
      {replies.length > 1 && (
        <li>
          <ViewMoreBtn
            text={`View ${replies.length - 1} more replies`}
            showAll={showAllReplies}
            toggleShowAll={toggleReplies}
          />
        </li>
      )}
    </ul>
  );
};

export default RepliesList;
