import {useAtom} from 'jotai';
import {userDynamicCaption} from '../hubState';
import {getUserCaption} from '../../../network/api/app';

export const useDynamicCaption = () => {
  const [dynamicCaption, setDynamicCaption] = useAtom(userDynamicCaption);

  const getCaption = async token => {
    try {
      const response = await getUserCaption({token});

      setDynamicCaption(response);
    } catch (e) {
      console.log('error => ', e.message);
    }
  };

  return {
    dynamicCaption,

    getCaption,
  };
};
