import React, { useEffect } from 'react';
import ProfileInfoMobile from '../ProfileMain/profileMainMobile/items/profileInfoMobile/ProfileInfoMobile';
import UserProfileTags from '../ProfileUser/items/userProfileTags/UserProfileTags';
import UserProfileSocial from '../ProfileUser/items/userProfileSocial/UserProfileSocial';
import ProfileText from '../items/profileText/ProfileText';
import UserSkills from '../ProfileUser/items/userSkills/UserSkills';
import BackBtnWithTitlePage from '../../../../components/reusable/btns/backBtn/BackBtnWithTitlePage';
import { useBuzzmiAuth } from '../../../../appState/hooks/useBuzzmiAuth/index.web';

const mockLinks = [
  {
    title: '@janedoe',
    type: 'facebook',
    link: 'https://facebook.com',
  },
  {
    title: 'Jane Doe',
    type: 'instagram',
    link: 'https://instagram.com',
  },
  {
    title: 'Jane Doe',
    type: 'linkedin',
    link: 'https://linkedin.com',
  },
  {
    title: 'Jane Doe',
    type: 'youtube',
    link: 'https://youtube.com',
  },
];


const ProfileMobileAboutBody = () => {
  const { userData } = useBuzzmiAuth();

  return (
    <div className="profile-user">
      <BackBtnWithTitlePage title="About me" />
      <ProfileInfoMobile showAchievement isOtherProfile={false} />

      <div className="mt-3">
        <UserProfileTags />
      </div>
      <div className="mt-3 link-wrqapper">
        <ProfileText
          text={userData?.bio || '-'}
          title="Bio"
          istwoCap={true}
        />
      </div>
      <div className="mt-3">
        <UserSkills skill={userData?.skill || []} />
      </div>
      <div className="mt-3">
        <ProfileText
          text={userData?.college || '-'}
          title="Education"
        />
      </div>
      <div className="mt-3">
        <ProfileText text={userData?.workOrganization || '-'} title="Company" />
      </div>
      <UserProfileSocial links={mockLinks} />
    </div>
  );
};

export default ProfileMobileAboutBody;
