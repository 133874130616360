import ProfileInformationDesktop from './tabs/ProfileInformationDesktop';
import ProfileAboutDesktop from './tabs/ProfileAboutDesktop';
import ProfileContentTabs from '../profileContentTabs/ProfileContentTabs';
import React from 'react';
import ProfileUsersBody from '../../profileMobileUsers/ProfileUsersBody';

const OtherProfileDesktopTabsChildrens = ({ activeTabId }) => {
    const contentMap = {
        Post: <ProfileContentTabs isOtherProfile />,
        About: <ProfileAboutDesktop isOtherProfile />,
        Information: <ProfileInformationDesktop isOtherProfile />,
        Friends: <ProfileUsersBody isOtherProfile />,
    };

    return !activeTabId || !contentMap[activeTabId]
        ? null
        : contentMap[activeTabId];
};

export default OtherProfileDesktopTabsChildrens;
