import React from "react"
import CodeInputsFour from "../../../../../reusable/form/items/inputs/codeInputs/CodeInputsFour"

const VerifyPhoneAlertMobile = ({ phone }) => {
  return (
    <div className="d-md-none alert-mobile phone-alert text-r">
      <div className="alert__body phone-alert p-4">
        <div className="phone-alert__title">OTP Verification</div>
        <div className=" mb-3">
          Enter the OTP sent to <span className="phone-alert__phone">{phone}</span>
        </div>
        <div>
          <CodeInputsFour  codeLength={4} label={undefined} formClassName={undefined} identifier={undefined} hideMessage hideBtn />
        </div>
        <div className="mt-3">
          <span>Don`t receive the OTP</span>
          <button className="text-uppercase text-alt-primary phone-alert__resend ms-21">RESEND OTP</button>
        </div>
      </div>
      <button className="alert__bottom text-error h4">VERIFY & PROCEED</button>
    </div>
  )
}

export default VerifyPhoneAlertMobile
