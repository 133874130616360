// import React, {useEffect, useState} from 'react';
// import Badge from '../../../../../../components/reusable/badge/Badge.jsx';

// const CheckTag = ({value, tag, onChange, index}) => {
//   const [checked, setChecked] = useState(false);

//   useEffect(() => {
//     setChecked(value?.includes(tag.id));
//   }, [value]);

//   return (
//     <label>
//       <input
//         className="check-tags__checkbox"
//         type="checkbox"
//         checked={checked}
//         onChange={() => onChange(tag.id)}
//       />

//       <Badge
//         type="secondary"
//         active={checked}
//         title={tag.tagName}
//         index={index}
//       />
//     </label>
//   );
// };

// export default CheckTag;
import {useEffect, useState} from 'react';

const CheckTag = ({value, tag, handleRemove, isChanged, isChecked}) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (!value) return;
    setChecked(value.map(({id}) => id).includes(tag.id));
  }, [value]);

  return (
    <label>
      {isChecked && (
        <input
          className="check-tags__checkbox"
          type="checkbox"
          checked={checked}
        />
      )}
      <div className="badge__tags badge  badge_light-blue badge__active d-flex align-items-center">
        <span>{tag.title}</span>
        {isChanged && (
          <img
            onClick={() => handleRemove(tag)}
            src="/images/assets/remove-tag.svg"
            className="ms-2"
          />
        )}
      </div>
    </label>
  );
};

export default CheckTag;
