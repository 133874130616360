import React, { useState, useEffect, useRef, Suspense } from 'react';
import { Platform, StatusBar, Linking, Alert, Text } from 'react-native';

//third party lib
import DeepLinking from 'react-native-deep-linking';
import { getPathFromState, NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { getData, saveData } from '../res/asyncStorageHelper';
import Modal from '../components/modal/Modal.jsx';

import { QueryClient, QueryClientProvider } from 'react-query';
import { Network } from '../NetworkProvider';

import ChatNavigator from './navigators/ChatNavigator';
import { options } from './navigators/utils';
import TabNavigator from './navigators/TabNavigator';
import AuthStackNavigator from './navigators/AuthStackNavigator';
import { EventRegister } from 'react-native-event-listeners';
import ProfileNavigator from './navigators/ProfileNavigator';
import HubstackNavigator from './navigators/HubstackNavigator';
import { ToastProvider } from 'react-native-toast-notifications';
import { ErrorBoundary, useErrorBoundary } from 'react-error-boundary';
import PrimaryButton from '../components/reusable/form/items/buttons/PrimaryButton';
import BaseLayout from '../components/layouts/baseLayout/BaseLayout';
import HomeStackNavigator from './navigators/HomeStackNavigator';
import Subscriptions from '../container/App/Subscriptions/index';
import CommentsScreen from '../container/App/Profile/items/comments/CommentsScreen';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Subscription from '../container/Auth/Sales/Subscription/index';
import { getFBToken, NotificationListener } from '../firebase.js';
import { useAtom } from 'jotai';
import { _userFcmToken } from '../appState/userState.js';

const Stack = createNativeStackNavigator();

const queryClient = new QueryClient();

export const GetTranslucent = (state = true) => {
  Platform.OS === 'android' && StatusBar.setTranslucent(state);
  return Platform.select({ ios: state, android: state });
};

const PERSISTENCE_KEY = 'NAVIGATION_STATE_V1';
const AppNavigator = () => {
  global.activeMenu = '/hub/home';

  // usePermissions(CALL_PERMISSIONS);

  /**
   * The flow initiated after clicking on the deep link (invite link)
   * @param url
   * @returns {Promise<void>}
   */
  const handleChannelInvite = async url => {
    console.log('the url', url);
    if (typeof url !== 'string') {
      return;
    }

    // const resolvedLink: string = await dynamicLinks()
    //   .resolveLink(url)
    //   .then(resolved => resolved);

    // if (
    //   typeof resolvedLink?.url !== 'string' &&
    //   !resolvedLink?.url.includes('http')
    // ) {
    //   return;
    // }

    let _params = null;
    _params = {
      ..._params,
      channelName: _params?.channelName?.replace('+', ' '),
    };

    setTimeout(() => EventRegister.emit('dl_channel_invite', _params), 1500);
  };
  NotificationListener(payload => {
    // Handle the notification payload in your component
    console.log('Notification payload in component:', payload);
  });

  useEffect(() => {
    const handleUrl = ({ url }) => {
      Linking.canOpenURL(url).then(supported => {
        if (supported) {
          console.log(
            'Hello deep link 1https://files.slack.com/files-pri/T04BHTJ6E9X-F06BNVDEZA7/image.png23=> ',
            url,
          );
          // DeepLinking.evaluateUrl(url);
          handleChannelInvite(url).then();
        }
      });
    };
    // if (Platform.OS !== 'android') {
    addRoutesToDeepLinking();
    Linking.getInitialURL(url => {
      console.log('Hello deep link (getInitialURL) => ', url);
    });
    Linking.addEventListener('url', handleUrl);
    // }
    return () => {
      // if (Platform.OS !== 'android') {
      Linking.removeEventListener('url', handleUrl);
      // }
    };
  }, []);

  function addRoutesToDeepLinking() {
    DeepLinking.addScheme('https://');
    // https://trivacall.page.link/referral/DD3zJ1nLZc2sSPU17
    DeepLinking.addRoute('/buzzmi.page.link/referral', response => {
      console.log('Hello deep link 321 => ', response);
      // navigate('upcoming');
    });
  }

  return <Navigations />;
};

const Navigations = () => {
  // const { connect } = useConnection();
  const multipleNavigationRef = useRef(null);
  const [initialPageName, setInitialPageName] = useState('Auth');

  // const { userData } = useBuzzmiAuth();
  // useEffect(
  //   function () {
  //     stackNavigationRef.current = multipleNavigationRef.current;
  //     navigationRef.current = multipleNavigationRef.current;
  //   },
  //   [multipleNavigationRef.current],
  // );

  // TODO: Sendbird not implemented in web app...
  // useEffect(() => {
  //     if (currentUser) {
  //       CallHistoryManager.init(currentUser.userId);
  //     }
  //   }, [currentUser]);

  useEffect(() => {
    getData(
      'fontSize',
      async success => {
        if (success != null) {
          global.fontSize = success;
        } else {
          global.fontSize = 'm';
        }
      },
      failure => {
        global.fontSize = 'm';
      },
    );

    getData(
      'userDetails',
      async success => {
        setInitialPageName(success == null ? 'Auth' : 'Tab');
      },
      failure => {
        setInitialPageName('Auth');
      },
    );
  }, []);

  const applinking = {
    prefixes: ['https://buzzmi.app/', 'http://localhost:3000/'],
    config: {
      screens: {
        Tab: {
          // path: '/home',
          // screens: {
          //   Home: {
          //     path: 'home',
          //   },
          // },

          path: '/hub',
          screens: {
            Social: {
              path: '/v2/social',
            },
            Homev2: {
              path: '/v2/home',
            },
            Hub: {
              path: 'home',
            },
            // Hub: {
            //   path: 'hub-activities',
            // },
            // HubLeaderBoard: 'leaderboard',
            HubLeaderBoardv2: '/v2/leaderboard',
            // HubTasks: '/v2/tasks:tab?',
            HubTasksV2: '/v2/:tab?',
            HubPresetPreview: 'preset-preview',
            //AddActivities: 'add-activities',

            AddActivitiesv2: '/v2/add-activities',
            HomeWallet: 'v2/wallet-earning',

            PurchaseWallet: 'v2/purchase-wallet',
            WalletHistory: 'v2/wallet-history',

            WalletDetail: {
              path: 'v2/wallet-detail/:type',
              parse: {
                type: type => `${type}`,
              },
            },

            HubEditPreset: 'edit-preset',
            // HubAddTask: 'add-activity',
            HubAddTaskv2: '/v2/add-activity',

            AddPosts: 'add-posts',
            // HubStats: 'stats',
            HubTread: 'stats',
            FinishAccount: 'finish-account',
            Subscription: 'Subscription',
            // HubCreateTask: 'create-activity',
            HubCreateTaskv2: '/v2/create-activity',

            // HubTaskInfo: 'activity-info',
            HubTaskInfov2: '/v2/activity-info',

            // HubLeaders: {
            //   path: 'leaders/:id',
            //   parse: {
            //     id: id => `${id}`,
            //   },
            // },

            HubLeadersv2: {
              path: '/v2/leaders/:id',
              parse: {
                id: id => `${id}`,
              },
            },
          },
        },
        ChooseInfoNav: {
          path: '/info',
          screens: {
            ChooseRole: 'choose-role',
            AddCreators: 'add-creators',
            ChooseInterest: 'add-interest',
          },
        },
        Discover: {
          screens: {
            HomeDashboard: {
              path: 'Discover',
            },
          },
        },
        Reel: {
          screens: {
            Reel: {
              path: 'Reel',
            },
          },
        },
        ProfileNav: {
          path: '/profile',
          screens: {
            Profile: '',
            ProfileV3: {
              path: '/v2',
            },

            CommentsScreen: ':entity/:id/comments',
            EditProfile: 'edit',
            ProfilePremiumContent: 'premium-content/:id',
            PremiumContentReviews: 'premium-content/:id/reviews',
            VideoSessions: 'video-sessions/:id',
            GemsPostScreen: 'post/:id/gems',
            GiftsPostScreen: 'post/:id/gifts',
            Wallet: 'Profile/Wallet',
            ProfileRattings: 'profile-ratting',
            UserRattings: {
              path: '/user-ratting/:type',
              parse: {
                type: type => `${type}`,
              },
            },
            ProfileUser: {
              path: '/user/:id',
              parse: {
                id: id => `${id}`,
              },
            },
            ProfileMembers: 'profile-shop',
            BookMarkLists: 'bookmark-lists',
            VisitorViewContentList: {
              path: 'visitor-view/:id/:ispost',
              parse: {
                id: id => `${id}`,
                ispost: ispost => `${ispost}`,
              },
            },
            UserProfileViewContentList: {
              path: 'user-view/:id/:ispost',
              parse: {
                id: id => `${id}`,
                ispost: ispost => `${ispost}`,
              },
            },
            // ProfileMobileAbout: {
            //   path: '/user-mobile-about/:id',
            //   parse: {
            //     id: id => `${id}`,
            //   },
            // },
            ProfileMobileAboutV2: {
              path: '/user-mobile-about/:id',
              parse: {
                id: id => `${id}`,
              },
            },
            ReelsFullscreen: {
              path: '/reels-fullscreen/:id',
              parse: {
                id: id => `${id}`,
              },
            },
            VisitorReelsFullscreen: {
              path: '/visitor-reels-fullscreen/:id',
              parse: {
                id: id => `${id}`,
              },
            },
            VideoFullscreen: {
              path: '/visitor-fullscreen',
            },
          },
        },
      },
    },
    getPathFromState(state, config) {
      let path = getPathFromState(state, config);
      const index = path.indexOf('?');
      if (index >= 0) {
        path = path.substr(0, index);
      }
      return path;
    },
  };

  const authlinking = {
    prefixes: ['https://buzzmi.app/', 'http://localhost:3000/'],
    config: {
      screens: {
        Auth: {
          screens: {
            Welcome: 'Welcome',
            Login: 'login',
            LoginV2: {
              path: 'v2/login',
            },
            Registration: 'registration',
            ForgetPassword: 'forgetPassword',
            OtherServices: 'otherservices',
            Subscription: 'Subscription',
            MembershipV2: 'MembershipV2',
            Pay: 'Pay',
            MobilePay: 'MobilePay',
            ProvidePhone: 'provide-phone',
            ProvideEmail: 'provide-email',
            ProvidePhoneCode: 'provide-phone-code',
            Bio: 'bio',
            CreatePassword: 'create-password',
            ChooseRole: 'choose-role',
            AddCreators: 'add-creators',
          },
        },
      },
    },
    getPathFromState(state, config) {
      let path = getPathFromState(state, config);
      const index = path.indexOf('?');
      if (index >= 0) {
        path = path.substr(0, index);
      }
      return path;
    },
  };

  function fallbackRender({ error, resetErrorBoundary }) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.

    return (
      <div style={{ flex: 'auto' }}>
        <BaseLayout>
          <div className="text-center auth-layout__container mobile-layout pt-6 pt-sm-0">
            <div className="h4 content-auth__title">Something went wrong</div>
            <div className={'content-auth__text text-r'} style={{ color: 'red' }}>
              {error?.message || ''}
            </div>

            <div className="mb-5" style={{ marginTop: 16 }}>
              <div className="d-none d-sm-block">
                <PrimaryButton className={`mb-5`} onClick={resetErrorBoundary}>
                  Try again
                </PrimaryButton>
              </div>
            </div>
          </div>
        </BaseLayout>
      </div>
    );
  }

  return initialPageName === '' ? null : (
    <>
      <Suspense fallback={fallbackRender}>
        <ErrorBoundary
          fallbackRender={fallbackRender}
          onReset={details => {
            console.log(details);
            // Reset the state of your app so the error doesn't happen again
          }}>
          <Network />
          <ToastProvider>
            <QueryClientProvider client={queryClient}>
              <NavigationContainer
                ref={multipleNavigationRef}
                linking={initialPageName !== 'Auth' ? applinking : authlinking}
                fallback={<Text>Loading...</Text>}>
                <Modal />
                <Stack.Navigator initialRouteName={initialPageName}>
                  {initialPageName !== 'Auth' ? (
                    <Stack.Screen
                      name={'Tab'}
                      component={HomeStackNavigator}
                      options={options}
                    />
                  ) : (
                      <>
                        <Stack.Screen
                          name={'Auth'}
                          component={AuthStackNavigator}
                          options={options}
                        />
                        <Stack.Screen
                          name={'Tab'}
                          component={HomeStackNavigator}
                          options={options}
                        />
                      </>
                    )}

                  <Stack.Screen
                    name={'ProfileNav'}
                    component={ProfileNavigator}
                    options={options}
                  />

                  <Stack.Screen
                    name={'MarketPlaceNav'}
                    component={HubstackNavigator}
                    options={options}
                  />
                  <Stack.Screen
                    name={'chat'}
                    options={options}
                    component={ChatNavigator}
                  />
                  <Stack.Screen
                    options={options}
                    name={'Subscription'}
                    component={Subscription}
                  />
                </Stack.Navigator>
              </NavigationContainer>
            </QueryClientProvider>
          </ToastProvider>
        </ErrorBoundary>
      </Suspense>
    </>
  );
};
export default AppNavigator;
