/* eslint-disable react-hooks/exhaustive-deps */
// noinspection ES6CheckImport

import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';
// import DirectCallVideoCallingScreen from '../../container/App/CallContainer/DirectCallVideoCallingScreen.tsx';
// import DirectCallVoiceCallingScreen from '../../container/App/CallContainer/DirectCallVoiceCallingScreen.tsx';
// import StereoCardDetail from '../../container/App/SterioCard/StereoCardDetail';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import StripePaymentScreen from '../../container/App/StripePaymentScreen';
import MembershipScreen from '../../container/App/MembershipScreen';
// import FileViewerScreen from '../../uikit-app/FileViewerScreen.tsx';
// import SterioCard from '../../container/App/SterioCard';
// import {DirectRoutes} from '../../navigations/routes.ts';
import { getData } from '../../res/asyncStorageHelper';
import { options, options_modal } from './utils';
import { Routes } from '../../libs/navigation.web.ts';
// import {
//   GroupChannelCreateScreen,
//   GroupChannelInviteScreen,
//   GroupChannelMembersScreen,
//   GroupChannelScreen,
//   GroupChannelSettingsScreen,
// } from '../../uikit-app/index.ts';
// import DirectCallHistoryScreen from '../../container/App/CallContainer/DirectCallHistoryScreen.tsx';
import FAVerification from '../../container/App/Account/FAVerification';
import Disable2FA from '../../container/App/Account/Disable2FA';
import Invitations from '../../container/App/Account/Invitations';
import DeleteAccount from '../../container/App/Account/DeleteAccount';
// import ReasonModal from '../../container/App/Account/ReasonModal';
import VerifyOTPScreen from '../../container/Auth/VerifyOTPScreen';
import MyOrder from '../../container/App/Home/MyOrder';
import WalletActivityScreen from '../../container/App/Account/WalletActivityScreen';
import Homev2 from '../../container/App/Home/HomeV2';
import SocialDashboard from '../../container/App/HomeSocial/SocialDashboard/SocialDashboard';

import HubTasks from '../../container/App/Hub/HubTasks';
import HubTasksV2 from '../../container/App/Hub/HubTasksV2';

import Home from '../../container/App/Home';
import AddFriends from '../../container/App/Profile/AddFriend/index.web';
import ProfileMain from '../../container/App/Profile/ProfileMain/ProfileMain';
import EditProfile from '../../container/App/Profile/editProfile/EditProfile';
import ProfilePremiumContent from '../../container/App/Profile/profilePremiumContent/ProfilePremiumContent';
import { useBuzzmiAuth, useReels } from '../../appState/hooks';
// import HubSubscription from '../../container/App/HubMain/hubUpgrade/index.web';
import AddActivities from '../../container/App/Hub/AddActivities';
import AddActivitiesv2 from '../../container/App/Hub/AddActivitiesv2';

import HubPresetPreview from '../../container/App/Hub/HubPresetPreview/index.web';
import HubEditPreset from '../../container/App/Hub/EditPreset/index.web';
import HubLeaderBoard from '../../container/App/Hub/HubLeaderboard/index.web';
import HubAddTask from '../../container/App/Hub/AddTask/index.web';
import HubAddTaskv2 from '../../container/App/Hub/AddTaskv2/index.web';

import HubLeaders from '../../container/App/Hub/HubLeaders/index.web';
import HubLeadersv2 from '../../container/App/Hub/HubLeadersv2/index.web';

import HubStats from '../../container/App/Hub/HubStatsTaskScreen/index.web';
import HubTread from '../../container/App/Hub/HubTread/index.web';
import FinishAccountSetup from '../../container/App/Account/FinishAccountSetup/index.web';
import HubCreateTask from '../../container/App/Hub/CreateTask/index.web';
import HubCreateTaskv2 from '../../container/App/Hub/CreateTaskv2/index.web';

import HubTaskInfo from '../../container/App/Hub/HubTaskDetails/index.web';
import HubTaskInfov2 from '../../container/App/Hub/HubTaskDetailsv2/index.web';

import Subscriptions from '../../container/App/Subscriptions/index';
import CommentsScreen from '../../container/App/Profile/items/comments/CommentsScreen';
import HubLeaderBoardv2 from '../../container/App/Hub/HubLeaderboardv2/index.web';
import ChooseInfoNavigator from './ChooseInfoNavigator.web.js';
import { useEncryptedAtom } from '../../appState/hooks/useEncryptedAtom/index.web.js';
import {
  _userFcmToken,
  _userFcmTokenSavedOnBE,
  _userJustSignupAtom,
} from '../../appState/userState.js';
import { useAtom } from 'jotai';
import HomeWallet from '../../container/App/Hub/Wallet/index.web.js';
import SendGems from '../../container/App/Hub/SendGems/index.web';
import WalletDetail from '../../container/App/Hub/WalletDetail/index.web';
// import PurchaseWallet from '../../container/App/Hub/WalletDetail/index.web';
import WalletHistory from '../../container/App/Hub/WalletHistory';
import { getFBToken } from '../../firebase.js';
import { usePostFcmToken } from '../../appState/hooks/usePostFcmToken/index.js';
import ProfileRattings from '../../container/App/Profile/items/ProfileRattings';
import UserReviewContentPage from '../../container/App/Profile/premiumContentReviews/UserReviewContentPage.jsx';

const Stack = createNativeStackNavigator();

const HomeStackNavigator = () => {
  // Initialize context api
  const { defaultLogin, updateLanguage, userData } = useBuzzmiAuth();
  const { actionGetUserReels, actionGetReelList } = useReels();
  const [initroute, setInitroute] = useState('ChooseInfoNav');
  const [justSignup, setjustSignup] = useAtom(_userJustSignupAtom);
  const [userFcmToken, setUserFcmToken] = useAtom(_userFcmToken);
  const [userFcmTokenSavedOnBE, setUserFcmTokenSavedOnBE] = useAtom(
    _userFcmTokenSavedOnBE,
  );
  const { postSendFcmToken } = usePostFcmToken();
  useEffect(() => {
    console.log('userData?.token before', userData?.token);
    console.log('userFcmTokenSavedOnBE?.token before', userFcmTokenSavedOnBE);

    if (userFcmToken && !userFcmTokenSavedOnBE && userData?.token) {
      console.log('userData?.token after', userData?.token);
      postSendFcmToken(
        Object.assign(
          {
            fcmToken: userFcmToken,
          },
          {
            params: { isAuth: true, token: userData?.token },
          },
        ),
      );
    }
  }, [userFcmToken]);

  useEffect(() => {
    setTimeout(() => {
      getFBToken(setUserFcmToken);
    }, 2000);

    getData('useLanguage', useLanguage => {
      updateLanguage(useLanguage ? useLanguage : 'en');
    });
    getData(
      'userDetails',
      success => {
        if (justSignup) {
          setTimeout(() => {
            setjustSignup(false);
          }, 1000);
        }
        defaultLogin(success);
      },
      failure => { },
    );
  }, []);

  return (
    <Stack.Navigator
      key={initroute}
      initialRouteName={justSignup ? 'ChooseInfoNav' : 'Homev2'}>
      <Stack.Screen name={'Home'} component={Home} options={options} />
      <Stack.Screen
        name={'Social'}
        component={SocialDashboard}
        options={options}
      />
      <Stack.Screen
        name={'ChooseInfoNav'}
        component={ChooseInfoNavigator}
        options={options}
      />

      <Stack.Screen
        name={'ProfileRattings'}
        component={ProfileRattings}
        options={options}
      />

      <Stack.Screen
        name={'UserRattings'}
        component={UserReviewContentPage}
        options={options}
      />

      <Stack.Screen name={'Homev2'} component={Homev2} options={options} />
      <Stack.Screen
        options={options}
        name={'WalletHistory'}
        component={WalletHistory}
      />

      <Stack.Screen name={'Hub'} component={Home} options={options} />
      <Stack.Screen
        name={Routes.MyOrders}
        options={options}
        component={MyOrder}
      />
      <Stack.Screen
        name={'DeleteAccount'}
        component={DeleteAccount}
        options={options}
      />
      <Stack.Screen
        name={'Invitations'}
        component={Invitations}
        options={options}
      />
      <Stack.Screen options={options} name={'HubTasks'} component={HubTasks} />
      <Stack.Screen
        options={options}
        name={'HubTasksV2'}
        component={HubTasksV2}
      />

      <Stack.Screen
        options={options}
        name={'AddActivities'}
        component={AddActivities}
      />

      <Stack.Screen
        options={options}
        name={'AddActivitiesv2'}
        component={AddActivitiesv2}
      />

      <Stack.Screen
        options={options}
        name={'HomeWallet'}
        component={HomeWallet}
      />

      <Stack.Screen
        options={options}
        name={'PurchaseWallet'}
        component={SendGems}
      />

      <Stack.Screen
        options={options}
        name={'WalletDetail'}
        component={WalletDetail}
      />

      <Stack.Screen
        options={options}
        name={'HubPresetPreview'}
        component={HubPresetPreview}
      />

      <Stack.Screen
        options={options}
        name={'HubEditPreset'}
        component={HubEditPreset}
      />
      <Stack.Screen
        options={options}
        name={'HubAddTask'}
        component={HubAddTask}
      />

      <Stack.Screen
        options={options}
        name={'HubAddTaskv2'}
        component={HubAddTaskv2}
      />

      <Stack.Screen
        options={options}
        name={'HubLeaderBoard'}
        component={HubLeaderBoard}
      />
      <Stack.Screen
        options={options}
        name={'HubLeaderBoardv2'}
        component={HubLeaderBoardv2}
      />
      <Stack.Screen
        options={options}
        name={'HubLeaders'}
        component={HubLeaders}
      />

      <Stack.Screen
        options={options}
        name={'HubLeadersv2'}
        component={HubLeadersv2}
      />

      <Stack.Screen options={options} name={'HubStats'} component={HubStats} />
      <Stack.Screen options={options} name={'HubTread'} component={HubTread} />
      <Stack.Screen
        options={options}
        name={'FinishAccount'}
        component={FinishAccountSetup}
      />

      <Stack.Screen
        options={options}
        name={'HubCreateTask'}
        component={HubCreateTask}
      />

      <Stack.Screen
        options={options}
        name={'HubCreateTaskv2'}
        component={HubCreateTaskv2}
      />

      <Stack.Screen
        options={options}
        name={'HubTaskInfo'}
        component={HubTaskInfo}
      />

      <Stack.Screen
        options={options}
        name={'HubTaskInfov2'}
        component={HubTaskInfov2}
      />

      <Stack.Screen
        name={'VerifyOTPScreen'}
        component={VerifyOTPScreen}
        options={options}
      />
      <Stack.Screen
        name={'WalletActivity'}
        component={WalletActivityScreen}
        options={{ headerShown: false }}
      />
      <Stack.Group
        screenOptions={{
          headerShown: false,
          presentation: 'modal',
          gestureEnabled: Platform.OS !== 'android',
        }}>
        {/*<Stack.Screen name={'ReasonModal'} component={ReasonModal} />*/}
        <Stack.Screen name={'FAVerification'} component={FAVerification} />
        <Stack.Screen name={'Membership'} component={MembershipScreen} />
        <Stack.Screen
          name={'StripePaymentScreen'}
          component={StripePaymentScreen}
        />
      </Stack.Group>

      <Stack.Screen
        name={'Disable2FA'}
        component={Disable2FA}
        options={{ headerShown: false }}
      />

      {/*<Stack.Group
        screenOptions={{presentation: 'containedModal', headerShown: false}}>
        <Stack.Screen name={Routes.FileViewer} component={FileViewerScreen} />
      </Stack.Group>
      <Stack.Screen
        name={Routes.GroupChannel}
        options={options}
        component={GroupChannelScreen}
      />
      <Stack.Screen
        name={Routes.GroupChannelSettings}
        options={options}
        component={GroupChannelSettingsScreen}
      />
      <Stack.Screen
        name={Routes.GroupChannelCreate}
        options={options}
        component={GroupChannelCreateScreen}
      />
      <Stack.Screen
        name={Routes.GroupChannelInvite}
        options={options}
        component={GroupChannelInviteScreen}
      />
      <Stack.Screen
        name={Routes.GroupChannelMembers}
        options={options}
        component={GroupChannelMembersScreen}
      />
      <Stack.Screen
        name={'SterioCard'}
        component={SterioCard}
        options={options}
      />
      <Stack.Screen
        name={'StereoCardDetail'}
        component={StereoCardDetail}
        options={options}
      />
      <Stack.Screen
        name={'CallHistory'}
        component={DirectCallHistoryScreen}
        options={{headerShown: false}}
      />
      <Stack.Screen
        name={'Disable2FA'}
        component={Disable2FA}
        options={{headerShown: false}}
      />
       //////////////Moved from App Navigator
      <Stack.Screen
        name={'ProfileNav'}
        component={ProfileNavigator}
        options={options}
      />
      <Stack.Screen
        name={'MarketPlaceNav'}
        component={HubstackNavigator}
        options={options}
      />
       ////////////////////////////////////
      <Stack.Group screenOptions={{headerShown: false, gestureEnabled: false}}>
        <Stack.Screen
          name={DirectRoutes.VIDEO_CALLING}
          component={DirectCallVideoCallingScreen}
        />
        <Stack.Screen
          name={DirectRoutes.VOICE_CALLING}
          component={DirectCallVoiceCallingScreen}
        />
      </Stack.Group>
      */}

      {/*<Stack.Screen
        name={'ProductCategories'}
        options={options}
        component={ProductCategories}
      />
      <Stack.Screen
        name={'VendorSignup'}
        options={options}
        component={VendorSignup}
      />
      <Stack.Screen
        name={'AddCategory'}
        options={options}
        component={AddCategory}
      />
      <Stack.Screen
        name={'AddProducts'}
        options={options}
        component={AddProducts}
      />
      <Stack.Screen
        name={'ProductList'}
        options={options}
        component={ProductList}
      />*/}
      <Stack.Screen
        name={'AddFriends'}
        options={options}
        component={AddFriends}
      />
    </Stack.Navigator>
  );
};

export default HomeStackNavigator;
