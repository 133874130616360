import React from 'react';
import cn from 'classnames';
import Strings from '../../../../../string_key/Strings.web';
import {ShowSuccess, ShowError} from '../../../../HOC/MessageWeb.js';
import {useToast} from 'react-native-toast-notifications';

const SUB_TYPES = {
  PREMIUM_MEMBER: 'premium_member',
  FREE_MEMBER: 'free_member',
  ELITE_MEMBERSHIP: 'elite_membership',
}

const SubscriptionOpener = ({
  mySub,
  openers,
  subscription,
  active,
  onChange,
  isCurrent,
  icon,
}) => {
  const {title, id} = subscription;
  const toast = useToast();

  function handleSubscriptionClick() {
    if (mySub.type === subscription.type) {
      return onChange(subscription);
    } else if (mySub.type === SUB_TYPES.ELITE_MEMBERSHIP) {
      switch (subscription.type) {
        case SUB_TYPES.FREE_MEMBER:
          ShowError('You are already an Elite member', toast);
          return;
        case SUB_TYPES.PREMIUM_MEMBER:
          ShowError('You are already an Elite member', toast);
          return;
        default:
          return;
      }
    } else if (mySub.type === SUB_TYPES.PREMIUM_MEMBER) {
      switch (subscription.type) {
        case SUB_TYPES.FREE_MEMBER:
          ShowError('You are already an Premium member', toast);
          return;
        default:
          return onChange(subscription);
      }
    }
  }

  return (
    <div
      className={cn('subscription-opener', {
        'subscription-opener--active': active,
      })}
      onClick={() => handleSubscriptionClick()}>
      <div className="subscription-opener__holder">
        {isCurrent && (
          <span className="subscription-opener__label">
            {Strings.your_current_level}
          </span>
        )}
        <div className="subscription-opener__img">
          <img src={icon} alt={title} />
        </div>
        <span className="subscription-opener__name">{title}</span>
        {!active && (
          <span className="subscription-opener__more">
            {Strings.learn_more}
          </span>
        )}
        {active && <em className="subscription-opener__marker" />}
      </div>
    </div>
  );
};

export default SubscriptionOpener;
