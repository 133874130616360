import React, {useEffect, useRef, useState} from 'react';
import {useProfileApp} from '../../../../../appState/hooks';
import {useBuzzmiAuth} from '../../../../../appState/hooks/useBuzzmiAuth/index.web';
import Input from '../../../../../components/reusable/form/items/inputs/Input';

const AddCommentV2 = ({userAvatar, item, callback}) => {
  const [commentText, setCommentText] = useState('');
  const [inputNotEmpty, setInputNotEmpty] = useState(false);
  const {userData} = useBuzzmiAuth();

  const {getUserPostLists, getUserReelLists, getBuzzmiUserPostLists} =
    useProfileApp();

  const {commentResponse, postCommentOnComments} = useProfileApp();

  useEffect(() => {
    if (commentResponse) {
      callback(commentResponse, item);
      getUserPostLists(userData.token, userData?.id, {page: 1, limit: 20});
      getUserReelLists(userData.token, userData?.id, {page: 1, limit: 20});
      getBuzzmiUserPostLists(userData.token, {page: 1, limit: 20});
    }
  }, [commentResponse, item]);

  const handlePostComment = () => {
    if (inputNotEmpty) {
      let param_data = Object.assign({
        body: commentText,
      });
      postCommentOnComments(item?.id, param_data);
      setCommentText('');
      setInputNotEmpty(false);
    }
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter' && commentText.trim() !== '') {
      handlePostComment();
    }
  };

  const handleInputChange = e => {
    setCommentText(e.target.value);
    setInputNotEmpty(
      e.target.value.trim() !== '' && e.target.value?.length > 2,
    );
  };

  return (
    <div
      className="comments-post__add-comment_v2 d-flex flex-row align-items-center"
      style={{bottom: 10}}>
      <img src={userAvatar} alt="avatar" className="header-post__avatar_v2" />

      <div className="comments-post__add-comment-wrapper">
        <Input
          className="comments-post__add-comment-input text-r rounded-full"
          name="add comment"
          placeholder="Add a comment"
          value={commentText}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          register={() => {}}
        />
        <button
          onClick={handlePostComment}
          className={`comments-post__post-comment_v2 ${
            inputNotEmpty ? 'text-alt-primary' : ''
          } `}>
          Post
        </button>
      </div>
    </div>
  );
};

export default AddCommentV2;
