export const premiumContentData = {
  id: 1,
  title: "Yoga in nature",
  img: {
    src: "/images/pages/profile/moc/content/content.png",
    alt: "",
  },
  description:
    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil",
  highlights: [
    {
      id: 1,
      icon: {
        src: "/images/pages/profile/premium-content/man-biking.png",
        alt: "man-biking",
      },
      text: "Speed up your metabolism and burn more calories throughout the day.",
    },
    {
      id: 2,
      icon: {
        src: "/images/pages/profile/premium-content/moon.png",
        alt: "moon",
      },
      text: "Great mood",
    },
    {
      id: 3,
      icon: {
        src: "/images/pages/profile/premium-content/sunflower.png",
        alt: "sunflower",
      },
      text: "Beautiful nature around",
    },
  ],
  equipment: [
    {
      id: 1,
      image: {
        src: "/images/pages/profile/premium-content/equipment/weights.jpg",
        alt: "weights",
      },
      title: "Weights",
    },
    {
      id: 2,
      image: {
        src: "/images/pages/profile/premium-content/equipment/dumbbells.png",
        alt: "dumbbells",
      },
      title: "Dumbbells",
    },
    {
      id: 3,
      image: {
        src: "/images/pages/profile/premium-content/equipment/espander.jpg",
        alt: "espander",
      },
      title: "Espander",
    },
    {
      id: 4,
      image: {
        src: "/images/pages/profile/premium-content/equipment/jump-rope.jpg",
        alt: "jump-rope",
      },
      title: "Jump rope",
    },
    {
      id: 5,
      image: {
        src: "/images/pages/profile/premium-content/equipment/tennis-rackets.jpg",
        alt: "tennis-rackets",
      },
      title: "Tennis rackets",
    },
    {
      id: 6,
      image: {
        src: "/images/pages/profile/premium-content/equipment/yoga-mat.png",
        alt: "yoga-mat",
      },
      title: "Yoga mat",
    },
  ],
  "social-media": [
    {
      id: 1,
      icon: {
        src: "/images/pages/profile/phone.svg",
        alt: "phone",
      },
      link: "tel:+9812562585",
      value: "+981 256 25 85",
    },
    {
      id: 2,
      icon: {
        src: "/images/pages/profile/facebook.svg",
        alt: "facebook",
      },
      link: "https://www.facebook.com/",
      value: "Jane Doe",
    },
    {
      id: 3,
      icon: {
        src: "/images/pages/profile/instagram.svg",
        alt: "instagram",
      },
      link: "https://www.instagram.com/",
      value: "@janedoe",
    },
    {
      id: 4,
      icon: {
        src: "/images/pages/profile/youtube.svg",
        alt: "youtube",
      },
      link: "https://www.youtube.com/",
      value: "Jane Doe",
    },
  ],
  sold: 10,
  likes: 1300,
  price: 20,
  author: {
    id: 1,
    name: "Sara Smith",
    nickname: "sara_smith",
    profession: "Trainer, yoga coach",
    avatar: {
      src: "/images/moc/trainer.jpg",
      alt: "avatar",
    },
  },
  reviews: [
    {
      id: 1,
      author: {
        nickname: "alexandra",
        name: "Alexandra Johnson",
        position: "Elite creator",
        isVerified: true,
        avatar: {
          src: "/images/post/mock/matilda.jpg",
        },
      },
      date: new Date("2023-07-22"),
      rating: 5,
      text: "I recently completed an online sport course and I must say, it exceeded all my expectations. The course was well-structured and covered a wide range of sports - related topics. The instructor were knowledgeable and engaging, making the learning experience enjoyable.",
      replies: [
        {
          id: 1,
          author: {
            nickname: "janedoe",
            name: "John Doe",
            position: "Elite creator",
            isVerified: true,
            avatar: {
              src: "/images/post/mock/jane.jpg",
            },
          },
          text: "Thank you!",
          date: new Date("2023-07-23"),
        },
      ],
    },
    {
      id: 2,
      author: {
        nickname: "max",
        name: "Max Johnson",
        position: "Elite creator",
        isVerified: true,
        avatar: {
          src: "/images/post/mock/jarvis.jpg",
        },
      },
      date: new Date("2023-08-09"),
      rating: 4,
      text: "I recently completed an online sport course and I must say, it exceeded all my expectations. The course was well-structured and covered a wide range of sports - related topics. The instructor were knowledgeable and engaging, making the learning experience enjoyable.",
      replies: null,
    },
  ],
  multimedia: [
    {
      id: 1,
      type: "image",
      src: "/images/moc/sport1.jpg",
      title: "Weight management",
      text: "Effective for burning calories, which can aid in weight management and fat loss.",
    },
    {
      id: 2,
      type: "image",
      src: "/images/moc/sport2.jpg",
      title: "Cardiovascular health",
      text: "Improving cardiovascular health by elevating your heart rate and increasing circulation.",
    },
    {
      id: 3,
      type: "image",
      src: "/images/moc/sport3.jpg",
      title: "Convenience and accessibility",
      text: "Treadmills provide a convenient way to exercise regardless of weather conditions or outdoor limitations.",
    },
    {
      id: 4,
      type: "image",
      src: "/images/moc/sport4.jpg",
      title: "Correct running technique",
      text: "Correct running technique is essential for maximizing efficiency, reducing the risk of injury, and enhancing overall performance.",
    },
    {
      id: 5,
      type: "image",
      src: "/images/moc/sport5.jpg",
      title: "Weight management",
      text: "Effective for burning calories, which can aid in weight management and fat loss.",
    },
    {
      id: 6,
      type: "image",
      src: "/images/moc/sport6.jpg",
      title: "Weight management",
      text: "Effective for burning calories, which can aid in weight management and fat loss.",
    },
    {
      id: 7,
      type: "image",
      src: "/images/moc/sport7.jpg",
      title: "Weight management",
      text: "Effective for burning calories, which can aid in weight management and fat loss.",
    },
    {
      id: 8,
      type: "video",
      src: "/video/5.mp4",
      title: "Weight management",
      text: "Effective for burning calories, which can aid in weight management and fat loss.",
    },
  ],
}
