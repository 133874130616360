import {useAtom} from 'jotai';
import {userSuggestedTasks} from '../hubState';
import {getSuggestedTasks} from '../../../network/api/app';
import {useEffect} from 'react';

export const useGetSuggestedTasks = () => {
  const [suggestedTasks, setSuggestedTasks] = useAtom(userSuggestedTasks);
  useEffect(() => {
    return () => {
      setSuggestedTasks([]);
    };
  }, []);

  const getMySuggestedTasks = async (token, setLoading, params) => {
    setLoading(true);
    try {
      // console.log('params ===> ', params);
      const response = await getSuggestedTasks(params)({token});

      setSuggestedTasks(response);

      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log('error => ', e.message);
    }
  };

  return {
    suggestedTasks,
    getMySuggestedTasks,
  };
};
