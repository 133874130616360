import React, { useState, useRef, useEffect } from 'react';

const DefaultIcon = () => <i className="fa-solid fa-angle-down"></i>;

const Dropdown = ({
  title,
  Icon,
  Content,
  dropDownClass,
  openFromPar,
  setOpenFromPar,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);
  const IconComponent = Icon || DefaultIcon;
  useEffect(() => {
    setIsOpen(openFromPar);
  }, [openFromPar]);

  return (
    <div className={`dropdown${dropDownClass ? dropDownClass : ''} `}>
      <button
        className="dropdown__button"
        onClick={() => {
          setIsOpen(!isOpen);
          setOpenFromPar != undefined && setOpenFromPar(!openFromPar);
        }}>
        <span className="text-sm font-semibold leading-6 text-gray-900">
          {title}
        </span>
        <div
          className={`dropdown__arrow ${isOpen ? 'dropdown__arrow-open' : ''}`}>
          <IconComponent />
        </div>
      </button>
      <div
        className={`dropdown__content ${isOpen ? 'dropdown__content--open' : ''
          }`}
        style={{
          '--content-height': isOpen
            ? `${contentRef.current?.children[0]?.scrollHeight}px`
            : 0,
        }}
        ref={contentRef}>
        {isOpen && <Content />}
      </div>
    </div>
  );
};

export default Dropdown;
