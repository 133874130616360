import {useNavigation} from '@react-navigation/core';
import React from 'react';
// import {Link} from 'react-router-dom';
import {Link} from '@react-navigation/native';

const ChoseSocial = () => {
  const {navigate} = useNavigation();
  return (
    <ul className="chose-social">
      <li className="mb-2">
        <button
          onClick={() => {
            console.log('Hello!');
            navigate('AddContacts');
          }}
          style={{width: '100%'}}
          className={'d-flex justify-content-between align-items-center'}>
          <div className="align-items-center d-flex">
            <img
              src="/images/add-users/assets/users.svg"
              alt=""
              className="chose-social__icon"
            />
            <span className="h6">Your contacts</span>
          </div>
          <i className="fa-solid fa-chevron-right text-primary" />
        </button>
      </li>
      <li className="mb-2">
        <button
          onClick={() => {
            console.log('Hello!');
          }}
          style={{width: '100%'}}
          className={'d-flex justify-content-between align-items-center'}>
          <div className="align-items-center d-flex">
            <img
              src="/images/add-users/assets/facebook.svg"
              alt=""
              className="chose-social__icon"
            />
            <span className="h6">Facebook friends</span>
          </div>
          <i className="fa-solid fa-chevron-right text-primary" />
        </button>
      </li>
      <li className="mb-2">
        <button
          onClick={() => {
            console.log('Hello!');
          }}
          style={{width: '100%'}}
          className={'d-flex justify-content-between align-items-center'}>
          <div className="align-items-center d-flex">
            <img
              src="/images/add-users/assets/instagram.svg"
              alt=""
              className="chose-social__icon"
            />
            <span className="h6">Instagram friends</span>
          </div>
          <i className="fa-solid fa-chevron-right text-primary" />
        </button>
      </li>
      <li className="mb-2">
        <button
          onClick={() => {
            console.log('AddFriends!');
            navigate('AddFriends');
          }}
          style={{width: '100%'}}
          className={'d-flex justify-content-between align-items-center'}>
          <div className="align-items-center d-flex">
            <img
              src="/images/add-users/assets/arrow.svg"
              alt=""
              className="chose-social__icon"
            />
            <span className="h6">Invite friends</span>
          </div>
          <i className="fa-solid fa-chevron-right text-primary" />
        </button>
      </li>
    </ul>
  );
};

export default ChoseSocial;
