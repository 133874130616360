export const images = {
  ic_define_payout: require('../assets/ic_define_payout.png'),
  ic_two_fact: require('../assets/ic_two_fact.png'),
  ic_phone_verify: require('../assets/ic_phone_verify.png'),
  ic_email_verify: require('../assets/ic_email_verify.png'),
  ic_profile_pic: require('../assets/ic_profile_piic.png'),
  ph: require('../assets/ph.png'),
  ic_next_arrow: require('../assets/ic_next_arrow.png'),
  ph2: require('../assets/ph_2.png'),
  l_3: require('../assets/l_3.jpeg'),
  ic_one_say_sign: require('../assets/ic_one_say_sign.png'),
  bell: require('../assets/bell.png'),
  plus: require('../assets/plus.png'),
  story: require('../assets/story.png'),
  home_ic: require('../assets/home.png'),
  call_ic: require('../assets/call.png'),
  chat_ic: require('../assets/chat.png'),
  user_ic: require('../assets/user.png'),
  app_logo: require('../assets/icon.png'),
  eye_show: require('../assets/show.png'),
  ic_add: require('../assets/ic_add.png'),
  ic_done: require('../assets/ic_done.png'),
  ic_cancel: require('../assets/ic_cancel.png'),
  avatar: require('../assets/avatar.png'),
  add_cat: require('../assets/add_cat.png'),
  reels_ic: require('../assets/reels.png'),
  menu_ic: require('../assets/menu_ic.png'),
  lock_ic: require('../assets/lock_ic.png'),
  more_ic: require('../assets/more_ic.png'),
  bg_gray: require('../assets/bg_gray.png'),
  back_white: require('../assets/back.png'),
  demo_img: require('../assets/demo_img.png'),
  heart_ic: require('../assets/heart_ic.png'),
  avatar_1: require('../assets/avatar_1.png'),
  avatar_2: require('../assets/avatar_2.png'),
  video_ic: require('../assets/video_ic.png'),
  settings: require('../assets/settings.png'),
  add_full: require('../assets/add_full.png'),
  terms_ic: require('../assets/terms_ic.png'),
  right_ic: require('../assets/right_ic.png'),
  new_chat: require('../assets/new_chat.png'),
  call_btn: require('../assets/call_btn.png'),
  chat_btn: require('../assets/chat_btn.png'),
  network_ic: require('../assets/network.png'),
  logout_ic: require('../assets/logout_ic.png'),
  search_ic: require('../assets/search_ic.png'),
  ic_camera: require('../assets/ic_camera.png'),
  new_group: require('../assets/new_group.png'),
  video_btn: require('../assets/video_btn.png'),
  volume_up: require('../assets/volume_up.png'),
  sound_off: require('../assets/sound_off.png'),
  dollar_ic: require('../assets/dollar_ic.png'),
  menu_line: require('../assets/menu_line.png'),
  back_black: require('../assets/back_black.png'),
  account_ic: require('../assets/account_ic.png'),
  report_acc: require('../assets/report_acc.png'),
  profilePic: require('../assets/profilePic.png'),
  plus_round: require('../assets/plus_round.png'),
  reel_heart: require('../assets/reel_heart.png'),
  balance_ic: require('../assets/balance_ic.png'),
  message_ic: require('../assets/message_ic.png'),
  plus_small: require('../assets/plus_small.png'),
  community_ic: require('../assets/community.png'),
  language_ic: require('../assets/language_ic.png'),
  new_contact: require('../assets/new_contact.png'),
  new_channel: require('../assets/new_channel.png'),
  dotted_line: require('../assets/dotted_line.png'),
  bookmark_ic: require('../assets/bookmark_ic.png'),
  subtract_ic: require('../assets/subtract_ic.png'),
  search_blue: require('../assets/search_blue.png'),
  bg_elevation: require('../assets/bg_elevation.png'),
  settings_acc: require('../assets/settings_acc.png'),
  address_book: require('../assets/address_book.png'),
  video_record: require('../assets/video_record.png'),
  coin_earn_ic: require('../assets/coin_earn_ic.png'),
  telephone_ic: require('../assets/telephone_ic.png'),
  welcome_menu: require('../assets/welcome_menu.png'),
  walkthrogh_1: require('../assets/walkthrogh_1.png'),
  walkthrogh_2: require('../assets/walkthrogh_2.png'),
  walkthrogh_3: require('../assets/walkthrogh_3.png'),
  walkthrogh_4: require('../assets/walkthrogh_4.png'),
  rightArrow_ic: require('../assets/rightArrow_ic.png'),
  call_message_ic: require('../assets/call_message.png'),
  setting_bar_ic: require('../assets/setting_bar_ic.png'),
  share_money_ic: require('../assets/share_money_ic.png'),
  bg_search_page: require('../assets/bg_search_page.png'),
  btnCallDecline: require('../assets/btnCallDecline.png'),
  iconDecline_3x: require('../assets/iconDecline_3x.png'),
  notification_ic: require('../assets/notification_ic.png'),
  preferences_acc: require('../assets/preferences_acc.png'),
  notication_blue: require('../assets/notication_blue.png'),
  icCall_Primary: require('../assets/icCallFilled_20.png'),
  up_volume: require('../assets/up_volume.png'),
  down_volume: require('../assets/down_volume.png'),
  doggy: require('../assets/doggy.png'),
  ic_flip_camera: require('../assets/ic_flip_camera.png'),

  qr_code: require('../assets/qr_code.png'),
  radio_fill: require('../assets/radio_fill.png'),
  radio_ring: require('../assets/radio_ring.png'),

  card_ic: require('../assets/card_ic.png'),
  market_place: require('../assets/m_place.png'),

  categories: require('../assets/categories.png'),
  apple: require('../assets/apple.png'),
  android: require('../assets/android.png'),

  buzzmi_new: require('../assets/buzzmi_new.png'),
  buzzmi_new_ic: require('../assets/buzzmi_new_ic.png'),
  help_modal: require('../assets/help_modal.png'),
  mail_verified: require('../assets/mail_verified.png'),
  phone_verified: require('../assets/phone_verified.png'),

  wrong: require('../assets/wrong.png'),
  success: require('../assets/success.png'),
  smile: require('../assets/smile.png'),

  down_ic: require('../assets/down_ic.png'),
  up_ic: require('../assets/up_ic.png'),
  date_ic: require('../assets/date_ic.png'),

  rocket_ic: require('../assets/rocket.png'),
  shield_ic: require('../assets/shield.png'),

  ic_explorer: require('../assets/explorer.png'),
  explorer_ic: require('../assets/explorer_ic.png'),
  both_ic: require('../assets/both.png'),

  fb_ic: require('../assets/fb_ic.png'),
  insta_ic: require('../assets/insta_ic.png'),
  your_contact_ic: require('../assets/your_contact_ic.png'),
  invite_friend_ic: require('../assets/invite_friend_ic.png'),

  right_stroke_ic: require('../assets/right_stroke_ic.png'),

  messanger_ic: require('../assets/messanger_ic.png'),
  copy_link_ic: require('../assets/copy_link_ic.png'),
  facebook_ic: require('../assets/facebook_ic.png'),
  tiktok_ic: require('../assets/tiktok_ic.png'),
  instagram_ic: require('../assets/instagram_ic.png'),

  target_ic: require('../assets/target_ic.png'),
  star_ic: require('../assets/star_ic.png'),
  girl_ic: require('../assets/girl_ic.png'),
  creator_ic: require('../assets/creator_ic.png'),

  creator: require('../assets/creator.png'),

  triangle_ic: require('../assets/triangle_ic.png'),
  check_ic: require('../assets/check_ic.png'),

  payoneer_pay: require('../assets/payoneer_pay.png'),
  paypal_pay: require('../assets/paypal_pay.png'),
  google_pay: require('../assets/google_pay.png'),
  apple_pay: require('../assets/apple_pay.png'),

  payment_card_ic: require('../assets/payment_card_ic.png'),
  finish_ic: require('../assets/finish_ic.png'),
  text_logo: require('../assets/text_logo.png'),

  check_box_fill: require('../assets/check_box_fill.png'),
  check_box: require('../assets/check_box.png'),
  tri_blank: require('../assets/tri_blank.png'),

  save_ic: require('../assets/save_ic.png'),
  gallery_post: require('../assets/gallery_post.png'),

  notification_round_ic: require('../assets/notification_round_ic.png'),
  search_round_ic: require('../assets/search_round_ic.png'),
  chat_round_ic: require('../assets/chat_round_ic.png'),
  buzz_coin: require('../assets/buzz_coin.png'),
  gems: require('../assets/gems.png'),
  diamonds: require('../assets/diamonds.png'),
  badge: require('../assets/badge.png'),
  recharge_1: require('../assets/recharge_1.png'),
  recharge_2: require('../assets/recharge_2.png'),
  recharge_3: require('../assets/recharge_3.png'),
  user_trans_2: require('../assets/user_trans_2.png'),
  user_trans_1: require('../assets/user_trans_1.png'),
  sent_arrow: require('../assets/sent_arrow.png'),
  receive_arrow: require('../assets/receive_arrow.png'),
  gems_purchase: require('../assets/gems_purchase.png'),
  gems_back: require('../assets/gems_back.png'),
  gems_vector: require('../assets/gems_vector.png'),

  task_ic: require('../assets/task_ic.png'),
  diamonds_vector: require('../assets/diamonds_vector.png'),

  one_x: require('../assets/1x.png'),
  two_x: require('../assets/2x.png'),

  workouts_1: require('../assets/workouts_1.png'),
  workouts_2: require('../assets/workouts_2.png'),
  personal_plan: require('../assets/personal_plan.png'),
  habits: require('../assets/habits.png'),
  programs: require('../assets/programs.png'),
  buzzmi_task: require('../assets/buzzmi_task.png'),
  space_ic: require('../assets/space.png'),
  hub_ic: require('../assets/hub.png'),
  challenges_ic: require('../assets/challenges.png'),
  subscription_ic: require('../assets/subscription.png'),

  tab_avatar: require('../assets/tab_avatar.png'),
  tab_badges: require('../assets/tab_badges.png'),
  tab_ranking: require('../assets/tab_ranking.png'),

  gem_hunter: require('../assets/gem_hunter.png'),
  buzz_rookie: require('../assets/buzz_rookie.png'),
  social_butterfly: require('../assets/social_butterfly.png'),

  diamond_miner: require('../assets/diamond_miner.png'),
  buzz_builder: require('../assets/buzz_builder.png'),
  buzz_leader: require('../assets/buzz_leader.png'),
  gem_master: require('../assets/gem_master.png'),
  diamond_knight: require('../assets/diamond_knight.png'),
  buzz_champion: require('../assets/buzz_champion.png'),
  buzz_legend: require('../assets/buzz_legend.png'),
  down_arr: require('../assets/down_arr.png'),

  social_lion: require('../assets/social_lion.png'),
  socialite: require('../assets/socialite.png'),
  friendly_tigger: require('../assets/friendly_tigger.png'),
  all_star: require('../assets/all_star.png'),
  mentor: require('../assets/mentor.png'),
  trendsetter: require('../assets/trendsetter.png'),
  big_spender: require('../assets/big_spender.png'),
  super_saver: require('../assets/super_saver.png'),
  master_chef: require('../assets/master_chef.png'),
  fitness_fanatic: require('../assets/fitness_fanatic.png'),
  early_bird: require('../assets/early_bird.png'),
  cool_hunter: require('../assets/cool_hunter.png'),

  silver: require('../assets/silver.png'),
  gold: require('../assets/gold.png'),
  bronze: require('../assets/bronze.png'),
  broadcast_channel: require('../assets/broadcast_channel.png'),
  social_channel: require('../assets/social_channel.png'),
  exclusive: require('../assets/exclusive.png'),

  sort_ic: require('../assets/sort_ic.png'),
  send_message: require('../assets/send_message.png'),
  mute: require('../assets/mute.png'),
  copy_link: require('../assets/copy_link.png'),
  notify: require('../assets/notify.png'),
  share_ic: require('../assets/share_ic.png'),

  cross_round: require('../assets/cross_round.png')
};
