import React, {useState} from 'react';
import RankRow from '../../../Items/rank/items/RankRow/RankRow.jsx';

import LinkTabs from '../../../../../../components/reusable/assets/linkTabs/LinkTabs.jsx';
import FilterList from '../../../Items/filterList/FilterList.jsx';
import Calendar from '../../../../../../components/reusable/calendar/Calendar.jsx';
import UpdateInfo from '../../../../../../components/reusable/updateInfo/UpdateInfo.jsx';
import Strings from '../../../../../../string_key/Strings.web.js';
import {useHubApp} from '../../../../../../appState/hooks';
import PlanSearch from '../../../../../../components/reusable/assets/additionalInformation/items/additionalInformationPlan/items/planSearch/PlanSearch.jsx';

const HubTasksMobile = props => {
  const [calendarValue, setCalendarValue] = useState('2023-05-01');

  //todo moc data
  const tabsFirst = [
    {
      href: '/hub',
      title: Strings.hub,
      id: 0,
    },
    {
      href: '/calendar',
      title: Strings.calendar,
      id: 1,
    },
  ];

  const tabsLast = [
    {
      href: '/hub/tasks',
      title: Strings.tasks,
      id: 0,
    },
    {
      href: '/hub/stats',
      title: Strings.stats,
      id: 1,
    },
    {
      href: '/hub/leaderboard',
      title: Strings.leaderboard,
      id: 2,
    },
  ];

  return (
    <>
      <LinkTabs list={tabsFirst} activeTabId={0} />
      <div className="my-3">
        <RankRow withAvatar />
      </div>
      <div className="my-3">
        <PlanSearch />
      </div>
      <LinkTabs list={tabsLast} activeTabId={0} />
      <UpdateInfo
        text="Points last synced on Today, 01:32 pm"
        onBtnClick={() => null}
      />
      <div className="box box_small mb-4 mt-5 tasks-calendar">
        <Calendar
          onChange={setCalendarValue}
          value={calendarValue}
          period="week"
          noIndent
          // buttonProps={{text: 'See todays tasks', handler: () => null}}
        />
      </div>
      <FilterList data={props?.data || []} tagsData={props?.tagsData || []} />
    </>
  );
};

export default HubTasksMobile;
