import React, { useState } from 'react';
import Input from "../../../../components/reusable/form/items/inputs/Input.jsx";
import PrimaryButton from "../../../../components/reusable/form/items/buttons/PrimaryButton.jsx";
import { useNavigate } from "react-router-dom";
import Form from "../../../../components/reusable/form/Form";
import ValidationSchema from '../../../../form-validator/ValidationSchema.js';
import { ShowError, ShowSuccess } from '../../../HOC/MessageWeb.js';
import { useToast } from "react-native-toast-notifications";
import { POST } from '../../../../api_helper/ApiServices.js';
import { SEND_EMAIL_VERIFICATION, SEND_OTP } from '../../../../api_helper/Api.js';
import { saveData } from '../../../../res/asyncStorageHelper.web.js';
import { colors } from '../../../../res/colors.js';
import Loading from 'react-fullscreen-loading';
import { useFormValidator } from '../../../../form-validator/hooks/index.js';
import Strings from '../../../../string_key/Strings.web.js';
import {useEncryptedAtom} from "../../../../appState/hooks/useEncryptedAtom/index.web";
import {_fSecretAtom, _fUuidAtom} from "../../../../appState/userState";


const validationSchema = new ValidationSchema(
  {
    identifier: [
      { rule: 'required' },
      { rule: 'phoneOrEmail' },
    ],
  }
);

const FormForgotPassword = (props) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [isLoad, setIsLoad] = useState(false);
  const [fSecret, setFSecret] = useEncryptedAtom(_fSecretAtom);
  const [fUuid, setFUuid] = useEncryptedAtom(_fUuidAtom);

  const { register, handleSubmit, isValid } = useFormValidator(validationSchema, async (formData) => {
    onSubmit(formData)
  });

  const onSubmit = (data) => {
    const { identifier: email } = data;
    // let re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const regexExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;

    if (regexExp.test(email)) {
      setIsLoad(true)
      let postData = { "email": email, "flag": false };
      POST(SEND_EMAIL_VERIFICATION, false, null, null, postData, (response, isError) => {
        setIsLoad(false)
        if (isError) {
          console.log(isError)
        } else {
          setFSecret(response?.secret)
          props.props.navigation.navigate('EnterOtp', { param: Object.assign({ "email": email }) });
          setTimeout(() => {
            ShowSuccess(response?.message, toast)
          }, 250);
        }
      });
    } else {
      setIsLoad(true)
      let postData = { "mobile": email, "flag": false };
      POST(SEND_OTP, false, null, null, postData, (response, isError) => {
        setIsLoad(false)
        if (isError) {
          setTimeout(() => {
            ShowError(response, toast)
          }, 250);
        } else {
          setFSecret(response?.secret)
          setFUuid(response?.uniqueUUID)
          props.props.navigation.navigate('EnterOtp', { param: Object.assign({ "phone": email }) })
          setTimeout(() => {
            ShowSuccess(response?.message, toast)
          }, 250);
        }
      });
    }
  };





  return (
    <Form onSubmit={handleSubmit} formClassName='form content-auth__form'>
      <Loading loading={isLoad} background="transparent" loaderColor={colors.PRIMARY_COLOR} />
      <div className={"contents"}>
        <Input label={Strings.your_phone_or_email} name='identifier' register={register} />
        <PrimaryButton className={!isValid ? 'button_inactive' : ''} type='submit'>{Strings.continue}</PrimaryButton>
      </div>
    </Form>
  );
};

export default FormForgotPassword;
