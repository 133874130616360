import React from 'react';
import PaySystems from "./items/PaySystems.jsx";
import PayForm from "./items/PayForm.jsx";
import Strings from '../../../../string_key/Strings.web.js';

const DesktopPay = () => {
  return (
    <div className={'d-none d-lg-block'}>
      <div className='h3'>
        {Strings.become_premium_member}
      </div>
      <div className='pay__row'>
        <PaySystems />
        <div>
          <PayForm subtotal={144} discount={0} tax={6} total={150} />
        </div>
      </div>
    </div>
  );
};

export default DesktopPay;