import React, { useEffect, useState } from 'react';
import { useHubApp } from '../../../../../../../appState/hooks/useHubApp/index.web.js';
import CircleChart from '../../../../../../../components/reusable/assets/charts/circleChart/CircleChart.jsx';
import Strings from '../../../../../../../string_key/Strings.web';

const RankRowV2 = ({ withAvatar, onlyImageWithProg }) => {
  const { rankInfo } = useHubApp();
  if (onlyImageWithProg) {
    return (
      <li>
        <CircleChart
          progress={rankInfo?.progress ? rankInfo?.progress : 0}
          withAvatar={true}
        />

      </li>
    );
  }
  return (

    <div className="">

      <div className="grid grid-cols-3">
        <div className="pt-4 rank-card-container">
          <div className="rank-row__label justify-center rank-card-container-inner grid-temlate-apply">
            <div className="u-profile">
              <img
                className="rank-row__img m-auto"
                src={
                  rankInfo?.currentRankBadge
                    ? rankInfo?.currentRankBadge
                    : '/images/buzz_rookie.png'
                }
                alt="current rank"
                style={{
                  backgroundColor: '#f3ecfd',
                  objectFit: 'contain',
                  borderRadius: 50,
                  border: '10px solid #f3ecfd',
                }}
              />
            </div>
            <div className={'h6'}>
              <p
                className={
                  'mt-3 bg-gradient-to-r from-[#eb4dac] to-[#c72bb7] bg-clip-text text-transparent'
                }>
                {rankInfo?.currentRank || 'Rank 1'}
              </p>
            </div>

          </div>
        </div>

        <li>
          <CircleChart
            progress={rankInfo?.progress ? rankInfo?.progress : 0}
            withAvatar={false}
            withAvatar={true}
            Image={
              rankInfo?.currentRankBadge
                ? rankInfo?.currentRankBadge
                : '/images/logo.ico'
            }
          />
        </li>

        <div className="pt-4 rank-card-container">
          <div className="rank-row__label justify-center rank-card-container-inner grid-temlate-apply">
            <div className="u-profile">
              <img
                className="rank-row__img m-auto grayscale"
                src={
                  rankInfo?.nextRankBadge
                    ? rankInfo?.nextRankBadge
                    : '/images/gem_hunter.png'
                }
                alt="next rank"
                style={{
                  backgroundColor: '#f3ecfd',
                  objectFit: 'contain',
                  borderRadius: 50,
                  border: '10px solid #f3ecfd',
                }}
              />
            </div>

            <div className={'h6'}>
              <p
                className={
                  'mt-3 bg-gradient-to-r from-[#f1e9fc] to-[#bbb6b6] bg-clip-text text-transparent'
                }>
                {rankInfo?.nextRank || 'Rank 2'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RankRowV2;
