import React from 'react';
import LogoutDesktopAlert from './LogoutDesktopAlert';
import LogoutMobileAlert from './LogoutMobileAlert';

const LogoutAlert = props => {
  /**
   * Окей значит функции можем определять тут и передавать их ниже
   */

  const handleLogoutFromAllUsers = () => {
    // console.log("handleLogoutFromAllUsers", taskId)

    props?.closeModal();
  };
  const handleLogoutOnlyBaseTask = () => {
    // console.log("handleLogoutOnlyBaseTask", taskId)
    props?.logoutCallback();
  };

  return (
    <>
      <LogoutDesktopAlert
        handleLogoutFromAllUsers={handleLogoutFromAllUsers}
        handleLogoutOnlyBaseTask={handleLogoutOnlyBaseTask}
      />
      <LogoutMobileAlert
        handleLogoutFromAllUsers={handleLogoutFromAllUsers}
        handleLogoutOnlyBaseTask={handleLogoutOnlyBaseTask}
      />
    </>
  );
};

export default LogoutAlert;
