import {Text, View} from 'react-native';
import React from 'react';

const HubTaskVideoPreview = () => {
  return (
    <View>
      <Text>HubTaskImagePreview</Text>
    </View>
  );
};

export default HubTaskVideoPreview;
