import React, {useEffect, useState} from 'react';
import {useProfileApp} from '../../../../../../../appState/hooks';
import {useBuzzmiAuth} from '../../../../../../../appState/hooks/useBuzzmiAuth/index.web';
import LikeIcon from '../../../icons/LikeIcon';

const LikesPostBtn = ({likes, id, likesList}) => {
  const [likesCount, setLikesCount] = useState(null);
  const [isLiked, setIsLiked] = useState(false);
  const [hovered, setHovered] = useState(false);
  const {postLikePost, postDislikePost} = useProfileApp();
  const {userData} = useBuzzmiAuth();

  useEffect(() => {
    setLikesCount(Number(likes));
  }, [likes]);

  useEffect(() => {
    setIsLiked(
      likesList?.length > 0 &&
        likesList?.[0].participants?.nodes?.length &&
        likesList?.[0].participants.nodes.filter(
          o => o?.participant?.id === userData?.bettermodeUserId,
        ).length > 0,
    );
  }, [likesList]);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleClick = () => {
    if (isLiked) {
      postDislikePost(id, userData.token);
    } else {
      console.log('like', id);
      postLikePost(id, userData.token);
    }
    setIsLiked(prevIsLiked => !prevIsLiked);
    setHovered(false);
    setLikesCount(prevLikes => prevLikes + (isLiked ? -1 : 1));
  };

  return (
    <div
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="post-buttons__item d-flex align-items-center">
      <LikeIcon isHovered={isLiked || hovered} />
      <p
        className={`post-buttons__item-text likes ${
          isLiked || hovered ? 'hovered' : ''
        }`}>
        {likesCount}
      </p>
    </div>
  );
};

export default LikesPostBtn;
