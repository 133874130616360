import React from 'react';
import {Link} from "react-router-dom";

const Policy = () => {
  return (
    <div className='policy mb-4'>
      By continuing, you agree to Buzzmi’s <a className={"font-medium text-[#000]"}>Terms of Service</a> and confirm that you
      have read Buzzmi’s <a className={"font-medium text-[#000]"}>Privacy Policy</a>.
    </div>
  );
};

export default Policy;