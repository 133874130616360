import React, {useState, useEffect} from 'react';
import {Header, TabBar, SubTab, GridLayout} from './componentsV2';
import MainLayoutContent from '../../../../components/layouts/mainLayout/mainLayoutContent/MainLayoutContent';
import ResizeComponent from '../../../../components/reusable/assets/resizeComponent/ResizeComponent';
import ProfileMainMobileV2 from './profileMainMobileV2/ProfileMainMobileV2';
import ProfileMainDesktop from './profileMainDesktop/ProfileMainDesktop';
import ProfileMainDesktopV2 from './profileMainDesktopV2/ProfileMainDesktopV2';
import MainLayoutV2 from '../../../../components/layouts/mainLayout/MainLayoutV2';
import MainLayoutContentV2 from '../../../../components/layouts/mainLayout/mainLayoutContentV2/MainLayoutContentV2';
import CreatePostInput from './componentsV2/CreatePostInput';
import Box from '../../../../components/reusable/cards/box/Box';
import ProfileDataBadges from './items/profileDataBlocks/items/ProfileDataBadges';
import ProfileDataTextInfo from './items/profileDataBlocks/items/ProfileDataTextInfo';
import {useProfileApp} from '../../../../appState/hooks';
import {useBuzzmiAuth} from '../../../../appState/hooks/useBuzzmiAuth/index.web';
import RankV2 from '../../Hub/Items/rank/RankV2';
import FriendsModal from './componentsV2/FriendsModal';
import ActivityModal from './componentsV2/ActivityModal';
import BadgesModal from './componentsV2/BadgesModal';
import {ArrowDownIcon, ArrowUpIcon} from '@heroicons/react/20/solid';

const mocDataBadges = [
  {
    id: 1,
    icon: {
      src: '/images/pages/profile/moc/pig.png',
      alt: 'Pig',
    },
    info: 'badges 1',
  },
  {
    id: 2,
    icon: {
      src: '/images/pages/profile/moc/like.png',
      alt: 'Like',
    },
    info: 'badges 2',
  },
  {
    id: 3,
    icon: {
      src: '/images/pages/profile/moc/gym.png',
      alt: 'Gym',
    },
    info: 'badges 3',
  },
  {
    id: 4,
    icon: {
      src: '/images/pages/profile/moc/chef.png',
      alt: 'Chef',
    },
    info: 'badges 4',
  },
  {
    id: 5,
    icon: {
      src: '/images/pages/profile/moc/bird.png',
      alt: 'Bird',
    },
    info: 'badges 5',
  },
  {
    id: 6,
    icon: {
      src: '/images/pages/profile/moc/chef.png',
      alt: 'Chef',
    },
    info: 'badges 6',
  },
  {
    id: 7,
    icon: {
      src: '/images/pages/profile/moc/bird.png',
      alt: 'Bird',
    },
    info: 'badges 7',
  },
];

let mocDataContacts = [];

const profileDataBlocks = [
  {
    title: 'Badges',
    icon: {
      src: '/images/pages/profile/badge.png',
      alt: 'Badges',
    },
  },
  {
    title: 'Contacts',
    icon: {
      src: '/images/pages/profile/contacts.png',
      alt: 'Contacts',
    },
  },
  {
    title: 'Activity',
    icon: {
      src: '/images/pages/profile/activity.png',
      alt: 'Activity',
    },
  },
];

export const profileMobileDataBlocks = [
  {
    title: 'Badges',
    icon: {
      src: '/images/pages/profile/badge.png',
      alt: 'Badges',
    },
  },
  {
    title: 'Activity',
    icon: {
      src: '/images/pages/profile/activity.png',
      alt: 'Activity',
    },
  },
];

let mocDataActivity = [];
// const mocDataActivity = [
//   {
//     title: 'Posts',
//     value: 9876,
//   },
//   {
//     title: 'Views',
//     value: 3456,
//   },
//   {
//     title: 'Listens',
//     value: 123,
//   },
// ];

const ProfileDataBlockComponent = ({icon, title}) => (
  <div className="profile-infoV2__header">
    <img src={icon.src} alt={icon.alt} />
    {/* <div>{title}</div> */}
    <h3 className="text-base font-semibold leading-6 text-gray-900">{title}</h3>
  </div>
);

const stats = [
  {
    name: 'Total Subscribers',
    stat: '71,897',
    previousStat: '70,946',
    change: '12%',
    changeType: 'increase',
  },
  {
    name: 'Avg. Open Rate',
    stat: '58.16%',
    previousStat: '56.14%',
    change: '2.02%',
    changeType: 'increase',
  },
  {
    name: 'Avg. Click Rate',
    stat: '24.57%',
    previousStat: '28.62%',
    change: '4.05%',
    changeType: 'decrease',
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const StatsBlockComponent = ({data}) => {
  return (
    <div>
      <dl className="mt-2 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-x md:divide-y-0">
        {data.map(item => (
          <div key={item.title} className="px-3 py-2 sm:p-6">
            <dt className="text-sm font-normal text-gray-900">{item.title}</dt>
            <dd className="flex items-baseline justify-between md:block lg:flex">
              <div className="flex items-baseline text-xl font-semibold">
                {item.value}
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
};

const ProfileMainV2 = () => {
  const {
    getInformationContacts,
    userContactInfo,
    getUsersActivity,
    userActivityInfo,
  } = useProfileApp();
  const {userData} = useBuzzmiAuth();
  const [openFriendsModal, setOpenFriendsModal] = useState(false);
  const [openActivityModal, setOpenActivityModal] = useState(false);
  const [openBadgesModal, setOpenBadgesModal] = useState(false);
  useEffect(() => {
    getInformationContacts(userData?.token);
    getUsersActivity(userData?.token);
  }, [userData]);

  useEffect(() => {
    mocDataContacts = [
      {
        title: 'Following',
        value: userContactInfo?.following,
        link: '/profile/mobile-users/1',
      },
      {
        title: 'Followers',
        value: userContactInfo?.followers,
        link: '/profile/mobile-users/1',
      },
      {
        title: 'Subscribers',
        value: userContactInfo?.friends,
        link: '/profile/mobile-users/1',
      },
    ];
  }, [userContactInfo]);

  useEffect(() => {
    mocDataActivity = [
      {
        title: 'Posts',
        value: userActivityInfo?.posts || 0,
      },
      {
        title: 'Views',
        value: userActivityInfo?.views || 0,
      },
      {
        title: 'Listens',
        value: userActivityInfo?.listens || 0,
      },
    ];
  }, [userActivityInfo]);

  return (
    <>
      <MainLayoutV2 page={'profile'} centerSpace={true}>
        <MainLayoutContentV2
          iscenter={true}
          rightColumnContent={
            <>
              {/* <div className="mt-4" /> */}
              <Box paddingMode="small" onPress={() => setOpenBadgesModal(true)}>
                <ProfileDataBadges data={mocDataBadges} />
              </Box>
              <div className="mt-2" />
              <RankV2 small={true} />

              <div className="mt-2" />
              <Box paddingMode="mid" onPress={() => setOpenFriendsModal(true)}>
                <ProfileDataBlockComponent
                  icon={profileDataBlocks[1].icon}
                  title={profileDataBlocks[1].title}
                />

                <StatsBlockComponent isLinks data={mocDataContacts} />
                <div className="mt-4">
                  <ProfileDataBlockComponent
                    icon={profileDataBlocks[2].icon}
                    title={profileDataBlocks[2].title}
                  />

                  <StatsBlockComponent isLinks data={mocDataActivity} />
                </div>
              </Box>
              <div className="mt-2" />
              {/* <Box paddingMode="mid" onPress={() => setOpenActivityModal(true)}>
                <ProfileDataBlockComponent
                  icon={profileDataBlocks[2].icon}
                  title={profileDataBlocks[2].title}
                />

                <StatsBlockComponent isLinks data={mocDataActivity} />
              </Box> */}
            </>
          }>
          <ResizeComponent
            desktopComponent={<ProfileMainDesktopV2 />}
            mobileComponent={
              <ProfileMainMobileV2
                setOpenFriendsModal={setOpenFriendsModal}
                setOpenActivityModal={setOpenActivityModal}
                setOpenBadgesModal={setOpenBadgesModal}
              />
            }
          />
        </MainLayoutContentV2>
      </MainLayoutV2>
      <FriendsModal open={openFriendsModal} setOpen={setOpenFriendsModal} />
      <ActivityModal open={openActivityModal} setOpen={setOpenActivityModal} />
      <BadgesModal open={openBadgesModal} setOpen={setOpenBadgesModal} />
    </>
  );
};

export default ProfileMainV2;
