import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import ProfileContentListV2 from '../ProfileContentListV2';
import ProfileExclusive from '../profileExclusive/ProfileExclusive';
import ProfileMembers from '../profileMembers/ProfileMembers';
import Loading from 'react-fullscreen-loading';

import { profileSideBarNavAtom } from '../../../../../appState/navState';
import { colors } from '../../../../../res/colors';

const ProfileContentTabsV2 = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [activeSideItem, setActiveSideItem] = useAtom(profileSideBarNavAtom || 'Posts');

  const handleTabSelect = index => {
    setActiveTabIndex(index);
    setActiveSideItem(
      index == 1
        ? 'Reel'
        : index == 2
          ? 'Premium Members'
          : index == 3
            ? 'About'
            : 'Posts',
    );
  };
  useEffect(() => {
    if (activeSideItem == 'Posts') { setActiveTabIndex(0) }
    if (activeSideItem == 'Reel') { setActiveTabIndex(1) }
    if (activeSideItem == 'Premium Members') { setActiveTabIndex(2) }
    if (activeSideItem == 'About') { setActiveTabIndex(3) }
    if (activeSideItem == '') { setActiveSideItem('Posts'), setActiveTabIndex(0) }
  }, [activeSideItem]);

  return (
    (activeSideItem == 'Posts' || activeSideItem == 'Reel' || activeSideItem == 'Premium Members' || activeSideItem == 'About') ?
      <Tabs
        className="profile-section_v2 profile-stick-v2"
        selectedIndex={activeTabIndex}
        onSelect={handleTabSelect}>
        <TabList>
          <Tab style={{ display: 'flex', justifyContent: "center", color: '#e536af', opacity: activeTabIndex == 0 ? 1 : 0.4 }}><img src={"/images/social/grid.png"} className="h-8 w-8" /></Tab>
          <Tab style={{ display: 'flex', justifyContent: "center", color: '#e536af', opacity: activeTabIndex == 1 ? 1 : 0.4 }}><img src={"/images/social/vid.png"} className="h-7 w-7" /></Tab>
          <Tab style={{ display: 'flex', justifyContent: "center", color: '#e536af', opacity: activeTabIndex == 2 ? 1 : 0.4 }}><img src={"/images/social/premium.png"} className="h-7 w-7" /></Tab>
          <Tab style={{ display: 'flex', justifyContent: "center", color: '#e536af', opacity: activeTabIndex == 3 ? 1 : 0.4 }}><img src={"/images/social/exclusive.png"} className="h-7 w-7" /></Tab>
        </TabList>


        {/* <Tab>Exclusive</Tab> */}

        <TabPanel key="posts">
          <ProfileContentListV2 isPosts={true} />
        </TabPanel>
        <TabPanel key="reel">
          <ProfileContentListV2 isPosts={false} />
        </TabPanel>
        <TabPanel key="members">
          <ProfileMembers />
        </TabPanel>
        <TabPanel key="exclusive">
          <ProfileMembers />
        </TabPanel>
      </Tabs>
      :

      <Loading
        loading={activeSideItem == '' ? false : true}
        background="transparent"
        loaderColor={colors.PRIMARY_COLOR}
      />
  );
};

export default ProfileContentTabsV2;
