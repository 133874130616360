import React, {useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import ShareIcon from '../../icons/ShareIcon';
import {openModal} from '../../../../../../store/slices/modalSlice/modalSlice';
import {useProfileApp} from '../../../../../../appState/hooks';
import {useBuzzmiAuth} from '../../../../../../appState/hooks/useBuzzmiAuth/index.web';
import {colors} from '../../../../../../res/colors';
import Loading from 'react-fullscreen-loading';
import SharePostModal from '../../../ProfileMain/componentsV2/SharePostModel';

const ShareReelBtnV2 = ({userName, userAvatar, reelId, reel, isFromPost}) => {
  const [isHovered, setIsHovered] = useState(false);
  const {getDynamicLink, getPostReelDynamicLink, linkLoad} = useProfileApp();
  const {userData} = useBuzzmiAuth();
  const dispatch = useDispatch();
  const [openShareModal, setOpenShareModal] = useState(false);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    if (getDynamicLink) {
      setModalData({
        userName,
        userAvatar,
        userShareDetails: Object.assign(getDynamicLink, {
          thumbnail: reel.thumbnail,
        }),
      });
      setOpenShareModal(true);
      // dispatch(
      //   openModal({
      //     modalName: 'ShareModal',
      //     modalType: 'swipe-pink',
      //     modalProps: {
      //       userName,
      //       userAvatar,
      //       userShareDetails: Object.assign(getDynamicLink, {
      //         thumbnail: reel.thumbnail,
      //       }),
      //     },
      //     modalTitle: 'Share',
      //   }),
      // );
    }
  }, [getDynamicLink]);

  const handleOpenModal = () => {
    getPostReelDynamicLink('reel', reelId, userData?.token);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <Loading
        loading={linkLoad}
        background="transparent"
        loaderColor={colors.PRIMARY_COLOR}
      />
      <button
        className="reels__buttons-list-button"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleOpenModal}>
        <ShareIcon isHovered={isHovered} />
      </button>
      <SharePostModal
        open={openShareModal}
        data={{entity: 'post', id: reelId, ...modalData}}
        setOpen={setOpenShareModal}
      />
    </>
  );
};

export default ShareReelBtnV2;
