import {useMutation} from 'react-query';
import {postShareActivity} from '../../../network/api/app';
import {useBuzzmiAuth} from '../useBuzzmiAuth/index.web';
import {ShowError, ShowSuccess} from '../../../container/HOC/MessageWeb';
import {useToast} from 'react-native-toast-notifications';

export const usePostShareActivity = () => {
  const {userData} = useBuzzmiAuth();
  const toast = useToast();

  const {
    mutate: mutateShareActivity,
    isLoading: isSharePostLoading,
    isError: isSharePostError,
    data: SharePostResponse,
    error: SharePostErrorResponse,
  } = useMutation({
    mutationFn: param => {
      console.log('param?.body');
      console.log(param?.body);
      return postShareActivity(
        Object.assign(param?.body, {
          params: {isAuth: true, token: userData?.token},
          // body: { ...param?.body },
        }),
      );
    },
    onSuccess: data => {
      console.log('success on share ==> ', data);
      ShowSuccess('Activity Shared successfully', toast);
    },
    onError: data => {
      ShowError('Activity Share Failed', toast);
    },
  });
  const ShareActivity = async body => mutateShareActivity({body});

  return {
    SharePostResponse,
    SharePostErrorResponse,
    isSharePostLoading,
    isSharePostError,
    ShareActivity,
  };
};
