import React, {useEffect, useState, useRef, Fragment} from 'react';

import {Dialog, Transition} from '@headlessui/react';
import {XMarkIcon} from '@heroicons/react/24/outline';
import HubCreateTaskv2 from '../CreateTaskv2/index.web';
import ModalBackdrop from '../../../../components/layouts/mainLayout/componentsV2/ModalBackDrop';
import HubTaskInfov2 from '../HubTaskDetailsv2/index.web';
import Strings from '../../../../string_key/Strings.web';
import FormToggleDropdown from '../../../../components/reusable/assets/dropdown/formToggleDropdown/FormToggleDropdown.jsx';
import HubRepeat from '../Items/form/hubRepeat/HubRepeat';
import moment from 'moment';
import HubTaskList from '../Items/form/checkList/items/HubTaskList';
import MultiBox from '../../../../components/reusable/cards/multiBox/MultiBox.jsx';
import CheckTags from '../Items/form/checkTags/CheckTags';
import PictureUploaderList from '../../../../components/reusable/uploader/pictureUploader/PictureUploaderList';
import HubReminder from '../Items/form/hubReminder/HubReminder';
import SpecificTime from '../Items/specificTime/SpecificTime';
import RadioMode from '../Items/form/radioMode/RadioMode';
import Form from '../../../../components/reusable/form/Form.jsx';
import SetGoal from '../Items/form/setGoal/SetGoal';
import HubMedia from '../Items/hubMedia/HubMedia';
import {isImage, isVideo} from '../../../../types/media';

let error = '';
const when = [
  {title: 'Morning', value: 'Morning'},
  {title: 'Afternoon', value: 'Afternoon'},
  {title: 'Evening', value: 'Evening'},
  {title: 'Any time', value: 'Any time'},
];

const PreviewActivityModal = ({
  setValue,
  values,
  activityModal,
  showActivityModal,
  item,
}) => {
  const [showSpecificTime, setShowSpecificTime] = useState(false);

  useEffect(() => {
    console.log('item');
    console.log(item);
  }, [item]);

  const handleShowSpecificTime = () =>
    setShowSpecificTime(prev => {
      if (prev) {
        setValue('specificTime', undefined);
        setValue('timing', values?.timing);
      }
      return !showSpecificTime;
    });

  return (
    <Transition.Root show={activityModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={showActivityModal}>
        <ModalBackdrop />
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full">
                <Dialog.Panel
                  className="pointer-events-auto w-screen max-w-md"
                  style={{maxWidth: 640}}>
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="p-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900"></Dialog.Title>

                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-pink-500"
                            onClick={() => showActivityModal(() => false)}>
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="pt-4 px-4 py-4 pointer-events-none">
                      <>
                        <div className="description__mobile mb-3">
                          <div className="">
                            {isImage(item?.image) && (
                              <img
                                src={item?.image}
                                alt={item?.image?.alt}
                                className="w-10 h-10 sm:w-20 sm:h-20 md:w-24 md:h-24 lg:w-32 lg:h-32 xl:w-32 xl:h-32  rounded-md"
                              />
                            )}

                            {isVideo(item?.image) && (
                              <video
                                src={item?.image}
                                alt={item?.image?.alt}
                                className="w-10 h-10 sm:w-20 sm:h-20 md:w-24 md:h-24 lg:w-32 lg:h-32 xl:w-32 xl:h-32 rounded-md"
                              />
                            )}
                          </div>

                          <div
                            className="description__column-m"
                            style={{flexGrow: 1}}>
                            <>
                              <div className="description__title-m">
                                {item?.title}
                              </div>
                              <div className="description__text-m">
                                <div>{item?.description}</div>
                              </div>
                            </>
                          </div>
                        </div>

                        <HubMedia
                          galleryList={item?.descriptionMedia?.values}
                          videoSrcLink={item?.descriptionVideo}
                          audioSrcLink={item?.descriptionAudio}
                        />

                        <Form
                          id="task-form"
                          error={error}
                          formClassName="hub-form-wrapper">
                          <MultiBox
                            setValue={setValue}
                            icon="/images/hub/form/calendar.png"
                            defaultValue={values?.timing || 'anyTime'}
                            title={Strings.when}
                            name="timing">
                            <RadioMode
                              disabled={showSpecificTime}
                              setValue={setValue}
                              value={values?.timing}
                              name="timing"
                              variants={when}
                              defaultValue="anyTime"
                            />
                            <SpecificTime
                              values={values?.specificTime}
                              showSpecificTime={showSpecificTime}
                              handleShowSpecificTime={handleShowSpecificTime}
                              setValues={value =>
                                setValue('specificTime', value)
                              }
                            />
                            {showSpecificTime && (
                              <>
                                <div style={{marginTop: 15}} />
                                <HubReminder
                                  values={
                                    values?.specificTime || {
                                      hours: 12,
                                      minutes: '00',
                                      ampm: 'AM',
                                    }
                                  }
                                  setValues={value =>
                                    setValue('specificTime', value)
                                  }
                                />
                              </>
                            )}
                          </MultiBox>
                          <FormToggleDropdown
                            setValue={setValue}
                            defaultValue={values?.repeat}
                            icon="/images/hub/form/repeat.png"
                            title={Strings.repeat}
                            name="repeat"
                            text="set a cycle for your plan"
                            values={
                              values?.repeat || {
                                type: 'Week', // day/week/month
                                every: '01', // will be required for day/week category only
                                value: null, // will contain all the dates (array) selected from the calendar
                                endDate: moment().add(1, 'days').format('LL'),
                              }
                            }>
                            <HubRepeat
                              values={
                                values?.repeat || {
                                  type: 'Week',
                                  every: '01',
                                  value: null,
                                  endDate: moment().add(1, 'days').format('LL'),
                                }
                              }
                              setValues={value => setValue('repeat', value)}
                            />
                          </FormToggleDropdown>
                          <FormToggleDropdown
                            defaultValue={values?.goal}
                            setValue={setValue}
                            icon="/images/hub/form/goal.png"
                            title={Strings.set_a_goal}
                            values={values?.goal || {type: 'mins', number: 0}}
                            name="goal">
                            <SetGoal
                              values={values?.goal || {type: 'mins', number: 0}}
                              setValues={value => setValue('goal', value)}
                            />
                          </FormToggleDropdown>
                          <FormToggleDropdown
                            setValue={setValue}
                            icon="/images/hub/form/clock.png"
                            title={Strings.reminder}
                            name="reminder"
                            values={
                              values?.reminder || {
                                hours: 12,
                                minutes: '00',
                                ampm: 'AM',
                              }
                            }
                            defaultValue={values?.reminder}>
                            <HubReminder
                              values={
                                values?.reminder || {
                                  hours: 12,
                                  minutes: '00',
                                  ampm: 'AM',
                                }
                              }
                              setValues={value => setValue('reminder', value)}
                            />
                          </FormToggleDropdown>
                          <FormToggleDropdown
                            setValue={setValue}
                            icon="/images/hub/form/check-list.png"
                            title={Strings.checklist}
                            defaultValue={values?.tasks}
                            name="tasks">
                            <HubTaskList
                              value={values?.tasks}
                              setValues={value => setValue('tasks', value)}
                            />
                          </FormToggleDropdown>
                          <FormToggleDropdown
                            setValue={setValue}
                            icon="/images/hub/form/notes.png"
                            title={Strings.note}
                            defaultValue={values?.note}
                            name="note">
                            <input
                              type="text"
                              className="input input_simple-text"
                              placeholder={Strings.add_your_note_here}
                              value={values?.note}
                              // onChange={e => setValue('note', e.target.value)}
                            />
                          </FormToggleDropdown>
                          <FormToggleDropdown
                            setValue={setValue}
                            icon="/images/hub/form/photo.png"
                            title={Strings.add_photos_of_your_progress}
                            name="photos"
                            defaultValue={values?.images}>
                            <PictureUploaderList
                              classNames="picture-uploader__wrapper_mod"
                              setValue={setValue}
                              defaultValues={values?.images}
                              picture_type={'progress_photos'}
                              isBig
                              isProgress={true}
                            />
                          </FormToggleDropdown>
                          {/* {console.log('values?.tags', values)} */}
                          <MultiBox
                            setValue={setValue}
                            icon="/images/hub/form/hash.png"
                            title={Strings.tags}
                            name="tags">
                            <CheckTags
                              value={values?.tags || []}
                              setValue={setValue}
                              valueName="tags"
                              hideBtn
                            />
                          </MultiBox>
                        </Form>
                      </>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default PreviewActivityModal;
