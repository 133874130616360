import React, { useContext, useState, useEffect } from 'react';
import {
  TouchableOpacity,
  View,
  FlatList,
  Image,
  ActivityIndicator,
  ScrollView,
  TextInput,
  Dimensions,
} from 'react-native';
import { Spacer } from '../../../../res/spacer';
// style themes and components
import { GlobalFlex } from '../../../../res/globalStyles';
import { BackHeader } from '../../../../components/BackHeader';
import { POST_MULTIPART, POST } from '../../../../api_helper/ApiServices';
import { COMMUNITY_POST, POST_FILES } from '../../../../api_helper/Api';
import { styles, Text } from './style';
import { colors } from '../../../../res/colors';
import {
  heightPercentageToDP as hp,
  widthPercentageToDP as wp,
} from 'react-native-responsive-screen';
import DeviceInfo from 'react-native-device-info';
import { saveData } from '../../../../res/asyncStorageHelper';
import { getData } from '../../../../res/asyncStorageHelper.web';
import { useToast } from 'react-native-toast-notifications';
import { ShowError, ShowSuccess } from '../../../HOC/MessageWeb';
import * as Icons from 'react-native-heroicons/solid';
import { useBuzzmiAuth } from '../../../../appState/hooks';
import { useAtom } from 'jotai';
import { _userDetailsAtom } from '../../../../appState/userState';
import { useEncryptedAtom } from "../../../../appState/hooks/useEncryptedAtom";

let f_array = [];
let file_data = [];

const ScreenWidth = Dimensions.get('window').width;

const CreatePost = props => {
  const [state, setState] = useState({ thumbnail: null, loading: true });
  const [inputLine, setInputLine] = useState(null);

  const [fileData, setFileData] = useState(null);

  const [filesArray, setFilesArray] = useState([]);
  const [sRandom, setRandom] = useState(Math.random());

  const [loading, setLoading] = useState(false);
  const { userData } = useBuzzmiAuth();
  const [userDetails, setUserDetails] = useEncryptedAtom(_userDetailsAtom);

  const toast = useToast();

  const getFileName = url => {
    return url.substring(url.lastIndexOf('/') + 1);
  };

  const onPostFile = async file_param => {
    setLoading(true);
    const fd = new FormData();
    fd.append('file', file_param);
    POST_MULTIPART(POST_FILES, userDetails.token, fd, data => {
      if (data) {
        var fileSuccess = new Promise((resolve, reject) => {
          file_data.push(
            Object.assign(data, {
              src: data.url,
              meta: {
                name: getFileName(data.url),
                formatType: data?.meta.formatType,
              },
            }),
          );
          resolve();
        });
        fileSuccess.then(() => {
          setLoading(false);
          setFilesArray(file_data);
          setFileData(file_data);
        });
      }
    });

  };

  const onCreatePost = async () => {
    if (inputLine == null || inputLine == '') {
      ShowError('Please enter content', toast);
    } else {
      setLoading(true);
      let postData = {
        content: inputLine,
        images: fileData,
      };
      console.log(postData);
      const uniqueID = await DeviceInfo.getUniqueId();

      // getData('userDetails', userEData => {
      POST(
        COMMUNITY_POST,
        true,
        userDetails.token,
        uniqueID,
        postData,
        async data => {
          setLoading(false);
          if (data) {
            file_data = [];
            f_array = [];
            setUserDetails({ ...userDetails, tribeUserId: data.user.id });
            // saveData('userDetails', Object.assign(userEData, { tribeUserId: data.user.id }))
            userData.tribeUserId = data.user.id;
            props.navigation.goBack();
            setTimeout(() => {
              ShowSuccess('Post added Successfully', toast);
            }, 350);
          }
        },
      );
      // },
      // );
    }
  };

  const onRemove = index => {
    var removeFileData = file_data;
    removeFileData.splice(index, 1);
    setFileData(removeFileData);
    var removeImage = filesArray;
    removeImage.splice(index, 1);
    setFilesArray(removeImage);
    setRandom(Math.random());
  };
  const is_Image = type => {
    let v_type = type;
    return v_type.split('/')[0] == 'video' ? 2 : 1;
  };

  const onChangeFile = event => {
    onPostFile(event.target.files[0]);
  };

  return (
    <GlobalFlex>
      <BackHeader
        textColor={{ color: colors.BLACK }}
        onNextPress={() => {
          onCreatePost();
        }}
        rightText={'Save'}
        isRightText={true}
        onBackPress={() => {
          props.navigation.goBack();
        }}
        is_center_text
        title={'Create a Post'}
      />
      <Spacer space={hp(3)} />
      <View style={{ flex: 1 }}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <View
            style={{
              flexDirection: 'row',
              width: ScreenWidth > 670 ? hp(70) : wp(90),
              alignSelf: 'center',
            }}>
            <TextInput
              style={[
                styles.input,
                {
                  borderRadius: hp(2),
                  padding: hp(2),
                  borderWidth: wp(0.1),
                  borderColor: colors.BORDER_COLOR,
                },
              ]}
              value={inputLine}
              onChangeText={text => setInputLine(text)}
              multiline={true}
              textAlignVertical={'top'}
              underlineColorAndroid="transparent"
              placeholder={"What's on your mind"}
            />
          </View>
          <Spacer space={hp(1)} />
          <TouchableOpacity
            onPress={() => {
              document.getElementById('selectedFile').click();
            }}
            style={{
              width: ScreenWidth > 670 ? hp(70) : wp(90),
              alignSelf: 'center',
              padding: hp(2),
              backgroundColor: colors.PRIMARY_COLOR,
            }}>
            <Text style={{ color: colors.WHITE, fontSize: hp(2.5) }}>
              Choose File
            </Text>
          </TouchableOpacity>

          <View style={{ alignSelf: 'center', display: 'none' }}>
            <input
              type="file"
              onChange={onChangeFile}
              accept="image/*"
              id="selectedFile"
            />
          </View>

          <Spacer space={hp(1)} />

          {filesArray.length > 0 && (
            <FlatList
              data={filesArray}
              renderItem={({ item, index }) => (
                <View key={index} style={{ margin: hp(2) }}>
                  <TouchableOpacity
                    onPress={() =>
                      props.navigation.navigate('PostViewer', {
                        file_url: item.uri,
                        fileType: 'image',
                      })
                    }>
                    <Image
                      style={[
                        styles.imageThumbnail,
                        { width: hp(22), height: hp(22) },
                      ]}
                      source={{ uri: item.uri || item.url }}
                      resizeMode={'cover'}
                    />
                    <TouchableOpacity
                      style={{
                        position: 'absolute',
                        right: 5,
                        top: 5,
                        zIndex: 1,
                      }}
                      onPress={() => {
                        onRemove(index);
                      }}>
                      <Icons.XCircleIcon
                        color={colors.HAWKES_BLUE}
                        size={hp(4)}
                      />
                    </TouchableOpacity>
                  </TouchableOpacity>
                </View>
              )}
              numColumns={3}
              keyExtractor={(item, index) => index.toString()}
              contentContainerStyle={{ width: hp(80), alignSelf: 'center' }}
            />
          )}
        </ScrollView>

        {loading && (
          <ActivityIndicator
            style={{
              position: 'absolute',
              justifyContent: 'center',
              alignSelf: 'center',
              width: wp(100),
              height: hp(60),
            }}
            size={40}
            color={colors.PRIMARY_COLOR}
          />
        )}
      </View>
    </GlobalFlex>
  );
};

export default CreatePost;
