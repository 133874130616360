// noinspection JSUnresolvedReference
import React, {useState} from 'react';
import Rank from '../Items/rank/Rank.jsx';
import LinkTabs from '../../../../components/reusable/assets/linkTabs/LinkTabs.jsx';
import Calendar from '../../../../components/reusable/calendar/Calendar.jsx';
import FilterList from '../Items/filterList/FilterList.jsx';
import MainLayoutRightColumn from '../../../../components/layouts/mainLayout/mainLayoutContent/mainLayoutRightColumn/MainLayoutRightColumn.jsx';
import AdditionalInformation from '../../../../components/reusable/assets/additionalInformation/AdditionalInformation.jsx';
import UpdateInfo from '../../../../components/reusable/updateInfo/UpdateInfo.jsx';
import PeriodList from '../../../../components/reusable/periodList/PeriodList.jsx';
import MainLayout from '../../../../components/layouts/mainLayout/MainLayout.jsx';
import Strings from '../../../../string_key/Strings.web.js';
import {useBuzzmiAuth} from '../../../../appState/hooks/useBuzzmiAuth/index.web.js';
// import { tabsLast } from "../../../../mock-data/hub/hub.js"

const mockItems = [
  {
    title: 'Any time of the day',
    list: [
      {
        id: 'dwqdsads',
        href: '/hub/add-activities',
        image:
          'https://onesay-backend-dev.s3.amazonaws.com/398fe95b-cca1-4cdb-b5ac-f9802fc3df3b-gym.png',
        badges: [],
        title: 'Strength Traning',
        description:
          'Place your feet and hips apart, and grab a pair of dumbbells. Bend your knees slightly, and hinge at the',
      },
      {
        id: 'kuykmt',
        href: '/hub/add-activities',
        image:
          'https://onesay-backend-dev.s3.amazonaws.com/4bc1d577-1b3d-43ba-ad9b-86c29780c99f-drink.png',
        badges: [],
        title: 'Drink your water',
        description:
          'Drink up! Increasing your daily water intake is vital as you to stay healthy. Dehydration can cause fatigue, indigestion, a...',
      },
      {
        id: 'fwebtrtt',
        href: '/hub/add-activities',
        image:
          'https://onesay-backend-dev.s3.amazonaws.com/4bc1d577-1b3d-43ba-ad9b-86c29780c99f-drink.png',
        badges: [],
        title: 'Drink your water',
        description:
          'Drink up! Increasing your daily water intake is vital as you to stay healthy. Dehydration can cause fatigue, indigestion, a...',
      },
    ],
  },
  {
    title: 'Any time of the day',
    list: [
      {
        id: 'dwqdsads',
        href: '/hub/add-activities',
        image:
          'https://onesay-backend-dev.s3.amazonaws.com/398fe95b-cca1-4cdb-b5ac-f9802fc3df3b-gym.png',
        badges: [],
        title: 'Strength Traning',
        description:
          'Place your feet and hips apart, and grab a pair of dumbbells. Bend your knees slightly, and hinge at the',
      },
      {
        id: 'kuykmt',
        href: '/hub/add-activities',
        image:
          'https://onesay-backend-dev.s3.amazonaws.com/4bc1d577-1b3d-43ba-ad9b-86c29780c99f-drink.png',
        badges: [],
        title: 'Drink your water',
        description:
          'Drink up! Increasing your daily water intake is vital as you to stay healthy. Dehydration can cause fatigue, indigestion, a...',
      },
      {
        id: 'fwebtrtt',
        href: '/hub/add-activities',
        image:
          'https://onesay-backend-dev.s3.amazonaws.com/4bc1d577-1b3d-43ba-ad9b-86c29780c99f-drink.png',
        badges: [],
        title: 'Drink your water',
        description:
          'Drink up! Increasing your daily water intake is vital as you to stay healthy. Dehydration can cause fatigue, indigestion, a...',
      },
    ],
  },
  {
    title: 'Any time of the day',
    list: [
      {
        id: 'dwqdsads',
        href: '/hub/add-activities',
        image:
          'https://onesay-backend-dev.s3.amazonaws.com/398fe95b-cca1-4cdb-b5ac-f9802fc3df3b-gym.png',
        badges: [],
        title: 'Strength Traning',
        description:
          'Place your feet and hips apart, and grab a pair of dumbbells. Bend your knees slightly, and hinge at the',
      },
      {
        id: 'kuykmt',
        href: '/hub/add-activities',
        image:
          'https://onesay-backend-dev.s3.amazonaws.com/4bc1d577-1b3d-43ba-ad9b-86c29780c99f-drink.png',
        badges: [],
        title: 'Drink your water',
        description:
          'Drink up! Increasing your daily water intake is vital as you to stay healthy. Dehydration can cause fatigue, indigestion, a...',
      },
      {
        id: 'fwebtrtt',
        href: '/hub/add-activities',
        image:
          'https://onesay-backend-dev.s3.amazonaws.com/4bc1d577-1b3d-43ba-ad9b-86c29780c99f-drink.png',
        badges: [],
        title: 'Drink your water',
        description:
          'Drink up! Increasing your daily water intake is vital as you to stay healthy. Dehydration can cause fatigue, indigestion, a...',
      },
    ],
  },
];

const periodList = [
  {
    id: 0,
    text: 'Last week',
  },
  {
    id: 1,
    text: 'This week',
  },
  {
    id: 2,
    text: 'Last Month',
  },
  {
    id: 3,
    text: 'This month',
  },
  {
    id: 4,
    text: 'Year',
  },
];

const HubStats = () => {
  const [calendarValue, setCalendarValue] = useState('2023-05-01');
  const [periodType, setPeriodType] = useState(null);
  const {language} = useBuzzmiAuth();

  const tabsLast = [
    {
      href: '/hub/tasks',
      title: Strings.tasks,
      id: 0,
    },
    {
      href: '/hub/stats',
      title: Strings.stats,
      id: 1,
    },
    {
      href: '/hub/v2/leaderboard',
      title: Strings.leaderboard,
      id: 2,
    },
  ];

  return (
    <>
      <MainLayout>
        <div className="main-layout__row">
          <div className="main-layout__mid">
            <div className="mb-7">
              <Rank withAvatar />
            </div>
            <LinkTabs list={tabsLast} activeTabId={1} />
            <UpdateInfo
              text="Points last synced on Today, 01:30 pm"
              onBtnClick={() => null}
            />
            <PeriodList
              ligntMode
              items={periodList}
              onClick={periodValue => setPeriodType(periodValue)}
              value={periodType}
            />
            <div className="box box_big mb-3">
              <Calendar
                onChange={setCalendarValue}
                value={calendarValue}
                period="week"
                noIndent
              />
            </div>
            <FilterList data={mockItems} />
          </div>

          <MainLayoutRightColumn>
            <AdditionalInformation />
          </MainLayoutRightColumn>
        </div>
      </MainLayout>
    </>
  );
};

export default HubStats;
