import React from 'react';
import Button from './Button.jsx';
import {Link} from 'react-router-dom';

const PrimaryButton = ({link, children, className, ...props}) => {
  // console.log('isValid', props?.isValid);
  return link ? (
    <Link
      to={link}
      className={
        'gap-x-1.5 hover:rounded-full rounded-md bg-pink-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600 ' +
        className
      }
      {...props}>
      {children}
    </Link>
  ) : (
    // <Button className={'_fw button_primary ' + className} {...props}>
    <Button
      style={{
        width: '100%',

        // maxWidth: 420
      }}
      className={
        '_fw button_primary gap-x-1.5 hover:rounded-full  px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600 ' +
        className
      }
      {...props}>
      {children}
    </Button>
  );
};

export default PrimaryButton;
