import React, {useEffect, useState, Fragment, useRef} from 'react';
import {getData} from '../../../../res/asyncStorageHelper';
import {
  useBuzzmiAuth,
  useHubApp,
  useProfileApp,
} from '../../../../appState/hooks';
import AdditionalInformation from '../../../../components/reusable/assets/additionalInformation/AdditionalInformation.jsx';
import {useEventEmitter} from '../../../../hooks/useEventEmitter';
import {useLinkTo, useNavigation} from '@react-navigation/native';
import {Link} from '@react-navigation/native';
import Loading from 'react-fullscreen-loading';

import Strings from '../../../../string_key/Strings.web';
import CircleChart from '../../../../components/reusable/assets/charts/circleChart/CircleChart';
import {useLeaderBoard} from '../../../../appState/hooks/useLeaderBoard';
import {
  openModal,
  closeModal,
} from '../../../../store/slices/modalSlice/modalSlice';
import {removeData} from '../../../../res/asyncStorageHelper.web';
import {useDispatch} from 'react-redux';
import MainLayoutWithAspectSide from '../../../../components/layouts/mainLayout/MainLayoutWithAspectSide';
import InfoCardList from '../../../../components/reusable/cards/infoCardv2/InfoCardList';
import PlanActivityModal from '../../Hub/PlanActivityModal';
import {EventRegister} from 'react-native-event-listeners';
import {colors} from '../../../../res/colors';
import PreviewActivityModal from '../../Hub/PreviewActivityModal';
import Dropdown from '../../../../components/reusable/assets/dropdown/Dropdown';
import HubNextLevelInfo from '../hubMainMobile/items/hubNextLevelInfo/HubNextLevelInfo';
import Box from '../../../../components/reusable/cards/box/Box';
import PlanActStatsModal from '../../Hub/PlanActStatsModal/index.js';
import {
  getCurrentMonthDates,
  getCurrentWeekDates,
  getLastMonthDates,
  getNextMonthDates,
} from '../../../../utils/utilitiesFunctions.js';
import {useBookMarkLists} from '../../../../appState/hooks/useBookMarkLists';
import {useUserBadges} from '../../../../appState/hooks/useUserBadges';
import {useBuzzmiActivities} from '../../../../appState/hooks/useBuzzmiActivities/index.js';
import {
  ArrowLongRightIcon,
  ArrowRightIcon,
  ViewfinderCircleIcon,
} from '@heroicons/react/24/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
let setValue;
let values;
const Home = props => {
  const cancelButtonRef = useRef(null);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const {userData, logout} = useBuzzmiAuth();
  const {rankInfo} = useHubApp();
  const [activityModal, showActivityModal] = useState(false);
  const [activityStatModal, showActivityStatModal] = useState(false);

  const [previewTaskModal, showPreviewTaskModal] = useState(false);
  const {replace} = useNavigation();

  const [currActivity, setCurrActivity] = useState({});

  const {useLeaderBoardList} = useLeaderBoard();
  const {
    getWalletData,
    getRankInfoDetail,
    getNextRankInfoDetail,
    getCoverImageList,
    getPlannedActivities,
    plannedActivities,
    getTagsList,
    getTodayTasks,
    userTodayPlanTasks,
    getPlannedActivitiesStat,
  } = useHubApp();
  const linkTo = useLinkTo();
  const [open, setOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const dispatch = useDispatch();

  const {
    postFollowUser,
    suggestedUsers,
    getSuggestedUsers,
    getUserPostLists,
    getBuzzmiUserPostLists,
    getUserReelLists,
    getFollowingPostLists,
  } = useProfileApp();
  const {getAllBookMarkList} = useBookMarkLists();
  const {getOnUserBadges} = useUserBadges();

  // useEffect(() => {
  //   console.log('userTodayPlanTasks');
  //   console.log(userTodayPlanTasks);
  // }, [userTodayPlanTasks]);

  useEffect(() => {
    if (userData) {
      console.log(userData);
      getOnUserBadges(userData?.id, userData?.token);
      getTagsList(userData?.token);

      getTodayTasks(userData?.token, setLoadingList, {
        page: '1',
        limit: '10',
      });
      getWalletData(userData?.token);
      getRankInfoDetail(userData?.id, userData?.token);
      getNextRankInfoDetail(userData?.id, userData?.token);

      getFollowingPostLists(userData?.token, {page: 1, limit: 20});
      getBuzzmiUserPostLists(userData?.token, {page: 1, limit: 20});
      getUserPostLists(userData?.token, userData?.id, {page: 1, limit: 20});
      getUserReelLists(userData?.token, userData?.id, {page: 1, limit: 20});
      getSuggestedUsers(userData?.token, {page: 1, limit: 10});
      getAllBookMarkList(userData?.token);
    }
  }, [userData]);

  const handleLogout = () => {
    dispatch(
      openModal({
        modalName: 'renderAlert',
        modalType: 'alert',

        modalProps: {
          nameAlert: 'LogoutAlert',
          propsAlert: {
            logoutCallback: _logout,
            closeModal: () => dispatch(closeModal()),
          },
        },
      }),
    );
    console.log('logged ouet');
  };

  const _logout = () => {
    dispatch(closeModal());
    setTimeout(() => {
      removeData('userDetails');
      removeData('company_id');
      logout();
      setTimeout(() => {
        window.location.reload();
        replace('Auth');
      }, 150);
    }, 500);
  };
  const {getBuzzmiActivitiesList} = useBuzzmiActivities();

  useEventEmitter('refresh-planActivities', data => {
    const par = {
      page: data?.page ? data?.page : '1',
      limit: data?.limit ? data?.limit : '10',
    };

    if (data?.title) {
      Object.assign(par, {title: data?.title});
    }
    if (data?.tag) {
      Object.assign(par, {tag: data?.tag});
    }
    if (data?.startDate) {
      Object.assign(par, {startDate: data?.startDate});
    }
    if (data?.endDate) {
      Object.assign(par, {endDate: data?.endDate});
    }
    if (data?.status) {
      Object.assign(par, {status: data?.status});
    }

    getPlannedActivities(
      userData?.token,
      data?.setLoading ? data.setLoading : () => {},
      par,
    );
    getPlannedActivitiesStat(userData?.token, () => {}, {
      startDate: getCurrentMonthDates().startDate,
      endDate: getNextMonthDates().endDate,
    });
    getTodayTasks(userData?.token, setLoadingList, {
      page: '1',
      limit: '10',
    });
    getBuzzmiActivitiesList(userData?.token, () => {}, {
      page: '1',
      limit: '10',
    });
  });

  useEffect(() => {
    if (typeof userData !== 'undefined' && userData !== null) {
      if (userData?.membership === null) {
        getData('isMembershipSkipped', isMembershipSkipped => {
          if (
            typeof isMembershipSkipped !== 'boolean' ||
            !isMembershipSkipped
          ) {
            props.navigation.navigate('Membership');
          }
        });
      }
    }
  }, [userData]);

  useEventEmitter('openPlanActivityModal', e => {
    setCurrActivity(e);
    showActivityModal(() => true);
  });
  useEventEmitter('openPlanActStatModal', e => {
    setCurrActivity(e);
    showActivityStatModal(() => true);
  });

  useEventEmitter('openPreviewActivityModal', e => {
    setCurrActivity(e.item);
    setValue = e.setValue;
    values = e.values;
    showPreviewTaskModal(() => true);
  });

  return (
    <>
      <Loading
        loading={loadingList}
        background="transparent"
        loaderColor={colors.PRIMARY_COLOR}
      />
      <MainLayoutWithAspectSide showTitleButton={true} isHome={true}>
        <div className="lg:px-15 py-1 pb-0 px-0">
          <div className="flex flex-wrap items-center justify-between sm:flex-nowrap pb-3">
            <div>
              <h3 className="sm:text-xl text-md font-semibold leading-6 text-gray-900">
                My plan for today
              </h3>
            </div>
            <div className="flex-shrink-0">
              <Link to={'/hub/v2/activities'}>
                <button
                  onClick={() => {
                    global.assignMenu = 'Hub';
                    global.activeMenu = '/hub/v2/tasks';
                  }}
                  type="button"
                  className="relative inline-flex items-center sm:bg-rose-600 px-3 py-2 rounded-full text-sm sm:font-semibold text-pink-500 shadow-md sm:hover:bg-pink-500 sm:focus-visible:outline sm:focus-visible:outline-2 sm:focus-visible:outline-offset-2 sm:focus-visible:outline-pink-600"
                  style={{backgroundColor: '#fff'}}>
                  Planned Activities <ArrowRightIcon className={'w-4 h-4'} />
                </button>
              </Link>
            </div>
          </div>
        </div>

        <InfoCardList
          infoList={userTodayPlanTasks.slice(0, 3)}
          taskType={'plan'}
          onItemClick={e => EventRegister.emit('openPlanActivityModal', e)}
          loading={loadingList}
          isMainActivity={true}
        />
      </MainLayoutWithAspectSide>
      <PlanActivityModal
        item={currActivity}
        activityModal={activityModal}
        showActivityModal={showActivityModal}
      />

      <PlanActStatsModal
        item={currActivity}
        activityModal={activityStatModal}
        showActivityModal={showActivityStatModal}
      />

      <PreviewActivityModal
        item={currActivity}
        setValue={setValue}
        values={values}
        activityModal={previewTaskModal}
        showActivityModal={showPreviewTaskModal}
      />
    </>
  );
};
export default Home;
