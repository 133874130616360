import {getStatByTaskId} from '../../../network/api/app';

import {useState} from 'react';

export const useStatTaskById = () => {
  const [taskStat, setTaskStat] = useState(null);

  const getMyTaskStat = async (token, setLoading, params) => {
    setLoading(true);
    try {
      console.log('params stat lsis parsm ===> ', token, params);
      const response = await getStatByTaskId(params)({token});
      console.log('response of task stat', response);
      setTaskStat(response);

      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log('error => ', e.message);
    }
  };

  return {
    taskStat,

    getMyTaskStat,
  };
};
