import React, { useEffect, useState } from 'react';
import styles from './Avatar.module.scss';
import propTypes from 'prop-types';
import { useBuzzmiAuth } from '../../../../appState/hooks';
import { useProfileInfo } from '../../../../appState/hooks/useProfileInfo';

const VisitorAvatar = ({ mode = '', isOtherProfile = false }) => {
    const [avatarMode, setAvatarMode] = useState('');
    const [avatar, setAvatar] = useState(
        'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541',
    );
    const { userData } = useBuzzmiAuth();
    const { profileInfo } = useProfileInfo();

    useEffect(() => {
        switch (mode) {
            case 'small':
                setAvatarMode(styles['avatar__inner']);
                break;
            case 'medium':
                setAvatarMode(styles['avatar__mid']);
                break;
            case 'border':
                setAvatarMode(styles['avatar__with-border']);
                break;
            default:
                setAvatarMode('');
        }
    }, [mode]);

    const getData = async () => {
        try {
            const data = '/images/moc/avatar-big.png';
            setAvatar(data);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className={`${styles.avatar} ${avatarMode}`}>
            {isOtherProfile ?
                <img
                    src={profileInfo?.avatar ? profileInfo?.avatar : avatar}
                    alt="avatar"
                    className={styles['avatar__img']}
                />
                :
                <img
                    src={userData?.avatar ? userData?.avatar : avatar}
                    alt="avatar"
                    className={styles['avatar__img']}
                />
            }
        </div>
    );
};

VisitorAvatar.propTypes = {
    mode: propTypes.string,
};
export default VisitorAvatar;
