import React, { useEffect, useState } from 'react';
import AdditionalInformation from '../../../../components/reusable/assets/additionalInformation/AdditionalInformation.jsx';
import MainLayoutContent from '../../../../components/layouts/mainLayout/mainLayoutContent/MainLayoutContent.jsx';
import Rank from '../Items/rank/Rank.jsx';
import LinkTabs from '../../../../components/reusable/assets/linkTabs/LinkTabs.jsx';
import Board from '../../../../components/reusable/board/Board.jsx';
import MainLayout from '../../../../components/layouts/mainLayout/MainLayout.jsx';
import Loading from 'react-fullscreen-loading';
// mock data
import { useBuzzmiAuth } from '../../../../appState/hooks/useBuzzmiAuth/index.web.js';
import { colors } from '../../../../res/colors.js';
import Strings from '../../../../string_key/Strings.web.js';
import { useLeaderBoard } from '../../../../appState/hooks/useLeaderBoard';
import MainLayoutWithAspectSide from '../../../../components/layouts/mainLayout/MainLayoutWithAspectSide.jsx';

const HubLeaderBoardv2 = () => {
  const { userData } = useBuzzmiAuth();
  const { useLeaderBoardList } = useLeaderBoard();
  const { data: leaderBoardata, isLoading } = useLeaderBoardList(
    userData && userData?.token,
  );

  const tabsLast = [
    {
      href: '/hub/tasks',
      title: Strings.tasks,
      id: 0,
    },
    {
      href: '/hub/tasks:1',
      title: Strings.stats,
      id: 1,
    },
    {
      href: '/hub/leaderboard',
      title: Strings.leaderboard,
      id: 2,
    },
  ];

  return (
    <MainLayoutWithAspectSide showTitleButton={false}>
      <Loading
        loading={isLoading}
        background="transparent"
        loaderColor={colors.PRIMARY_COLOR}
      />

      <>
      <div className="px-6">
        <form>
          <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
          <div class="relative">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
              </svg>
            </div>
            <input type="search" id="default-search" class="block w-full px-8 py-2  pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search..." required />
          </div>
        </form>
      </div>

        <div className="lg:px-15 py-5 pb-2 px-3">
          <div className="grid grid-cols-1 text-sm sm:grid-cols-12 sm:grid-rows-1 sm:gap-x-6 md:gap-x-8 lg:gap-x-8">

            <div className="sm:col-span-4 md:col-span-5 md:row-span-2 md:row-end-2 self-center">
              <div className="aspect-h-2 overflow-hidden rounded-lg">
                <div className="hub__cup">
                  <img src="/images/assets/cup.png" alt="cup -img" />
                </div>
              </div>
            </div>
            <div className="sm:col-span-12 md:col-span-7">
              <Board items={leaderBoardata?.listOfLeaderboards} />
            </div>
          </div>
        </div>
      </>
    </MainLayoutWithAspectSide>
  );
};

export default HubLeaderBoardv2;
