// noinspection JSUnresolvedReference

import React, { useContext, useState } from 'react';
import { TouchableOpacity, View, FlatList, ActivityIndicator } from 'react-native';
import { Spacer } from '../../../../res/spacer';
import { GlobalFlex } from '../../../../res/globalStyles';
import { BackHeader } from '../../../../components/BackHeader';
import { styles, Text } from './style';
import { colors } from '../../../../res/colors';
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from 'react-native-responsive-screen';
import { getrelamContactName } from '../../../../commonAction';

const Notifications = (props) => {

  const [isLoading, setIsLoading] = useState(false);
  const [actionData, setActionData] = useState([]);

  // useEffect(() => {
  //   onGetActionsData();
  //   // console.log(userData)
  // }, [userData]);
  //
  // const onGetActionsData = async () => {
  //   setIsLoading(true);
  //   const uniqueID = await DeviceInfo.getUniqueId();
  //
  //   GET_DATA(GET_ACTIONS + `${userData?.mobile}`, true, userData?.token, uniqueID, (data) => {
  //     data.forEach((async (element) => {
  //       const { name } = await sdk.GroupChannel.getChannel(element?.invitationLink);
  //       // console.log(name.toString())
  //       element.channelname = name.toString();
  //     }));
  //     setTimeout(() => {
  //       setIsLoading(false);
  //       setActionData(data);
  //     }, 1000);
  //   });
  // };


  // const onJoinGroup = async (item) => {
  //   if (item?.channelType == 'group') {
  //     PUT_SENDBIRD(item?.invitationLink, userData?.userId, async (channel) => {
  //       const mainchannel = await sdk.GroupChannel.getChannel(item?.invitationLink);
  //       Alert.alert(item?.channelname,
  //         'Thank you for joining',
  //         [
  //           {
  //             text: 'Okay',
  //             onPress: () => {
  //               setTimeout(() => {
  //                 props.navigation.navigate(Routes.GroupChannel, { serializedChannel: mainchannel.serialize() });
  //               }, 500);
  //             },
  //           },
  //         ],
  //         { cancelable: false });
  //     });
  //   } else {
  //     const params = new sdk.GroupChannelParams();
  //     params.isSuper = false;
  //     params.isPublic = false;
  //     if (currentUser) {params.operatorUserIds = [currentUser.userId];}
  //     params.addUserIds([item?.invitationLink]);
  //     params.name = '';
  //     params.isDistinct = true;
  //     const channel = await sdk.GroupChannel.createChannel(params);
  //     setTimeout(() => {
  //       props.navigation.navigate(Routes.GroupChannel, { serializedChannel: channel.serialize() });
  //     }, 500);
  //   }
  // };


  const renderListItem = ({ item }) => {
    return (
      <TouchableOpacity style={styles.listItemContainer} key={item?.id}>
        <Text style={{ ...styles.listItemLabel, width: wp(70) }}>
          {`${getrelamContactName(item.sender)} has invited you to join ${
            item?.channelType == 'group' ?
              (item?.channelname || '') :
              'Individual Chat'}`
          }
        </Text>
        <TouchableOpacity
          onPress={() => {
            console.log('Under Development!');
            /*onJoinGroup(item);*/
          }}
          style={{
            padding: wp(3),
            borderRadius: wp(4),
            backgroundColor: colors.PRIMARY_COLOR,
            marginRight: wp(4),
        }}>
          <Text style={{ ...styles.listItemLabel, color: colors.WHITE }}>{'Join'}</Text>
        </TouchableOpacity>
      </TouchableOpacity>
    );
  };

  return (
    <GlobalFlex>
      <BackHeader onBackPress={() => { props.navigation.goBack(); }} is_center_text title={'Notifications'} />
      <Spacer space={hp(1)} />
      <View style={{ flex: 1 }}>
        {
          isLoading ?
            <View style={{ flex: 1, justifyContent: 'center' }}>
              <ActivityIndicator size={'large'} color={colors.PRIMARY_COLOR} />
            </View>
            :
            actionData.length > 0 ?
              <FlatList
                style={{ flex: 1 }}
                data={actionData}
                renderItem={renderListItem}
                keyExtractor={item => item.recordID}
              />
              :
              <View style={{ justifyContent: 'center', flex: 1 }}>
                <Text style={{ ...styles.listItemLabel, textAlign: 'center' }}>{'No notification found'}</Text>
              </View>
        }
      </View>
    </GlobalFlex>
  );
};
export default Notifications;
