// noinspection ES6CheckImport

import React from 'react';
import { Text, TouchableOpacity, Image, View, Pressable, Platform } from 'react-native';
import { ActivityDot, ImgBackGroundgContainer } from '../../res/globalStyles';
import { widthPercentageToDP as wp } from 'react-native-responsive-screen';
import { colors } from '../../res/colors';
import { images } from '../../res/images';
import { fonts } from '../../res/fonts';
import { styles } from './style';

export const AppHeader = props => {
  const { pendingNotifications, userData, onBackPress = null } = props;

  return (
    <>
      <ImgBackGroundgContainer source={images.menu_line} resizeMode={'stretch'}>
        <View style={styles.imageWrapper}>
          <View style={styles.flexWrapper}>
            <View style={styles.flexInnerWrapper}>
              {onBackPress !== null && (
                <Pressable style={{ alignSelf: 'center' }} onPress={onBackPress}>
                  <Image
                    source={images.back_white}
                    style={styles.icBackStyle}
                  />
                </Pressable>
              )}
              <Image
                source={
                  userData?.avatar?.length > 0
                    ? { uri: userData?.avatar }
                    : images.avatar
                }
                style={styles.profile_ic}
              />
              <View style={{ alignSelf: 'center', marginLeft: '1rem' }}>
                <Text
                  style={{
                    color: colors.WHITE,
                    fontSize: '0.8rem',
                    textAlign: 'left',
                  }}>
                  {props.userData != null && props.userData?.displayName}{' '}
                </Text>

                {Platform.OS != 'web' &&
                  <Text
                    style={{
                      color: colors.WHITE,
                      fontSize: '0.5rem',
                      textAlign: 'left',
                      fontFamily: fonts.MEDIUM,
                    }}>
                    {`@${userData?.username}`}{' '}
                  </Text>
                }
              </View>
            </View>
            <View style={styles.flexInnerWrapper}>
              <TouchableOpacity
                style={styles.rightIconWrap}
                onPress={() => {
                  props.onNotification();
                }}>
                <Image
                  style={styles.image_ic}
                  source={images.notification_ic}
                />
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.rightIconWrap}
                onPress={() => {
                  props.action_open();
                }}>
                {pendingNotifications && <ActivityDot />}
                <Image style={styles.image_ic} source={images.setting_bar_ic} />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </ImgBackGroundgContainer>
    </>
  );
};
