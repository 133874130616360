import React, {useEffect, useState, useContext} from 'react';
// import {Link} from 'react-router-dom';
import {Link} from '@react-navigation/native';
import CustomSelect from '../../../reusable/form/select/customSelect/CustomSelect.jsx';
import {options} from '../../../reusable/form/select/mocData.jsx';
import {useDispatch, useSelector} from 'react-redux';
import {closeModal} from '../../../../store/slices/modalSlice/modalSlice';
import {useBuzzmiAuth} from '../../../../appState/hooks';

const FormReport = () => {
  const {updateLanguage, language} = useBuzzmiAuth();

  const data = useSelector(state => state.modal);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(
    options.filter(o => o.value == language) || [],
  );

  useEffect(() => {
    if (selected?.value) {
      updateLanguage(selected?.value);
      setTimeout(() => {
        dispatch(closeModal());
      }, 100);
    }
  }, [selected]);

  return (
    <form className="reports">
      <div className="reports__top">
        <div className="h4 text-alert reports__title">Report a problem</div>
        <div>
          <div className="h6 reports__subtitle">Topics</div>
          <ul className="reports__list">
            <li>
              <Link to="/">Logging in</Link>
            </li>
            <li>
              <Link to="/test2">Forgot password</Link>
            </li>
            <li>
              <Link to="/test3">Suspended account</Link>
            </li>
            <li>
              <Link to="/test4">Adding multiply accounts</Link>
            </li>
            <li>
              <Link to="/test5">Can’t log in with [Social network name]</Link>
            </li>
            <li>
              <Link to="/test6">Account logged out automatically</Link>
            </li>
            <li>
              <Link to="/test7">Other</Link>
            </li>
          </ul>
        </div>
      </div>

      <div>
        <div className="h4 text-alert reports__select-title">
          Change language
        </div>
        <CustomSelect
          selected={selected}
          setSelected={setSelected}
          options={options}
        />
      </div>
    </form>
  );
};

export default FormReport;
