import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import ReelCotainer from '../../container/App/ReelCotainer';
import { options } from './utils';
// import RecordingContainer from '../../container/App/ReelCotainer/RecordingContainer';
import PostFileViewer from '../../container/App/HomeDashboard/PostFileViewer';
import PostComment from '../../container/App/HomeDashboard/PostComment';
import ReelSearchContainer from '../../container/App/ReelCotainer/ReelSearchContainer';

const Stack = createNativeStackNavigator();

const ReelStackNavigator = () => {
  return (
    <Stack.Navigator initialRouteName={'Reel'}>
      <Stack.Screen name={'Reel'} options={options} component={ReelCotainer} />
      <Stack.Screen
        name={'ReelSearch'}
        options={options}
        component={ReelSearchContainer}
      />
      <Stack.Screen
        name={'PostComment'}
        options={options}
        component={PostComment}
      />
      <Stack.Screen
        name={'PostViewer'}
        options={options}
        component={PostFileViewer}
      />
    </Stack.Navigator>
  );
};

export default ReelStackNavigator;
