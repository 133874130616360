// noinspection JSUnresolvedReference

import { queryClient } from "../../navigator/AppNavigator";
import { POST, GET } from "../utils/apiClient";
import {
  LOGIN,
  SEND_OTP,
  REGISTER,
  ADD_INTEREST,
  LOGIN_SOCIAL,
  NEW_VERIFY_OTP,
  REGISTER_EMAIL,
  REGISTER_PHONE,
  REGISTER_SOCIAL,
  GET_USER_PROFILE,
  VERIFY_PHONE_OTP,
  VERIFY_EMAIL_CODE,
  NEW_RESET_PASSWORD,
  SEND_EMAIL_VERIFICATION,
  VALIDATE_USER_INFO
} from "../../api_helper/Api";

const postWithErrorHandling = apiEndpoint => {
  return async (credentials) => {
  
    let params = { isAuth: false, token: '' };

    if (credentials?.params) {
      params = credentials?.params
    }

    try {
      return await POST(apiEndpoint, params?.isAuth, params?.token, credentials);
    } catch (error) {
      if (typeof error?.message === 'string') {
        throw new Error(error);
      }
      throw new Error('Something went wrong...');
    }
  };
};

const getWithErrorHandling = apiEndpoint => {
  return async params => {
    try {
      return await GET(apiEndpoint, params);
    } catch (error) {
      if (typeof error?.message === 'string') {
        throw new Error(error);
      }
      throw new Error('Something went wrong...');
    }
  };
};

const fetchUserData = async token => {
  try {
    // TODO: Need to move this API to RQ (react-query)
    // return await queryClient.fetchQuery({
    //   queryKey: 'get_user_query',
    //   queryFn: getUserData({token})
    // });
    return await getUserData({ token });
  } catch (error) {
    if (typeof error?.message === 'string') {
      throw new Error(error);
    }
    throw new Error('Something went wrong...');
  }
};

const login = postWithErrorHandling(LOGIN);
const sendOTP = postWithErrorHandling(SEND_OTP);
const register = postWithErrorHandling(REGISTER);
const socialLogin = postWithErrorHandling(LOGIN_SOCIAL);
const addInterest = postWithErrorHandling(ADD_INTEREST);
const verifyOTP = postWithErrorHandling(NEW_VERIFY_OTP);
const getUserData = getWithErrorHandling(GET_USER_PROFILE);
const registerPhone = postWithErrorHandling(REGISTER_PHONE);
const registerEmail = postWithErrorHandling(REGISTER_EMAIL);
const verifyEmail = postWithErrorHandling(VERIFY_EMAIL_CODE);
const socialRegister = postWithErrorHandling(REGISTER_SOCIAL);
const verifyPhoneOTP = postWithErrorHandling(VERIFY_PHONE_OTP);
const verifyEmailCode = postWithErrorHandling(VERIFY_EMAIL_CODE);
const resetOldPassword = postWithErrorHandling(NEW_RESET_PASSWORD);
const sendEmailVerification = postWithErrorHandling(SEND_EMAIL_VERIFICATION);
const validateUserInfo = postWithErrorHandling(VALIDATE_USER_INFO);


export {
  login,
  sendOTP,
  register,
  verifyOTP,
  verifyEmail,
  socialLogin,
  addInterest,
  registerEmail,
  registerPhone,
  fetchUserData,
  verifyPhoneOTP,
  socialRegister,
  verifyEmailCode,
  resetOldPassword,
  sendEmailVerification,
  validateUserInfo
};
