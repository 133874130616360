// development
import Config from 'react-native-config';
export const APP_ID = Config.APP_ID;
export const API_SERVER_KEY = 'AAAAN9lNmwY:APA91bGjB9eC_gN3WeATCbk6fGb1RGN3wSTemboZ-vUHdME5NR73jTWnvW0Yq780movpHq1DTKFDIepWXAKkUTU-IUdMuQvUBlp6bzCwY4D2JiwWMtsnbppLE3-r8dEdm5aO2O9KOAnv'


// staging
// export const APP_ID = '033D0C2B-1B69-4592-90E5-2B6D8D58A86B';

// Production
// export const APP_ID = 'A602CBEC-EC8C-4EFA-83CE-CAE057853DD7'
