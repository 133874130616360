import React, {useState, Suspense, lazy} from 'react';
import PrimaryButton from '../../../components/reusable/form/items/buttons/PrimaryButton.jsx';
import AddCreatorsMobileBtns from '../AddCreators/items/AddCreatorsMobileBtns.jsx';
import BaseLayout from '../../../components/layouts/baseLayout/BaseLayout';
import {useNavigation} from '@react-navigation/native';
import {interests} from '../items/mocData.js';
import {getData} from '../../../res/asyncStorageHelper.web.js';
import Loading from 'react-fullscreen-loading';
import {colors} from '../../../res/colors.js';
import {POST} from '../../../api_helper/ApiServices.js';
import {ADD_INTEREST} from '../../../api_helper/Api.js';
import {ShowSuccess} from '../../HOC/MessageWeb.js';
import {useToast} from 'react-native-toast-notifications';
import Strings from '../../../string_key/Strings.web.js';
import {_userDetailsAtom} from '../../../appState/userState.js';
import {useEncryptedAtom} from '../../../appState/hooks/useEncryptedAtom/index.web.js';

import MainLayoutV2 from '../../../components/layouts/mainLayout/MainLayoutV2.jsx';
import BackBtnWithTitlePage from '../../../components/reusable/btns/backBtn/BackBtnWithTitlePage.jsx';
const InterestItemsCollection = lazy(() =>
  import('../items/InterestItemsCollection'),
); // Lazy-loaded

const ChooseInterest = () => {
  const [selectedItems, setSelectedItems] = useState([]);
  const {navigate} = useNavigation();
  const [isLoad, setIsLoad] = useState(false);
  const toast = useToast();
  const [userDetails, setUserDetails] = useEncryptedAtom(_userDetailsAtom);
  const handleContinue = () => {
    setIsLoad(true);
    getData('sessionToken', token => {
      getData('choose_interest', choose_interest => {
        getData('add_creator', add_creator => {
          getData('add_role', add_role => {
            getData('user_email', user_email => {
              let postData = {
                creators: add_creator,
                interestFields: choose_interest,
                email: user_email,
                preferredRole: add_role,
              };
              POST(
                ADD_INTEREST,
                true,
                token,
                null,
                postData,
                (response, isError) => {
                  setIsLoad(false);
                  if (isError) {
                    console.log('isError getting the interest', isError);
                  } else {
                    // navigate('MembershipV2');
                    // setUserDetails({...userDetails, justSignup: false});
                    window.location.reload();
                    setTimeout(() => {
                      ShowSuccess(response?.message, toast);
                    }, 250);
                  }
                },
              );
            });
          });
        });
      });
    });
  };

  return (
    // <BaseLayout showLogin>
    <MainLayoutV2>
      <Loading
        loading={isLoad}
        background="transparent"
        loaderColor={colors.PRIMARY_COLOR}
      />
      <BackBtnWithTitlePage hideBottomBorder={true} isFixed={true} />
      <div className="w-full max-w-[500px] md:max-w-[800px] px-4 py-10 mx-auto flex flex-col justify-center items-center text-center md:flex-column md:justify-start md:flex-0 sm:height-[calc(100vh-65px)] md:height-[calc(100vh-60px)] sm:px-4 md:px-0">
        <div className="profile__wrapper">
          <div className="row align-items-center mb-md-6 mb-2 gx-0">
            <div className="col-3">
              <img
                src="/images/assets/rocket.png"
                alt="rocket"
                className="img-fluid"
              />
            </div>
            <div className="col text-left">
              <div className="h4 mb-2">{Strings.we_are_all_ears}</div>
              <div className="text-r">{Strings.tell_us_what_your_interest}</div>
            </div>
          </div>

          <Suspense
            fallback={
              <Loading
                loading={true}
                background="transparent"
                loaderColor={colors.PRIMARY_COLOR}
              />
            }>
            <InterestItemsCollection
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              items={interests}
            />
          </Suspense>
          <AddCreatorsMobileBtns handleContinue={handleContinue} />
          <div className="d-none d-sm-block">
            <buttin onClick={handleContinue} className="d-block my-sm-8 w-100">
              <PrimaryButton>{Strings.next}</PrimaryButton>
            </buttin>
          </div>
        </div>
      </div>
    </MainLayoutV2>
    // {/* </BaseLayout> */}
  );
};

export default ChooseInterest;
