import {useMutation} from 'react-query';
import {postfileUpload} from '../../../network/api/app';

export const useUploadImages = () => {
  const {
    mutateAsync: mutateFileUploadAsync,
    isLoading: isFileUploadLoading,
    isError: isFileUploadError,
    // data: fileUploadResponse,
    // error: fileUploadErrorResponse,
  } = useMutation(postfileUpload);

  const UploadFiles = (params, callback) => {
    let promiseArray = [];
    params.forEach(param => {
      promiseArray.push(mutateFileUploadAsync(param));
    });

    Promise.all(promiseArray)
      .then(res => {
        callback(res.map(response => response?.link));
      })
      .catch(e => console.log('Error => ', e.message));
  };

  return {UploadFiles, isFileUploadLoading, isFileUploadError};
};
