import React, {useEffect, useState, useRef} from 'react';
import BackBtnWithTitlePage from '../../../../../components/reusable/btns/backBtn/BackBtnWithTitlePage';
import useWindowSize from '../../../../../hooks/useWindowSize';

const VideoFullscreen = props => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const videoRef = useRef(null);
  const {width} = useWindowSize();

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement && width < 767) {
      videoElement.play();
      setIsVideoPlaying(true);
    }
  }, [width]);

  const handleVideoClick = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      if (isVideoPlaying) {
        videoElement.pause();
      } else {
        videoElement.play();
      }
      setIsVideoPlaying(!isVideoPlaying);
    }
  };

  return (
    <>
      <BackBtnWithTitlePage btnText="" title="Video" hideBottomBorder />
      <div className="reels__fullscreen flex justify-center w-100">
        <video
          ref={videoRef}
          poster={global.videoPost?.poster}
          className={'flex justify-center'}
          onClick={handleVideoClick}
          controls={true}>
          <source src={global.videoPost?.original} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </>
  );
};

export default VideoFullscreen;
